import { t } from "i18next";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { FiraLoginInput } from "../../../utils/types";
import Button, { ButtonStyle } from "../../Button/Button";
import { ArrowDown, TrashCan } from "../../FiraIcons";
import OpenLink from "../../FiraIcons/OpenLink";
import Input, { InputStyles } from "../../Input/Input";
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";
import styles from "./EventLogin.module.scss";
import { EventConfigurationContext } from "../../../views/EventConfigurationView/EventConfigurationView";

export interface LoginConfiguration {
  enableLogin: boolean;
  disclaimerUrl: string;
  loginForm: FiraLoginInput[];
}

enum fieldLabels {
  text = "event_creation.params.fieldTypes.text",
  email = "event_creation.params.fieldTypes.email",
  password = "event_creation.params.fieldTypes.password",
  tel = "event_creation.params.fieldTypes.tel",
  number = "event_creation.params.fieldTypes.number",
}

const EventLogin = () => {
  const { firaWebConfiguration, setFiraWebConfiguration } = useContext(
    EventConfigurationContext
  );
  const [useLogin, setUseLogin] = useState<boolean>(false);
  const [urlInput, setUrlInput] = useState<string>("");
  //www.firaonlive.com/TratamiendoDeDatosPersonales
  const [urlInputPersonalDta, setUrlInputPersonalData] = useState<string>(
    firaWebConfiguration.personalDataUrl
      ? firaWebConfiguration.personalDataUrl
      : ""
  );
  const [userFields, setUserFields] = useState<FiraLoginInput[]>([
    {
      fieldLabel: "user",
      fieldType: "USERNAME",
      isRequired: true,
      order: 0,
    },
  ]);
  const [inputNames, setInputNames] = useState<string[]>([]);
  const [isEnableFields, setIsEnableFields] = useState(false);
  const [activePersonalData, setActivePersonalData] = useState<boolean>(false);
  const urlRegex = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/))(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)(\.){1,}[a-zA-Z0-9()]*\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=])*/gi
  );

  const handlePopulation = () => {
    const types = Object.values(fieldLabels);
    const translatedTypes: string[] = [];
    types.map((translation) => {
      translatedTypes.push(t(translation));
    });

    //Used as display for select options
    setInputNames(translatedTypes);
  };

  const handleToggle = () => {
    setIsEnableFields(!useLogin);
    setUseLogin(!useLogin);
    let config = firaWebConfiguration;
    config.enableLogin = !useLogin;
    config.loginForm = userFields;
    setFiraWebConfiguration(config);
  };

  const handleToggleUrlPersonalData = () => {
    if (!activePersonalData === false) {
      setUrlInputPersonalData("");
    }
    setActivePersonalData(!activePersonalData);
    setFiraWebConfiguration({
      ...firaWebConfiguration,
      personalDataUrl: urlInputPersonalDta,
    });
  };

  useEffect(() => {
    setFiraWebConfiguration({
      ...firaWebConfiguration,
      personalDataUrl: urlInputPersonalDta,
    });
  }, [urlInputPersonalDta]);

  const handleUrlInput = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const url = event.target.value;
    setUrlInput(url);
    setFiraWebConfiguration({
      ...firaWebConfiguration,
      disclaimerUrl: url,
    });
  };

  const handleUrlInputPersonalData = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const url = event.target.value;
    setUrlInputPersonalData(url);
    setFiraWebConfiguration({
      ...firaWebConfiguration,
      personalDataUrl: url,
    });
  };

  const handleInputChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const values: FiraLoginInput[] = [...userFields];
    values[index].fieldLabel = event.target.value;
    setUserFields(values);
  };

  const addField = () => {
    let newField = {
      fieldLabel: t(fieldLabels.text),
      fieldType: "text",
      isRequired: false,
      order: 0,
    };

    if (userFields != null && userFields.length > 0) {
      setUserFields([...userFields, newField]);
    } else {
      setUserFields([newField]);
    }
  };

  useEffect(() => {
    setIsEnableFields(!useLogin);
  }, []);

  const deleteField = (index: number) => {
    const values: FiraLoginInput[] = [...userFields];
    values.splice(index, 1);
    setUserFields(values);
  };

  const handleSelectChange = (
    index: number,
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const fields: FiraLoginInput[] = [...userFields];
    const { value } = event.target;
    fields[index].fieldLabel = value;
    switch (value) {
      case t(fieldLabels.text):
        fields[index].fieldType = "text";

        break;
      case t(fieldLabels.email):
        fields[index].fieldType = "email";
        break;
      case t(fieldLabels.password):
        fields[index].fieldType = "password";
        break;
      case t(fieldLabels.tel):
        fields[index].fieldType = "tel";
        break;
      case t(fieldLabels.number):
        fields[index].fieldType = "number";
        break;
    }

    setUserFields(fields);
  };

  const handleRequired = (index: number) => {
    const fields: FiraLoginInput[] = [...userFields];
    fields[index].isRequired = !fields[index].isRequired;
    if (fields != null) {
      setUserFields(fields);
    } else {
      setUserFields([]);
    }
  };

  const getDefaultValue = (input: string) => {
    switch (input) {
      case "text":
        return t(fieldLabels.text);
      case "email":
        return t(fieldLabels.email);
      case "password":
        return t(fieldLabels.password);
      case "tel":
        return t(fieldLabels.tel);
      case "number":
        return t(fieldLabels.number);
    }
  };

  useEffect(() => {
    handlePopulation();
  }, []);

  useEffect(() => {
    if (userFields)
      if (userFields.length === 4) {
        setIsEnableFields(false);
      } else {
        setIsEnableFields(true);
      }
  }, [userFields]);

  useEffect(() => {
    if (useLogin === true) {
      setFiraWebConfiguration({
        ...firaWebConfiguration,
        loginForm: [
          {
            fieldLabel: "user",
            fieldType: "USERNAME",
            isRequired: true,
            order: 0,
          },
          ...userFields,
        ],
      });
    } else {
      setFiraWebConfiguration({
        ...firaWebConfiguration,
        loginForm: [],
      });
    }
  }, [useLogin]);

  const validateUrl = () => {
    let url = "";
    if (urlInput && !urlInput.includes("https") && !urlInput.includes("http")) {
      url = url.concat("https://", urlInput.replace(" ", ""));
    } else {
      url = urlInput;
    }
    window.open(url, "_blank");
  };

  const validateUrlData = () => {
    let url = "";
    if (
      urlInputPersonalDta &&
      !urlInputPersonalDta.includes("https") &&
      !urlInputPersonalDta.includes("http")
    ) {
      url = url.concat("https://", urlInputPersonalDta.replace(" ", ""));
    } else {
      url = urlInputPersonalDta;
    }
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (firaWebConfiguration) {
      setUserFields(firaWebConfiguration.loginForm);
    }
  }, []);

  useEffect(() => {
    setFiraWebConfiguration({ ...firaWebConfiguration, loginForm: userFields });
  }, [userFields]);

  useEffect(() => {
    if (firaWebConfiguration) {
      setUseLogin(firaWebConfiguration.enableLogin);
      setUserFields(
        firaWebConfiguration.loginForm !== null
          ? firaWebConfiguration.loginForm
          : []
      );
      setUrlInput(firaWebConfiguration.disclaimerUrl);
    }
  }, []);

  return (
    <>
      <div className={styles.LoginParamsWrapper}>
        <div className={styles["container-box"]}>
          <div className={styles.LoginParamsContainer}>
            {/* Switch Controlller */}
            <div className={styles.SwitchContainer}>
              <div className={`${styles.h5} ${styles.LoginEnabled}`}>
                {t("event_creation.params.loginEnabled")}
              </div>
              <div className={styles.LoginParamMessage}>
                <ToggleSwitch checked={useLogin} handleChecked={handleToggle} />
              </div>
            </div>

            {/* Login Fields */}
            <div className={!useLogin ? styles.DisabledForm : undefined}>
              <div className={` ${styles.FieldsContainer}`}>
                <div className={styles.Fields}>
                  {/* User Field */}
                  <div className={`${styles.Field} ${styles.UserField} `}>
                    <Input
                      inputStyle={
                        !useLogin ? InputStyles.url : InputStyles.normal
                      }
                      width="100%"
                      height="48px"
                      value={t("event_creation.params.fieldTypes.username")}
                      isDisabled={true}
                      onChange={() => ""}
                    />

                    <div className={styles.LoginSelect}>
                      <select
                        name="loginSelect"
                        onChange={() => ""}
                        disabled={true}
                      >
                        <option>
                          {t("event_creation.params.fieldTypes.text")}
                        </option>
                      </select>
                      <div className={styles.ArrowIcon}>
                        <ArrowDown />
                      </div>
                    </div>
                    <Button
                      width="95px"
                      height="37px"
                      isDisabled={true}
                      buttonStyle={ButtonStyle.main}
                    >
                      <span className={`${styles["alert-text-14-bold"]} `}>
                        {t("event_creation.params.requiredField")}
                      </span>
                    </Button>
                    <div
                      style={{
                        padding: "0px",
                      }}
                    >
                      <Button
                        width="33px"
                        height="36px"
                        isDisabled={true}
                        buttonStyle={ButtonStyle.border}
                        className={undefined}
                      >
                        <span
                          style={{
                            color: "#230B34",
                            fontSize: "18px",
                          }}
                        >
                          <TrashCan color="#D0D0D0" />
                        </span>
                      </Button>
                    </div>
                  </div>

                  {/* Regular Fields */}
                  {userFields != null &&
                    userFields.length != 0 &&
                    userFields.map((input, index) => {
                      return input.fieldType === "USERNAME" ? null : (
                        <div className={styles.Field} key={index}>
                          <Input
                            inputStyle={
                              !useLogin ? InputStyles.url : InputStyles.normal
                            }
                            width="100%"
                            height="48px"
                            isDisabled={!useLogin}
                            value={input.fieldLabel}
                            onChange={(e) => {
                              handleInputChange(index, e);
                            }}
                          />
                          <div className={styles.LoginSelect}>
                            <select
                              value={getDefaultValue(input.fieldType)}
                              name="loginSelect"
                              onChange={(e) => handleSelectChange(index, e)}
                              disabled={!useLogin}
                            >
                              {inputNames.map((type, i) => {
                                return (
                                  <option value={type} key={i}>
                                    {type}
                                  </option>
                                );
                              })}
                            </select>
                            <div className={styles.ArrowIcon}>
                              <ArrowDown />
                            </div>
                          </div>
                          <Button
                            clickHandler={() => handleRequired(index)}
                            width="95px"
                            height="37px"
                            isDisabled={!useLogin}
                            buttonStyle={
                              input.isRequired
                                ? ButtonStyle.main
                                : ButtonStyle.border
                            }
                          >
                            <span className={styles["alert-text-14-bold"]}>
                              {t("event_creation.params.requiredField")}
                            </span>
                          </Button>
                          <div
                            style={{
                              padding: "0px",
                            }}
                          >
                            <Button
                              width="33px"
                              height="36px"
                              isDisabled={!useLogin}
                              buttonStyle={ButtonStyle.border}
                              className={!useLogin ? undefined : styles.Delete}
                              clickHandler={() => deleteField(index)}
                            >
                              <span
                                style={{
                                  color: !useLogin ? "#230B34" : "#F16063",
                                  fontSize: "18px",
                                }}
                              >
                                <TrashCan
                                  color={!useLogin ? "#D0D0D0" : "#F16063"}
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div
                  className={`${styles.AddNew} ${
                    !useLogin ? styles.DisabledForm : ""
                  }`}
                >
                  <Button
                    width="261px"
                    height="50px"
                    isDisabled={!isEnableFields}
                    clickHandler={addField}
                    buttonStyle={
                      !useLogin ? ButtonStyle.inactivate : ButtonStyle.main
                    }
                  >
                    <span className={styles["alert-text-14-bold"]}>
                      {t("event_creation.params.addFields")}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.ContainerElements}>
              {/* URL Input */}
              <div
                className={`${styles.URLContainer} ${
                  !useLogin ? styles.DisabledForm : ""
                }`}
              >
                <div
                  className={styles.h6}
                  style={{
                    paddingBottom: "17px",
                    color: useLogin ? "#000" : "#D0D0D0",
                  }}
                >
                  {t("event_creation.params.terms_and_conditions")}
                  <a className={styles.LabelIcon} onClick={validateUrl}>
                    <OpenLink color={useLogin ? "#000" : "#D0D0D0"} />
                  </a>
                </div>
                <Input
                  width="100%"
                  height="50px"
                  inputStyle={InputStyles.url}
                  isDisabled={!useLogin}
                  value={urlInput}
                  onChange={handleUrlInput}
                />
              </div>

              <div
                className={`${styles.URLContainer} ${
                  !useLogin ? styles.DisabledForm : ""
                }`}
              >
                <div className={styles.TitleContainer}>
                  <div
                    className={styles.h6}
                    style={{
                      paddingBottom: "17px",
                      color: useLogin ? "#000" : "#D0D0D0",
                    }}
                  >
                    {t(
                      "event_creation.params.terms_and_conditions_personal_data"
                    )}
                    <a className={styles.LabelIcon} onClick={validateUrlData}>
                      <OpenLink color={useLogin ? "#000" : "#D0D0D0"} />
                    </a>
                  </div>

                  <ToggleSwitch
                    checked={activePersonalData}
                    handleChecked={handleToggleUrlPersonalData}
                  />
                </div>
                <Input
                  width="100%"
                  height="50px"
                  inputStyle={InputStyles.url}
                  isDisabled={!activePersonalData}
                  value={urlInputPersonalDta}
                  onChange={handleUrlInputPersonalData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventLogin;
