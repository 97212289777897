import { useContext, useEffect, useState } from "react";
import { SimplePermissionItem } from "../../../types/Response";
import {
  FiraPackage,
  FiraUserRole,
  PermissionCardType,
} from "../../../utils/enums";
import {
  activateAll,
  areAllActive,
  checkActiveByGroup,
  hasSomePermissions,
  inactivateAll,
  singleCheckHandler,
  userHasPermission,
} from "../../../utils/handlePermissionCheckboxSelection";
import {
  permissionsEditableObjectBuilder,
  permissionTitlesObjBuilder,
  SimplePermissionsCaller,
} from "../../../utils/handleUserPermissions";
import {
  PermissionCategories,
  PermissionsByGroupType,
} from "../../../utils/types.d";
import { RolesAndPermissionsContext } from "../../../views/OwnerPermissionsEditView/OwnerPermissionsEditView";
import {
  CalendarIcon,
  Dashboard,
  FiraLiveLogo,
  OpenBox,
  Video,
} from "../../FiraIcons";
import { FiraUserPermissionComponent } from "../../NewUserComponents";
import {
  EditablePermissionsType,
  FiraSimplePermissionList,
} from "../../NewUserComponents/FiraUserPermissionComponent/FiraUserPermissionComponent";

import { FiraTV, ManagerWeb, ProSeller } from "./Manager/Manager";
import { ModeratorFiraTV, ModeratorManager } from "./Moderator/Moderator";
import {
  OperatorDashboard,
  OperatorEvents,
  OperatorFiraTV,
  OperatorProducts,
  OperatorProSeller,
} from "./Operator/Operator";
import styles from "./Permissions.module.scss";

const Permissions = () => {
  const { permissionsData, isLoading, setIsLoading } = useContext(
    RolesAndPermissionsContext
  );

  useEffect(() => {
    if (permissionsData.permissions) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      setIsLoading(true);
    }
  }, [permissionsData.permissions]);

  return (
    <>
      <div
        className={`${styles.PermissionsWrapper} ${
          isLoading ? styles.isLoading : ""
        }`}
      >
        {isLoading && (
          <div className={styles.loaderContainer}>
            <span className={styles.loader}></span>
          </div>
        )}
        {!isLoading && (
          <div style={{ width: "100%" }}>
            {permissionsData.role !== null &&
              (() => {
                switch (permissionsData.role) {
                  case FiraUserRole.MANAGER:
                    return <PermissionsForManager />;
                  case FiraUserRole.MODERATOR:
                    return <PermissionsForModerator />;
                  case FiraUserRole.OPERATOR:
                    return <PermissionsForOperator />;
                  default:
                    return null;
                }
              })()}
          </div>
        )}
      </div>
    </>
  );
};

const PermissionsForManager = () => {
  const { handleProductChange, permissionsData, mainInfo } = useContext(
    RolesAndPermissionsContext
  );

  const handleModuleClick = (permissions: SimplePermissionItem) => {
    handleProductChange(permissions.firaProduct);
  };

  return (
    <div className={styles.PermissionsGrid}>
      <FiraUserPermissionComponent
        icon={<FiraLiveLogo />}
        title={ManagerWeb().title}
        permissions={{
          firaProduct: FiraPackage.FIRA_WEB,
          permissions: [],
        }}
        description={ManagerWeb().description}
        isEditable={false}
        hasPermissions={userHasPermission(
          permissionsData.permissions,
          FiraPackage.FIRA_WEB,
          mainInfo.role
        )}
        onModuleClick={handleModuleClick}
        // onPermissionClick={() => handleProductChange(FiraPackage.FIRA_WEB)}
      >
        <FiraSimplePermissionList permissions={ManagerWeb().permissions} />
      </FiraUserPermissionComponent>
      <FiraUserPermissionComponent
        icon={<FiraLiveLogo />}
        title={ProSeller().title}
        permissions={{
          firaProduct: FiraPackage.PROSELLER,
          permissions: [],
        }}
        description={ProSeller().description}
        isEditable={false}
        hasPermissions={userHasPermission(
          permissionsData.permissions,
          FiraPackage.PROSELLER,
          mainInfo.role
        )}
        onModuleClick={handleModuleClick}
        // onPermissionClick={() => handleProductChange(FiraPackage.PROSELLER)}
      >
        <FiraSimplePermissionList permissions={ProSeller().permissions} />
      </FiraUserPermissionComponent>
      <FiraUserPermissionComponent
        icon={<Video />}
        title={FiraTV().title}
        permissions={{
          firaProduct: FiraPackage.FIRA_TV,
          permissions: [],
        }}
        description={FiraTV().description}
        isEditable={false}
        hasPermissions={userHasPermission(
          permissionsData.permissions,
          FiraPackage.FIRA_TV,
          mainInfo.role
        )}
        onModuleClick={handleModuleClick}
        // onPermissionClick={() => handleProductChange(FiraPackage.FIRA_TV)}
      >
        <FiraSimplePermissionList permissions={FiraTV().permissions} />
      </FiraUserPermissionComponent>
    </div>
  );
};

const PermissionsForOperator = () => {
  const { permissionsData, handleModuleSelection, handleProductChange } =
    useContext(RolesAndPermissionsContext);
  const [dashboard, setDashboard] = useState<EditablePermissionsType[]>();
  const [events, setEventsPermissions] = useState<EditablePermissionsType[]>();
  const [products, setProducts] = useState<EditablePermissionsType[]>();
  const [firaTV, setFiraTV] = useState<EditablePermissionsType[]>();

  //New values
  const [dashboardData, setDashboardData] = useState<SimplePermissionItem>();
  const [eventsData, setEventsData] = useState<SimplePermissionItem>();
  const [producstData, setProductsData] = useState<SimplePermissionItem>();
  const [firaTVData, setFiraTVData] = useState<SimplePermissionItem>();
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const handleSelection = (item: EditablePermissionsType) => {
    let product = {
      firaProduct: item.firaProduct,
      permissions: item.permissions,
    };
    handleModuleSelection(product, item.isActive);
  };

  const handleModule = (item: SimplePermissionItem, isSelected: boolean) => {
    handleModuleSelection(item, isSelected);
  };

  const singleSelectionHandler = (userPermissions: EditablePermissionsType) => {
    handleSelection(userPermissions);
    switch (userPermissions.card) {
      case PermissionCardType.dashboard:
        {
          dashboard &&
            setDashboard(singleCheckHandler(userPermissions, dashboard));
        }
        break;
      case PermissionCardType.events:
        {
          events &&
            setEventsPermissions(singleCheckHandler(userPermissions, events));
        }
        break;
      case PermissionCardType.products:
        {
          products &&
            setProducts(singleCheckHandler(userPermissions, products));
        }
        break;
      case PermissionCardType.firaTv:
        {
          firaTV && setFiraTV(singleCheckHandler(userPermissions, firaTV));
        }
        break;
      default:
        return;
    }
  };

  const selectAll = (val: boolean, group: PermissionCardType) => {
    switch (group) {
      case PermissionCardType.dashboard:
        {
          if (dashboard)
            val
              ? setDashboard(activateAll(dashboard))
              : setDashboard(inactivateAll(dashboard));
        }
        break;
      case PermissionCardType.events:
        {
          if (events)
            val
              ? setEventsPermissions(activateAll(events))
              : setEventsPermissions(inactivateAll(events));
        }
        break;
      case PermissionCardType.products:
        {
          if (products)
            val
              ? setProducts(activateAll(products))
              : setProducts(inactivateAll(products));
        }
        break;
      case PermissionCardType.firaTv:
        {
          if (firaTV)
            val
              ? setFiraTV(activateAll(firaTV))
              : setFiraTV(inactivateAll(firaTV));
        }
        break;
    }
  };

  const setUp = async () => {
    const dash = await OperatorDashboard().permissions;
    let builder;
    builder = await permissionsEditableObjectBuilder(
      dash,
      FiraPackage.FIRA_WEB,
      permissionsData.permissions,
      PermissionCardType.dashboard
    );
    setDashboard(builder);

    const ev = await OperatorEvents().permissions;
    let evBuilder = await permissionsEditableObjectBuilder(
      ev,
      FiraPackage.FIRA_WEB,
      permissionsData.permissions,
      PermissionCardType.events
    );
    setEventsPermissions(evBuilder);

    const prod = await OperatorProducts().permissions;
    let prodBuilder = await permissionsEditableObjectBuilder(
      prod,
      FiraPackage.FIRA_WEB,
      permissionsData.permissions,
      PermissionCardType.products
    );
    setProducts(prodBuilder);

    const tv = await OperatorFiraTV().permissions;
    let tvBuilder = await permissionsEditableObjectBuilder(
      tv,
      FiraPackage.FIRA_TV,
      permissionsData.permissions,
      PermissionCardType.firaTv
    );
    setFiraTV(tvBuilder);
  };

  const setUpByModule = async () => {
    const dashboardTypes = [PermissionCategories.FIRA_WEB_DASHBOARD];

    const eventTypes = [
      PermissionCategories.EVENT_MANAGEMENT,
      PermissionCategories.EVENT_MODERATION,
      PermissionCategories.EVENT_STATISTICS,
    ];
    const productTypes = [
      PermissionCategories.PRODUCTS_MANAGEMENT,
      PermissionCategories.PRODUCTS_BATCH,
    ];
    const firaTVTypes = [
      PermissionCategories.VOD_MANAGEMENT,
      PermissionCategories.WIDGETS_MANAGEMENT,
      PermissionCategories.FIRA_TV_STATISTICS,
      PermissionCategories.FIRA_TV_COLLECTIONS_MANAGEMENT,
    ];

    let dashBuilder = await SimplePermissionsCaller(dashboardTypes);
    let eventsBuilder = await SimplePermissionsCaller(eventTypes);
    let productsBuilder = await SimplePermissionsCaller(productTypes);
    let firaTvBuilder = await SimplePermissionsCaller(firaTVTypes);

    setDashboardData({
      firaProduct: FiraPackage.FIRA_WEB,
      permissions: dashBuilder,
    });

    setEventsData({
      firaProduct: FiraPackage.FIRA_WEB,
      permissions: eventsBuilder,
    });

    setProductsData({
      firaProduct: FiraPackage.FIRA_WEB,
      permissions: productsBuilder,
    });

    setFiraTVData({
      firaProduct: FiraPackage.FIRA_TV,
      permissions: firaTvBuilder,
    });
  };

  useEffect(() => {
    if (dashboardData && firaTVData && producstData && eventsData) {
      setShowLoader(false);
    }
  }, [dashboardData, firaTVData, producstData, eventsData]);

  useEffect(() => {
    setShowLoader(true);
    setUp();
    setUpByModule();
  }, []);

  return (
    <>
      {showLoader && (
        <div className={styles.loaderContainer}>
          <span className={styles.loader}></span>
        </div>
      )}
      <div className={styles.PermissionsGrid}>
        <>
          {!showLoader && (
            <>
              {dashboardData && (
                <FiraUserPermissionComponent
                  canBeSelected={false}
                  icon={<Dashboard />}
                  title={OperatorDashboard().title}
                  permissions={dashboardData}
                  description={OperatorDashboard().description}
                  isEditable={false}
                >
                  <FiraSimplePermissionList
                    permissions={permissionTitlesObjBuilder(
                      OperatorDashboard().permissions
                    )}
                  />
                </FiraUserPermissionComponent>
              )}
              {eventsData && (
                <FiraUserPermissionComponent
                  canBeSelected={false}
                  icon={<CalendarIcon />}
                  title={OperatorEvents().title}
                  permissions={eventsData}
                  description={OperatorEvents().description}
                  isEditable={false}
                >
                  <FiraSimplePermissionList
                    permissions={permissionTitlesObjBuilder(
                      OperatorEvents().permissions
                    )}
                  />
                </FiraUserPermissionComponent>
              )}
              {producstData && (
                <FiraUserPermissionComponent
                  canBeSelected={false}
                  icon={<OpenBox />}
                  title={OperatorProducts().title}
                  permissions={producstData}
                  description={OperatorProducts().description}
                  isEditable={false}
                >
                  <FiraSimplePermissionList
                    permissions={permissionTitlesObjBuilder(
                      OperatorProducts().permissions
                    )}
                  />
                </FiraUserPermissionComponent>
              )}
              {firaTVData && (
                <FiraUserPermissionComponent
                  canBeSelected={false}
                  icon={<Video />}
                  title={OperatorFiraTV().title}
                  permissions={firaTVData}
                  description={OperatorFiraTV().description}
                  isEditable={false}
                >
                  <FiraSimplePermissionList
                    permissions={permissionTitlesObjBuilder(
                      OperatorFiraTV().permissions
                    )}
                  />
                </FiraUserPermissionComponent>
              )}
              {firaTVData && (
                <FiraUserPermissionComponent
                  canBeSelected={false}
                  icon={<FiraLiveLogo />}
                  title={OperatorProSeller().title}
                  permissions={[]}
                  description={OperatorProSeller().description}
                  isEditable={false}
                >
                  <FiraSimplePermissionList
                    permissions={OperatorProSeller().permissions}
                  />
                </FiraUserPermissionComponent>
              )}
            </>
          )}
        </>
      </div>
    </>
  );
};

const PermissionsForModerator = () => {
  const { handleProductChange, permissionsData, mainInfo } = useContext(
    RolesAndPermissionsContext
  );
  return (
    <div className={styles.PermissionsGrid}>
      <FiraUserPermissionComponent
        icon={<FiraLiveLogo />}
        title={ModeratorManager().title}
        permissions={[]}
        description={ModeratorManager().description}
        isEditable={false}
        onModuleClick={() => handleProductChange(FiraPackage.FIRA_WEB)}
        hasPermissions={userHasPermission(
          permissionsData.permissions,
          FiraPackage.FIRA_WEB,
          mainInfo.role
        )}
      >
        <FiraSimplePermissionList
          permissions={permissionTitlesObjBuilder(
            OperatorProducts().permissions
          )}
        />
      </FiraUserPermissionComponent>
      <FiraUserPermissionComponent
        icon={<Video />}
        title={ModeratorFiraTV().title}
        permissions={[]}
        description={ModeratorFiraTV().description}
        isEditable={false}
        onModuleClick={() => handleProductChange(FiraPackage.FIRA_TV)}
        hasPermissions={userHasPermission(
          permissionsData.permissions,
          FiraPackage.FIRA_TV,
          mainInfo.role
        )}
      >
        <FiraSimplePermissionList permissions={ModeratorFiraTV().permissions} />
      </FiraUserPermissionComponent>
    </div>
  );
};

export default Permissions;
