import { ContentState, EditorState } from "draft-js";
import { t } from "i18next";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventCategoriesActionCreator } from "../../../store/Event/EventActions";
import {
  Category,
  EventGeneralDetailsType,
  RootState,
  UpdateCategoryBody,
} from "../../../utils/types";
import {
  CloudUpload,
  Image,
  MagnifyingGlass,
  Pause,
  Play,
  VideoCamera,
} from "../../FiraIcons";
import SvgVolume from "../../FiraIcons/Volume";
import Input, { InputStyles } from "../../Input/Input";
import TextEditor from "../../TextEditor/TextEditor";
import styles from "./VideoGeneralDetails.module.scss";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import "./VideoControls.scss";
import SvgMute from "../../FiraIcons/Mute";

import {
  updateAddCategoryCreator,
  updateDeleteCategoryCreator,
} from "../../../store/Video/VideoActions";
import PopUpAlert, { PopUpAlertTypes } from "../../PopUpAlert/PopUpAlert";

let interval: any;

export enum VideoControlsType {
  mute = "mute",
  unmute = "unmute",
  play = "play",
  pause = "pause",
}

interface VideoGeneralDetailsProps {
  detailsData(details: EventGeneralDetailsType): void;
  teaserFile(fileAvailable: boolean, file?: File, url?: string): void;
  previewFile(fileAvailable: boolean, file?: File, url?: string): void;
  newConfiguration: boolean;
  previousData?: EventGeneralDetailsType;
  prevImage?: string;
  prevTeaser?: string;
  handleCategories(cat: Category[], isAdd: boolean): void;
  prevCategories: Category[];
}

const VideoGeneralDetailsInitialState = {
  broadcastingId: "",
  eventName: "",
  scheduleDate: new Date("2022/10/02"),
};

const VideoGeneralData = ({
  detailsData,
  teaserFile,
  previewFile,
  previousData,
  prevImage,
  prevTeaser,
  newConfiguration,
  prevCategories,
  handleCategories,
}: VideoGeneralDetailsProps) => {
  const dispatch = useDispatch();
  const { event, video } = useSelector((state: RootState) => state);

  const [imagePreview, setImagePreview] = useState<string>(
    prevImage !== undefined ? prevImage : ""
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [videoPreview, setVideoPreview] = useState<string>(
    prevTeaser !== undefined ? prevTeaser : ""
  );
  const inputRefVideo = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const formvideoRef = useRef<HTMLFormElement>(null);
  const [playing, setPlaying] = useState(false);
  const [mute, setMute] = useState(false);

  const [progress, setProgress] = useState<number | undefined>(0);
  const [textAreaState, setTextAreaState] = useState(
    EditorState.createWithContent(ContentState.createFromText(""))
  );
  const videoFileFormat: string = "Mp4";
  const videoFileSize: string = "10Mb (1:1)";
  const imageFileFormat: string = "Jpg, Jpeg, Png";
  const imageFileSize: string = "600x600 (1:1)";
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [categories, setCategories] = useState<Category[] | undefined>([
    {
      id: "",
      description: "",
    },
  ]);
  const [selectedCategories, setSelectedCategories] =
    useState<Category[]>(prevCategories);
  const [deletedCategories, setdeletedCategories] = useState<Category[]>();
  const [videoName, setVideoName] = useState<string>(
    previousData?.eventName !== undefined ? previousData.eventName : ""
  );

  const [eventDetails, setEventDetails] = useState<EventGeneralDetailsType>(
    previousData !== undefined ? previousData : VideoGeneralDetailsInitialState
  );

  //FORMS
  const handleEventName = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const name = event.target.value;
    setVideoName(name);
    setEventDetails({ ...eventDetails, eventName: name });
    detailsData({ ...eventDetails, eventName: name });
  };

  const handleEditor = (editorState: EditorState) => {
    setTextAreaState(editorState);
    setEventDetails({
      ...eventDetails,
      eventDescription:
        editorState.getCurrentContent().getPlainText().length > 0
          ? editorState.getCurrentContent().getPlainText()
          : " ",
    });
    detailsData({
      ...eventDetails,
      eventDescription:
        editorState.getCurrentContent().getPlainText().length > 0
          ? editorState.getCurrentContent().getPlainText()
          : " ",
    });
  };

  //IMAGE & VIDEO/CONTROLS

  const onImageClickHandle = () => {
    inputRef.current?.click();
  };

  const onImageInputHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const fileType = event.target.files[0].type;
      const fileSize = event.target.size;
      if (!fileType.match(/.(jpg|jpeg|png)$/i) || fileSize > 5242880) {
        return;
      }
      const img = URL.createObjectURL(event.target.files[0]);
      setImagePreview(img);
      previewFile(true, event.target.files[0], img);
    }
  };

  const onVideoClickHandle = () => {
    inputRefVideo.current?.click();
  };

  const onVideoInputHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files != undefined) {
      const fileType = event.target.files[0].type;
      const fileSize = event.target.files[0].size;
      if (fileType !== "video/mp4" || fileSize > 5242880) {
        return;
      }
      const vid = URL.createObjectURL(event.target.files[0]);
      teaserFile(true, event.target.files[0], vid);
      setVideoPreview(vid);
    }
  };

  const videoHandler = (
    event: React.MouseEvent<HTMLElement>,
    control: VideoControlsType
  ) => {
    event.stopPropagation();
    if (control === VideoControlsType.play) {
      setPlaying(true);
      const vid = document.getElementById("preview-video") as HTMLVideoElement;
      handleTime();
      videoRef.current?.play();
    } else if (control === VideoControlsType.pause) {
      videoRef.current?.pause();
      setPlaying(false);
      clearInterval(interval);
    }
  };

  const videoMutedHandler = (
    event: React.MouseEvent<HTMLElement>,
    control: VideoControlsType
  ) => {
    event.stopPropagation();
    if (control === VideoControlsType.mute) {
      setMute(true);
      if (videoRef.current?.currentTime !== undefined) {
        videoRef.current.muted = true;
      }
    } else if (control === VideoControlsType.unmute) {
      setMute(false);
      if (videoRef.current?.currentTime !== undefined) {
        videoRef.current.muted = false;
      }
    }
  };
  const handleTime = () => {
    const vid = document.getElementById("preview-video") as HTMLVideoElement;
    const videoT = Math.floor(vid.duration);
    interval = setInterval(() => {
      if (videoRef.current?.currentTime !== undefined && videoT !== undefined) {
        if (videoT > 0) {
          setProgress(
            Math.floor((videoRef.current?.currentTime / videoT) * 100)
          );
        }
      }
      if (videoRef.current?.ended == true) {
        clearInterval(interval);
      }
    }, 100);
  };

  const handleProgress = (val: number | number[]) => {
    const vid = document.getElementById("preview-video") as HTMLVideoElement;
    const videoT = Math.floor(vid.duration);
    if (videoRef.current?.currentTime !== undefined && videoT !== undefined) {
      let t = Math.round(Number(val));
      videoRef.current.currentTime = Math.floor((t / 100) * videoT);
      handleTime();
    }
  };

  // CATEGORIES

  const getCategories = () => {
    dispatch(getEventCategoriesActionCreator());
  };

  const handleCategoriesFiltering = (ev: ChangeEvent<HTMLInputElement>) => {
    const keyword = ev.target.value;
    setSearchInputValue(keyword);

    if (keyword !== "") {
      setCategories(
        event.eventCategories?.filter((p) => {
          return p.description
            .toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase());
        })
      );
    } else {
      setCategories(event.eventCategories);
    }
  };

  const checkSelection = (category: Category) => {
    if (selectedCategories != null) {
      const contains = selectedCategories.some((el) => {
        if (el.id === category.id) {
          return true;
        }
        return false;
      });

      if (contains) {
        return true;
      } else {
        return false;
      }
    }
  };

  const addCategory = (category: Category) => {
    const selectedArr: Category[] = selectedCategories || [];

    if (!checkSelection(category)) {
      selectedArr.push(category);
      setSelectedCategories(selectedArr);
      handleCategories(selectedArr, true);
      const objAdd = {
        videoId: video.currentVideo?.id,
        categories: [category],
      };

      dispatch(updateAddCategoryCreator(objAdd as UpdateCategoryBody));
    } else {
      return;
    }
  };

  const deleteCategory = (category: Category) => {
    const deletedArr: Category[] = deletedCategories || [];
    const selectedArr: Category[] = [...selectedCategories];
    const index = selectedArr.map((obj) => obj.id).indexOf(category.id);
    if (index > -1) {
      selectedArr.splice(index, 1);
    }
    if (selectedArr.length === 0) {
      handleShowAlert();
      setMessage(t("views.videos.errors.category"));
    } else {
      setSelectedCategories(selectedArr);
      handleCategories(deletedArr, false);
      if (index > -1) {
        const objDelete = {
          videoId: video.currentVideo?.id,
          categories: [category],
        };

        dispatch(updateDeleteCategoryCreator(objDelete as UpdateCategoryBody));
        deletedArr.push(category);
      }
    }
  };

  useEffect(() => {
    getCategories();

    if (previousData?.eventDescription !== undefined) {
      setTextAreaState(
        EditorState.createWithContent(
          ContentState.createFromText(previousData?.eventDescription)
        )
      );
    }
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCategories(event.eventCategories);
  }, [event.eventCategories]);

  useEffect(() => {
    if (videoRef.current?.ended) {
      clearInterval(interval);
      setPlaying(false);
      setProgress(0);
    }
  }, [videoRef.current?.ended]);

  useEffect(() => {
    video.currentVideo &&
      (setVideoName(video.currentVideo?.videoName),
      setImagePreview(
        prevImage ? prevImage : video.currentVideo?.eventPreviewImage
      ),
      setVideoPreview(prevTeaser ? prevTeaser : video.currentVideo.eventTeaser),
      setTextAreaState(
        EditorState.createWithContent(
          ContentState.createFromText(
            video.currentVideo?.videoDescription
              ? video.currentVideo?.videoDescription
              : ""
          )
        )
      ),
      setSelectedCategories(video.currentVideo?.categories));
  }, []);

  let timerID: NodeJS.Timeout;
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const handleShowAlert = () => {
    setShowAlert(true);
    timerID = setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const handleCloseAlert = () => {
    if (showAlert) {
      clearTimeout(timerID);
      setMessage("");
      setShowAlert(false);
    }
  };

  return (
    <>
      <style>
        {`.DraftEditor-root {
                max-width: 460px !important;
                overflow-y: scroll !important;
                height: 82px !important;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }
          .DraftEditor-root::-webkit-scrollbar {
            display: none;
          }
            `}
      </style>
      <div className={styles.EventGeneralDetailsWrapper}>
        <>
          <div className={styles.TitleContainer}>
            <div className={styles.h5}>
              {t("views.videos.editVideo.general_details.title")}
            </div>
            <div
              className={`${styles["small-text"]} ${styles.EventGeneralDetailsMessage}`}
            >
              {t("views.videos.editVideo.general_details.message")}
            </div>
          </div>
        </>
        {showAlert && (
          <PopUpAlert
            width={"100%"}
            type={PopUpAlertTypes.error}
            title={message}
            handleCloseIcon={handleCloseAlert}
          />
        )}
        <div className={styles.GeneralDetailsContainer}>
          {/* FORMS */}
          <div className={styles.ContainerGridItem}>
            <div className={styles.FormGroup}>
              <label className={`${styles["body-16-text"]}`}>
                {t("views.videos.editVideo.general_details.forms.event_name")}
              </label>
              <Input
                width="467px"
                height="40px"
                inputStyle={InputStyles.normal}
                isDisabled={newConfiguration}
                value={videoName}
                onChange={handleEventName}
                placeholder={t(
                  "views.videos.editVideo.general_details.forms.event_name"
                )}
              />
            </div>

            <div className={styles.FormGroup}>
              <label className={styles["body-16-text"]}>
                {t("views.videos.editVideo.general_details.forms.desc")}
              </label>
              <TextEditor
                editorState={textAreaState}
                onChangeHandler={handleEditor}
                id="description"
                name="description"
                visibleCounter={false}
                maxLenght={280}
              />
            </div>

            {/* CATEGORIES */}
            <div className={styles.FormGroup}>
              <label className={styles["body-16-text"]}>
                {t(
                  "views.videos.editVideo.general_details.forms.select_categories"
                )}
              </label>
              <div>
                <Input
                  height="30px"
                  width="100%"
                  icon={<MagnifyingGlass />}
                  placeholder={t(
                    "views.videos.editVideo.general_details.forms.placeholder"
                  )}
                  onChange={handleCategoriesFiltering}
                  value={searchInputValue}
                />
              </div>
              <div className={styles.CategoriesContainer}>
                {categories?.map((cat) => {
                  return (
                    <div
                      className={`${styles.CategoryTag} ${
                        checkSelection(cat) ? styles.SelectedCategory : ""
                      }`}
                      key={cat.id}
                      onClick={() =>
                        checkSelection(cat)
                          ? deleteCategory(cat)
                          : addCategory(cat)
                      }
                    >
                      <span className={styles["small-text"]}>
                        {cat.description}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* PREVIEWS */}
          <div className={styles.ContainerGridItem}>
            {/* LOAD TEASER  */}
            <div
              style={{ position: "relative", width: "340px", height: "212px" }}
            >
              <div className={styles.SquaredGridItem}>
                <form ref={formvideoRef} style={{ display: "none" }}>
                  <input
                    ref={inputRefVideo}
                    type="file"
                    accept="video/mp4"
                    onChange={onVideoInputHandleChange}
                  />
                </form>
                {videoPreview === undefined ||
                  videoPreview === "" ||
                  (videoPreview === null && (
                    <>
                      <div className={styles.Icon} onClick={onVideoClickHandle}>
                        <VideoCamera />
                      </div>
                      <div
                        className={styles.Upload}
                        onClick={onVideoClickHandle}
                      >
                        <CloudUpload /> &nbsp;
                        <span
                          className={`${styles["body-14-text"]} ${styles["load"]}`}
                        >
                          {t(
                            "views.videos.editVideo.general_details.forms.load_teaser"
                          )}
                        </span>
                      </div>
                      <span
                        className={`${styles["body-14-text"]} ${styles.LightText}`}
                      >
                        {t(
                          "views.videos.editVideo.general_details.forms.teaser_info"
                        )}
                      </span>
                      <div>
                        <span className={`${styles["alert-text-12"]}`}>
                          {t(
                            "views.videos.editVideo.general_details.forms.file_format"
                          )}
                        </span>
                        &nbsp;
                        <span
                          className={`${styles["alert-text-12"]} ${styles.LightText}`}
                        >
                          {videoFileFormat}
                        </span>
                      </div>
                      <div>
                        <span className={`${styles["alert-text-12"]}`}>
                          {t(
                            "views.videos.editVideo.general_details.forms.recommended_size"
                          )}
                        </span>
                        &nbsp;
                        <span
                          className={`${styles["alert-text-12"]} ${styles.LightText}`}
                        >
                          {videoFileSize}
                        </span>
                      </div>
                    </>
                  ))}
              </div>
              {/* Preview Display */}
              {videoPreview != undefined && videoPreview !== "" && (
                <div
                  className={styles.SquaredGridDisplay}
                  onClick={onVideoClickHandle}
                >
                  <div id={styles.videoControls}>
                    {!mute ? (
                      <div
                        className={styles["video-control"]}
                        onClick={(e) =>
                          videoMutedHandler(e, VideoControlsType.mute)
                        }
                      >
                        <SvgVolume opacity={1} />
                      </div>
                    ) : (
                      <div
                        className={styles["video-control"]}
                        onClick={(e) =>
                          videoMutedHandler(e, VideoControlsType.unmute)
                        }
                      >
                        <SvgMute />
                      </div>
                    )}
                    {!playing ? (
                      <div
                        className={styles["video-control"]}
                        onClick={(e) => videoHandler(e, VideoControlsType.play)}
                      >
                        <Play />
                      </div>
                    ) : (
                      <div
                        className={styles["video-control"]}
                        onClick={(e) =>
                          videoHandler(e, VideoControlsType.pause)
                        }
                      >
                        <Pause />
                      </div>
                    )}

                    <div
                      className={styles["progress-bar"]}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Slider
                        className={"fira-video-range"}
                        min={0}
                        max={100}
                        defaultValue={progress}
                        value={progress}
                        step={1}
                        onChange={(value) => handleProgress(value)}
                      />
                    </div>
                  </div>
                  <video
                    src={videoPreview}
                    ref={videoRef}
                    id="preview-video"
                    key={videoPreview}
                  ></video>
                </div>
              )}
            </div>

            {/* END OF LOAD TEASER */}

            {/* LOAD IMAGE */}
            <div
              style={{ position: "relative", width: "340px", height: "212px" }}
            >
              <div className={styles.SquaredGridItem}>
                <form ref={formRef} style={{ display: "none" }}>
                  <input
                    ref={inputRef}
                    type="file"
                    accept="image/jpeg, image/jpg, image/png"
                    onChange={onImageInputHandleChange}
                  />
                </form>
                <>
                  {imagePreview === undefined ||
                    imagePreview === "" ||
                    (imagePreview === null && (
                      <>
                        <div
                          className={styles.Icon}
                          onClick={onImageClickHandle}
                        >
                          <Image />
                        </div>
                        <div
                          className={styles.Upload}
                          onClick={onImageClickHandle}
                        >
                          <CloudUpload /> &nbsp;
                          <span
                            className={`${styles["body-14-text"]} ${styles["load"]}`}
                          >
                            {t(
                              "views.videos.editVideo.general_details.forms.load_image"
                            )}
                          </span>
                          &nbsp;
                          <span
                            className={`${styles["body-14-text"]} ${styles.LightText}`}
                          >
                            {t(
                              "views.videos.editVideo.general_details.forms.optional"
                            )}
                          </span>
                        </div>
                        <span
                          className={`${styles["body-14-text"]} ${styles.LightText}`}
                        >
                          {t(
                            "views.videos.editVideo.general_details.forms.image_info"
                          )}
                        </span>
                        <div>
                          <span className={`${styles["alert-text-12"]}`}>
                            {t(
                              "views.videos.editVideo.general_details.forms.file_format"
                            )}
                          </span>
                          &nbsp;
                          <span
                            className={`${styles["alert-text-12"]} ${styles.LightText}`}
                          >
                            {imageFileFormat}
                          </span>
                        </div>
                        <div>
                          <span className={`${styles["alert-text-12"]}`}>
                            {t(
                              "views.videos.editVideo.general_details.forms.recommended_size"
                            )}
                          </span>
                          &nbsp;
                          <span
                            className={`${styles["alert-text-12"]} ${styles.LightText}`}
                          >
                            {imageFileSize}
                          </span>
                        </div>
                      </>
                    ))}
                </>
              </div>
              {/* Preview Display */}
              {imagePreview !== undefined &&
                imagePreview !== "" &&
                imagePreview !== null && (
                  <div
                    className={styles.SquaredGridDisplay}
                    onClick={onImageClickHandle}
                  >
                    <img
                      src={imagePreview}
                      alt="image-preview"
                      key={imagePreview}
                    />
                  </div>
                )}
            </div>

            {/* END OF LOAD IMAGE */}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoGeneralData;
