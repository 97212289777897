import { ChangeEvent, useState } from "react";
import Input from "../../Input/Input";
import styles from "./TitleInput.module.scss";

interface TitleInputProps {
  name?: string;
  onChange?(val: string): void;
}

const TitleInput = ({ name, onChange }: TitleInputProps) => {
  const [title, setTitle] = useState<string>("");
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;
    setTitle(value);
    if (onChange) onChange(value);
  };

  return (
    <div className={styles.TitleInputWrapper}>
      <span>{name}</span>
      <Input
        width="244px"
        height="32px"
        textLimit={16}
        value={title}
        onChange={handleChange}
      />
    </div>
  );
};

export default TitleInput;
