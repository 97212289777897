import { VirtualElement } from "@popperjs/core";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import popupStyles from "./EventDetailPopUp.module.scss";
import toMaterialStyle from "material-color-hash";
import {
  ArrowDownFill,
  ChatBubble,
  CheckCircleFill,
  CopyPaperFill,
  Cross,
  CrossCircleFill,
  Edit,
  ErrorSign,
  RoundedX,
  Statistics,
  TrashCan,
} from "../../FiraIcons";
import { FiraUserModerator, RootState } from "../../../utils/types";
import { EventStatus } from "../../../utils/enums";
import Button, { ButtonStyle } from "../../Button/Button";
import {
  monthAndYear,
  timeToLocalTimeZone,
  weekDaynumber,
} from "../../../utils/handleDates";
import { EventResumeResponse } from "../../../types/Response";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useClickOutside } from "../../../utils/useClickOutside";
import { getStoreBy } from "../../../services/store";
import { setCurrentStoreActionCreator } from "../../../store/Brand/BrandActions";
import { putCancelEventsActionCreator } from "../../../store/Event/EventActions";
import { te } from "date-fns/locale";

export enum PopUpPosition {
  centered = "CENTERED",
  cardinal = "CARDINAL",
  relative = "RELATIVE",
}

interface exampleProps {
  x?: number;
  y?: number;
  elementRef?: HTMLElement | undefined;
  onClose(): void;
  customPosition: PopUpPosition;
  eventInfo: EventResumeResponse;
  eventId: string;
  customDestination: string;
  handleDeleted?: Dispatch<SetStateAction<string>>;
}

const EventDetailPopUp: React.FC<exampleProps> = ({
  x,
  y,
  elementRef,
  onClose,
  customPosition,
  eventInfo,
  customDestination,
  eventId,
  handleDeleted,
}) => {
  const { event, brand, authentication } = useSelector(
    (state: RootState) => state
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const element = document.getElementById(customDestination);
  const [elementVirtual, setElementVirtual] = useState<VirtualElement>({
    getBoundingClientRect: () => new DOMRect(x, y),
  });
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [portalElement, setPortalElemen] = useState<HTMLElement | null>(null);
  const NotConfigured = eventInfo.firaWebConfiguration === null;
  const domNode = useClickOutside(onClose);
  const modifiers = [
    {
      name: "offset",
      options: {
        offset: [0, 0],
      },
    },
    {
      name: "preventOverflow",
      options: {
        padding: 0,
        boundary: element,
      },
    },
    {
      name: "flip",
      options: {
        fallbackPlacements: ["left", "right"],
        allowedAutoPlacements: ["left", "right"],
      },
    },
    {
      name: "computeStyles",
      options: {
        gpuAcceleration: customPosition === PopUpPosition.cardinal,
      },
    },
  ];

  const { styles, attributes, update, state } = usePopper(
    elementRef ? elementRef : elementVirtual,
    popperElement,

    {
      modifiers,
      placement: "left-start",
    }
  );

  let containerStyle: any = {
    ...styles.popper,
    display: "flex",
    zIndex: 1002,
    flexDirection: "column",
    borderRadius: "4px",
    padding: "10px",
    marginLeft: customPosition === PopUpPosition.centered ? "38vw" : undefined,
    justifyContent:
      customPosition === PopUpPosition.centered ? "center" : undefined,
    bottom: customPosition === PopUpPosition.centered ? "0px" : undefined,
    top: customPosition === PopUpPosition.centered ? "37vh" : undefined,
    position:
      customPosition === PopUpPosition.relative ? "relative" : "absolute",
  };
  if (customPosition === PopUpPosition.relative) {
    containerStyle = {
      ...containerStyle,
      left: 0,
      right: 0,
    };
  }

  const handleUpdatePosition = () => {
    update && update();
  };

  // const handleConfigEvent = () => {
  //   if (eventInfo.moderators) {
  //     navigate("/home/events/edit/" + eventId);
  //   } else {
  //     navigate(`new/${eventId}`);
  //   }
  // };

  //TEMP
  const handleConfigEvent = () => {
    navigate(`event-configuration/${eventId}`);
  };

  const handleCancelEvent = () => {
    if (handleDeleted) {
      handleDeleted(eventId);
    }
    dispatch(putCancelEventsActionCreator(eventId));
  };

  const handelModerateEvent = () => {
    if (event.currentEvent?.storeId && authentication.isAccountOwner) {
      getStoreBy(event.currentEvent?.storeId).then((val) => {
        if (brand.currentStore == null && authentication.sessionId) {
          dispatch(
            setCurrentStoreActionCreator(authentication.sessionId, val.data)
          );
        }
      });
    }
    navigate("/home/events/event-detail/moderation");
  };

  const parseInitialDay = (date: string) => {
    const tempArr = date.split(" ");
    const upperinitial =
      tempArr[0].charAt(0).toUpperCase() + tempArr[0].slice(1);
    return `${upperinitial} ${tempArr[1]}`;
  };

  useEffect(() => {
    if (customPosition !== PopUpPosition.centered) {
      setElementVirtual({ getBoundingClientRect: () => new DOMRect(x, y) });
    }
  }, [x, y]);
  useEffect(() => {
    setPortalElemen(element);
  }, []);

  return (
    portalElement &&
    ReactDOM.createPortal(
      <div ref={setPopperElement} style={containerStyle} {...attributes.popper}>
        <div className={popupStyles.EventPopUpDetailWrapper} ref={domNode}>
          <div className={popupStyles.PopupHeader}>
            <div className={popupStyles.left}>
              {!NotConfigured ? (
                <>
                  {eventInfo.status === EventStatus.SCHEDULED && (
                    <TrashCan
                      className={popupStyles.icon}
                      onClick={handleCancelEvent}
                    />
                  )}
                  {eventInfo.status === EventStatus.SCHEDULED ||
                  eventInfo.status === EventStatus.DONE ? (
                    <Edit
                      className={popupStyles.icon}
                      onClick={handleConfigEvent}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <TrashCan
                  className={popupStyles.icon}
                  onClick={handleCancelEvent}
                />
              )}
            </div>

            <RoundedX
              className={`${popupStyles.right} ${popupStyles.icon}`}
              onClick={onClose}
            />
          </div>
          <div className={popupStyles.PopupInfoContainer}>
            <div className={popupStyles.EventInfo}>
              <p className={popupStyles.EventName}>{eventInfo.eventName}</p>
              <p className={popupStyles.StoreName}>{eventInfo.storeName}</p>
              <div className={popupStyles.Status}>
                <div
                  className={`${popupStyles.Icon} ${
                    {
                      [EventStatus.SCHEDULED]: popupStyles.yellow,
                      [EventStatus.NOT_STARTED]: popupStyles.yellow,
                      [EventStatus.STARTED]: popupStyles.yellow,
                      [EventStatus.DONE]: popupStyles.gray,
                      [EventStatus.CANCELLED]: popupStyles.black,
                    }[eventInfo.status]
                  }`}
                >
                  {eventInfo.status !== EventStatus.CANCELLED ? (
                    <CheckCircleFill />
                  ) : (
                    <CrossCircleFill />
                  )}
                </div>

                <p className={popupStyles.Text}>
                  {
                    {
                      [EventStatus.SCHEDULED]: t(
                        "views.events.status.broadcast.scheduled"
                      ),
                      [EventStatus.NOT_STARTED]: t(
                        "views.events.status.broadcast.not_started"
                      ),
                      [EventStatus.STARTED]: t(
                        "views.events.status.broadcast.started"
                      ),
                      [EventStatus.DONE]: t(
                        "views.events.status.broadcast.done"
                      ),
                      [EventStatus.CANCELLED]: t(
                        "views.events.status.broadcast.cancelled"
                      ),
                    }[eventInfo.status]
                  }
                </p>
              </div>
              <p className={popupStyles.Date}>
                {parseInitialDay(
                  weekDaynumber(
                    new Date(
                      eventInfo.startDate
                        ? eventInfo.startDate
                        : eventInfo.scheduledDate
                    )
                  )
                ) + ","}
                &nbsp;
                {monthAndYear(
                  new Date(
                    eventInfo.startDate
                      ? eventInfo.startDate
                      : eventInfo.scheduledDate
                  )
                )}
              </p>
              <p className={popupStyles.Date}>
                {timeToLocalTimeZone(
                  new Date(
                    eventInfo.startDate
                      ? eventInfo.startDate
                      : eventInfo.scheduledDate
                  )
                )}
              </p>
            </div>

            {eventInfo.moderators && eventInfo.moderators.length !== 0 && (
              <ModeratorsList
                list={eventInfo.moderators ? eventInfo.moderators : []}
                updatePopper={handleUpdatePosition}
              />
            )}

            <Button
              width="100%"
              buttonStyle={
                NotConfigured ? ButtonStyle.dangerLight : ButtonStyle.main
              }
              clickHandler={
                NotConfigured ? handleConfigEvent : handelModerateEvent
              }
              isDisabled={eventInfo.status === EventStatus.CANCELLED}
            >
              <div
                className={`${popupStyles.popUpButton} ${
                  eventInfo.status === EventStatus.CANCELLED
                    ? popupStyles.canceled
                    : ""
                }`}
              >
                {NotConfigured ? (
                  <>
                    <ErrorSign className={popupStyles.alertIcon} />
                    {t("views.events.calendar.config")}
                  </>
                ) : (
                  <>
                    {
                      {
                        [EventStatus.SCHEDULED]: (
                          <ChatBubble className={popupStyles.icon} />
                        ),
                        [EventStatus.NOT_STARTED]: (
                          <ChatBubble className={popupStyles.icon} />
                        ),
                        [EventStatus.STARTED]: (
                          <ChatBubble className={popupStyles.icon} />
                        ),
                        [EventStatus.DONE]: (
                          <Statistics className={popupStyles.icon} />
                        ),
                        [EventStatus.CANCELLED]: (
                          <TrashCan className={popupStyles.icon} />
                        ),
                      }[eventInfo.status]
                    }

                    {
                      {
                        [EventStatus.SCHEDULED]: t(
                          "views.events.calendar.moderate"
                        ),
                        [EventStatus.NOT_STARTED]: t(
                          "views.events.calendar.moderate"
                        ),
                        [EventStatus.STARTED]: t(
                          "views.events.calendar.moderate"
                        ),
                        [EventStatus.DONE]: t("views.events.calendar.detail"),
                        [EventStatus.CANCELLED]: t(
                          "views.events.calendar.canceled"
                        ),
                      }[eventInfo.status]
                    }
                  </>
                )}
              </div>
            </Button>
          </div>
        </div>
      </div>,
      portalElement
    )
  );
};

interface ModeratorsListProps {
  list: FiraUserModerator[];
  updatePopper(): void;
}

const ModeratorsList: React.FC<ModeratorsListProps> = ({
  list,
  updatePopper,
}) => {
  const { t } = useTranslation();
  const [showList, setShowList] = useState(false);
  const parseInitials = (firstName: string, lastName: string) => {
    return (
      (firstName ? firstName.split(" ")[0] : "") +
      " " +
      (lastName ? lastName.split(" ")[0] : "")
    );
  };

  const handleShow = () => {
    updatePopper();
    setShowList(!showList);
  };

  return (
    <div className={popupStyles.ModeratorsListWrapper}>
      <div className={popupStyles.PreviewContainer}>
        {list.length === 1 && list[0] !== null ? (
          <div
            className={`${popupStyles.ModeratorElement} ${popupStyles.single}`}
          >
            <div className={popupStyles.left}>
              <ImageContainer
                id={list[0].id}
                name={parseInitials(list[0].firstName, list[0].lastName)}
                source={list[0].avatar && list[0].avatar}
              />
              <p className={popupStyles.Name}>
                {parseInitials(list[0].firstName, list[0].lastName)}
              </p>
            </div>
            <p className={popupStyles.Role}>
              {
                {
                  ADMIN: t("views.events.calendar.modList.roles.admin"),
                  SELLER: t("views.events.calendar.modList.roles.seller"),
                  PROSELLER: t("views.events.calendar.modList.roles.proseller"),
                  OPERATOR: t("views.events.calendar.modList.roles.operator"),
                  MANAGER: t("views.events.calendar.modList.roles.manager"),
                  MODERATOR: t("views.events.calendar.modList.roles.moderator"),
                  ACCOUNT_OWNER: t(
                    "views.events.calendar.modList.roles.account_owner"
                  ),
                  SUPER_ADMIN: t(
                    "views.events.calendar.modList.roles.account_owner"
                  ),
                }[list[0].role]
              }
            </p>
          </div>
        ) : (
          <div className={popupStyles.MultiElement} onClick={handleShow}>
            <>
              {showList ? (
                <p className={popupStyles.Title}>
                  {t("views.events.calendar.modList.title")}
                </p>
              ) : (
                list.length > 0 &&
                list[0] !== null &&
                list.slice(0, 5).map((mod, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        left: index != 0 ? -10 * index : 0,
                        position: "relative",
                      }}
                    >
                      {mod !== null && (
                        <ImageContainer
                          id={mod.id}
                          name={parseInitials(mod.firstName, mod.lastName)}
                          source={mod.avatar && mod.avatar}
                        />
                      )}
                    </div>
                  );
                })
              )}

              {list.slice(5).length < 5
                ? ""
                : !showList && (
                    <div className={popupStyles.MoreMods}>
                      +{list.slice(5).length}
                    </div>
                  )}
              <div className={popupStyles.arrow}>
                <ArrowDownFill />
              </div>
            </>
          </div>
        )}
      </div>
      {showList && (
        <div className={popupStyles.ListContainer}>
          {list[0] !== null &&
            list.map((mod, index) => {
              return (
                <div
                  id={mod.id}
                  className={`${popupStyles.ModeratorElement} ${popupStyles.list}`}
                >
                  <ImageContainer
                    id={mod.id}
                    name={parseInitials(mod.firstName, mod.lastName)}
                    source={mod.avatar && mod.avatar}
                  />
                  <div className={popupStyles.info}>
                    <p className={popupStyles.Name}>
                      {parseInitials(mod.firstName, mod.lastName)}
                    </p>
                    <p className={popupStyles.Role}>
                      {
                        {
                          ADMIN: t("views.events.calendar.modList.roles.admin"),
                          SELLER: t(
                            "views.events.calendar.modList.roles.seller"
                          ),
                          PROSELLER: t(
                            "views.events.calendar.modList.roles.proseller"
                          ),
                          OPERATOR: t(
                            "views.events.calendar.modList.roles.operator"
                          ),
                          MANAGER: t(
                            "views.events.calendar.modList.roles.manager"
                          ),
                          MODERATOR: t(
                            "views.events.calendar.modList.roles.moderator"
                          ),
                          ACCOUNT_OWNER: t(
                            "views.events.calendar.modList.roles.account_owner"
                          ),
                          SUPER_ADMIN: t(
                            "views.events.calendar.modList.roles.account_owner"
                          ),
                        }[mod.role]
                      }
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

interface ImageContainerProps {
  id: string;
  name: string;
  source?: string | null;
}

const ImageContainer: React.FC<ImageContainerProps> = ({
  id,
  name,
  source,
}) => {
  let color = id ? toMaterialStyle(id, 400).backgroundColor : "";
  const words: string[] = name.split(" ");
  const initials =
    words[0].charAt(0).toUpperCase() +
    (words.length > 1 ? words[1].charAt(0).toUpperCase() : "");

  return (
    <div id={id} style={{ background: color }} className={popupStyles.Avatar}>
      {source ? <img src={source} className={popupStyles.image} /> : initials}
    </div>
  );
};

export default EventDetailPopUp;
