import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
  clickHandler?(): void;
}

const SvgCloseButton = ({
  title,
  titleId,
  clickHandler,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    onClick={clickHandler}
  >
    <g>
      <rect
        width="23"
        height="23"
        x="0.5"
        y="0.5"
        fill="#000"
        stroke="#000"
        rx="3.5"
      ></rect>
      <path
        fill="#fff"
        stroke="null"
        d="M17.25 8.25a1.06 1.06 0 00-1.5-1.5l-9 9a1.06 1.06 0 001.5 1.5l9-9z"
      ></path>
      <path
        fill="#fff"
        stroke="null"
        d="M6.75 8.25a1.06 1.06 0 111.5-1.5l9 9a1.06 1.06 0 01-1.5 1.5l-9-9z"
      ></path>
    </g>
  </svg>
);

export default SvgCloseButton;
