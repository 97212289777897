import { handleError, RequestError } from "../../services/errorHandling";
import {
  changeUserRoleService,
  getFiraUserService,
  getInvitationListService,
  getModeratorsByStoreIdService,
  getStoreTeamService,
  postAcceptInvitationService,
  postRejectInvitationService,
  postSendInvitationService,
  postTeamService,
  updateAccountOwnerProfile,
  updatePermissionsService,
  updateUserStatusService,
} from "../../services/user";
import { UserActions } from "../actionsContants";
import {
  FiraPermissions,
  FiraUser,
  FiraUserStore,
  InvitationForm,
  TeamMember,
} from "../../utils/types";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { uploadImageService } from "../../services/upload";
import { LogoutActionType } from "../Auth/AuthActions";
import { FiraUserResponse, SimplePermissionItem } from "../../types/Response";
import { FiraPackage, FiraUserRole } from "../../utils/enums";

interface GetUserActionType {
  type:
    | UserActions.getUserLoading
    | UserActions.getUserSuccess
    | UserActions.getUserFailed;
  payload?: FiraUserResponse | RequestError;
}

export const getUserActionCreator = (userId: string) => {
  return async (
    dispatch: ThunkDispatch<
      GetUserActionType,
      Record<string, unknown>,
      GetUserActionType
    >
  ) => {
    try {
      dispatch({ type: UserActions.getUserLoading });
      const response = await getFiraUserService(userId);
      dispatch({
        type: UserActions.getUserSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UserActions.getUserFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateUserActionType {
  type:
    | UserActions.updateUserLoading
    | UserActions.updateUserSuccess
    | UserActions.updateUserFailed;
  payload?: FiraUser | RequestError;
}

export const updateUserActionCreator = (
  userId: string,
  body: any,
  image?: File,
  accountOwner?: boolean
) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateUserActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: UserActions.updateUserLoading });
      if (image && image !== undefined) {
        const response = await uploadImageService(image);
        body.avatar = response.data.images[0];
      }
      // const response = accountOwner
      //   ? await updateAccountOwnerProfile(body)
      //   : await updateUserService(userId, body);
      const response = await updateAccountOwnerProfile(body);
      dispatch({ type: UserActions.updateUserSuccess, payload: response.data });
    } catch (error) {
      dispatch({
        type: UserActions.updateUserFailed,
        payload: handleError(error),
      });
    }
  };
};

interface GetUsersPermissionsActionType {
  type:
    | UserActions.getUsersLoading
    | UserActions.getUsersSuccess
    | UserActions.getUsersFailed;
  payload?: TeamMember[] | RequestError;
}

export const getUsersPermissionsActionCreator = (
  storeId: string,
  offSet: number,
  size: number
) => {
  return async (
    dispatch: ThunkDispatch<
      GetUsersPermissionsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: UserActions.getUsersLoading });
      const response = await getStoreTeamService(storeId, offSet, size);
      dispatch({
        type: UserActions.getUsersSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UserActions.getUsersFailed,
        payload: handleError(error),
      });
    }
  };
};
interface PostSendInvitationActionType {
  type:
    | UserActions.sendInvitationPermissionsLoading
    | UserActions.sendInvitationPermissionsSuccess
    | UserActions.sendInvitationPermissionsFailed;
  payload?: InvitationForm | RequestError;
}
export const postSendInvitationActionCreator = (invitation: InvitationForm) => {
  return async (
    dispatch: ThunkDispatch<UserActions, Record<string, unknown>, AnyAction>
  ) => {
    try {
      dispatch({
        type: UserActions.sendInvitationPermissionsLoading,
      });
      const response = await postSendInvitationService(invitation);
      dispatch({
        type: UserActions.sendInvitationPermissionsSuccess,
        payload: response.data as InvitationForm,
      });
    } catch (error) {
      dispatch({
        type: UserActions.sendInvitationPermissionsFailed,
        payload: handleError(error),
      });
    }
  };
};

// DEPRECATED
interface UpdateUsersPermissionsActionType {
  type:
    | UserActions.updateUsersPermissionsLoading
    | UserActions.updateUsersPermissionsSuccess
    | UserActions.updateUsersPermissionsFailed;
  payload?: FiraPermissions | RequestError;
}

export const updateUsersPermissionsActionCreator = (body: FiraPermissions) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateUsersPermissionsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: UserActions.updateUsersPermissionsLoading });
      const response = await postTeamService(body);
      dispatch({
        type: UserActions.updateUsersPermissionsSuccess,
        payload: response.data as FiraPermissions,
      });
    } catch (error) {
      dispatch({
        type: UserActions.updateUsersPermissionsFailed,
        payload: handleError(error),
      });
    }
  };
};
// DEPRECATED

interface UpdateUserStatusActionType {
  type:
    | UserActions.updateUserStatusLoading
    | UserActions.updateUsersStatusSuccess
    | UserActions.updateUsersStatusFailed;
  payload?: unknown | RequestError;
}

export const updateUserStatusActionCreator = (
  userId: string,
  storeId: string
) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateUserStatusActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: UserActions.updateUserStatusLoading });
      const response = await updateUserStatusService(userId, storeId);
      dispatch({
        type: UserActions.updateUsersStatusSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UserActions.updateUsersPermissionsFailed,
        payload: handleError(error),
      });
    }
  };
};

export interface CleanMessageActionType {
  type: UserActions.cleanMessage;
}

export const cleanMessageActionCreator = (): CleanMessageActionType => {
  return { type: UserActions.cleanMessage };
};

export interface GetInvitationListActionType {
  type:
    | UserActions.getInvitationListLoading
    | UserActions.getInvitationListSuccess
    | UserActions.getInvitationListError;
  payload?: unknown | RequestError;
}

export const getInvitationListActionCreator = (email: string) => {
  return async (
    dispatch: ThunkDispatch<
      GetInvitationListActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: UserActions.getInvitationListLoading });
      const response = await getInvitationListService(email);
      dispatch({
        type: UserActions.getInvitationListSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UserActions.getInvitationListError,
        payload: handleError(error),
      });
    }
  };
};

export interface AcceptInvitationActionType {
  type:
    | UserActions.acceptInvitationLoading
    | UserActions.acceptInvitationSuccess
    | UserActions.acceptInvitationError;
  payload?: { userStore: FiraUserStore; invitationId: string } | RequestError;
}

export const acceptInvitationActionCreator = (invitationId: string) => {
  return async (
    dispatch: ThunkDispatch<
      AcceptInvitationActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: UserActions.acceptInvitationLoading });
      const response = await postAcceptInvitationService(invitationId);
      dispatch({
        type: UserActions.acceptInvitationSuccess,
        payload: { userStore: response.data, invitationId },
      });
    } catch (error) {
      dispatch({
        type: UserActions.acceptInvitationError,
        payload: handleError(error),
      });
    }
  };
};

interface RejectInvitationActionType {
  type:
    | UserActions.rejectInvitationLoading
    | UserActions.rejectInvitationSuccess
    | UserActions.rejectInvitationError;
  payload?: string | RequestError;
}

export const rejectInvitationActionCreator = (invitationId: string) => {
  return async (
    dispatch: ThunkDispatch<
      RejectInvitationActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: UserActions.rejectInvitationLoading });
      const response = await postRejectInvitationService(invitationId);
      dispatch({
        type: UserActions.rejectInvitationSuccess,
        payload: invitationId,
      });
    } catch (error) {
      dispatch({
        type: UserActions.rejectInvitationError,
        payload: handleError(error),
      });
    }
  };
};

interface GetModeratorsActionType {
  type:
    | UserActions.getModeratorsLoading
    | UserActions.getModeratorsFailed
    | UserActions.getModeratorsSuccess;
  payload?: unknown | RequestError;
}

export const getModeratorsActionCreator = (storeId: string) => {
  return async (
    dispatch: ThunkDispatch<
      GetModeratorsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: UserActions.getModeratorsLoading });
      const response = await getModeratorsByStoreIdService(storeId);
      dispatch({
        type: UserActions.getModeratorsSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UserActions.getModeratorsFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateUserRoleActionType {
  type:
    | UserActions.updateUserRoleLoading
    | UserActions.updateUserRoleFailed
    | UserActions.updateUserRoleSuccess;
  payload?: unknown | RequestError; //Define this type
}

export const updateUserRoleActionCreator = (
  userId: string,
  userRole: FiraPackage,
  storeId: string
) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateUserActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({
        type: UserActions.updateUserLoading,
      });
      const response = await changeUserRoleService(userId, userRole, storeId);
      dispatch({
        type: UserActions.updateUserRoleSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UserActions.updateUserRoleFailed,
      });
    }
  };
};

interface UpdatePermissionsActionType {
  type:
    | UserActions.updateUserPermissionsLoading
    | UserActions.updateUserPermissionsSuccess
    | UserActions.updateUserPermissionsFailed;
  payload: unknown | RequestError; //Define this type
}

export const updatePermissiosnActionCreator = (model: {
  storeId: string;
  name: string;
  userEmail: string;
  role: FiraUserRole | null;
  permissions: SimplePermissionItem[] | [];
}) => {
  return async (
    dispatch: ThunkDispatch<
      UpdatePermissionsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({
        type: UserActions.updateUserPermissionsLoading,
      });
      const response = await updatePermissionsService(model);
      dispatch({
        type: UserActions.updateUserPermissionsSuccess,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: UserActions.updateUserPermissionsFailed,
      });
    }
  };
};

export interface CleanUpdatePermissionsMessageActionType {
  type: UserActions.cleanUserPermissionsMessage;
}

export const cleanUpdatePermissionsMessage =
  (): CleanUpdatePermissionsMessageActionType => {
    return { type: UserActions.cleanUserPermissionsMessage };
  };

export type UserActionType =
  | GetUserActionType
  | UpdateUserActionType
  | PostSendInvitationActionType
  | GetUsersPermissionsActionType
  | UpdateUsersPermissionsActionType
  | CleanMessageActionType
  | GetInvitationListActionType
  | AcceptInvitationActionType
  | RejectInvitationActionType
  | GetModeratorsActionType
  | UpdateUserRoleActionType
  | UpdatePermissionsActionType
  | CleanUpdatePermissionsMessageActionType
  | LogoutActionType;
