import {
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Calendar,
  dateFnsLocalizer,
  DayLayoutFunction,
  EventPropGetter,
  EventProps,
  luxonLocalizer,
} from "react-big-calendar";
import type {
  View,
  Event,
  WeekProps,
  NavigateAction,
  EventWrapperProps,
} from "react-big-calendar";
import format from "date-fns/format";
import { isToday } from "date-fns";
import { DateTime } from "luxon";

import { TimeClockFill } from "../FiraIcons";
import {
  EventStatus,
  FiraEvent,
  FiraSimpleEvent,
  RootState,
} from "../../utils/types.d";

import styles from "./FiraCalendar.module.scss";
import "./BigCalendarStyles.scss";
import { checkBrowserLanguage } from "../../utils/checkBrowserLanguage";
import PopUpEventOptions from "../PopUpEventOption/PopUpEventOption";
import { useDispatch, useSelector } from "react-redux";
import {
  putCancelEventsActionCreator,
  resetEventMessageActionCreator,
  setCurrentEventActionCreator,
} from "../../store/Event/EventActions";
import OptionModal from "../Modals/OptionModal/OptionModal";
import {
  convertDateLocal,
  convertDateUTC,
  formatIsoLocal,
} from "../../utils/handleDates";
import PopUpCreateEventTop from "../PopUpCreateEvent/PopUpCreateEventTop";
import { useClickOutside } from "../../utils/useClickOutside";
import { useTranslation } from "react-i18next";
import React from "react";
import { getEventService } from "../../services/events";
import { FiraUserRole } from "../../utils/enums";

const localizer = luxonLocalizer(DateTime);

interface FiraCalendarProps {
  date: Date;
  events: FiraEvent[] | FiraSimpleEvent[];
  view: View;
  handleViewChange(view: View): void;
  handleCalendarNavigate(date: Date, view: View, action: NavigateAction): void;
  popUpFromViewIsOpen?: boolean;
  closeViewPopUp?(): void;
}
const FiraCalendar = ({
  date,
  events,
  view,
  handleViewChange,
  handleCalendarNavigate,
  popUpFromViewIsOpen,
  closeViewPopUp,
}: FiraCalendarProps) => {
  const { t } = useTranslation();

  const calendarEvents: Event[] = events.map((e): Event => {
    const schedule = new Date(
      e.startDate ? e.startDate : formatIsoLocal(new Date(e.scheduledDate))
    );

    return {
      title: e.eventName,
      start: schedule,
      end: new Date(e.endDate),
      resource: e,
    };
  });
  const dispatch = useDispatch();
  const { event, authentication } = useSelector((state: RootState) => state);
  const [show, setShow] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [showModalOption, setShowModalOption] = useState(false);
  const [posx, SetPosx] = useState(0);
  const [posy, SetPosy] = useState(0);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [viewselect, setViewSelect] = useState<View>(view);
  const [validMonth, setvalidMonth] = useState(false);
  const [eventSelect, setEventSelect] = useState<FiraEvent | FiraSimpleEvent>(
    events[0]
  );
  const [elemetTarget, setElementTarget] = useState<HTMLElement>();

  const [elemetTargetOption, setElementTargetOption] = useState<HTMLElement>();

  const handleSelectSlot = useCallback(({ start, end, box }) => {
    const today = new Date();
    const current = start as Date;

    closePopUp();

    if (verifyMonth(current) && current.getFullYear() >= today.getFullYear()) {
      if (
        (current.getFullYear() == today.getFullYear() &&
          current.getDate() >= today.getDate() &&
          current.getMonth() == today.getMonth()) ||
        current.getFullYear() > today.getFullYear() ||
        current.getMonth() > today.getMonth()
      ) {
        setStartDate(start);
        setEndDate(end);
        SetPosx(box.x);
        SetPosy(box.y);
        setShow(true);
      } else {
        setShow(false);
      }
    } else {
      if (current.getTime() >= today.getTime()) {
        setStartDate(start);
        setEndDate(end);
        SetPosx(box.x);
        SetPosy(box.y);
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, []);

  const verifyMonth = (date: Date): boolean => {
    if (
      date.getHours() == 0 &&
      date.getMinutes() == 0 &&
      date.getSeconds() == 0
    ) {
      setvalidMonth(true);
      return true;
    } else {
      setvalidMonth(false);
      return false;
    }
  };

  const handleSelectEvent = (event: Event, e: SyntheticEvent<HTMLElement>) => {
    setShow(false);
    const evento: FiraEvent = event.resource;
    setEventSelect(evento);
    setElementTarget(e.currentTarget);
    setElementTargetOption(e.currentTarget);
    setShowOption(true);
    handleCurrentEvent(evento.id);
    dispatch(setCurrentEventActionCreator(evento));
  };
  const handleCurrentEvent = (id: string) => {
    getEventService(id).then((val) => {
      if (val) {
        dispatch(setCurrentEventActionCreator(val.data));
      }
    });
  };
  const closePopUp = () => {
    setShow(false);
    setShowOption(false);
  };

  const actionCancelEventPopUp = () => {
    setShowModalOption(true);
  };

  const onClickOption = (option: boolean) => {
    if (option) {
      if (event.currentEvent)
        dispatch(putCancelEventsActionCreator(event.currentEvent?.id));
    } else {
      setShowModalOption(option);
    }
  };

  const handleCancelClick = () => {
    setShowOption(false);
  };

  useEffect(() => {
    closePopUp();
    setViewSelect(view);
  }, [view]);

  useEffect(() => {
    if (event.cancelSuccess) {
      closePopUp();
      setShowModalOption(false);
      dispatch(resetEventMessageActionCreator());
    }
  }, [event]);

  useEffect(() => {
    closePopUp();
  }, [date]);

  useEffect(() => {
    if (popUpFromViewIsOpen) {
      closePopUp();
    }
  }, [popUpFromViewIsOpen]);

  useEffect(() => {
    if (show && closeViewPopUp) {
      closeViewPopUp();
    }
  }, [show]);

  return (
    <div className={styles.FiraCalendarWrapper}>
      <Calendar
        dayLayoutAlgorithm={"overlap"}
        localizer={localizer}
        date={date}
        events={calendarEvents}
        view={view}
        onView={handleViewChange}
        onNavigate={handleCalendarNavigate}
        views={["day", "week", "month"]}
        toolbar={false}
        messages={{
          showMore: (total: number) =>
            `${total + t("views.events.calendar.showMore")}`,
        }}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
        components={{
          timeGutterHeader: FiraCalendarHeaderGutter,
          timeSlotWrapper: FiraCalendarTimeSlot,
          day: {
            event: FiraCalendarEvent,
          },
          week: {
            header: FiraCalendarWeekHeader,
            event: FiraCalendarEvent,
          },
          month: {
            header: FiraCalendarWeekHeader,
            event: FiraCalendarMonthEvent,
          },
        }}
      />

      {show &&
        authentication.currentRole !== FiraUserRole.MODERATOR &&
        ((validMonth && viewselect.toString() == "month") ||
          (!validMonth && viewselect.toString() != "month")) && (
          <PopUpCreateEventTop
            x={posx}
            y={posy}
            startDateSelected={startDate}
            onClose={closePopUp}
          />
        )}
      {showOption && (
        <PopUpEventOptions
          eventData={eventSelect}
          elementRef={elemetTargetOption}
          onClickCancel={actionCancelEventPopUp}
          onClose={handleCancelClick}
        />
      )}
      {showModalOption && (
        <OptionModal
          isOpen={true}
          title={"Cancelar Evento"}
          action_description={"¿Estas seguro de querer cancelar el evento?"}
          elementName={"Evento"}
          onClickOption={onClickOption}
        />
      )}
    </div>
  );
};

const FiraCalendarEvent = ({ event }: EventProps) => {
  let eventIsTest = event.resource.eventType === "TEST";
  let eventStatus = event.resource.status;
  const [isActive, setIsActive] = useState(false);
  const domNode = useClickOutside(() => setIsActive(false));

  return (
    <div
      onClick={() => setIsActive(true)}
      className={`${styles.FiraCalendarEventWrapper}  ${styles["alert-text"]} ${
        eventStatus === EventStatus.DONE && !eventIsTest
          ? styles.isFinished
          : ""
      } ${eventIsTest ? styles.eventTest : ""}
      ${isActive ? styles.Active : ""}`}
      ref={domNode}
    >
      <div className={styles.text}>{event.title} </div>
    </div>
  );
};

const FiraCalendarMonthEvent = ({ event }: EventProps) => {
  let eventIsTest = event.resource.eventType === "TEST";
  let eventStatus = event.resource.status;

  return (
    <div
      className={`${styles.FiraCalendarMonthEventWrapper} ${styles["alert-text"]} `}
    >
      <div
        className={`${styles.firaCalendarCircle}
        ${
          eventStatus === EventStatus.DONE && !eventIsTest
            ? styles.isFinished
            : ""
        } ${eventIsTest ? styles.eventTest : ""}`}
      ></div>
      <span className={styles.text}>{event.title} </span>
    </div>
  );
};

const FiraCalendarWeekHeader = ({ date }: WeekProps) => {
  return (
    <div
      className={`${styles.FiraCalendarWeekHeader} ${styles["body-12-low-text"]}`}
    >
      {format(date, "EEEE", { locale: checkBrowserLanguage() })}
    </div>
  );
};

const FiraCalendarHeaderGutter = () => {
  return (
    <div className={styles.FiraCalendarGutterWrapper}>
      <TimeClockFill className={styles.Icon} />
    </div>
  );
};

const FiraCalendarTimeSlot = ({
  children,
}: PropsWithChildren<Record<string, unknown>>) => {
  return (
    <div
      className={`${styles.FiraCalendarTimeSlotGroup} ${styles["alert-text"]}`}
    >
      {children}
    </div>
  );
};

export default FiraCalendar;
