import {
  EditEventRequestDTO,
  FiraFiles,
  FiraFirstSettingUpConfiguration,
} from "../../../types/Events";
import { BadWordsType } from "../../../components/EventConfiguration/EventAttachments/EventAttachments";
import {
  FiraConfiguration,
  FiraProduct,
  FiraUserModerator,
  ResponseSimpleProduct,
  RoleType,
} from "../../../utils/types";
import {
  addEventProducts,
  addModeratorService,
  deleteEventProduct,
  deleteModeratorService,
} from "../../../services/events";

export const eventDetailsEditInitialState: EditEventRequestDTO = {
  broadcastingId: "",
  eventName: "",
  scheduleDate: new Date(),
  eventDescription: "",
  categories: [],
  isGlobal: true,
};

export const firaWebConfigurationInitialState: FiraConfiguration = {
  id: "",
  enableLogin: false,
  enableCart: false,
  enableAdvisorLogin: false,
  enableEcommerceLogin: false,
  enableBuyNowButton: false,
  enableChatLogin: false,
  enableNicknameModification: false,
  likesUrls: [
    "https://firalivedev.blob.core.windows.net/images/file/defaultlike.png",
  ],
  registerUrl: "",
  loadingUrl: "",
  cartIconUrl: "",
  mainColor: "#ffde07",
  decimalSeparator: "",
  thousandsSeparator: "",
  showPrices: false,
  showViews: false,
  loginForm: [],
  disclaimerUrl: "",
  personalDataUrl: undefined,
  showLoading: false,
  buyButtonText: null,
  resolution: "HORIZONTAL",
};

export const firaFirstSettingUpInitialState: FiraFirstSettingUpConfiguration = {
  id: "",
  firaWebConfiguration: firaWebConfigurationInitialState,
  products: [],
  moderatorList: [],
  badWordsType: BadWordsType.default,
};

export const FiraEventDetailsFormData = (
  eventDetails: EditEventRequestDTO,
  files: FiraFiles
) => {
  const form = new FormData();
  const date = new Date(eventDetails.scheduleDate);
  //Regular data
  form.append("broadcastingId", eventDetails.broadcastingId);
  form.append("eventName", eventDetails.eventName);
  form.append("scheduleDate", date.toISOString());
  form.append("eventDescription", eventDetails.eventDescription);
  form.append("isGlobal", eventDetails.isGlobal + "");
  if (
    eventDetails.categories !== undefined &&
    eventDetails.categories.length > 0
  ) {
    eventDetails.categories.map((cat: string) => {
      form.append("categories", cat);
    });
  }
  //Append teaser & image
  if (files.eventImageFile !== null) {
    form.append("image", files.eventImageFile, files.eventImageFile.name);
  } else {
    form.append("image", new Blob(undefined, { type: "image/png" }), "");
  }
  if (files.eventTeaserFile !== null) {
    form.append("teaser", files.eventTeaserFile, files.eventTeaserFile.name);
  } else {
    form.append("teaser", new Blob(undefined, { type: "video/mp4" }), "");
  }

  return form;
};

export const compareModerators = (
  currenModeratorList: FiraUserModerator[],
  newModeratorList: FiraUserModerator[]
) => {
  const added = newModeratorList.filter(
    (itemB) =>
      !currenModeratorList.some(
        (itemA: FiraUserModerator) => itemA.id === itemB.id
      )
  );
  const removed = currenModeratorList.filter(
    (itemA) =>
      !newModeratorList.some(
        (itemB: FiraUserModerator) => itemA.id === itemB.id
      )
  );

  return { added, removed };
};

export const handleModeratorsEdition = async (
  toAdd: FiraUserModerator[],
  toRemove: FiraUserModerator[],
  id: string
) => {
  if (id) {
    if (toAdd.length > 0) {
      const body = {
        broadcastingId: id,
        moderatorList: toAdd,
      };

      let res = await addModeratorService(body);
      console.log(res);
    }

    if (toRemove.length > 0) {
      const modListIds: string[] = [];
      toRemove.map((mod: FiraUserModerator) => {
        modListIds.push(mod.id);
      });

      const body = {
        broadcastingId: id,
        idList: modListIds,
      };

      let res = await deleteModeratorService(body);
      console.log(res);
    }
  }
};

export const formatCurrentFiraProducts = (
  firaProducts: ResponseSimpleProduct[]
) => {
  let products: any = [];
  for (let product of firaProducts) {
    products.push(product.product);
  }
  return products;
};

export const compareProducts = (
  currentEventProducts: ResponseSimpleProduct[],
  newEventProducts: FiraProduct[]
) => {
  const toCompare: FiraProduct[] =
    formatCurrentFiraProducts(currentEventProducts);

  const added = newEventProducts.filter(
    (itemB) => !toCompare.some((itemA: FiraProduct) => itemA.id === itemB.id)
  );
  const removed = toCompare.filter(
    (itemA) =>
      !newEventProducts.some((itemB: FiraProduct) => itemA.id === itemB.id)
  );

  return { added, removed };
};

export const handleProductsEdition = (
  toAdd: FiraProduct[],
  toRemove: FiraProduct[],
  broadcastingId: string,
  currentEventProducts: ResponseSimpleProduct[]
) => {
  if (broadcastingId) {
    if (toAdd && toAdd.length > 0) {
      let productIds: string[] = [];
      for (let product of toAdd) {
        productIds.push(product.id);
      }
      let body = {
        broadcastingId: broadcastingId,
        broadcastingProduct: productIds,
      };
      addEventProducts(body);
    }

    if (toRemove && toRemove.length > 0) {
      let productIds: string[] = [];
      for (let product of toRemove) {
        let obj = currentEventProducts.find(
          (obj) => obj.product.id === product.id
        );
        if (obj) {
          productIds.push(obj.id);
        }
      }
      productIds.map((product: string) => {
        deleteEventProduct(broadcastingId, product);
      });
    }
  }
};

export const formatFiraProductsAsStrings = (firaProducts: FiraProduct[]) => {
  let products: string[] = [];

  for (let product of firaProducts) {
    products.push(product.id);
  }

  return products;
};

export const loginFormHandler = (config: FiraConfiguration) => {
  if (config.enableLogin) {
    if (
      !config.loginForm ||
      !config.loginForm.some((obj) => obj.fieldType === "USERNAME")
    ) {
      config.loginForm = [
        {
          fieldLabel: "user",
          fieldType: "USERNAME",
          isRequired: true,
          order: 0,
        },
        ...(config.loginForm || []), // Use an empty array if config.loginForm is null or undefined
      ];
    }
  }

  return config;
};

export const changeSuperAdmin = (moderators: FiraUserModerator[]) => {
  const updatedModerators = moderators.map((moderator) => {
    if (moderator.role === "SUPER_ADMIN") {
      return {
        ...moderator,
        role: "ACCOUNT_OWNER",
      };
    }
    return moderator;
  });

  return updatedModerators;
};
