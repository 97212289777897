import React, { useContext, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { EventConfigurationContext } from "../../../views/EventConfigurationView/EventConfigurationView";
import styles from "./EventColor.module.scss";
import {ChatV1, ChatV2, Web} from "../../Mockups";

const EventColor = () => {
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState<string>("#ffde07");
  const { handleChange, eventForm, firaWebConfiguration, setFiraWebConfiguration } = useContext(EventConfigurationContext);
  const handelColorChange = (color: any) => {
    setSelectedColor(color.hex);
    handleChange({
      ...eventForm,
      firaWebConfiguration: {
        ...eventForm.firaWebConfiguration,
        mainColor: color.hex || "#ffde07",
      },
    });

    setFiraWebConfiguration({...firaWebConfiguration, mainColor: color.hex || "#ffde07"});
  };


  useEffect(() => {
    if(firaWebConfiguration)
    setSelectedColor(firaWebConfiguration.mainColor ? firaWebConfiguration.mainColor : "#ffde07");
  }, [firaWebConfiguration]);

/*  useEffect(() => {
    eventForm.firaWebConfiguration &&
      setSelectedColor(eventForm.firaWebConfiguration.mainColor || "#ffde07");
  }, [eventForm]);*/

  return (
    <div className={styles.EventColorWrapper}>
      <div className={styles.EventColorWrapper__SelectorContainer}>
        <SketchPicker
          width="290px"
          onChange={handelColorChange}
          color={selectedColor}
        />
      </div>
      <div className={styles.EventColorWrapper__Mockups}>
        <span className={styles.EventColorWrapper__Mockups__Title}>
          {t("event_creation.params.colorPreview")}
        </span>
        <div className={styles.EventColorWrapper__Mockups__Chat}>
          <ChatV1 currentColor={selectedColor} height={'200'} />
          <ChatV2 currentColor={selectedColor} height={'200'} />

        </div>
        <div className={styles.EventColorWrapper__Mockups__Web}>
         <Web currentColor={selectedColor}/>
        </div>
      </div>
    </div>
  );
};

export default EventColor;
