import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "./AuthView.scss";
import AuthHeader, { ViewTypes } from "../../components/AuthHeader/AuthHeader";
import { useEffect, useState } from "react";
import { AuthAlert } from "../../components/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../utils/types";
import { resetAlertActionCreator } from "../../store/Register/RegisterActions";
import { useTranslation } from "react-i18next";
import {
  businessLoginActionCreator,
  getOwnerInfoData,
  setStatusBusinessRedirection,
} from "../../store/Authentication/actions";
import MainLoader from "../../components/MainLoader/MainLoader";

const AuthView = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { registration, recovery, authentication } = useSelector(
    (state: RootState) => state
  );
  const dispatch = useDispatch();
  const [view, setView] = useState(ViewTypes.login);
  const [alertState, setAlertState] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [searchParams] = useSearchParams();
  const [isBusinessRedirection, setIsBusinessRedirection] =
    useState<boolean>(false);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setView(ViewTypes.login);
        break;
      case "/register":
        setView(ViewTypes.register);
        break;
      case "/recovery":
        setView(ViewTypes.recovery);
        break;
      case "/activate":
        setView(ViewTypes.activate);
        break;
      case "/pwdcode":
        setView(ViewTypes.pwdcode);
        break;
      case "/changepwd":
        setView(ViewTypes.changepwd);
        break;
    }
  }, [location]);

  useEffect(() => {
    if (registration.processFinish) {
      setAlertState(true);
      setAlertMessage(t("auth.alerts.activation_success"));
    } else if (recovery.processFinish) {
      setAlertState(true);
      setAlertMessage(t("auth.alerts.registration_success"));
    }
  }, [registration.processFinish, recovery.processFinish]);

  const handleSkip = () => {
    setAlertState(false);
    navigate("/");
    dispatch(resetAlertActionCreator());
  };

  useEffect(() => {
    const token = searchParams.get("t");
    const userId = searchParams.get("u");
    const businessId = searchParams.get("b");

    if (token && userId && businessId) {
      dispatch(setStatusBusinessRedirection(true));
      setIsBusinessRedirection(true);
      dispatch(getOwnerInfoData(businessId));
      dispatch(businessLoginActionCreator(token, userId, businessId));
    } else {
      setIsBusinessRedirection(false);
      dispatch(setStatusBusinessRedirection(false));
    }
  }, [searchParams]);

  return (
    <div className="Auth">
      <div className="square">
        <AuthHeader view={view} />
        {isBusinessRedirection && <MainLoader />}
        <Outlet />
        {alertState && (
          <AuthAlert message={alertMessage} skipHandler={handleSkip} />
        )}
      </div>
    </div>
  );
};

export default AuthView;
