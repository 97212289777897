import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../utils/types";
import { changeUserPasswordActionCreator } from "../../store/Recovery/RecoveryActions";
import { ArrowRight } from "../FiraIcons";
import { useTranslation } from "react-i18next";
import Input, { InputStyles } from "../Input/Input";
import Button from "../Button/Button";
import "./ChangePassword.scss";

const ChangePassword = () => {
  const { t } = useTranslation();
  const recovery = useSelector((state: RootState) => state.recovery);
  const [recoveryCode, setRecoveryCode] = useState("");
  const [mail, setMail] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [validPwd, setValidPwd] = useState<boolean>();
  const dispatch = useDispatch();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNewPass(value);
    if (value.length < 8) {
      setValidPwd(false);
    }
  };

  useEffect(() => {
    if (recovery.email && recovery.code) {
      setMail(recovery.email);
      setRecoveryCode(recovery.code);
    }
  }, [recovery]);

  if (!recovery.isRecovering) {
    return <Navigate to="/" replace />;
  }

  const pwdMatch = () => {
    return newPass === confirmPass && newPass !== "" && confirmPass !== ""
      ? false
      : true;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(changeUserPasswordActionCreator(newPass, recoveryCode, mail));
  };

  return (
    <div className="ChangePasswordForm">
      <div className="desc alert-text">{t("auth.login.message")}</div>
      <form onSubmit={handleSubmit}>
        <div className="FormGroup">
          <label className="lead-text form-label">
            {t("auth.changepwd.new_pass")}
          </label>
          <div className="input-container">
            <Input
              type="password"
              width="316px"
              height="46px"
              showPswButton={true}
              value={newPass}
              inputStyle={InputStyles.auth}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="FormGroup">
          <label className="lead-text form-label">
            {t("auth.changepwd.confirm_pass")}
          </label>
          <div className="input-container">
            <Input
              type="password"
              width="316px"
              height="46px"
              showPswButton={true}
              value={confirmPass}
              inputStyle={InputStyles.auth}
              onChange={(e) => setConfirmPass(e.target.value)}
            />
          </div>
        </div>
        <div className="ButtonWrapper">
          <Button
            width="316px"
            height="48px"
            type="submit"
            isDisabled={(validPwd || pwdMatch())}
          >
            <div className="ButtonInner">
              {t("auth.changepwd.button")}
              <ArrowRight className="icon" />
            </div>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
