import {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import DateTimeInput, {
  DateTimeInputType,
  handleFormatDate,
  handleFormatTime,
} from "../../DateTimeInput/DateTimeInput";
import Input, { InputStyles } from "../../Input/Input";
import TextEditor from "../../TextEditor/TextEditor";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { Category, EventStatus, RootState } from "../../../utils/types.d";
import {
  validImageForEvents,
  validVideoForEvents,
} from "../../../utils/handleFileTypes";
import { getEventCategoriesActionCreator } from "../../../store/Event/EventActions";
import {
  CloudUpload,
  Image,
  MagnifyingGlass,
  Pause,
  Play,
  TrashCan,
  VideoCamera,
} from "../../FiraIcons";
import styles from "./EventGeneralDetails.module.scss";
import { AlertType, usePopupAlert } from "../../../providers/AlertProvider";
import {
  EventConfig,
  EventConfigurationContext,
} from "../../../views/EventConfigurationView/EventConfigurationView";
import SvgVolume from "../../FiraIcons/Volume";
import SvgMute from "../../FiraIcons/Mute";
import Slider from "rc-slider";
import { convertToHTML } from "draft-convert";
import Checkbox from "../../Checkbox/Checkbox";
import RadioButton from "../../RadioButton/RadioButton";
import PopUpInfo from "../../PopUpInfo/PopUpInfo";
import InfoCircle from "../../FiraIcons/InfoCircle";

let interval: any;

export enum VideoControlsType {
  mute = "mute",
  unmute = "unmute",
  play = "play",
  pause = "pause",
}

const EventGeneralDetails = () => {
  const dispatch = useDispatch();
  const { authentication, brand, event } = useSelector(
    (state: RootState) => state
  );
  const {
    configType,
    firaEventEditDetails,
    setFiraEventEditDetails,
    firaEventCategories,
    setFiraEventCategories,
  } = useContext(EventConfigurationContext);
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [inputTime, setInputTime] = useState<string>("00:00:00");
  const [inputDate, setInputDate] = useState<string>("0000/00/00");
  const [inputDateTime, setInputDateTime] = useState<Date>();
  const [textAreaState, setTextAreaState] = useState(
    EditorState.createWithContent(ContentState.createFromText(""))
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "eventName":
        {
          setFiraEventEditDetails({
            ...firaEventEditDetails,
            eventName: value,
          });
        }
        break;
      case "date":
        {
          setInputDate(value);
          const d = new Date(value + " " + inputTime);
          setFiraEventEditDetails({ ...firaEventEditDetails, scheduleDate: d });
        }
        break;
      case "time":
        {
          setInputTime(value);
          const time = new Date(inputDate + " " + value);
          setFiraEventEditDetails({
            ...firaEventEditDetails,
            scheduleDate: time,
          });
        }
        break;
      default:
        return;
    }
  };

  const handleEditor = (editorState: EditorState) => {
    const html = convertToHTML(editorState.getCurrentContent());
    setTextAreaState(editorState);
    setFiraEventEditDetails({
      ...firaEventEditDetails,
      eventDescription: html,
    });
  };

  const getCategories = () => {
    dispatch(getEventCategoriesActionCreator());
  };

  const addCategory = (category: Category) => {
    setFiraEventEditDetails({
      ...firaEventEditDetails,
      categories: [...firaEventEditDetails.categories, category.description],
    });
    setFiraEventCategories([...firaEventCategories, category]);
  };

  const deleteCategory = (category: Category) => {
    setFiraEventCategories((prevState: Category[]) =>
      prevState.filter((obj) => obj.id !== category.id)
    );
    setFiraEventEditDetails({
      ...firaEventEditDetails,
      categories: firaEventEditDetails.categories.filter(
        (obj: string) => obj !== category.description
      ),
    });
  };

  const handleCategoriesFiltering = (e: ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setSearchInputValue(keyword);

    if (keyword !== "" && event.eventCategories) {
      setCategories(
        event.eventCategories?.filter((p) => {
          return p.description
            .toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase());
        })
      );
    } else {
      setCategories(event.eventCategories || []);
    }
  };

  useEffect(() => {
    if (
      event.eventCategories?.length == 0 ||
      event.eventCategories === undefined
    ) {
      getCategories();
    } else if (event.eventCategories) {
      setCategories(event.eventCategories);
    }
  }, [event.eventCategories]);

  useEffect(() => {
    setInputTime(handleFormatTime(firaEventEditDetails.scheduleDate) || "");
    setInputDate(handleFormatDate(firaEventEditDetails.scheduleDate) || "");
    setInputDateTime(firaEventEditDetails.scheduleDate);
  }, [firaEventEditDetails]);

  useEffect(() => {
    if (firaEventEditDetails.eventDescription) {
      const blocksFromHTML = convertFromHTML(
        firaEventEditDetails.eventDescription
      );
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setTextAreaState(EditorState.createWithContent(state));
    }
  }, []);

  useEffect(() => {
    setSelectedCategories(firaEventCategories);
  }, [firaEventCategories]);

  const [isGlobal, setIsGlobal] = useState(firaEventEditDetails.isGlobal);

  const handleGlobalChange = () => {
    setIsGlobal(true);
  };

  const handleIndividualChange = () => {
    setIsGlobal(false);
  };

  useEffect(() => {
    setFiraEventEditDetails({
      ...firaEventEditDetails,
      isGlobal: isGlobal,
    });
  }, [isGlobal]);
  const [showInfoGlobal, setShowInfoGlobal] = useState(false);
  const [showInfoIndividual, setShowInfoindividual] = useState(false);
  const [elemetTarget, setElementTarget] = useState<HTMLElement>();
  const [isActiveGlobal, setIsActiveGlobal] = useState(false);
  const [isActiveIndividual, setIsActiveIndividual] = useState(false);

  const handleEventInfoGlobal = (e: SyntheticEvent<HTMLElement>) => {
    setShowInfoGlobal(showInfoGlobal ? false : true);
    setIsActiveGlobal(!showInfoGlobal);
    setElementTarget(e.currentTarget);
  };

  const handleEventInfoIndividual = (e: SyntheticEvent<HTMLElement>) => {
    setShowInfoindividual(showInfoIndividual ? false : true);
    setIsActiveIndividual(!showInfoIndividual);
    setElementTarget(e.currentTarget);
  };

  return (
    <>
      {inputDateTime && (
        <div className={styles.EventGeneralDetailsWrapper}>
          <div className={styles.FormWrapper}>
            <div className={styles.FormItem}>
              <label className={`${styles["body-16-text"]}`}>
                {t("event_creation.general_details.forms.event_name")}
              </label>
              <Input
                width="100%"
                height="40px"
                inputStyle={InputStyles.normal}
                placeholder={t(
                  "event_creation.general_details.forms.event_name"
                )}
                value={firaEventEditDetails.eventName}
                onChange={handleInputChange}
                inputName={"eventName"}
                isDisabled={configType === EventConfig.NEW}
              />
            </div>
            <div className={styles.FormItem}>
              <label className={styles["body-16-text"]}>
                {t("event_creation.general_details.forms.datetime")}
              </label>
              <div className={styles.DateTimeItem}>
                <DateTimeInput
                  width="100%"
                  height="40px"
                  type={DateTimeInputType.date}
                  value={inputDateTime ? inputDateTime : undefined}
                  onChange={handleInputChange}
                  inputName={"date"}
                  isDisabled={event.currentEvent?.status === EventStatus.DONE} //If the event doesn't have firawebconfiguration
                />

                <DateTimeInput
                  width="100%"
                  height="40px"
                  type={DateTimeInputType.time}
                  value={inputDateTime ? inputDateTime : undefined}
                  onChange={handleInputChange}
                  inputName={"time"}
                  isDisabled={event.currentEvent?.status === EventStatus.DONE}
                />
              </div>
            </div>

            <div className={styles.FormItemRow}>
              <div className={styles.FormItem}>
                <label className={styles["body-16-text"]}>
                  {t("event_creation.general_details.forms.desc")}
                </label>
                <TextEditor
                  editorState={textAreaState}
                  id="description"
                  name="description"
                  visibleCounter={false}
                  maxLenght={240}
                  onChangeHandler={handleEditor}
                />
              </div>
              {authentication.isAccountOwner &&
                (brand.currentStore?.storeType === "MAIN_STORE" ||
                  !brand.currentStore) &&
                event.currentEvent?.isParent && (
                  <div className={styles.FormItem}>
                    <label className={styles["body-16-text"]}>
                      {"Escoge el tipo de moderación del chat"}
                    </label>
                    <div className={styles.FormItemRow}>
                      <RadioButton
                        isActive={isGlobal}
                        clickHandler={handleGlobalChange}
                      />
                      <label style={{ fontSize: 14, fontWeight: 400 }}>
                        {t(
                          "event_creation.general_details.forms.globalModeration"
                        )}
                      </label>
                      <span
                        className={""}
                        onMouseEnter={handleEventInfoGlobal}
                        onMouseLeave={handleEventInfoGlobal}
                      >
                        <InfoCircle
                          color={isActiveGlobal ? "#ffde07" : "#000"}
                        />
                      </span>
                      {showInfoGlobal && (
                        <PopUpInfo
                          title={t(
                            "event_creation.general_details.forms.infoGlobal"
                          )}
                          elementRef={elemetTarget}
                        />
                      )}
                    </div>
                    <div className={styles.FormItemRow}>
                      <RadioButton
                        isActive={!isGlobal}
                        clickHandler={handleIndividualChange}
                      />
                      <label style={{ fontSize: 14, fontWeight: 400 }}>
                        {t(
                          "event_creation.general_details.forms.individuallModeration"
                        )}
                      </label>
                      <span
                        className={""}
                        onMouseEnter={handleEventInfoIndividual}
                        onMouseLeave={handleEventInfoIndividual}
                      >
                        <InfoCircle
                          color={isActiveIndividual ? "#ffde07" : "#000"}
                        />
                      </span>
                      {showInfoIndividual && (
                        <PopUpInfo
                          title={t(
                            "event_creation.general_details.forms.infoIndividual"
                          )}
                          elementRef={elemetTarget}
                        />
                      )}
                    </div>
                  </div>
                )}
            </div>

            <div className={styles.FormItem}>
              <label className={styles["body-16-text"]}>
                {t("event_creation.general_details.forms.select_categories")}
              </label>

              <Input
                height="30px"
                width="100%"
                icon={<MagnifyingGlass />}
                placeholder={t("event_creation.team.placeholder")}
                value={searchInputValue}
                onChange={handleCategoriesFiltering}
              />
              <Categories
                categories={categories}
                onSelect={addCategory}
                onDelete={deleteCategory}
                selected={selectedCategories}
              />
            </div>
          </div>
          <div className={styles.MultimediaWrapper}>
            <VideoDisplay />
            <ImageDisplay />
          </div>
        </div>
      )}
    </>
  );
};

interface CategoriesProps {
  categories: Category[];
  onSelect(tag: Category): void;
  onDelete(tag: Category): void;
  selected: Category[];
}

const Categories = ({
  categories,
  onSelect,
  onDelete,
  selected,
}: CategoriesProps) => {
  const isSelected = (category: Category) => {
    let contains: boolean = false;

    if (selected != null) {
      contains = selected.some((el) => {
        if (el.id === category.id) {
          return true;
        }
        return false;
      });
    }

    return contains;
  };

  const handleSelect = (category: Category) => {
    !isSelected(category) ? onSelect(category) : () => {};
  };

  const handleDelete = (category: Category) => {
    isSelected(category) ? onDelete(category) : () => {};
  };

  return (
    <>
      <div className={styles.CategoriesContainer}>
        {categories?.map((category) => (
          <div
            className={`${styles.CategoryTag}
            ${isSelected(category) ? styles.selected : ""}
            `}
            key={category.id}
            onClick={() =>
              isSelected(category)
                ? handleDelete(category)
                : handleSelect(category)
            }
          >
            <span className={styles["small-text"]}>{category.description}</span>
          </div>
        ))}
      </div>
    </>
  );
};

const ImageDisplay = () => {
  const { firaFiles, setFiraFiles } = useContext(EventConfigurationContext);
  const imageFileFormat: string = "Jpg, Jpeg, Png";
  const imageFileSize: string = "600x600 (1:1)";
  const imageRef = useRef<HTMLInputElement>(null);
  const imageFormRef = useRef<HTMLFormElement>(null);
  const [imagePreview, setImagePreview] = useState<string>("");
  const showAlert = usePopupAlert();

  const onImageClickHandle = () => {
    imageRef.current?.click();
  };

  const onImageInputHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const image = event.target.files[0];
      if (validImageForEvents(image)) {
        const url = URL.createObjectURL(image);
        setImagePreview(url);
        setFiraFiles({
          ...firaFiles,
          eventImageName: url,
          eventImageFile: image,
        });
      } else {
        const error = t("event_creation.general_details.image_error");
        showAlert(AlertType.error, error, 5000);
        imageFormRef.current?.reset();
      }
    }
  };

  const clearImage = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setImagePreview("");
    setFiraFiles({ ...firaFiles, eventImageName: "", eventImageFile: null });
    imageFormRef.current?.reset();
  };

  const NoImage = (
    <>
      <div className={styles.NoImage} onClick={onImageClickHandle}>
        <div className={styles.Icon}>
          <Image />
        </div>
        <div className={styles.Upload}>
          <CloudUpload />{" "}
          <span>{t("event_creation.general_details.forms.load_image")}</span>
          <span>{t("event_creation.general_details.forms.optional")}</span>
        </div>
        <div className={styles.InfoMessage}>
          <span>{t("event_creation.general_details.forms.image_info")}</span>
        </div>
        <div>
          <div className={styles.Format}>
            <span>{t("event_creation.general_details.forms.file_format")}</span>
            <span>{imageFileFormat}</span>
          </div>
          <div className={styles.Format}>
            <span>
              {t("event_creation.general_details.forms.recommended_size")}
            </span>
            <span>{imageFileSize}</span>
          </div>
        </div>
      </div>
    </>
  );

  const LoadedImage = (
    <>
      <div className={styles.ImagePreview} onClick={onImageClickHandle}>
        <div className={styles.DeleteIcon} onClick={clearImage}>
          <TrashCan />
        </div>
        <img src={imagePreview} alt="Image preview" loading="lazy" />
      </div>
    </>
  );

  useEffect(() => {
    if (firaFiles && firaFiles.eventImageName) {
      setImagePreview(firaFiles.eventImageName);
    }
  }, [firaFiles]);

  return (
    <>
      <div className={styles.ImageContainer}>
        <form ref={imageFormRef} style={{ display: "none" }}>
          <input
            type="file"
            accept="image/jpeg, image/jpg, image/png"
            ref={imageRef}
            onChange={onImageInputHandleChange}
          />
        </form>
        {imagePreview !== "" ? <>{LoadedImage}</> : <>{NoImage}</>}
      </div>
    </>
  );
};

const VideoDisplay = () => {
  const { firaFiles, setFiraFiles, configType } = useContext(
    EventConfigurationContext
  );
  const videoFileFormat: string = "Mp4";
  const videoFileSize: string = "(1:1) 5MB";
  const inputRefVideo = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoFormRef = useRef<HTMLFormElement>(null);
  const [videoPreview, setVideoPreview] = useState<string>("");
  const [playing, setPlaying] = useState(false);
  const [mute, setMute] = useState(false);
  const showAlert = usePopupAlert();
  const [progress, setProgress] = useState<number | undefined>(0);

  const onVideoClickHandle = () => {
    inputRefVideo.current?.click();
  };

  const onVideoInputHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const video = event.target.files[0];
      if (validVideoForEvents(video)) {
        const url = URL.createObjectURL(video);
        setVideoPreview(url);
        setFiraFiles({
          ...firaFiles,
          eventTeaserName: url,
          eventTeaserFile: video,
        });
      } else {
        const error = t("event_creation.general_details.image_error");
        showAlert(AlertType.error, error, 5000);
        videoFormRef.current?.reset();
      }
    }
  };

  const clearVideo = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setVideoPreview("");
    videoFormRef.current?.reset();
    setFiraFiles({
      ...firaFiles,
      eventTeaserName: "",
      eventTeaserFile: null,
    });
  };

  const videoMutedHandler = (
    event: React.MouseEvent<HTMLElement>,
    control: VideoControlsType
  ) => {
    event.stopPropagation();
    if (control === VideoControlsType.mute) {
      setMute(true);
      if (videoRef.current?.currentTime !== undefined) {
        videoRef.current.muted = true;
      }
    } else if (control === VideoControlsType.unmute) {
      setMute(false);
      if (videoRef.current?.currentTime !== undefined) {
        videoRef.current.muted = false;
      }
    }
  };

  const handleTime = () => {
    const vid = document.getElementById("preview-video") as HTMLVideoElement;
    const videoT = Math.floor(vid.duration);
    interval = setInterval(() => {
      if (videoRef.current?.currentTime !== undefined && videoT !== undefined) {
        if (videoT > 0) {
          setProgress(
            Math.floor((videoRef.current?.currentTime / videoT) * 100)
          );
        }
      }
      if (videoRef.current?.ended == true) {
        clearInterval(interval);
      }
    }, 100);
  };

  const videoHandler = (
    event: React.MouseEvent<HTMLElement>,
    control: VideoControlsType
  ) => {
    event.stopPropagation();
    if (control === VideoControlsType.play) {
      setPlaying(true);
      const vid = document.getElementById("preview-video") as HTMLVideoElement;
      handleTime();
      videoRef.current?.play();
    } else if (control === VideoControlsType.pause) {
      videoRef.current?.pause();
      setPlaying(false);
      clearInterval(interval);
    }
  };

  const handleProgress = (val: number | number[]) => {
    const vid = document.getElementById("preview-video") as HTMLVideoElement;
    const videoT = Math.floor(vid.duration);
    if (videoRef.current?.currentTime !== undefined && videoT !== undefined) {
      let t = Math.round(Number(val));
      videoRef.current.currentTime = Math.floor((t / 100) * videoT);
      handleTime();
    }
  };

  const NoVideo = (
    <>
      <div className={styles.NoVideo} onClick={onVideoClickHandle}>
        <div className={styles.Icon}>
          <VideoCamera />
        </div>
        <div className={styles.Upload}>
          <CloudUpload />
          <span>{t("event_creation.general_details.forms.load_teaser")}</span>
        </div>
        <div className={styles.InfoMessage}>
          <span>{t("event_creation.general_details.forms.teaser_info")}</span>
        </div>
        <div>
          <div className={styles.Format}>
            <span>{t("event_creation.general_details.forms.file_format")}</span>
            <span>{videoFileFormat}</span>
          </div>
          <div className={styles.Format}>
            <span>
              {t("event_creation.general_details.forms.recommended_size")}
            </span>
            <span>{videoFileSize}</span>
          </div>
        </div>
      </div>
    </>
  );

  const LoadedVideo = (
    <>
      <div className={styles.VideoPreview} onClick={onVideoClickHandle}>
        {configType === EventConfig.NEW && (
          <div className={styles.DeleteIcon} onClick={clearVideo}>
            <TrashCan />
          </div>
        )}

        <div id={styles.videoControls}>
          {!mute ? (
            <div
              className={styles["video-control"]}
              onClick={(e) => videoMutedHandler(e, VideoControlsType.mute)}
            >
              <SvgVolume opacity={1} />
            </div>
          ) : (
            <div
              className={styles["video-control"]}
              onClick={(e) => videoMutedHandler(e, VideoControlsType.unmute)}
            >
              <SvgMute />
            </div>
          )}
          {!playing ? (
            <div
              className={styles["video-control"]}
              onClick={(e) => videoHandler(e, VideoControlsType.play)}
            >
              <Play />
            </div>
          ) : (
            <div
              className={styles["video-control"]}
              onClick={(e) => videoHandler(e, VideoControlsType.pause)}
            >
              <Pause />
            </div>
          )}

          <div
            className={styles["progress-bar"]}
            onClick={(e) => e.stopPropagation()}
          >
            <Slider
              className={"fira-video-range"}
              min={0}
              max={100}
              defaultValue={progress}
              value={progress}
              step={1}
              onChange={(value) => handleProgress(value)}
            />
          </div>
        </div>
        <video
          src={videoPreview}
          ref={videoRef}
          id="preview-video"
          key={videoPreview}
        ></video>
      </div>
    </>
  );

  useEffect(() => {
    if (firaFiles && firaFiles.eventTeaserName) {
      setVideoPreview(firaFiles.eventTeaserName);
    }
  }, [firaFiles]);

  return (
    <>
      <div className={styles.VideoContainer}>
        <form ref={videoFormRef} style={{ display: "none" }}>
          <input
            type="file"
            accept="video/mp4"
            ref={inputRefVideo}
            onChange={onVideoInputHandleChange}
          />
        </form>
        {videoPreview !== "" ? <>{LoadedVideo}</> : <>{NoVideo}</>}
      </div>
    </>
  );
};

export default EventGeneralDetails;
