import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import './Collections.scss'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'

// import {getVideoCollectionsById} from 'firalibrary'

const collectionSample = [ 
  {id:'1',
  collectionName:'collecion ejemplo 1',
  imageUrl:"https://firalivedev.blob.core.windows.net/images/PNG2/Screen%20Shot%202023-03-28%20at%208.57.28%20PM.png",
  },
  {id:'2',
  collectionName:'collecion ejemplo 2',
  imageUrl:"https://firalivedev.blob.core.windows.net/images/PNG2/Screen%20Shot%202023-03-28%20at%208.57.28%20PM.png",
  },
  {id:'3',
  collectionName:'collecion ejemplo 3',
  imageUrl:"https://firalivedev.blob.core.windows.net/images/PNG2/Screen%20Shot%202023-03-28%20at%208.57.28%20PM.png",
  },
  {id:'4',
  collectionName:'collecion ejemplo 4',
  imageUrl:"https://firalivedev.blob.core.windows.net/images/PNG2/Screen%20Shot%202023-03-28%20at%208.57.28%20PM.png",
  },
  {id:'5',
  collectionName:'collecion ejemplo 5',
  imageUrl:"https://firalivedev.blob.core.windows.net/images/PNG2/Screen%20Shot%202023-03-28%20at%208.57.28%20PM.png",
  },
  {id:'6',
  collectionName:'collecion ejemplo 6',
  imageUrl:"https://firalivedev.blob.core.windows.net/images/PNG2/Screen%20Shot%202023-03-28%20at%208.57.28%20PM.png",
  },
  {id:'7',
  collectionName:'collecion ejemplo 7',
  imageUrl:"https://firalivedev.blob.core.windows.net/images/PNG2/Screen%20Shot%202023-03-28%20at%208.57.28%20PM.png",
  },
  {id:'8',
  collectionName:'collecion ejemplo 8',
  imageUrl:"https://firalivedev.blob.core.windows.net/images/PNG2/Screen%20Shot%202023-03-28%20at%208.57.28%20PM.png",
  }
]

const Collections = ({
  // firaKey,
  // firstCollectionId,
  // secondCollectionId,
  // thirdCollectionId,
  // fourthCollectionId,
  // fifthCollectionId,
  // sixthCollectionId,
  // seventhCollectionId,
  // eighthCollectionId,
  // firstUrl,
  primaryTextColor,
  secondaryTextColor,
  primaryColor,
  backgroundColor,
  isMobileAttr=false,
  headerTitle,
})=>{
const mediaWidth = 790;
const [collectionsList, setCollectionsList]= useState(collectionSample)
const [windowSize, setWindowSize] = useState(window.innerWidth);
const [isMobile, setIsMobile]= useState(isMobileAttr)

// const getWindowSize=()=> {
//   const { innerWidth } = window;
//   return  innerWidth;
// }

// const handleResize=()=>{
//   window.addEventListener("resize", ()=>setWindowSize(getWindowSize()));

//   return () => {
//     window.removeEventListener("resize",()=> setWindowSize(getWindowSize()));
//   };
// }
// const handleMobile = () =>{
//   if(windowSize < mediaWidth){
//     setIsMobile(true)
//   }else{
//     setIsMobile(false)
//   }
// }

// const getCollection = async (id) =>{
//   try{
//     const response = await getVideoCollectionsById(firaKey,id,env)
//     return response
//   }catch(err){
//     console.error(err)
//   }
// }

const handleShowMore = ()=>{
  // window.open(firstUrl)
  return;
 }

// const getCollections = async () =>{
//   if(firstCollectionId){
//     let collection = await getCollection(firstCollectionId) 
//     setCollectionsList(current =>[...current, collection])
//   }
//   if(secondCollectionId){
//     let collection = await getCollection(secondCollectionId)
//     setCollectionsList(current =>[...current, collection])
//   }
//   if(thirdCollectionId){
//     let collection = await getCollection(thirdCollectionId)
//     setCollectionsList(current =>[...current, collection])
//   }
//   if(fourthCollectionId){
//     let collection = await getCollection(fourthCollectionId)
//     setCollectionsList(current =>[...current, collection])
//   }
//   if(fifthCollectionId){
//     let collection = await getCollection(fifthCollectionId)
//     setCollectionsList(current =>[...current, collection])
//   }
//   if(sixthCollectionId){
//     let collection = await getCollection(sixthCollectionId)
//     setCollectionsList(current =>[...current, collection])
//   }
//   if(seventhCollectionId){
//     let collection = await getCollection(seventhCollectionId)
//     setCollectionsList(current =>[...current, collection])
//   }
//   if(eighthCollectionId){
//     let collection = await getCollection(eighthCollectionId)
//     setCollectionsList(current =>[...current, collection])
//   }
  
// }
// useEffect(() => {
//   // getCollections()
//   handleMobile()
//   handleResize()
// }, []);

// useEffect(()=>{
//   if(windowSize){
//     handleMobile()
//   }
// },[windowSize])

 return(
  <div className='fira-collections-widget-wrapper' style={{background:backgroundColor}}>
      <div className='fira-collections-widget-header'>
        <div className='fira-collections-title' style={{color:primaryTextColor}}>
          {headerTitle}
        </div>
       {!isMobile&& 
        <div 
          className='fira-collections-show-more' 
          style={{color:secondaryTextColor}} 
          onClick={handleShowMore}>
          ver mas
        </div>
        }
      </div>
      {!isMobile ?     
      <div className={`fira-collections-widget-group ${isMobile?'fira-collections-widget-mobile-group':''}`}>
        {
          collectionsList.map((collection)=>{
            return(
            <CollectionElement
              id={collection.id}
              img={collection.imageUrl}
              name={collection.collectionName}
              // url={firstUrl}
            />)
          })
        }
      </div>:
      <div className='fira-collections-widget-mobile-group'>
        <Swiper
        slidesPerView={2}
        spaceBetween={50}
        modules={[Pagination]}
        loop={true}
        pagination={{ 
            clickable: true, 
            el:'.custom-pagination',
            bulletClass:'fira-collections-bullet swiper-pagination-bullets'
            }
        }
        style={{
          "--swiper-theme-color": primaryColor,
        }}
        >
          {         
          collectionsList.map((collection)=>{
              return(
              <SwiperSlide key={collection.id} style={{display:'flex', justifyContent:'center'}}>
                  <CollectionElement
                    id={collection.id}
                    img={collection.imageUrl}
                    name={collection.collectionName}
                    // url={firstUrl}
                  />
              </SwiperSlide>
              )
            })}
            <div className='fira-collections-custom-bullets-container'>
            <div 
              className='custom-pagination fira-collections-pagination'
              style={{'--fira-bullet-color':primaryColor}}>
            </div> 
            </div>
        </Swiper>
      </div>}
  </div>
 ) 
}

const CollectionElement =({
  img,
  name,
  // id,
  // url,
})=>{

 const handleClick = ()=>{
  // window.open(url + '?fira-collection-id='+id)
  return;
 }

 return(
  <div className='fira-collections-element' onClick={handleClick}>

    <img className='fira-collections-element-img' src={img} alt={name}/>

    <div className='fira-collections-element-name'>
      {name}
    </div>
  </div>)
}

export default Collections;