import axios from "axios";

export const apiBusiness = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_BUSINESS,
});

export const apiBusinessPrivate = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_BUSINESS,
});

export const apiManagement = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_MANAGEMENT,
});

export const apiManagementPrivate = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_MANAGEMENT,
});

// TODO: add access token to make private api calls
export const apiServicePrivate = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_SERVICE,
});

export const apiActionsPrivate = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_ACTIONS,
});

export const eventSourceApiActions = (url: string) => {
  const eventSource = new EventSource(
    `${process.env.REACT_APP_BASE_URL_ACTIONS}${url}`
  );
  return eventSource;
};

export const apiBroadcastingService = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_BSERVICE,
});
