import { Route, Routes, useLocation } from "react-router-dom";
import { ActivateForm, LoginForm } from "./components";
import PasswordRecovery from "./components/PasswordRecovery/PasswordRecovery";
import PwdCode from "./components/PwdCode/PwdCode";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import HomeLayout from "./layout/HomeLayout/HomeLayout";
import AuthView from "./views/AuthView/AuthView";
import ProductsView from "./views/ProductsView/ProductsView";
import style from "./App.module.scss";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import DetailProductView from "./views/DetailProductView/DetailProductView";
import ViewLayout from "./layout/ViewLayout/ViewLayout";
import EventsView from "./views/EventsView/EventsView";
import UserPermissionsGuard from "./components/UserPermissionsGuard/UserPermissionsGuard";
import EventDetailView from "./views/EventDetailView/EventDetailView";
import { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentScreenWidthActionCreator } from "./store/Utils/UtilsActions";
import VideosView from "./views/VideosView/VideosView";
import VideoStatsView from "./views/VideoStatsView/VideoStatsView";
import CollectionsView from "./views/CollectionsView/CollectionsView";
import EditVideoView from "./views/EditVideoView/EditVideoView";
import CollectionsDetailView from "./views/CollectionsDetailView/CollectionsDetailView";
import FiraTvWidgetsView from "./views/FiraTvWidgetsView/FiraTvWidgetsView";
import FiraTvWidgetSelectionView from "./views/FiraTvWidgetSelectionView/FiraTvWidgetSelectionView";
import DashboardTabLayout from "./layout/DashboardTabLayout/DashboardTabLayout";
import DashboardEvents from "./components/Dashboard/DashboardEvents/DashboardEvents";
import DashboardOwner from "./components/Dashboard/DashboardOwner/DashboardOwner";
import DashboardVideosOwner from "./components/Dashboard/DashboardVideosOwner/DashboardVideosOwner";
import { AccountOwnerGuard, AuthGuard } from "./components/RouteGuards";
import StoresView from "./views/StoresView/StoresView";
import StoresDetail from "./views/StoresDetailView/StoresDetailView";
import CreateStoreForm from "./components/StoreSettings/CreateStoreForm/CreateStoreForm";
import OwnerTeamDetailsView from "./views/OwnerTeamDetailsView/OwnerTeamDetailsView";
import OwnerEventsView from "./views/OwnerEventsView/OwnerEventsView";
import EventConfigurationView from "./views/EventConfigurationView/EventConfigurationView";
import SendInvitationView from "./views/SendInvitationView/SendInvitationView";
import OwnerPermissionsEditView from "./views/OwnerPermissionsEditView/OwnerPermissionsEditView";
import { DashboardVideos } from "./components/Dashboard";
import { RootState } from "./store/Store";
import LandingConfigurationView from "./views/LandingConfigurationView/LandingConfigurationView";
import { LandingPageView } from "./views/LandingPageView/LandingPageView";
import { ProductVariationsProvider } from "./store/Products/ProductVariationsContext";
import OwnerProductsView from "./views/OwnerProductsView/OwnerProductsView";
import SignUpExpressMK from "./components/SignUpExpress/SignUpExpress";
import TeamView from "./views/TeamView/TeamView";
import { UtilsProvider } from "./providers/UtilsProvider";
import SignUpExpress from "./views/SignUpExpressView/SignUpExpress";

function App() {
  const { authentication, products } = useSelector((state: RootState) => state);
  const location = useLocation();
  const dispatch = useDispatch();

  const state = location.state as { backgroundLocation: Location };

  const handleResize = () => {
    dispatch(setCurrentScreenWidthActionCreator(window.innerWidth));
  };

  const firaTVStatisticsPermissions = (permissions: any) => {
    const firaTvStatistics = "FIRA_TV_GET_VOD_STATISTICS_BY_DATE";
    if (authentication.currentPermission) {
      for (const firaProduct of permissions) {
        if (firaProduct.permissions.includes(firaTvStatistics)) {
          return true;
        }
      }
      return false;
    }
  };

  const dashboardTabs: { title: string; element: ReactElement }[] =
    firaTVStatisticsPermissions(authentication.currentPermission)
      ? [
          { title: "Lives", element: <DashboardEvents /> },
          {
            title: "Fira tv",
            element: <DashboardVideos />,
          },
        ]
      : [{ title: "Lives", element: <DashboardEvents /> }];

  const mainboardTabs: { title: string; element: ReactElement }[] = [
    { title: "Lives", element: <DashboardOwner /> },
    {
      title: "Fira tv",
      element: <DashboardVideosOwner />,
    },
  ];

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <UtilsProvider>
      <div className={style.App}>
        <Routes location={state?.backgroundLocation || location}>
          <Route path="/" element={<AuthView />}>
            <Route index element={<LoginForm />} />
            <Route path="register" element={<RegisterForm />} />
            <Route path="recovery" element={<PasswordRecovery />} />
            <Route path="pwdcode" element={<PwdCode />} />
            <Route path="changepwd" element={<ChangePassword />} />
            <Route path="activate" element={<ActivateForm />} />
          </Route>

          <Route path="sign-up-mary-kay/:id" element={<SignUpExpressMK />} />
          <Route path="sign-up-express/:id" element={<SignUpExpress />} />

          <Route element={<AuthGuard />}>
            <Route path="home" element={<HomeLayout />}>
              {/** Routes only for ACCOUNT OWNER */}
              <Route element={<AccountOwnerGuard />}>
                <Route path="mainboard" element={<ViewLayout />}>
                  <Route
                    index
                    element={<DashboardTabLayout tabs={mainboardTabs} />}
                  ></Route>
                </Route>
                <Route path="stores" element={<ViewLayout />}>
                  <Route index element={<StoresView />}></Route>
                  <Route path=":id" element={<StoresDetail />}></Route>
                  <Route path="new" element={<CreateStoreForm />} />
                </Route>
                <Route path="calendar" element={<ViewLayout />}>
                  <Route index element={<OwnerEventsView />} />
                  <Route
                    path="new/:id"
                    element={<EventConfigurationView />}
                  ></Route>
                  <Route
                    path="edit/:id"
                    element={<EventConfigurationView />}
                  ></Route>
                  <Route
                    path="event-configuration/:id"
                    element={<EventConfigurationView />}
                  />
                </Route>
                <Route path="all-videos" element={<ViewLayout />}>
                  <Route index element={<VideosView />} />
                  <Route path="stats" element={<VideoStatsView />} />
                  <Route path="edit" element={<EditVideoView />}></Route>
                </Route>

                <Route path="all-products" element={<ViewLayout />}>
                  <Route index element={<OwnerProductsView />} />

                  <Route
                    path="new"
                    element={
                      <ProductVariationsProvider
                        initialProductOptions={[]}
                        initialProductVariations={[]}
                      >
                        <DetailProductView />
                      </ProductVariationsProvider>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <ProductVariationsProvider
                        initialProductOptions={
                          products.currentProduct.firaProductOptionDtos
                        }
                        initialProductVariations={
                          products.currentProduct.firaVariationsDtos
                        }
                      >
                        <DetailProductView />
                      </ProductVariationsProvider>
                    }
                  />
                </Route>
              </Route>

              {/** Routes for everybody else when and current store id is set in the main redux state */}
              <Route path="dashboard" element={<ViewLayout />}>
                <Route
                  index
                  element={<DashboardTabLayout tabs={dashboardTabs} />}
                ></Route>
              </Route>

              <Route path="products" element={<ViewLayout />}>
                <Route index element={<ProductsView />} />

                <Route
                  path="new"
                  element={
                    <ProductVariationsProvider
                      initialProductOptions={[]}
                      initialProductVariations={[]}
                    >
                      <DetailProductView />
                    </ProductVariationsProvider>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <ProductVariationsProvider
                      initialProductOptions={
                        products.currentProduct.firaProductOptionDtos
                      }
                      initialProductVariations={
                        products.currentProduct.firaVariationsDtos
                      }
                    >
                      <DetailProductView />
                    </ProductVariationsProvider>
                  }
                />
              </Route>

              <Route path="events" element={<ViewLayout />}>
                <Route index element={<EventsView />} />
                <Route
                  path="new/:id"
                  element={<EventConfigurationView />}
                ></Route>
                <Route
                  path="edit/:id"
                  element={<EventConfigurationView />}
                ></Route>
                <Route
                  path="event-detail/:view"
                  element={<EventDetailView />}
                />

                <Route
                  path="event-configuration/:id"
                  element={<EventConfigurationView />}
                />
              </Route>
              <Route element={<UserPermissionsGuard />}>
                <Route path="team" element={<ViewLayout />}>
                  <Route index element={<TeamView />} />
                  <Route path=":id" element={<OwnerTeamDetailsView />} />
                  <Route
                    path=":id/permissions/:storeId"
                    element={<OwnerPermissionsEditView />}
                  />
                  <Route path="new" element={<SendInvitationView />} />
                </Route>
              </Route>
              <Route path="widgets" element={<ViewLayout />}>
                <Route index element={<FiraTvWidgetSelectionView />} />
              </Route>
              <Route path="widgets-config/:widget" element={<ViewLayout />}>
                <Route index element={<FiraTvWidgetsView />} />
              </Route>
              <Route path="videos" element={<ViewLayout />}>
                <Route index element={<VideosView />} />
                <Route path="stats" element={<VideoStatsView />} />
                <Route path="edit" element={<EditVideoView />}></Route>
              </Route>
              <Route path="collections" element={<ViewLayout />}>
                <Route index element={<CollectionsView />} />
                <Route path="new" element={<CollectionsDetailView />} />
                <Route path="edit/:id" element={<CollectionsDetailView />} />
              </Route>
              <Route path="landing" element={<ViewLayout />}>
                <Route index element={<LandingPageView />} />
              </Route>
              <Route
                path="landing/config"
                element={<LandingConfigurationView />}
              />
            </Route>
          </Route>
        </Routes>
      </div>
    </UtilsProvider>
  );
}

export default App;
