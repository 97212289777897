import styles from "./ViewsByDevicesWidget.module.scss";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";
import Skeleton from "../../components/Skeleton/Skeleton";

interface Props {
  desktop: number;
  mobile: number;
  other: number;
  isLoading?: boolean;
  height?: string;
}

const ViewsByDevices = ({
  desktop,
  mobile,
  other,
  isLoading,
  height,
}: Props) => {
  const { t } = useTranslation();
  const dataDesktop = [
    { name: "Group A", value: mobile ? mobile : 0 },
    { name: "Group B", value: desktop },
    { name: "Group C", value: other ? other : 0 },
  ];
  const dataMobile = [
    { name: "Group B", value: mobile },
    { name: "Group A", value: desktop ? desktop : 0 },
    { name: "Group C", value: other ? other : 0 },
  ];
  const dataOther = [
    { name: "Group A", value: desktop ? desktop : 0 },
    { name: "Group B", value: mobile ? mobile : 0 },
    { name: "Group C", value: other },
  ];
  const COLORSA = ["#F7F8F9", "#5ECDF7", "#F7F8F9"];
  const COLORSB = ["#FFDE0D", "#F7F8F9", "#F7F8F9"];
  const COLORSC = ["#F7F8F9", "#F7F8F9", "#D0D0D0"];

  return (
    <div style={{ height }} className={styles.ContainerViewsByDevices}>
      <label className={styles.TitleWidget}>
        <label>
          {isLoading ? (
            <Skeleton height="20px" width={"60%"} />
          ) : (
            t("widgets.viewsByDevices.title")
          )}
        </label>
      </label>
      <div
        className={`${styles.WrapperDevices} ${
          isLoading ? styles.contentLoading : ""
        }`}
      >
        <GraphDataItem
          isLoading={isLoading}
          dataTitle={t("widgets.viewsByDevices.desktop")}
          datagraph={dataDesktop}
          dataNumber={desktop}
          dataColors={COLORSA}
        />

        <GraphDataItem
          isLoading={isLoading}
          dataTitle={t("widgets.viewsByDevices.mobile")}
          datagraph={dataMobile}
          dataNumber={mobile}
          dataColors={COLORSB}
        />

        <GraphDataItem
          isLoading={isLoading}
          dataTitle={t("widgets.viewsByDevices.other")}
          datagraph={dataOther}
          dataNumber={other}
          dataColors={COLORSC}
        />
      </div>
    </div>
  );
};

export default ViewsByDevices;

interface DataType {
  name: string;
  value: number;
}
interface GraphDataItemProps {
  datagraph: DataType[];
  isLoading?: boolean;
  dataTitle: string;
  dataNumber: number;
  dataColors: string[];
}

const GraphDataItem = ({
  datagraph,
  isLoading,
  dataTitle,
  dataNumber,
  dataColors,
}: GraphDataItemProps) => {
  return (
    <div className={styles.itemContent}>
      {isLoading ? (
        <LoadingPie />
      ) : (
        <div className={styles.itemPie}>
          <div className={styles.inner}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={datagraph}
                  innerRadius={"65%"}
                  outerRadius={"100%"}
                  fill="#8884d8"
                  dataKey="value"
                  startAngle={90}
                  endAngle={450}
                >
                  {datagraph.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={dataColors[index % dataColors.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
      <div className={styles.itemData}>
        <label className={styles.TitleData}>
          {isLoading ? <Skeleton height="16px" width={"100%"} /> : dataTitle}
        </label>
        <label className={styles.ValueData}>
          {isLoading ? <Skeleton height="16px" width={"50%"} /> : dataNumber}
        </label>
      </div>
    </div>
  );
};

const LoadingPie = () => {
  return (
    <div className={styles.loadingPie}>
      <div className={styles.innerLoading}>
        <div className={`${styles.child} ${styles.one}`}></div>
        <div className={`${styles.child} ${styles.two}`}></div>
        <div className={styles.innerCircle}></div>
      </div>
    </div>
  );
};
