import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCloudUpload = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g
      clipPath="url(#CloudUpload_svg__a)"
      stroke="#000"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M13.834 13.333 10.5 10l-3.333 3.333M10.5 10v7.5" />
      <path d="M17.492 15.325A4.167 4.167 0 0 0 15.5 7.5h-1.05A6.667 6.667 0 1 0 3 13.583" />
      <path d="M13.834 13.333 10.5 10l-3.333 3.333" />
    </g>
    <defs>
      <clipPath id="CloudUpload_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCloudUpload;
