import { t } from "i18next";
import { PermissionsByGroupType } from "../../../../utils/types.d";

export const ModeratorManager = () => {
  return {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.manager.titlev2"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescriptionv2"
    ),
    permissions: [
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.manager.eventsv2"
        ),
        group: PermissionsByGroupType.FIRA_WEB_VIEW_EVENTS,
      },
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.events.moderation"
        ),
        group: PermissionsByGroupType.FIRA_WEB_MODERATOR,
      },
    ],
  };
};

export const ModeratorFiraTV = () => {
  return {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.title"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescriptionv2"
    ),
    permissions: [
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.moderation"
      ),
    ],
  };
};
