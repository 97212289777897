import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { getLandingsByStoreIdService } from "../../services/landing";
import React, { useEffect, useState } from "react";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import { useTranslation } from "react-i18next";
import Button, { ButtonStyle } from "../../components/Button/Button";
import styles from "./LandingPageView.module.scss";
import iPhone from "../../assets/img/iPhone.png";
import MacBook from "../../assets/img/MacBook.png";
import PopUpInfo from "../../components/PopUpInfo/PopUpInfo";
import { useNavigate } from "react-router-dom";
import LandingIntro from "../../components/LandingIntro/LandingIntro";

export const LandingPageView = () => {
  const { t } = useTranslation();
  const { brand } = useSelector((state: RootState) => state);
  const envUrl = process.env.REACT_APP_BASE_URL_LANDING;
  const [landingURL, setLandingURL] = useState<string>("");
  const [elementTarget, setElementTarget] = useState<HTMLElement>();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const navigate = useNavigate();

  const info = (
    <p
      style={{
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "14px",
      }}
    >
      {t("views.landing.popUp")}
    </p>
  );
  const getLandings = () => {
    if (brand.currentStore)
      getLandingsByStoreIdService(brand.currentStore.id).then((val) => {
        if (val.data.length !== 0) {
          const landing = val.data;

          setLandingURL(`${envUrl}${landing.name}`);
        }
      });
  };

  const handleRedirect = (e: React.MouseEvent<HTMLElement>) => {
    if (landingURL !== "") {
      window.open(landingURL, "_blank", "noreferrer");
    } else {
      setShowInfo(!showInfo);
      setElementTarget(e.currentTarget);
    }
  };

  const navigateToConfig = () => {
    navigate("/home/landing/config");
  };

  useEffect(() => {
    getLandings();
  }, []);

  const headerOptions = (
    <>
      <div style={{ display: "flex", gap: "16px" }}>
        <Button
          buttonStyle={ButtonStyle.transparent}
          width={"70px"}
          clickHandler={handleRedirect}
        >
          <span
            className={"body-12-low-text"}
            style={{ textDecoration: "underline" }}
          >
            {t("views.landing.subLinks.preview")}
          </span>
        </Button>
        {showInfo && (
          <PopUpInfo title={""} elementRef={elementTarget} message={info} />
        )}
        {brand.currentStore && (
          <Button width="136px" height="32px" clickHandler={navigateToConfig}>
            <span className={"body-12-low-text"}>
              {t("views.landing.subLinks.config")}
            </span>
          </Button>
        )}
      </div>
    </>
  );

  return (
    <>
      <FiraNavBar
        titleSection={`${t("views.landing.title")} - ${
          brand.currentStore?.name
        }`}
        trailingOptions={headerOptions}
      />
      <LandingIntro />
    </>
  );
};
