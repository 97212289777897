import { useEffect } from "react";
import  "./NextEvents.scss";
import { invertColor } from "../../utils/InvertirColor";
import { useState } from "react";
import { useTranslation } from "react-i18next";
//import {  playFiraModal } from "firalibrary";
/**
 * Description of main video carousel
 * @prop {string} primaryColor - button and swiper pagination color
 * @prop {Object} event - Object to contains event info

 */
const EventItem = ({
  firakey, 
  firasrc,
  primaryColor,
  textColor,
  displayColor,
  buttonTextColor, 
  event, 
  type}) => {

    const { t } = useTranslation();
  const [second, setSeconds]= useState("00");
  const [minutes, setMinutes]= useState("00");
  const [hours, setHours]= useState("00");
  const [days, setDays]= useState("00");
  const [eventDate, setEventDate]=useState("");
  const [eventHour, setEventHour]=useState("");
  const color={
    background:primaryColor? primaryColor:"#ffde07",
    color:buttonTextColor?buttonTextColor:"#000"
  };
  const styleText={
    color:textColor?textColor:"#000"
  };
  const lang = navigator.language.split(/-|_/)[0];

  const getTime = dateTo => {
   
    let now = new Date(),
    time = (new Date(dateTo) - now + 1000) / 1000,
    seconds = ('0' + Math.floor(time % 60)).slice(-2),
    minutes = ('0' + Math.floor(time / 60 % 60)).slice(-2),
    hours = ('0' + Math.floor(time / 3600 % 24)).slice(-2),
    days = Math.floor(time / (3600 * 24));
  
    let zero = new Date(),
    timex = 0,
    secondsx = 0,
    minutesx = 0,
    hoursx = 0,
    daysx = 0;
    
    if(dateTo < new Date()){
      return {
        secondsx,
        minutesx,
        hoursx,
        daysx,
        timex
    }  
    }else{
      return {
        seconds,
        minutes,
        hours,
        days,
        time
    }  
    }
   
    
};

function hourFormat(date) {
  
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var newformat = hours >= 12 ? 'PM' : 'AM'; 
  
  hours = hours % 12; 

  hours = hours ? hours : 12; 
  minutes = minutes < 10 ? '0' + minutes : minutes;
   
  return hours + ":" + minutes + " " + newformat;
}

const initTime=()=>{
  if(event){
    var new_date = new Date(event.scheduledDate);
    setEventDate(t('views.firaTv.exampleItems.fakeDate'))
    setEventHour('9:26 AM')
    // setEventDate(new_date.toLocaleDateString('es-ES', {weekday:"long"})+", "+new_date.toLocaleDateString('es-ES', {year:"numeric", month:"long"}))

    // setEventHour(hourFormat(new_date));
    // setSeconds("00");
    
    //   setInterval(() => {
    //     let currenTime = getTime(new_date);
       
    //     if(!isNaN(currenTime.seconds)){
    //      setSeconds( currenTime.seconds);
         
    //     }
 
    //     setMinutes(currenTime.minutes>=0?currenTime.minutes:0);
    //     setHours(currenTime.hours>=0?currenTime.hours:0);
    //     setDays(currenTime.days>=0?currenTime.days:0);
        
    //   }, 1000);
    
     
     
  }
  
}


useEffect(()=>{
 
  initTime();
  
},[event]);

const clickHandler = () => {
  
    playFiraModal(firakey, firasrc);
  
 
};
   return (
    <div className={"MainCarrouselEvents_ContainerWidget"} >
    <div className={"MainCarrouselEvents_ImageBox"}>
      
     
      {
        event.evenTeaser ?
        <video className={"MainCarrouselEvents_ImageElement"} 
        src={event.evenTeaser}
        controls
              >
        </video> :
        <>
          {
            event.eventPreviewImage ? 
            (<img  onClick={clickHandler} className={'MainCarrouselEvents_ImageElement'}  src={event.eventPreviewImage}></img>) :
            (<EventImage className={'MainCarrouselEvents_ImageElement'}/>)
          }
        </>
        
        
       
      }
     
      <div className={"MainCarrouselEvents_boxElement"} style={{color:displayColor}}>
        <div className={"MainCarrouselEvents_itemEventDate"}> 
          <p className={"MainCarrouselEvents_textDate"}>{eventDate}</p>
          <p className={"MainCarrouselEvents_textDate"}><b>{eventHour}</b></p>
        </div>

         <div className={"MainCarrouselEvents_itemCategory"}>{''}</div>
       </div>
     
    </div>
    <div className={"MainCarrouselEvents_DescriptionBox"} style={styleText}>
      <span className={"MainCarrouselEvents_titleEvent"} style={styleText} onClick={clickHandler}>{event.eventName}</span>
      <p className={"MainCarrouselEvents_DescriptionText"} style={styleText}>{event.eventDescription}</p>

      {/* Dejo esto comentado mientras esperamos por nuevo diseño de cuenta regresiva */}

      {/* {
        type === "NEXT-EVENTS" && (
          <div className={"MainCarrouselEvents_timerCount"}>
          <div className={"MainCarrouselEvents_elementTime"}>
            <span className={"MainCarrouselEvents_textItemTimeBold"}>{days+ " :"}</span>
            <span className={"MainCarrouselEvents_textItemTime"}>
            {
          (() => {
            switch(lang) {
              case "es":
                return <>Dias</>
              case "en":
                return <>Days</>
              case "pt": 
                return <>Dias</>
              default:
                return <>Dias</>
            }
          })() 
        }
            </span>
          </div>
          <div className={"MainCarrouselEvents_elementTime"}>
            <span className={"MainCarrouselEvents_textItemTimeBold"}>{hours +" :"}</span>
            <span className={"MainCarrouselEvents_textItemTime"}>Hrs</span>
          </div>
          <div className={"MainCarrouselEvents_elementTime"}>
            <span className={"MainCarrouselEvents_textItemTimeBold"}>{minutes+" :"}</span>
            <span className={"MainCarrouselEvents_textItemTime"}>Min</span>
          </div>
          <div className={"MainCarrouselEvents_elementTime"}>
            <span className={"MainCarrouselEvents_textItemTimeBold"}>{second}</span>
            <span className={"MainCarrouselEvents_textItemTime"}>
            {
          (() => {
            switch(lang) {
              case "es":
                return <>Seg</>
              case "en":
                return <>Sec</>
              case "pt": 
                return <>Seg</>
              default:
                return <>Seg</>
            }
          })() 
        }
            </span>
          </div>
       </div>
        )
      } */}

      <button style={color}  className={"MainCarrouselEvents_btnShowMore"} onClick={clickHandler}>
        {
          (() => {
            switch(lang) {
              case "es":
                return <>Conocer más</>
              case "en":
                return <>Know more</>
              case "pt": 
                return <>Saber mais</>
              default:
                return <>Conocer más</>
            }
          })() 
        }
      </button>
    </div>
   
</div>
  )
}

export const EventImage = (props) => {
  const classname = props.className;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1080"
      height="1080"
      viewBox="0 0 1080 1080"
      className={classname}
    >
      <rect
        width="1080"
        height="1080"
        x="-540"
        y="-540"
        fill="#FFF"
        rx="0"
        ry="0"
        transform="translate(540 540)"
        vectorEffect="non-scaling-stroke"
        visibility="hidden"
      ></rect>
      <path
        d="M10.11 23.334h-20.223c-7.908 0-13.221-5.547-13.221-13.804V-9.527c0-8.257 5.313-13.806 13.221-13.806h20.225c7.908 0 13.22 5.549 13.22 13.806V9.53c0 8.257-5.312 13.804-13.223 13.804"
        opacity="0.4"
        transform="matrix(3.15 0 0 3.15 540 514.43)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        fillRule="evenodd"
        d="M-3.233-10.028A5.806 5.806 0 01-9.03-4.23a5.804 5.804 0 01-5.798-5.798c0-3.197 2.599-5.8 5.798-5.8a5.808 5.808 0 015.798 5.8zm19.03 12.63c.543.527.933 1.129 1.19 1.77.777 1.942.373 4.275-.458 6.198a8.49 8.49 0 01-5.245 4.774c-1.055.338-2.16.483-3.264.483h-19.556c-1.946 0-3.668-.453-5.08-1.302-.884-.532-1.04-1.757-.385-2.555 1.097-1.33 2.18-2.665 3.272-4.011 2.08-2.576 3.483-3.323 5.042-2.667.632.27 1.267.679 1.92 1.108 1.74 1.153 4.16 2.735 7.348 1.018 2.179-1.192 3.443-3.23 4.544-5.005l.022-.035.221-.356c.374-.605.743-1.2 1.16-1.75.519-.678 2.441-2.801 4.926-1.29 1.584.952 2.917 2.24 4.342 3.62z"
        transform="matrix(3.15 0 0 3.15 540 514.43) translate(1.47 2.27)"
        vectorEffect="non-scaling-stroke"
      ></path>
    </svg>
  );
}

export default EventItem;