import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button, { ButtonStyle } from "../../components/Button/Button";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { ArrowDownFill, EmptyPieChartWidget } from "../../components/FiraIcons";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import Skeleton from "../../components/Skeleton/Skeleton";
import {
  orderingType,
  TableHeader,
  TableRow,
  TableCell,
  Table,
  TableHead,
  InfiniteTable,
} from "../../components/Table/Table";
import { getAllUsersService } from "../../services/store";
import { RootState } from "../../store/Store";
import {
  getAllUsersActionCreator,
  resetUsersActionCreator,
} from "../../store/Team/TeamActions";
import { updateUserStatusActionCreator } from "../../store/Team/TeamActions";
import { FiraBusinessUser } from "../../utils/types";
import styles from "./OwnerTeamView.module.scss";

enum OrderingType {
  status = "STATUS",
  descend = "DESCEND_ALPHABET",
  ascend = "ASCEND_ALPHABET",
}

enum StatusSelectType {
  active = "ACTIVE",
  inactive = "INACTIVE",
}

const OwnerTeamView = () => {
  const { team, brand } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const [userList, setUserList] = useState<FiraBusinessUser[]>([]);
  const [users, setUsers] = useState<FiraBusinessUser[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderingList = [
    {
      id: 1,
      name: t("views.stores.table.status"),
      value: OrderingType.status,
    },
    {
      id: 2,
      name: "A - Z",
      value: OrderingType.descend,
    },
    {
      id: 3,
      name: "Z - A",
      value: OrderingType.ascend,
    },
  ];

  const [selectedFilter, setSelectedFilter] = useState<orderingType>(
    orderingList[0]
  );

  const optionsStatus = [
    {
      name: t("components.statusSelect.active"),
      value: StatusSelectType.active,
      color: "#FFF29C",
    },
    {
      name: t("components.statusSelect.inactive"),
      value: StatusSelectType.inactive,
      color: "#F7F8F9",
    },
  ];

  const handleOrdering = (id: number) => {
    let selected = orderingList.filter((p) => {
      return p.id === id;
    });

    setSelectedFilter(selected[0]);
  };

  const handleSelectState = (option: any, id: string) => {
    brand.currentStore?.id &&
      dispatch(updateUserStatusActionCreator(id, brand.currentStore.id));
  };

  const handleFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
    if (userList) {
      //TEMP
      if (keyword !== "") {
        setUserList(
          userList.filter((user) => {
            return (
              user.firstName.toLowerCase().includes(keyword.toLowerCase()) ||
              user.lastName.toLowerCase().includes(keyword.toLowerCase())
            );
          })
        );
      } else {
        setUserList(users);
      }
    }
  };

  const getUsers = async () => {
    const size = userList.length > 0 ? userList.length + 1 : 0;
    try {
      dispatch(
        getAllUsersActionCreator(
          size,
          20,
          brand.currentStore && brand.currentStore.id
        )
      );

      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUserDetails = (userId: string) => {
    navigate(`${userId}`);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (team.list && team.list !== null) {
      let teamMemberList: FiraBusinessUser[] = [];
      team.list.map((t) => {
        teamMemberList.push(t.user);
      });

      setUserList(teamMemberList);
      setUsers(teamMemberList);
    }

    setIsLoading(team.isLoading);
  }, [team]);

  useEffect(() => {
    return () => {
      dispatch(resetUsersActionCreator());
    };
  }, []);

  /** TABLE */
  const headerColumns = [
    {
      name: "",
      size: "16px",
    },
    {
      name: t("views.stores.table.storeName"),
      size: "140px",
    },
    {
      name: "",
      size: "",
    },
    {
      name: "",
      size: "16px",
    },
  ];

  const header = (
    <TableRow>
      {headerColumns.map((item, i) => {
        return (
          <TableHead key={i} size={item.size} centerContent={i === 2}>
            {item.name}
          </TableHead>
        );
      })}
    </TableRow>
  );

  const body = (
    <>
      {userList.map((user, i) => {
        return (
          <TableRow key={i}>
            <TableCell size="16px"></TableCell>
            <TableCell size="140px">
              <p className={`${styles["body-14-text"]} ${styles.ellipsis}`}>
                {user.firstName}
              </p>
            </TableCell>

            <TableCell RightContent>
              <Button
                buttonStyle={ButtonStyle.transparent}
                width={"80px"}
                clickHandler={() => handleUserDetails(user.id)}
              >
                <span className={styles.TableLink}>
                  {t("views.team.seeProfileBtn")}
                </span>
              </Button>
            </TableCell>
            <TableCell size="16px"></TableCell>
          </TableRow>
        );
      })}
    </>
  );

  /** LOADING TABLE */
  const placeholder = [
    { id: 1, name: "placeholder" },
    { id: 2, name: "placeholder" },
    { id: 3, name: "placeholder" },
    { id: 4, name: "placeholder" },
    { id: 5, name: "placeholder" },
  ];

  const SkeletonTableBody = placeholder.map((data) => {
    return (
      <TableRow key={data.id}>
        <TableCell size="16px"></TableCell>
        <TableCell size="140px">
          <div>
            <Skeleton width="90%" height="22px" />
          </div>
        </TableCell>
        <TableCell size="100px" centerContent>
          <div className="centerSkeleton">
            <Skeleton width="88px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="100%" RightContent>
          <Skeleton width="122px" height="22px" />
        </TableCell>
        <TableCell size="16px"></TableCell>
      </TableRow>
    );
  });

  const EmptyState = () => {
    return (
      <div className={styles.TableEmptyState}>
        <EmptyPieChartWidget />
      </div>
    );
  };

  return (
    <>
      <TableHeader
        hideCheckBox={true}
        // orderingList={orderingList}
        // orderingValue={selectedFilter}
        // orderingClick={handleOrdering}
        searchInputValue={filterValue}
        searchInputChangeHandle={handleFiltering}
      />
      {userList.length !== 0 ? (
        <InfiniteTable
          header={header}
          body={!isLoading ? body : SkeletonTableBody}
          rowsNumber={userList.length}
          loadData={() => getUsers()}
          hasMore={userList.length >= 20}
        />
      ) : (
        <EmptyState />
      )}
    </>
  );
};

export default OwnerTeamView;
