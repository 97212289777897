import * as React from "react";
import { SVGProps } from "react";

const AddImageBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={65}
    height={61}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M61.01 42.32a11.002 11.002 0 0 0-.69-3.37 10.93 10.93 0 0 0-8.24-6.91 9.075 9.075 0 0 0-.91-.14c-.36 0-.72-.05-1.09-.05h-.34a11 11 0 0 0-10.7 11c-.003.441.023.882.08 1.32.045.415.122.826.23 1.23.058.26.132.518.22.77a11 11 0 0 0 21.47-3.32c-.02-.19-.02-.36-.03-.53Zm-6.37 1.28a1 1 0 0 1-.62.22 1 1 0 0 1-.78-.38l-2.22-2.78v7.15a1 1 0 0 1-2 0v-7.15l-2.22 2.78a1 1 0 0 1-.78.38 1 1 0 0 1-.78-1.62l3.95-4.93a.18.18 0 0 1 .05-.07c.046-.06.1-.114.16-.16l.1-.07.09-.05H50.21l.09.05.1.07c.061.046.115.1.16.16l.05.07 3.95 4.93a1 1 0 0 1 .08 1.4ZM16.55 17.92a2.14 2.14 0 1 1-4.278 0 2.14 2.14 0 0 1 4.278 0Zm4.72 6.52 6.64 4.74 9.87-9.87a1 1 0 0 1 1.39 0L50.5 29.84h.78l.75.1V13.5a6 6 0 0 0-6-6H9.98a6 6 0 0 0-6 6v21.9l16.15-11a1 1 0 0 1 1.14.04Zm-6.86-2.38a4.14 4.14 0 1 1 0-8.28 4.14 4.14 0 0 1 0 8.28Zm23 23.76c0 .11 0 .22.08.32H9.98a6 6 0 0 1-6-6v-2.32l16.7-11.35 6.76 4.83a1 1 0 0 0 1.29-.11l9.77-9.79 9.26 8.61a13 13 0 0 0-10.74 12.81c-.004.521.026 1.043.09 1.56.055.486.145.967.27 1.44h.03Z"
      fill="#000"
    />
  </svg>
);

export default AddImageBlack;
