import { t } from "i18next";
import { useEffect, useState } from "react";
import RadioButton from "../../RadioButton/RadioButton";
import styles from "./WidgetRadioButton.module.scss";

export enum RadioOptions {
  collections = "COLLECTIONS",
  savedVideos = "SAVED_VIDEOS",
}

interface WidgetRadioButtonProps {
  title?: string;
  onChange?(option: RadioOptions | null): void;
}

const WidgetRadioButton = ({ title, onChange }: WidgetRadioButtonProps) => {
  const [activeOption, setActiveOption] = useState<RadioOptions | null>(null);

  const options = [
    {
      label: t("components.widgetRadioButton.collections"),
      clickHandler: () => setActiveOption(RadioOptions.collections),
      type: RadioOptions.collections,
    },
    {
      label: t("components.widgetRadioButton.videos"),
      clickHandler: () => setActiveOption(RadioOptions.savedVideos),
      type: RadioOptions.savedVideos,
    },
  ];

  useEffect(() => {
    if (onChange) onChange(activeOption);
  }, [activeOption]);

  return (
    <>
      <div className={styles.WidgetRadioButtonWrapper}>
        <div className={styles.WidgetRadioButtonWrapper__Title}>
          {title?.toUpperCase()}
        </div>
        <div className={styles.WidgetRadioButtonWrapper__Options}>
          {options.map((option, i) => (
            <div className={styles.Option} key={i}>
              <RadioButton
                clickHandler={option.clickHandler}
                isActive={activeOption === option.type}
              />
              <label>{option.label}</label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WidgetRadioButton;
