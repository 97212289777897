import { RequestError } from "../../services/errorHandling";
import { EcommerceType, ProductStatus } from "../../utils/enums";
import { FiraProduct } from "../../utils/types";
import { ProductForm } from "../../views/DetailProductView/DetailProductView";
import { AuthActions, ProductsActions } from "../actionsContants";
import type { ProductsActionType } from "./ProductsActions";

export interface ProductsState {
  isLoading: boolean;
  products: FiraProduct[];
  error?: RequestError;
  currentProduct: ProductForm | FiraProduct;
  imagesChangeSuccess?: boolean;
}
const initialState: ProductsState = {
  isLoading: false,
  products: [],
  currentProduct: {
    id: "",
    name: "",
    storeId: "",
    sku: "",
    externalId: "",
    description: "",
    quantity: 0,
    directUrl: "",
    price1: "",
    price2: "",
    price3: "",
    images: [],
    firaProductOptionDtos: [],
    firaVariationsDtos: [],
    status: ProductStatus.active,
    origin: EcommerceType.FIRA,
    labels: [],
    categories: [],
    isAdvancedUrl: false,
  },
};
export const productsReducer = (
  state = initialState,
  action: ProductsActionType
): ProductsState => {
  switch (action.type) {
    case ProductsActions.getStoreProductsLoading: {
      return { ...state, isLoading: true };
    }
    case ProductsActions.getStoreProductsSuccess: {
      const { payload } = action;
      return { ...state, isLoading: false, products: payload as FiraProduct[] };
    }
    case ProductsActions.getStoreProductsFailed: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }
    case ProductsActions.deleteProductsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case ProductsActions.deleteProductsSuccess: {
      const { payload } = action;
      const newProducts = state.products.filter(
        (p) => (payload as string[]).includes(p.id) === false
      );
      return { ...state, isLoading: false, products: newProducts };
    }
    case ProductsActions.deleteProductsFailed: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }
    case ProductsActions.createProductLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case ProductsActions.createProductSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        currentProduct: payload as FiraProduct,
      };
    }
    case ProductsActions.createProductFailed: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }
    /** ACTIONS UPDATE */
    case ProductsActions.updateProductInfoLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case ProductsActions.updateProductInfoSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        currentProduct: payload as FiraProduct,
      };
    }
    case ProductsActions.updateProductInfoFailed: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }
    /** const newEvent = {
        ...state.currentEvent,
        suscriberCredentialsDto: payload as SuscriberCredentialsDto,
      }; */

    /** ACTIONS UPDATE */
    case ProductsActions.updateImagesProductLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case ProductsActions.updateImagesProductReset: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        imagesChangeSuccess: false,
      };
    }
    case ProductsActions.updateImagesProductSuccess: {
      const { payload } = action;
      const newProduct = {
        ...state.currentProduct,
        images: payload as string[],
      };
      return {
        ...state,
        isLoading: false,
        currentProduct: newProduct,
        imagesChangeSuccess: true,
      };
    }
    case ProductsActions.updateImagesProductFailed: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }

    case ProductsActions.setCurrentProduct: {
      const { payload } = action;
      return { ...state, currentProduct: payload };
    }
    case ProductsActions.resetCurrentProduct: {
      return { ...state, currentProduct: initialState.currentProduct };
    }
    case AuthActions.logout: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
