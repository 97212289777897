import { NavLink } from "../../../utils/types";
import { useClickOutside } from "../../../utils/useClickOutside";
import styles from "./FiraTopContextMenu.module.scss";

interface FiraTopContextMenuProps {
  options: NavLink[];
  isLoading?: boolean;
  closeModal(): void;
}

const FiraTopContextMenu = ({
  options,
  isLoading,
  closeModal,
}: FiraTopContextMenuProps) => {
  const domNode = useClickOutside(() => closeModal());
  return (
    <>
      <div className={styles.FiraTopContextMenuWrapper} ref={domNode}>
        {options.map((item, i) => (
          <div
            className={`${styles.FiraTopContextMenuItem} ${
              isLoading ? styles.disabledOption : ""
            }`}
            key={i}
            onClick={
              item.clickHandler
                ? () => {
                    item.clickHandler!();
                    closeModal();
                  }
                : () => ""
            }
          >
            <div className={styles.Icon}>{item.icon}</div>
            <div className={styles.Label}>{item.name}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FiraTopContextMenu;
