import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDashboard = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.54 5h3.38c1.41 0 2.54 1.15 2.54 2.561v3.409c0 1.42-1.13 2.56-2.54 2.56H7.54c-1.4 0-2.54-1.14-2.54-2.56V7.561C5 6.15 6.14 5 7.54 5Zm0 11.47h3.38c1.41 0 2.54 1.14 2.54 2.56v3.41c0 1.41-1.13 2.56-2.54 2.56H7.54C6.14 25 5 23.85 5 22.44v-3.41c0-1.42 1.14-2.56 2.54-2.56ZM22.46 5h-3.38c-1.41 0-2.54 1.15-2.54 2.561v3.409c0 1.42 1.13 2.56 2.54 2.56h3.38c1.4 0 2.54-1.14 2.54-2.56V7.561C25 6.15 23.86 5 22.46 5Zm-3.38 11.47h3.38c1.4 0 2.54 1.14 2.54 2.56v3.41c0 1.41-1.14 2.56-2.54 2.56h-3.38c-1.41 0-2.54-1.15-2.54-2.56v-3.41c0-1.42 1.13-2.56 2.54-2.56Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDashboard;
