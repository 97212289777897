import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgMail = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 21.44c0 .858.72 1.56 1.6 1.56h12.8c.88 0 1.6-.702 1.6-1.56v-8.157c0-.507-.24-1.015-.68-1.288L15 7l-7.32 4.995c-.44.312-.68.78-.68 1.288v8.156Zm12.8-10.147h-9.6v8.585h9.6v-8.585Z"
      fill="#010202"
    />
    <path
      d="M21.4 23H8.6c-.88 0-1.6-.702-1.6-1.56v-7.806l8 5.073 8-5.073v7.805c0 .859-.72 1.561-1.6 1.561Z"
      fill="#010202"
    />
  </svg>
);

export default SvgMail;
