import type { ThunkDispatch } from "redux-thunk";
import type { AnyAction } from "redux";

import {
  loginService,
  refreshTokenService,
} from "../../services/authentication";
import { LoginResponse } from "../../utils/types";
import { handleError, RequestError } from "../../services/errorHandling";
import { AuthActions, BrandActions } from "../actionsContants";
import { AxiosResponse } from "axios";
import {
  PostCreateStoresActionType,
  SetCurrentStoreActionType,
} from "../Brand/BrandActions";
import { AcceptInvitationActionType } from "../User/UserActions";
import { getStoreBy } from "../../services/store";

interface LoginActionType {
  type:
    | AuthActions.loginSuccess
    | AuthActions.loginFailed
    | AuthActions.loginLoading;
  payload?: LoginResponse | RequestError;
}
export const loginActionCreator = (
  user: string,
  password: string,
  isEmail = false
) => {
  return async (
    dispatch: ThunkDispatch<LoginActionType, Record<string, unknown>, AnyAction>
  ) => {
    try {
      dispatch({ type: AuthActions.loginLoading });
      let response: AxiosResponse<LoginResponse, any>;
      if (isEmail) {
        response = await loginService(password, user);
      } else {
        response = await loginService(password, null, user);
      }
      dispatch({ type: AuthActions.loginSuccess, payload: response.data });
    } catch (error) {
      dispatch({ type: AuthActions.loginFailed, payload: handleError(error) });
    }
  };
};

export interface LogoutActionType {
  type: AuthActions.logout;
}
export const logoutActionCreator = (): LogoutActionType => {
  return { type: AuthActions.logout };
};

export interface RefreshTokenActionType {
  type:
    | AuthActions.refreshTokenSuccess
    | AuthActions.refreshTokenFailed
    | AuthActions.refreshTokenLoading
    | BrandActions.getStoreLoading;
  payload?: LoginResponse | RequestError;
}
export const refreshTokenActionCreator = (
  sessionId: string,
  storeId: string
) => {
  return async (
    dispatch: ThunkDispatch<
      RefreshTokenActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: AuthActions.refreshTokenLoading });
      const response = await refreshTokenService(sessionId, storeId);
      dispatch({
        type: AuthActions.refreshTokenSuccess,
        payload: response.data,
      });

      dispatch({ type: BrandActions.getStoreLoading });
      const otherResponse = await getStoreBy(storeId);
      dispatch({
        type: BrandActions.setCurrentStore,
        payload: otherResponse.data,
      });
    } catch (error) {
      dispatch({
        type: AuthActions.refreshTokenFailed,
        payload: handleError(error),
      });
    }
  };
};

export type AuthActionType =
  | LoginActionType
  | LogoutActionType
  | RefreshTokenActionType
  | PostCreateStoresActionType
  | SetCurrentStoreActionType
  | AcceptInvitationActionType;
