import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { ArrowLeft, ArrowRight } from "../../components/FiraIcons/index"
import './SliderBanner.scss'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'

// import { 
//   getVideoCollectionsById,
//   getVideoDataById, 
//   playFiraModal, 
//   firaLiveWidget
// } from 'firalibrary'

const SliderBanner = (  {
  // firaKey,
  firstImage="",
  secondImage="",
  thirdImage="",
  // firstVideoId,
  // secondVideoId,
  // thirdVideoId,
  // firstCollectionId,
  // secondCollectionId,
  // thirdCollectionId,
  // firstUrl,
  // secondUrl,
  // thirdUrl,
  primaryTextColor,
  secondaryTextColor,
  tertiaryTextColor,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  quaternaryColor,
  isMobileAttr=false,
  // env="DEV"
})=>{

  const banners = [
    {
      id:"1",
      name:'Evento 1',
      category:'Categoria 1',
      videoId:'',
      url:'',
      image: firstImage,
    },
    {
      id:"2",
      name:'Evento 2',
      category:'Categoria 2',
      videoId:'',
      url:'',
      image: secondImage,
    },
    {
      id:"3",
      name:'Evento 3',
      category:'Categoria 3',
      videoId:'',
      url:'',
      image: thirdImage,
    }
  ]
  // const mediaWidth = 790;
  // const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile]= useState(isMobileAttr)
  const [ bannersSet,setbannersSet] = useState(banners)

  // const getWindowSize=()=> {
  //   const { innerWidth } = window;
  //   return  innerWidth;
  // }

  // const handleResize=()=>{
  //   window.addEventListener("resize", ()=>setWindowSize(getWindowSize()));

  //   return () => {
  //     window.removeEventListener("resize",()=> setWindowSize(getWindowSize()));
  //   };
  // }

  // const handleMobile = () =>{
  //   if(windowSize < mediaWidth){
  //     setIsMobile(true)
  //   }else{
  //     setIsMobile(false)
  //   }
  // }

  // const getCollection = async (id) =>{
  //   try{
  //     const response = await getVideoCollectionsById(firaKey,id,env)
  //     return response
  //   }catch(err){
  //     console.error(err)
  //   }
  // }

  // const updateItem = (newItem)=>{
  //   const newList = bannersSet.map((item)=>{
  //     if(item.id === newItem.id){
  //       item.name=newItem.collectionName?newItem.collectionName:newItem.eventName;
  //       if(newItem.originEvent){
  //         item.videoId=newItem.originEvent;
  //       }
  //     }
  //     return item
  //   })
  //   setbannersSet(newList)
  // }

  // const getCollections = async () =>{
  //   if(firstCollectionId){
  //     let collection = await getCollection(firstCollectionId) 
  //     updateItem(collection)
  //   }
  //   if(secondCollectionId){
  //     let collection = await getCollection(secondCollectionId)
  //     updateItem(collection)
  //   }
  //   if(thirdCollectionId){
  //     let collection = await getCollection(thirdCollectionId)
  //     updateItem(collection)
  //   }
  // }

  // const getVideo = async (id)=>{
  //   try{
  //     const response = await getVideoDataById(id,env)
  //     return response
  //   }catch(err){
  //     console.error('error video',err)
  //   }
  // }

  // const getVideos = async ()=>{
  //   if(firstVideoId){
  //     let video = await getVideo(firstVideoId)
  //     updateItem(video)
  //   }
  //   if(secondVideoId){
  //     let video = await getVideo(secondVideoId)
  //     updateItem(video)
  //   }
  //   if(thirdVideoId){
  //     let video = await getVideo(thirdVideoId)
  //     updateItem(video)
  //   }
  // }
  // useEffect(() => {
  //   // if(firstVideoId || secondVideoId || thirdVideoId){
  //   //   firaLiveWidget(firaKey,env);
  //   // }

  //   handleMobile()
  //   // getVideos()
  //   // if(firstCollectionId || secondCollectionId || thirdCollectionId){
  //   //   getCollections()
  //   // }

  //   handleResize()
  // }, []);

  // useEffect(()=>{
  //   if(windowSize){
  //     handleMobile()
  //   }
  // },[windowSize])


return(<>
<div className='fira-slider-banner-wrapper'>
  <Swiper
  slidesPerView={1}
  spaceBetween={16}
  navigation={
    {
      prevEl: '.fira-slider-left-navigation-container',
      nextEl: '.fira-slider-right-navigation-container',
    }
  }
  pagination={
            { 
              // type:'custom',
              clickable: true, 
              el:'.custom-pagination',
              bulletClass:'fira-slider-banner-bullet swiper-pagination-bullets'
              }
            }
  modules={[Pagination, Navigation]}
  loop={true}
  centeredSlides={true}
  style={{
    "--swiper-theme-color": secondaryColor,
    'max-height':'559px'
  }}
  >
    <div className='fira-slider-left-navigation-container'> 
     { !isMobile &&
      <div style={{color:secondaryColor, background:tertiaryColor}} className='fira-slider-navigation-button'>
        <ArrowLeft />
      </div>}
    </div>
    {
      <><SwiperSlide key={banners[0].id}>
        <BannerItem
        id={banners[0].id}
        img={banners[0].image}
        category={banners[0].category}
        name={banners[0].name}
        url={banners[0].url}
        videoId={banners[0].videoId}
        categoryTextColor={secondaryTextColor}
        nameTextColor={primaryTextColor}
        buttonColor={primaryColor}
        buttonTextColor={tertiaryTextColor}
        />
      </SwiperSlide>
      <SwiperSlide key={banners[1].id}>
        <BannerItem
        id={banners[1].id}
        img={banners[1].image}
        category={banners[1].category}
        name={banners[1].name}
        url={banners[1].url}
        videoId={banners[1].videoId}
        categoryTextColor={secondaryTextColor}
        nameTextColor={primaryTextColor}
        buttonColor={primaryColor}
        buttonTextColor={tertiaryTextColor}
        />
      </SwiperSlide>
      <SwiperSlide key={banners[2].id}>
        <BannerItem
        id={banners[2].id}
        img={banners[2].image}
        category={banners[2].category}
        name={banners[2].name}
        url={banners[2].url}
        videoId={banners[2].videoId}
        categoryTextColor={secondaryTextColor}
        nameTextColor={primaryTextColor}
        buttonColor={primaryColor}
        buttonTextColor={tertiaryTextColor}
        />
      </SwiperSlide>
  </> 
    }

    <div className='fira-slider-right-navigation-container'>
      {!isMobile &&
      <div style={{color:secondaryColor, background:tertiaryColor}} className='fira-slider-navigation-button'>
        <ArrowRight />
      </div>}
    </div>
    <div className={`fira-slider-banner-pagination-container ${isMobile?'fira-slider-banner-pagination-mobile':''}`}>
      <div 
      className='custom-pagination fira-slider-banner-pagination'
      style={{background:tertiaryColor,'--fira-bullet-color':quaternaryColor}}></div>
    </div>
  </Swiper>
</div>

</>)
}


const BannerItem = ({
  // id,
  img,
  category,
  name,
  // url,
  // videoId,
  categoryTextColor,
  nameTextColor,
  buttonColor,
  buttonTextColor,
  // firaKey,
  // env
})=>{

  const handleClick=()=>{
    // if(url.length !== 0){
    //   window.open(url + '?fira-collection-id='+id)
    // }else{
    //   playFiraModal(firaKey,videoId,env)
    // }
    return;
  }

  return<div className='fira-slider-banner-element'>
    <div className='fira-slider-banner-inner-element' onClick={handleClick}>
      <img className='fira-slider-banner-image' src={img} alt={name}/>
      <div className='fira-slider-banner-text'>
        <div className='fira-slider-banner-category' style={{color:categoryTextColor}}>{category}</div>
        <div className='fira-slider-banner-name' style={{color:nameTextColor}}>{name}</div> 
        <button className='fira-slider-banner-button' style={{background:buttonColor, color:buttonTextColor}}>Play</button>
      </div>

    </div>

  </div>
}

export default SliderBanner