import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgErrorBubble = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M14.416 4.594a7.49 7.49 0 0 0-1.612-2.4 7.435 7.435 0 0 0-2.4-1.611A7.46 7.46 0 0 0 7.5 0h-.033a7.48 7.48 0 0 0-5.298 2.217 7.449 7.449 0 0 0-1.594 2.39 7.531 7.531 0 0 0 .23 6.275v2.544c0 .425.345.77.77.77H4.12A7.524 7.524 0 0 0 7.467 15h.036a7.501 7.501 0 0 0 2.89-.574 7.437 7.437 0 0 0 2.39-1.594 7.446 7.446 0 0 0 1.616-2.377A7.5 7.5 0 0 0 15 7.533a7.519 7.519 0 0 0-.584-2.94Z"
      fill="currentColor"
    />
    <path
      d="M7.5 5.16a.39.39 0 0 1 .39.39v2.34a.39.39 0 1 1-.78 0V5.55a.39.39 0 0 1 .39-.39Zm0 3.9a.39.39 0 1 0 0 .78.39.39 0 0 0 0-.78Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 3.6a3.9 3.9 0 1 0 0 7.8 3.9 3.9 0 0 0 0-7.8ZM4.38 7.5a3.12 3.12 0 1 0 6.24 0 3.12 3.12 0 0 0-6.24 0Z"
      fill="#fff"
    />
  </svg>
);

export default SvgErrorBubble;
