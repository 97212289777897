import React from "react";

function TikTok() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          fill="#000"
          d="M19.322 5.562a5.124 5.124 0 01-.443-.258 6.229 6.229 0 01-1.137-.966c-.85-.971-1.166-1.956-1.282-2.645h.004c-.097-.573-.057-.943-.05-.943h-3.865v14.943c0 .2 0 .399-.008.595 0 .024-.003.046-.004.073 0 .01 0 .022-.003.033v.009a3.28 3.28 0 01-1.65 2.604 3.226 3.226 0 01-1.6.422c-1.8 0-3.26-1.468-3.26-3.281 0-1.814 1.46-3.282 3.26-3.282.34 0 .68.054 1.004.16l.005-3.936a7.178 7.178 0 00-5.532 1.62 7.582 7.582 0 00-1.655 2.04c-.163.281-.78 1.412-.853 3.246-.047 1.04.266 2.12.415 2.565v.01c.093.262.457 1.158 1.049 1.913a7.856 7.856 0 001.674 1.58v-.01l.009.01c1.87 1.27 3.945 1.187 3.945 1.187.359-.015 1.562 0 2.928-.647 1.515-.718 2.377-1.787 2.377-1.787a7.43 7.43 0 001.296-2.153c.35-.92.466-2.022.466-2.462V8.273c.047.028.672.441.672.441s.9.577 2.303.952c1.006.267 2.363.324 2.363.324V6.153c-.475.052-1.44-.098-2.43-.59l.002-.001z"
        ></path>
      </g>
    </svg>
  );
}

export default TikTok;
