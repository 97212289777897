import styles from "./LandingCatalog.module.scss";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "../../../ToggleSwitch/ToggleSwitch";
import { useEffect, useState } from "react";
import ProductList from "../../../ProductList/ProductList";
import { LandingProduct } from "../../../../types/Landing";
import { useSelector } from "react-redux";
import { RootState } from "../../../../utils/types";
import {
  getLandingsProductsByStoreIdService,
  landingExistByNameService,
} from "../../../../services/landing";
import { getStoreProductsGlobalService } from "../../../../services/store";
import { handleSearch } from "../../../../utils/handleSearch";
import { useLandingConfig } from "../../../../providers/LandingConfigProvider";
import { useSettingsLanding } from "../../../../providers/SettingsLandingProvider";

const LandingCatalog = () => {
  const { existingLanding, landingConfig, setLandingConfig } =
    useLandingConfig();
  const { globalLandingConfig } = useSettingsLanding();
  const { brand } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const [showCatalog, setShowCatalog] = useState<boolean>(false);
  const [productList, setProductList] = useState<LandingProduct[]>([]);
  const [available, setAvailable] = useState<boolean>(false);
  const handleProductSelection = (product: LandingProduct) => {
    if (product.inLanding && countSelectedProducts() >= 8) {
      return;
    }

    const index = productList.findIndex((p) => p.id === product.id);
    const updatedProducts = [...productList];
    if (index !== -1) {
      updatedProducts[index] = {
        ...product,
        inLanding: !product.inLanding,
        selected: !product.inLanding,
      };
      setProductList(updatedProducts);
    }
  };

  const handleSelectAll = () => {
    const allSelected = productList.every((product) => product.inLanding);
    const updatedProducts = productList.map((product) => ({
      ...product,
      inLanding: !allSelected,
      selected: !allSelected,
    }));
    setProductList(updatedProducts);
  };

  const countSelectedProducts = () => {
    return productList.filter((product) => product.inLanding).length;
  };

  const getLandingProducts = async () => {
    try {
      let response; // existe landign, estoy en tienda
      if (brand.currentStore && existingLanding) {
        response = await getLandingsProductsByStoreIdService(
          brand.currentStore.id
        );
      } else {
        !brand.currentStore
          ? (response = await getStoreProductsGlobalService())
          : (response = await getLandingsProductsByStoreIdService(
              brand.currentStore.id
            ));
      }

      if (brand.currentStore && existingLanding) {
        setProductList(response.data);
      } else {
        const landingProductIds = new Set(
          globalLandingConfig?.products
            ?.filter((product) => product.inLanding)
            .map((item) => item.id)
        );

        const initialProducts = response.data.map(
          (product: LandingProduct) => ({
            ...product,
            inLanding: landingProductIds.has(product.id),
            selected: landingProductIds.has(product.id),
          })
        );

        setProductList(initialProducts);
      }
    } catch (error) {
      console.error("Error fetching landing products:", error);
    }
  };
  useEffect(() => {
    getLandingProducts();
  }, []); // Ejecutar solo una vez al inicio para obtener productos de aterrizaje

  useEffect(() => {
    if (existingLanding) {
      setShowCatalog(existingLanding.productCatalog);
    }
  }, [existingLanding]); // Actualizar solo si existingLanding cambia

  useEffect(() => {
    setLandingConfig({ ...landingConfig, products: productList });
  }, [productList]); // Actualizar solo si productList cambia

  const handleProductSearch = (value: string) => {
    // Filtrar productos en base a la búsqueda
    const filtered = handleSearch(productList, value);
    setProductList(filtered);
  };

  return (
    <div className={styles.LandingCatalogWrapper}>
      <div className={styles.LandingModule}>
        <div className={styles.LandingModule__Title}>
          {t("views.landing.catalog.moduleTitle")}
        </div>
        <div className={styles.ShoppingSwitch}>
          <label>{t("views.landing.catalog.toggleLabel")}</label>
          <ToggleSwitch
            hideTags={true}
            checked={showCatalog}
            handleChecked={() => setShowCatalog(!showCatalog)}
          />
        </div>
      </div>
      <div className={styles.LandingModule}>
        <div className={styles.LandingModule__Title}>
          {t("views.landing.catalog.products")} ({countSelectedProducts()})
        </div>
        <p>{t("views.landing.catalog.desc")}</p>
      </div>
      <div className={styles.LandingModule}>
        <ProductList
          products={productList}
          onSelect={handleProductSelection}
          onSearch={handleProductSearch}
          selectAllAvailable={true}
          onSelectAll={handleSelectAll}
          currency={brand.currentStore?.regionalConfiguration.currency || "USD"}
          mode={"catalog"}
        />
      </div>
    </div>
  );
};

export default LandingCatalog;
