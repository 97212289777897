import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgUserCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.921 14.74A8.99 8.99 0 0 0 12.055.535 9.007 9.007 0 0 0 1.01 4.85 8.987 8.987 0 0 0 0 9.001c0 2.099.74 4.131 2.091 5.74l-.012.01c.045.055.096.1.142.154.058.066.12.129.18.193.18.195.366.383.56.56.06.053.12.103.18.155.206.177.418.346.637.502.029.02.054.045.083.065v-.008a8.948 8.948 0 0 0 10.293 0v.008c.028-.02.053-.045.082-.065.219-.157.431-.325.637-.502.06-.052.12-.102.18-.156.194-.177.38-.364.56-.559.06-.064.121-.127.18-.193.046-.053.098-.1.143-.154l-.015-.01ZM9.006 3.859a2.897 2.897 0 0 1 2.675 1.786 2.89 2.89 0 0 1-2.11 3.944 2.897 2.897 0 0 1-3.46-2.837 2.891 2.891 0 0 1 2.895-2.893ZM3.864 14.741a3.21 3.21 0 0 1 3.212-3.17h3.86a3.215 3.215 0 0 1 3.213 3.17 7.685 7.685 0 0 1-10.285 0Z"
      fill="#000"
    />
  </svg>
);

export default SvgUserCircle;
