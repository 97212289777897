import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgReplace = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m13.534 12.375-4.49 3.393a.66.66 0 0 1-.736.05.766.766 0 0 1-.39-.665v-1.897l-6.337-.432c-.5-.034-.923-.466-.954-1.015-.032-.599.39-1.114.954-1.148l6.336-.416V8.35c0-.283.157-.549.391-.665a.66.66 0 0 1 .736.05l4.49 3.393c.188.15.297.366.297.616s-.11.482-.297.632ZM12.25 5.321l-6.336.416v1.897a.746.746 0 0 1-.391.665.66.66 0 0 1-.736-.05L.297 4.856A.773.773 0 0 1 0 4.24c0-.25.11-.466.297-.615L4.787.23a.66.66 0 0 1 .736-.05.766.766 0 0 1 .39.666v1.896l6.337.433c.5.033.923.465.954 1.014.016.582-.406 1.098-.954 1.131Z"
      fill="#000"
    />
  </svg>
);

export default SvgReplace;
