import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgNewsFill = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.35 7.333H16.65c-.047-1.553-.22-2.48-.861-3.121-.879-.879-2.293-.879-5.122-.879H9.333c-2.828 0-4.243 0-5.121.879-.642.641-.815 1.568-.862 3.121Zm13.316 2H3.333v1.334c0 2.828 0 4.242.879 5.121.878.879 2.293.879 5.121.879h1.333c2.829 0 4.243 0 5.122-.879.878-.879.878-2.293.878-5.121V9.333Z"
      fill="#000"
    />
  </svg>
);

export default SvgNewsFill;
