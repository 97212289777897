export const dateFormat = (date, isMobile) => {
  const fullDate = new Date(date).toLocaleDateString("es-es", {
    weekday: !isMobile ? "long" : "short",
    month: "long",
    day: "numeric",
  });
  return fullDate.replace(",", "").replace("de", "");
};

export const hourFormat = (d) => {
  const date = new Date(d);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, "0");
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
