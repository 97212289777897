import { FiraStep } from "../components/FiraStepper/FiraStepper";

export const handleActiveRoutes = (steps: FiraStep[], keys: Array<number>) => {
  return steps.map((step, index) => {
    if (index >= keys[0] && index <= keys[1]) {
      return { ...step, active: true };
    } else {
      return { ...step, active: false };
    }
  });
};

export const inactivateAllSteps = (steps: FiraStep[]) => {
  return steps.map((step, index) => {
    return { ...step, active: false };
  });
};

export const limiterMax = (steps: FiraStep[], current: number) => {
  return steps.length - 1 === current;
};

export const limiterMin = (current: number) => {
  return current === 0;
};
