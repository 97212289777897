import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgMuted = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      opacity={0.3}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.756 5.49h-1.64a1.005 1.005 0 0 0-1.003 1v6a.998.998 0 0 0 1.004 1H6.02l5.312 4.332a.503.503 0 0 0 .82-.387v-4.158L3.756 5.49Zm12.901 7.982-1.47-1.362a3.992 3.992 0 0 0-.906-6.012l1.444-1.439a5.973 5.973 0 0 1 2.45 4.831 5.965 5.965 0 0 1-1.518 3.982Zm3.675 3.407-1.469-1.361a8.975 8.975 0 0 0-.993-12.996l1.425-1.42a10.98 10.98 0 0 1 2.878 3.765 10.943 10.943 0 0 1 1.02 4.623c0 2.845-1.083 5.437-2.861 7.39Zm-8.179-7.583L7.1 4.61l4.233-3.452a.502.502 0 0 1 .82.387v7.75Z"
      fill="#fff"
    />
    <path
      opacity={0.3}
      fill="#fff"
      d="M0 1.309 1.214 0l20.225 18.753-1.214 1.309z"
    />
  </svg>
);

export default SvgMuted;
