import { useTranslation } from "react-i18next";
import ModalLayout from "../../layout/ModalLayout/ModalLayout";
import bigSuccess from "../../assets/svg/big-success.svg";
import "./SuccessMessage.scss";
import ReactDOM from "react-dom";
import { useClickOutside } from "../../utils/useClickOutside";

interface MessageSuccessProps {
  edited: boolean;
  eventName: string;
  date?: string;
  onClose(): void;
  isOpen: boolean;
}
const SuccessMessage = ({
  edited,
  eventName,
  date,
  onClose,
  isOpen,
}: MessageSuccessProps) => {
  const { t } = useTranslation();
  const domNode = useClickOutside(() => onClose());
  const cad = t("modals.success_message.description").replace(
    "(xxxx)",
    `${eventName}`
  );

  const handleClose = () => {
    onClose();
  };
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalLayout>
      <div className="MessageWrapperSuccess" ref={domNode}>
        <img className="ImagePreview" src={bigSuccess} alt="avatar" />
        <h4 className="Text-success">
          {edited
            ? t("modals.success_message.edited")
            : t("modals.success_message.title")}
        </h4>
        <p className="body-14-text Text-detail ">
          {t("modals.success_message.description1")}
          {eventName}
          {t("modals.success_message.description2")}
          {date + "."}
        </p>
      </div>
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};

export default SuccessMessage;
