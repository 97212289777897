import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgAdminIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 29 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M22.39 8c0 4.418-3.532 8-7.89 8-4.357 0-7.889-3.582-7.889-8s3.532-8 7.89-8c4.357 0 7.889 3.582 7.889 8ZM1.36 26.641C2.665 21.054 8.134 18 13.796 18h1.41c5.663 0 11.131 3.054 12.435 8.641a26.04 26.04 0 0 1 .566 3.362c.108 1.1-.782 1.997-1.872 1.997H2.667c-1.09 0-1.98-.898-1.872-1.997.113-1.15.313-2.28.566-3.362Z"
      fill="#000"
    />
    <path
      d="M14.892 21.214a.5.5 0 0 1-.79 0l-1.145-1.477a.5.5 0 0 1 .395-.806h2.29a.5.5 0 0 1 .395.806l-1.145 1.477Z"
      fill="#fff"
    />
    <path
      d="M14.017 21.72c.14-.48.82-.48.96 0l1.5 5.158a.5.5 0 0 1-.48.64h-2.999a.5.5 0 0 1-.48-.64l1.499-5.157Z"
      fill="#fff"
    />
  </svg>
);

export default SvgAdminIcon;
