import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgComputer = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.4 16v-.889l1.8-1.778H1.8c-.495 0-.918-.174-1.27-.521A1.702 1.702 0 0 1 0 11.556V1.778C0 1.288.177.87.53.522A1.743 1.743 0 0 1 1.8 0h14.4c.495 0 .92.174 1.272.522.352.348.528.767.528 1.256v9.778c0 .488-.176.907-.528 1.256a1.747 1.747 0 0 1-1.271.521h-5.4l1.8 1.778V16H5.4ZM1.8 8.889h14.4V1.778H1.8v7.11Zm0 0V1.778v7.11Z"
      fill="#000"
    />
  </svg>
);

export default SvgComputer;
