import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgUsers = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15 14a5 5 0 0 1 5 5v6H10v-6a5 5 0 0 1 5-5Zm-6.712 3.006a6.984 6.984 0 0 0-.28 1.65L8 19v6H5v-4.5a3.5 3.5 0 0 1 3.119-3.48l.17-.014h-.001Zm13.424 0A3.501 3.501 0 0 1 25 20.5V25h-3v-6c0-.693-.1-1.362-.288-1.994ZM8.5 11a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm13 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM15 5a4 4 0 1 1 0 8 4 4 0 0 1 0-8Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUsers;
