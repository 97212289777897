import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
    currentColor?: string;
    height?: string;
}

const SvgMobileScreenChatMockupVariant = ({
                                       title,
                                       titleId,
                                       currentColor,
                                       height,
                                       ...props
                                   }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="92"
        height={height}
        fill="none"
        viewBox="0 0 92 200"
    >
        <g>
            <rect
                width="17.908"
                height="20.637"
                x="0.75"
                y="-0.75"
                fill="#F7F8F9"
                stroke={currentColor}
                strokeWidth="1.5"
                rx="3.25"
                transform="matrix(1 0 0 -1 5.293 52.7)"
            ></rect>
            <rect
                width="89.323"
                height="199"
                x="1.314"
                y="0.5"
                stroke="#D5DDE0"
                rx="9.5"
            ></rect>
            <rect
                width="9.704"
                height="11.45"
                fill={currentColor}
                rx="3"
                transform="matrix(1 0 0 -1 76.955 182.441)"
            ></rect>
            <rect
                width="9.704"
                height="9.924"
                fill={currentColor}
                rx="3"
                transform="matrix(1 0 0 -1 76.955 196.945)"
            ></rect>
            <rect
                width="67.182"
                height="9.924"
                fill="#F7F8F9"
                rx="2"
                transform="matrix(1 0 0 -1 6.04 196.184)"
            ></rect>
            <rect
                width="9.704"
                height="10.687"
                fill={currentColor}
                rx="3"
                transform="matrix(1 0 0 -1 76.955 166.412)"
            ></rect>
            <rect
                width="9.704"
                height="9.924"
                fill={currentColor}
                rx="3"
                transform="matrix(1 0 0 -1 76.955 151.143)"
            ></rect>
            <rect
                width="9.704"
                height="10.687"
                fill={currentColor}
                rx="3"
                transform="matrix(1 0 0 -1 76.955 136.641)"
            ></rect>
            <rect
                width="12.69"
                height="3.817"
                fill="#F7F8F9"
                rx="1"
                transform="matrix(1 0 0 -1 9.025 25.19)"
            ></rect>
            <rect
                width="12.69"
                height="3.817"
                fill="#F7F8F9"
                rx="1"
                transform="matrix(1 0 0 -1 73.967 15.266)"
            ></rect>
            <rect
                width="11.197"
                height="10.687"
                fill="#F7F8F9"
                rx="5.344"
                transform="matrix(1 0 0 -1 9.771 18.32)"
            ></rect>
            <rect
                width="65.689"
                height="9.924"
                fill="#F7F8F9"
                rx="2"
                transform="matrix(1 0 0 -1 7.533 161.068)"
            ></rect>
            <rect
                width="65.689"
                height="9.16"
                fill="#F7F8F9"
                rx="2"
                transform="matrix(1 0 0 -1 7.533 141.221)"
            ></rect>
            <g>
                <rect
                    width="65.53"
                    height="8.831"
                    fill="#F7F8F9"
                    rx="2"
                    transform="matrix(1 0 0 -1 7.398 180.26)"
                ></rect>
                <rect
                    width="26.212"
                    height="4.675"
                    fill={currentColor}
                    rx="1"
                    transform="matrix(1 0 0 -1 7.398 170.393)"
                ></rect>
                <rect
                    width="13.106"
                    height="4.675"
                    fill={currentColor}
                    rx="1"
                    transform="matrix(1 0 0 -1 59.822 170.393)"
                ></rect>
            </g>
            <rect
                width="26.126"
                height="4.58"
                fill="#F7F8F9"
                rx="2"
                transform="matrix(1 0 0 -1 7.533 150.381)"
            ></rect>
            <rect
                width="26.126"
                height="4.58"
                fill="#F7F8F9"
                rx="2"
                transform="matrix(1 0 0 -1 7.533 130.535)"
            ></rect>
        </g>
    </svg>
);

export default SvgMobileScreenChatMockupVariant;
