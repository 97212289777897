import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPause = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect x={0.35} y={0.3} width={2.1} height={8.4} rx={1} fill="#F7F8F9" />
    <rect x={4.55} y={0.3} width={2.1} height={8.4} rx={1} fill="#F7F8F9" />
  </svg>
);

export default SvgPause;
