import ReactDOM from "react-dom";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import Button, { ButtonStyle } from "../../Button/Button";
import styles from "./AddStreamProducts.module.scss";
import noImageStoreSVG from "../../../assets/svg/no-image-store.svg";
import Input from "../../Input/Input";
import { MagnifyingGlass } from "../../FiraIcons";
import { ChangeEvent, useEffect, useState } from "react";
import Checkbox from "../../Checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { getStoreProductsActionCreator } from "../../../store/Products/ProductsActions";
import {
  FiraProduct,
  ResponseSimpleProduct,
  RootState,
} from "../../../utils/types";
import {
  AddCurrentEventProductsActionCreator,
  cleanMessageActionCreator,
  GetCurrentEventProductsActionCreator,
  GetInactiveEventProductsActionCreator,
  ReactiveCurrentEventProductsActionCreator,
} from "../../../store/Event/EventActions";
import { useTranslation } from "react-i18next";
import currencyFormat from "../../../utils/currencyFormat";
import PopUpAlert, { PopUpAlertTypes } from "../../PopUpAlert/PopUpAlert";

interface AddStreamProductsProps {
  isOpen: boolean;
  onClose(): void;
}

let timerID: NodeJS.Timeout;

const AddStreamProducts = ({
  isOpen = false,
  onClose,
}: AddStreamProductsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { event, products, brand } = useSelector((state: RootState) => state);
  const [filteredList, setFilteredList] = useState<FiraProduct[]>([]);
  const [filterValue, setFilterValue] = useState("");
  const [productsList, setProductsList] = useState<FiraProduct[]>(filteredList);
  const [activeProductsSelected, setActiveProductsSelected] = useState<
    ResponseSimpleProduct[]
  >([]);
  const [inactiveProductsSelected, setInactiveProductsSelected] = useState<
    ResponseSimpleProduct[]
  >([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const handleProductsFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);

    const parsedKeyword = keyword
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");
    if (keyword !== "") {
      setProductsList(
        filteredList.filter((p) => {
          return String(p.name)
            .replace("null", "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .includes(parsedKeyword);
        })
      );
    } else {
      setProductsList(filteredList);
    }
  };

  const checkboxHandle = (product: FiraProduct) => {
    if (event.currentEvent) {
      const simpleProduct: ResponseSimpleProduct = {
        id: "",
        broadcastingId: event.currentEvent.id,
        product: {
          id: product.id,
          name: product.name,
          directUrl: product.directUrl,
          images: product.images,
          price1: Number(product.price1),
          price2: Number(product.price2),
          price3: Number(product.price3),
        },
        onPopup: false,
      };
      const inactiveSelected = event.inactiveEventProducts.filter((p) => {
        return p.product.id === simpleProduct.product.id;
      });
      if (selectedIds.includes(product.id)) {
        inactiveSelected.length !== 0 &&
          setInactiveProductsSelected((currentProducts) => [
            ...currentProducts.filter((p) => product.id !== p.product.id),
          ]);
        inactiveSelected.length == 0 &&
          setActiveProductsSelected((currentProducts) => [
            ...currentProducts.filter((p) => simpleProduct.id !== p.id),
          ]);
        setSelectedIds((currentIds) => [
          ...currentIds.filter((p) => product.id !== p),
        ]);
        return;
      }
      inactiveSelected.length !== 0 &&
        setInactiveProductsSelected([
          ...inactiveProductsSelected,
          inactiveSelected[0],
        ]);

      inactiveSelected.length == 0 &&
        setActiveProductsSelected((currentProducts) => [
          ...currentProducts,
          simpleProduct,
        ]);
      setSelectedIds((currentProducts) => [...currentProducts, product.id]);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleShowAlert = () => {
    setShowAlert(true);
    timerID = setTimeout(() => {
      setShowAlert(false);
      dispatch(cleanMessageActionCreator());
    }, 5000);
  };

  const handleCloseAlert = () => {
    if (showAlert) {
      clearTimeout(timerID);
      setShowAlert(false);
      dispatch(cleanMessageActionCreator());
    }
  };

  const handleAddProducts = () => {
    let activeIds: string[] = [];
    let inactiveIds: string[] = [];
    inactiveProductsSelected.map((product) => {
      inactiveIds = [...inactiveIds, product.id];
    });
    activeProductsSelected.map((product) => {
      activeIds = [...activeIds, product.product.id];
    });
    if (event.currentEvent) {
      const addProductsForm = {
        broadcastingId: event.currentEvent.id,
        broadcastingProduct: activeIds,
      };
      const reactiveProductsForm = {
        broadcastingId: event.currentEvent?.id,
        broadcastingProduct: inactiveIds,
      };
      activeIds.length != 0 &&
        dispatch(
          AddCurrentEventProductsActionCreator(
            addProductsForm,
            event.currentEvent.status,
            activeProductsSelected
          )
        );

      inactiveIds.length != 0 &&
        dispatch(
          ReactiveCurrentEventProductsActionCreator(reactiveProductsForm)
        );
    }
    if (activeIds.length == 0 && inactiveIds.length == 0) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  useEffect(() => {
    if (event.currentEvent) {
      if (filteredList.length == 0) {
        dispatch(getStoreProductsActionCreator(event.currentEvent.storeId));
      }
      if (event.currentEvent.status === "STARTED") {
        dispatch(GetInactiveEventProductsActionCreator(event.currentEvent.id));
      }
    }
  }, []);

  useEffect(() => {
    setFilteredList(
      products.products.filter((current) => {
        return !event.currentEventProducts.find((item) => {
          return item.product.name == current.name;
        });
      })
    );
  }, [products.products]);

  useEffect(() => {
    setProductsList(filteredList);
  }, [filteredList]);

  useEffect(() => {
    setDisableButton(selectedIds.length === 0);
  }, [selectedIds]);

  useEffect(() => {
    if (!event.isLoading) {
      if (event.addProductSuccess) {
        event.currentEvent &&
          dispatch(
            GetCurrentEventProductsActionCreator(
              event.currentEvent.id,
              event.currentEvent.status
            )
          );
        onClose();
      }
      if (event.error) {
        handleShowAlert();
      }
    }
  }, [event.isLoading]);

  const header = [
    { title: "", width: "", center: false },
    {
      title: t("modals.eventDetail.addProducts.table.name"),
      width: "280px",
      center: false,
    },
    {
      title: t("modals.eventDetail.addProducts.table.sku"),
      width: "106px",
      center: true,
    },
    {
      title: t("modals.eventDetail.addProducts.table.inventory"),
      width: "116px",
      center: true,
    },
    {
      title: t("modals.eventDetail.addProducts.table.price"),
      width: "",
      center: true,
    },
    {
      title: t("modals.eventDetail.addProducts.table.discount"),
      width: "",
      center: true,
    },
    { title: "", width: "", center: false },
  ];
  const TableHead = (
    <tr className={styles.headRow}>
      {header.map((header, index) => {
        return (
          <th
            key={index}
            className={`${styles.headCell} ${
              header.center ? styles.headCenter : ""
            }`}
            style={{ width: header.width }}
          >
            <p className="body-14-text ">{header.title}</p>
          </th>
        );
      })}
    </tr>
  );

  const TableBody = productsList.map((product, index) => {
    return (
      <tr className={styles.tableRow} key={index}>
        <td className={`${styles.tableCell} ${styles.left}`}>
          <div className={styles.tableImage}>
            {product.images[0] ? (
              <img
                src={
                  product.images.length != 0
                    ? product.images[0]
                    : noImageStoreSVG
                }
              />
            ) : (
              <img src={noImageStoreSVG} />
            )}
          </div>
        </td>
        <td className={`${styles.tableCell}  lead-text`}>
          <p className={styles.Ellipsis}>{product.name}</p>
        </td>
        <td className={`${styles.tableCell} ${styles.center}  lead-text`}>
          {product.sku}
        </td>
        <td className={`${styles.tableCell} ${styles.center} lead-text`}>
          {product.quantity}
        </td>
        <td className={`${styles.tableCell} ${styles.center} lead-text`}>
          <p className={styles.Ellipsis}>
            {Number(product.price1) != 0
              ? currencyFormat(
                  parseFloat(product.price1),
                  brand.currentStore?.regionalConfiguration.currency
                )
              : "-"}
          </p>
        </td>
        <td className={`${styles.tableCell} ${styles.center} lead-text`}>
          <p className={styles.Ellipsis}>
            {Number(product.price2) != 0
              ? currencyFormat(
                  parseFloat(product.price2),
                  brand.currentStore?.regionalConfiguration.currency
                )
              : "-"}
          </p>
        </td>
        <td className={`${styles.tableCell} ${styles.center} ${styles.right}`}>
          <Checkbox
            clickHandler={() => checkboxHandle(product)}
            isActive={selectedIds.includes(product.id)}
          />
        </td>
      </tr>
    );
  });

  return ReactDOM.createPortal(
    <ModalLayout>
      {showAlert && (
        <PopUpAlert
          type={PopUpAlertTypes.error}
          title={t("modals.eventDetail.addProducts.messages.error_generic")}
          width={"fit-content"}
          handleCloseIcon={handleCloseAlert}
        />
      )}
      <div className={styles.addStreamProductsWrapper}>
        <div className={styles.header}>
          <h6 className={styles.title}>
            {t("modals.eventDetail.addProducts.title")}
          </h6>
          <h6>({selectedIds.length})</h6>
        </div>
        <div className={styles.content}>
          <div className={styles.inputContainer}>
            <Input
              height="44px"
              width="848px"
              icon={<MagnifyingGlass className="SearchIcon" />}
              placeholder={t("modals.eventDetail.addProducts.filter")}
              value={filterValue}
              onChange={handleProductsFiltering}
            />
          </div>
          <div className={styles.tableWrapper}>
            <table>
              <thead>{TableHead}</thead>
              <tbody>{TableBody}</tbody>
            </table>
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button
            width="122px"
            height="30px"
            buttonStyle={ButtonStyle.border}
            clickHandler={handleCancel}
          >
            <p>{t("modals.eventDetail.addProducts.cancel")}</p>
          </Button>
          <Button
            width="122px"
            height="30px"
            isDisabled={event.isLoading || disableButton}
            clickHandler={handleAddProducts}
          >
            <p>{t("modals.eventDetail.addProducts.add")}</p>
          </Button>
        </div>
      </div>
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};

export default AddStreamProducts;
