import { useEffect, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from "recharts";
import { DashboardEvent } from "../../utils/types";

import styles from "./AudienceResumeWidget.module.scss";

const DEFAULT_DIGIT_SIZE = 55;
const MIN_DIGIT_SIZE = 16;

interface props {
  listEvent: DashboardEvent[];
  height?: number;
}
const AudienceResumeWidget = ({ listEvent, height = 147 }: props) => {
  const [totalAudience, setTotalAudience] = useState(0);
  const [fontSize, setFontSize] = useState(DEFAULT_DIGIT_SIZE);
  const [events, setEvents] = useState<DashboardEvent[]>(listEvent); // TODO: check for other solution
  const { t } = useTranslation();

  useEffect(() => {
    let sum = 0;
    listEvent.map((item) => (sum += item.connections));

    listEvent.sort((a, b) =>
      new Date(a.starDate).getTime() > new Date(b.starDate).getTime() ? 1 : -1
    );

    setTotalAudience(sum);
    setEvents(listEvent);
  }, [listEvent, setTotalAudience, setEvents]);

  useEffect(() => {
    const totalDigits = totalAudience.toString().length - 1;
    const digitSize = DEFAULT_DIGIT_SIZE - totalDigits * 4;
    if (digitSize >= MIN_DIGIT_SIZE && totalDigits > 3) {
      setFontSize(digitSize);
    } else if (digitSize <= MIN_DIGIT_SIZE) {
      setFontSize(MIN_DIGIT_SIZE);
    }
  }, [totalAudience]);

  return (
    <div className={styles.ContainerAudienceResume} style={{ height }}>
      <div className={styles.boxContainer}>
        <div className={styles.textContent}>
          <span className={styles.SubtitleValue}>
            {t("widgets.audienceResume.audiencia")}
          </span>
          <label className={styles.TextValue} style={{ fontSize }}>
            {totalAudience}
          </label>
        </div>

        <ResponsiveContainer height={"70%"}>
          <LineChart cy={"connections"} cx={"startDate"} data={events}>
            <Line
              type="monotone"
              dataKey="connections"
              stroke="#ffde07"
              strokeWidth={1.5}
              dot={false}
            />
            <Tooltip content={CustomTooltip} cursor={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const CustomTooltip: FC<any> = ({ active, payload, label }) => {
  const { CustomTooltipWrapper, TooltipLabel, SubLabel } = styles;

  if (active && payload && payload.length) {
    const eventHour = new Date(payload[0].payload.starDate);
    const audience = payload[0].payload.connections;
    return (
      <div className={CustomTooltipWrapper}>
        <p className={TooltipLabel}>{`${audience}`}</p>
        <p className={SubLabel}>{eventHour.toLocaleDateString()}</p>
        <p className={SubLabel}>
          {eventHour.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </p>
      </div>
    );
  }

  return null;
};

export default AudienceResumeWidget;
