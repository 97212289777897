import React from "react";

interface Column<T> {
  key: keyof T;
  title: React.ReactNode;
  sortable?: boolean;
  render?: (value: T[keyof T], row: T) => React.ReactNode;
  accessor?: (row: T) => T[keyof T];
}

interface GridTableProps<T extends object> {
  columns: Array<Column<T>>;
  data: Array<T>;
  showHeader?: boolean;
  onRowClick?: (row: T) => void;
  emptyComponent?: React.ReactNode;
}

enum SortDirection {
  ASCENDING = "ascending",
  DESCENDING = "descending",
}

const multilineEllipsisStyle: React.CSSProperties = {
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
};

const headerStyles: React.CSSProperties = {
  position: "sticky",
  top: 0,
  backgroundColor: "#F7F8F9",
  zIndex: 1,
  textAlign: "left",
  fontSize: "14px",
  fontWeight: "400",
  padding: "8px",
  cursor: "pointer",
  borderRadius: 4,
};

const rowStyles: React.CSSProperties = {
  cursor: "pointer",
  padding: "0px 8px",
  backgroundColor: "#fff",
};

const cellStyles: React.CSSProperties = {
  padding: "20px 0px",
  borderBottom: "1px solid #D5DDE0",
  fontSize: "12px",
  fontWeight: "400",
};

function FiraTable<T extends object>({
  columns,
  data,
  showHeader = true,
  onRowClick,
  emptyComponent,
}: GridTableProps<T>): JSX.Element {
  const [sortConfig, setSortConfig] = React.useState<{
    key: keyof T;
    direction: SortDirection;
  } | null>(null);

  const sortedData = React.useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig !== null) {
      const { key, direction } = sortConfig;
      const column = columns.find((col) => col.key === key);

      sortableItems.sort((a, b) => {
        const aValue = column?.accessor ? column.accessor(a) : a[key];
        const bValue = column?.accessor ? column.accessor(b) : b[key];

        const aValueAsAny = aValue as any;
        const bValueAsAny = bValue as any;

        const aIsNullOrEmpty =
          aValueAsAny === null ||
          aValueAsAny === undefined ||
          aValueAsAny === 0;
        const bIsNullOrEmpty =
          bValueAsAny === null ||
          bValueAsAny === undefined ||
          bValueAsAny === 0;

        if (aIsNullOrEmpty && !bIsNullOrEmpty) return 1;
        if (!aIsNullOrEmpty && bIsNullOrEmpty) return -1;
        if (aIsNullOrEmpty && bIsNullOrEmpty) return 0;

        if (aValueAsAny < bValueAsAny) {
          return direction === SortDirection.ASCENDING ? -1 : 1;
        }
        if (aValueAsAny > bValueAsAny) {
          return direction === SortDirection.ASCENDING ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig, columns]);

  const requestSort = (key: keyof T) => {
    let direction = SortDirection.ASCENDING;
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === SortDirection.ASCENDING
    ) {
      direction = SortDirection.DESCENDING;
    }
    setSortConfig({ key, direction });
  };

  const gridTemplateColumns = `repeat(${columns.length}, minmax(0, 1fr))`;

  return (
    <div
      style={{
        overflowX: "auto",
        position: "relative",
      }}
    >
      {data.length === 0 ? (
        emptyComponent
      ) : (
        <div
          style={{ display: "grid", borderCollapse: "collapse", width: "100%" }}
        >
          {showHeader && (
            <div
              style={{ ...headerStyles, display: "grid", gridTemplateColumns }}
            >
              {columns.map((column, index) => (
                <div
                  key={index}
                  onClick={() =>
                    column.sortable ? requestSort(column.key) : null
                  }
                >
                  {column.title}
                  {sortConfig &&
                    sortConfig.key === column.key &&
                    (sortConfig.direction === SortDirection.ASCENDING
                      ? " 🔼"
                      : " 🔽")}
                </div>
              ))}
            </div>
          )}
          {sortedData.map((item, rowIndex) => (
            <div
              key={rowIndex}
              style={{ ...rowStyles, display: "grid", gridTemplateColumns }}
              onClick={() => onRowClick && onRowClick(item)}
            >
              {columns.map((column, colIndex) => {
                const value = column.accessor
                  ? column.accessor(item)
                  : item[column.key];
                return (
                  <div
                    key={colIndex}
                    style={{ ...cellStyles, ...multilineEllipsisStyle }}
                  >
                    {column.render ? column.render(value, item) : value}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default FiraTable;
