import * as React from "react";
const NoImageStore = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="#F7F8F9" d="M0 0h84v84H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.781 37.023a.202.202 0 0 0 .179.112c2.142 0 3.891 1.829 3.891 4.068v5.542c0 2.24-1.75 4.068-3.891 4.068H36.89c-2.15 0-3.891-1.829-3.891-4.068v-5.542c0-2.24 1.74-4.068 3.892-4.068a.175.175 0 0 0 .17-.112l.053-.112.094-.208c.228-.503.481-1.06.638-1.387.41-.84 1.106-1.307 1.972-1.316h4.204c.866.01 1.571.476 1.982 1.316a77.141 77.141 0 0 1 .687 1.502l.09.205Zm-.651 3.583c0 .466.357.84.803.84a.826.826 0 0 0 .812-.84c0-.467-.366-.85-.812-.85-.446 0-.803.383-.803.85Zm-5.749 1.446a2.107 2.107 0 0 1 3.08-.01c.41.43.633 1 .633 1.606-.009 1.25-.973 2.267-2.169 2.267-.58 0-1.124-.233-1.535-.663a2.304 2.304 0 0 1-.634-1.604v-.01a2.244 2.244 0 0 1 .625-1.586Zm4.017 4.19a3.414 3.414 0 0 1-2.473 1.072c-.937 0-1.811-.382-2.48-1.073a3.751 3.751 0 0 1-1.027-2.593 3.697 3.697 0 0 1 1.017-2.585 3.423 3.423 0 0 1 2.49-1.082c.938 0 1.821.382 2.482 1.073.66.7 1.026 1.623 1.026 2.594a3.785 3.785 0 0 1-1.035 2.593Z"
      fill="currentColor"
    />
  </svg>
);
export default NoImageStore;
