import { ChangeEvent, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../Button/Button";
import { ArrowRight } from "../FiraIcons";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import "./RegisterForm.scss";
import { RootState, UserRegisterForm } from "../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import {
  registerUserActionCreator,
  resetRegisterActionCreator,
  userActivationExist,
} from "../../store/Register/RegisterActions";
import { useTranslation } from "react-i18next";

const RegisterForm = () => {
  const { t } = useTranslation();
  const registration = useSelector((state: RootState) => state.registration);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState<string>(searchParams.get("code") || "");
  const [storeName, setStoreName] = useState<string | null>(
    searchParams.get("referer") || null
  );
  const [exist, setExist] = useState<string>(searchParams.get("exist") || "");

  const initialState = {
    firstName: "",
    lastName: "",
    email: searchParams.get("user") || "",
    username: "",
    password: "",
    storeName: searchParams.get("referer") || "",
  };

  const [form, setForm] = useState<UserRegisterForm>(initialState);
  const [emailError, setEmailError] = useState<boolean>();
  const [passwordError, setPasswordError] = useState<boolean>();
  const [password, setPassword] = useState({ value: "", dirty: false });
  const [passwordRepeat, setPasswordRepeat] = useState({
    value: "",
    dirty: false,
  });
  const [passwordRepeatError, setPasswordRepeatError] = useState<boolean>();
  const [checkState, setCheckState] = useState<boolean>(false);
  const [formValidated, setFormValidated] = useState<boolean>(false);
  const [inputUser, setInputUser] = useState<boolean>();
  const [showRegexError, setShowRegexError] = useState<boolean>(false);

  const regexError = (
    <span className={`lead-8-text errorText ${showRegexError ? "" : "none"}`}>
      {t("auth.register.message")}
    </span>
  );
  //handle inputs change
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    const { name, value } = e.target;

    switch (name) {
      case "username":
        setInputUser(value.length === 0);
        break;
    }
    if (name === "email") {
      const validated = regex.test(value);
      setEmailError(!validated);
    }

    if (passwordRepeatError === undefined) {
      setPasswordError(undefined);
    }

    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //handle change on password repeat input
  const handleChangePasswordRepeat = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPasswordRepeat({ value, dirty: true });
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      ["password"]: value,
    }));
    setPassword({ value, dirty: true });
  };

  //handle click of checkbox
  const handleClick = () => {
    setCheckState(checkState === false ? true : false);
  };

  //handle button click
  const handleRegisterSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(registerUserActionCreator(form));
  };

  const hasCode = () => {
    if (code) {
      setForm({ ...form, code: { code: code } });
    } else {
      return;
    }
  };

  const hasStoreName = () => {
    if (storeName) {
      setForm({ ...form, storeName: storeName });
    } else {
      setForm({ ...form, storeName: null });
      return;
    }
  };

  if (!searchParams.get("user") && !searchParams.get("referer")) {
    navigate("/");
  }

  //check if all the form inputs are validated
  //check every change and check the form validation
  useEffect(() => {
    if (
      form.firstName !== "" &&
      form.lastName !== "" &&
      form.email !== "" &&
      form.username.length !== 0 &&
      emailError !== true &&
      inputUser !== true &&
      passwordError !== true &&
      checkState === true
    ) {
      setFormValidated(true);
    } else {
      setFormValidated(false);
    }
  }, [form, emailError, passwordError, checkState]);

  //check for changes on password and repeat password inputs
  //and makes comparison
  useEffect(() => {
    if (passwordRepeat.dirty && password.dirty) {
      if (password.value.length >= 8 && passwordRepeat.value.length >= 8) {
        setShowRegexError(false);
      } else {
        setShowRegexError(true);
      }
      if (
        password.value === passwordRepeat.value &&
        password.value.length >= 8 &&
        passwordRepeat.value.length >= 8
      ) {
        setPasswordError(false);
        setPasswordRepeatError(false);
      } else {
        setPasswordError(true);
        setPasswordRepeatError(true);
      }
    }
  }, [passwordRepeat, form.password]);

  useEffect(() => {
    if (registration.isRegistering) {
      if (exist == "" && storeName == null) {
        navigate("/activate");
      } else {
        if (storeName) {
          exist ? dispatch(userActivationExist()) : navigate("/activate");
        }
      }
    }
  }, [registration, navigate]);

  useEffect(() => {
    if (registration.registerError) {
      setEmailError(true);
      setInputUser(true);
      dispatch(resetRegisterActionCreator());
    }
  }, [registration.registerError, dispatch]);

  useEffect(() => {
    hasCode();
    hasStoreName();
  }, []);

  return (
    <form className="formContainer" onSubmit={handleRegisterSubmit}>
      <div className="row">
        <div className="formGroup mr">
          <label className="lead-text" htmlFor={"firstName"}>
            {t("auth.register.name")}
          </label>
          <div className="input-container">
            <Input
              inputName={"firstName"}
              inputId={"firstName"}
              value={form.firstName}
              type={"text"}
              width="150px"
              height="30px"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="formGroup">
          <label htmlFor={"lastName"} className="lead-text ">
            {t("auth.register.lastname")}
          </label>
          <div className="input-container">
            <Input
              inputName={"lastName"}
              inputId={"lastName"}
              value={form.lastName}
              type={"text"}
              width="150px"
              height="30px"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="formGroup mr">
          <label htmlFor={"email"} className="lead-text ">
            {t("auth.register.email")}
          </label>
          <div className="input-container">
            <Input
              inputName={"email"}
              inputId={"email"}
              isValidated
              haveError={emailError}
              value={form.email}
              type={"text"}
              width="150px"
              height="30px"
              onChange={handleChange}
              isDisabled={initialState.email != ""}
            />
          </div>
        </div>
        <div className="formGroup">
          <label htmlFor={"username"} className="lead-text ">
            {t("auth.register.username")}
          </label>
          <div className="input-container">
            <Input
              inputName={"username"}
              inputId={"username"}
              value={form.username}
              isValidated
              haveError={inputUser}
              type={"text"}
              width="150px"
              height="30px"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="formGroup mr">
          <label htmlFor={"password"} className="lead-text ">
            {t("auth.register.password")}
          </label>
          <div className="input-container">
            <Input
              inputName={"password"}
              inputId={"password"}
              isValidated
              haveError={passwordError}
              value={password.value}
              type={"password"}
              width="150px"
              height="30px"
              onChange={handleChangePassword}
            />
          </div>
        </div>

        <div className="formGroup">
          <label htmlFor={"repeatPassword"} className="lead-text ">
            {t("auth.register.confirm_pass")}
          </label>
          <div className="input-container">
            <Input
              inputName={"repeatPassword"}
              inputId={"repeatPassword"}
              isValidated
              haveError={passwordRepeatError}
              value={passwordRepeat.value}
              type={"password"}
              width="150px"
              height="30px"
              onChange={handleChangePasswordRepeat}
            />
          </div>
        </div>
      </div>
      {regexError}
      <div className="checkWrapper row">
        <Checkbox clickHandler={handleClick} isActive={checkState} />
        <p className="alert-text text">
          {t("auth.register.terms_and_conditions")}{" "}
          <a
            className={"alert-text-bold"}
            href="https://fira-live.com"
            rel="noreferrer"
            target={"_blank"}
          >
            {t("auth.register.terms_and_conditions_link")}
          </a>
        </p>
      </div>

      <Button
        type="submit"
        width="316px"
        height="48px"
        isDisabled={!formValidated || registration.isLoading}
      >
        <div className="ButtonInner">
          {t("auth.register.button")}
          <ArrowRight className="icon" />
        </div>
      </Button>
    </form>
  );
};
export default RegisterForm;
