import { ChangeEvent, useEffect, useState } from "react";
import Button from "../Button/Button";
import { Add, CircleX, Info, MagnifyingGlass, Window } from "../FiraIcons";
import Timer from "../Timer/Timer";
import styles from "./EventModeration.module.scss";
import noImageStoreSVG from "../../assets/svg/no-image-store.svg";
import Input from "../Input/Input";
import currencyFormat from "../../utils/currencyFormat";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCurrentEventProductsActionCreator,
  SendPopUpActionCreator,
} from "../../store/Event/EventActions";
import { useTranslation } from "react-i18next";
import DeleteStreamProducts from "../Modals/DeleteStreamProducts/DeleteStreamProducts";
import CardWidget from "../../widgets/CardWidget/CardWidget";
import PopUpAlert, { PopUpAlertTypes } from "../PopUpAlert/PopUpAlert";
//import { GeneralContext } from "../../context/GeneralContext";
import { EventStatus, FiraEventType } from "../../utils/enums";
import { getLiveSumary, sendPopUp } from "../../services/events";
import { useEvent } from "../../providers/EventProvider";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";
import { useWebSocket } from "../../providers/WebsocketProvider";
import { RootState } from "../../store/Store";
import {
  ResponseCurrentPopup,
  ResponseSimpleProduct,
  ResponseSummary,
} from "../../utils/types";
import useSocket from "../../hook/useSocket";

interface EventModerationProps {
  openAddProductsModal(): void;
}

const EventModeration = ({ openAddProductsModal }: EventModerationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { event } = useSelector((state: RootState) => state);
  const { popups } = useEvent();
  const { eventCounters, onMessageHandler } = useWebSocket();
  const showAlert = usePopupAlert();

  const [filterValue, setFilterValue] = useState("");
  const [productsList, setProductsList] = useState<ResponseSimpleProduct[]>(
    event.currentEventProducts
  );
  const [showError, setShowError] = useState(false);
  const [activeCard, setActiveCard] = useState("");
  const [deleteProductModalIsOpen, setDeleteProductModalIsOpen] =
    useState(false);
  const [deleteElement, setDeleteElement] = useState<ResponseSimpleProduct>();

  const handleProductsFiltering = (eventEl: ChangeEvent<HTMLInputElement>) => {
    const keyword = eventEl.target.value;
    setFilterValue(keyword);

    const parsedKeyword = keyword
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");

    if (keyword !== "") {
      setProductsList(
        event.currentEventProducts.filter((p) => {
          return String(p.product.name)
            .replace("null", "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .includes(parsedKeyword);
        })
      );
    } else {
      setProductsList(event.currentEventProducts);
    }
  };

  const handleProductHighlighted = async (product: ResponseSimpleProduct) => {
    if (popups.length < 2) {
      const body = {
        broadcastingId: product.broadcastingId,
        broadcastingProductId: product.id,
        price: "price1",
      };
      await sendPopUp(body);
    } else {
      showAlert(
        AlertType.error,
        t("views.events.eventDetail.products.messages.popupError")
      );
    }
  };

  const handleCloseAlert = () => {
    setShowError(false);
  };

  const openDeleteModal = (product: ResponseSimpleProduct) => {
    setDeleteElement(product);
    setDeleteProductModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteProductModalIsOpen(false);
  };

  const { startConnection, cleanUp } = useSocket(
    event.currentEvent?.id ?? "",
    onMessageHandler
  );

  useEffect(() => {
    if (event.currentEvent?.status === EventStatus.STARTED) {
      startConnection(event.currentEvent?.id);
    }

    return () => {
      cleanUp();
    };
  }, [event.currentEvent?.status]);

  useEffect(() => {
    event.currentEvent &&
      dispatch(
        GetCurrentEventProductsActionCreator(
          event.currentEvent.id,
          event.currentEvent.status
        )
      );
  }, []);

  useEffect(() => {
    setProductsList(event.currentEventProducts);
  }, [event.currentEventProducts]);

  return (
    <div className={styles.moderationContainer}>
      <div className={styles.moderationCounters}>
        <CardWidget
          isLoading={event.isLoading}
          title={t("views.events.eventDetail.likes")}
          description={""}
          data={eventCounters.likes.toLocaleString().replace(/, /g, ".")}
        />
        <CardWidget
          isLoading={event.isLoading}
          title={t("views.events.eventDetail.shares")}
          description={""}
          data={eventCounters.shares.toLocaleString().replace(/, /g, ".")}
        />
        <CardWidget
          isLoading={event.isLoading}
          title={t("views.events.eventDetail.averageClicks")}
          description={""}
          data={(eventCounters.clicks + eventCounters.popupClicks)
            .toLocaleString()
            .replace(/, /g, ".")}
        />
        <CardWidget
          isLoading={event.isLoading}
          title={t("views.events.eventDetail.connections")}
          description={""}
          data={
            (eventCounters.connections + eventCounters.mobileConnections)
              .toLocaleString()
              .replace(/,/g, ".") +
            (event.currentEvent?.eventType === FiraEventType.test
              ? `/${event.currentEvent.testUnits?.connectionsAllowed}`
              : "")
          }
        />
      </div>
      {showError && event.error && (
        <PopUpAlert
          type={PopUpAlertTypes.error}
          title={event.error.message}
          width="100%"
          handleCloseIcon={handleCloseAlert}
        />
      )}
      <div className={styles.productsContainer}>
        <div className={styles.productsHeader}>
          <div className={styles.title}>
            <h6>{t("views.events.eventDetail.products.title")}</h6>
            <Info className={styles.infoIcon} />
          </div>

          <Input
            height="30px"
            width="644px"
            icon={<MagnifyingGlass className="SearchIcon" />}
            placeholder={t("views.events.eventDetail.products.filter")}
            value={filterValue}
            onChange={handleProductsFiltering}
          />
        </div>
        <div className={styles.itemsContainer}>
          {event.currentEvent?.status != "DONE" && (
            <div
              className={`${styles.addProductContainer} ${
                event.currentEvent?.status == "NOT_STARTED"
                  ? styles.disabled
                  : ""
              }`}
              onClick={() =>
                event.currentEvent?.status == "NOT_STARTED"
                  ? {}
                  : openAddProductsModal()
              }
            >
              <div className={styles.contentGroup}>
                <div className={styles.iconWrapper}>
                  <Add className={styles.addIcon} />
                </div>
                <p className="body-16-text">
                  {t("views.events.eventDetail.products.add")}
                </p>
              </div>
            </div>
          )}
          {productsList.map((product, index) => {
            return (
              <ProductCard
                key={index}
                product={product}
                highlightedHandler={handleProductHighlighted}
                deleteProduct={openDeleteModal}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
            );
          })}
        </div>
      </div>

      {deleteProductModalIsOpen && deleteElement && (
        <DeleteStreamProducts
          isOpen={deleteProductModalIsOpen}
          onClose={closeDeleteModal}
          productName={deleteElement.product.name}
          producId={deleteElement.id}
          eventId={deleteElement.broadcastingId}
        />
      )}
    </div>
  );
};

interface ProductCardProps {
  highlightedHandler(product: ResponseSimpleProduct): void;
  deleteProduct(product: ResponseSimpleProduct): void;
  product: ResponseSimpleProduct;
  activeCard: string;
  setActiveCard(id: string): void;
}

const ProductCard = ({
  product,
  highlightedHandler,
  deleteProduct,
  activeCard,
  setActiveCard,
}: ProductCardProps) => {
  const { t } = useTranslation();
  const { brand, event } = useSelector((state: RootState) => state);
  const { popups } = useEvent();

  const pricesGroup = brand.currentStore && (
    <div className={styles.pricesWrapper}>
      <div className={styles.pricesGroup}>
        <p
          className={`${styles.pricesEllipsis} ${
            product.product.price2 == 0 ? styles.boldText : styles.lineText
          }`}
        >
          {product.product.price1 != 0
            ? String(
                currencyFormat(
                  product.product.price1,
                  brand.currentStore?.regionalConfiguration.currency
                )
              )
            : ""}
        </p>
        <p className={`${styles.pricesEllipsis} ${styles.boldText}`}>
          {product.product.price2 != 0
            ? currencyFormat(
                product.product.price2,
                brand.currentStore?.regionalConfiguration.currency
              )
            : ""}
        </p>
      </div>
    </div>
  );

  const inPopup = popups.find((p) => p.broadcastingProductId === product.id);

  const button = (
    <Button
      className={styles.showButton}
      width="79px"
      height="24px"
      clickHandler={() => highlightedHandler(product)}
    >
      <div className={styles.inner}>
        <Window className={styles.windowIcon} />
        <p>{t("views.events.eventDetail.products.highlight")}</p>
      </div>
    </Button>
  );

  const deleteButton = (
    <div className={styles.closeIcon} onClick={() => deleteProduct(product)}>
      <CircleX className={styles.icon} />
    </div>
  );
  const renderHighlight = (popup: ResponseCurrentPopup) => (
    <div className={styles.highlighted}>
      <Timer
        remaingTime={popup.remainingTime}
        key={product.id + "moderation"}
      />
    </div>
  );
  return (
    <div className={styles.cardWrapper}>
      {activeCard === product.id &&
      !inPopup &&
      event.currentEvent?.status !== "NOT_STARTED"
        ? deleteButton
        : ""}
      {inPopup && renderHighlight(inPopup)}
      <div
        className={`${styles.productsCard} ${
          activeCard === product.id ? styles.selected : ""
        } ${inPopup ? styles.Blur : ""}`}
        onClick={() => setActiveCard(product.id)}
      >
        <div className={styles.imgContainer}>
          <img
            alt={"product"}
            src={
              product?.product.images.length !== 0 &&
              product?.product.images[0] !== ""
                ? product?.product.images[0]
                : noImageStoreSVG
            }
          />
        </div>

        <div className={styles.textContainer}>
          <div className={`${styles.title} ${styles.ellipsis}`}>
            {product.product.name}
          </div>
          {activeCard === product.id &&
          !product.onPopup &&
          brand.currentStore &&
          event.currentEvent?.status == "STARTED"
            ? button
            : pricesGroup}
        </div>
      </div>
    </div>
  );
};

export default EventModeration;
