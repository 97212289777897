import * as React from "react";
import { SVGProps } from "react";

const InfoCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={9}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.822 6.429V3.857H3.536V4.5h.643V6.43h-.965v.643h2.572v-.643h-.964Zm-.322-4.5a.482.482 0 1 0 0 .964.482.482 0 0 0 0-.964Z"
      fill="currentColor"
    />
    <path
      d="M4.5 9a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm0-8.357a3.857 3.857 0 1 0 0 7.714 3.857 3.857 0 0 0 0-7.714Z"
      fill="currentColor"
    />
  </svg>
);

export default InfoCircle;
