import React, { ReactElement, useEffect, useState } from "react";
import {
  FiraAdminPermissions,
  FiraModeratorPermissions,
  FiraOperatorPermissions,
} from "../../../types/Permissions";

import stylesheet from "./FiraUserPermissionComponent.module.scss";
import { Checkbox } from "../..";
import { useTranslation } from "react-i18next";
import { SimplePermissionsType } from "../../../views/SendInvitationView/FiraPermissions/PermissionsItemsForOperator/PermissionItemsForOperator";
import { SimplePermissionItem } from "../../../types/Response";
import {
  activateAll,
  areAllActive,
  isPermissionAvailable,
  isPermissionGroupAvailable,
} from "../../../utils/handlePermissionCheckboxSelection";
import { FiraPackage, PermissionCardType } from "../../../utils/enums";
import { event } from "jquery";

const {
  FiraUserPermissionWrapper,
  CheckboxWrapper,
  ComponentBorder,
  Selected,
  TitleWrapper,
  IconWrapper,
  TextWrapper,
  DescriptionWrapper,
  PermissionButtonWrapper,
  IsActive,
  FiraSimplePermissionListPropsWrapper,
  PermissionListWrapper,
  DisabledModule,
  FiraEditableList,
  EditableItem,
} = stylesheet;

export type FiraPermissions =
  | FiraAdminPermissions[]
  | FiraModeratorPermissions[]
  | FiraOperatorPermissions[];

export interface EditablePermissionsType {
  name: string;
  permissions: string[];
  firaProduct?: FiraPackage;
  isActive?: boolean;
  card?: PermissionCardType; //Sólo en caso de Operador
}

interface Props {
  icon: ReactElement;
  title: string;
  description: string;
  permissions: any;
  onPermissionClick?(
    permissions: FiraPermissions | SimplePermissionsType
  ): void;
  isEditable?: boolean;
  isOnlyDisplay?: boolean;
  isDisabled?: boolean;
  editablePermissions?: EditablePermissionsType[];
  onEditablePermissionClick?(permissions: EditablePermissionsType): void;
  selectedAll?: boolean;
  userPermissions?: SimplePermissionItem[];
  hasPermissions?: boolean;
  onModuleClick?(permissions: SimplePermissionItem, remove: boolean): void;
  isModuleSelected?: boolean;
  hasSomePermissions?: boolean;
  canBeSelected?: boolean;
}
const FiraUserPermissionComponent: React.FC<Props> = ({
  icon,
  title,
  description,
  permissions,
  onPermissionClick = (permissions) => {},
  isEditable = false,
  children,
  isOnlyDisplay = false,
  isDisabled = false,
  editablePermissions,
  selectedAll = false,
  onEditablePermissionClick,
  userPermissions,
  hasPermissions,
  onModuleClick,
  isModuleSelected = false,
  hasSomePermissions = false,
  canBeSelected = true,
}) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);

  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState<boolean>(false);
  const [isCardSelected, setIsCardSelected] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const checkBoxClick = () => {
    setIsSelected((previousState) => {
      if (!previousState === true) {
        onPermissionClick(permissions);
      } else {
        setIsEditActive(false);
        onPermissionClick(permissions);
      }
      return !previousState;
    });
  };

  const moduleClickHandler = (permissions: SimplePermissionItem) => {
    setIsSelected(!isSelected);
    onModuleClick && onModuleClick(permissions, isSelected);
  };

  const editHandler = (item: EditablePermissionsType) => {
    if (editablePermissions && onEditablePermissionClick) {
      onEditablePermissionClick(item);
      //setIsSelected(areAllActive(editablePermissions));
    }
  };

  const areAllActive = (editablePermissions: EditablePermissionsType[]) => {
    return editablePermissions.every(
      (element: EditablePermissionsType) => element.isActive
    );
  };

  const isAvailable = (item: string[]) => {
    if (editablePermissions && userPermissions) {
      return isPermissionGroupAvailable(userPermissions, item);
    }
  };

  useEffect(() => {
    if (!isSelected) {
      setIsEditActive(false);
      setIsCardSelected(false);
    }
  }, [isSelected]);
  useEffect(() => {
    setIsSelected(isModuleSelected);
  }, [isModuleSelected]);

  useEffect(() => {
    hasPermissions && setIsSelected(true);
  }, [hasPermissions]);

  useEffect(() => {
    selectedAll && setIsSelected(selectedAll);
  }, [selectedAll]);

  useEffect(() => {
    hasSomePermissions && setIsCardSelected(hasSomePermissions);
  }, [hasSomePermissions]);

  return (
    <div
      className={`${FiraUserPermissionWrapper} ${
        isDisabled ? DisabledModule : ""
      }`}
      onClick={() => canBeSelected && moduleClickHandler(permissions)}
    >
      {canBeSelected && (
        <div className={CheckboxWrapper}>
          {!isOnlyDisplay && !isEditable && (
            <Checkbox
              isActive={isSelected}
              clickHandler={() => moduleClickHandler(permissions)}
              stopPropagation={isEditable}
            />
          )}
          {isEditable && (
            <Checkbox
              isActive={selectedAll}
              clickHandler={() => moduleClickHandler(permissions)}
              stopPropagation={isEditable}
            />
          )}
        </div>
      )}
      <div className={TitleWrapper}>
        <div className={IconWrapper}>{icon}</div>
        <div className={TextWrapper}>{title}</div>
      </div>
      {!isEditActive && <div className={DescriptionWrapper}>{description}</div>}

      {!isEditActive && <div>{children}</div>}

      {isEditActive && onEditablePermissionClick && (
        <div className={FiraEditableList}>
          {editablePermissions &&
            editablePermissions.map((item, i) => (
              <div
                // className={`${EditableItem} ${
                //   !isAvailable(item.permissions) ? DisabledModule : ""
                // }`} FIX THIS
                className={`${EditableItem}`}
                key={i}
              >
                <Checkbox
                  clickHandler={() => editHandler(item)}
                  isActive={item.isActive}
                  inputName={`check_${i}`}
                  stopPropagation={true}
                />
                <label
                  htmlFor={`check_${i}`}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    editHandler(item);
                  }}
                >
                  {" "}
                  {item.name}
                </label>
              </div>
            ))}
        </div>
      )}

      {isEditable && isSelected && (
        <div className={PermissionButtonWrapper}>
          <button
            className={isEditActive ? IsActive : ""}
            onClick={(e) => {
              e.stopPropagation();
              setIsEditActive(!isEditActive);
            }}
          >
            {t("components.permissionCard.edit")}
          </button>
        </div>
      )}

      <div
        className={`${ComponentBorder} ${
          isCardSelected || selectedAll || isSelected ? Selected : ""
        }`}
      ></div>
    </div>
  );
};

interface FiraSimplePermissionListProps {
  permissions: string[];
}
export const FiraSimplePermissionList: React.FC<
  FiraSimplePermissionListProps
> = ({ permissions }) => {
  const { t } = useTranslation();
  return (
    <div className={FiraSimplePermissionListPropsWrapper}>
      <div className={TitleWrapper}>{t("components.permissionCard.title")}</div>
      <ul className={PermissionListWrapper}>
        {permissions.map((permission, index) => (
          <li key={index}>{permission}</li>
        ))}
      </ul>
    </div>
  );
};

export default FiraUserPermissionComponent;
