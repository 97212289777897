import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { handleError, RequestError } from "../../services/errorHandling";
import {
  deleteProductsService,
  getStoreProductsService,
  createProductService,
  updateProductService,
  addImagesProductService,
  sortImagesProductService,
  deleteImagesProductService,
  createProductLabelService,
  getStoreProductsOwnerService,
  getStoreProductsGlobalService,
} from "../../services/store";
import { uploadImageService } from "../../services/upload";
import { LabelsResponse } from "../../types/Response";
import {
  FiraProduct,
  FiraProductLabels,
  ImageProductObject,
  LabelProductCreate,
} from "../../utils/types";
import { ProductForm } from "../../views/DetailProductView/DetailProductView";
import { ProductsActions } from "../actionsContants";
import { LogoutActionType } from "../Auth/AuthActions";

interface GetStoreProductsActionType {
  type:
    | ProductsActions.getStoreProductsLoading
    | ProductsActions.getStoreProductsSuccess
    | ProductsActions.getStoreProductsFailed;
  payload: FiraProduct[] | RequestError;
}
export const getStoreProductsActionCreator = (storeId: string) => {
  return async (
    dispatch: ThunkDispatch<
      GetStoreProductsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: ProductsActions.getStoreProductsLoading });
      const response = await getStoreProductsService(storeId);
      dispatch({
        type: ProductsActions.getStoreProductsSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProductsActions.getStoreProductsFailed,
        payload: handleError(error),
      });
    }
  };
};

export const getStoreProductsGlobalActionCreator = () => {
  return async (
    dispatch: ThunkDispatch<
      GetStoreProductsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: ProductsActions.getStoreProductsLoading });
      const response = await getStoreProductsGlobalService();
      dispatch({
        type: ProductsActions.getStoreProductsSuccess,
        payload: response.data as FiraProduct[],
      });
    } catch (error) {
      dispatch({
        type: ProductsActions.getStoreProductsFailed,
        payload: handleError(error),
      });
    }
  };
};

export const getStoreProductsOwnerActionCreator = () => {
  return async (
    dispatch: ThunkDispatch<
      GetStoreProductsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: ProductsActions.getStoreProductsLoading });
      const response = await getStoreProductsOwnerService();
      dispatch({
        type: ProductsActions.getStoreProductsSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProductsActions.getStoreProductsFailed,
        payload: handleError(error),
      });
    }
  };
};

interface DeleteProductsActionType {
  type:
    | ProductsActions.deleteProductsLoading
    | ProductsActions.deleteProductsSuccess
    | ProductsActions.deleteProductsFailed;
  payload?: string[] | RequestError;
}
export const deleteProductsActionCreator = (
  storeId: string,
  productsToDelete: string[]
) => {
  return async (
    dispatch: ThunkDispatch<
      DeleteProductsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: ProductsActions.deleteProductsLoading });
      await deleteProductsService(storeId, productsToDelete);
      dispatch({
        type: ProductsActions.deleteProductsSuccess,
        payload: productsToDelete,
      });
    } catch (error) {
      dispatch({
        type: ProductsActions.deleteProductsFailed,
        payload: handleError(error),
      });
    }
  };
};

interface CreateProductActionType {
  type:
    | ProductsActions.createProductLoading
    | ProductsActions.createProductSuccess
    | ProductsActions.createProductFailed;
  payload?: FiraProduct | RequestError;
}
export const createProductActionCreator = (
  productForm: ProductForm,
  newImages: File[],
  labels: LabelProductCreate[] // Nuevas etiquetas que deseas agregar al producto
) => {
  return async (
    dispatch: ThunkDispatch<
      CreateProductActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: ProductsActions.createProductLoading });

      // Crea las etiquetas primero
      const labelsResponses = await Promise.all(
        labels.map(async (label) => {
          const responseLabel = await createProductLabelService(label);
          return responseLabel.data;
        })
      );

      // Agrega las etiquetas al formulario del producto
      const productFormWithLabels = {
        ...productForm,
        labels: labelsResponses as FiraProductLabels[],
      };

      // Crea el producto con las etiquetas
      const response = await createProductService(
        productFormWithLabels,
        newImages
      );
      const createdProduct = response.data;

      dispatch({
        type: ProductsActions.createProductSuccess,
        payload: createdProduct,
      });
    } catch (error) {
      dispatch({
        type: ProductsActions.createProductFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateProductActionType {
  type:
    | ProductsActions.updateProductInfoLoading
    | ProductsActions.updateProductInfoSuccess
    | ProductsActions.updateProductInfoFailed;
  payload?: FiraProduct | RequestError;
}
export const updateInfoProductActionCreator = (
  product: FiraProduct,
  labels: LabelProductCreate[]
) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateProductActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: ProductsActions.updateProductInfoLoading });
      const labelsResponses: LabelsResponse[] = [];

      for (const label of labels) {
        const responseLabel = await createProductLabelService(label);
        labelsResponses.push(responseLabel.data);
      }
      const updatedProduct: FiraProduct = {
        ...product,
        labels: labelsResponses as FiraProductLabels[],
      };

      const response = await updateProductService(updatedProduct);

      dispatch({
        type: ProductsActions.updateProductInfoSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProductsActions.updateProductFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateImageProductActionType {
  type:
    | ProductsActions.updateImagesProductLoading
    | ProductsActions.updateImagesProductSuccess
    | ProductsActions.updateImagesProductFailed
    | ProductsActions.updateImagesProductReset;
  payload?: string[] | RequestError;
}
export const updateImagesProductActionCreator = (
  id: string,
  isAdd: boolean,
  isOrder: boolean,
  isDelete: boolean,
  listImages: ImageProductObject[]
) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateImageProductActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: ProductsActions.updateImagesProductLoading });
      let response;

      if (isAdd) {
        const addImmage = listImages
          .filter((item) => item.isNew)
          .map((item) => {
            return item.image;
          });

        response = await addImagesProductService(id, addImmage);
        const imagesResult = response.data.images as string[];

        let imagesAdded = imagesResult.slice(
          listImages.length - addImmage.length
        );

        if (imagesAdded) {
          listImages
            .filter((item) => item.isNew)
            .map((item, key) => {
              item.imageUrl = imagesAdded[key] as string;
              item.isNew = false;
            });
        }
      }
      if (isDelete) {
        const deleteImmage = listImages
          .filter((item) => item.isDelete)
          .map((item) => {
            return item.imageUrl;
          });

        response = await deleteImagesProductService(id, deleteImmage);
      }
      if (isOrder) {
        const orderImmages = listImages
          .filter((item) => !item.isDelete)
          .map((item) => {
            return item.imageUrl;
          });
        dispatch({ type: ProductsActions.updateImagesProductLoading });
        response = await sortImagesProductService(id, orderImmages);
      }
      dispatch({
        type: ProductsActions.updateImagesProductSuccess,
        payload: response?.data.images as string[],
      });
    } catch (error) {
      dispatch({
        type: ProductsActions.updateImagesProductFailed,
        payload: handleError(error),
      });
    }
  };
};

interface ResetUpdateImagesProductActionType {
  type: ProductsActions.updateImagesProductReset;
}
export const resetUpdateImageProductActionCreator =
  (): ResetUpdateImagesProductActionType => {
    return { type: ProductsActions.updateImagesProductReset };
  };
interface SetCurrentProductActionType {
  type: ProductsActions.setCurrentProduct;
  payload: FiraProduct;
}
export const setCurrentProductActionCreator = (
  product: FiraProduct
): SetCurrentProductActionType => {
  return { type: ProductsActions.setCurrentProduct, payload: product };
};

interface ResetCurrentProductActionType {
  type: ProductsActions.resetCurrentProduct;
}
export const resetCurrentProductActionCreator =
  (): ResetCurrentProductActionType => {
    return { type: ProductsActions.resetCurrentProduct };
  };

export type ProductsActionType =
  | GetStoreProductsActionType
  | DeleteProductsActionType
  | CreateProductActionType
  | UpdateProductActionType
  | UpdateImageProductActionType
  | ResetCurrentProductActionType
  | SetCurrentProductActionType
  | LogoutActionType;
