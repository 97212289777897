import * as React from "react";
import { SVGProps } from "react";

const Delete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.167 2.788a.45.45 0 0 1 .444.458v.237c0 .25-.202.457-.444.457H.836a.455.455 0 0 1-.445-.457v-.237a.45.45 0 0 1 .445-.458h1.817a.8.8 0 0 0 .774-.633l.095-.425c.148-.579.635-.963 1.192-.963h2.574c.551 0 1.043.384 1.186.933l.102.455c.082.37.403.633.773.633h1.818Zm-.924 8.659c.19-1.769.522-5.97.522-6.012a.465.465 0 0 0-.113-.348.452.452 0 0 0-.326-.146H1.68a.44.44 0 0 0-.326.146.493.493 0 0 0-.119.348l.033.403c.088 1.099.335 4.16.494 5.609.113 1.067.813 1.738 1.828 1.762.782.018 1.589.024 2.413.024.777 0 1.565-.006 2.372-.024 1.05-.018 1.75-.677 1.868-1.762Z"
      fill="#F16063"
    />
  </svg>
);

export default Delete;
