import { FiraInputSearchType } from "../../components/InputSearch/InputSearch";
import { RequestError } from "../../services/errorHandling";
import {
  EditEventResponse,
  FiraConfiguration,
  UpdateEventResponse,
  FiraCredentials,
  FiraEvent,
  streamBlock,
  ResponseQueues,
  StartEventResponse,
  ResponseSimpleProduct,
  AddEventProductPayload,
  SuscriberCredentialsDto,
  Category,
  OwnerEventCreateResponse,
  TemplatesResponse,
  FiraEventNew,
} from "../../utils/types";
import {
  AuthActions,
  EventActions,
  MultiEventActions,
} from "../actionsContants";
import {
  EventActionType,
  ResponseDeletePopupAction,
  ResponseSendPopupAction,
} from "./EventActions";

export interface EventState {
  isLoading: boolean;
  list: FiraEvent[];
  error?: RequestError;
  createSuccess?: boolean;
  createEventResponse?: boolean;
  firaConfig: FiraConfiguration[];
  streamBlocks: streamBlock[];
  currentEvent?: FiraEvent;
  credentialsSuccess?: boolean;
  credentialsError?: boolean;
  cancelSuccess?: boolean;
  updatedEvent?: boolean;
  updatedEventResponse?: UpdateEventResponse;
  currentQueues?: ResponseQueues;
  currentEventProducts: ResponseSimpleProduct[];
  addProductSuccess?: boolean;
  editedEvent?: boolean;
  editEventResponse?: EditEventResponse;
  inactiveEventProducts: ResponseSimpleProduct[];
  eventCategories?: Category[];
  eventTemplatesList?: TemplatesResponse[];
  storeList?: FiraInputSearchType[];
}

enum EventStatus {
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
  DONE = "DONE",
}

const initialState: EventState = {
  isLoading: false,
  list: [],
  firaConfig: [],
  streamBlocks: [],
  currentEventProducts: [],
  inactiveEventProducts: [],
};

export const eventReducer = (
  state = initialState,
  action: EventActionType
): EventState => {
  switch (action.type) {
    case EventActions.getStoreEventsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.getStoreEventsSuccess: {
      const { payload } = action;
      return { ...state, isLoading: false, list: payload as FiraEvent[] };
    }
    case EventActions.getStoreEventsFailed: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }

    case EventActions.getEventLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.getEventSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        currentEvent: payload as FiraEvent,
      };
    }

    case EventActions.getEventFailed: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }

    case EventActions.createEventLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.createEventSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        createSuccess: true,
        currentEvent: payload as FiraEvent,
        list: [...state.list, payload as FiraEvent],
      };
    }
    case EventActions.createEventFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        createSuccess: false,
        error: payload as RequestError,
      };
    }

    case EventActions.putCancelEventLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.putCancelEventSuccess: {
      const { payload } = action;
      const newList = state.list;

      newList.map((element) => {
        if (element.id.includes(payload as string)) {
          element.status = EventStatus.CANCELLED;
        }
      });

      return {
        ...state,
        isLoading: false,
        cancelSuccess: true,
        list: newList,
      };
    }
    case EventActions.putCancelEventFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case EventActions.resetEventMessage: {
      return {
        ...state,
        isLoading: false,
        createSuccess: false,
        cancelSuccess: false,
      };
    }

    case EventActions.getFiraWebConfigurationLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case EventActions.getFiraWebConfigurationSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        firaConfig: payload as FiraConfiguration[],
      };
    }

    case EventActions.getFiraWebConfigurationFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case EventActions.getStreamBlocksLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case EventActions.getStreamBlocksSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        streamBlocks: payload as streamBlock[],
      };
    }

    case EventActions.getStreamBlocksFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case EventActions.setCurrentEvent: {
      const { payload } = action;
      return { ...state, currentEvent: payload, error: undefined };
    }

    case EventActions.resetCurrentEvent: {
      return {
        ...state,
        currentEvent: initialState.currentEvent,
        currentQueues: undefined,
        currentEventProducts: initialState.currentEventProducts,
        inactiveEventProducts: initialState.inactiveEventProducts,
      };
    }
    case EventActions.setStoresListEvent: {
      const { payload } = action;
      return { ...state, storeList: payload, error: undefined };
    }
    case EventActions.updateEventLoading: {
      return { ...state, isLoading: true };
    }

    case EventActions.updateEventSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        updatedEvent: true,
        updatedEventResponse: payload as UpdateEventResponse,
      };
    }

    case EventActions.updateEventFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        updatedEvent: false,
        error: payload as RequestError,
      };
    }
    case EventActions.updateEventReset: {
      return {
        ...state,
        updatedEvent: false,
        error: undefined,
      };
    }
    case EventActions.generateCredentialsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.generateCredentialsSuccess: {
      const { publisherCredentialsDto, suscriberCredentialsDto } =
        action.payload as FiraCredentials;
      const newEvent = {
        ...state.currentEvent,
        status: EventStatus.NOT_STARTED,
        publisherCredentialsDto,
        suscriberCredentialsDto,
      };
      return {
        ...state,
        isLoading: false,
        currentEvent: newEvent as FiraEvent,
        credentialsSuccess: true,
      };
    }
    case EventActions.generateCredentialsFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
        credentialsError: true,
      };
    }
    case EventActions.updateCredentialsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.updateCredentialsSuccess: {
      const { payload } = action;
      const newEvent = {
        ...state.currentEvent,
        suscriberCredentialsDto: payload as SuscriberCredentialsDto,
      };
      return {
        ...state,
        isLoading: false,
        currentEvent: newEvent as FiraEvent,
      };
    }
    case EventActions.updateCredentialsFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case EventActions.startEventLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.startEventSuccess: {
      const { status, queues, startedTime } =
        action.payload as StartEventResponse;

      const newEvent = {
        ...state.currentEvent,
        status: status,
        startDate: startedTime,
      };
      return {
        ...state,
        isLoading: false,
        currentEvent: newEvent as FiraEvent,
        currentQueues: queues,
      };
    }

    case EventActions.startEventFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case EventActions.finishEventLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.finishEventSuccess: {
      const { payload } = action;
      const newEvent = {
        ...state.currentEvent,
        status: payload,
      };

      return {
        ...state,
        isLoading: false,
        currentEvent: newEvent as FiraEvent,
      };
    }

    case EventActions.finishEventFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case EventActions.cleanMessage: {
      return {
        ...state,
        isLoading: false,
        credentialsSuccess: false,
        credentialsError: false,
        cancelSuccess: false,
        addProductSuccess: false,
        createSuccess: false,
        updatedEvent: false,
        editedEvent: false,
        createEventResponse: undefined,
        error: undefined,
        updatedEventResponse: undefined,
        editEventResponse: undefined,
      };
    }

    case EventActions.getCurrentEventProductsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case EventActions.getCurrentEventProductsSuccess: {
      const { payload } = action;
      // const products = payload as ResponseSimpleProduct[];
      // const activePopUps = products.filter((p) => p.onPopup == true);
      return {
        ...state,
        isLoading: false,
        currentEventProducts: payload as ResponseSimpleProduct[],
        // activePopUps: activePopUps,
      };
    }

    case EventActions.getCurrentEventProductsFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case EventActions.addCurrentEventProductsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.addCurrentEventProductsSuccess: {
      const { response } = action.payload as AddEventProductPayload;
      //todo lo de queues se comento por que esta explotando
      //hay que ver como llegaran esas colas y como consumirlas
      //comentarios para recordar que hice aqui  cuando me pregunten
      //setea el estado inicial de las nuevas queques
      // let newQueues = state.currentQueues;

      // //si el state tiene queques
      // if (state.currentQueues) {
      //   //de las currentqueques toma el arreglo de
      //   //queues de productos
      //   let productsQueues = state.currentQueues.broadcastingProductActionQueue;
      //   //a ese arreglo de queues le agrega los nuevos productos
      //   response.broadcastingProductActionQueue.map((queue) => {
      //     productsQueues = [...productsQueues, queue];
      //   });
      //   //y luego se lo pasamos al nuevo objeto de queques
      //   newQueues = {
      //     likeQueue: state.currentQueues.likeQueue,
      //     shareQueue: state.currentQueues.shareQueue,
      //     shoppingCartQueue: state.currentQueues.shareQueue,
      //     mobConnQueue: state.currentQueues.shareQueue,
      //     webConnQueue: state.currentQueues.shareQueue,
      //     regularClickQueue: state.currentQueues.shareQueue,
      //     popupClickQueue: state.currentQueues.shareQueue,
      //     broadcastingProductActionQueue: productsQueues,
      //   };
      // }

      return {
        ...state,
        isLoading: false,
        // currentEventProducts: newList,
        addProductSuccess: true,
        //comentado porque estaba explotando
        // currentQueues: newQueues,
      };
    }
    case EventActions.addCurrentEventProductsFailed: {
      const { payload } = action;

      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case EventActions.reactiveCurrentEventProductsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.reactiveCurrentEventProductsSuccess: {
      const { response } = action.payload as AddEventProductPayload;
      return {
        ...state,
        addProductSuccess: true,
        isLoading: false,
      };
    }
    case EventActions.reactiveCurrentEventProductsFailed: {
      const { payload } = action;

      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case EventActions.deleteCurrentEventProductsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.deleteCurrentEventProductsSuccess: {
      const { payload } = action;
      const newCurrentProducts = state.currentEventProducts.filter(
        (p) => payload !== p.id
      );
      return {
        ...state,
        isLoading: false,
        currentEventProducts: newCurrentProducts,
      };
    }
    case EventActions.deleteCurrentEventProductsFailed: {
      const { payload } = action;

      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case EventActions.sendPopUpLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.sendPopUpSuccess: {
      const { product } = action.payload as ResponseSendPopupAction;

      const newProduct = { ...product, onPopup: true };
      const refreshProducts = state.currentEventProducts.map(
        (currentProduct) => {
          if (product.id === currentProduct.id) {
            return newProduct;
          }
          return currentProduct;
        }
      );
      return {
        ...state,
        isLoading: false,
        currentEventProducts: refreshProducts,
      };
    }
    case EventActions.sendPopUpFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case EventActions.deletePopUpLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case EventActions.deletePopUpSuccess: {
      const { product } = action.payload as ResponseDeletePopupAction;
      const newProduct = { ...product, onPopup: false };
      const refreshProduct = state.currentEventProducts.map(
        (currentProduct) => {
          if (newProduct.id === currentProduct.id) {
            return newProduct;
          }
          return currentProduct;
        }
      );
      return {
        ...state,
        isLoading: false,
        currentEventProducts: refreshProduct,
      };
    }

    case EventActions.deletePopUpFailed: {
      const { payload } = action;

      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case EventActions.editEventLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case EventActions.editEventSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        editedEvent: true,
        editEventResponse: payload as EditEventResponse,
      };
    }

    case EventActions.editEventFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        editedEvent: false,
        error: payload as RequestError,
      };
    }

    case EventActions.editEventReset: {
      return {
        ...state,
        editedEvent: false,
        error: undefined,
      };
    }

    case EventActions.getInactiveProductsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.getInactiveProductsSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        inactiveEventProducts: payload as ResponseSimpleProduct[],
      };
    }
    case EventActions.getInactiveProductsFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case EventActions.getEventQueuesLoading: {
      return {
        ...state,
        isLoading: true,
        currentQueues: undefined,
      };
    }

    case EventActions.getEventQueuesSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        currentQueues: payload as ResponseQueues,
      };
    }

    case EventActions.getEventQueuesFailed: {
      // TODO: show error
      return {
        ...state,
        isLoading: false,
      };
    }

    case EventActions.updateStatsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case EventActions.updateStatsSuccess: {
      const { userActionsStats } = action.payload as FiraEvent;
      const newEvent = {
        ...state.currentEvent,
        userActionsStats: userActionsStats,
      };

      return {
        ...state,
        isLoading: false,
        currentEvent: newEvent as FiraEvent,
      };
    }

    case EventActions.updateStatsFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case EventActions.getEventCategoriesLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case EventActions.getEventCategoriesSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        eventCategories: payload as Category[],
      };
    }

    case EventActions.getEventCategoriesFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case EventActions.testEventTimerFinish: {
      const { payload } = action;
      const newEvent = {
        ...state.currentEvent,
        status: payload,
      };

      return {
        ...state,
        isLoading: false,
        currentEvent: newEvent as FiraEvent,
      };
    }

    case MultiEventActions.createMultiEventLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        createEventResponse: false,
      };
    }
    case MultiEventActions.createMultiEventSuccess: {
      const { payload } = action;

      return {
        ...state,
        isLoading: false,
        createSuccess: true,
        currentEvent: payload as FiraEvent,
        createEventResponse: true,
      };
    }
    case MultiEventActions.createMultiEventFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        createSuccess: false,
        error: payload as RequestError,
      };
    }

    case EventActions.setUpEventLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case EventActions.setUpEventSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        updatedEvent: true,
        updatedEventResponse: payload as UpdateEventResponse,
      };
    }

    case EventActions.setUpEventFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        updatedEvent: false,
        error: payload as RequestError,
      };
    }

    case EventActions.resetCurrentEventProducts: {
      return {
        ...state,
        currentEventProducts: [],
      };
    }

    case AuthActions.logout: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
