import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgThumbnailCategories = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 197 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={197} height={48} rx={4} fill="#F7F8F9" />
    <rect
      x={5.783}
      y={6.445}
      width={52.908}
      height={8.481}
      rx={4.24}
      fill="#D5DDE0"
    />
    <rect
      x={179.988}
      y={9.498}
      width={11.228}
      height={3.393}
      rx={1.696}
      fill="#D5DDE0"
    />
    <rect
      x={76.385}
      y={38.332}
      width={18.883}
      height={3.223}
      rx={1.612}
      fill="#D5DDE0"
    />
    <rect
      x={123.678}
      y={38.332}
      width={18.883}
      height={3.223}
      rx={1.612}
      fill="#D5DDE0"
    />
    <rect
      x={99.522}
      y={38.332}
      width={18.883}
      height={3.223}
      rx={1.612}
      fill="#D5DDE0"
    />
    <rect
      x={53.078}
      y={38.332}
      width={18.883}
      height={3.223}
      rx={1.612}
      fill="#D5DDE0"
    />
    <rect
      x={29.941}
      y={38.332}
      width={18.883}
      height={3.223}
      rx={1.612}
      fill="#D5DDE0"
    />
    <rect
      x={6.635}
      y={38.332}
      width={18.883}
      height={3.223}
      rx={1.612}
      fill="#D5DDE0"
    />
    <rect
      x={148.686}
      y={38.332}
      width={18.883}
      height={3.223}
      rx={1.612}
      fill="#D5DDE0"
    />
    <rect
      x={171.822}
      y={38.332}
      width={18.883}
      height={3.223}
      rx={1.612}
      fill="#D5DDE0"
    />
    <ellipse cx={16.076} cy={26.375} rx={7.74} ry={7.717} fill="#D5DDE0" />
    <ellipse cx={39.383} cy={26.375} rx={7.74} ry={7.717} fill="#D5DDE0" />
    <ellipse cx={62.52} cy={26.375} rx={7.74} ry={7.717} fill="#D5DDE0" />
    <ellipse cx={85.826} cy={26.375} rx={7.74} ry={7.717} fill="#D5DDE0" />
    <ellipse cx={108.963} cy={26.375} rx={7.74} ry={7.717} fill="#D5DDE0" />
    <ellipse cx={133.119} cy={26.375} rx={7.74} ry={7.717} fill="#D5DDE0" />
    <ellipse cx={158.127} cy={26.375} rx={7.74} ry={7.717} fill="#D5DDE0" />
    <ellipse cx={181.264} cy={26.375} rx={7.74} ry={7.717} fill="#D5DDE0" />
  </svg>
);

export default SvgThumbnailCategories;
