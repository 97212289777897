import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Calendar, DateObject } from "react-multi-date-picker";

import CalendarMaterial from "../../components/FiraIcons/CalendarMaterial";
import Input from "../Input/Input";
import {
  completeDate,
  convertDateUTC, formatIsoTimeZone,
  hyphenDateFormat,
  shortDate,
  shortYearDate,
  timeZoneDate,
} from "../../utils/handleDates";
import { RootState } from "../../utils/types";
import { setDateRangeActionCreator } from "../../store/Dashboard/DashboardActions";
import { ArrowLeft, ArrowRight } from "../FiraIcons";

import stylesheet from "./DateRangePickerComponent.module.scss";
import "./calendar-picker.scss";

const {
  DateRangePicker,
  pickerButton,
  opened,
  dateText,
  calendarContainer,
  selectFilter,
  rangeInputs,
  calendarRange,
  icon,
} = stylesheet;

const DateRangePickerComponent: React.FC = () => {
  const [opened, setOpened] = useState(false);
  const { dashboard } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { firstDate, lastDate } = dashboard;
  const openRange = () => {
    setOpened(!opened);
  };

  const onClickOutsideListener = () => {
    setOpened(false);
    document.removeEventListener("click", onClickOutsideListener);
  };

  const showDate = () => {
    if (firstDate == lastDate) {
      return `${completeDate(lastDate ? lastDate : new Date())}`;
    } else {
      return `${shortDate(
        firstDate ? convertDateUTC(firstDate) : new Date()
      )} - ${shortYearDate(lastDate ? lastDate : new Date())}`;
    }
  };

  const handleChangeDate = (startDate: Date, endDate: Date) => {
    dispatch(setDateRangeActionCreator(startDate, endDate));
  };

  return (
    <div className={DateRangePicker}>
      <div
        className={`${pickerButton} ${opened ? opened : ""}`}
        onClick={openRange}
      >
        <CalendarMaterial className={icon} />
        <div className={dateText}>{showDate()}</div>
      </div>
      {opened && (
        <div
          className={calendarContainer}
          onMouseLeave={() => {
            document.addEventListener("click", onClickOutsideListener);
          }}
          onMouseEnter={() => {
            document.removeEventListener("click", onClickOutsideListener);
          }}
        >
          <Personalized
            initDate={firstDate}
            endDate={lastDate}
            onChange={handleChangeDate}
          />
        </div>
      )}
    </div>
  );
};

interface PersonalizedProps {
  initDate: Date;
  endDate: Date;
  onChange(init: Date, end: Date): void;
}
const Personalized = ({ initDate, endDate, onChange }: PersonalizedProps) => {
  const { brand, authentication } = useSelector((state: RootState) => state);
  const [startDate, setStartDate] = useState<Date>(initDate);
  const [endedDate, setEndedDate] = useState<Date>(endDate);
  const [values, setValues] = useState([new DateObject(), new DateObject()]);
  const { t } = useTranslation();
  const weekDays = ["D", "L", "M", "M", "J", "V", "S"];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const callRequest = (init: Date, end: Date | null) => {
    if ((authentication.isAccountOwner || brand.currentStore) && init && end) {
      init.setUTCHours(0);
      init.setUTCMinutes(0);
      end.setUTCHours(23);
      end.setUTCMinutes(59);
      callRequestMode(init, end);
    } else {
      if (brand.currentStore && init) {
        init.setUTCHours(0);
        init.setUTCMinutes(0);
        const newEnd = new Date(init);
        newEnd.setUTCHours(23);
        newEnd.setUTCMinutes(59);
        callRequestMode(init, newEnd);
      }
    }
  };

  const callRequestMode = (init: Date, end: Date) => {
    if (authentication.isAccountOwner || brand.currentStore) {
      onChange(init, end);
      const current = new Date();
      current.setUTCHours(0);
      current.setUTCMinutes(0);
    }
  };

  const handleChange = (objects: DateObject[]) => {
    if (objects[1]) {
      setEndedDate(new Date(objects[1].toString()));
    } else {
      setEndedDate(new Date(objects[0].toString()));
    }
    if (objects[0]) {
      setStartDate(new Date(objects[0].toString()));
    }

    callRequest(
      new Date(objects[0].toString()),
      objects[1] ? new Date(objects[1].toString()) : null
    );
  };

  return (
    <>
      <div className={rangeInputs}>
        <div>
          <h6>{t("views.dashboard.from")}</h6>
          <Input
            key={"start"}
            value={hyphenDateFormat(startDate)}
            width="196px"
            height="36px"
            inputName="startDate"
            onChange={() => {}}
          />
        </div>
        <div>
          <h6>{t("views.dashboard.to")}</h6>
          <Input
            key={"end"}
            value={hyphenDateFormat(endedDate)}
            width="196px"
            height="36px"
            inputName="endedDate"
            onChange={() => {}}
          />
        </div>
      </div>
      <div>
        <Calendar
          value={values}
          onChange={handleChange}
          numberOfMonths={2}
          range
          hideYear
          weekDays={weekDays}
          months={months}
          shadow={false}
          renderButton={CustomButton}
          className="yellow custom-calendar "
        />
      </div>
    </>
  );
};

const CustomButton = (
  direction: String,
  handleClick: any,
  disabled: boolean
) => {
  return (
    <div
      onClick={handleClick}
      style={{
        padding: "0 10px",
        fontWeight: "bold",
        color: disabled ? "black" : "black",
        cursor: "pointer",
      }}
      className={"cursor-pointer"}
    >
      {direction === "right" ? (
        <ArrowRight className="icon" />
      ) : (
        <ArrowLeft className="icon" />
      )}
    </div>
  );
};

export default DateRangePickerComponent;
