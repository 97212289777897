import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgUpDownArrow = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11 14.35V7.34H9v7.01H6l4 3.99 4-3.99h-3ZM4 .34 0 4.33h3v7.01h2V4.33h3L4 .34Zm7 14.01V7.34H9v7.01H6l4 3.99 4-3.99h-3ZM4 .34 0 4.33h3v7.01h2V4.33h3L4 .34Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUpDownArrow;
