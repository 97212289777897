import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSign = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4.131.938c.089.093.159.203.207.324a1.045 1.045 0 0 1-.207 1.09C2.704 3.855 1.903 5.89 1.903 8.013c0 2.122.801 4.157 2.228 5.658.088.093.159.203.206.324a1.045 1.045 0 0 1-.206 1.09.948.948 0 0 1-.309.217.91.91 0 0 1-1.036-.217C-.93 11.178-.93 4.844 2.786.938A.95.95 0 0 1 3.094.72a.91.91 0 0 1 1.037.217Zm12.106 0c3.714 3.907 3.714 10.24 0 14.146a.926.926 0 0 1-.669.28.928.928 0 0 1-.664-.292 1.026 1.026 0 0 1-.278-.699 1.028 1.028 0 0 1 .267-.703c1.426-1.501 2.228-3.536 2.228-5.659 0-2.122-.802-4.157-2.228-5.658a1.027 1.027 0 0 1-.28-.707c0-.266.1-.52.28-.708a.928.928 0 0 1 .671-.293c.253 0 .495.105.673.293ZM6.953 3.812c.178.187.278.442.278.707 0 .265-.1.52-.278.707a3.94 3.94 0 0 0-.809 1.272 4.096 4.096 0 0 0 0 3c.188.476.462.908.809 1.272.09.092.163.202.213.324a1.044 1.044 0 0 1-.202 1.102.948.948 0 0 1-.312.219.91.91 0 0 1-1.044-.231 6.079 6.079 0 0 1-1.649-4.186c0-1.57.593-3.076 1.649-4.186a.928.928 0 0 1 .672-.293c.252 0 .494.105.673.293Zm6.617 0a6.079 6.079 0 0 1 1.649 4.186c0 1.57-.593 3.076-1.65 4.186a.949.949 0 0 1-.308.217.909.909 0 0 1-.728 0 .948.948 0 0 1-.308-.217 1.003 1.003 0 0 1-.207-.324 1.045 1.045 0 0 1 .206-1.09c.346-.364.621-.796.808-1.272a4.095 4.095 0 0 0 0-3 3.938 3.938 0 0 0-.808-1.272 1.027 1.027 0 0 1-.278-.708c0-.265.1-.52.279-.707a.928.928 0 0 1 .672-.292c.253 0 .495.105.673.293Zm-3.982 2.77c.188 0 .373.039.546.114.173.075.33.186.463.325.133.14.238.305.31.487a1.567 1.567 0 0 1 0 1.148 1.507 1.507 0 0 1-.31.486c-.132.14-.29.25-.463.326a1.366 1.366 0 0 1-.546.114c-.378 0-.74-.158-1.008-.44a1.54 1.54 0 0 1-.418-1.06c0-.398.15-.78.418-1.06.267-.282.63-.44 1.008-.44Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSign;
