import { Country } from "../../utils/types";
import { CountriesActions, UtilActions } from "../actionsContants";
import { UtilsActionType } from "./UtilsActions";

export interface UtilsState {
  navBarIsClosed: boolean;
  screenWidth: number;
  isLoading: boolean;
  countries: Country[];
  settingsModalIsOpen: boolean;
  prevPage: string;
}
const initialState: UtilsState = {
  navBarIsClosed: false,
  screenWidth: 0,
  isLoading: false,
  countries: [],
  settingsModalIsOpen: false,
  prevPage: "",
};
export const utilsReducer = (
  state = initialState,
  action: UtilsActionType
): UtilsState => {
  switch (action.type) {
    case UtilActions.setNavbarState: {
      const { payload } = action;
      return { ...state, navBarIsClosed: payload as boolean };
    }
    case UtilActions.currentScreenWidth: {
      const { payload } = action;
      return {
        ...state,
        screenWidth: payload as number,
        navBarIsClosed: payload <= 1200,
      };
    }
    case CountriesActions.getCountriesLoading: {
      return { ...state, isLoading: true };
    }

    case CountriesActions.getCountriesSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        countries: payload as Country[],
      };
    }

    case UtilActions.setSettingsModalState: {
      const { payload } = action;
      return { ...state, settingsModalIsOpen: payload as boolean };
    }
    case UtilActions.setPrevPageState: {
      const { payload } = action;
      return { ...state, prevPage: payload as string };
    }
    default: {
      return state;
    }
  }
};
