import styles from "./TypeSelect.module.scss";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDown } from "../../FiraIcons";

export interface TypeSelectModel {
  value: string;
  name: string;
  icon: ReactElement;
}
interface TypeSelectProps {
  options: TypeSelectModel[];
  onTypeChange?(type: any): void;
  value?: TypeSelectModel;
}

const TypeSelect = ({ options, onTypeChange, value }: TypeSelectProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<TypeSelectModel>();
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const handleSelection = (option: TypeSelectModel) => {
    setSelected(option);
    setShowOptions(false);
  };

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  useEffect(() => {
    if (onTypeChange) {
      onTypeChange(selected);
    }
  }, [selected]);

  return (
    <div className={styles.TypeSelectWrapper}>
      <div
        className={`${styles.TypeSelect} ${showOptions ? styles.isOpen : ""}`}
      >
        <div
          className={`${styles.TypeSelect__Input} ${
            showOptions ? styles.isOpen : ""
          }`}
          onClick={() => setShowOptions(!showOptions)}
        >
          {selected ? (
            <>{selected.name}</>
          ) : (
            <>{t("components.typeSelect.title")}</>
          )}
          <ArrowDown />
        </div>
        {showOptions && (
          <div className={styles.TypeSelect__Options}>
            {options &&
              options.map((option: TypeSelectModel, i: number) => (
                <li key={i} onClick={() => handleSelection(option)}>
                  <div className={styles.Icon}>{option.icon}</div>
                  <span>{option.name}</span>
                </li>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TypeSelect;
