import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import { deleteEventProduct } from "../../../services/events";
import { DeleteCurrentEventProductActionCreator } from "../../../store/Event/EventActions";
import Button, { ButtonStyle } from "../../Button/Button";
import styles from "./DeleteStreamProducts.module.scss";

interface DeleteStreamProductsProps {
  isOpen: boolean;
  productName: string;
  producId: string;
  eventId: string;
  onClose(canDelete: boolean): void;
}
const DeleteStreamProducts = ({
  isOpen = false,
  onClose,
  productName,
  producId,
  eventId,
}: DeleteStreamProductsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCancel = () => {
    onClose(false);
  };
  const handleDelete = () => {
    dispatch(DeleteCurrentEventProductActionCreator(eventId, producId));
    onClose(true);
  };

  if (!isOpen) {
    return null;
  }
  return ReactDOM.createPortal(
    <ModalLayout>
      <div className={styles.deleteModalWrapper}>
        <h5>{t("modals.eventDetail.deleteProducts.title")}</h5>
        <p className="alert-text">
          {t("modals.eventDetail.deleteProducts.message", {
            name: productName,
          })}
        </p>
        <div className={styles.buttonGroup}>
          <Button
            width="132px"
            height="42px"
            buttonStyle={ButtonStyle.transparent}
            clickHandler={handleCancel}
          >
            <h6>{t("modals.eventDetail.deleteProducts.no")}</h6>
          </Button>
          <Button width="132px" height="42px" clickHandler={handleDelete}>
            <h6>{t("modals.eventDetail.deleteProducts.yes")}</h6>
          </Button>
        </div>
      </div>
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};

export default DeleteStreamProducts;
