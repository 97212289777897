import React from "react";

import stylesheet from "./SettingTabsLayout.module.scss";

const { Tab, Selected, Container } = stylesheet;

interface Props {
  title?: string;
  components: {
    tabTitle: string;
    component: React.ReactNode;
  }[];
}
const SettingTabsLayout: React.FC<Props> = ({ title, components }) => {
  const [currentComponent, setCurrentComponent] = React.useState<{
    tabTitle: string;
    component: React.ReactNode;
  }>();

  React.useEffect(() => {
    if (components.length > 0) {
      setCurrentComponent(components[0]);
    }
  }, [components]);
  return (
    <div className={Container}>
      <div style={Wrapper}>
        {title && (
          <div style={{ ...TitleWrapper, ...WrapperBorder }}>{title}</div>
        )}
        <div style={{ ...TabsWrapper, ...WrapperBorder }}>
          {components.map((c, index) => (
            <button
              key={index}
              onClick={() => setCurrentComponent(c)}
              className={`${Tab} ${
                c.tabTitle === currentComponent?.tabTitle ? Selected : ""
              }`}
            >
              <span>{c.tabTitle}</span>
            </button>
          ))}
        </div>
        <div style={ComponentWrapper}>
          {currentComponent && <>{currentComponent.component}</>}
        </div>
      </div>
    </div>
  );
};

const Wrapper: React.CSSProperties = {
  borderRadius: 8,
  backgroundColor: "#fff",
};
const WrapperBorder: React.CSSProperties = {
  borderBottom: "1px solid #D5DDE0",
};
const TitleWrapper: React.CSSProperties = {
  fontSize: 16,
  fontWeight: "700",
  padding: "10px 32px",
};
const TabsWrapper: React.CSSProperties = {
  padding: "10px 32px",
  display: "flex",
};
const ComponentWrapper: React.CSSProperties = {
  padding: "24px 32px",
};

export default SettingTabsLayout;
