import { ChangeEvent, useEffect, useRef, useState } from "react";
import { handleImageLoadError } from "../../utils/handleImageLink";
import noImageStoreSVG from "../../assets/svg/no-image-store.svg";
import { Camera } from "../FiraIcons";
import "./InputImage.scss";

interface Props {
  preview?: string;
  imageResult?(imageFile: File): void;
  isDisabled?: boolean;
  width?: string;
  height?: string;
  noImageHeight?: string;
  noImageWidth?: string;
}
const InputImage = ({
  preview,
  imageResult,
  isDisabled = false,
  width = "100px",
  height = "100px",
  noImageHeight = "100%",
  noImageWidth = "100%",
}: Props) => {
  const [imageFile, setImageFile] = useState<File>();
  const [imagePreview, setImagePreview] = useState<string | undefined>(preview);
  const inputRef = useRef<HTMLInputElement>(null);

  const style = {
    width: width,
    height: height,
  };

  const onWrapperClickHandle = () => {
    inputRef.current?.click();
  };

  const onInputChangeHandle = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      setImageFile(event.target.files[0]);
      setImagePreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  useEffect(() => {
    if (imageFile && imageResult) {
      imageResult(imageFile);
    }
  }, [imageFile, imageResult]);

  useEffect(() => {
    setImagePreview(preview);
  }, [preview]);

  return (
    <div
      onClick={onWrapperClickHandle}
      className="InputImageWrapper"
      style={style}
    >
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        disabled={isDisabled}
        onChange={onInputChangeHandle}
        accept="image/jpeg, image/jpg, image/png, image/svg, image/gif"
      />
      {/* <Camera className="Icon" /> */}
      {imagePreview ? (
        <img
          className="ImagePreview"
          src={imagePreview}
          onError={handleImageLoadError}
          alt="avatar"
        />
      ) : (
        <img
          className="NoImagePreview"
          src={noImageStoreSVG}
          onError={handleImageLoadError}
          alt="avatar"
          width={noImageWidth}
          height={noImageHeight}
        />
      )}
    </div>
  );
};

export default InputImage;
