import * as React from "react";

const GridOfFour = ({
  title,
  titleId,
  ...props
}) => (
<svg 
  width="1.3em"
  height="1em" 
  viewBox="0 0 9 7" 
  fill="none" 
  xmlns="http://www.w3.org/2000/svg">
  <rect width="1.82414" height="1.80556" fill="currentColor"/>
  <rect y="2.1665" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect y="4.33301" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="2.18896" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="2.18896" y="2.1665" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="2.18896" y="4.33301" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="4.37939" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="4.37939" y="2.1665" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="4.37939" y="4.33301" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="6.56592" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="6.56592" y="2.1665" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="6.56592" y="4.33301" width="1.82414" height="1.80556" fill="currentColor"/>
</svg>

);

export default GridOfFour;