import { t } from "i18next";
import { ChangeEvent, useState } from "react";
import { Redirect } from "../../FiraIcons";
import Input from "../../Input/Input";
import styles from "./URLInput.module.scss";

interface UrlInputProps {
  onChange(value: string): void;
}

const URLInput = ({ onChange }: UrlInputProps) => {
  const [url, setUrl] = useState<string>("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUrl(value);
    onChange(value);
  };
  return (
    <div className={styles.URLInputWrapper}>
      <div className={styles.URLInputWrapper__Title}>
        <span>{t("components.widgetUrlInput.title")}</span>
        <Redirect />
      </div>
      <Input width="100%" height="40px" value={url} onChange={handleChange} />
    </div>
  );
};

export default URLInput;
