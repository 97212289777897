import "./VideoControls.scss";
import minireproductor from "../../../assets/svg/minireproductor.svg";
import fullScreen from "../../../assets/svg/fullScreen.svg";
import volume from "../../../assets/svg/volume.svg";
import muted from "../../../assets/svg/muted.svg";
import subtract from "../../../assets/svg/subtract.svg";
import { Dispatch, SetStateAction, useEffect } from "react";

interface VideoControlsProps {
  hideChat: boolean;
  setHideChat: Dispatch<SetStateAction<boolean>>;
  isVideoMuted: boolean;
  setIsVideoMuted: Dispatch<SetStateAction<boolean>>;
  playerRef: HTMLVideoElement | null;
}

const VideoControls: React.FC<VideoControlsProps> = ({
  hideChat,
  setHideChat,
  isVideoMuted,
  setIsVideoMuted,
  playerRef,
}) => {
  const pipButton = document.querySelector(".pip-button") as HTMLDivElement;
  if (!("pictureInPictureEnabled" in document) && pipButton) {
    pipButton.classList.add("d-none");
  }

  const handleMute = () => {
    if (playerRef) {
      if (playerRef.muted == true) {
        playerRef.muted = false;
        setIsVideoMuted(false);
      } else {
        playerRef.muted = true;
        setIsVideoMuted(true);
      }
    }
  };

  const handleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      // @ts-ignore
    } else if (document.webkitFullscreenElement) {
      // Need this to support Safari
      // @ts-ignore
      document.webkitExitFullscreen();
      // @ts-ignore
    } else if (playerRef.webkitRequestFullscreen) {
      // Need this to support Safari
      // @ts-ignore
      playerRef.webkitRequestFullscreen();
    } else {
      playerRef && playerRef.requestFullscreen();
    }
  };

  const handlePip = async () => {
    const pipButton = document.querySelector(
      "#videoControls .pip-button"
    ) as HTMLDivElement;
    try {
      if (
        pipButton &&
        playerRef &&
        playerRef !== document.pictureInPictureElement
      ) {
        pipButton.style.pointerEvents = "none";
        await playerRef.requestPictureInPicture();
      } else {
        await document.exitPictureInPicture();
      }
    } catch (error) {
      console.error(error);
    } finally {
      pipButton.style.pointerEvents = "auto";
    }
  };

  const handleToggleChat = () => {
    setHideChat(!hideChat);
  };

  return (
    <div id="videoControls">
      <div className=" pointer video-control volume-button">
        <img
          src={volume}
          alt="volume"
          onClick={handleMute}
          className={isVideoMuted ? "d-none" : ""}
        />
        <img
          src={muted}
          alt="muted"
          onClick={handleMute}
          className={!isVideoMuted ? "d-none" : ""}
        />
      </div>
      <div className=" pointer video-control pip-button">
        <img src={minireproductor} alt="pipreprod" onClick={handlePip} />
      </div>
      <div className="pointer video-control sub-button">
        <img src={subtract} alt="pipreprod" onClick={handleToggleChat} />
      </div>
      <div className=" pointer video-control fullscreen-button">
        <img src={fullScreen} alt="fullscreen" onClick={handleFullScreen} />
      </div>
    </div>
  );
};

export default VideoControls;
