import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoreProductsActionCreator,
  getStoreProductsGlobalActionCreator,
} from "../../../store/Products/ProductsActions";
import { FiraProduct, RootState } from "../../../utils/types";
import Checkbox from "../../Checkbox/Checkbox";
import EmptyProducts from "../../FiraIcons/EmptyProducts";
import Skeleton from "../../Skeleton/Skeleton";
import {
  checkBoxPosition,
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableImage,
  TableRow,
} from "../../Table/Table";
import styles from "./EventProducts.module.scss";
import noImageStoreSVG from "../../../assets/svg/no-image-store.svg";
import currencyFormat from "../../../utils/currencyFormat";
import {
  EventConfig,
  EventConfigurationContext,
} from "../../../views/EventConfigurationView/EventConfigurationView";
import { getStoreProductsGlobalService } from "../../../services/store";

const EventProducts = () => {
  const { brand, event, authentication } = useSelector(
    (state: RootState) => state
  );
  const dispatch = useDispatch();
  const [selectedArr, setSelectedArr] = useState<string[]>([]);
  const { products } = useSelector((state: RootState) => state);
  const [storeProducts, setStoreProducts] = useState<FiraProduct[]>([]);
  const { t } = useTranslation();
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const {
    eventProducts,
    setEventProducts,
    firaEventProducts,
    setFiraEventProducts,
    configType,
  } = useContext(EventConfigurationContext);

  const handleProductFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setSearchInputValue(keyword);

    if (searchInputValue != "") {
      setStoreProducts(
        products.products.filter((p) => {
          return String(p.name)
            .replace("null", "")
            .toLowerCase()
            .includes(keyword.toLowerCase());
        })
      );
    } else {
      setStoreProducts(products.products);
    }
  };

  const getProducts = () => {
    if (authentication.isAccountOwner && brand.currentStore === undefined) {
      dispatch(getStoreProductsGlobalActionCreator());
    } else if (event.currentEvent) {
      dispatch(getStoreProductsActionCreator(event.currentEvent.storeId));
    }
  };

  const handleProductCheckbox = (id: string) => {
    if (selectedArr.includes(id)) {
      setFiraEventProducts((storeProducts: any) => [
        ...firaEventProducts.filter((p: any) => id !== p.id),
      ]);
      return;
    } else {
      let add = storeProducts.find((obj: any) => obj.id === id);
      setFiraEventProducts([...firaEventProducts, add]);
    }
  };

  const checkSelection = (id: string) => {
    if (selectedArr && selectedArr.includes(id)) return true;
  };

  const handleHeaderCheckbox = () => {
    if (storeProducts.length === 0) {
      return;
    }

    if (selectedArr.length === storeProducts.length) {
      setFiraEventProducts([]);
    } else {
      const arr = storeProducts.map((item) => {
        return item;
      });
      setFiraEventProducts(arr);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    setStoreProducts(products.products);
  }, [products]);

  useEffect(() => {
    if (firaEventProducts) {
      let productsArray: string[] = [];
      firaEventProducts.map((item: any) => {
        productsArray.push(item.id);
      });
      setSelectedArr(productsArray);
    }
  }, [firaEventProducts]);

  const productsHeader = [
    { name: "", size: "22px" },
    { name: "", size: "70px" },
    { name: t("views.products.tableHeader.name"), size: "300px" },
    { name: "SKU", size: "130px" },
    { name: t("views.products.tableHeader.inventory"), size: "130px" },
    { name: `${t("views.products.tableHeader.price")}`, size: "" },
    { name: `${t("views.products.tableHeader.discount")}`, size: "" },
    { name: "", size: "42px" },
    { name: "", size: "10px" },
  ];

  const header = (
    <TableRow>
      {productsHeader.map((head, i) => (
        <TableHead key={i} size={head.size} centerContent={i !== 2}>
          {head.name}
        </TableHead>
      ))}
    </TableRow>
  );
  const mainStore = brand.list.find((item) => item.storeType === "MAIN_STORE");

  const getCurrencyStore = () => {
    if (authentication.isAccountOwner && !brand.currentStore) {
      return mainStore?.regionalConfiguration.currency;
    } else {
      return brand.currentStore?.regionalConfiguration.currency;
    }
  };
  const body = storeProducts.map((product) => {
    return (
      <TableRow
        key={product.id}
        onClick={() => {
          handleProductCheckbox(product.id);
        }}
      >
        <TableCell size="22px"></TableCell>
        <TableCell size="70px">
          <TableImage
            src={
              product.images.length === 0 || product.images[0] === ""
                ? noImageStoreSVG
                : product.images[0]
            }
          />
        </TableCell>
        <TableCell size="180px">
          <span className="LimitText">{product.name}</span>
        </TableCell>
        <TableCell centerContent>{product.sku}</TableCell>
        <TableCell size="100px" centerContent>
          {product.quantity}
        </TableCell>
        <TableCell centerContent>
          <span className="ellipsis">
            {currencyFormat(parseFloat(product.price1), getCurrencyStore())}
          </span>
        </TableCell>
        <TableCell centerContent>
          <span className="ellipsis">
            {currencyFormat(parseFloat(product.price2), getCurrencyStore())}
          </span>
        </TableCell>
        <TableCell size="42px">
          <Checkbox isActive={checkSelection(product.id)} />
        </TableCell>
        <TableCell size="10px"></TableCell>
      </TableRow>
    );
  });

  const EmptyProductView = () => {
    return (
      <div className="EmptyWrapper">
        <EmptyProducts />
      </div>
    );
  };

  const placeholder = [
    { id: 1, name: "placeholder" },
    { id: 2, name: "placeholder" },
    { id: 3, name: "placeholder" },
    { id: 4, name: "placeholder" },
    { id: 5, name: "placeholder" },
    { id: 6, name: "placeholder" },
    { id: 7, name: "placeholder" },
    { id: 8, name: "placeholder" },
    { id: 9, name: "placeholder" },
    { id: 10, name: "placeholder" },
  ];

  const SkeletonTableBody = placeholder.map((data) => {
    return (
      <TableRow key={data.id}>
        <TableCell size="80px">
          <Checkbox />
        </TableCell>
        <TableCell size="90px">
          <Skeleton width="50px" height="49px" />
        </TableCell>
        <TableCell size="160px">
          <div className="centerSkeleton">
            <Skeleton width="104px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="160px">
          <div className="centerSkeleton">
            <Skeleton width="104px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="160px">
          <div className="centerSkeleton">
            <Skeleton width="104px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="160px">
          <div className="centerSkeleton">
            <Skeleton width="104px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="160px">
          <div className="centerSkeleton">
            <Skeleton width="104px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="160px">
          <div className="centerSkeleton">
            <Skeleton width="104px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="49px"></TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <div className={styles.EventProductsWrapper}>
        <TableHeader
          checkBoxPosition={checkBoxPosition.right}
          checkboxHandle={handleHeaderCheckbox}
          checkboxIsActive={
            selectedArr && selectedArr.length === storeProducts.length
          }
          searchInputValue={searchInputValue}
          searchInputChangeHandle={handleProductFiltering}
        />
        {storeProducts.length > 0 ? (
          <div className={styles.TableWrapper}>
            <Table
              header={header}
              body={products.isLoading ? SkeletonTableBody : body}
            />
          </div>
        ) : (
          <EmptyProductView />
        )}
      </div>
    </>
  );
};

export default EventProducts;
