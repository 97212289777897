import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, StoreStatusType } from "../../../utils/types.d";
import { ArrowDownFill, MagnifyingGlass } from "../../FiraIcons";
import NoImage from "../../../assets/svg/no-image-store.svg";
import styles from "./ContextChangeSelect.module.scss";
import {
  removeCurrentStoreActionCreator,
  setCurrentStoreActionCreator,
} from "../../../store/Brand/BrandActions";
import {
  getStoreBy,
  getUserStoresService,
  ownerStoreListByStateService,
} from "../../../services/store";
import { FiraUserRole } from "../../../utils/enums";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "../../../utils/useClickOutside";
import RadioButton from "../../RadioButton/RadioButton";
import { useNavigate } from "react-router-dom";
import { setNavbarState } from "../../../store/Utils/UtilsActions";
import { FiraStore } from "../../../types/Stores";

interface ContextChangeSelectProps {
  onClose(): void;
}

interface SimpleStoreItem {
  storeId: string;
  storeName: string;
  logo: string;
}

interface AccountInfoType {
  name: string;
  logo: string;
  role: FiraUserRole | null;
}

const selectedStoreInitialState = {
  storeId: "",
  storeName: "",
  logo: "",
};

const ContextChangeSelect = ({ onClose }: ContextChangeSelectProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, authentication, brand, utils } = useSelector(
    (state: RootState) => state
  );
  const [accountInfo, setAccountInfo] = useState<AccountInfoType>({
    name: "",
    logo: "",
    role: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCurrentStore = (store: FiraStore) => {
    setIsLoading(true);
    authentication.sessionId &&
      dispatch(setCurrentStoreActionCreator(authentication.sessionId, store));
    navigate("/home/dashboard");
  };

  const [stores, setStores] = useState<SimpleStoreItem[]>([]);
  const [accountOwnerStores, setAccountOwnerStores] = useState<FiraStore[]>([]);
  const [selectedStore, setSelectedStore] = useState<SimpleStoreItem>(
    selectedStoreInitialState
  );

  const useRegularUserStores = async () => {
    try {
      await getUserStoresService().then((val) => {
        const data = val.data as any;
        let newData: SimpleStoreItem[] = [];

        data.map((store: any) => {
          newData.push({
            storeId: store.id,
            storeName: store.name,
            logo: store.urlLogo,
          });
        });

        setStores(newData);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const useAccountOwnerStores = async () => {
    if (authentication.user?.businessClientId) {
      try {
        await ownerStoreListByStateService(
          authentication.user.businessClientId,
          StoreStatusType.ACTIVE
        ).then((val) => {
          setAccountOwnerStores(val.data);

          let newData: SimpleStoreItem[] = [];
          val.data.map((store: any) => {
            newData.push({
              storeId: store.id,
              storeName: store.name,
              logo: store.brand !== null ? store.brand.logo : "",
            });
          });

          setStores(newData);
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleStoreSelection = async (store: SimpleStoreItem) => {
    if (authentication && authentication.isAccountOwner && accountOwnerStores) {
      const selectedStore = accountOwnerStores.find(
        (item) => item.id === store.storeId
      );
      selectedStore && handleCurrentStore(selectedStore);
    } else if (!authentication.isAccountOwner) {
      const selectedStore = await getStoreBy(store.storeId);
      selectedStore && handleCurrentStore(selectedStore.data);
    }
  };

  useEffect(() => {
    if (authentication.isAccountOwner) {
      useAccountOwnerStores();
    } else {
      useRegularUserStores();
    }
  }, [authentication]);

  useEffect(() => {
    if (user) {
      setAccountInfo({
        name: `${user.info?.firstName} ${user.info?.lastName}` || "",
        logo: user.info?.avatar || "",
        role: user.info?.permissionsStore[0].role || null,
      });
    }
  }, [user]);

  useEffect(() => {
    if (brand.currentStore) {
      setSelectedStore(
        {
          storeId: brand.currentStore.id,
          storeName: brand.currentStore.name,
          logo:
            brand.currentStore.brand !== null
              ? brand.currentStore.brand.logo
              : "",
        } || selectedStoreInitialState
      );
      setIsLoading(false);
    }
  }, [brand.currentStore]);

  return (
    <>
      <ContextChange
        account={accountInfo}
        data={stores}
        isLoading={isLoading}
        isAccountOwner={authentication.isAccountOwner || false}
        onSelectedStore={handleStoreSelection}
        selectedStore={selectedStore}
      />
    </>
  );
};

interface ContextChangeProps {
  data: SimpleStoreItem[];
  account: AccountInfoType;
  isLoading: boolean;
  isAccountOwner: boolean;
  onSelectedStore(store: SimpleStoreItem): void;
  selectedStore: SimpleStoreItem;
}

const ContextChange = ({
  data,
  account,
  isLoading,
  isAccountOwner,
  onSelectedStore,
  selectedStore,
}: ContextChangeProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brand, authentication, utils } = useSelector(
    (state: RootState) => state
  );
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [storeList, setStoreList] = useState<SimpleStoreItem[]>([]);
  const [storeListForFiltering, setStoreListForFiltering] = useState<
    SimpleStoreItem[]
  >([]);
  const domNode = useClickOutside(() => setIsOptionsOpen(false));
  const [activeRadio, setActiveRadio] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(isLoading);
  const inputRef = useRef<HTMLInputElement>(null);

  const isCurrentStore = (id: string) => {
    if (brand.currentStore) {
      return brand.currentStore.id === id;
    }
  };

  const goBackToAccountOwner = async () => {
    setActiveRadio(true);
    setLoading(true);
    if (authentication.sessionId) {
      navigate("/home/mainboard");
      await dispatch(removeCurrentStoreActionCreator(authentication.sessionId));
    }
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setSearchInputValue(keyword);

    if (keyword !== "") {
      setStoreListForFiltering(
        storeList.filter((p) => {
          return String(p.storeName)
            .replace("null", "")
            .toLowerCase()
            .includes(keyword.toLocaleLowerCase());
        })
      );
    } else {
      setStoreListForFiltering(storeList || []);
    }
  };

  useEffect(() => {
    if (data) {
      setStoreList(data || []);
      setStoreListForFiltering(data || []);
    }
  }, [data]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!isOptionsOpen) {
      setSearchInputValue("");
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      setStoreListForFiltering(storeList);
    }
  }, [isOptionsOpen]);

  return (
    <>
      <div className={styles.ContextChangeSelectWrapper} ref={domNode}>
        <div
          className={styles.ContextChangeSelectWrapper__Input}
          onClick={() => setIsOptionsOpen(!isOptionsOpen)}
        >
          <div className={styles.LogoContainer}>
            <img
              src={selectedStore?.logo || NoImage}
              alt={selectedStore?.storeName || ""}
            />
          </div>
          <div className={styles.AccountTitle}>
            <span>{selectedStore?.storeName || ""}</span>
          </div>
          <div className={styles.ContextChangeSelectWrapper__Input__Icon}>
            <ArrowDownFill width={10} color={"#5C5F62"} />
          </div>
        </div>

        {isOptionsOpen && (
          <div
            className={`${styles.ContextChangeSelectWrapper__Options} ${
              !isAccountOwner ? styles.IsRegularUser : ""
            }`}
          >
            {isAccountOwner && (
              <div
                className={styles.AccountOwner}
                onClick={goBackToAccountOwner}
              >
                <div className={styles.AccountOwner__Logo}>
                  <img
                    src={
                      account.logo !== null && account.logo ? account.logo : ""
                    }
                    alt={account.name || ""}
                  />
                </div>
                <div className={styles.AccountOwner__Name}>
                  {t("components.selects.contextChange.yourAccount")}
                </div>
                <div className={styles.AccountOwner__RadioButton}>
                  <RadioButton isActive={activeRadio} />
                </div>
              </div>
            )}
            {isAccountOwner && <hr />}

            {/* Search Input */}
            <div className={styles.SearchInput}>
              <MagnifyingGlass
                fontSize={"24px"}
                color={"#ABAFB1"}
                className={styles.SearchIcon}
              />
              <input
                type="text"
                placeholder={t("views.events.header.filter")}
                value={searchInputValue || ""}
                onChange={handleSearch}
                ref={inputRef}
              />
            </div>

            <hr />

            {/* Options */}
            <div className={styles.StoresList}>
              <ul className={loading ? styles.disable : ""}>
                {storeListForFiltering &&
                  storeListForFiltering?.map((store: SimpleStoreItem) => (
                    <li
                      className={`${styles.ListItem} ${
                        loading ? styles.disable : ""
                      }
                      ${
                        isCurrentStore(store.storeId)
                          ? styles.isActiveBrand
                          : ""
                      }
                    `}
                      key={store.storeId}
                      style={loading ? { cursor: "wait" } : {}}
                      onClick={() => {
                        onSelectedStore(store),
                          setIsOptionsOpen(!isOptionsOpen);
                      }}
                    >
                      <div className={styles.LogoContainer}>
                        <img
                          src={store.logo ? store.logo : NoImage}
                          alt={store.storeName ? store.storeName : ""}
                          width={"32px"}
                        />
                      </div>
                      <span>{store.storeName || ""}</span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ContextChangeSelect;
