import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";

import {
  MessageType,
  ModerationReason,
  useAgoraSignaling,
} from "../hooks/useAgoraSignaling";
import {
  ChatUser,
  FiraChatConfiguration,
  GetChatHistoricalResponse,
  GetChatSummaryResponse,
  PinMessage,
  UserMessage,
} from "../types";
import { addUserToLS, getUser, updateUserInLS } from "../utils/usersUtils";
import { getChatHistorical, getChatSummary, getToken } from "../services";

export interface FiraChatUser {
  userId: string;
  username?: string;
  isModerator: boolean;
  token?: string;
}

export enum ChatStatus {
  connected = "CONNECTED",
  disconnected = "DISCONNECTED",
  connecting = "CONNECTING",
  disconnecting = "DISCONNECTING",
  reconnecting = "RECONNECTING",
}

export enum ChatModes {
  PLAYER,
  ADMIN,
}

interface ChatContextType {
  status: ChatStatus;
  currentUser?: FiraChatUser;
  messageList: UserMessage[];
  pinnedMessage?: UserMessage;
  showRetryScreen: boolean;
  sendNewMessage: (msg: string) => Promise<boolean>;
  registerToChat: (username: string) => void;
  getChatMode: () => ChatModes;
  getChatConfig: () => FiraChatConfiguration;
  getChatSrc: () => string;
  getEventStatus: () => string;
  moderateUser: (reason: ModerationReason, userId: string) => void;
  isUserBanned: (userId: string) => boolean;
  pinMessage: (message: UserMessage) => Promise<boolean>;
  deleteMessage: (message: UserMessage) => Promise<boolean>;
  isMessagePin: (messageId: string) => boolean;
  retryConnection: () => void;
  startModerator: (
    userId: string,
    token: string,
    isModerator: boolean,
    username?: string
  ) => Promise<void>;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

const defaultChatConfig: FiraChatConfiguration = {
  enableLogin: false,
  mainColor: "#C707FF",
  loginForm: [],
  disclaimerUrl: "",
};

interface ChatProviderProps {
  children: ReactNode;
  src: string;
  eventStatus: string;
  eventStartDate: string;
  mode?: ChatModes;
  config?: FiraChatConfiguration;
  isGlobal?: boolean;
  parentId?: string;
}
export const ChatProvider: React.FC<ChatProviderProps> = ({
  children,
  src,
  eventStatus,
  eventStartDate,
  mode = ChatModes.PLAYER,
  config = defaultChatConfig,
  isGlobal = false,
  parentId,
}) => {
  const [currentUser, setCurrentUser] = useState<FiraChatUser>();
  const [status, setStatus] = useState<ChatStatus>(ChatStatus.disconnected);
  const [messageList, setMessageList] = useState<UserMessage[]>([]);
  const [pinnedMessage, setPinnedMessage] = useState<PinMessage>();
  const [showRetryScreen, setShowRetryScreen] = useState(false);

  const changeChatStatus = (currentStatus: ChatStatus) => {
    setStatus(currentStatus);
  };

  const getChatMode = () => mode;

  const getChatSrc = () => src;

  const getChatConfig = () => config;

  const getEventStatus = () => eventStatus;

  const processMessage = (message: UserMessage) => {
    switch (message.type) {
      case MessageType.NORMAL:
        setMessageList((prevState) => [...prevState, message]);
        break;
      case MessageType.PIN:
        togglePinMessage(message as PinMessage);
        break;
      case MessageType.DELETE:
        setMessageList((prevState) =>
          prevState.filter((value) => value.itemId !== message.itemId)
        );
        break;
      default:
        setMessageList((prevState) => [...prevState, message]);
        break;
    }
  };

  const {
    connect,
    login,
    sendMessage,
    sendPinMessage,
    sendDeleteMessage,
    processBanSummary,
    banUser,
    unbanUser,
    bannedUsers,
  } = useAgoraSignaling({
    eventStartDate,
    changeChatStatus,
    processMessage,
  });

  const processChatSummary = (summary: GetChatSummaryResponse) => {
    const { pinnedMessage, bannedUsers } = summary;
    processBanSummary(bannedUsers);
    if (pinnedMessage) {
      const { chatUser, externalId, message } = pinnedMessage;
      const { name, userId, role } = chatUser;

      setPinnedMessage({
        from: name,
        itemId: externalId,
        msg: message,
        type: MessageType.PIN,
        userId,
        isAdmin: role === "moderator",
        time: new Date().getTime(),
      });
    }
  };

  const processChatHistorical = (historical: GetChatHistoricalResponse[]) => {
    const oldMessageHistorical = historical.map((msg): UserMessage => {
      const { externalId, text, createdAt, chatUser } = msg;
      const { id, name, role } = chatUser;
      const messageType =
        role === "fira_audience" ? MessageType.NORMAL : MessageType.ADMIN;

      const time = new Date(createdAt).getTime();

      return {
        itemId: externalId,
        userId: id,
        type: messageType,
        from: name,
        msg: text,
        time,
      };
    });

    setMessageList((prevState) => {
      return [...oldMessageHistorical.reverse(), ...prevState];
    });
  };

  const sendNewMessage = async (msg: string) => {
    if (status === ChatStatus.connected) {
      const type =
        mode === ChatModes.PLAYER ? MessageType.NORMAL : MessageType.ADMIN;

      const response = await sendMessage(
        msg,
        currentUser?.username || "anon",
        type
      );
      return response;
    } else {
      throw new Error("need to connect to a chat first");
    }
  };

  const pinMessage = async (message: UserMessage) => {
    if (status === ChatStatus.connected) {
      if (mode === ChatModes.PLAYER) return false;
      const response = await sendPinMessage(message);
      return response;
    } else {
      throw new Error("need to connect to a chat first");
    }
  };

  const deleteMessage = async (message: UserMessage) => {
    if (status === ChatStatus.connected) {
      if (mode === ChatModes.PLAYER) return false;
      const response = await sendDeleteMessage(message);
      return response;
    } else {
      throw new Error("need to connect to a chat first");
    }
  };

  const togglePinMessage = (message: PinMessage) => {
    setPinnedMessage((prevState) => {
      if (prevState) {
        if (prevState.itemId === message.itemId) return undefined;
        else return message;
      } else return message;
    });
  };

  const isMessagePin = (messageId: string): boolean => {
    if (pinnedMessage) {
      return pinnedMessage.itemId === messageId;
    }

    return false;
  };

  const registerToChat = (username: string) => {
    const currentLSUser = getUser(src);
    updateUserInLS(
      {
        name: username,
        banned: false,
        color: "",
        id: currentLSUser!.id,
        role: "fira_audience",
      },
      src,
      false
    );
    setCurrentUser((prevState) => {
      if (prevState) {
        return { ...prevState, username };
      }
      return { username, userId: currentLSUser!.id, isModerator: false };
    });
  };

  const moderateUser = (reason: ModerationReason, userId: string) => {
    switch (reason) {
      case ModerationReason.BAN:
        banUser(userId);
        break;
      case ModerationReason.UNBAN:
        unbanUser(userId);
        break;
    }
  };

  const isUserBanned = (userId: string): boolean => {
    return bannedUsers.includes(userId);
  };

  const initAgoraConnection = async (
    src: string,
    userId: string,
    token: string,
    parentId?: string
  ) => {
    connect(src, parentId);
    await login(userId, token);
  };

  const startModerator = async (
    userId: string,
    token: string,
    isModerator: boolean,
    username?: string
  ) => {
    try {
      setShowRetryScreen(false);
      if (isGlobal) await initAgoraConnection(src, userId, token, parentId);
      else await initAgoraConnection(src, userId, token);

      setCurrentUser((prevState) => ({
        ...prevState,
        isModerator,
        userId,
        token,
        username,
      }));
    } catch (error) {
      console.error("ERROR ON LOGIN", error);
      setShowRetryScreen(true);
    }
  };

  const startPlayer = async () => {
    const currentLSUser = getUser(src);
    if (currentLSUser) {
      try {
        const { data } = await getToken(currentLSUser.id);
        setShowRetryScreen(false);
        if (isGlobal)
          await initAgoraConnection(
            src,
            currentLSUser.id,
            data.token,
            parentId
          );
        else await initAgoraConnection(src, currentLSUser.id, data.token);

        setCurrentUser({
          token: data.token,
          username: currentLSUser.name,
          userId: currentLSUser.id,
          isModerator: false,
        });
      } catch (error) {
        console.error("ERROR ON LOGIN", error);
        setShowRetryScreen(true);
      }
    } else {
      const newAnonUser: ChatUser = {
        id: uuidv4(),
        name: `anon`,
        banned: false,
        color: "",
        role: "fira_audience",
      };
      addUserToLS(newAnonUser, src, true);
      startPlayer();
    }
  };

  const retryConnection = async () => {
    setShowRetryScreen(false);
    switch (mode) {
      case ChatModes.PLAYER:
        startPlayer();
        break;
      case ChatModes.ADMIN:
        if (currentUser && currentUser.token) {
          const { userId, isModerator, token, username } = currentUser;
          startModerator(userId, token, isModerator, username);
        } else startPlayer();
        break;
    }
  };

  useEffect(() => {
    const getSummary = async () => {
      try {
        const response = await getChatSummary(src);
        processChatSummary(response.data);
      } catch (error) {
        console.log("ERROR GETTING SUMMARY", error);
      }
    };

    const getHistorical = async () => {
      try {
        const response =
          isGlobal && parentId
            ? await getChatHistorical(parentId, isGlobal, parentId)
            : await getChatHistorical(src);
        processChatHistorical(response.data);
      } catch (error) {
        console.log("ERROR GETTING HISTORICAL", error);
      }
    };

    if (eventStatus === "STARTED") {
      getSummary();
      getHistorical();
    }
  }, [eventStatus]);

  useEffect(() => {
    const initChat = () => {
      switch (mode) {
        case ChatModes.PLAYER:
          startPlayer();
          break;
      }
    };

    if (eventStatus === "STARTED" && mode === ChatModes.PLAYER) {
      initChat();
    }
  }, [eventStatus, mode]);

  useEffect(() => {
    if (status === ChatStatus.disconnected && eventStatus === "STARTED") {
      setShowRetryScreen(true);
    }
  }, [status, eventStatus]);

  return (
    <ChatContext.Provider
      value={{
        status,
        currentUser,
        messageList,
        pinnedMessage,
        showRetryScreen,
        sendNewMessage,
        registerToChat,
        getChatMode,
        getChatConfig,
        getChatSrc,
        getEventStatus,
        moderateUser,
        isUserBanned,
        pinMessage,
        deleteMessage,
        isMessagePin,
        retryConnection,
        startModerator,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);

  if (context === undefined) {
    throw new Error("useChatContext must be use within an ChatProvider");
  }

  return context;
};
