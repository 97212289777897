export const validImageForEvents = (image: File) => {
  const regex = /.(jpg|jpeg|png)$/i;

  const fileType = image.type;
  const fileSize = image.size;

  return fileType.match(regex) && fileSize < 5242880 ? true : false;
};

export const validVideoForEvents = (video: File) => {
  const regex = /.(mp4|mpeg4)$/i;
  const fileType = video.type;
  const fileSize = video.size;

  return fileType.match(regex) && fileSize < 5242880 ? true : false;
};
