import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgBigSuccess = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 173 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect x={39.227} y={8} width={110} height={110} rx={55} fill="#FFDE07" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m86.106 73.56 31.936-31.714 4.861 4.827-36.797 36.54-19.614-19.476 4.86-4.827 14.754 14.65Z"
      fill="#fff"
    />
    <circle cx={48.227} cy={5} r={4} stroke="#FFDE07" strokeWidth={2} />
    <circle cx={46.227} cy={117} r={2} fill="#FFDE07" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m167.281 11.679-1.897 4.211-1.387-.625 1.897-4.211-4.212-1.897.625-1.388 4.212 1.897 1.897-4.211 1.387.625-1.897 4.211 4.212 1.897-.625 1.388-4.212-1.897Z"
      fill="#FFDE07"
      stroke="#FFDE07"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.6 71.383-3.067.17-.056-1.01 3.067-.17-.17-3.067 1.01-.056.17 3.066 3.067-.17.056 1.01-3.067.17.171 3.068-1.01.056-.17-3.067Z"
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.665}
    />
  </svg>
);

export default SvgBigSuccess;
