import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
// import { firaLiveWidget, playFiraModal, getNextEvents } from "firalibrary";
// import { format } from "date-fns";
import NoImageStore from "./NoImageStore";
// import * as Locales from "date-fns/locale";

import { useTranslation } from "react-i18next";

import "./GridEventsWidget.scss";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";


const lang = navigator.language.split(/-|_/)[0];

function hourFormat(date) {
  
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var newformat = hours >= 12 ? 'PM' : 'AM'; 
  
  hours = hours % 12; 

  hours = hours ? hours : 12; 
  minutes = minutes < 10 ? '0' + minutes : minutes;
   
  return hours + ":" + minutes + " " + newformat;
}

const parseDate = (date) => {
  const new_date=new Date(date);
  const d = new_date.toLocaleDateString('es-ES', {weekday:"long"})+", "+new_date.toLocaleDateString('es-ES', {year:"numeric", month:"long"});//format(new Date(date), "ccc dd MMMM", { locale: locale() });
  const h = hourFormat(new_date);//format(new Date(date), "hh:mm a", { locale: locale() });
  return `${d} | ${h}`;
};

// const getEvents = async (key) => {
//   try {
//     const events = await getNextEvents(key);
//     return events;
//   } catch (error) {
//     console.error("error");
//     return [];
//   }
// };

const GridEventsWidget = ({
  firaKey,
  primaryTextColor,
  secondaryTextColor,
  buttonTextColor,
  primaryColor,
  cardColor,
  backgroundColor,
  title = "Próximos eventos",
  type,
}) => {

  const { t } = useTranslation();
  const [eventsList, setEventsList] = useState([{}]);
  const [showMore, setShowMore] = useState(false);

  const dataPreview=[
    {
      "id": "636a559eefc8e70f4872c8ff",
      "scheduledDate": "2022-12-12T13:26:00.000+00:00",
      "eventDescription": t('views.firaTv.exampleItems.eventDescription'),
      "evenTeaser": null,
      "eventPreviewImage": null,
      "firaEventCategory": null
    },
    {
      "id": "636a56c8efc8e70f4872c900",
      "scheduledDate": "2022-12-12T13:26:00.000+00:00",
      "eventDescription": t('views.firaTv.exampleItems.eventDescription'),
      "evenTeaser": null,
      "eventPreviewImage": null,
      "firaEventCategory": null
    },
    {
      "id": "636a58aaefc8e70f4872c901",
      "scheduledDate": "2022-12-12T13:26:00.000+00:00",
      "eventDescription": t('views.firaTv.exampleItems.eventDescription'),
      "evenTeaser": null,
      "eventPreviewImage": null,
      "firaEventCategory": null
    }
  ]

  const handleMoreElements = () => {
    // setShowMore(!showMore);
    setShowMore(false);
  };

  const openPlayer = (firaSrc) => {
    playFiraModal(firaKey, firaSrc);
  };
 
  const callEndpoint=async ()=>{
    //const data= await getNextEvents(firaKey,type);
    setEventsList(dataPreview);//data.list);
   };

  useEffect(() => {
    
  }, [firaKey]);

  useEffect(() => {
    //setEventsList(dataPreview);
    // firaLiveWidget(firaKey);
    callEndpoint();
  }, []);

  return (
   
 <div className={"fira-next-events-container"} style={{backgroundColor:backgroundColor}}>
      <div className={"fira-next-events-wrapper"} >
        <div className={"fira-next-events-header"}>
          <div className={"fira-next-events-title"} style={{color: primaryTextColor}}>{title}</div>
          <p
            onClick={() => handleMoreElements()}
            className={"fira-next-events-see-more"}
            style={{ color: secondaryTextColor }}
          >
            {
          (() => {
            switch(lang) {
              case "es":
                return <>{showMore ? "Ver menos" : "Ver más"}</>
              case "en":
                return <>{showMore ? "Show less" : "Show more"}</>
              case "pt": 
              return <>{showMore ? "Ver menos" : "Ver mais"}</>
              default:
                return <>{showMore ? "Ver menos" : "Ver más"}</>
            }
          })() 
        }
            
          </p>
        </div>

        <div
          className={"fira-next-events-divisor"}
          style={{ background: primaryColor }}
        ></div>

        <div
          className={`${"fira-next-events-elements"} ${
            showMore ? "more-elements" : ""
          }`}
        >
          {eventsList.slice(0, 6).map((event, key) => {
            return (
              <EventElement
                key={key}
                title={event.eventName}
                description={event.eventDescription}
                date={parseDate(event.scheduledDate)}
                primaryTextColor={primaryTextColor}
                secondaryTextColor={secondaryTextColor}
                buttonTextColor={buttonTextColor}
                buttonColor={primaryColor}
                cardColor={cardColor}
                image={event.eventPreviewImage}
                playerHandler={() => openPlayer(event.id)}
              />
            );
          })}
        </div>

        <div className="" style={{ overflow: "hidden" }}>
          <Swiper
            slidesPerView={3}
            spaceBetween={16}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            loop={true}
            centeredSlides={true}
            className={"fira-next-event-swipper"}
            style={{
              "--swiper-theme-color": primaryColor,
            }}
          >
            {eventsList.map((event, key) => {
              return (
                <SwiperSlide key={key}>
                  
                  <EventElement
                    key={key}
                    title={event.eventName}
                    description={event.eventDescription}
                    date={parseDate(event.scheduledDate)}
                    primaryTextColor={primaryTextColor}
                    secondaryTextColor={secondaryTextColor}
                    buttonTextColor={buttonTextColor}
                    buttonColor={primaryColor}
                    cardColor={cardColor}
                    image={event.eventPreviewImage}
                    playerHandler={() => openPlayer()}
                  />
                  
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
    
   
  );
};

const EventElement = ({
  title,
  description,
  date,
  image,
  playerHandler,
  primaryTextColor,
  secondaryTextColor,
  buttonTextColor,
  buttonColor,
  cardColor,
}) => {

  const { t } = useTranslation();
  return (
    <div
      className={"fira-next-event-wrapper"}
      style={{ background: cardColor }}
    >
      <div onClick={playerHandler}>
        {image ? (
          <img
            src={image}
            alt={"'fallo'"}
            className={"fira-next-event-image"}
          ></img>
        ) : (
          <div className={"fira-next-event-image-error"}>
            <NoImageStore />
          </div>
        )}

        <div
          className={"fira-next-event-date"}
          style={{ color: secondaryTextColor }}
        >
          {t('views.firaTv.exampleItems.fakeDate')} | 9:26 AM
        </div>

        <div onClick={playerHandler} className={"fira-next-event-title"} style={{color:primaryTextColor}}>
          {title}
        </div>

        <div className={"fira-next-event-description"} style={{color:primaryTextColor}}>{description}</div>
      </div>

      <button
        onClick={playerHandler}
        className={"fira-next-event-button"}
        style={{ background: buttonColor, color: buttonTextColor }}
      >
        {
          (() => {
            switch(lang) {
              case "es":
                return <>Ver más</>
              case "en":
                return <>Show more</>
              case "pt": 
                return <>Ver mais</>
              default:
                return <>Ver más</>
            }
          })() 
        }
      </button>
    </div>
  );
};

export default GridEventsWidget;
