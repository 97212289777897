import React, { ReactElement, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { FiraUser, RootState } from "../../utils/types";

import stylesheet from "./DashboardTabLayout.module.scss";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import DateRangePickerComponent from "../../components/DateRangePickerComponent/DateRangePickerComponent";
import { FiraUserResponse } from "../../types/Response";

import { setDateRangeActionCreator } from "../../store/Dashboard/DashboardActions";
import { getOwnerInfoData } from "../../store/Authentication/actions";

const {
  DashboardTabWrapper,
  DashboardTabButtonWrapper,
  TabsWrapper,
  Content,
  GreetingsWrapper,
  TextWrapper,
  Title,
  Info,
  Active,
} = stylesheet;

const getCurrentGreetings = (): string => {
  var currentHour = new Date().getHours();

  if (currentHour >= 0 && currentHour < 12) {
    return "views.dashboard.greetings.goodMorning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "views.dashboard.greetings.goodAfternoon";
  } else if (currentHour >= 18 && currentHour < 24) {
    return "views.dashboard.greetings.goodEvening";
  } else {
    return "views.dashboard.greetings.goodMorning";
  }
};

interface Props {
  tabs: { title: string; element: ReactElement }[];
  hideCalendar?: boolean;
}
const DashboardTabLayout: React.FC<Props> = ({
  tabs,
  hideCalendar = false,
}) => {
  const { user, authentication } = useSelector((state: RootState) => state);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const dispatch = useDispatch();
  const onTabPressed = (tabSelectedName: string) => {
    setActiveTab((previousTabSelected) => {
      const tabSelected = tabs.find((tab) => tab.title === tabSelectedName);
      if (tabSelected) return tabSelected;

      return previousTabSelected;
    });
  };

  useEffect(() => {
    let startDate = new Date();
    startDate.setDate(1);
    dispatch(setDateRangeActionCreator(startDate, new Date()));

    authentication.isAccountOwner &&
      authentication.user?.businessClientId &&
      dispatch(getOwnerInfoData(authentication.user.businessClientId));
  }, []);

  return (
    <div className={DashboardTabWrapper}>
      <FiraNavBar
        titleSection={user.info && <HeaderTitleGreetings user={user.info} />}
        trailingOptions={!hideCalendar && <DateRangePickerComponent />}
      />
      <div className={TabsWrapper}>
        {tabs.map((tab, index) => (
          <DashboardTabButton
            text={tab.title}
            tabIsActive={tab.title === activeTab.title}
            onTabSelected={onTabPressed}
            key={index}
          />
        ))}
      </div>
      <div className={Content}>{activeTab.element}</div>
    </div>
  );
};

interface DashboardTabButtonProps {
  text: string;
  tabIsActive: boolean;
  onTabSelected(tabText: string): void;
}
export const DashboardTabButton: React.FC<DashboardTabButtonProps> = ({
  text,
  tabIsActive,
  onTabSelected,
}) => {
  return (
    <button
      className={`${DashboardTabButtonWrapper} ${tabIsActive ? Active : ""}`}
      onClick={() => onTabSelected(text)}
    >
      {text}
    </button>
  );
};

const HeaderTitleGreetings: React.FC<{
  user?: FiraUserResponse;
}> = ({ user }) => {
  const { authentication } = useSelector((state: RootState) => state);
  const [greeting, setGreeting] = useState(
    "views.dashboard.greetings.goodMorning"
  );

  const nameToShow = user
    ? user.firstName
      ? user.firstName
      : user.username
    : "";

  const { t } = useTranslation();

  useEffect(() => {
    !authentication?.isAccountOwner && setGreeting(getCurrentGreetings());
  }, [setGreeting]);

  return (
    <div className={GreetingsWrapper}>
      <div className={TextWrapper}>
        <label className={Title}>
          {authentication?.isAccountOwner
            ? t("views.dashboard.greetings.welcome")
            : t(greeting)}
          <b> {nameToShow}</b>
          {authentication?.isAccountOwner && " a la cuenta "}
          {authentication?.isAccountOwner && (
            <b>{authentication?.accountName}</b>
          )}
        </label>
        <label className={Info}>{t("views.dashboard.infoText")}</label>
      </div>
    </div>
  );
};

export default DashboardTabLayout;
