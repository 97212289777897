import { useEffect, useState, useRef, Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import VideoControls from "./VideoControls/VideoControls";
import muted from "../../assets/svg/muted.svg";
import "./Player.scss";
import VideoPopup from "./VideoPopup/VideoPopup";
import { Sign } from "../FiraIcons";
import { handleImageLoadError } from "../../utils/handleImageLink";
import { useTranslation } from "react-i18next";
import { testEventTimerFinishActionCreator } from "../../store/Event/EventActions";
import { updateCredentialsActionCreator } from "../../store/Event/EventActions";
import Red5Player from "./Red5Player/Red5Player";
import {
  ResponseCurrentPopup,
  RootState,
  SuscriberCredentialsDto,
} from "../../utils/types";
import { AgoraPlayer } from "./AgoraPlayer/AgoraPlayer";
import noImageStoreSVG from "../../assets/svg/no-image-store.svg";
import { useEvent } from "../../providers/EventProvider";
import { deletePopup } from "../../services/events";

interface PlayerProps {
  setHideChat: Dispatch<SetStateAction<boolean>>;
  hideChat: boolean;
  suscriberCredentialsDto: SuscriberCredentialsDto;
  userId?: string;
  counterTest?: number | null;
}

const Player: React.FC<PlayerProps> = ({
  setHideChat,
  hideChat,
  suscriberCredentialsDto,
  userId,
  counterTest,
}) => {
  const { event, brand } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [timerCounter, setTimerCounter] = useState<number | null>(
    counterTest ? counterTest : null
  );
  const [videoExist, setvideoExist] = useState(true);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [reconnectPlayer, setReconnectPlayer] = useState(0);
  const [loadingPlayer, setLoadingPlayer] = useState(false);
  //para saber si esta cargando por primera vez y mostrar el mensaje de conectando,
  //si no pues muestra el mensaje de reconectar
  const [firstTime, setFirstTime] = useState(0);
  const [playerRef, setPlayerRef] = useState<HTMLVideoElement | null>(null);
  const [isAgora, setIsAgora] = useState(false);

  const { popups, removePopup } = useEvent();

  const handleMouseOver = () => {
    const controls = document.querySelector("#videoControls") as HTMLDivElement;
    if (controls) {
      controls.style.display = "block";
    }
  };

  const handleMouseOut = () => {
    const controls = document.querySelector("#videoControls") as HTMLDivElement;
    if (controls) {
      controls.style.display = "none";
    }
  };

  const reloadStatusEvent = () => {
    if (event.currentEvent) {
      dispatch(testEventTimerFinishActionCreator());
    }
  };

  const refreshLiveData = () => {
    if (event.currentEvent) {
      dispatch(updateCredentialsActionCreator(event.currentEvent.id));
      setReconnectPlayer(reconnectPlayer + 1);
    }
  };

  const generateTextColor = (bgColor: string) => {
    // Función para determinar el color de texto basado en el color de fondo
    // Puedes ajustar estos valores dependiendo de tus necesidades
    const umbralColor = 130;
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);
    const luminosidad = 0.299 * r + 0.587 * g + 0.114 * b;

    return luminosidad > umbralColor ? "#000000" : "#ffffff";
  };

  const handleRemovePopup = async (popup: ResponseCurrentPopup) => {
    if (event.currentEvent) {
      await deletePopup(event.currentEvent.id, popup.broadcastingProductId);
    }
  };

  useEffect(() => {
    if (counterTest) {
      setTimerCounter(counterTest);
    }
  }, [counterTest]);

  useEffect(() => {
    if (event.currentEvent?.suscriberCredentialsDto?.appId) {
      setIsAgora(true);
    } else {
      setIsAgora(false);
    }
  }, [event.currentEvent?.suscriberCredentialsDto]);

  const messageContainer = (
    <div className={`reconnectingMessage  overVideo`}>
      {brand.currentStore ? (
        <img
          className={"storeAvatar"}
          src={
            brand.currentStore?.brand?.logo ? brand.currentStore.brand.logo : ""
          }
          onError={handleImageLoadError}
          alt={brand.currentStore.name}
        />
      ) : (
        <img
          className={"storeAvatar"}
          src={noImageStoreSVG}
          onError={handleImageLoadError}
        />
      )}
      {event.currentEvent &&
        (event.currentEvent.status == "NOT_STARTED" ||
          event.currentEvent.status == "STARTED") && (
          <p className="blockquote-text ">
            {firstTime > 1
              ? t("views.events.eventDetail.player.reconnecting")
              : t("views.events.eventDetail.player.connecting")}
          </p>
        )}
      {event.currentEvent && event.currentEvent.status == "DONE" && (
        <p className="blockquote-text ">
          {t("views.events.eventDetail.player.finished")}
        </p>
      )}
      <Sign
        className={"signIcon"}
        style={{
          color:
            event.currentEvent && event.currentEvent.firaWebConfiguration
              ? event.currentEvent.firaWebConfiguration.mainColor
              : "#000",
        }}
      />
    </div>
  );

  return (
    <div
      id="reproductor"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className="VideoPopupContainer">
        {popups.map((popup) => {
          const product = event.currentEventProducts.find(
            (p) => p.id === popup.broadcastingProductId
          );
          return (
            <VideoPopup
              key={popup.broadcastingProductId}
              popup={popup}
              product={product}
              removeAction={handleRemovePopup}
            />
          );
        })}
      </div>
      {event.currentEvent &&
        event.currentEvent.status == "DONE" &&
        messageContainer}
      {event.currentEvent && event.currentEvent.status !== "DONE" && (
        <div className={"subscriberContainer "}>
          {event.currentEvent &&
            event.currentEvent.eventType == "TEST" &&
            event.currentEvent.status == "STARTED" &&
            timerCounter && (
              <CounterTimer
                counterTest={timerCounter / 1000}
                reloadStatusEvent={reloadStatusEvent}
              />
            )}

          {loadingPlayer && messageContainer}

          {isAgora ? (
            <AgoraPlayer
              setVideoExist={setvideoExist}
              subscriberData={suscriberCredentialsDto}
              handlevideoPlayerRef={setPlayerRef}
              isVideoMuted={isVideoMuted}
            />
          ) : (
            <></>
          )}

          <div className={`videoMuted ${!isVideoMuted && "d-none"}`}>
            <img src={muted} alt="" />
          </div>
          {videoExist && !loadingPlayer && (
            <VideoControls
              hideChat={hideChat}
              setHideChat={setHideChat}
              isVideoMuted={isVideoMuted}
              setIsVideoMuted={setIsVideoMuted}
              playerRef={playerRef}
            />
          )}
        </div>
      )}
      {!videoExist &&
        !loadingPlayer &&
        event.currentEvent &&
        (event.currentEvent.status == "NOT_STARTED" ||
          event.currentEvent.status == "STARTED") && (
          <div className="reload">
            <div className={`reconnectingMessage `}>
              {brand.currentStore && (
                <img
                  className={"storeAvatar"}
                  src={
                    brand.currentStore?.brand?.logo
                      ? brand.currentStore.brand.logo
                      : ""
                  }
                  onError={handleImageLoadError}
                  alt={brand.currentStore.name}
                />
              )}
              <p className="blockquote-text breakName">
                {event.currentEvent.eventName}
              </p>
              <Sign
                className={"signIcon"}
                style={{
                  color: event.currentEvent.firaWebConfiguration
                    ? event.currentEvent.firaWebConfiguration.mainColor
                    : "#000",
                }}
              />
            </div>
            <div className="reloadButton">
              <p className={" reloadText"}>
                {t("views.events.eventDetail.player.text_1")}
                <span className="bold">
                  {" "}
                  {t("views.events.eventDetail.player.text_2")}
                </span>
                {t("views.events.eventDetail.player.text_3")}
              </p>
            </div>
            <button
              type="button"
              onClick={refreshLiveData}
              style={{
                backgroundColor: event.currentEvent.firaWebConfiguration
                  ? event.currentEvent.firaWebConfiguration.mainColor
                  : "#000",
                color: event.currentEvent.firaWebConfiguration
                  ? generateTextColor(
                      event.currentEvent.firaWebConfiguration.mainColor
                    )
                  : generateTextColor("#000"),
              }}
              className={"RefreshButton"}
            >
              <p className={"blockquote-text"}>
                {t("views.events.eventDetail.player.refresh")}
              </p>
            </button>
            {/* <button onClick={()=>{setReconnectPlayer(reconnectPlayer + 1);}}>recargar</button> */}
          </div>
        )}
      {
        // <p className={`noVideoText ${videoExist ? "d-none" : ""}`}>
        // 	Sorry, Stream has not started yet. Please reload the page or contact
        // 	to administrator.
        // </p>
      }
    </div>
  );
};

interface CounterTimerProps {
  counterTest: number;
  reloadStatusEvent(): void;
}

// TODO: revisar logica de este componente para simplificarlo
const CounterTimer: React.FC<CounterTimerProps> = ({
  counterTest,
  reloadStatusEvent,
}) => {
  const [time, setTime] = useState<number | null>(Math.floor(counterTest));
  const [status, setStatus] = useState(false);
  const timeRef = useRef(time);

  useEffect(() => {
    if (counterTest !== null) {
      if (typeof counterTest !== "undefined" && !isNaN(counterTest)) {
        setTime(Math.floor(counterTest));
        if (time) {
          const timer = setInterval(() => {
            if (timeRef.current !== null) {
              setTime((prevTime) => {
                if (prevTime) {
                  return prevTime === 0 ? 0 : prevTime - 1;
                } else {
                  clearInterval(timer);
                  reloadStatusEvent();
                  return 0;
                }
              });
            }
            // console.log("timercounter", time);
          }, 1000);

          return () => {
            clearInterval(timer);
          };
        }
      }
    }
  }, [counterTest]);

  useEffect(() => {
    timeRef.current = time;
    if (time) {
      if (time === 0) {
        reloadStatusEvent();
      } else if (time <= 30 && !status) {
        setStatus(true);
      }
    }
  }, [time]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className={"countdownTimer"}>
      <div className={`${status ? "timerEnd" : "timerStart"}`}>
        <label className={`${status ? "timerTextEnd" : "timerText"}`}>
          {time && (!isNaN(time) || time !== 0) ? formatTime(time) : "00:00"}
        </label>
      </div>
    </div>
  );
};

export default Player;
