import { ChangeEvent, ReactChild, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableButton,
  TableHeader,
  TableImage,
  ViewHeader,
  Dialog,
} from "../../components";
import { ButtonStyle } from "../../components/Button/Button";
import { EmptyProducts } from "../../components/FiraIcons";
import { getStoreProductsExcelFileService } from "../../services/store";
import {
  deleteProductsActionCreator,
  getStoreProductsActionCreator,
  resetCurrentProductActionCreator,
  setCurrentProductActionCreator,
} from "../../store/Products/ProductsActions";
import { FiraProduct, RootState } from "../../utils/types";
import "./ProductsView.scss";
import ImportProductsModal from "../../components/ImportProductsModal/ImportProductsModal";
import { handleExcelFileDownload } from "../../utils/handleFileDownload";
import Skeleton from "../../components/Skeleton/Skeleton";
import currencyFormat from "../../utils/currencyFormat";
import noImageStoreSVG from "../../assets/svg/no-image-store.svg";
import PopupAlert, {
  PopUpAlertTypes,
} from "../../components/PopUpAlert/PopUpAlert";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";

let timerID: NodeJS.Timeout;

const ProductsView = () => {
  const { brand, products, authentication } = useSelector(
    (state: RootState) => state
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState<FiraProduct[]>([]);
  const [storeProducts, setStoreProducts] = useState<FiraProduct[]>(
    products.products
  );
  const [filterValue, setFilterValue] = useState("");
  const [dialogIsVisible, setDialogIsVisible] = useState(false);
  const [importProductsModalIsOpen, setImportProductsModalIsOpen] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertBulkLoad, setAlertBulkLoad] = useState<PopUpAlertTypes>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { t } = useTranslation();

  const canEdit = !authentication.isAccountOwner
    ? brand.currentStore
      ? brand.currentStore.productConfig
        ? brand.currentStore.productConfig.canEdit
        : true
      : true
    : true;

  const handleExportProducts = async (storeId: string) => {
    try {
      const response = await getStoreProductsExcelFileService(storeId);
      handleExcelFileDownload(response.data, "Lista de productos.xls");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteDialodTextContent = (products: FiraProduct[]): string => {
    return products.length === 1
      ? t("dialogs.deleteProducts.singleTextContent").replace(
          "{productName}",
          `"${products[0].name}"`
        )
      : t("dialogs.deleteProducts.multipleTextContent").replace(
          "{quantity}",
          `${products.length}`
        );
  };

  const dialogTextContent = (
    <span>
      {handleDeleteDialodTextContent(selectedProducts)}
      <br />
      <span className="alert-text-12-bold">
        {`${t("dialogs.deleteProducts.textContent")}`}
      </span>
    </span>
  );

  const handleDeleteProducts = () => {
    const productsToDelete = selectedProducts.map((p) => p.id);
    if (brand.currentStore) {
      dispatch(
        deleteProductsActionCreator(brand.currentStore.id, productsToDelete)
      );
      setDialogIsVisible(false);
    }
  };

  const handleHeaderCheckbox = () => {
    if (storeProducts.length === 0) {
      return;
    }

    if (selectedProducts.length === storeProducts.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(storeProducts);
    }
  };

  const handleProductCheckbox = (product: FiraProduct) => {
    if (selectedProducts.includes(product)) {
      setSelectedProducts((currentProducts) => [
        ...currentProducts.filter((p) => product.id !== p.id),
      ]);
      return;
    }
    setSelectedProducts((currentProducts) => [...currentProducts, product]);
  };

  const handleProductFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
    if (keyword !== "") {
      setStoreProducts(
        products.products.filter((p) => {
          return p.name.toLowerCase().includes(keyword.toLowerCase());
        })
      );
    } else {
      setStoreProducts(products.products);
    }
  };

  const openImportProductsModal = () => {
    setImportProductsModalIsOpen(true);
  };

  const closeImportProductsModal = () => {
    setImportProductsModalIsOpen(false);
  };

  const handleAlert = (message: string, type: PopUpAlertTypes) => {
    setAlertMessage(message);
    setAlertBulkLoad(type);
    showAlertSuccess();
  };

  const showAlertSuccess = () => {
    setShowAlert(true);
    timerID = setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const handleCloseAlert = () => {
    if (showAlert) {
      clearTimeout(timerID);
      setShowAlert(false);
    }
  };

  const handleProductClick = (product: FiraProduct) => {
    dispatch(setCurrentProductActionCreator(product));
    navigate(`${product.id}`);
  };

  const productsHeader = [
    { name: "", size: "52.5px" },
    { name: "", size: "70px" },
    { name: t("views.products.tableHeader.name"), size: "300px" },
    { name: "SKU", size: "" },
    { name: t("views.products.tableHeader.inventory"), size: "100px" },
    { name: `${t("views.products.tableHeader.price")}`, size: "" },
    { name: `${t("views.products.tableHeader.discount")}`, size: "" },
    { name: "", size: "49px" },
  ];
  const header = (
    <TableRow>
      {productsHeader.map((head, i) => (
        <TableHead key={i} size={head.size} centerContent={i !== 2}>
          {head.name}
        </TableHead>
      ))}
    </TableRow>
  );

  const body = storeProducts.map((product) => {
    return (
      <TableRow key={product.id}>
        <TableCell size="52.5px">
          <Checkbox
            isDisabled={!canEdit}
            clickHandler={() => {
              handleProductCheckbox(product);
            }}
            isActive={selectedProducts.includes(product)}
          />
        </TableCell>
        <TableCell size="70px">
          <TableImage
            src={
              product.images.length === 0 || product.images[0] === ""
                ? noImageStoreSVG
                : product.images[0]
            }
          />
        </TableCell>
        <TableCell size="180px">
          <span className="ellipsis ">{product.name}</span>
        </TableCell>
        <TableCell centerContent>
          <span className="ellipsis">{product.sku}</span>
        </TableCell>
        <TableCell size="100px" centerContent>
          <span className="ellipsis">{product.quantity}</span>
        </TableCell>
        <TableCell centerContent>
          <span className="ellipsis">
            {currencyFormat(
              parseFloat(product.price1),
              brand.currentStore?.regionalConfiguration.currency
            )}
          </span>
        </TableCell>
        <TableCell centerContent>
          <span className="ellipsis">
            {currencyFormat(
              parseFloat(product.price2),
              brand.currentStore?.regionalConfiguration.currency
            )}
          </span>
        </TableCell>
        <TableCell size="49px">
          <TableButton
            onClick={() => {
              handleProductClick(product);
            }}
          />
        </TableCell>
      </TableRow>
    );
  });

  useEffect(() => {
    if (brand.currentStore) {
      dispatch(getStoreProductsActionCreator(brand.currentStore.id));
      setStoreProducts(products.products);
    }
  }, [brand.currentStore]);

  useEffect(() => {
    authentication.isAccountOwner && console.log("owner init");
    // agregar call de servicio para listar productos globales agreagados
  }, []);

  useEffect(() => {
    setSelectedProducts([]);
    setStoreProducts(products.products);
    setFilterValue("");
  }, [products.products]);

  let exportButton: any;
  if (products.products.length > 0) {
    exportButton = (
      <Button
        width="73px"
        buttonStyle={ButtonStyle.transparent}
        clickHandler={() => {
          handleExportProducts(brand.currentStore?.id as string);
        }}
      >
        <span className="lead-text">{t("views.products.exportButton")}</span>
      </Button>
    );
  }

  const importProduct = (canCreate = true) => {
    return (
      <Button
        width="73px"
        buttonStyle={ButtonStyle.transparent}
        clickHandler={() => {
          openImportProductsModal();
        }}
        isDisabled={!canCreate && !authentication.isAccountOwner}
      >
        <span className="lead-text">{t("views.products.importButton")}</span>
      </Button>
    );
  };

  const newProductButton = (canCreate = true) => {
    return (
      <Button
        isDisabled={!canCreate && !authentication.isAccountOwner}
        clickHandler={() => {
          dispatch(resetCurrentProductActionCreator());
          navigate("new");
        }}
      >
        {t("views.products.addProductButton")}
      </Button>
    );
  };

  const titleSection = (
    <span className="h3 LimitText">{t("views.products.title")}</span>
  );

  return (
    <>
      <FiraNavBar
        titleSection={titleSection}
        trailingOptions={
          authentication.isAccountOwner && !brand.currentStore ? (
            newProductButton
          ) : !brand.currentStore?.productConfig ? (
            <>
              {exportButton}
              {importProduct()}
              {newProductButton()}
            </>
          ) : (
            <>
              {exportButton}
              {importProduct(brand.currentStore.productConfig.canCreate)}
              {newProductButton(brand.currentStore.productConfig.canCreate)}
            </>
          )
        }
      ></FiraNavBar>

      {showAlert && (
        <PopupAlert
          title={alertMessage}
          type={alertBulkLoad}
          width={"100%"}
          handleCloseIcon={handleCloseAlert}
        />
      )}
      <TableHeader
        checkboxHandle={handleHeaderCheckbox}
        checkboxIsActive={
          selectedProducts.length === storeProducts.length &&
          storeProducts.length > 0
        }
        hideCheckBox={!canEdit}
        selected={selectedProducts.length}
        searchInputValue={filterValue}
        searchInputChangeHandle={handleProductFiltering}
        handleOptionClick={() => setDialogIsVisible(true)}
        optionIsActive={true}
      />
      {storeProducts.length > 0 ? (
        <Table
          header={header}
          body={products.isLoading ? SkeletonTableBody : body}
        />
      ) : (
        <EmptyProductView />
      )}
      {dialogIsVisible && (
        <Dialog
          title={t("dialogs.deleteProducts.title")}
          textContent={dialogTextContent}
          confirmButtonText={t("dialogs.deleteProducts.deleteButton")}
          cancelButtonText={t("dialogs.cancelButton")}
          confirmHandler={handleDeleteProducts}
          cancelHandler={() => setDialogIsVisible(false)}
        />
      )}
      {importProductsModalIsOpen && (
        <ImportProductsModal
          sendAlert={handleAlert}
          isOpen={importProductsModalIsOpen}
          onClose={() => closeImportProductsModal()}
        />
      )}
    </>
  );
};
const EmptyProductView = () => {
  return (
    <div className="EmptyWrapper">
      <EmptyProducts />
    </div>
  );
};

const placeholder = [
  { id: 1, name: "placeholder" },
  { id: 2, name: "placeholder" },
  { id: 3, name: "placeholder" },
  { id: 4, name: "placeholder" },
  { id: 5, name: "placeholder" },
  { id: 6, name: "placeholder" },
  { id: 7, name: "placeholder" },
  { id: 8, name: "placeholder" },
  { id: 9, name: "placeholder" },
  { id: 10, name: "placeholder" },
];

const SkeletonTableBody = placeholder.map((data) => {
  return (
    <TableRow key={data.id}>
      <TableCell size="80px">
        <Checkbox />
      </TableCell>
      <TableCell size="90px">
        <Skeleton width="50px" height="49px" />
      </TableCell>
      <TableCell size="160px">
        <div className="centerSkeleton">
          <Skeleton width="104px" height="22px" />
        </div>
      </TableCell>
      <TableCell size="160px">
        <div className="centerSkeleton">
          <Skeleton width="104px" height="22px" />
        </div>
      </TableCell>
      <TableCell size="160px">
        <div className="centerSkeleton">
          <Skeleton width="104px" height="22px" />
        </div>
      </TableCell>
      <TableCell size="160px">
        <div className="centerSkeleton">
          <Skeleton width="104px" height="22px" />
        </div>
      </TableCell>
      <TableCell size="160px">
        <div className="centerSkeleton">
          <Skeleton width="104px" height="22px" />
        </div>
      </TableCell>
      <TableCell size="160px">
        <div className="centerSkeleton">
          <Skeleton width="104px" height="22px" />
        </div>
      </TableCell>
      <TableCell size="49px">
        <TableButton />
      </TableCell>
    </TableRow>
  );
});

export default ProductsView;
