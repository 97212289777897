import * as React from "react";
import { SVGProps } from "react";

const SvgTimeClock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.703 23a7.111 7.111 0 1 0 0-14.222 7.111 7.111 0 0 0 0 14.222Z"
      stroke="#010202"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.703 12.333v4.148h2.963M10.556 7 7 10.556M18.852 7l3.556 3.556M9.963 21.222 8.186 23M19.444 21.222 21.222 23"
      stroke="#010202"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgTimeClock;
