import React from "react";

function FiraLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="36"
      fill="none"
      viewBox="0 0 86 36"
    >
      <path
        fill="#fff"
        d="M3.24 3.116v5.301h8.403v2.73H3.24v6.676H.02V.405h12.703v2.71H3.24zM15.453.406h3.24v17.417h-3.24V.405zM34.38 17.823l-3.554-5.105c-.157.02-.373.02-.667.02h-3.927v5.065h-3.24V.387h7.167c1.512 0 2.827.255 3.946.746 1.12.491 1.984 1.217 2.573 2.14s.903 2.023.903 3.299c0 1.315-.314 2.435-.962 3.377-.629.943-1.551 1.65-2.75 2.12l4.006 5.714H34.38v.04zM33.222 4.039c-.727-.589-1.806-.903-3.2-.903h-3.79v6.93h3.79c1.413 0 2.473-.294 3.2-.902.726-.609 1.1-1.473 1.1-2.572 0-1.1-.374-1.964-1.1-2.553zM52.07 13.797h-8.698l-1.708 4.026h-3.338L46.16.405h3.181l7.854 17.417h-3.377l-1.747-4.026zm-1.06-2.552l-3.279-7.618-3.26 7.618h6.54zM3.986 34.592c-1.237-.687-2.219-1.61-2.926-2.808C.353 30.586 0 29.251 0 27.78c0-1.473.353-2.808 1.06-4.006a7.325 7.325 0 012.926-2.808c1.237-.687 2.63-1.02 4.182-1.02 1.532 0 2.906.333 4.163 1.02 1.237.688 2.199 1.61 2.905 2.808.707 1.178 1.041 2.533 1.041 4.006 0 1.492-.353 2.827-1.04 4.005a7.68 7.68 0 01-2.906 2.808c-1.237.687-2.631 1.02-4.163 1.02-1.551 0-2.945-.333-4.182-1.02zm7.206-1.689a5.587 5.587 0 002.12-2.1c.51-.904.786-1.905.786-3.005 0-1.1-.256-2.1-.786-3.004a5.452 5.452 0 00-2.12-2.1c-.903-.511-1.905-.767-3.024-.767-1.12 0-2.14.256-3.043.766a5.64 5.64 0 00-2.14 2.101 6.013 6.013 0 00-.786 3.004c0 1.1.255 2.101.785 3.004.511.904 1.237 1.59 2.14 2.101.904.51 1.925.766 3.044.766 1.12 0 2.12-.255 3.024-.766zM32.77 20.14v15.296h-1.786l-9.19-11.408v11.408h-2.179V20.141h1.787l9.17 11.407V20.141h2.198z"
      ></path>
      <path
        fill="#FFDD0D"
        d="M36.6 20.14h4.32v11.88h7.303v3.436H36.6V20.14zM49.932 20.14h4.32v15.296h-4.32V20.141zM72.826 20.14l-6.558 15.296h-4.26l-6.54-15.295h4.674l4.162 9.974 4.242-9.974h4.28zM86 32.098v3.338H73.71V20.141h11.997v3.338h-7.717v2.572h6.794v3.24h-6.794v2.807H86z"
      ></path>
    </svg>
  );
}

export default FiraLogo;
