import React from "react";
import TabsLayout from "../../layout/TabsLayout/TabsLayout";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import { Button } from "../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OwnerTeamView from "../OwnerTeamView/OwnerTeamView";
import Invitations from "./components/Invitations";

const TeamView: React.FC = () => {
  const { brand } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const teamTabs: { title: string; element: React.ReactElement }[] = [
    { title: t("views.team.title"), element: <OwnerTeamView /> },
    {
      title: t("views.team.invites"),
      element: <Invitations />,
    },
  ];

  const handleNewMember = () => {
    navigate("new");
  };

  const headerOptions = (
    <>
      <div style={{ marginLeft: "5px" }}>
        {brand.currentStore && (
          <Button width="136px" height="32px" clickHandler={handleNewMember}>
            <span className={"body-12-low-text"}>
              {t("views.team.inviteMember")}
            </span>
          </Button>
        )}
      </div>
    </>
  );

  const renderNavBar = () => (
    <FiraNavBar
      titleSection={t("views.team.title")}
      trailingOptions={headerOptions}
    />
  );

  return <TabsLayout HeaderComponent={renderNavBar()} tabs={teamTabs} />;
};

export default TeamView;
