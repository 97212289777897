import { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import "./SegmentedInput.scss";

const CODE_LENGTH = new Array(6).fill(0);

interface Props {
  onChange?(value: string): void;
}
const SegmentedInput = ({ onChange }: Props) => {
  const [value, setValue] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const input = useRef<HTMLInputElement>(null);

  const values = value.split("");

  const sendResult = (value: string) => {
    onChange && onChange(value);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValue((state) => {
      const re = /^[0-9\b]+$/;
      if (state.length >= CODE_LENGTH.length || !re.test(inputValue))
        return state;
      const result = (state + inputValue).slice(0, CODE_LENGTH.length);
      sendResult(result);
      return result;
    });
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace") {
      setValue((state) => {
        const removeValue = state.slice(0, state.length - 1);
        sendResult(removeValue);
        return removeValue;
      });
    }
  };

  const clickHandler = () => {
    input.current?.focus();
  };

  const focusHandler = () => {
    setIsFocus(true);
  };

  const blurHandler = () => {
    setIsFocus(false);
  };

  const selectedIndex =
    values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;
  const hideInput = !(values.length < CODE_LENGTH.length);

  const inputStyle = {
    width: "45px",
    top: "0px",
    height: "45px",
    bottom: "0px",
    left: `${selectedIndex * 51}px`,
    opacity: hideInput ? 0 : 1,
  };

  return (
    <div className="SegmentedInputWrapper" onClick={clickHandler}>
      <input
        value=""
        ref={input}
        onChange={handleChange}
        onFocus={focusHandler}
        onBlur={blurHandler}
        onKeyUp={handleKeyUp}
        className="Input"
        style={inputStyle}
      />
      {CODE_LENGTH.map((v, index) => {
        const selected = values.length === index;
        const filled =
          values.length === CODE_LENGTH.length &&
          index === CODE_LENGTH.length - 1;
        return (
          <div key={index} className="Digit">
            {values[index]}
            {(selected || filled) && isFocus && (
              <div className="FocusOutline" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SegmentedInput;
