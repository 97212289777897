export const parseTimeFromMilliseconds = (milliseconds: number) => {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const remainingMilliseconds = milliseconds - (hours * 1000 * 60 * 60);
    const minutes = Math.floor(remainingMilliseconds / (1000 * 60));

    return `${hours}h:${minutes}m`;
};

export const parseTime = (minutes: number) => {
    const milliseconds = minutes * 60000;
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const min = minutes - 60 * hours;
    return `${hours != 0 ? hours + "h:" : ""}${Math.floor(min)}m`;
};