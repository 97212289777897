import React, { ReactElement } from "react";
import stylesheet from "./FiraUserRoleComponent.module.scss";

const {
  FiraUserRoleWrapper,
  Selected,
  TitleWrapper,
  IconWrapper,
  TextWrapper,
  DescriptionWrapper,
  ComponentBorder,
} = stylesheet;

interface Props {
  role: FiraUserRoleTest;
  onClick: (role: FiraUserRoleTest) => void;
  isSelected?: boolean;
}
const FiraUserRoleComponent: React.FC<Props> = ({
  role,
  onClick,
  isSelected = false,
}) => {
  const { icon, name, description } = role;
  return (
    <div
      className={`${FiraUserRoleWrapper} ${isSelected ? Selected : ""}`}
      onClick={() => (isSelected ? null : onClick(role))}
    >
      <div className={`${ComponentBorder} ${isSelected ? Selected : ""}`}></div>
      <div className={TitleWrapper}>
        <div className={IconWrapper}>{icon}</div>
        <div className={TextWrapper}>{name}</div>
      </div>

      <div className={DescriptionWrapper}>{description}</div>
    </div>
  );
};

export interface FiraUserRoleTest {
  icon: ReactElement;
  name: string;
  description: string;
}

export default FiraUserRoleComponent;
