import CardWidget from "../../../widgets/CardWidget/CardWidget";
import { useTranslation } from "react-i18next";
import styles from "./DashboardVideos.module.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import InteractionsWidget from "../../../widgets/InteractionsWidget/InteractionsWidget";
import { getDashboardStatisticByDateService } from "../../../services/videos";
import { parseTimeFromMilliseconds } from "../../../utils/handleTime";
import { formatIsoTimeZone } from "../../../utils/handleDates";

interface interactionsType {
  likes: number;
  clicks: number;
  messages: number;
  shares: number;
}

const DashboardVideos = () => {
  const { t } = useTranslation();
  const { utils, brand, dashboard } = useSelector((state: RootState) => state);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [finalDate, setFinalDate] = useState<Date | null>(null);
  const [audience, setAudience] = useState<number>(0);
  const [playAvg, setPlayAvg] = useState<number>(0);
  const [totalPlay, setTotalPlay] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [interactions, setInteractions] = useState<interactionsType>({
    likes: 0,
    clicks: 0,
    messages: 0,
    shares: 0,
  });

  const getVODStats = async (
    storeId: string,
    initialDate: Date,
    finalDate: Date
  ) => {
    setIsLoading(true);
    const init = formatIsoTimeZone(initialDate);
    const final = formatIsoTimeZone(finalDate);

    const response = await getDashboardStatisticByDateService(
      storeId,
      init,
      final
    );

    setAudience(response.data.accumTotalAudience);
    setPlayAvg(response.data.accumTotalvideoPlayAvgTime);
    setTotalPlay(response.data.accumTotalVideoPlay);
    setInteractions({
      likes: response.data.accumTotalLikeCount || 0,
      clicks: response.data.accumTotalClicks || 0,
      messages: response.data.accumTotalCommentCount || 0,
      shares: response.data.accumTotalShareCount || 0,
    });

    setIsLoading(false);
  };

  useEffect(() => {
    if (utils.screenWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [utils.screenWidth]);

  useEffect(() => {
    if (initialDate !== null && finalDate !== null && brand.currentStore) {
      getVODStats(brand.currentStore.id, initialDate, finalDate);
    }
  }, [initialDate, finalDate]);

  useEffect(() => {
    if (dashboard && dashboard.firstDate && dashboard.lastDate) {
      setInitialDate(dashboard.firstDate);
      setFinalDate(dashboard.lastDate);
    }
  }, [dashboard]);

  const containerCards = (
    <div
      className={`${styles.countersWrapper} ${
        isMobile ? styles.mobileCounter : ""
      }`}
    >
      <CardWidget
        title={t("views.dashboard.audienceCount")}
        description={t("views.dashboard.audienceCount")}
        data={audience ? String(audience) : "0"}
        isLoading={isLoading}
      />

      <CardWidget
        title={t("views.dashboard.reproductionTotal")}
        description={t("views.dashboard.reproductionTotal")}
        data={totalPlay ? parseTimeFromMilliseconds(totalPlay) : "0"}
        isLoading={isLoading}
      />
      <CardWidget
        title={t("views.dashboard.reproductionAverage")}
        description={t("views.dashboard.reproductionAverage")}
        data={playAvg ? parseTimeFromMilliseconds(playAvg) : "0"}
        isLoading={isLoading}
      />
    </div>
  );
  return (
    <div className={styles.dashboardContainer}>
      {containerCards}
      {!isMobile ? (
        <div className={styles.desktopContainer}>
          <div className={styles.desktopContainer__firstCol}>
            <InteractionsWidget
              likes={interactions ? interactions.likes : 0}
              clicks={interactions ? interactions.clicks : 0}
              messages={interactions ? interactions.messages : 0}
              shares={interactions ? interactions.shares : 0}
              pieHeight={268}
              isLoading={isLoading}
            />
          </div>

          <div className={styles.desktopContainer__secondCol}></div>
        </div>
      ) : (
        <div className={styles.mobileContainer}>
          <div className={styles.subGrid}>
            <div className={styles.subGrid__interactions}>
              <InteractionsWidget
                likes={interactions ? interactions.likes : 0}
                clicks={interactions ? interactions.clicks : 0}
                messages={interactions ? interactions.messages : 0}
                shares={interactions ? interactions.shares : 0}
                isLoading={isLoading}
                pieHeight={360}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardVideos;
