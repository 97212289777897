import { RequestError } from "../../services/errorHandling";
import { AuthActions } from "../actionsContants";
import { RecoveryActionType } from "./RecoveryActions";

export interface RecoveryState {
  isLoading: boolean;
  processFinish: boolean;
  isRecovering: boolean;
  code?: string;
  email?: string;
  recoveryError?: RequestError;
  validationError?: RequestError;
  changePswError?: RequestError;
}
const initialState: RecoveryState = {
  isLoading: false,
  processFinish: false,
  isRecovering: false,
};
export const recoveryReducer = (
  state: RecoveryState = initialState,
  action: RecoveryActionType
): RecoveryState => {
  switch (action.type) {
    case AuthActions.recoveryLoading: {
      return { ...state, isLoading: true };
    }
    case AuthActions.recoverySuccess: {
      return {
        ...state,
        isLoading: false,
        isRecovering: true,
        email: action.payload as string,
      };
    }
    case AuthActions.recoveryFailed: {
      return {
        ...state,
        isLoading: false,
        email: undefined,
        recoveryError: action.payload as RequestError,
      };
    }
    case AuthActions.validateCodeLoading: {
      return { ...state, isLoading: true };
    }
    case AuthActions.validateCodeSuccess: {
      return { ...state, isLoading: false, code: action.payload as string };
    }
    case AuthActions.validateCodeFailed: {
      return {
        ...state,
        isLoading: false,
        code: undefined,
        validationError: action.payload as RequestError,
      };
    }
    case AuthActions.changePasswordLoading: {
      return { ...state, isLoading: true };
    }
    case AuthActions.changePasswordSuccess: {
      return { isLoading: false, isRecovering: false, processFinish: true };
    }
    case AuthActions.changePasswordFailed: {
      return {
        ...state,
        isLoading: false,
        changePswError: action.payload as RequestError,
      };
    }
    case AuthActions.resetAuthAlert: {
      return { ...state, processFinish: false };
    }
    case AuthActions.logout: {
      return initialState;
    }
    default:
      return state;
  }
};
