import { SVGProps } from "react";

const ItemDraggable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={11}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={8.794}
      cy={12.794}
      r={1.956}
      transform="rotate(180 8.794 12.794)"
      fill="#fff"
      stroke="#D0D0D0"
      strokeWidth={0.5}
    />
    <circle
      cx={2.206}
      cy={12.794}
      r={1.956}
      transform="rotate(180 2.206 12.794)"
      fill="#fff"
      stroke="#D0D0D0"
      strokeWidth={0.5}
    />
    <circle
      cx={8.794}
      cy={7.5}
      r={1.956}
      transform="rotate(180 8.794 7.5)"
      fill="#fff"
      stroke="#D0D0D0"
      strokeWidth={0.5}
    />
    <circle
      cx={2.206}
      cy={7.5}
      r={1.956}
      transform="rotate(180 2.206 7.5)"
      fill="#fff"
      stroke="#D0D0D0"
      strokeWidth={0.5}
    />
    <circle
      cx={8.794}
      cy={2.206}
      r={1.956}
      transform="rotate(180 8.794 2.206)"
      fill="#fff"
      stroke="#D0D0D0"
      strokeWidth={0.5}
    />
    <circle
      cx={2.206}
      cy={2.206}
      r={1.956}
      transform="rotate(180 2.206 2.206)"
      fill="#fff"
      stroke="#D0D0D0"
      strokeWidth={0.5}
    />
  </svg>
);

export default ItemDraggable;
