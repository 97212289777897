import { RequestError } from "../../services/errorHandling";
import {
  FiraLoginResponse,
  OwnerDataResponse,
  SimplePermissionItem,
} from "../../types/Response";
import { FiraStore } from "../../types/Stores";
import { FiraUserRole } from "../../utils/enums";
import { AuthActions, BrandActions } from "../actionsContants";
import { AuthenticationActionType } from "./actions";

const FIRA_SESSION_ID = "FIRA_SESSION";
const FIRA_STORE_ID_SESSION = "FIRA_STORE_SESSION";
const FIRA_BUSINESS_ID = "FIRA_BUSINESS_ID";

export interface AuthenticationState {
  isUserLogIn: boolean;
  currentStoreIdSession?: string;
  sessionId?: string;
  user?: FiraLoginResponse;
  isAccountOwner?: boolean;
  currentRole?: FiraUserRole;
  error?: RequestError;
  businessClientId?: string;
  currentPermission?: SimplePermissionItem[];
  redirectedFromFiraBusiness?: boolean;
  accountName: string;
  ownerInfo?: OwnerDataResponse;
}

const initialState: AuthenticationState = {
  isUserLogIn: localStorage.getItem(FIRA_SESSION_ID) ? true : false,
  currentStoreIdSession:
    localStorage.getItem(FIRA_STORE_ID_SESSION) ?? undefined,
  sessionId: localStorage.getItem(FIRA_SESSION_ID) ?? undefined,
  accountName: "",
};

export const authenticationReducer = (
  state = initialState,
  action: AuthenticationActionType
): AuthenticationState => {
  switch (action.type) {
    case AuthActions.loginSuccess: {
      const { payload, superAdmin } = action;
      const response = payload as FiraLoginResponse;
      let storeId = undefined;
      localStorage.setItem(FIRA_SESSION_ID, response.sessionId);
      localStorage.setItem(FIRA_BUSINESS_ID, response.businessClientId || "");

      let isAccountOwner = false;
      let role = response.permissionsStore[0].role;
      if (
        role === FiraUserRole.ACCOUNT_OWNER ||
        role === FiraUserRole.SUPER_ADMIN
      ) {
        isAccountOwner = true;
      }

      if (!isAccountOwner) {
        localStorage.setItem(
          FIRA_STORE_ID_SESSION,
          response.permissionsStore[0].storeId
        );
        storeId = response.permissionsStore[0].storeId;
      }

      if (superAdmin) {
        return {
          ...state,
          isUserLogIn: true,
          user: payload as FiraLoginResponse,
          isAccountOwner,
          sessionId: response.sessionId,
          currentStoreIdSession: storeId,
          currentRole: response.permissionsStore[0].role,
          redirectedFromFiraBusiness: true,
        };
      }

      return {
        ...state,
        isUserLogIn: true,
        user: payload as FiraLoginResponse,
        isAccountOwner,
        sessionId: response.sessionId,
        currentStoreIdSession: storeId,
        currentRole:
          role === FiraUserRole.SUPER_ADMIN
            ? FiraUserRole.ACCOUNT_OWNER
            : response.permissionsStore[0].role,
      };
    }

    case AuthActions.loginFailed: {
      const { payload } = action;
      return { ...initialState, error: payload as RequestError };
    }

    case AuthActions.refreshTokenSuccess: {
      const { payload } = action;
      const response = payload as FiraLoginResponse;
      let altResponse: FiraLoginResponse;
      let isAccountOwner = false;
      const role = response.permissionsStore[0].role;

      if (response.permissionsStore[0].role === FiraUserRole.ACCOUNT_OWNER) {
        isAccountOwner = true;
      }

      if (response.permissionsStore[0].role === FiraUserRole.SUPER_ADMIN) {
        isAccountOwner = true;
        let permissionsRole = {
          ...response.permissionsStore,
        };
        permissionsRole[0].role = FiraUserRole.ACCOUNT_OWNER;
        altResponse = {
          ...response,
          permissionsStore: permissionsRole,
          businessClientId:
            localStorage.getItem(FIRA_BUSINESS_ID) || response.businessClientId,
        };
      } else {
        altResponse = response;
      }

      return {
        ...state,
        isAccountOwner,
        currentRole:
          role === FiraUserRole.SUPER_ADMIN
            ? FiraUserRole.ACCOUNT_OWNER
            : response.permissionsStore[0].role,
        user: altResponse,
      };
    }

    case AuthActions.refreshTokenFailed: {
      localStorage.clear();
      return { isUserLogIn: false, accountName: "" };
    }
    case BrandActions.setCurrentStore: {
      const { payload } = action;
      const store = state.user?.permissionsStore.find(
        (element) => element.storeId === (payload as FiraStore).id
      );
      let userPermissions = store?.permissions;
      let userRole = store?.role;

      if (state.isAccountOwner && state.user) {
        userPermissions = state.user.permissionsStore[0].permissions;
        userRole = state.user.permissionsStore[0].role;
      }

      localStorage.setItem(FIRA_STORE_ID_SESSION, (payload as FiraStore).id);
      return {
        ...state,
        currentStoreIdSession: (payload as FiraStore).id,
        currentPermission: userPermissions,
        currentRole: userRole,
      };
    }

    case AuthActions.logout: {
      localStorage.clear();
      return { isUserLogIn: false, accountName: "" };
    }
    case AuthActions.setStatusBusinessRedirection: {
      return {
        ...state,
        redirectedFromFiraBusiness: action.payload as boolean,
      };
    }
    case AuthActions.getAccountOwnerInfoSuccess: {
      const ownerInfo = action.payload as OwnerDataResponse;
      return { ...state, accountName: ownerInfo.name, ownerInfo };
    }
    default: {
      return state;
    }
  }
};
