import { currencyRegex } from "./constants";

export const getCurrencyFormatted = (value: string) => {
  const match = value.match(currencyRegex);
  const regexPrice = /[^.,1234567890]/g;
  if (value === "") {
    return "0";
  } else if (match) {
    let d = value.replace(",", ".");
    if (d.startsWith("0") && !d.startsWith("0.")) {
      d = d.slice(1);
    }

    return d;
  } else {
    return value.slice(0, -1).replace(regexPrice, "");
  }
};
