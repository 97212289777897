import maskGroup from "../../../assets/svg/Mask-Group.svg";
import maskGroupEnd from "../../../assets/svg/Mask-Group-end.svg";
import styles from "./BreadcrumbNavBar.module.scss";
import { Step } from "../../../views/EditVideoView/EditVideoView";

interface BreadcrumbNavBarProps {
  steps: {
    title: string;
    route: string;
    active: boolean;
    ministeps?: Step[];
  }[];
}

/**
 * Description of Breadcrumb Navbar Component
 * @param {string} title - Receives the title of the route
 * @param {string} route - Receives the path
 * @param {boolean} active - Receives active state for style class
 */

const BreadcrumbNavBar = ({ steps = [] }: BreadcrumbNavBarProps) => {
  return (
    <>
      <div className={styles.BreadCrumbNavBarWrapper}>
        <div className={styles.LeftIconMarkUp}>
          <img src={maskGroup} alt="" />
        </div>
        <ul className={styles.BreadCrumbNavBarStepList}>
          {steps.map((step, i) => (
            <li className={styles["body-14-text"]} key={i}>
              {step.title}
              <div
                className={`${styles["tiny-text"]} ${styles.step} ${
                  step.active ? styles.active : ""
                }`}
              ></div>
              {step.ministeps != undefined && (
                <MiniSteps steps={step.ministeps} />
              )}
              {/* {i == steps.length - 1 && <span>This is the last one</span>} */}
              {/* {i == steps.length - 1 && (
                
              )} */}
            </li>
          ))}
        </ul>

        <div className={styles.RightIconMarkUp}>
          <img src={maskGroupEnd} alt="" />
        </div>
      </div>
    </>
  );
};

interface MiniStepsNavbarProps {
  steps: Step[];
}

export const MiniSteps = ({ steps = [] }: MiniStepsNavbarProps) => {
  return (
    <>
      <ul className={styles.ProgressBar}>
        {steps.map((step, i) => (
          <li key={i}>
            <div
              className={`${styles.ProgressBarStep} ${
                step.active ? styles.active : ""
              }`}
            ></div>
          </li>
        ))}
        <div className={styles.LastLine}></div>
      </ul>
      {/* <ul className={styles.MiniStepsList}>
        {steps.map((step, i) => (
          <li key={i}>
            <div
              className={`${styles.MiniStep} ${
                step.active ? styles.active : ""
              }`}
            ></div>
          </li>
        ))}
      </ul> */}
    </>
  );
};

export default BreadcrumbNavBar;
