import { SVGProps } from "react";

const SvgClockComplete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0C4.478 0 0 4.478 0 10s4.478 10 10 10 10-4.478 10-10S15.522 0 10 0Zm0 18.304A8.305 8.305 0 0 1 1.696 10 8.305 8.305 0 0 1 10 1.696 8.305 8.305 0 0 1 18.304 10 8.305 8.305 0 0 1 10 18.304Z"
      fill="currentColor"
    />
    <path
      d="m13.9 12.826-3.184-2.301V5a.179.179 0 0 0-.178-.178H9.464A.18.18 0 0 0 9.286 5v6.147c0 .059.026.112.073.146l3.692 2.692a.18.18 0 0 0 .25-.038l.639-.871a.178.178 0 0 0-.04-.25Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgClockComplete;
