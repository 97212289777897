import bigSuccess from "../../assets/svg/big-success.svg";
import "./AuthAlert.scss";
import { ArrowRight } from "../FiraIcons";
import Button, { ButtonStyle } from "../Button/Button";

interface Props {
  message?: string;
  skipHandler?(): void;
}
const AuthAlert = ({ message, skipHandler }: Props) => {
  return (
    <div className="wrapper">
      <img src={bigSuccess} />
      <div className="message h5">
        <p>{message}</p>
      </div>
      <div className="skip-wrapper ">
        <Button
          width="76px"
          height="30px"
          buttonStyle={ButtonStyle.transparent}
          clickHandler={skipHandler}
        >
          <div className="buttonInner h6">
            <p className="mr">Skip</p>
            <div className="icon">
              <ArrowRight />
              <ArrowRight />
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default AuthAlert;
