import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { RootState } from "../../utils/types";
import { loginActionCreator } from "../../store/Authentication/actions";
import Input, { InputStyles } from "../Input/Input";
import Button from "../Button/Button";
import ArrowRight from "../FiraIcons/ArrowRight";
import { useTranslation } from "react-i18next";
import { ViewTypes } from "../AuthHeader/AuthHeader";
import "./LoginForm.scss";

/**
 * Description of Login component
 * @param {string} email - user email
 * @param {string} password - user password
 * @function handleSubmit - creates a login action
 * @function validForm - checks validation from email and password
 */
import { emailRegex } from "../../utils/constants";

const LoginForm = () => {
  const { t } = useTranslation();
  const authentication = useSelector(
    (state: RootState) => state.authentication
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const isEmail = emailRegex.test(user);
    if (isEmail) {
      dispatch(loginActionCreator(user, "EMAIL", password));
    } else {
      dispatch(loginActionCreator(user, "USERNAME", password));
    }
  };

  useEffect(() => {
    if (authentication.isUserLogIn) {
      navigate("home");
    }
  }, [authentication.isUserLogIn, navigate]);

  const validForm = () => {
    return user !== "" && password !== "" ? false : true;
  };

  return (
    <form onSubmit={handleSubmit} className="LoginFormContainer">
      <div className="FormGroup">
        <label htmlFor="email-user" className="lead-text form-label">
          {t("auth.login.user")}
        </label>
        <div className="input-container">
          <Input
            inputId="email-user"
            width="316px"
            height="46px"
            type="text"
            value={user}
            inputStyle={InputStyles.normal}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="FormGroup">
        <label htmlFor="password" className="lead-text form-label">
          {t("auth.login.password")}
        </label>
        <div className="input-container">
          <Input
            inputId="password"
            type="password"
            width="316px"
            height="46px"
            showPswButton={true}
            value={password}
            inputStyle={InputStyles.normal}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="transparent">
        <button>
          <div className="tiny-text">
            <Link to={ViewTypes.recovery}>
              {t("auth.login.password_recovery")}
            </Link>
          </div>
        </button>
      </div>
      <Button
        width="316px"
        height="48px"
        type="submit"
        isDisabled={validForm()}
      >
        <div className="ButtonInner ">
          {t("auth.login.button")}
          <ArrowRight className="icon" />
        </div>
      </Button>
    </form>
  );
};

export default LoginForm;
