import axios from "axios";

export interface RequestError {
  timestamp: Date;
  message: string;
  details: string;
}

export const handleError = (error: unknown): RequestError => {
  let result: RequestError;
  if (axios.isAxiosError(error)) {
    if (typeof error.response?.data === "string") {
      const data = error.response.data;
      return { timestamp: new Date(), message: data, details: "unknown" };
    }

    result = error.response?.data as RequestError;
    return result;
  }

  return { timestamp: new Date(), message: "unknown", details: "unknown" };
};
