import React from "react";
import { useDraggable, useDroppable, DndContext } from "@dnd-kit/core";
import { useSelector } from "react-redux";

import { Button, Input } from "../../../components";
import { ButtonStyle } from "../../../components/Button/Button";
import OpenLink from "../../../components/FiraIcons/OpenLink";
import { RootState } from "../../../store/Store";
import { FiraStore } from "../../../types/Stores";

interface UrlFormProps {
  url: string;
  isAdvanced: boolean;
  setUrl(name: string, value: string): void;
  setUrlType(isAdvancedUrl: boolean): void;
}
const UrlForm: React.FC<UrlFormProps> = ({
  url,
  isAdvanced,
  setUrl,
  setUrlType,
}) => {
  const validateUrl = (): string => {
    let redirect = "";
    if (!url.includes("https") && !url.includes("http")) {
      redirect = url.concat("https://", url);
    } else {
      redirect = url;
    }

    return redirect;
  };

  return (
    <div
      style={{
        paddingLeft: "16px",
        paddingRight: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        <span>URL*</span>
        <a style={{ color: "black" }} href={validateUrl()} target="_blank">
          <OpenLink />
        </a>
      </div>
      <UrlInput
        url={url}
        isAdvanced={isAdvanced}
        setUrl={setUrl}
        setUrlType={setUrlType}
      />
    </div>
  );
};

const UrlInput: React.FC<UrlFormProps> = ({
  url,
  isAdvanced,
  setUrl,
  setUrlType,
}) => {
  const { setNodeRef } = useDroppable({
    id: "droppable",
  });
  const { brand, authentication } = useSelector((state: RootState) => state);

  const checkAdditionalsFields = (): boolean => {
    if (brand.currentStore && brand.currentStore.additionalFields) {
      return Object.keys(brand.currentStore.additionalFields).length > 0;
    } else if (
      authentication.ownerInfo &&
      authentication.ownerInfo.additionalFields
    ) {
      return Object.keys(authentication.ownerInfo.additionalFields).length > 0;
    }

    return false;
  };

  const getAdditionalFields = (): { [key: string]: string } => {
    if (brand.currentStore && brand.currentStore.additionalFields) {
      return brand.currentStore.additionalFields;
    } else if (
      authentication.ownerInfo &&
      authentication.ownerInfo.additionalFields
    ) {
      return authentication.ownerInfo.additionalFields;
    }

    return {};
  };

  const hasAdditionalsFields = checkAdditionalsFields();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUrl(name, value);
  };

  const handleDrop = (event: any) => {
    const inputElement = document.getElementById(
      "directUrl"
    ) as HTMLInputElement;
    const cursorPosition = inputElement.selectionStart;

    // Check if cursorPosition is not null
    if (cursorPosition !== null) {
      const textBefore = url.slice(0, cursorPosition);
      const textAfter = url.slice(cursorPosition);
      setUrl(
        "directUrl",
        textBefore + event.active.data.current.text + textAfter
      );
    }
  };

  return (
    <DndContext onDragEnd={handleDrop}>
      <div
        style={{ display: "flex", gap: "16px", flexDirection: "column" }}
        ref={setNodeRef}
      >
        <div style={{ position: "relative" }}>
          <Input
            inputId="directUrl"
            inputName="directUrl"
            type="text"
            value={url}
            onChange={handleInputChange}
            height="40px"
            width="100%"
          />
          <div style={{ position: "absolute", right: 0, top: 0, bottom: 0 }}>
            <Button
              clickHandler={() => setUrlType(!isAdvanced)}
              height="100%"
              buttonStyle={ButtonStyle.transparent}
              isDisabled={!hasAdditionalsFields}
            >
              <span style={{ textDecoration: "underline", fontWeight: 600 }}>
                {isAdvanced ? "URL básica" : "URL Avanzada"}
              </span>
            </Button>
          </div>
        </div>

        {isAdvanced && (
          <div style={{ display: "flex", gap: "10px" }}>
            {Object.entries(getAdditionalFields()).map(([key, _]) => (
              <DraggableTile id={key} text={`{{${key}}}`} />
            ))}
          </div>
        )}
      </div>
    </DndContext>
  );
};

interface DraggableTileProps {
  id: string;
  text: string;
}

const DraggableTile: React.FC<DraggableTileProps> = ({ id, text }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id,
      data: { text },
    });

  const [style, setStyle] = React.useState({});

  React.useEffect(() => {
    if (!isDragging) {
      setStyle({ transform: `translate3d(0px, 0px, 0)`, cursor: "move" });
    } else if (transform) {
      setStyle({
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        cursor: "move",
      });
    }
  }, [isDragging, transform]);

  return (
    <div
      ref={setNodeRef}
      style={{ ...style, ...tileStyle }}
      {...listeners}
      {...attributes}
    >
      {id}
    </div>
  );
};

const tileStyle: React.CSSProperties = {
  backgroundColor: "#067DC70D",
  color: "#067DC7CC",
  padding: "3px 14px",
};

export default UrlForm;
