import { useEffect, useRef, useState } from "react";
import { FullScreen, Play } from "../FiraIcons";
import SvgMuted from "../FiraIcons/Muted";
import SvgPause from "../FiraIcons/Pause";
import SvgVolume from "../FiraIcons/Volume";
import "./VideoControls.scss";

interface VideoControlsProps {
  videoRef: any;
  bottom?: string;
  gap?: string;
  paddingLeft?: string;
  paddingRight?: string;
  hasAutoplay?: boolean;
  mainColor?: string;
}

const VideoControls = ({
  videoRef,
  bottom = "20px",
  gap = "16px",
  paddingLeft = "19px",
  paddingRight = "22px",
  hasAutoplay = false,
  mainColor = "#FFDE07",
}: VideoControlsProps) => {
  const progressBarRef = useRef<any>();
  const [controlStatus, setControlStatus] = useState({
    isPlaying: hasAutoplay,
    isMuted: true,
    progressPosition: 0,
  });

  const updateVideoProgress = (e: any) => {
    // Obtiene el elemento de video y la barra de progreso
    const video = videoRef?.current;
    const progressBar = progressBarRef?.current;

    if (video && progressBar) {
      // Calcula el tiempo actual del video
      const currentTime = video.duration * (parseInt(progressBar.value) / 100);

      // Actualiza el tiempo actual del video
      video.currentTime = currentTime;

      progressBar.value =
        (parseFloat(video.currentTime) / parseFloat(video.duration)) * 100;

      setControlStatus((prevStatus) => ({
        ...prevStatus,
        progressPosition: progressBar.value,
      }));
    }
  };

  const updateProgressBar = () => {
    // Obtiene el elemento de video y la barra de progreso
    const video = videoRef?.current;
    const progressBar = progressBarRef?.current;

    if (video && progressBar) {
      // Calcula el porcentaje de progreso del video
      const progress = (video.currentTime / video.duration) * 100;

      // Actualiza el valor de la barra de progreso
      progressBar.value = progress;

      setControlStatus((prevStatus) => ({
        ...prevStatus,
        progressPosition: progress,
      }));
    }
  };

  const iconStyle = {
    color: "#fff",
    fontSize: "20px",
  };

  const iconPlay = controlStatus.isPlaying ? (
    <SvgPause style={iconStyle} />
  ) : (
    <Play style={iconStyle} />
  );

  const iconMuted = controlStatus.isMuted ? (
    <SvgMuted style={iconStyle} />
  ) : (
    <SvgVolume style={iconStyle} />
  );

  const handlePlay = () => {
    const video = videoRef?.current;

    if (video) {
      if (video.paused) {
        video.play();

        setControlStatus((prevStatus) => ({
          ...prevStatus,
          isPlaying: true,
        }));
      } else {
        video.pause();

        setControlStatus((prevStatus) => ({
          ...prevStatus,
          isPlaying: false,
        }));
      }
    }
  };

  const handleMute = () => {
    const video = videoRef?.current;

    if (video) {
      if (video.muted) {
        video.muted = false;

        setControlStatus((prevStatus) => ({
          ...prevStatus,
          isMuted: false,
        }));
      } else {
        video.muted = true;

        setControlStatus((prevStatus) => ({
          ...prevStatus,
          isMuted: true,
        }));
      }
    }
  };

  const enterFullscreen = () => {
    const video = videoRef?.current;

    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.webkitRequestFullscreen) {
      /* Safari */
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      /* IE11 */
      video.msRequestFullscreen();
    }
  };

  const resetAttributes = () => {
    const video = videoRef?.current;

    if (video) {
      video.currentTime = 0;
      video.pause();

      setControlStatus((prevStatus) => ({
        ...prevStatus,
        isPlaying: false,
        progressPosition: 0,
      }));
    }
  };

  useEffect(() => {
    const video = videoRef?.current;
    const progressBar = progressBarRef?.current;

    if (video) {
      // Actualiza la barra de progreso automaticamente
      video.addEventListener("timeupdate", updateProgressBar);

      video.addEventListener("loadedmetadata", () => {
        resetAttributes();
      });

      video.addEventListener("ended", () => {
        setControlStatus((prevStatus) => ({
          ...prevStatus,
          isPlaying: false,
        }));
      });
    }
  }, [videoRef]);

  useEffect(() => {
    if (progressBarRef.current) {
      progressBarRef.current.style.setProperty(
        "--scrollbar-background",
        mainColor
      );
    }
  }, [progressBarRef]);

  return (
    <div
      id="VideoControls"
      style={{
        bottom: bottom,
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        width: `calc(100% - ${paddingLeft} - ${paddingRight})`,
      }}
    >
      <div
        className="video_controls_wrapper"
        style={{
          gap: gap,
        }}
      >
        <div className="video_control mute_button" onClick={handleMute}>
          {iconMuted}
        </div>
        <div className="video_control play_button" onClick={handlePlay}>
          {iconPlay}
        </div>
        <div className="video_control progress_bar">
          <input
            type="range"
            id="progressBar"
            min="0"
            max="100"
            step="0.1"
            value={controlStatus.progressPosition}
            ref={progressBarRef}
            onChange={updateVideoProgress}
            style={{
              background: `linear-gradient(to right, ${mainColor} 0%, ${mainColor} ${controlStatus.progressPosition}%, rgba(255,255,255,0.35) ${controlStatus.progressPosition}%, rgba(255,255,255,0.35) 100%)`,
            }}
          />
        </div>
        <div
          className="video_control fullscreen_button"
          onClick={enterFullscreen}
        >
          <FullScreen style={iconStyle} />
        </div>
      </div>
    </div>
  );
};

export default VideoControls;
