import * as React from "react";

const GridOfThree = ({
  title,
  titleId,
  ...props
}) => (
<svg     
  width="1em"
  height="1em" 
  viewBox="0 0 7 7" 
  fill="none" 
  xmlns="http://www.w3.org/2000/svg">
  <rect width="1.82414" height="1.80556" fill="currentColor"/>
  <rect y="2.1665" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect y="4.33301" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="2.18848" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="2.18848" y="2.1665" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="2.18848" y="4.33301" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="4.37939" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="4.37939" y="2.1665" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect x="4.37939" y="4.33301" width="1.82414" height="1.80556" fill="currentColor"/>
</svg>

);

export default GridOfThree;