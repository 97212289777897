import { t } from "i18next";
import {useContext, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { getPrevWebConfigurationsService } from "../../../services/events";
import { RootState } from "../../../store/Store";
import { FiraLoginInput, TemplatesResponse } from "../../../utils/types";
import Button, { ButtonStyle } from "../../Button/Button";
import {
  Add,
  ArrowLeft,
  ArrowRight,
  SimpleCheck,
  SimpleX,
} from "../../FiraIcons";
import styles from "./EventTemplates.module.scss";
import {EventConfigurationContext} from "../../../views/EventConfigurationView/EventConfigurationView";

const EventTemplates = () => {
  const { event, utils } = useSelector((state: RootState) => state);
  const [paginatedData, setPaginatedData] = useState<TemplatesResponse[][]>([]);
  const { useCustomConfig, useTemplate } = useContext(EventConfigurationContext);
  const [paginationIsActive, setPaginationIsActive] = useState<boolean>(true);
  const [cantGoNext, setCantGoNext] = useState<boolean>(false);
  const [cantGoBack, setCantGoBack] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [showTemplates, setShowTemplates] = useState<boolean>(false);
  const [tData, setTData] = useState<TemplatesResponse[]>([]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplatesResponse | null>(null);

  const getFiraConfigs = () => {
    if (event.currentEvent) {
      getPrevWebConfigurationsService(event.currentEvent?.storeId, 0, 100).then(
        (val) => {
          if (val.status === 200) {
            val.data.length > 0;

            setTData(val.data);
          } else if (val.status === 500) {
            console.error(val.statusText);
          }
        }
      );
    }
  };

  const handlePagination = (arr: TemplatesResponse[], chunkSize: number) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const handleNextClick = () => {
    if (currentPage === paginatedData.length - 1) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage === 0) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSelection = async (data: TemplatesResponse | null) => {
    if (selectedTemplate === data) {
      setSelectedTemplate(null);
    } else {
      setSelectedTemplate(data);
    }
  };

  useEffect(() => {
    useTemplate(selectedTemplate)
  }, [selectedTemplate]);

  useEffect(() => {
    if (tData) setPaginatedData(handlePagination(tData, 3));
    if (currentPage === 0) {
      setCantGoBack(true);
    } else {
      setCantGoBack(false);
    }

    if (currentPage === paginatedData.length - 1) {
      setCantGoNext(true);
    } else {
      setCantGoNext(false);
    }
  }, [currentPage]);

  useEffect(() => {
    if (tData)
      if (tData.length > 3) {
        setPaginatedData(handlePagination(tData, 3));
        setPaginationIsActive(true);
      } else {
        setTData(tData);
        setPaginationIsActive(false);
      }
    if (paginatedData != undefined) {
      setShowTemplates(true);
    }
  }, [tData]);

  useEffect(() => {
      if(utils.screenWidth > 940){
        setPaginatedData(handlePagination(tData, 3));
      }

      if(utils.screenWidth < 940){
        setPaginatedData(handlePagination(tData, 2));
      }

      if(utils.screenWidth < 727){
        setPaginatedData(handlePagination(tData, 1));
      }
  }, [utils.screenWidth]);

  useEffect(() => {
    getFiraConfigs();
  }, []);

  return (
    <>
      <div className={`${styles.TemplateParamsWrapper}`}>
        {paginationIsActive && (
          <div
            className={`${cantGoBack ? styles.disabled : ""} ${
              styles.PaginationBtn
            } ${styles.BtnLeft}`}
            onClick={handlePreviousClick}
          >
            <ArrowLeft />
          </div>
        )}
        <div className={styles.ContentWrapper}>
          <div
            className={`${!paginationIsActive ? styles.Center : ""} ${
              styles.TemplatesWrapper
            }`}
          >
            <div className={styles.TemplateItems}>
              <>
                {showTemplates &&
                  (!paginationIsActive
                    ? tData.map((item: TemplatesResponse, index: number) => (
                        <Template
                          data={item}
                          templateSelection={handleSelection}
                          key={index}
                          selectedItem={selectedTemplate}
                        />
                      ))
                    : paginatedData[currentPage]?.map(
                        (item: TemplatesResponse, index: number) => (
                          <Template
                            data={item}
                            templateSelection={handleSelection}
                            key={index}
                            selectedItem={selectedTemplate}
                          />
                        )
                      ))}
              </>
            </div>
          </div>
        </div>
        <div className={styles.AddMore} onClick={useCustomConfig}>
          <div className={styles.AddButton}>
              <Add/>
          </div>
          {t("event_creation.params.interfaceAddMore")}
        </div>
        {paginationIsActive && (
          <div
            className={`${cantGoNext ? styles.disabled : ""} ${
              styles.PaginationBtn
            } ${styles.BtnRight}`}
            onClick={handleNextClick}
          >
            <ArrowRight />
          </div>
        )}
      </div>
    </>
  );
};

interface TemplateProps {
  data: TemplatesResponse;
  templateSelection(template: TemplatesResponse): void;
  selectedItem: TemplatesResponse | null;
}

export const Template = ({
  data,
  templateSelection,
  selectedItem,
}: TemplateProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const checkSelection = () => {
    if (selectedItem === data) {
      return true;
    }
  };

  const handleSelection = (data: TemplatesResponse) => {
    templateSelection(data);
  };

  return (
    <>
      <div
        className={styles.TemplateContainer}
        onClick={() => {
          handleSelection(data);
        }}
      >
        <div
          className={`${styles.TemplateItem} ${
            checkSelection() ? styles.Selected : ""
          }`}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div className={styles.TemplateTitle}>
            <span>{data.eventName}</span>
          </div>
          <div className={styles.TemplateParams}>
            {data.firaWebConfiguration.mainColor != null && (
              <div className={styles.ParamsItem}>
                <div
                  className={`${styles.ListTitle} ${styles["body-12-low-text"]}`}
                >
                  <div className={`${styles.ListTitleIcon}`}>
                    <SimpleCheck />
                  </div>
                  Color
                  <div
                    style={{
                      backgroundColor: data.firaWebConfiguration.mainColor,
                    }}
                    className={styles.ColorCircle}
                  ></div>
                </div>
              </div>
            )}
            {data.firaWebConfiguration.enableLogin && (
              <div className={styles.ParamsItem}>
                <div
                  className={`${styles.ListTitle} ${styles["body-12-low-text"]}`}
                >
                  <div className={`${styles.ListTitleIcon}`}>
                    <SimpleCheck />
                  </div>
                  Login
                </div>
                {data.firaWebConfiguration.loginForm && (
                  <ul>
                    {data.firaWebConfiguration.loginForm.map(
                      (field: FiraLoginInput, i: number) => (
                        <li key={i}>
                          <div className={`${styles.ListItemIcon}`}>
                            <SimpleCheck />
                          </div>
                          {field.fieldLabel}
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            )}

            <div className={styles.ParamsItem}>
              <div
                className={`${styles.ListTitle} ${styles["body-12-low-text"]}`}
              >
                <div className={`${styles.ListTitleIcon}`}>
                  <SimpleCheck />
                </div>
                {t("event_creation.params.attachments")}
              </div>
              <ul>
                <li>
                  <div className={`${styles.ListItemIcon}`}>
                    {data.firaWebConfiguration.likesUrls?.length !== 0 ? (
                      <SimpleCheck />
                    ) : (
                      <SimpleX />
                    )}
                  </div>
                  Likes
                </li>
                <li>
                  <div className={`${styles.ListItemIcon}`}>
                    <SimpleCheck />:
                  </div>
                  {t("event_creation.params.words_to_moderate")}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Button
          width="186px"
          buttonStyle={
            isHovering || checkSelection()
              ? ButtonStyle.main
              : ButtonStyle.border
          }
        >
          <span>{t("event_creation.params.interfaceButton")}</span>
        </Button>
      </div>
    </>
  );
};

export default EventTemplates;
