import { LandingActions } from "../actionsContants";
import { FiraLanding } from "../../types/Landing";
import { handleError, RequestError } from "../../services/errorHandling";
import { ThunkDispatch } from "redux-thunk/es/types";
import { AnyAction } from "redux";
import {
  landingCreateService,
  landingUpdateService,
} from "../../services/landing";

interface CreateLandingActionType {
  type:
    | LandingActions.createLandingFailed
    | LandingActions.createLandingLoading
    | LandingActions.createLandingSuccess;
  payload?: FiraLanding | RequestError;
}

export const createLandingActionCreator = (firaLanding: FiraLanding) => {
  return async (
    dispatch: ThunkDispatch<
      CreateLandingActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: LandingActions.createLandingLoading });
      const response = await landingCreateService(firaLanding);

      dispatch({
        type: LandingActions.createLandingSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: LandingActions.createLandingFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateLandingActionType {
  type:
    | LandingActions.updateLandingLoading
    | LandingActions.updateLandingSuccess
    | LandingActions.updateLandingFailed;
  payload?: FiraLanding | RequestError;
}

export const updateLandingActionCreator = (
  id: string,
  landing: FiraLanding
) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateLandingActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: LandingActions.updateLandingLoading });

      const response = await landingUpdateService(id, landing);
      dispatch({
        type: LandingActions.updateLandingSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: LandingActions.updateLandingFailed,
        payload: handleError(error),
      });
    }
  };
};
export interface CleanLandingMessageActionType {
  type: LandingActions.cleanLandingMessage;
}
export const cleanLandingMessage = (): CleanLandingMessageActionType => {
  return { type: LandingActions.cleanLandingMessage };
};

export type LandingActionType =
  | CreateLandingActionType
  | CleanLandingMessageActionType
  | UpdateLandingActionType;
