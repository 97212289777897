import { LoginResponse, UserRegisterForm } from "../utils/types";
import { apiBusiness, apiBusinessPrivate, apiManagement } from "./api";
import { FiraLoginResponse } from "../types/Response";

export const loginService = (
  password: string,
  email?: string | null,
  username?: string
) => {
  if (email) {
    return apiManagement.post<LoginResponse>("/api/v1/fira-user/login", {
      email,
      password,
    });
  }

  return apiManagement.post<LoginResponse>("/api/v1/fira-user/login", {
    username,
    password,
  });
};

export const logoutService = (sessionId: string) => {
  return apiBusinessPrivate.put(
    `/v1/authentication-service/logout/${sessionId}`
  );
};

export const refreshTokenService = (sessionId: string, storeId: string) => {
  return apiManagement.put<LoginResponse>(
    `/api/v1/fira-authentication/refresh-token/${sessionId}/store/${storeId}`
  );
};

export const registerService = (registerForm: UserRegisterForm) => {
  console.log(registerForm);
  return apiBusiness.post("/v1/user-service/create-user", registerForm);
};

export const sendActivationCodeService = (email: string) => {
  return apiManagement.get(
    `/api/v1/fira-user/send-activation-code/user-email/${email}`
  );
};

export const activateUserService = (
  confirmationCode: string,
  userId: string
) => {
  return apiManagement.get(
    `/api/v1/fira-user/activate-user/${confirmationCode}/user-id/${userId}`
  );
};

export const sendRecoverCodeService = (email: string) => {
  return apiBusiness.get(`/v1/user-service/send-pwd-code/${email}`);
};

// TODO: endpoint may change in the future, NEED TO CHANGE!!!
export const validateRecoveryCodeService = (
  recoveryCode: string,
  email: string
) => {
  return apiManagement.get(
    `/api/v1/fira-user/validate-pwd-code/${recoveryCode}/email/${email}`
  );
};

export const changeUserPasswordService = (
  newPassword: string,
  recoveryCode: string,
  email: string
) => {
  return apiBusiness.post(
    `/v1/user-service/recover-user/${recoveryCode}/user-email/${email}`,
    newPassword,
    {
      headers: { "Content-Type": "text/plain" },
    }
  );
};

export const firaBusinessLoginService = (
  token: string,
  userId: string,
  businessClientId: string
) => {
  return apiBusiness.put<FiraLoginResponse>(
    `/v1/super-admin/generate-authorization/${token}/${userId}/${businessClientId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
