import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import SelectButton from "../Checkbox/Checkbox";
import styles from "./InputSearch.module.scss";
import { useTranslation } from "react-i18next";

export interface FiraInputSearchType {
  id?: string;
  name: string;
  value?: string | number;
}

interface FiraInputSearchProps {
  width?: string;
  height?: string;
  placeholder?: string;
  options?: FiraInputSearchType[];
  multipleSelection?: boolean;
  onSelect(options: FiraInputSearchType[]): void;
  currentSelection?: FiraInputSearchType[];
  listHeight?: string;
}

const FiraInputSearch = ({
  width = "320px",
  height = "40px",
  placeholder,
  options,
  multipleSelection = false,
  onSelect,
  currentSelection = [],
  listHeight = "160px",
}: FiraInputSearchProps) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [valuesList, setValuesList] = useState<FiraInputSearchType[]>(
    options || []
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const allSelected = valuesList.every((option) =>
      currentSelection.includes(option)
    );

    setSelectAll(allSelected);
  }, [currentSelection, valuesList]);

  const handleSelection = (selectedOption: FiraInputSearchType) => {
    let updatedSelection;
    if (
      currentSelection.some((option) => option.value === selectedOption.value)
    ) {
      updatedSelection = currentSelection.filter(
        (option) => option.value !== selectedOption.value
      );
    } else {
      updatedSelection = [...currentSelection, selectedOption];
    }
    onSelect(updatedSelection);
  };

  const handleFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setSearchValue(keyword);
    setIsOptionsOpen(true);
    if (keyword !== "") {
      setValuesList(
        options?.filter((value) =>
          value.name.toLowerCase().includes(keyword.toLowerCase())
        ) || []
      );
    } else {
      setValuesList(options || []);
    }
  };

  const handleSelectAll = () => {
    if (!selectAll && options) {
      if (searchValue !== "") {
        let selectedOptions: FiraInputSearchType[] = [];
        selectedOptions = options.filter((option) =>
          valuesList.some((value) => value.name === option.name)
        );
        onSelect(selectedOptions);
      } else {
        onSelect(options);
      }
    } else {
      onSelect([]);
    }
    setSelectAll(!selectAll);
  };

  const handleInputClick = () => {
    setIsOptionsOpen(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target as Node) &&
      event.target instanceof HTMLElement &&
      !event.target.closest(`.${styles.FiraInputSearchOptions}`)
    ) {
      setIsOptionsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`${isOptionsOpen ? styles.isOpen : ""} ${
        styles.FiraInputSearchWrapper
      }`}
    >
      <div
        className={`${styles.FiraInputSearch} ${
          isOptionsOpen ? styles.isOpen : ""
        } `}
        style={{ width: width, height: height }}
      >
        <input
          type="text"
          placeholder={placeholder || ""}
          value={searchValue}
          onChange={handleFiltering}
          ref={inputRef}
          onClick={handleInputClick}
        />
      </div>

      {isOptionsOpen && (
        <div className={styles.FiraInputSearchOptions} style={{}}>
          <div className={styles.FiraInputSearchOptions__Item}>
            <SelectButton
              inputName="test"
              isActive={selectAll}
              stopPropagation={true}
              clickHandler={handleSelectAll}
            />
            <label>{t("buttons.select_all")}</label>
          </div>
          {valuesList.map((option, index) => (
            <div className={styles.FiraInputSearchOptions__Item} key={index}>
              <SelectButton
                inputName={"" + index}
                isActive={currentSelection.includes(option)}
                stopPropagation={true}
                clickHandler={() => handleSelection(option)}
              />
              <label>{option.name}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FiraInputSearch;
