import styles from "./LandingTopBar.module.scss";
import LeftArrowNoPadding from "../../FiraIcons/LeftArrowNoPadding";
import { useNavigate } from "react-router-dom";
import { CircleX, Computer, InfoCircleV2, Smartphone } from "../../FiraIcons";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import Button, { ButtonStyle } from "../../Button/Button";
import { useTranslation } from "react-i18next";
import PopUpInfo from "../../PopUpInfo/PopUpInfo";
import { useDebounce } from "../../../utils/useDebounce";
import { landingExistByNameService } from "../../../services/landing";
import { AlertType, usePopupAlert } from "../../../providers/AlertProvider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import { cleanLandingMessage } from "../../../store/Landing/LandingActions";
import { FiraUserRole } from "../../../utils/enums";
import { useLandingConfig } from "../../../providers/LandingConfigProvider";

interface LandingTopBarProps {
  onCreate(): void;
}

const LandingTopBar = ({ onCreate }: LandingTopBarProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { landing, authentication } = useSelector((state: RootState) => state);
  const {
    isGlobalConfig,
    layoutMode,
    setLayoutMode,
    landingConfig,
    setLandingConfig,
    mobileModuleBar,
    existingLanding,
    creatingPage,
    saveGlobalLanding,
  } = useLandingConfig();
  const [URL, setURL] = useState<string>(
    "" + process.env.REACT_APP_BASE_URL_LANDING
  );
  const [validUrl, setValidUrl] = useState<boolean>(false);
  const [available, setAvailable] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [domain, setDomain] = useState<string>("");
  const [elementTarget, setElementTarget] = useState<HTMLElement>();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const debounced = useDebounce(domain, 200);
  const showAlert = usePopupAlert();
  const regex = /^[a-zA-Z0-9-]+$/;
  const { utils } = useSelector((state: RootState) => state);

  const info = (
    <p
      style={{
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "10px",
      }}
    >
      <span>{t("views.landing.infoLineOne")}</span> <br />
      <span>{t("views.landing.infoLineTwo").split("/")[0]}/</span>
      <span style={{ color: "red" }}>
        {t("views.landing.infoLineTwo").split("/").slice(1).join("/")}
      </span>
    </p>
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDomain(value);
    setValidUrl(regex.test(value));
    dispatch(cleanLandingMessage());
  };

  const inputValidation = () => {
    if (isEditing) {
      if (!validUrl || !available) {
        return styles.invalid;
      } else if (validUrl && available) {
        return styles.valid;
      }
    }
    return "";
  };

  const cancel = () => {
    setIsEditing(false);
    if (existingLanding) {
      setDomain(existingLanding.name);
    } else {
      setDomain("");
    }
  };

  const backButtonHandler = () => {
    if (isGlobalConfig) {
      navigate("/home/mainboard");
      return;
    }

    navigate("/home/landing");
  };

  const handleInfoPopUp = (e: React.MouseEvent<HTMLElement>) => {
    setShowInfo(!showInfo);
    setElementTarget(e.currentTarget);
  };

  const checkExistence = async (domainName: string) => {
    if (domainName !== "") {
      const response = await landingExistByNameService(domainName);
      setAvailable(!response.data);
    }
  };

  const saveDomain = () => {
    setIsEditing(false);
    if (validUrl && available) {
      setLandingConfig({ ...landingConfig, name: domain });
    } else if (!available) {
      showAlert(AlertType.error, t("views.landing.createError"));
      setDomain("");
      setIsEditing(true);
    }
  };
  const createLanding = () => {
    if (validUrl) {
      onCreate();
    }
  };

  useEffect(() => {
    if (existingLanding !== null) {
      setDomain(existingLanding.name);
      setValidUrl(regex.test(existingLanding.name));
    }
  }, [existingLanding]);

  useEffect(() => {
    checkExistence(domain);
  }, [debounced]);

  useEffect(() => {
    if (utils.screenWidth < 730) {
      setURL("https://...firaonlive.com/");
    } else if (utils.screenWidth > 730) {
      setURL("" + process.env.REACT_APP_BASE_URL_LANDING);
    }
  }, [utils]);

  return (
    <>
      <div className={styles.LandingTopBarWrapper}>
        <div
          className={styles.LandingTopBarWrapper__BackBtn}
          onClick={backButtonHandler}
        >
          <LeftArrowNoPadding />
          <span>{t("views.landing.backBtn")}</span>
        </div>
        {authentication.currentRole !== FiraUserRole.OPERATOR &&
          !isGlobalConfig && (
            <div className={styles.LandingTopBarWrapper__Edit}>
              {showInfo && (
                <PopUpInfo
                  title={""}
                  elementRef={elementTarget}
                  message={info}
                />
              )}

              <div
                style={{ paddingTop: 6 }}
                onMouseEnter={handleInfoPopUp}
                onMouseLeave={handleInfoPopUp}
              >
                <InfoCircleV2 />
              </div>

              <div className={styles.LandingTopBarWrapper__Edit__FauxInput}>
                <div
                  className={styles.LandingTopBarWrapper__Edit__FauxInput__Url}
                >
                  {URL}
                </div>

                <div style={{ width: "100%" }}>
                  {isEditing ? (
                    <input
                      type="text"
                      maxLength={75}
                      value={domain}
                      onChange={handleInputChange}
                      className={inputValidation()}
                      placeholder={t("views.landing.storeName")}
                    />
                  ) : (
                    <span>{domain}</span>
                  )}
                </div>
                {isEditing ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      buttonStyle={ButtonStyle.transparent}
                      width={"70px"}
                      clickHandler={saveDomain}
                    >
                      <span
                        className={
                          styles.LandingTopBarWrapper__Edit__FauxInput__InnerButton
                        }
                      >
                        {t("views.landing.saveBtn")}
                      </span>
                    </Button>
                    <CircleX
                      color={"#D5DDE0"}
                      fontSize={24}
                      style={{ cursor: "pointer" }}
                      onClick={cancel}
                    />
                  </div>
                ) : (
                  <Button
                    buttonStyle={ButtonStyle.transparent}
                    width={"70px"}
                    clickHandler={() => setIsEditing(true)}
                  >
                    <span
                      className={
                        styles.LandingTopBarWrapper__Edit__FauxInput__InnerButton
                      }
                    >
                      {t("views.landing.editBtn")}
                    </span>
                  </Button>
                )}
              </div>
            </div>
          )}
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <div className={styles.LandingTopBarWrapper__LayoutButtons}>
            <div
              className={`${
                styles.LandingTopBarWrapper__LayoutButtons__Button
              } ${layoutMode === "DESKTOP" ? styles.activeLayout : ""}`}
              onClick={() => setLayoutMode("DESKTOP")}
            >
              <Computer width={20} />
            </div>
            <div
              className={`${
                styles.LandingTopBarWrapper__LayoutButtons__Button
              } ${layoutMode === "MOBILE" ? styles.activeLayout : ""}`}
              onClick={() => setLayoutMode("MOBILE")}
            >
              <Smartphone height={22} />
            </div>
          </div>
          {!isGlobalConfig ? (
            <Button
              clickHandler={createLanding}
              isDisabled={landing.isLoading || isEditing}
              width={mobileModuleBar ? "70px" : "106px"}
            >
              {creatingPage
                ? t("views.landing.createBtn")
                : t("views.landing.saveBtn")}
            </Button>
          ) : (
            <Button
              clickHandler={saveGlobalLanding}
              isDisabled={landing.isLoading}
              width={mobileModuleBar ? "70px" : "106px"}
            >
              {t("views.landing.saveBtn")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default LandingTopBar;
