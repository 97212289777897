export const timeTextFormatHMS = (total?: number) => {
  var timeConvert = "";
  if (total && total > 0) {
    if (total > 60) {
      var h = total / 60;
      var x = h - Math.floor(h);
      var horas = x * 60;

      if (total > 4320) {
        timeConvert = Math.floor(h) + "h";
      } else {
        timeConvert = Math.floor(h) + "h:" + Math.floor(horas) + "m";
      }
    } else {
      var minutos = Math.floor(total);
      var seg = total - minutos;
      timeConvert = minutos + "m:" + Math.floor(seg * 60) + "s";
    }
  } else {
    timeConvert = "0";
  }
  return timeConvert;
};

export const timeTextFormatDHMS = (totalIn?: number) => {
  var timeConvert = "";
  let total = totalIn ? totalIn / 1000 / 60 : 0;
  if (total && total > 0) {
    if (total > 60) {
      var h = total / 60;
      var x = h - Math.floor(h);
      var horas = x * 60;

      if (total > 4320) {
        var d = Math.floor(h) / 24;
        timeConvert = Math.floor(d) + "d";
      } else {
        timeConvert = Math.floor(h) + "h:" + Math.floor(horas) + "m";
      }
    } else {
      var minutos = Math.floor(total);
      var seg = total - minutos;
      timeConvert = minutos + "m:" + Math.floor(seg * 60) + "s";
    }
  } else {
    timeConvert = "0";
  }
  return timeConvert;
};
