import { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateAction, View } from "react-big-calendar";
import { useTranslation } from "react-i18next";
import {
  add,
  format,
  startOfMonth,
  startOfWeek,
  sub,
  endOfWeek,
  isSameMonth,
  endOfMonth,
} from "date-fns";

import {
  Button,
  Table,
  TableCell,
  TableHead,
  TableRow,
  ViewHeader,
} from "../../components";
import { ButtonStyle } from "../../components/Button/Button";
import { TableStyles } from "../../components/Table/Table";
import {
  Add,
  ArrowLeft,
  ArrowRight,
  ChatBubble,
  Edit,
  EmptySwing,
  Statistics,
} from "../../components/FiraIcons";
import { FiraEvent, RootState } from "../../utils/types";
import {
  getStoreEventsActionCreator,
  resetUpdateEventActionCreator,
  setCurrentEventActionCreator,
  resetEditEventActionCreator,
} from "../../store/Event/EventActions";
import FiraCalendar from "../../components/FiraCalendar/FiraCalendar";
import {
  convertDateLocal,
  dateFormat,
  locale,
  timeFormat,
  timeFormatTest,
  timeZoneDate,
} from "../../utils/handleDates";

import bigSuccess from "../../assets/svg/big-success.svg";
import styles from "./EventsView.module.scss";
import { checkBrowserLanguage } from "../../utils/checkBrowserLanguage";
import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";
import { useNavigate } from "react-router-dom";
import { getEventService } from "../../services/events";
import PopUpCreateEventTop from "../../components/PopUpCreateEvent/PopUpCreateEventTop";
import { FiraEventType, FiraUserRole } from "../../utils/enums";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import { setPrevPageActionCreator } from "../../store/Utils/UtilsActions";

export enum EventStatus {
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
  DONE = "DONE",
}

let timerID: NodeJS.Timeout;

const handleCalendarControllerNavigation = (
  date: Date,
  action: NavigateAction,
  calendarView: View
): Date => {
  switch (calendarView) {
    case "month":
      if (action === "PREV") {
        return sub(endOfMonth(date), { months: 1 });
      } else if (action === "NEXT") {
        return add(startOfMonth(date), { months: 1 });
      }
      return startOfMonth(date);
    case "week":
      if (action === "PREV") {
        return sub(date, { weeks: 1 });
      } else if (action === "NEXT") {
        return add(date, { weeks: 1 });
      }
      return startOfWeek(date);
    case "day":
      if (action === "PREV") {
        return sub(date, { days: 1 });
      } else if (action === "NEXT") {
        return add(date, { days: 1 });
      }
      return date;
    default:
      return date;
  }
};

const formatDateToUpperCase = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
const handleCalendarControllerLabel = (
  date: Date,
  calendarView: View
): string => {
  switch (calendarView) {
    case "month": {
      const monthDate = format(date, "MMMM", {
        locale: checkBrowserLanguage(),
      });
      const finalMonth = formatDateToUpperCase(monthDate);
      return `${finalMonth} ${format(date, "yyyy", {
        locale: checkBrowserLanguage(),
      })}`;
    }
    case "week": {
      const start = startOfWeek(date);
      const end = endOfWeek(date);
      const startMonthDate = format(start, "MMMM", {
        locale: checkBrowserLanguage(),
      });
      const startFinalMonth = formatDateToUpperCase(startMonthDate);

      const endMonthDate = format(end, "MMMM", {
        locale: checkBrowserLanguage(),
      });
      const endFinalMonth = formatDateToUpperCase(endMonthDate);

      const label = `${startFinalMonth} ${format(start, "dd", {
        locale: checkBrowserLanguage(),
      })} – ${!isSameMonth(start, end) ? endFinalMonth : ""} ${format(
        end,
        "dd",
        {
          locale: checkBrowserLanguage(),
        }
      )}`;
      return label;
    }
    case "day": {
      const day = formatDateToUpperCase(
        format(date, "cccc", { locale: checkBrowserLanguage() })
      );
      return `${day} ${format(date, "MMM dd", {
        locale: checkBrowserLanguage(),
      })}`;
    }
    default:
      return "";
  }
};

const EventsView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { brand, event, authentication } = useSelector(
    (state: RootState) => state
  );

  const [calendarIsActive, setCalendarIsActive] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarView, setCalendarView] = useState<View>("month");
  const [controllerLabel, setControllerLabel] = useState("");
  const [edited, setEdited] = useState<boolean>(false);
  const [successPopUpIsOpen, setSuccessPopUpIsOpen] = useState<boolean>(false);
  const [createEventPopup, setCreateEventPopup] = useState<boolean>(false);
  const [popUpFromViewIsOpen, setPopUpFromViewIsOpen] =
    useState<boolean>(false);

  const [eventSuccess, setEventSuccess] = useState<{
    date: string;
    name: string;
  }>({
    date: "",
    name: "",
  });

  const onCalendarViewChange = (view: View) => {
    setCalendarIsActive(true);
    setCalendarView(view);
  };

  const messageToShow = (id: string) => {
    getEventService(id).then((val) => {
      const date = new Date(val.data.scheduledDate).toLocaleDateString();
      const ev = {
        date: date,
        name: val.data.eventName,
      };
      setEventSuccess(ev);
      setSuccessPopUpIsOpen(true);
    });
    timerID = setTimeout(() => {
      setSuccessPopUpIsOpen(false);
    }, 4000);

    if (event.updatedEvent) {
      dispatch(resetUpdateEventActionCreator());
    }

    if (event.editedEvent) {
      dispatch(resetEditEventActionCreator());
    }
  };

  const onEventListChange = () => {
    setCalendarIsActive(false);
  };

  const onCalendarNavigate = (date: Date) => {
    setCurrentDate(date);
  };

  const handleControllerNavigation = (action: NavigateAction) => {
    setCurrentDate(
      handleCalendarControllerNavigation(currentDate, action, calendarView)
    );
  };

  const handleClose = () => {
    setSuccessPopUpIsOpen(false);
  };

  useEffect(() => {
    setCalendarView(calendarView);
    setControllerLabel(
      handleCalendarControllerLabel(currentDate, calendarView)
    );
  }, [currentDate, calendarView]);

  useEffect(() => {
    if (brand.currentStore) {
      dispatch(getStoreEventsActionCreator(brand.currentStore.id));
    }
  }, [brand.currentStore]);

  useEffect(() => {
    if (event.updatedEvent) {
      setEdited(false);
      if (event.updatedEventResponse != undefined) {
        messageToShow(event.updatedEventResponse?.id);
      }
    }

    if (event.editedEvent) {
      setEdited(true);
      if (event.editEventResponse != undefined) {
        messageToShow(event.editEventResponse?.id);
      }
    }

    if (event.cancelSuccess && brand.currentStore) {
      dispatch(getStoreEventsActionCreator(brand.currentStore.id));
    }
  }, [event.updatedEvent, event.editedEvent, event.cancelSuccess]);

  useEffect(() => {
    dispatch(setPrevPageActionCreator("Events"));
  }, []);

  const [elemetTarget, setElementTarget] = useState<HTMLElement>();

  const handleClosePopUp = () => {
    setCreateEventPopup(false);
  };

  const handleClickEvent = (e: SyntheticEvent<HTMLButtonElement>) => {
    setElementTarget(e.currentTarget);
    setCreateEventPopup(true);
    setPopUpFromViewIsOpen(true);
  };

  const handleClosePopUps = () => {
    setCreateEventPopup(false);
    setPopUpFromViewIsOpen(false);
  };

  let titleSection;
  {
    titleSection = (
      <span className={`h3 ${styles.LimitText}`}>
        {t("views.events.title")}
      </span>
    );
  }

  let buttonOption;
  {
    buttonOption =
      authentication.currentRole === FiraUserRole.MODERATOR ? (
        <></>
      ) : (
        <Button width="140px" height="32px" clickHandler={handleClickEvent}>
          <div className={styles.TextButton}>
            <span className="lead-text">
              {t("views.events.popup.button_create")}
            </span>
            <Add width={"17"} height={"17"} />
          </div>
        </Button>
      );
  }
  return (
    <>
      {successPopUpIsOpen && (
        <SuccessMessage
          edited={edited}
          eventName={eventSuccess?.name}
          date={eventSuccess?.date}
          onClose={handleClose}
          isOpen={successPopUpIsOpen}
        />
      )}
      {createEventPopup && (
        <PopUpCreateEventTop
          elementRef={elemetTarget}
          onClose={handleClosePopUp}
        />
      )}
      <FiraNavBar titleSection={titleSection} trailingOptions={buttonOption} />

      <EventsHeader
        calendarIsActive={calendarIsActive}
        currentCalendarView={calendarView}
        controllerLabel={controllerLabel}
        handleCalendarActivation={onEventListChange}
        handleCalendarViewChange={onCalendarViewChange}
        handleControllerNavigation={handleControllerNavigation}
      />

      {calendarIsActive ? (
        <FiraCalendar
          date={currentDate}
          view={calendarView}
          handleViewChange={onCalendarViewChange}
          handleCalendarNavigate={onCalendarNavigate}
          events={event.list}
          popUpFromViewIsOpen={popUpFromViewIsOpen}
          closeViewPopUp={handleClosePopUps}
        />
      ) : (
        <EventsTable events={event.list.slice().reverse()} />
      )}
    </>
  );
};

interface EventHeaderProps {
  calendarIsActive: boolean;
  currentCalendarView: View;
  controllerLabel: string;
  handleCalendarActivation(): void;
  handleCalendarViewChange(view: View): void;
  handleControllerNavigation(action: NavigateAction): void;
}
const EventsHeader = ({
  calendarIsActive,
  currentCalendarView,
  controllerLabel,
  handleCalendarActivation,
  handleCalendarViewChange,
  handleControllerNavigation,
}: EventHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.eventsHeader}>
      <div className={styles.eventList}>
        <Button
          height="44px"
          buttonStyle={calendarIsActive ? ButtonStyle.border : ButtonStyle.main}
          className={calendarIsActive ? "body-14-text" : "h6"}
          clickHandler={handleCalendarActivation}
        >
          {t("views.events.header.listButton")}
        </Button>
      </div>
      {calendarIsActive && (
        <CalendarControler
          dateLabel={controllerLabel}
          onNavigate={handleControllerNavigation}
        />
      )}
      <div className={styles.buttonGroup}>
        <button
          onClick={() => handleCalendarViewChange("day")}
          className={`${styles.left} ${styles.CalendarViewButton} ${
            calendarIsActive && currentCalendarView === "day"
              ? `${styles.IsActive} h6`
              : "lead-text"
          }`}
        >
          {t("views.events.header.day")}
        </button>
        <button
          onClick={() => handleCalendarViewChange("week")}
          className={`${styles.middle} ${styles.CalendarViewButton} ${
            calendarIsActive && currentCalendarView === "week"
              ? `${styles.IsActive} h6`
              : "lead-text"
          }`}
        >
          {t("views.events.header.week")}
        </button>
        <button
          onClick={() => handleCalendarViewChange("month")}
          className={`${styles.right} ${styles.CalendarViewButton} ${
            calendarIsActive && currentCalendarView === "month"
              ? `${styles.IsActive} h6`
              : "lead-text"
          }`}
        >
          {t("views.events.header.month")}
        </button>
      </div>
    </div>
  );
};

interface CalendarControllerProps {
  dateLabel: string;
  onNavigate(action: NavigateAction): void;
}
const CalendarControler = ({
  dateLabel,
  onNavigate,
}: CalendarControllerProps) => {
  return (
    <div className={styles.CalendarControllerWrapper}>
      <ArrowLeft
        onClick={() => onNavigate("PREV")}
        className={styles.ControllerButtonIcon}
      />
      <div className={`${styles.ControllerLabel} ${styles.h6}`}>
        {dateLabel}
      </div>
      <ArrowRight
        onClick={() => onNavigate("NEXT")}
        className={styles.ControllerButtonIcon}
      />
    </div>
  );
};

interface EventsTableProps {
  events: FiraEvent[];
}

const EventsTable = ({ events }: EventsTableProps) => {
  const { authentication, brand, event } = useSelector(
    (state: RootState) => state
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const evento: FiraEvent = events;

  const handleModerationEvent = (evento: FiraEvent) => {
    if (evento.status !== "CANCELLED") {
      navigate("/home/events/event-detail/moderation");
      dispatch(setCurrentEventActionCreator(evento));
    }
  };

  const handleStatisticsEvent = (evento: FiraEvent) => {
    if (evento.status !== "CANCELLED") {
      navigate("/home/events/event-detail/stats");
      dispatch(setCurrentEventActionCreator(evento));
    }
  };

  const statusButtonElement = (
    status: EventStatus,
    haveConfig: boolean,
    eventType?: FiraEventType
  ) => {
    let button = (
      <div className={`${styles.statusInner} ${styles.green}`}>
        {t("views.events.table.statusOptions.scheduled")}
      </div>
    );
    if (haveConfig) {
      if (eventType && eventType === FiraEventType.test) {
        button = (
          <div className={`${styles.statusInner} ${styles.test}`}>
            {
              {
                [EventStatus.SCHEDULED]: t(
                  "views.events.table.statusOptions.scheduled"
                ),
                [EventStatus.NOT_STARTED]: t(
                  "views.events.table.statusOptions.initEvent"
                ),
                [EventStatus.STARTED]: t(
                  "views.events.table.statusOptions.live"
                ),
                [EventStatus.DONE]: t(
                  "views.events.table.statusOptions.finished"
                ),
                [EventStatus.CANCELLED]: t(
                  "views.events.table.statusOptions.cancelled"
                ),
              }[status]
            }
          </div>
        );
      } else {
        switch (status) {
          case EventStatus.SCHEDULED:
            button = (
              <div className={`${styles.statusInner} ${styles.yellow}`}>
                {t("views.events.table.statusOptions.scheduled")}
              </div>
            );
            break;
          case EventStatus.NOT_STARTED:
            button = (
              <div className={`${styles.statusInner} ${styles.yellow}`}>
                {t("views.events.table.statusOptions.initEvent")}
              </div>
            );
            break;
          case EventStatus.STARTED:
            button = (
              <div className={`${styles.statusInner} ${styles.yellow}`}>
                {t("views.events.table.statusOptions.live")}
              </div>
            );
            break;
          case EventStatus.CANCELLED:
            button = (
              <div className={`${styles.statusInner} ${styles.transparent}`}>
                {t("views.events.table.statusOptions.cancelled")}
              </div>
            );
            break;

          case EventStatus.DONE:
            button = (
              <div className={`${styles.statusInner} ${styles.gray}`}>
                {t("views.events.table.statusOptions.finished")}
              </div>
            );
            break;
        }
      }
    } else {
      button = (
        <div className={`${styles.statusInner} ${styles.noConfig}`}>
          {t("views.events.table.statusOptions.config")}
        </div>
      );
    }

    return button;
  };

  const editButtonStatus = (event: FiraEvent) => {
    return (
      event.status === EventStatus.DONE ||
      event.status === EventStatus.SCHEDULED
    );
  };

  const productsHeader = [
    { name: "", size: "37px" },
    { name: "views.events.header.eventName", size: "157px" },
    { name: "views.events.header.date", size: "117px" },
    { name: "views.events.header.startHour", size: "133px" },
    { name: "views.events.header.endHour", size: "117px" },
    { name: "views.events.header.status", size: "138px" },
    { name: "views.events.header.options", size: "128px" },
    { name: "", size: "19px" },
  ];

  const header = (
    <TableRow>
      {productsHeader.map((head, i) => (
        <TableHead key={i} size={head.size} centerContent>
          <span className="body-14-text">{t(head.name)}</span>
        </TableHead>
      ))}
    </TableRow>
  );

  const body = events.map((eventObject) => {
    let schedule: Date;
    if (eventObject.startDate) {
      schedule = new Date(eventObject.startDate);
    } else {
      schedule = new Date(
        format(
          new Date(eventObject.scheduledDate),
          "yyyy-MM-dd'T'HH:mm:ss.SSS'+'00:00"
        )
      );
    }
    const haveConfig = true;
    return (
      <TableRow key={eventObject.id}>
        <TableCell size="31px"></TableCell>
        <TableCell size="117px">
          <p className={styles.EventName}>
            <span>{eventObject.eventName}</span>
          </p>
        </TableCell>
        <TableCell size="117px" centerContent>
          <span>{dateFormat(schedule)}</span>
        </TableCell>
        <TableCell size="133px" centerContent>
          <span>{timeFormat(schedule)}</span>
        </TableCell>
        <TableCell size="117px" centerContent>
          {/*<span>{timeFormat(new Date(event.scheduledDate))}</span>*/}
          {eventObject.status === "DONE"
            ? timeFormat(new Date(eventObject.endDate))
            : timeFormat(new Date(schedule.getTime() + 60 * 60 * 1000))}
        </TableCell>
        <TableCell size="126px" centerContent>
          <div className={styles.statusContainer}>
            {statusButtonElement(
              eventObject.status,
              haveConfig,
              eventObject.eventType
            )}
            {/* {statusButtonElement(event.status)} */}
          </div>
        </TableCell>
        <TableCell size="128px" centerContent>
          <div className={styles.tableIcons}>
            {eventObject.status !== EventStatus.DONE &&
              eventObject.status !== EventStatus.CANCELLED && (
                <ChatBubble
                  onClick={() => handleModerationEvent(eventObject)}
                />
              )}

            {authentication.currentRole !== FiraUserRole.MODERATOR &&
            (eventObject.status === EventStatus.DONE ||
              eventObject.status === EventStatus.CANCELLED) ? (
              <Statistics
                className={
                  eventObject.status === EventStatus.CANCELLED
                    ? styles.disabled
                    : ""
                }
                onClick={() => handleStatisticsEvent(eventObject)}
              />
            ) : (
              ""
            )}
            {editButtonStatus(eventObject) &&
            authentication.isAccountOwner &&
            brand.currentStore?.storeType !== "MAIN_STORE" &&
            eventObject.parentId !== null ? (
              ""
            ) : authentication.currentRole !== FiraUserRole.MODERATOR ? (
              <Edit
                onClick={() => {
                  dispatch(setCurrentEventActionCreator(eventObject));
                  navigate("/home/events/edit/" + eventObject.id);
                }}
              />
            ) : (
              ""
            )}
          </div>
        </TableCell>
        <TableCell size="19px" centerContent></TableCell>
      </TableRow>
    );
  });

  return (
    <>
      {events.length > 0 ? (
        <Table header={header} body={body} tableStyle={TableStyles.noHeader} />
      ) : (
        <EmptyEventsView />
      )}
    </>
  );
};

const EmptyEventsView = () => {
  return (
    <div className={styles.EmptyWrapper}>
      <EmptySwing />
    </div>
  );
};

export default EventsView;
