import CardWidget from "../../../widgets/CardWidget/CardWidget";
import { useTranslation } from "react-i18next";
import styles from "./DashboardVideosOwner.module.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import InteractionsWidget from "../../../widgets/InteractionsWidget/InteractionsWidget";
import TableWidget from "../../../widgets/TableWidget/TableWidget";
import { getAccountOwnerDashboardVODStatisticsService } from "../../../services/videos";
import {parseTimeFromMilliseconds} from "../../../utils/handleTime";
import {formatIsoTimeZone} from "../../../utils/handleDates";

interface interactionsType {
  likes: number;
  clicks: number;
  messages: number;
  shares: number;
}

interface tableRowType {
  storeName: string;
  audience: string;
  viewsAverage: string;
  viewsTotal: string;
}

const DashboardVideosOwner = () => {
  const { t } = useTranslation();
  const { utils, dashboard, authentication } = useSelector(
    (state: RootState) => state
  );
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [businessClientId, setBusinessClientId] = useState<string>("");
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [finalDate, setFinalDate] = useState<Date | null>(null);

  //CARDS DATA
  const [totalAudience, setTotalAudience] = useState<string>("");
  const [playAvg, setPlayAvg] = useState<string>("");
  const [playTotal, setPlayTotal] = useState<string>("");

  //INTERACTIONS WIDGET
  const [interactions, setInteractions] = useState<interactionsType>({
    likes: 0,
    clicks: 0,
    messages: 0,
    shares: 0,
  });

  //TABLE WIDGET
  const [tableData, setTableData] = useState<tableRowType[]>([]);

  useEffect(() => {
    if (utils.screenWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [utils.screenWidth]);

  const tableHeader = [
    {
      name: "",
      size: "9px",
    },
    {
      name: t("views.dashboardOwnerVideos.store"),
      size: "25%",
    },
    {
      name: t("views.dashboardOwnerVideos.audience"),
      size: "25%",
    },
    {
      name: t("views.dashboardOwnerVideos.viewsAverage"),
      size: "25%",
    },
    {
      name: t("views.dashboardOwnerVideos.viewsTotal"),
      size: "25%",
    },
    {
      name: "",
      size: "10px",
    },
  ];

  const containerCards = (
    <div
      className={`${styles.countersWrapper} ${
        isMobile ? styles.mobileCounter : ""
      }`}
    >
      <CardWidget
        title={t("views.dashboardOwner.audienceTotal")}
        description={""}
        data={totalAudience ? totalAudience : "0"}
        isLoading={isLoading}
      />

      <CardWidget
        title={t("views.dashboard.reproductionAverage")}
        description={t("views.dashboard.reproductionAverage")}
        data={playAvg ? parseTimeFromMilliseconds(Number(playAvg)) : "0"}
        isLoading={isLoading}
      />
      <CardWidget
        title={t("views.dashboard.reproductionTotal")}
        description={t("views.dashboard.reproductionTotal")}
        data={playTotal ?  parseTimeFromMilliseconds(Number(playTotal)) : "0"}
        isLoading={isLoading}
      />
    </div>
  );

  /** CALLS */
  const callVODStatistics = async (
    id: string,
    initialDate: Date,
    finalDate: Date
  ) => {
    setIsLoading(true)
    const init = formatIsoTimeZone(initialDate);
    const final = formatIsoTimeZone(finalDate);

    if (id && initialDate !== null && finalDate !== null) {
      const response = await getAccountOwnerDashboardVODStatisticsService(
        id,
        init,
        final
      );

      setTotalAudience(response.data.accumTotalAudience.toString() || "0");
      setPlayAvg(response.data.accumTotalvideoPlayAvgTime.toString() || "0");
      setPlayTotal(response.data.accumTotalVideoPlay.toString() || "0");
      setInteractions({
        likes: response.data.accumTotalLikeCount || 0,
        clicks:
          response.data.accumTotalLikeCount +
            response.data.accumTotalShareCount +
            response.data.accumTotalProductClickCount || 0,
        messages: response.data.accumTotalCommentCount || 0,
        shares: response.data.accumTotalShareCount || 0,
      });

      let storeStats: tableRowType[] = [];
      response.data.vodStats.map((stats: any) => {
        storeStats.push({
          storeName: stats.storeName,
          audience: stats.accumulatedAudience.toString(),
          viewsAverage: parseTimeFromMilliseconds(stats.videoPlayAvgTime).toString(),
          viewsTotal: parseTimeFromMilliseconds(stats.videoPlayAccum).toString(),
        });
      });

      setTableData(storeStats);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    if (authentication.user?.businessClientId) {
      setBusinessClientId(authentication.user.businessClientId);
    }

    if (dashboard.firstDate && dashboard.lastDate) {
      setInitialDate(dashboard.firstDate);
      setFinalDate(dashboard.lastDate);
    } else {
      setInitialDate(null);
      setFinalDate(null);
    }
  }, [authentication, dashboard]);

  useEffect(() => {
    if (initialDate !== null && finalDate !== null && businessClientId)
      callVODStatistics(businessClientId, initialDate, finalDate);
  }, [initialDate, finalDate]);


  return (
    <div className={styles.dashboardContainer}>
      {containerCards}
      {!isMobile ? (
        <div className={styles.desktopContainer}>
          <div id={styles["tableWidget"]}>
            <TableWidget
              header={tableHeader}
              title={t("views.dashboardOwner.tableWidgetTitle")}
              subTitle={t("views.dashboardOwnerVideos.tableDescription")}
              bodyData={tableData || []}
              onClick={() => ""}
              isLoading={isLoading}
            />
          </div>
          <div id={styles["interactions"]}>
            <InteractionsWidget
              likes={interactions.likes || 0}
              clicks={interactions.clicks || 0}
              messages={interactions.messages || 0}
              shares={interactions.shares || 0}
              isLoading={isLoading}
              pieHeight={268}
            />
          </div>
        </div>
      ) : (
        <div className={styles.mobileContainer}>
          <div className={styles.subGrid}>
            <div className={styles.subGrid__table}>
              <TableWidget
                header={tableHeader}
                title={t("views.dashboardOwner.tableWidgetTitle")}
                subTitle={t("views.dashboardOwnerVideos.tableDescription")}
                bodyData={tableData || []}
                onClick={() => ""}
                isLoading={isLoading}
              />
            </div>
            <div className={styles.subGrid__interactions}>
              <InteractionsWidget
                likes={interactions.likes || 0}
                clicks={interactions.clicks || 0}
                messages={interactions.messages || 0}
                shares={interactions.shares || 0}
                isLoading={isLoading}
                pieHeight={268}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardVideosOwner;
