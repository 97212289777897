import {
  ChangeEvent,
  HTMLInputTypeAttribute,
  ReactElement,
  useRef,
  useState,
} from "react";
import { CheckCircleFill, LineCircleFill } from "../FiraIcons";
import "./InputTime.scss";

export enum InputTypes {
  string = "String",
  calendar = "Calendar",
}

export enum InputStyles {
  normal = "",
  auth = "AuthStyleInput",
}

interface Props {
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  type?: HTMLInputTypeAttribute | undefined;
  isDisabled?: boolean;
  haveError?: boolean | undefined;
  value?: string;
  isValidated?: boolean;
  width?: string;
  height?: string;
  icon?: ReactElement;
  showPswButton?: boolean;
  inputName?: string;
  inputId?: string;
  placeholder?: string;
  inputStyle?: InputStyles;
  isTextCenter?: boolean;
}
/**
 * Description of Input component
 * @prop {function} onChange - handler for the action of the input
 * @prop {InputTypes} type - type of input to show: STRING | CALENDAR
 * @prop {boolean} isDisabled - tells the input to be disabled
 * @prop {string} value - tells the input value
 * @prop {boolean} isValidated - tells the input have validation
 * @prop {boolean | undefined} haveError - tells the input have an error
 * @prop {string} width - tells the input width
 * @prop {string} height - tells the input heigth
 * @prop {ReactElement} icon - react element to be use as a icon in the input
 * @prop {boolean} showPswButton - tells the input activate button to preview password
 * @prop {string} inputName - the input name
 * @prop {string} inputId - the input id
 * @prop {boolean} isTextCenter - tells the text align
 */
const InputTime = (props: Props) => {
  const {
    onChange,
    inputName,
    inputId,
    type,
    value,
    isDisabled = false,
    isValidated = false,
    haveError = false || null,
    icon,
    height = "30px",
    width = "150px",
    showPswButton = false,
    placeholder = "",
    inputStyle = InputStyles.normal,
    isTextCenter,
  } = props;

  const [stateType, setStateType] = useState(type);
  const inputRef = useRef<HTMLInputElement>(null);

  let rightIconInput;
  let inputClass = "";
  let leftIconInput;

  if (isValidated) {
    if (haveError === true) {
      rightIconInput = (
        <span className="icon right">
          <LineCircleFill className="error" />
        </span>
      );
      inputClass = "Error";
    } else if (haveError === false) {
      rightIconInput = (
        <span className="icon right">
          <CheckCircleFill className="check" />
        </span>
      );
      inputClass = "Check";
    }
  }

  if (icon) {
    leftIconInput = <span className="icon left">{icon}</span>;
  }

  const style = {
    height,
    width,
  };

  return (
    <div className="InputTimeWrapper">
      <input
        name={inputName}
        id={inputId}
        ref={inputRef}
        style={style}
        className={`Input ${inputClass} ${icon ? "left" : ""} ${
          showPswButton ? "right" : ""
        } ${inputStyle}  ${isTextCenter ? "center-text" : ""}`}
        disabled={isDisabled}
        type={stateType}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
      {leftIconInput}
      {rightIconInput}
    </div>
  );
};

export default InputTime;
