import React, { ReactElement, ReactNode } from "react";

import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  EventStatus,
  FiraEvent,
  FiraSimpleEvent,
  RootState,
} from "../../utils/types.d";

import {
  ChatBubble,
  CheckCircleFill,
  Circle,
  CircleX,
  Edit,
  Statistics,
  TimeClock,
} from "../FiraIcons";
import "./PopUpEventOption.scss";
import { usePopper } from "react-popper";

import SvgCloseX from "../FiraIcons/CloseX";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { timeFormat } from "../../utils/handleDates";
import { format } from "date-fns";
import { FiraEventType, FiraUserRole } from "../../utils/enums";

interface EventOptionsProps {
  eventData: FiraEvent | FiraSimpleEvent;
  elementRef: HTMLElement | undefined;
  onClickCancel(): void;
  onClose(): void;
}
interface Props {
  position?: "absolute" | "fixed" | "initial";
  anchorPoint: { x: number; y: number };
  isVisible?: boolean;
  navLinks?: { name: string; icon: ReactElement; clickHandler(): void }[];
}

const ContextMenuPopUp = ({
  position = "fixed",
  anchorPoint,
  isVisible = false,
  navLinks = [],
}: Props) => {
  const style = {
    position,
    top: anchorPoint.y,
    left: anchorPoint.x,
  };

  return isVisible ? (
    <div className="ContextMenuPopUp" style={style}>
      {navLinks.map((link) => (
        <ContextMenuButtonPopUp
          key={link.name}
          icon={link.icon}
          clickHandler={link.clickHandler}
        >
          {link.name}
        </ContextMenuButtonPopUp>
      ))}
    </div>
  ) : null;
};

interface ContextMenuButtonProps {
  clickHandler?(): void;
  children?: ReactNode | undefined;
  icon?: ReactElement;
}
const ContextMenuButtonPopUp = ({
  clickHandler,
  children,
  icon,
}: ContextMenuButtonProps) => {
  return (
    <button className="ContextMenuButtonPopUp" onClick={clickHandler}>
      {icon}
      <span className="TextContent lead-text">{children}</span>
    </button>
  );
};

const PopUpEventOptions = ({
  eventData,
  elementRef,
  onClickCancel,
  onClose,
}: EventOptionsProps) => {
  const navigate = useNavigate();
  const { brand, event, authentication } = useSelector(
    (state: RootState) => state
  );
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [showButtons, setShowButtons] = useState(true);
  const [buttonList, setButtonList] = useState<
    {
      name: string;
      icon: JSX.Element;
      clickHandler: () => void;
    }[]
  >();

  const contextMenuLinksTest = [
    {
      name: t("views.events.popup_option.moderation"),
      icon: <ChatBubble width={25} height={22} />,
      clickHandler: () => {
        navigate("/home/events/event-detail/moderation");
      },
    },
    {
      name: t("views.events.popup_option.statistics"),
      icon: <Statistics width={25} height={22} />,
      clickHandler: () => {
        navigate("/home/events/event-detail/stats");
      },
    },
    {
      name: t("views.events.popup_option.edit_event"),
      icon: <Edit width={25} height={22} />,
      clickHandler: () => {
        navigate("/home/events/edit/" + event.currentEvent?.id);
      },
    },
    {
      name: t("views.events.popup_option.cancel_event"),
      icon: <SvgCloseX width={25} height={12} />,
      clickHandler: () => {
        onClickCancel();
      },
    },
    {
      name: t("views.events.popup_option.event_canceled"),
      icon: <SvgCloseX width={25} height={12} />,
      clickHandler: () => {
        onClose();
      },
    },
  ];

  const getButtonsList = (status: string) => {
    //TO DO: Use EventStatusTypes

    switch (status) {
      case "SCHEDULED": {
        let newArr = [contextMenuLinksTest[0]];
        const modArr = [contextMenuLinksTest[0]];
        // owner main store is parent true
        // owner desde tienda isparent

        if (
          authentication.isAccountOwner &&
          brand.currentStore?.storeType !== "MAIN_STORE" &&
          event.currentEvent?.parentId !== null &&
          event.currentEvent?.parentId !== undefined
        ) {
          newArr = [contextMenuLinksTest[0], contextMenuLinksTest[3]];
        } else if (
          (event.currentEvent?.parentId !== null &&
            event.currentEvent?.parentId !== undefined) ||
          event.currentEvent?.isParent
        ) {
          newArr = [contextMenuLinksTest[0]];
        } else {
          newArr = [
            contextMenuLinksTest[0],
            contextMenuLinksTest[2],
            contextMenuLinksTest[3],
          ];
        }
        setButtonList(
          authentication.currentRole !== FiraUserRole.MODERATOR
            ? newArr
            : modArr
        );
        setShowButtons(true);
        break;
      }
      case "STARTED": {
        const newArr = [contextMenuLinksTest[0]];
        setShowButtons(true);
        setButtonList(newArr);
        break;
      }
      case "NOT_STARTED": {
        const newArr = [contextMenuLinksTest[0]];
        setShowButtons(true);
        setButtonList(newArr);
        break;
      }
      case "CANCELLED": {
        setShowButtons(false);
        break;
      }
      case "DONE": {
        const newArr = [contextMenuLinksTest[1], contextMenuLinksTest[2]];
        setShowButtons(true);
        setButtonList(
          authentication.currentRole !== FiraUserRole.MODERATOR ? newArr : []
        );
        break;
      }
    }
  };

  useEffect(() => {
    getButtonsList(eventData.status);
  }, [eventData.status]);

  useEffect(() => {
    getButtonsList(eventData.status);
  }, [elementRef]);

  useEffect(() => {
    if (eventData.startDate) {
      const eventStart = getEventDate(new Date(eventData.startDate).getTime());
      setStartTime(eventStart);
    } else {
      const schedule = new Date(eventData.scheduledDate);
      const eventStart = getEventDate(
        new Date(
          format(schedule, "yyyy-MM-dd'T'HH:mm:ss.SSS'+'00:00")
        ).getTime()
      );
      setStartTime(eventStart);
    }
  }, [eventData.startDate]);

  useEffect(() => {
    if (eventData.status === "DONE") {
      const eventEnd = getEventDate(new Date(eventData.endDate).getTime());
      setEndTime(eventEnd);
    } else {
      const schedule = new Date(eventData.scheduledDate);
      const eventEnd = getEventDate(
        new Date(
          format(schedule, "yyyy-MM-dd'T'HH:mm:ss.SSS'+'00:00")
        ).getTime() +
          60 * 60 * 1000
      );
      setEndTime(eventEnd);
    }
  }, [eventData.endDate]);

  const getEventDate = (date: number) => {
    const eventDate = timeFormat(new Date(date));
    return eventDate;
  };

  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  //Popper Modifiers Definition

  const modifiers = [
    {
      name: "offset",
      options: {
        offset: [0, 8],
      },
    },
    {
      name: "preventOverflow",
      options: {
        padding: 10,
      },
    },
    {
      name: "arrow",
      options: {
        element: arrowElement,
      },
    },
  ];

  const { styles, attributes } = usePopper(elementRef, popperElement, {
    modifiers,
  });

  const containerStyle: any = {
    ...styles.popper,
    display: "flex",
    zIndex: 99,
    flexDirection: "column",
    borderRadius: "4px",
  };

  return (
    <div ref={setPopperElement} style={containerStyle} {...attributes.popper}>
      <div className={"EventOptionsWrapper"}>
        <div className={"EventOptionsMenu"}>
          <div className={`${"EventOptionsHeader"} ${"lead-text"}`}>
            {brand.currentStore?.brand?.logo ? (
              <img className="logo" src={brand.currentStore?.brand.logo} />
            ) : (
              <div className="logo" />
            )}
            <div className="EventNameContent">
              <p className="EventName">{eventData.eventName}</p>
              <p className=" lead-10-text">
                <OptionStatus
                  statusName={eventData.status}
                  eventType={eventData.eventType}
                />
              </p>
            </div>
          </div>
          <div className={"TimeSlot"}>
            <div className={"Hour"}>
              <div className={"TimeIcon"}>
                <TimeClock />
              </div>
              <div className={"tiny-text"}>{startTime}</div>
            </div>
            <span>—</span>
            <div className={"Hour"}>
              <div className={"TimeIcon"}>
                <TimeClock />
              </div>
              <div className={"tiny-text"}>{endTime}</div>
            </div>
          </div>
          {showButtons && (
            <div className={"MenuButtons"}>
              <ContextMenuPopUp
                position="initial"
                isVisible={true}
                anchorPoint={{ x: 0, y: 0 }}
                navLinks={buttonList}
              />
            </div>
          )}
        </div>
      </div>
      <div ref={setArrowElement} style={styles.arrow}></div>
    </div>
  );
};

interface PropsStatus {
  statusName: string;
  eventType?: FiraEventType;
}
const OptionStatus = ({ statusName, eventType }: PropsStatus) => {
  let icon;
  let event;
  if (eventType && eventType === FiraEventType.broadcast) {
    switch (statusName) {
      case EventStatus.SCHEDULED:
        icon = <CheckCircleFill color="#FFDE07" />;
        event = t("views.events.status.broadcast.scheduled");
        break;
      case EventStatus.STARTED:
        icon = <CheckCircleFill color="#ffde07" />;
        event = t("views.events.status.broadcast.started");
        break;
      case EventStatus.CANCELLED:
        icon = <CircleX color="#000000" />;
        event = t("views.events.status.broadcast.cancelled");
        break;
      case EventStatus.NOT_STARTED:
        icon = <CheckCircleFill color="#66CB9F" />;
        event = t("views.events.status.broadcast.not_started");
        break;
      case EventStatus.DONE:
        icon = <CheckCircleFill color="#D5DDE0" />;
        event = t("views.events.status.broadcast.done");
        break;
      default:
        break;
    }
  } else {
    switch (statusName) {
      case EventStatus.SCHEDULED:
        icon = <Circle color="#FFDE07" />;
        event = t("views.events.status.test.scheduled");
        break;
      case EventStatus.STARTED:
        icon = <Circle color="#FFDE07" />;
        event = t("views.events.status.test.started");
        break;
      case EventStatus.CANCELLED:
        icon = <CircleX color="#000000" />;
        event = t("views.events.status.test.cancelled");
        break;
      case EventStatus.NOT_STARTED:
        icon = <Circle color="#FFDE07" />;
        event = t("views.events.status.test.not_started");
        break;
      case EventStatus.DONE:
        icon = <Circle color="#FFDE07" fontSize={"12px"} />;
        event = t("views.events.status.test.done");
        break;
      default:
        break;
    }
  }
  return (
    <span className="EventStatus">
      {icon}

      {event}
    </span>
  );
};

export default PopUpEventOptions;
