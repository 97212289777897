import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";
import { RootState } from "../../../store/Store";
import { Category } from "../../../utils/types";
import styles from "./SelectCategories.module.scss";

interface ItemsOption {
  value: string;
  label: string;
  isSelected: boolean;
}

interface SelectProps {
  canEdit?: boolean;
  name?: string;
  data: Category[];
  onChange(items: ItemsOption[]): void;
}

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: any) => {
  const [isActive, setIsActive] = useState(false);

  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "left",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    alignItem: "left",
    alignContent: "start",
    justifyContent: "start",
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <div
        className={`${styles.CheckboxWrapper} ${
          isSelected ? styles.IsActive : ""
        } ${isFocused ? styles.IsFocus : ""} ${
          isDisabled ? styles.IsDisabled : ""
        }`}
      >
        <input
          className={styles.Checkbox}
          name={"name"}
          type="checkbox"
          checked={isSelected}
          disabled={isDisabled}
          onChange={() => {}}
        />
        <div className={styles.CheckboxButton}></div>
      </div>
      {children}
    </components.Option>
  );
};

const SelectCategories: React.FC<SelectProps> = ({
  name,
  data,
  onChange,
  canEdit = true,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<ItemsOption[]>([]);
  const { products } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  useEffect(() => {
    products.currentProduct.categories &&
      setSelectedOptions(
        data.map((category) => ({
          value: category.id.toString(),
          label: category.description,
          isSelected: products.currentProduct.categories.some(
            (selectedCategory) => selectedCategory.id === category.id
          ),
        }))
      );
  }, [data, products.currentProduct.categories]);
  //CUSTOM STYLE DEL COMPONENTE SELECT
  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      marginTop: 0,
    }),
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      fontSize: "12px",
      paddingTop: 7,
      paddingBottom: 7,
    }),
    control: (provided: any) => ({
      ...provided,
      minHeight: "30px",
      height: "32px",
      boxShadow: "none",
      borderColor: "#e5e5e5",
      "&:hover": {
        color: "#e5e5e5",
        border: provided.isFocused ? "1px solid #ffde07" : "1px solid #ffde07",
      },
    }),
    input: (provided: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: "30px",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "#000000",
      fontWeight: 500,
    }),
  };

  return (
    <div>
      <Select
        isDisabled={!canEdit}
        placeholder={t("views.products.detailProduct.categoryTitle")}
        styles={customStyles}
        isMulti
        isClearable={false}
        isSearchable={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        value={selectedOptions.filter((option) => option.isSelected)}
        onChange={(options, { action, removedValue }) => {
          if (Array.isArray(options)) {
            const updatedOptions = data.map((category) => ({
              value: category.id.toString(),
              label: category.description,
              isSelected: options.some(
                (opt) => opt.value === category.id.toString()
              ),
            }));
            setSelectedOptions(updatedOptions);

            onChange(updatedOptions.filter((opt) => opt.isSelected));
          }
        }}
        options={data.map((category) => ({
          value: category.id.toString(),
          label: category.description,
          isSelected: selectedOptions.some(
            (opt) => opt.value === category.id.toString()
          ),
        }))}
        components={{
          Option: InputOption,
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

export default SelectCategories;
