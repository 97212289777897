import { t } from "i18next";
import {
  ChangeEvent,
  ReactElement,
  SVGProps,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { ViewHeader } from "../../components";
import {
  Banner,
  CalendarFill,
  Carrousel,
  Collections,
  MagnifyingGlass,
  Mosaic,
  NewsFill,
  OrderFill,
  ThumbnailBannerComercial,
  ThumbnailBannerSlider,
  ThumbnailCategories,
  ThumbnailGaleriaVod,
  ThumbnailVodLive,
} from "../../components/FiraIcons";
import CarrouselEventPreview from "../../components/FiraIcons/CarrouselEventPreview";
import MainCarrouselPreview from "../../components/FiraIcons/MainCarrouselPreview";
import NextEventsPreview from "../../components/FiraIcons/NextEventsPreview";
import Input, { InputStyles } from "../../components/Input/Input";
import { WidgetCategory, WidgetType } from "../../utils/types.d";
import styles from "./FiraTvWidgetSelectionView.module.scss";

interface WidgetTypeCategory {
  name: string;
  type: WidgetCategory;
  icon: ReactElement;
}

interface itemWidget {
  id: number;
  name: string;
  img: SVGProps<SVGSVGElement>;
  type: WidgetType | null;
  category: WidgetCategory;
}

const FiraTvWidgetSelectionView = () => {
  const navigate = useNavigate();
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [categories, setCategories] = useState<WidgetTypeCategory[]>();
  const [selectedCategory, setSelectedCategory] =
    useState<WidgetCategory | null>(null);
  const [thumbnailsList, setThumbnailsList] = useState<itemWidget[]>();
  const [availableCategories, setAvailableCategories] =
    useState<itemWidget[]>();

  let catArray = [
    {
      name: t("views.firaTv.widgetSelectionView.cards"),
      type: WidgetCategory.cards,
      icon: <OrderFill />,
    },
    {
      name: t("views.firaTv.widgetSelectionView.mosaic"),
      type: WidgetCategory.grid,
      icon: <Mosaic />,
    },
    {
      name: t("views.firaTv.widgetSelectionView.banners"),
      type: WidgetCategory.banners,
      icon: <Banner />,
    },
    {
      name: t("views.firaTv.widgetSelectionView.collections"),
      type: WidgetCategory.collections,
      icon: <Collections />,
    },
    {
      name: t("views.firaTv.widgetSelectionView.carousel"),
      type: WidgetCategory.carousel,
      icon: <Carrousel />,
    },
    // {
    //   name: t("views.firaTv.widgetSelectionView.calendar"),
    //   type: WidgetCategory.calendar,
    //   icon: <CalendarFill />,
    // },
    {
      name: t("views.firaTv.widgetSelectionView.scheduled"),
      type: WidgetCategory.scheduled,
      icon: <NewsFill />,
    },
  ];

  let widgetThumbNails: itemWidget[] = [
    {
      id: 1,
      name: t("views.firaTv.widgetSelectionView.thumbnails.collectionCard"),
      img: <NextEventsPreview />,
      type: WidgetType.gridEvents,
      category: WidgetCategory.cards,
    },
    {
      id: 2,
      name: t("views.firaTv.widgetSelectionView.thumbnails.vodGallery"),
      img: <ThumbnailGaleriaVod />,
      type: WidgetType.vodGallery,
      category: WidgetCategory.grid,
    },
    {
      id: 3,
      name: t("views.firaTv.widgetSelectionView.thumbnails.comingSoon"),
      img: <MainCarrouselPreview />,
      type: WidgetType.mainCarousel,
      category: WidgetCategory.carousel,
    },
    {
      id: 4,
      name: t("views.firaTv.widgetSelectionView.thumbnails.collectionCarousel"),
      img: <CarrouselEventPreview />,
      type: WidgetType.nextEventsCarousel,
      category: WidgetCategory.scheduled,
    },
    {
      id: 5,
      name: t("views.firaTv.widgetSelectionView.thumbnails.bannerSlider"),
      img: <ThumbnailBannerSlider />,
      type: WidgetType.bannerSlider,
      category: WidgetCategory.banners,
    },
    {
      id: 6,
      name: t("views.firaTv.widgetSelectionView.thumbnails.bannerCommercial"),
      img: <ThumbnailBannerComercial />,
      type: WidgetType.bannerCommercial,
      category: WidgetCategory.banners,
    },
    // {
    //   id: 7,
    //   name: t("views.firaTv.widgetSelectionView.thumbnails.bannerLive"),
    //   img: <ThumbnailVodLive />,
    //   type: WidgetType.bannerTop,
    //   category: WidgetCategory.banners,
    // },
    {
      id: 7,
      name: t("views.firaTv.widgetSelectionView.thumbnails.categories"),
      img: <ThumbnailCategories />,
      type: WidgetType.categories,
      category: WidgetCategory.collections,
    },
  ];

  /** SEARCH HANDLER */
  const searchInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setSearchInputValue(keyword);

    if (keyword !== "") {
      setCategories(
        catArray.filter((cat) => {
          return cat.type.toLowerCase().includes(keyword.toLowerCase());
        })
      );
    } else {
      setCategories(catArray);
    }
  };

  /** FILTER BY */
  const filterWidgetsByCategory = (category: WidgetCategory) => {
    if (selectedCategory !== category) {
      setSelectedCategory(category);
      setThumbnailsList(
        widgetThumbNails.filter((item) => {
          return item.category === category;
        })
      );
    } else {
      setSelectedCategory(null);
      setThumbnailsList(widgetThumbNails);
    }
  };

  const navigationHandler = (widget: itemWidget) => {
    if (widget.type !== null) {
      navigate("/home/widgets-config/" + widget.type?.toLowerCase());
    }
  };

  useEffect(() => {
    setCategories(catArray);
    setThumbnailsList(widgetThumbNails);
  }, []);
  return (
    <>
      <div className={styles.HeaderTitle}>
        {t("views.firaTv.widgetSelectionView.title")}
      </div>
      <div className={styles.FiraTvWidgetSelectionViewWrapper}>
        <div className={styles.WidgetCategoriesCard}>
          <div className={styles.WidgetCategoriesCard__Searchbar}>
            <Input
              width="177px"
              height="36px"
              placeholder={t("views.firaTv.widgetSelectionView.searchbar")}
              icon={<MagnifyingGlass />}
              biggerIcon
              onChange={searchInputHandler}
              value={searchInputValue}
            />
          </div>
          <div className={styles.WidgetCategoriesCard__CategoriesList}>
            {categories?.map((item, i) => (
              <li
                key={i}
                tabIndex={0}
                className={`${
                  selectedCategory !== null && selectedCategory !== item.type
                    ? styles.disabled
                    : ""
                } ${
                  selectedCategory === item.type ? styles.selectedCategory : ""
                }`}
                onClick={() => filterWidgetsByCategory(item.type)}
              >
                <div className={styles.icon}>{item.icon}</div>{" "}
                <span>{item.name}</span>
              </li>
            ))}
          </div>
        </div>
        <div className={styles.WidgetListContainer}>
          <div className={styles.WidgetListContainer__List}>
            {thumbnailsList &&
              thumbnailsList.map((item, i) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  onClick={() => navigationHandler(item)}
                  key={i}
                >
                  <div
                    key={i}
                    tabIndex={0}
                    className={`${styles.WidgetListContainer__List__Item} `}
                  >
                    <div
                      className={
                        styles.WidgetListContainer__List__Item__Thumbnail
                      }
                    >
                      {item.img}
                    </div>
                  </div>
                  <span>{item.name}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FiraTvWidgetSelectionView;
