import styles from "./InputRange.module.scss";
import { ChangeEvent, useEffect, useRef, useState } from "react";

interface InputRangeProps {
  onRangeChange(range: number): void;
  sliderVal?: number;
}

const InputRange = ({ onRangeChange, sliderVal }: InputRangeProps) => {
  const [sliderValue, setSliderValue] = useState<number>(72); //make it a prop
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const tempSliderValue = parseInt(event.target.value, 10);

    setSliderValue(tempSliderValue);
    onRangeChange(tempSliderValue);

    handleSliderProgress(event.target.min, event.target.max, tempSliderValue);
  };

  const handleSliderProgress = (
    min: string,
    max: string,
    tempSliderValue: number
  ) => {
    const minValue = parseInt(min, 10);
    const range = parseInt(max, 10) - minValue;
    const progress = ((tempSliderValue - minValue) / range) * 100;

    if (sliderRef.current) {
      sliderRef.current.style.background = `linear-gradient(to right, #ffde07 ${progress}%, rgba(204, 204, 204, 0) ${progress}%)`;
    }
  };

  useEffect(() => {
    if (sliderVal) {
      setSliderValue(sliderVal);
      handleSliderProgress(String(72), String(150), sliderVal);
    }
  }, [sliderVal]);

  return (
    <>
      <div className={styles.InputRange}>
        <div ref={sliderRef} className={styles.fauxInputProgress}></div>
        <div className={styles.fauxInputBg}></div>
        <input
          type={"range"}
          min={72}
          max={150}
          value={sliderValue}
          onChange={handleSliderChange}
        />
      </div>
    </>
  );
};

export default InputRange;
