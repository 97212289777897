import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { RootState } from "../../utils/types";
import { validateRecoveryCodeActionCreator } from "../../store/Recovery/RecoveryActions";
import { sendRecoverCodeService } from "../../services/authentication";
import { useTranslation } from "react-i18next";
import { ArrowRight } from "../FiraIcons";
import Button from "../Button/Button";
import SegmentedInput from "../SegmentedInput/SegmentedInput";
import "./PwdCode.scss";

const PwdCode = () => {
  const { t } = useTranslation();
  const recovery = useSelector((state: RootState) => state.recovery);
  const [code, setCode] = useState("");
  const [mail, setMail] = useState("");
  const [valid, setValid] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (code !== "" && mail !== "") {
      dispatch(validateRecoveryCodeActionCreator(code, mail));
    }
  };

  const handleResendCode = () => {
    if (typeof recovery.email === "string") {
      sendRecoverCodeService(recovery.email);
    }
  };

  useEffect(() => {
    if (typeof recovery.email === "string") {
      setMail(recovery.email);
    }
  }, [recovery]);

  useEffect(() => {
    if (recovery.code) {
      navigate("/changepwd");
    }
  }, [recovery, navigate]);

  useEffect(() => {
    if (code.length === 6) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [code]);

  if (!recovery.isRecovering) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="PwdCodeForm">
      <div className="small-text desc">{t("auth.pwdcode.message")}</div>
      <form onSubmit={handleSubmit}>
        <div className="FormGroup">
          <SegmentedInput onChange={(value) => setCode(value)} />
          <div className="tiny-text">
            {t("auth.pwdcode.recovery_message")}{" "}
            <span onClick={handleResendCode}>{t("auth.pwdcode.resend")}</span>
          </div>
        </div>
        <div className="ButtonWrapper">
          <Button width="316px" height="48px" type="submit" isDisabled={!valid}>
            <div className="ButtonInner">
              {t("auth.pwdcode.button")}
              <ArrowRight className="icon" />
            </div>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PwdCode;
