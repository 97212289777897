import { useTranslation } from "react-i18next";
import "./settingsModal.scss";
import SettingsSidebar from "./SettingsSidebar";
import SettingsIcon from "../../FiraIcons/Settings";
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { RootState, SettingElements } from "../../../utils/types";
import { ArrowLeft, RoundedX } from "../../FiraIcons";
import { useSelector } from "react-redux";

type SettingsModalProps = {
  isOpen?: boolean;
  onClose?(): void;
  settingElements: SettingElements[];
  setSettingElements: Dispatch<SetStateAction<SettingElements[]>>;
  nuvemShopCode?: string | null;
};

export default function SettingsModal({
  isOpen,
  onClose,
  settingElements,
  setSettingElements,
  nuvemShopCode,
}: SettingsModalProps) {
  const { t } = useTranslation();
  const SettingsModal = useRef<HTMLDivElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const [component, setComponent] = useState<ReactElement | null>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { utils } = useSelector((state: RootState) => state);
  function handleResize() {
    setScreenWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (nuvemShopCode) {
      activeItem("syncStore");
    }
  }, [nuvemShopCode]);

  useEffect(() => {
    if (SettingsModal.current) {
      SettingsModal.current.style.top = isOpen ? "0" : "100%";
    }
  }, [SettingsModal]);

  const activeItem = (id: string) => {
    const settingItems: SettingElements[] = [...settingElements];

    settingItems.map((element) => {
      element.subElements.map((item) => {
        if (item.id === id) {
          item.isActive = true;
          setComponent(item.component);
        }
      });
    });

    setSettingElements(settingItems);
  };

  const inactiveAllItems = () => {
    const settingItems: SettingElements[] = [...settingElements];

    settingItems.forEach((element) => {
      element.subElements.forEach((subElement) => {
        subElement.isActive = false;
      });
    });
  };

  const handleOnClose = () => {
    inactiveAllItems();

    if (SettingsModal.current) {
      SettingsModal.current.style.top = "100%";
    }

    if (onClose) {
      setTimeout(() => {
        onClose();
      }, 500);
    }
  };

  const returnToSidebar = () => {
    setComponent(null);
    inactiveAllItems();

    if (componentRef.current) {
      componentRef.current.style.display = "none";
    }
  };

  useEffect(() => {
    return () => {
      inactiveAllItems();
    };
  }, []);

  return (
    <div id="SettingsModalWrapper">
      <div
        ref={SettingsModal}
        id="SettingsModal"
        className="SettingsModal"
        style={{ top: "100%" }}
      >
        <div className="SettingsModalHeader" id="SettingsModalHeader">
          <div className="SettingsModalHeaderBody">
            <SettingsIcon className="SettingsModalTitleIcon" />
            <h3 id="SettingsModalTitle">
              {t("components.navBar.settingButton")}
            </h3>
          </div>
          <div className="CloseButtonIcon" onClick={handleOnClose}>
            <RoundedX />
          </div>
        </div>
        <div id="SettingsModalBody">
          {screenWidth < 981 && component !== null && (
            <>
              <div id="returnToSidebar" onClick={returnToSidebar}>
                <ArrowLeft
                  style={{
                    fontSize: "30px",
                  }}
                />
              </div>
            </>
          )}

          <>
            <SettingsSidebar
              settingElements={settingElements}
              setSettingElements={setSettingElements}
              setComponent={setComponent}
              inactiveAllItems={inactiveAllItems}
              component={component}
              screenWidth={screenWidth}
              activeItem={activeItem}
              componentRef={componentRef}
            />
            <div ref={componentRef} id="SettingsModalComponent">
              {component}
            </div>
          </>
        </div>
      </div>
    </div>
  );
}
