import styles from "./LandingWidgets.module.scss";
import { useTranslation } from "react-i18next";
import InputColor from "../../../InputColor/InputColor";
import TypeSelect, {
  TypeSelectModel,
} from "../../../Selects/TypeSelect/TypeSelect";
import {
  EventSelectType,
  FiraCollection,
  RootState,
  VideoFilter,
} from "../../../../utils/types.d";
import { CalendarIcon } from "../../../FiraIcons";
import React, { useContext, useEffect, useState } from "react";
import {
  FiraWidgetConfiguration,
  LandingProduct,
  WidgetType,
} from "../../../../types/Landing.d";
import { noHash } from "../../../../utils/handleColor";
import ClosedBox from "../../../FiraIcons/ClosedBox";
import { useDispatch, useSelector } from "react-redux";
import { SimpleFormSelect } from "../../../Selects/FormSelect/FormSelect";
import {
  getCollectionsActionCreator,
  resetCollectionListActionCreator,
} from "../../../../store/Collection/CollectionActions";
import BouncingLoader from "../../../BouncingLoader/BouncingLoader";
import RadioButton from "../../../RadioButton/RadioButton";
import InfoCircle from "../../../FiraIcons/InfoCircle";
import PopUpInfo from "../../../PopUpInfo/PopUpInfo";
import ProductList from "../../../ProductList/ProductList";
import Input from "../../../Input/Input";
import { handleSearch } from "../../../../utils/handleSearch";
import {
  getLandingsProductsByStoreIdService,
  mergeFeaturedProductsService,
} from "../../../../services/landing";
import {
  useLandingConfig,
  widgetContentInitialState,
} from "../../../../providers/LandingConfigProvider";
import { getStoreProductsGlobalService } from "../../../../services/store";
import { useSettingsLanding } from "../../../../providers/SettingsLandingProvider";

const LandingWidgets = () => {
  const dispatch = useDispatch();
  const {
    existingLanding,
    setFiraLandingConfiguration,
    firaLandingConfiguration,
    landingConfig,
    setLandingConfig,
    refreshLanding,
  } = useLandingConfig();
  const [widget, setWidget] = useState<FiraWidgetConfiguration | null>(
    widgetContentInitialState
  );
  const { t } = useTranslation();
  const { brand, collection } = useSelector((state: RootState) => state);
  const [collections, setCollections] = useState<FiraCollection[]>([]);
  const [selectedType, setSelectedType] = useState<TypeSelectModel>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [selectedCollection, setSelectedCollection] =
    useState<FiraCollection | null>(null);
  const [showFeaturedLivesInfo, setShowFeaturedLivesInfo] =
    useState<boolean>(false);
  const [showFeaturedProductsInfo, setShowFeaturedProductsInfo] =
    useState<boolean>(false);
  const [elementTarget, setElementTarget] = useState<HTMLElement>();
  const [selectedRadioOption, setSelectedRadioOption] = useState<
    "VOD" | "PRODUCTS"
  >("PRODUCTS");
  const [selectedProducts, setSelectedProducts] = useState<LandingProduct[]>(
    []
  );
  const [productsWidgetTitle, setProductsWidgetTitle] = useState<string>("");
  const [landingProductsFixed, setLandingProductsFixed] = useState<
    LandingProduct[]
  >([]);
  const { globalLandingConfig } = useSettingsLanding();
  const options = [
    {
      value: EventSelectType.nextEvents,
      name: t("components.selectEventsWidget.nextEvents"),
      icon: <CalendarIcon />,
    },
    {
      value: EventSelectType.prevEvents,
      name: t("components.selectEventsWidget.prevEvents"),
      icon: <CalendarIcon />,
    },
    {
      value: EventSelectType.collections,
      name: t("components.selectEventsWidget.collections"),
      icon: <ClosedBox />,
    },
  ];

  const infoV1 = (
    <p
      style={{
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "10px",
      }}
    >
      {t("views.landing.widgets.featuredInfo")}
    </p>
  );

  const infoV2 = (
    <p
      style={{
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "10px",
      }}
    >
      {t("views.landing.widgets.featuredProductsInfo")}
    </p>
  );

  const handleBackgroundColor = (color: string) => {
    let widgetConfig: any;
    if (firaLandingConfiguration) {
      widgetConfig = {
        ...firaLandingConfiguration.widgetContent,
        bgColor: `#${color}`,
      };
    } else {
      widgetConfig = {
        ...widget,
        bgColor: `#${color}`,
      };
    }

    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      widgetContent: widgetConfig,
    });
  };

  const handleTitleColor = (color: string) => {
    let widgetConfig: any;
    if (firaLandingConfiguration) {
      widgetConfig = {
        ...firaLandingConfiguration.widgetContent,
        fontColor: `#${color}`,
      };
    } else {
      widgetConfig = {
        ...widget,
        fontColor: `#${color}`,
      };
    }

    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      widgetContent: widgetConfig,
    });
  };

  const handleScreenColor = (color: string) => {
    let widgetConfig: any;
    if (firaLandingConfiguration) {
      widgetConfig = {
        ...firaLandingConfiguration.widgetContent,
        displayColor: `#${color}`,
      };
    } else {
      widgetConfig = {
        ...widget,
        displayColor: `#${color}`,
      };
    }

    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      widgetContent: widgetConfig,
    });
  };

  const handleDetailsColor = (color: string) => {
    let widgetConfig: any;
    if (firaLandingConfiguration) {
      widgetConfig = {
        ...firaLandingConfiguration.widgetContent,
        detailsColor: `#${color}`,
      };
    } else {
      widgetConfig = {
        ...widget,
        detailsColor: `#${color}`,
      };
    }

    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      widgetContent: widgetConfig,
    });
  };

  const handleType = (type: TypeSelectModel) => {
    if (type) {
      setSelectedType(type);

      let widgetConfig = {
        ...firaLandingConfiguration.widgetContent,
        previousEvents: `${type.value}`,
        sectionTitle: `${type.name}`,
      };
      setFiraLandingConfiguration({
        ...firaLandingConfiguration,
        widgetContent: widgetConfig,
      });
    }
  };

  const getCollections = () => {
    if (brand.currentStore) {
      dispatch(
        getCollectionsActionCreator(
          brand.currentStore.id,
          20,
          0,
          VideoFilter.newer
        )
      );
    }
  };

  const handleCollection = (collection: FiraCollection) => {
    setSelectedCollection(collection);
    let widgetConfig = {
      ...firaLandingConfiguration.widgetContent,
      collectionId: collection?.id,
      sectionTitle: collection?.collectionName,
    };
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      widgetContent: widgetConfig,
    });
  };

  const handleClearCollection = () => {
    setSelectedCollection(null);
    let widgetConfig: FiraWidgetConfiguration = {
      ...firaLandingConfiguration.widgetContent,
      collectionId: null,
    };
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      widgetContent: widgetConfig,
    });
  };

  const handleInfoPopUpV1 = (e: React.MouseEvent<HTMLElement>) => {
    setShowFeaturedLivesInfo(!showFeaturedLivesInfo);
    setElementTarget(e.currentTarget);
  };

  const handleInfoPopUpV2 = (e: React.MouseEvent<HTMLElement>) => {
    setShowFeaturedProductsInfo(!showFeaturedProductsInfo);
    setElementTarget(e.currentTarget);
  };

  /** PRODUCTS **/
  const getProducts = async () => {
    let response;
    if (brand.currentStore && existingLanding) {
      response = await getLandingsProductsByStoreIdService(
        brand.currentStore.id
      );
      let featureRev = existingLanding.featuredProducts
        ? existingLanding.featuredProducts
        : [];

      const featuredProductIds = featureRev.map((product) => product.id);
      const featuredProductIdsSet = new Set(featuredProductIds);
      response.data.forEach((product: LandingProduct) => {
        product.featuredProduct = featuredProductIdsSet.has(product.id);
      });
    } else {
      !brand.currentStore
        ? (response = await getStoreProductsGlobalService())
        : (response = await getLandingsProductsByStoreIdService(
            brand.currentStore.id
          ));
    }
    if (brand.currentStore && existingLanding) {
      setSelectedProducts(response.data);
      setLandingProductsFixed(response.data);
    } else {
      const landingProductIds = new Set(
        globalLandingConfig?.featuredProducts
          ?.filter((product) => product.featuredProduct)
          .map((item) => item.id)
      );

      const initialProducts = response.data.map((product: LandingProduct) => ({
        ...product,
        featuredProduct: landingProductIds.has(product.id),
        selected: landingProductIds.has(product.id),
      }));
      setSelectedProducts(initialProducts);
      setLandingProductsFixed(initialProducts);
    }
  };

  const handleProductSelection = (productLanding: LandingProduct) => {
    if (countSelected() >= 8 && !productLanding.featuredProduct) {
      return;
    }

    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.map((product) =>
        product.id === productLanding.id
          ? { ...product, featuredProduct: !product.featuredProduct }
          : product
      )
    );

    setLandingProductsFixed((prevSelectedProducts) =>
      prevSelectedProducts.map((product) =>
        product.id === productLanding.id
          ? { ...product, featuredProduct: !product.featuredProduct }
          : product
      )
    );
  };

  const countSelected = () => {
    const selectedObjects = landingProductsFixed.filter(
      (obj) => obj.featuredProduct === true
    );
    return selectedObjects.length;
  };

  const handleProductWidgetTitle = (str: string) => {
    setProductsWidgetTitle(str);
    let newWidget = {
      ...firaLandingConfiguration.widgetContent,
      sectionTitle: str,
    };
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      widgetContent: newWidget,
    });
  };

  const handleProductSearch = (value: string) => {
    const filtered = handleSearch(landingProductsFixed, value);
    setSelectedProducts(filtered);
  };

  const getSelectedProducts = (products: LandingProduct[]) => {
    const selectedProductIds = new Set();

    return products.filter((product) => {
      if (product.featuredProduct && !selectedProductIds.has(product.id)) {
        selectedProductIds.add(product.id);
        return true;
      }
      return false;
    });
  };

  const handleWidgetType = (type: WidgetType) => {
    if (type === WidgetType.products) {
      let newWidget = {
        ...firaLandingConfiguration.widgetContent,
        widgetType: type,
        collectionId: "",
        previousEvents: "",
        sectionTitle: productsWidgetTitle,
        widgetId: "",
      };

      setFiraLandingConfiguration({
        ...firaLandingConfiguration,
        widgetContent: newWidget,
      });
      setWidget(newWidget);
    } else {
      let widgetConfig = {
        ...firaLandingConfiguration.widgetContent,
        widgetType: type,
      };
      setFiraLandingConfiguration({
        ...firaLandingConfiguration,
        widgetContent: widgetConfig,
      });
      setWidget(widgetConfig);
    }
  };

  useEffect(() => {
    let selected = getSelectedProducts(selectedProducts);
    setLandingConfig({ ...landingConfig, featuredProducts: selected });
  }, [selectedProducts]);

  useEffect(() => {
    if (!collection.isLoading) {
      setIsFetching(false);
      if (collection.list) {
        setCollections(collection.list);
      }
    } else {
      setIsFetching(true);
    }
  }, [collection]);

  useEffect(() => {
    if (selectedType?.value === EventSelectType.collections) {
      getCollections();
    }
  }, [selectedType]);

  useEffect(() => {
    if (
      existingLanding &&
      existingLanding.firaLandingConfiguration &&
      existingLanding.firaLandingConfiguration.widgetContent
    ) {
      setWidget(existingLanding.firaLandingConfiguration.widgetContent);
      setSelectedType(
        options.find(
          (col) =>
            col.value ===
            existingLanding.firaLandingConfiguration?.widgetContent
              .previousEvents
        )
      );
      setProductsWidgetTitle(
        existingLanding.firaLandingConfiguration.widgetContent.sectionTitle
      );
    }
  }, [existingLanding]);

  useEffect(() => {
    if (collections && existingLanding) {
      let col = collections.find(
        (obj) =>
          obj.id ===
          existingLanding.firaLandingConfiguration?.widgetContent.collectionId
      );

      if (col) {
        setSelectedCollection(col);
      }
    }
  }, [collections]);

  useEffect(() => {
    getProducts();
    return () => {
      dispatch(resetCollectionListActionCreator());
    };
  }, []);

  return (
    <div className={styles.LandingWidgetsWrapper}>
      <div className={styles.LandingModule}>
        <div className={styles.LandingModule__Title}>
          {t("views.landing.widgets.selectWidget")}
        </div>
        <div className={styles.LandingModule__Radio}>
          <label>
            <span
              className={`${
                widget?.widgetType === WidgetType.vod ? styles.selected : ""
              }`}
            >
              {t("views.landing.widgets.featured")}
            </span>
            <div
              onMouseEnter={handleInfoPopUpV1}
              onMouseLeave={handleInfoPopUpV1}
            >
              <InfoCircle />
            </div>
            {showFeaturedLivesInfo && (
              <PopUpInfo
                title={""}
                elementRef={elementTarget}
                message={infoV1}
              />
            )}
          </label>
          <div tabIndex={0}>
            <RadioButton
              isActive={widget?.widgetType === WidgetType.vod}
              clickHandler={() => handleWidgetType(WidgetType.vod)}
            />
          </div>
        </div>
        <div className={styles.LandingModule__Radio}>
          <label>
            <span
              className={`${
                widget?.widgetType === WidgetType.products
                  ? styles.selected
                  : ""
              }`}
            >
              {t("views.landing.widgets.featuredProducts")}
            </span>
            <div
              onMouseEnter={handleInfoPopUpV2}
              onMouseLeave={handleInfoPopUpV2}
            >
              <InfoCircle />
            </div>

            {showFeaturedProductsInfo && (
              <PopUpInfo
                title={""}
                elementRef={elementTarget}
                message={infoV2}
              />
            )}
          </label>
          <div tabIndex={0}>
            <RadioButton
              isActive={widget?.widgetType === WidgetType.products}
              clickHandler={() => handleWidgetType(WidgetType.products)}
            />
          </div>
        </div>
      </div>
      {widget?.widgetType === WidgetType.vod ? (
        <>
          <div className={styles.LandingModule}>
            <div className={styles.LandingModule__Title}>Widget</div>
            <div className={styles.LandingModule}>
              <span style={{ fontSize: "10px" }}>
                {t("views.landing.widgets.widgetTitle")}
              </span>
              <Input
                width={"100%"}
                textLimit={25}
                value={productsWidgetTitle}
                onChange={(e) => handleProductWidgetTitle(e.target.value)}
              />
            </div>
            <span style={{ fontSize: "10px" }}>
              {t("views.landing.widgets.widgetsDesc")}
            </span>
            <TypeSelect
              options={options}
              onTypeChange={handleType}
              value={selectedType}
            />
            {selectedType?.value === EventSelectType.collections && (
              <>
                <div className={styles.LandingModule__Title}>
                  {t("views.landing.widgets.collection")}
                </div>
                {collections?.length > 0 ? (
                  <SimpleFormSelect
                    height={"32px"}
                    name={selectedCollection?.collectionName || ""}
                    fontSize={"10px"}
                    placeholder={t(
                      "views.landing.widgets.collectionPlaceholder"
                    )}
                    removeIcon={selectedCollection !== null}
                    showArrow={false}
                    onRemove={() => handleClearCollection()}
                  >
                    <>
                      {collections &&
                        collections.map((col, i) => (
                          <div key={i} onClick={() => handleCollection(col)}>
                            {col.collectionName}
                          </div>
                        ))}
                    </>
                  </SimpleFormSelect>
                ) : isFetching ? (
                  <div className={styles.BouncingLoaderContainer}>
                    <BouncingLoader color={"#ffde07"} />
                  </div>
                ) : (
                  <div className={styles.FauxWidgetInput}>
                    No hay colleciones
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.LandingModule} style={{ paddingRight: "8px" }}>
            <div className={styles.LandingModule__Title}>
              {t("views.landing.widgets.widgetStyle")}
            </div>
            <InputColor
              onHexChange={handleTitleColor}
              label={t("views.landing.widgets.titleLabel")}
              value={widget ? noHash(widget.fontColor) : ""}
            />
            <InputColor
              onHexChange={handleScreenColor}
              label={t("views.landing.widgets.screen")}
              value={widget ? noHash(widget.displayColor) : ""}
            />
            <InputColor
              onHexChange={handleDetailsColor}
              label={t("views.landing.widgets.details")}
              value={widget ? noHash(widget.detailsColor) : ""}
            />
            <InputColor
              label={t("views.landing.widgets.background")}
              onHexChange={handleBackgroundColor}
              value={widget ? noHash(widget.bgColor) : ""}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.LandingModule}>
            <div className={styles.LandingModule__Title}>
              {t("views.landing.widgets.widgetTitle")}
            </div>
            <Input
              width={"100%"}
              textLimit={25}
              value={productsWidgetTitle}
              onChange={(e) => handleProductWidgetTitle(e.target.value)}
            />
          </div>

          <div className={styles.LandingModule}>
            <div className={styles.LandingModule__Title}>
              {t("views.landing.widgets.products")} {countSelected()}/8
            </div>
            <ProductList
              products={selectedProducts}
              onSelect={handleProductSelection}
              onSearch={handleProductSearch}
              currency={
                brand.currentStore?.regionalConfiguration.currency || "USD"
              }
              mode={"widget"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default LandingWidgets;
