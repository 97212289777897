import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSilent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.615 5.692c.297.001.54.244.5.538a4.127 4.127 0 0 1-.538 1.53L8.8 6.982c.103-.236.18-.489.226-.754.05-.295.29-.54.589-.537Zm-2.002.104L3.832 2.014C3.832.943 4.594 0 5.719 0c1.042 0 1.894.847 1.894 1.894v3.902Zm3.384 4.998a.569.569 0 1 1-.804.805L7.948 9.355a4.5 4.5 0 0 1-1.61.568v1.44a.631.631 0 1 1-1.263 0V9.916c-1.896-.284-3.496-1.8-3.752-3.708-.04-.294.205-.535.5-.535.297 0 .533.242.581.534.268 1.605 1.723 2.685 3.296 2.685.512 0 1.004-.12 1.46-.326L6.11 7.52l-.416.045A1.896 1.896 0 0 1 3.8 5.669l-.007-.454L.401 1.822a.57.57 0 0 1 .805-.806l9.791 9.778Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSilent;
