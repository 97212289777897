import {
  CollectionBasicData,
  FiraCollection,
  FiraSimpleCollection,
} from "../utils/types";
import { apiBusinessPrivate } from "./api";

export const getCollectionsService = (
  storeId: string,
  limit: number,
  offSet: number,
  sortType: string
) => {
  return apiBusinessPrivate.get<FiraCollection[]>(
    `/v1/fira-tv/collection/fetch-collections/${storeId}/${limit}/${offSet}/${sortType}`
  );
};

export const createCollectionService = (
  collection: FiraSimpleCollection,
  file: File
) => {
  const body = new FormData();
  const data = {
    storeId: collection.storeId,
    collectionName: collection.collectionName,
    collectionDescription: collection.collectionDescription || null,
    videos: collection.videos,
  };
  const json = JSON.stringify(data);
  const blob = new Blob([json], {
    type: "application/json",
  });
  body.append("json", blob);
  body.append("image_file", file, file.name);

  const headers = {
    "Content-Type": `multipart/form-data`,
  };
  return apiBusinessPrivate.post(
    `/v1/fira-tv/collection/create-collection`,
    body,
    { headers }
  );
};

export const deleteCollectionService = (collectionId: string) => {
  return apiBusinessPrivate.delete(
    `/v1/fira-tv/collection/delete/${collectionId}`
  );
};

export const editCollectionService = (
  collectionId: string,
  body: CollectionBasicData
) => {
  return apiBusinessPrivate.put(
    `/v1/fira-tv/collection/edit/${collectionId}`,
    body
  );
};

export const updateCollectionImageService = (
  collectionId: string,
  file: File
) => {
  const body = new FormData();
  body.append("file", file, file.name);
  const headers = {
    "Content-Type": `multipart/form-data`,
  };
  return apiBusinessPrivate.put(
    `/v1/fira-tv/collection/update-image/${collectionId}`,
    body,
    { headers }
  );
};

export const addVODToCollectionService = (
  collectionId: string,
  videos: string[]
) => {
  let body = {
    collectionId: collectionId,
    videos: videos,
  };
  return apiBusinessPrivate.post(`/v1/fira-tv/collection/vod/add`, body);
};

export const deleteVODFromCollectionService = (
  collectionId: string,
  videos: string[]
) => {
  const config = {
    data: {
      collectionId: collectionId,
      videos: videos,
    },
  };
  return apiBusinessPrivate.delete(`/v1/fira-tv/collection/vod/delete`, config);
};

export const getVODSFromCollectionService = (
  collectionId: string,
  sortBy: string
) => {
  return apiBusinessPrivate.get(
    `/v1/fira-tv/collection/vods/sortBy/${collectionId}/${sortBy}`
  );
};
