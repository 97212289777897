import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDownload = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#download2_svg__a)" fill="#000">
      <path d="M5.602 9.023a.562.562 0 0 0 .796 0L8.519 6.9a.562.562 0 1 0-.795-.795L6.562 7.267V1.125a.563.563 0 0 0-1.125 0v6.142l-1.16-1.161a.563.563 0 0 0-.796.795l2.121 2.122Z" />
      <path d="M10.875 5.438a.562.562 0 0 0-.563.562v4.313H1.688V6A.563.563 0 1 0 .563 6v4.5a.938.938 0 0 0 .937.938h9a.938.938 0 0 0 .938-.938V6a.563.563 0 0 0-.563-.563Z" />
    </g>
    <defs>
      <clipPath id="download2_svg__a">
        <path fill="#fff" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDownload;
