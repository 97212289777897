import * as React from "react";
import { SVGProps } from "react";

const HorizontalDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={4}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2a2 2 0 1 1-4.001-.001A2 2 0 0 1 4 2Zm6 0a2 2 0 1 1-4.001-.001A2 2 0 0 1 10 2Zm6 0a2 2 0 1 1-4.001-.001A2 2 0 0 1 16 2Z"
      fill="#5C5F62"
    />
  </svg>
);

export default HorizontalDots;
