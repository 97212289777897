import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDangerCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#dangerCircle_svg__a)">
      <path
        d="m19.349 14.182-6.13-11.737C12.394.866 11.254 0 10 0S7.605.866 6.782 2.445L.652 14.182c-.777 1.498-.863 2.935-.24 4.066C1.034 19.378 2.26 20 3.87 20h12.26c1.61 0 2.836-.622 3.458-1.752.623-1.131.537-2.578-.24-4.066ZM9.282 7.132c0-.418.325-.764.718-.764.393 0 .718.346.718.764v5.094c0 .418-.325.764-.718.764-.393 0-.718-.346-.718-.764V7.132Zm1.398 8.874-.144.122a.712.712 0 0 1-.172.092.556.556 0 0 1-.182.061c-.058.01-.125.02-.182.02-.057 0-.125-.01-.192-.02a.586.586 0 0 1-.172-.06.71.71 0 0 1-.172-.093l-.144-.122a1.108 1.108 0 0 1-.278-.723c0-.265.106-.53.278-.724l.144-.122a.708.708 0 0 1 .172-.092.58.58 0 0 1 .172-.06.771.771 0 0 1 .374 0c.067.01.124.03.182.06.057.02.115.051.172.092l.144.122c.172.194.278.459.278.724 0 .265-.106.53-.278.723Z"
        fill="#F16063"
      />
    </g>
    <defs>
      <clipPath id="dangerCircle_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDangerCircle;
