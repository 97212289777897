import React, { useState } from "react";
import { usePopper } from "react-popper";
import "./PopUpInfo.scss";
interface InfoProps {
  title: string;
  elementRef: HTMLElement | undefined;
  onClickCancel?(): void;
  onClose?(): void;
  message?: JSX.Element;
}

const PopUpInfo = ({ title, elementRef, message }: InfoProps) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const modifiers = [
    {
      name: "offset",
      options: {
        offset: [0, 8],
      },
    },
    {
      name: "preventOverflow",
      options: {
        padding: 10,
      },
    },
    {
      name: "arrow",
      options: {
        element: arrowElement,
      },
    },
  ];
  const { styles, attributes } = usePopper(elementRef, popperElement, {
    modifiers,
  });
  const containerStyle: any = {
    ...styles.popper,
    display: "flex",
    zIndex: 99,
    flexDirection: "column",
    borderRadius: "10px",
    left: "65px",
    border: "1px solid #d0d0d0",
  };

  return (
    <div ref={setPopperElement} style={containerStyle} {...attributes.popper}>
      <div className="triangle"></div>
      <div className={"popupContainer"}>
        {message ? message : <p className={"popupText"}>{title}</p>}
      </div>
    </div>
  );
};

export default PopUpInfo;
