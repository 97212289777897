import { t } from "i18next";
import { useEffect, useState } from "react";
import { EventSelectType } from "../../../utils/types.d";
import { useClickOutside } from "../../../utils/useClickOutside";
import { Archive, ArrowDown, CalendarIcon } from "../../FiraIcons";
import styles from "./WidgetSelect.module.scss";

interface WidgetSelectProps {
  onChange?(selected: EventSelectType): void;
  noNextEvents?: boolean;
  showLabel?: boolean;
}

const WidgetSelect = ({
  onChange,
  noNextEvents = false,
  showLabel = true,
}: WidgetSelectProps) => {
  const [optionsIsOpen, setOptionsIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [optionsList, setOptionsList] = useState<any>([]);
  const domNode = useClickOutside(() => setOptionsIsOpen(false));
  const options = [
    {
      id: 1,
      value: EventSelectType.nextEvents,
      name: t("components.selectEventsWidget.nextEvents"),
      icon: <CalendarIcon />,
    },
    {
      id: 2,
      value: EventSelectType.prevEvents,
      name: t("components.selectEventsWidget.prevEvents"),
      icon: <CalendarIcon />,
    },
    {
      id: 3,
      value: EventSelectType.collections,
      name: t("components.selectEventsWidget.collections"),
      icon: <Archive />,
    },
  ];

  const handleSelectedType = (option: EventSelectType, name: string) => {
    setSelectedOption(name);
    if (onChange) onChange(option);
    setOptionsIsOpen(false);
  };

  useEffect(() => {
    if (noNextEvents) {
      let arr = [];
      arr.push(options[1], options[2]);

      setOptionsList(arr);
    } else {
      setOptionsList(options);
    }
  }, [noNextEvents]);

  return (
    <div className={styles.WidgetSelectWrapper} ref={domNode}>
      {showLabel && (
        <label className={styles.WidgetSelectTitle}>
          {t("components.selectEventsWidget.title")}
        </label>
      )}

      <div
        className={`${styles.WidgetSelect} ${
          optionsIsOpen ? styles.openShadow : ""
        }`}
      >
        <div
          className={`${styles.WidgetSelect__Input} ${
            optionsIsOpen ? styles.isOpen : ""
          }`}
          onClick={() => setOptionsIsOpen(!optionsIsOpen)}
        >
          {selectedOption === "" ? (
            <>{t("components.selectEventsWidget.eventTypes")}</>
          ) : (
            <>{selectedOption}</>
          )}
          <ArrowDown />
        </div>
        {optionsIsOpen && (
          <div
            className={styles.WidgetSelect__Options}
            style={noNextEvents ? { height: "104px" } : { height: "144px" }}
          >
            {optionsList.map((item: any, i: number) => (
              <li
                key={item.id}
                onClick={() => handleSelectedType(item.value, item.name)}
              >
                <span className={styles.Icon}>{item.icon}</span>
                {item.name}
              </li>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default WidgetSelect;
