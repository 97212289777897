import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableVideosActionCreator } from "../../../store/Video/VideoActions";
import { RootState } from "../../../store/Store";
import { FiraEvent, FiraVideo } from "../../../utils/types";
import { RoundedX } from "../../FiraIcons";
import styles from "./SelectByEventName.module.scss";

interface SelectByEventNameProps {
  title?: string;
  onSelect?(selection: FiraVideo | null): void;
  defaultName?: string;
}

const SelectByEventName = ({
  title,
  onSelect,
  defaultName = t("components.selectByEventNameWidget.defaultv1"),
}: SelectByEventNameProps) => {
  const { brand, video } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [firstFetch, setFirstFetch] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedEvent, setSelectedEvent] = useState<FiraVideo | null>(null);

  const getName = () => {
    if (selectedEvent !== null) {
      return selectedEvent.videoName;
    } else {
      return defaultName;
    }
  };

  const getEvents = () => {
    if (firstFetch) {
      if (brand.currentStore) {
        dispatch(
          getAvailableVideosActionCreator(
            brand.currentStore.id,
            0,
            1000,
            "DEFAULT"
          )
        );
      }
    } else {
      return;
    }
  };

  const handleSelection = (sel: FiraVideo) => {
    setIsOptionsOpen(false);
    setSelectedEvent(sel);
    if (onSelect) onSelect(sel);
  };

  const removeSelection = () => {
    setIsOptionsOpen(false);
    setSelectedEvent(null);
    if (onSelect) onSelect(null);
  };

  useEffect(() => {
    if (!video.isLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [video]);

  useEffect(() => {
    getEvents();
    setFirstFetch(false);
  }, []);

  return (
    <>
      <div className={styles.EventSelectWrapper}>
        <div className={styles.EventSelectWrapper__Title}>
          <p>{title?.toUpperCase()}</p>
        </div>
        <div className={`${styles.EventSelectWrapper__Select}`}>
          <div
            className={`${styles.EventSelectWrapper__Select__Input} ${
              isOptionsOpen ? styles.isOpen : ""
            }`}
          >
            <div
              className={`${styles.EventSelectWrapper__Select__Input__Name}`}
              onClick={() => setIsOptionsOpen(!isOptionsOpen)}
            >
              {getName()}
            </div>
            {selectedEvent !== null && (
              <div
                className={`${styles.EventSelectWrapper__Select__Input__Icon}`}
                onClick={() => removeSelection()}
              >
                <RoundedX />
              </div>
            )}
          </div>
          {isOptionsOpen && (
            <div className={styles.EventSelectWrapper__Select__Options}>
              {!isLoading &&
                video.availableList.map((item, i) => (
                  <li key={i} onClick={() => handleSelection(item)}>
                    {item.videoName}
                  </li>
                ))}
              {isLoading && (
                <div className={styles.loaderContainer}>
                  <span className={styles.loader}></span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectByEventName;
