import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  EventSelectType,
  FiraConfigurationOptions,
  RootState,
  WidgetType,
} from "../../utils/types.d";
import CarouselWidget from "../../widgetsFiraTV/CarrouselEvent/Carousel";
import GridEventsWidget from "../../widgetsFiraTV/GridEvent/GridEventsWidget";
import MainNextEvents from "../../widgetsFiraTV/MainCarrouselEvent/MainCarrouselEvents";
import SliderBanner from "../../widgetsFiraTV/SliderBannersWidget/SliderBanner";
import VodGallery from "../../widgetsFiraTV/VodGalleryWidget/VodGallery";
import ComercialBanner from "../../widgetsFiraTV/ComercialBannerWidget/ComercialBanner";
import { BigSmartphone, Image } from "../FiraIcons";
import styles from "./WidgetsViewer.module.scss";
import Collections from "../../widgetsFiraTV/CollectionsWidget/Collections";
import noImageStore from "../../assets/svg/no-image-store.svg";

export enum viewModeType {
  desktop = "DESKTOP",
  mobile = "MOBILE",
}

const widgetConfigInitialState: FiraConfigurationOptions = {
  primaryTextColor: "#000000",
  secondaryTextColor: "#067DC7",
  buttonTextColor: "#ffffff",
  primaryColor: "#067DC7",
  cardColor: "#ffffff",
  displayColor: "#000",
  type: EventSelectType.prevEvents,
};

interface WidgetsViewerProps {
  viewportType: viewModeType;
  widgetType?: WidgetType;
  widgetConfig?: FiraConfigurationOptions;
}

const WidgetsViewer = ({
  viewportType,
  widgetConfig = widgetConfigInitialState,
  widgetType,
}: WidgetsViewerProps) => {
  const { brand } = useSelector((state: RootState) => state);
  const [selectedWidgetClassName, setSelectedWidgetClassName] =
    useState<string>("");
  const [selectedWidgetClassNameMobile, setSelectedWidgetClassNameMobile] =
    useState<string>("");
  const [height, setHeight] = useState<{ height: string }>();
  const [displayHeight, setDisplayHeight] = useState<{ height: string }>();

  const mainEventsWidget = (
    <MainNextEvents
      firaKey={brand.currentStore?.id}
      primaryTextColor={widgetConfig?.primaryTextColor || "#000"}
      displayColor={widgetConfig?.displayColor || "#000"}
      buttonTextColor={widgetConfig?.buttonTextColor || "#fff"}
      primaryColor={widgetConfig?.primaryColor || "#067DC7"}
      backgroundColor={widgetConfig?.backgroundColor || ""}
      type={"NEXT-EVENTS"}
    />
  );

  const carouselEventWidget = (
    <CarouselWidget
      firaKey={brand.currentStore?.id}
      widgetTitle={widgetConfig?.title || "Próximos eventos"}
      primaryTextColor={widgetConfig?.primaryTextColor || "#000"}
      displayColor={widgetConfig?.displayColor || "#000"}
      primaryColor={widgetConfig?.primaryColor || "#067DC7"}
      backgroundColor={widgetConfig?.backgroundColor || "#fff"}
      type={widgetConfig?.type}
      isMobileAttr={viewportType === viewModeType.desktop ? false : true}
    />
  );

  const gridEventsWidget = (
    <GridEventsWidget
      firaKey={brand.currentStore?.id}
      title={widgetConfig?.title}
      primaryTextColor={widgetConfig?.primaryTextColor || "#000"}
      secondaryTextColor={widgetConfig?.secondaryTextColor || "#067DC7"}
      buttonTextColor={widgetConfig?.buttonTextColor || "#fff"}
      primaryColor={widgetConfig?.primaryColor || "#067DC7"}
      cardColor={widgetConfig?.cardColor || "#fff"}
      backgroundColor={widgetConfig?.backgroundColor || ""}
      type={"NEXT-EVENTS"}
    />
  );

  const vodGalleryWidget = (
    <VodGallery
      videoSort={true}
      videoFilter={true}
      primaryTextColor={widgetConfig?.primaryTextColor || "#000000"}
      secondaryTextColor={widgetConfig?.secondaryTextColor || "#000000"}
      tertiaryTextColor={widgetConfig?.tertiaryTextColor || "#000000"}
      primaryColor={widgetConfig?.primaryColor || "#000000"}
      secondaryColor={widgetConfig?.secondaryColor || "#ABAFB1"}
      tertiaryColor={widgetConfig?.tertiaryColor || "#FFFFFF"}
      quaternaryColor={widgetConfig?.quinaryColor || "#868586"}
      quinaryColor={widgetConfig?.quaternaryColor || "#000000"}
      backgroundColor={widgetConfig?.backgroundColor || ""}
      isMobileAttr={viewportType === viewModeType.desktop ? false : true}
    />
  );

  const bannerSliderWidget = (
    <SliderBanner
      firstImage={widgetConfig?.firstImage || ""}
      secondImage={widgetConfig?.secondImage || ""}
      thirdImage={widgetConfig?.thirdImage || ""}
      primaryTextColor={widgetConfig?.primaryTextColor || "#FFFFFF"}
      secondaryTextColor={widgetConfig?.secondaryTextColor || "#000000"}
      tertiaryTextColor={widgetConfig?.tertiaryTextColor || "#000000"}
      primaryColor={widgetConfig?.primaryColor || "#3FC1C9"}
      secondaryColor={widgetConfig?.secondaryColor || "#364F6B"}
      tertiaryColor={widgetConfig?.tertiaryColor || "#364F6B"}
      quaternaryColor={widgetConfig?.quaternaryColor || "#FC5185"}
      isMobileAttr={viewportType === viewModeType.desktop ? false : true}
    />
  );

  const commercialBannerWidget = widgetConfig.firstImage ? (
    <ComercialBanner firstImage={widgetConfig?.firstImage} />
  ) : (
    <NoImage />
  );

  const collectionsWidget = (
    <Collections
      primaryTextColor={widgetConfig?.primaryTextColor || "#000000"}
      secondaryTextColor={widgetConfig?.secondaryTextColor || "#000000"}
      primaryColor={widgetConfig?.primaryColor || "#3FC1C9"}
      isMobileAttr={viewportType === viewModeType.desktop ? false : true}
      headerTitle={widgetConfig?.title || ""}
      backgroundColor={widgetConfig?.backgroundColor || ""}
    />
  );

  const handleViewerCase = () => {
    switch (widgetType) {
      case WidgetType.gridEvents: {
        return gridEventsWidget;
      }
      case WidgetType.nextEventsCarousel: {
        return carouselEventWidget;
      }
      case WidgetType.mainCarousel: {
        return mainEventsWidget;
      }
      case WidgetType.vodGallery: {
        return vodGalleryWidget;
      }
      case WidgetType.bannerSlider: {
        return bannerSliderWidget;
      }
      case WidgetType.bannerCommercial: {
        return commercialBannerWidget;
      }
      case WidgetType.categories: {
        return collectionsWidget;
      }
    }
  };

  const handleClassCase = () => {
    switch (widgetType) {
      case WidgetType.gridEvents:
        {
          setSelectedWidgetClassName("gridEventsContainer");
          setSelectedWidgetClassNameMobile("gridEventsContainerMobile");
          setHeight({ height: "377px" });
          setDisplayHeight({ height: "350px" });
        }
        break;
      case WidgetType.nextEventsCarousel:
        {
          setSelectedWidgetClassName("carouselEventsContainer");
          setSelectedWidgetClassNameMobile("carouselEventsContainerMobile");
        }
        break;
      case WidgetType.mainCarousel:
        {
          setSelectedWidgetClassName("mainCarouselContainer");
          setSelectedWidgetClassNameMobile("mainCarouselContainerMobile");
        }
        break;
      case WidgetType.vodGallery:
        {
          setSelectedWidgetClassName("vodGalleryContainer");
          setSelectedWidgetClassNameMobile("vodGalleryContainerMobile");
          setHeight({ height: "483px" });
          setDisplayHeight({ height: "440px" });
        }
        break;
      case WidgetType.bannerCommercial:
        {
          setSelectedWidgetClassName("bannerCommercialContainer");
          setSelectedWidgetClassNameMobile("bannerCommercialContainerMobile");
          setHeight({ height: "307px" });
          setDisplayHeight({ height: "60%" });
        }
        break;
      case WidgetType.bannerSlider:
        {
          setSelectedWidgetClassName("bannerSliderContainer");
          setSelectedWidgetClassNameMobile("bannerSliderContainerMobile");
          setHeight({ height: "307px" });
          setDisplayHeight({ height: "236px" });
        }
        break;
      case WidgetType.categories:
        {
          setSelectedWidgetClassName("categoriesContainer");
          setSelectedWidgetClassNameMobile("categoriesContainerMobile");
          setHeight({ height: "307px" });
          setDisplayHeight({ height: "236px" });
        }
        break;
      default: {
        setSelectedWidgetClassName("gridEventsContainer");
        setSelectedWidgetClassNameMobile("gridEventsContainerMobile");
      }
    }
  };

  useEffect(() => {
    handleClassCase();
  }, [widgetType]);

  return (
    <>
      <div
        className={styles.WidgetsViewer}
        style={
          viewportType === viewModeType.desktop ? height : { height: "540px" }
        }
      >
        <div className={styles.WidgetsViewerTitle}>
          <span>
            {viewportType === viewModeType.desktop
              ? t("views.firaTv.desktop")
              : t("views.firaTv.mobile")}
          </span>
        </div>
        <div
          className={styles.Display}
          style={
            viewportType === viewModeType.desktop
              ? displayHeight
              : { height: "480px" }
          }
        >
          {viewportType === viewModeType.mobile ? (
            <div className={styles.MobileDisplay}>
              <div className={styles.MobileMockup}>
                <BigSmartphone className={styles.previewMobileContainer} />
                <div className={styles.WidgetView}>
                  <div
                    className={
                      selectedWidgetClassNameMobile
                        ? styles[selectedWidgetClassNameMobile]
                        : ""
                    }
                  >
                    {handleViewerCase()}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                selectedWidgetClassName ? styles[selectedWidgetClassName] : ""
              }
            >
              {handleViewerCase()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const NoImage = () => {
  return (
    <div className={styles.NoImageBanner}>
      <Image />
    </div>
  );
};

export default WidgetsViewer;
