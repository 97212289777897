import React from "react";

interface Props {
  color?: string;
  textColor?: string;
}
const FiraBadge: React.FC<Props> = ({
  children,
  color = "#067DC7",
  textColor = "#fff",
}) => {
  return (
    <div style={{ ...wrapper, backgroundColor: color, color: textColor }}>
      {children}
    </div>
  );
};

const wrapper: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "3px 6px",
  borderRadius: 4,
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "normal",
};

export default FiraBadge;
