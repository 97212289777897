import * as React from "react";

const GridOfTwo = ({
  title,
  titleId,
  ...props
}) => (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.25" y="0.25" width="6.5" height="6.5" stroke="currentColor" strokeWidth="0.5"/>
  <rect x="0.25" y="9.25" width="6.5" height="6.5" stroke="currentColor" strokeWidth="0.5"/>
  <rect x="9.25" y="0.25" width="6.5" height="6.5" stroke="currentColor" strokeWidth="0.5"/>
  <rect x="9.25" y="9.25" width="6.5" height="6.5" stroke="currentColor" strokeWidth="0.5"/>
</svg>

);

export default GridOfTwo;