import { apiManagement, apiManagementPrivate } from "./api";

interface ResponseUploadImage {
  images: string[];
}

export const uploadImageService = (images: File[] | File) => {
  const data = new FormData();
  if (images instanceof File) {
    data.append("files", images, images.name);
  } else {
    images.forEach((image) => {
      data.append("files", image, image.name);
    });
  }

  const headers = {
    "Content-Type": `multipart/form-data`,
  };

  return apiManagementPrivate.post<ResponseUploadImage>(
    "/api/v1/upload",
    data,
    {
      headers,
    }
  );
};

export const uploadProductsFromFileService = (file: File, storeId: string) => {
  const data = new FormData();
  data.append("excelFile", file);
  data.append(
    "type",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );

  const headers = {
    "Content-Type": `multipart/form-data`,
  };

  return apiManagementPrivate.post(
    "/api/v1/pre-load-product/upload-products-from-file/store-id/" + storeId,
    data,
    { headers }
  );
};

export const updateProductsListService = (id: string, storeId: string) => {
  return apiManagementPrivate.post(
    "/api/v1/pre-load-product/update/" + id + "?storeId=" + storeId,
    {}
  );
};

export const uploadLikesFileService = (eventId: string, file: File) => {
  const data = new FormData();
  data.append("file", file);
  data.append("type", "application/zip");

  const headers = {
    "Content-Type": `multipart/form-data`,
  };
  return apiManagementPrivate.post(
    "/api/v1/fira-broadcast-event-controller/upload-likes?id=" + eventId,
    data,
    {
      headers,
    }
  );
};
