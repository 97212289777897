import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCatalog = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <g>
      <path
        fill="#000"
        d="M18.643 15.8c-.423 0-.82-.273-1.035-.71l-1.607-3.236-1.605 3.236c-.218.44-.615.713-1.038.713a.964.964 0 01-.332-.057L9.6 14.556v5.403c0 .446.25.835.605.941l5.405 1.642c.255.076.522.076.774 0l5.41-1.642c.355-.11.605-.498.605-.94v-5.404l-3.425 1.187a.962.962 0 01-.332.057zm5.315-3.405l-1.288-3.12c-.077-.189-.245-.298-.417-.27L16 9.971l2.292 4.617c.095.192.285.283.462.222l4.948-1.715c.247-.088.367-.422.255-.701zM9.33 9.275l-1.287 3.12c-.115.279.007.613.252.698l4.947 1.715c.178.06.368-.03.463-.222L16 9.972l-6.255-.968c-.173-.024-.338.082-.415.27z"
      ></path>
    </g>
  </svg>
);

export default SvgCatalog;
