import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { BlockPicker } from "react-color";
import { useClickOutside } from "../../../utils/useClickOutside";
import styles from "./ColorInput.module.scss";

interface ColorInputProps {
  title: string;
  onChange?(color: string, type: string): void;
  noColor?: boolean;
  type?: string;
  subtitle?: string;
  color?: string;
}

const ColorInput = ({
  title,
  onChange,
  noColor,
  type,
  color,
  subtitle,
}: ColorInputProps) => {
  const [hasNoColor, setHasNoColor] = useState<boolean>(noColor || false);
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [elementCoordinates, setElementCoordinates] = useState({ x: 0, y: 0 });
  const [selectedColor, setSelectedColor] = useState<string>(
    color ? color.toUpperCase() : ""
  );
  const [firstRun, setFirstRun] = useState<boolean>(true);

  const handleChangeComplete = (color: any) => {
    setSelectedColor(color.hex);
    if (onChange && type) {
      onChange(color.hex, type);
    }
  };

  const handleColorPicker = (e: SyntheticEvent<HTMLElement>) => {
    setElementCoordinates({
      x: e.currentTarget.getBoundingClientRect().x,
      y: e.currentTarget.getBoundingClientRect().y,
    });

    setShowColorPicker(!showColorPicker);
  };

  const strHex = (color: string) => {
    if (color.includes("#") || color === "") {
      return color;
    } else {
      return `#${color}`;
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstRun(false);
    const color = e.target.value;
    if (color.length > 7) {
      return;
    } else if (/^[A-Fa-f0-9-#]*$/.test(color)) {
      setSelectedColor(strHex(color).toUpperCase());
    } else {
      return;
    }
  };

  const containerStyle: any = {
    position: "absolute",
    zIndex: 99,
    borderRadius: "6px",
    border: "1px solid #d0d0d0",
    top: elementCoordinates.y - 140,
    left: elementCoordinates.x + 100,
  };

  const domNode = useClickOutside(() => setShowColorPicker(false));

  useEffect(() => {
    if (selectedColor.charAt(0) !== "#" || selectedColor === "") {
      setSelectedColor("");
    }

    if (selectedColor !== "") {
      setHasNoColor(false);
    } else if (noColor && selectedColor === "") {
      setHasNoColor(true);
    }

    if (onChange && type) {
      onChange(selectedColor, type);
    }
  }, [selectedColor]);

  useEffect(() => {
    if (color && onChange) {
      onChange(selectedColor, type!);
    }
  }, []);

  return (
    <>
      {showColorPicker && (
        <div
          className={styles.ColorPicker}
          style={containerStyle}
          ref={domNode}
        >
          <BlockPicker
            color={selectedColor}
            onChangeComplete={handleChangeComplete}
            triangle="hide"
            styles={containerStyle}
          />
        </div>
      )}
      <div className={styles.ColorInputWrapper}>
        <label htmlFor="color">
          {title}{" "}
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        </label>
        <div className={styles.ColorInput}>
          <span>Color</span>
          {hasNoColor ? (
            <div
              className={`${styles.ColorInput__noColor}`}
              onClick={handleColorPicker}
            >
              <div className={styles.ColorInput__noColor__line}></div>
            </div>
          ) : (
            <div
              className={`${styles.ColorInput__selector} ${
                selectedColor === "#FFFFFF" || selectedColor === "#FFF"
                  ? styles.WhiteColor
                  : ""
              }`}
              style={{
                backgroundColor: selectedColor ? selectedColor : "#067DC7",
              }}
              onClick={handleColorPicker}
            ></div>
          )}

          <input
            type="text"
            value={`${selectedColor ? `${selectedColor}` : ""}`}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default ColorInput;
