import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPlay = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6.88 3.85 1.452.405C.992.113.4.466.4 1.055v6.891c0 .588.59.943 1.052.65L6.88 5.15a.719.719 0 0 0 .252-.274.803.803 0 0 0 0-.752.719.719 0 0 0-.252-.274Z"
      fill="#fff"
    />
  </svg>
);

export default SvgPlay;
