import * as React from "react";
import { SVGProps } from "react";

const CloseImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 1.5a.707.707 0 1 0-1-1l-6 6a.707.707 0 0 0 1 1l6-6Z"
      fill="#D0D0D0"
    />
    <path
      d="M.5 1.5a.707.707 0 1 1 1-1l6 6a.707.707 0 1 1-1 1l-6-6Z"
      fill="#D0D0D0"
    />
  </svg>
);

export default CloseImage;
