import { ChangeEvent, useState, useEffect, ReactNode } from "react";
import "./CreateStore.scss";
import Input from "../../Input/Input";
import { ArrowLeft } from "../../FiraIcons";
import Button, { ButtonStyle } from "../../Button/Button";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import InputImage from "../../InputImage/InputImage";
import {
  Country,
  CreateStoreForm,
  Currencies,
  Languages,
  RootState,
} from "../../../utils/types.d";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  postCreateStoresActionCreator,
  resetCreateStoreMessageActionCreator,
} from "../../../store/Brand/BrandActions";
import InputTextArea from "../../InputTextArea/InputTextArea";
import Select from "../../Select/Select";
import {
  getCountriesService,
  getCurrenciesService,
  getLanguagesService,
} from "../../../services/store";
import bigSuccess from "../../../assets/svg/big-success.svg";
import PopUpAlert, { PopUpAlertTypes } from "../../PopUpAlert/PopUpAlert";

let timerID: NodeJS.Timeout;

const selectTransform = (
  arr: Currencies[] | Languages[] | Country[]
): string[] => {
  return arr?.map((val) => val.name);
};
interface CreateStoreModalProps {
  isOpen: boolean;
  onClose(): void;
  onCreateStoreHandle?(): void;
  data?: ReactNode[];
}
const CreateStore = ({ isOpen = false, onClose }: CreateStoreModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formValidated, setFormValidated] = useState<boolean>(false);
  const { brand, authentication } = useSelector((state: RootState) => state);
  const [currencies, setCurrencies] = useState<string[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [storeLogo, setStoreLogo] = useState<File | undefined>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showAlertError, setShowAlertError] = useState<boolean>(false);

  const initialState: CreateStoreForm = {
    logo: "",
    name: "",
    description: "",
    language: "",
    currency: "",
    address: {
      address1: "",
      city: "",
      country: "",
      state: "",
      postCode: "",
    },
  };

  //Form
  const [form, setForm] = useState<CreateStoreForm>(initialState);

  const initSelects = async () => {
    try {
      const languageResponse = await getLanguagesService();
      setLanguages(selectTransform(languageResponse.data));
      const countryResponse = await getCountriesService();
      setCountries(selectTransform(countryResponse.data));
      const currencyResponse = await getCurrenciesService();
      setCurrencies(selectTransform(currencyResponse.data));
    } catch (error) {
      console.error("error retriving selects values");
    }
  };

  useEffect(() => {
    initSelects();
  }, []);

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    switch (name) {
      case "language":
        setForm({ ...form, language: value });
        break;
      case "currency":
        setForm({ ...form, currency: value });
        break;
      case "country":
        setForm({
          ...form,
          address: {
            ...form.address,
            country: value,
          },
        });
        break;
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let numberValue;
    switch (name) {
      case "name":
        setForm({ ...form, name: value });
        break;
      case "description":
        setForm({ ...form, description: value });
        break;
      case "state":
        setForm({
          ...form,
          address: {
            ...form.address,
            state: value,
          },
        });
        break;
      case "city":
        setForm({
          ...form,
          address: {
            ...form.address,
            city: value,
          },
        });
        break;
      case "address":
        setForm({
          ...form,
          address: {
            ...form.address,
            address1: value,
          },
        });
        break;
      case "postCode":
        numberValue = e.target.validity.valid
          ? e.target.value
          : form.address.postCode;
        setForm({
          ...form,
          address: {
            ...form.address,
            postCode: numberValue,
          },
        });
        break;
    }
  };

  const handleImage = (image: File) => {
    setStoreLogo(image);
  };

  // Submit event
  const handleCreateSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    authentication.sessionId &&
      dispatch(
        postCreateStoresActionCreator(authentication.sessionId, form, storeLogo)
      );
    //setShowAlert(true);
  };

  const handleCloseAlert = () => {
    if (showAlert) {
      clearTimeout(timerID);
      setShowAlertError(false);
    }
  };

  const showAlertSuccess = () => {
    setShowAlert(true);
    timerID = setTimeout(() => {
      setShowAlert(false);
      dispatch(resetCreateStoreMessageActionCreator());
      onClose();
    }, 3000);
  };

  const showMessageError = () => {
    setShowAlertError(true);
    timerID = setTimeout(() => {
      setShowAlertError(false);
      dispatch(resetCreateStoreMessageActionCreator());
    }, 8000);
  };

  //validate form
  useEffect(() => {
    if (
      form.name !== "" &&
      form.description !== "" &&
      form.currency.length !== 0 &&
      form.address.country !== "" &&
      form.address.address1 !== ""
    ) {
      setFormValidated(true);
    } else {
      setFormValidated(false);
    }
  }, [form]);

  useEffect(() => {
    if (brand.createSuccess && !brand.error && !showAlert) {
      showAlertSuccess();
    }
    if (brand.error) {
      showMessageError();
    }
  }, [brand]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalLayout>
      {showAlertError && (
        <MessageError
          errorMessage={brand.error?.message}
          clickHandler={handleCloseAlert}
        />
      )}
      {showAlert ? (
        <MessageSuccess
          bigSuccess={bigSuccess}
          storeName={brand.currentStore?.name}
        />
      ) : (
        <div className="CreateStoreWrapper">
          <div className="header h5">
            <div className="icon">
              <ArrowLeft onClick={onClose} />
            </div>
            <p> {t("store.edit_store_modal.title_create")}</p>
          </div>

          <form
            className="formCreateStoreContainer"
            onSubmit={handleCreateSubmit}
          >
            <div className="imageContainer">
              <InputImage preview={form.logo} imageResult={handleImage} />
            </div>
            <div className="scroll-view">
              <div className="row">
                <div className="formGroup">
                  <label htmlFor={"name"} className="lead-text">
                    {t("store.edit_store_modal.name")}
                  </label>
                  <Input
                    inputName={"name"}
                    inputId={"name"}
                    value={form.name}
                    type={"text"}
                    width={"309px"}
                    height={"30px"}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="formGroup">
                  <label htmlFor={"description"} className="lead-text">
                    {t("store.edit_store_modal.desc")}
                  </label>
                  <InputTextArea
                    inputName={"description"}
                    inputId={"description"}
                    value={form.description}
                    type={"textarea"}
                    width="309px"
                    height="67px"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row space">
                <div className="formGroup">
                  <label htmlFor={"language"} className="lead-text">
                    {t("store.edit_store_modal.lang")}
                  </label>
                  <Select
                    onChange={handleSelect}
                    data={languages}
                    name="language"
                    width={144}
                    height={30}
                    value={form.language}
                  />
                </div>
                <div className="formGroup">
                  <label htmlFor={"currency"} className="lead-text">
                    {t("store.edit_store_modal.currency")}
                  </label>
                  <Select
                    onChange={handleSelect}
                    data={currencies}
                    name="currency"
                    width={144}
                    height={30}
                    value={form.currency}
                  />
                </div>
              </div>

              <div className="row">
                <div className="formGroup">
                  <label htmlFor="country" className="lead-text">
                    País
                  </label>
                  <Select
                    onChange={handleSelect}
                    data={countries}
                    name="country"
                    width={309}
                    height={30}
                    value={form.address.country}
                  />
                </div>
              </div>
              <div className="row space">
                <div className="formGroup">
                  <label htmlFor={"state"} className="lead-text">
                    {t("store.edit_store_modal.state")}
                  </label>
                  <Input
                    inputName={"state"}
                    inputId={"state"}
                    value={form.address.state}
                    type={"text"}
                    width={"144px"}
                    height={"30px"}
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup">
                  <label htmlFor={"city"} className="lead-text">
                    {t("store.edit_store_modal.city")}
                  </label>
                  <Input
                    inputName={"city"}
                    inputId={"city"}
                    value={form.address.city}
                    type={"text"}
                    width={"144px"}
                    height={"30px"}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="formGroup">
                  <label htmlFor={"address"} className="lead-text">
                    {t("store.edit_store_modal.address")}
                  </label>
                  <Input
                    inputName={"address"}
                    inputId={"address"}
                    value={form.address.address1}
                    type={"text"}
                    width={"309px"}
                    height={"30px"}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="formGroup">
                  <label htmlFor="postCode" className="lead-text">
                    {t("store.edit_store_modal.postCode")}
                  </label>

                  <Input
                    inputName={"postCode"}
                    inputId={"postCode"}
                    value={form.address.postCode}
                    type={"text"}
                    width={"309px"}
                    height={"30px"}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="buttonGroup">
              <Button
                width="131px"
                height="42px"
                buttonStyle={ButtonStyle.transparent}
                clickHandler={onClose}
              >
                <div className="h6"> {t("store.edit_store_modal.cancel")}</div>
              </Button>
              <Button
                width="131px"
                height="42px"
                type={"submit"}
                isDisabled={!formValidated}
              >
                <div className="h6"> {t("store.edit_store_modal.save")}</div>
              </Button>
            </div>
          </form>
        </div>
      )}
    </ModalLayout>
  );
};

// Componente para mostrar mensaje exiitoso
interface MessageSuccessProps {
  bigSuccess: string;
  storeName: string | undefined;
}
const MessageSuccess = (props: MessageSuccessProps) => {
  const { t } = useTranslation();
  return (
    <div className="CreateStoreWrapperSuccess">
      <img className="ImagePreview" src={props.bigSuccess} alt="avatar" />
      <h5 className="Text-success">
        {t("store.edit_store_modal.alert_success_create").replace(
          "{name}",
          `${props.storeName}`
        )}
      </h5>
    </div>
  );
};

// componente para mostrar mensajes de error
export enum ErrorMessages {
  file_is_not_image = "file is not an image",
}
interface MessageErrorProps {
  errorMessage: string | undefined;
  clickHandler?(): void;
}
const MessageError = (props: MessageErrorProps) => {
  const { t } = useTranslation();

  switch (props.errorMessage) {
    case ErrorMessages.file_is_not_image:
      return (
        <PopUpAlert
          type={PopUpAlertTypes.error}
          title={t("store.messages.error.file_is_not_image")}
          width="465px"
          handleCloseIcon={props.clickHandler}
        />
      );
    default:
      return (
        <PopUpAlert
          type={PopUpAlertTypes.error}
          title={t("store.messages.error.default")}
          width="465px"
          handleCloseIcon={props.clickHandler}
        />
      );
  }
};

export default CreateStore;
