import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgFiraLiveLogo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M62.405 79.466H17.958C8.274 79.466.406 71.64.406 61.999V17.702C.406 8.06 8.274.235 17.958.235h44.447c9.685 0 17.552 7.825 17.552 17.467v44.276c0 9.663-7.846 17.488-17.552 17.488Z"
      fill="#010202"
    />
    <path
      d="M65.291 80H14.71C6.606 80 0 73.394 0 65.291V14.71C0 6.606 6.606 0 14.709 0H65.29C73.394 0 80 6.606 80 14.709V65.29C80 73.394 73.394 80 65.291 80ZM14.71 2.202C7.803 2.202 2.18 7.825 2.18 14.73v50.583c0 6.905 5.622 12.528 12.528 12.528H65.29c6.906 0 12.528-5.623 12.528-12.528V14.709c0-6.906-5.622-12.528-12.528-12.528H14.71v.021Z"
      fill="#FFDE07"
    />
    <path
      d="M16.505 31.834v3.27H21.7v1.69h-5.195v4.126h-2.01V30.143h7.868v1.668h-5.858v.021ZM24.05 30.144h2.01v10.775h-2.01V30.144ZM35.768 40.919l-2.202-3.164c-.086 0-.235.021-.406.021h-2.437v3.143h-2.01V30.144h4.425c.941 0 1.753.15 2.438.47.684.3 1.218.749 1.603 1.326.363.577.556 1.261.556 2.052 0 .813-.193 1.518-.599 2.095-.385.578-.962 1.027-1.71 1.304l2.48 3.55h-2.138v-.022Zm-.727-8.53c-.449-.364-1.112-.556-1.988-.556h-2.33v4.297h2.33c.876 0 1.54-.192 1.988-.556.449-.384.684-.898.684-1.603 0-.684-.235-1.197-.684-1.582ZM46.714 38.418h-5.388l-1.069 2.501h-2.052l4.853-10.775h1.967l4.853 10.775h-2.095l-1.07-2.501Zm-.663-1.561-2.03-4.703-2.01 4.703h4.04ZM16.974 51.287a4.665 4.665 0 0 1-1.796-1.732 4.888 4.888 0 0 1-.662-2.48c0-.919.213-1.731.662-2.48a4.513 4.513 0 0 1 1.796-1.731c.77-.428 1.625-.62 2.587-.62.94 0 1.796.214 2.566.62a4.664 4.664 0 0 1 1.795 1.732c.428.726.642 1.56.642 2.48 0 .919-.214 1.752-.642 2.48a4.665 4.665 0 0 1-1.796 1.731c-.77.428-1.624.62-2.565.62-.962.021-1.838-.192-2.587-.62Zm4.447-1.047c.556-.321.983-.749 1.304-1.305.32-.556.47-1.175.47-1.86 0-.684-.17-1.304-.47-1.86a3.471 3.471 0 0 0-1.304-1.304c-.556-.32-1.176-.47-1.881-.47-.684 0-1.326.15-1.882.47a3.529 3.529 0 0 0-1.325 1.304c-.32.556-.47 1.176-.47 1.86 0 .684.15 1.304.47 1.86.32.556.77.984 1.325 1.304.556.321 1.198.47 1.882.47a3.472 3.472 0 0 0 1.881-.47ZM34.76 42.352v9.47H33.65l-5.687-7.055v7.055h-1.347v-9.47h1.112l5.687 7.055v-7.055h1.347Z"
      fill="#fff"
    />
    <path
      d="M37.135 42.352h2.672v7.333h4.532V51.8h-7.183v-9.45h-.021ZM45.387 42.352h2.672v9.47h-2.672v-9.47ZM59.539 42.352l-4.062 9.47h-2.63l-4.04-9.47h2.886l2.565 6.157 2.63-6.157h2.65ZM67.685 49.749v2.073h-7.59v-9.47h7.42v2.073h-4.768v1.604h4.211v2.01h-4.211v1.731h4.938v-.021ZM67.13 19.477h-5.558a2.007 2.007 0 0 1-2.01-2.01v-5.559c0-1.111.898-2.01 2.01-2.01h5.559c1.111 0 2.01.899 2.01 2.01v5.559c0 1.111-.899 2.01-2.01 2.01Zm-5.536-8.53a.995.995 0 0 0-.984.983v5.558c0 .535.449.984.983.984h5.559a.995.995 0 0 0 .983-.984V11.93a.995.995 0 0 0-.983-.984h-5.559Z"
      fill="#FFDE07"
    />
  </svg>
);

export default SvgFiraLiveLogo;
