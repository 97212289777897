import type { ThunkDispatch } from "redux-thunk";
import type { AnyAction } from "redux";
import { AuthActions } from "../actionsContants";
import {
  changeUserPasswordService,
  sendRecoverCodeService,
  validateRecoveryCodeService,
} from "../../services/authentication";
import { handleError, RequestError } from "../../services/errorHandling";
import { ResetAlertActionType } from "../Register/RegisterActions";
import { LogoutActionType } from "../Auth/AuthActions";

interface SendRecoveryCodeActionType {
  type:
    | AuthActions.recoveryLoading
    | AuthActions.recoverySuccess
    | AuthActions.recoveryFailed;
  payload: string | RequestError | null;
}
export const sendRecoveryCodeActionCreator = (email: string) => {
  return async (
    dispatch: ThunkDispatch<
      SendRecoveryCodeActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: AuthActions.recoveryLoading });
      await sendRecoverCodeService(email);
      dispatch({ type: AuthActions.recoverySuccess, payload: email });
    } catch (error) {
      dispatch({
        type: AuthActions.recoveryFailed,
        payload: handleError(error),
      });
    }
  };
};

interface ValidateRecoveryCodeActionType {
  type:
    | AuthActions.validateCodeLoading
    | AuthActions.validateCodeSuccess
    | AuthActions.validateCodeFailed;
  payload: string | RequestError | null;
}
export const validateRecoveryCodeActionCreator = (
  recoveryCode: string,
  email: string
) => {
  return async (
    dispatch: ThunkDispatch<
      ValidateRecoveryCodeActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: AuthActions.validateCodeLoading });
      await validateRecoveryCodeService(recoveryCode, email);
      dispatch({
        type: AuthActions.validateCodeSuccess,
        payload: recoveryCode,
      });
    } catch (error) {
      dispatch({
        type: AuthActions.validateCodeFailed,
        payload: handleError(error),
      });
    }
  };
};

interface ChangeUserPasswordActionType {
  type:
    | AuthActions.changePasswordLoading
    | AuthActions.changePasswordSuccess
    | AuthActions.changePasswordFailed;
  payload: RequestError | null;
}
export const changeUserPasswordActionCreator = (
  newPassword: string,
  recoveryCode: string,
  email: string
) => {
  return async (
    dispatch: ThunkDispatch<
      ChangeUserPasswordActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: AuthActions.changePasswordLoading });
      await changeUserPasswordService(newPassword, recoveryCode, email);
      dispatch({ type: AuthActions.changePasswordSuccess });
    } catch (error) {
      dispatch({
        type: AuthActions.changePasswordFailed,
        payload: handleError(error),
      });
    }
  };
};

export type RecoveryActionType =
  | SendRecoveryCodeActionType
  | ValidateRecoveryCodeActionType
  | ChangeUserPasswordActionType
  | ResetAlertActionType
  | LogoutActionType;
