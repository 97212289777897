import { MouseEvent, MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleImageLoadError } from "../../utils/handleImageLink";
import { ImageProductObject } from "../../utils/types";
import CloseImage from "../FiraIcons/CloseImage";
import ItemDraggable from "../FiraIcons/ItemDraggable";
import SvgStar from "../FiraIcons/Star";
import TrashCan from "../FiraIcons/TrashCan";

import StyleSheet from "./ProductImage.module.scss";

interface Props {
  clickHandler(url: string): void;
  element: ImageProductObject;
}

/**
 * Description of Image
 * @prop {function} clickHandler - handler for the action of the Image
 * @prop {string} url - Url of the image to display
 */

const ProductImage = (props: Props) => {
  const { t } = useTranslation();
  const clickDelete = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    props.clickHandler(props.element.imageUrl);
  };

  return (
    <div
      className={
        props.element.selected
          ? StyleSheet["container-selected"]
          : StyleSheet.container
      }
    >
      <div className={StyleSheet.box}>
        <img
          className={props.element.isDelete ? StyleSheet["delete-select"] : ""}
          src={props.element.imageUrl}
          onError={handleImageLoadError}
        />
        <div
          className={`${StyleSheet["box-item"]} ${
            props.element.isDelete
              ? StyleSheet["delete-mode"]
              : StyleSheet["active-mode"]
          }`}
        >
          <button
            className={`${StyleSheet["item-select"]} ${StyleSheet["delete-button"]}`}
            onClick={clickDelete}
          >
            {props.element.isDelete ? (
              <CloseImage />
            ) : (
              <TrashCan color="#D0D0D0" />
            )}
          </button>

          <div className={StyleSheet["item-recovery"]}>
            {props.element.isDelete && (
              <button
                className={StyleSheet["recovery-button"]}
                onClick={clickDelete}
              >
                {t("views.products.detailProduct.form.retrieve")}
              </button>
            )}
          </div>
          {props.element.selected && (
            <div className={StyleSheet["item-star"]}>
              <SvgStar />
            </div>
          )}
          <div className={StyleSheet["item-draggable"]}>
            <ItemDraggable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductImage;
