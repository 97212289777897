import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgMute = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1.226 2.51h1.95L5.827.357a.252.252 0 0 1 .41.193V8.45a.247.247 0 0 1-.144.225.252.252 0 0 1-.266-.032L3.175 6.489H1.226a.503.503 0 0 1-.5-.497V3.008a.495.495 0 0 1 .5-.497ZM12 7l-1.843-2.586L11.88 2h-1.236L9.528 3.621 8.391 2h-1.3L8.83 4.457 7 7h1.314l1.172-1.736L10.679 7H12Z"
      fill="#fff"
    />
  </svg>
);

export default SvgMute;
