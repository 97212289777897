import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCalendar = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m19.41 5.769.002.75c2.755.215 4.574 2.092 4.577 4.97L24 19.917c.004 3.138-1.968 5.069-5.128 5.074l-7.72.01c-3.14.004-5.137-1.973-5.141-5.12L6 11.553c-.004-2.898 1.752-4.77 4.506-5.023v-.75a.746.746 0 0 1 .758-.77.747.747 0 0 1 .762.769v.7l5.865-.009v-.7A.747.747 0 0 1 18.65 5a.753.753 0 0 1 .76.769ZM7.522 11.862l14.949-.02v-.35c-.043-2.15-1.121-3.277-3.056-3.445v.77c0 .43-.334.77-.758.77a.752.752 0 0 1-.762-.768v-.81l-5.865.008v.81c0 .43-.324.77-.759.77a.752.752 0 0 1-.76-.769l-.002-.77c-1.925.193-2.99 1.325-2.987 3.493v.31Zm10.719 4.542v.011c.01.46.385.809.84.799a.823.823 0 0 0 .789-.852.83.83 0 0 0-.82-.797.828.828 0 0 0-.81.84Zm.815 4.488a.848.848 0 0 1-.821-.848.833.833 0 0 1 .809-.852h.01c.463 0 .84.379.84.849a.844.844 0 0 1-.838.851Zm-4.883-4.472c.02.46.396.819.85.799a.823.823 0 0 0 .78-.86.816.816 0 0 0-.821-.8c-.454.02-.81.402-.809.861Zm.854 4.427a.823.823 0 0 1-.85-.799c0-.46.354-.84.809-.86a.815.815 0 0 1 .82.797c.02.461-.335.841-.779.862Zm-4.922-4.392c.02.46.396.82.85.799.445-.02.8-.4.778-.86a.815.815 0 0 0-.82-.8.852.852 0 0 0-.808.861Zm.854 4.397a.822.822 0 0 1-.85-.799.853.853 0 0 1 .809-.86.815.815 0 0 1 .82.798c.021.46-.333.841-.779.861Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCalendar;
