import * as React from "react";
import { SVGProps } from "react";

const NextEventsPreview = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={221}
    height={102}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={221} height={102} rx={8} fill="#F7F8F9" />
    <path
      d="M27 21.133c0 .512.287.928.64.928h165.72c.353 0 .64-.416.64-.928s-.287-.928-.64-.928H27.64c-.353 0-.64.416-.64.928Z"
      fill="#D5DDE0"
    />
    <rect
      x={27.184}
      y={10}
      width={82.572}
      height={7.422}
      rx={1.5}
      fill="#D5DDE0"
    />
    <rect
      x={172.661}
      y={10}
      width={21.339}
      height={7.422}
      rx={1.5}
      fill="#D5DDE0"
    />
    <rect
      x={27.5}
      y={28.5}
      width={44.568}
      height={62.438}
      rx={1.5}
      stroke="#D5DDE0"
    />
    <rect
      x={31.074}
      y={32.968}
      width={38.314}
      height={21.337}
      rx={1.5}
      fill="#D5DDE0"
      stroke="#D5DDE0"
    />
    <rect
      x={31.074}
      y={58.454}
      width={38.314}
      height={2.574}
      rx={0.5}
      fill="#D5DDE0"
      stroke="#D5DDE0"
    />
    <rect
      x={31.074}
      y={65.454}
      width={38.314}
      height={4.361}
      rx={0.5}
      fill="#D5DDE0"
      stroke="#D5DDE0"
    />
    <rect
      x={31.021}
      y={73.442}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={31.021}
      y={76.123}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={31.021}
      y={78.803}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={31.021}
      y={81.483}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={31.021}
      y={84.163}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={87.5}
      y={28.5}
      width={44.568}
      height={62.438}
      rx={1.5}
      stroke="#D5DDE0"
    />
    <rect
      x={91.074}
      y={32.968}
      width={38.314}
      height={21.337}
      rx={1.5}
      fill="#D5DDE0"
      stroke="#D5DDE0"
    />
    <rect
      x={91.074}
      y={58.454}
      width={38.314}
      height={2.574}
      rx={0.5}
      fill="#D5DDE0"
      stroke="#D5DDE0"
    />
    <rect
      x={91.074}
      y={65.454}
      width={38.314}
      height={4.361}
      rx={0.5}
      fill="#D5DDE0"
      stroke="#D5DDE0"
    />
    <rect
      x={91.021}
      y={73.442}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={91.021}
      y={76.123}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={91.021}
      y={78.803}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={91.021}
      y={81.483}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={91.021}
      y={84.163}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={148.5}
      y={28.5}
      width={44.568}
      height={62.438}
      rx={1.5}
      stroke="#D5DDE0"
    />
    <rect
      x={152.074}
      y={32.968}
      width={38.314}
      height={21.337}
      rx={1.5}
      fill="#D5DDE0"
      stroke="#D5DDE0"
    />
    <rect
      x={152.074}
      y={58.454}
      width={38.314}
      height={2.574}
      rx={0.5}
      fill="#D5DDE0"
      stroke="#D5DDE0"
    />
    <rect
      x={152.074}
      y={65.454}
      width={38.314}
      height={4.361}
      rx={0.5}
      fill="#D5DDE0"
      stroke="#D5DDE0"
    />
    <rect
      x={152.021}
      y={73.442}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={152.021}
      y={76.123}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={152.021}
      y={78.803}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={152.021}
      y={81.483}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
    <rect
      x={152.021}
      y={84.163}
      width={38.42}
      height={0.893}
      rx={0.447}
      fill="#D5DDE0"
      stroke="#D5DDE0"
      strokeWidth={0.893}
    />
  </svg>
);

export default NextEventsPreview;
