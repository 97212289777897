import React, { ReactNode } from "react";

import StepIndicator from "./StepIndicator";
import { WizardStep, useWizard } from "../../providers/WizardProvider";
import Button, { ButtonStyle } from "../Button/Button";

import stylesheet from "./EventWizard.module.scss";
import { useTranslation } from "react-i18next";

const { Wrapper, Footer } = stylesheet;

interface Props {
  components: ReactNode[];
}
const EventWizard: React.FC<Props> = ({ components }) => {
  const { t } = useTranslation();
  const { step, steps, nextStep, prevStep, save, disableSave } = useWizard();

  return (
    <div className={Wrapper}>
      <StepIndicator steps={steps} currentStep={step} />
      {components[step]}
      <div className={Footer}>
        {step > 0 && (
          <Button buttonStyle={ButtonStyle.border} clickHandler={prevStep}>
            {t("buttons.back")}
          </Button>
        )}
        {step < steps.length - 1 && (
          <Button clickHandler={nextStep}>{t("buttons.next")}</Button>
        )}
        {step === steps.length - 1 && (
          <Button isDisabled={disableSave} clickHandler={save}>
            {t("buttons.save")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EventWizard;
