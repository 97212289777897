import { t } from "i18next";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  getDefaultBadWordsService,
  getStreamBlocksService,
} from "../../../services/events";
import { RootState } from "../../../utils/types";
import Button, { ButtonStyle } from "../../Button/Button";
import { CircleX, Likes } from "../../FiraIcons";
import Input, { InputStyles } from "../../Input/Input";
import RadioButton from "../../RadioButton/RadioButton";
import styles from "./EventAttachments.module.scss";

interface AttachmentsProps {
  handleAttachments(attachments: AttachmentType): void;
  wrongFiles(val: boolean): void;
  type: boolean;
}

export interface AttachmentType {
  profanityWords: string[];
  likeFile: File | null;
  useDefault: boolean;
}

export enum BadWordsType {
  default = "DEFAULT",
  custom = "CUSTOM",
}

const EventAttachments = ({
  handleAttachments,
  wrongFiles,
  type = true,
}: AttachmentsProps) => {
  const { brand, event } = useSelector((state: RootState) => state);
  const [attachments, setAttachments] = useState<AttachmentType>({
    profanityWords: [""],
    likeFile: null,
    useDefault: false,
  });

  //Likes
  const [likesDefault, setLikesDefault] = useState<boolean>(true);
  const [loadedFile, setLoadedFile] = useState<boolean>(false);
  const [likesFile, setLikesFile] = useState<File | null>(null);
  const inputRefLikes = useRef<HTMLInputElement>(null);

  //Blocked Words
  const [useDefaultWords, setUseDefaultWords] = useState<boolean>(type);
  const [wordsLoadedFile, setWordsLoadedFile] = useState<boolean>(false);
  const [wordsFile, setWordsFile] = useState<File | null>();
  const [defaultWords, setDefaultWords] = useState<string[]>([]);
  const [words, setWords] = useState<string[]>([]);
  const [wordList, setWordList] = useState<string[]>([]);
  const [newWord, setNewWord] = useState<string>("");
  const inputRefWords = useRef<HTMLInputElement>(null);

  //For File Error
  const [incorrectLikesFile, setIncorrectLikesFile] = useState<boolean>(false);
  const [incorrectWordsFile, setIncorrectWordsFile] = useState<boolean>(false);

  const [wordsFileName, setWordsFileName] = useState<string>("");
  const [likesFileName, setLikesFileName] = useState<string>("");

  //WORDS HANDLING

  const addNewWord = () => {
    setWordList([...wordList, newWord]);
    handleAttachments({
      ...attachments,
      profanityWords: [...wordList, newWord],
    });
    setNewWord("");
  };

  const deleteWord = (word: string) => {
    setWordList(wordList.filter((item) => item != word));
    handleAttachments({
      ...attachments,
      profanityWords: wordList.filter((item) => item != word),
    });
  };

  const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter") {
      if (newWord !== "") {
        addNewWord();
      } else {
        return;
      }
    }
  };

  const onWordsInputClickHandle = () => {
    inputRefWords.current?.click();
  };

  const onWordsInputChangeHandle = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.target.files) {
      setWordsFileName(e.target.files[0].name);
      if (e.target.files[0].type === "text/plain") {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target != null) {
            const text = e.target.result?.toString();
            let textArr = text?.split(",");
            textArr = textArr?.filter((e) => String(e).trim());
            if (textArr != undefined) {
              const arr = wordList;
              textArr.map((word) => {
                arr.push(word);
              });
              setWordList(arr);
              handleAttachments({
                ...attachments,
                profanityWords: arr,
              });
            }
          }
        };
        reader.readAsText(e.target.files![0]);

        setWordsFile(e.target.files[0]);
        setWordsLoadedFile(true);
        wrongFiles(false);
      } else {
        setIncorrectWordsFile(true);
        wrongFiles(true);
      }
    }
  };

  const resetWordsFile = () => {
    setWordsFile(null);
    setWordsLoadedFile(false);
    setWordsFileName("");
    setWordList([]);
    setIncorrectWordsFile(false);
    inputRefWords.current!.value = "";
    wrongFiles(true);
  };

  const onLikesInputClickHandle = () => {
    inputRefLikes.current?.click();
  };

  const onLikesInputChangeHandle = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      setLikesFileName(event.target.files[0].name);
      if (event.target.files[0].type === "application/zip") {
        setLikesFile(event.target.files[0]);
        handleAttachments({
          ...attachments,
          likeFile: event.target.files[0],
        });
        setLoadedFile(true);
        wrongFiles(false);
      } else {
        setIncorrectLikesFile(true);
        wrongFiles(true);
      }
    }
  };

  const resetLikesFile = () => {
    setLikesFile(null);
    setLoadedFile(false);
    setLikesFileName("");
    setIncorrectLikesFile(false);
    inputRefLikes.current!.value = "";
  };

  const handleDefaultCheck = (val: boolean) => {
    setUseDefaultWords(val);
    wrongFiles(false);
    handleAttachments({
      ...attachments,
      useDefault: val,
    });
  };

  const handleLikesDefault = (val: boolean) => {
    setLikesDefault(true);
    wrongFiles(false);
  };

  //--Blocked Words
  const getBadWordsDefault = () => {
    if (event.currentEvent?.id != undefined) {
      getDefaultBadWordsService(
        event.currentEvent?.id,
        BadWordsType.default
      ).then((val) => {
        setDefaultWords(val.data.wordList);
      });
    }
  };

  const getBadWordsCustom = () => {
    if (brand.currentStore?.id != undefined) {
      getDefaultBadWordsService(
        brand.currentStore.id,
        BadWordsType.custom
      ).then((val) => {
        if (val.data != undefined || val.data != []) {
          setWordList(val.data);
          handleAttachments({
            ...attachments,
            profanityWords: val.data,
          });
        } else {
          setWordList([]);
        }
      });
    }
  };

  useEffect(() => {
    if (useDefaultWords) {
      getBadWordsDefault();
      handleAttachments({
        ...attachments,
        useDefault: true,
      });
    } else {
      getBadWordsCustom();
      handleAttachments({
        ...attachments,
        useDefault: false,
      });
    }
  }, []);

  return (
    <>
      <div className={styles.AttachmentsWrapper}>
        <div className={`${styles.TitleContainer} ${styles["alert-text-12"]}`}>
          <div className={styles.h5}>
            {t("event_creation.params.attachmentsTitle")}
          </div>
          <div className={styles.AttachmentsMessage}>
            {t("event_creation.params.attachmentsMessage")}
          </div>
        </div>
        <div className={styles.AttachmentsSections}>
          <div className={styles.AttachmentsColumns}>
            {/* LIKES */}
            <div className={styles.AttachmentsColumn}>
              <div className={`${styles["lead-text"]} ${styles.ColumnTitle}`}>
                {t("event_creation.params.likes")}
              </div>
              <div className={styles.Row}>
                <div className={styles.Icon}>
                  <Likes />
                </div>
                <div className={styles.RowRadio}>
                  <RadioButton
                    isActive={likesDefault}
                    clickHandler={() => handleLikesDefault(true)}
                  />
                </div>
              </div>
              <div className={styles.Row}>
                <div className={styles["alert-text-12"]}>
                  {t("event_creation.params.custom")}
                </div>
                <div className={styles.RowRadio}>
                  <RadioButton
                    isActive={!likesDefault}
                    clickHandler={() => setLikesDefault(false)}
                  />
                </div>
              </div>
              {!likesDefault && (
                <>
                  <input
                    type="file"
                    accept=".zip"
                    ref={inputRefLikes}
                    style={{ display: "none" }}
                    onChange={onLikesInputChangeHandle}
                  />
                  {!loadedFile && likesFileName === "" && (
                    <Button
                      buttonStyle={ButtonStyle.load}
                      width={"123px"}
                      height={"22px"}
                      clickHandler={onLikesInputClickHandle}
                    >
                      <span className={styles["lead-10-text"]}>
                        {t("event_creation.params.load")} .zip
                      </span>
                    </Button>
                  )}
                  {likesFileName && (
                    <>
                      <div className={styles.FileName}>
                        <div className={styles.WordFlex}>
                          <div
                            className={styles["tiny-text-8"]}
                            style={{
                              width: "100px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {likesFileName}
                          </div>
                          <div
                            className={styles.WordIcon}
                            onClick={() => resetLikesFile()}
                          >
                            <CircleX
                              color={incorrectLikesFile ? "#F16063" : ""}
                            />
                          </div>
                        </div>
                      </div>
                      {incorrectLikesFile && (
                        <small className={styles.FileWarning}>
                          {t("event_creation.incorrect_file_loaded")} .zip
                        </small>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            {/* BLOCKED WORDS */}
            <div className={styles.AttachmentsColumn}>
              <div className={`${styles["lead-text"]} ${styles.ColumnTitle}`}>
                {t("event_creation.params.words_to_moderate")}
                {/* TO DO: ADD TOOLTIP */}
              </div>
              <div className={styles.Row}>
                <div className={styles["alert-text-12"]}>
                  {t("event_creation.params.default")}
                </div>
                <div className={styles.RowRadio}>
                  <RadioButton
                    isActive={useDefaultWords}
                    clickHandler={() => {
                      handleDefaultCheck(true);
                      getBadWordsDefault();
                    }}
                  />
                </div>
              </div>
              <div className={styles.Row}>
                <div className={styles["alert-text-12"]}>
                  {t("event_creation.params.custom")}
                </div>
                <div className={styles.RowRadio}>
                  <RadioButton
                    isActive={!useDefaultWords}
                    clickHandler={() => {
                      handleDefaultCheck(false);
                      getBadWordsCustom();
                    }}
                  />
                </div>
              </div>
              {!useDefaultWords && (
                <>
                  <input
                    accept=".txt"
                    type="file"
                    ref={inputRefWords}
                    style={{ display: "none" }}
                    onChange={onWordsInputChangeHandle}
                  />
                  {!wordsLoadedFile && wordsFileName === "" && (
                    <Button
                      buttonStyle={ButtonStyle.load}
                      width={"123px"}
                      height={"22px"}
                      clickHandler={onWordsInputClickHandle}
                    >
                      <span className={styles["lead-10-text"]}>
                        {t("event_creation.params.load")} .txt
                      </span>
                    </Button>
                  )}
                  {wordsFileName && (
                    <>
                      <div className={styles.FileName}>
                        <div className={styles.WordFlex}>
                          <div
                            className={styles["tiny-text-8"]}
                            style={{
                              width: "100px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {wordsFileName}
                          </div>
                          <div
                            className={styles.WordIcon}
                            onClick={() => resetWordsFile()}
                          >
                            <CircleX
                              color={incorrectWordsFile ? "#F16063" : ""}
                            />
                          </div>
                        </div>
                      </div>
                      {incorrectWordsFile && (
                        <small className={styles.FileWarning}>
                          {t("event_creation.incorrect_file_loaded")} .txt
                        </small>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={styles.ProfanityWordsContainer}>
            <div className={styles["lead-text"]}>
              {t("event_creation.params.words_to_moderate")}
            </div>
            <div className={styles.WordsContainer}>
              <div className={styles.InputWord}>
                <Input
                  inputStyle={InputStyles.url}
                  height="22px"
                  width="146px"
                  onKeyDown={keyDownHandler}
                  value={newWord}
                  onChange={(e) => setNewWord(e.target.value)}
                  placeholder={t("event_creation.params.add_word_placeholder")}
                />
              </div>

              {!useDefaultWords &&
                wordList !== [] &&
                wordList.map((word, i) => (
                  <div className={styles.Word} key={i}>
                    <div className={styles.WordFlex}>
                      <div className={styles["tiny-text-8"]}>{word}</div>
                      <div
                        className={styles.WordIcon}
                        onClick={() => deleteWord(word)}
                      >
                        <CircleX />
                      </div>
                    </div>
                  </div>
                ))}
              {useDefaultWords &&
                defaultWords.map((word, i) => (
                  <div className={styles.Word} key={i}>
                    <div className={styles.WordFlex}>
                      <div className={styles["tiny-text-8"]}>{word}</div>
                      <div
                        className={styles.WordIcon}
                        onClick={() => deleteWord(word)}
                      >
                        <CircleX />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventAttachments;
