import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgEdit = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.73 22.974 9.275-11.994c.504-.647.683-1.394.515-2.156-.145-.692-.571-1.35-1.21-1.85l-1.556-1.236c-1.356-1.078-3.036-.965-4 .272l-1.04 1.352a.4.4 0 0 0 .066.554l2.689 2.156c.179.17.313.398.347.67a.997.997 0 0 1-.863 1.1.894.894 0 0 1-.683-.192l-2.766-2.202a.33.33 0 0 0-.449.057l-6.575 8.51a2.251 2.251 0 0 0-.425 1.895l.84 3.642a.422.422 0 0 0 .414.33l3.697-.046a2.221 2.221 0 0 0 1.725-.862Zm5.177-1.134h6.027c.588 0 1.066.484 1.066 1.08 0 .597-.478 1.08-1.066 1.08h-6.027a1.074 1.074 0 0 1-1.066-1.08c0-.596.478-1.08 1.066-1.08Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEdit;
