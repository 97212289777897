import React from "react";
import {
  ConfigurationTemplate,
  LoginFormResponse,
  RootState,
} from "../utils/types";
import {
  getGraphicDefaultConfig,
  setGraphicDefaultConfig,
} from "../services/settings";
import { AlertType, usePopupAlert } from "./AlertProvider";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export enum WizardStep {
  INFO = "INFO",
  PRODUCT = "PRODUCT",
  COLOR = "COLOR",
  BUTTON = "BUTTON",
  ORIENTATION = "ORIENTATION",
  LOGIN = "LOGIN",
}

interface WizardContextType {
  step: number;
  steps: WizardStep[];
  form: { [key: string]: string };
  loginForm: LoginFormResponse[];
  loginActive: boolean;
  disableSave: boolean;
  nextStep(): void;
  prevStep(): void;
  setFormKey(key: string, value: string): void;
  setLoginInput(input: string, type: string, required: boolean): void;
  updateLoginInputs(inputs: LoginFormResponse[]): void;
  removeLoginInput(index: number): void;
  toggleLogin(): void;
  save(): void;
  toggleSave(state: boolean): void;
}
const WizardContext = React.createContext<WizardContextType | undefined>(
  undefined
);

interface Props {
  steps: WizardStep[];
}
export const WizardProvider: React.FC<Props> = ({ steps, children }) => {
  const { authentication } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const [step, setStep] = React.useState(0);
  const [form, setForm] = React.useState<{ [key: string]: string }>({});
  const [loginActive, setLoginActive] = React.useState(false);
  const [loginForm, setLoginForm] = React.useState<LoginFormResponse[]>([
    { fieldLabel: "user", fieldType: "text", isRequired: true, order: 0 },
  ]);
  const [disableSave, setDisableSave] = React.useState(false);
  const showAlert = usePopupAlert();

  const nextStep = () => {
    setStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const prevStep = () => {
    setStep((prev) => Math.max(prev - 1, 0));
  };

  const save = async () => {
    const configuration: ConfigurationTemplate = {
      mainColor: form.mainColor,
      buyButtonText: form.buyButtonText,
      enableLogin: loginActive,
      loginForm,
      disclaimerUrl: form.disclaimerUrl,
      resolution: form.resolution,
      likesUrls: [],
    };
    try {
      await setGraphicDefaultConfig(
        authentication.user?.businessClientId || "",
        configuration
      );
      showAlert(AlertType.success, t("alerts.success"));
    } catch (error) {
      // TODO: show error alert
    }
  };

  const setFormKey = (key: string, value: string) => {
    setForm({ ...form, [key]: value });
  };

  const setLoginInput = (
    fieldLabel: string,
    fieldType: string,
    isRequired: boolean
  ) => {
    setLoginForm([
      ...loginForm,
      { fieldLabel, fieldType, isRequired, order: loginForm.length },
    ]);
  };

  const updateLoginInputs = (inputs: LoginFormResponse[]) => {
    setLoginForm([...inputs]);
  };

  const removeLoginInput = (index: number) => {
    setLoginForm(loginForm.filter((_, i) => index !== i));
  };

  const toggleLogin = () => {
    setLoginActive(!loginActive);
  };

  const getDefaultConfig = async () => {
    try {
      const response = await getGraphicDefaultConfig(
        authentication.user?.businessClientId || ""
      );
      if (response.configurationTemplate) {
        const {
          mainColor,
          loginForm,
          resolution,
          buyButtonText,
          disclaimerUrl,
          enableLogin,
        } = response.configurationTemplate;
        setLoginActive(enableLogin);
        setForm({
          ...form,
          mainColor,
          resolution,
          buyButtonText,
          disclaimerUrl,
        });
        setLoginForm(
          loginForm.map((input, index) => {
            if (index === 0) {
              return { ...input, fieldLabel: "user" };
            }
            return input;
          })
        );
      }
    } catch (error) {
      // TODO: show error alert
    }
  };

  const toggleSave = (state: boolean) => {
    setDisableSave(state);
  };

  React.useEffect(() => {
    getDefaultConfig();
  }, []);

  return (
    <WizardContext.Provider
      value={{
        step,
        steps,
        form,
        loginForm,
        loginActive,
        disableSave,
        nextStep,
        prevStep,
        setFormKey,
        setLoginInput,
        updateLoginInputs,
        removeLoginInput,
        toggleLogin,
        save,
        toggleSave,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};

export const useWizard = () => {
  const context = React.useContext(WizardContext);

  if (context === undefined) {
    throw new Error("useWizard must be use within an WizardProvider");
  }

  return context;
};
