import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgArrowUp = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.69 19.171c-.62.641-1.624.641-2.266.022-.32-.32-.47-.727-.47-1.154 0-.406.15-.812.47-1.111L13.84 9.47a1.601 1.601 0 0 1 2.265 0l7.415 7.458c.641.62.641 1.624 0 2.265-.62.62-1.645.62-2.265-.022l-6.282-6.303L8.69 19.17Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgArrowUp;
