import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSimpleX = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 5 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4.977 1.084 4.493.6 2.577 2.517.66.6l-.483.484L2.093 3 .177 4.917.66 5.4l1.917-1.916L4.493 5.4l.484-.483L3.06 3l1.917-1.916Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSimpleX;
