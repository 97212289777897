import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { handleError, RequestError } from "../../services/errorHandling";
import {
  getFiraWebConfigurationService,
  getEventService,
  getStoreEventsService,
  getStreamBlocksService,
  postCreateEventsService,
  putCancelEventService,
  postFiraWebConfigurationService,
  generateCredentialsService,
  startBroadcastService,
  finishBroadcastService,
  getEventProducts,
  getActiveEventProducts,
  addEventProductsOnStreaming,
  addEventProducts,
  deleteEventProduct,
  sendPopUp,
  deletePopup,
  editFiraWebConfigurationService,
  getInactiveEventProducts,
  /*getEventQueues,*/
  updateCredentialsService,
  getEventCategories,
  editEventGeneralDetails,
  createMultipleEventsService,
  getPrevWebConfigurationsService,
  editEventGeneralDetailsService,
  createMassiveEventsService,
} from "../../services/events";
import { uploadLikesFileService } from "../../services/upload";
import reactiveProducts from "../../utils/reactiveProducts";
import {
  FiraConfiguration,
  FiraEvent,
  FiraEventForm,
  streamBlock,
  FiraEventNew,
  UpdateEventResponse,
  EventSetUpConfiguration,
  FiraCredentials,
  StartEventResponse,
  ResponseSimpleProduct,
  AddEventProductForm,
  AddEventProductPayload,
  ResponseSendPopUp,
  ResponseDeletePopUp,
  SendPopUpForm,
  EditEventResponse,
  ResponseQueues,
  SuscriberCredentialsDto,
  Category,
  EventGeneralDetailsType,
  FiraMultiEventForm,
  OwnerEventCreateResponse,
  TemplatesResponse,
} from "../../utils/types";
import { EventActions, MultiEventActions } from "../actionsContants";
import { LogoutActionType } from "../Auth/AuthActions";
import { EventType, StreamProvider } from "../../utils/enums";
import { FiraInputSearchType } from "../../components/InputSearch/InputSearch";

enum EventStatus {
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
  DONE = "DONE",
}

interface GetStoreEventsActionType {
  type:
    | EventActions.getStoreEventsLoading
    | EventActions.getStoreEventsSuccess
    | EventActions.getStoreEventsFailed;
  payload?: FiraEvent[] | RequestError;
}
export const getStoreEventsActionCreator = (storeId: string) => {
  return async (
    dispatch: ThunkDispatch<
      GetStoreEventsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.getStoreEventsLoading });
      const response = await getStoreEventsService(storeId);

      dispatch({
        type: EventActions.getStoreEventsSuccess,
        payload: response.data as FiraEvent,
      });
    } catch (error) {
      dispatch({
        type: EventActions.getStoreEventsFailed,
        payload: handleError(error),
      });
    }
  };
};

interface GetEventActionType {
  type:
    | EventActions.getEventLoading
    | EventActions.getEventSuccess
    | EventActions.getEventFailed;
  payload?: FiraEvent | RequestError;
}
export const getEventActionCreator = (eventId: string) => {
  return async (
    dispatch: ThunkDispatch<
      GetEventActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.getEventLoading });
      const response = await getEventService(eventId);
      dispatch({
        type: EventActions.getEventSuccess,
        payload: response.data as FiraEvent,
      });
    } catch (error) {
      dispatch({
        type: EventActions.getEventFailed,
        payload: handleError(error),
      });
    }
  };
};

export const postCreateEventActionCreator = (
  event: FiraEventForm,
  eventType: EventType = EventType.broadcast
) => {
  return async (
    dispatch: ThunkDispatch<EventActions, Record<string, unknown>, AnyAction>
  ) => {
    try {
      dispatch({
        type: EventActions.createEventLoading,
      });
      const response = await postCreateEventsService(event, eventType);
      const evento: FiraEventNew = {
        id: response.data.id as string,
        eventName: event.eventName,
        startDate: new Date(event.eventDateTimeRange.startDate),
        endDate: new Date(event.eventDateTimeRange.endDate),
        storeId: event.storeId,
        status: EventStatus.SCHEDULED,
      };
      dispatch({
        type: EventActions.createEventSuccess,
        payload: evento,
      });
    } catch (error) {
      dispatch({
        type: EventActions.createEventFailed,
        payload: handleError(error),
      });
    }
  };
};

interface PutCancelEventActionType {
  type:
    | EventActions.putCancelEventSuccess
    | EventActions.putCancelEventLoading
    | EventActions.putCancelEventFailed;
  payload?: string | RequestError;
}
export const putCancelEventsActionCreator = (eventId: string) => {
  return async (
    dispatch: ThunkDispatch<
      PutCancelEventActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.putCancelEventLoading });
      const response = await putCancelEventService(eventId);
      dispatch({
        type: EventActions.putCancelEventSuccess,
        payload: response.data.id as string,
      });
    } catch (error) {
      dispatch({
        type: EventActions.putCancelEventFailed,
        payload: handleError(error),
      });
    }
  };
};
interface ResetEventMessageActionType {
  type: EventActions.resetEventMessage;
}
export const resetEventMessageActionCreator =
  (): ResetEventMessageActionType => {
    return { type: EventActions.resetEventMessage };
  };

interface GetFiraWebConfigurationActionType {
  type:
    | EventActions.getFiraWebConfigurationLoading
    | EventActions.getFiraWebConfigurationSuccess
    | EventActions.getFiraWebConfigurationFailed;
  payload?: FiraConfiguration[] | RequestError;
}

export const getFiraWebConfigurationActionCreator = (storeId: string) => {
  return async (
    dispatch: ThunkDispatch<EventActions, Record<string, unknown>, AnyAction>
  ) => {
    try {
      dispatch({
        type: EventActions.getFiraWebConfigurationLoading,
      });
      const response = await getFiraWebConfigurationService(storeId);
      dispatch({
        type: EventActions.getFiraWebConfigurationSuccess,
        payload: response.data as FiraConfiguration[],
      });
    } catch (error) {
      dispatch({
        type: EventActions.getFiraWebConfigurationFailed,
        payload: handleError(error),
      });
    }
  };
};

interface GetStreamBlocksActionTypes {
  type:
    | EventActions.getStreamBlocksLoading
    | EventActions.getStreamBlocksFailed
    | EventActions.getStreamBlocksSuccess;
  payload?: streamBlock[] | RequestError;
}

export const getStreamBlocksActionCreator = () => {
  return async (
    dispatch: ThunkDispatch<EventActions, Record<string, unknown>, AnyAction>
  ) => {
    try {
      dispatch({
        type: EventActions.getStreamBlocksLoading,
      });
      const response = await getStreamBlocksService();
      dispatch({
        type: EventActions.getStreamBlocksSuccess,
        payload: response.data as streamBlock,
      });
    } catch (error) {
      dispatch({
        type: EventActions.getStreamBlocksFailed,
        payload: handleError(error),
      });
    }
  };
};

interface StoresListEventActionType {
  type: EventActions.setStoresListEvent;
  payload: FiraInputSearchType[];
}

export const setStoresListEventActionCreator = (
  storesList: FiraInputSearchType[]
): StoresListEventActionType => {
  return { type: EventActions.setStoresListEvent, payload: storesList };
};

interface CurrentEventActionType {
  type: EventActions.setCurrentEvent;
  payload: FiraEvent;
}

export const setCurrentEventActionCreator = (
  event: FiraEvent
): CurrentEventActionType => {
  return { type: EventActions.setCurrentEvent, payload: event };
};

interface ResetCurrentEventActionType {
  type: EventActions.resetCurrentEvent;
}
export const resetCurrentEventActionCreator =
  (): ResetCurrentEventActionType => {
    return { type: EventActions.resetCurrentEvent };
  };

interface UpdateEventActionType {
  type:
    | EventActions.updateEventLoading
    | EventActions.updateEventSuccess
    | EventActions.updateEventFailed;
  payload?: UpdateEventResponse | RequestError;
}

//First Set Up of the Event
export const UpdateEventActionCreator = (
  body: EventSetUpConfiguration,
  data: EventGeneralDetailsType,
  teaser?: File,
  preview?: File,
  eventId?: string,
  file?: File | null
) => {
  return async (
    dispatch: ThunkDispatch<EventActions, Record<string, unknown>, AnyAction>
  ) => {
    try {
      dispatch({
        type: EventActions.updateEventLoading,
      });

      const edit = await editEventGeneralDetails(data, teaser, preview);
      const response = await postFiraWebConfigurationService(body);

      if (file != null && eventId) {
        uploadLikesFileService(eventId, file);
      }

      dispatch({
        type: EventActions.updateEventSuccess,
        payload: response.data as UpdateEventResponse,
      });
    } catch (error) {
      dispatch({
        type: EventActions.updateEventFailed,
        payload: handleError(error),
      });
    }
  };
};

interface ResetUpdateEventActionType {
  type: EventActions.updateEventReset;
}

export const resetUpdateEventActionCreator = (): ResetUpdateEventActionType => {
  return { type: EventActions.updateEventReset };
};

interface GenerateCredentialsActionType {
  type:
    | EventActions.generateCredentialsLoading
    | EventActions.generateCredentialsSuccess
    | EventActions.generateCredentialsFailed;
  payload: FiraCredentials | RequestError;
}
export const generateCredentialsActionCreator = (
  eventId: string,
  provider?: string
) => {
  return async (
    dispatch: ThunkDispatch<
      GenerateCredentialsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.generateCredentialsLoading });

      const response = await generateCredentialsService(eventId, provider);
      // const {publisherCredentialsDto,suscriberCredentialsDto } = response.data
      // const responseLivedata = await updateCredentialsService(eventId);
      // let credentials ={
      //   publisherCredentialsDto: response.data;
      //   suscriberCredentialsDto: SuscriberCredentialsDto;
      // }

      dispatch({
        type: EventActions.generateCredentialsSuccess,
        payload: response.data as FiraCredentials,
      });
    } catch (error) {
      dispatch({
        type: EventActions.generateCredentialsFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateCredentialsActionType {
  type:
    | EventActions.updateCredentialsLoading
    | EventActions.updateCredentialsSuccess
    | EventActions.updateCredentialsFailed;
  payload: SuscriberCredentialsDto | RequestError;
}
export const updateCredentialsActionCreator = (eventId: string) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateCredentialsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.updateCredentialsLoading });
      const response = await updateCredentialsService(eventId);
      const { suscriberCredentialsDto } = response.data;
      dispatch({
        type: EventActions.updateCredentialsSuccess,
        payload: suscriberCredentialsDto,
      });
    } catch (error) {
      dispatch({
        type: EventActions.updateCredentialsFailed,
        payload: handleError(error),
      });
    }
  };
};

interface PostCreateEventActionType {
  type:
    | EventActions.createEventSuccess
    | EventActions.createEventLoading
    | EventActions.createEventFailed;
  payload?: FiraEvent | RequestError;
}

interface StartEventActionType {
  type:
    | EventActions.startEventLoading
    | EventActions.startEventSuccess
    | EventActions.startEventFailed;
  payload: StartEventResponse | RequestError;
}
export const startEventActionCreator = (eventId: string) => {
  return async (
    dispatch: ThunkDispatch<
      StartEventActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.startEventLoading });
      const response = await startBroadcastService(eventId);
      dispatch({
        type: EventActions.startEventSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: EventActions.startEventFailed,
        payload: handleError(error),
      });
    }
  };
};

interface FinishEventActionType {
  type:
    | EventActions.finishEventLoading
    | EventActions.finishEventSuccess
    | EventActions.finishEventFailed;
  payload: string | RequestError;
}
export const finishEventActionCreator = (eventId: string) => {
  return async (
    dispatch: ThunkDispatch<
      FinishEventActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.finishEventLoading });
      const response = await finishBroadcastService(eventId);
      dispatch({
        type: EventActions.finishEventSuccess,
        payload: EventStatus.DONE,
      });
    } catch (error) {
      dispatch({
        type: EventActions.finishEventFailed,
        payload: handleError(error),
      });
    }
  };
};

export interface CleanMessageActionType {
  type: EventActions.cleanMessage;
}

export const cleanMessageActionCreator = (): CleanMessageActionType => {
  return { type: EventActions.cleanMessage };
};

export interface GetCurrentEventProductsActionType {
  type:
    | EventActions.getCurrentEventProductsLoading
    | EventActions.getCurrentEventProductsSuccess
    | EventActions.getCurrentEventProductsFailed;
  payload: ResponseSimpleProduct[] | RequestError;
}

export const GetCurrentEventProductsActionCreator = (
  eventId: string,
  eventStatus: EventStatus
) => {
  return async (
    dispatch: ThunkDispatch<
      GetCurrentEventProductsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.getCurrentEventProductsLoading });
      let response: AxiosResponse;
      if (eventStatus === EventStatus.STARTED) {
        response = await getActiveEventProducts(eventId);
      } else {
        response = await getEventProducts(eventId);
      }

      dispatch({
        type: EventActions.getCurrentEventProductsSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: EventActions.getCurrentEventProductsFailed,
        payload: handleError(error),
      });
    }
  };
};

export interface AddCurrentEventProductsActionType {
  type:
    | EventActions.addCurrentEventProductsLoading
    | EventActions.addCurrentEventProductsSuccess
    | EventActions.addCurrentEventProductsFailed;
  payload: AddEventProductPayload | RequestError;
}

export const AddCurrentEventProductsActionCreator = (
  body: AddEventProductForm,
  eventStatus: EventStatus,
  products: ResponseSimpleProduct[]
) => {
  return async (
    dispatch: ThunkDispatch<
      AddCurrentEventProductsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.addCurrentEventProductsLoading });
      let response: AxiosResponse;

      if (eventStatus === EventStatus.STARTED) {
        response = await addEventProductsOnStreaming(body);
      } else {
        response = await addEventProducts(body);
      }

      const newResponse = {
        products: products as ResponseSimpleProduct[],
        response: response.data,
      };
      dispatch({
        type: EventActions.addCurrentEventProductsSuccess,
        payload: newResponse,
      });
    } catch (error) {
      dispatch({
        type: EventActions.addCurrentEventProductsFailed,
        payload: handleError(error),
      });
    }
  };
};

export interface ReactiveCurrentEventProductsActionType {
  type:
    | EventActions.reactiveCurrentEventProductsLoading
    | EventActions.reactiveCurrentEventProductsSuccess
    | EventActions.reactiveCurrentEventProductsFailed;
  payload: AddEventProductPayload | RequestError;
}

export const ReactiveCurrentEventProductsActionCreator = (
  body: AddEventProductForm
) => {
  return async (
    dispatch: ThunkDispatch<
      AddCurrentEventProductsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.reactiveCurrentEventProductsLoading });

      const Response = await reactiveProducts(body);
      dispatch({
        type: EventActions.reactiveCurrentEventProductsSuccess,
        payload: Response,
      });
    } catch (error) {
      dispatch({
        type: EventActions.reactiveCurrentEventProductsFailed,
        payload: handleError(error),
      });
    }
  };
};

export interface DeleteCurrentEventProductActionType {
  type:
    | EventActions.deleteCurrentEventProductsLoading
    | EventActions.deleteCurrentEventProductsSuccess
    | EventActions.deleteCurrentEventProductsFailed;
  payload: string | RequestError;
}

export const DeleteCurrentEventProductActionCreator = (
  eventId: string,
  eventProductId: string
) => {
  return async (
    dispatch: ThunkDispatch<
      DeleteCurrentEventProductActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.deleteCurrentEventProductsLoading });
      const response = await deleteEventProduct(eventId, eventProductId);
      dispatch({
        type: EventActions.deleteCurrentEventProductsSuccess,
        payload: eventProductId,
      });
    } catch (error) {
      dispatch({
        type: EventActions.deleteCurrentEventProductsFailed,
        payload: handleError(error),
      });
    }
  };
};

export interface ResponseSendPopupAction {
  response: ResponseSendPopUp;
  product: ResponseSimpleProduct;
}

export interface SendPopUpActionType {
  type:
    | EventActions.sendPopUpLoading
    | EventActions.sendPopUpSuccess
    | EventActions.sendPopUpFailed;
  payload: ResponseSendPopupAction | RequestError;
}

export const SendPopUpActionCreator = (
  body: SendPopUpForm,
  product: ResponseSimpleProduct
) => {
  return async (
    dispatch: ThunkDispatch<
      SendPopUpActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.sendPopUpLoading });
      const response = await sendPopUp(body);
      const newResponse = {
        response,
        product,
      };
      dispatch({
        type: EventActions.sendPopUpSuccess,
        payload: newResponse,
      });
    } catch (error) {
      dispatch({
        type: EventActions.sendPopUpFailed,
        payload: handleError(error),
      });
    }
  };
};

export interface ResponseDeletePopupAction {
  response: ResponseDeletePopUp;
  product: ResponseSimpleProduct;
}

export interface DeletePopUpActionType {
  type:
    | EventActions.deletePopUpLoading
    | EventActions.deletePopUpSuccess
    | EventActions.deletePopUpFailed;
  payload: ResponseDeletePopupAction | RequestError;
}

export const DeletePopUpActionCreator = (
  eventId: string,
  productId: string,
  product: ResponseSimpleProduct
) => {
  return async (
    dispatch: ThunkDispatch<
      DeletePopUpActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.deletePopUpLoading });
      const response = await deletePopup(eventId, productId);
      const newResponse = {
        response,
        product,
      };
      dispatch({
        type: EventActions.deletePopUpSuccess,
        payload: newResponse,
      });
    } catch (error) {
      dispatch({
        type: EventActions.deleteCurrentEventProductsFailed,
        payload: handleError(error),
      });
    }
  };
};

interface EditEventActionType {
  type:
    | EventActions.editEventLoading
    | EventActions.editEventSuccess
    | EventActions.editEventFailed;
  payload?: EditEventResponse | RequestError;
}

export const EditEventActionCreator = (
  body: {
    id: string;
    firaWebConfiguration: FiraConfiguration;
  },
  data: EventGeneralDetailsType,
  teaser?: File,
  preview?: File,
  eventId?: string,
  file?: File | null
) => {
  return async (
    dispatch: ThunkDispatch<EventActions, Record<string, unknown>, AnyAction>
  ) => {
    try {
      dispatch({
        type: EventActions.editEventLoading,
      });
      const edit = await editEventGeneralDetails(data, teaser, preview);
      const response = await editFiraWebConfigurationService(body);
      if (file != null && eventId) {
        uploadLikesFileService(eventId, file);
      }

      dispatch({
        type: EventActions.editEventSuccess,
        payload: response.data as EditEventResponse,
      });
    } catch (error) {
      dispatch({
        type: EventActions.editEventFailed,
        payload: handleError(error),
      });
    }
  };
};

interface ResetEditEventActionType {
  type: EventActions.editEventReset;
}

export const resetEditEventActionCreator = (): ResetEditEventActionType => {
  return { type: EventActions.editEventReset };
};

export interface GetEventQueuesActionType {
  type:
    | EventActions.getEventQueuesLoading
    | EventActions.getEventQueuesSuccess
    | EventActions.getEventQueuesFailed;
  payload?: ResponseQueues | RequestError;
}
/*export const getEventQueuesActionCreator = (eventId: string) => {
  return async (
    dispatch: ThunkDispatch<EventActions, Record<string, unknown>, AnyAction>
  ) => {
    try {
      dispatch({ type: EventActions.getEventQueuesLoading });
      const response = await getEventQueues(eventId);
      dispatch({
        type: EventActions.getEventQueuesSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: EventActions.getEventQueuesFailed,
        payload: handleError(error),
      });
    }
  };
};*/

export interface GetInactiveEventProductsActionType {
  type:
    | EventActions.getInactiveProductsLoading
    | EventActions.getInactiveProductsSuccess
    | EventActions.getInactiveProductsFailed;
  payload: ResponseSimpleProduct[] | RequestError;
}
export const GetInactiveEventProductsActionCreator = (eventId: string) => {
  return async (
    dispatch: ThunkDispatch<
      GetInactiveEventProductsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.getInactiveProductsLoading });

      const response = await getInactiveEventProducts(eventId);
      dispatch({
        type: EventActions.getInactiveProductsSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: EventActions.getInactiveProductsFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateStatsActionType {
  type:
    | EventActions.updateStatsLoading
    | EventActions.updateStatsSuccess
    | EventActions.updateStatsFailed;
  payload: FiraEvent | RequestError;
}

export const UpdateStatsActionCreator = (eventId: string) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateStatsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.updateStatsLoading });
      const response = await getEventService(eventId);
      dispatch({
        type: EventActions.updateStatsSuccess,
        payload: response.data as FiraEvent,
      });
    } catch (error) {
      dispatch({
        type: EventActions.updateStatsFailed,
        payload: handleError(error),
      });
    }
  };
};

interface GetEventCategoriesActionType {
  type:
    | EventActions.getEventCategoriesLoading
    | EventActions.getEventCategoriesSuccess
    | EventActions.getEventCategoriesFailed;
  payload: Category[] | RequestError;
}

export const getEventCategoriesActionCreator = () => {
  return async (
    dispatch: ThunkDispatch<
      GetEventCategoriesActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: EventActions.getEventCategoriesLoading });
      const response = await getEventCategories();
      dispatch({
        type: EventActions.getEventCategoriesSuccess,
        payload: response.data as Category[],
      });
    } catch (error) {
      dispatch({
        type: EventActions.getEventCategoriesFailed,
        payload: handleError(error),
      });
    }
  };
};

interface GeneralDetailsEditActionType {
  type:
    | EventActions.getEventCategoriesLoading
    | EventActions.getEventCategoriesSuccess
    | EventActions.getEventCategoriesFailed;
  payload: FiraEvent | RequestError;
}
export const GeneralDetailsEditActionCreator = (
  data: EventGeneralDetailsType,
  teaser?: File,
  preview?: File
) => {
  return async (
    dispatch: ThunkDispatch<
      GeneralDetailsEditActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({
        type: EventActions.editEventLoading,
      });
      const edit = await editEventGeneralDetails(data, teaser, preview);
      dispatch({
        type: EventActions.editEventSuccess,
        payload: edit.data as FiraEvent,
      });
    } catch (error) {
      dispatch({
        type: EventActions.editEventFailed,
        payload: handleError(error),
      });
    }
  };
};

interface TestEventTimerFinishActionType {
  type: EventActions.testEventTimerFinish;
  payload: EventStatus.DONE;
}
export const testEventTimerFinishActionCreator =
  (): TestEventTimerFinishActionType => {
    return {
      type: EventActions.testEventTimerFinish,
      payload: EventStatus.DONE,
    };
  };

interface CreateMultiEventActionType {
  type:
    | MultiEventActions.createMultiEventLoading
    | MultiEventActions.createMultiEventSuccess
    | MultiEventActions.createMultiEventFailed;
  payload?: FiraEvent | RequestError;
}

export const createMultiEventActionCreator = (
  event: FiraMultiEventForm,
  eventType: EventType = EventType.broadcast
) => {
  return async (
    dispatch: ThunkDispatch<
      MultiEventActions,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({
        type: MultiEventActions.createMultiEventLoading,
      });
      const response = await createMultipleEventsService(event, eventType);
      const evento: FiraEventNew = {
        id: response.data.id as string,
        eventName: event.eventName,
        startDate: new Date(event.eventDateTimeRange.startDate),
        endDate: new Date(event.eventDateTimeRange.endDate),
        status: EventStatus.SCHEDULED,
      };

      dispatch({
        type: MultiEventActions.createMultiEventSuccess,
        payload: evento,
      });
    } catch (error) {
      dispatch({
        type: MultiEventActions.createMultiEventFailed,
        payload: handleError(error),
      });
    }
  };
};

export const createMassiveEventActionCreator = (
  event: FiraMultiEventForm,
  eventType: EventType = EventType.broadcast
) => {
  return async (
    dispatch: ThunkDispatch<
      MultiEventActions,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({
        type: MultiEventActions.createMultiEventLoading,
      });
      const response = await createMassiveEventsService(event, eventType);
      const evento: FiraEventNew = {
        id: response.data.id as string,
        eventName: event.eventName,
        startDate: new Date(event.eventDateTimeRange.startDate),
        endDate: new Date(event.eventDateTimeRange.endDate),
        status: EventStatus.SCHEDULED,
      };
      dispatch({
        type: MultiEventActions.createMultiEventSuccess,
        payload: evento,
      });
    } catch (error) {
      dispatch({
        type: MultiEventActions.createMultiEventFailed,
        payload: handleError(error),
      });
    }
  };
};

interface GetEventTemplatesActionType {
  type:
    | EventActions.getEventTemplatesLoading
    | EventActions.getEventTemplatesSuccess
    | EventActions.getEventTemplateFailed;
  payload?: TemplatesResponse[] | RequestError;
}

export const getEventTemplatesActionCreator = (
  eventId: string,
  offset: number,
  limit: number
) => {
  return async (
    dispatch: ThunkDispatch<EventActions, Record<string, unknown>, AnyAction>
  ) => {
    try {
      dispatch({
        type: EventActions.getEventTemplatesLoading,
      });

      const response = await getPrevWebConfigurationsService(
        eventId,
        offset,
        limit
      );
      dispatch({
        type: EventActions.getEventTemplatesSuccess,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: EventActions.getEventTemplateFailed,
        payload: handleError(error),
      });
    }
  };
};

interface FirstSetUpActionType {
  type:
    | EventActions.setUpEventFailed
    | EventActions.setUpEventLoading
    | EventActions.setUpEventSuccess;
  payload?: UpdateEventResponse | RequestError;
}
/** DEPRECATED **/
export const FirstSetUpActionCreator = (
  details: EventGeneralDetailsType,
  config: EventSetUpConfiguration
) => {
  return async (
    dispatch: ThunkDispatch<EventActions, Record<string, unknown>, AnyAction>
  ) => {
    try {
      await editEventGeneralDetails(
        details,
        details.eventTeaser || undefined,
        details.eventPreviewImage || undefined
      );

      const res = await postFiraWebConfigurationService(config);

      dispatch({
        type: EventActions.setUpEventSuccess,
        payload: res.data as UpdateEventResponse,
      });
    } catch (error) {
      dispatch({
        type: EventActions.setUpEventFailed,
        payload: handleError(error),
      });
    }
  };
};
/** DEPRECATED **/

/** Optimized Edit Actions **/
interface EditFiraEventActionType {
  type:
    | EventActions.editEventFailed
    | EventActions.editEventLoading
    | EventActions.editEventSuccess;
  payload?: UpdateEventResponse | RequestError;
}

export const FirstSettingUpOptimizedActionCreator = (
  eventSetUpConfiguration: EventSetUpConfiguration, //formatted on data handler
  eventGeneralDetails: FormData
) => {
  return async (
    dispatch: ThunkDispatch<EventActions, Record<string, unknown>, AnyAction>
  ) => {
    try {
      dispatch({
        type: EventActions.setUpEventLoading,
      });
      await editEventGeneralDetailsService(eventGeneralDetails);
      const response = await postFiraWebConfigurationService(
        eventSetUpConfiguration
      );
      dispatch({
        type: EventActions.editEventSuccess,
        payload: response.data as UpdateEventResponse,
      });
    } catch (error) {
      dispatch({
        type: EventActions.setUpEventFailed,
        payload: handleError(error),
      });
    }
  };
};

export const EditFiraEventOptimizedActionCreator = (
  eventGeneralDetails: FormData, //Formatted in EventConfigurationView with the Data Handler
  firaWebConfiguration: FiraConfiguration,
  eventId: string,
  status: EventStatus
) => {
  return async (
    dispatch: ThunkDispatch<EventActions, Record<string, unknown>, AnyAction>
  ) => {
    try {
      dispatch({
        type: EventActions.editEventLoading,
      });
      const res = await editEventGeneralDetailsService(eventGeneralDetails);
      let body = {
        id: eventId,
        firaWebConfiguration: firaWebConfiguration,
      };
      if (status !== EventStatus.DONE) {
        const response = await editFiraWebConfigurationService(body);

        dispatch({
          type: EventActions.editEventSuccess,
          payload: response.data as EditEventResponse,
        });
      } else {
        dispatch({
          type: EventActions.editEventSuccess,
          payload: res.data as EditEventResponse,
        });
      }
    } catch (error) {
      dispatch({
        type: EventActions.setUpEventFailed,
        payload: handleError(error),
      });
    }
  };
};

export interface ResetCurrentProductsActionType {
  type: EventActions.resetCurrentEventProducts;
}

export const resetCurrentProductsActionCreator =
  (): ResetCurrentProductsActionType => {
    return { type: EventActions.resetCurrentEventProducts };
  };

export type EventActionType =
  | GetStoreEventsActionType
  | PostCreateEventActionType
  | ResetEventMessageActionType
  | GetFiraWebConfigurationActionType
  | GetStreamBlocksActionTypes
  | CurrentEventActionType
  | PutCancelEventActionType
  | ResetCurrentEventActionType
  | GetEventActionType
  | UpdateEventActionType
  | ResetUpdateEventActionType
  | GenerateCredentialsActionType
  | UpdateCredentialsActionType
  | StartEventActionType
  | FinishEventActionType
  | CleanMessageActionType
  | GetCurrentEventProductsActionType
  | AddCurrentEventProductsActionType
  | DeleteCurrentEventProductActionType
  | SendPopUpActionType
  | DeletePopUpActionType
  | EditEventActionType /**DEPRECATED**/
  | ResetEditEventActionType
  | GetInactiveEventProductsActionType
  | ReactiveCurrentEventProductsActionType
  | GetEventQueuesActionType
  | LogoutActionType
  | UpdateStatsActionType
  | GetEventCategoriesActionType
  | TestEventTimerFinishActionType
  | CreateMultiEventActionType
  | GetEventTemplatesActionType
  | FirstSetUpActionType
  | EditFiraEventActionType
  | ResetCurrentProductsActionType
  | StoresListEventActionType;
