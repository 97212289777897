import React from "react";

import { WizardStep } from "../../providers/WizardProvider";
import stylesheet from "./StepIndicator.module.scss";
import { useTranslation } from "react-i18next";

const { StepWrapper, Step, Active, Line, Filled, Off } = stylesheet;

interface Props {
  steps: WizardStep[];
  currentStep: number;
}
const StepIndicator: React.FC<Props> = ({ currentStep, steps }) => {
  const { t } = useTranslation();
  const renderStep = (step: WizardStep, index: number) => {
    return (
      <div
        key={index}
        className={`${StepWrapper} ${index === steps.length - 1 ? Off : ""}`}
      >
        <div className={`${Step} ${index <= currentStep ? Active : ""} `}>
          <span>{t(`wizard.steps.${steps[index]}`)}</span>
          {index + 1}
        </div>
        {index < steps.length - 1 && (
          <div className={`${Line} ${index < currentStep ? Filled : ""}`}></div>
        )}
      </div>
    );
  };

  return (
    <div style={Wrapper}>
      {steps.map((step, index) => renderStep(step, index))}
    </div>
  );
};

const Wrapper: React.CSSProperties = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  maxWidth: 700,
  margin: "0 auto",
  height: 45,
};

export default StepIndicator;
