import { Currency } from "./types.d";

export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case Currency.ars:
      return "$";
    case Currency.brl:
      return "R$";
    case Currency.clp:
      return "$";
    case Currency.cop:
      return "$";
    case Currency.eur:
      return "€";
    case Currency.mxn:
      return "MX$";
    case Currency.pen:
      return "S/";
    case Currency.usd:
      return "$";
    case Currency.gtq:
      return "Q";
    case Currency.nio:
      return "C$";
    case Currency.crc:
      return "₡";
    case Currency.hnl:
      return "L";
    case Currency.svc:
      return "$";
    default:
      return "$";
  }
};
