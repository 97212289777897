import { RequestError } from "../../services/errorHandling";
import {
  DashboardCountingResponse,
  DashboardEvent,
  NextEventList,
} from "../../utils/types";
import { AuthActions, DashboardActions } from "../actionsContants";
import { DashboardActionType } from "./DashboardActions";

export interface DashboardState {
  firstDate: Date;
  lastDate: Date;
  isLoading: boolean;
  error?: RequestError;
}

const initialState: DashboardState = {
  firstDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  lastDate: new Date(),
  isLoading: false,
};

export const DashboardReducer = (
  state = initialState,
  action: DashboardActionType
): DashboardState => {
  switch (action.type) {
    case DashboardActions.setDateRange: {
      const { firstDate, lastDate } = action.payload;
      return { ...state, firstDate, lastDate };
    }

    case AuthActions.logout: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
