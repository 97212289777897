import { ChangeEvent, useEffect, useRef, useState } from "react";
import { convertDateLocal, inputDateFormat } from "../../utils/handleDates";
import { CalendarNumbers } from "../FiraIcons";
import SvgClockComplete from "../FiraIcons/ClockComplete";
import styles from "./DateTimeInput.module.scss";

export enum DateTimeInputType {
  date = "date",
  time = "time",
}

interface Props {
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  width?: string;
  height?: string;
  type?: DateTimeInputType;
  value?: Date | null;
  isDisabled: boolean;
  inputName?: string;
}

const DateTimeInput = (props: Props) => {
  const { height, width, type, value, isDisabled, onChange, inputName } = props;
  const style = {
    height,
    width,
  };
  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputTime = (value: Date | null) => {
    setTime(handleFormatTime(value));
  };

  const handleInputDate = (value: Date | null) => {
    setDate(handleFormatDate(value));
  };

  useEffect(() => {
    if (value !== undefined) {
      type === DateTimeInputType.time
        ? handleInputTime(value)
        : handleInputDate(value);
    }
  }, [value]);

  return (
    <div className={styles.DateTimeInputWrapper}>
      {type === DateTimeInputType.time && (
        <div className={styles.Icon}>
          <SvgClockComplete />
        </div>
      )}
      {type === DateTimeInputType.date && (
        <div className={styles.Icon}>
          <CalendarNumbers />
        </div>
      )}
      <input
        type={type}
        style={style}
        className={styles.Input}
        defaultValue={type === DateTimeInputType.date ? date : time}
        onChange={onChange}
        disabled={isDisabled}
        ref={inputRef}
        required
        name={inputName}
      />
    </div>
  );
};
export const handleFormatDate = (val: Date | null) => {
  if (val != null) {
    const d = new Date(val);
    return inputDateFormat(d);
  }
};

export const handleFormatTime = (val: Date | null) => {
  if (val != null) {
    const t = new Date(val);
    return convertDateLocal(t).toLocaleString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }
};
export default DateTimeInput;
