import { Locale } from "date-fns";
import { enUS, es, pt, ptBR } from "date-fns/locale";

const checkBrowserLanguage = (): Locale => {
  if (navigator.language.includes("es")) return es;
  else if (navigator.language.includes("pt-BR")) return ptBR;
  else if (navigator.language.includes("pt")) return pt;

  return enUS;
};

export { checkBrowserLanguage };
