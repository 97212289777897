import React from "react";
import { ResponseCurrentPopup } from "../utils/types";
import { getEventPopups } from "../services/events";

interface EventState {
  popups: ResponseCurrentPopup[];
  addPopup(popup: ResponseCurrentPopup): void;
  removePopup(id: string): void;
  loadCurrentPopups(): void;
  clearPopups(): void;
}

const EventContext = React.createContext<EventState | null>(null);

interface ProviderProps {
  children: React.ReactNode;
  firaSrc: string;
}
const EventProvider: React.FC<ProviderProps> = ({ children, firaSrc }) => {
  const [popups, setPopups] = React.useState<ResponseCurrentPopup[]>([]);

  const addPopup = (
    single?: ResponseCurrentPopup,
    list?: ResponseCurrentPopup[]
  ) => {
    if (single) {
      setPopups([...popups, single]);
    } else if (list) {
      setPopups(list);
    }
  };

  const removePopup = (id: string) => {
    setPopups((prevState) =>
      prevState.filter((p) => p.broadcastingProductId !== id)
    );
  };

  const loadCurrentPopups = async () => {
    const response = await getEventPopups(firaSrc);
    addPopup(undefined, response);
  };

  const clearPopups = () => {
    setPopups([]);
  };

  return (
    <EventContext.Provider
      value={{ popups, addPopup, removePopup, loadCurrentPopups, clearPopups }}
    >
      {children}
    </EventContext.Provider>
  );
};

const useEvent = () => {
  const context = React.useContext(EventContext);

  if (!context) {
    throw new Error("You have to use the context inside EventProvider");
  }

  return context;
};

export { EventProvider, useEvent };
