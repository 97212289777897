import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify-icon/react";

import Checkbox from "../Checkbox/Checkbox";
import { RootState } from "../../utils/types";
import { FiraStore } from "../../types/Stores";
import Input, { InputStyles } from "../Input/Input";
import { getOwnerStoresActionCreator } from "../../store/Brand/BrandActions";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import Button, { ButtonStyle } from "../Button/Button";
import { useSettingsLanding } from "../../providers/SettingsLandingProvider";
import { saveGlobalLandingSettings } from "../../services/landing";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";

const SettingsLandingPermissions: React.FC = () => {
  const { t } = useTranslation();
  const { brand, authentication } = useSelector((state: RootState) => state);
  const dispath = useDispatch();
  const [search, setSearch] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    initialState,
    state,
    setState,
    globalLandingConfig,
    setInitialState,
    loadSettings,
  } = useSettingsLanding();
  const [filterList, setFilterList] = React.useState(
    brand.list.filter((s) => s.state === "ACTIVE")
  );
  const [isChanged, setIsChanged] = React.useState(false);
  const showAlert = usePopupAlert();

  const toggleAll = () => {
    if (
      state.stores.length ===
      brand.list.filter((s) => s.state === "ACTIVE").length
    ) {
      setState({ ...state, stores: [] });
      return;
    }

    setState({
      ...state,
      stores: brand.list.filter((s) => s.state === "ACTIVE").map((s) => s.id),
    });
  };

  const onStoreSelected = (store: FiraStore) => {
    if (state.stores.includes(store.id)) {
      setState({
        ...state,
        stores: state.stores.filter((s) => s !== store.id),
      });
      return;
    }
    setState({ ...state, stores: [...state.stores, store.id] });
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setFilterList(
      brand.list.filter(
        (s) =>
          s.name.toLowerCase().includes(event.target.value.toLowerCase()) &&
          s.state === "ACTIVE"
      )
    );
  };

  const toggleActive = () => {
    setState({
      ...state,
      isActive: !state.isActive,
      blockWidgetsAndCart:
        !state.isActive === false ? false : state.blockWidgetsAndCart,
      stores: !state.isActive === false ? [] : state.stores,
    });
  };

  const toggleWidget = () => {
    setState({
      ...state,
      isActive: !state.blockWidgetsAndCart !== true ? state.isActive : true,
      blockWidgetsAndCart: !state.blockWidgetsAndCart,
      stores: !state.isActive ? [] : state.stores,
    });
  };

  const renderStoreList = () => {
    return (
      <ul style={List}>
        {brand.isLoading && (
          <div style={ListLoadingWrapper}>
            <Icon size={32} color="#ffde07" icon="line-md:loading-loop" />
          </div>
        )}
        <li onClick={toggleAll} style={Item}>
          <div>
            <Checkbox
              onChange={toggleAll}
              isActive={
                state.stores.length ===
                brand.list.filter((s) => s.state === "ACTIVE").length
              }
            />
          </div>
          <span style={ItemText}>Seleccionar Todo</span>
        </li>
        {filterList.map((store) => {
          return (
            <li
              onClick={() => onStoreSelected(store)}
              key={store.id}
              style={Item}
            >
              <div>
                <Checkbox
                  onChange={() => onStoreSelected(store)}
                  isActive={state.stores.includes(store.id)}
                />
              </div>
              <span style={ItemText}>{store.name}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const handleCancelAction = () => {
    setState(initialState);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      showAlert(AlertType.warning, t("alerts.saving"), 1000);
      setIsLoading(true);
      if (
        authentication.user &&
        authentication.user.businessClientId &&
        globalLandingConfig
      ) {
        await saveGlobalLandingSettings(
          authentication.user.businessClientId,
          state,
          globalLandingConfig
        );
        setInitialState(state);
        setIsLoading(false);
        showAlert(AlertType.success, t("alerts.success"), 100000);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 412) {
        showAlert(AlertType.error, t("alerts.landing_error"));
      } else {
        showAlert(AlertType.error, JSON.stringify(error));
      }

      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setIsChanged(
      JSON.stringify(state.blockWidgetsAndCart) !==
        JSON.stringify(initialState.blockWidgetsAndCart) ||
        JSON.stringify(state.stores) !== JSON.stringify(initialState.stores)
    );
  }, [state, initialState]);

  React.useEffect(() => {
    if (authentication.user && authentication.user.businessClientId) {
      dispath(
        getOwnerStoresActionCreator(authentication.user.businessClientId)
      );
    }
  }, [authentication.user]);

  React.useEffect(() => {
    loadSettings();
  }, []);

  return (
    <div style={Wrapper}>
      {globalLandingConfig === null && (
        <div style={Blocker}>
          <span style={BlockerText}>
            {t("components.settings.landing.permissions.blocker")}
          </span>
        </div>
      )}
      <div style={Header}>
        <div style={LeadingSpace}>
          <div style={StepNumber}>2</div>
          <span style={Title}>{t("components.settings.landing.title")}</span>
        </div>
      </div>
      <form style={InnerWrapper} onSubmit={handleSubmit}>
        <div style={ComponentWrapper}>
          <span style={Title}>
            {t("components.settings.event.tabs.permissions")}
          </span>
          <div style={PermissionWrapper}>
            <span>
              {t("components.settings.landing.permissions.useDefault")}
            </span>
            <ToggleSwitch
              hideTags
              checked={state.isActive}
              handleChecked={toggleActive}
            />
          </div>
          <div style={PermissionWrapper}>
            <span>
              {t("components.settings.landing.permissions.widgetDefault")}
            </span>
            <ToggleSwitch
              hideTags
              checked={state.blockWidgetsAndCart}
              handleChecked={toggleWidget}
            />
          </div>
        </div>
        {state.isActive && (
          <div style={ComponentWrapper}>
            <Input
              width="100%"
              biggerIcon
              fontSize="15px"
              placeholder={t("inputs.searchPlaceholder")}
              value={search}
              onChange={onSearchChange}
              inputStyle={InputStyles.searchConfig}
              icon={<Icon icon="mynaui:search" />}
            />
            {renderStoreList()}
          </div>
        )}

        <div style={ButtonWrapper}>
          <Button
            buttonStyle={ButtonStyle.light}
            clickHandler={handleCancelAction}
            isDisabled={isLoading}
            type="button"
          >
            {t("buttons.cancel")}
          </Button>
          <Button type="submit" isDisabled={!isChanged || isLoading}>
            {t("buttons.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

const Wrapper: React.CSSProperties = {
  position: "relative",
  width: "100%",
  backgroundColor: "#fff",
  borderRadius: 8,
};

const Blocker: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 5,
  backgroundColor: "rgba(217, 217, 217, 0.40)",
  borderRadius: 8,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const BlockerText: React.CSSProperties = {
  fontSize: 30,
  fontWeight: 500,
  color: "#000000",
};

const InnerWrapper: React.CSSProperties = {
  padding: "24px 32px",
  display: "flex",
  flexDirection: "column",
  gap: 16,
};

const Header: React.CSSProperties = {
  padding: "18px 32px",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #D5DDE0",
};

const LeadingSpace: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  gap: 8,
};

const StepNumber: React.CSSProperties = {
  width: 32,
  height: 32,
  borderRadius: "50%",
  backgroundColor: "#FFDE07",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 16,
  fontWeight: "600",
};

const Title: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 600,
  color: "#000",
};

const ComponentWrapper: React.CSSProperties = {
  display: "grid",
  gridTemplateRows: "auto 1fr",
  gridTemplateColumns: "1fr",
  gap: 16,
};

const PermissionWrapper: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: 14,
  fontWeight: 400,
};

const List: React.CSSProperties = {
  position: "relative",
  listStyle: "inside",
  maxHeight: 300,
  minHeight: 250,
  overflowY: "auto",
  listStyleType: "none",
  backgroundColor: "#F7F8F9",
  borderRadius: 8,
  padding: 9,
};

const ListLoadingWrapper: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(1, 1, 1, 0.4)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Item: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 10,
  padding: 8,
  borderBottom: "1px solid #D5DDE0",
};

const ItemText: React.CSSProperties = {
  fontSize: 12,
  lineHeight: "24px",
};

const ButtonWrapper: React.CSSProperties = {
  display: "flex",
  gap: 10,
  justifyContent: "center",
};

export default SettingsLandingPermissions;
