import styles from "./LandingTypography.module.scss";
import { SimpleFormSelect } from "../../../Selects/FormSelect/FormSelect";
import { useContext, useEffect, useState } from "react";
import { FONTS } from "../../../../assets/fonts/fonts";
import { useTranslation } from "react-i18next";
import { useLandingConfig } from "../../../../providers/LandingConfigProvider";

const LandingTypography = () => {
  const { firaLandingConfiguration, setFiraLandingConfiguration } =
    useLandingConfig();
  const { t } = useTranslation();
  const fontSizes = ["12", "14", "16", "18", "20", "24", "32", "48", "64"];
  const fontWeights = [
    { name: "Thin", weight: 100 },
    { name: "Extra-Light", weight: 200 },
    { name: "Light", weight: 300 },
    { name: "Regular", weight: 400 },
    { name: "Medium", weight: 500 },
    { name: "Semi-Bold", weight: 600 },
    { name: "Bold", weight: 700 },
    { name: "Extra-Bold", weight: 800 },
    { name: "Black", weight: 900 },
  ];
  const fontFamilies = FONTS;

  /** Body **/
  const [selectedFontSize, setSelectedFontSize] = useState<string>(
    fontSizes[0]
  );
  const [selectedFontWeight, setSelectedFontWeight] = useState<{
    name: string;
    weight: number;
  }>(fontWeights[4]);
  const [selectedFontFamily, setSelectedFontFamily] = useState<{
    font: string;
  }>(fontFamilies[14]);

  const handleBodyFontSize = (size: string) => {
    setSelectedFontSize(size);
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      fontSizeHeader: Number(size),
      fontSizeContent: Number(size),
    });
  };

  const handleBodyFontFamily = (font: { font: string }) => {
    setSelectedFontFamily(font);
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      fontContent: font.font,
      fontHeader: font.font,
    });
  };

  const handleBodyFontWeight = (font: { name: string; weight: number }) => {
    setSelectedFontWeight(font);
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      fontContentWeight: font.weight,
      fontHeaderWeight: font.weight,
    });
  };

  /** Footer **/
  const [selectedFontSizeFooter, setSelectedFontSizeFooter] = useState<string>(
    fontSizes[0]
  );
  const [selectedFontWeightFooter, setSelectedFontWeightFooter] = useState<{
    name: string;
    weight: number;
  }>(fontWeights[4]);
  const [selectedFontFamilyFooter, setSelectedFontFamilyFooter] = useState<{
    font: string;
  }>(fontFamilies[14]);

  const handleFooterFontSize = (size: string) => {
    setSelectedFontSizeFooter(size);
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      fontSizeFooter: Number(size),
    });
  };

  const handleFooterFontFamily = (font: { font: string }) => {
    setSelectedFontFamilyFooter(font);
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      fontFooter: font.font,
    });
  };

  const handleFooterFontWeight = (font: { name: string; weight: number }) => {
    setSelectedFontWeightFooter(font);
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      fontFooterWeight: font.weight,
    });
  };

  useEffect(() => {
    if (firaLandingConfiguration) {
      setSelectedFontFamilyFooter(
        fontFamilies.find(
          (option) => option.font === firaLandingConfiguration.fontFooter
        ) || fontFamilies[0]
      );
      setSelectedFontSizeFooter(
        fontSizes.find(
          (option) => Number(option) === firaLandingConfiguration.fontSizeFooter
        ) || fontSizes[0]
      );
      setSelectedFontWeightFooter(
        fontWeights.find(
          (option) =>
            option.weight === firaLandingConfiguration.fontFooterWeight
        ) || fontWeights[4]
      );

      setSelectedFontFamily(
        fontFamilies.find(
          (option) => option.font === firaLandingConfiguration.fontContent
        ) || fontFamilies[0]
      );
      setSelectedFontSizeFooter(
        fontSizes.find(
          (option) =>
            Number(option) === firaLandingConfiguration.fontSizeContent
        ) || fontSizes[0]
      );
      setSelectedFontWeight(
        fontWeights.find(
          (option) =>
            option.weight === firaLandingConfiguration.fontContentWeight
        ) || fontWeights[4]
      );
    }
  }, []);

  return (
    <>
      <div className={styles.LandingTypographyModulesWrapper}>
        <div className={styles.LandingTypographyModule}>
          <div className={styles.LandingTypographyModule__Title}>
            {t("views.landing.typography.body")}
          </div>
          <SimpleFormSelect
            width="100%"
            value={selectedFontFamily.font}
            name={selectedFontFamily.font}
            placeholder={"12"}
            height={"24px"}
            fontSize={"12px"}
          >
            <>
              {fontFamilies &&
                fontFamilies.map((font: { font: string }, i: number) => (
                  <div
                    key={i}
                    style={{ fontFamily: font.font }}
                    onClick={() => handleBodyFontFamily(font)}
                  >
                    {font.font}
                  </div>
                ))}
            </>
          </SimpleFormSelect>
          <SimpleFormSelect
            width="100%"
            value={`${selectedFontWeight.name} - ${selectedFontWeight.weight}`}
            name={`${selectedFontWeight.name} - ${selectedFontWeight.weight}`}
            placeholder={"12"}
            height={"24px"}
            fontSize={"12px"}
          >
            <>
              {fontWeights &&
                fontWeights.map(
                  (
                    weight: {
                      name: string;
                      weight: number;
                    },
                    i: number
                  ) => (
                    <div key={i} onClick={() => handleBodyFontWeight(weight)}>
                      {weight.name} - {weight.weight}
                    </div>
                  )
                )}
            </>
          </SimpleFormSelect>
          <SimpleFormSelect
            width="100%"
            value={selectedFontSize}
            name={selectedFontSize}
            placeholder={"12"}
            height={"24px"}
            fontSize={"12px"}
          >
            <>
              {fontSizes &&
                fontSizes.map((size: string, i: number) => (
                  <div key={i} onClick={() => handleBodyFontSize(size)}>
                    {size}
                  </div>
                ))}
            </>
          </SimpleFormSelect>
        </div>
        <div className={styles.LandingTypographyModule}>
          <div className={styles.LandingTypographyModule__Title}>
            {t("views.landing.typography.footer")}
          </div>
          <SimpleFormSelect
            width="100%"
            value={selectedFontFamilyFooter.font}
            name={selectedFontFamilyFooter.font}
            placeholder={"12"}
            height={"24px"}
            fontSize={"12px"}
          >
            <>
              {fontFamilies &&
                fontFamilies.map((font: { font: string }, i: number) => (
                  <div
                    key={i}
                    style={{ fontFamily: font.font }}
                    onClick={() => handleFooterFontFamily(font)}
                  >
                    {font.font}
                  </div>
                ))}
            </>
          </SimpleFormSelect>
          <SimpleFormSelect
            width="100%"
            value={`${selectedFontWeightFooter.name} - ${selectedFontWeightFooter.weight}`}
            name={`${selectedFontWeightFooter.name} - ${selectedFontWeightFooter.weight}`}
            placeholder={"12"}
            height={"24px"}
            fontSize={"12px"}
          >
            <>
              {fontWeights &&
                fontWeights.map(
                  (
                    weight: {
                      name: string;
                      weight: number;
                    },
                    i: number
                  ) => (
                    <div key={i} onClick={() => handleFooterFontWeight(weight)}>
                      {weight.name} - {weight.weight}
                    </div>
                  )
                )}
            </>
          </SimpleFormSelect>
          <SimpleFormSelect
            width="100%"
            value={selectedFontSizeFooter}
            name={selectedFontSizeFooter}
            placeholder={"12"}
            height={"24px"}
            fontSize={"12px"}
          >
            <>
              {fontSizes &&
                fontSizes.map((size: string, i: number) => (
                  <div key={i} onClick={() => handleFooterFontSize(size)}>
                    {size}
                  </div>
                ))}
            </>
          </SimpleFormSelect>
        </div>
      </div>
    </>
  );
};

export default LandingTypography;
