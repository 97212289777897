import React, { useEffect, useState } from "react";
import Button, { ButtonStyle } from "../../../components/Button/Button";
import Checkbox from "../../../components/Checkbox/Checkbox";
import noImageStoreSVG from "../../../assets/svg/no-image-store.svg";
import SimpleCloseX from "../../../components/FiraIcons/SimpleCloseX";
import { TableImage } from "../../../components/Table/Table";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import { useProductContext } from "../../../store/Products/ProductVariationsContext";
import style from "./ModalProductVariations.module.scss";
import { FiraProductVariation } from "../../../utils/types";
import { useTranslation } from "react-i18next";

interface ProductVariationsModalProps {
  onClose(): void;
  onChange(): void;
}

const ModalProductVariations = ({
  onClose,
  onChange,
}: ProductVariationsModalProps) => {
  const { addToCurrentProductVariations, getUnselectedVariations } =
    useProductContext();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { t } = useTranslation();
  const [productVariations, setProductVariations] = useState<
    FiraProductVariation[]
  >([]);

  const toggleSelectItem = (sku: string) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(sku)) {
        return prevSelected.filter((item) => item !== sku);
      } else {
        return [...prevSelected, sku];
      }
    });
  };

  const addVariations = () => {
    selectedItems.map((item) => {
      addToCurrentProductVariations(item);
    });
    onChange();
    onClose();
  };

  useEffect(() => {
    setProductVariations(getUnselectedVariations());
  }, []);

  return (
    <ModalLayout>
      <div className={style.DialogWrapper}>
        <div className={style.HeaderDialog}>
          <label style={{ marginLeft: "16px" }}>
            {t("views.products.detailProduct.addVariant")}
          </label>
          <span
            onClick={() => onClose()}
            style={{ cursor: "pointer", marginRight: "16px" }}
          >
            <SimpleCloseX />
          </span>
        </div>
        <div style={{ width: "100%", maxHeight: "428px", overflowY: "auto" }}>
          <table className={style.TableWrapper}>
            <tbody>
              {productVariations.map((variation, index) => (
                <tr key={variation.sku}>
                  <td
                    className={style.itemTr}
                    style={{
                      width: "30px",
                      marginBottom: "10px",
                      height: "67px",
                    }}
                  >
                    <Checkbox
                      clickHandler={() => toggleSelectItem(variation.sku)}
                      isActive={selectedItems.includes(variation.sku)}
                    />
                  </td>
                  <td
                    className={style.itemTr}
                    style={{ width: "60px", marginBottom: "10px" }}
                  >
                    <TableImage
                      src={
                        variation.image || variation.image === ""
                          ? noImageStoreSVG
                          : variation.image
                      }
                    />
                  </td>
                  <td className={style.itemTr}>{variation.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {productVariations.length == 0 ? (
            <label className={style.labelEmpty}>
              {t("views.products.detailProduct.emptyVariant")}
            </label>
          ) : (
            ""
          )}
        </div>
        <div className={style.FooterDialog}>
          <Button
            width="105px"
            height="32px"
            clickHandler={() => onClose()}
            buttonStyle={ButtonStyle.border}
          >
            <span className="lead-text">
              {" "}
              {t("views.products.detailProduct.cancel")}
            </span>
          </Button>
          <Button
            width="105px"
            height="32px"
            clickHandler={(e) => addVariations()}
            buttonStyle={ButtonStyle.main}
            isDisabled={selectedItems.length === 0 ? true : false}
          >
            <span className="lead-text">
              {" "}
              {t("views.products.detailProduct.addButton")}
            </span>
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ModalProductVariations;
