import styles from "./EventBuyButton.module.scss";
import RadioButton from "../../RadioButton/RadioButton";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import EventBuyButtonPrev from "./EventBuyButtonPrev/EventBuyButtonPrev";
import { EventConfigurationContext } from "../../../views/EventConfigurationView/EventConfigurationView";

const EventBuyButton = () => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<"CUSTOM" | "DEFAULT">(
    "DEFAULT"
  );
  const [buttonText, setButtonText] = useState<string>("");
  const [selectedColor, setSelectedColor] = useState<string>("#ffde07");
  const [saved, setSaved] = useState<boolean>(false);
  const {
    firaWebConfiguration,
    setFiraWebConfiguration,
    setBuyButtonSaved,
    buyButtonSaved,
  } = useContext(EventConfigurationContext);

  function isOnlySpaces(str: string) {
    return /^\s*$/.test(str);
  }
  const validator = () => {
    const notEmptyStringRegex = /^(?!\s*$).+/;
    return (
      selectedOption === "CUSTOM" &&
      notEmptyStringRegex.test(buttonText) &&
      !buyButtonSaved &&
      !saved &&
      !isOnlySpaces(buttonText)
    );
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBuyButtonSaved(false);
    const text = event.target.value;
    if (text.length === 19) {
      return;
    }
    setButtonText(text);
    setSaved(false);
  };

  const saveBtn = () => {
    setSaved(true);
    if (selectedOption === "CUSTOM" && buttonText !== "") {
      setFiraWebConfiguration({
        ...firaWebConfiguration,
        buyButtonText: buttonText,
      });
      setBuyButtonSaved(true);
    }
  };
  useEffect(() => {
    if (selectedOption === "DEFAULT") {
      setFiraWebConfiguration({ ...firaWebConfiguration, buyButtonText: null });
    } else {
      setFiraWebConfiguration({
        ...firaWebConfiguration,
        buyButtonText: buttonText,
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    if (firaWebConfiguration)
      setSelectedColor(
        firaWebConfiguration.mainColor
          ? firaWebConfiguration.mainColor
          : "#ffde07"
      );

    if (firaWebConfiguration.buyButtonText) {
      setSelectedOption("CUSTOM");
      setButtonText(firaWebConfiguration.buyButtonText);
    }
  }, [firaWebConfiguration]);

  return (
    <>
      <div className={styles.EventBuyButtonWrapper}>
        <div className={styles.SwitchContainer}>
          <label>
            {t("event_creation.params.buyButtonDefault")}
            <RadioButton
              isActive={selectedOption === "DEFAULT"}
              clickHandler={() => setSelectedOption("DEFAULT")}
            />
          </label>
          <label>
            {t("event_creation.params.buyButtonCustom")}
            <RadioButton
              isActive={selectedOption === "CUSTOM"}
              clickHandler={() => setSelectedOption("CUSTOM")}
            />
          </label>
        </div>
        <div className={styles.InputContainer}>
          <label>
            <span style={{ position: "relative", top: "-8px" }}>
              {t("event_creation.params.buyButtonText")}
            </span>
            <div className={styles.InputField}>
              <Input
                isDisabled={selectedOption === "DEFAULT"}
                height={"40px"}
                width={"100%"}
                placeholder={t("event_creation.params.buyButtonPlaceholder")}
                onChange={(event) => handleInputChange(event)}
                value={buttonText}
                counterTop={selectedOption === "CUSTOM"}
                invalid={buttonText.length === 18}
              />
            </div>
          </label>
          <Button
            width={"106px"}
            height={"40px"}
            isDisabled={!validator()}
            clickHandler={saveBtn}
          >
            <span>{t("event_creation.params.buyButtonSave")}</span>
          </Button>
        </div>
        {selectedOption === "CUSTOM" && (
          <div className={styles.PrevWrapper}>
            <div className={styles.PrevTitle}>
              {t("event_creation.params.buyButtonPrev")}
            </div>
            <div className={styles.PrevContainer}>
              <EventBuyButtonPrev
                title={
                  selectedOption && selectedOption === "CUSTOM"
                    ? buttonText || t("event_creation.params.buyButtonView")
                    : t("event_creation.params.buyButtonView")
                }
                color={selectedColor}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EventBuyButton;
