import { ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import { cleanMessageActionCreator } from "../../../store/Event/EventActions";
import { RootState } from "../../../utils/types";
import Button, { ButtonStyle } from "../../Button/Button";
import {
  ArrowUp,
  CheckCircleFill,
  CopyPaper,
  CrossCircleFill,
} from "../../FiraIcons";
import PopUpAlert, { PopUpAlertTypes } from "../../PopUpAlert/PopUpAlert";
import styles from "./Credentials.module.scss";

enum MessageType {
  server = "server",
  key = "key",
  success = "success",
}

enum CopyType {
  button,
  script,
  server,
  key,
}

let timerID: NodeJS.Timeout;

interface CredentialsProps {
  isOpen: boolean;
  onClose(): void;
  generateCredentials(): void;
}
const Credentials = ({
  isOpen,
  onClose,
  generateCredentials,
}: CredentialsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { event } = useSelector((state: RootState) => state);
  const [messageType, setMessageType] = useState<PopUpAlertTypes>();
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [generatingCredentials, setGeneratingCredentials] = useState<boolean>(false);

  if (!isOpen) {
    return null;
  }

  const fallbackCopyTextToClipboard = (text: string) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const copyTextToClipboard = async (text: string = "") => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.log("ERROR COPYING TEXT", error);
    }
  };

  const scriptURL =
    process.env.NODE_ENV === "production"
      ? "https://firalivepro.blob.core.windows.net/fira-live-widget/index.min.js"
      : "https://firalivedev.blob.core.windows.net/fira-live-widget/index.js";
  const buttonTextExample = `<button type="button" class="fira_live_button" fira-src="${event.currentEvent?.id}">LIVE</button>`;
  const exampleButtonCode = <span>{buttonTextExample}</span>;
  const scriptTextExample = `<script src="${scriptURL}" id="fira-live-widget" fira-key="${event.currentEvent?.storeId}"></script>`;
  const exampleScriptCode = <span>{scriptTextExample}</span>;

  const copyAction = (type: CopyType) => {
    switch (type) {
      case CopyType.button:
        copyTextToClipboard(buttonTextExample);
        break;
      case CopyType.script:
        copyTextToClipboard(scriptTextExample);
        break;
      case CopyType.server:
        copyTextToClipboard(event.currentEvent?.publisherCredentialsDto?.url);
        break;
      case CopyType.key:
        copyTextToClipboard(event.currentEvent?.publisherCredentialsDto?.key);
        break;
    }
  };

  const checkCredentials = (): boolean => {
    const { currentEvent } = event;
    return currentEvent && currentEvent.publisherCredentialsDto ? true : false;
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    dispatch(cleanMessageActionCreator());
  };

  const handleCloseModal = () => {
    showAlert && handleCloseAlert();
    onClose();
  };

  useEffect(() => {
    if (event.currentEvent) {
      if (!event.isLoading && !event.error && event.credentialsSuccess) {
        setMessage(MessageType.success);
        handleCloseAlert();
      }
    }

    return () => {
      clearTimeout(timerID);
    };
  }, [event.currentEvent, event.isLoading, event.error]);

  useEffect(() => {
    if (event.currentEvent) {
      if (!event.isLoading) {
        if (event.credentialsSuccess) {
          setMessageType(PopUpAlertTypes.success);
          setMessage(
            t("modals.eventDetail.credentials.credentials.messages.success")
          );
          setShowAlert(true);
          timerID = setTimeout(() => {
            handleCloseAlert();
          }, 5000);
        }
        if (event.error) {
          setShowAlert(true);
          setMessageType(PopUpAlertTypes.error);
          if (event.credentialsError) {
            switch (event.error.message) {
              case "INVALID ID":
                setMessage(
                  t("modals.eventDetail.credentials.messages.invalid_id", {
                    streamId: event.currentEvent.id,
                  })
                );
                break;
              case "Can not generate credentials to broadcast not scheduled":
                setMessage(
                  t("modals.eventDetail.credentials.messages.cant_generate")
                );
                break;
              case "Error saving broadcasting credentials":
                setMessage(
                  t("modals.eventDetail.credentials.messages.error_saving")
                );
                break;
              case "error getting credentials to PUBLISH_TRANSCODER":
                setMessage(t("views.events.eventDetail.messages.red5_error"));
                break;
              default:
                setMessage(event.error.message);
                console.error(event.error);
                break;
            }
          }

          timerID = setTimeout(() => {
            handleCloseAlert();
          }, 5000);
        }
      }
    }

    return () => {
      clearTimeout(timerID);
    };
  }, [event.isLoading, event.error]);

  return ReactDOM.createPortal(
    <ModalLayout>
      {showAlert && (
        <PopUpAlert
          type={messageType}
          title={message}
          width="510px"
          handleCloseIcon={handleCloseAlert}
        />
      )}
      <div className={styles.credentialsWrapper}>
        <Button
          className={styles.CloseButton}
          buttonStyle={ButtonStyle.transparent}
          width="30px"
          height="30px"
          clickHandler={handleCloseModal}
        >
          <CrossCircleFill />
        </Button>
        <div className={styles.header}>
          <h5>{t("modals.eventDetail.credentials.title")}</h5>
          <p className="alert-text">
            {t("modals.eventDetail.credentials.subTitle")}
          </p>
        </div>
        <div className={styles.DivisorGroup}>
          <Divisor
            headerText={t("modals.eventDetail.credentials.integration.title")}
          >
            <div className={styles.CodeGroup}>
              <p>
                {t("modals.eventDetail.credentials.integration.instruction_1")}
              </p>
              <CopyButton
                width="100%"
                onClick={() => copyAction(CopyType.button)}
              >
                <div className={styles.CodeInner}>
                  <p>{exampleButtonCode}</p>
                  <CopyPaper className={styles.Icon} />
                </div>
              </CopyButton>
              <p>
                {t("modals.eventDetail.credentials.integration.instruction_2")}
              </p>
              <CopyButton
                width="100%"
                onClick={() => copyAction(CopyType.script)}
              >
                <div className={styles.CodeInner}>
                  <p>{exampleScriptCode}</p>
                  <CopyPaper className={styles.Icon} />
                </div>
              </CopyButton>
            </div>
          </Divisor>

          <Divisor
            headerText={t("modals.eventDetail.credentials.credentials.title")}
          >
            <div className={styles.buttonsGroup}>
              {!checkCredentials() && (
                <Button
                  width="174px"
                  height="37px"
                  clickHandler={()=>{setGeneratingCredentials(true); generateCredentials()}}
                  isDisabled={generatingCredentials}
                >
                  <p className={styles.buttonInner}>
                    {t("modals.eventDetail.credentials.credentials.generate")}
                  </p>
                </Button>
              )}

              {checkCredentials() && (
                <>
                  <CopyButton onClick={() => copyAction(CopyType.server)}>
                    <div className={styles.buttonInner}>
                      <p className={styles.text}>
                        {t("modals.eventDetail.credentials.credentials.server")}
                      </p>
                    </div>
                  </CopyButton>
                  {event.currentEvent?.publisherCredentialsDto?.key && (
                    <CopyButton onClick={() => copyAction(CopyType.key)}>
                      <div className={styles.buttonInner}>
                        <p className={styles.text}>
                          {t("modals.eventDetail.credentials.credentials.key")}
                        </p>
                      </div>
                    </CopyButton>
                  )}
                </>
              )}
            </div>
          </Divisor>
        </div>
      </div>
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};

interface DivisorProps {
  headerText: string;
  children?: ReactNode;
}
const Divisor = ({ headerText = "", children }: DivisorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDivisor = () => {
    setIsOpen((previousState) => !previousState);
  };

  return (
    <div
      className={`${styles.DivisorWrapper} ${
        isOpen ? "" : styles.CloseDivisor
      }`}
    >
      <div onClick={toggleDivisor} className={styles.DivisorHeader}>
        <p className={styles["body-14-text"]}>{headerText}</p>
        <ArrowUp className={styles.ArrowIcon} />
      </div>
      <div className={styles.DivisorBody}>{children}</div>
    </div>
  );
};

let copyTimerID: NodeJS.Timeout;

interface CopyButtonProps {
  onClick(): void;
  children: ReactNode;
  width?: string;
}
const CopyButton = ({
  onClick,
  children,
  width = "129px",
}: CopyButtonProps) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const onCopyClickButton = () => {
    onClick();
    setIsActive(true);
    copyTimerID = setTimeout(() => {
      setIsActive(false);
    }, 3000);
  };

  useEffect(() => {
    return () => {
      if (copyTimerID) {
        clearTimeout(copyTimerID);
      }
    };
  }, []);
  return (
    <button
      style={{ width }}
      className={`${styles.CopyButtonWrapper} ${
        isActive ? styles.CopyActive : ""
      }`}
      onClick={onCopyClickButton}
    >
      {!isActive ? (
        children
      ) : (
        <div className={styles.SuccessCopy}>
          <CheckCircleFill className={styles.Icon} />
          <span>
            {t("modals.eventDetail.credentials.credentials.messages.copied")}
          </span>
        </div>
      )}
    </button>
  );
};

export default Credentials;
