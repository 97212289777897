import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import styles from "./NewCustomCalendar.module.scss";
import "./FullCalendarStyles.scss";
import { EventStatus, FiraEventType } from "../../utils/enums";
import { formatIsoLocal, timeFormatGraph } from "../../utils/handleDates";
import { OwnerEventsType, RootState } from "../../utils/types";
import { useState, useEffect } from "react";
import { CheckCircleFill, TimeClockFill } from "../FiraIcons";
import esLocale from "@fullcalendar/core/locales/es";
import enLocale from "@fullcalendar/core/locales/en-gb";
import prLocale from "@fullcalendar/core/locales/pt-br";
import {
  EventContentArg,
  EventClickArg,
  EventMountArg,
  DayHeaderContentArg,
  MoreLinkArg,
  EventSegment,
} from "@fullcalendar/core";
import { useRef } from "react";
import { checkBrowserLanguage } from "../../utils/checkBrowserLanguage";
import ReactDOM from "react-dom";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { EventCreationPopUp } from "../Popups";
import { getEventResume, getEventService } from "../../services/events";
import { useDispatch, useSelector } from "react-redux";
import EventDetailPopUp, {
  PopUpPosition,
} from "../Popups/EventDetailPopUp/EventDetailPopUp";
import { EventResumeResponse } from "../../types/Response";
import EventListPopUp from "../Popups/EventListPopUp/EventListPopUp";
import ModalLayout, {
  ModalLayoutAlign,
} from "../../layout/ModalLayout/ModalLayout";
import {
  cleanMessageActionCreator,
  resetCurrentEventActionCreator,
  setCurrentEventActionCreator,
} from "../../store/Event/EventActions";

export interface CalendarEventType {
  title: string;
  start: string;
  end: string;
  extendedProps: {
    eventId: string;
    status: string;
    eventType: FiraEventType;
    storeLogo: string;
    storeName: string;
    storeColor: string;
    eventStart: string;
    eventEnd: string;
  };
}

interface CustomCalendarProps {
  eventsList: OwnerEventsType[];
  getCalendarApi(api: FullCalendar): void;
}

//getCalendarApi es una funcion para retornar la api de
//el calendario, y asi acceder a las funciones de este desde el padre
//de estra forma se puede aser api.next() por ejemplo y acceder
//a la navegacion

const NewCustomCalendar: React.FC<CustomCalendarProps> = ({
  getCalendarApi,
  eventsList,
}) => {
  const { event } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const calendarRef = useRef<InstanceType<typeof FullCalendar>>(null);
  const api = calendarRef.current && calendarRef.current;

  const [calendarLocale, setCalendarLocale] = useState(enLocale);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEventType[]>([]);
  const [showCreate, setShowCreate] = useState(false);
  const [showEventDetail, setShowEventDetail] = useState(false);
  const [posx, SetPosx] = useState(0);
  const [posy, SetPosy] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] =
    useState<EventResumeResponse | null>();
  const [selectedId, setSelectedId] = useState("");
  const [elementRef, setElementRef] = useState<HTMLElement>();

  //EVENT LIST POP UP
  const [showEventListPopUp, setShowEventListPopUp] = useState<boolean>(false);
  const [eventListForPopUp, setEventListForPopUp] =
    useState<CalendarEventType[]>();
  const [eventListDate, setEventListDate] = useState<Date>();
  const [showEventDetailFromList, setShowEventDetailFromList] =
    useState<boolean>();

  const [deletedId, setDeletedId] = useState<string>("");

  const parseEventsList = () => {
    setCalendarEvents([]);
    eventsList.forEach((event: OwnerEventsType) => {
      setCalendarEvents((current) => [
        ...current,
        {
          title: event.event.eventName,
          start: event.event.startDate
            ? formatIsoLocal(new Date(event.event.startDate))
            : formatIsoLocal(new Date(event.event.scheduledDate)),
          end: formatIsoLocal(new Date(event.event.endDate)),
          extendedProps: {
            eventId: event.event.id,
            status: event.event.status,
            eventType: event.event.eventType,
            storeLogo: event.store.brand ? event.store.brand.logo : "",
            storeName: event.store.name,
            storeColor: event.store.color,
            eventStart: event.event.startDate
              ? event.event.startDate
              : event.event.scheduledDate,
            eventEnd: event.event.endDate,
          },
        },
      ]);
    });
  };

  const handleCalendarlocale = () => {
    const locale = checkBrowserLanguage().code;
    if (locale === "es") {
      setCalendarLocale(esLocale);
    } else if (locale === "en") {
      setCalendarLocale(enLocale);
    } else {
      setCalendarLocale(prLocale);
    }
  };

  const addContent = () => {
    createPopupPortal();
    createPopupPortalForList();
    const elemento = document.getElementsByClassName("ClockIcon")[0];
    const divElement = document.getElementsByClassName("fc-timegrid-axis")[0];
    if (elemento) {
      elemento.remove();
    }
    if (divElement) {
      const nuevoElemento = document.createElement("div");
      nuevoElemento.className = "ClockIcon";
      nuevoElemento.style.height = "100%";
      nuevoElemento.style.display = "flex";
      nuevoElemento.style.justifyContent = "center";
      nuevoElemento.style.alignItems = "center";
      nuevoElemento.style.minHeight = "48px";
      const icon = <TimeClockFill />;
      ReactDOM.render(icon, nuevoElemento);
      divElement.appendChild(nuevoElemento);
    }
  };

  const createPopupPortal = () => {
    setShowEventDetail(false);
    const divElement = document.getElementsByClassName(
      "fc-scroller-harness-liquid"
    )[0];
    divElement.id = "#CalendarDestination";
  };

  const createPopupPortalForList = () => {
    setShowEventDetail(false);
    setShowEventDetailFromList(false);
    const divElement = document.getElementsByClassName("ModalContainer")[0];
    if (divElement) divElement.id = "#ModalDestination";
  };

  const dayFormatheader = (date: Date) => {
    return (
      <div className={styles.dayHeaderFormat}>
        <span>
          {format(date, "EEEE", {
            locale: checkBrowserLanguage(),
          })}
        </span>
      </div>
    );
  };

  const weekFormatHeader = (date: Date) => {
    let weekDay = format(date, "EEE", {
      locale: checkBrowserLanguage(),
    });
    return (
      <div className={styles.Weekformat}>
        <span className={styles.DayText}>
          {weekDay.charAt(0).toUpperCase() + weekDay.slice(1)}
        </span>
        <span className={styles.DayNumber}>
          {format(date, "dd", {
            locale: checkBrowserLanguage(),
          })}
        </span>
      </div>
    );
  };

  const weekFormatSlot = (date: Date) => {
    return (
      <span className={styles.TimeSlot}>
        {format(date, "hh aa", {
          locale: checkBrowserLanguage(),
        })}
      </span>
    );
  };

  const dayFormatSlot = (date: Date) => {
    return (
      <span className={styles.TimeSlot}>
        {format(date, "hh:mm", {
          locale: checkBrowserLanguage(),
        })}
      </span>
    );
  };

  const monthFormatheader = (data: DayHeaderContentArg) => {
    return (
      <div className={styles.MonthFormat}>
        <span>
          {format(data.date, "EEEE", {
            locale: checkBrowserLanguage(),
          })}
        </span>
      </div>
    );
  };

  const handleCalendarClick = (data: DateClickArg) => {
    //Selected date
    setShowEventDetail(false);
    handleCleanCoordinates();
    const [selyear, selmonth, selday] = data.dateStr.split("-");
    const seldate = new Date(
      parseInt(selyear),
      parseInt(selmonth) - 1,
      parseInt(selday)
    );

    //Todays date
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    if (data.dateStr >= formattedDate) {
      const selected = new Date(seldate.toISOString());
      const clickData = data.jsEvent;

      setSelectedDate(selected);

      handleShowCreate(clickData);
    } else {
      return;
    }
  };

  const handleOverlapingPercent = (data: EventMountArg) => {
    //para manipular la posicion de el evento overlaping
    const principalParent = data.el.offsetParent as HTMLElement;
    //de el principal obtenemos el porcenjate de separacion de la izquierda
    const EventParentLeft = principalParent && principalParent.style.left;
    if (EventParentLeft && EventParentLeft !== "0%") {
      //si esta separado de la izquierda, parseamos el porcentaje,
      //reducimos el porcentaje y se lo seteamos al principal
      //esto con la finalidad, de que encaje con lo soliciado por diseño
      const parsedPercentage = parseFloat(
        principalParent.style.left.replace("%", "")
      );
      let result = parsedPercentage - parsedPercentage / 3;
      const formattedResult = `${result}%`;
      principalParent.style.left = formattedResult;
      principalParent.style.width = "auto";
    }
  };

  const handleShowCreate = (data: any) => {
    SetPosx(data.x);
    SetPosy(data.y);
    setElementRef(data.toElement);
    setShowCreate(true);
  };

  const handleSelectEvent = async (data: EventClickArg) => {
    setElementRef(data.el);

    const instance = data.jsEvent;
    const event = data.event["_def"].extendedProps;
    setShowEventDetail(false);
    handleCloseCreate();
    await getEvent(event.eventId);
    SetPosx(instance.x);
    SetPosy(instance.y);
    setShowEventDetail(true);
    handleCurrentEvent(event.eventId);
  };

  const getEvent = async (id: string) => {
    try {
      const response = await getEventResume(id);
      setSelectedEvent(response.data);
      setSelectedId(id);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleCurrentEvent = (id: string) => {
    getEventService(id).then((val) => {
      if (val) {
        dispatch(setCurrentEventActionCreator(val.data));
      }
    });
  };

  const handleCreateSuccess = () => {
    handleCloseCreate();
  };

  const handleCloseCreate = () => {
    handleCleanCoordinates();
    setShowCreate(false);
  };

  const handleCloseDetails = () => {
    setShowEventDetail(false);
    handleCleanCoordinates();
  };
  const handleCleanCoordinates = () => {
    SetPosx(0);
    SetPosy(0);
  };

  const handleEventListPopulation = async (
    data: CalendarEventType[],
    date: Date
  ) => {
    setShowCreate(false);
    setShowEventDetail(false);
    setEventListForPopUp(data);
    setEventListDate(date);
    setShowEventListPopUp(true);
    createPopupPortalForList();
  };

  const handleEventListPopUp = (data: MoreLinkArg) => {
    setShowEventDetail(false);
    setShowCreate(false);
    handleCleanCoordinates();

    const all: EventSegment[] = data.allSegs;
    const eventListTemp: CalendarEventType[] = [];
    if (all) {
      all.map((event: EventSegment) => {
        const tempEvent: CalendarEventType = {
          title: event.event.title,
          start: String(event.start),
          end: String(event.end),
          extendedProps: {
            eventId: event.event.extendedProps.eventId,
            status: event.event.extendedProps.status,
            eventType: event.event.extendedProps.eventType,
            storeLogo: event.event.extendedProps.storeLogo,
            storeName: event.event.extendedProps.storeName,
            storeColor: event.event.extendedProps.storeColor,
            eventStart: event.event.extendedProps.eventStart,
            eventEnd: event.event.extendedProps.eventEnd,
          },
        };
        eventListTemp.push(tempEvent);
      });
      handleEventListPopulation(eventListTemp, data.date);
    }
  };

  const handleSelectEventFromList = (event: CalendarEventType) => {
    setSelectedEvent(null);
    getEvent(event.extendedProps.eventId);
    handleCurrentEvent(event.extendedProps.eventId);
    setShowEventDetailFromList(true);
  };

  const handleEventListClose = () => {
    setShowEventDetailFromList(false);
    setShowEventListPopUp(false);
  };

  const removeDeleted = (id: string) => {
    const newList = eventListForPopUp?.filter(
      (p) => p.extendedProps.eventId !== id
    );
    setEventListForPopUp(newList);
    setDeletedId("");
    setSelectedEvent(null);
    dispatch(resetCurrentEventActionCreator());
  };

  useEffect(() => {
    handleCalendarlocale();
  }, []);

  useEffect(() => {
    parseEventsList();
  }, [eventsList]);

  useEffect(() => {
    api && getCalendarApi(api);
    if (api) {
      api.getApi().setOption("firstDay", 0);
    }
  }, [api]);

  useEffect(() => {
    setShowEventDetail(false);
    handleCleanCoordinates();
  }, [api?.getApi().view]);

  useEffect(() => {
    if (event.createSuccess) {
      handleCreateSuccess();
    }
  }, [event.createSuccess]);

  useEffect(() => {
    if (event.cancelSuccess) {
      setShowEventDetail(false);
      setShowEventDetailFromList(false);
    }
  }, [event.cancelSuccess]);

  useEffect(() => {
    if (deletedId.length !== 0) {
      removeDeleted(deletedId);
    }
  }, [deletedId]);

  return (
    <div className={styles.CustomFullCalendarWrapper}>
      {showEventDetailFromList && selectedEvent && (
        <>
          <EventDetailPopUp
            eventInfo={selectedEvent}
            x={0}
            y={0}
            onClose={() => setShowEventDetailFromList(false)}
            customPosition={PopUpPosition.relative}
            elementRef={elementRef}
            customDestination="#ModalDestination"
            eventId={selectedId}
            handleDeleted={setDeletedId}
          />
        </>
      )}
      {showEventListPopUp && (
        <>
          <ModalLayout align={ModalLayoutAlign.right}>
            <div
              className="ModalContainer"
              style={{
                width: "280px",
                marginRight: "14px",
              }}
            ></div>
            <EventListPopUp
              onClose={handleEventListClose}
              selectEvent={handleSelectEventFromList}
              eventList={eventListForPopUp}
              day={eventListDate}
            />
          </ModalLayout>
        </>
      )}
      <FullCalendar
        height={"100%"}
        eventBackgroundColor={"#fff0"}
        ref={calendarRef}
        plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}
        events={calendarEvents}
        initialEvents={eventsList}
        headerToolbar={false}
        allDaySlot={false}
        dateClick={(data) => handleCalendarClick(data)}
        eventClick={(data) => handleSelectEvent(data)}
        locale={calendarLocale}
        moreLinkClick={(data) => {
          handleEventListPopUp(data);
        }}
        viewDidMount={createPopupPortal}
        views={{
          day: {
            viewDidMount: addContent,
            titleFormat: {
              weekday: "long",
              month: "short",
              day: "2-digit",
            },
            slotLabelInterval: "00:30:00",
            slotDuration: "00:30:00",
            slotLabelContent: (data) => dayFormatSlot(data.date),
            dayHeaderContent: (data) => dayFormatheader(data.date),
            eventMaxStack: 6,
            moreLinkContent: (data) => {
              return (
                <div className={styles.CustomMoreEvents}>
                  <span className={styles.MoreEventsText}>
                    {data.num + "+"}
                  </span>
                </div>
              );
            },
            eventContent: FiraOwnerCalendarEvent,
            eventDidMount: handleOverlapingPercent,
          },
          week: {
            viewDidMount: addContent,
            titleFormat: {
              month: "short",
              day: "2-digit",
            },
            slotDuration: "00:60:00",
            dayHeaderContent: (data) => weekFormatHeader(data.date),
            slotLabelContent: (data) => weekFormatSlot(data.date),
            eventMaxStack: 5,
            moreLinkContent: (data) => {
              return (
                <div className={styles.CustomMoreEvents}>
                  <span className={styles.MoreEventsText}>
                    {data.num + "+"}
                  </span>
                </div>
              );
            },
            eventContent: FiraOwnerCalendarEvent,
            eventDidMount: handleOverlapingPercent,
          },
          dayGrid: {
            dayHeaderFormat: { weekday: "long" },
            dayMaxEventRows: 5,
            moreLinkContent: (data) => {
              return data.num + t("views.events.calendar.showMore");
            },
            eventContent: FiraCalendarMonthEvent,
            dayHeaderContent: (data) => monthFormatheader(data),
          },
        }}
      />

      {showCreate && (
        <EventCreationPopUp
          x={posx}
          y={posy}
          onClose={handleCloseCreate}
          startDate={selectedDate}
          customDestination="#CalendarDestination"
          elementRef={elementRef}
        />
      )}
      {showEventDetail && selectedEvent && (
        <>
          <EventDetailPopUp
            eventInfo={selectedEvent}
            x={posx}
            y={posy}
            onClose={handleCloseDetails}
            customPosition={PopUpPosition.cardinal}
            elementRef={elementRef}
            customDestination="#CalendarDestination"
            eventId={selectedId}
          />
        </>
      )}
    </div>
  );
};

const FiraCalendarMonthEvent = (data: EventContentArg) => {
  const eventData = data.event;
  const otherProps = data.event.extendedProps;

  let eventIsTest = otherProps.eventType === "TEST";
  let eventStatus = otherProps.status;

  return (
    <div
      className={`${styles.FiraCalendarMonthEventWrapper} ${styles["alert-text"]} `}
    >
      <div
        className={`${styles.firaCalendarCircle}
        ${
          eventStatus === EventStatus.DONE && !eventIsTest
            ? styles.isFinished
            : ""
        } ${eventIsTest ? styles.eventTest : ""}`}
      ></div>
      <span className={styles.text}>{eventData.title} </span>
    </div>
  );
};

enum ContainerType {
  small = "SMALL",
  medium = "MEDIUM",
  big = "BIG",
}
interface TranslateProps {
  text: string;
}

const TranslatedText: React.FC<TranslateProps> = ({ text }) => {
  const { t } = useTranslation();
  return <>{t(text)}</>;
};

const FiraOwnerCalendarEvent = (data: EventContentArg) => {
  const eventData = data.event;
  const otherProps = data.event.extendedProps;
  const eventIsTest = otherProps.eventType === "TEST";
  const eventStatus = otherProps.status as EventStatus;
  const words: string[] = otherProps.storeName.split(" ");
  const storeInitials =
    words[0].charAt(0).toUpperCase() +
    (words.length > 1 ? words[1].charAt(0).toUpperCase() : "");

  //tipo de tamaño contenedor, define el estilo de el evento
  let containerType = ContainerType.small;

  const child = document.getElementById(otherProps.eventId);
  const parent = child && (child.offsetParent as HTMLElement);

  //aqui se verifica el tamaño de el evento, para colocar que tipo es
  if (parent && parent.offsetHeight < 90 && parent.offsetHeight > 50) {
    containerType = ContainerType.medium;
  } else if (parent && parent.offsetHeight < 50) {
    containerType = ContainerType.small;
  } else {
    containerType = ContainerType.big;
  }

  return (
    <div
      id={otherProps.eventId}
      className={`${styles.FiraOwnerCalendarEvent}  ${styles["alert-text"]} 
      ${eventIsTest && eventIsTest ? styles.EventTest : styles.NormalEvent}
      ${
        eventStatus === EventStatus.DONE ||
        eventStatus === EventStatus.CANCELLED
          ? styles.isFinished
          : ""
      }
      ${styles[containerType]}
      `}
    >
      <div
        className={styles.StoreLogo}
        style={{ backgroundColor: otherProps.storeColor }}
      >
        {containerType !== ContainerType.small && storeInitials}
      </div>
      <div className={styles.ElementGroup}>
        <div className={styles.title}>{eventData.title}</div>
        {containerType === ContainerType.big && (
          <div className={styles.eventState}>
            <CheckCircleFill className={styles.icon} />
            <p className={styles.state}>
              {eventIsTest
                ? {
                    [EventStatus.SCHEDULED]: (
                      <TranslatedText
                        text={"views.events.status.test.scheduled"}
                      />
                    ),
                    [EventStatus.NOT_STARTED]: (
                      <TranslatedText
                        text={"views.events.status.test.not_started"}
                      />
                    ),
                    [EventStatus.STARTED]: (
                      <TranslatedText
                        text={"views.events.status.test.started"}
                      />
                    ),
                    [EventStatus.DONE]: (
                      <TranslatedText text={"views.events.status.test.done"} />
                    ),
                    [EventStatus.CANCELLED]: (
                      <TranslatedText
                        text={"views.events.status.test.cancelled"}
                      />
                    ),
                  }[eventStatus]
                : {
                    [EventStatus.SCHEDULED]: (
                      <TranslatedText
                        text={"views.events.status.broadcast.scheduled"}
                      />
                    ),
                    [EventStatus.NOT_STARTED]: (
                      <TranslatedText
                        text={"views.events.status.broadcast.not_started"}
                      />
                    ),
                    [EventStatus.STARTED]: (
                      <TranslatedText
                        text={"views.events.status.broadcast.started"}
                      />
                    ),
                    [EventStatus.DONE]: (
                      <TranslatedText
                        text={"views.events.status.broadcast.done"}
                      />
                    ),
                    [EventStatus.CANCELLED]: (
                      <TranslatedText
                        text={"views.events.status.broadcast.cancelled"}
                      />
                    ),
                  }[eventStatus]}
            </p>
          </div>
        )}
        {containerType !== ContainerType.small && (
          <div className={styles.time}>
            {eventData.start && timeFormatGraph(eventData.start)}
            {eventData.end &&
              "- " + (eventData.end && timeFormatGraph(eventData.end))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewCustomCalendar;
