import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgLeftArrowNoPadding = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 11 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10.171 15.31c.641.62.641 1.624.022 2.266-.32.32-.727.47-1.154.47-.406 0-.812-.15-1.111-.47L.47 10.16a1.601 1.601 0 0 1 0-2.265L7.928.48c.62-.641 1.624-.641 2.265 0 .62.62.62 1.645-.022 2.265L3.868 9.028l6.303 6.282Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLeftArrowNoPadding;
