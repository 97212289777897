import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  addVODToCollectionService,
  createCollectionService,
  deleteCollectionService,
  deleteVODFromCollectionService,
  editCollectionService,
  getCollectionsService,
  getVODSFromCollectionService,
  updateCollectionImageService,
} from "../../services/collections";
import { handleError, RequestError } from "../../services/errorHandling";
import {
  CollectionBasicData,
  EditCollectionResponse,
  FiraCollection,
  FiraSimpleCollection,
  FiraVideo,
  VideoFilter,
} from "../../utils/types";
import { CollectionActions, EventActions } from "../actionsContants";

interface GetColletionsActionType {
  type:
    | CollectionActions.getCollectionsLoading
    | CollectionActions.getCollectionsSuccess
    | CollectionActions.getCollectionsFailed
    | CollectionActions.resetCollectionList;
  payload?: FiraCollection[] | RequestError;
}

export const getCollectionsActionCreator = (
  storeId: string,
  limit: number,
  offSet: number,
  sortType: VideoFilter
) => {
  return async (
    dispatch: ThunkDispatch<
      GetColletionsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: CollectionActions.getCollectionsLoading });
      const response = await getCollectionsService(
        storeId,
        limit,
        offSet,
        sortType
      );

      dispatch({
        type: CollectionActions.getCollectionsSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CollectionActions.getCollectionsFailed,
        payload: handleError(error),
      });
    }
  };
};

interface ResetCollectionListActionType {
  type: CollectionActions.resetCollectionList;
}

export const resetCollectionListActionCreator =
  (): ResetCollectionListActionType => {
    return { type: CollectionActions.resetCollectionList };
  };

interface CurrentCollectionActionType {
  type: CollectionActions.setCurrentCollection;
  payload: FiraCollection;
}

export const setCurrentCollectionActionCreator = (
  collection: FiraCollection
): CurrentCollectionActionType => {
  return {
    type: CollectionActions.setCurrentCollection,
    payload: collection,
  };
};

interface ResetCurrentCollectionActionType {
  type: CollectionActions.resetCurrentCollection;
}

export const resetCurrentCollectionActionCreator =
  (): ResetCurrentCollectionActionType => {
    return { type: CollectionActions.resetCurrentCollection };
  };

interface CreateCollectionActionType {
  type:
    | CollectionActions.createCollectionLoading
    | CollectionActions.createCollectionSuccess
    | CollectionActions.createCollectionFailed;
  payload?: FiraCollection | RequestError;
}

export const createCollectionActionCreator = (
  collection: FiraSimpleCollection,
  file: File
) => {
  return async (
    dispatch: ThunkDispatch<
      CreateCollectionActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: CollectionActions.createCollectionLoading });

      const response = await createCollectionService(collection, file);
      dispatch({
        type: CollectionActions.createCollectionSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CollectionActions.createCollectionFailed,
        payload: handleError(error),
      });
    }
  };
};

interface DeleteCollectionActionType {
  type:
    | CollectionActions.deleteCollectionLoading
    | CollectionActions.deleteCollectionSuccess
    | CollectionActions.deleteCollectionFailed;
  payload?: FiraCollection | RequestError;
}

export const deleteCollectionActionCreator = (collectionId: string) => {
  return async (
    dispatch: ThunkDispatch<
      DeleteCollectionActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: CollectionActions.deleteCollectionLoading });
      const response = await deleteCollectionService(collectionId);
      dispatch({
        type: CollectionActions.deleteCollectionSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CollectionActions.deleteCollectionFailed,
        payload: handleError(error),
      });
    }
  };
};

interface EditCollectionActionType {
  type:
    | CollectionActions.editCollectionLoading
    | CollectionActions.editCollectionSuccess
    | CollectionActions.editCollectionFailed;
  payload?: EditCollectionResponse | RequestError;
}

export const editCollectionActionCreator = (
  collectionId: string,
  collection: CollectionBasicData,
  videosToDelete?: string[],
  videosToAdd?: string[],
  image?: File
) => {
  return async (
    dispatch: ThunkDispatch<
      CollectionActions,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({
        type: CollectionActions.editCollectionLoading,
      });

      if (image) {
        const res = await updateCollectionImageService(collectionId, image);
      }

      if (videosToAdd && videosToAdd?.length > 0) {
        await addVODToCollectionService(collectionId, videosToAdd);
      }

      const response = await editCollectionService(collectionId, collection);

      if (videosToDelete && videosToDelete.length > 0) {
        await deleteVODFromCollectionService(collectionId, videosToDelete);
      }

      dispatch({
        type: CollectionActions.editCollectionSuccess,
        payload: response.data as EditCollectionResponse,
      });
    } catch (error) {
      dispatch({
        type: CollectionActions.editCollectionFailed,
        payload: handleError(error),
      });
    }
  };
};

interface GetVODSByActionType {
  type:
    | CollectionActions.sortCollectionVideosByLoading
    | CollectionActions.sortCollectionVideosByFailed
    | CollectionActions.sortCollectionVideosBySuccess;
  payload?: FiraCollection | RequestError;
}

export const getVODByActionCreator = (collectionId: string, sortBy: string) => {
  return async (
    dispatch: ThunkDispatch<
      GetVODSByActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: CollectionActions.sortCollectionVideosByLoading });
      const response = await getVODSFromCollectionService(collectionId, sortBy);

      dispatch({
        type: CollectionActions.sortCollectionVideosBySuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CollectionActions.sortCollectionVideosByFailed,
        payload: handleError(error),
      });
    }
  };
};

export type CollectionActionType =
  | GetColletionsActionType
  | ResetCollectionListActionType
  | CurrentCollectionActionType
  | ResetCurrentCollectionActionType
  | CreateCollectionActionType
  | DeleteCollectionActionType
  | EditCollectionActionType
  | GetVODSByActionType;
