import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";
import styles from "./ToggleInput.module.scss";

export enum ToggleInputType {
  videoSort = "SORT_VIDEOS",
  videoFilter = "FILTER_VIDEOS",
}

interface ToggleInputProps {
  label: string;
  checked: boolean;
  onChange(value: ToggleInputType): void;
  value: ToggleInputType;
}

const ToggleInput = ({ label, checked, onChange, value }: ToggleInputProps) => {
  return (
    <div className={styles.ToggleInputWrapper}>
      <label className={styles.ToggleInputWrapper__Label}>{label}</label>
      <div className={styles.ToggleInputWrapper__Toggle}>
        <ToggleSwitch
          handleChecked={() => onChange(value)}
          checked={checked}
          hideTags
        />
      </div>
    </div>
  );
};

export default ToggleInput;
