import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgFullScreen = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M18.065 0h2.008v6h-2.008V2h-4.014V0h4.014ZM2.007 0h4.015v2H2.007v4H0V0h2.007Zm16.058 16v-4h2.008v6H14.05v-2h4.014ZM2.007 16h4.015v2H0v-6h2.007v4Z"
      fill="#fff"
      fillOpacity={0.3}
    />
  </svg>
);

export default SvgFullScreen;
