import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AddImageButton, Checkbox, ProductImage } from "../../../components";
import Button, { ButtonStyle } from "../../../components/Button/Button";
import SimpleCloseX from "../../../components/FiraIcons/SimpleCloseX";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import {
  resetUpdateImageProductActionCreator,
  updateImagesProductActionCreator,
} from "../../../store/Products/ProductsActions";
import { useProductContext } from "../../../store/Products/ProductVariationsContext";
import { RootState } from "../../../store/Store";
import { ImageProductObject } from "../../../utils/types";
import { SortableList } from "../DetailProductView";
import style from "./ModalImageVariations.module.scss";

interface ModalImageVariationProps {
  onClose(): void;
  onImageSave(imageProduct: string): void;
}
const fileBlobToURL = (file: File): string => {
  return URL.createObjectURL(file);
};
const ModalImageVariation = ({
  onClose,
  onImageSave,
}: ModalImageVariationProps) => {
  const { t } = useTranslation();
  const { products } = useSelector((state: RootState) => state);
  const [imagesList, setImagesList] = useState<ImageProductObject[]>([]);
  const [images, setImages] = useState<File[]>([]);
  const inputImageRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [isReadySave, setIsReadySave] = useState(false);
  const [isClickSave, setIsClickSave] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );

  const handleImageSelect = (index: number) => {
    const updatedImagesList = imagesList.map((image) => ({
      ...image,
      selected: image.id === index,
    }));

    setImagesList(updatedImagesList);
    setSelectedImageIndex(index);
  };

  const handleAddImageButtonClick = () => {
    if (imagesList.length < 10) {
      inputImageRef.current?.click();
    }
  };

  const handleSave = () => {
    setIsClickSave(true);
    const selectedImage = imagesList.find((item) => item.selected);

    // Si hay una imagen seleccionada, envía la información al componente padre
    if (selectedImage && !selectedImage?.isNew) {
      onImageSave(selectedImage.imageUrl);
      setIsClickSave(false);
      onClose();
    }

    // Si la imagen seleccionada es nueva, realiza la acción de dispatch
    if (selectedImage?.isNew) {
      products.currentProduct.id &&
        dispatch(
          updateImagesProductActionCreator(
            products.currentProduct.id,
            true,
            false,
            false,
            [selectedImage]
          )
        );
    }
  };

  const addImageToUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setIsReadySave(true);
    const { files } = event.target;
    if (files) {
      const fileArray = Array.from(files);
      if (fileArray.length <= 10) {
        setImages((prevState) => [...prevState, ...fileArray]);

        fileArray.map((file, i) => {
          setImagesList((prevState) => [
            ...prevState,
            {
              id: prevState.length,
              imageUrl: fileBlobToURL(file),
              image: file,
              isDelete: false,
              isPrimary: false,
              selected: false,
              isNew: true,
            },
          ]);
        });
      }
    }
  };

  useEffect(() => {
    //Carga inicial de imagenes
    let fileAux: File;
    products.currentProduct.images.map((img, index) => {
      setImagesList((prevState) => [
        ...prevState,
        {
          id: index,
          imageUrl: img,
          image: fileAux,
          isDelete: false,
          isPrimary: false,
          selected: false,
          isNew: false,
        },
      ]);
    });
  }, []);

  useEffect(() => {
    if (products.imagesChangeSuccess) {
      let archivo: File;

      const imageObject = products.currentProduct.images.map((img, index) => {
        return {
          id: index,
          imageUrl: img,
          image: archivo,
          isDelete: false,
          isPrimary: false,
          selected: false,
          isNew: false,
        };
      });

      setImagesList(imageObject);

      dispatch(resetUpdateImageProductActionCreator());

      if (isClickSave) {
        const selectedImage = imagesList.find((item) => item.selected);

        const size = products.currentProduct.images.length;

        if (selectedImage) {
          onImageSave(products.currentProduct.images[size - 1]);
          setIsClickSave(false);
          onClose();
        }
      }
    }
  }, [products.imagesChangeSuccess]);

  return (
    <ModalLayout>
      <div className={style.DialogWrapper}>
        <div className={style.HeaderDialog}>
          <label style={{ marginLeft: "16px" }}>
            {t("views.products.detailProduct.addImageVar")}
          </label>
          <span
            onClick={() => onClose()}
            style={{ cursor: "pointer", marginRight: "16px" }}
          >
            <SimpleCloseX />
          </span>
        </div>
        <div
          className={`${style.ProductImages} ${
            imagesList.length > 3 ? style["columnMode"] : ""
          }`}
        >
          <input
            ref={inputImageRef}
            type={"file"}
            style={{ display: "none" }}
            onChange={addImageToUpload}
            multiple
            accept="image/*"
          />
          <div
            className={`${style.ProductImageList} ${
              imagesList.length > 4 ? style["columnMode"] : ""
            }`}
            style={imagesList.length > 4 ? { flexWrap: "wrap" } : {}}
          >
            {imagesList.map((image) => (
              <div
                key={image.id}
                style={{ position: "relative", margin: "8px" }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    cursor: "pointer",
                  }}
                >
                  <Checkbox
                    isActive={selectedImageIndex === image.id}
                    clickHandler={() => handleImageSelect(image.id)}
                    stopPropagation={true}
                  />
                </div>

                <img
                  src={image.imageUrl}
                  alt={`Image ${image.id + 1}`}
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            ))}
          </div>

          <AddImageButton clickHandler={handleAddImageButtonClick} />
        </div>
        <div className={style.FooterDialog}>
          <Button
            width="105px"
            height="32px"
            clickHandler={() => onClose()}
            buttonStyle={ButtonStyle.border}
          >
            <span className="lead-text">
              {t("views.products.detailProduct.cancel")}
            </span>
          </Button>
          <Button
            width="105px"
            height="32px"
            clickHandler={(e) => handleSave()}
            buttonStyle={ButtonStyle.main}
            isDisabled={products.isLoading || selectedImageIndex === null}
          >
            <span className="lead-text">
              {t("views.products.detailProduct.ready")}
            </span>
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ModalImageVariation;
