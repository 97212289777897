import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { RootState } from "../../utils/types";
import {
  ArrowLeft,
  CrossTimer,
  ErrorBubble,
  FiraLiveLogo,
  SuccessBubble,
} from "../FiraIcons";
import Tabs from "../Tabs/Tabs";
import "./AuthHeader.scss";

export enum ViewTypes {
  login = "/",
  register = "/register",
  recovery = "/recovery",
  pwdcode = "/pwdcode",
  changepwd = "/changepwd",
  activate = "/activate",
}

interface AuthHeaderProps {
  view?: ViewTypes;
}

let timerID: NodeJS.Timeout;

const AuthHeader = (props: AuthHeaderProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { view = ViewTypes.login } = props;
  const { authentication, registration, recovery } = useSelector(
    (state: RootState) => state
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showBackButton, setShowBackButton] = useState(false);
  const [showTabs, setShowTabs] = useState(true);
  const [invitationLogo, setInvitationLogo] = useState<string>("");
  const [referer, setReferer] = useState<string>("");
  const and = "&";
  const [invitationLogoStyle, setInvitationLogoStyle] = useState({
    backgroundImage: "",
    backgroundSize: "cover",
  });

  const handleCloseAlert = () => {
    if (showAlert) {
      clearTimeout(timerID);
      setShowAlert(false);
    }
  };

  const setAlertMessageToShow = (message: string) => {
    setShowAlert(true);
    setAlertMessage(message);
    timerID = setTimeout(() => {
      setShowAlert(false);
    }, 8000);
  };

  useEffect(() => {
    if (authentication.error) {
      setAlertMessageToShow(authentication.error.message);
    } else if (registration.registerError) {
      setAlertMessageToShow(registration.registerError.message);
    } else if (registration.activationError) {
      setAlertMessageToShow(registration.activationError.message);
    } else if (recovery.recoveryError) {
      setAlertMessageToShow(recovery.recoveryError.message);
    } else if (recovery.validationError) {
      setAlertMessageToShow(recovery.validationError.message);
    }
  }, [
    authentication.error,
    registration.registerError,
    registration.activationError,
    recovery.recoveryError,
    recovery.validationError,
  ]);

  useEffect(() => {
    handleCloseAlert();
    if (view === ViewTypes.recovery) {
      setShowBackButton(true);
      setShowTabs(false);
    } else if (
      view === ViewTypes.pwdcode ||
      view === ViewTypes.changepwd ||
      view === ViewTypes.activate
    ) {
      setShowTabs(false);
      setShowBackButton(false);
    } else {
      setShowBackButton(false);
      setShowTabs(true);
    }
  }, [view]);

  useEffect(() => {
    if (searchParams) {
      const logo = searchParams.get("logo");
      const ref = searchParams.get("referer");
      if (logo) {
        setInvitationLogo(logo);
      } else {
        setInvitationLogo("");
      }
      if (ref) {
        setReferer(ref);
      } else {
        setReferer("");
      }
    }
  }, [view]);

  useEffect(() => {
    if (invitationLogo) {
      setInvitationLogoStyle({
        ...invitationLogoStyle,
        backgroundImage: `url(${invitationLogo})`,
      });
    }
  }, [invitationLogo]);
  const TitleCase = (param: ViewTypes) => {
    switch (param) {
      case ViewTypes.login:
        return t("auth.header.welcome_back");
      case ViewTypes.register:
        return t("auth.header.sign_up");
      case ViewTypes.recovery:
        return t("auth.header.recovery");
      case ViewTypes.pwdcode:
        return t("auth.header.pwdcode");
      case ViewTypes.changepwd:
        return t("auth.header.changepwd");
      case ViewTypes.activate:
        return t("auth.header.activate");
    }
  };

  return (
    <div className="HeaderWrapper">
      {showBackButton && (
        <div className="BackButton">
          <Link to={ViewTypes.login}>
            <ArrowLeft />
          </Link>
        </div>
      )}
      <div className="Logo">
        <FiraLiveLogo />
        {invitationLogo !== "" && view === "/register" && (
          <>
            <h4>{and}</h4>
            <div className="InvitationLogo" style={invitationLogoStyle}></div>
          </>
        )}
      </div>
      {referer != "" && view === "/register" && (
        <div className="TitleMessage">
          <span className="lead-text">
            {t("auth.header.invitation_message").replace(
              "{referer}",
              `${referer}`
            )}
          </span>
        </div>
      )}
      {referer === "" && view === "/" && (
        <div className="Title">
          <div className="h4">{TitleCase(view)}</div>
        </div>
      )}

      {showAlert && (
        <AuthHeaderAlerts
          message={alertMessage}
          closeHandler={handleCloseAlert}
        />
      )}
      {/* {showTabs && <Tabs />} */}
    </div>
  );
};

/** Auth Alerts */
export enum AlertTypes {
  info = "info",
  danger = "danger",
}

export enum ErrorMessages {
  username_not_found = "User not found for this username",
  email_not_found = "User not found for this email",
  not_activated = "USER_NOT_ACTIVATED",
  invalid_password = "Invalid password",
  unavailable_user = "Error: email or username already exist",
  invalid_user_code = "INVALID_USER_CODE",
  expired_user_code = "EXPIRED_CODE",
}

interface AuthHeaderAlertsProps {
  type?: AlertTypes;
  message?: string;
  closeHandler?(): void;
}
const AuthHeaderAlerts = (props: AuthHeaderAlertsProps) => {
  const { t } = useTranslation();

  const { type = AlertTypes.danger, message, closeHandler } = props;

  const MessageCase = (param?: string) => {
    switch (param) {
      case ErrorMessages.username_not_found:
        return (
          <div className="alert-text">
            {t("auth.messages.error.username_not_found")}.{" "}
            <Link to={"/register"} className="alert-text-bold danger">
              {t("auth.messages.error.create_account")}
            </Link>
          </div>
        );

      case ErrorMessages.email_not_found:
        return (
          <div className="alert-text">
            {t("auth.messages.error.username_not_found")}.{" "}
            <Link to={"/register"} className="alert-text-bold danger">
              {t("auth.messages.error.create_account")}
            </Link>
          </div>
        );

      case ErrorMessages.not_activated:
        return (
          <div className="alert-text">
            {t("auth.messages.error.username_not_found")}.{" "}
            <Link to={"/register"} className="alert-text-bold danger">
              {t("auth.messages.error.create_account")}
            </Link>
          </div>
        );

      case ErrorMessages.invalid_password:
        return (
          <div className="alert-text">
            {t("auth.messages.error.invalid_password")}.{" "}
            <Link to={"/recovery"} className="alert-text-bold danger">
              {t("auth.messages.error.wrong_credentials")}
            </Link>
          </div>
        );

      case ErrorMessages.unavailable_user:
        return (
          <div className="alert-text">
            {t("auth.messages.error.unavailable_user")}.{" "}
            <Link to={"/recovery"} className="alert-text-bold danger">
              {t("auth.messages.error.recover_password")}
            </Link>
          </div>
        );
      case ErrorMessages.invalid_user_code:
        return (
          <div className="alert-text">
            {t("auth.messages.error.invalid_user_code")}.{" "}
            <span className="alert-text-bold danger">
              {t("auth.messages.error.try_again")}.
            </span>
          </div>
        );
      case ErrorMessages.expired_user_code:
        return (
          <div className="alert-text">
            {t("auth.messages.error.expired_user_code")},{" "}
            <span className="alert-text-bold danger">
              {t("auth.messages.error.request_new_code")}.
            </span>
          </div>
        );
      default:
        return (
          <div className="alert-text">{t("auth.messages.error.default")}.</div>
        );
    }
  };

  return (
    <div className={`alert-container ${type}`}>
      <div className="icon-bubble">
        {type === "danger" ? <ErrorBubble /> : <SuccessBubble />}
      </div>
      <div className="alert-message">{MessageCase(message)}</div>
      <button onClick={closeHandler} className="button-alert">
        <CrossTimer />
      </button>
    </div>
  );
};

export default AuthHeader;
