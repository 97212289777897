import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import { InvitationType } from "../../../utils/types";
import { validateEmail } from "../../../utils/validateEmail";
import styles from "./FiraInvitationForm.module.scss";

interface FiraInvitationFormProps {
  handleNext(): void;
  onChange(value: string, name: string): void;
  invitation?: InvitationType;
}

const FiraInvitationForm = ({
  handleNext,
  onChange,
  invitation,
}: FiraInvitationFormProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>(invitation?.name || "");
  const [email, setEmail] = useState<string>(invitation?.userEmail || "");
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    name === "name" ? setName(value) : setEmail(value);
    onChange(value, name);
  };

  const handleValidation = () => {
    if (name !== "" && email !== "" && validateEmail(email)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    handleValidation();
  }, [name, email]);

  return (
    <>
      <div className={styles.InvitationForm}>
        <p className={styles.Title}>{t("views.operators.invitation_title")}</p>
        <p className={styles.Desc}>{t("views.operators.invitation_desc")}</p>
        <div className={styles.Form}>
          <div className={styles.FormItem}>
            <label htmlFor="">{t("views.operators.name")}</label>
            <Input
              width="100%"
              height="40px"
              onChange={handleChange}
              value={name}
              inputName={"name"}
            />
          </div>
          <div className={styles.FormItem}>
            <label htmlFor="">{t("views.operators.email")}</label>
            <Input
              width="100%"
              height="40px"
              onChange={handleChange}
              value={email}
              inputName={"email"}
            />
          </div>
        </div>
        <div className={styles.Button}>
          <Button
            width="117px"
            height="32px"
            clickHandler={handleNext}
            isDisabled={!isValid}
          >
            <span className={styles.Inner}>{t("views.operators.nextBtn")}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FiraInvitationForm;
