import React from "react";
import { Icon, IconifyIconHTMLElement } from "@iconify-icon/react";
import * as Popper from "react-popper";

interface Props {
  text: string;
}
const InfoHelpTooltip: React.FC<Props> = ({ text }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [referenceElement, setReferenceElement] =
    React.useState<IconifyIconHTMLElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = React.useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = Popper.usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["top", "right"],
          },
        },
        { name: "arrow", options: { element: arrowElement } },
      ],
    }
  );

  return (
    <div
      style={wrapper}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <Icon ref={setReferenceElement} icon={"simple-line-icons:info"} />
      {showTooltip && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div style={tooltip}>
            {text}
            <div ref={setArrowElement} style={styles.arrow} />
          </div>
        </div>
      )}
    </div>
  );
};

const wrapper: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const tooltip: React.CSSProperties = {
  position: "relative",
  padding: 8,
  maxWidth: 200,
  background: "#fff",
  boxShadow: "2px 22px 117px -41px rgba(0,0,0,0.75)",
  border: "1px solid #E7E7E7",
  borderRadius: 10,
  fontSize: 10,
  fontWeight: 400,
};

export default InfoHelpTooltip;
