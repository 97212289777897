import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import stylesheet from "./FiraChat.module.scss";
import { ChatStatus, useChatContext } from "./providers/ChatProvider";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import MessageList from "./components/MessageList/MessageList";
import InputMessage from "./components/InputMessage/InputMessage";
import MessageItem from "./components/MessageItem/MessageItem";
import { ContextMenuProvider } from "./providers/ContextMenuProvider";
import useScreenWidth from "./hooks/useScreenWidth";
import { getTextColor } from "./utils/colorUtils";
import { GetModTokenReponse } from "./types";
import { t } from "i18next";

const {
  ChatWrapper,
  WithPinMessage,
  RetryWrapper,
  IconWrapper,
  DiconnectWrapper,
} = stylesheet;

interface Props {
  likes?: number;
  handleLikes?: () => void;
  storeName?: string;
  moderatorConfig?: GetModTokenReponse;
}
const FiraChat: React.FC<Props> = ({ storeName, moderatorConfig }) => {
  const {
    status,
    pinnedMessage,
    showRetryScreen,
    startModerator,
    getEventStatus,
  } = useChatContext();
  const [showForm, setShowForm] = useState(false);

  const screenWidth = useScreenWidth();
  const eventStatus = getEventStatus();

  const handleRegistrationComplete = () => {
    setShowForm(false);
  };

  // useEffect(() => {
  //   switch (status) {
  //     case ChatStatus.disconnected:
  //       setShowForm(true);
  //       break;
  //     default:
  //       setShowForm(true);
  //       break;
  //   }
  // }, [status]);

  useEffect(() => {
    if (moderatorConfig && storeName) {
      const { id, token, moderator } = moderatorConfig;
      startModerator(id, token, moderator, storeName);
    }
  }, []);

  if (showRetryScreen) {
    return <ChatRetryComponent />;
  }

  if (eventStatus === "DONE") {
    return <ChatDisconnectedComponent />;
  }

  return (
    <ContextMenuProvider>
      <div
        className={`${ChatWrapper} ${
          pinnedMessage !== undefined ? WithPinMessage : ""
        }`}
      >
        {pinnedMessage && screenWidth > 981 && (
          <MessageItem message={pinnedMessage} msgStyle="PIN" />
        )}
        <MessageList />
        {pinnedMessage && screenWidth < 982 && (
          <MessageItem message={pinnedMessage} msgStyle="PIN" />
        )}
        <InputMessage />
        {showForm && (
          <RegisterForm onRegistrationComplete={handleRegistrationComplete} />
        )}
      </div>
    </ContextMenuProvider>
  );
};

const ChatRetryComponent: React.FC = () => {
  const { getChatConfig, retryConnection } = useChatContext();
  const { mainColor } = getChatConfig();
  return (
    <div className={RetryWrapper}>
      <Icon className={IconWrapper} icon="ph:warning-fill" />
      <span>{t("views.events.eventDetail.fakeChat.refresh")}</span>
      <button
        style={{ backgroundColor: mainColor, color: getTextColor(mainColor) }}
        onClick={retryConnection}
      >
        {t("views.events.eventDetail.fakeChat.refreshButton")}
      </button>
    </div>
  );
};

const ChatDisconnectedComponent: React.FC = () => {
  return (
    <div className={DiconnectWrapper}>
      <Icon
        className={IconWrapper}
        icon="system-uicons:speech-bubble"
        hFlip={true}
      />
      <span>{t("components.chat.noStarted")}</span>
    </div>
  );
};

export default FiraChat;
