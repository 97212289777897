import { t } from "i18next";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModeratorsActionCreator } from "../../../store/User/UserActions";
import { FiraUserModerator, RoleType, RootState } from "../../../utils/types.d";
import Checkbox from "../../Checkbox/Checkbox";
import { MagnifyingGlass } from "../../FiraIcons";
import Input from "../../Input/Input";
import noImageStoreSVG from "../../../assets/svg/no-image-store.svg";
import styles from "./EventTeam.module.scss";
import SvgStar from "../../FiraIcons/Star";
import {
  EventConfig,
  EventConfigurationContext,
} from "../../../views/EventConfigurationView/EventConfigurationView";
import Skeleton from "../../Skeleton/Skeleton";

const EventTeam = () => {
  const dispatch = useDispatch();
  const { user, event, authentication } = useSelector(
    (state: RootState) => state
  );
  const {
    handleChange,
    eventForm,
    firaEventModerators,
    setFiraEventModerators,
    setModeratorsListIsLoading,
    configType,
  } = useContext(EventConfigurationContext);
  const [selectedTeam, setSelectedTeam] = useState<FiraUserModerator[]>([]);
  const [modList, setModList] = useState<FiraUserModerator[]>([]); //lista base
  const [modFilteredList, setModFilteredList] = useState<FiraUserModerator[]>(
    []
  );
  const [moderators, setModerators] = useState<FiraUserModerator[]>([]);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [doneParsing, setDoneParsing] = useState<boolean>(false);

  /** USER LIST HANDLING */
  const getModerators = (storeId: string) => {
    dispatch(getModeratorsActionCreator(storeId));
  };

  const handleModsFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setSearchInputValue(keyword);
    if (keyword !== "") {
      setModFilteredList(
        modList.filter((p) => {
          return (
            String(p.lastName)
              .replace("null", "")
              .toLowerCase()
              .includes(keyword.toLowerCase()) ||
            String(p.firstName)
              .replace("null", "")
              .toLowerCase()
              .includes(keyword.toLowerCase())
          );
        })
      );
    } else {
      setModFilteredList(modList);
    }
  };

  const checkSelection = (member: FiraUserModerator) => {
    const isSelected = selectedTeam.filter((p) => {
      return p.id === member.id;
    });
    return isSelected.length != 0;
  };

  /** SELECTION HANDLE */
  const selectUserHandler = (member: FiraUserModerator) => {
    const isSelected = checkSelection(member);
    if (!isSelected) {
      handleChange({
        ...eventForm,
        moderatorList: [...eventForm.moderatorList, member],
      });
      setFiraEventModerators([...firaEventModerators, member]);
    } else {
      const mods = eventForm.moderatorList.filter(
        (p: any) => member.id !== p.id
      );
      handleChange({
        ...eventForm,
        moderatorList: mods,
      });
      setFiraEventModerators(mods);
    }
  };

  /** CREATOR SELECTION HANDLING */
  const checkIfCreator = (member: FiraUserModerator) => {
    let haveStar = false;
    if (member.id === event.currentEvent?.userId) {
      haveStar = true;
    } else if (
      member.role === "MANAGER" ||
      member.role === "ACCOUNT_OWNER" ||
      member.role === "SUPER_ADMIN"
    ) {
      haveStar = true;
    }

    return haveStar;
  };

  //Utilizamos este handler por ahora mientras no se tenga pantalla de edición en nuevo wizard
  const handleRequiredOwner = () => {
    return new Promise<void>((resolve) => {
      if (event.currentEvent && event.currentEvent.moderators) {
        let ownerInEvent = event.currentEvent.moderators.find(
          (owner) => owner.role === RoleType.ACCOUNT_OWNER
        ); //Should ALWAYS have an account owner
        let ownerInModlist = modList.find(
          (owner) => owner.role === RoleType.ACCOUNT_OWNER
        );

        let newModList: FiraUserModerator[] = [];
        if (ownerInEvent && ownerInModlist === undefined) {
          newModList = [...modList, ownerInEvent];
          if (authentication.redirectedFromFiraBusiness && user.info) {
            let superMod = {
              id: user.info.id,
              email: user.info.email,
              role: RoleType.SUPER_ADMIN,
              userName: user.info.username,
              firstName: user.info.firstName,
              lastName: user.info.lastName || "",
              avatar: user.info.avatar || "",
              username: user.info.username,
            };
            newModList = [...newModList, superMod];
          }
          setModList(newModList);
        } else {
          return;
        }
      }
      resolve();
    });
  };

  const parseModerators = () => {
    const uniqueList = new Set(modList.map((item) => item.id));
    return new Promise<void>((resolve) => {
      let parsedList: FiraUserModerator[] = [];
      if (user.moderators) {
        user.moderators.forEach((mod) => {
          let store = mod.permissionsStore.filter((p) => {
            return p.storeId === event.currentEvent?.storeId;
          })[0];

          if (!uniqueList.has(mod.id) && store && store.role) {
            let tempMod = {
              id: mod.id,
              email: mod.email,
              role: store.role || RoleType.MANAGER,
              userName: mod.username,
              firstName: mod.firstName,
              lastName: mod.lastName,
              avatar: mod.avatar,
              username: mod.username,
            };
            parsedList.push(tempMod);
          }
        });
      }

      setModerators(parsedList);
      setDoneParsing(true);
      resolve();
    });
  };

  const setAllDefaults = async () => {
    if (modList.length == 0) {
      await handleRequiredOwner();
    }
    await parseModerators();
  };

  useEffect(() => {
    setModeratorsListIsLoading(true);
    if (event.currentEvent) getModerators(event.currentEvent.storeId);
  }, []);

  useEffect(() => {
    if (moderators.length > 0 && modList.length > 0 && modList && moderators) {
      let con = [...modList, ...moderators];
      setModList(con);
      handleChange({ ...eventForm, moderatorList: modList });
      setIsLoading(false);
      setModeratorsListIsLoading(false);
    } else if (
      moderators.length === 0 &&
      doneParsing &&
      user.moderators.length === 0 &&
      modList.length > 0
    ) {
      setIsLoading(false);
      setModeratorsListIsLoading(false);
    }
  }, [moderators]);

  useEffect(() => {
    setAllDefaults();
  }, [user.moderators]);

  useEffect(() => {
    if (modList.length > 0) {
      const sortedList = modList.sort((a, b) => {
        if (a.role === "SUPER_ADMIN") return -1;
        if (b.role === "SUPER_ADMIN") return 1;
        if (a.role < b.role) return -1;
        if (a.role > b.role) return 1;
        return 0;
      });
      setModFilteredList(sortedList);
    }

    handleChange({ ...eventForm, moderatorList: modList });

    if (configType === EventConfig.NEW) {
      setFiraEventModerators(modList);
    }
  }, [modList]);

  useEffect(() => {
    if (firaEventModerators) {
      setSelectedTeam(firaEventModerators);
    }
  }, [firaEventModerators]);

  return (
    <>
      <div className={styles.EventTeamWrapper}>
        <>
          <div className={styles.SearchContainer}>
            <div className={styles.inputContainer}>
              <Input
                height="30px"
                width="100%"
                icon={<MagnifyingGlass className={styles.SearchIcon} />}
                placeholder={t("event_creation.team.placeholder")}
                value={searchInputValue}
                onChange={handleModsFiltering}
              />
            </div>

            <div className={styles.counter}>
              <p className={styles.text}>{t("event_creation.team.selected")}</p>
              <div className={`${styles.square} alert-text-14-bold`}>
                {selectedTeam.length}
              </div>
            </div>
          </div>
          <div className={styles.TeamMembersWrapper}>
            {isLoading ? (
              <LoadingTeamView />
            ) : (
              <div className={styles.TeamMembers}>
                {modFilteredList.length !== 0 &&
                  modFilteredList.map((member) => {
                    return (
                      <div
                        className={`${styles.Member} ${
                          checkSelection(member) ? styles.selected : ""
                        } ${
                          checkIfCreator(member) ? styles.Creator : undefined
                        } `}
                        key={member.id}
                        onClick={() => selectUserHandler(member)}
                      >
                        <div className={styles.CheckTypes}>
                          {!checkIfCreator(member) && (
                            <Checkbox
                              key={member.id}
                              isActive={checkSelection(member)}
                            />
                          )}

                          {checkIfCreator(member) && <SvgStar />}
                        </div>
                        <div className={`${styles.Overlay}`}></div>
                        <div className={styles.Avatar}>
                          <img
                            src={
                              member.avatar ? member.avatar : noImageStoreSVG
                            }
                            alt="USER"
                          />
                        </div>
                        <div className={styles.MemberInfo}>
                          <div
                            className={`${styles["body-16-text"]} ${styles.MemberName}`}
                          >
                            {member.firstName + " " + member.lastName}
                          </div>
                          {member.role}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export const LoadingTeamView = () => {
  return (
    <div className={styles.TeamMembers}>
      <Skeleton width="180px" height="183px" />
      <Skeleton width="180px" height="183px" />
      <Skeleton width="180px" height="183px" />
      <Skeleton width="180px" height="183px" />
      <Skeleton width="180px" height="183px" />
      <Skeleton width="180px" height="183px" />
      <Skeleton width="180px" height="183px" />
      <Skeleton width="180px" height="183px" />
    </div>
  );
};

export default EventTeam;
