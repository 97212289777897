import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCodeTag = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6.103 11.68a.8.8 0 0 1-.569 1.373.754.754 0 0 1-.557-.236l-3.741-3.72a.803.803 0 0 1 0-1.136l3.741-3.72a.79.79 0 0 1 1.136 0 .8.8 0 0 1-.01 1.136L2.94 8.53l3.163 3.152ZM15.897 5.32a.799.799 0 0 1 .569-1.373c.203 0 .407.075.557.236l3.741 3.72a.803.803 0 0 1 0 1.136l-3.741 3.72a.79.79 0 0 1-1.136 0 .8.8 0 0 1 .01-1.136L19.06 8.47l-3.163-3.152Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path d="M13 1 9 16" stroke="currentColor" strokeWidth={2} />
  </svg>
);

export default SvgCodeTag;
