import { ReactElement } from "react";
import maskGroup from "../../assets/svg/Mask-Group.svg";
import maskGroupEnd from "../../assets/svg/Mask-Group-end.svg";
import styles from "./FiraStepper.module.scss";

export enum EVENT_ROUTES {
  GENERAL = "GENERAL_DETAILS",
  TEAM = "TEAM",
  PRODUCTS = "PRODUCTS",
  PARAMS = "PARAMS",
  TEMPLATES = "TEMPLATES",
  COLOR = "COLOR_SELECTION",
  LOGIN = "LOGIN_PARAMS",
  BUY_BUTTON = "BUY_BUTTON",
  ORIENTATION = "ORIENTATION",
  ATTACHMENTS = "ATTACHMENTS",
  LIKES = "LIKES",
  MODERATION = "MODERATION",
  CREATE_LIVE = "CREATE_LIVE",
}

export interface FiraStep {
  title: string;
  route: string;
  active: boolean;
  component: ReactElement;
  ministeps?: FiraStep[];
  info?: {
    title: string;
    desc: string;
  };
}

interface FiraStepperProps {
  steps: FiraStep[];
  showMiniSteps?: boolean;
}

const FiraStepper = ({ steps = [], showMiniSteps }: FiraStepperProps) => {
  return (
    <div className={styles.FiraStepperWrapper}>
      <div className={styles.LeftIconMarkUp}>
        <img src={maskGroup} alt="" />
      </div>

      {/* Main steps titles */}
      <ul className={styles.FiraStepperListTitles}>
        {steps.map((step, i) => (
          <li key={i} className={styles["body-14-text"]}>
            <span>{step.title}</span>
          </li>
        ))}
      </ul>
      {/* Main steps progress */}
      <ul className={styles.FiraStepperList}>
        {steps.map((step, i) => (
          <li key={i}>
            <div
              title={step.title}
              className={`${styles["tiny-text"]} ${styles.step} ${
                step.active ? styles.active : ""
              } ${step.ministeps !== undefined ? styles.hasMiniSteps : ""}`}
            ></div>
            {step.ministeps !== undefined && (
              <>
                <MiniFiraSteps
                  steps={step.ministeps}
                  showMiniSteps={showMiniSteps}
                />
              </>
            )}
          </li>
        ))}
      </ul>

      <div className={styles.RighIconMarkUp}>
        <img src={maskGroupEnd} alt="" />
      </div>
    </div>
  );
};

interface MiniStepsProps {
  steps: FiraStep[];
  showMiniSteps?: boolean;
}

export const MiniFiraSteps = ({
  steps = [],
  showMiniSteps = true,
}: MiniStepsProps) => {
  return (
    <>
      <ul
        className={`${
          showMiniSteps ? styles.ProgressBar : styles.ProgressBarSimple
        }`}
      >
        {steps.map((step, i) => (
          <li key={i}>
            <div
              className={`${showMiniSteps ? styles.ProgressBarStep : {}} ${
                step.active ? styles.active : ""
              }`}
            ></div>
          </li>
        ))}
        <div className={styles.LastLine}></div>
      </ul>
    </>
  );
};

export default FiraStepper;
