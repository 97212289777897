import { ChangeEvent, useRef, useState } from "react";
import { FiraProductOption } from "../../../utils/types";
import { v4 as uuid } from "uuid";
import StyleSheet from "./VariationForm.module.scss";
import Input from "../../../components/Input/Input";
import Button, { ButtonStyle } from "../../../components/Button/Button";
import { TrashCan } from "../../../components/FiraIcons";
import { useTranslation } from "react-i18next";

const variationOptionsRegex = new RegExp("^[0-9a-zA-Z]+(,[0-9a-zA-Z]+)*$");

interface VariationFormProps {
  productOption?: FiraProductOption;
  onVariationSave(newVariation: FiraProductOption): void;
  onCancel(): void;
}

export const VariationForm = ({
  productOption,
  onVariationSave,
  onCancel,
}: VariationFormProps) => {
  const [value, setValue] = useState("");
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation();
  const [name, setName] = useState(productOption?.name || "");
  const [variationItems, setVariationItems] = useState<string[]>(
    productOption?.optionItems || []
  );
  const [currentVariationItem, setCurrentVariationItem] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  //NUEVO
  const [inputValues, setInputValues] = useState<string[]>(
    variationItems && variationItems.length > 0 ? [...variationItems, ""] : [""]
  );

  const handleInputChange = (index: number, value: string) => {
    const newValues = [...inputValues];
    newValues[index] = value;
    setInputValues(newValues);
    if (index === inputValues.length - 1 && value.trim() !== "") {
      setInputValues([...newValues, ""]);
      setIsValid(true);
    }
  };
  const handleRemoveInput = (index: number) => {
    const newValues = [...inputValues];
    newValues.splice(index, 1);
    setInputValues(newValues);
    setIsValid(true);
  };
  const handleSave = () => {
    // Filtrar los valores vacíos antes de guardar
    const nonEmptyValues = inputValues.filter((value) => value.trim() !== "");
    setVariationItems(inputValues.filter((value) => value.trim() !== ""));
  };

  const handleVariationNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);
  };

  const handleVariationValuesChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setIsValid(variationOptionsRegex.test(value));
    setCurrentVariationItem(value);
  };

  const handleAddVariation = () => {
    if (isValid && currentVariationItem.trim() !== "") {
      setVariationItems((prevItems) => [...prevItems, currentVariationItem]);
      setCurrentVariationItem("");
      inputRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddVariation();
    }
  };

  const handleVariationSave = () => {
    const optionItems = inputValues.filter((value) => value.trim() !== "");
    let newVariation: FiraProductOption;

    if (productOption) {
      newVariation = {
        ...productOption,
        name,
        optionItems,
      };
    } else {
      newVariation = {
        id: uuid(),
        name,
        optionItems,
      };
    }

    onVariationSave(newVariation);

    setName("");
    setVariationItems([]);
  };

  const handleOptionFormCancel = () => {
    setValue("");
    setName("");
    onCancel();
  };

  const handleRemoveVariation = (index: number) => {
    setIsValid(true);
    setVariationItems((prevItems) =>
      prevItems.filter((item, i) => i !== index)
    );
  };

  return (
    <div className={StyleSheet.VariationForm}>
      <div className={StyleSheet.VariationInputWrapper}>
        <label htmlFor="variationName">
          {t("views.products.detailProduct.variationName")}
        </label>
        <Input
          width="100%"
          height="40px"
          inputId="variationName"
          value={name}
          fontSize={"16px"}
          onChange={handleVariationNameChange}
          ref={inputRef}
        />
      </div>

      <div className={StyleSheet.VariationInputWrapper}>
        <label htmlFor="variationValues">
          {t("views.products.detailProduct.variationValue")}
        </label>
        <div>
          {inputValues.map((value, index) => (
            <div
              key={index}
              style={{
                marginBottom: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Input
                width="100%"
                height="40px"
                placeholder={t("views.products.detailProduct.addValue")}
                inputId="variationValues"
                value={value}
                fontSize={"16px"}
                onChange={(e) => handleInputChange(index, e.target.value)}
              />
              {index !== inputValues.length - 1 && (
                <span
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                  onClick={() => handleRemoveInput(index)}
                >
                  <TrashCan width={24} height={24} color={"#D0D0D0"} />
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={StyleSheet.VariationButtonWrapper}>
        <Button
          width="105px"
          height="32px"
          buttonStyle={ButtonStyle.border}
          clickHandler={handleOptionFormCancel}
        >
          <span className="lead-text">
            {t("views.products.detailProduct.cancel")}
          </span>
        </Button>
        <Button
          width="105px"
          height="32px"
          clickHandler={handleVariationSave}
          isDisabled={!isValid}
          buttonStyle={ButtonStyle.main}
        >
          <span className="lead-text">
            {t("views.products.detailProduct.save")}
          </span>
        </Button>
      </div>
    </div>
  );
};
