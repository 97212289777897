import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import _ from "lodash";

import {
  AddImageButton,
  Button,
  ProductImage,
  Input,
  TextEditor,
  PopUpAlert,
  MainLoader,
} from "../../components";
import OpenLink from "../../components/FiraIcons/OpenLink";
import { ButtonStyle } from "../../components/Button/Button";
import { Edit, TrashCan } from "../../components/FiraIcons";
import {
  createProductActionCreator,
  resetCurrentProductActionCreator,
  resetUpdateImageProductActionCreator,
  updateImagesProductActionCreator,
  updateInfoProductActionCreator,
} from "../../store/Products/ProductsActions";
import {
  AssociatedStore,
  Category,
  FiraProduct,
  FiraProductLabels,
  FiraProductOption,
  FiraProductVariation,
  ImageProductObject,
  ItemStore,
  LabelProductCreate,
  RootState,
} from "../../utils/types";

import StyleSheet from "./DetailProductView.module.scss";

import SvgStar from "../../components/FiraIcons/Star";

import InfoCircle from "../../components/FiraIcons/InfoCircle";
import PopUpInfo from "../../components/PopUpInfo/PopUpInfo";
import { convertToHTML } from "draft-convert";
import { EcommerceType, ProductStatus } from "../../utils/enums";
import { PopUpAlertTypes } from "../../components/PopUpAlert/PopUpAlert";
import { getCurrencyFormatted } from "../../utils/getCurrencyFormatted";
import { getCurrencySymbol } from "../../utils/getCurrencySymbol";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import { VariationForm } from "./VariationForm/VariationForm";
import VariationsTable from "./VariationsTable/VariationsTable";
import { useProductContext } from "../../store/Products/ProductVariationsContext";
import LabelProduct from "./LabelProduct/LabelProduct";
import {
  getEventCategories,
  getStoresBusinessClient,
} from "../../services/events";
import SelectCategories from "./SelectCategories/SelectCategories";
import ImagesGrid from "./ImagesGrid/ImagesGrid";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";
import SearchSelectStore from "../../components/SearchSelectStore/SearchSelectStore";
import UrlForm from "./UrlForm/UrlForm";

const optionsModifier = (data: FiraProductOption[] = []): string[][] => {
  return data.map((a) => {
    if (a.optionItems) {
      return a.optionItems.map((b) => b);
    }
    return [];
  });
};

export interface ProductForm {
  id?: string;
  name: string;
  sku: string;
  storeId: string;
  externalId: string;
  quantity: number;
  directUrl: string;
  description?: string;
  price1: string;
  price2?: string;
  price3?: string;
  images: string[];
  firaProductOptionDtos: FiraProductOption[];
  firaVariationsDtos: FiraProductVariation[];
  status: ProductStatus;
  origin: EcommerceType;
  labels: FiraProductLabels[];
  categories: Category[];
  isAdvancedUrl: boolean;
  associatedStores?: AssociatedStore[];
  isGlobal?: boolean;
}

export interface ProductCreateForm {
  firaProductDto: ProductForm;
  files: FormData;
}

const fileBlobToURL = (file: File): string => {
  return URL.createObjectURL(file);
};

const isCompleteProduct = (
  data: ProductForm | FiraProduct
): data is FiraProduct => {
  return (
    (data as FiraProduct).id !== undefined && (data as FiraProduct).id !== ""
  );
};

enum messageTypes {
  loading = "loading",
  error = "error",
  success = "success",
}

let timerID: NodeJS.Timeout;
const notNumbersRegex = new RegExp(/\D+/g);
const priceRegex = new RegExp(/[^.,\d]/g);
const urlRegex = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/))(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)(\.){1,}[a-zA-Z0-9()]*\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=])*/gi
);

const DetailProductView = () => {
  const { authentication, brand, products } = useSelector(
    (state: RootState) => state
  );
  const [productForm, setProductForm] = useState<ProductForm | FiraProduct>({
    ...products.currentProduct,
    storeId: brand.currentStore?.id as string,
  });
  const storeCurrency =
    brand.currentStore?.regionalConfiguration.currency || "USD";
  const [images, setImages] = useState<File[]>([]);
  const [imagesList, setImagesList] = useState<ImageProductObject[]>([]);

  const [messageType, setMessageType] = useState("");
  const [messageTypeImage, setMessageTypeImage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formValidated, setFormValidated] = useState(false);
  const [isImagesChange, setIsImagesChange] = useState(false);
  const [isEditProduct, setIseditProduct] = useState(false);
  const [textAreaState, setTextAreaState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(
        productForm.description ? productForm.description : ""
      )
    )
  );
  const inputImageRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [elemetTarget, setElementTarget] = useState<HTMLElement>();
  const [showInfoSku, setShowInfoSku] = useState(false);
  const [showInfoExter, setShowInfoExter] = useState(false);
  const [isActiveSku, setIsActiveSku] = useState(false);
  const [isActiveExter, setIsActiveExter] = useState(false);
  const {
    productOptions,
    productVariations,
    currentProductVariations,
    setInitialProductOptions,
    setInitialProductVariations,
    setInitialCurrentProductVariations,
  } = useProductContext();
  const [categories, setCategories] = useState<Category[]>([
    {
      id: "",
      description: "",
    },
  ]);

  const [storeList, setStoreList] = useState<ItemStore[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<Category[]>([]);
  const [labelsSelected, setLablesSelected] = useState<LabelProductCreate[]>(
    []
  );
  const showAlertPopup = usePopupAlert();
  const canEdit = !authentication.isAccountOwner
    ? brand.currentStore
      ? brand.currentStore.productConfig
        ? brand.currentStore.productConfig.canEdit
        : true
      : true
    : true;

  useEffect(() => {
    getEventCategoriesProduct();
    getStoresBC();
  }, []);

  const getEventCategoriesProduct = async () => {
    const response = await getEventCategories();

    setCategories(response.data);
    return response.data;
  };

  const getStoresBC = async () => {
    const response = await getStoresBusinessClient();

    setStoreList(response.data);
    return response.data;
  };

  const handleBackButton = () => {
    navigate(-1);
    dispatch(resetCurrentProductActionCreator());
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProductForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleInventoryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const d = value.replace(notNumbersRegex, "");
    setProductForm((prevState) => ({ ...prevState, [name]: d }));
  };

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const d = getCurrencyFormatted(value);
    setProductForm((prevState) => ({ ...prevState, [name]: d }));
  };

  const handleURLChange = (name: string, value: string) => {
    setProductForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const urlChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProductForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const urlTypeChange = (isAdvancedUrl: boolean) => {
    setProductForm((prevState) => ({ ...prevState, isAdvancedUrl }));
  };

  const addImageToUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      const fileArray = Array.from(files);
      if (fileArray.length <= 10) {
        setImages((prevState) => [...prevState, ...fileArray]);
        setIsImagesChange(true);
        fileArray.map((file, index) => {
          setImagesList((prevState) => [
            ...prevState,
            {
              id: index,
              imageUrl: fileBlobToURL(file),
              image: file,
              isDelete: false,
              isPrimary: false,
              selected: false,
              isNew: true,
            },
          ]);
        });
      }
    }
  };

  const handleDrop = (files: FileList) => {
    if (files) {
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

      const validFiles = Array.from(files).filter((file) =>
        validImageTypes.includes(file.type)
      );

      if (validFiles.length > 0) {
        setImages((prevState) => [...prevState, ...validFiles]);
        setIsImagesChange(true);
        validFiles.map((file, index) => {
          setImagesList((prevState) => [
            ...prevState,
            {
              id: index,
              imageUrl: fileBlobToURL(file),
              image: file,
              isDelete: false,
              isPrimary: false,
              selected: false,
              isNew: true,
            },
          ]);
        });
      } else {
        showAlertPopup(
          AlertType.error,
          t("views.products.detailProduct.errorImages")
        );
      }
    }
  };

  const handleAddImageButtonClick = () => {
    if (imagesList.length < 10) {
      inputImageRef.current?.click();
    }
  };

  const handleCloseAlert = () => {
    setMessageType("");
    setShowAlert(false);
  };
  const handleCloseAlertImage = () => {
    setMessageTypeImage("");
    setShowAlert(false);
  };

  useEffect(() => {
    setProductForm((prevState) => ({
      ...prevState,
      firaProductOptionDtos: productOptions,
      firaVariationsDtos: currentProductVariations,
    }));
  }, [productOptions, currentProductVariations]);

  const onChangeStores = (itemsSelected: ItemStore[], isglobal: boolean) => {
    setProductForm((prevState) => ({
      ...prevState,
      associatedStores: itemsSelected,
      isGlobal: isglobal,
    }));
  };

  const handleSave = () => {
    if (!products.isLoading) {
      const addImmage =
        imagesList.filter((item) => item.isNew).length > 0 ? true : false;
      const deleteImmage =
        imagesList.filter((item) => item.isDelete).length > 0 ? true : false;

      if (formValidated) {
        if (isCompleteProduct(productForm)) {
          if (formValidated && isEditProduct) {
            console.log("form completo edit", productForm);
            dispatch(
              updateInfoProductActionCreator(productForm, labelsSelected)
            );
          }
          if (isImagesChange) {
            dispatch(
              updateImagesProductActionCreator(
                productForm.id,
                addImmage,
                isImagesChange,
                deleteImmage,
                imagesList
              )
            );
          }
        } else {
          dispatch(
            createProductActionCreator(productForm, images, labelsSelected)
          );
        }

        setShowAlert(true);
      }
    }
  };

  const invalidImages = () => {
    setShowAlert(true);
    setMessageTypeImage(messageTypes.error);
    setErrorMessage(t("views.products.detailProduct.imageError"));
    timerID = setTimeout(() => {
      handleCloseAlertImage();
    }, 5000);
  };

  const handleEditor = (editorState: EditorState) => {
    const html = convertToHTML(editorState.getCurrentContent());
    setTextAreaState(editorState);
    setProductForm((prevState) => ({
      ...prevState,
      description: html,
    }));
  };

  useEffect(() => {
    setInitialProductOptions(
      productForm.firaProductOptionDtos ? productForm.firaProductOptionDtos : []
    );
    setInitialProductVariations(
      productForm.firaVariationsDtos ? productForm.firaVariationsDtos : []
    );
    setInitialCurrentProductVariations(
      productForm.firaVariationsDtos ? productForm.firaVariationsDtos : []
    );

    if (isCompleteProduct(productForm)) {
      let archivo: File;
      let isMounted = true;
      setIsImagesChange(false);
      if (isMounted) {
        productForm.images.map((image, index) => {
          setImagesList((prevState) => [
            ...prevState,
            {
              id: index,
              imageUrl: image,
              image: archivo,
              isDelete: false,
              isPrimary: false,
              selected: index === 0 ? true : false,
              isNew: false,
            },
          ]);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (products.imagesChangeSuccess) {
      setImagesList([]);
      let archivo: File;
      products.currentProduct.images &&
        products.currentProduct.images.map((image, index) => {
          setImagesList((prevState) => [
            ...prevState,
            {
              id: index,
              imageUrl: image,
              image: archivo,
              isDelete: false,
              isPrimary: false,
              selected: index === 0 ? true : false,
              isNew: false,
            },
          ]);
        });

      dispatch(resetUpdateImageProductActionCreator());
    }
  }, [products.imagesChangeSuccess]);

  const validateForm = () => {
    if (
      productForm.name !== "" &&
      productForm.sku !== "" &&
      productForm.directUrl !== "" &&
      productForm.directUrl.match(urlRegex) &&
      productForm.price1 !== "" &&
      !productForm.price1.endsWith(".") &&
      !productForm.price2?.endsWith(".") &&
      productForm.externalId !== "" &&
      productForm.quantity > 0
    ) {
      if (
        !_.isEqual(productForm, products.currentProduct) &&
        isCompleteProduct(productForm)
      ) {
        setIseditProduct(true);
        setFormValidated(true);
      } else {
        if (isImagesChange) {
          setFormValidated(true);
        } else {
          setFormValidated(false);
        }
      }
    } else {
      setFormValidated(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [productForm, images]);

  useEffect(() => {}, [productForm.firaProductOptionDtos]);

  useEffect(() => {
    if (showAlert) {
      if (products.isLoading && showAlert) {
        setMessageType(messageTypes.loading);
      } else {
        if (products.error && showAlert) {
          if (products.error.message === "file is not an image") {
            showAlertPopup(
              AlertType.error,
              t("views.products.detailProduct.imageError")
            );
          } else if (
            products.error.message ===
            "The Image list cannot contain more than 10 elements."
          ) {
            showAlertPopup(
              AlertType.error,
              t("views.products.detailProduct.imageError")
            );
          } else {
            showAlertPopup(
              AlertType.error,
              t("views.products.detailProduct.error")
            );
          }
          setMessageType(messageTypes.error);
          setImages([]);
        } else if (!products.error && showAlert) {
          setMessageType(messageTypes.success);
          setImages([]);
          setIsImagesChange(false);
          setProductForm(products.currentProduct);
        }
      }
    }
  }, [products.isLoading, products.error]);

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      setIsImagesChange(true);
      setImagesList((prevItem) =>
        arrayMoveImmutable(prevItem, oldIndex, newIndex)
      );

      setImages((prevItem) =>
        imagesList.map((item) => {
          return item.image;
        })
      );
    }
  };

  /* Verificacion de cambiose en imagen*/
  useEffect(() => {
    if (isImagesChange) {
      validateForm();
    }
  }, [isImagesChange]);

  useEffect(() => {
    if (imagesList.length > 0) {
      const array = imagesList;
      array.map((item, index) => {
        item.selected = index === 0 ? true : false;
      });
      setImagesList(array);
      setFormValidated(true);
    }
  }, [imagesList]);

  const handleClickDelete = (url: string) => {
    const deleteItem = imagesList.map((item) => {
      if (item.imageUrl === url) {
        item.isDelete = item.isDelete ? false : true;
      }
      return item;
    });

    const resultImages = deleteItem.filter((item) => {
      if (!item.isDelete || (item.isDelete && !item.isNew)) return item;
    });

    setIsImagesChange(true);

    setImagesList(resultImages);

    setImages((item) =>
      imagesList.map((item) => {
        return item.image;
      })
    );
  };
  const onChangeItems = () => {
    setIsImagesChange(true);
  };

  const validateUrl = (): string => {
    let url = "";
    if (
      !productForm.directUrl.includes("https") &&
      !productForm.directUrl.includes("http")
    ) {
      url = url.concat("https://", productForm.directUrl);
    } else {
      url = productForm.directUrl;
    }

    return url;
  };

  const handleEventInfoSku = (e: SyntheticEvent<HTMLElement>) => {
    setShowInfoSku(showInfoSku ? false : true);
    setIsActiveSku(!showInfoSku);
    setElementTarget(e.currentTarget);
  };

  const handleEventInfoExter = (e: SyntheticEvent<HTMLElement>) => {
    setShowInfoExter(showInfoExter ? false : true);
    setIsActiveExter(!showInfoExter);
    setElementTarget(e.currentTarget);
  };

  useEffect(() => {
    if (products.currentProduct.description !== undefined) {
      const blocksFromHTML = convertFromHTML(
        products.currentProduct.description
      );
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setTextAreaState(EditorState.createWithContent(state));
    }
  }, [products]);

  let titleSection;
  {
    titleSection = (
      <span className={`h3 ${StyleSheet.LimitTextDetail}`}>
        {productForm.name === ""
          ? t("views.products.detailProduct.newProduct")
          : productForm.name}
      </span>
    );
  }

  const optionBtn = () => {
    if (canEdit)
      return (
        <>
          <div style={{ marginRight: "8px" }}>
            <Button
              buttonStyle={ButtonStyle.border}
              clickHandler={handleBackButton}
            >
              <span className={StyleSheet["lead-text"]}>
                {t("views.products.detailProduct.discard")}
              </span>
            </Button>
          </div>

          <Button
            clickHandler={handleSave}
            isDisabled={!formValidated || products.isLoading}
          >
            <span className={StyleSheet["lead-text"]}>
              {t("views.products.detailProduct.save")}
            </span>
          </Button>
        </>
      );

    return <></>;
  };

  const handleChangeForm = () => {
    setIseditProduct(true);
    setFormValidated(true);
    setProductForm((prevState) => ({
      ...prevState,
      firaProductOptionDtos: productOptions,
    }));
  };

  const handleChangeVariations = () => {
    setFormValidated(true);
    setProductForm((prevState) => ({
      ...prevState,
      firaVariationsDtos: currentProductVariations,
    }));
  };
  const handleLabelsChange = (labels: LabelProductCreate[]) => {
    const arrayLabels = labels.filter(
      (label) => label.name && label.name.trim() !== ""
    );
    setLablesSelected(arrayLabels);
    setFormValidated(true);
    setIseditProduct(true);
  };

  interface ItemsOption {
    value: string;
    label: string;
    isSelected: boolean;
  }

  const onChangeCategoriesProducts = (selectedOptions: ItemsOption[]) => {
    setCategoriesSelected(
      selectedOptions.map((item) => {
        return { id: item.value, description: item.label };
      })
    );
    setProductForm((prevState) => ({
      ...prevState,

      categories: selectedOptions.map((item) => {
        return { id: item.value, description: item.label };
      }),
    }));
    setFormValidated(true);
  };

  useEffect(() => {
    messageType !== "" && messageType !== messageTypes.loading
      ? showAlertPopup(
          AlertType.success,
          t("views.products.detailProduct.saved")
        )
      : "";
  }, [messageType]);

  const getStoreName = (storeId: string) => {
    const store = storeList.find((store) => store.id === storeId);
    return store?.name ? store?.name : "";
  };
  return (
    <>
      <FiraNavBar
        backButtonHandler={handleBackButton}
        titleSection={titleSection}
        trailingOptions={optionBtn()}
      />

      {messageType === messageTypes.loading && <MainLoader />}

      <div className={StyleSheet["ProductInfoForm"]}>
        <div className={StyleSheet["ProductInfoColumn"]}>
          <div
            className={`${StyleSheet["NewProductWrapper"]}  ${StyleSheet["ProductInputsGrid"]} `}
          >
            <div className={StyleSheet["InputWrapper"]}>
              <label className={StyleSheet["body-16-text"]} htmlFor="name">
                {t("views.products.detailProduct.form.name") + "*"}
              </label>
              <Input
                inputId="name"
                inputName="name"
                isDisabled={!canEdit}
                value={productForm.name}
                onChange={handleInputChange}
                width="100%"
              />
            </div>
            <div className={StyleSheet.InputWrapper}>
              {showInfoSku && (
                <PopUpInfo
                  title={t("views.products.detailProduct.form.skuInfo")}
                  elementRef={elemetTarget}
                />
              )}
              <label className={StyleSheet["body-16-text"]} htmlFor="sku">
                {t("views.products.detailProduct.form.SKU")}*
                <span
                  className={StyleSheet.info}
                  onMouseEnter={handleEventInfoSku}
                  onMouseLeave={handleEventInfoSku}
                >
                  <InfoCircle color={isActiveSku ? "#ffde07" : "#000"} />
                </span>
              </label>
              <Input
                inputId="sku"
                inputName="sku"
                isDisabled={!canEdit}
                value={productForm.sku}
                onChange={handleInputChange}
                width="100%"
              />
            </div>
            <div className={StyleSheet.InputWrapper}>
              {showInfoExter && (
                <PopUpInfo
                  title={t("views.products.detailProduct.form.exterIdInfo")}
                  elementRef={elemetTarget}
                />
              )}
              <label
                className={StyleSheet["body-16-text"]}
                htmlFor="externalId"
              >
                {t("views.products.detailProduct.form.exterId")}*
                <span
                  className={StyleSheet.info}
                  onMouseEnter={handleEventInfoExter}
                  onMouseLeave={handleEventInfoExter}
                >
                  <InfoCircle color={isActiveExter ? "#ffde07" : "#000"} />
                </span>
              </label>

              <Input
                inputId="externalId"
                inputName="externalId"
                isDisabled={!canEdit}
                value={productForm.externalId}
                onChange={handleInputChange}
                width="100%"
              />
            </div>
            <div className={StyleSheet.InputWrapper}>
              <label className={StyleSheet["body-16-text"]} htmlFor="quantity">
                {t("views.products.detailProduct.form.inventory")}*
              </label>
              <Input
                inputId="quantity"
                inputName="quantity"
                isDisabled={!canEdit}
                width="100%"
                value={productForm.quantity.toString()}
                onChange={handleInventoryChange}
              />
            </div>
            <div className={StyleSheet.InputWrapper}>
              <label className={StyleSheet["body-16-text"]} htmlFor="price1">
                {t("views.products.detailProduct.form.price1")} *
              </label>
              <Input
                inputId="price1"
                inputName="price1"
                isDisabled={!canEdit}
                value={productForm.price1.toString()}
                onChange={handlePriceChange}
                width="100%"
                currency={getCurrencySymbol(storeCurrency)}
              />
            </div>
            <div className={StyleSheet.InputWrapper}>
              <label className={StyleSheet["body-16-text"]} htmlFor="price2">
                {t("views.products.detailProduct.form.discount")}
              </label>
              <Input
                inputId="price2"
                inputName="price2"
                isDisabled={!canEdit}
                value={
                  productForm.price2 === "0"
                    ? ""
                    : productForm.price2?.toString()
                }
                placeholder={`${t(
                  "views.products.detailProduct.form.optional"
                )}`}
                onChange={handlePriceChange}
                width="100%"
                currency={getCurrencySymbol(storeCurrency)}
              />
            </div>
          </div>

          <div className={StyleSheet.NewProductWrapper}>
            <div className={StyleSheet.LabelWrapper}>
              <label htmlFor="description">
                {t("views.products.detailProduct.form.description")}
              </label>
            </div>
            <TextEditor
              canEdit={canEdit}
              editorState={textAreaState}
              onChangeHandler={handleEditor}
              id="description"
              name="description"
              maxLenght={140}
            />
          </div>

          <div className={StyleSheet.NewProductDivider}>
            <UrlForm
              url={productForm.directUrl}
              isAdvanced={productForm.isAdvancedUrl}
              setUrl={handleURLChange}
              setUrlType={urlTypeChange}
            />
          </div>

          <div className={StyleSheet.NewProductDivider}>
            <div className={StyleSheet.LabelWrapper}>
              <label htmlFor="description">
                {t("views.products.detailProduct.form.media")}*
              </label>
            </div>
            <input
              ref={inputImageRef}
              type={"file"}
              style={{ display: "none" }}
              onChange={addImageToUpload}
              multiple
              accept=".png, .jpg, .jpeg"
            />
            <ImagesGrid
              canEdit={canEdit}
              items={imagesList}
              setItems={setImagesList}
              onDelete={handleClickDelete}
              onChangeItems={onChangeItems}
            >
              <AddImageButton
                canEdit={canEdit}
                clickHandler={handleAddImageButtonClick}
                onDrop={handleDrop}
              />
            </ImagesGrid>

            <div className={StyleSheet.LabelInfoImages}>
              <SvgStar />
              <span className={StyleSheet["label-description-images"]}>
                {t("views.products.detailProduct.form.images")}
              </span>
            </div>
          </div>

          <OptionsList
            canEdit={canEdit}
            variations={productOptions ? productOptions : []}
            handleVariationsChange={handleChangeForm}
          />
          <VariationsTable
            canEdit={canEdit}
            variations={
              currentProductVariations ? currentProductVariations : []
            }
            onChange={handleChangeVariations}
          />
        </div>
        <div
          className={StyleSheet["ProductInfoColumn"]}
          style={{ width: "31vh" }}
        >
          {authentication.isAccountOwner && !brand.currentStore && (
            <div className={StyleSheet.NewProductWrapper}>
              <div className={StyleSheet.LabelWrapper}>
                <h6>{t("views.products.detailProduct.stores")}</h6>
                <SearchSelectStore
                  data={
                    products.currentProduct.associatedStores
                      ? products.currentProduct.associatedStores
                      : []
                  }
                  options={storeList}
                  onchange={onChangeStores}
                  storeId={products.currentProduct.storeId}
                />
              </div>
            </div>
          )}
          {authentication.isAccountOwner &&
            !brand.currentStore &&
            products.currentProduct.storeId !== "" &&
            products.currentProduct.storeId !== null &&
            products.currentProduct.storeId !== undefined && (
              <div className={StyleSheet.NewProductWrapper}>
                <div
                  className={StyleSheet.LabelWrapper}
                  style={{
                    display: "flex",
                    gap: "12px",
                    flexDirection: "column",
                  }}
                >
                  <h6>{t("views.products.detailProduct.origin")}</h6>
                  <label>
                    {"" + getStoreName(products.currentProduct.storeId)}
                  </label>
                </div>
              </div>
            )}
          {
            <div className={StyleSheet.NewProductWrapper}>
              <div className={StyleSheet.LabelWrapper}>
                <h6>{t("views.products.detailProduct.categoryTitle")}</h6>
                <SelectCategories
                  canEdit={canEdit}
                  data={categories}
                  onChange={onChangeCategoriesProducts}
                />
                {categoriesSelected.map((item) => {
                  <label>{item.description}</label>;
                })}
              </div>
            </div>
          }

          {
            //Componente de etiquetas
            <LabelProduct canEdit={canEdit} onChange={handleLabelsChange} />
          }
        </div>
      </div>
    </>
  );
};

/**
 * Componente SortableContainer
 */
export const SortableListItem = SortableElement(
  ({
    item,
    callback,
  }: {
    item: ImageProductObject;
    callback(url: string): void;
  }) => {
    const element: ImageProductObject = item;

    return <ProductImage element={element} clickHandler={callback} />;
  }
);

export const SortableList = SortableContainer(
  ({
    items,
    callback,
  }: {
    items: ImageProductObject[];
    callback(url: string): void;
  }) => {
    return (
      <ul className={StyleSheet.listItem}>
        {items.map((item, index) => {
          return (
            <SortableListItem
              key={index}
              index={index}
              item={item}
              callback={callback}
            />
          );
        })}
      </ul>
    );
  }
);

interface VariationsListProps {
  canEdit?: boolean;
  variations: FiraProductOption[];
  handleVariationsChange(): void;
}
export const OptionsList = ({
  canEdit = true,
  variations,
  handleVariationsChange,
}: VariationsListProps) => {
  const [addOptionIsActive, setAddOptionIsActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentOptionVariation, setCurrentOptionVariation] =
    useState<FiraProductOption>();
  const { t } = useTranslation();
  const { products } = useSelector((state: RootState) => state);
  const {
    productOptions,
    productVariations,
    addProductOption,
    editProductOption,
    deleteProductOption,
    addProductVariation,
    addCurrentProductVariation,
    filterCurrentProductVariations,
    resetProductVariations,
    resetCurrentProductVariations,
  } = useProductContext();

  const handleOptionVariationDelete = (option: FiraProductOption) => {
    setIsEdit(true);
    deleteProductOption(option.id);
  };

  const handleOptionVariationEdit = (option: FiraProductOption) => {
    const newVariations = option.optionItems.join(",");
    setCurrentOptionVariation(option);
    setIsEdit(true);
    setAddOptionIsActive(true);
  };

  const handleOptionFormCancel = () => {
    setAddOptionIsActive(false);
    setCurrentOptionVariation(undefined);
  };

  const handleVariationFormSave = (newVariation: FiraProductOption) => {
    addProductOption(newVariation);
    handleVariationsChange();
    handleOptionFormCancel();
  };

  const handleVariationFormEdit = (newVariation: FiraProductOption) => {
    editProductOption(newVariation.id, newVariation);
    handleVariationsChange();
    handleOptionFormCancel();
  };

  const clickAddOption = () => {
    setIsEdit(false);
    handleVariationsChange();
    setAddOptionIsActive(true);
  };

  useEffect(() => {
    resetProductVariations();

    products.currentProduct.id === "" ||
    products.currentProduct.id === undefined ||
    products.currentProduct.id === null
      ? resetCurrentProductVariations()
      : filterCurrentProductVariations();
    if (productOptions.length > 0) {
      generateCombinations(productOptions);
    }
  }, [productOptions]);

  useEffect(() => {
    filterCurrentProductVariations();
  }, [productVariations]);

  const generateCombinations = (
    options: FiraProductOption[],
    currentCombination: string[] = [],
    index = 0
  ) => {
    if (index === options.length) {
      const newVariation: FiraProductVariation = {
        productId: products.currentProduct.id,
        sku: currentCombination.join("_"),
        name: currentCombination.join("/"),
        price1: 0,
        quantity: 0,
        image: "",
      };

      addProductVariation(newVariation);
      if (
        products.currentProduct.id === "" ||
        products.currentProduct.id === undefined ||
        products.currentProduct.id === null
      )
        addCurrentProductVariation(newVariation);
      //addToCurrentProductVariations(newVariation.sku);
      return;
    }

    const currentOption = options[index];

    for (const optionItem of currentOption.optionItems) {
      const newCombination = [...currentCombination, optionItem];

      generateCombinations(options, newCombination, index + 1);
    }
  };

  return (
    <div className={StyleSheet.NewProductDivider}>
      <div className={StyleSheet.LabelWrapper}>
        <label htmlFor={"description"}>
          {t("views.products.detailProduct.variationOption")}
        </label>
      </div>
      <div className={StyleSheet.ProductsVariations}>
        {variations.length > 0 && !addOptionIsActive && (
          <div className={StyleSheet.OptionList}>
            {variations.map((option, index) => (
              <div key={index} className={StyleSheet.OptionItem}>
                <div className="OptionItemName body-14-text">{option.name}</div>
                <div className={StyleSheet.VariationList}>
                  {option.optionItems.map((value, index) => (
                    <div key={index} className={StyleSheet.VariationItemName}>
                      {value}
                    </div>
                  ))}
                </div>
                {canEdit && (
                  <div className={StyleSheet.VariationItemButtons}>
                    <Edit
                      fontSize={24}
                      onClick={() => handleOptionVariationEdit(option)}
                    />
                    <TrashCan
                      fontSize={24}
                      onClick={() => handleOptionVariationDelete(option)}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {addOptionIsActive && (
          <VariationForm
            productOption={currentOptionVariation}
            onVariationSave={
              isEdit ? handleVariationFormEdit : handleVariationFormSave
            }
            onCancel={handleOptionFormCancel}
          />
        )}

        {!addOptionIsActive && (
          <Button
            width="105px"
            height="32px"
            clickHandler={() => clickAddOption()}
            buttonStyle={ButtonStyle.border}
            isDisabled={variations.length === 4 || !canEdit}
          >
            <span className="lead-text">
              {t("views.products.detailProduct.addButton")}
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default DetailProductView;
