import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCopyPaperv2 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="22"
    fill="none"
    viewBox="0 0 19 22"
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5.99 3v12a2 2 0 002 2h8a2 2 0 002-2V6.242a2 2 0 00-.602-1.43L14.073 1.57A2 2 0 0012.675 1H7.99a2 2 0 00-2 2v0z"
    ></path>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13.99 17v2a2 2 0 01-2 2h-8a2 2 0 01-2-2V8a2 2 0 012-2h2"
    ></path>
  </svg>
);

export default SvgCopyPaperv2;
