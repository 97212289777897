import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgBan = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15.481 24a9.448 9.448 0 0 0 6.658-2.739l.046-.035s.024-.032.038-.047A9.476 9.476 0 0 0 15.52 5a9.482 9.482 0 0 0-6.704 2.776l-.038.047s-.023.032-.038.046A9.469 9.469 0 0 0 15.481 24Zm7.147-9.476a7.102 7.102 0 0 1-1.34 4.158L11.322 8.72a7.143 7.143 0 0 1 11.306 5.804ZM9.675 10.366l9.966 9.963a7.143 7.143 0 0 1-11.282-5.22 7.137 7.137 0 0 1 1.316-4.743Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBan;
