import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCollectionsActionCreator,
  resetCollectionListActionCreator,
} from "../../../store/Collection/CollectionActions";
import { FiraCollection, RootState, VideoFilter } from "../../../utils/types.d";
import { RoundedX } from "../../FiraIcons";
import styles from "./SelectByCollectionName.module.scss";

interface SelectByCollectionNameProps {
  title?: string;
  onSelect?(collection: FiraCollection | null): void;
  defaultName?: string;
}

const SelectByCollectionName = ({
  title,
  onSelect,
  defaultName = t("components.selectByCollectionNameWidget.defaultv1"),
}: SelectByCollectionNameProps) => {
  const { brand, collection } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [selectedCollection, setSelectedCollection] =
    useState<FiraCollection | null>(null);
  const [firstFetch, setFirstFetch] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getName = () => {
    if (selectedCollection !== null) {
      return selectedCollection.collectionName;
    } else {
      return defaultName;
    }
  };

  const getCollections = () => {
    if (firstFetch) {
      if (brand.currentStore) {
        dispatch(
          getCollectionsActionCreator(
            brand.currentStore.id,
            20,
            0,
            VideoFilter.newer
          )
        );
      }
    } else {
      return;
    }
  };

  const handleSelection = (sel: FiraCollection) => {
    setIsOptionsOpen(false);
    setSelectedCollection(sel);
    if (onSelect) onSelect(sel);
  };

  const removeSelection = () => {
    setIsOptionsOpen(false);
    setSelectedCollection(null);
    if (onSelect) onSelect(null);
  };

  useEffect(() => {
    if (!collection.isLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [collection]);

  useEffect(() => {
    getCollections();
    setFirstFetch(false);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetCollectionListActionCreator());
    };
  }, [dispatch]);

  return (
    <>
      <div className={styles.CollectionSelectWrapper}>
        <div className={styles.CollectionSelectWrapper__Title}>
          <p>{title?.toUpperCase()}</p>
        </div>
        <div className={`${styles.CollectionSelectWrapper__Select}`}>
          <div
            className={`${styles.CollectionSelectWrapper__Select__Input} ${
              isOptionsOpen ? styles.isOpen : ""
            }`}
          >
            <div
              className={`${styles.CollectionSelectWrapper__Select__Input__Name}`}
              onClick={() => setIsOptionsOpen(!isOptionsOpen)}
            >
              {getName()}
            </div>
            {selectedCollection !== null && (
              <div
                className={`${styles.CollectionSelectWrapper__Select__Input__Icon}`}
                onClick={() => removeSelection()}
              >
                <RoundedX />
              </div>
            )}
          </div>
          {isOptionsOpen && (
            <div className={styles.CollectionSelectWrapper__Select__Options}>
              {!isLoading &&
                collection.list.map((item, i) => (
                  <li key={i} onClick={() => handleSelection(item)}>
                    {item.collectionName}
                  </li>
                ))}
              {isLoading && (
                <div className={styles.loaderContainer}>
                  <span className={styles.loader}></span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectByCollectionName;
