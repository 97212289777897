import { useEffect, useState } from "react";

import StepByStep from "../../components/StepByStep/StepByStep";
import {
  Category,
  EventGeneralDetailsType,
  RootState,
} from "../../utils/types";
import styles from "./EditVideoView.module.scss";
import { t } from "i18next";
import VideoGeneralData from "../../components/VideoEdit/VideoGeneralDataView/VideoGeneralData";
import EditProductsTable from "../../components/EditProductsTable/EditProductsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePreviewImageCreator,
  updateVideoGeneralInfoCreator,
  updateVideoTeaserCreator,
} from "../../store/Video/VideoActions";

import VideoSettings from "../../components/VideoSettings/VideoSettings";

export enum StepRoutes {
  general = "GENERAL_DETAILS",
  video = "VIDEO",
  products = "PRODUCTS",
  comments = "COMMENTS",
}

export interface Step {
  title: string;
  route: StepRoutes;
  active: boolean;
  ministeps?: Step[];
}
const StepsInitialState = [
  {
    title: t("views.videos.steps.generalData"),
    route: StepRoutes.general,
    active: true,
  },
  {
    title: t("views.videos.steps.video"),
    route: StepRoutes.video,
    active: false,
  },
  {
    title: t("views.videos.steps.products"),
    route: StepRoutes.products,
    active: false,
  },

  {
    title: t("views.videos.steps.comments"),
    route: StepRoutes.comments,
    active: false,
  },
];

const EditVideoView = () => {
  // GENERAL DETAILS SCREEN
  const dispatch = useDispatch();
  const { video } = useSelector((state: RootState) => state);
  const [teaserFile, setTeaserFile] = useState<File>();
  const [previewFile, setPreviewFile] = useState<File>();
  const [imagePreview, setImagePreview] = useState<string>("");
  const [teaser, setTeaser] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([]);

  const [isModifiedCategories, setIsModifiedCategories] =
    useState<boolean>(false);
  const [isModifiedDetails, setIsModifiedDetails] = useState<boolean>(false);
  const [isModifiedTeaser, setIsModifiedTeaser] = useState<boolean>(false);
  const [isModifiedPreview, setIsModifiedPreview] = useState<boolean>(false);
  const [generalDetails, setGeneralDetails] = useState<EventGeneralDetailsType>(
    {
      broadcastingId: "",
      eventName: "",
      scheduleDate: new Date("2022/10/02"),
    }
  );
  const [initCategory, setInitCategory] = useState<Category[]>([]);
  const [breadcrumbSteps, setBreadcrumbSteps] =
    useState<Step[]>(StepsInitialState);
  const [addCategories, setAddCategories] = useState<Category[]>();
  const [deletedCategories, setDeletedCategories] = useState<Category[]>();

  const initSteps = () => {
    const stepsObj = [
      {
        title: t("views.videos.steps.generalData"),
        route: StepRoutes.general,
        active: true,
      },
      {
        title: t("views.videos.steps.video"),
        route: StepRoutes.video,
        active: false,
      },
      {
        title: t("views.videos.steps.products"),
        route: StepRoutes.products,
        active: false,
      },
    ];
    setBreadcrumbSteps(stepsObj);
    if (video.currentVideo) {
      setInitCategory(video.currentVideo?.categories as Category[]);
      setCategories(video.currentVideo?.categories as Category[]);
    }
  };

  //FUNCTION CONTROL FOR GENERAL VIEW
  const handleDetailsData = (val: EventGeneralDetailsType) => {
    setIsModifiedDetails(true);
    setGeneralDetails(val);
  };

  const handleTeaserFile = (
    fileAvailable: boolean,
    teaser?: File,
    prev?: string
  ) => {
    if (fileAvailable) {
      setTeaserFile(teaser);
      setIsModifiedTeaser(true);
    }
    if (prev !== undefined) setTeaser(prev);
    setGeneralDetails({ ...generalDetails, teaserAvailable: fileAvailable });
  };

  const handlePreviewFile = (
    fileAvailable: boolean,
    image?: File,
    prev?: string
  ) => {
    if (fileAvailable) {
      setPreviewFile(image);
      setIsModifiedPreview(true);
    }
    if (prev !== undefined) setImagePreview(prev);
    setGeneralDetails({ ...generalDetails, imageAvailable: fileAvailable });
  };

  const handleCategories = (cat: Category[], isAdd: boolean) => {
    if (isAdd) {
      setAddCategories(cat);
    } else {
      setDeletedCategories(cat);
    }
    setCategories(cat);
    setGeneralDetails({ ...generalDetails, firaEventCategory: cat });
    setIsModifiedCategories(true);
  };

  //INIT VIEWS
  const generalView = (
    <VideoGeneralData
      detailsData={handleDetailsData}
      teaserFile={handleTeaserFile}
      previewFile={handlePreviewFile}
      previousData={generalDetails}
      prevImage={imagePreview}
      prevTeaser={teaser}
      newConfiguration={false}
      prevCategories={categories}
      handleCategories={handleCategories}
    />
  );

  const replaceVideoView = (
    <VideoSettings
      title={t("components.videoSettings.title")}
      subtitle={t("components.videoSettings.subtitle")}
      videoUrl={
        video.currentVideo?.videoUrls
          ? video.currentVideo?.videoUrls[0].url
          : ""
      }
      videoId={video.currentVideo?.id ? video.currentVideo?.id : ""}
      disableButtons={
        video.currentVideo?.videoEditing === "ENABLED" ? false : true
      }
    />
  );

  const productsView = <EditProductsTable />;

  const handleChange = (step: string) => {
    switch (step) {
      case StepRoutes.general:
        if (isModifiedDetails) {
          callServiceUpdateDetail();
        }
        if (isModifiedPreview) {
          callServiceUpdatePreview();
        }
        if (isModifiedTeaser) {
          callServiceUpdateTeaser();
        }
        if (isModifiedCategories) {
          callServiceUpdateCategories();
        }
        break;
      case StepRoutes.video:
        break;
    }
  };

  const callServiceUpdateDetail = () => {
    if (video.currentVideo)
      dispatch(
        updateVideoGeneralInfoCreator(
          video.currentVideo?.id,
          generalDetails.eventName
            ? generalDetails.eventName
            : video.currentVideo.videoName,
          generalDetails.eventDescription
            ? generalDetails.eventDescription
            : video.currentVideo.videoDescription
        )
      );
    setIsModifiedDetails(false);
  };

  const callServiceUpdatePreview = () => {
    if (video.currentVideo && previewFile && isModifiedPreview)
      dispatch(updatePreviewImageCreator(video.currentVideo.id, previewFile));
    setIsModifiedPreview(false);
  };

  const callServiceUpdateTeaser = () => {
    if (video.currentVideo && teaserFile)
      dispatch(updateVideoTeaserCreator(video.currentVideo?.id, teaserFile));
    setIsModifiedTeaser(false);
  };

  const callServiceUpdateCategories = () => {};

  useEffect(() => {
    initSteps();
  }, []);

  return (
    <div className={styles.ContainerEditVideo}>
      <StepByStep
        arrayView={[generalView, replaceVideoView, productsView]}
        steps={breadcrumbSteps}
        handleChange={handleChange}
      />
    </div>
  );
};

export default EditVideoView;
