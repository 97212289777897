import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify-icon/react";

import stylesheet from "./StepButtonText.module.scss";
import { useWizard } from "../../providers/WizardProvider";
import RadioButton from "../RadioButton/RadioButton";
import Input from "../Input/Input";
import Button from "../Button/Button";
import SvgWebButtonMockup from "../EventConfiguration/EventBuyButton/EventBuyButtonPrev/WebPrevSvg";

const {
  Wrapper,
  Header,
  RadioWrapper,
  CustomWrapper,
  Preview,
  MockupWrapper,
  Img,
  Indicators,
  Dot,
  TextSkeleton,
  IconMock,
  PreviewText,
} = stylesheet;

const StepButtonText: React.FC = () => {
  const { form, setFormKey } = useWizard();
  const { t } = useTranslation();
  const [type, setType] = useState<"DEFAULT" | "CUSTOM">(
    form.buyButtonText ? "CUSTOM" : "DEFAULT"
  );

  const handleSelect = (type: "DEFAULT" | "CUSTOM") => {
    setType(type);
    if (type === "DEFAULT") {
      setFormKey("buyButtonText", "");
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormKey("buyButtonText", event.target.value);
  };

  React.useEffect(() => {
    setFormKey("buyButtonText", form.buyButtonText || "");
  }, []);

  return (
    <div className={Wrapper}>
      <div className={Header}>
        <span>{t("event_creation.params.buyButtonTitle")}</span>
        <span>{t("event_creation.params.buyButtonMessage")}</span>
      </div>
      <div className={RadioWrapper}>
        <span onClick={() => handleSelect("DEFAULT")}>
          {t("event_creation.params.buyButtonDefault")}
          <RadioButton
            clickHandler={() => handleSelect("DEFAULT")}
            isActive={type === "DEFAULT"}
          />
        </span>
        <span onClick={() => handleSelect("CUSTOM")}>
          {t("event_creation.params.buyButtonCustom")}
          <RadioButton
            clickHandler={() => handleSelect("CUSTOM")}
            isActive={type === "CUSTOM"}
          />
        </span>
      </div>
      <div className={CustomWrapper}>
        <Input
          isDisabled={type === "DEFAULT"}
          height="40px"
          width="360px"
          fontSize="16px"
          value={form.buyButtonText}
          onChange={handleInputChange}
          placeholder={t("event_creation.params.buyButtonPlaceholder")}
        />
        <div className={Preview}>
          <span>{t("event_creation.params.buyButtonPrev")}</span>
          <div>
            <SvgWebButtonMockup currentColor={form.mainColor} />
            <MockupProduct color={form.mainColor} value={form.buyButtonText} />
          </div>
        </div>
      </div>
    </div>
  );
};

interface MockupProductProps {
  color: string;
  value: string;
}
const MockupProduct: React.FC<MockupProductProps> = ({ color, value }) => {
  const dots = Array.from({ length: 4 }, (_, index) => ({
    id: `item-${index}`,
  }));
  const { t } = useTranslation();
  return (
    <div className={MockupWrapper} style={{ borderColor: color }}>
      <Icon color="#5C767F" className={IconMock} icon="ph:x-circle-fill" />
      <div className={Img}></div>
      <div className={Indicators}>
        {dots.map((d, index) => (
          <div
            key={d.id}
            style={{ backgroundColor: index === 1 ? color : "" }}
            className={Dot}
          ></div>
        ))}
      </div>
      <div style={{ width: 132 }} className={TextSkeleton}></div>
      <div style={{ width: 100 }} className={TextSkeleton}></div>
      <Button height="32px" background={color} width="200px">
        <span className={PreviewText}>
          {value || t("event_creation.params.buyButtonView")}
        </span>
      </Button>
    </div>
  );
};

export default StepButtonText;
