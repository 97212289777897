import styles from "./InputColor.module.scss";
import React, { ChangeEvent, useEffect, useState } from "react";

interface InputColorProps {
  canEdit?: boolean;
  label?: string;
  onHexChange?(color: string): void;
  value?: string;
  isProductLabel?: boolean;
}

const InputColor = ({
  canEdit = true,
  label = "Color",
  onHexChange,
  value,
  isProductLabel = false,
}: InputColorProps) => {
  const [color, setColor] = useState<string>("D9D9D9");

  const onColorChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    console.log("change color", input);
    if (/^[A-Fa-f0-9-#]*$/.test(input)) {
      setColor(input);
      onHexChange && onHexChange(input); // Llamar a onHexChange directamente
    }
  };

  useEffect(() => {
    if (value) {
      setColor(value);
    }
  }, [value]);

  return (
    <>
      <div className={styles.InputColorWrapper}>
        {isProductLabel ? (
          <label style={{ fontSize: "12px" }}>{label}</label>
        ) : (
          <label>{label}</label>
        )}
        <div className={styles.ColorContainer}>
          <div
            className={styles.color}
            style={{ backgroundColor: "#" + color }}
          ></div>
          <div className={styles.fauxinput}>
            #
            <input
              disabled={!canEdit}
              type="text"
              maxLength={6}
              pattern={"^#(?:[0-9a-fA-F]{3}){1,2}$"}
              value={color}
              onChange={onColorChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InputColor;
