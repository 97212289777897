const handleExcelFileDownload = (data: ArrayBuffer, fileName: string) => {
  const blob = new Blob([data]),
    url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.click();
};

export { handleExcelFileDownload };
