import * as React from "react";
import { SVGProps } from "react";

const SvgTV = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M20.0002 15.0007V11.8004C20.0002 9.53782 20.0002 8.40572 19.297 7.70326C18.6369 7.0424 17.5984 7.0024 15.5999 7V19.8011C17.5984 19.7987 18.6369 19.7587 19.297 19.0978C20.0002 18.3954 20.0002 17.2625 20.0002 15.0007ZM17.6 11.0003C17.8122 11.0003 18.0157 11.0846 18.1658 11.2347C18.3158 11.3847 18.4001 11.5882 18.4001 11.8004C18.4001 12.0126 18.3158 12.2161 18.1658 12.3661C18.0157 12.5162 17.8122 12.6005 17.6 12.6005C17.3878 12.6005 17.1843 12.5162 17.0343 12.3661C16.8842 12.2161 16.8 12.0126 16.8 11.8004C16.8 11.5882 16.8842 11.3847 17.0343 11.2347C17.1843 11.0846 17.3878 11.0003 17.6 11.0003ZM17.6 14.2006C17.8122 14.2006 18.0157 14.2849 18.1658 14.4349C18.3158 14.585 18.4001 14.7885 18.4001 15.0007C18.4001 15.2129 18.3158 15.4164 18.1658 15.5664C18.0157 15.7165 17.8122 15.8008 17.6 15.8008C17.3878 15.8008 17.1843 15.7165 17.0343 15.5664C16.8842 15.4164 16.8 15.2129 16.8 15.0007C16.8 14.7885 16.8842 14.585 17.0343 14.4349C17.1843 14.2849 17.3878 14.2006 17.6 14.2006Z"
      fill="black"
    />
    <path
      d="M14.8569 4.99078L13.1328 7.00055H14.4009V19.8016H8.80041C6.53782 19.8016 5.40572 19.8016 4.70326 19.0984C4 18.3959 4 17.2638 4 15.002V11.8018C4 9.53757 4 8.40627 4.70326 7.70381C5.40572 7.00055 6.53782 7.00055 8.80041 7.00055H10.8678L9.14444 4.99078C9.04694 4.8693 9.00049 4.71468 9.01491 4.55958C9.02934 4.40449 9.10351 4.26108 9.22175 4.15968C9.33998 4.05827 9.49301 4.00682 9.6485 4.01619C9.80398 4.02557 9.94972 4.09503 10.0549 4.20991L12.0007 6.4789L13.9449 4.20991C14.0484 4.08896 14.1958 4.0141 14.3545 4.0018C14.5132 3.98949 14.6704 4.04075 14.7913 4.1443C14.9123 4.24785 14.9871 4.39521 14.9994 4.55395C15.0117 4.7127 14.9605 4.86983 14.8569 4.99078Z"
      fill="black"
    />
  </svg>
);

export default SvgTV;
