import Cross from '../WidgetIcons/Cross';
import './FiraFilters.scss'

const FiraFilters = (
  {categoriesList=[],
  categoriesToShow,
  handleShowMore,
  selectedFilter,
  setSelectedFilter,
  handleCloseMenu,
  primaryTextColor,
  secondaryTextColor,
  tertiaryTextColor,
  primaryColor,
  secondaryColor,
  })=>{

    const clickHandler=(selected)=>{
      if(selectedFilter === selected){
        setSelectedFilter()
      }else{
        setSelectedFilter(selected)
      }
    }
  return(<div className='fira-filter-container' >
  <div className='fira-filter-header'>
    <div style={{color:primaryTextColor}}>FILTROS</div>
    {handleCloseMenu &&
    <div 
      className='fira-filter-header-icon' 
      onClick={handleCloseMenu}>
        <Cross/>
    </div>
    } 
  </div>
  <div className='fira-filter-group-header' style={{color:tertiaryTextColor}}>Categoría</div>
  <div className='fira-filter-group'>

  { categoriesList.slice(0, categoriesToShow).map((category,index)=>{
    return (
    <div className='fira-filter-element' key={index}>
        <RadioButton 
          key={category.id} 
          isActive={selectedFilter === category.description} 
          clickHandler={()=>clickHandler(category.description)}
          secondaryTextColor={secondaryTextColor}/>
        <div className='fira-filter-text' style={{color:primaryColor}}>{category.description}</div>
        <div className='fira-filter-count' style={{color:secondaryColor}}>{category.videoCount}</div>
      </div>
      )
    })}
   {handleShowMore && categoriesList.length >= 8?
    <div
      className='fira-filter-show-more'
      onClick={handleShowMore}
      style={{color:secondaryTextColor}}>
      {categoriesToShow === 8 ? 'ver mas' : 'ver menos'}
    </div>:''}
  </div>
</div>)
}

const RadioButton =({
  clickHandler,
  isActive,
  secondaryTextColor
})=>{
  return(    <div
    onClick={ clickHandler}
    className={`fira-radio-button`}
    style={{
      '--fira-radio-color':secondaryTextColor
    }}
  > <div className={`fira-inside ${isActive ? "fira-is-active" : ""}`}></div></div>)
}

export default FiraFilters;