import React, { ChangeEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Add } from "../../../components/FiraIcons";
import Delete from "../../../components/FiraIcons/Delete";
import Input from "../../../components/Input/Input";
import InputColor from "../../../components/InputColor/InputColor";
import { RootState } from "../../../store/Store";
import StyleSheet from "./LabelProduct.module.scss";

export interface LabelProductCreate {
  name: string;
  labelColor: string;
  fontColor: string;
}

interface Props {
  canEdit?: boolean;
  onChange: (labels: LabelProductCreate[]) => void;
  initialLabels?: LabelProductCreate[];
}

const LabelProduct: React.FC<Props> = ({ onChange, canEdit = true }) => {
  const { t } = useTranslation();
  const { products } = useSelector((state: RootState) => state);
  const [labels, setLabels] = useState<LabelProductCreate[]>(
    products.currentProduct.labels && products.currentProduct.labels.length > 0
      ? products.currentProduct.labels
      : []
  );

  const noHash = (str: string) => {
    if (str) {
      return str.replace(/#/g, "");
    }
  };

  const handleAddLabel = () => {
    if (labels.length < 2) {
      setLabels([
        ...labels,
        { name: "", labelColor: "D9D9D9", fontColor: "D9D9D9" },
      ]);
    }
  };

  const handleRemoveLabel = (index: number) => {
    setLabels((prevLabels) => {
      const updatedLabels = prevLabels.filter((_, i) => i !== index);

      return updatedLabels;
    });
  };

  const handleLabelInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const index = Number(name.replace("nameLabel", ""));

    if (value.length <= 20) {
      if (!isNaN(index) && index >= 0 && index < labels.length) {
        setLabels((prevLabels) => {
          const updatedLabels = [...prevLabels];
          updatedLabels[index] = { ...updatedLabels[index], name: value };

          return updatedLabels;
        });
      }
    }
  };

  const handleColorChange = (
    index: number,
    colorType: "labelColor" | "fontColor",
    hexValue: string
  ) => {
    setLabels((prevLabels) => {
      const updatedLabels = [...prevLabels];
      updatedLabels[index] = { ...updatedLabels[index], [colorType]: hexValue };

      // Devolver el nuevo estado
      return updatedLabels;
    });
  };
  useEffect(() => {
    onChange(labels);
  }, [labels]);
  return (
    <div className={StyleSheet.LabelWrapper}>
      <h6 style={{ marginBottom: "16px" }}>
        {t("views.products.detailProduct.labels")}
      </h6>

      {labels.map((label, index) => (
        <div key={index} className="containerLabel">
          <div className={StyleSheet.ContainerTitle}>
            <div className={StyleSheet.labelTitle}>{`${t(
              "views.products.detailProduct.label"
            )} #${index + 1}`}</div>

            {canEdit && (
              <div
                className={StyleSheet.btnDelete}
                style={{ marginBottom: "8px" }}
              >
                <Delete onClick={() => handleRemoveLabel(index)} />
              </div>
            )}
          </div>

          <Input
            isDisabled={!canEdit}
            inputId={`nameLabel${index}`}
            inputName={`nameLabel${index}`}
            value={label.name}
            onChange={(e) => handleLabelInputChange(e)}
            width="100%"
            placeholder={t("views.products.detailProduct.example")}
          />
          <div style={{ display: "flex", width: "64%", marginTop: "9px" }}>
            <InputColor
              canEdit={canEdit}
              onHexChange={(hexValue) =>
                handleColorChange(index, "labelColor", hexValue)
              }
              label={"Fondo"}
              value={noHash(label.labelColor)}
              isProductLabel={true}
            />
          </div>
          <div style={{ display: "flex", width: "64%", marginTop: "9px" }}>
            <InputColor
              canEdit={canEdit}
              onHexChange={(hexValue) =>
                handleColorChange(index, "fontColor", hexValue)
              }
              label={"Texto"}
              value={noHash(label.fontColor)}
              isProductLabel={true}
            />
          </div>
        </div>
      ))}
      {labels.length < 2 && (
        <div className={StyleSheet.ContainerButton}>
          <button
            disabled={!canEdit}
            className={StyleSheet.AddImageButton}
            onClick={handleAddLabel}
          >
            <Add className={StyleSheet.AddImageIcon} width={32} height={32} />
          </button>
        </div>
      )}
    </div>
  );
};

export default LabelProduct;
