import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgEmptySwing = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 375 328"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g opacity={0.4}>
      <path
        d="M187.393 327.322c103.495 0 187.394-4.898 187.394-10.941 0-6.042-83.899-10.94-187.394-10.94C83.899 305.441 0 310.339 0 316.381c0 6.043 83.899 10.941 187.393 10.941Z"
        fill="#F5F5F5"
      />
      <path
        d="M63.799 158.998s-16.373 30.183-1.537 38.031l10.4-35.857-8.863-2.174Z"
        fill="#EBB376"
      />
      <path
        opacity={0.2}
        d="M63.799 158.998s-16.373 30.183-1.537 38.031l10.4-35.857-8.863-2.174Z"
        fill="#000"
      />
      <path
        d="M79.62 136.73s-11.926 6.842-18.363 23.36a129.043 129.043 0 0 0 16.759 4.919s6.156-16.681 1.604-28.279Z"
        fill="#FFDE07"
      />
      <path
        opacity={0.4}
        d="M79.62 136.73s-11.926 6.842-18.363 23.36a129.043 129.043 0 0 0 16.759 4.919s6.156-16.681 1.604-28.279Z"
        fill="#000"
      />
      <path
        d="M110.538 117.245c.072.101.149.198.232.29a14.021 14.021 0 0 0 4.079 3.605c4.668 2.532 8.263 2.784 11.105 1.141 4.04-2.523 6.475-8.303 8.031-15.648.72-2.902.974-5.9.754-8.882V97.277a10.24 10.24 0 0 0-5.586-8.021 21.406 21.406 0 0 0-4.147-1.663c-14.062-4.02-23.041 18.615-14.468 29.652Z"
        fill="#EBB376"
      />
      <path
        opacity={0.2}
        d="M110.77 117.535a13.704 13.704 0 0 0 4.137 3.383c4.668 2.522 8.302 2.619 11.153.966a14.501 14.501 0 0 1-8.699-3.208c-1.797-1.469-4.175-1.624-6.591-1.141Z"
        fill="#000"
      />
      <path
        d="M102.623 128.118c-2.725 3.277 4.321 7.674 4.321 7.674 9.587.261 13.028-3.315 13.028-3.315-5.074-6.553 1.401-15.522 1.401-15.522h-1.672l-12.564-3.035s-.28 9.095-4.514 14.198Z"
        fill="#EBB376"
      />
      <path
        d="M134.778 97.722s-1.933 10.09-12.487 11.753c0 0-6.852 9.152-12.816 7.664-8.128-2.03-12.071-15.464-6.04-26.395 7.809-14.217 31.131-9.114 31.343 6.978Z"
        fill="#263238"
      />
      <path
        d="M122.194 81.814s16.518 0 15.658 11.298c-1.199 15.667-15.706 13.096-15.706 13.096s.493 12.777-12.671 10.844c-22.055-3.054-12.66-48.711 12.719-35.238Z"
        fill="#263238"
      />
      <path
        d="M127.819 107.899c.416-1.546-.106-4.475-2.6-4.784-2.493-.309-5.644 5.412.078 8.467.821.434 1.643-.464 2.522-3.683Z"
        fill="#EBB376"
      />
      <path
        d="M120.348 82.577s12.439-3.383 17.339 4.6c3.508 5.703-.203 12.207-.203 12.207"
        stroke="#263238"
        strokeWidth={0.605}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.533 170.992c-5.906 2.754-7.732 4.214-13.898 6.34 1.343 3.866 4.416 13.299 3.595 14.169-2.291 2.455-19.794 1.353-19.794 1.353l-3.054 10.96a206.26 206.26 0 0 0 24.588-.348c10.815-1.034 14.69-.406 11.83-17.696-.551-3.48-2.417-11.521-3.267-14.778Z"
        fill="#EBB376"
      />
      <path
        d="M139.069 179.236a186.256 186.256 0 0 0 18.054-7.732 94.266 94.266 0 0 0-13.434-28.357s-13.927 4.736-12.207 14.217c1.054 5.751 5.132 14.991 7.587 21.872Z"
        fill="#FFDE07"
      />
      <path
        opacity={0.2}
        d="M139.069 179.236a186.256 186.256 0 0 0 18.054-7.732 94.266 94.266 0 0 0-13.434-28.357s-13.927 4.736-12.207 14.217c1.054 5.751 5.132 14.991 7.587 21.872Z"
        fill="#000"
      />
      <path
        d="M82.936 284.999a74.707 74.707 0 0 0 17.106 0s7.81-41.491 6.109-50.412H84.182s-1.246 39.22-1.246 50.412Z"
        fill="#FFDE07"
      />
      <path
        opacity={0.6}
        d="M82.936 284.999a74.707 74.707 0 0 0 17.106 0s7.81-41.491 6.109-50.412H84.182s-1.246 39.22-1.246 50.412Z"
        fill="#fff"
      />
      <path
        d="m147.613 283.724 16.846.193c2.242-10.129 11.202-60.686 2.958-76.353 0 0-2.948-6.437-19.33-3.953-16.382 2.484-39.24 8.495-56.057 11.463a87.988 87.988 0 0 1-30.174.174s-7.413 25.225 11.724 28.994c19.136 3.77 69.52.339 73.82-1.807 3.635-1.788 1.064 31.334.213 41.289Z"
        fill="#FFDE07"
      />
      <path
        opacity={0.6}
        d="m147.613 283.724 16.846.193c2.242-10.129 11.202-60.686 2.958-76.353 0 0-2.948-6.437-19.33-3.953-16.382 2.484-39.24 8.495-56.057 11.463a87.988 87.988 0 0 1-30.174.174s-7.413 25.225 11.724 28.994c19.136 3.77 69.52.339 73.82-1.807 3.635-1.788 1.064 31.334.213 41.289Z"
        fill="#fff"
      />
      <path
        d="m150.619 281.858-1.111 18.798 9.036-1.246 1.025-17.552h-8.95Z"
        fill="#EBB376"
      />
      <path
        opacity={0.2}
        d="m150.619 281.858-.551 9.163 9.008-.793.493-8.37h-8.95Z"
        fill="#000"
      />
      <path
        d="m146.386 286.981 19.996 1.314 1.711-8.553-21.968-1.025.261 8.264Z"
        fill="#FFDE07"
      />
      <path
        opacity={0.3}
        d="m146.386 286.981 19.996 1.314 1.711-8.553-21.968-1.025.261 8.264Z"
        fill="#fff"
      />
      <path
        d="M159.443 299.439h.194s.202.067.222.087c.019.019 0 .048 0 .067.025.01.052.01.077 0 .773.244 1.57.403 2.378.474.6.105 1.217-.019 1.73-.348a1.142 1.142 0 0 0 .338-1.16.843.843 0 0 0-.464-.638 3.766 3.766 0 0 0-2.522.464c.537-.563.856-1.3.898-2.078a.856.856 0 0 0-.773-.686 1.301 1.301 0 0 0-1.015.232c-.966.821-1.101 3.402-1.101 3.557l.038.029Zm.967-.532-.184.106c0-.86.193-2.3.764-2.783a.586.586 0 0 1 .464-.164h.106c.299.058.377.212.386.338a4.34 4.34 0 0 1-1.527 2.503h-.009Zm.135.454c1.092-.609 2.658-1.275 3.238-.966a.392.392 0 0 1 .222.319.79.79 0 0 1-.203.744c-.445.348-1.643.329-3.247-.097h-.01Z"
        fill="#FFDE07"
      />
      <path
        d="m159.221 298.327-10.206-.638a.65.65 0 0 0-.706.571l-.966 8.05a1.61 1.61 0 0 0 1.488 1.711c3.566.164 5.277.068 9.771.309 2.755.165 8.186.754 11.994.387s3.335-4.127 1.692-4.301c-3.866-.445-9.056-3.305-11.492-5.48a2.55 2.55 0 0 0-1.575-.609Z"
        fill="#263238"
      />
      <path
        d="m86.193 281.858-1.102 18.798 9.027-1.246 1.024-17.552h-8.95Z"
        fill="#EBB376"
      />
      <path
        opacity={0.2}
        d="m86.193 281.858-.542 9.163 9.008-.793.493-8.37h-8.96Z"
        fill="#000"
      />
      <path
        d="m81.969 286.981 19.987 1.314 1.711-8.553-21.959-1.025.261 8.264Z"
        fill="#FFDE07"
      />
      <path
        opacity={0.3}
        d="m81.969 286.981 19.987 1.314 1.711-8.553-21.959-1.025.261 8.264Z"
        fill="#fff"
      />
      <path
        d="M95.027 299.439h.183s.203.067.232.087c.03.019 0 .048 0 .067.025.01.053.01.078 0 .772.245 1.57.404 2.377.474.6.105 1.218-.019 1.73-.348a1.11 1.11 0 0 0 .329-1.16.803.803 0 0 0-.455-.638 3.762 3.762 0 0 0-2.522.464c.538-.563.856-1.3.899-2.078a.861.861 0 0 0-.783-.686 1.29 1.29 0 0 0-.967.232c-.966.821-1.101 3.402-1.101 3.557v.029Zm.966-.532-.193.106c0-.86.203-2.3.773-2.783a.587.587 0 0 1 .464-.164h.106c.3.058.367.212.377.338a4.263 4.263 0 0 1-1.517 2.503h-.01Zm.126.454c1.102-.609 2.658-1.275 3.237-.966a.377.377 0 0 1 .223.319.791.791 0 0 1-.194.744c-.454.348-1.642.329-3.257-.097h-.01Z"
        fill="#FFDE07"
      />
      <path
        d="m94.804 298.327-10.206-.638a.648.648 0 0 0-.716.57l-.966 8.051a1.614 1.614 0 0 0 1.488 1.711c3.557.164 5.268.068 9.762.309 2.755.165 8.196.754 11.994.387s3.335-4.127 1.701-4.301c-3.866-.445-9.056-3.305-11.501-5.48a2.553 2.553 0 0 0-1.556-.609Z"
        fill="#263238"
      />
      <path
        d="M99.82 129.326s-17.059 2.784-21.698 8.95c-4.64 6.166-27.13 74.188-16.295 76.991 13.918 3.595 59.314 5.799 62.899-3.054 3.586-8.853 10.023-43.705 18.364-58.222 2.812-4.91 3.015-7.915.628-10.882-5.239-6.515-19.649-9.868-22.664-11.898l.174-3.663a739.17 739.17 0 0 1-18.451-3.518l-2.957 5.296Z"
        fill="#FFDE07"
      />
      <path
        opacity={0.2}
        d="M100.41 128.331a2.427 2.427 0 0 1-1.653 1.198c-3.866.735-16.681 3.528-20.605 8.747-4.64 6.157-27.13 74.188-16.296 76.991 13.918 3.595 59.314 5.799 62.9-3.054 3.586-8.853 10.022-43.705 18.363-58.222 2.813-4.91 3.016-7.915.628-10.882-4.629-5.799-16.43-9.047-21.175-11.134a2.39 2.39 0 0 1-1.421-2.31 2.39 2.39 0 0 0-1.933-2.484 821.066 821.066 0 0 1-14.643-2.793 2.402 2.402 0 0 0-2.59 1.188l-1.575 2.755Z"
        fill="#000"
      />
      <path
        d="M25.1 0a11.501 11.501 0 0 0-11.5 11.492V316.38H36.62V11.491A11.49 11.49 0 0 0 25.101 0Z"
        fill="#263238"
      />
      <path
        opacity={0.1}
        d="M25.1 0a11.501 11.501 0 0 0-11.5 11.492V316.38H36.62V11.491A11.49 11.49 0 0 0 25.101 0Z"
        fill="#fff"
      />
      <path
        opacity={0.3}
        d="M32.842 14.69a5.384 5.384 0 1 1-5.373-5.373 5.374 5.374 0 0 1 5.373 5.374Z"
        fill="#000"
      />
      <path
        d="M211.604 250.64c0-1.043.638-1.933 1.421-1.933s1.421.851 1.421 1.933c0 1.083-.638 1.933-1.421 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 249.442v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 244.455c0-1.044.638-1.933 1.421-1.933s1.421.85 1.421 1.933c0 1.082-.638 1.933-1.421 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 243.256v-4.126"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 238.269c0-1.043.638-1.933 1.421-1.933s1.421.851 1.421 1.933c0 1.083-.638 1.933-1.421 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 237.071v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 232.064c0-1.043.638-1.933 1.421-1.933s1.421.851 1.421 1.933c0 1.083-.638 1.933-1.421 1.933s-1.421-.869-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 230.885v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 225.898c0-1.044.638-1.933 1.421-1.933s1.421.851 1.421 1.933c0 1.083-.638 1.933-1.421 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 224.7v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 219.713c0-1.044.638-1.933 1.421-1.933s1.421.85 1.421 1.933c0 1.082-.638 1.933-1.421 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 218.514v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.025 215.421c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.421.848-1.421 1.894 0 1.046.637 1.894 1.421 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 212.329v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.025 209.236c.785 0 1.421-.848 1.421-1.894 0-1.047-.636-1.895-1.421-1.895-.784 0-1.421.848-1.421 1.895 0 1.046.637 1.894 1.421 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 206.143v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.025 203.05c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.421.848-1.421 1.894 0 1.046.637 1.894 1.421 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 199.958v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.025 196.865c.785 0 1.421-.848 1.421-1.895 0-1.046-.636-1.894-1.421-1.894-.784 0-1.421.848-1.421 1.894 0 1.047.637 1.895 1.421 1.895Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 193.772v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.025 190.679c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.421.848-1.421 1.894 0 1.046.637 1.894 1.421 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 187.587v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.025 184.494c.785 0 1.421-.848 1.421-1.895 0-1.046-.636-1.894-1.421-1.894-.784 0-1.421.848-1.421 1.894 0 1.047.637 1.895 1.421 1.895Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 181.401v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.025 178.308c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.421.848-1.421 1.894 0 1.046.637 1.894 1.421 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 175.215v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 170.209c0-1.044.638-1.885 1.421-1.885s1.421.841 1.421 1.885-.638 1.933-1.421 1.933-1.421-.86-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 169.03v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 164.043c0-1.044.638-1.885 1.421-1.885s1.421.841 1.421 1.885-.638 1.933-1.421 1.933-1.421-.88-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 162.844v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 157.867c0-1.054.638-1.933 1.421-1.933s1.421.841 1.421 1.933-.638 1.933-1.421 1.933-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 156.659v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 151.681c0-1.044.638-1.933 1.421-1.933s1.421.851 1.421 1.933c0 1.083-.638 1.885-1.421 1.885s-1.421-.841-1.421-1.885Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 150.473v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 145.496c0-1.044.638-1.933 1.421-1.933s1.421.85 1.421 1.933c0 1.082-.638 1.884-1.421 1.884s-1.421-.84-1.421-1.884Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 144.288v-4.118"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 139.281c0-1.044.638-1.933 1.421-1.933s1.421.851 1.421 1.933c0 1.083-.638 1.885-1.421 1.885s-1.421-.812-1.421-1.885Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 138.102v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 132.583c0-1.043.638-1.933 1.421-1.933s1.421.851 1.421 1.933c0 1.083-.638 1.933-1.421 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 131.385v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 126.398c0-1.044.638-1.933 1.421-1.933s1.421.85 1.421 1.933c0 1.082-.638 1.933-1.421 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 125.199v-4.126"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 120.212c0-1.043.638-1.933 1.421-1.933s1.421.851 1.421 1.933c0 1.083-.638 1.933-1.421 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 119.014v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 114.027c0-1.044.638-1.933 1.421-1.933s1.421.85 1.421 1.933c0 1.082-.638 1.933-1.421 1.933s-1.421-.841-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 112.828v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 107.841c0-1.044.638-1.933 1.421-1.933s1.421.851 1.421 1.933c0 1.083-.638 1.933-1.421 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 106.643v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 101.656c0-1.044.638-1.933 1.421-1.933s1.421.85 1.421 1.933c0 1.082-.638 1.933-1.421 1.933s-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 100.457V96.33"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 95.47c0-1.044.638-1.885 1.421-1.885s1.421.841 1.421 1.885-.638 1.933-1.421 1.933-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 94.272v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 89.285c0-1.044.638-1.933 1.421-1.933s1.421.84 1.421 1.933c0 1.092-.638 1.933-1.421 1.933s-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 88.086V83.96"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 83.099c0-1.044.638-1.933 1.421-1.933s1.421.84 1.421 1.933c0 1.092-.638 1.933-1.421 1.933s-1.421-.84-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 81.9v-4.126"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 76.913c0-1.043.638-1.884 1.421-1.884s1.421.84 1.421 1.885c0 1.043-.638 1.933-1.421 1.933s-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 75.715v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 70.728c0-1.044.638-1.885 1.421-1.885s1.421.841 1.421 1.885-.638 1.933-1.421 1.933-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 69.53v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 64.542c0-1.043.638-1.884 1.421-1.884s1.421.84 1.421 1.884-.638 1.933-1.421 1.933-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 63.344v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 58.357c0-1.044.638-1.933 1.421-1.933s1.421.84 1.421 1.933c0 1.092-.638 1.933-1.421 1.933s-1.421-.88-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 57.158v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 52.171c0-1.044.638-1.933 1.421-1.933s1.421.841 1.421 1.933-.638 1.933-1.421 1.933-1.421-.84-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 50.973v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 45.995c0-1.053.638-1.933 1.421-1.933s1.421.841 1.421 1.933c0 1.093-.638 1.933-1.421 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 44.787V40.67"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 39.81c0-1.054.638-1.933 1.421-1.933s1.421.84 1.421 1.933c0 1.092-.638 1.884-1.421 1.884s-1.421-.84-1.421-1.884Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 38.602v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 33.624c0-1.044.638-1.933 1.421-1.933s1.421.85 1.421 1.933c0 1.083-.638 1.885-1.421 1.885s-1.421-.84-1.421-1.885Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 32.416V28.3"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 27.439c0-1.044.638-1.933 1.421-1.933s1.421.85 1.421 1.933c0 1.082-.638 1.933-1.421 1.933s-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 26.23v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.604 21.253c0-1.044.638-1.933 1.421-1.933s1.421.85 1.421 1.933c0 1.083-.638 1.933-1.421 1.933s-1.421-.85-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M213.025 20.045v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity={0.5} stroke="#000" strokeWidth={1.067}>
        <path
          opacity={0.5}
          d="M211.604 250.64c0-1.043.638-1.933 1.421-1.933.782 0 1.42.851 1.42 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 249.442v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 244.455c0-1.044.638-1.933 1.421-1.933.782 0 1.42.85 1.42 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 243.256v-4.126"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 238.269c0-1.043.638-1.933 1.421-1.933.782 0 1.42.851 1.42 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 237.071v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 232.064c0-1.043.638-1.933 1.421-1.933.782 0 1.42.851 1.42 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.869-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 230.885v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 225.898c0-1.044.638-1.933 1.421-1.933.782 0 1.42.851 1.42 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 224.7v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 219.713c0-1.044.638-1.933 1.421-1.933.782 0 1.42.85 1.42 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 218.514v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M213.025 215.421c.784 0 1.42-.848 1.42-1.894 0-1.046-.636-1.894-1.42-1.894-.785 0-1.421.848-1.421 1.894 0 1.046.636 1.894 1.421 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 212.329v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M213.025 209.236c.784 0 1.42-.848 1.42-1.894 0-1.047-.636-1.895-1.42-1.895-.785 0-1.421.848-1.421 1.895 0 1.046.636 1.894 1.421 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 206.143v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M213.025 203.05c.784 0 1.42-.848 1.42-1.894 0-1.046-.636-1.894-1.42-1.894-.785 0-1.421.848-1.421 1.894 0 1.046.636 1.894 1.421 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 199.958v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M213.025 196.865c.784 0 1.42-.848 1.42-1.895 0-1.046-.636-1.894-1.42-1.894-.785 0-1.421.848-1.421 1.894 0 1.047.636 1.895 1.421 1.895Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 193.772v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M213.025 190.679c.784 0 1.42-.848 1.42-1.894 0-1.046-.636-1.894-1.42-1.894-.785 0-1.421.848-1.421 1.894 0 1.046.636 1.894 1.421 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 187.587v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M213.025 184.494c.784 0 1.42-.848 1.42-1.895 0-1.046-.636-1.894-1.42-1.894-.785 0-1.421.848-1.421 1.894 0 1.047.636 1.895 1.421 1.895Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 181.401v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M213.025 178.308c.784 0 1.42-.848 1.42-1.894 0-1.046-.636-1.894-1.42-1.894-.785 0-1.421.848-1.421 1.894 0 1.046.636 1.894 1.421 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 175.215v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 170.209c0-1.044.638-1.885 1.421-1.885.782 0 1.42.841 1.42 1.885s-.638 1.933-1.42 1.933c-.783 0-1.421-.86-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 169.03v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 164.043c0-1.044.638-1.885 1.421-1.885.782 0 1.42.841 1.42 1.885s-.638 1.933-1.42 1.933c-.783 0-1.421-.88-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 162.844v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 157.867c0-1.054.638-1.933 1.421-1.933.782 0 1.42.841 1.42 1.933s-.638 1.933-1.42 1.933c-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 156.659v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 151.681c0-1.044.638-1.933 1.421-1.933.782 0 1.42.851 1.42 1.933 0 1.083-.638 1.885-1.42 1.885-.783 0-1.421-.841-1.421-1.885Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 150.473v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 145.496c0-1.044.638-1.933 1.421-1.933.782 0 1.42.85 1.42 1.933 0 1.082-.638 1.884-1.42 1.884-.783 0-1.421-.84-1.421-1.884Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 144.288v-4.118"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 139.281c0-1.044.638-1.933 1.421-1.933.782 0 1.42.851 1.42 1.933 0 1.083-.638 1.885-1.42 1.885-.783 0-1.421-.812-1.421-1.885Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 138.102v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 132.583c0-1.043.638-1.933 1.421-1.933.782 0 1.42.851 1.42 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 131.385v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 126.398c0-1.044.638-1.933 1.421-1.933.782 0 1.42.85 1.42 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 125.199v-4.126"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 120.212c0-1.043.638-1.933 1.421-1.933.782 0 1.42.851 1.42 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 119.014v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 114.027c0-1.044.638-1.933 1.421-1.933.782 0 1.42.85 1.42 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.841-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 112.828v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 107.841c0-1.044.638-1.933 1.421-1.933.782 0 1.42.851 1.42 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 106.643v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 101.656c0-1.044.638-1.933 1.421-1.933.782 0 1.42.85 1.42 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 100.457V96.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 95.47c0-1.044.638-1.885 1.421-1.885.782 0 1.42.841 1.42 1.885s-.638 1.933-1.42 1.933c-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 94.272v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 89.285c0-1.044.638-1.933 1.421-1.933.782 0 1.42.84 1.42 1.933 0 1.092-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 88.086V83.96"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 83.099c0-1.044.638-1.933 1.421-1.933.782 0 1.42.84 1.42 1.933 0 1.092-.638 1.933-1.42 1.933-.783 0-1.421-.84-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 81.9v-4.126"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 76.913c0-1.043.638-1.884 1.421-1.884.782 0 1.42.84 1.42 1.885 0 1.043-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 75.715v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 70.728c0-1.044.638-1.885 1.421-1.885.782 0 1.42.841 1.42 1.885s-.638 1.933-1.42 1.933c-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 69.53v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 64.542c0-1.043.638-1.884 1.421-1.884.782 0 1.42.84 1.42 1.884s-.638 1.933-1.42 1.933c-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 63.344v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 58.357c0-1.044.638-1.933 1.421-1.933.782 0 1.42.84 1.42 1.933 0 1.092-.638 1.933-1.42 1.933-.783 0-1.421-.88-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 57.158v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 52.171c0-1.044.638-1.933 1.421-1.933.782 0 1.42.841 1.42 1.933s-.638 1.933-1.42 1.933c-.783 0-1.421-.84-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 50.973v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 45.995c0-1.053.638-1.933 1.421-1.933.782 0 1.42.841 1.42 1.933 0 1.093-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 44.787V40.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 39.81c0-1.054.638-1.933 1.421-1.933.782 0 1.42.84 1.42 1.933 0 1.092-.638 1.884-1.42 1.884-.783 0-1.421-.84-1.421-1.884Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 38.602v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 33.624c0-1.044.638-1.933 1.421-1.933.782 0 1.42.85 1.42 1.933 0 1.083-.638 1.885-1.42 1.885-.783 0-1.421-.84-1.421-1.885Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 32.416V28.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 27.439c0-1.044.638-1.933 1.421-1.933.782 0 1.42.85 1.42 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 26.23v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M211.604 21.253c0-1.044.638-1.933 1.421-1.933.782 0 1.42.85 1.42 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.85-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M213.025 20.045v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M315.676 244.503H196.575a4.832 4.832 0 0 0-4.832 4.833v6.601a4.832 4.832 0 0 0 4.832 4.832h119.101a4.832 4.832 0 0 0 4.833-4.832v-6.601a4.833 4.833 0 0 0-4.833-4.833Z"
        fill="#263238"
      />
      <path
        opacity={0.1}
        d="M315.676 244.503H196.575a4.832 4.832 0 0 0-4.832 4.833v6.601a4.832 4.832 0 0 0 4.832 4.832h119.101a4.832 4.832 0 0 0 4.833-4.832v-6.601a4.833 4.833 0 0 0-4.833-4.833Z"
        fill="#fff"
      />
      <path
        d="M315.686 244.503h-29.835a4.833 4.833 0 0 0-4.833 4.833v6.601a4.832 4.832 0 0 0 4.833 4.832h29.835a4.832 4.832 0 0 0 4.833-4.832v-6.601a4.833 4.833 0 0 0-4.833-4.833Z"
        fill="#263238"
      />
      <path
        opacity={0.2}
        d="M304.958 252.631a4.195 4.195 0 1 1-8.39 0 4.195 4.195 0 0 1 8.39 0Z"
        fill="#000"
      />
      <path
        d="M299.855 250.64c0-1.043.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 249.442v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 244.455c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 243.256v-4.126"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 238.269c0-1.043.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 237.071v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 232.064c0-1.043.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.869-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 230.885v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 225.898c0-1.044.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 224.7v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 219.713c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 218.514v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M301.275 215.421c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.046.636 1.894 1.42 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 212.329v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M301.275 209.236c.785 0 1.421-.848 1.421-1.894 0-1.047-.636-1.895-1.421-1.895-.784 0-1.42.848-1.42 1.895 0 1.046.636 1.894 1.42 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 206.143v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M301.275 203.05c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.046.636 1.894 1.42 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 199.958v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M301.275 196.865c.785 0 1.421-.848 1.421-1.895 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.047.636 1.895 1.42 1.895Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 193.772v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M301.275 190.679c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.046.636 1.894 1.42 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 187.587v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M301.275 184.494c.785 0 1.421-.848 1.421-1.895 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.047.636 1.895 1.42 1.895Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 181.401v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M301.275 178.308c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.046.636 1.894 1.42 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 175.215v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 170.209c0-1.044.638-1.885 1.42-1.885.783 0 1.412.841 1.412 1.885s-.629 1.933-1.412 1.933c-.782 0-1.42-.86-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 169.03v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 164.043c0-1.044.638-1.885 1.42-1.885.783 0 1.412.841 1.412 1.885s-.629 1.933-1.412 1.933c-.782 0-1.42-.88-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 162.844v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 157.867c0-1.054.638-1.933 1.42-1.933.783 0 1.412.841 1.412 1.933s-.629 1.933-1.412 1.933c-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 156.659v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 151.681c0-1.044.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.885-1.412 1.885-.782 0-1.42-.841-1.42-1.885Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 150.473v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 145.496c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.884-1.412 1.884-.782 0-1.42-.84-1.42-1.884Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 144.288v-4.118"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 139.281c0-1.044.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.885-1.412 1.885-.782 0-1.42-.812-1.42-1.885Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 138.102v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 132.583c0-1.043.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 131.385v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 126.398c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 125.199v-4.126"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 120.212c0-1.043.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 119.014v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 114.027c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.841-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 112.828v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 107.841c0-1.044.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 106.643v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 101.656c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.89-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 100.457V96.33"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 95.47c0-1.044.638-1.885 1.42-1.885.783 0 1.412.841 1.412 1.885s-.629 1.933-1.412 1.933c-.782 0-1.42-.89-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 94.272v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 89.285c0-1.044.638-1.933 1.42-1.933.783 0 1.412.84 1.412 1.933 0 1.092-.629 1.933-1.412 1.933-.782 0-1.42-.89-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 88.086V83.96"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 83.099c0-1.044.638-1.933 1.42-1.933.783 0 1.412.84 1.412 1.933 0 1.092-.629 1.933-1.412 1.933-.782 0-1.42-.84-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 81.9v-4.126"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 76.913c0-1.043.638-1.884 1.42-1.884.783 0 1.412.84 1.412 1.885 0 1.043-.629 1.933-1.412 1.933-.782 0-1.42-.89-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 75.715v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 70.728c0-1.044.638-1.885 1.42-1.885.783 0 1.412.841 1.412 1.885s-.629 1.933-1.412 1.933c-.782 0-1.42-.89-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 69.53v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 64.542c0-1.043.638-1.884 1.42-1.884.783 0 1.412.84 1.412 1.884s-.629 1.933-1.412 1.933c-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 63.344v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 58.357c0-1.044.638-1.933 1.42-1.933.783 0 1.412.84 1.412 1.933 0 1.092-.629 1.933-1.412 1.933-.782 0-1.42-.88-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 57.158v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 52.171c0-1.044.638-1.933 1.42-1.933.783 0 1.412.841 1.412 1.933s-.629 1.933-1.412 1.933c-.782 0-1.42-.84-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 50.973v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 45.995c0-1.053.638-1.933 1.42-1.933.783 0 1.412.841 1.412 1.933 0 1.093-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 44.787V40.67"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 39.81c0-1.054.638-1.933 1.42-1.933.783 0 1.412.84 1.412 1.933 0 1.092-.629 1.884-1.412 1.884-.782 0-1.42-.84-1.42-1.884Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 38.602v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 33.624c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.083-.629 1.885-1.412 1.885-.782 0-1.42-.84-1.42-1.885Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 32.416V28.3"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 27.439c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.89-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 26.23v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.855 21.253c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.85-1.42-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M301.276 20.045v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity={0.5} stroke="#000" strokeWidth={1.067}>
        <path
          opacity={0.5}
          d="M299.855 250.64c0-1.043.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 249.442v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 244.455c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 243.256v-4.126"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 238.269c0-1.043.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 237.071v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 232.064c0-1.043.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.869-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 230.885v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 225.898c0-1.044.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 224.7v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 219.713c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 218.514v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M301.275 215.421c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.046.636 1.894 1.42 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 212.329v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M301.275 209.236c.785 0 1.421-.848 1.421-1.894 0-1.047-.636-1.895-1.421-1.895-.784 0-1.42.848-1.42 1.895 0 1.046.636 1.894 1.42 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 206.143v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M301.275 203.05c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.046.636 1.894 1.42 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 199.958v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M301.275 196.865c.785 0 1.421-.848 1.421-1.895 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.047.636 1.895 1.42 1.895Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 193.772v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M301.275 190.679c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.046.636 1.894 1.42 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 187.587v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M301.275 184.494c.785 0 1.421-.848 1.421-1.895 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.047.636 1.895 1.42 1.895Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 181.401v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M301.275 178.308c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894-.784 0-1.42.848-1.42 1.894 0 1.046.636 1.894 1.42 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 175.215v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 170.209c0-1.044.638-1.885 1.42-1.885.783 0 1.412.841 1.412 1.885s-.629 1.933-1.412 1.933c-.782 0-1.42-.86-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 169.03v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 164.043c0-1.044.638-1.885 1.42-1.885.783 0 1.412.841 1.412 1.885s-.629 1.933-1.412 1.933c-.782 0-1.42-.88-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 162.844v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 157.867c0-1.054.638-1.933 1.42-1.933.783 0 1.412.841 1.412 1.933s-.629 1.933-1.412 1.933c-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 156.659v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 151.681c0-1.044.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.885-1.412 1.885-.782 0-1.42-.841-1.42-1.885Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 150.473v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 145.496c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.884-1.412 1.884-.782 0-1.42-.84-1.42-1.884Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 144.288v-4.118"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 139.281c0-1.044.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.885-1.412 1.885-.782 0-1.42-.812-1.42-1.885Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 138.102v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 132.583c0-1.043.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 131.385v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 126.398c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 125.199v-4.126"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 120.212c0-1.043.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 119.014v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 114.027c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.841-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 112.828v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 107.841c0-1.044.638-1.933 1.42-1.933.783 0 1.412.851 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 106.643v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 101.656c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.89-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 100.457V96.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 95.47c0-1.044.638-1.885 1.42-1.885.783 0 1.412.841 1.412 1.885s-.629 1.933-1.412 1.933c-.782 0-1.42-.89-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 94.272v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 89.285c0-1.044.638-1.933 1.42-1.933.783 0 1.412.84 1.412 1.933 0 1.092-.629 1.933-1.412 1.933-.782 0-1.42-.89-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 88.086V83.96"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 83.099c0-1.044.638-1.933 1.42-1.933.783 0 1.412.84 1.412 1.933 0 1.092-.629 1.933-1.412 1.933-.782 0-1.42-.84-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 81.9v-4.126"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 76.913c0-1.043.638-1.884 1.42-1.884.783 0 1.412.84 1.412 1.885 0 1.043-.629 1.933-1.412 1.933-.782 0-1.42-.89-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 75.715v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 70.728c0-1.044.638-1.885 1.42-1.885.783 0 1.412.841 1.412 1.885s-.629 1.933-1.412 1.933c-.782 0-1.42-.89-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 69.53v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 64.542c0-1.043.638-1.884 1.42-1.884.783 0 1.412.84 1.412 1.884s-.629 1.933-1.412 1.933c-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 63.344v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 58.357c0-1.044.638-1.933 1.42-1.933.783 0 1.412.84 1.412 1.933 0 1.092-.629 1.933-1.412 1.933-.782 0-1.42-.88-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 57.158v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 52.171c0-1.044.638-1.933 1.42-1.933.783 0 1.412.841 1.412 1.933s-.629 1.933-1.412 1.933c-.782 0-1.42-.84-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 50.973v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 45.995c0-1.053.638-1.933 1.42-1.933.783 0 1.412.841 1.412 1.933 0 1.093-.629 1.933-1.412 1.933-.782 0-1.42-.889-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 44.787V40.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 39.81c0-1.054.638-1.933 1.42-1.933.783 0 1.412.84 1.412 1.933 0 1.092-.629 1.884-1.412 1.884-.782 0-1.42-.84-1.42-1.884Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 38.602v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 33.624c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.083-.629 1.885-1.412 1.885-.782 0-1.42-.84-1.42-1.885Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 32.416V28.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 27.439c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.082-.629 1.933-1.412 1.933-.782 0-1.42-.89-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 26.23v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M299.855 21.253c0-1.044.638-1.933 1.42-1.933.783 0 1.412.85 1.412 1.933 0 1.083-.629 1.933-1.412 1.933-.782 0-1.42-.85-1.42-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M301.276 20.045v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M71.415 139.281c0-1.044.638-1.933 1.42-1.933.783 0 1.421.851 1.421 1.933 0 1.083-.638 1.885-1.42 1.885-.783 0-1.421-.812-1.421-1.885Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 138.102v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 132.583c0-1.043.638-1.933 1.42-1.933.783 0 1.421.851 1.421 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 131.385v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 126.398c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 125.199v-4.126"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 120.212c0-1.043.638-1.933 1.42-1.933.783 0 1.421.851 1.421 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 119.014v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 114.027c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.841-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 112.828v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 107.841c0-1.044.638-1.933 1.42-1.933.783 0 1.421.851 1.421 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 106.643v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 101.656c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 100.457V96.33"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 95.47c0-1.044.638-1.885 1.42-1.885.783 0 1.421.841 1.421 1.885s-.638 1.933-1.42 1.933c-.783 0-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 94.272v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 89.285c0-1.044.638-1.933 1.42-1.933.783 0 1.421.84 1.421 1.933 0 1.092-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 88.086V83.96"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 83.099c0-1.044.638-1.933 1.42-1.933.783 0 1.421.84 1.421 1.933 0 1.092-.638 1.933-1.42 1.933-.783 0-1.421-.84-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 81.9v-4.126"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 76.913c0-1.043.638-1.884 1.42-1.884.783 0 1.421.84 1.421 1.885 0 1.043-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 75.715v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 70.728c0-1.044.638-1.885 1.42-1.885.783 0 1.421.841 1.421 1.885s-.638 1.933-1.42 1.933c-.783 0-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 69.53v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 64.542c0-1.043.638-1.884 1.42-1.884.783 0 1.421.84 1.421 1.884s-.638 1.933-1.42 1.933c-.783 0-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 63.344v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 58.357c0-1.044.638-1.933 1.42-1.933.783 0 1.421.84 1.421 1.933 0 1.092-.638 1.933-1.42 1.933-.783 0-1.421-.88-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 57.158v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 52.171c0-1.044.638-1.933 1.42-1.933.783 0 1.421.841 1.421 1.933s-.638 1.933-1.42 1.933c-.783 0-1.421-.84-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 50.973v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 45.995c0-1.053.638-1.933 1.42-1.933.783 0 1.421.841 1.421 1.933 0 1.093-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 44.787V40.67"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 39.81c0-1.054.638-1.933 1.42-1.933.783 0 1.421.84 1.421 1.933 0 1.092-.638 1.884-1.42 1.884-.783 0-1.421-.84-1.421-1.884Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 38.602v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 33.624c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.083-.638 1.885-1.42 1.885-.783 0-1.421-.84-1.421-1.885Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 32.416V28.3"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 27.439c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 26.23v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.415 21.253c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.85-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M72.874 20.045v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity={0.5} stroke="#000" strokeWidth={1.067}>
        <path
          opacity={0.5}
          d="M71.415 139.281c0-1.044.638-1.933 1.42-1.933.783 0 1.421.851 1.421 1.933 0 1.083-.638 1.885-1.42 1.885-.783 0-1.421-.812-1.421-1.885Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 138.102v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 132.583c0-1.043.638-1.933 1.42-1.933.783 0 1.421.851 1.421 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 131.385v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 126.398c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 125.199v-4.126"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 120.212c0-1.043.638-1.933 1.42-1.933.783 0 1.421.851 1.421 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 119.014v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 114.027c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.841-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 112.828v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 107.841c0-1.044.638-1.933 1.42-1.933.783 0 1.421.851 1.421 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 106.643v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 101.656c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 100.457V96.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 95.47c0-1.044.638-1.885 1.42-1.885.783 0 1.421.841 1.421 1.885s-.638 1.933-1.42 1.933c-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 94.272v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 89.285c0-1.044.638-1.933 1.42-1.933.783 0 1.421.84 1.421 1.933 0 1.092-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 88.086V83.96"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 83.099c0-1.044.638-1.933 1.42-1.933.783 0 1.421.84 1.421 1.933 0 1.092-.638 1.933-1.42 1.933-.783 0-1.421-.84-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 81.9v-4.126"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 76.913c0-1.043.638-1.884 1.42-1.884.783 0 1.421.84 1.421 1.885 0 1.043-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 75.715v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 70.728c0-1.044.638-1.885 1.42-1.885.783 0 1.421.841 1.421 1.885s-.638 1.933-1.42 1.933c-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 69.53v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 64.542c0-1.043.638-1.884 1.42-1.884.783 0 1.421.84 1.421 1.884s-.638 1.933-1.42 1.933c-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 63.344v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 58.357c0-1.044.638-1.933 1.42-1.933.783 0 1.421.84 1.421 1.933 0 1.092-.638 1.933-1.42 1.933-.783 0-1.421-.88-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 57.158v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 52.171c0-1.044.638-1.933 1.42-1.933.783 0 1.421.841 1.421 1.933s-.638 1.933-1.42 1.933c-.783 0-1.421-.84-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 50.973v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 45.995c0-1.053.638-1.933 1.42-1.933.783 0 1.421.841 1.421 1.933 0 1.093-.638 1.933-1.42 1.933-.783 0-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 44.787V40.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 39.81c0-1.054.638-1.933 1.42-1.933.783 0 1.421.84 1.421 1.933 0 1.092-.638 1.884-1.42 1.884-.783 0-1.421-.84-1.421-1.884Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 38.602v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 33.624c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.083-.638 1.885-1.42 1.885-.783 0-1.421-.84-1.421-1.885Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 32.416V28.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 27.439c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.082-.638 1.933-1.42 1.933-.783 0-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 26.23v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M71.415 21.253c0-1.044.638-1.933 1.42-1.933.783 0 1.421.85 1.421 1.933 0 1.083-.638 1.933-1.42 1.933-.783 0-1.421-.85-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M72.874 20.045v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M175.525 244.503h-119.1a4.833 4.833 0 0 0-4.833 4.833v6.601a4.832 4.832 0 0 0 4.833 4.832h119.1a4.832 4.832 0 0 0 4.833-4.832v-6.601a4.833 4.833 0 0 0-4.833-4.833Z"
        fill="#263238"
      />
      <path
        opacity={0.1}
        d="M175.525 244.503h-119.1a4.833 4.833 0 0 0-4.833 4.833v6.601a4.832 4.832 0 0 0 4.833 4.832h119.1a4.832 4.832 0 0 0 4.833-4.832v-6.601a4.833 4.833 0 0 0-4.833-4.833Z"
        fill="#fff"
      />
      <path
        d="M175.535 244.503H145.7a4.833 4.833 0 0 0-4.833 4.833v6.601a4.832 4.832 0 0 0 4.833 4.832h29.835a4.832 4.832 0 0 0 4.833-4.832v-6.601a4.833 4.833 0 0 0-4.833-4.833Z"
        fill="#263238"
      />
      <path
        opacity={0.2}
        d="M164.807 252.631a4.195 4.195 0 1 1-8.39 0 4.195 4.195 0 0 1 8.39 0Z"
        fill="#000"
      />
      <path
        d="M159.704 250.64c0-1.043.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 249.442v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 244.455c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 243.256v-4.126"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 238.269c0-1.043.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 237.071v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 232.064c0-1.043.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.869-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 230.885v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 225.898c0-1.044.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 224.7v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 219.713c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 218.514v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.125 215.421c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.046.636 1.894 1.421 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 212.329v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.125 209.236c.785 0 1.421-.848 1.421-1.894 0-1.047-.636-1.895-1.421-1.895s-1.421.848-1.421 1.895c0 1.046.636 1.894 1.421 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 206.143v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.125 203.05c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.046.636 1.894 1.421 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 199.958v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.125 196.865c.785 0 1.421-.848 1.421-1.895 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.047.636 1.895 1.421 1.895Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 193.772v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.125 190.679c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.046.636 1.894 1.421 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 187.587v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.125 184.494c.785 0 1.421-.848 1.421-1.895 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.047.636 1.895 1.421 1.895Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 181.401v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.125 178.308c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.046.636 1.894 1.421 1.894Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 175.215v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 170.209c0-1.044.638-1.885 1.421-1.885s1.411.841 1.411 1.885-.628 1.933-1.411 1.933-1.421-.86-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 169.03v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 164.043c0-1.044.638-1.885 1.421-1.885s1.411.841 1.411 1.885-.628 1.933-1.411 1.933-1.421-.88-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 162.844v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 157.867c0-1.054.638-1.933 1.421-1.933s1.411.841 1.411 1.933-.628 1.933-1.411 1.933-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 156.659v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 151.681c0-1.044.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.885-1.411 1.885s-1.421-.841-1.421-1.885Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 150.473v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 145.496c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.884-1.411 1.884s-1.421-.84-1.421-1.884Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 144.288v-4.118"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 139.281c0-1.044.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.885-1.411 1.885s-1.421-.812-1.421-1.885Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 138.102v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 132.583c0-1.043.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 131.385v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 126.398c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 125.199v-4.126"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 120.212c0-1.043.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 119.014v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 114.027c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.841-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 112.828v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 107.841c0-1.044.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 106.643v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 101.656c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 100.457V96.33"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 95.47c0-1.044.638-1.885 1.421-1.885s1.411.841 1.411 1.885-.628 1.933-1.411 1.933-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 94.272v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 89.285c0-1.044.638-1.933 1.421-1.933s1.411.84 1.411 1.933c0 1.092-.628 1.933-1.411 1.933s-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 88.086V83.96"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 83.099c0-1.044.638-1.933 1.421-1.933s1.411.84 1.411 1.933c0 1.092-.628 1.933-1.411 1.933s-1.421-.84-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 81.9v-4.126"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 76.913c0-1.043.638-1.884 1.421-1.884s1.411.84 1.411 1.885c0 1.043-.628 1.933-1.411 1.933s-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 75.715v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 70.728c0-1.044.638-1.885 1.421-1.885s1.411.841 1.411 1.885-.628 1.933-1.411 1.933-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 69.53v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 64.542c0-1.043.638-1.884 1.421-1.884s1.411.84 1.411 1.884-.628 1.933-1.411 1.933-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 63.344v-4.127"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 58.357c0-1.044.638-1.933 1.421-1.933s1.411.84 1.411 1.933c0 1.092-.628 1.933-1.411 1.933s-1.421-.88-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 57.158v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 52.171c0-1.044.638-1.933 1.421-1.933s1.411.841 1.411 1.933-.628 1.933-1.411 1.933-1.421-.84-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 50.973v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 45.995c0-1.053.638-1.933 1.421-1.933s1.411.841 1.411 1.933c0 1.093-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 44.787V40.67"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 39.81c0-1.054.638-1.933 1.421-1.933s1.411.84 1.411 1.933c0 1.092-.628 1.884-1.411 1.884s-1.421-.84-1.421-1.884Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 38.602v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 33.624c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.083-.628 1.885-1.411 1.885s-1.421-.84-1.421-1.885Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 32.416V28.3"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 27.439c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.89-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 26.23v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.704 21.253c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.85-1.421-1.933Z"
        stroke="#263238"
        strokeWidth={1.067}
        strokeMiterlimit={10}
      />
      <path
        d="M161.125 20.045v-4.117"
        stroke="#263238"
        strokeWidth={1.067}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity={0.5} stroke="#000" strokeWidth={1.067}>
        <path
          opacity={0.5}
          d="M159.704 250.64c0-1.043.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 249.442v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 244.455c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 243.256v-4.126"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 238.269c0-1.043.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 237.071v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 232.064c0-1.043.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.869-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 230.885v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 225.898c0-1.044.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 224.7v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 219.713c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 218.514v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M161.125 215.421c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.046.636 1.894 1.421 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 212.329v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M161.125 209.236c.785 0 1.421-.848 1.421-1.894 0-1.047-.636-1.895-1.421-1.895s-1.421.848-1.421 1.895c0 1.046.636 1.894 1.421 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 206.143v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M161.125 203.05c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.046.636 1.894 1.421 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 199.958v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M161.125 196.865c.785 0 1.421-.848 1.421-1.895 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.047.636 1.895 1.421 1.895Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 193.772v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M161.125 190.679c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.046.636 1.894 1.421 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 187.587v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M161.125 184.494c.785 0 1.421-.848 1.421-1.895 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.047.636 1.895 1.421 1.895Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 181.401v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M161.125 178.308c.785 0 1.421-.848 1.421-1.894 0-1.046-.636-1.894-1.421-1.894s-1.421.848-1.421 1.894c0 1.046.636 1.894 1.421 1.894Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 175.215v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 170.209c0-1.044.638-1.885 1.421-1.885s1.411.841 1.411 1.885-.628 1.933-1.411 1.933-1.421-.86-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 169.03v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 164.043c0-1.044.638-1.885 1.421-1.885s1.411.841 1.411 1.885-.628 1.933-1.411 1.933-1.421-.88-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 162.844v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 157.867c0-1.054.638-1.933 1.421-1.933s1.411.841 1.411 1.933-.628 1.933-1.411 1.933-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 156.659v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 151.681c0-1.044.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.885-1.411 1.885s-1.421-.841-1.421-1.885Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 150.473v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 145.496c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.884-1.411 1.884s-1.421-.84-1.421-1.884Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 144.288v-4.118"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 139.281c0-1.044.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.885-1.411 1.885s-1.421-.812-1.421-1.885Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 138.102v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 132.583c0-1.043.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 131.385v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 126.398c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 125.199v-4.126"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 120.212c0-1.043.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 119.014v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 114.027c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.841-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 112.828v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 107.841c0-1.044.638-1.933 1.421-1.933s1.411.851 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 106.643v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 101.656c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 100.457V96.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 95.47c0-1.044.638-1.885 1.421-1.885s1.411.841 1.411 1.885-.628 1.933-1.411 1.933-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 94.272v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 89.285c0-1.044.638-1.933 1.421-1.933s1.411.84 1.411 1.933c0 1.092-.628 1.933-1.411 1.933s-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 88.086V83.96"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 83.099c0-1.044.638-1.933 1.421-1.933s1.411.84 1.411 1.933c0 1.092-.628 1.933-1.411 1.933s-1.421-.84-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 81.9v-4.126"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 76.913c0-1.043.638-1.884 1.421-1.884s1.411.84 1.411 1.885c0 1.043-.628 1.933-1.411 1.933s-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 75.715v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 70.728c0-1.044.638-1.885 1.421-1.885s1.411.841 1.411 1.885-.628 1.933-1.411 1.933-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 69.53v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 64.542c0-1.043.638-1.884 1.421-1.884s1.411.84 1.411 1.884-.628 1.933-1.411 1.933-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 63.344v-4.127"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 58.357c0-1.044.638-1.933 1.421-1.933s1.411.84 1.411 1.933c0 1.092-.628 1.933-1.411 1.933s-1.421-.88-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 57.158v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 52.171c0-1.044.638-1.933 1.421-1.933s1.411.841 1.411 1.933-.628 1.933-1.411 1.933-1.421-.84-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 50.973v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 45.995c0-1.053.638-1.933 1.421-1.933s1.411.841 1.411 1.933c0 1.093-.628 1.933-1.411 1.933s-1.421-.889-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 44.787V40.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 39.81c0-1.054.638-1.933 1.421-1.933s1.411.84 1.411 1.933c0 1.092-.628 1.884-1.411 1.884s-1.421-.84-1.421-1.884Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 38.602v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 33.624c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.083-.628 1.885-1.411 1.885s-1.421-.84-1.421-1.885Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 32.416V28.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 27.439c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.082-.628 1.933-1.411 1.933s-1.421-.89-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 26.23v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.5}
          d="M159.704 21.253c0-1.044.638-1.933 1.421-1.933s1.411.85 1.411 1.933c0 1.083-.628 1.933-1.411 1.933s-1.421-.85-1.421-1.933Z"
          strokeMiterlimit={10}
        />
        <path
          opacity={0.5}
          d="M161.125 20.045v-4.117"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M347.01 12.014H27.778a2.677 2.677 0 0 0-2.677 2.677 2.687 2.687 0 0 0 2.677 2.687H347.01a2.688 2.688 0 0 0 2.678-2.687 2.678 2.678 0 0 0-2.678-2.678Z"
        fill="#263238"
      />
      <path
        opacity={0.6}
        d="M347.01 12.014H27.778a2.677 2.677 0 0 0-2.677 2.677 2.687 2.687 0 0 0 2.677 2.687H347.01a2.688 2.688 0 0 0 2.678-2.687 2.678 2.678 0 0 0-2.678-2.678Z"
        fill="#fff"
      />
      <path
        d="M166.904 10.41h-11.559a2.9 2.9 0 0 0-2.9 2.899v2.764a2.9 2.9 0 0 0 2.9 2.9h11.559a2.9 2.9 0 0 0 2.9-2.9v-2.764a2.9 2.9 0 0 0-2.9-2.9Z"
        fill="#263238"
      />
      <path
        opacity={0.1}
        d="M166.904 10.41h-11.559a2.9 2.9 0 0 0-2.9 2.899v2.764a2.9 2.9 0 0 0 2.9 2.9h11.559a2.9 2.9 0 0 0 2.9-2.9v-2.764a2.9 2.9 0 0 0-2.9-2.9Z"
        fill="#fff"
      />
      <path
        opacity={0.3}
        d="M166.895 10.41h-1.112a2.9 2.9 0 0 0-2.899 2.899v2.764a2.9 2.9 0 0 0 2.899 2.9h1.112a2.9 2.9 0 0 0 2.899-2.9v-2.764a2.9 2.9 0 0 0-2.899-2.9Z"
        fill="#000"
      />
      <path
        d="M170.77 12.014h-3.247a2.675 2.675 0 0 0-2.687 2.677 2.686 2.686 0 0 0 2.687 2.687h3.247v-5.364Z"
        fill="#263238"
      />
      <path
        opacity={0.6}
        d="M170.77 12.014h-3.247a2.675 2.675 0 0 0-2.687 2.677 2.686 2.686 0 0 0 2.687 2.687h3.247v-5.364Z"
        fill="#fff"
      />
      <path
        d="M78.17 10.41H66.611a2.9 2.9 0 0 0-2.9 2.899v2.764a2.9 2.9 0 0 0 2.9 2.9h11.56a2.9 2.9 0 0 0 2.899-2.9v-2.764a2.9 2.9 0 0 0-2.9-2.9Z"
        fill="#263238"
      />
      <path
        opacity={0.1}
        d="M78.17 10.41H66.611a2.9 2.9 0 0 0-2.9 2.899v2.764a2.9 2.9 0 0 0 2.9 2.9h11.56a2.9 2.9 0 0 0 2.899-2.9v-2.764a2.9 2.9 0 0 0-2.9-2.9Z"
        fill="#fff"
      />
      <path
        opacity={0.3}
        d="M78.17 10.41h-1.11a2.9 2.9 0 0 0-2.9 2.899v2.764a2.9 2.9 0 0 0 2.9 2.9h1.11a2.9 2.9 0 0 0 2.9-2.9v-2.764a2.9 2.9 0 0 0-2.9-2.9Z"
        fill="#000"
      />
      <path
        d="M82.047 12.014h-3.248a2.677 2.677 0 0 0-2.677 2.677 2.687 2.687 0 0 0 2.677 2.687h3.248v-5.364Z"
        fill="#263238"
      />
      <path
        opacity={0.6}
        d="M82.047 12.014h-3.248a2.677 2.677 0 0 0-2.677 2.677 2.687 2.687 0 0 0 2.677 2.687h3.248v-5.364Z"
        fill="#fff"
      />
      <path
        d="M307.017 10.41h-11.559a2.9 2.9 0 0 0-2.9 2.899v2.764a2.9 2.9 0 0 0 2.9 2.9h11.559a2.9 2.9 0 0 0 2.899-2.9v-2.764a2.9 2.9 0 0 0-2.899-2.9Z"
        fill="#263238"
      />
      <path
        opacity={0.1}
        d="M307.017 10.41h-11.559a2.9 2.9 0 0 0-2.9 2.899v2.764a2.9 2.9 0 0 0 2.9 2.9h11.559a2.9 2.9 0 0 0 2.899-2.9v-2.764a2.9 2.9 0 0 0-2.899-2.9Z"
        fill="#fff"
      />
      <path
        opacity={0.3}
        d="M307.017 10.41h-1.112a2.9 2.9 0 0 0-2.899 2.899v2.764a2.9 2.9 0 0 0 2.899 2.9h1.112a2.9 2.9 0 0 0 2.899-2.9v-2.764a2.9 2.9 0 0 0-2.899-2.9Z"
        fill="#000"
      />
      <path
        d="M310.892 12.014h-3.257a2.677 2.677 0 0 0-2.677 2.677 2.687 2.687 0 0 0 2.677 2.687h3.257v-5.364Z"
        fill="#263238"
      />
      <path
        opacity={0.6}
        d="M310.892 12.014h-3.257a2.677 2.677 0 0 0-2.677 2.677 2.687 2.687 0 0 0 2.677 2.687h3.257v-5.364Z"
        fill="#fff"
      />
      <path
        d="M218.293 10.41h-11.56a2.9 2.9 0 0 0-2.899 2.899v2.764a2.9 2.9 0 0 0 2.899 2.9h11.56a2.9 2.9 0 0 0 2.899-2.9v-2.764a2.9 2.9 0 0 0-2.899-2.9Z"
        fill="#263238"
      />
      <path
        opacity={0.1}
        d="M218.293 10.41h-11.56a2.9 2.9 0 0 0-2.899 2.899v2.764a2.9 2.9 0 0 0 2.899 2.9h11.56a2.9 2.9 0 0 0 2.899-2.9v-2.764a2.9 2.9 0 0 0-2.899-2.9Z"
        fill="#fff"
      />
      <path
        opacity={0.3}
        d="M218.292 10.41h-1.111a2.9 2.9 0 0 0-2.9 2.899v2.764a2.9 2.9 0 0 0 2.9 2.9h1.111a2.9 2.9 0 0 0 2.9-2.9v-2.764a2.9 2.9 0 0 0-2.9-2.9Z"
        fill="#000"
      />
      <path
        d="M222.187 12.014h-3.247a2.685 2.685 0 0 0-2.687 2.677 2.7 2.7 0 0 0 2.687 2.687h3.247v-5.364Z"
        fill="#263238"
      />
      <path
        opacity={0.6}
        d="M222.187 12.014h-3.247a2.685 2.685 0 0 0-2.687 2.677 2.7 2.7 0 0 0 2.687 2.687h3.247v-5.364Z"
        fill="#fff"
      />
      <path
        d="M349.687 0a11.489 11.489 0 0 0-11.52 11.492V316.38h22.993V11.491A11.505 11.505 0 0 0 349.687 0Z"
        fill="#263238"
      />
      <path
        opacity={0.1}
        d="M349.687 0a11.489 11.489 0 0 0-11.52 11.492V316.38h22.993V11.491A11.505 11.505 0 0 0 349.687 0Z"
        fill="#fff"
      />
      <path
        opacity={0.2}
        d="M13.6 11.492V316.38h5.567V11.491A11.5 11.5 0 0 1 27.865.349 11.472 11.472 0 0 0 13.59 11.492h.01ZM349.687 0a11.489 11.489 0 0 0-11.52 11.492V316.38h5.557V11.491A11.493 11.493 0 0 1 352.423.349 11.441 11.441 0 0 0 349.639 0h.048Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgEmptySwing;
