import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCopyPaper = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M18.8 20.4v1.8A1.8 1.8 0 0 1 17 24H9.8A1.8 1.8 0 0 1 8 22.2v-9.9a1.8 1.8 0 0 1 1.8-1.8h1.8"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6 7.8v10.8a1.8 1.8 0 0 0 1.8 1.8h7.2a1.8 1.8 0 0 0 1.8-1.8v-7.882a1.8 1.8 0 0 0-.542-1.287l-2.983-2.918A1.8 1.8 0 0 0 17.617 6H13.4a1.8 1.8 0 0 0-1.8 1.8v0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCopyPaper;
