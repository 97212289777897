import { reactiveEventProductsOnStreaming } from "../services/events";
import { AddEventProductForm } from "./types";

const reactiveProducts = async (reactiveProductsBody: AddEventProductForm) => {
  const results: any[] = [];
  for (const product of reactiveProductsBody.broadcastingProduct) {
    const response = await reactiveEventProductsOnStreaming(
      reactiveProductsBody.broadcastingId,
      product
    );
    results.push(response);
  }
  return results[0];
};

export default reactiveProducts;
