import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { usePopper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import {
  ownerStoreList,
  ownerStoreListByStateService,
} from "../../../services/store";
import {
  FiraEvent,
  FiraMassiveEventForm,
  FiraMultiEventForm,
  RootState,
  StoreStatusType,
} from "../../../utils/types.d";
import Button, { ButtonStyle } from "../../Button/Button";
import { RoundedX, SimpleX } from "../../FiraIcons";
import Input from "../../Input/Input";
import FiraInputSearch, {
  FiraInputSearchType,
} from "../../InputSearch/InputSearch";
import InputTime from "../../InputTime/InputTime";
import { VirtualElement } from "@popperjs/core";
import style from "./EventCreationPopUp.module.scss";
import { EventType } from "../../../utils/enums";
import {
  createMassiveEventActionCreator,
  createMultiEventActionCreator,
  postCreateEventActionCreator,
  setStoresListEventActionCreator,
} from "../../../store/Event/EventActions";
import { dateFormat } from "../../../utils/handleDates";
import { useClickOutside } from "../../../utils/useClickOutside";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import RadioButton from "../../RadioButton/RadioButton";

import InfoCircle from "../../FiraIcons/InfoCircle";
import PopUpInfo from "../../PopUpInfo/PopUpInfo";

interface FiraEventFormSubmitType {
  storeId?: string;
  storeIdList?: string[];
  eventName: string;
  startDate: Date;
  endDate: Date;
}

interface EventCreationPopUpProps {
  x?: number;
  y?: number;
  elementRef?: HTMLElement | undefined;
  startDate?: Date;
  onClose(): void;
  customDestination: string;
}

/**
 * Descripcion de componente
 * @prop {number} x - indica la posicion X del elemento seleccionado (solo para elemento Virtual)
 * @prop {number} y - indica la posicion Y del elemento seleccionado (solo para elemento Virtual)
 * @prop {Date} startDate - Indica la fecha seleccionada (solo para elemento Virtual)
 * @prop {HTMLElement} elementRef - Indica la referencia del elemento HTML
 * @prop {function()} onClose - Evento de cierra el popup
 * */

const EventCreationPopUp = ({
  x,
  y,
  startDate,
  elementRef,
  onClose,
  customDestination,
}: EventCreationPopUpProps) => {
  const initialStateForm: FiraEventFormSubmitType = {
    storeId: "",
    eventName: "",
    startDate: startDate ? startDate : new Date(),
    endDate: new Date(),
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authentication, event, brand } = useSelector(
    (state: RootState) => state
  );
  const { t } = useTranslation();
  const [form, setForm] = useState<FiraEventFormSubmitType>(initialStateForm);
  const weekDays = ["D", "L", "M", "M", "J", "V", "S"];
  const [timer, setTimer] = useState<boolean>(false);
  const [formValidated, setFormValidated] = useState<boolean>(true);
  const [storesList, setStoresList] = useState<FiraInputSearchType[]>([]);
  const [selectedStores, setSelectedStores] = useState<FiraInputSearchType[]>(
    []
  );
  const [elementVirtual, setElementVirtual] = useState<VirtualElement>({
    getBoundingClientRect: () => new DOMRect(x, y),
  });
  const [popper, setPopper] = useState<HTMLElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  const element = document.getElementById(customDestination);
  const [selectedOption, setSelectedOption] = useState<"MASIVE" | "MULTIPLE">(
    "MASIVE"
  );
  const domNode = useClickOutside(onClose);
  const mainStore = brand.list.find((item) => item.storeType === "MAIN_STORE");
  //Popper Modifiers

  const modifiers = [
    {
      name: "offset",
      options: {
        offset: [-100, -100],
      },
    },
    {
      name: "preventOverflow",
      options: {
        padding: 0,
        boundary: element,
      },
    },
    {
      name: "flip",
      options: {
        fallbackPlacements: ["left", "right"],
        allowedAutoPlacements: ["left", "right"],
      },
    },
  ];

  const { styles, attributes, update, state } = usePopper(
    elementRef ? elementRef : elementVirtual,
    popper,
    {
      modifiers,
      placement: "left-start",
    }
  );

  const popperStyle: any = {
    ...styles.popper,
    display: "flex",
    zIndex: 999,
    flexDirection: "column",
    borderRadius: "4px",
    padding: "10px",
  };

  const handleUpdatePosition = () => {
    update && update();
  };

  //Change handlers
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setForm({ ...form, eventName: value });
        break;
      case "startTime":
        if (value) {
          changeDate(value);
          setTimer(true);
          setSelectedTime(value);
        } else {
          setTimer(false);
          setSelectedTime("");
          setFormValidated(false);
        }
        break;
    }
  };

  const handleChangeDate = (item: DateObject) => {
    item._format = "DD/MM/YY";
    setSelectedDate(
      new Date(item.year, item.month.number - 1, item.day).toISOString()
    );
    setForm({
      ...form,
      startDate: new Date(item.year, item.month.number - 1, item.day),
    });
  };

  const changeDate = (time: string) => {
    const newTime = time.split(":");
    const h = Number(newTime[0]);
    const min = Number(newTime[1]);

    form.startDate.setHours(h, min);
    const fecha = new Date(form.startDate);
    setForm({ ...form, startDate: fecha });

    const fechaFin = new Date(form.startDate);
    fechaFin.setHours(h + 1, min);
    setForm({ ...form, endDate: fechaFin });
  };

  //End of Change handlers

  //Service Calls

  const getAllStores = async () => {
    const list: FiraInputSearchType[] = [];
    if (authentication.user?.businessClientId) {
      const response = await ownerStoreListByStateService(
        authentication.user.businessClientId,
        StoreStatusType.ACTIVE
      );

      if (response.data.length > 0) {
        response.data.map((store: FiraInputSearchType) => {
          list.push({
            name: store.name,
            value: store.id,
          });

          setStoresList(list);
        });
      }
    }
  };

  const handleSubmit = (e: React.FormEvent, eventType: EventType) => {
    e.preventDefault();

    // send selectedOption
    if (
      selectedOption === "MULTIPLE" &&
      form.storeIdList &&
      form.storeIdList?.length > 0
    ) {
      const formSend = {
        //storeId: form.storeIdList[0],
        eventName: form.eventName,
        eventDateTimeRange: {
          startDate: form.startDate.toISOString(),
          endDate: form.endDate.toISOString(),
        },
        storeIdList: form.storeIdList,
      };
      dispatch(createMultiEventActionCreator(formSend, eventType));
    } else if (selectedOption === "MASIVE") {
      const formSend: FiraMassiveEventForm = {
        storeId: mainStore?.id ? mainStore?.id : "",

        eventName: form.eventName,
        eventDateTimeRange: {
          startDate: form.startDate.toISOString(),
          endDate: form.endDate.toISOString(),
        },
      };

      dispatch(createMassiveEventActionCreator(formSend, eventType));
    }

    setFormValidated(false);
  };

  //End of Service Calls

  const CustomInput = ({ openCalendar, value, handleValueChange }: any) => {
    return (
      <Input
        inputName="date"
        width="186.5px"
        height="40px"
        onFocus={openCalendar}
        value={value}
        onChange={handleValueChange}
        isTextCenter={true}
        placeholder={t("views.events.popup_top.placeholder")}
      />
    );
  };

  //Store selection handler
  const handleSearchInput = (option: FiraInputSearchType[]) => {
    setSelectedStores(option);
  };

  const removeFromSelection = (option: FiraInputSearchType) => {
    let newArr = [];
    newArr = selectedStores.filter((store) => store !== option);
    setSelectedStores(newArr);
  };

  //End of store selection handler

  //Validation and Formats
  const checkFormValidation = () => {
    if (
      form.eventName !== "" &&
      selectedDate !== "" &&
      selectedTime !== ""
      //selectedStores.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  //End of validation and Formats

  useEffect(() => {
    let arr: string[] = [];
    if (selectedStores.length > 0) {
      selectedStores.map((store) => {
        if (store.value) arr.push(store.value as string);
      });
    }

    setForm({ ...form, storeIdList: arr });
    handleUpdatePosition();
  }, [selectedStores]);

  useEffect(() => {
    setFormValidated(checkFormValidation());
  }, [form]);

  useEffect(() => {
    getAllStores();
  }, [authentication]);

  useEffect(() => {
    setElementVirtual({ getBoundingClientRect: () => new DOMRect(x, y) });
  }, [x, y]);

  useEffect(() => {
    setPortalElement(element);
    handleUpdatePosition();
  }, []);

  useEffect(() => {
    if (event.isLoading) {
      setFormValidated(false);
    }
  }, [event.isLoading]);

  useEffect(() => {
    if (startDate) {
      setSelectedDate(startDate.toISOString());
    }
  }, [startDate]);

  useEffect(() => {
    if (event.createSuccess && selectedOption === "MASIVE") {
      dispatch(setStoresListEventActionCreator(selectedStores));
      const eventResponse = event.currentEvent as FiraEvent;
      /*if (
        form.storeIdList?.length &&
        form.storeIdList?.length > 0 &&
        selectedOption === "MULTIPLE"
      ) {
        navigate(`/home/calendar/new/${eventResponse.id}`);
      } else */

      if (selectedOption === "MASIVE") {
        navigate(`/home/calendar/new/${event.currentEvent?.id}`);
      } else {
        onClose();
      }
    }
  }, [event]);

  const clickMassive = () => {
    setSelectedStores(storesList);
    setSelectedOption("MASIVE");
  };

  const clickMultiple = () => {
    setSelectedStores([]);
    setSelectedOption("MULTIPLE");
  };

  const [showRetransmission, setShowRetransmission] = useState(false);
  const [showInfoPlaceholder, setShowInfoPlaceholder] = useState(false);
  const [elemetTarget, setElementTarget] = useState<HTMLElement>();
  const [isActiveRetransmission, setIsActiveRetransmission] = useState(false);
  const [isActivePlaceholder, setIsActivePlaceholder] = useState(false);

  const handleEventInfoReTransmission = (e: SyntheticEvent<HTMLElement>) => {
    setShowRetransmission(showRetransmission ? false : true);
    setIsActiveRetransmission(!showRetransmission);
    setElementTarget(e.currentTarget);
  };

  const handleEventInfoPlaceholder = (e: SyntheticEvent<HTMLElement>) => {
    setShowInfoPlaceholder(showInfoPlaceholder ? false : true);
    setIsActivePlaceholder(!showInfoPlaceholder);
    setElementTarget(e.currentTarget);
  };

  return (
    <>
      {portalElement &&
        ReactDOM.createPortal(
          <div ref={setPopper} style={popperStyle} {...attributes.popper}>
            <div className={style.EventCreationPopUpWrapper} ref={domNode}>
              <div className={style.EventCreationPopUpWrapper__Top}>
                <span>{t("views.events.popup.event_name")}</span>
                <div
                  className={style.EventCreationPopUpWrapper__Top__CloseIcon}
                >
                  <RoundedX
                    width={"14px"}
                    height={"14px"}
                    color={"#ABAFB1"}
                    onClick={onClose}
                  />
                </div>
              </div>
              <form
                className={style.EventCreationPopUpWrapper__EventCreationForm}
              >
                {/* Name of the event */}
                <div
                  className={`${style.EventCreationPopUpWrapper__EventCreationForm__InputContainer}`}
                >
                  <Input
                    inputName={"name"}
                    inputId={"name"}
                    type={"text"}
                    width={"381px"}
                    height={"40px"}
                    value={form.eventName}
                    onChange={handleChange}
                  />
                </div>
                {/* end of name of the event */}

                {/* Date and Hour Inputs */}
                <div
                  className={`${style.EventCreationPopUpWrapper__EventCreationForm__InputContainer}`}
                  style={{ gap: "8px" }}
                >
                  <div>
                    <label
                      htmlFor="date"
                      className={`${style.EventCreationPopUpWrapper__EventCreationForm__InputContainer__Label}`}
                    >
                      {t("views.events.new_popup.start_time")}
                    </label>
                    {startDate ? (
                      <Input
                        width="100%"
                        height="40px"
                        value={dateFormat(form.startDate)}
                        isTextCenter={true}
                        isDisabled={true}
                      />
                    ) : (
                      <DatePicker
                        arrow={false}
                        minDate={new Date()}
                        format="DD/MM/YY"
                        className="yellow custom-calendar "
                        weekDays={weekDays}
                        onChange={handleChangeDate}
                        render={<CustomInput />}
                      />
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="startTime"
                      className={`${style.EventCreationPopUpWrapper__EventCreationForm__InputContainer__Label}`}
                    >
                      {t("views.events.popup_top.time")}
                    </label>
                    <InputTime
                      width="186.5px"
                      inputName={"startTime"}
                      inputId={"startTime"}
                      type={"time"}
                      height={"40px"}
                      onChange={handleChange}
                      isTextCenter={true}
                    />
                  </div>
                </div>
                {/* end of date and Hour Inputs */}

                <div className={style.SwitchContainer}>
                  <label>
                    <span
                      className={""}
                      onMouseEnter={handleEventInfoReTransmission}
                      onMouseLeave={handleEventInfoReTransmission}
                    >
                      <InfoCircle
                        color={isActiveRetransmission ? "#ffde07" : "#000"}
                      />
                    </span>
                    {showRetransmission && (
                      <PopUpInfo
                        title={t("views.events.new_popup.infoSimulcast")}
                        elementRef={elemetTarget}
                      />
                    )}
                    {t("views.events.new_popup.simulcastEvent")}
                    <RadioButton
                      isActive={selectedOption === "MASIVE"}
                      clickHandler={() => clickMassive()}
                    />
                  </label>
                  <label>
                    <span
                      className={""}
                      onMouseEnter={handleEventInfoPlaceholder}
                      onMouseLeave={handleEventInfoPlaceholder}
                    >
                      <InfoCircle
                        color={isActivePlaceholder ? "#ffde07" : "#000"}
                      />
                    </span>
                    {showInfoPlaceholder && (
                      <PopUpInfo
                        title={t("views.events.new_popup.infoIndependent")}
                        elementRef={elemetTarget}
                      />
                    )}
                    {t("views.events.new_popup.independentEvents")}
                    <RadioButton
                      isActive={selectedOption === "MULTIPLE"}
                      clickHandler={() => clickMultiple()}
                    />
                  </label>
                </div>
                {/* Store selection Input and list */}
                {selectedOption === "MULTIPLE" && (
                  <>
                    <div
                      className={`${style.EventCreationPopUpWrapper__EventCreationForm__InputContainer} ${style.SearchInput}`}
                    >
                      <FiraInputSearch
                        width="100%"
                        height="40px"
                        placeholder={t(
                          "views.events.new_popup.placeholderSearch"
                        )}
                        options={storesList}
                        onSelect={handleSearchInput}
                        multipleSelection={true}
                        currentSelection={selectedStores}
                      />
                    </div>
                    <div
                      className={`${style.EventCreationPopUpWrapper__EventCreationForm__InputContainerList} ${style.EventCreationPopUpWrapper__EventCreationForm__InputContainerList__SelectedOptions}`}
                    >
                      {selectedStores.map((item, index) => (
                        <div
                          key={index}
                          className={
                            style.EventCreationPopUpWrapper__EventCreationForm__InputContainerList__SelectedOptions__SelectedItem
                          }
                        >
                          {item.name}

                          <RoundedX
                            width={"10px"}
                            height={"10px"}
                            className={
                              style.EventCreationPopUpWrapper__EventCreationForm__InputContainerList__SelectedOptions__SelectedItem__ItemCloseIcon
                            }
                            onClick={() => removeFromSelection(item)}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {/* end of store selection Input and list */}

                {/* Button containers */}
                <div
                  className={`${style.EventCreationPopUpWrapper__EventCreationForm__InputContainer} ${style.Buttons}`}
                >
                  <Button
                    width={"100%"}
                    height={"32px"}
                    isDisabled={!formValidated}
                    borderRadius={"8px"}
                    clickHandler={(e) => handleSubmit(e, EventType.broadcast)}
                  >
                    <span className={style.InnerText}>
                      {t("views.events.popup_top.button_create")}
                    </span>
                  </Button>

                  {/* Por ahora no disponible eventos prueba masivos */}
                  <div
                    style={
                      !formValidated
                        ? { pointerEvents: "none", opacity: "0.5" }
                        : {}
                    }
                  >
                    <Button
                      width={"100%"}
                      height={"32px"}
                      buttonStyle={ButtonStyle.transparent}
                      clickHandler={(e) => handleSubmit(e, EventType.test)}
                    >
                      <span className={style.InnerText}>
                        {t("views.events.popup_top.button_create_test")}
                      </span>
                    </Button>
                  </div>
                </div>
                {/* end of button containers */}
              </form>
            </div>
          </div>,
          portalElement
        )}
    </>
  );
};

export default EventCreationPopUp;
