import { t } from "i18next";
import styles from "./ToggleSwitch.module.scss";

interface ToggleSwitchProps {
  handleChecked(): void;
  checked: boolean;
  hideTags?: boolean;
  disable?: boolean;
}

const ToggleSwitch = ({
  handleChecked,
  checked,
  hideTags,
  disable,
}: ToggleSwitchProps) => {
  return (
    <>
      <div
        className={styles.ToggleContainer}
        style={hideTags ? { width: "56px" } : { width: "120px" }}
      >
        {!hideTags && <span className={styles.h6}>{t("toggle.no")}</span>}

        <div>
          <label className={styles.switch}>
            <input
              type="checkbox"
              onChange={handleChecked}
              checked={checked}
              disabled={disable}
            />
            <span className={styles.slider}></span>
          </label>
        </div>
        {!hideTags && <span className={styles.h6}>{t("toggle.yes")}</span>}
      </div>
    </>
  );
};

export default ToggleSwitch;
