import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import { FiraPackage } from "../../utils/enums";
import { InvitationType, RoleType, RootState } from "../../utils/types";
import FiraInvitationForm from "./FiraInvitationForm/FiraInvitationForm";
import FiraOperatorRole from "./FiraOperatorRole/FiraOperatorRole";
import FiraPermissions from "./FiraPermissions/FiraPermissions";
import {
  getOperatorPermissions,
  sendInvitationService,
} from "../../services/user";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";
import { onlyAvailablePermissions } from "../../utils/handlePermissionCheckboxSelection";
import { SimplePermissionItem } from "../../types/Response";
import { resetCurrentEventActionCreator } from "../../store/Event/EventActions";
import { resetCurrentStoreActionCreator } from "../../store/Brand/BrandActions";
import { handleError } from "../../services/errorHandling";

const InvitationInitialState: InvitationType = {
  storeId: "",
  name: "",
  userEmail: "",
  role: null,
  permissions: [],
};

function SendInvitationView() {
  const { brand, authentication, utils } = useSelector(
    (state: RootState) => state
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [invitation, setInvitation] = useState<InvitationType>(
    InvitationInitialState
  );
  const showAlert = usePopupAlert();
  const [userPermissionFullList, setUserPermissionFullList] = useState<
    string[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleBackButton = () => {
    if (authentication.sessionId && utils.prevPage === "storesDetailView") {
      dispatch(resetCurrentStoreActionCreator(authentication.sessionId));
      navigate(`/home/stores/${brand.currentStore?.id}`);
    } else {
      navigate(-1);
    }
  };

  const handleNext = () => {
    if (currentPage == pages.length - 1) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage == 0) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSendInvitation = async () => {
    setIsLoading(true);
    let message = "";
    if (invitation) {
      try {
        await sendInvitationService(invitation).then((data) => {
          if (data.status === 200) {
            message = t("views.operators.success") + invitation.userEmail;
            showAlert(AlertType.success, message);
          }
        });
      } catch (error) {
        switch (handleError(error).message) {
          case "The user was already added to the store":
            message = t("views.operators.already_invited");
            break;
          case "You can not create an account for yourself":
            message = t("views.operators.himself_invitation");
            break;
          default:
            message = t("views.operators.duplicated_invitation");
        }
        showAlert(AlertType.error, message);
      }
      handleBackButton();
    }
  };

  /** From Invitation Form Page */
  const handleInputChange = (value: string, name: string) => {
    if (name === "name") {
      setInvitation((prevState: InvitationType) => ({
        ...prevState,
        name: value ? value : "",
      }));
    } else if (name === "email") {
      setInvitation((prevState: InvitationType) => ({
        ...prevState,
        userEmail: value ? value : "",
      }));
    }
  };

  /** From Invitation Operator Role Page */
  const handleRoleSelection = (role: RoleType) => {
    if (role !== null) {
      setInvitation((prevState: InvitationType) => ({
        ...prevState,
        role: role,
        permissions: [],
      }));
    }
  };

  /** From Fira Permissions Page */
  const addFiraProduct = (product: FiraPackage) => {
    let productArr: any[] = [...invitation.permissions];
    productArr.push({ firaProduct: product });

    setInvitation({ ...invitation, permissions: productArr });
  };

  const removeFiraProduct = (product: FiraPackage) => {
    let productArr = invitation.permissions.filter(
      (item) => item.firaProduct != product
    );

    setInvitation({
      ...invitation,
      permissions: productArr,
    });
  };

  const addPermissionsByGroup = (
    firaPackage: FiraPackage,
    permissions: string[]
  ) => {
    //Antes de agregar, detectar si el firaProduct existe.
    const firaProductAlreadyExists = invitation.permissions.find(
      (p) => p.firaProduct === firaPackage
    );

    if (firaProductAlreadyExists) {
      //Si el firaProduct existe, concatenar arreglo de permisos
      const mergedPermissions = [
        ...firaProductAlreadyExists.permissions,
        ...permissions,
      ];

      //Check if permissions exist in users permission before sending them!
      const available = onlyAvailablePermissions(
        userPermissionFullList,
        mergedPermissions
      );

      const updatedInvitation = invitation.permissions.map((element) =>
        element.firaProduct === firaPackage
          ? { ...element, permissions: available }
          : element
      );

      setInvitation({ ...invitation, permissions: updatedInvitation });
    } else {
      //...Si no existe, simplemente agregar
      setInvitation({
        ...invitation,
        permissions: [
          ...invitation.permissions,
          { firaProduct: firaPackage, permissions: permissions },
        ],
      });
    }
  };

  const removePermissionsByGroup = (
    firaPackage: FiraPackage,
    permissions: string[]
  ) => {
    /**Siempre chequear si el firaProduct existe primero,
     * ya que en caso de que no exista, el filtro no funcionará
     * **/

    const firaProductAlreadyExists = invitation.permissions.find(
      (p) => p.firaProduct === firaPackage
    );

    if (firaProductAlreadyExists) {
      const updatedPermissions = firaProductAlreadyExists.permissions.filter(
        (element) => !permissions.includes(element)
      );

      const updatedInvitation = invitation.permissions.map((element) =>
        element.firaProduct === firaPackage
          ? { ...element, permissions: updatedPermissions }
          : element
      );

      if (
        firaProductAlreadyExists.firaProduct !== FiraPackage.PROSELLER &&
        updatedInvitation[0].permissions.length === 0
      ) {
        const deleteEntireFiraProduct = removeEntireGroup(
          firaProductAlreadyExists.firaProduct
        );

        setInvitation({ ...invitation, permissions: deleteEntireFiraProduct });
      } else {
        setInvitation({ ...invitation, permissions: updatedInvitation });
      }
    }
  };

  const removeEntireGroup = (group: FiraPackage) => {
    const deleteEntireGroup = invitation.permissions.filter(
      (obj) => obj.firaProduct !== group
    );

    return deleteEntireGroup;
  };

  const getAllPermissions = (firaProducts: SimplePermissionItem[]) => {
    const allPermissions = [];
    for (const productsArray of firaProducts) {
      allPermissions.push(...productsArray.permissions);
    }
    return allPermissions;
  };

  const handleOperatorInvite = async () => {
    try {
      setIsLoading(true);
      const response = await getOperatorPermissions();
      const currentInvitation: InvitationType = {
        ...invitation,
        permissions: response,
      };
      await sendInvitationService(currentInvitation);
      showAlert(
        AlertType.success,
        `${t("views.operators.success")} ${invitation.userEmail}`
      );
      handleBackButton();
    } catch (error) {
      showAlert(AlertType.error, JSON.stringify(error));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (brand.currentStore) {
      setInvitation((prevState: InvitationType) => ({
        ...prevState,
        storeId: brand.currentStore ? brand.currentStore.id : "",
      }));
    }
  }, [brand.currentStore]);

  useEffect(() => {
    if (authentication.user?.permissionsStore) {
      setUserPermissionFullList(
        getAllPermissions(authentication.user.permissionsStore[0].permissions)
      );
    }
  }, [authentication]);

  const pages = [
    {
      page: 0,
      component: (
        <FiraInvitationForm
          handleNext={handleNext}
          onChange={handleInputChange}
          invitation={invitation}
        />
      ),
    },
    {
      page: 1,
      component: (
        <FiraOperatorRole
          handleNext={handleNext}
          handlePrev={handlePrev}
          handleInvite={handleOperatorInvite}
          invitation={invitation}
          onSelect={handleRoleSelection}
          isLoading={isLoading}
        />
      ),
    },
    {
      page: 2,
      component: (
        <FiraPermissions
          handleCreate={handleSendInvitation}
          handlePrev={handlePrev}
          invitation={invitation}
          handleAddPermissionsByProduct={addFiraProduct}
          handleDeletePermissionsByProduct={removeFiraProduct}
          handleAddPermissionsByGroup={addPermissionsByGroup}
          handleDeletePermissionsByGroup={removePermissionsByGroup}
          handleDeleteEntireGroup={removeEntireGroup}
          isLoading={isLoading}
        />
      ),
    },
  ];

  return (
    <>
      <FiraNavBar
        titleSection={t("views.operators.title")}
        backButtonHandler={handleBackButton}
      />
      {/* Page Outlet */}
      <div>{pages[currentPage].component}</div>
    </>
  );
}

export default SendInvitationView;
