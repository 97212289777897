import React from "react";

function ClosedBox() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="27"
      fill="none"
      viewBox="0 0 26 27"
    >
      <path
        fill="#222"
        d="M3.25 9.95c0-.827 0-1.24.12-1.572a2 2 0 011.196-1.195c.331-.121.745-.121 1.573-.121H19.86c.828 0 1.242 0 1.573.12a2 2 0 011.195 1.196c.121.331.121.745.121 1.573 0 .414 0 .62-.06.786a1 1 0 01-.598.598c-.166.06-.373.06-.786.06H4.694c-.413 0-.62 0-.786-.06a1 1 0 01-.598-.598c-.06-.165-.06-.372-.06-.786z"
      ></path>
      <path
        fill="#222"
        fillRule="evenodd"
        d="M5.212 22.433c-.879-.878-.879-2.293-.879-5.121v-4.833h17.333v4.833c0 2.828 0 4.243-.878 5.121-.879.879-2.293.879-5.122.879h-5.333c-2.828 0-4.243 0-5.121-.879zm5.621-6.371a.75.75 0 000 1.5h4.333a.75.75 0 100-1.5h-4.333z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ClosedBox;
