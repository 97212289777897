// import { useEffect } from 'react';
import './ComercialBanner.scss'

// import {  playFiraModal, firaLiveWidget} from 'firalibrary'

const ComercialBanner = ({
  // firaKey,
  firstImage,
  // firsCollectiontId,
  // firstVideoId,
  // firstUrl,
  // env="DEV"
  })=>{

    const handleClick=()=>{
      // if(firstUrl){
      //   window.open(firstUrl + '?fira-collection-id='+firsCollectiontId)
      // }
      // if(firstVideoId){
      //   playFiraModal(firaKey,firstVideoId,env)
      // }
      return;
    }
  
    // useEffect(()=>{
    //   if(firstVideoId){
    //     firaLiveWidget(firaKey,env);
    //   }
    // },[])

  return (
  <div className="fira-comercial-banner-wrapper">
    <div className='fira-comercial-banner-container' onClick={handleClick}>
      <img src={firstImage} alt={'comercial-banner'} className='fira-comercial-banner-img'/>
    </div>
  </div>)
}

export default ComercialBanner;