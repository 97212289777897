import { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { sendActivationCodeService } from "../../services/authentication";
import { userActivationActionCreator } from "../../store/Register/RegisterActions";
import { RootState } from "../../utils/types";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import { ArrowRight } from "../FiraIcons";
import SegmentedInput from "../SegmentedInput/SegmentedInput";
import "./ActivateForm.scss";

const ActivateForm = () => {
  const { t } = useTranslation();
  const [activationCode, setActivationCode] = useState("");
  const dispatch = useDispatch();
  const registration = useSelector((state: RootState) => state.registration);

  const handleSendCode = (event: FormEvent) => {
    event.preventDefault();
    if (registration.registerResult && registration.registerResult.id) {
      dispatch(
        userActivationActionCreator(
          activationCode,
          registration.registerResult.id
        )
      );
    }
  };

  const handleResendCode = () => {
    if (typeof registration.email === "string") {
      sendActivationCodeService(registration.email);
    }
  };

  if (!registration.isRegistering) {
    return <Navigate to="/" replace />;
  }

  return (
    <form className="ActivateForm" onSubmit={handleSendCode}>
      <span className="InfoText body-12-low-text">
        {t("auth.activation.message")}
      </span>
      <SegmentedInput onChange={(value) => setActivationCode(value)} />
      <span className="ResendText">
        {t("auth.activation.resend_message")}{" "}
        <span onClick={handleResendCode} className="ResendLink">
          {t("auth.activation.resend")}
        </span>
      </span>
      <Button width="316px" height="46px" type="submit">
        <div className="ButtonInner" style={{ fontWeight: 700 }}>
          {t("auth.password_recovery.button")}
          <ArrowRight className="icon" />
        </div>
      </Button>
    </form>
  );
};

export default ActivateForm;
