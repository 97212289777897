import { t } from "i18next";
import { StoreType, UnitSystemType } from "../../types/Stores.d";
import { Currency } from "../../utils/types.d";

//Returns stores types array with translations and value as SimpleFormSelect
const StoreTypes = () => {
  const arr = [
    {
      name: t(`components.selects.storeTypes.${StoreType.TEXTILE}`),
      value: StoreType.TEXTILE,
    },
    {
      name: t(`components.selects.storeTypes.${StoreType.ELECTRONICS}`),
      value: StoreType.ELECTRONICS,
    },
    {
      name: t(`components.selects.storeTypes.${StoreType.COSMETICS}`),
      value: StoreType.COSMETICS,
    },
    {
      name: t(`components.selects.storeTypes.${StoreType.VARIOUS}`),
      value: StoreType.VARIOUS,
    },
  ];
  return arr;
};

//Returns currencies array with translations and value as SimpleFormSelect
const Currencies = () => {
  const arr = [
    {
      name: t(`components.selects.currencies.${Currency.usd}`),
      value: Currency.usd,
    },
    {
      name: t(`components.selects.currencies.${Currency.eur}`),
      value: Currency.eur,
    },
    {
      name: t(`components.selects.currencies.${Currency.pen}`),
      value: Currency.pen,
    },
    {
      name: t(`components.selects.currencies.${Currency.brl}`),
      value: Currency.brl,
    },
    {
      name: t(`components.selects.currencies.${Currency.mxn}`),
      value: Currency.mxn,
    },
    {
      name: t(`components.selects.currencies.${Currency.cop}`),
      value: Currency.cop,
    },
    {
      name: t(`components.selects.currencies.${Currency.clp}`),
      value: Currency.clp,
    },
    {
      name: t(`components.selects.currencies.${Currency.ars}`),
      value: Currency.ars,
    },
    {
      name: t(`components.selects.currencies.${Currency.gtq}`),
      value: Currency.gtq,
    },
    {
      name: t(`components.selects.currencies.${Currency.nio}`),
      value: Currency.nio,
    },
    {
      name: t(`components.selects.currencies.${Currency.crc}`),
      value: Currency.crc,
    },
    {
      name: t(`components.selects.currencies.${Currency.hnl}`),
      value: Currency.hnl,
    },
    {
      name: t(`components.selects.currencies.${Currency.svc}`),
      value: Currency.svc,
    },
  ];

  return arr;
};

//Returns unit systems array with translations and value as SimpleFormSelect
const UnitSystems = () => {
  const arr = [
    {
      name: t(`components.selects.unitSystems.${UnitSystemType.METRIC}`),
      value: UnitSystemType.METRIC,
    },
    {
      name: t(`components.selects.unitSystems.${UnitSystemType.IMPERIAL}`),
      value: UnitSystemType.IMPERIAL,
    },
  ];
  return arr;
};

//Returns weight units array with translations and value as SimpleFormSelect
const WeightUnits = () => {
  const arr = [
    {
      name: "KILOGRAMS",
      value: "Kg",
    },
    {
      name: "LIBRAS",
      value: "Lb",
    },
  ];
  return arr;
};

const timeZonesUTC = () => {
  const arr = [
    { name: "Línea Internacional de Cambio de Fecha", value: "-12:00" },
    { name: "Samoa Estándar, Niue", value: "-11:00" },
    { name: "Hawái-Aleutianas, Islas Cook, Tahití", value: "-10:00" },
    { name: "Alaska, Islas Gambier", value: "-9:00" },
    { name: "Pacífico (Hora Estándar del Pacífico)", value: "-08:00" },
    {
      name: "Montañas Rocosas (Hora Estándar de las Montañas Rocosas)",
      value: "-07:00",
    },
    { name: "Centro (Hora Estándar Central)", value: "-06:00" },
    {
      name: "Este (Hora Estándar del Este), Colombia, Perú",
      value: "-05:00",
    },
    {
      name: "Atlántico (Hora Estándar del Atlántico), Venezuela",
      value: "-04:00",
    },
    {
      name: "Terranova y Labrador (Hora Estándar de Terranova)",
      value: "-03:30",
    },
    { name: "Argentina, Brasil, Uruguay, Chile", value: "-03:00" },
    { name: "Georgia del Sur, Islas Sandwich del Sur", value: "-02:00" },
    { name: "Azores, Cabo Verde", value: "-01:00" },
    {
      name: "Tiempo Universal Coordinado (UTC), Londres, España (Canarias)",
      value: "+00:00",
    },
    {
      name: "Europa Central, África Occidental, España (Península y Baleares)",
      value: "+01:00",
    },
    { name: "Europa Oriental, África Central", value: "+02:00" },
    { name: "Moscú, Arabia Saudita", value: "+03:00" },
    { name: "Irán", value: "+03:30" },
    { name: "Azerbaiyán, Emiratos Árabes Unidos", value: "+04:00" },
    { name: "Afganistán", value: "+04:30" },
    { name: "Pakistán, Uzbekistán, India, Sri Lanka", value: "+05:00" },
    { name: "Nepal", value: "+05:45" },
    { name: "Kazajistán, Bangladesh, Islas Cocos, Myanmar", value: "+06:00" },
    { name: "Tailandia, Vietnam", value: "+07:00" },
    {
      name: "China, Singapur, Australia Occidental (Eucla)",
      value: "+08:00",
    },
    {
      name: "Japón, Corea del Sur, Australia Central (Adelaida)",
      value: "+09:00",
    },
    {
      name: "Australia Oriental (Sídney), Guam, Australia Central (Isla Lorde Howe)",
      value: "+10:00",
    },
    { name: "Islas Salomón, Vanuatu", value: "+11:00" },
    { name: "Islas Norfolk", value: "+11:30" },
    { name: "Nueva Zelanda, Fiyi", value: "+12:00" },
    { name: "Isla Chatham", value: "+12:45" },
    { name: "Tonga, Samoa", value: "+13:00" },
    {
      name: "Islas Kiritimati (Línea de Tiempo de las Islas Fénix)",
      value: "+14:00",
    },
  ];

  return arr;
};

export { StoreTypes, Currencies, WeightUnits, UnitSystems, timeZonesUTC };
