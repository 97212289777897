import { t } from "i18next";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { CloudUpload, Image } from "../FiraIcons";
import styles from "./ImageUploadInput.module.scss";
import { UncachedImage } from "react-uncached-image";
import SvgNoImageError from "../FiraIcons/NoImageError";

interface ImageUploadInputProps {
  description?: string;
  iconSize?: string;
  iconFontSize?: string;
  fileFormat?: string;
  fileSize?: string;
  preview?: string;
  imageResult?(imageFile: File): void;
}

const ImageUploadInput = ({
  description,
  iconSize = "24px",
  iconFontSize = "24px",
  fileFormat,
  fileSize,
  preview,
  imageResult,
}: ImageUploadInputProps) => {
  const [imageFile, setImageFile] = useState<File>();
  const [imagePreview, setImagePreview] = useState<string>(preview || "");
  const inputRef = useRef<HTMLInputElement>(null);
  const [newFile, setNewFile] = useState<boolean>(false);

  const onClickHandle = () => {
    inputRef.current?.click();
  };

  const onInputChangeHandle = (event: ChangeEvent<HTMLInputElement>) => {
    setNewFile(true);
    event.preventDefault();
    if (event.target.files) {
      if (
        event.target.files[0].type === "image/jpg" ||
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        setImageFile(event.target.files[0]);
        setImagePreview(URL.createObjectURL(event.target.files[0]));
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    if (imageFile && imageResult) {
      imageResult(imageFile);
    }
  }, [imageFile]);

  useEffect(() => {
    if (preview) setImagePreview(preview);
  }, [preview]);

  return (
    <>
      <div className={styles.ImageUploadWrapper} onClick={onClickHandle}>
        <input
          ref={inputRef}
          type="file"
          style={{ display: "none" }}
          onChange={onInputChangeHandle}
          accept="image/jpeg, image/jpg, image/png"
        />
        {!imagePreview ? (
          <div className={styles.UploadHere}>
            <div
              className={styles.UploadHere__icon}
              style={{ width: iconSize, height: iconSize }}
            >
              <Image style={{ fontSize: iconFontSize }} />
            </div>
            <div className={styles.loadtext}>
              <CloudUpload /> <span>{t("components.imageUpload.load")}</span>
            </div>
            <div className={styles.description}>
              <span className={styles.lightText}>{description}</span>
            </div>
            {fileFormat !== undefined && (
              <div className={styles.label}>
                <span>{t("components.imageUpload.fileFormat")}</span>
                <span className={styles.lightText}>{fileFormat}</span>
              </div>
            )}
            {fileSize !== undefined && (
              <div className={styles.label}>
                <span>{t("components.imageUpload.fileSize")}</span>
                <span className={styles.lightText}>{fileSize}</span>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.Preview}>
            {newFile ? (
              <img src={imagePreview} alt="Image" />
            ) : (
              <UncachedImage src={imagePreview} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ImageUploadInput;
