import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgThumbnailBannerSlider = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 197 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={197} height={90.753} rx={6} fill="#F7F8F9" />
    <rect
      x={177.632}
      y={41.503}
      width={7.194}
      height={7.194}
      rx={3.597}
      fill="#D5DDE0"
    />
    <rect
      x={14.388}
      y={48.697}
      width={7.194}
      height={7.194}
      rx={3.597}
      transform="rotate(-180 14.388 48.697)"
      fill="#D5DDE0"
    />
    <rect
      x={26.008}
      y={38.183}
      width={22.135}
      height={3.874}
      rx={1.937}
      fill="#D5DDE0"
    />
    <rect
      x={26.008}
      y={43.163}
      width={39.843}
      height={7.194}
      rx={3.597}
      fill="#D5DDE0"
    />
    <rect
      x={26.562}
      y={52.017}
      width={12.174}
      height={4.98}
      rx={2.49}
      fill="#D5DDE0"
    />
    <rect
      x={26.008}
      y={32.649}
      width={13.834}
      height={3.32}
      rx={1.66}
      fill="#D5DDE0"
    />
    <path
      d="M11.084 46.124a.188.188 0 0 1-.134.324.178.178 0 0 1-.131-.056l-.883-.877a.19.19 0 0 1 0-.268l.883-.877a.186.186 0 0 1 .268 0 .188.188 0 0 1-.003.268l-.745.743.745.743ZM180.697 44.637a.189.189 0 1 1 .265-.268l.883.877a.19.19 0 0 1 0 .268l-.883.877a.189.189 0 1 1-.265-.268l.746-.743-.746-.743Z"
      fill="#F7F8F9"
    />
  </svg>
);

export default SvgThumbnailBannerSlider;
