import React from "react";
import { CountryCode, getPhoneCodes } from "../services/utils";

interface UtilsState {
  countryCodes: CountryCode[];
  loadCountryCodes(): Promise<void>;
}

const UtilsContext = React.createContext<UtilsState | null>(null);

const UtilsProvider: React.FC = ({ children }) => {
  const [countryCodes, setCountryCodes] = React.useState<CountryCode[]>([]);

  const loadCountryCodes = async () => {
    try {
      const response = await getPhoneCodes();
      setCountryCodes(response);
    } catch (error) {
      setCountryCodes([]);
    }
  };
  return (
    <UtilsContext.Provider value={{ countryCodes, loadCountryCodes }}>
      {children}
    </UtilsContext.Provider>
  );
};

const useUtils = () => {
  const context = React.useContext(UtilsContext);

  if (!context) {
    throw new Error("You have to use the context inside UtilsProvider");
  }

  return context;
};

export { UtilsProvider, useUtils };
