const MAIN_CARROUSEL_CSS_DEV =
  "https://firalivedev.blob.core.windows.net/widgets/mainCarrouselEventDev.css";
const MAIN_CARROUSEL_JS_DEV =
  "https://firalivedev.blob.core.windows.net/widgets/mainCarrouselEventDev.js";
const MAIN_CARROUSEL_CSS_PREPROD =
  "https://firalivepro.blob.core.windows.net/widget-preprod/mainCarrouselWidget.css";
const MAIN_CARROUSEL_JS_PREPROD =
  "https://firalivepro.blob.core.windows.net/widget-preprod/mainCarrouselWidget.js";
const MAIN_CARROUSEL_CSS =
  "https://firalivepro.blob.core.windows.net/widget/mainCarrouselWidget.css";
const MAIN_CARROUSEL_JS =
  "https://firalivepro.blob.core.windows.net/widget/mainCarrouselWidget.js";

const GRID_EVENT_CSS_DEV =
  "https://firalivedev.blob.core.windows.net/widgets/gridEventWidgetDev.css";
const GRID_EVENT_JS_DEV =
  "https://firalivedev.blob.core.windows.net/widgets/gridEventWidgetDev.js";
const GRID_EVENT_CSS_PREPROD =
  "https://firalivepro.blob.core.windows.net/widget-preprod/gridEventWidget.css";
const GRID_EVENT_JS_PREPROD =
  "https://firalivepro.blob.core.windows.net/widget-preprod/gridEventWidget.js";
const GRID_EVENT_CSS =
  "https://firalivepro.blob.core.windows.net/widget/gridEventWidget.css";
const GRID_EVENT_JS =
  "https://firalivepro.blob.core.windows.net/widget/gridEventWidget.js";

const CARROUSEL_EVENT_CSS_DEV =
  "https://firalivedev.blob.core.windows.net/widgets/carrouselWidgetDev.css";
const CARROUSEL_EVENT_JS_DEV =
  "https://firalivedev.blob.core.windows.net/widgets/carrouselWidgetDev.js";
const CARROUSEL_EVENT_CSS_PREPROD =
  "https://firalivepro.blob.core.windows.net/widget-preprod/carrouselWidget.css";
const CARROUSEL_EVENT_JS_PREPROD =
  "https://firalivepro.blob.core.windows.net/widget-preprod/carrouselWidget.js";
const CARROUSEL_EVENT_CSS =
  "https://firalivepro.blob.core.windows.net/widget/carrouselEventWidget.css";
const CARROUSEL_EVENT_JS =
  "https://firalivepro.blob.core.windows.net/widget/carrouselEventWidget.js";

export const getUrlsMainCarrousel = () => {
  let array;
  process.env.NODE_ENV === "production"
    ? (array = [MAIN_CARROUSEL_CSS, MAIN_CARROUSEL_JS])
    : (array = [MAIN_CARROUSEL_CSS_DEV, MAIN_CARROUSEL_JS_DEV]);

  return array;
};

export const getUrlsGridEvent = () => {
  let array;
  process.env.NODE_ENV === "production"
    ? (array = [GRID_EVENT_CSS, GRID_EVENT_JS])
    : (array = [GRID_EVENT_CSS_DEV, GRID_EVENT_JS_DEV]);

  return array;
};
export const getUrlsCarrouselEvent = () => {
  let array;
  process.env.NODE_ENV === "production"
    ? (array = [CARROUSEL_EVENT_CSS, CARROUSEL_EVENT_JS])
    : (array = [CARROUSEL_EVENT_CSS_DEV, CARROUSEL_EVENT_JS_DEV]);

  return array;
};

const WIDGET_JS_URL =
  "https://firalivepro.blob.core.windows.net/fira-live-widget/***/index.js";
const WIDGET_CSS_URL =
  "https://firalivepro.blob.core.windows.net/fira-live-widget/***/index.css";
const WIDGET_JS_URL_PREPROD =
    "https://firalivepro.blob.core.windows.net/fira-live-widget-preprod/***/index.js";
const WIDGET_CSS_URL_PREPROD =
    "https://firalivepro.blob.core.windows.net/fira-live-widget-preprod/***/index.css";
const WIDGET_JS_URL_DEV =
    "https://firalivepro.blob.core.windows.net/fira-live-widget-preprod/***/index.js";
const WIDGET_CSS_URL_DEV =
    "https://firalivepro.blob.core.windows.net/fira-live-widget-preprod/***/index.css";

export const CARDS_COLLECTION_CARD_JS = WIDGET_JS_URL.replace(
  "***",
  "card-collections-card"
);

export const CARDS_COLLECTION_CARD_JS_DEV = WIDGET_JS_URL_DEV.replace(
    "***",
    "card-collections-card"
);
export const CARDS_COLLECTION_CARD_JS_PREPROD = WIDGET_JS_URL_PREPROD.replace(
    "***",
    "card-collections-card"
);

export const CARDS_COLLECTION_CARD_CSS = WIDGET_CSS_URL.replace(
  "***",
  "card-collections-card"
);

export const CARDS_COLLECTION_CARD_CSS_DEV = WIDGET_CSS_URL_DEV.replace(
    "***",
    "card-collections-card"
);
export const CARDS_COLLECTION_CARD_CSS_PREPROD = WIDGET_CSS_URL_PREPROD.replace(
    "***",
    "card-collections-card"
);

export const CARROUSEL_COLLECTIONS_CARROUSEL_JS = WIDGET_JS_URL.replace(
  "***",
  "carrousel-collections-carrousel"
);

export const CARROUSEL_COLLECTIONS_CARROUSEL_JS_DEV = WIDGET_JS_URL_DEV.replace(
    "***",
    "carrousel-collections-carrousel"
);

export const CARROUSEL_COLLECTIONS_CARROUSEL_JS_PREPROD = WIDGET_JS_URL_PREPROD.replace(
    "***",
    "carrousel-collections-carrousel"
);

export const CARROUSEL_COLLECTIONS_CARROUSEL_CSS = WIDGET_CSS_URL.replace(
  "***",
  "carrousel-collections-carrousel"
);

export const CARROUSEL_COLLECTIONS_CARROUSEL_CSS_DEV = WIDGET_CSS_URL_DEV.replace(
    "***",
    "carrousel-collections-carrousel"
);

export const CARROUSEL_COLLECTIONS_CARROUSEL_CSS_PREPROD = WIDGET_CSS_URL_PREPROD.replace(
    "***",
    "carrousel-collections-carrousel"
);

export const SCHEDULE_COMING_SOON_JS = WIDGET_JS_URL.replace(
  "***",
  "schedule-coming-soon"
);

export const SCHEDULE_COMING_SOON_JS_DEV = WIDGET_JS_URL_DEV.replace(
    "***",
    "schedule-coming-soon"
);

export const SCHEDULE_COMING_SOON_JS_PREPROD = WIDGET_JS_URL_PREPROD.replace(
    "***",
    "schedule-coming-soon"
);

export const SCHEDULE_COMING_SOON_CSS = WIDGET_CSS_URL.replace(
  "***",
  "schedule-coming-soon"
);

export const SCHEDULE_COMING_SOON_CSS_DEV = WIDGET_CSS_URL_DEV.replace(
    "***",
    "schedule-coming-soon"
);

export const SCHEDULE_COMING_SOON_CSS_PREPROD = WIDGET_CSS_URL_PREPROD.replace(
    "***",
    "schedule-coming-soon"
);

export const GRID_VOD_GALLERY_JS = WIDGET_JS_URL.replace(
  "***",
  "grid-vod-gallery-widget"
);

export const GRID_VOD_GALLERY_JS_DEV = WIDGET_JS_URL_DEV.replace(
    "***",
    "grid-vod-gallery-widget"
);

export const GRID_VOD_GALLERY_JS_PREPROD = WIDGET_JS_URL_PREPROD.replace(
    "***",
    "grid-vod-gallery-widget"
);

export const GRID_VOD_GALLERY_CSS = WIDGET_CSS_URL.replace(
  "***",
  "grid-vod-gallery-widget"
);

export const GRID_VOD_GALLERY_CSS_DEV = WIDGET_CSS_URL_DEV.replace(
    "***",
    "grid-vod-gallery-widget"
);

export const GRID_VOD_GALLERY_CSS_PREPROD = WIDGET_CSS_URL_PREPROD.replace(
    "***",
    "grid-vod-gallery-widget"
);

export const BANNER_COMMERCIAL_JS = WIDGET_JS_URL.replace(
  "***",
  "banners-comercial-banner-widget"
);

export const BANNER_COMMERCIAL_JS_DEV = WIDGET_JS_URL_DEV.replace(
    "***",
    "banners-comercial-banner-widget"
);

export const BANNER_COMMERCIAL_JS_PREPROD = WIDGET_JS_URL_PREPROD.replace(
    "***",
    "banners-comercial-banner-widget"
);

export const BANNER_COMMERCIAL_CSS = WIDGET_CSS_URL.replace(
  "***",
  "banners-comercial-banner-widget"
);

export const BANNER_COMMERCIAL_CSS_DEV = WIDGET_CSS_URL_DEV.replace(
    "***",
    "banners-comercial-banner-widget"
);

export const BANNER_COMMERCIAL_CSS_PREPROD = WIDGET_CSS_URL_PREPROD.replace(
    "***",
    "banners-comercial-banner-widget"
);

export const BANNER_SLIDER_JS = WIDGET_JS_URL.replace(
  "***",
  "banners-slider-banner-widget"
);

export const BANNER_SLIDER_JS_DEV = WIDGET_JS_URL_DEV.replace(
    "***",
    "banners-slider-banner-widget"
);

export const BANNER_SLIDER_JS_PREPROD = WIDGET_JS_URL_PREPROD.replace(
    "***",
    "banners-slider-banner-widget"
);

export const BANNER_SLIDER_CSS = WIDGET_CSS_URL.replace(
  "***",
  "banners-slider-banner-widget"
);

export const BANNER_SLIDER_CSS_DEV = WIDGET_CSS_URL_DEV.replace(
    "***",
    "banners-slider-banner-widget"
);

export const BANNER_SLIDER_CSS_PREPROD = WIDGET_CSS_URL_PREPROD.replace(
    "***",
    "banners-slider-banner-widget"
);

export const COLLECTIONS_JS = WIDGET_JS_URL.replace(
  "***",
  "collections-collections-widget"
);

export const COLLECTIONS_JS_DEV = WIDGET_JS_URL_DEV.replace(
    "***",
    "collections-collections-widget"
);

export const COLLECTIONS_JS_PREPROD = WIDGET_JS_URL_PREPROD.replace(
    "***",
    "collections-collections-widget"
);

export const COLLECTIONS_CSS = WIDGET_CSS_URL.replace(
  "***",
  "collections-collections-widget"
);

export const COLLECTIONS_CSS_DEV = WIDGET_CSS_URL_DEV.replace(
    "***",
    "collections-collections-widget"
);

export const COLLECTIONS_CSS_PREPROD = WIDGET_CSS_URL_PREPROD.replace(
    "***",
    "collections-collections-widget"
);
