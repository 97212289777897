import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgMosaic = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={11.429} height={7.143} rx={1} fill="#000" />
    <rect x={12.857} width={7.143} height={7.143} rx={1} fill="#000" />
    <rect y={8.571} width={7.143} height={8.571} rx={1} fill="#000" />
    <rect
      x={8.571}
      y={8.571}
      width={11.429}
      height={8.571}
      rx={1}
      fill="#000"
    />
  </svg>
);

export default SvgMosaic;
