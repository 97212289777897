import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import { startBroadcastService } from "../../../services/events";
import { startEventActionCreator } from "../../../store/Event/EventActions";
import { RootState } from "../../../utils/types";
import Button, { ButtonStyle } from "../../Button/Button";
import styles from "./StartBroadcast.module.scss";
//import {useContext} from "react";
//import {GeneralContext} from "../../../context/GeneralContext";

interface StartBroadcastProps {
  isOpen: boolean;
  onClose(): void;
}

const StartBroadcast = ({ isOpen = false, onClose }: StartBroadcastProps) => {
  const { event } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //const {setEventFinished} = useContext<any>(GeneralContext);

  const handleCancel = () => {
    onClose();
  };

  const handleInit = () => {
    if (event.currentEvent) {
      //setEventFinished(false)
      dispatch(startEventActionCreator(event.currentEvent.id));
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalLayout>
      <div className={styles.startModalWrapper}>
        <h5>{t("modals.eventDetail.start.title")}</h5>
        <p className={styles.messageText}>
          <span>{t("modals.eventDetail.start.message_1")}</span>{" "}
          {t("modals.eventDetail.start.message_2")}
        </p>
        <div className={styles.buttonGroup}>
          <Button
            width="132px"
            height="42px"
            buttonStyle={ButtonStyle.transparent}
            clickHandler={handleCancel}
            isDisabled={event.isLoading}
          >
            <h6>{t("modals.eventDetail.start.no")}</h6>
          </Button>
          <Button
            width="132px"
            height="42px"
            clickHandler={handleInit}
            isDisabled={event.isLoading}
          >
            <h6>{t("modals.eventDetail.start.yes")}</h6>
          </Button>
        </div>
      </div>
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};

export default StartBroadcast;
