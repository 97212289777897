import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgThumbnailGaleriaVod = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 197 152"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={197} height={152} rx={6} fill="#F7F8F9" />
    <rect
      x={14.74}
      y={22.836}
      width={9.193}
      height={2.059}
      rx={1.029}
      fill="#D5DDE0"
    />
    <rect
      x={39.711}
      y={27.847}
      width={1.079}
      height={1.638}
      rx={0.54}
      fill="#D5DDE0"
    />
    <rect
      x={39.711}
      y={45.388}
      width={1.079}
      height={1.638}
      rx={0.54}
      fill="#D5DDE0"
    />
    <rect
      x={39.711}
      y={36.618}
      width={1.079}
      height={1.638}
      rx={0.54}
      fill="#D5DDE0"
    />
    <rect
      x={39.711}
      y={54.159}
      width={1.079}
      height={1.638}
      rx={0.54}
      fill="#D5DDE0"
    />
    <rect
      x={20.779}
      y={28.18}
      width={9.084}
      height={2.037}
      rx={1.018}
      fill="#D5DDE0"
    />
    <rect
      x={20.779}
      y={45.721}
      width={9.084}
      height={2.037}
      rx={1.018}
      fill="#D5DDE0"
    />
    <rect
      x={20.779}
      y={36.951}
      width={9.084}
      height={2.037}
      rx={1.018}
      fill="#D5DDE0"
    />
    <rect
      x={20.779}
      y={54.492}
      width={9.084}
      height={2.037}
      rx={1.018}
      fill="#D5DDE0"
    />
    <rect
      x={39.713}
      y={32.233}
      width={1.005}
      height={1.616}
      rx={0.502}
      fill="#D5DDE0"
    />
    <rect
      x={39.713}
      y={49.773}
      width={1.005}
      height={1.616}
      rx={0.502}
      fill="#D5DDE0"
    />
    <rect
      x={39.713}
      y={41.003}
      width={1.005}
      height={1.616}
      rx={0.502}
      fill="#D5DDE0"
    />
    <rect
      x={39.713}
      y={58.545}
      width={1.005}
      height={1.616}
      rx={0.502}
      fill="#D5DDE0"
    />
    <rect
      x={20.779}
      y={32.565}
      width={9.084}
      height={2.037}
      rx={1.018}
      fill="#D5DDE0"
    />
    <rect
      x={20.779}
      y={50.107}
      width={9.084}
      height={2.037}
      rx={1.018}
      fill="#D5DDE0"
    />
    <rect
      x={20.779}
      y={41.336}
      width={9.084}
      height={2.037}
      rx={1.018}
      fill="#D5DDE0"
    />
    <rect
      x={20.779}
      y={58.877}
      width={9.084}
      height={2.037}
      rx={1.018}
      fill="#D5DDE0"
    />
    <rect
      x={32.49}
      y={62.142}
      width={8.19}
      height={1.747}
      rx={0.874}
      fill="#D5DDE0"
    />
    <rect
      x={15.211}
      y={12.911}
      width={8.651}
      height={1.641}
      rx={0.82}
      fill="#D5DDE0"
    />
    <rect
      x={159.156}
      y={7.537}
      width={27.644}
      height={4.934}
      rx={2.467}
      fill="#D5DDE0"
    />
    <rect
      x={57.818}
      y={112.648}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={75.889}
      y={124.819}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={77.57}
      y={126.223}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
    <rect
      x={57.818}
      y={49.061}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={75.889}
      y={61.23}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={77.57}
      y={62.634}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
    <rect
      x={101.926}
      y={17.267}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={119.994}
      y={29.439}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={121.676}
      y={30.843}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
    <rect
      x={101.926}
      y={80.855}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={119.994}
      y={93.024}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={121.676}
      y={94.428}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
    <rect
      x={146.035}
      y={17.267}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={164.105}
      y={29.439}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={165.787}
      y={30.843}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
    <rect
      x={146.035}
      y={80.855}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={164.105}
      y={93.024}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={165.787}
      y={94.428}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
    <rect
      x={57.818}
      y={17.267}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={75.889}
      y={29.438}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={77.57}
      y={30.842}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
    <rect
      x={57.818}
      y={80.855}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={75.889}
      y={93.024}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={77.57}
      y={94.428}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
    <rect
      x={101.926}
      y={49.061}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={119.994}
      y={61.23}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={121.676}
      y={62.634}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
    <rect
      x={101.926}
      y={112.648}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={119.994}
      y={124.819}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={121.676}
      y={126.224}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
    <rect
      x={146.035}
      y={49.061}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={164.105}
      y={61.23}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={165.787}
      y={62.634}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
    <rect
      x={146.035}
      y={112.648}
      width={40.686}
      height={28.891}
      rx={6}
      fill="#D5DDE0"
    />
    <rect
      x={164.105}
      y={124.819}
      width={4.487}
      height={4.495}
      rx={2.244}
      fill="#ABAFB1"
    />
    <rect
      x={165.787}
      y={126.224}
      width={1.262}
      height={1.686}
      rx={0.631}
      fill="#fff"
    />
  </svg>
);

export default SvgThumbnailGaleriaVod;
