import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import eventError from "../../../assets/svg/eventError.svg";
import styles from "./EventErrorMessage.module.scss";
import Button from "../../Button/Button";

interface EventErrorMessageProps {
  onClose(): void;
  isOpen: boolean;
  goToCalendar(): void;
}

const EventErrorMessage = ({
  isOpen,
  onClose,
  goToCalendar,
}: EventErrorMessageProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const accept = () => {
    goToCalendar();
  };

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalLayout onClickHandle={handleClose}>
      <div className={styles.EventErrorWrapper}>
        <img src={eventError} alt="ERROR" className={styles.Image} />
        <h4 className={`${styles.h4} ${styles.Title}`}>
          {t("event_creation.fail")}
        </h4>
        <p className={`${styles["body-14-text"]} ${styles.Message}`}>
          {t("event_creation.fail_message")}
        </p>
        <Button width="176px" height="40px" clickHandler={accept}>
          <span className={styles.InnerButton}>
            {t("event_creation.fail_btn")}
          </span>
        </Button>
      </div>
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};

export default EventErrorMessage;
