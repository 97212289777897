import React from "react";
import ReactDOM from "react-dom";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import Button, { ButtonStyle } from "../../Button/Button";
import styles from "./OptionModal.module.scss";

interface OptionModalProps {
  isOpen: boolean;
  elementName?: string;
  title: string;
  action_description: string;
  onClickOption(option: boolean): void;
}

const OptionModal = ({
  isOpen = false,
  onClickOption,
  elementName,
  title,
  action_description,
}: OptionModalProps) => {
  const handleCancel = () => {
    onClickOption(false);
  };

  const handleDelete = () => {
    onClickOption(true);
  };

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalLayout>
      <div className={styles.optionModalWrapper}>
        <h5>{title}</h5>
        <p className="lead-text">
          {action_description.replace("{element}", `${elementName}`)}
        </p>
        <div className={styles.buttonGroup}>
          <Button width="132px" height="42px" clickHandler={handleDelete}>
            <h6>si</h6>
          </Button>
          <Button
            width="132px"
            height="42px"
            buttonStyle={ButtonStyle.transparent}
            clickHandler={handleCancel}
          >
            <h6>no</h6>
          </Button>
        </div>
      </div>
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};

export default OptionModal;
