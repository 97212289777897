import React from "react";

import styles from "./LandingModulesBar.module.scss";
import LandingPage from "../Modules/LandingPage/LandingPage";
import LandingWidgets from "../Modules/LandingWidgets/LandingWidgets";
import LandingTypography from "../Modules/LandingTypography/LandingTypography";
import LandingCatalog from "../Modules/LandingCatalog/LandingCatalog";
import {
  modulesType,
  useLandingConfig,
} from "../../../providers/LandingConfigProvider";

const LandingModulesBar = () => {
  const { moduleInView, showModuleBar } = useLandingConfig();
  return (
    <>
      <div
        style={!showModuleBar ? { padding: "0" } : {}}
        className={`${styles.LandingModulesBarWrapper}`}
      >
        {showModuleBar &&
          (() => {
            switch (moduleInView) {
              case modulesType.PAGE:
                return (
                  <div className={styles.LandingScrollView}>
                    <LandingPage />
                  </div>
                );
              case modulesType.TYPOGRAPHY:
                return (
                  <div className={styles.LandingScrollView}>
                    <LandingTypography />
                  </div>
                );
              case modulesType.WIDGETS:
                return (
                  <div className={styles.LandingScrollView}>
                    <LandingWidgets />
                  </div>
                );
              case modulesType.CATALOG:
                return (
                  <div className={styles.LandingScrollView}>
                    <LandingCatalog />
                  </div>
                );
              default:
                return null;
            }
          })()}
      </div>
    </>
  );
};

export default LandingModulesBar;
