import React from "react";
import { getGlobalLandingSettings } from "../services/landing";
import { useSelector } from "react-redux";
import { RootState } from "../store/Store";
import { FiraLanding } from "../types/Landing";

export interface GlobalLandingSettings {
  isActive: boolean;
  blockWidgetsAndCart: boolean;
  stores: string[];
}
interface SettingsLandingContextProps {
  initialState: GlobalLandingSettings;
  state: GlobalLandingSettings;
  setGlobalConfig: React.Dispatch<React.SetStateAction<FiraLanding | null>>;
  setState: React.Dispatch<React.SetStateAction<GlobalLandingSettings>>;
  setInitialState: React.Dispatch<React.SetStateAction<GlobalLandingSettings>>;
  loadSettings(): void;
  globalLandingConfig: FiraLanding | null;
}
const SettingsLandingContext =
  React.createContext<SettingsLandingContextProps | null>(null);

export const SettingsLandingProvider: React.FC = ({ children }) => {
  const { authentication } = useSelector((state: RootState) => state);
  const [initialState, setInitialState] = React.useState<GlobalLandingSettings>(
    {
      isActive: false,
      blockWidgetsAndCart: false,
      stores: [],
    }
  );
  const [state, setState] = React.useState<GlobalLandingSettings>(initialState);
  const [config, setConfig] = React.useState<FiraLanding | null>(null);

  const loadSettings = async () => {
    if (authentication.user && authentication.user.businessClientId) {
      const response = await getGlobalLandingSettings(
        authentication.user.businessClientId
      );
      const {
        useOwnerDefaultLandingConfig,
        parametersSetted,
        defaultLandingConfig,
        landingBusinessTemplate,
      } = response;
      setState({
        isActive: useOwnerDefaultLandingConfig || initialState.isActive,
        blockWidgetsAndCart:
          parametersSetted || initialState.blockWidgetsAndCart,
        stores: defaultLandingConfig || initialState.stores,
      });
      setInitialState({
        isActive: useOwnerDefaultLandingConfig || initialState.isActive,
        blockWidgetsAndCart:
          parametersSetted || initialState.blockWidgetsAndCart,
        stores: defaultLandingConfig || initialState.stores,
      });
      setConfig(landingBusinessTemplate);
    }
  };

  React.useEffect(() => {
    loadSettings();
  }, []);

  return (
    <SettingsLandingContext.Provider
      value={{
        initialState,
        state,
        globalLandingConfig: config,
        setGlobalConfig: setConfig,
        setState,
        setInitialState,
        loadSettings,
      }}
    >
      {children}
    </SettingsLandingContext.Provider>
  );
};

export const useSettingsLanding = () => {
  const context = React.useContext(SettingsLandingContext);

  if (context === null) {
    throw new Error(
      "You hace to use useSettingsLanding hook inside SettingsLandingProvider"
    );
  }

  return context;
};
