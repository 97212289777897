import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./store/Store";
import interceptorSetup from "./services/interceptors";
import "./translations/i18n";
import { AlertProvider } from "./providers/AlertProvider";
import { ProductVariationsProvider } from "./store/Products/ProductVariationsContext";
//import { GeneralProvider } from "./context/GeneralProvider";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider>
        {/*<GeneralProvider>*/}

        <BrowserRouter>
          <App />
        </BrowserRouter>

        {/*</GeneralProvider>*/}
      </AlertProvider>
    </Provider>
    ,
  </React.StrictMode>,
  document.getElementById("root")
);

// Setup of interceptor for private calls in the API
interceptorSetup(store);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
