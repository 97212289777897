import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSimpleCheck = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.766 8.16a1.06 1.06 0 0 1-.777-.316L.491 5.146a.746.746 0 0 1-.166-.28.655.655 0 0 1-.023-.31.705.705 0 0 1 .125-.293.875.875 0 0 1 .252-.232c.102-.063.219-.11.343-.136a1.185 1.185 0 0 1 .739.084c.11.053.207.123.284.207L3.69 5.958 7.823.535A.993.993 0 0 1 8.431.18c.251-.048.516-.012.736.1.456.232.597.723.312 1.097L4.594 7.784a.912.912 0 0 1-.335.267c-.136.065-.29.102-.448.108l-.045.001Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSimpleCheck;
