import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgHeader = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 16 16"
    version="1.1"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M15 0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-2 5.1c0 .4-.3.7-.7.7s-.7-.3-.7-.7c0-.4-.3-.7-.7-.7H8.7v7.1h.7c.4 0 .7.3.7.7s-.3.8-.7.8H6.6c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h.7V4.4H5.1c-.4 0-.7.3-.7.7 0 .4-.3.7-.7.7S3 5.5 3 5.1V3.7c0-.4.3-.7.7-.7h8.5c.5 0 .8.3.8.7v1.4z"></path>
  </svg>
);

export default SvgHeader;
