import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  FiraConfigurationOptions,
  RootState,
  WidgetType,
} from "../../utils/types.d";
import Button from "../Button/Button";
import { CheckCircleFill, CopyParperv2 } from "../FiraIcons";
import styles from "./WidgetCopyButton.module.scss";
import {
  BANNER_COMMERCIAL_CSS, BANNER_COMMERCIAL_CSS_DEV, BANNER_COMMERCIAL_CSS_PREPROD,
  BANNER_COMMERCIAL_JS, BANNER_COMMERCIAL_JS_DEV, BANNER_COMMERCIAL_JS_PREPROD,
  BANNER_SLIDER_CSS, BANNER_SLIDER_CSS_DEV, BANNER_SLIDER_CSS_PREPROD,
  BANNER_SLIDER_JS, BANNER_SLIDER_JS_DEV, BANNER_SLIDER_JS_PREPROD,
  CARDS_COLLECTION_CARD_CSS,
  CARDS_COLLECTION_CARD_CSS_DEV,
  CARDS_COLLECTION_CARD_CSS_PREPROD,
  CARDS_COLLECTION_CARD_JS,
  CARDS_COLLECTION_CARD_JS_DEV,
  CARDS_COLLECTION_CARD_JS_PREPROD,
  CARROUSEL_COLLECTIONS_CARROUSEL_CSS,
  CARROUSEL_COLLECTIONS_CARROUSEL_CSS_DEV,
  CARROUSEL_COLLECTIONS_CARROUSEL_CSS_PREPROD,
  CARROUSEL_COLLECTIONS_CARROUSEL_JS,
  CARROUSEL_COLLECTIONS_CARROUSEL_JS_DEV,
  CARROUSEL_COLLECTIONS_CARROUSEL_JS_PREPROD,
  COLLECTIONS_CSS, COLLECTIONS_CSS_DEV, COLLECTIONS_CSS_PREPROD,
  COLLECTIONS_JS, COLLECTIONS_JS_DEV, COLLECTIONS_JS_PREPROD,
  GRID_VOD_GALLERY_CSS, GRID_VOD_GALLERY_CSS_DEV, GRID_VOD_GALLERY_CSS_PREPROD,
  GRID_VOD_GALLERY_JS, GRID_VOD_GALLERY_JS_DEV, GRID_VOD_GALLERY_JS_PREPROD,
  SCHEDULE_COMING_SOON_CSS,
  SCHEDULE_COMING_SOON_CSS_DEV,
  SCHEDULE_COMING_SOON_CSS_PREPROD,
  SCHEDULE_COMING_SOON_JS,
  SCHEDULE_COMING_SOON_JS_DEV,
  SCHEDULE_COMING_SOON_JS_PREPROD,
} from "../../utils/constantsFiraTv";

let copyTimerID: NodeJS.Timeout;

interface CopyWidgetButtonProps {
  widgetConfig?: FiraConfigurationOptions;
  widetType?: WidgetType;
}

const getWidgetEnvironment = (
  currentEnvironment: string
): "PRE_PROD" | "DEV" | "PRO" => {
  switch (currentEnvironment) {
    case "Preproduction":
      return "PRE_PROD";
    case "Development":
      return "DEV";
    case "Production":
      return "PRO";
    default:
      return "PRO";
  }
};

const WidgetCopyButton = ({
  widgetConfig,
  widetType,
}: CopyWidgetButtonProps) => {
  const { brand } = useSelector((state: RootState) => state);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [codeToCopy, setCodeToCopy] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>(true);

  const currentEnvironment =
    process.env.REACT_APP_CURRENT_ENVIRONMENT || "Production";
  //COPY CODES
  const mainCarrouselWidget = `
    <div class="fira_main_carrousel_widget" 
      fira-key="${brand.currentStore?.id}"
      fira-primary-text-color="${widgetConfig?.primaryTextColor}" 
      fira-display-color="${widgetConfig?.displayColor}" 
      fira-button-text-color="${widgetConfig?.buttonTextColor}" 
      fira-primary-color="${widgetConfig?.primaryColor}" 
      fira-background-color="${widgetConfig?.backgroundColor}"
      fira-env="${getWidgetEnvironment(currentEnvironment)}"
      ></div>
      
    <link href="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? SCHEDULE_COMING_SOON_CSS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? SCHEDULE_COMING_SOON_CSS_PREPROD : SCHEDULE_COMING_SOON_CSS_DEV)}" rel="stylesheet"/>
    <script src="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? SCHEDULE_COMING_SOON_JS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? SCHEDULE_COMING_SOON_JS_PREPROD : SCHEDULE_COMING_SOON_JS_DEV)}"></script>`;

  const gridEventsWidget = `
    <div class="fira_grid_events_widget" 
      fira-key="${brand.currentStore?.id}"  
      fira-primary-text-color="${widgetConfig?.primaryTextColor}"
      fira-secondary-text-color="${widgetConfig?.secondaryTextColor}"
      fira-button-text-color="${widgetConfig?.buttonTextColor}"
      fira-primary-color="${widgetConfig?.primaryColor}"
      fira-card-color="${widgetConfig?.cardColor}"
      fira-background-color="${widgetConfig?.backgroundColor}"
      fira-title="${widgetConfig?.title}"
      fira-events-mode="${widgetConfig?.type}"
      fira-env="${getWidgetEnvironment(currentEnvironment)}"
      fira-collection-id="${widgetConfig?.collectionId}"
      ></div>
    <link href="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? CARDS_COLLECTION_CARD_CSS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? CARDS_COLLECTION_CARD_CSS_PREPROD : CARDS_COLLECTION_CARD_CSS_DEV)}" rel="stylesheet"/>
    <script src="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? CARDS_COLLECTION_CARD_JS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? CARDS_COLLECTION_CARD_JS_PREPROD : CARDS_COLLECTION_CARD_JS_DEV)}"></script>`;

  const nextEventsWidget = `<div class="fira_events_carrousel_widget" 
    fira-key="${brand.currentStore?.id}"  
    fira-primary-text-color="${widgetConfig?.primaryTextColor}"
    fira-diplay-color="${widgetConfig?.displayColor}"
    fira-primary-color="${widgetConfig?.primaryColor}"
    fira-background-color="${widgetConfig?.backgroundColor}"
    fira-title="${widgetConfig?.title}" 
    fira-events-mode="${widgetConfig?.type}"
    fira-env="${getWidgetEnvironment(currentEnvironment)}"
    fira-collection-id="${widgetConfig?.collectionId}"
    ></div>
  <link href="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? CARROUSEL_COLLECTIONS_CARROUSEL_CSS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? CARROUSEL_COLLECTIONS_CARROUSEL_CSS_PREPROD : CARROUSEL_COLLECTIONS_CARROUSEL_CSS_DEV)}" rel="stylesheet"/>
  <script src="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? CARROUSEL_COLLECTIONS_CARROUSEL_JS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? CARROUSEL_COLLECTIONS_CARROUSEL_JS_PREPROD : CARROUSEL_COLLECTIONS_CARROUSEL_JS_DEV)}"></script>`;

  const vodGalleryWidget = `
  <div class="fira_vod_gallery_widget"
  fira-key="${brand.currentStore?.id}"
  fira-first-collection-id="${widgetConfig?.collectionId || ""}"
  fira-video-sort="${widgetConfig?.videoSort}"
  fira-video-filter="${widgetConfig?.videoFilter}"
  fira-primary-text-color="${widgetConfig?.primaryTextColor}"
  fira-secondary-text-color="${widgetConfig?.secondaryTextColor}"
  fira-tertiary-text-color="${widgetConfig?.tertiaryTextColor}"
  fira-primary-color="${widgetConfig?.primaryColor}"
  fira-secondary-color="${widgetConfig?.secondaryColor}"
  fira-tertiary-color="${widgetConfig?.tertiaryColor}"
  fira-quaternary-color='${widgetConfig?.quinaryColor}'
  fira-quinary-color="${widgetConfig?.quaternaryColor}"
  fira-background-color="${widgetConfig?.backgroundColor}"
  fira-env="${getWidgetEnvironment(currentEnvironment)}"></div>
  <link href="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? GRID_VOD_GALLERY_CSS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? GRID_VOD_GALLERY_CSS_PREPROD : GRID_VOD_GALLERY_CSS_DEV)}" rel="stylesheet"/>
  <script src="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? GRID_VOD_GALLERY_JS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? GRID_VOD_GALLERY_JS_PREPROD : GRID_VOD_GALLERY_JS_DEV)}"></script>
  `;

  const bannerCommercialWidget = `
  <div class="fira_comercial_banner_widget"
    fira-key="${brand.currentStore?.id}"
    fira-first-image="${widgetConfig?.firstImage || ""}"
    fira-first-collection-id="${widgetConfig?.collectionId || ""}"
    fira-first-video-id="${widgetConfig?.eventId || ""}"
    fira-first-url=""
    fira-env="${getWidgetEnvironment(currentEnvironment)}"
    ></div>
    <link href="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? BANNER_COMMERCIAL_CSS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? BANNER_COMMERCIAL_CSS_PREPROD : BANNER_COMMERCIAL_CSS_DEV)}" rel="stylesheet"/>
    <script src="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? BANNER_COMMERCIAL_JS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? BANNER_COMMERCIAL_JS_PREPROD : BANNER_COMMERCIAL_JS_DEV)}"></script>
  `;

  const bannerSliderWidget = `
  <div class="fira_slider_banner_widget"
    fira-key="${brand.currentStore?.id}"
    fira-first-image="${widgetConfig?.firstImage || ""}"
    fira-second-image="${widgetConfig?.secondImage || ""}"
    fira-third-image="${widgetConfig?.thirdImage || ""}"
    fira-first-url="${widgetConfig?.firstUrl || ""}"
    fira-second-url="${widgetConfig?.secondUrl || ""}"
     fira-third-url="${widgetConfig?.thirdUrl || ""}"
    fira-first-video-id="${widgetConfig?.firstVideoId || ""}"
    fira-second-video-id="${widgetConfig?.secondVideoId || ""}"
    fira-third-video-id="${widgetConfig?.thirdVideoId || ""}"
    fira-first-collection-id="${widgetConfig?.firstCollectionId || ""}"
    fira-second-collection-id="${widgetConfig?.secondCollectionId || ""}"
    fira-third-collection-id="${widgetConfig?.thirdCollectionId || ""}"
    fira-primary-text-color="${widgetConfig?.primaryTextColor || ""}"
    fira-secondary-text-color="${widgetConfig?.secondaryTextColor || ""}"
    fira-tertiary-text-color="${widgetConfig?.tertiaryTextColor || ""}"
    fira-primary-color="${widgetConfig?.primaryColor || ""}"
    fira-secondary-color="${widgetConfig?.secondaryColor || ""}"
    fira-tertiary-color="${widgetConfig?.tertiaryColor || ""}"
    fira-quaternary-color='${widgetConfig?.quaternaryColor || ""}'
    fira-env="${getWidgetEnvironment(currentEnvironment)}"></div>
    <link href="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? BANNER_SLIDER_CSS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? BANNER_SLIDER_CSS_PREPROD : BANNER_SLIDER_CSS_DEV)}" rel="stylesheet"/>
    <script src="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? BANNER_SLIDER_JS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? BANNER_SLIDER_JS_PREPROD : BANNER_SLIDER_JS_DEV)}"></script>
  `;

  const categoriesWidget = `
  <div class="fira_collections_widget"
    fira-key="${brand.currentStore?.id}"
    fira-title="${widgetConfig?.title || ""}"
    fira-first-collection-id="${widgetConfig?.firstCollectionId || ""}"
    fira-second-collection-id="${widgetConfig?.secondCollectionId || ""}"
    fira-third-collection-id="${widgetConfig?.thirdCollectionId || ""}"
    fira-fourth-collection-id="${widgetConfig?.fourthCollectionId || ""}"
    fira-fifth-collection-id="${widgetConfig?.fifthCollectionId || ""}"
    fira-sixth-collection-id="${widgetConfig?.sixthCollectionId || ""}"
    fira-seventh-collection-id="${widgetConfig?.seventhCollectionId || ""}"
    fira-eighth-collection-id="${widgetConfig?.eightCollectionId || ""}"
    fira-first-url="${widgetConfig?.firstUrl || ""}"
    fira-primary-text-color="${widgetConfig?.primaryTextColor || ""}"
    fira-secondary-text-color="${widgetConfig?.secondaryTextColor || ""}"
    fira-primary-color="${widgetConfig?.primaryColor || ""}"
    fira-background-color='${widgetConfig?.backgroundColor || ""}'
    fira-env="${getWidgetEnvironment(currentEnvironment)}"></div>
    <link href="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? COLLECTIONS_CSS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? COLLECTIONS_CSS_PREPROD : COLLECTIONS_CSS_DEV)}" rel="stylesheet"/>
    <script src="${getWidgetEnvironment(currentEnvironment) === 'PRO' ? COLLECTIONS_JS : (getWidgetEnvironment(currentEnvironment) === 'PRE_PROD' ? COLLECTIONS_JS_PREPROD : COLLECTIONS_JS_DEV)}"></script>
  `;

  const availableConditionalHandler = () => {
    // if (
    //   widetType === WidgetType.vodGallery &&
    //   widgetConfig?.collectionId !== ""
    // ) {
    //   setIsValid(true);
    // } else {
    //   setIsValid(false);
    // }
    setIsValid(true);
  };

  const fallbackCopyTextToClipboard = (text: string) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const copyTextToClipboard = async (text: string = "") => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      copyTimerID = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    } catch (error) {
      console.log("ERROR COPYING TEXT", error);
    }
  };

  const handleCodeToCopy = (type: WidgetType) => {
    switch (type) {
      case WidgetType.gridEvents:
        {
          setCodeToCopy(gridEventsWidget);
        }
        break;
      case WidgetType.mainCarousel:
        {
          setCodeToCopy(mainCarrouselWidget);
        }
        break;
      case WidgetType.nextEventsCarousel:
        {
          setCodeToCopy(nextEventsWidget);
        }
        break;
      case WidgetType.vodGallery:
        {
          setCodeToCopy(vodGalleryWidget);
        }
        break;
      case WidgetType.bannerCommercial:
        {
          setCodeToCopy(bannerCommercialWidget);
        }
        break;
      case WidgetType.bannerSlider:
        {
          setCodeToCopy(bannerSliderWidget);
        }
        break;
      case WidgetType.categories:
        {
          setCodeToCopy(categoriesWidget);
        }
        break;
    }
  };

  useEffect(() => {
    if (widetType) {
      handleCodeToCopy(widetType);
    }

    if (widgetConfig) {
      availableConditionalHandler();
    }
  }, [widetType, widgetConfig]);

  useEffect(() => {
    return () => {
      if (copyTimerID) {
        clearTimeout(copyTimerID);
      }
    };
  }, []);

  return (
    <>
      <div
        className={styles.CopyButtonContainer}
        style={{ pointerEvents: copySuccess ? "none" : "auto" }}
      >
        <label>{t("views.firaTv.copyCode")}</label>

        <Button
          width="100%"
          clickHandler={() => copyTextToClipboard(codeToCopy)}
          isDisabled={!isValid}
        >
          <div>
            {!copySuccess ? (
              <div className={styles.ButtonInner}>
                <div className={styles.Icon}>
                  <CopyParperv2 />
                </div>
                <p>{t("views.firaTv.copy")}</p>
              </div>
            ) : (
              <div className={styles.ButtonInnerSuccess}>
                <div className={styles.Icon}>
                  <CheckCircleFill />
                </div>
                <span>
                  {t(
                    "modals.eventDetail.credentials.credentials.messages.copied"
                  )}
                </span>
              </div>
            )}
          </div>
        </Button>
      </div>
    </>
  );
};

export default WidgetCopyButton;
