import { ReactNode, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiraUserRole } from "../../../utils/enums";
import { RolesAndPermissionsContext } from "../../../views/OwnerPermissionsEditView/OwnerPermissionsEditView";
import { AdminIcon, UserIcon } from "../../FiraIcons";
import styles from "./Roles.module.scss";

interface SimpleRoleType {
  name: string;
  icon: ReactNode;
  role: FiraUserRole | null;
}

interface RolesProps {
  onRoleSelect(role: FiraUserRole): void;
}

const Roles = ({ onRoleSelect }: RolesProps) => {
  const { t } = useTranslation();
  const { permissionsData, handleRoleChange } = useContext(
    RolesAndPermissionsContext
  );
  const [selectedRole, setSelectedRole] = useState<SimpleRoleType>({
    name: "",
    icon: "",
    role: null,
  });

  const rolesObj = [
    {
      name: t("views.edit_permissions.roles.manager"),
      icon: <AdminIcon fontSize={"24px"} />,
      role: FiraUserRole.MANAGER,
    },
    {
      name: t("views.edit_permissions.roles.operator"),
      icon: <UserIcon fontSize={"24px"} />,
      role: FiraUserRole.OPERATOR,
    },
    {
      name: t("views.edit_permissions.roles.moderator"),
      icon: <UserIcon fontSize={"24px"} />,
      role: FiraUserRole.MODERATOR,
    },
  ];

  const handleRoleSelection = (role: SimpleRoleType) => {
    setSelectedRole(role);
    if (role.role !== null) {
      onRoleSelect(role.role);
      handleRoleChange(role.role);
    }
  };

  const checkSelection = (roleType: any) => {
    return selectedRole?.role == roleType;
  };

  useEffect(() => {
    const getRole = rolesObj.find((item) => item.role === permissionsData.role);
    getRole && setSelectedRole(getRole);
  }, [permissionsData.role]);

  return (
    <>
      <div className={styles.RolesWrapper}>
        {rolesObj.map((role, i) => (
          <div
            className={`${styles.RoleButton} ${
              checkSelection(role.role) ? styles.selected : ""
            }`}
            onClick={() => handleRoleSelection(role)}
            key={i}
          >
            {role.icon}
            <span>{role.name}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default Roles;
