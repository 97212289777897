import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, TableCell, TableHead, TableRow } from "../../components";
import { EmptyProductsWidget } from "../../components/FiraIcons";
import Skeleton from "../../components/Skeleton/Skeleton";
import { TableButton, TableStyles } from "../../components/Table/Table";
import styles from "./TableWidget.module.scss";

interface headerElement {
  name: string;
  size: string;
}
interface Props {
  bodyData: Array<any>;
  header: headerElement[];
  title: string;
  subTitle: string;
  onClick(data: any): void;
  isLoading: boolean;
  rowAlignLeft?: number;
  clickActive?: boolean;
  rowButton?: boolean;
}

const TableWidget = ({
  bodyData,
  header,
  title,
  subTitle,
  onClick,
  isLoading,
  rowAlignLeft = 0,
  clickActive,
  rowButton = true,
}: Props) => {
  const skeletonRows = [
    { id: 1, name: "" },
    { id: 2, name: "" },
    { id: 3, name: "" },
    { id: 4, name: "" },
    { id: 5, name: "" },
  ];

  const tableHeader = (
    <TableRow>
      {header.map((head, i) => (
        <TableHead background key={i} size={head.size} centerContent={i !== 1}>
          <div
            className={`${
              i !== 1 ? styles.tableHeader : styles.tableHeaderFirst
            }`}
          >
            <p>{head.name}</p>
          </div>
        </TableHead>
      ))}
      <TableHead background></TableHead>
    </TableRow>
  );

  const body = bodyData.map((rowData, index) => {
    return (
      <TableRow key={index} shrink>
        <TableCell size="9px" shrink></TableCell>

        {Object.keys(bodyData[0]).map((data, i) => {
          let rowKey = rowData["id"] ? i - 1 : i;

          if (data != "id") {
            return (
              <TableCell key={i} centerContent={rowKey !== rowAlignLeft} shrink>
                <div className={rowKey !== rowAlignLeft ? styles.centered : ""}>
                  <span
                    className={`${styles.tableEllipsis} 
                  ${rowKey !== rowAlignLeft ? styles.centered : ""}
                  ${data == "email" ? styles.emailCell : ""}
                  ${data == "phone" ? styles.phoneCell : ""}
                  ${data == "username" ? styles.nameCell : ""}`}
                  >
                    {rowData[data]}
                  </span>
                </div>
              </TableCell>
            );
          }
        })}
        {rowButton ? (
          <TableCell size="29px" shrink centerContent>
            <TableButton
              height="31px"
              width="19px"
              iconSize="10px"
              onClick={() => onClick(rowData)}
            />
          </TableCell>
        ) : (
          <TableCell size="0" shrink centerContent></TableCell>
        )}
      </TableRow>
    );
  });

  return (
    <div className={styles.usersWidgetContainer}>
      {isLoading ? (
        <>
          <Skeleton width="60%" height="36px" />
          <table>
            <tbody>{SkeletonSideBody}</tbody>
          </table>
        </>
      ) : (
        <>
          <div className={styles.header}>
            <h6>{title}</h6>
            <p className="small-text">{subTitle}</p>
          </div>
          <div className={styles.tableContainer}>
            {
              <div className={styles.scrollWrapper}>
                {bodyData.length == 0 ? (
                  <EmptyStateProductWidget />
                ) : (
                  <Table
                    tableStyle={TableStyles.underline}
                    header={tableHeader}
                    body={body}
                    height={bodyData.length !== 0 ? "100%" : "20%"}
                    pointerEvents={clickActive ? true : false}
                  />
                )}
              </div>
            }
          </div>
        </>
      )}
    </div>
  );
};

const EmptyStateProductWidget = () => {
  return (
    <div className={styles.emptyProducts}>
      <EmptyProductsWidget />
    </div>
  );
};

const rows = [
  { id: 1, name: "" },
  { id: 2, name: "" },
  { id: 3, name: "" },
];
const SkeletonSideBody = rows.map((data) => {
  return (
    <TableRow key={data.id}>
      <TableCell size="100px">
        <span>
          <Skeleton width="90%" height="36px" />
        </span>
      </TableCell>
    </TableRow>
  );
});
export default TableWidget;
