import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgVideoCamera = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.172 7.172A4 4 0 0 0 0 10v12a4 4 0 0 0 4 4h15a4 4 0 0 0 3.966-3.476l6.222 2.764A2 2 0 0 0 32 23.462V8.538a2 2 0 0 0-2.814-1.826l-6.22 2.764A4 4 0 0 0 19 6H4a4 4 0 0 0-2.828 1.172Zm1.853 1.765C2.37 9.537 2 10.351 2 11.2v9.6c0 .849.369 1.663 1.025 2.263.657.6 1.547.937 2.475.937h13.125c.849 0 1.669-.282 2.307-.794.639-.511 1.052-2.217 1.163-2.987l5.445 2.211a1.896 1.896 0 0 0 1.663-.12c.245-.145.445-.344.585-.578.139-.234.212-.496.212-.762v-9.94c0-.266-.073-.529-.212-.763a1.667 1.667 0 0 0-.585-.578 1.895 1.895 0 0 0-1.665-.12l-5.443 2.212c-.111-.77-.524-2.476-1.163-2.988A3.696 3.696 0 0 0 18.625 8H5.5c-.928 0-1.818.337-2.475.937Z"
      fill="#000"
    />
  </svg>
);

export default SvgVideoCamera;
