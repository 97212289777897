import React from "react";
import { useTranslation } from "react-i18next";
import LandingIntro from "../LandingIntro/LandingIntro";
import Button, { ButtonStyle } from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { useSettingsLanding } from "../../providers/SettingsLandingProvider";

const SettingsLandingModule: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { globalLandingConfig, state } = useSettingsLanding();

  const openLandingConfig = () => {
    navigate("/home/landing/config", {
      state: {
        isGlobalConfig: true,
        landingConfig: globalLandingConfig,
        globalLandingSettings: state,
      },
    });
  };

  return (
    <div style={Wrapper}>
      <div style={Header}>
        <div style={LeadingSpace}>
          <div style={StepNumber}>1</div>
          <span style={Title}>{t("components.settings.landing.one")}</span>
        </div>
        <div style={TrailingSpace}>
          {/*<Button buttonStyle={ButtonStyle.transparent}>
            <span style={Link}>{t("buttons.preview")}</span>
  </Button>*/}
          <Button clickHandler={openLandingConfig}>{t("buttons.setup")}</Button>
        </div>
      </div>
      <LandingIntro noRadiusTop />
    </div>
  );
};

const Wrapper: React.CSSProperties = {
  width: "100%",
  backgroundColor: "#fff",
  borderRadius: 8,
};

const Header: React.CSSProperties = {
  padding: "18px 32px",
  display: "flex",
  justifyContent: "space-between",
};

const LeadingSpace: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  gap: 8,
};

const TrailingSpace: React.CSSProperties = {
  display: "flex",
  gap: 8,
};

const StepNumber: React.CSSProperties = {
  width: 32,
  height: 32,
  borderRadius: "50%",
  backgroundColor: "#FFDE07",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 16,
  fontWeight: "600",
};

const Title: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 600,
  color: "#000",
};

const Link: React.CSSProperties = {
  textDecoration: "underline",
};

export default SettingsLandingModule;
