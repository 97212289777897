import React, { MouseEventHandler, ReactElement } from "react";
import "./Button.scss";

export enum ButtonStyle {
  main = "Main",
  border = "Border",
  transparent = "Transparent",
  inactivate = "Inactivate",
  load = "Load",
  light = "Light",
  danger = "Danger",
  delete = "Delete",
  mainBorder = "MainBorder",
  dangerLight = "DangerLight",
  disabledLink = "DisabledLink",
  wrapped = "Wrap",
  mk = "MK",
}
interface Props {
  name?: string;
  clickHandler?: MouseEventHandler<HTMLButtonElement>;
  type?: "submit" | "reset" | "button" | undefined;
  buttonStyle?: ButtonStyle;
  isDisabled?: boolean;
  width?: string;
  height?: string;
  className?: string;
  borderRadius?: string;
  disabledLink?: boolean;
  background?: string;
  wrapText?: boolean;
  minHeight?: string;
}
/**
 * Description of Button component
 * @prop {function} clickHandler - handler for the action of the button
 * @prop {ButtonTypes} type - type of button to show: MAIN | BORDER | TRANSPARENT
 * @prop {boolean} isDisabled - tells the button to be disable
 * @prop {string} width - width of the button: px | em | %
 * @prop {string} height - height of the button: px | em | %
 * @prop {ReactElement} children - text in the button
 * @prop {string} className - the classname of the button
 */
const Button: React.FC<Props> = (props) => {
  const {
    name,
    className,
    clickHandler,
    type,
    buttonStyle = ButtonStyle.main,
    isDisabled = false,
    width = "128px",
    height = "30px",
    children,
    borderRadius = "6px",
    disabledLink = false,
    background,
    minHeight,
  } = props;

  const style = {
    minWidth: width,
    height,
    borderRadius,
    background,
    minHeight,
  };
  return (
    <button
      name={name}
      disabled={isDisabled}
      className={`Button ${buttonStyle} ${className}`}
      onClick={clickHandler}
      type={type}
      style={style}
    >
      {children}
    </button>
  );
};

export default Button;
