import styles from "./LandingPage.module.scss";
import InputMedia, {
  InputMediaBorderStyle,
  InputMediaType,
} from "../../../InputMedia/InputMedia";
import { useEffect, useState } from "react";
import InputColor from "../../../InputColor/InputColor";
import InputRange from "../../../InputRange/InputRange";
import { t } from "i18next";
import { uploadImageService } from "../../../../services/upload";
import { FiraLandingConfiguration } from "../../../../types/Landing";
import ToggleSwitch from "../../../ToggleSwitch/ToggleSwitch";
import InputWS, { CallingCodeSimple } from "../../../InputWS/InputWS";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/Store";
import { FiraUserRole } from "../../../../utils/enums";
import { useLandingConfig } from "../../../../providers/LandingConfigProvider";
import { FiraPhone } from "../../../../utils/types";
import { CountryCode } from "../../../../services/utils";

const LandingPage = () => {
  const { authentication } = useSelector((state: RootState) => state);
  const { landingConfig } = useLandingConfig();
  return (
    <>
      <div className={styles.LandingPageModulesWrapper}>
        <ShoppingCart />
        {authentication.currentRole !== FiraUserRole.OPERATOR &&
          !landingConfig.useOwnerDefaultLandingConfig && (
            <>
              <LogoDesktop />
              <LogoMobile />
              <HeaderHeight />
              <ColorStyle />
              <LogoAlignment />
            </>
          )}
      </div>
    </>
  );
};

const ShoppingCart = () => {
  const { existingLanding, landingConfig, setLandingConfig, isGlobalConfig } =
    useLandingConfig();
  const [showShoppingCart, setShowShoppingCart] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<FiraPhone>();

  const [prevPhone, setPrevPhone] = useState<FiraPhone>();

  const handleShoppingCart = () => {
    setShowShoppingCart(!showShoppingCart);
    setLandingConfig({
      ...landingConfig,
      shoppingCart: !showShoppingCart,
      phoneNumber: phoneNumber,
      storeNumber: prevPhone,
    });
  };

  const handlePhoneNumber = (number: string, code: CountryCode) => {
    const phone: FiraPhone = {
      number,
      country: code.countryCode,
      operator: code.dialCode,
      type: "MOBILE",
    };
    setPhoneNumber(phone);
    setLandingConfig({
      ...landingConfig,
      name:
        existingLanding && existingLanding.name
          ? existingLanding.name
          : landingConfig.name,
      phoneNumber: phone,
      storeNumber: phone,
      shoppingCart: showShoppingCart,
    });
  };

  useEffect(() => {
    if (existingLanding !== null) {
      const { shoppingCart } = existingLanding;
      setShowShoppingCart(shoppingCart || false);
      if (
        existingLanding.phoneNumber &&
        existingLanding.phoneNumber.number !== ""
      ) {
        setPrevPhone(existingLanding.phoneNumber);
      }
    }
  }, [existingLanding]);

  return (
    <div className={styles.LandingModule}>
      <div className={styles.LandingModule__Title}>
        {t("views.landing.page.shoppingCart")}
      </div>
      <div className={styles.ShoppingSwitch}>
        <label>{t("views.landing.page.shoppingCartSwitch")}</label>
        <ToggleSwitch
          hideTags={true}
          checked={showShoppingCart}
          handleChecked={handleShoppingCart}
          disable={landingConfig.parametersSetted}
        />
      </div>
      {showShoppingCart && !isGlobalConfig && (
        <InputWS
          onChange={handlePhoneNumber}
          phone={
            existingLanding && existingLanding.storeNumber
              ? existingLanding.storeNumber
              : undefined
          }
          disable={landingConfig.parametersSetted}
        />
      )}
    </div>
  );
};

const LogoDesktop = () => {
  const formats = "PNG, SVG";
  const sizes = "150x150 (1:1)";
  const {
    existingLanding,
    firaLandingConfiguration,
    setFiraLandingConfiguration,
  } = useLandingConfig();
  const [imageIsLoading, setImageIsLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>("");

  const handleFile = (file: File | null) => {
    if (file !== null) {
      setImageIsLoading(true);
      uploadImageService(file).then((val) => {
        if (val) {
          setFiraLandingConfiguration({
            ...firaLandingConfiguration,
            webLogoUrl: val.data.images[0],
          });
        }
        setImageIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (existingLanding && existingLanding.firaLandingConfiguration) {
      setLogo(existingLanding.firaLandingConfiguration.webLogoUrl);
    }
  }, [existingLanding]);

  return (
    <div className={styles.LandingModule}>
      <div className={styles.LandingModule__Title}>Logo desktop</div>
      <InputMedia
        borderStyle={InputMediaBorderStyle.dashed}
        height={"140px"}
        formats={formats}
        sizes={sizes}
        mediaType={InputMediaType.image}
        onFileChange={handleFile}
        isDisabled={imageIsLoading}
        preview={logo ? logo : undefined}
      />
    </div>
  );
};

const LogoMobile = () => {
  const formats = "PNG, SVG";
  const sizes = "150x150 (1:1)";
  const {
    existingLanding,
    firaLandingConfiguration,
    setFiraLandingConfiguration,
  } = useLandingConfig();
  const [imageIsLoading, setImageIsLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>("");
  const handleFile = (file: File | null) => {
    if (file !== null) {
      setImageIsLoading(true);
      uploadImageService(file).then((val) => {
        if (val) {
          setFiraLandingConfiguration({
            ...firaLandingConfiguration,
            mobileLogoUrl: val.data.images[0],
          });
        }
        setImageIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (existingLanding && existingLanding.firaLandingConfiguration) {
      setLogo(existingLanding.firaLandingConfiguration.mobileLogoUrl);
    }
  }, [existingLanding]);

  return (
    <div className={styles.LandingModule}>
      <div className={styles.LandingModule__Title}>Logo mobile</div>
      <InputMedia
        borderStyle={InputMediaBorderStyle.dashed}
        height={"140px"}
        formats={formats}
        sizes={sizes}
        mediaType={InputMediaType.image}
        onFileChange={handleFile}
        isDisabled={imageIsLoading}
        preview={logo ? logo : undefined}
      />
    </div>
  );
};

const HeaderHeight = () => {
  const {
    existingLanding,
    firaLandingConfiguration,
    setFiraLandingConfiguration,
  } = useLandingConfig();
  const [height, setHeight] = useState<number>(72);

  const handleHeight = (value: number) => {
    setHeight(value);
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      headerHeight: value,
    });
  };

  useEffect(() => {
    if (existingLanding && existingLanding.firaLandingConfiguration) {
      setHeight(existingLanding.firaLandingConfiguration.headerHeight);
    }
  }, [existingLanding]);

  return (
    <div className={styles.LandingModule}>
      <div className={styles.LandingModule__Title}>
        {t("views.landing.page.headerHeight")}
        <div className={styles.LandingModule__Title__counter}>{height}</div>
      </div>
      <InputRange onRangeChange={handleHeight} sliderVal={height} />
    </div>
  );
};

const ColorStyle = () => {
  const {
    existingLanding,
    firaLandingConfiguration,
    setFiraLandingConfiguration,
  } = useLandingConfig();
  const [firaConfig, setFiraConfig] = useState<FiraLandingConfiguration | null>(
    null
  );

  const handleBackground = (color: string) => {
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      bgColor: `#${color}`,
    });
  };

  const handleHeaderBackground = (color: string) => {
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      headerColor: `#${color}`,
    });
  };

  const handleFooterBackground = (color: string) => {
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      footerColor: `#${color}`,
    });
  };

  const noHash = (str: string) => {
    if (str) {
      return str.replace(/#/g, "");
    }
  };

  useEffect(() => {
    if (existingLanding && existingLanding.firaLandingConfiguration) {
      setFiraConfig(existingLanding.firaLandingConfiguration);
    }
  }, [existingLanding]);

  return (
    <div className={styles.LandingModule} style={{ paddingRight: "8px" }}>
      <div className={styles.LandingModule__Title}>
        {t("views.landing.page.colorStyle")}
      </div>
      <InputColor
        onHexChange={handleBackground}
        label={t("views.landing.page.background")}
        value={firaConfig ? noHash(firaConfig.bgColor) : ""}
      />
      <InputColor
        onHexChange={handleHeaderBackground}
        label={t("views.landing.page.header")}
        value={firaConfig ? noHash(firaConfig.headerColor) : ""}
      />
      <InputColor
        onHexChange={handleFooterBackground}
        label={t("views.landing.page.footer")}
        value={firaConfig ? noHash(firaConfig.footerColor) : ""}
      />
    </div>
  );
};

export enum LogoAlignmentType {
  left = "LEFT",
  center = "CENTER",
}

const LogoAlignment = () => {
  const {
    existingLanding,
    firaLandingConfiguration,
    setFiraLandingConfiguration,
  } = useLandingConfig();
  const [logoAlignment, setLogoAlignment] = useState<LogoAlignmentType>(
    LogoAlignmentType.center
  );

  const handleLogoAlignment = (alignment: LogoAlignmentType) => {
    setFiraLandingConfiguration({
      ...firaLandingConfiguration,
      logoAlignment: alignment,
    });
  };

  useEffect(() => {
    if (existingLanding && existingLanding.firaLandingConfiguration !== null) {
      setLogoAlignment(
        existingLanding.firaLandingConfiguration.logoAlignment ||
          LogoAlignmentType.center
      );
    }
  }, [existingLanding]);

  useEffect(() => {
    handleLogoAlignment(logoAlignment);
  }, [logoAlignment]);

  return (
    <div className={styles.LandingModule}>
      <div className={styles.LandingModule__Title}>
        {t("views.landing.page.logoAlignment")}
      </div>
      <div className={styles.LogoAlignmentSelector}>
        <div
          className={styles.LogoAlignmentSelector__Option}
          onClick={() => setLogoAlignment(LogoAlignmentType.left)}
        >
          <div
            className={`${styles.LogoAlignmentSelector__Option__Circle} ${
              logoAlignment === LogoAlignmentType.left ? styles.active : ""
            }`}
          ></div>
          <label>{t("views.landing.page.left")}</label>
        </div>
        <div
          className={styles.LogoAlignmentSelector__Option}
          onClick={() => setLogoAlignment(LogoAlignmentType.center)}
        >
          <div
            className={`${styles.LogoAlignmentSelector__Option__Circle} ${
              logoAlignment === LogoAlignmentType.center ? styles.active : ""
            }`}
          ></div>
          <label>{t("views.landing.page.center")}</label>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
