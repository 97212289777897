import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgOpenBox = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M18.63 15.189c-.66 0-1.28-.37-1.617-.96l-2.511-4.372-2.508 4.372c-.34.595-.961.964-1.621.964-.176 0-.352-.024-.52-.078l-5.351-1.608v7.301c0 .603.39 1.128.945 1.272l8.445 2.219c.399.102.817.102 1.211 0l8.453-2.22c.555-.147.946-.672.946-1.27v-7.302l-5.352 1.604a1.718 1.718 0 0 1-.52.078Zm8.305-4.602-2.011-4.216a.631.631 0 0 0-.653-.365l-9.77 1.308 3.583 6.239a.629.629 0 0 0 .722.299l7.73-2.318c.387-.118.575-.57.4-.947ZM4.08 6.371l-2.012 4.216c-.18.377.012.829.395.943l7.73 2.318a.629.629 0 0 0 .723-.3l3.586-6.234-9.774-1.308a.632.632 0 0 0-.648.365Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgOpenBox;
