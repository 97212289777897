import { ReactNode } from "react";
import "./WidgetConfigurationLayout.scss";

interface WidgetConfigurationLayoutProps {
  children?: ReactNode;
  style?: Object;
}

const WidgetConfigurationLayout = ({
  children,
  style,
}: WidgetConfigurationLayoutProps) => {
  return (
    <div
      className={`${"WidgetConfigurationLayout"}`}
      style={style ? style : {}}
    >
      {children}
    </div>
  );
};

export default WidgetConfigurationLayout;
