export const FONTS = [
  { font: "Helvetica" },
  { font: "Serif" },
  { font: "Calibri" },
  { font: "Averta" },
  { font: "Arvo" },
  { font: "Quicksand" },
  { font: "Baskervville" },
  { font: "Blacker" },
  { font: "Radio Grotesk" },
  { font: "Woodland" },
  { font: "Symphony Script" },
  { font: "Playfair Display" },
  { font: "Titillium" },
  { font: "Lato" },
  { font: "Montserrat" },
  { font: "Merriweather" },
  { font: "Nexa" },
  { font: "Pacifico" },
  { font: "FreeLine" },
  { font: "Hillenberg" },
  { font: "Space Mono" },
  { font: "Syne" },
  { font: "Poppins" },
  { font: "IBM Plex Sans" },
  { font: "Soleil" },
  { font: "Unna" },
  { font: "Interstate" },
  { font: "Indie Flower" },
  { font: "Mukta" },
  { font: "Josefine Sans" },
  { font: "Futura" },
  { font: "Candara" },
  { font: "Oswald" },
  { font: "Ariel" },
  { font: "Helvetica" },
  { font: "Times" },
  { font: "Courier New" },
  { font: "Verdana" },
  { font: "Courier" },
  { font: "Arial Narrow" },
  { font: "Ginebra" },
  { font: "Optima" },
  { font: "Cambria" },
  { font: "Garamond" },
  { font: "Perpetua" },
  { font: "Monaco" },
  { font: "Didot" },
  { font: "Brush Script MT" },
  { font: "Copperplate" },
];
