import { useEffect, useState } from "react";

import styles from "./VideoStatsView.module.scss";
import {
  completeDate,
  hyphenDateFormat,
  shortYearDate,
} from "../../utils/handleDates";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import {
  ChartDataOptionItemType,
  CriteriaType,
  DashboardEvent,
  FiraVideo,
  RootState,
} from "../../utils/types";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { Calendar, DateObject } from "react-multi-date-picker";

import { t } from "i18next";

import "react-multi-date-picker/styles/colors/yellow.css";
import CardWidget from "../../widgets/CardWidget/CardWidget";
import CalendarMaterial from "../../components/FiraIcons/CalendarMaterial";

import InteractionsWidget from "../../widgets/InteractionsWidget/InteractionsWidget";

import { timeTextFormatDHMS } from "../../utils/timeTextFormatHMS";
import GeneralStatiticsChart from "../../components/GeneralStatisticsChart/GeneralStatisticsChart";
import LeftArrowNoPadding from "../../assets/svg/LeftArrowNoPadding.svg";
import {
  getProductsStatsCreator,
  getVideoStatsActionCreator,
} from "../../store/Video/VideoActions";
import ViewsByDevices from "../../widgets/ViewsbyDevicesWidget/ViewsByDevicesWidget";
import { Input } from "../../components";
import { ArrowLeft, ArrowRight, Download } from "../../components/FiraIcons";
import { downloadVODStatistics, getVideoCriteria } from "../../services/videos";
import ProductListWidget from "../../widgets/ProductListWidget/ProductListWidget";
import Button, { ButtonStyle } from "../../components/Button/Button";
import { handleExcelFileDownload } from "../../utils/handleFileDownload";
import MainLoader from "../../components/MainLoader/MainLoader";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";
import OpenLink from "../../components/FiraIcons/OpenLink";
import FiraBadge from "../../components/FiraBadge";

interface eventBodyType {
  id: string;
  name: string;
  date: string;
  products: number;
  likes: number;
  shares: number;
}

const VideoStatsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { brand, video } = useSelector((state: RootState) => state);
  const [criteriaData, setCriteriaData] = useState<CriteriaType[]>([]);
  const [criteriaSelected, setCriteriaSelected] = useState<string>();
  const [totalAudience, setTotalAudience] = useState(0);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const showAlert = usePopupAlert();
  const playerUrl = process.env.REACT_APP_BASE_URL_PLAYER;
  const parseEventsArray = (events: DashboardEvent[]) => {
    let temparray: eventBodyType[] = [];
    events.forEach((event) => {
      let tempObj = {
        id: event.id,
        name: event.name,
        date: format(new Date(event.starDate), "dd/MM/yyyy"),
        products: event.products,
        likes: event.likes,
        shares: event.shares,
      };
      temparray.push(tempObj);
    });
    return temparray;
  };

  useEffect(() => {
    const init = new Date();
    var primerDia = new Date(init.getFullYear(), init.getMonth(), 1);

    init.setHours(0);
    init.setMinutes(0);
    init.setSeconds(0);
    init.setMilliseconds(0);

    primerDia.setHours(0);
    primerDia.setMinutes(0);
    primerDia.setSeconds(0);
    primerDia.setMilliseconds(0);

    const end = new Date();

    end.setHours(23);
    end.setMinutes(59);
  }, [brand.currentStore]);

  const handleEventList = () => {
    navigate("/home/events");
  };

  useEffect(() => {
    audienceSum();
  }, [video]);

  const audienceSum = () => {
    let total = 0;
    if (video.currentVideo && video.currentVideo.videoStats.audienceCount) {
      const totalResponse = video.currentVideo.videoStats.audienceCount;
      total =
        total +
        totalResponse.webAudienceCount +
        totalResponse.iosAudienceCount +
        totalResponse.mobileAudienceCount +
        totalResponse.androidAudienceCount;
    }

    setTotalAudience(total);
  };

  const containerCards = (
    <div className={styles.countersWrapper}>
      <CardWidget
        title={t("widgets.card.availableTime")}
        description={"Duración del evento"}
        data={timeTextFormatDHMS(video.currentVideo?.visibleTimeMillis) + ""}
        isLoading={false}
      />

      <CardWidget
        title={t("widgets.card.avgReproduction")}
        description={"Promedio de reproduccion"}
        data={
          timeTextFormatDHMS(
            video.currentVideo?.videoStats.videoPlayAvgTime !== null
              ? video.currentVideo?.videoStats.videoPlayAvgTime
              : 0
          ) + ""
        }
        isLoading={false}
      />
      <CardWidget
        title={t("views.dashboard.engagement")}
        description={"Engagement"}
        data={
          (video.currentVideo?.videoStats.engagementRate !== null
            ? video.currentVideo?.videoStats.engagementRate
            : 0) + "%"
        }
        isLoading={false}
      />
      <CardWidget
        title={t("views.dashboard.rate")}
        description={"Tasa de rebote"}
        data={
          (video.currentVideo?.videoStats.bouncingRate !== null
            ? video.currentVideo?.videoStats.bouncingRate
            : 0) + "%"
        }
        isLoading={false}
      />
      <CardWidget
        title={t("widgets.audienceResume.audiencia")}
        description={"Audiencia"}
        data={totalAudience + ""}
        isLoading={false}
      />

      <CardWidget
        title={t("views.dashboard.reproductionAccum")}
        description={"Tiempo total de reproducción"}
        data={
          timeTextFormatDHMS(
            video.currentVideo?.videoStats.videoPlayAccum !== null
              ? video.currentVideo?.videoStats.videoPlayAccum
              : 0
          ) + ""
        }
        isLoading={false}
      />
    </div>
  );

  interface ChartDataType {
    data: any[];
    optionsToShow: ChartDataOptionItemType[];
    elementsToShow: number;
    lineType?:
      | "monotone"
      | "monotoneX"
      | "monotoneY"
      | "natural"
      | "step"
      | "stepAfter"
      | "stepBefore"
      | "linear"
      | undefined;
    isLoading?: boolean;
  }
  const optionsToShow = [
    { dataKey: "Likes", name: "Likes", active: true, color: "#1E3BB3" },
    { dataKey: "Share", name: "Share", active: true, color: "#F8CFC7" },
    { dataKey: "Clicks", name: "Clicks", active: true, color: "#BEEF00" },
    { dataKey: "Messages", name: "Messages", active: true, color: "#53CDF9" },
    { dataKey: "Audience", name: "Audience", active: true, color: "#FFDE07" },
  ];
  const [generalChartData, setGeneralChartData] = useState<ChartDataType>({
    data: [],
    optionsToShow: optionsToShow,
    elementsToShow: 1,
    isLoading: false,
  });

  const backButtonHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (video.currentVideo) {
      dispatch(getProductsStatsCreator(video.currentVideo?.id));
      callServicesCriteria();
    }
  }, []);

  const callServicesCriteria = async () => {
    try {
      if (video.currentVideo) {
        const videoCritera = (await getVideoCriteria(video.currentVideo.id))
          .data;
        setCriteriaData(videoCritera);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const listenChange = (criteria: string) => {
    setCriteriaSelected(criteria);
    video.currentVideo?.id &&
      dispatch(getVideoStatsActionCreator(video.currentVideo?.id, criteria));
  };

  const formatTimeStats = (time: Date) => {
    let timeDef: string;
    let timeDif: number;

    const currentDate = new Date(); // Obtiene la fecha y hora actual
    const localOffset = currentDate.getTimezoneOffset() / 60; // Offset de la zona horaria local en horas
    const utcOffset = 0; // Offset de la zona horaria UTC en horas, que es 0
    const timeDifference = localOffset - utcOffset; // Diferencia horaria en horas
    if (timeDifference > 0) {
      timeDif = timeDifference * -1;
    } else if (timeDifference < 0) {
      timeDif = timeDifference;
    } else {
      timeDif = 0;
    }

    if (criteriaSelected === "current" || criteriaSelected === "yesterday") {
      timeDef =
        time.getHours() < 5
          ? 24 + timeDif + time.getHours() + ":00"
          : timeDif + time.getHours() + ":00";
      timeDef = timeDef.length === 4 ? "0" + timeDef : timeDef;
    } else {
      timeDef = time.getDate() + "/" + time.getMonth();
    }

    return timeDef;
  };
  const exportStats = async () => {
    if (video.currentVideo) {
      try {
        setShowLoader(true);
        const response = await downloadVODStatistics(video.currentVideo.id);
        handleExcelFileDownload(
          response.data,
          `FiraVOD_${video.currentVideo.videoName}_StatsData.xls`
        );
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
        showAlert(AlertType.error, t("views.videos.error.stats"));
        console.error(error);
      }
    }
  };

  const redirectToPlayer = () => {
    if (video.currentVideo) {
      window.open(
        `${playerUrl}?firakey=123&firasrc=${video.currentVideo.originEvent}`,
        "_blank",
        "noreferrer"
      );
    }
  };

  const renderVideoBadge = (video: FiraVideo) => {
    return video.isParent ? (
      <FiraBadge>Simulcast</FiraBadge>
    ) : !video.isParent && video.parentId === null ? (
      <FiraBadge color="#F7F8F9" textColor="#000">
        Individual
      </FiraBadge>
    ) : (
      ""
    );
  };

  useEffect(() => {
    const data: any[] = [];
    if (video.currentVideo?.videoStats.statsTime) {
      video.currentVideo?.videoStats.statsTime.map((item) => {
        data.push({
          Minute: formatTimeStats(new Date(item.elapsedInit)),
          Likes: item.likeCount,
          Share: item.shareCount,
          Clicks: item.productClickCount,
          Messages: item.commentCount,
          Audience: item.audienceCount,
        });
      });
    }

    setGeneralChartData({ ...generalChartData, data: data });
  }, [video.currentVideo?.videoStats.statsTime]);

  return (
    <>
      {showLoader && <MainLoader />}
      <div className={styles.HeaderDashboard}>
        <div className={styles.ItemText}>
          <label className={styles.title}>
            <button className={styles.BackBtnStats} onClick={backButtonHandler}>
              <img src={LeftArrowNoPadding} alt="" />
            </button>
            <b>{video.currentVideo?.videoName}</b>
          </label>
          {video.currentVideo && renderVideoBadge(video.currentVideo)}
        </div>
        <div className={styles.itemsRight}>
          <Button
            width="122px"
            height="31px"
            isDisabled={
              showLoader || video.currentVideo?.visibility !== "VISIBLE"
            }
            clickHandler={redirectToPlayer}
            buttonStyle={ButtonStyle.transparent}
          >
            <div className={styles.previewButton}>
              <p className="alert-text-14">{t("views.videos.previewLink")}</p>
              <OpenLink />
            </div>
          </Button>
          <Button
            width="122px"
            height="31px"
            isDisabled={showLoader}
            clickHandler={exportStats}
          >
            <div className={styles.exportButton}>
              <Download />
              <p className="alert-text-14">
                {t("views.products.exportButton")}
              </p>
            </div>
          </Button>
        </div>
      </div>

      <div className={styles.videoContainer}>
        {containerCards}

        <div className={styles.itemJumbo}>
          <GeneralStatiticsChart
            title={t("views.videos.labels.titleStats")}
            subTitle={t("views.videos.labels.subtitleStats")}
            bodyData={generalChartData.data}
            fieldX="Minute"
            optionsToShow={generalChartData.optionsToShow}
            elementsToShow={4}
            lineType={"monotone"}
            isLoading={false}
            isSelectCheckboxes={true}
            criteriaSelect={criteriaData}
            listenChange={listenChange}
          />
        </div>
        <div className={styles.ContainerGroup}>
          <div className={styles.content}>
            <div className={styles.bigContainers}>
              <InteractionsWidget
                likes={Number(
                  video.currentVideo?.videoStats.likeCount !== null
                    ? video.currentVideo?.videoStats.likeCount
                    : 0
                )}
                clicks={Number(
                  video.currentVideo?.videoStats.productClickCount !== null
                    ? video.currentVideo?.videoStats.productClickCount
                    : 0
                )}
                messages={Number(
                  video.currentVideo?.videoStats.commentCount !== null
                    ? video.currentVideo?.videoStats.commentCount
                    : 0
                )}
                shares={Number(
                  video.currentVideo?.videoStats.shareCount !== null
                    ? video.currentVideo?.videoStats.shareCount
                    : 0
                )}
                isLoading={false}
              />
            </div>
            {
              <div className={styles.sideContainers}>
                {
                  <div className={styles.itemBox}>
                    <ViewsByDevices
                      desktop={
                        video.currentVideo?.videoStats.audienceCount
                          .webAudienceCount != null
                          ? video.currentVideo?.videoStats.audienceCount
                              .webAudienceCount
                          : 0
                      }
                      mobile={
                        video.currentVideo?.videoStats.audienceCount
                          .mobileAudienceCount != null
                          ? video.currentVideo?.videoStats.audienceCount
                              .mobileAudienceCount
                          : 0
                      }
                      other={
                        video.currentVideo?.videoStats.audienceCount
                          .androidAudienceCount ||
                        video.currentVideo?.videoStats.audienceCount
                          .iosAudienceCount
                          ? video.currentVideo?.videoStats.audienceCount
                              .androidAudienceCount != null
                            ? video.currentVideo?.videoStats.audienceCount
                                .androidAudienceCount
                            : 0 +
                                video.currentVideo?.videoStats.audienceCount
                                  .iosAudienceCount !=
                              null
                            ? video.currentVideo?.videoStats.audienceCount
                                .iosAudienceCount
                            : 0
                          : 0
                      }
                    />
                  </div>
                }
                <ProductListWidget
                  title={t("views.videos.productList.title")}
                  subtitle={t("views.videos.productList.subtitle")}
                  products={
                    video.currentVideo?.videoStats.productsClickStats
                      ? video.currentVideo?.videoStats.productsClickStats
                      : []
                  }
                  isStatsProducts={true}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

const DateRangePickerComponent = () => {
  const [opened, setOpened] = useState(false);

  const [openSelect, setOpenSelect] = useState(false);
  const [initDate, setInitDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  useEffect(() => {
    var date = new Date();

    var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    setInitDate(primerDia);

    var ultimoDia = new Date();
    setEndDate(ultimoDia);
  }, []);
  const openRange = () => {
    setOpened(!opened);
  };

  const onClickOutsideListener = () => {
    setOpenSelect(false);
    setOpened(false);
    document.removeEventListener("click", onClickOutsideListener);
  };

  const showDate = () => {
    if (initDate == endDate) {
      return `${completeDate(endDate ? endDate : new Date())}`;
    } else {
      return `${shortYearDate(
        initDate ? initDate : new Date()
      )} - ${shortYearDate(endDate ? endDate : new Date())}`;
    }
  };

  const handleChangeDate = (startdate: Date, enddate: Date) => {
    setInitDate(startdate);
    setEndDate(enddate);
  };
  return (
    <div className={styles.DateRangePicker}>
      <div
        className={`${styles.pickerButton} ${opened ? styles.opened : ""}`}
        onClick={openRange}
      >
        <CalendarMaterial className={styles.icon} />
        <div className={styles.dateText}>{showDate()}</div>
      </div>
      {opened && (
        <div
          className={styles.calendarContainer}
          onMouseLeave={() => {
            document.addEventListener("click", onClickOutsideListener);
          }}
          onMouseEnter={() => {
            document.removeEventListener("click", onClickOutsideListener);
          }}
        >
          <Personalized
            initDate={initDate}
            endDate={endDate}
            onChange={handleChangeDate}
          />
        </div>
      )}
    </div>
  );
};
interface PersonalizedProps {
  initDate: Date;
  endDate: Date;
  onChange(init: Date, end: Date): void;
}

const Personalized = ({ initDate, endDate, onChange }: PersonalizedProps) => {
  const { brand } = useSelector((state: RootState) => state);
  const [startDate, setStartDate] = useState<Date>(initDate);
  const [endedDate, setEndedDate] = useState<Date>(endDate);
  const weekDays = [
    t("calendar.sunday"),
    t("calendar.monday"),
    t("calendar.tuesday"),
    t("calendar.wednesday"),
    t("calendar.thursday"),
    t("calendar.friday"),
    t("calendar.saturday"),
  ];
  const months = [
    t("calendar.january"),
    t("calendar.february"),
    t("calendar.march"),
    t("calendar.april"),
    t("calendar.may"),
    t("calendar.june"),
    t("calendar.july"),
    t("calendar.august"),
    t("calendar.september"),
    t("calendar.october"),
    t("calendar.november"),
    t("calendar.december"),
  ];

  const callRequest = (init: Date, end: Date | null) => {
    if (brand.currentStore && init && end) {
      end.setHours(23);
      end.setMinutes(59);
      callRequestMode(init, end);
    } else {
      if (brand.currentStore && init) {
        const newEnd = init;
        newEnd.setHours(23);
        newEnd.setMinutes(59);
        callRequestMode(init, newEnd);
      }
    }
  };

  const callRequestMode = (init: Date, end: Date) => {
    if (brand.currentStore) {
      onChange(init, end);
      const current = new Date();
      current.setHours(0);
      current.setMinutes(0);
    }
  };
  const [values, setValues] = useState([new DateObject(), new DateObject()]);

  const handleChange = (objects: DateObject[]) => {
    if (objects[1]) {
      setEndedDate(new Date(objects[1].toString()));
    } else {
      setEndedDate(new Date(objects[0].toString()));
    }
    if (objects[0]) {
      setStartDate(new Date(objects[0].toString()));
    }

    callRequest(
      new Date(objects[0].toString()),
      objects[1] ? new Date(objects[1].toString()) : null
    );
  };

  return (
    <>
      <div className={styles.rangeInputs}>
        <div>
          <h6>{t("calendar.from")}</h6>
          <Input
            key={"start"}
            value={hyphenDateFormat(startDate)}
            width="196px"
            height="36px"
            inputName="startDate"
            onChange={() => {}}
          />
        </div>
        <div>
          <h6>{t("calendar.to")}</h6>
          <Input
            key={"end"}
            value={hyphenDateFormat(endedDate)}
            width="196px"
            height="36px"
            inputName="endedDate"
            onChange={() => {}}
          />
        </div>
      </div>
      <div>
        <Calendar
          value={values}
          onChange={handleChange}
          numberOfMonths={2}
          range
          hideYear
          weekDays={weekDays}
          months={months}
          shadow={false}
          renderButton={CustomButton}
          className="yellow custom-calendar "
        />
      </div>
    </>
  );
};

const CustomButton = (
  direction: String,
  handleClick: any,
  disabled: boolean
) => {
  return (
    <div
      onClick={handleClick}
      style={{
        padding: "0 10px",
        fontWeight: "bold",
        color: disabled ? "black" : "black",
        cursor: "pointer",
      }}
      className={"cursor-pointer"}
    >
      {direction === "right" ? (
        <ArrowRight className="icon" />
      ) : (
        <ArrowLeft className="icon" />
      )}
    </div>
  );
};

export default VideoStatsView;
