import styles from "./LandingConfigurationView.module.scss";
import LandingSideBar from "../../components/Landing/LandingSideBar/LandingSideBar";
import LandingTopBar from "../../components/Landing/LandingTopBar/LandingTopBar";
import LandingModulesBar from "../../components/Landing/LandingModulesBar/LandingModulesBar";

import LandingCanvasViewer from "../../components/Landing/LandingCanvasViewer/LandingCanvasViewer";

import { MainLoader } from "../../components";
import { useLocation } from "react-router-dom";
import LandingConfigProvider, {
  useLandingConfig,
} from "../../providers/LandingConfigProvider";
import { FiraLanding } from "../../types/Landing";
import { GlobalLandingSettings } from "../../providers/SettingsLandingProvider";

export type LocationState = {
  isGlobalConfig: boolean;
  landingConfig: FiraLanding | null;
  globalLandingSettings: GlobalLandingSettings;
};

const LandingConfigurationView = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const isGlobalConfig = state ? state.isGlobalConfig : false;
  const initalState: LocationState = {
    isGlobalConfig: false,
    landingConfig: null,
    globalLandingSettings: {
      isActive: false,
      blockWidgetsAndCart: false,
      stores: [],
    },
  };
  const { landingConfig, globalLandingSettings } = state ? state : initalState;

  return (
    <div className={styles.LandingConfigurationComponent}>
      <LandingConfigProvider
        isGlobalConfig={isGlobalConfig}
        globalLandingSettings={globalLandingSettings}
        config={landingConfig}
      >
        <LandingConfigScreen />
      </LandingConfigProvider>
    </div>
  );
};

const LandingConfigScreen = () => {
  const {
    mobileModuleBar,
    showModuleBar,
    creatingPage,
    createLanding,
    saveLanding,
    isLoading,
  } = useLandingConfig();

  return (
    <>
      {isLoading && <MainLoader />}
      <div id={styles.navbar}>
        <LandingTopBar onCreate={creatingPage ? createLanding : saveLanding} />
      </div>
      <div id={styles.sidebar}>
        <LandingSideBar />
      </div>
      <div
        id={styles.modules}
        className={`${mobileModuleBar ? styles.sidebarIsMobile : ""} ${
          showModuleBar ? styles.showSideBar : ""
        }`}
      >
        <LandingModulesBar />
      </div>
      {showModuleBar && mobileModuleBar && (
        <div className={styles.viewerOverlay}></div>
      )}
      <div id={styles.viewer}>
        <LandingCanvasViewer />
      </div>
    </>
  );
};

export default LandingConfigurationView;
