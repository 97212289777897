import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button, { ButtonStyle } from "../../../components/Button/Button";
import { SimplePermissionItem } from "../../../types/Response";
import { FiraPackage } from "../../../utils/enums";
import { onlyAvailablePermissions } from "../../../utils/handlePermissionCheckboxSelection";
import { InvitationType, RoleType, RootState } from "../../../utils/types.d";
import styles from "./FiraPermissions.module.scss";
import PermissionItemsForAdmin from "./PermissionItemsForAdmin/PermissionsItemsForAdmin";
import PermissionItemsForModerator from "./PermissionsItemsForModerator/PermissionItemsForModerator";
import PermissionItemsForOperator from "./PermissionsItemsForOperator/PermissionItemsForOperator";

interface FiraPermissionsProps {
  invitation: InvitationType;
  handlePrev(): void;
  handleCreate(): void;
  handleAddPermissionsByProduct(product: FiraPackage): void;
  handleDeletePermissionsByProduct(product: FiraPackage): void;
  handleAddPermissionsByGroup(
    product: FiraPackage,
    permissions: string[]
  ): void;
  handleDeletePermissionsByGroup(
    product: FiraPackage,
    permissions: string[]
  ): void;
  handleDeleteEntireGroup(product: FiraPackage): void;
  isLoading: boolean;
}

const FiraPermissions = ({
  invitation,
  handlePrev,
  handleCreate,
  handleAddPermissionsByProduct,
  handleDeletePermissionsByProduct,
  handleAddPermissionsByGroup,
  handleDeletePermissionsByGroup,
  handleDeleteEntireGroup,
  isLoading,
}: FiraPermissionsProps) => {
  const { t } = useTranslation();
  const [selectedRole, setSelectedRople] = useState<RoleType>();
  const [profileTile, setProfileTitle] = useState<string>("");
  const [profileDesc, setProfileDesc] = useState<string>("");

  const getInfo = () => {
    switch (selectedRole) {
      case RoleType.MANAGER: {
        return {
          title: t("views.operators.admin_profile"),
          desc: t("views.operators.admin_profile_desc"),
          component: (
            <PermissionItemsForAdmin
              handlePermissionsByProduct={handlePermissionsByProduct}
              isLoading={isLoading}
            />
          ),
        };
      }
      case RoleType.OPERATOR: {
        return {
          title: t("views.operators.operator_profile"),
          desc: t("views.operators.operator_profile_desc"),
          component: (
            <PermissionItemsForOperator
              addPermissionsByGroup={handleAddPermissionsByGroup}
              deletePermissionsByGroup={handleDeletePermissionsByGroup}
              deleteEntireGroup={handleDeleteEntireGroup}
              isLoading={isLoading}
            />
          ),
        };
      }
      case RoleType.MODERATOR: {
        return {
          title: t("views.operators.moderator_profile"),
          desc: t("views.operators.moderator_profile_desc"),
          component: (
            <PermissionItemsForModerator
              handlePermissionsByProduct={handlePermissionsByProduct}
              isLoading={isLoading}
            />
          ),
        };
      }
    }
  };

  const handlePermissionsByProduct = (product: FiraPackage.FIRA_WEB) => {
    const alreadyInArray = invitation.permissions.some(
      (el) => el.firaProduct === product
    );
    if (alreadyInArray) {
      handleDeletePermissionsByProduct(product);
    } else {
      handleAddPermissionsByProduct(product);
    }
  };

  useEffect(() => {
    if (invitation.role) {
      setSelectedRople(invitation.role);
    }
  }, [invitation]);

  useEffect(() => {
    if (selectedRole) {
      setProfileTitle(getInfo()?.title || "");
      setProfileDesc(getInfo()?.desc || "");
    }
  }, [selectedRole]);

  return (
    <>
      <div className={styles.FiraPermissions}>
        <div className={styles.Title}>{profileTile}</div>
        <div className={styles.Desc}>{profileDesc}</div>
        <div className={styles.PermissionsGrid}>{getInfo()?.component}</div>
        <div className={styles.Buttons}>
          <Button
            buttonStyle={ButtonStyle.border}
            width="112px"
            height="32px"
            clickHandler={handlePrev}
            isDisabled={isLoading}
          >
            <span className={styles.Inner}>{t("views.operators.prevBtn")}</span>
          </Button>
          <Button
            buttonStyle={ButtonStyle.main}
            width="112px"
            height="32px"
            isDisabled={invitation.permissions.length === 0 || isLoading}
            clickHandler={handleCreate}
          >
            <span className={styles.Inner}>
              {t("views.operators.inviteBtn")}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FiraPermissions;
