import { format, toDate } from "date-fns";
import * as Locales from "date-fns/locale";

export const locale = () => {
  if (navigator.language.includes("es")) {
    return Locales.es;
  } else {
    return Locales.enGB;
  }
};

export const dateFormat = (date: Date) => {
  return format(date, "dd/MM/yyyy");
};

export const eventDateFormat = (date: string) => {
  return format(new Date(date), "dd/MM/yyyy");
};

export const hyphenDateFormat = (date: Date) => {
  return format(date, "dd-MM-yyyy");
};

export const hyphenDateFormatInit = (date: Date) => {
  let newDate = date.setDate(date.getDay() + 1);
  return format(newDate, "dd-MM-yyyy");
};
export const timeFormat = (date: Date) => {
  return format(date, "hh:mm a", { locale: locale() });
};

export const timeFormatTest = (date: Date) => {
  return format(date, "dd-MM-yyyy hh:mm a", { locale: locale() });
};
export const timeFormatGraph = (date: Date) => {
  return format(date, "hh:mm ");
};

export const shortDate = (date: Date) => {
  return format(date, "dd MMM", { locale: locale() });
};

export const shortYearDate = (date: Date) => {
  return format(date, "dd MMM yyyy", { locale: locale() });
};

export const completeDate = (date: Date) => {
  return format(date, "dd MMMM yyyy", { locale: locale() });
};

export const timeZoneDate = (date: Date) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};

export const convertDateUTC = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes()
  );
};

export const convertDateLocal = (date: Date) => {
  return new Date(format(date, "yyyy-MM-dd'T'HH:mm"));
};

export const inputDateFormat = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const weekDaynumber = (date: Date) => {
  return format(date, "EEEE dd", { locale: locale() });
};

export const monthAndYear = (date: Date) => {
  const extraWord = navigator.language.includes("es") ? "de" : "";
  //example:'julio de 2023'
  return format(date, ` MMMM '${extraWord}' yyyy`, { locale: locale() });
};

export const weekDayAndNumberWithComma = (date: Date) => {
  return format(date, "EEEE, dd", { locale: locale() });
};

export const weekDayNumberAndYear = (date: Date) => {
  //eg: 'Mar 14, 2019'
  return format(date, "MMM dd, yyyy", { locale: locale() });
};

export const timeToLocalTimeZone = (date: Date) => {
  const timezoneOffsetInMinutes = date.getTimezoneOffset();
  const localTime = new Date(date.getTime() - timezoneOffsetInMinutes * 60000);

  return format(localTime, "hh:mm a", { locale: locale() });
};

export const formatIsoLocal = (date: Date) => {
  const timezoneOffsetInMinutes = date.getTimezoneOffset();
  const localTime = new Date(date.getTime() - timezoneOffsetInMinutes * 60000);
  return format(localTime, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};

export const formatIsoTimeZone = (date: Date) => {
  const minutesDiff = date.getTimezoneOffset();

  if (minutesDiff > 0) {
    return new Date(date.getTime() + minutesDiff * 60000).toISOString();
  } else {
    return new Date(date.getTime() - minutesDiff * 60000).toISOString();
  }
};
