import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
} from "recharts";
import Skeleton from "../../components/Skeleton/Skeleton";
import styles from "./StoresStatsPercentageWidget.module.scss";
import toMaterialStyle from "material-color-hash";
import { EmptyPieChartWidget } from "../../components/FiraIcons";
import { StatsDataType } from "../../utils/types";

interface StoresStatsProps {
  title: string;
  subTitle: string;
  isLoading: boolean;
  dataLive?: StatsDataType[];
  dataClicks?: StatsDataType[];
  dataAudience?: StatsDataType[];
}

const StoresStatsPercentageWidget = ({
  title,
  subTitle,
  isLoading = false,
  dataLive,
  dataClicks,
  dataAudience,
}: StoresStatsProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [arrayLive, setArrayLive] = useState<StatsDataType[]>(
    dataLive ? dataLive : []
  );
  const [arrayClicks, setArrayClicks] = useState<StatsDataType[]>(
    dataClicks ? dataClicks : []
  );
  const [arrayAudience, setArrayAudience] = useState<StatsDataType[]>(
    dataAudience ? dataAudience : []
  );

  const [alldataEmpty, setAlldataEmpty] = useState(true);

  const CustomLegend = () => {
    if (arrayLive !== undefined) {
      return (
        <div className={styles.CustomLegend} ref={ref}>
          <label className={styles.TitleLegend}>
            {t("views.videos.labels.yourStores")}
          </label>
          <div className={styles.LegendItem}>
            {arrayLive.map((item: StatsDataType, index: number) => (
              <div className={styles.LegendLabel} key={index}>
                <div
                  style={{ backgroundColor: `${item.color}` }}
                  className={styles.Icon}
                ></div>
                <label className={styles.LegendText}>{item.name}</label>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  const verifyInteractions = (dataArray: StatsDataType[]) => {
    let itsEmpty = true;
    dataArray.map((obj) => {
      if (obj.value !== 0) {
        itsEmpty = false;
      }
    });
    return itsEmpty;
  };

  useEffect(() => {
    if (dataLive?.length) {
      setArrayLive(dataLive);
      setAlldataEmpty(verifyInteractions(dataLive));
    }
  }, [dataLive]);

  useEffect(() => {
    if (dataAudience?.length) {
      setArrayAudience(dataAudience);
      setAlldataEmpty(verifyInteractions(dataAudience));
    }
  }, [dataAudience]);

  useEffect(() => {
    if (dataClicks?.length) {
      setArrayLive(dataClicks);
      setAlldataEmpty(verifyInteractions(dataClicks));
    }
  }, [dataClicks]);

  return (
    <div className={styles.storeStatsContainer}>
      {!isLoading && (
        <div className={styles.header}>
          <label className={styles.headerTitle}>{title}</label>
          <p className={styles.smallText}>{subTitle}</p>
        </div>
      )}
      {isLoading && (
        <div className={styles.skeleton}>
          <>
            <Skeleton width={"141px"} height={"22px"} />
            <Skeleton width={"98px"} height={"22px"} />
          </>
        </div>
      )}

      {alldataEmpty ? (
        <div className={styles.emptyElements}>
          <EmptyPieChartWidget height={"70%"} />
        </div>
      ) : (
        !isLoading && (
          <div className={styles.elementsGroup}>
            <div className={styles.chartContainer}>
              <div className={styles.itemContent}>
                <div className={styles.itemPie}>
                  <PieChartWidget title="Lives" data={arrayLive} />
                </div>
              </div>
              <div className={styles.itemContent}>
                <div className={styles.itemPie}>
                  <PieChartWidget title="Clicks" data={arrayClicks} />
                </div>
              </div>
              <div className={styles.itemContent}>
                <div className={styles.itemPie}>
                  <PieChartWidget title="Audience" data={arrayAudience} />
                </div>
              </div>
            </div>
            {CustomLegend()}
          </div>
        )
      )}
    </div>
  );
};

interface PieChartProps {
  title: string;
  data: StatsDataType[];
}
const PieChartWidget = ({ title, data }: PieChartProps): JSX.Element => {
  const [statePie, setStatePie] = useState({
    activeIndex: 0,
  });

  const pieChartDefaultSize = {
    width: "100%",
    height: "100%",
    minHeight: "300px",
    maxHeight: "400px",
    maxWidth: "400px",
  };
  const CustomTooltip = ({ active, payload: [data] }: any) => {
    if (active && data) {
      const { value, name } = data;
      return (
        <div className={styles.CustomTooltip}>
          <p className={styles.textValue}>{`${value} %`}</p>
          <p className={styles.textLabel}>{` ${name} `}</p>
        </div>
      );
    }

    return null;
  };

  const renderActiveShape = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;
    const style = {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
    };
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" style={style}>
          {title}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius}
          outerRadius={outerRadius + 12}
          fill={fill}
          opacity={0.5}
        />
      </g>
    );
  };

  const onPieEnter = (_: any, index: number) => {
    setStatePie({
      activeIndex: index,
    });
  };

  return (
    <div style={pieChartDefaultSize}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={500}>
          alo
          <Pie
            activeIndex={statePie.activeIndex}
            activeShape={renderActiveShape}
            dataKey="value"
            data={data}
            fill="#8884d8"
            innerRadius={`50%`}
            paddingAngle={data.length === 1 ? 0 : 2}
            cx="50%"
            cy="50%"
            blendStroke={data.length === 1}
            onMouseEnter={onPieEnter}
          >
            <Tooltip />
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip content={CustomTooltip} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
export default StoresStatsPercentageWidget;
