import * as React from "react";

const List = ({
  title,
  titleId,
  ...props
}) => (
<svg 
  width="1.5em"
  height="1em" 
  viewBox="0 0 11 7" 
  fill="none" 
  xmlns="http://www.w3.org/2000/svg">
  <rect width="1.82414" height="1.80556" fill="currentColor"/>
  <rect y="2.16699" width="1.82414" height="1.80556" fill="currentColor"/>
  <rect y="4.33301" width="1.82414" height="1.80556" fill="currentColor"/>
  <line x1="2.18848" y1="0.833496" x2="10.5795" y2="0.833496" stroke="currentColor" strokeWidth="0.5"/>
  <line x1="2.18848" y1="3.00049" x2="10.5795" y2="3.00049" stroke="currentColor" strokeWidth="0.5"/>
  <line x1="2.18848" y1="5.1665" x2="10.5795" y2="5.1665" stroke="currentColor" strokeWidth="0.5"/>
</svg>
);

export default List;