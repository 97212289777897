import { t } from "i18next";
import {
  InfiniteTable,
  orderingType,
  Table,
  TableButton,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Table/Table";
import Button from "../../components/Button/Button";
import Skeleton from "../../components/Skeleton/Skeleton";
import { FiraCollection, RootState, VideoFilter } from "../../utils/types.d";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCollectionsActionCreator,
  resetCollectionListActionCreator,
  setCurrentCollectionActionCreator,
} from "../../store/Collection/CollectionActions";
import { dateFormat } from "../../utils/handleDates";
import { EmptyPieChartWidget } from "../../components/FiraIcons";
import styles from "./CollectionsView.module.scss";
import { useNavigate } from "react-router-dom";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";

const CollectionsView = () => {
  const { brand, collection } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState<string>("");
  const [listIsLoading, setListIsLoading] = useState<boolean>(true);
  const [firstFetch, setFirstFetch] = useState<boolean>(true);
  const orderingList = [
    {
      id: 1,
      name: t("views.videos.filterOptions.recent"),
      value: VideoFilter.newer,
    },
    {
      id: 2,
      name: t("views.videos.filterOptions.older"),
      value: VideoFilter.older,
    },
    {
      id: 3,
      name: t("views.videos.filterOptions.alphabet"),
      value: VideoFilter.ascend,
    },
    {
      id: 4,
      name: t("views.videos.filterOptions.reverse"),
      value: VideoFilter.descend,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState<orderingType>(
    orderingList[0]
  );
  const [collectionList, setCollectionList] = useState<FiraCollection[]>([]);
  const [selectedCollections, setSelectedCollections] = useState<
    FiraCollection[]
  >([]);

  //FUNCTIONS
  const getCollections = (
    limit: number,
    offset: number,
    filter: VideoFilter
  ) => {
    if (brand.currentStore) {
      dispatch(
        getCollectionsActionCreator(
          brand.currentStore.id,
          limit,
          offset,
          filter
        )
      );
    }
  };

  const formatDate = (value: string) => {
    const date = new Date(value);

    return dateFormat(date);
  };

  const handleOrdering = (id: number) => {
    let selected = orderingList.filter((p) => {
      return p.id === id;
    });

    setSelectedFilter(selected[0]);
  };

  const handleCollectionFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
    if (keyword !== "") {
      setCollectionList(
        collection.list.filter((col) => {
          return col.collectionName
            .toLowerCase()
            .includes(keyword.toLowerCase());
        })
      );
    } else {
      setCollectionList(collection.list);
    }
  };

  const handleCollectionCheckbox = (collection: FiraCollection) => {
    if (selectedCollections.includes(collection)) {
      setSelectedCollections((collectionList) => [
        ...collectionList.filter((c) => collection.id !== c.id),
      ]);
      return;
    }

    setSelectedCollections((collectionList) => [...collectionList, collection]);
  };

  const handleHeaderCheckBox = () => {
    if (collectionList.length === 0) {
      return;
    }

    if (selectedCollections.length === collectionList.length) {
      setSelectedCollections([]);
    } else {
      setSelectedCollections(collectionList);
    }
  };

  const loadMoreData = () => {
    getCollections(
      20,
      collectionList.length + 1,
      selectedFilter.value as VideoFilter
    );
  };

  const handleCollectionClick = (collection: FiraCollection) => {
    dispatch(setCurrentCollectionActionCreator(collection));
    navigate(`edit/${collection.id}`);
  };

  const handleNewCollectionClick = () => {
    navigate("new");
  };

  useEffect(() => {
    setListIsLoading(true);
    dispatch(resetCollectionListActionCreator());
    setCollectionList([]);
    getCollections(20, 0, selectedFilter.value as VideoFilter);
    setFirstFetch(false);
  }, [selectedFilter]);

  useEffect(() => {
    setCollectionList(collection.list);
    if (!collection.isLoading && !firstFetch) {
      setListIsLoading(false);
    }
  }, [collection]);

  //TABLE CONSTANTS

  const headerRows = [
    {
      name: "",
      size: "52.5px",
    },
    {
      name: t("views.collections.table.name"),
      size: "180px",
    },
    {
      name: t("views.collections.table.date"),
      size: "100px",
    },
    {
      name: t("views.collections.table.videos"),
      size: "100px",
    },
    {
      name: t("views.collections.table.id"),
      size: "150px",
    },
    {
      name: "",
      size: "49px",
    },
  ];

  const header = (
    <TableRow>
      {headerRows.map((item, i) => {
        return (
          <TableHead key={i} size={item.size} centerContent={i !== 1}>
            {item.name}
          </TableHead>
        );
      })}
    </TableRow>
  );

  const placeholder = [
    { id: 1, name: "placeholder" },
    { id: 2, name: "placeholder" },
    { id: 3, name: "placeholder" },
    { id: 4, name: "placeholder" },
    { id: 5, name: "placeholder" },
  ];

  const SkeletonTableBody = placeholder.map((data, i) => {
    return (
      <TableRow key={i}>
        <TableCell size="52px">{/* <Checkbox /> */}</TableCell>
        <TableCell size="180px">
          <div>
            <Skeleton width="180px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="120px">
          <div className="centerSkeleton">
            <Skeleton width="120px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="100px">
          <div className="centerSkeleton">
            <Skeleton width="100px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="150px">
          <div className="centerSkeleton">
            <Skeleton width="140px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="49px">
          <TableButton />
        </TableCell>
      </TableRow>
    );
  });

  const NoCollectionsView = () => {
    return (
      <div className={styles.emptyWrapper}>
        <EmptyPieChartWidget />
      </div>
    );
  };

  const body = (
    <>
      {collectionList.map((collection, i) => {
        return (
          <TableRow key={i}>
            <TableCell size="52px">
              {/* <Checkbox
                clickHandler={() => {
                  handleCollectionCheckbox(collection);
                }}
                isActive={selectedCollections.includes(collection)}
              /> */}
            </TableCell>
            <TableCell size="180px">
              <p className={`body-14-text ${styles.ellipsis}`}>
                {collection.collectionName}
              </p>
            </TableCell>
            <TableCell size="120px" centerContent>
              <div>
                <p className={`body-14-text`}>
                  {formatDate(collection.creationDate)}
                </p>
              </div>
            </TableCell>
            <TableCell size="100px" centerContent>
              <div>
                <p className={`body-14-text`}>{collection.videos.length}</p>
              </div>
            </TableCell>
            <TableCell size="180px" centerContent>
              <p className={`body-14-text ${styles.ellipsis}`}>
                {collection.id}
              </p>
            </TableCell>
            <TableCell size="49px">
              <TableButton onClick={() => handleCollectionClick(collection)} />
            </TableCell>
          </TableRow>
        );
      })}
      {collection.isLoading && <>{SkeletonTableBody}</>}
    </>
  );

  return (
    <>
      <FiraNavBar
        titleSection={t("views.collections.titlePage")}
        trailingOptions={
          <Button clickHandler={() => handleNewCollectionClick()}>
            <span className={`lead-text`}>
              {t("views.collections.createCollection")}
            </span>
          </Button>
        }
      />

      <TableHeader
        // checkboxHandle={handleHeaderCheckBox}
        // checkboxIsActive={
        //   selectedCollections.length === collectionList.length &&
        //   collectionList.length > 0
        // }
        hideCheckBox={true} //TEMP
        orderingList={orderingList}
        orderingValue={selectedFilter}
        orderingClick={handleOrdering}
        searchInputValue={filterValue}
        searchInputChangeHandle={handleCollectionFiltering}
      />
      <div className={styles.infiniteTableWrapper}>
        {listIsLoading && <Table header={header} body={SkeletonTableBody} />}
        {!listIsLoading && collectionList.length > 0 ? (
          <>
            <InfiniteTable
              header={header}
              body={body}
              rowsNumber={collectionList.length}
              loadData={() => loadMoreData()}
              hasMore={collectionList.length >= 20 && !collection.error}
            />
          </>
        ) : !listIsLoading && collectionList.length === 0 ? (
          <NoCollectionsView />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default CollectionsView;
