import { ChangeEvent } from "react";
import { ArrowDown } from "../FiraIcons";

import styles from "./Select.module.scss";

interface Props {
  value?: string;
  onChange?(event: ChangeEvent<HTMLSelectElement>): void;
  name: string;
  height?: number;
  width?: number;
  data: string[];
  isDisabled?: boolean;
  style?: object;
}
/**
 * Description of Card
 * @prop {function} onChange - handler for the action of the select
 * @prop {string[]} data - item list
 * @prop {String} name - indicates card status success|warning|danger
 * @prop {number} height - indicates the height of the element
 * @prop {number} width - indicates the width of the element
 * @prop {object}  - indicates the width of the element
 */
const Select = (props: Props) => {
  const {
    width = "150",
    height = "30px",
    onChange,
    isDisabled = false,
    style,
  } = props;
  const size = {
    width,
    height,
  };
  // Chrome 1 - 71

  return (
    <div className={styles.Select} style={style != undefined ? style : size}>
      <select
        name={props.name}
        value={props.value}
        onChange={onChange}
        disabled={isDisabled}
      >
        <option disabled value=""></option>
        {props.data.map((item, i) => {
          return (
            <option value={item} key={i}>
              {item}
            </option>
          );
        })}
      </select>
      <div className={styles.ArrowIcon}>
        <ArrowDown />
      </div>
    </div>
  );
};

export default Select;
