import { useState, useEffect } from 'react';
import GridOfFour from './WidgetIcons/GridOfFour';
import GridOfThree from './WidgetIcons/GridOfThree';
import GridOfTwo from './WidgetIcons/GridOfTwo';
import List from './WidgetIcons/List';
import Play from './WidgetIcons/Play';
import Square from './WidgetIcons/Square';
import Filter from './WidgetIcons/Filter';
import FiraFilters from './FiraFilters/FiraFilters';
import './VodGallery.scss'

// import { getVideoCollectionsById, playFiraModal, firaLiveWidget} from 'firalibrary'

const collectionsSample={
  "id": "1",
  "videos": [
    {
      "id": "1",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "6359b3174684a113f0508d80",
          "description": "Alimentos"
        },
      ],
      "eventPreviewImage": null,
    },
    {
      "id": "2",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "6359b3174684a113f0508d80",
          "description": "Alimentos"
        },
      ],
      "eventPreviewImage": null,
    },
    {
      "id": "3",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "635a80e20991eb2accd60779",
          "description": "Electrodomésticos"
        },
      ],
      "eventPreviewImage": null,
    },
    {
      "id": "4",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "635a810f0991eb2accd6077a",
          "description": "Entretenimiento"
        },
      ],
      "eventPreviewImage": null,
    },
    {
      "id": "5",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "635a810f0991eb2accd6077a",
          "description": "Entretenimiento"
        },
      ],
      "eventPreviewImage": null,
    },
    {
      "id": "6",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "635a810f0991eb2accd6077a",
          "description": "Entretenimiento"
        },
      ],
      "eventPreviewImage": null,
    },
    {
      "id": "7",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "635a810f0991eb2accd6077a",
          "description": "Entretenimiento"
        },
      ],
      "eventPreviewImage": null,
    },
    {
      "id": "8",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "6359b5eff6239b4c4e9a1bf4",
          "description": "Deportes"
        },
      ],
      "eventPreviewImage": null,
    },    {
      "id": "9",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "635a81650991eb2accd6077d",
          "description": "Hogar"
        },
      ],
      "eventPreviewImage": null,
    },    {
      "id": "10",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "6359b5d4f6239b4c4e9a1bf3",
          "description": "Infantil"
        },
      ],
      "eventPreviewImage": null,
    },    {
      "id": "11",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "635a81340991eb2accd6077c",
          "description": "Joyería"
        },
      ],
      "eventPreviewImage": null,
    },    {
      "id": "12",
      "categories": [
        {
          "id": "635a80cc0991eb2accd60778",
          "description": "Belleza y bienestar"
        },
        {
          "id": "635a809c0991eb2accd60777",
          "description": "Mascotas"
        },
      ],
      "eventPreviewImage": null,
    },
  ],
  
}

const orderingType= {
  GRIDOFONE:'GRIDOFONE',
  GRIDOFTWO:'GRIDOFTWO',
  GRIDOFTHREE:'GRIDOFTHREE',
  GRIDOFFOUR:'GRIDOFFOUR',
  LIST:'LIST'
}

const VodGallery = ( {
  // firaKey,
  // collectionId,
  videoSort,
  videoFilter,
  primaryTextColor,
  secondaryTextColor,
  tertiaryTextColor,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  quaternaryColor,
  quinaryColor,
  backgroundColor,
  isMobileAttr=false,
  // env
} )=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const collectionIdparam = urlParams.get('fira-collection-id')
    const mediaWidth = 790;
    const [selectedFilter,setSelectedFilter]= useState();
    const [categoriesToShow, setCategoriesToShow]=useState(8);
    const [categoriesList, setCategoriesList]=useState([])
    const [videoList, setVideoList]= useState([])
    const [activeOrdering, setActiveOrdering] = useState(orderingType.GRIDOFTHREE)
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [isMobile, setIsMobile]= useState(isMobileAttr)
    const [showMenu, setShowMenu]= useState(false)
    const [collection, setCollection]= useState(collectionsSample)


    const handleShowMore = ()=>{
      if(categoriesToShow === 8){
        setCategoriesToShow(categoriesList.length)
      }else{
        setCategoriesToShow(8)
      }
    }
    const getWindowSize=()=> {
      const { innerWidth } = window;
      return  innerWidth;
    }
    // const handleResize=()=>{
    //   window.addEventListener("resize", ()=>setWindowSize(getWindowSize()));
  
    //   return () => {
    //     window.removeEventListener("resize",()=> setWindowSize(getWindowSize()));
    //   };
    // }

    // const handleMobile = () =>{
    //   if(windowSize < mediaWidth){
    //     setIsMobile(true)
    //     setActiveOrdering(orderingType.GRIDOFTWO)
    //   }else{
    //     setIsMobile(false)
    //     setShowMenu(false)
    //     setActiveOrdering(orderingType.GRIDOFTHREE)
    //   }
    // }

    // const getCollection = async () =>{
    //   let id = collectionId ? collectionId: collectionIdparam
    //   try{
    //     const collection = await getVideoCollectionsById(firaKey,id,env)
    //     setCollection(collection)
    //   }catch(error){
    //     console.error(error)
    //   }
    // }

    const getCategories = () =>{
      let videoList = collection.videos
      let categories = []
        //recorremos lista de video
      if(videoList){
        if( videoList.length){
          videoList.forEach((video)=>{
            //si no tiene categoria, lo contamos sin categoria
            if(video.categories){

              //tomamos las categorias
              const videoCategories = video.categories
              videoCategories.forEach((category, index) => {
                //si no existe se agrega
                if (!categories[category.description]) {
                  categories[category.description] = {
                    description: category.description,
                    id: category.id,
                    videoCount:1}
                    //si existe cuenta uno mas
                } else {
                  categories[category.description].videoCount++;
                }
              })

            }
          })
        }

        //el arreglo resultante de lo anterior
        // es una lista de categorias con el nombre 
        //como index, ahora construimos dicha lista con 
        // un index que pueda entender js en el .map
        let categoriesIndex =[]
        Object.keys(categories).forEach((a)=>{
          categoriesIndex.push(categories[a])
        })

        setCategoriesList(categoriesIndex)
      }

    }

    const handlePlay =(id)=>{
      // playFiraModal(firaKey, id,env);
      return;
    }

    const handleVideoFiltering =()=>{
      let filtered =[]
      if(selectedFilter){
        filtered =collection.videos.filter(video =>{
        return video.categories && video.categories.some(category => category.description.includes(selectedFilter))
        })
        setVideoList(filtered)

      }else{
        setVideoList(collection.videos)
      }

    }

    useEffect(() => {
      // firaLiveWidget(firaKey,env);
      // handleMobile()
      // getCollection()
      // handleResize()
 
    }, []);


    // useEffect(()=>{
    //   if(windowSize){
    //     handleMobile()
    //   }
    // },[windowSize])

    useEffect(()=>{
      if(collection){
        getCategories()
        console.log("categoryList",categoriesList);
        setVideoList(collection.videos)
      }
    },[collection])

    useEffect(()=>{
      if(collection){
        handleVideoFiltering()
      }
      
    },[selectedFilter])

  return <>
  <div className={"fira-vod-gallery-wrapper"} style={{background:backgroundColor}}>
    {!isMobile &&videoFilter?
    <FiraFilters  
      categoriesList={categoriesList}
      categoriesToShow={categoriesToShow}
      handleShowMore={handleShowMore}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      primaryTextColor={primaryTextColor}
      secondaryTextColor={secondaryTextColor}
      tertiaryTextColor={tertiaryTextColor}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
    />:''}

    <div className={`fira-videos-container ${isMobile? 'fira-videos-mobile-container':''}`}>
      <div className='fira-videos-header'>
        <div className='fira-mobile-filter' onClick={()=>setShowMenu(true)}>
          {isMobile && videoFilter?
          <>
          <Filter className={'fira-filter-icon'}/>
          <div className='fira-filter-text' style={{color:primaryTextColor}}>Ver filtros ({categoriesList.length})</div>
          </>:''}
        </div>
       {videoSort &&
        <div className={`fira-sort-type-group ${isMobile?'fira-sort-mobile':''} `}
              style={{
                background:!isMobile? tertiaryColor:'',
                '--fira-sort-color':quaternaryColor,
                '--fira-sort-active':quinaryColor}}>
          {!isMobile ?
            <>
              {/* botones de filtro para version completa */}
                <div 
                className={`fira-sort-element fira-sort-web-element ${activeOrdering === orderingType.GRIDOFTHREE ? 'sort-selected':''}`}
                onClick={()=>{setActiveOrdering(orderingType.GRIDOFTHREE)}}    >
                <GridOfThree  />
              </div> 
              <div 
                className={`fira-sort-element fira-sort-web-element ${activeOrdering === orderingType.GRIDOFFOUR ? 'sort-selected':''}`}
                onClick={()=>{setActiveOrdering(orderingType.GRIDOFFOUR)}}   >
                <GridOfFour/>
              </div>  
              <div 
                className={`fira-sort-element fira-sort-web-element ${activeOrdering === orderingType.LIST ? 'sort-selected':''}`}
                onClick={()=>''}   >
                <List/>  
              </div>
            </> : 
            <>        
              {/* versiones de filtro para version mobile */}
              <div 
                className={`fira-sort-element fira-sort-mobile-element ${activeOrdering === orderingType.GRIDOFONE ? 'sort-selected':''}`}
                onClick={()=>{setActiveOrdering(orderingType.GRIDOFONE)}}   >
                <Square/>
              </div> 
              <div 
                className={`fira-sort-element fira-sort-mobile-element ${activeOrdering === orderingType.GRIDOFTWO ? 'sort-selected':''}`}
                onClick={()=>{setActiveOrdering(orderingType.GRIDOFTWO)}}   >
                <GridOfTwo/> 
              </div>   

            </> 
          }
        </div>
      }
      </div>

      
      <div className={`
            fira-videos-group 
            ${
              {
              GRIDOFONE:'fira-video-grid-1',
              GRIDOFTWO:'fira-video-grid-2',
              GRIDOFTHREE:'fira-video-grid-3', 
              GRIDOFFOUR:'fira-video-grid-4', 
              LIST:'fira-video-list'
              }[activeOrdering]
            }`}>
        {videoList &&videoList.map((video)=>{
          return(
          <div key={video.id} onClick={()=>handlePlay(video.originEvent)} >
           {activeOrdering === orderingType.LIST? 
            <div className='fira-video-list-element'>
              <div className='fira-video-image-container' >
                <img 
                className='fira-video-image'
                src={video.eventPreviewImage} 
                alt={video.videoName} />
                <div className='fira-video-play'><Play/></div>
              </div>
              <div className='fira-video-name'>
                <p className='fira-video-title'>{video.videoName}</p>
                <p className='fira-video-description'>{video.videoDescription}</p>
                <p className='fira-video-time'>Duracion {video.videoUrls[0].videoDuration}</p>
              </div>
        
            </div>
            :
            <div className='fira-video-grid-element'  >
                <img className='fira-video-image' src={video.eventPreviewImage} alt={video.videoName} />
                <div className='fira-video-play'><Play/></div>
            </div>
          }
          </div>    
          )
        })}</div>
    </div>
    {showMenu && isMobile && videoFilter?<div className='fira-filter-mobile-wrapper'>
      <div className='fira-filter-mobile-container' style={{background:backgroundColor}}>
        <FiraFilters  
          categoriesList={categoriesList}
          categoriesToShow={categoriesList.length}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          handleCloseMenu={()=>setShowMenu(false)}
          primaryTextColor={primaryTextColor}
          secondaryTextColor={secondaryTextColor}
          tertiaryTextColor={tertiaryTextColor}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      </div>
    </div>:''}
  </div>
  </>


}




export default VodGallery;