import { SyntheticEvent } from "react";

import noImageStoreSVG from "../assets/svg/no-image-store.svg";

// TODO: externalId may change in the future
export const handleImageLink = (
  storeId: string,
  externalId: string,
  imageName: string
) => {
  const imgURL = process.env.IMAGE_BASE_URL;
  return `${imgURL}/${storeId}/${externalId}/${imageName}`;
};

export const handleImageLoadError = ({
  currentTarget,
}: SyntheticEvent<HTMLImageElement, Event>) => {
  currentTarget.src = noImageStoreSVG;
  currentTarget.onerror = null;
};
