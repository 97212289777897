import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
  currentColor?: string;
  height?: string;
}

const SvgWebButtonMockup = ({
  title,
  titleId,
  currentColor,
  height,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="464"
    height="304"
    fill="none"
    viewBox="0 0 464 304"
  >
    <path
      stroke="#D5DDE0"
      d="M.5 10A9.5 9.5 0 0110 .5h444a9.5 9.5 0 019.5 9.5v284a9.5 9.5 0 01-9.5 9.5H10A9.5 9.5 0 01.5 294V10z"
    ></path>
    <rect
      width="319.16"
      height="37.29"
      x="0.5"
      y="-0.5"
      stroke="#D5DDE0"
      rx="5.5"
      transform="matrix(1 0 0 -1 17.4 272.832)"
    ></rect>
    <rect
      width="319.16"
      height="200.893"
      x="17.898"
      y="32.99"
      stroke="#D5DDE0"
      rx="5.5"
    ></rect>
    <rect
      width="28.66"
      height="33.309"
      x="0.75"
      y="-0.75"
      fill="#F7F8F9"
      stroke="#D5DDE0"
      strokeWidth="1.5"
      rx="3.25"
      transform="matrix(1 0 0 -1 26.678 75.082)"
    ></rect>
    <rect
      width="146.98"
      height="179.508"
      x="0.75"
      y="-0.75"
      stroke={currentColor}
      strokeWidth="1.5"
      rx="3.25"
      transform="matrix(1 0 0 -1 100.918 224.762)"
    ></rect>
    <rect
      width="57.66"
      height="28.668"
      x="0.75"
      y="-0.75"
      fill="#F7F8F9"
      stroke="#D5DDE0"
      strokeWidth="1.5"
      rx="5.25"
      transform="matrix(1 0 0 -1 23.2 268.852)"
    ></rect>
    <rect
      width="97.1"
      height="17.065"
      x="0.75"
      y="-0.75"
      fill={currentColor}
      fillOpacity="0.5"
      stroke={currentColor}
      strokeWidth="1.5"
      rx="3.25"
      transform="matrix(1 0 0 -1 125.279 211.996)"
    ></rect>
    <rect
      width="59.16"
      height="30.168"
      fill="#F7F8F9"
      rx="6"
      transform="matrix(1 0 0 -1 85.84 270.352)"
    ></rect>
    <rect
      width="74.24"
      height="74.26"
      fill="#F7F8F9"
      rx="6"
      transform="matrix(1 0 0 -1 139.199 135.756)"
    ></rect>
    <rect
      width="61.48"
      height="30.168"
      fill="#F7F8F9"
      rx="6"
      transform="matrix(1 0 0 -1 147.318 270.352)"
    ></rect>
    <rect
      width="59.16"
      height="30.168"
      fill="#F7F8F9"
      rx="6"
      transform="matrix(1 0 0 -1 212.279 270.352)"
    ></rect>
    <rect
      width="61.48"
      height="30.168"
      fill="#F7F8F9"
      rx="6"
      transform="matrix(1 0 0 -1 272.6 270.352)"
    ></rect>
    <rect
      width="17.4"
      height="6.962"
      fill="#D5DDE0"
      rx="3"
      transform="matrix(1 0 0 -1 423.4 82.38)"
    ></rect>
    <rect
      width="73.24"
      height="12.924"
      x="0.5"
      y="-0.5"
      stroke="#F7F8F9"
      rx="3.5"
      transform="matrix(1 0 0 -1 349.16 265.871)"
    ></rect>
    <rect
      width="12.76"
      height="13.924"
      fill="#F7F8F9"
      rx="4"
      transform="matrix(1 0 0 -1 428.041 266.871)"
    ></rect>
    <rect
      width="91.64"
      height="13.924"
      fill="#F7F8F9"
      rx="4"
      transform="matrix(1 0 0 -1 349.16 97.465)"
    ></rect>
    <rect
      width="91.64"
      height="12.763"
      fill="#F7F8F9"
      rx="4"
      transform="matrix(1 0 0 -1 349.16 216.977)"
    ></rect>
    <rect
      width="91.64"
      height="12.763"
      fill="#F7F8F9"
      rx="4"
      transform="matrix(1 0 0 -1 349.16 157.801)"
    ></rect>
    <rect
      width="91.64"
      height="12.763"
      fill="#F7F8F9"
      rx="4"
      transform="matrix(1 0 0 -1 349.16 187.969)"
    ></rect>
    <rect
      width="91.64"
      height="12.763"
      fill="#F7F8F9"
      rx="4"
      transform="matrix(1 0 0 -1 128.76 163.602)"
    ></rect>
    <rect
      width="91.64"
      height="12.763"
      fill="#F7F8F9"
      rx="4"
      transform="matrix(1 0 0 -1 128.76 181.008)"
    ></rect>
    <rect
      width="91.64"
      height="12.763"
      fill="#F7F8F9"
      rx="4"
      transform="matrix(1 0 0 -1 349.16 127.633)"
    ></rect>
    <rect
      width="91.64"
      height="13.924"
      fill="#F7F8F9"
      rx="4"
      transform="matrix(1 0 0 -1 349.16 68.459)"
    ></rect>
    <rect
      width="90.781"
      height="13.423"
      fill="#F7F8F9"
      rx="4"
      transform="matrix(1 0 0 -1 350.057 247.938)"
    ></rect>
    <rect
      width="36.313"
      height="7.106"
      fill="#D5DDE0"
      rx="3"
      transform="matrix(1 0 0 -1 350.057 232.936)"
    ></rect>
    <rect
      width="18.156"
      height="7.106"
      fill="#D5DDE0"
      rx="3"
      transform="matrix(1 0 0 -1 422.682 232.936)"
    ></rect>
    <rect
      width="37.12"
      height="6.962"
      fill="#D5DDE0"
      rx="3"
      transform="matrix(1 0 0 -1 349.16 82.38)"
    ></rect>
    <rect
      width="37.12"
      height="6.962"
      fill="#F7F8F9"
      rx="3"
      transform="matrix(1 0 0 -1 349.16 203.053)"
    ></rect>
    <rect
      width="37.12"
      height="6.962"
      fill="#F7F8F9"
      rx="3"
      transform="matrix(1 0 0 -1 349.16 143.877)"
    ></rect>
    <rect
      width="37.12"
      height="6.962"
      fill="#F7F8F9"
      rx="3"
      transform="matrix(1 0 0 -1 349.16 172.885)"
    ></rect>
    <rect
      width="37.12"
      height="6.962"
      fill="#F7F8F9"
      rx="3"
      transform="matrix(1 0 0 -1 349.16 112.547)"
    ></rect>
    <rect
      width="37.12"
      height="6.962"
      fill="#F7F8F9"
      rx="3"
      transform="matrix(1 0 0 -1 349.16 52.21)"
    ></rect>
    <rect
      width="105.72"
      height="242.664"
      x="341.541"
      y="30.668"
      stroke="#D5DDE0"
      rx="9.5"
    ></rect>
    <path
      fill="#5C767F"
      d="M240.12 59.176a4.641 4.641 0 11-9.282 0 4.641 4.641 0 019.282 0z"
    ></path>
    <path
      fill="#fff"
      d="M236.926 58.162a.29.29 0 00-.41-.41l-2.461 2.461a.29.29 0 00.41.41l2.461-2.46z"
    ></path>
    <path
      fill="#fff"
      d="M234.055 58.162a.29.29 0 11.41-.41l2.461 2.461a.29.29 0 01-.411.41l-2.46-2.46z"
    ></path>
  </svg>
);

export default SvgWebButtonMockup;
