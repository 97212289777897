import {
  Button,
  MainLoader,
  PopUpAlert,
  VideoInstructions,
} from "../../components";
import {
  Sign,
  EmptyChatBubble,
  FakeInput,
  CopyPaperBold,
  Download,
} from "../../components/FiraIcons";
import styles from "./EventDetailView.module.scss";
import React, { useEffect, useState, SyntheticEvent } from "react";
import FinishStream from "../../components/Modals/FinishStream/FinishStream";
import { ButtonStyle } from "../../components/Button/Button";
import EventModeration from "../../components/EventModeration/EventModeration";
import Credentials from "../../components/Modals/Credentials/Credentials";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanMessageActionCreator,
  getEventActionCreator,
  /*getEventQueuesActionCreator,*/
  resetCurrentEventActionCreator,
  generateCredentialsActionCreator,
  updateCredentialsActionCreator,
  UpdateStatsActionCreator,
  finishEventActionCreator,
} from "../../store/Event/EventActions";
import {
  FiraEvent,
  ResponseValidModerator,
  RootState,
  ResponseSummary,
  FiraUserActionsStats,
} from "../../utils/types.d";
import { useTranslation } from "react-i18next";
import StartBroadcast from "../../components/Modals/StartBroadcast/StartBroadcast";
/*import {
  connectToSocket,
  disconnectFromSocket,
  QueueResponse,
  subscribeToQueues,
} from "../../services/socket-api";*/
import Player from "../../components/Player/Player";
import { getLiveSumary, validateModerator } from "../../services/events";
import { handleImageLoadError } from "../../utils/handleImageLink";
import { downloadAllStats } from "../../services/download";
import { handleExcelFileDownload } from "../../utils/handleFileDownload";
import InfoCircle from "../../components/FiraIcons/InfoCircle";
import PopUpInfo from "../../components/PopUpInfo/PopUpInfo";
import EventStatistics from "../../components/EventStatistics/EventStatistics";
import { PopUpAlertTypes } from "../../components/PopUpAlert/PopUpAlert";
import AddStreamProducts from "../../components/Modals/AddStreamProducts/AddStreamProducts";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import { handleError } from "../../services/errorHandling";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";
import { getCounterTestVideosService } from "../../services/videos";
import { FiraEventType } from "../../utils/enums";
import { resetCurrentStoreActionCreator } from "../../store/Brand/BrandActions";
//import { useRSocket } from "../../hook/useRSocket";
//import { GeneralContext } from "../../context/GeneralContext";
import { FiraStore, StoreType } from "../../types/Stores";
import {
  ChatModes,
  ChatProvider,
} from "../../components/FiraChat/providers/ChatProvider";
import FiraChat from "../../components/FiraChat/FiraChat";
import OpenLink from "../../components/FiraIcons/OpenLink";
import { EventProvider, useEvent } from "../../providers/EventProvider";
import { WebsocketProvider } from "../../providers/WebsocketProvider";

const initCounters: FiraUserActionsStats = {
  allConnectionTime: 0,
  averageConnectionTime: 0,
  bouncingRate: 0,
  carts: 0,
  chatMessageCounting: 0,
  clickAverage: 0,
  engagementRate: 0,
  eventDuration: 0,
  likes: 0,
  webConnections: 0,
  mobileConnections: 0,
  shares: 0,
  regularClicks: 0,
  popupClicks: 0,
};

let timerID: NodeJS.Timeout;
let updateTimerId: NodeJS.Timeout;
let copyTimerID: NodeJS.Timeout;

enum EventStatus {
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
  DONE = "DONE",
}

const EventDetailView = () => {
  const { event } = useSelector((state: RootState) => state);
  const firaSrc = event.currentEvent?.id ?? "";
  const status = event.currentEvent?.status ?? EventStatus.SCHEDULED;
  return (
    <EventProvider firaSrc={firaSrc}>
      <WebsocketProvider firaSrc={firaSrc} status={status}>
        <EventDetailContent />
      </WebsocketProvider>
    </EventProvider>
  );
};

const EventDetailContent = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { event, brand, authentication, utils } = useSelector(
    (state: RootState) => state
  );
  const { loadCurrentPopups } = useEvent();
  const [credentialsModalIsOpen, setCredentialsModalIsOpen] = useState(false);
  const [finishStreamModalIsOpen, setFinishStreamModalIsOpen] = useState(false);
  const [startBroadcastModalIsOpen, setStartBroadcastModalIsOpen] =
    useState(false);
  const [addProductsModalIsOpen, setAddProductsModalIsOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const showAlert = usePopupAlert();
  const [message, setMessage] = useState("");
  const [counters, setCounter] = useState(
    event.currentEvent?.userActionsStats || initCounters
  );
  const [moderator, setModerator] = useState<ResponseValidModerator>();
  const [showChat, setShowChat] = useState(false);
  //este state cuenta las veces que se intento actualizar el userActionsStats del evento
  const [tryCounter, setTryCounter] = useState(0);
  const [elemetTarget, setElementTarget] = useState<HTMLElement>();
  const [showIdInfo, setShowIdInfo] = useState(false);
  const [processingMessage, setProcessingMessage] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const updateChances = 2;
  const [counterTest, setCounterTest] = useState<number>(0);
  const playerUrl = process.env.REACT_APP_BASE_URL_PLAYER;

  const generateCredentials = () => {
    if (event.currentEvent?.status == EventStatus.SCHEDULED) {
      dispatch(generateCredentialsActionCreator(event.currentEvent.id));
    }
  };

  const openCredentialsModal = () => {
    setCredentialsModalIsOpen(true);
  };

  const closeCredentialsModal = () => {
    setCredentialsModalIsOpen(false);
  };

  const openFinishModal = () => {
    setFinishStreamModalIsOpen(true);
  };

  const closeFinishModal = () => {
    setFinishStreamModalIsOpen(false);
  };

  const openStartModal = () => {
    setStartBroadcastModalIsOpen(true);
  };

  const closeStartModal = () => {
    setStartBroadcastModalIsOpen(false);
  };

  const openAddProductsModal = () => {
    dispatch(cleanMessageActionCreator());
    setAddProductsModalIsOpen(true);
  };

  const closeAddProductsModal = () => {
    setAddProductsModalIsOpen(false);
  };

  const handleBackButton = () => {
    dispatch(resetCurrentEventActionCreator());
    if (utils.prevPage === "Calendar") {
      authentication.sessionId &&
        dispatch(resetCurrentStoreActionCreator(authentication.sessionId));
      navigate("/home/calendar");
    } else {
      navigate("/home/events");
    }
  };

  const handleShowAlert = () => {
    setIsShowAlert(true);
    timerID = setTimeout(() => {
      setIsShowAlert(false);
      dispatch(cleanMessageActionCreator());
    }, 5000);
  };

  const handleCloseAlert = () => {
    if (isShowAlert) {
      clearTimeout(timerID);
      setIsShowAlert(false);
      dispatch(cleanMessageActionCreator());
    }
  };
  const handleShowSuccess = () => {
    setCopySuccess(true);
    copyTimerID = setTimeout(() => {
      setCopySuccess(false);
      dispatch(cleanMessageActionCreator());
    }, 2000);
  };

  const handleCloseSuccess = () => {
    if (copySuccess) {
      clearTimeout(copyTimerID);
      setCopySuccess(false);
    }
  };

  const fallbackCopyTextToClipboard = (text: string) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      let successful = document.execCommand("copy");
      let msg = successful ? "successful" : "unsuccessful";
      handleShowSuccess();
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const copyTextToClipboard = async (text: string = "") => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      handleShowSuccess();
    } catch (error) {
      console.log("ERROR COPYING TEXT", error);
    }
  };

  const handleEventInfoId = (e: SyntheticEvent<HTMLElement>) => {
    setShowIdInfo(!showIdInfo);
    setElementTarget(e.currentTarget);
  };

  const validatedModerator = async () => {
    try {
      if (event.currentEvent) {
        const response = await validateModerator(event.currentEvent?.id);
        setModerator(response.data);
      }
    } catch (e) {
      console.error(e);
      setModerator(undefined);
    }
  };

  const liveSummary = async () => {
    try {
      if (event.currentEvent) {
        const response = await getLiveSumary(event.currentEvent?.id);
        const sumary = response.data as ResponseSummary;
        setCounter((prevState) => ({
          ...prevState,
          likes: sumary.likes,
          shares: sumary.shares,
          regularClicks: sumary.regularClicks,
          popupClicks: sumary.popupClicks,
          webConnections: sumary.webConnections,
          mobileConnections: sumary.mobileConnections,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const reloadVideo = () => {
    if (event.currentEvent) {
      dispatch(updateCredentialsActionCreator(event.currentEvent.id));
    }
  };

  const callCounterTest = async () => {
    if (event.currentEvent) {
      try {
        const response = await getCounterTestVideosService(
          event.currentEvent?.id
        );
        setCounterTest(response.data);
      } catch (error) {
        const handledErr = handleError(error);
        if (handledErr.message === "El evento prueba Ha Finalizado!!!") {
          dispatch(finishEventActionCreator(event.currentEvent.id));
          showAlert(AlertType.warning, handledErr.message);
        } else {
          showAlert(AlertType.error, handledErr.message);
        }
      }
    }
  };

  const getAllStats = async (eventId: string) => {
    try {
      setShowLoader(true);
      const response = await downloadAllStats(eventId);
      handleExcelFileDownload(response.data, "FiraAllStatsData.xls");
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      handleShowAlert();
      setMessage(t("views.events.eventDetail.messages.cant_generate_stats"));
      console.error(error);
    }
  };

  const updateCounters = () => {
    setProcessingMessage(true);
    updateTimerId = setTimeout(() => {
      event.currentEvent &&
        dispatch(UpdateStatsActionCreator(event.currentEvent.id));
    }, 6000);
  };

  const redirectToPlayer = (eventId: string) => {
    if (eventId) {
      window.open(
        `${playerUrl}?firakey=${brand.currentStore?.id}&firasrc=${eventId}`,
        "_blank",
        "noreferrer"
      );
    }
  };

  const renderChat = () => {
    if (showChat && moderator && event.currentEvent && brand.currentStore) {
      if (
        !event.currentEvent.isGlobal &&
        brand.currentStore.storeType === "MAIN_STORE"
      ) {
        return <></>;
      }

      return (
        event.currentEvent.status !== EventStatus.SCHEDULED &&
        event.currentEvent.status !== EventStatus.NOT_STARTED &&
        event.currentEvent?.status !== EventStatus.DONE && (
          <FiraChat
            storeName={brand.currentStore.name}
            moderatorConfig={{
              token: moderator?.token,
              id: moderator?.id,
              moderator: moderator?.moderator,
            }}
          />
        )
      );
    }
  };

  useEffect(() => {
    if (event.currentEvent) {
      dispatch(getEventActionCreator(event.currentEvent.id));
      // TODD: check for this logic later
      if (event.currentEvent.status == EventStatus.STARTED) {
        validatedModerator();
        if (event.currentEvent.eventType === FiraEventType.test) {
          callCounterTest();
        }
      }
    }
    //resetContext();
  }, []);

  useEffect(() => {
    if (event.currentEvent) {
      if (event.isLoading) {
        setShowLoader(true);
      } else {
        setShowLoader(false);
        if (event.credentialsSuccess) {
          setCredentialsModalIsOpen(true);
        }
        if (event.error && !credentialsModalIsOpen) {
          handleShowAlert();
          switch (event.error.message) {
            case "INVALID ID":
              setMessage(
                t("views.events.eventDetail.messages.invalid_id", {
                  streamId: event.currentEvent.id,
                })
              );
              break;
            case "Can not stop a broadcast with status different than started":
              setMessage(t("views.events.eventDetail.messages.not_started"));
              break;
            case "No value present":
              setMessage(t("views.events.eventDetail.messages.no_value"));
              break;
            case "There is another event STARTED":
              setMessage(t("views.events.eventDetail.messages.other_started"));
              break;
            default:
              setMessage(event.error.message);
              console.error(event.error);
              break;
          }
        }
      }
    }
  }, [event.currentEvent, event.isLoading, event.error]);

  //para que actualice el state de los contadores cuando userActionStats tenga algun cambio
  //la primera vez que se dispara el UpdateStatsActionCreator es en el modal de finish broadcast
  //ya que ahi es donde esta el boton de finalizar, y es el unico sitio donde necesita usarse
  //de esta forma se evita que cada vez que entremos a la vista y este DONE se ejecute esa accion
  useEffect(() => {
    clearTimeout(updateTimerId);
    setProcessingMessage(false);
    if (event.currentEvent?.userActionsStats) {
      setCounter(event.currentEvent.userActionsStats);
      //si ambos esta en 0 significa que aun no se calcularon, asi que se vuelve a intentar
      if (counters.bouncingRate != 0 && counters.eventDuration != 0) {
        if (tryCounter < updateChances) {
          setTryCounter(tryCounter + 1);
          updateCounters();
        }
      }
    }
  }, [event.currentEvent?.userActionsStats]);

  useEffect(() => {
    if (event.currentEvent) {
      switch (event.currentEvent.status) {
        case EventStatus.SCHEDULED:
          break;
        case EventStatus.NOT_STARTED:
          dispatch(updateCredentialsActionCreator(event.currentEvent.id));
          setShowChat(true);
          break;
        case EventStatus.STARTED:
          dispatch(updateCredentialsActionCreator(event.currentEvent.id));
          validatedModerator();
          if (event.currentEvent.eventType === FiraEventType.test) {
            callCounterTest();
          }
          liveSummary();
          loadCurrentPopups();
          setShowChat(true);
          break;
        case EventStatus.DONE:
          setShowChat(true);
          break;
        default:
          break;
      }
    }
  }, [event.currentEvent?.status]);

  if (
    event.currentEvent?.status == EventStatus.DONE &&
    params.view == "moderation"
  ) {
    navigate("/home/events/event-detail/stats");
  }

  let optionsBtn = (
    <div className={`${styles.eventButtonsContainer}`}>
      <div className={styles.rightButtonsWrapper}>
        {event.currentEvent?.status === EventStatus.STARTED ? (
          <Button
            width="122px"
            height="31px"
            clickHandler={() =>
              event.currentEvent && redirectToPlayer(event.currentEvent.id)
            }
            isDisabled={showLoader}
            buttonStyle={ButtonStyle.transparent}
          >
            <div className={styles.previewButton}>
              <p className="alert-text-14">{t("views.videos.previewLink")}</p>
              <OpenLink />
            </div>
          </Button>
        ) : (
          <div className={styles.idWrapper}>
            {showIdInfo && (
              <PopUpInfo
                title={t("views.events.eventDetail.infoId")}
                elementRef={elemetTarget}
              />
            )}

            <span
              onMouseEnter={handleEventInfoId}
              onMouseLeave={handleEventInfoId}
            >
              <InfoCircle className={styles.infoIcon} />
            </span>
            <span>FIRA SRC </span>
            <span className={`${styles.idText} ${styles.ellipsis}`}>
              {event.currentEvent && event.currentEvent.id}
            </span>
            <CopyPaperBold
              onClick={() =>
                copyTextToClipboard(event.currentEvent && event.currentEvent.id)
              }
              className={styles.copyIcon}
            />
          </div>
        )}

        {/* boton de credenciales */}
        {!authentication.isAccountOwner &&
        (event.currentEvent?.parentId !== null || event.currentEvent?.isParent)
          ? ""
          : event.currentEvent?.status !== EventStatus.DONE && (
              <Button
                width="122px"
                height="31px"
                buttonStyle={ButtonStyle.light}
                clickHandler={openCredentialsModal}
              >
                <p className="lead-text">
                  {t("views.events.eventDetail.credentials")}
                </p>
              </Button>
            )}

        {/* boton de finalizar evento */}
        {!authentication.isAccountOwner &&
        (event.currentEvent?.parentId !== null || event.currentEvent?.isParent)
          ? ""
          : event.currentEvent?.status === EventStatus.STARTED && (
              <Button
                width="122px"
                height="31px"
                buttonStyle={ButtonStyle.danger}
                clickHandler={openFinishModal}
              >
                <p className="lead-text">
                  {event.currentEvent.eventType === FiraEventType.broadcast
                    ? t("views.events.eventDetail.finish")
                    : t("views.events.eventDetail.finishTest")}
                </p>
              </Button>
            )}

        {/* boton de iniciar evento */}
        {!authentication.isAccountOwner &&
        (event.currentEvent?.parentId !== null || event.currentEvent?.isParent)
          ? ""
          : event.currentEvent?.status === EventStatus.NOT_STARTED && (
              <Button
                width="122px"
                height="31px"
                buttonStyle={ButtonStyle.main}
                clickHandler={openStartModal}
              >
                <p className="lead-text">
                  {event.currentEvent.eventType === FiraEventType.broadcast
                    ? t("views.events.eventDetail.start")
                    : t("views.events.eventDetail.startTest")}
                </p>
              </Button>
            )}

        {!event.isLoading && processingMessage && (
          <span className={styles.loader}></span>
        )}
        {/*exportar datos cuando finaliza el evento */}
        {event.currentEvent?.status === EventStatus.DONE &&
        params.view === "stats" &&
        event.currentEvent.eventType === FiraEventType.broadcast ? (
          <Button
            width="122px"
            height="31px"
            clickHandler={() =>
              event.currentEvent && getAllStats(event.currentEvent.id)
            }
            isDisabled={
              showLoader ||
              processingMessage ||
              (counters.bouncingRate == 0 && counters.eventDuration == 0)
            }
            buttonStyle={ButtonStyle.main}
          >
            <div className={styles.exportButton}>
              <Download />
              <p className="alert-text-14">
                {processingMessage
                  ? t("views.events.eventDetail.processing")
                  : t("views.events.eventDetail.exportData")}
              </p>
            </div>
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );

  return (
    <ChatProvider
      config={{
        mainColor: event.currentEvent?.firaWebConfiguration
          ? event.currentEvent?.firaWebConfiguration.mainColor
          : "",
        enableLogin: event.currentEvent?.firaWebConfiguration
          ? event.currentEvent?.firaWebConfiguration.enableLogin
          : false,
        loginForm: event.currentEvent?.firaWebConfiguration
          ? event.currentEvent?.firaWebConfiguration.loginForm
          : [],
        disclaimerUrl:
          event.currentEvent?.firaWebConfiguration &&
          event.currentEvent.firaWebConfiguration.disclaimerUrl
            ? event.currentEvent?.firaWebConfiguration.disclaimerUrl
            : "",
      }}
      mode={ChatModes.ADMIN}
      src={event.currentEvent?.id || ""}
      eventStatus={event.currentEvent?.status || ""}
      eventStartDate={event.currentEvent?.startDate || ""}
      isGlobal={event.currentEvent?.isGlobal}
      parentId={
        event.currentEvent?.parentId
          ? event.currentEvent.parentId
          : event.currentEvent?.id
      }
    >
      <>
        {showLoader && <MainLoader />}
        <FiraNavBar
          titleSection={event.currentEvent?.eventName}
          backButtonHandler={handleBackButton}
          trailingOptions={optionsBtn}
        />

        {isShowAlert &&
        !addProductsModalIsOpen &&
        !finishStreamModalIsOpen &&
        !credentialsModalIsOpen &&
        !startBroadcastModalIsOpen ? (
          <PopUpAlert
            width={"100%"}
            type={PopUpAlertTypes.error}
            title={message}
            handleCloseIcon={handleCloseAlert}
          />
        ) : (
          ""
        )}

        {copySuccess && (
          <PopUpAlert
            width={"100%"}
            type={PopUpAlertTypes.success}
            title={t("views.events.eventDetail.messages.copy_success")}
            handleCloseIcon={handleCloseSuccess}
          />
        )}

        <div className={styles.contentWrapper}>
          {/* iframe */}
          <div
            className={`${styles.iframeContainer} ${
              event.currentEvent?.status == EventStatus.SCHEDULED
                ? styles.scheduledVideo
                : ""
            }
          ${!showChat ? styles.HideChat : ""} ${
              event.currentEvent &&
              !event.currentEvent.isGlobal &&
              brand.currentStore &&
              brand.currentStore.storeType === "MAIN_STORE"
                ? styles.HideChat
                : ""
            }`}
          >
            {event.currentEvent?.status !== EventStatus.SCHEDULED &&
              event.currentEvent?.suscriberCredentialsDto && (
                <Player
                  hideChat={showChat}
                  setHideChat={setShowChat}
                  counterTest={counterTest}
                  suscriberCredentialsDto={
                    event.currentEvent.suscriberCredentialsDto
                  }
                  userId={moderator?.id}
                />
              )}
            {event.currentEvent &&
              event.currentEvent?.status !== EventStatus.SCHEDULED &&
              !event.currentEvent?.suscriberCredentialsDto && (
                <SkeletonVideo
                  event={event.currentEvent}
                  store={brand.currentStore!}
                  reloadCallback={reloadVideo}
                />
              )}

            {renderChat()}
            {showChat &&
              event.currentEvent &&
              event.currentEvent.status === EventStatus.NOT_STARTED && (
                <FakeChat />
              )}
            {event.currentEvent?.status === EventStatus.SCHEDULED && (
              <VideoInstructions
                startDate={event.currentEvent.scheduledDate}
                storeLogo={brand.currentStore?.brand?.logo}
                eventColor={
                  event.currentEvent.firaWebConfiguration
                    ? event.currentEvent.firaWebConfiguration.mainColor
                    : ""
                }
              />
            )}
          </div>

          {params.view &&
            {
              moderation: (
                <EventModeration openAddProductsModal={openAddProductsModal} />
              ),
              stats: <EventStatistics counters={counters} />,
            }[params.view]}
        </div>
        {finishStreamModalIsOpen && (
          <FinishStream
            isOpen={finishStreamModalIsOpen}
            onClose={closeFinishModal}
            updateCount={updateCounters}
          />
        )}
        {startBroadcastModalIsOpen && (
          <StartBroadcast
            isOpen={startBroadcastModalIsOpen}
            onClose={closeStartModal}
          />
        )}
        {credentialsModalIsOpen && (
          <Credentials
            isOpen={credentialsModalIsOpen}
            onClose={closeCredentialsModal}
            generateCredentials={generateCredentials}
          />
        )}
        {addProductsModalIsOpen && (
          <AddStreamProducts
            isOpen={addProductsModalIsOpen}
            onClose={closeAddProductsModal}
          />
        )}
      </>
    </ChatProvider>
  );
};

const FakeChat = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.chatPlaceholder}>
      <div className={styles.message}>
        <EmptyChatBubble className={styles.bubble} />
        <p className={styles.title}>
          {t("views.events.eventDetail.fakeChat.title")}
        </p>
        <p className={styles.sub}>
          {t("views.events.eventDetail.fakeChat.subTitle")}
        </p>
      </div>
      <div className={styles.input}>
        <FakeInput />
      </div>
    </div>
  );
};

interface SkeletonVideoProps {
  event: FiraEvent;
  store: FiraStore;

  reloadCallback(): void;
}

const SkeletonVideo = ({ event, reloadCallback }: SkeletonVideoProps) => {
  const { t } = useTranslation();
  const generateTextColor = (bgColor: string) => {
    // Función para determinar el color de texto basado en el color de fondo
    // Puedes ajustar estos valores dependiendo de tus necesidades
    const umbralColor = 130;
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);
    const luminosidad = 0.299 * r + 0.587 * g + 0.114 * b;

    return luminosidad > umbralColor ? "#000000" : "#ffffff";
  };
  return (
    <div className={styles.SkeletonVideo}>
      <div className={styles.SkeletonWrapper}>
        <div className={styles.SkeletonHeader}>
          <img
            className={styles.StoreAvatar}
            src={""}
            onError={handleImageLoadError}
            alt={""}
          />
          <p className={`blockquote-text ${styles.breakName}`}>
            {event.status !== EventStatus.DONE
              ? event.eventName
              : t("views.events.eventDetail.skeleton.finished")}
          </p>
          <Sign
            className={styles.SignIcon}
            style={{
              color: event.firaWebConfiguration
                ? event.firaWebConfiguration.mainColor
                : "#000",
            }}
          />
        </div>
        {event.status !== EventStatus.DONE && (
          <div className={styles.SkeletonBody}>
            <p className={styles["body-14-text"]}>
              {t("views.events.eventDetail.skeleton.message")}
            </p>
            <button
              type="button"
              onClick={reloadCallback}
              style={{
                backgroundColor: event.firaWebConfiguration
                  ? event.firaWebConfiguration.mainColor
                  : "#000",
                color: event.firaWebConfiguration
                  ? generateTextColor(event.firaWebConfiguration.mainColor)
                  : generateTextColor("#000"),
              }}
              className={styles.RefreshButton}
            >
              <p className={`${styles["blockquote-text"]} ${styles.breakName}`}>
                {t("views.events.eventDetail.skeleton.refresh")}
              </p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventDetailView;
