import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCross = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M23 8.611 21.389 7 15 13.389 8.611 7 7 8.611 13.389 15 7 21.389 8.611 23 15 16.611 21.389 23 23 21.389 16.611 15 23 8.611Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCross;
