import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ViewHeader } from "../../components";
import { Computer, Smartphone } from "../../components/FiraIcons";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import InfoCard from "../../components/InfoCard/InfoCard";
import WidgetConfigurationCard from "../../components/WidgetConfigurationCard/WidgetConfigurationCard";
import WidgetCopyButton from "../../components/WidgetCopyButton/WidgetCopyButton";
import WidgetsViewer, {
  viewModeType,
} from "../../components/WidgetsViewer/WidgetsViewer";
import { FiraConfigurationOptions, WidgetType } from "../../utils/types.d";
import styles from "./FiraTvWidgetsView.module.scss";

const FiraTvWidgetsView = () => {
  const navigate = useNavigate();
  const { widget } = useParams();
  const [widgetType, setWidgetType] = useState<WidgetType>();
  const [viewType, setViewType] = useState<viewModeType>(viewModeType.desktop);
  const [widgetConfig, setWidgetConfig] = useState<FiraConfigurationOptions>();
  const [title, setTitle] = useState<string>("");
  const [infoCardDescription, setInfoCardDescription] = useState<string>("");
  const style = {
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "24px",
  };

  const handleWidgetConfig = (config: FiraConfigurationOptions) => {
    setWidgetConfig(config);
  };

  const handleWidgetTitle = (type: WidgetType) => {
    switch (type) {
      case WidgetType.gridEvents:
        {
          setTitle(
            t("views.firaTv.widgets.cards.COLLECTION_CARDS.title").toLowerCase()
          );
        }
        break;
      case WidgetType.nextEventsCarousel:
        {
          setTitle(
            t(
              "views.firaTv.widgets.carousel.COLLECTION_CAROUSEL.title"
            ).toLowerCase()
          );
        }
        break;
      case WidgetType.mainCarousel:
        {
          setTitle(
            t("views.firaTv.widgets.scheduled.COMING_SOON.title").toLowerCase()
          );
        }
        break;
      case WidgetType.vodGallery:
        {
          setTitle(t("views.firaTv.widgets.grid.VOD_GALLERY.title"));
        }
        break;
      case WidgetType.bannerSlider:
        {
          setTitle(
            t("views.firaTv.widgets.banners.SLIDER.title").toLowerCase()
          );
        }
        break;
      case WidgetType.bannerCommercial:
        {
          setTitle(
            t("views.firaTv.widgets.banners.COMMERCIAL.title").toLowerCase()
          );
        }
        break;
      case WidgetType.bannerTop:
        {
          setTitle(t("views.firaTv.widgets.banners.TOP.title"));
        }
        break;
      case WidgetType.categories:
        {
          setTitle(
            t("views.firaTv.widgets.collections.CATEGORIES.title").toLowerCase()
          );
        }
        break;
    }
  };

  const handleWidgetDescription = (type: WidgetType) => {
    switch (type) {
      case WidgetType.gridEvents:
        {
          setInfoCardDescription(
            t("views.firaTv.widgets.cards.COLLECTION_CARDS.infoCardDescription")
          );
        }
        break;
      case WidgetType.nextEventsCarousel:
        {
          setInfoCardDescription(
            t(
              "views.firaTv.widgets.carousel.COLLECTION_CAROUSEL.infoCardDescription"
            )
          );
        }
        break;
      case WidgetType.mainCarousel:
        {
          setInfoCardDescription(
            t("views.firaTv.widgets.scheduled.COMING_SOON.infoCardDescription")
          );
        }
        break;
      case WidgetType.vodGallery:
        {
          setInfoCardDescription(
            t("views.firaTv.widgets.grid.VOD_GALLERY.infoCardDescription")
          );
        }
        break;
      case WidgetType.bannerSlider:
        {
          setInfoCardDescription(
            t("views.firaTv.widgets.banners.SLIDER.infoCardDescription")
          );
        }
        break;
      case WidgetType.bannerCommercial:
        {
          setInfoCardDescription(
            t("views.firaTv.widgets.banners.COMMERCIAL.infoCardDescription")
          );
        }
        break;
      case WidgetType.bannerTop:
        {
          setInfoCardDescription(
            t("views.firaTv.widgets.banners.TOP.infoCardDescription")
          );
        }
        break;
      case WidgetType.categories:
        {
          setInfoCardDescription(
            t("views.firaTv.widgets.collections.CATEGORIES.infoCardDescription")
          );
        }
        break;
    }
  };

  const widgetCaseHandler = () => {
    const type = widget?.toUpperCase() as WidgetType;
    switch (type) {
      case WidgetType.gridEvents:
        {
          handleWidgetTitle(WidgetType.gridEvents);
          handleWidgetDescription(WidgetType.gridEvents);
          setWidgetType(WidgetType.gridEvents);
        }
        break;
      case WidgetType.mainCarousel:
        {
          handleWidgetTitle(WidgetType.mainCarousel);
          handleWidgetDescription(WidgetType.mainCarousel);
          setWidgetType(WidgetType.mainCarousel);
        }
        break;
      case WidgetType.nextEventsCarousel:
        {
          handleWidgetTitle(WidgetType.nextEventsCarousel);
          handleWidgetDescription(WidgetType.nextEventsCarousel);
          setWidgetType(WidgetType.nextEventsCarousel);
        }
        break;
      case WidgetType.vodGallery:
        {
          handleWidgetTitle(WidgetType.vodGallery);
          handleWidgetDescription(WidgetType.vodGallery);
          setWidgetType(WidgetType.vodGallery);
        }
        break;
      case WidgetType.bannerSlider:
        {
          handleWidgetTitle(WidgetType.bannerSlider);
          handleWidgetDescription(WidgetType.bannerSlider);
          setWidgetType(WidgetType.bannerSlider);
        }
        break;
      case WidgetType.bannerCommercial:
        {
          handleWidgetTitle(WidgetType.bannerCommercial);
          handleWidgetDescription(WidgetType.bannerCommercial);
          setWidgetType(WidgetType.bannerCommercial);
        }
        break;
      case WidgetType.bannerTop:
        {
          handleWidgetTitle(WidgetType.bannerTop);
          handleWidgetDescription(WidgetType.bannerTop);
          setWidgetType(WidgetType.bannerTop);
        }
        break;
      case WidgetType.categories:
        {
          handleWidgetTitle(WidgetType.categories);
          handleWidgetDescription(WidgetType.categories);
          setWidgetType(WidgetType.categories);
        }
        break;
    }
  };

  //BUTTONS
  const handleBackButton = () => {
    navigate(-1);
  };

  const handleViewType = (view: viewModeType) => {
    setViewType(view);
  };

  useEffect(() => {
    widgetCaseHandler();
  }, []);
  let optionsBtn = (
    <div className={styles.ViewportButtons}>
      <button
        className={viewType === viewModeType.desktop ? styles.active : ""}
        onClick={() => handleViewType(viewModeType.desktop)}
      >
        <Computer />
      </button>
      <button
        className={viewType === viewModeType.mobile ? styles.active : ""}
        onClick={() => handleViewType(viewModeType.mobile)}
      >
        <Smartphone />
      </button>
    </div>
  );
  return (
    <>
      <FiraNavBar
        titleSection={`${t("views.firaTv.settings")} ${title}`}
        backButtonHandler={handleBackButton}
        style={style}
        trailingOptions={optionsBtn}
      />
      <div className={styles.FiraTvWidgetsViewWrapper}>
        <div id={styles["infocard"]}>
          <InfoCard description={infoCardDescription} />
        </div>
        <div id={styles["widgetboard"]}>
          <div id={styles["configcard"]}>
            <WidgetConfigurationCard
              configHandler={handleWidgetConfig}
              widgetType={widgetType}
            />
          </div>
          <div id={styles["widgetviewer"]}>
            <WidgetsViewer
              viewportType={viewType}
              widgetType={widgetType}
              widgetConfig={widgetConfig}
            />
            <WidgetCopyButton
              widgetConfig={widgetConfig}
              widetType={widgetType}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FiraTvWidgetsView;
