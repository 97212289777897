import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgArrowDown = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M21.31 10.829c.62-.641 1.625-.641 2.266-.022.32.32.47.727.47 1.154 0 .406-.15.812-.47 1.111L16.16 20.53a1.601 1.601 0 0 1-2.265 0L6.48 13.072c-.641-.62-.641-1.624 0-2.265.62-.62 1.645-.62 2.265.022l6.282 6.303 6.283-6.303Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgArrowDown;
