import React, { useEffect, useState } from "react";
import styles from "./UserDataPopUp.module.scss";
import useContextMenu from "../../providers/ContextMenuProvider";
import {
  Ban,
  CopyPaperFill,
  PinFill,
  RoundedX,
  Silent,
} from "../../../FiraIcons";
import Button, { ButtonStyle } from "../../../Button/Button";
import { useChatContext } from "../../providers/ChatProvider";
import { useTranslation } from "react-i18next";
import { formatRelativeTime } from "../../utils/formattersUtils";
import { ModerationReason } from "../../hooks/useAgoraSignaling";
import { getChatUserDataService } from "../../../../services/events";
import { FiraChatInputConfig } from "../../types";

const UserDataPopUp: React.FC = () => {
  const { t } = useTranslation();
  const { isMessagePin, isUserBanned, status, getChatConfig } =
    useChatContext();
  const {
    hideContextMenu,
    handlePinMessage,
    handleDeleteMessage,
    handleBanUser,
    contextMenuState,
  } = useContextMenu();
  const [showUserInfoPopUp, setShowUserInfoPopUp] = useState<boolean>(false);
  if (!contextMenuState) return null;
  const { item } = contextMenuState;
  const [userData, setUserData] = useState<any>([]);
  const [enableLogin, setEnableLogin] = useState<boolean>(false);
  const [loginFields, setLoginFields] = useState<FiraChatInputConfig[]>([]);

  const fallbackCopyText = (text: string) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      let successful = document.execCommand("copy");
      let msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };
  const copyText = async (text: string = "", item: any) => {
    if (!navigator.clipboard) {
      fallbackCopyText(text);
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setUserData((prevUserData: any) =>
        prevUserData.map((i: any) =>
          i.id === item.id ? { ...i, copied: !i.copied } : i
        )
      );
    } catch (error) {
      console.log("ERROR COPYING TEXT", error);
    }
  };

  const handleUserData = (data: Record<string, any>): Record<string, any>[] => {
    let idCounter = 0;

    const { username, disclaimerAccepted, user_0, ...rest } = data;

    return Object.keys(rest).map((key) => ({
      id: idCounter++,
      data: rest[key],
      copied: false,
    }));
  };

  const getUserData = async () => {
    await getChatUserDataService(item.userId).then((val: any) => {
      setUserData(handleUserData(val.data.params));
    });
  };

  function isOnlySpaces(str: string) {
    return /^\s*$/.test(str);
  }

  useEffect(() => {
    if (showUserInfoPopUp) {
      getUserData();
    }
  }, [showUserInfoPopUp]);

  useEffect(() => {
    setEnableLogin(getChatConfig().enableLogin);
    let firaLogin: FiraChatInputConfig[] | null = getChatConfig().loginForm;
    setLoginFields(firaLogin ? firaLogin : []);
  });

  return (
    <div className={styles.UserDataPopUpWrapper}>
      <div className={styles.ActionsContainer}>
        <div className={styles.UserActions}>
          <Ban
            style={{ cursor: "pointer" }}
            fontSize={28}
            color={"#ABAFB1"}
            onClick={() => {
              handleDeleteMessage(item);
              hideContextMenu();
            }}
          />
          <Silent
            style={{ cursor: "pointer" }}
            fontSize={18}
            color={isUserBanned(item.userId) ? "#000" : "#ABAFB1"}
            onClick={
              isUserBanned(item.userId)
                ? () => {
                    handleBanUser(ModerationReason.UNBAN, item.userId);
                    hideContextMenu();
                  }
                : () => {
                    handleBanUser(ModerationReason.BAN, item.userId);
                    hideContextMenu();
                  }
            }
          />
          <PinFill
            style={{ cursor: "pointer" }}
            fontSize={18}
            color={isMessagePin(item.itemId) ? "#000" : "#ABAFB1"}
            onClick={() => {
              handlePinMessage(item);
              hideContextMenu();
            }}
          />
        </div>
        <RoundedX
          onClick={hideContextMenu}
          style={{ cursor: "pointer" }}
          fontSize={14}
          color={"#ABAFB1"}
        />
      </div>
      <div className={styles.MessageContainer}>
        <div className={styles.User}>
          <div className={styles.name}>{item.from}</div>
          <div className={styles.time}>{formatRelativeTime(item.time)}</div>
        </div>
        <div className={styles.Message}>{item.msg}</div>
      </div>

      <div className={styles.Divider}></div>
      {enableLogin && loginFields.length > 1 && (
        <div
          className={`${styles.InfoContainer} ${
            showUserInfoPopUp ? styles.Collapsed : styles.Closed
          }`}
        >
          <div className={styles.Title}>{t("components.chat.info")}</div>
          {userData === undefined && (
            <div className={styles.loaderContainer}>
              <span className={styles.loader}></span>
            </div>
          )}

          {userData &&
            userData.map(
              (item: any, i: number) =>
                item.data !== "" &&
                !isOnlySpaces(item.data) && (
                  <div key={i} className={styles.Item}>
                    <span className={styles.value}>{item.data}</span>{" "}
                    {item.copied ? (
                      <span className={styles.copy}>
                        {t("components.chat.copy")}
                      </span>
                    ) : (
                      <CopyPaperFill
                        fontSize={18}
                        onClick={() => copyText(item.data, item)}
                      />
                    )}
                  </div>
                )
            )}
        </div>
      )}

      {enableLogin && loginFields.length > 1 ? (
        <Button
          buttonStyle={ButtonStyle.border}
          clickHandler={() => setShowUserInfoPopUp(!showUserInfoPopUp)}
        >
          {showUserInfoPopUp ? (
            <span>{t("components.chat.hide")}</span>
          ) : (
            <span>{t("components.chat.detailsButton")}</span>
          )}
        </Button>
      ) : (
        <div className={styles.NoData}>
          <span>{t("components.chat.noData")}</span>
        </div>
      )}
    </div>
  );
};

export default UserDataPopUp;
