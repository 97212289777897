import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSettings = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M19.77 7.303a.696.696 0 0 0-.351-.464l-2.122-1.157a8.096 8.096 0 0 0-.384-.654l.04-2.385a.691.691 0 0 0-.233-.531A9.966 9.966 0 0 0 13.05.035a.726.726 0 0 0-.585.067l-2.081 1.226a8.354 8.354 0 0 0-.769 0L7.532.1a.723.723 0 0 0-.585-.067A9.966 9.966 0 0 0 3.28 2.115a.702.702 0 0 0-.234.531l.041 2.383c-.138.212-.267.43-.385.654L.578 6.84a.71.71 0 0 0-.35.465 9.568 9.568 0 0 0 .002 4.159.695.695 0 0 0 .351.464l2.123 1.157c.117.223.245.441.383.653l-.04 2.386a.69.69 0 0 0 .233.53 9.966 9.966 0 0 0 3.67 2.077.726.726 0 0 0 .585-.066l2.081-1.226c.256.012.513.012.769 0l2.083 1.227a.724.724 0 0 0 .585.067 9.967 9.967 0 0 0 3.667-2.082.703.703 0 0 0 .233-.53l-.04-2.384c.138-.212.267-.43.384-.653l2.125-1.158a.711.711 0 0 0 .35-.464 9.57 9.57 0 0 0-.002-4.16ZM10 13.239a3.978 3.978 0 0 1-2.182-.65 3.872 3.872 0 0 1-1.446-1.73 3.791 3.791 0 0 1-.224-2.228 3.835 3.835 0 0 1 1.075-1.975 3.95 3.95 0 0 1 2.01-1.055 3.995 3.995 0 0 1 2.27.22 3.913 3.913 0 0 1 1.763 1.42c.431.634.661 1.38.661 2.142a3.825 3.825 0 0 1-1.151 2.726A3.968 3.968 0 0 1 10 13.239Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSettings;
