import * as React from "react";
import { SVGProps } from "react";
import "./CrossTimer.scss";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCrossTimer = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7 2C4.24 2 2 4.24 2 7s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5Zm2.385 7.5-.95-.005L7 7.735l-1.43 1.76-.955.005a.117.117 0 0 1-.115-.12c0-.03.01-.055.025-.075L6.405 7 4.53 4.7a.133.133 0 0 1-.03-.08c0-.065.05-.12.115-.12l.955.005L7 6.265l1.43-1.76.955-.005c.06 0 .115.055.115.12 0 .03-.01.055-.025.075L7.6 7l1.875 2.305c.015.02.025.05.025.075 0 .065-.05.12-.115.12Z"
      fill="currentColor"
    />
    <path
      className="EllipseTimer"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.738 2.991A7 7 0 0 1 14 7h-.874a6.125 6.125 0 1 0-5.117 6.043l.144.861a7 7 0 1 1 4.585-10.913Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCrossTimer;
