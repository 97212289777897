import { apiBusiness, apiBusinessPrivate, apiManagementPrivate } from "./api";
import {
  FiraLanding,
  GlobalLandingConfigResponse,
  LandingProduct,
} from "../types/Landing";
import { GlobalLandingSettings } from "../providers/SettingsLandingProvider";
import { reject } from "lodash";

export const landingExistByNameService = (name: string) => {
  return apiBusiness.get<boolean>(`/v1/fira-landing/exist-by-name/${name}`);
};

export const landingCreateService = (firaLanding: FiraLanding) => {
  return apiBusinessPrivate.post(`/v1/fira-landing/create`, firaLanding);
};

export const landingUpdateService = (id: string, config: FiraLanding) => {
  return apiBusinessPrivate.put(`/v1/fira-landing/update/${id}`, config);
};
//NEW  UPDATE PRODUCT  GET /api/v1/fira-product/landing-products/update/{storeId}
export const landingUpdateProductService = (
  storeId: string,
  productList: LandingProduct[]
) => {
  let body = {
    firaProductList: productList,
  };
  return apiManagementPrivate.post(
    `/api/v1/fira-product/landing-products/update/${storeId}`,
    productList
  );
};

export const landingUpdateFeatureProductService = (
  storeId: string,
  productList: LandingProduct[]
) => {
  return apiManagementPrivate.post(
    `/api/v1/fira-product/landing-products/update-featured/${storeId}`,
    productList
  );
};
export const getLandingsByStoreIdService = (storeId: string) => {
  return apiBusinessPrivate.get(`/v1/fira-landing/by-store-id/${storeId}`);
};

export const getLandingById = (landingId: string) => {
  return apiBusinessPrivate.get(`/v1/fira-landing/by-id/${landingId}`);
};

export const mergeFeaturedProductsService = (
  storeId: string,
  landingId: string
) => {
  let body = {
    storeId: storeId,
    landingId: landingId,
  };

  return apiBusiness.post(`/v1/fira-landing/merge-featured-products/`, body);
};

export const mergeProductsService = (storeId: string, landingId: string) => {
  let body = {
    storeId: storeId,
    landingId: landingId,
  };

  return apiBusiness.post(`/v1/fira-landing/merge-products/`, body);
};

///api/v1/fira-product/pro-seller/store-id/{storeId}
export const getLandingsProductsByStoreIdService = (storeId: string) => {
  return apiManagementPrivate.get(
    `/api/v1/fira-product/pro-seller/store-id/${storeId}`
  );
};

export const getGlobalLandingSettings = (
  businessClientId: string
): Promise<GlobalLandingConfigResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response =
        await apiBusinessPrivate.get<GlobalLandingConfigResponse>(
          `/v1/fira-landing/get-data-config-landing/${businessClientId}`
        );

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const saveGlobalLandingSettings = (
  businessClientId: string,
  settings: GlobalLandingSettings,
  config: FiraLanding
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = {
        businessClientId,
        useOwnerDefaultLandingConfig: settings.isActive,
        defaultLandingConfig: settings.stores,
        parametersSetted: settings.blockWidgetsAndCart,
        landingBusinessTemplate: config,
      };
      await apiBusinessPrivate.post(
        "/v1/fira-landing/create-massive-configuration-landing",
        data
      );
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};
