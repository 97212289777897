import { useEffect, useState } from "react";
import { EventResumeResponse } from "../../../types/Response";
import { RoundedX } from "../../FiraIcons";
import CalendarM from "../../FiraIcons/CalendarM";
import styles from "./EventListPopUp.module.scss";
import {
  convertDateUTC,
  timeFormat,
  timeToLocalTimeZone,
  weekDayAndNumberWithComma,
  weekDayNumberAndYear,
} from "../../../utils/handleDates";
import { CalendarEventType } from "../../NewCustomCalendar/NewCustomCalendar";

interface EventListPopUpProps {
  onClose(): void;
  eventList?: CalendarEventType[];
  day?: Date;
  selectEvent(event: CalendarEventType): void;
}

const EventListPopUp = ({
  onClose,
  eventList,
  day,
  selectEvent,
}: EventListPopUpProps) => {
  const [currentDate, setCurrentDate] = useState<string>("");
  const [list, setList] = useState<CalendarEventType[]>([]);

  const handleDate = (day: Date) => {
    const formatted = weekDayAndNumberWithComma(convertDateUTC(day));
    const capitalized = capitalize(formatted);
    setCurrentDate(capitalized);
  };

  const handleSelectEvent = (event: CalendarEventType) => {
    selectEvent(event);
  };

  useEffect(() => {
    if (day) {
      handleDate(day);
    }
  }, [day]);
  useEffect(() => {
    if (eventList) {
      setList(eventList);
    }
  }, [eventList]);

  return (
    <>
      <div style={{ padding: "10px" }}>
        <div className={styles.EventListPopUpWrapper}>
          <div className={styles.EventListPopUpWrapper__Top}>
            <span className={styles.EventListPopUpWrapper__Top__Title}>
              {currentDate || ""}
            </span>
            <div
              className={styles.EventListPopUpWrapper__Top__Icon}
              onClick={onClose}
            >
              <RoundedX color="#ABAFB1" width={"14px"} height={"14px"} />
            </div>
          </div>
          <div className={styles.EventListPopUpWrapper__ItemsList}>
            {eventList &&
              list.map((event, i) => (
                <>
                  <EventItem
                    handleSelection={handleSelectEvent}
                    key={i}
                    event={event}
                  />
                  <div className={styles.dividerLine}></div>
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

interface EventItemProps {
  event: CalendarEventType;
  handleSelection(event: CalendarEventType): void;
}

const EventItem = ({ event, handleSelection }: EventItemProps) => {

  const handleItemSheduledDate = (date: Date) => {
    const formatted = weekDayNumberAndYear(date);
    const capitalized = capitalize(formatted);
    return capitalized;
  };

  //los eventos finalizados o iniciados empiezan a venir con
  //+00:00, esto cambia el formato ya que indica una zona horaria
  //y crea una diferencia de hora con respecto a el evento que muestra
  //el detalle de evento, asi que se remueve
  const parseTime = (date: string) => {
    const timeSPlited = date.split("+")[0];

    return new Date(timeSPlited);
  };

  return (
    <div className={styles.EventItem} onClick={() => handleSelection(event)}>
      <div
        className={styles.EventItem__StatusIndicator}
        style={
          event.extendedProps.status === "STARTED"
            ? { backgroundColor: "#ff2c30" }
            : { backgroundColor: "#ffde07" }
        }
      ></div>
      <div className={styles.EventItem__EventInfo}>
        <div className={styles.EventItem__EventInfo__Top}>
          <h4>{event.title || ""}</h4>
          {event.extendedProps.status === "STARTED" && (
            <div className={styles.EventItem__EventInfo__Top__LiveIcon}>
              <div
                className={styles.EventItem__EventInfo__Top__LiveIcon__Circle}
              ></div>
              <span>Live</span>
            </div>
          )}
        </div>
        <div className={styles.EventItem__EventInfo__StoreName}>
          <span>{event.extendedProps.storeName || ""}</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <CalendarM />
            <span className={styles.eventSmall}>
              {handleItemSheduledDate(new Date(event.start))}
            </span>
          </div>

          <span className={styles.eventSmall}>
            {timeToLocalTimeZone(parseTime(event.extendedProps.eventStart))}
          </span>
        </div>
      </div>
    </div>
  );
};

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default EventListPopUp;
