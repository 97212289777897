import React from "react";

import stylesheet from "./StepOrientation.module.scss";
import { useTranslation } from "react-i18next";
import { useWizard } from "../../providers/WizardProvider";

const {
  Wrapper,
  Header,
  Content,
  Item,
  Rect,
  Landscape,
  Portrait,
  RectContainer,
  IsSelected,
} = stylesheet;

const StepOrientation: React.FC = () => {
  const { t } = useTranslation();
  const { form, setFormKey } = useWizard();

  const handleOrientationChange = (value: "VERTICAL" | "HORIZONTAL") => {
    setFormKey("resolution", value);
  };

  React.useEffect(() => {
    setFormKey("resolution", form.resolution || "HORIZONTAL");
  }, []);

  return (
    <div className={Wrapper}>
      <div className={Header}>
        <span>{t("event_creation.params.broadcastingResolution.title")}</span>
        <span>
          {t("event_creation.params.broadcastingResolution.subtitle")}
        </span>
      </div>
      <div className={Content}>
        <OrientationItem
          value="HORIZONTAL"
          title={t("event_creation.params.broadcastingResolution.horizontal")}
          ratio="16:9"
          isSelected={form.resolution === "HORIZONTAL"}
          onSelect={handleOrientationChange}
        />
        <OrientationItem
          value="VERTICAL"
          title={t("event_creation.params.broadcastingResolution.vertical")}
          ratio="9:16"
          isSelected={form.resolution === "VERTICAL"}
          onSelect={handleOrientationChange}
        />
      </div>
    </div>
  );
};

interface OrientationItemProps {
  value: "VERTICAL" | "HORIZONTAL";
  title: string;
  ratio: string;
  isSelected: boolean;
  onSelect(value: "VERTICAL" | "HORIZONTAL"): void;
}
const OrientationItem: React.FC<OrientationItemProps> = ({
  value,
  ratio,
  title,
  isSelected,
  onSelect,
}) => {
  return (
    <div
      onClick={() => onSelect(value)}
      className={`${Item} ${isSelected ? IsSelected : ""}`}
    >
      <span>{title}</span>
      <div className={RectContainer}>
        <div
          className={`${Rect} ${value === "HORIZONTAL" ? Landscape : Portrait}`}
        ></div>
      </div>
      <span>{ratio}</span>
    </div>
  );
};

export default StepOrientation;
