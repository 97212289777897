// import { getNextEvents, playFiraModal } from "firalibrary";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { dateFormat, hourFormat } from "./DateFormat";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight } from "../../components/FiraIcons";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Carousel.scss";


function CarouselWidget({
  firaKey,
  widgetTitle,
  primaryTextColor,
  displayColor,
  primaryColor,
  backgroundColor,
  type,
  isMobileAttr=false}) {
  const mediaWidth = 790;
  const [nextEventsData, setNextEventsData] = useState([{}]);
  const [mobileMode, setMobileMode] = useState(isMobileAttr);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const { t } = useTranslation();

  const dataPreview=[
    {
      "id": "636a559eefc8e70f4872c8ff",
      "scheduledDate": "2022-12-31T00:00:00.000+00:00",
      "eventDescription": t('views.firaTv.exampleItems.eventDescription'),
      "evenTeaser": null,
      "eventPreviewImage": null,
      "firaEventCategory": null,
      "eventName":t('views.firaTv.exampleItems.eventName')
    },
    {
      "id": "636a56c8efc8e70f4872c900",
      "scheduledDate": "2022-12-30T13:17:00.000+00:00",
      "eventDescription": t('views.firaTv.exampleItems.eventDescription'), 
      "evenTeaser": null,
      "eventPreviewImage": null,
      "firaEventCategory": null,
      "eventName":t('views.firaTv.exampleItems.eventName')
    },
    {
      "id": "636a58aaefc8e70f4872c901",
      "scheduledDate": "2022-12-12T13:26:00.000+00:00",
      "eventDescription": t('views.firaTv.exampleItems.eventDescription'),
      "evenTeaser": null,
      "eventPreviewImage": null,
      "firaEventCategory": null,
      "eventName":t('views.firaTv.exampleItems.eventName')
    }
  ]

  const customNav = {
    nextEl: ".Carousel_CarouselWidgetWrapper__NextButton",
    prevEl: ".Carousel_CarouselWidgetWrapper__PrevButton",
  };

  function getWindowSize() {

    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const callEndpoint=async ()=>{
    //const data= await getNextEvents(firaKey,type);
    setNextEventsData(dataPreview);
   };
  useEffect(() => {
    /*getNextEvents(firaKey,type).then((val) => {
      setNextEventsData(val.list);
    });*/
    callEndpoint();
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    console.log(windowSize.innerWidth)
    if (windowSize.innerWidth < mediaWidth) {

      setMobileMode(true);
    } else {
      setMobileMode(false);
    }
  }, [windowSize]);

  useEffect(()=>{
    setMobileMode(isMobileAttr)
  },[isMobileAttr])

  return (
    <>
      <div className={"CarouselWidgetWrapper"} style={{backgroundColor:backgroundColor}}>
        <div className={"CarouselWidgetWrapper__Top"}>
          <div className={"CarouselWidgetWrapper__Top__Title"} style={{color:primaryTextColor}}>
            {widgetTitle}
          </div>

          {/* Navigation */}
          {!mobileMode && (
            <div
              className={"CarouselWidgetWrapper__Top__Underline"}
              style={{ backgroundColor: primaryColor }}
            ></div>
          )}
        </div>

        <div className='fira-carrousel-left-navigation-container'> 
          { !mobileMode &&
            <div style={{color:primaryColor}} className='fira-carrousel-navigation-button'>
              <ArrowLeft  />
            </div>}
        </div>
        <div className={"CarouselWidgetWrapper__Swiper"}>
          <div className={"CarouselWidgetWrapper__Swiper__Container"}>
            <Swiper
              slidesPerView={2}
              spaceBetween={mobileMode ? 16 : 27}
              pagination={{
                clickable: true,
                dynamicBullets: false,
              }}
              navigation={
                {
                  prevEl: '.fira-carrousel-left-navigation-container',
                  nextEl: '.fira-carrousel-right-navigation-container',
                }
              }
              modules={[Pagination, Navigation]}
              loop={true}
              initialSlide={2}
              centeredSlides={mobileMode ? true : false}
              className={"FiraSwiper"}
              style={{
                "--swiper-theme-color": primaryColor,
              }}
            >
              {nextEventsData.map((data, i) => {
                return (
                  <SwiperSlide key={i}>
                    <CarouselItem
                      data={data}
                      isMobile={mobileMode}
                      firaKey={firaKey}
                      displayColor={displayColor}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <div className='fira-carrousel-right-navigation-container'>
          {!mobileMode &&
          <div style={{color:primaryColor}} className='fira-carrousel-navigation-button'>
            <ArrowRight />
          </div>}
        </div>   

      </div>
    </>
  );
}

export const CarouselItem = (props) => {
  const {firaKey, isMobile, data, displayColor} = props;
  const {eventPreviewImage, firaEventCategory, eventName,eventDate,videoName, scheduledDate, id } = data
  
  const { t } = useTranslation();

  const style = {
    backgroundImage: eventPreviewImage ? `url(${eventPreviewImage})` : "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const clickHandler = () => {
    playFiraModal(firaKey, id);
  };

  return (
    <>
      <div className={"FiraCarouselWrapper CarouselItemWrapper"} >
        <div className={"CarouselItemWrapper__ImageBox"}>
          {eventPreviewImage &&<img className={"CarouselItemWrapper__ImageElement"}  src={eventPreviewImage} alt={eventName}/>}
        </div>
        
        <div className={"CarouselItemWrapper__Overlay"}>
        </div>

        <div className={"CarouselItemWrapper__Top"}>
          <div className={"CarouselItemWrapper__Top__Date"} style={{color: displayColor}}>
            {eventDate ? dateFormat(eventDate, isMobile, lang): ''}
          </div>
          <div className={"CarouselItemWrapper__Top__Time"} style={{color: displayColor}}>
            {eventDate ? hourFormat(eventDate): ''}
          </div>
        </div>
        <div className={"CarouselItemWrapper__Center"}>
          <svg
              width="57"
              height="57"
              viewBox="0 0 57 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={clickHandler}
            >
              <path
                d="M28.2158 52.5297C21.7402 52.5297 15.5298 49.9551 10.9509 45.3724C6.37197 40.7897 3.79956 34.5742 3.79956 28.0933C3.79956 21.6124 6.37197 15.3969 10.9509 10.8142C15.5298 6.23146 21.7402 3.65692 28.2158 3.65692C34.6914 3.65692 40.9017 6.23146 45.4807 10.8142C50.0596 15.3969 52.632 21.6124 52.632 28.0933C52.632 34.5742 50.0596 40.7897 45.4807 45.3724C40.9017 49.9551 34.6914 52.5297 28.2158 52.5297ZM28.2158 56.0206C35.6165 56.0206 42.714 53.0782 47.9471 47.8409C53.1801 42.6035 56.12 35.5001 56.12 28.0933C56.12 20.6865 53.1801 13.5831 47.9471 8.34573C42.714 3.10835 35.6165 0.166016 28.2158 0.166016C20.8151 0.166016 13.7176 3.10835 8.48449 8.34573C3.25143 13.5831 0.311523 20.6865 0.311523 28.0933C0.311523 35.5001 3.25143 42.6035 8.48449 47.8409C13.7176 53.0782 20.8151 56.0206 28.2158 56.0206Z"
                fill={displayColor}
              />
              <path
                d="M22.1816 17.8118C22.4668 17.6648 22.7871 17.5994 23.1071 17.6228C23.4271 17.6462 23.7345 17.7575 23.9954 17.9445L36.2035 26.6717C36.4296 26.8332 36.6138 27.0464 36.741 27.2935C36.8681 27.5406 36.9345 27.8146 36.9345 28.0925C36.9345 28.3705 36.8681 28.6445 36.741 28.8916C36.6138 29.1387 36.4296 29.3519 36.2035 29.5133L23.9954 38.2406C23.7346 38.4274 23.4274 38.5386 23.1075 38.562C22.7877 38.5854 22.4676 38.5201 22.1824 38.3733C21.8972 38.2264 21.6581 38.0037 21.4912 37.7297C21.3242 37.4556 21.2361 37.1408 21.2363 36.8198V19.3653C21.236 19.0444 21.324 18.7296 21.4908 18.4556C21.6576 18.1815 21.8966 17.9588 22.1816 17.8118Z"
                fill={displayColor}
              />
            </svg>
        </div>
        <div className={"CarouselItemWrapper__Bottom"}>
          <div className={"CarouselItemWrapper__Bottom__Category"}>{""}
          </div>
          <div className={"CarouselItemWrapper__Bottom__Title"} style={{color: displayColor}}>
            {eventName ? eventName : videoName}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselWidget;
