import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgEmptyChatBubble = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 100 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m82.23 68.09 2.484-3.56c3.892-5.577 6.124-12.135 6.124-19.153 0-19.785-17.983-36.387-40.908-36.387C27.004 8.99 9.02 25.592 9.02 45.377S27.004 81.763 49.93 81.763c2.875 0 5.677-.263 8.379-.764l1.44-.266 29.268 5.054-6.786-17.697Zm11.052 26.736L59.8 89.044a54.17 54.17 0 0 1-9.87.901C23.379 89.945.84 70.523.84 45.377.84 20.23 23.379.809 49.93.809c26.553 0 49.09 19.421 49.09 44.568 0 8.802-2.81 16.976-7.595 23.835l7.265 18.946a4.988 4.988 0 0 1-.779 4.923 4.886 4.886 0 0 1-4.628 1.745Z"
      fill="#E5E5E6"
    />
  </svg>
);

export default SvgEmptyChatBubble;
