import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ProductClicksTable,
  RootState,
  UserInfoResponse,
  FiraUserActionsStats,
  ChartDataOptionItemType,
} from "../../utils/types";
import styles from "./EventStatistics.module.scss";
import {
  getBroadcastingProduct,
  getChatDataMinuteByMinute,
  getDataMinuteByMinute,
  getProductDataMinuteByMinute,
  getUsersSumary,
  getViewsDataMinuteByMinute,
} from "../../services/events";
import { useTranslation } from "react-i18next";
import CardWidget from "../../widgets/CardWidget/CardWidget";
import TableWidget from "../../widgets/TableWidget/TableWidget";
import ViewsByDevices from "../../widgets/ViewsbyDevicesWidget/ViewsByDevicesWidget";
import InteractionsWidget from "../../widgets/InteractionsWidget/InteractionsWidget";
import GeneralStatiticsChart from "../GeneralStatisticsChart/GeneralStatisticsChart";
import ProductListWidget from "../../widgets/ProductListWidget/ProductListWidget";

interface EventStatisticsProps {
  counters: FiraUserActionsStats;
}

interface usersType {
  id: string;
  username: string;
  email: string;
  phone: string;
  bpaCount: number;
}

interface ChartDataType {
  data: any[];
  optionsToShow: ChartDataOptionItemType[];
  elementsToShow: number;
  lineType?:
    | "monotone"
    | "monotoneX"
    | "monotoneY"
    | "natural"
    | "step"
    | "stepAfter"
    | "stepBefore"
    | "linear"
    | undefined;
  isLoading?: boolean;
}

interface chartItemType {
  minute: number;
  counting: number;
}

const EventStatistics = ({ counters }: EventStatisticsProps) => {
  const { t } = useTranslation();
  const { event } = useSelector((state: RootState) => state);
  const [usersInfo, setUsersInfo] = useState<usersType[]>([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [productsClicks, setProductsClicks] = useState<ProductClicksTable[]>(
    []
  );
  const [connectionsMobile, setConnectionsMobile] = useState<number>(0);
  const [connectionsWeb, setConnectionsWeb] = useState<number>(0);
  const [generalChartData, setGeneralChartData] = useState<ChartDataType>({
    data: [],
    optionsToShow: [],
    elementsToShow: 1,
    isLoading: false,
  });
  const [concurrencyChartData, setConcurrencyChartData] =
    useState<ChartDataType>({
      data: [],
      optionsToShow: [],
      elementsToShow: 0,
      isLoading: false,
    });

  // const parseUsersArray = (users: UserInfoResponse[]) => {
  //   let temparray: usersType[] = [];
  //   users.forEach((user, index) => {
  //     let tempObj = {
  //       id: String(index),
  //       username: user.params.user_0 ? user.params.user_0 : "anon",
  //       email: user.params.email ? user.params.email : "-",
  //       phone: user.params.phone ? user.params.phone : "-",
  //       bpaCount: user.bpaCount,
  //     };
  //     temparray.push(tempObj);
  //   });
  //   return temparray;
  // };

  // const getUsersInfo = async (eventId: string) => {
  //   setUsersLoading(true);
  //   try {
  //     const response = await getUsersSumary(eventId);
  //     setUsersLoading(false);
  //     setUsersInfo(parseUsersArray(response.data));
  //   } catch (error) {
  //     setUsersLoading(false);
  //     console.error(error);
  //   }
  // };

  const dataByMinute = async (action: string, eventId: string) => {
    const data = await getDataMinuteByMinute(eventId || "", action);

    if (data.status === 200) {
      return data.data;
    } else {
      return [];
    }
  };

  const productDataByMinute = async (eventId: string) => {
    const data_regular = await getProductDataMinuteByMinute(
      eventId || "",
      "regular-click"
    );

    const data_popup = await getProductDataMinuteByMinute(
      eventId || "",
      "popup-click"
    );

    let clickTotal = [];

    if (data_regular.status === 200 && data_popup.status === 200) {
      const regular = data_regular.data;
      const popup = data_popup.data;

      for (let i = 0; i < regular.length; i++) {
        const regularItem = regular[i];
        const popupItem = popup[i];

        const regularMinute = regularItem.minute;
        const popupMinute = popupItem.minute;

        if (regularMinute === popupMinute) {
          const regularCounting = regularItem.counting;
          const popupCounting = popupItem.counting;

          const total = regularCounting + popupCounting;

          clickTotal.push({
            minute: regularMinute,
            counting: total,
          });
        }
      }

      return clickTotal;
    } else {
      return [];
    }
  };

  const chatDataMinuteByMinute = async (eventId: string) => {
    const data = await getChatDataMinuteByMinute(eventId || "");

    if (data.status === 200) {
      return data.data;
    } else {
      return [];
    }
  };

  const viewsDataMinuteByMinute = async (eventId: string) => {
    const data = await getViewsDataMinuteByMinute(eventId);

    if (data.status === 200) {
      return data.data;
    } else {
      return [];
    }
  };

  const getGeneralChartData = async (eventId: string) => {
    try {
      setGeneralChartData((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const likesByMinute: chartItemType[] = await dataByMinute(
        "like",
        eventId
      );
      const sharesByMinute = await dataByMinute("share", eventId);
      const clicksByMinute = await productDataByMinute(eventId);
      const messagesByMinute = await chatDataMinuteByMinute(eventId);

      const data = likesByMinute.map((item: chartItemType, index: number) => {
        return {
          Minute: `${item.minute}:00` || "0:00",
          Likes: item.counting || 0,
          Shares: sharesByMinute[index]?.counting || 0,
          Clicks: clicksByMinute[index]?.counting || 0,
          Messages: messagesByMinute[index]?.counting || 0,
        };
      });

      const optionsToShow = [
        {
          id: 1,
          dataKey: "Likes",
          name: t("views.events.eventDetail.optionsDropdown.likes"),
          active: true,
          color: "#1E3BB3",
        },
        {
          id: 2,
          dataKey: "Shares",
          name: t("views.events.eventDetail.optionsDropdown.shares"),
          active: true,
          color: "#F8CFC7",
        },
        {
          id: 3,
          dataKey: "Clicks",
          name: t("views.events.eventDetail.optionsDropdown.clicks"),
          active: false,
          color: "#BEEF00",
        },
        {
          id: 4,
          dataKey: "Messages",
          name: t("views.events.eventDetail.optionsDropdown.messages"),
          active: false,
          color: "#53CDF9",
        },
      ];

      const elementsToShow = 2;

      const lineType = "monotone";

      setGeneralChartData({
        ...generalChartData,
        data,
        optionsToShow,
        elementsToShow,
        lineType,
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
      setGeneralChartData((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const getConcurrencyChartData = async (eventId: string) => {
    try {
      setConcurrencyChartData((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const viewsByMinute = await viewsDataMinuteByMinute(eventId);
      const data = viewsByMinute.map((item: chartItemType, index: number) => {
        return {
          Minute: `${item.minute}:00` || "0:00",
          Connections: item.counting || 0,
        };
      });
      if (data[0].Connections !== 0) {
        data.unshift({
          Minute: "0:00",
          Connections: 0,
        });
      }
      data[data.length - 1] = {
        Minute: data[data.length - 1].Minute,
        Connections: 0,
      };

      const optionsToShow = [
        {
          dataKey: "Connections",
          name: t("views.events.eventDetail.concurrencyChart.connections"),
          active: true,
          color: "#53CDF9",
        },
      ];

      const elementsToShow = 1;

      const lineType = "linear";

      setConcurrencyChartData({
        ...concurrencyChartData,
        data,
        optionsToShow,
        elementsToShow,
        lineType,
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
      setConcurrencyChartData((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const getBroadcastingProductsService = async (eventId: string) => {
    try {
      const responseBroadcastingProducts = await getBroadcastingProduct(
        eventId
      );
      setProductsClicks(responseBroadcastingProducts.data);
    } catch (error) {
      console.error(error);
    }
  };

  const parseTime = (minutes: number) => {
    const miliseconds = minutes * 60000;
    const hours = Math.floor(miliseconds / (1000 * 60 * 60));
    const min = minutes - 60 * hours;
    return `${hours != 0 ? hours + "h:" : ""}${Math.floor(min)}m`;
  };

  const usersHeaderData = [
    { name: "", size: "9px" },
    { name: t("views.events.eventDetail.users_table.username"), size: "180px" },
    { name: t("views.events.eventDetail.users_table.email"), size: "180px" },
    { name: t("views.events.eventDetail.users_table.phone"), size: "180px" },
    { name: t("views.events.eventDetail.users_table.bpaCount"), size: "90px" },
    { name: "", size: "9px" },
  ];

  useEffect(() => {
    if (event.currentEvent) {
      getBroadcastingProductsService(event.currentEvent.id);
      getGeneralChartData(event.currentEvent.id);
      getConcurrencyChartData(event.currentEvent.id);
    }
  }, [event.currentEvent]);

  useEffect(() => {
    if (event.currentEvent?.userActionsStats) {
      setConnectionsMobile(
        event.currentEvent.userActionsStats.mobileConnections
      );
      setConnectionsWeb(event.currentEvent.userActionsStats.webConnections);
    }
  }, [event.currentEvent?.userActionsStats]);

  return (
    <div className={styles.statisticsContent}>
      <div className={styles.statisticsCounters}>
        <CardWidget
          title={t("views.events.eventDetail.liveTime")}
          description={""}
          data={parseTime(counters.eventDuration)}
          isLoading={event.isLoading}
        />
        <CardWidget
          title={t("views.events.eventDetail.averageConnectionTime")}
          description={""}
          data={parseTime(counters.averageConnectionTime)}
          isLoading={event.isLoading}
        />
        <CardWidget
          title={t("views.events.eventDetail.connectionTime")}
          description={""}
          data={parseTime(counters.allConnectionTime)}
          isLoading={event.isLoading}
        />
        <CardWidget
          title={t("views.events.eventDetail.engagement")}
          description={""}
          data={
            counters.engagementRate
              ? (Math.floor(counters.engagementRate * 100) / 100).toString() +
                "%"
              : "0%"
          }
          isLoading={event.isLoading}
        />
        <CardWidget
          title={t("views.events.eventDetail.bouncingRate")}
          description={""}
          data={
            counters.bouncingRate
              ? (Math.floor(counters.bouncingRate * 100) / 100).toString() + "%"
              : "0%"
          }
          isLoading={event.isLoading}
        />
        <CardWidget
          title={t("views.events.eventDetail.connections")}
          description={""}
          data={(counters.webConnections + counters.mobileConnections)
            .toLocaleString()
            .replace(/,/g, ".")}
          isLoading={event.isLoading}
        />
      </div>
      <div className={styles.widgetsWrapper}>
        <div className={styles.leftWidgets}>
          <GeneralStatiticsChart
            title={t("views.events.eventDetail.statiticsChart.title")}
            subTitle={t("views.events.eventDetail.statiticsChart.subtitle")}
            bodyData={generalChartData.data}
            fieldX="Minute"
            optionsToShow={generalChartData.optionsToShow}
            elementsToShow={generalChartData.elementsToShow}
            lineType={generalChartData.lineType}
            isLoading={generalChartData.isLoading}
            isSelectCheckboxes={false}
          />
          <GeneralStatiticsChart
            title={t("views.events.eventDetail.concurrencyChart.title")}
            subTitle={t("views.events.eventDetail.concurrencyChart.subtitle")}
            bodyData={concurrencyChartData.data}
            fieldX="Minute"
            optionsToShow={concurrencyChartData.optionsToShow}
            elementsToShow={concurrencyChartData.elementsToShow}
            lineType={concurrencyChartData.lineType}
            isLoading={concurrencyChartData.isLoading}
            isSelectCheckboxes={false}
          />
          {/* <TableWidget
            title={t("views.events.eventDetail.users_table.title")}
            subTitle={t("views.events.eventDetail.users_table.subtitle")}
            bodyData={usersInfo}
            header={usersHeaderData}
            onClick={() => {}}
            isLoading={usersLoading}
          /> */}
          <ProductListWidget
            title={t(
              "views.events.eventDetail.products_clicks.interactionTitle"
            )}
            subtitle={t(
              "views.events.eventDetail.products_clicks.interactionSubtitle"
            )}
            products={productsClicks}
            loading={event.isLoading}
            isStatsProducts={false}
            header={[
              t("views.events.eventDetail.products_clicks.gallery"),
              t("views.events.eventDetail.products_clicks.highlighted"),
              t("views.events.eventDetail.products_clicks.interactionTotal"),
            ]}
            type={"INTENTION"}
          />
          <ProductListWidget
            title={t("views.events.eventDetail.products_clicks.buyTitle")}
            subtitle={t("views.events.eventDetail.products_clicks.buySubtitle")}
            products={productsClicks}
            loading={event.isLoading}
            isStatsProducts={false}
            header={[
              t("views.events.eventDetail.products_clicks.gallery"),
              t("views.events.eventDetail.products_clicks.highlighted"),
              t("views.events.eventDetail.products_clicks.buyIntent"),
            ]}
            type={"REGULAR"}
          />
        </div>

        <div className={styles.rightWidgets}>
          <ViewsByDevices
            desktop={connectionsWeb}
            mobile={connectionsMobile}
            other={0}
            isLoading={event.isLoading}
          />
          <InteractionsWidget
            likes={counters.likes}
            shares={counters.shares}
            messages={counters.chatMessageCounting}
            clicks={counters.popupClicks + counters.regularClicks}
            isLoading={event.isLoading}
            pieHeight={300}
          />
        </div>
      </div>
    </div>
  );
};

export default EventStatistics;
