import type { ThunkDispatch } from "redux-thunk";
import type { AnyAction } from "redux";

import {
  activateUserService,
  registerService,
} from "../../services/authentication";
import { RegisterResponse, UserRegisterForm } from "../../utils/types";
import { handleError, RequestError } from "../../services/errorHandling";
import { AuthActions } from "../actionsContants";
import { LogoutActionType } from "../Auth/AuthActions";

export interface RegisterUserActionType {
  type:
    | AuthActions.registerSuccess
    | AuthActions.registerFailed
    | AuthActions.registerLoading;
  payload: {
    data: RegisterResponse | RequestError | null;
    email: string | null;
  };
}
export const registerUserActionCreator = (registerForm: UserRegisterForm) => {
  return async (
    dispatch: ThunkDispatch<
      RegisterActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: AuthActions.registerLoading });
      const response = await registerService(registerForm);
      dispatch({
        type: AuthActions.registerSuccess,
        payload: {
          data: response.data as RegisterResponse,
          email: registerForm.email,
        },
      });
    } catch (error) {
      dispatch({
        type: AuthActions.registerFailed,
        payload: { data: handleError(error) },
      });
    }
  };
};

export interface UserActivationActionType {
  type:
    | AuthActions.activationLoading
    | AuthActions.activationSuccess
    | AuthActions.activationError;
  payload: RequestError | null;
}
export const userActivationActionCreator = (
  confirmationCode: string,
  userId: string
) => {
  return async (
    dispatch: ThunkDispatch<
      UserActivationActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: AuthActions.activationLoading });
      await activateUserService(confirmationCode, userId);
      dispatch({ type: AuthActions.activationSuccess });
    } catch (error) {
      dispatch({
        type: AuthActions.activationError,
        payload: handleError(error),
      });
    }
  };
};

export interface ResetRegisterActionType {
  type: AuthActions.resetRegister;
}
export const resetRegisterActionCreator = (): ResetRegisterActionType => {
  return { type: AuthActions.resetRegister };
};

export interface ResetAlertActionType {
  type: AuthActions.resetAuthAlert;
}
export const resetAlertActionCreator = (): ResetAlertActionType => {
  return { type: AuthActions.resetAuthAlert };
};

export interface UserActivationExistType {
  type: AuthActions.activationSuccess;
}
export const userActivationExist = (): UserActivationExistType => {
  return { type: AuthActions.activationSuccess };
};

export type RegisterActionType =
  | RegisterUserActionType
  | UserActivationActionType
  | ResetRegisterActionType
  | ResetAlertActionType
  | UserActivationExistType
  | LogoutActionType;
