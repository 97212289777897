import { RequestError } from "../../services/errorHandling";
import {
  FiraOwnerTeamMemberResponse,
  FiraUserResponse,
} from "../../types/Response";
import {
  FiraInvitation,
  FiraPermissions,
  FiraUserStore,
  InvitationForm,
  TeamMember,
} from "../../utils/types";
import { AuthActions, UserActions } from "../actionsContants";
import type { UserActionType } from "./UserActions";

export interface UserState {
  isLoading: boolean;
  info?: FiraUserResponse;
  invitation?: InvitationForm;
  team: TeamState;
  error?: RequestError;
  invitations: FiraInvitation[];
  moderators: FiraOwnerTeamMemberResponse[];
  updatedSuccess?: boolean;
}

export interface TeamState {
  isLoading: boolean;
  list: TeamMember[];
  error?: RequestError;
  sendSuccess?: boolean;
  updated?: FiraPermissions;
  currentTeamMember?: TeamMember;
  updatedUserPermissions?: boolean;
}

const initialState: UserState = {
  isLoading: false,
  team: { isLoading: false, list: [] },
  invitations: [],
  moderators: [],
};

export const userReducer = (
  state = initialState,
  action: UserActionType
): UserState => {
  switch (action.type) {
    case UserActions.getUserLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case UserActions.getUserSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        info: payload as FiraUserResponse,
      };
    }
    case UserActions.getUserFailed: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }
    case UserActions.updateUserLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case UserActions.updateUserSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        info: { ...state.info, ...payload } as FiraUserResponse,
        updatedSuccess: true,
      };
    }
    case UserActions.updateUserFailed: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }
    case UserActions.getUsersLoading: {
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: true,
          error: undefined,
        },
      };
    }
    case UserActions.getUsersSuccess: {
      const { payload } = action;
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: false,
          list: payload as TeamMember[],
        },
      };
    }

    case UserActions.getUsersFailed: {
      const { payload } = action;
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: false,
          error: payload as RequestError,
        },
      };
    }

    case UserActions.sendInvitationPermissionsLoading: {
      return {
        ...state,
        team: { ...state.team, isLoading: true, error: undefined },
      };
    }
    case UserActions.sendInvitationPermissionsSuccess: {
      return {
        ...state,
        team: {
          ...state.team,
          sendSuccess: true,
          isLoading: false,
        },
      };
    }
    case UserActions.sendInvitationPermissionsFailed: {
      const { payload } = action;
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: false,
          error: payload as RequestError,
        },
      };
    }
    case UserActions.updateUsersPermissionsLoading: {
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: true,
          error: undefined,
          updated: undefined,
        },
      };
    }
    case UserActions.updateUsersPermissionsSuccess: {
      const { payload } = action;
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: false,
          updated: payload as FiraPermissions,
        },
      };
    }

    case UserActions.updateUsersPermissionsFailed: {
      const { payload } = action;
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: false,
          error: payload as RequestError,
        },
      };
    }
    case UserActions.cleanMessage: {
      return {
        ...state,
        team: {
          ...state.team,
          updated: undefined,
          sendSuccess: false,
        },
        updatedSuccess: false,
      };
    }
    case UserActions.getInvitationListLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case UserActions.getInvitationListSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        invitations: payload as FiraInvitation[],
      };
    }
    case UserActions.getInvitationListError: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }
    case UserActions.acceptInvitationLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case UserActions.acceptInvitationSuccess: {
      const { invitationId } = action.payload as {
        userStore: FiraUserStore;
        invitationId: string;
      };
      const newInvitations = state.invitations.filter(
        (p) => invitationId.includes(p.id) === false
      );
      return { ...state, isLoading: false, invitations: newInvitations };
    }
    case UserActions.acceptInvitationError: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }
    case UserActions.rejectInvitationLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case UserActions.rejectInvitationSuccess: {
      const { payload } = action;
      const newInvitations = state.invitations.filter(
        (p) => (payload as string).includes(p.id) === false
      );
      return { ...state, isLoading: false, invitations: newInvitations };
    }
    case UserActions.rejectInvitationError: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }

    case UserActions.getModeratorsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case UserActions.getModeratorsSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        moderators: payload as FiraOwnerTeamMemberResponse[],
      };
    }

    case UserActions.getModeratorsFailed: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }

    case UserActions.updateUserPermissionsSuccess: {
      return {
        ...state,
        error: undefined,
        team: {
          ...state.team,
          updatedUserPermissions: true,
        },
      };
    }

    case UserActions.updateUserPermissionsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case UserActions.updateUsersPermissionsFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case UserActions.cleanUserPermissionsMessage: {
      return {
        ...state,
        team: {
          ...state.team,
          updatedUserPermissions: false,
        },
      };
    }

    case AuthActions.logout: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
