import {
  useRef,
  ChangeEvent,
  DragEvent,
  useState,
  useEffect,
  LegacyRef,
  SyntheticEvent,
} from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../utils/types";
import {
  uploadProductsFromFileService,
  updateProductsListService,
} from "../../services/upload";
import {
  getDuplicatedExcelService,
  getApprovedExcelService,
  getRejectedExcelService,
} from "../../services/download";
import { handleError } from "../../services/errorHandling";
import Button, { ButtonStyle } from "../Button/Button";
import { CheckCircleFill, CrossCircleFill, LineCircleFill } from "../FiraIcons";
import ModalLayout from "../../layout/ModalLayout/ModalLayout";
import { t } from "i18next";
import styles from "./ImportProductsModal.module.scss";
import { getStoreProductsActionCreator } from "../../store/Products/ProductsActions";
import { handleExcelFileDownload } from "../../utils/handleFileDownload";
import SvgCross from "../FiraIcons/Cross";
import PopUpInfo from "../PopUpInfo/PopUpInfo";
import InfoCircle from "../FiraIcons/InfoCircle";
import { PopUpAlertTypes } from "../PopUpAlert/PopUpAlert";

interface fileData {
  Cargados: string;
  Rechazados: string;
  Repetidos: string;
  Total: string;
  id?: string;
  storeId?: string;
}

const fileDataInitialState: fileData = {
  Cargados: "",
  Rechazados: "",
  Repetidos: "",
  Total: "",
};

enum ExcelType {
  duplicated = "Duplicated",
  rejected = "Rejected",
  approved = "Approved",
}

interface ImportProductsModalProps {
  isOpen: boolean;
  onClose(): void;
  sendAlert(id: string, type: PopUpAlertTypes, show: boolean): void;
}
const ImportProductsModal = ({
  isOpen = false,
  onClose,
  sendAlert,
}: ImportProductsModalProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { brand } = useSelector((state: RootState) => state);
  const [brandId, setBrandId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileData, setFileData] = useState<fileData>(fileDataInitialState);
  const [success, setSuccess] = useState<boolean>(false);
  const [fileName, setFileName] = useState("");
  const [inputFile, setInputFile] = useState<File>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (brand.currentStore) {
      setBrandId(brand.currentStore.id);
    }
  }, [brand.currentStore]);

  if (!isOpen) {
    return null;
  }

  const onDivClick = () => {
    inputRef.current?.click();
  };

  const resetInputHanlder = () => {
    if (inputRef.current != null) {
      inputRef.current.value = "";
    }
  };

  const handleClose = () => {
    resetInputHanlder();
    setSuccess(false);
    setIsLoading(false);
    onClose();
  };

  const HandleLoadFile = async () => {
    if (inputFile) {
      try {
        setIsLoading(true);
        const response = await uploadProductsFromFileService(
          inputFile,
          brandId
        );
        setSuccess(true);
        setFileData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.warn("Error: ", handleError(error));
        setIsLoading(false);
        resetInputHanlder();
      }
    }
  };

  const onInputChangeHandle = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const file = event.target.files[0];
      setInputFile(file);
      setFileName(file.name);
    }
  };

  const onDropChangeHandle = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setInputFile(file);
    setFileName(file.name);
  };

  const syncProducts = async () => {
    setIsLoading(true);
    if (fileData.id !== undefined && fileData.storeId !== undefined) {
      const id = fileData.id;
      const storeId = fileData.storeId;
      try {
        const response = await updateProductsListService(id, storeId);
        dispatch(getStoreProductsActionCreator(storeId));
        sendAlert(response.data, PopUpAlertTypes.success, true);
        handleClose();
      } catch (error) {
        console.warn("Error: ", handleError(error));
        sendAlert(
          "Ha ocurrido un error. Intente de nuevo más tarde.",
          PopUpAlertTypes.error,
          true
        );
        handleClose();
      }
    }
  };

  const handleDownload = async (type: ExcelType) => {
    if (!fileData.id) {
      return;
    }
    switch (type) {
      case ExcelType.rejected:
        try {
          const response = await getRejectedExcelService(fileData.id);
          const fileName = `${t(
            "modals.products.load_data_modal.rejected_file"
          )} ${new Date()}.xls`;
          handleExcelFileDownload(response.data, fileName);
        } catch (error) {
          console.warn("Error: ", handleError(error));
        }
        break;
      case ExcelType.duplicated:
        try {
          const response = await getDuplicatedExcelService(fileData.id);
          const fileName = `${t(
            "modals.products.load_data_modal.duplicated_file"
          )} ${new Date()}.xls`;
          handleExcelFileDownload(response.data, fileName);
        } catch (error) {
          console.warn("Error: ", handleError(error));
        }
        break;
      case ExcelType.approved:
        try {
          const response = await getApprovedExcelService(fileData.id);
          const fileName = `${t(
            "modals.products.load_data_modal.approved_file"
          )} ${new Date()}.xls`;
          handleExcelFileDownload(response.data, fileName);
        } catch (error) {
          console.warn("Error: ", handleError(error));
        }
        break;
    }
  };

  return ReactDOM.createPortal(
    <ModalLayout onClickHandle={!isLoading ? handleClose : undefined}>
      <div onClick={(e) => e.stopPropagation()}>
        {!success && (
          <LoadModal
            handleClose={handleClose}
            inputRef={inputRef}
            onInputChangeHandle={onInputChangeHandle}
            onDivHandleClick={onDivClick}
            onLoadButtonClick={HandleLoadFile}
            fileName={fileName}
            isLoading={isLoading}
            onDropChangeHandle={onDropChangeHandle}
          />
        )}
        {success && (
          <SuccessModal
            fileData={fileData}
            handleClose={handleClose}
            syncProducts={syncProducts}
            handleDownload={handleDownload}
            isLoading={isLoading}
          />
        )}
      </div>
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};

//FIRST MODAL
interface loadProductsProps {
  handleClose(): void;
  inputRef?: LegacyRef<HTMLInputElement>;
  onInputChangeHandle(event: ChangeEvent<HTMLInputElement>): void;
  onDropChangeHandle(event: DragEvent<HTMLDivElement>): void;
  onDivHandleClick(): void;
  onLoadButtonClick(): void;
  isLoading: boolean;
  fileName: string;
}
export const LoadModal = (props: loadProductsProps) => {
  const {
    handleClose,
    inputRef,
    onInputChangeHandle,
    onDropChangeHandle,
    onDivHandleClick,
    onLoadButtonClick,
    isLoading,
    fileName,
  } = props;
  return (
    <div className={styles.ImportProductsWrapper}>
      <div className={styles.Container}>
        <div className={styles.Title}>
          <div className={styles.items}>
            <p className={`body-14-text `}>
              {t("modals.products.load_modal.title")}
            </p>

            <SvgCross className={styles.cross} onClick={handleClose} />
          </div>
        </div>
        <div className={`${styles.Desc} ${styles["alert-text"]}`}>
          <div className={styles.descContainer}>
            <p className="body-14-text">
              {t("modals.products.load_modal.message_1")}{" "}
              <a href="https://firalivepro.blob.core.windows.net/templates/Plantilla de Cargue Masivo de Productos.xlsx">
                {t("modals.products.load_modal.message_2")}
              </a>{" "}
              {t("modals.products.load_modal.message_3")}
            </p>
            <div
              className={styles.dropZone}
              onClick={onDivHandleClick}
              onDrop={onDropChangeHandle}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              <p className="lead-text">
                {fileName.length
                  ? fileName
                  : t("modals.products.load_modal.drop_zone")}
              </p>
              <input
                type="file"
                ref={inputRef}
                onChange={onInputChangeHandle}
                style={{ display: "none" }}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>
          </div>
        </div>
        <div className={styles.ButtonsWrapper}>
          <Button
            buttonStyle={ButtonStyle.light}
            width="142px"
            height="32px"
            className={styles["lead-text"]}
            clickHandler={handleClose}
          >
            <div>{t("modals.products.load_modal.cancel_button")}</div>
          </Button>

          <Button
            width="142px"
            height="32px"
            clickHandler={onLoadButtonClick}
            isDisabled={isLoading}
            className={styles["lead-text"]}
          >
            <div>{t("modals.products.load_modal.load_button")}</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

//SECOND MODAL

interface SuccessModalProps {
  fileData: fileData;
  handleClose(): void;
  syncProducts(): void;
  handleDownload(type: ExcelType): void;
  isLoading: boolean;
}
export const SuccessModal = (props: SuccessModalProps) => {
  const [elemetTarget, setElementTarget] = useState<HTMLElement>();
  const [showInfo, setShowInfo] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { fileData, handleClose, syncProducts, handleDownload, isLoading } =
    props;

  const handleClickInfo = (e: SyntheticEvent<HTMLElement>) => {
    setShowInfo(showInfo ? false : true);
    setIsActive(!showInfo);
    setElementTarget(e.currentTarget);
  };
  const tablerows = [
    {
      name: t("modals.products.load_data_modal.valid"),
      products: fileData.Cargados,
      requestType: ExcelType.approved,
      icon: <CheckCircleFill className={`${styles.icon} ${styles.green}`} />,
      info: "",
    },
    {
      name: t("modals.products.load_data_modal.duplicated"),
      products: fileData.Repetidos,
      requestType: ExcelType.duplicated,
      icon: <LineCircleFill className={`${styles.icon} ${styles.yellow}`} />,
      info: "",
    },
    {
      name: t("modals.products.load_data_modal.rejected"),
      products: fileData.Rechazados,
      requestType: ExcelType.rejected,
      icon: <CrossCircleFill className={`${styles.icon} ${styles.red}`} />,
      info: (
        <div
          className={styles.info}
          onMouseEnter={handleClickInfo}
          onMouseLeave={handleClickInfo}
        >
          <InfoCircle color={isActive ? "#ffde07" : "#000"} />
        </div>
      ),
    },
  ];

  return (
    <div className={styles.LoadProductsWrapper}>
      <div className={`${styles.Title}`}>
        <p className={`body-14-text`}>
          {t("modals.products.load_data_modal.title")}
        </p>
      </div>
      <div className={styles.LoadProductsTable}>
        <table>
          <thead>
            <tr>
              <th className={styles["lead-text"]}>
                {t("modals.products.load_data_modal.state")}
              </th>
              <th className={styles["lead-text"]}>
                {t("modals.products.load_data_modal.quantity")}
              </th>
            </tr>
          </thead>
          <tbody>
            {tablerows.map((row) => (
              <tr key={row.name}>
                <td className={styles["lead-text"]}>
                  <div className={styles.group}>
                    {row.icon}
                    <p>{row.name}</p>
                    {row.info}
                    {showInfo && (
                      <PopUpInfo
                        title={t("modals.products.load_data_modal.info")}
                        elementRef={elemetTarget}
                      />
                    )}
                  </div>
                </td>
                <td className={styles["lead-text"]}>{row.products}</td>
                <td
                  className={`${styles.LoadProductsIcons} ${
                    row.products === "0" ? styles.disabled : ""
                  }`}
                >
                  <div onClick={() => handleDownload(row.requestType)}>
                    <p className="alert-text-14-bold ">
                      {t("modals.products.load_data_modal.download_file")}
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.ButtonsWrapper}>
        <Button
          width="142px"
          height="32px"
          buttonStyle={ButtonStyle.light}
          clickHandler={handleClose}
        >
          <div className={styles["lead-text"]}>
            {t("modals.products.load_data_modal.cancel_button")}
          </div>
        </Button>
        <Button
          width="142px"
          height="32px"
          clickHandler={syncProducts}
          isDisabled={fileData.Cargados === "0" || isLoading}
        >
          <div className={styles["lead-text"]}>
            {t("modals.products.load_data_modal.save_button")}
          </div>
        </Button>
      </div>
    </div>
  );
};

export default ImportProductsModal;
