import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCircleX = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle
      cx={15}
      cy={15}
      r={10}
      transform="rotate(-45 15 15)"
      fill="currentColor"
    />
    <path
      d="m13.008 17.537 5.074-5.074M18.082 17.537l-5.074-5.074"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      fill="currentColor"
    />
  </svg>
);

export default SvgCircleX;
