import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import stylesheet from "./ProductPermissions.module.scss";
import { RootState } from "../../../store/Store";
import { Icon } from "@iconify-icon/react";
import Checkbox from "../../Checkbox/Checkbox";
import { AlertType, usePopupAlert } from "../../../providers/AlertProvider";
import { FiraStore } from "../../../types/Stores";
import Button, { ButtonStyle } from "../../Button/Button";
import {
  getProductSettings,
  postProductSettings,
  postStoreListForProductSettings,
} from "../../../services/settings";
import Input, { InputStyles } from "../../Input/Input";
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";

enum PermissionType {
  CAN_CREATE,
  CAN_EDIT,
}

const ProductPermissions: React.FC = () => {
  const { t } = useTranslation();
  const { brand } = useSelector((state: RootState) => state);
  const [search, setSearch] = React.useState("");
  const [initialState, setInitialState] = React.useState<{
    canEdit: boolean;
    canCreate: boolean;
    stores: string[];
  }>({
    canEdit: true,
    canCreate: true,
    stores: [],
  });
  const [form, setForm] = React.useState<{
    canEdit: boolean;
    canCreate: boolean;
    stores: string[];
  }>(initialState);
  const [isChanged, setIsChanged] = React.useState(false);
  const [filterList, setFilterList] = React.useState(
    brand.list.filter((s) => s.state === "ACTIVE")
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const showAlert = usePopupAlert();

  const toggleAll = () => {
    if (
      form.stores.length ===
      brand.list.filter((s) => s.state === "ACTIVE").length
    ) {
      setForm({ ...form, stores: [] });
      return;
    }

    setForm({
      ...form,
      stores: brand.list.filter((s) => s.state === "ACTIVE").map((s) => s.id),
    });
  };

  const onStoreSelected = (store: FiraStore) => {
    if (form.stores.includes(store.id)) {
      setForm({ ...form, stores: form.stores.filter((s) => s !== store.id) });
      return;
    }
    setForm({ ...form, stores: [...form.stores, store.id] });
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setFilterList(
      brand.list.filter(
        (s) =>
          s.name.toLowerCase().includes(event.target.value.toLowerCase()) &&
          s.state === "ACTIVE"
      )
    );
  };

  const togglePermission = (permission: PermissionType) => {
    switch (permission) {
      case PermissionType.CAN_CREATE:
        setForm({
          ...form,
          canCreate: !form.canCreate,
          stores: !form.canCreate === true && form.canEdit ? [] : form.stores,
        });
        break;
      case PermissionType.CAN_EDIT:
        setForm({
          ...form,
          canEdit: !form.canEdit,
          stores: form.canCreate && !form.canEdit === true ? [] : form.stores,
        });
        break;
    }
  };

  const handleCancelAction = () => {
    setForm(initialState);
  };

  const handleSubmitAction = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await postProductSettings({ ...form });
      await postStoreListForProductSettings(form.stores);
      showAlert(AlertType.success, t("alerts.success"));
    } catch (error) {
      showAlert(AlertType.error, JSON.stringify(error));
    } finally {
      loadSettings();
    }
  };

  const loadSettings = async () => {
    setIsLoading(true);
    try {
      const { storeIds, defaultStoreProductConfig } =
        await getProductSettings();
      setInitialState({
        ...initialState,
        stores: storeIds,
        canCreate: defaultStoreProductConfig
          ? defaultStoreProductConfig.canCreate
          : true,
        canEdit: defaultStoreProductConfig
          ? defaultStoreProductConfig.canEdit
          : true,
      });
      setForm({
        ...form,
        stores: storeIds,
        canCreate: defaultStoreProductConfig
          ? defaultStoreProductConfig.canCreate
          : true,
        canEdit: defaultStoreProductConfig
          ? defaultStoreProductConfig.canEdit
          : true,
      });
    } catch (error) {
      showAlert(AlertType.error, JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  const renderStoreList = () => {
    return (
      <ul style={List}>
        {isLoading && (
          <div style={ListLoadingWrapper}>
            <Icon size={32} color="#ffde07" icon="line-md:loading-loop" />
          </div>
        )}
        <li onClick={toggleAll} style={Item}>
          <div>
            <Checkbox
              onChange={toggleAll}
              isActive={
                form.stores.length ===
                brand.list.filter((s) => s.state === "ACTIVE").length
              }
            />
          </div>
          <span style={ItemText}>Seleccionar Todo</span>
        </li>
        {filterList.map((store) => {
          return (
            <li
              onClick={() => onStoreSelected(store)}
              key={store.id}
              style={Item}
            >
              <div>
                <Checkbox
                  onChange={() => onStoreSelected(store)}
                  isActive={form.stores.includes(store.id)}
                />
              </div>
              <span style={ItemText}>{store.name}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  React.useEffect(() => {
    setIsChanged(JSON.stringify(form) !== JSON.stringify(initialState));
  }, [form, initialState]);

  React.useEffect(() => {
    loadSettings();
  }, []);

  return (
    <div className={stylesheet.Wrapper}>
      <form className={stylesheet.Form} onSubmit={handleSubmitAction}>
        <div className={stylesheet.Title}>
          {t("components.settings.product.title")}
        </div>
        <div className={stylesheet.FormWrapper}>
          {(form.canCreate && form.canEdit) === false && (
            <>
              <Input
                width="100%"
                biggerIcon
                fontSize="15px"
                placeholder={t("inputs.searchPlaceholder")}
                value={search}
                onChange={onSearchChange}
                inputStyle={InputStyles.searchConfig}
                icon={<Icon icon="mynaui:search" />}
              />
              {renderStoreList()}
            </>
          )}

          <div className={stylesheet.Subtitle}>
            {t("components.settings.product.subtitle")}
          </div>
          <div className={stylesheet.PermissionWrapper}>
            <span>
              {t("components.settings.product.canCreate")}{" "}
              <span>{t("components.settings.product.create")}</span>{" "}
              {t("components.settings.product.canCreateCon")}
            </span>
            <ToggleSwitch
              hideTags
              checked={form.canCreate}
              handleChecked={() => togglePermission(PermissionType.CAN_CREATE)}
            />
          </div>
          <div className={stylesheet.PermissionWrapper}>
            <span>
              {t("components.settings.product.canEdit")}{" "}
              <span>{t("components.settings.product.edit")}</span>{" "}
              {t("components.settings.product.canEditCon")}
            </span>
            <ToggleSwitch
              hideTags
              checked={form.canEdit}
              handleChecked={() => togglePermission(PermissionType.CAN_EDIT)}
            />
          </div>
        </div>
        <div className={stylesheet.FormButtons}>
          <Button
            buttonStyle={ButtonStyle.light}
            clickHandler={handleCancelAction}
            type="button"
          >
            {t("buttons.cancel")}
          </Button>
          <Button type="submit" isDisabled={!isChanged || isLoading}>
            {t("buttons.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

const List: React.CSSProperties = {
  position: "relative",
  listStyle: "inside",
  maxHeight: 300,
  minHeight: 250,
  overflowY: "auto",
  listStyleType: "none",
  backgroundColor: "#F7F8F9",
  borderRadius: 8,
  padding: 9,
};

const ListLoadingWrapper: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(1, 1, 1, 0.4)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Item: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 10,
  padding: 8,
  borderBottom: "1px solid #D5DDE0",
};

const ItemText: React.CSSProperties = {
  fontSize: 12,
  lineHeight: "24px",
};

export default ProductPermissions;
