import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCollectionsActionCreator,
  resetCollectionListActionCreator,
} from "../../../store/Collection/CollectionActions";
import { getStoreEventsActionCreator } from "../../../store/Event/EventActions";
import { RootState } from "../../../store/Store";
import { FiraCollection, FiraEvent, VideoFilter } from "../../../utils/types.d";
import { RoundedX } from "../../FiraIcons";
import styles from "./SelectByName.module.scss";

export enum NameSelectType {
  collection = "COLLECTION",
  event = "EVENT",
}

interface NameSelectProps {
  title?: string;
  type: NameSelectType;
  onSelect?(selection: FiraEvent | FiraCollection | null): void;
  optional?: boolean;
  multipleSelection?: boolean;
  onMultipleSelect?(selection: FiraCollection[] | null): void;
}

const SelectByName = ({
  title,
  type = NameSelectType.collection,
  onSelect,
  optional,
  multipleSelection = false,
  onMultipleSelect,
}: NameSelectProps) => {
  const { brand, collection, event } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [selectedCollection, setSelectedCollection] =
    useState<FiraCollection | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<FiraEvent | null>(null);
  const [eventList, setEventList] = useState<FiraEvent[]>([]);
  const [collectionList, setCollectionList] = useState<FiraCollection[]>([]);
  const [firstFetch, setFirstFetch] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCollectionList, setSelectedCollectionList] = useState<
    FiraCollection[]
  >([]);
  const [maxLimit, setMaxLimit] = useState<boolean>(false);

  const handleColSelection = (sel: FiraCollection) => {
    setIsOptionsOpen(false);
    setSelectedCollection(sel);
    if (onSelect) onSelect(sel);
  };

  const handleEvSelection = (sel: FiraEvent) => {
    setIsOptionsOpen(false);
    setSelectedEvent(sel);
    if (onSelect) onSelect(sel);
  };

  const getCollections = () => {
    if (brand.currentStore) {
      dispatch(
        getCollectionsActionCreator(
          brand.currentStore.id,
          20,
          0,
          VideoFilter.newer
        )
      );
    }
  };

  const handleMultipleSelectionCollection = (collection: FiraCollection) => {
    if (selectedCollectionList.includes(collection)) {
      return;
    }

    setSelectedCollectionList((collectionList) => [
      ...collectionList,
      collection,
    ]);

    setIsOptionsOpen(false);
  };

  const removeFromSelectionList = (collection: FiraCollection) => {
    if (selectedCollectionList.includes(collection)) {
      setSelectedCollectionList((collectionList) => [
        ...collectionList.filter((c) => collection.id !== c.id),
      ]);

      return;
    }
  };

  const getEvents = () => {
    if (brand.currentStore) {
      dispatch(getStoreEventsActionCreator(brand.currentStore.id));
    }
  };

  const getName = () => {
    if (type === NameSelectType.collection) {
      return selectedCollection !== null
        ? selectedCollection?.collectionName
        : t("components.selectByNameWidget.select");
    } else if (type === NameSelectType.event) {
      return selectedEvent !== null
        ? selectedEvent?.eventName
        : t("components.selectByNameWidget.select");
    }
  };

  const handleRemoveCollection = () => {
    if (onSelect) onSelect(null);
    setSelectedCollection(null);
    setIsOptionsOpen(false);
  };

  useEffect(() => {
    if (!collection.isLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    if (collection.list && firstFetch) {
      setCollectionList(collection.list);
    }
  }, [collection]);

  useEffect(() => {
    if (!event.isLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    if (event.list && firstFetch) {
      setEventList(event.list);
    }
  }, [event]);

  useEffect(() => {
    if (type === NameSelectType.collection) {
      getCollections();
      setFirstFetch(false);
    } else {
      getEvents();
      setFirstFetch(false);
    }
  }, []);

  useEffect(() => {
    if (selectedCollectionList.length >= 8) {
      setMaxLimit(true);
    } else {
      setMaxLimit(false);
    }

    if (!maxLimit && onMultipleSelect) {
      onMultipleSelect(selectedCollectionList);
    } else {
      return;
    }
  }, [selectedCollectionList]);

  useEffect(() => {
    return () => {
      dispatch(resetCollectionListActionCreator());
    };
  }, [dispatch]);

  return (
    <>
      <div className={styles.CollectionSelectWrapper}>
        <div className={styles.CollectionSelectWrapper__Title}>
          <p>{title?.toUpperCase()}</p>
          <p>
            {optional &&
              t("components.selectByNameWidget.optional").toLowerCase()}
          </p>
        </div>
        <div
          className={`${isOptionsOpen ? styles.openShadow : ""} ${
            styles.CollectionSelect
          } `}
          style={
            selectedCollectionList.length > 0 ? { paddingBottom: "16px" } : {}
          }
        >
          <div
            className={`${isOptionsOpen ? styles.isOpen : ""} ${
              styles.CollectionSelect__Input
            } ${maxLimit ? styles.disabled : ""}`}
          >
            {" "}
            <div
              className={`${styles.CollectionName}`}
              onClick={() => setIsOptionsOpen(!isOptionsOpen)}
            >
              {getName()}
            </div>
            {(selectedCollection !== null || selectedEvent !== null) && (
              <div
                className={styles.Icon}
                onClick={
                  type === NameSelectType.collection
                    ? () => handleRemoveCollection()
                    : () => (setSelectedEvent(null), setIsOptionsOpen(false))
                }
              >
                <RoundedX />
              </div>
            )}
          </div>
          {isOptionsOpen && (
            <div className={styles.CollectionSelect__Options}>
              {!isLoading &&
                (type === NameSelectType.collection
                  ? collection.list.map((item, i) => (
                      <li
                        key={i}
                        onClick={
                          multipleSelection
                            ? () => handleMultipleSelectionCollection(item)
                            : () => handleColSelection(item)
                        }
                        className={
                          selectedCollectionList.includes(item)
                            ? styles.disabled
                            : ""
                        }
                      >
                        {item.collectionName}
                      </li>
                    ))
                  : event.list.map((item, i) => (
                      <li key={i} onClick={() => handleEvSelection(item)}>
                        {item.eventName}
                      </li>
                    )))}

              {isLoading && (
                <div className={styles.loaderContainer}>
                  <span className={styles.loader}></span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.SelectedOptionsList}>
          {selectedCollectionList.map((item, i) => (
            <div key={i} className={styles.SelectedOptionsList__Item}>
              <span>{item.collectionName}</span>
              <div
                className={styles.SelectedOptionsList__Item__Icon}
                onClick={() => removeFromSelectionList(item)}
              >
                <RoundedX />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SelectByName;
