import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

const SvgPinMessage = ({
                    title,
                    titleId,
                    ...props
                }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11.915.371a1.269 1.269 0 00-2.1.496l-.692 2.295-2.785 2.783c-3.025-.685-3.713 0-4.641.928-.496.495-.496 1.36 0 1.856l1.874 1.873-3.19 3.189c-.496.495-.514 1.342-.018 1.838.495.495 1.36.495 1.856 0l3.191-3.19 1.861 1.86c.495.496 1.357.491 1.852-.004.929-.928 1.614-1.615.929-4.639l2.784-2.783 2.296-.691a1.268 1.268 0 00.496-2.1L11.915.373z"
            clipRule="evenodd"
        ></path>
    </svg>

);

export default SvgPinMessage;
