import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  PopUpAlert,
  TableButton,
  TableCell,
  TableHead,
  TableHeader,
  TableImage,
  TableRow,
  ViewHeader,
} from "../../components";
import {
  Edit,
  CodeTag,
  Statistics,
  EmptyPieChartWidget,
  ChatBubble,
  ArrowDownFill,
} from "../../components/FiraIcons";
import { shortYearDate } from "../../utils/handleDates";
import styles from "./VideosView.module.scss";
import { FiraVideo, RootState, VideoFilter } from "../../utils/types.d";
import { InfiniteTable, orderingType } from "../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  getVideosActionCreator,
  resetVideoListActionCreator,
  setCurrentVideoActionCreator,
  updateVisibilityCreator,
} from "../../store/Video/VideoActions";
import Skeleton from "../../components/Skeleton/Skeleton";
import noImageStoreSVG from "../../assets/svg/no-image-store.svg";
import { useDebounce } from "../../utils/useDebounce";
import CustomSelect, {
  CustomSelectStyle,
  optionType,
} from "../../components/CustomSelect/CustomSelect";
import InsertVideo from "../../components/Modals/InsertVideo/InsertVideo";
import { PopUpAlertTypes } from "../../components/PopUpAlert/PopUpAlert";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import FiraBadge from "../../components/FiraBadge";

let timerID: NodeJS.Timeout;
let tableLoaderTimer: NodeJS.Timeout;

enum filteringType {
  sort = "SORT",
  byword = "BYWORD",
}
enum visibilityType {
  hide = "HIDE",
  visible = "VISIBLE",
}

const VideosView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brand, video, user } = useSelector((state: RootState) => state);

  const [filterValue, setFilterValue] = useState("");
  const [videoList, setVideoList] = useState<FiraVideo[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [insertModalIsOpen, setInsertModalIsOpen] = useState(false);
  //el debounced se activa al iniciar el componente ya que recibe el valor vacio del filtro
  //este state evita que el debounced ejecute la funcion en el useEffect
  const [firstLoad, setFirstLoad] = useState(true);

  //filtros de acciones comentados porque aun no van
  const debounced = useDebounce(filterValue, 800);
  const orderingList = [
    {
      id: 1,
      name: t("views.videos.filterOptions.recent"),
      value: VideoFilter.newer,
    },
    {
      id: 2,
      name: t("views.videos.filterOptions.older"),
      value: VideoFilter.older,
    },
    {
      id: 3,
      name: t("views.videos.filterOptions.alphabet"),
      value: VideoFilter.ascend,
    },
    {
      id: 4,
      name: t("views.videos.filterOptions.reverse"),
      value: VideoFilter.descend,
    },
    // {
    //   id: 5,
    //   name: t("views.videos.filterOptions.viewed"),
    //   value: VideoFilter.mostViewed,
    // },
    // {
    //   id: 6,
    //   name: t("views.videos.filterOptions.like"),
    //   value: VideoFilter.mostLiked,
    // },
    // {
    //   id: 7,
    //   name: t("views.videos.filterOptions.share"),
    //   value: VideoFilter.mostShared,
    // },
  ];
  const rowOptions = [
    {
      id: 1,
      name: t("views.videos.table.options.hide"),
      value: visibilityType.hide,
    },
    {
      id: 2,
      name: t("views.videos.table.options.visible"),
      value: visibilityType.visible,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState<orderingType>(
    orderingList[0]
  );

  const usersHeader = [
    { name: "", size: "8px" },
    { name: "", size: "100px" },
    { name: t("views.videos.table.name"), size: "300px" },
    { name: "", size: "200px" },
    { name: t("views.videos.table.type"), size: "" },
    { name: t("views.videos.table.visibility"), size: "" },
    { name: t("views.videos.table.date"), size: "" },
    { name: t("views.videos.table.category"), size: "" },
    { name: "", size: "50px" },
  ];

  const header = (
    <TableRow>
      {usersHeader.map((head, i) => (
        <TableHead key={i} size={head.size} centerContent={i !== 2}>
          {head.name}
        </TableHead>
      ))}
    </TableRow>
  );

  const handleSelectRowOption = (id: number, selectId: string) => {
    dispatch(updateVisibilityCreator(selectId));
  };

  const process = (state: string) => {
    return state === "PROCESSING" ? (
      <div className={styles.videoState}>
        <p>{t("views.videos.table.state.processing")}</p>
      </div>
    ) : (
      <div className={`${styles.videoState} ${styles.failed}`}>
        <p>{t("views.videos.table.state.failed")}</p>
      </div>
    );
  };

  const selectIcon = (
    <div className={styles.selectIcon}>
      <ArrowDownFill style={{ fontSize: "10px" }} />
    </div>
  );
  const body = videoList.map((video, i) => {
    return (
      <TableRow key={video.id} disabled={video.status === "PROCESSING"}>
        <TableCell size="8px"></TableCell>
        <TableCell>
          <TableImage
            src={
              video.eventPreviewImage
                ? video.eventPreviewImage + "?" + Math.random()
                : noImageStoreSVG
            }
          />
        </TableCell>
        <TableCell size="300px">
          <div className={`${styles.nameCell} `}>
            <p className={`body-14-text ${styles.ellipsis}`}>
              {video.videoName}
            </p>
            <div className={styles.icons}>
              <ChatBubble className={`${styles.icon} ${styles.inactive}`} />
              <Statistics
                className={`${styles.icon} ${
                  video.visibleTimeMillis !== 0 ||
                  video.visibility !== visibilityType.hide
                    ? styles.click
                    : styles.inactive
                }`}
                onClick={
                  video.visibleTimeMillis !== 0 ||
                  video.visibility !== visibilityType.hide
                    ? () => statsViewHandler(video)
                    : () => {}
                }
              />
              <Edit
                className={`${styles.icon}  ${
                  video.visibility !== visibilityType.hide
                    ? styles.inactive
                    : styles.click
                }`}
                onClick={
                  video.visibility == visibilityType.hide
                    ? () => EditVideoViewHandler(video)
                    : () => {}
                }
              />
              <p className={`${styles.icon} ${styles.click}`}>
                <CodeTag onClick={() => handleOpenInsert(video)} />
              </p>
            </div>
          </div>
        </TableCell>
        <TableCell centerContent>
          {(video.status === "PROCESSING" || video.status === "FAILED") &&
            process(video.status)}
        </TableCell>
        <TableCell centerContent>
          {video.isParent ? (
            <FiraBadge>Simulcast</FiraBadge>
          ) : !video.isParent && video.parentId === null ? (
            <FiraBadge color="#F7F8F9" textColor="#000">
              Individual
            </FiraBadge>
          ) : (
            <FiraBadge>Simulcast</FiraBadge>
          )}
        </TableCell>
        <TableCell centerContent>
          <div className={`${styles.selectVisibility} `}>
            {/* {brand.currentStore?.admin_userId == user.info?.id ? ( */}
            <CustomSelect
              width="89px"
              height="22px"
              selectId={video.id}
              options={
                video.visibility == visibilityType.hide
                  ? [rowOptions[1]]
                  : [rowOptions[0]]
              }
              value={
                video.visibility == visibilityType.hide
                  ? rowOptions[0].name
                  : rowOptions[1].name
              }
              handleSelected={handleSelectRowOption}
              selectStyle={CustomSelectStyle.main}
              icon={selectIcon}
              center
            />
            {/* revisar permisos de pantallas
            // ) : video.visibility == visibilityType.hide ? (
            //   rowOptions[0].name
            // ) : (
            //   rowOptions[1].name
            // )} */}
          </div>
        </TableCell>
        <TableCell centerContent>
          <div>
            <p className="body-14-text">
              {shortYearDate(new Date(video.creationDate))}
            </p>
          </div>
        </TableCell>
        <TableCell>
          <div>
            <p className="body-14-text">
              {video.categories !== null
                ? video.categories[0]?.description
                : "-"}
            </p>
          </div>
        </TableCell>
        <TableCell size="50px"></TableCell>
      </TableRow>
    );
  });

  const commentsViewHandler = (video: FiraVideo) => {
    dispatch(setCurrentVideoActionCreator(video));
    navigate(`comments`);
  };

  const statsViewHandler = (video: FiraVideo) => {
    dispatch(setCurrentVideoActionCreator(video));
    navigate(`stats`);
  };

  const EditVideoViewHandler = (video: FiraVideo) => {
    dispatch(setCurrentVideoActionCreator(video));
    navigate("edit");
  };

  const handleOpenInsert = (video: FiraVideo) => {
    dispatch(setCurrentVideoActionCreator(video));
    setInsertModalIsOpen(true);
  };

  const handleVideoFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
  };

  const handleDebouncedFilter = () => {
    if (filterValue.length != 0) {
      dispatch(
        getVideosActionCreator(
          0,
          20,
          false,
          filterValue,
          filteringType.byword,
          String(selectedFilter.value),
          filterValue,
          brand.currentStore ? brand.currentStore.id : undefined
        )
      );
    } else {
      dispatch(
        getVideosActionCreator(
          0,
          20,
          false,
          String(selectedFilter.value),
          filteringType.sort,
          String(selectedFilter.value),
          filterValue,
          brand.currentStore ? brand.currentStore.id : undefined
        )
      );
    }
  };

  const handleOrdering = (id: number) => {
    let selected = orderingList.filter((p) => {
      return p.id === id;
    });
    setSelectedFilter(selected[0]);
  };

  const loadMoreData = () => {
    tableLoaderTimer = setTimeout(() => {
      if (filterValue.length > 0 && videoList.length >= 20) {
        dispatch(
          getVideosActionCreator(
            videoList.length + 1,
            videoList.length + 20,
            true,
            filterValue,
            filteringType.byword,
            String(selectedFilter.value),
            filterValue,
            brand.currentStore ? brand.currentStore.id : undefined
          )
        );
      } else {
        dispatch(
          getVideosActionCreator(
            videoList.length + 1,
            videoList.length + 20,
            true,
            String(selectedFilter.value),
            filteringType.sort,
            String(selectedFilter.value),
            filterValue,
            brand.currentStore ? brand.currentStore.id : undefined
          )
        );
      }
    }, 1000);
  };

  const handleShowAlert = () => {
    setShowAlert(true);
    timerID = setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const handleCloseAlert = () => {
    if (showAlert) {
      clearTimeout(timerID);
      setMessage("");
      setShowAlert(false);
    }
  };

  useEffect(() => {
    dispatch(resetVideoListActionCreator());
  }, []);

  useEffect(() => {
    dispatch(
      getVideosActionCreator(
        0,
        20,
        false,
        String(selectedFilter.value),
        filteringType.sort,
        String(selectedFilter.value),
        filterValue,
        brand.currentStore ? brand.currentStore.id : undefined
      )
    );
    setFirstLoad(false);
  }, [selectedFilter]);

  useEffect(() => {
    !firstLoad && handleDebouncedFilter();
  }, [debounced]);

  useEffect(() => {
    setVideoList(video.list);
    if (!video.isLoading && !video.error) {
      setVideoList(video.list);
      clearTimeout(tableLoaderTimer);
    }
    if (!video.isLoading && video.error) {
      //offset es greater than last index: "no hay mas data que cargar"
      if (
        !video.error.message.includes("The offset is greater than last index")
      ) {
        handleShowAlert();
        setMessage(video.error.message);
      }
    }
  }, [video.list, video.error, video]);

  let titleSection;
  {
    titleSection = (
      <span className={`h3 ${styles.LimitText}`}>
        {t("views.videos.title")}
      </span>
    );
  }

  return (
    <>
      <FiraNavBar titleSection={titleSection}></FiraNavBar>
      {showAlert && (
        <PopUpAlert
          width={"100%"}
          type={PopUpAlertTypes.error}
          title={message}
          handleCloseIcon={handleCloseAlert}
        />
      )}
      <TableHeader
        hideCheckBox
        searchInputValue={filterValue}
        searchInputChangeHandle={handleVideoFiltering}
        orderingList={orderingList}
        orderingValue={selectedFilter}
        orderingClick={handleOrdering}
      />
      <div className={styles.infiniteTableWrapper}>
        {videoList.length > 0 || video.isLoading ? (
          <InfiniteTable
            header={header}
            body={
              videoList.length === 0 && video.isLoading
                ? SkeletonTableBody
                : body
            }
            rowsNumber={videoList.length}
            loadData={() => loadMoreData()}
            rowLoader={<RowLoader />}
            hasMore={videoList.length >= 20 && !video.error}
          />
        ) : (
          <EmptyVideosView />
        )}
      </div>
      {insertModalIsOpen && (
        <InsertVideo
          isOpen={insertModalIsOpen}
          onClose={() => setInsertModalIsOpen(false)}
        />
      )}
    </>
  );
};

const placeholder = [
  { id: 1, name: "placeholder" },
  { id: 2, name: "placeholder" },
  { id: 3, name: "placeholder" },
  { id: 4, name: "placeholder" },
  { id: 5, name: "placeholder" },
  { id: 6, name: "placeholder" },
  { id: 7, name: "placeholder" },
];

const SkeletonTableBody = placeholder.map((data) => {
  return (
    <TableRow key={data.id}>
      <TableCell size="8px"></TableCell>
      <TableCell>
        <Skeleton width="50px" height="50px" />
      </TableCell>
      <TableCell size="200px">
        <div className={styles.nameCell}>
          <Skeleton width="200px" />
        </div>
      </TableCell>
      <TableCell size="50px"></TableCell>
      <TableCell centerContent>
        <div className={`${styles.topCells} ${styles.selectVisibility} `}>
          <Skeleton />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={styles.topCells}>
          <Skeleton width="100px" />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={styles.topCells}>
          <Skeleton width="150px" />
        </div>
      </TableCell>
      <TableCell size="50px">
        <TableButton />
      </TableCell>
    </TableRow>
  );
});

const EmptyVideosView = () => {
  return (
    <div className={styles.emptyWrapper}>
      <EmptyPieChartWidget />
    </div>
  );
};

const RowLoader = () => {
  return (
    <table className={styles.fakeTable}>
      <tr className={styles.fakeRow} key={"loader"}>
        <td
          className={`${styles.first} ${styles.fakeCell}`}
          style={{ width: "8px" }}
        ></td>
        <td
          className={styles.fakeCell}
          style={{ width: "57px", minWidth: "50px" }}
        >
          <Skeleton width="50px" height="50px" />
        </td>
        <td
          className={styles.fakeCell}
          style={{ width: "300px", minWidth: "200px" }}
        >
          {/* por alguna razon esta celda agrega un margen interno, asi que lo cancelamos con marginRight */}
          <div style={{ marginRight: "-41px", width: "fit-content" }}>
            <Skeleton width="300px" />
          </div>
        </td>
        <td
          className={styles.fakeCell}
          style={{ width: "100px", minWidth: "100px" }}
        >
          {/* por alguna razon esta celda agrega un margen interno, asi que lo cancelamos con marginRight */}
          <div style={{ marginRight: "-41px", width: "fit-content" }}>
            <Skeleton width="100px" />
          </div>
        </td>
        <td className={styles.fakeCell} style={{ minWidth: "100px" }}>
          <div className={styles.centered}>
            <Skeleton width="50px" />
          </div>
        </td>
        <td className={styles.fakeCell} style={{ minWidth: "200px" }}>
          <div className={styles.centered}>
            <Skeleton width="100px" />
          </div>
        </td>
        <td className={styles.fakeCell} style={{ minWidth: "200px" }}>
          <div className={styles.centered}>
            <Skeleton width="100px" />
          </div>
        </td>
        <td
          className={`${styles.last} ${styles.fakeCell}`}
          style={{ width: "50px" }}
        ></td>
      </tr>
    </table>
  );
};

export default VideosView;
