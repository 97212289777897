import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCollections = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.106 3.447 5.789 6.106c-.78.39-1.171.585-1.171.894 0 .309.39.504 1.17.894l5.318 2.659c.438.22.658.329.894.329s.456-.11.894-.33l5.317-2.658c.78-.39 1.171-.585 1.171-.894 0-.309-.39-.504-1.17-.894l-5.318-2.659c-.438-.22-.658-.329-.894-.329s-.456.11-.894.33Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.022 10.49c.604.323 1.315.679 2.134 1.088l1.055.528c.878.439 1.317.658 1.79.658.471 0 .91-.219 1.788-.658l1.056-.528c.818-.409 1.53-.764 2.133-1.089l1.233.617c.78.39 1.171.585 1.171.894 0 .31-.39.504-1.17.895l-5.317 2.658c-.44.22-.659.33-.895.33s-.455-.11-.894-.33l-5.317-2.658c-.78-.39-1.17-.586-1.17-.895 0-.309.39-.504 1.17-.894l1.233-.617Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.022 15.49c.604.323 1.315.68 2.134 1.088l1.055.528c.878.44 1.317.659 1.79.659.471 0 .91-.22 1.788-.659l1.056-.528c.818-.409 1.53-.764 2.134-1.089l1.232.617c.78.39 1.171.585 1.171.894 0 .31-.39.504-1.17.895l-5.317 2.658c-.44.22-.659.329-.895.329s-.455-.11-.894-.33l-5.317-2.657c-.78-.39-1.17-.586-1.17-.895 0-.309.39-.504 1.17-.894l1.233-.617Z"
      fill="#000"
    />
  </svg>
);

export default SvgCollections;
