import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components, Theme } from "react-select";
import styles from "./SelectCheckbox.module.scss";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: any) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "left",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    alignItem: "left",
    alignContent: "start",
    justifyContent: "start",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <div
        className={`${styles.CheckboxWrapper} ${
          isSelected ? styles.IsActive : ""
        } ${isFocused ? styles.IsFocus : ""} ${
          isDisabled ? styles.IsDisabled : ""
        }`}
      >
        <input
          className={styles.Checkbox}
          name={"name"}
          type="checkbox"
          checked={isSelected}
          disabled={isDisabled}
          onChange={(e) => {}}
        />
        <div className={styles.CheckboxButton}></div>
      </div>
      {children}
    </components.Option>
  );
};

export interface itemsOption {
  value: String;
  label: String;
  isSelected: boolean;
}
const allOptions: itemsOption[] = [
  { value: "Messages", label: "Messages", isSelected: false },
  { value: "Clicks", label: "Clicks", isSelected: false },
  { value: "Share", label: "Share", isSelected: false },
  { value: "Likes", label: "Likes", isSelected: false },
  { value: "Audience", label: "Audience", isSelected: false },
];

interface SelectProps {
  name?: String;
  data?: itemsOption[];
  onChange(items: itemsOption[]): void;
}
const SelectCheckbox = ({ name, data, onChange }: SelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<itemsOption[]>(
    data ? data : allOptions
  );
  const { t } = useTranslation();

  //CUSTOM STYLE DEL COMPONENTE SELECT
  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      marginTop: 0,
    }),
    container: (provided: any) => ({
      ...provided,
      width: 140,
      fontSize: "12px",
      paddingTop: 7,
      paddingBottom: 7,
    }),
    control: (provided: any) => ({
      ...provided,
      minHeight: "30px",
      height: "32px",
      boxShadow: "none",
      borderColor: "#e5e5e5",
      "&:hover": {
        color: "#e5e5e5",
        border: provided.isFocused ? "1px solid #ffde07" : "1px solid #ffde07",
      },
    }),
    input: (provided: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: "30px",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "#000000",
      fontWeight: 500,
    }),
  };

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    data && setSelectedOptions(data);
  }, [data]);

  return (
    <div>
      <Select
        placeholder={t("views.videos.selects.filter")}
        styles={customStyles}
        isMulti
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        value={selectedOptions}
        onChange={(options) => {
          if (Array.isArray(options)) {
            setSelectedOptions(options.map((opt) => opt));
          }
        }}
        options={allOptions}
        components={{
          Option: InputOption,
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

export default SelectCheckbox;
