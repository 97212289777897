import { t } from "i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateFormat } from "../../../../utils/handleDates";
import Button, { ButtonStyle } from "../../../Button/Button";
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../Table/Table";

import styles from "./UserPermissionsTable.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../utils/types";

const orderingList = [
  {
    id: 1,
    name: t(
      "components.settings.userRolesAndPermissions.permissionsTable.orderOptions.recent"
    ),
    value: "newer",
  },
  {
    id: 2,
    name: t(
      "components.settings.userRolesAndPermissions.permissionsTable.orderOptions.older"
    ),
    value: "older",
  },
  {
    id: 3,
    name: t(
      "components.settings.userRolesAndPermissions.permissionsTable.orderOptions.alphabet"
    ),
    value: "ascend",
  },
  {
    id: 4,
    name: t(
      "components.settings.userRolesAndPermissions.permissionsTable.orderOptions.reverse"
    ),
    value: "decend",
  },
];

interface Props {
  handleSelectedId(id: string): void;
  stores: any[];
}
const UserPermissionsTable: React.FC<Props> = ({
  handleSelectedId,
  stores,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state);
  const [selectedFilter, setSelectedFilter] = useState(orderingList[0]);
  const [filterValue, setFilterValue] = useState("");
  const [userPermissions, setUserPermissions] = useState(stores);

  const usersHeader = [
    { name: "", size: "52.5px" },
    {
      name: t(
        "components.settings.userRolesAndPermissions.permissionsTable.storeName"
      ),
      size: "180px",
    },
    {
      name: t(
        "components.settings.userRolesAndPermissions.permissionsTable.rol"
      ),
      size: "120px",
    },
    {
      name: t(
        "components.settings.userRolesAndPermissions.permissionsTable.lastLog"
      ),
      size: "120px",
    },
    { name: "", size: "" },
    { name: "", size: "90px" },
  ];

  const handleStoreFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
  };

  const handleOrdering = (id: number) => {
    let selected = orderingList.filter((p) => {
      return p.id === id;
    });
    setSelectedFilter(selected[0]);
  };

  const getRoleName = (role: string) => {
    return t(`views.stores.details.table.roles.${role.toLowerCase()}`);
  };

  const buttonhandler = (id: string) => {
    handleSelectedId(id);
  };

  useEffect(() => {
    if (stores && stores !== null) {
      setUserPermissions(stores);
    }
    console.log(user.info?.lastSignedId);
  }, [stores, user]);

  const header = (
    <TableRow>
      {usersHeader.map((head, i) => (
        <TableHead key={i} size={head.size} centerContent={i !== 1}>
          {head.name}
        </TableHead>
      ))}
    </TableRow>
  );

  const body = userPermissions.map((p) => {
    return (
      <TableRow key={p.storeId || ""}>
        <TableCell></TableCell>
        <TableCell>
          <label>{p.storeName || ""}</label>
        </TableCell>
        <TableCell centerContent>
          <label>{getRoleName(p.role) || ""}</label>
        </TableCell>
        <TableCell centerContent>
          <label>
            {user.info
              ? dateFormat(new Date(user.info?.lastSignedId || ""))
              : ""}
          </label>
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          {/* Se comenta mientras no se tenga lista la pantalla de visualización de permisos */}
          {/* <Button
            buttonStyle={ButtonStyle.transparent}
            clickHandler={() =>
              buttonhandler(p.storeId || "6449841fdba5312ad78a7a9d")
            }
          >
            <p className={styles.showPermissions}>
              {t(
                "components.settings.userRolesAndPermissions.permissionsTable.seePermission"
              )}
            </p>
          </Button> */}
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    );
  });

  return (
    <div className={styles.UserPermissionsTableWrapper}>
      <div className={styles.header}>
        <span className={styles.title}>
          {t(
            "components.settings.userRolesAndPermissions.permissionsTable.title"
          )}
        </span>
      </div>
      <TableHeader
        hideCheckBox
        searchInputValue={filterValue}
        searchInputChangeHandle={handleStoreFiltering}
        // orderingList={orderingList}
        // orderingValue={selectedFilter}
        // orderingClick={handleOrdering}
      />
      <div className={styles.tableWrapper}>
        {userPermissions.length > 0 ? (
          <Table height={"312px"} header={header} body={body} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default UserPermissionsTable;
