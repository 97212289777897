import styles from "./EventBuyButtonPrev.module.scss";
import SvgWebButtonMockup from "./WebPrevSvg";
import SvgDots from "./Dots";
import { useTranslation } from "react-i18next";
import CircleX from "../../../FiraIcons/CircleX";
import Button, { ButtonStyle } from "../../../Button/Button";

interface Props {
  title: string;
  color: string;
}

const EventBuyButtonPrev = ({ title, color }: Props) => {
  return (
    <>
      <div className={styles.MockupsWrapper}>
        <div className={styles.WebMockup}>
          <SvgWebButtonMockup currentColor={color} />
        </div>

        <EventBuyButtonWebPrevMockup title={title} color={color} />
      </div>
    </>
  );
};

const EventBuyButtonWebPrevMockup = (props: any) => {
  const { title, color } = props;
  const { t } = useTranslation();
  const generateTextColor = (bgColor: string) => {
    const umbralColor = 130;
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);
    const luminosidad = 0.299 * r + 0.587 * g + 0.114 * b;

    return luminosidad > umbralColor ? "#000000" : "#ffffff";
  };
  return (
    <div className={styles.MockupShape}>
      <CircleX
        fontSize={18}
        color={"#5C767F"}
        style={{ marginLeft: "auto", paddingBottom: "8px" }}
      />
      <div className={styles.ProductPlaceholder}></div>
      <div className={styles.Dots}>
        <SvgDots currentColor={color} />
      </div>
      <div className={styles.ProductName}>
        {t("event_creation.params.buyButtonProductName")}
      </div>
      <div className={styles.Prices}>
        <span>$100.00</span>
        <span>$200.00</span>
      </div>
      <Button
        background={color}
        buttonStyle={ButtonStyle.wrapped}
        height={"auto"}
        width={"100%"}
        minHeight={"32px"}
      >
        <span
          style={{
            fontWeight: "600",
            fontSize: "14px",
            color: color ? generateTextColor(color) : generateTextColor("#000"),
          }}
        >
          {title}
        </span>
      </Button>
    </div>
  );
};

export default EventBuyButtonPrev;
