import { apiManagementPrivate } from "./api";

export const getDuplicatedExcelService = (preLoadProductId: string) => {
  return apiManagementPrivate.get(
    `/api/v1/pre-load-product/create-dup-excel/${preLoadProductId}`,
    {
      headers: {
        "Content-Disposition": "attachment; fileName=Lista de Productos.xls",
        "Content-Type": "application/vnd.ms-excel",
      },
      responseType: "arraybuffer",
    }
  );
};

export const getRejectedExcelService = async (id: string) => {
  return await apiManagementPrivate.get(
    "/api/v1/pre-load-product/create-rej-excel/" + id,
    {
      headers: {
        "Content-Disposition": "attachment",
        "Content-Type": "application/vnd.ms-excel",
      },
      responseType: "arraybuffer",
    }
  );
};

export const getApprovedExcelService = async (id: string) => {
  return await apiManagementPrivate.get(
    "/api/v1/pre-load-product/create-app-excel/" + id,
    {
      headers: {
        "Content-Disposition": "attachment",
        "Content-Type": "application/vnd.ms-excel",
      },
      responseType: "arraybuffer",
    }
  );
};

export const downloadAllStats = (eventId: string) => {
  return apiManagementPrivate.get(
    `/api/v1/fira-broadcast-event-controller/download-all-stats/${eventId}`,
    {
      headers: {
        "Content-Disposition": "attachment",
        "Content-Type": "application/vnd.ms-excel",
      },
      responseType: "arraybuffer",
    }
  );
};
