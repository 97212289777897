import React, { MouseEvent, ReactElement, useRef, useState } from "react";
import { Editor, EditorState, RichUtils, DraftHandleValue } from "draft-js";
import "../../../node_modules/draft-js/dist/Draft.css";

import "./TextEditor.scss";

//const MAX_LENGTH = 140;

const getLengthOfSelectedText = (editorState: EditorState): number => {
  const currentSelection = editorState.getSelection();
  const isCollapsed = currentSelection.isCollapsed();

  let length = 0;

  if (isCollapsed) {
    const currentContent = editorState.getCurrentContent();
    const startKey = currentSelection.getStartKey();
    const endKey = currentSelection.getEndKey();
    const startBlock = currentContent.getBlockForKey(startKey);
    const isStartAndEndBlockAreTheSame = startKey === endKey;
    const startBlockTextLength = startBlock.getLength();
    const startSelectedTextLength =
      startBlockTextLength - currentSelection.getStartOffset();
    const endSelectedTextLength = currentSelection.getEndOffset();
    const keyAfterEnd = currentContent.getKeyAfter(endKey);
    if (isStartAndEndBlockAreTheSame) {
      length +=
        currentSelection.getEndOffset() - currentSelection.getStartOffset();
    } else {
      let currentKey = startKey;

      while (currentKey && currentKey !== keyAfterEnd) {
        if (currentKey === startKey) {
          length += startSelectedTextLength + 1;
        } else if (currentKey === endKey) {
          length += endSelectedTextLength;
        } else {
          length += currentContent.getBlockForKey(currentKey).getLength() + 1;
        }

        currentKey = currentContent.getKeyAfter(currentKey);
      }
    }
  }

  return length;
};

interface TextAreaInputProps {
  id?: string;
  name?: string;
  editorState: EditorState;
  onChangeHandler(editorState: EditorState): void;
  visibleCounter?: boolean;
  canEdit?: boolean;
  maxLenght: number;
}
const TextEditor: React.FC<TextAreaInputProps> = ({
  editorState,
  onChangeHandler,
  visibleCounter = true,
  canEdit = true,
  maxLenght,
}) => {
  const editorRef = useRef<Editor>(null);
  const editorTextLength = editorState
    .getCurrentContent()
    .getPlainText().length;

  const handleBeforeInput = (): DraftHandleValue => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = getLengthOfSelectedText(editorState);

    if (currentContentLength - selectedTextLength > maxLenght - 1) {
      console.log("you can type max ten characters");

      return "handled";
    }

    return "not-handled";
  };

  const handlePastedText = (pastedText: string): DraftHandleValue => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = getLengthOfSelectedText(editorState);

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      maxLenght
    ) {
      console.log("you can type max ten characters");

      return "handled";
    }

    return "not-handled";
  };

  const toggleInlineStyle = (inlineStyle: string) => {
    onChangeHandler(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  return (
    <>
      <div className={`TextEditorInputWrapper ${!canEdit ? "IsDisabled" : ""}`}>
        <div className="TextEditorControls">
          <InlineStyleControls
            editorState={editorState}
            handleOnToggle={toggleInlineStyle}
          />
        </div>
        <Editor
          readOnly={!canEdit}
          ref={editorRef}
          editorState={editorState}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
          onChange={onChangeHandler}
        />
        {visibleCounter && (
          <div className="TextCounter lead-text">
            {editorTextLength + ` / ${maxLenght}`}
          </div>
        )}
      </div>
    </>
  );
};

interface EditorButtonProps {
  label: ReactElement;
  style: string;
  active: boolean;
  handleOnToggle(style: string): void;
}
const EditorButton = ({
  label,
  style,
  active,
  handleOnToggle,
}: EditorButtonProps) => {
  const onToggle = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    handleOnToggle(style);
  };

  let className = "TextEditorControlButton";
  if (active) {
    className += " IsActive";
  }

  return (
    <button className={className} onMouseDown={onToggle}>
      {label}
    </button>
  );
};

const INLINE_STYLES = [
  { label: <strong>B</strong>, style: "BOLD" },
  { label: <em>I</em>, style: "ITALIC" },
  { label: <u>U</u>, style: "UNDERLINE" },
];
interface InlineStyleControlsProps {
  editorState: EditorState;
  handleOnToggle(style: string): void;
}
const InlineStyleControls = ({
  editorState,
  handleOnToggle,
}: InlineStyleControlsProps) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <>
      {INLINE_STYLES.map((type, i) => (
        <EditorButton
          key={i}
          active={currentStyle.has(type.style)}
          label={type.label}
          handleOnToggle={handleOnToggle}
          style={type.style}
        />
      ))}
    </>
  );
};

export default TextEditor;
