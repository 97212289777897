import styles from "./ProductList.module.scss";
import Checkbox from "../Checkbox/Checkbox";
import { MagnifyingGlass } from "../FiraIcons";
import { useTranslation } from "react-i18next";
import { LandingProduct } from "../../types/Landing";
import noImageStoreSVG from "../../assets/svg/no-image-store.svg";
import { useState } from "react";
import currencyFormat from "../../utils/currencyFormat";

interface ProductListProps {
  products: LandingProduct[];
  onSelect(products: LandingProduct): void;
  onSearch?(value: string): void;
  selectAllAvailable?: boolean;
  onSelectAll?(products: LandingProduct[]): void;
  currency: string;
  mode: string;
}
const ProductList = ({
  products,
  onSelect,
  onSearch,
  selectAllAvailable,
  onSelectAll,
  currency,
  mode,
}: ProductListProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleSelectAll = () => {
    onSelectAll && onSelectAll(products);
  };

  const areAllSelected = (array: LandingProduct[]) => {
    return array.every((item) => item.selected === true);
  };

  return (
    <>
      <div className={styles.ProductListWrapper}>
        <div className={styles.SearchInput}>
          <MagnifyingGlass color={"#ABAFB1"} fontSize={24} />
          <input
            type="text"
            value={searchValue}
            placeholder={t("views.landing.widgets.searchInput")}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        {selectAllAvailable && (
          <div className={styles.SelectAll}>
            <div>
              <Checkbox
                clickHandler={handleSelectAll}
                isActive={areAllSelected(products)}
              />
            </div>
            <span>{t("views.landing.catalog.all")}</span>
          </div>
        )}

        {products &&
          products.map((product: any) => (
            <ProductItem
              product={product}
              key={product.id}
              clickHandler={onSelect}
              currency={currency}
              mode={mode}
            />
          ))}
      </div>
    </>
  );
};

interface ProductItemProps {
  clickHandler(product: LandingProduct): void;
  product: LandingProduct;
  currency: string;
  mode: string;
}

const ProductItem = ({
  product,
  clickHandler,
  currency,
  mode,
}: ProductItemProps) => {
  return (
    <div className={styles.ProductItem}>
      <Checkbox
        isActive={
          mode === "catalog" ? product.inLanding : product.featuredProduct
        }
        clickHandler={() => clickHandler(product)}
      />{" "}
      <div className={styles.ProductItem__Image}>
        <img
          src={
            product.images.length === 0 || product.images[0] === ""
              ? noImageStoreSVG
              : product.images[0]
          }
          alt={product.name}
        />
      </div>
      <div className={styles.ProductItem__ProductInfo}>
        <div className={styles.ProductItem__ProductInfo__Name}>
          {product.name}
        </div>
        <div className={styles.ProductItem__ProductInfo__Price}>
          {currencyFormat(Number(product.price1), currency)}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
