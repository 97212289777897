import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/Store";
import { getCountriesActionCreator } from "../../../../store/Utils/UtilsActions";
import {
  Account,
  PhoneType,
  SocialMediaType,
  StoreContactType,
} from "../../../../types/Stores.d";
import { Facebook, Instagram, TikTok } from "../../../FiraIcons";
import FiraSelect, { FiraSelectStyle } from "../../../FiraSelect/FiraSelect";
import Input, { InputStyles } from "../../../Input/Input";
import {
  ContactInformationInitialState,
  StoreProfileContext,
} from "../StoreProfile";
import styles from "./ContactInformationForm.module.scss";
import { phoneNumberFormat } from "../../../../utils/phoneNumberFormat";
import { CountryCode } from "../../../../services/utils";
import { useUtils } from "../../../../providers/UtilsProvider";
import { FiraPhone } from "../../../../types/Response";

const ContactInformationForm = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState<StoreContactType>(
    ContactInformationInitialState
  );
  const {
    contactForm,
    handleContactChange,
    setInvalidUrl,
    setInvalidContactForm,
  } = useContext(StoreProfileContext);
  const { countryCodes, loadCountryCodes } = useUtils();
  const [facebook, setFacebook] = useState<string>("");
  const [instagram, setInstagram] = useState<string>("");
  const [tiktok, setTiktok] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [webpages, setWebpages] = useState<string>("");
  const [invalid, setInvalid] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [selectedCode, setSelectedCode] = useState<CountryCode>();
  const urlRegex = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/))(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)(\.){1,}[a-zA-Z0-9()]*\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=])*/gi
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    switch (name) {
      case "facebook":
        setFacebook(value);
        handleFormSocial({
          type: Account.FACEBOOK,
          account: value.replace(/\s/g, ""),
        });
        break;
      case "instagram":
        setInstagram(value);
        handleFormSocial({
          type: Account.INSTAGRAM,
          account: value.replace(/\s/g, ""),
        });
        break;
      case "tiktok":
        setTiktok(value);
        handleFormSocial({
          type: Account.TIKTOK,
          account: value.replace(/\s/g, ""),
        });
        break;
      case "email":
        setEmail(value);
        setForm({ ...form, emails: [value.replace(/\s/g, "")] });
        break;
      case "webpages":
        setWebpages(value);
        setInvalid(!isURL(value));
        setForm({ ...form, webpages: [value.replace(/\s/g, "")] });
        break;
    }
    ContactValidator();
  };

  const handleFormSocial = (socialMediaObj: SocialMediaType) => {
    const index = form.socialMedia.findIndex(
      (obj) => obj.type === socialMediaObj.type
    );

    if (index !== -1) {
      const updatedSocialMedia = form.socialMedia.map((obj, i) => {
        if (i === index) {
          return socialMediaObj;
        }
        return obj;
      });

      setForm({
        ...form,
        socialMedia: updatedSocialMedia,
      });
    } else {
      setForm({ ...form, socialMedia: [...form.socialMedia, socialMediaObj] });
    }
  };

  const phoneMask = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneNumber(phoneNumberFormat(value) || "");
    handlePhoneNumber(value, selectedCode);
  };

  const handleCallingCodeSelect = (value: CountryCode) => {
    setSelectedCode(value);
    handlePhoneNumber(phoneNumber, value);
  };

  const handlePhoneNumber = (value: string, selectedCode?: CountryCode) => {
    const currentPhone = form.phones.length > 0 ? form.phones[0] : {};
    const num: FiraPhone = {
      ...currentPhone,
      country: selectedCode ? selectedCode.countryCode : "",
      operator: selectedCode ? selectedCode.dialCode : "",
      number: value.replaceAll(" ", ""),
      type: PhoneType.BUSINESS,
    };

    setForm({ ...form, phones: [num] });
  };

  const isURL = (str: string) => {
    return urlRegex.test(str);
  };

  const validator = (value: string) => {
    return value === "" || value === undefined || value === null;
  };

  const ContactValidator = () => {
    if (validator(phoneNumber) || !isURL(webpages)) {
      setInvalidContactForm(true);
    } else {
      setInvalidContactForm(false);
    }
  };

  useEffect(() => {
    setInvalidUrl(invalid);
  }, [invalid]);

  useEffect(() => {
    handleContactChange(form);
    let fb = form.socialMedia.find((obj) => obj.type === Account.FACEBOOK);
    fb && setFacebook(fb.account);

    let insta = form.socialMedia.find((obj) => obj.type === Account.INSTAGRAM);
    insta && setInstagram(insta.account);

    let tt = form.socialMedia.find((obj) => obj.type === Account.TIKTOK);
    tt && setTiktok(tt.account);

    form.emails.length > 0 && setEmail(form.emails[0]);
    form.webpages.length > 0 && setWebpages(form.webpages[0]);
  }, [form]);

  useEffect(() => {
    ContactValidator();
  }, [phoneNumber, selectedCode, webpages]);

  useEffect(() => {
    if (countryCodes.length === 0) {
      loadCountryCodes();
    }

    setForm(contactForm);
    contactForm.phones.length > 0 &&
      setPhoneNumber(contactForm.phones[0].number);
    if (contactForm.phones.length > 0 && contactForm.phones.country) {
      // TODO: set current code
      // setSelectedCode({
      //   value: contactForm.phones[0].operator,
      //   icon: `${contactForm.phones[0].country}.png`,
      //   name: contactForm.phones[0].number,
      // });
    }

    ContactValidator();
  }, []);

  return (
    <>
      <div className={styles.ContactInformationFormWrapper}>
        <form className={styles.ContactInformationFormWrapper__Form}>
          {/* Email */}
          <div
            className={styles.ContactInformationFormWrapper__Form__FormGroup}
          >
            <label>{t("components.store_settings.contact_info.email")}</label>
            <Input
              width="100%"
              height="40px"
              inputStyle={InputStyles.profile}
              inputName={"email"}
              value={email ? email : ""}
              onChange={handleInputChange}
            />
          </div>

          {/* Facebook */}
          <div
            className={
              styles.ContactInformationFormWrapper__Form__IconFormGroup
            }
          >
            <div
              className={
                styles.ContactInformationFormWrapper__Form__IconFormGroup__Icon
              }
            >
              <Facebook />
            </div>
            <div
              className={styles.ContactInformationFormWrapper__Form__FormGroup}
            >
              <label>Facebook</label>
              <Input
                width="100%"
                height="40px"
                inputStyle={InputStyles.profile}
                value={facebook ? facebook : ""}
                onChange={handleInputChange}
                inputName={"facebook"}
              />
            </div>
          </div>

          {/* Web */}
          <div
            className={styles.ContactInformationFormWrapper__Form__FormGroup}
          >
            <label>{t("components.store_settings.contact_info.web")}</label>
            <Input
              width="100%"
              height="40px"
              inputStyle={InputStyles.profile}
              onChange={handleInputChange}
              inputName={"webpages"}
              value={webpages ? webpages : ""}
              invalid={invalid}
            />
          </div>

          {/* Instagram */}
          <div
            className={
              styles.ContactInformationFormWrapper__Form__IconFormGroup
            }
          >
            <div
              className={
                styles.ContactInformationFormWrapper__Form__IconFormGroup__Icon
              }
            >
              <Instagram />
            </div>
            <div
              className={styles.ContactInformationFormWrapper__Form__FormGroup}
            >
              <label>Instagram</label>
              <Input
                width="100%"
                height="40px"
                inputStyle={InputStyles.profile}
                value={instagram ? instagram : ""}
                onChange={handleInputChange}
                inputName={"instagram"}
              />
            </div>
          </div>

          {/* Phonenumber */}
          <div
            className={styles.ContactInformationFormWrapper__Form__FormGroup}
          >
            <label>
              {t("components.store_settings.contact_info.phoneNumber")}
            </label>
            <div
              className={
                styles.ContactInformationFormWrapper__Form__FormGroup__phoneNumber
              }
            >
              <FiraSelect
                height="100%"
                selectStyle={FiraSelectStyle.phonecode}
                currentSelection={selectedCode}
                renderSelection={(code) => {
                  if (code)
                    return (
                      <div
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        {code.dialCode}{" "}
                        <img src={code.flagUrl} alt={code.name} />
                      </div>
                    );

                  return (
                    <div>
                      {t("modals.profile.edit_profile_modal.codePlaceholder")}
                    </div>
                  );
                }}
              >
                <>
                  {countryCodes.map((val, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => handleCallingCodeSelect(val)}
                        style={{ justifyContent: "space-between" }}
                      >
                        <img src={val.flagUrl} alt={val.name} />

                        {val.name}
                      </div>
                    );
                  })}
                </>
              </FiraSelect>
              <Input
                width="100%"
                height="40px"
                inputStyle={InputStyles.profile}
                value={phoneNumber ? phoneNumber : ""}
                inputName={"phoneNumber"}
                onChange={(e) => {
                  phoneMask(e);
                }}
              />
            </div>
          </div>

          {/* Tiktok */}
          <div
            className={
              styles.ContactInformationFormWrapper__Form__IconFormGroup
            }
          >
            <div
              className={
                styles.ContactInformationFormWrapper__Form__IconFormGroup__Icon
              }
            >
              <TikTok />
            </div>
            <div
              className={styles.ContactInformationFormWrapper__Form__FormGroup}
            >
              <label>Tiktok</label>
              <Input
                width="100%"
                height="40px"
                inputStyle={InputStyles.profile}
                value={tiktok ? tiktok : ""}
                onChange={handleInputChange}
                inputName={"tiktok"}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ContactInformationForm;
