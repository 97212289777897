import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgRedirect = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    fill="none"
    viewBox="0 0 14 12"
  >
    <path
      fill="#000"
      d="M11 7.25v2.5c0 .62-.22 1.15-.66 1.59-.44.44-.97.66-1.59.66h-6.5c-.62 0-1.15-.22-1.59-.66C.22 10.9 0 10.37 0 9.75v-6.5c0-.62.22-1.15.66-1.59.44-.44.97-.66 1.59-.66h5.5c.073 0 .133.023.18.07.047.047.07.107.07.18v.5a.243.243 0 01-.07.18.243.243 0 01-.18.07h-5.5c-.344 0-.638.122-.883.367S1 2.907 1 3.25v6.5c0 .344.122.638.367.883s.54.367.883.367h6.5c.344 0 .638-.122.883-.367s.367-.54.367-.883v-2.5c0-.073.023-.133.07-.18a.243.243 0 01.18-.07h.5c.073 0 .133.023.18.07.047.047.07.107.07.18zM14 .5v4a.48.48 0 01-.148.352A.48.48 0 0113.5 5a.48.48 0 01-.352-.148l-1.375-1.375L6.68 8.57a.247.247 0 01-.36 0l-.89-.89a.247.247 0 010-.36l5.093-5.093L9.148.852A.48.48 0 019 .5a.48.48 0 01.148-.352A.48.48 0 019.5 0h4a.48.48 0 01.352.148A.48.48 0 0114 .5z"
    ></path>
  </svg>
);

export default SvgRedirect;
