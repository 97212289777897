import { t } from "i18next";
import { useState } from "react";
import { InfoCircleFill, Minimize, RoundedX } from "../FiraIcons";
import styles from "./InfoCard.module.scss";

interface InfoCardProps {
  description: string;
}

const InfoCard = ({ description }: InfoCardProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <div
        className={`${isOpen ? styles.open : styles.closed} ${
          styles.InfoCardWrapper
        }`}
      >
        <div className={styles.InfoCardHeader}>
          <div className={styles.InfoIcon}>
            <InfoCircleFill />
          </div>

          <div className={styles.InfoCardTitle}>
            {t("components.infoCard.title")}
          </div>
          {!isOpen && (
            <div
              className={styles.MinimizeIcon}
              onClick={() => setIsOpen(true)}
            >
              <Minimize />
            </div>
          )}
          {isOpen && (
            <div className={styles.CloseIcon} onClick={() => setIsOpen(false)}>
              <RoundedX />
            </div>
          )}
        </div>

        <div
          className={`${isOpen ? styles.open : styles.closed} ${
            styles.InfoCardBody
          }`}
        >
          <div className={styles.InfoDescription}>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoCard;
