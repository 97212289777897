import { useContext, useEffect, useRef, useState } from "react";
import { SimpleFormSelect } from "../../../Selects/FormSelect/FormSelect";
import styles from "./BrandIdentityForm.module.scss";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "../../../../utils/useClickOutside";
import { FONTS } from "../../../../assets/fonts/fonts";
import { BrandContactType } from "../../../../types/Stores";
import { BrandContactInitialState, StoreProfileContext } from "../StoreProfile";
import InputImage from "../../../InputImage/InputImage";

const BrandIdentityForm = () => {
  const { t } = useTranslation();
  const [mainColor, setMainColor] = useState<string>("");
  const [secondaryColor, setSecondaryColor] = useState<string>("#000");
  const [showMainColorPicker, setShowMainColorPicker] =
    useState<boolean>(false);
  const [showSecondaryColorPicker, setShowSecondaryColorPicker] =
    useState<boolean>(false);
  const [faviconPreview, setFaviconPreview] = useState<string>("");
  const [logoPreview, setLogoPreview] = useState<string>("");
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [faviconFile, setFaviconFile] = useState<File | null>(null);
  const [font, setFont] = useState<string>("");
  const [form, setForm] = useState<BrandContactType>(BrandContactInitialState);

  const {
    brandForm,
    handleBrandChange,
    handleFileChange,
    setInvalidBrandForm,
  } = useContext(StoreProfileContext);

  const fonts = FONTS;

  const sketchPickerRefMain = useClickOutside(() =>
    setShowMainColorPicker(false)
  );
  const sketchPickerRefSecondary = useClickOutside(() =>
    setShowSecondaryColorPicker(false)
  );

  const handleChangeCompleteMain = (color: any) => {
    setMainColor(color.hex);
    setForm({ ...form, mainColor: color.hex });
  };

  const handleChangeCompleteSecondary = (color: any) => {
    setSecondaryColor(color.hex);
    setForm({ ...form, secondaryColor: color.hex });
  };

  const handleFavicon = (image: File) => {
    handleFileChange(image, "FAVICON");
    setFaviconFile(image);
    brandValidator();
  };

  const handleLogo = (image: File) => {
    handleFileChange(image, "LOGO");

    setLogoFile(image);
    brandValidator();
  };

  const handleFont = (fontName: string) => {
    setFont(fontName);
    setForm({ ...form, fontName: fontName });
    brandValidator();
  };

  const brandValidator = () => {
    if (logoPreview !== "" && font !== "" && mainColor !== "") {
      setInvalidBrandForm(false);
    } else {
      setInvalidBrandForm(true);
    }
  };

  useEffect(() => {
    if (logoFile) {
      const img = URL.createObjectURL(logoFile);
      setForm({ ...form, logo: img });
      setLogoPreview(img);
    }
  }, [logoFile]);

  useEffect(() => {
    setLogoPreview(brandForm.logo);
    setFaviconPreview(brandForm.favicon);
    setForm(brandForm);
    setMainColor(brandForm.mainColor !== "" ? brandForm.mainColor : "#ffde07");
    setSecondaryColor(
      brandForm.secondaryColor !== "" ? brandForm.secondaryColor : "#000"
    );
    setFont(brandForm.fontName);
    brandValidator();
  }, []);

  useEffect(() => {
    handleBrandChange(form);
    brandValidator();
  }, [form]);

  return (
    <>
      <div className={styles.BrandIdentityFormWrapper}>
        <div className={styles.BrandIdentityFormWrapper__Images}>
          <div className={styles.BrandIdentityFormWrapper__Images__Item}>
            <label htmlFor="">
              {t("components.store_settings.brand_identity.brand_logo")}*
            </label>
            <div
              className={
                styles.BrandIdentityFormWrapper__Images__Item__ImageContainer
              }
            >
              <InputImage
                width="100%"
                height="100%"
                noImageHeight="60%"
                noImageWidth="60%"
                preview={logoPreview}
                imageResult={handleLogo}
              />
            </div>
          </div>
          <div className={styles.BrandIdentityFormWrapper__Images__Item}>
            <label htmlFor="">
              {t("components.store_settings.brand_identity.brand_favicon")}
            </label>
            <div
              className={
                styles.BrandIdentityFormWrapper__Images__Item__ImageContainer
              }
            >
              <InputImage
                width="100%"
                height="100%"
                noImageHeight="60%"
                noImageWidth="60%"
                preview={faviconPreview}
                imageResult={handleFavicon}
              />
            </div>
          </div>
        </div>

        <div className={styles.BrandIdentityFormWrapper__Selects}>
          <div className={styles.BrandIdentityFormWrapper__Selects__Typography}>
            <label htmlFor="">
              {t("components.store_settings.brand_identity.typography")}*
            </label>
            <SimpleFormSelect
              width="100%"
              value={font}
              name={font}
              placeholder={"Open Sans"}
            >
              <>
                {fonts &&
                  fonts.map((font, i) => (
                    <div
                      style={{ fontFamily: font.font }}
                      key={i}
                      onClick={() => handleFont(font.font)}
                    >
                      {font.font}
                    </div>
                  ))}
              </>
            </SimpleFormSelect>
          </div>
          <div className={styles.SelectsWrapper}>
            <span>
              {t("components.store_settings.brand_identity.brand_colors")}
            </span>
            <div
              className={
                styles.BrandIdentityFormWrapper__Selects__ColorSelectors
              }
            >
              <div
                className={
                  styles.BrandIdentityFormWrapper__Selects__ColorSelectors__Selector
                }
              >
                <label htmlFor="">
                  {t("components.store_settings.brand_identity.main_color")}*
                </label>
                <div
                  className={
                    styles.BrandIdentityFormWrapper__Selects__ColorSelectors__Selector__Button
                  }
                  style={{ backgroundColor: mainColor }}
                  onClick={() => setShowMainColorPicker(!showMainColorPicker)}
                ></div>
                {showMainColorPicker && (
                  <div
                    style={{ position: "absolute" }}
                    ref={sketchPickerRefMain}
                  >
                    <SketchPicker
                      color={mainColor}
                      onChange={handleChangeCompleteMain}
                      disableAlpha={true}
                    />
                  </div>
                )}
              </div>
              <div
                className={
                  styles.BrandIdentityFormWrapper__Selects__ColorSelectors__Selector
                }
              >
                <label htmlFor="">
                  {" "}
                  {t(
                    "components.store_settings.brand_identity.secondary_color"
                  )}
                </label>
                <div
                  className={
                    styles.BrandIdentityFormWrapper__Selects__ColorSelectors__Selector__Button
                  }
                  style={{ backgroundColor: secondaryColor }}
                  onClick={() =>
                    setShowSecondaryColorPicker(!showSecondaryColorPicker)
                  }
                ></div>
                {showSecondaryColorPicker && (
                  <div
                    style={{ position: "absolute" }}
                    ref={sketchPickerRefSecondary}
                  >
                    <SketchPicker
                      color={secondaryColor}
                      onChange={handleChangeCompleteSecondary}
                      disableAlpha={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandIdentityForm;
