import { AnyAction, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { handleError, RequestError } from "../../services/errorHandling";
import {
  getVODProductsStats,
  getVODStatsByCriteria,
} from "../../services/store";
import {
  addVideoCategory,
  deleteVideoCategory,
  getAvailableVideosService,
  getOwnerVideos,
  getVideoCriteria,
  getVideosByService,
  getVideosService,
  replaceVideoService,
  updateAllVideoPricesVisibility,
  updateVideoPreviewImage,
  updateVideoPrimaryData,
  updateVideoProductInventory,
  updateVideoProductPrices,
  updateVideoProductSku,
  updateVideoProductState,
  updateVideoTeaser,
  updateVideoVisibility,
} from "../../services/videos";
import {
  CriteriaType,
  DataActionProductsStats,
  DataVideoStats,
  EditEventResponse,
  FiraVideo,
  FiraVideoInfo,
  FiraVideoProduct,
  UpdateCategoryBody,
  UpdatePricesBody,
  VideoProduct,
} from "../../utils/types.d";
import { VideoActions } from "../actionsContants";
import { LogoutActionType } from "../Auth/AuthActions";

export interface GetVideosResponse {
  payload: FiraVideo[];
  pagination: boolean;
}
interface GetVideosActionType {
  type:
    | VideoActions.getVideosLoading
    | VideoActions.getVideosSuccess
    | VideoActions.getVideosFailed;
  payload?: GetVideosResponse | RequestError;
}

export const getVideosActionCreator = (
  offSet: number,
  limit: number,
  pagination: boolean,
  filter: string,
  filterType: "SORT" | "BYWORD",
  sorting: string,
  search: string,
  storeId?: string
) => {
  return async (
    dispatch: ThunkDispatch<
      GetVideosActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.getVideosLoading });

      if (storeId) {
        const response =
          filterType === "SORT"
            ? await getVideosService(storeId, offSet, limit, filter)
            : await getVideosByService(storeId, offSet, limit, filter);

        const newResponse = {
          payload: response.data,
          pagination: pagination,
        };
        dispatch({
          type: VideoActions.getVideosSuccess,
          payload: newResponse,
        });
      } else {
        const response = await getOwnerVideos(offSet, limit, sorting, search);

        const newResponse = {
          payload: response,
          pagination: pagination,
        };
        dispatch({
          type: VideoActions.getVideosSuccess,
          payload: newResponse,
        });
      }
    } catch (error) {
      dispatch({
        type: VideoActions.getVideosFailed,
        payload: handleError(error),
      });
    }
  };
};

interface ResetVideoListActionType {
  type: VideoActions.resetVideoList;
}

export const resetVideoListActionCreator = (): ResetVideoListActionType => {
  return { type: VideoActions.resetVideoList };
};

interface CurrentVideoActionType {
  type: VideoActions.setCurrentVideo;
  payload: FiraVideo;
}

export const setCurrentVideoActionCreator = (
  video: FiraVideo
): CurrentVideoActionType => {
  return { type: VideoActions.setCurrentVideo, payload: video };
};

interface ResetCurrentVideoActionType {
  type: VideoActions.resetCurrentVideo;
}
export const resetCurrentVideoActionCreator =
  (): ResetCurrentVideoActionType => {
    return { type: VideoActions.resetCurrentVideo };
  };

export interface UpdateVideoVisibilityResponse {
  videoId: "string";
  message: "string";
}
interface UpdateVisibilityActionType {
  type:
    | VideoActions.updateVisibilityLoading
    | VideoActions.updateVisibilitySuccess
    | VideoActions.updateVisibilityFailed;
  payload?: UpdateVideoVisibilityResponse | RequestError;
}

export const updateVisibilityCreator = (videoId: string) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateVisibilityActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.updateVisibilityLoading });
      const response = await updateVideoVisibility(videoId);
      dispatch({
        type: VideoActions.updateVisibilitySuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: VideoActions.updateVisibilityFailed,
        payload: handleError(error),
      });
    }
  };
};

interface GetAvailableVideosActionType {
  type:
    | VideoActions.getAvailableVideosLoading
    | VideoActions.getAvailableVideosSuccess
    | VideoActions.getAvailableVideosFailed;
  payload?: FiraVideo[] | RequestError;
}

export const getAvailableVideosActionCreator = (
  storeId: string,
  offSet: number,
  limit: number,
  filter: string
) => {
  return async (
    dispatch: ThunkDispatch<
      GetAvailableVideosActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.getAvailableVideosLoading });
      const response = await getAvailableVideosService(
        storeId,
        offSet,
        limit,
        filter
      );

      dispatch({
        type: VideoActions.getAvailableVideosSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: VideoActions.getAvailableVideosFailed,
        payload: handleError(error),
      });
    }
  };
};

interface ResetAvailableListActionType {
  type: VideoActions.resetAvailableVideosList;
}

export const resetAvailableListActionCreator =
  (): ResetAvailableListActionType => {
    return { type: VideoActions.resetAvailableVideosList };
  };

export interface UpdateVideoProductStateResponse {
  id: string;
  message: "string";
}
interface UpdateVideoProductStateActionType {
  type:
    | VideoActions.updateVideoProductStateLoading
    | VideoActions.updateVideoProductStateSuccess
    | VideoActions.updateVideoProductStateFailed;
  payload?: UpdateVideoProductStateResponse | RequestError;
}

export const updateVideoProductStateActionCreator = (productId: string) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateVideoProductStateActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.updateVideoProductStateLoading });
      const response = await updateVideoProductState(productId);
      dispatch({
        type: VideoActions.updateVideoProductStateSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: VideoActions.updateVideoProductStateFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateVideoProductInfoActionType {
  type:
    | VideoActions.updateVideoProductInfoLoading
    | VideoActions.updateVideoProductInfoSuccess
    | VideoActions.updateVideoProductInfoFailed;
  payload?: FiraVideoProduct | RequestError;
}

export const updateVideoProductInfoActionCreator = (
  videoId: string,
  inputName: string,
  value: string | UpdatePricesBody
) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateVideoProductInfoActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.updateVideoProductInfoLoading });
      let response;
      switch (inputName) {
        case "sku":
          response = await updateVideoProductSku(videoId, value as string);
          break;
        case "quantity":
          response = await updateVideoProductInventory(
            videoId,
            value as string
          );
          break;
        case "price1" || "price2":
          response = await updateVideoProductPrices(value as UpdatePricesBody);
          break;
        case "price2":
          response = await updateVideoProductPrices(value as UpdatePricesBody);
          break;
      }
      response &&
        dispatch({
          type: VideoActions.updateVideoProductInfoSuccess,
          payload: response.data,
        });
    } catch (error) {
      dispatch({
        type: VideoActions.updateVideoProductInfoFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateAllPricesVisibilityActionType {
  type:
    | VideoActions.updateAllPricesVisibilityLoading
    | VideoActions.updateAllPricesVisibilitySuccess
    | VideoActions.updateAllPricesVisibilityFailed;
  payload?: FiraVideo | RequestError;
}

export const updateAllPricesVisibilityCreator = (videoId: string) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateAllPricesVisibilityActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.updateAllPricesVisibilityLoading });
      const response = await updateAllVideoPricesVisibility(videoId, "price1");
      const response2 = await updateAllVideoPricesVisibility(videoId, "price2");
      //si deshabilitas precio3 se habilita precio2
      dispatch({
        type: VideoActions.updateAllPricesVisibilitySuccess,
        payload: response2.data,
      });
    } catch (error) {
      dispatch({
        type: VideoActions.updateAllPricesVisibilityFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateVideoGeneralInfoActionType {
  type:
    | VideoActions.updateVideoGeneralInfoLoading
    | VideoActions.updateVideoGeneralInfoSuccess
    | VideoActions.updateVideoGeneralInfoFailed;
  payload?: FiraVideoInfo | RequestError;
}
export const updateVideoGeneralInfoCreator = (
  videoId: string,
  videoName: string,
  videoDescription: string
) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateVideoGeneralInfoActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.updateVideoGeneralInfoLoading });
      const response = await updateVideoPrimaryData(
        videoId,
        videoName,
        videoDescription
      );

      dispatch({
        type: VideoActions.updateVideoGeneralInfoSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: VideoActions.updateVideoGeneralInfoFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateVideoTeaserActionType {
  type:
    | VideoActions.updateVideoTeaserLoading
    | VideoActions.updateVideoTeaserSuccess
    | VideoActions.updateVideoTeaserFailed;
  payload?: EditEventResponse | RequestError;
}
export const updateVideoTeaserCreator = (videoId: string, teaserFile: File) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateVideoTeaserActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.updateVideoTeaserLoading });
      const response = await updateVideoTeaser(videoId, teaserFile);

      dispatch({
        type: VideoActions.updateVideoTeaserSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: VideoActions.updateVideoTeaserFailed,
        payload: handleError(error),
      });
    }
  };
};
//UpdateCategoryBody
/*

deleteVideoCategoryLoading = "DELETE_VIDEO_CATEGORY_LOADING_ACTION",
deleteVideoCategorySuccess = "DELETE_VIDEO_CATEGORY_SUCCESS_ACTION",
deleteVideoCategoryFailed = "DELETE_VIDEO_CATEGORY_FAILED_ACTION",*/
interface UpdateVideoCategoryActionType {
  type:
    | VideoActions.addVideoCategoryLoading
    | VideoActions.addVideoCategorySuccess
    | VideoActions.addVideoCategoryFailed;
  payload?: UpdateCategoryBody | RequestError;
}
export const updateAddCategoryCreator = (object: UpdateCategoryBody) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateVideoCategoryActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.addVideoCategoryLoading });
      const response = await addVideoCategory(object);

      dispatch({
        type: VideoActions.addVideoCategorySuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: VideoActions.addVideoCategoryFailed,
        payload: handleError(error),
      });
    }
  };
};

interface DeleteVideoCategoryActionType {
  type:
    | VideoActions.deleteVideoCategoryLoading
    | VideoActions.deleteVideoCategorySuccess
    | VideoActions.deleteVideoCategoryFailed;
  payload?: UpdateCategoryBody | RequestError;
}
export const updateDeleteCategoryCreator = (object: UpdateCategoryBody) => {
  return async (
    dispatch: ThunkDispatch<
      DeleteVideoCategoryActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.deleteVideoCategoryLoading });
      const response = await deleteVideoCategory(object);

      dispatch({
        type: VideoActions.deleteVideoCategorySuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: VideoActions.deleteVideoCategoryFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdatePreviewTeaserActionType {
  type:
    | VideoActions.updateVideoPreviewImageLoading
    | VideoActions.updateVideoPreviewImageSuccess
    | VideoActions.updateVideoPreviewImageFailed;
  payload?: EditEventResponse | RequestError;
}
export const updatePreviewImageCreator = (videoId: string, preview: File) => {
  return async (
    dispatch: ThunkDispatch<
      UpdatePreviewTeaserActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.updateVideoPreviewImageLoading });
      const response = await updateVideoPreviewImage(videoId, preview);

      dispatch({
        type: VideoActions.updateVideoPreviewImageSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: VideoActions.updateVideoPreviewImageFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateProductStatsActionType {
  type:
    | VideoActions.getVideoProductsStatsLoading
    | VideoActions.getVideoProductsStatsSuccess
    | VideoActions.getVideoProductsStatsFailed;
  payload?: DataActionProductsStats | RequestError;
}

export const getProductsStatsCreator = (videoId: string) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateProductStatsActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.getVideoProductsStatsLoading });

      const onSuccess = (message: MessageEvent) => {
        dispatch({
          type: VideoActions.getVideoProductsStatsSuccess,
          payload: JSON.parse(message.data) as DataActionProductsStats,
        });
      };

      getVODProductsStats(videoId, onSuccess);
    } catch (error) {
      dispatch({
        type: VideoActions.getVideoProductsStatsFailed,
        payload: handleError(error),
      });
    }
  };
};

interface GetStatsVideoActionType {
  type:
    | VideoActions.getVideoStatsLoading
    | VideoActions.getVideoStatsSuccess
    | VideoActions.getVideoStatsFailed
    | VideoActions.resetVideoStats;
  payload?: DataVideoStats[] | RequestError;
}
export const getVideoStatsActionCreator = (
  videoId: string,
  criteria: string
) => {
  return async (
    dispatch: ThunkDispatch<
      GetStatsVideoActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: VideoActions.resetVideoStats });
      dispatch({ type: VideoActions.getVideoStatsLoading });

      const onSuccess = (message: MessageEvent) => {
        dispatch({
          type: VideoActions.getVideoStatsSuccess,
          payload: JSON.parse(message.data) as DataVideoStats,
        });
      };

      getVODStatsByCriteria(criteria, videoId, onSuccess);
    } catch (error) {
      dispatch({
        type: VideoActions.getVideoStatsFailed,
        payload: handleError(error),
      });
    }
  };
};

export type VideoActionType =
  | GetVideosActionType
  | ResetVideoListActionType
  | CurrentVideoActionType
  | ResetCurrentVideoActionType
  | UpdateVisibilityActionType
  | LogoutActionType
  | GetAvailableVideosActionType
  | ResetAvailableListActionType
  | UpdateVideoProductStateActionType
  | UpdateVideoProductInfoActionType
  | UpdateAllPricesVisibilityActionType
  | UpdateVideoGeneralInfoActionType
  | UpdateVideoTeaserActionType
  | UpdatePreviewTeaserActionType
  | UpdateProductStatsActionType
  | UpdateVideoCategoryActionType
  | DeleteVideoCategoryActionType
  | GetStatsVideoActionType
  | LogoutActionType;
