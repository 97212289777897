import { RequestError } from "../../services/errorHandling";
import { FiraStore } from "../../types/Stores";
import { CreateStoreResponse } from "../../utils/response";
import { CreateStoreForm, UserStoreType } from "../../utils/types";
import { AuthActions, BrandActions } from "../actionsContants";
import type { BrandActionType } from "./BrandActions";

export interface BrandState {
  isLoading: boolean;
  isFirstTime: boolean;
  currentStore?: FiraStore;
  stores?: UserStoreType[];
  error?: RequestError;
  createdStore?: CreateStoreForm;
  editSuccess: boolean;
  createSuccess: boolean;
  syncStoreCode?: string | null;
  list: FiraStore[];
}
const initialState: BrandState = {
  isLoading: false,
  isFirstTime: false,
  editSuccess: false,
  createSuccess: false,
  list: [],
};
export const brandReducer = (
  state = initialState,
  action: BrandActionType
): BrandState => {
  switch (action.type) {
    case BrandActions.getStoreLoading: {
      return { ...state, isLoading: true };
    }
    case BrandActions.getUserStoresLoading: {
      return { ...state, isLoading: true };
    }
    case BrandActions.getUserStoresSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        stores: payload as UserStoreType[],
      };
    }
    case BrandActions.getUserStoresFailed: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload as RequestError };
    }
    case BrandActions.setIsNewUser: {
      const { payload } = action;
      return { ...state, isFirstTime: payload as boolean };
    }
    case BrandActions.setCurrentStore: {
      const { payload } = action;
      return { ...state, isLoading: false, currentStore: payload };
    }
    case BrandActions.updateCurrentStore: {
      const { payload } = action;
      return { ...state, currentStore: payload };
    }
    case BrandActions.createStoreLoading: {
      return { ...state, isLoading: true };
    }
    case BrandActions.createStoreSuccess: {
      const { payload } = action;
      let store = payload as FiraStore;
      // const newStore: UserStoreType = {
      //   businessClientId: store.businessClientId,
      //   id:store.id,
      //   legalName: store.legalName,
      //   name: store.name
      //   productsOn: //no existe en FiraStore
      //   role: //no existe en Firastor
      //   urlLogo: store.brand.logo
      // };
      // const newStore = payload as FiraStore;
      // const newStoreList = state.stores?.filter(
      //   (store) => store.id !== newStore.id
      // );
      // newStoreList?.push(newStore);
      //se cambio el comportamiento ya que la respuesta actual
      //no concuerda con el nuevo tipo de userStoreList
      return {
        ...state,
        isLoading: false,
        // stores: newStoreList,
        // currentStore: newStore,
        createSuccess: true,
      };
    }
    case BrandActions.createStoreFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case BrandActions.editStoreProfileLoading: {
      return { ...state, isLoading: true };
    }
    case BrandActions.editStoreProfileSuccess: {
      const { payload } = action;
      // const newStoreList = state.stores?.filter(
      //   (store) => store.id !== (payload as FiraStore).id
      // );
      // newStoreList?.push(payload as FiraStore);
      //mismo caso de createStoreSuccess
      return {
        ...state,
        isLoading: false,
        currentStore: payload as FiraStore,
        // stores: newStoreList,
        editSuccess: true,
      };
    }
    case BrandActions.editStoreProfileFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        editSuccess: false,
        error: payload as RequestError,
      };
    }
    case BrandActions.resetEditStoreMessage: {
      return { ...state, editSuccess: false, error: undefined };
    }
    case BrandActions.resetCreateStoreMessage: {
      return { ...state, createSuccess: false, error: undefined };
    }
    case AuthActions.logout: {
      return initialState;
    }
    case AuthActions.refreshTokenLoading: {
      return { ...state, isLoading: true };
    }
    case BrandActions.setSyncStoreCode: {
      const { payload } = action;
      return { ...state, syncStoreCode: payload };
    }
    case BrandActions.updateCurrentEcommerce: {
      const { payload } = action;
      const newCurrentStore = {
        ...state.currentStore,
        ecommerce: payload,
      };
      return {
        ...state,
        currentStore: newCurrentStore as FiraStore,
      };
    }
    case BrandActions.resetCreateStoreMessage: {
      return { ...state, createSuccess: false, error: undefined };
    }
    case BrandActions.resetCurrentStore: {
      return { ...state, isLoading: false, currentStore: undefined };
    }
    case BrandActions.updateOwnerStoreLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case BrandActions.updateOwnerStoreSuccess: {
      const { payload } = action;
      const newStore = payload as FiraStore;
      const refreshStores = state.list.map((store) => {
        if (store.id === newStore.id) {
          return newStore;
        }
        return store;
      });
      return {
        ...state,
        isLoading: false,
        list: refreshStores,
        editSuccess: true,
      };
    }
    case BrandActions.updateOwnerStoreFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case BrandActions.getOwnerStoresLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case BrandActions.getOwnerStoresSuccess: {
      const { payload } = action;

      return {
        ...state,
        isLoading: false,
        list: payload as FiraStore[],
      };
    }
    case BrandActions.getOwnerStoresFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case BrandActions.createOwnerStoreLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case BrandActions.createSOwnertoreSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        createSuccess: true,
        list: [...state.list, payload as FiraStore],
      };
    }
    case BrandActions.createOwnerStoreFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case BrandActions.cleanSuccessMessage: {
      return {
        ...state,
        createSuccess: false,
        editSuccess: false,
      };
    }

    case BrandActions.removeCurrentStore: {
      const newState = Object.assign({}, state);
      delete newState.currentStore;
      return {
        ...newState,
      };
    }
    default: {
      return state;
    }
  }
};
