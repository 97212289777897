import { reject } from "lodash";
import { ConfigurationTemplate, DefaultConfigResponse } from "../utils/types";
import { apiBusinessPrivate } from "./api";
import { OwnerDataResponse, ProductSettingsResponse } from "../types/Response";

export const updateStoresDefaultConfig = (
  data: {
    storeId: string;
    enabled: boolean;
  }[]
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiBusinessPrivate.put(
        "/v1/fira-business/store/set-event-default-config",
        data
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getGraphicDefaultConfig = (
  businessClientId: string
): Promise<DefaultConfigResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiBusinessPrivate.get<DefaultConfigResponse>(
        `/v1/fira-business/business-client/get-default-config/${businessClientId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const setGraphicDefaultConfig = (
  businessClientId: string,
  configuration: ConfigurationTemplate
) => {
  return new Promise(async (resolve, reject) => {
    try {
      await apiBusinessPrivate.put(
        `/v1/fira-business/business-client/set-default-config/${businessClientId}`,
        configuration
      );
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const getGlobalEventSettings = () => {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      const response = await apiBusinessPrivate.get<boolean>(
        "/v1/fira-business/business-client/get-global-views"
      );
      resolve(response.data);
    } catch (error) {
      reject(false);
    }
  });
};

export const setGlobalEventSettings = () => {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      const response = await apiBusinessPrivate.post(
        "/v1/fira-business/business-client/set-global-views"
      );
      resolve(response.data);
    } catch (error) {
      reject(false);
    }
  });
};

export const getProductSettings = (): Promise<ProductSettingsResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiBusinessPrivate.get<ProductSettingsResponse>(
        `/v1/fira-business/business-client/get-product-config`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postProductSettings = (permissions: {
  canEdit: boolean;
  canCreate: boolean;
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      await apiBusinessPrivate.post(
        "/v1/fira-business/business-client/set-product-config",
        {
          defaultStoreProductConfig: permissions,
        }
      );
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const postStoreListForProductSettings = (stores: string[]) => {
  return new Promise(async (resolve, reject) => {
    try {
      await apiBusinessPrivate.post(
        "/v1/fira-business/business-client/product-config/set-store-list",
        stores
      );
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const postAccountVars = (
  businessClientId: string,
  additionalFields: {
    [key: string]: string;
  }
) => {
  return new Promise<OwnerDataResponse>(async (resolve, reject) => {
    try {
      const response = await apiBusinessPrivate.put<OwnerDataResponse>(
        `/v1/fira-business/business-client/set-additional-fields/${businessClientId}`,
        additionalFields
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
