import { ReactElement, useState } from "react";
import { useClickOutside } from "../../../utils/useClickOutside";
import { ArrowDownFill, RoundedX } from "../../FiraIcons";
import "./FormSelect.scss";

export enum SimpleFormSelectStyle {
  default = "default",
}

interface SimpleFormSelectProps {
  width?: string;
  height?: string;
  name?: string;
  value?: string;
  formStyle?: SimpleFormSelectStyle;
  children?: ReactElement;
  placeholder?: string;
  id?: string;
  invalid?: boolean;
  borderRadius?: string;
  isDisabled?: boolean;
  fontSize?: string;
  removeIcon?: boolean;
  showArrow?: boolean;
  onRemove?(): void;
}

const SimpleFormSelect = ({
  width = "100%",
  height = "40px",
  borderRadius = "4px",
  name,
  children,
  formStyle = SimpleFormSelectStyle.default,
  placeholder,
  id,
  invalid = false,
  fontSize,
  removeIcon = false,
  showArrow = true,
  onRemove,
}: SimpleFormSelectProps) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const domNode = useClickOutside(() => setIsOptionsOpen(false));
  const style = {
    width: width,
    height: height,
    borderRadius: borderRadius,
    fontSize: fontSize,
  };

  return (
    <>
      <div className={"FormSelect"} ref={domNode} id={id}>
        <div
          tabIndex={0}
          className={`Input ${formStyle} ${
            isOptionsOpen && children ? "isOptionsOpen" : ""
          } ${invalid ? "invalid" : ""}`}
          onClick={() => setIsOptionsOpen(!isOptionsOpen)}
          style={style}
        >
          {placeholder && name === "" ? (
            <span className="placeholder" style={{ fontSize: fontSize } || {}}>
              {placeholder}
            </span>
          ) : (
            <span>{name}</span>
          )}
          {showArrow && <ArrowDownFill className="DropIcon" />}
          {removeIcon && <RoundedX onClick={onRemove} />}
        </div>
        {isOptionsOpen && children && (
          <div
            className={"FormSelectOptions"}
            onClick={() => setIsOptionsOpen(false)}
          >
            {children}
          </div>
        )}
      </div>
    </>
  );
};

export { SimpleFormSelect };
