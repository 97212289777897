import Stylesheet from "./BouncingLoader.module.scss";

interface Props {
  color: string;
}
const BouncingLoader = ({ color = "" }: Props) => {
  const { BouncingLoader } = Stylesheet;
  const dotsStyle = { backgroundColor: color };

  return (
    <div className={BouncingLoader}>
      <div style={dotsStyle}></div>
      <div style={dotsStyle}></div>
      <div style={dotsStyle}></div>
    </div>
  );
};

export default BouncingLoader;
