import Roles from "./Roles/Roles";
import Permissions from "./Permissions/Permissions";
import styles from "./RolesAndPermissions.module.scss";
import { useTranslation } from "react-i18next";
import { SimplePermissionItem } from "../../types/Response";
import { FiraUserRole } from "../../utils/enums";
import { useContext, useEffect, useState } from "react";
import { RolesAndPermissionsContext } from "../../views/OwnerPermissionsEditView/OwnerPermissionsEditView";

export interface RolesAndPermissionsType {
  userId?: string;
  storeId: string;
  storeName?: string;
  name?: string;
  role: FiraUserRole | null;
  permissions: SimplePermissionItem[] | [];
  userEmail?: string;
}

const RolesAndPermissions = () => {
  const { t } = useTranslation();
  const { permissionsData } = useContext(RolesAndPermissionsContext);
  const [permissions, setPermissions] = useState<RolesAndPermissionsType>({
    userId: "user",
    storeId: "store",
    role: null,
    permissions: [],
  });

  const getSelectedRole = (role: FiraUserRole | null) => {
    if (role && role !== null) {
      switch (role) {
        case FiraUserRole.MANAGER:
          return t("views.edit_permissions.roles.manager");
        case FiraUserRole.MODERATOR:
          return t("views.edit_permissions.roles.moderator");
        case FiraUserRole.OPERATOR:
          return t("views.edit_permissions.roles.operator");
        default:
          return "";
      }
    }
  };

  const handleRoleSelection = (role: FiraUserRole) => {
    setPermissions({
      ...permissions,
      role: role,
    });
  };

  useEffect(() => {
    permissionsData && setPermissions(permissionsData);
  }, []);

  return (
    <>
      <div className={styles.RolesAndPermissionsWrapper}>
        <div className={styles.RolesAndPermissionsWrapper__ComponetContainer}>
          <div
            className={
              styles.RolesAndPermissionsWrapper__ComponetContainer__InfoItem
            }
          >
            <p>
              {t("views.edit_permissions.role")}{" "}
              {permissionsData &&
                permissionsData.role &&
                getSelectedRole(permissionsData.role)}
            </p>
            <span>{t("views.edit_permissions.select_operator")}</span>
          </div>
          <Roles onRoleSelect={handleRoleSelection} />
          <div
            className={
              styles.RolesAndPermissionsWrapper__ComponetContainer__InfoItem
            }
          >
            <p>
              {t("views.edit_permissions.profile")}{" "}
              {permissionsData &&
                permissionsData.role &&
                getSelectedRole(permissionsData.role)}
            </p>
            <span>{t("views.edit_permissions.select_operator_profile")}</span>
          </div>
          <Permissions />
        </div>
      </div>
    </>
  );
};

export default RolesAndPermissions;
