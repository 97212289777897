import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventConfigurationContext } from "../../../views/EventConfigurationView/EventConfigurationView";
import styles from "./EventBroadcastingResolution.module.scss";
const EventBroadcastingResolution = () => {
  const { t } = useTranslation();
  const { firaWebConfiguration, setFiraWebConfiguration } = useContext(
    EventConfigurationContext
  );
  const [selectedOption, setSelectedOption] = useState<string>("HORIZONTAL");

  useEffect(() => {
    console.log(firaWebConfiguration);
  }, [firaWebConfiguration]);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setFiraWebConfiguration({
      ...firaWebConfiguration,
      resolution: option,
    });
  };

  return (
    <>
      <div className={styles.EventBroadcastingResolutionWrapper}>
        <div className={styles.SwitchContainer}>
          <div
            className={`${styles.OptionContainer} ${
              selectedOption === "HORIZONTAL" ? styles.selected : ""
            }`}
            onClick={() => handleOptionClick("HORIZONTAL")}
          >
            <label className={styles.titleCard}>
              {t("event_creation.params.broadcastingResolution.horizontal")}
            </label>
            <div className={styles.rectangleH}></div>
            <label className={styles.infoCard}>16:9</label>
          </div>
          <div
            className={`${styles.OptionContainer} ${
              selectedOption === "VERTICAL" ? styles.selected : ""
            }`}
            onClick={() => handleOptionClick("VERTICAL")}
          >
            <label className={styles.titleCard}>
              {t("event_creation.params.broadcastingResolution.vertical")}
            </label>
            <div className={styles.rectangleV}></div>
            <label className={styles.infoCard}>9:16</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventBroadcastingResolution;
