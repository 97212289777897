import React from "react";

function Minimize() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="4"
      fill="none"
      viewBox="0 0 12 4"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="3"
        d="M2 2h8"
      ></path>
    </svg>
  );
}

export default Minimize;
