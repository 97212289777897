import React from "react";
import { DashboardTabButton } from "../DashboardTabLayout/DashboardTabLayout";

interface Props {
  HeaderComponent?: React.ReactNode;
  tabs: { title: string; element: React.ReactElement }[];
}
const TabsLayout: React.FC<Props> = ({ HeaderComponent, tabs }) => {
  const [activeTab, setActiveTab] = React.useState(tabs[0]);

  const onTabPressed = (tabSelectedName: string) => {
    setActiveTab((previousTabSelected) => {
      const tabSelected = tabs.find((tab) => tab.title === tabSelectedName);
      if (tabSelected) return tabSelected;

      return previousTabSelected;
    });
  };

  return (
    <div style={Content}>
      {HeaderComponent}
      <div style={TabsWrapper}>
        {tabs.map((tab, index) => (
          <DashboardTabButton
            text={tab.title}
            tabIsActive={tab.title === activeTab.title}
            onTabSelected={onTabPressed}
            key={index}
          />
        ))}
      </div>
      <div>{activeTab.element}</div>
    </div>
  );
};

const TabsWrapper: React.CSSProperties = {
  display: "flex",
  gap: 4,
  marginBottom: 16,
};

const Content: React.CSSProperties = {
  display: "grid",
  gridTemplateRows: "auto auto 1fr",
  height: "calc(100% - 80px)",
};

export default TabsLayout;
