import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { FiraProductOption, FiraProductVariation } from "../../utils/types";
interface ProductContextProps {
  productOptions: FiraProductOption[];
  productVariations: FiraProductVariation[];
  currentProductVariations: FiraProductVariation[]; // Nuevo array para variaciones seleccionadas
  deletedVariations: FiraProductVariation[]; // Nuevo array para variaciones eliminadas
  addProductOption: (option: FiraProductOption) => void;
  editProductOption: (
    optionId: string,
    updatedOption: FiraProductOption
  ) => void;
  deleteProductOption: (optionId: string) => void;
  addProductVariation: (variation: FiraProductVariation) => void;
  addCurrentProductVariation: (variation: FiraProductVariation) => void;
  editProductVariation: (
    sku: string,
    updatedVariation: FiraProductVariation
  ) => void;
  editCurrentProductVariation: (
    sku: string,
    updatedVariation: FiraProductVariation
  ) => void;
  deleteProductVariation: (sku: string) => void;
  addToCurrentProductVariations: (sku: string) => void;
  removeFromCurrentProductVariations: (sku: string) => void;
  getUnselectedVariations: () => FiraProductVariation[];
  setInitialProductOptions: (options: FiraProductOption[]) => void;
  setInitialProductVariations: (variations: FiraProductVariation[]) => void;
  setInitialCurrentProductVariations: (
    variations: FiraProductVariation[]
  ) => void;
  resetProductVariations: () => void;
  resetCurrentProductVariations: () => void;
  editImageInCurrentProductVariations: (
    sku: string,
    updatedImage: string
  ) => void;
  filterCurrentProductVariations: () => void;
}

interface ProductVariationsProviderProps {
  initialProductOptions: FiraProductOption[];
  initialProductVariations: FiraProductVariation[];
}

const ProductVariationsContext = createContext<ProductContextProps | undefined>(
  undefined
);

export const ProductVariationsProvider: React.FC<
  ProductVariationsProviderProps & { children: ReactNode }
> = ({ children, initialProductOptions, initialProductVariations }) => {
  const [productOptions, setProductOptions] = useState<FiraProductOption[]>([]);
  const [productVariations, setProductVariations] = useState<
    FiraProductVariation[]
  >([]);
  const [currentProductVariations, setCurrentProductVariations] = useState<
    FiraProductVariation[]
  >([]);
  const [deletedVariations, setDeletedVariations] = useState<
    FiraProductVariation[]
  >([]);
  const addProductOption = (option: FiraProductOption) => {
    setProductOptions((prevOptions) => [...prevOptions, option]);
  };

  const editProductOption = (
    optionId: string,
    updatedOption: FiraProductOption
  ) => {
    setProductOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === optionId ? updatedOption : option
      )
    );
  };

  const deleteProductOption = (optionId: string) => {
    setProductOptions((prevOptions) =>
      prevOptions.filter((option) => option.id !== optionId)
    );
  };

  const addProductVariation = (variation: FiraProductVariation) => {
    setProductVariations((prevVariations) =>
      prevVariations ? [...prevVariations, variation] : [variation]
    );
  };

  const editProductVariation = (
    sku: string,
    updatedVariation: FiraProductVariation
  ) => {
    setProductVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.sku === sku ? updatedVariation : variation
      )
    );
  };

  const editCurrentProductVariation = (
    sku: string,
    updatedVariation: FiraProductVariation
  ) => {
    setCurrentProductVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.sku === sku ? updatedVariation : variation
      )
    );
  };

  const deleteProductVariation = (sku: string) => {
    const deletedVariation = productVariations.find(
      (variation) => variation.sku === sku
    );
    if (deletedVariation) {
      setProductVariations((prevVariations) =>
        prevVariations.filter((variation) => variation.sku !== sku)
      );
      setDeletedVariations((prevDeletedVariations) => [
        ...prevDeletedVariations,
        deletedVariation,
      ]);
    }
  };

  //new add current variation
  const addCurrentProductVariation = (variation: FiraProductVariation) => {
    setCurrentProductVariations((prevVariations) =>
      prevVariations ? [...prevVariations, variation] : [variation]
    );
  };

  const addToCurrentProductVariations = (sku: string) => {
    const selectedVariation = productVariations.find(
      (variation) => variation.sku === sku
    );
    if (selectedVariation) {
      setCurrentProductVariations((prevVariations) => [
        ...prevVariations,
        selectedVariation,
      ]);
    }
  };

  const removeFromCurrentProductVariations = (sku: string) => {
    setCurrentProductVariations((prevVariations) =>
      prevVariations.filter((variation) => variation.sku !== sku)
    );
  };

  const getUnselectedVariations = () => {
    return (
      productVariations &&
      productVariations.filter(
        (variation) =>
          !currentProductVariations.some(
            (selectedVariation) => selectedVariation.sku === variation.sku
          )
      )
    );
  };

  const setInitialProductOptions = (options: FiraProductOption[]) => {
    setProductOptions(options);
  };

  const setInitialProductVariations = (variations: FiraProductVariation[]) => {
    setProductVariations(variations);
  };
  const setInitialCurrentProductVariations = (
    variations: FiraProductVariation[]
  ) => {
    setCurrentProductVariations(variations);
  };

  const resetProductVariations = () => {
    setProductVariations([]);
  };

  const resetCurrentProductVariations = () => {
    setCurrentProductVariations([]);
  };

  const editImageInCurrentProductVariations = (
    sku: string,
    updatedImage: string
  ) => {
    setCurrentProductVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.sku === sku
          ? { ...variation, image: updatedImage }
          : variation
      )
    );
  };

  const filterCurrentProductVariations = () => {
    setCurrentProductVariations((prevVariations) =>
      prevVariations.filter((variation) =>
        productVariations.some(
          (productVariation) => productVariation.name === variation.name
        )
      )
    );
  };
  const contextValue: ProductContextProps = {
    productOptions,
    productVariations,
    currentProductVariations,
    deletedVariations,
    addProductOption,
    editProductOption,
    deleteProductOption,
    addProductVariation,
    editProductVariation,
    editCurrentProductVariation,
    deleteProductVariation,
    addToCurrentProductVariations,
    addCurrentProductVariation,
    removeFromCurrentProductVariations,
    getUnselectedVariations,
    setInitialProductOptions,
    setInitialProductVariations,
    setInitialCurrentProductVariations,
    resetProductVariations,
    resetCurrentProductVariations,
    editImageInCurrentProductVariations,
    filterCurrentProductVariations,
  };

  return (
    <ProductVariationsContext.Provider value={contextValue}>
      {children}
    </ProductVariationsContext.Provider>
  );
};

export const useProductContext = () => {
  const context = useContext(ProductVariationsContext);
  if (!context) {
    throw new Error(
      "useProductContext debe ser utilizado dentro de un ProductProvider"
    );
  }
  return context;
};
