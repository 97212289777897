import { ProductStatus } from "../utils/enums";
import { FiraPhone } from "../utils/types";

export interface FiraLanding {
  id?: string;
  name: string;
  storeId: string;
  firaLandingConfiguration: FiraLandingConfiguration | null;
  phoneNumber?: FiraPhone;
  shoppingCart?: boolean;
  active: boolean;
  storeNumber?: FiraPhone;
  products: LandingProduct[] | null;
  featuredProducts: LandingProduct[] | null;
  productCatalog: boolean;
  useOwnerDefaultLandingConfig?: boolean;
  parametersSetted?: boolean;
}

export interface FiraLandingConfiguration {
  storeUrl: string;
  webLogoUrl: string;
  mobileLogoUrl: string;
  headerHeight: number;
  headerColor: string;
  footerColor: string;
  bgColor: string;
  logoAlignment: LogoAlignment;
  mobileCentered: boolean;
  fontHeader?: string;
  fontSizeHeader?: number;
  fontContent?: string;
  fontSizeContent?: number;
  fontFooter?: string;
  fontSizeFooter?: number;
  widgetContent: FiraWidgetConfiguration;
  fontHeaderWeight?: number;
  fontContentWeight?: number;
  fontFooterWeight?: number;
}
interface FiraWidgetConfiguration {
  widgetId: string;
  previousEvents: string;
  collectionId: string | null;
  sectionTitle: string;
  bgColor: string;
  fontColor: string;
  displayColor: string;
  detailsColor: string;
  widgetType: WidgetType;
}

interface LandingProduct {
  id: string;
  externalId: string;
  storeId: string;
  sku: string;
  name: string;
  description: string;
  price1: string;
  price2: string;
  price3: string;
  quantity: number;
  directUrl: string;
  createAt: string;
  modifiedAt: string;
  images: string[];
  status: ProductStatus;
  selected?: boolean;
  inLanding?: boolean;
  featuredProduct?: boolean;
}

export enum WidgetType {
  vod = "VOD",
  products = "PRODUCTS",
}

export interface GlobalLandingConfigResponse {
  businessClientId: string;
  useOwnerDefaultLandingConfig: boolean | null;
  defaultLandingConfig: string[] | null;
  parametersSetted: boolean | null;
  landingBusinessTemplate: FiraLanding | null;
}
