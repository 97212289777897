import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";

import stylesheet from "./RegisterForm.module.scss";
import { FiraChatUser, useChatContext } from "../../providers/ChatProvider";
import { getUser } from "../../utils/usersUtils";
import { getTextColor } from "../../utils/colorUtils";
import useScreenWidth from "../../hooks/useScreenWidth";

const {
	Title,
	LoginFormWrapper,
	FormWrapper,
	TextInput,
	SubmitButton,
	CloseButton,
	ButtonTheme,
	formSlideIn,
	formSlideOut,
	backdropShow,
	backdropOut,
	WithError,
	InputWrapper,
	CharCounter,
	DisclaimerWrapper,
} = stylesheet;

interface SchemaShape {
	[key: string]: Yup.StringSchema | Yup.NumberSchema | Yup.BooleanSchema;
}

interface InitialValues {
	[key: string]: string;
}

type FormValues = {
	disclaimerAccepted: boolean;
	[key: string]: string | boolean;
};

interface Props {
	onRegistrationComplete: () => void;
}
const RegisterForm: React.FC<Props> = ({ onRegistrationComplete }) => {
	const { registerToChat, currentUser, getChatSrc, getChatConfig } =
		useChatContext();
	const src = getChatSrc();
	const { enableLogin, loginForm, mainColor, disclaimerUrl } = getChatConfig();
	const currentLSUser = getUser(src);

	const screenWidth = useScreenWidth();

	const [userFieldFocused, setUserFieldFocused] = React.useState(false);

	const sortedFields = [...(loginForm || [])].sort((a, b) => a.order - b.order);
	const validationSchema = Yup.object(
		sortedFields.reduce<SchemaShape>(
			(acc, field) => {
				acc[field.fieldLabel] = field.isRequired
					? Yup.string().required("Required")
					: Yup.string();
				return acc;
			},
			{
				disclaimerAccepted: Yup.boolean().oneOf(
					[true],
					"Disclaimer must be accepted."
				),
			}
		)
	);

	const formik = useFormik<FormValues>({
		initialValues: {
			...sortedFields.reduce<InitialValues>((acc, field) => {
				acc[field.fieldLabel] = "";
				return acc;
			}, {}),
			disclaimerAccepted: false, // Initial value for disclaimer checkbox
		},
		validationSchema,
		onSubmit: values => {
			// todo send data to backend

			if (typeof values["user"] === "string") {
				registerToChat(values["user"]);
				setIsRegistered(true);
			}
		},
	});

	const [isRegistered, setIsRegistered] = useState(
		enableLogin && !currentLSUser?.anonUser
	);
	const [formIsActive, setFormIsActive] = useState(false);
	const [animationClass, setAnimationClass] = useState(formSlideIn);
	const [backdropClass, setBackdropClass] = useState(backdropShow);

	useEffect(() => {
		if (isRegistered) {
			setAnimationClass(formSlideOut);
			setBackdropClass(backdropOut);

			const timerId = setTimeout(() => {
				onRegistrationComplete();
			}, 200);

			return () => clearTimeout(timerId);
		}
	}, [isRegistered, onRegistrationComplete]);

	const getOutlineStyle = (fieldName: string, color: string) => {
		return formik.touched[fieldName]
			? `2px solid ${formik.errors[fieldName] ? "red" : color}`
			: "";
	};

	if (!enableLogin) {
		return null;
	} else if (!formIsActive) {
		return (
			<div className={`${LoginFormWrapper} ${backdropClass}`}>
				<div
					style={{
						backgroundColor: screenWidth > 981 ? "white" : "transparent",
					}}
					className={`${FormWrapper} ${animationClass}`}
				>
					<button
						style={{
							height: 44,
							backgroundColor: mainColor,
							color: getTextColor(mainColor),
						}}
						className={`${ButtonTheme} ${SubmitButton}`}
						onClick={() => setFormIsActive(true)}
					>
						Participar
					</button>
				</div>
			</div>
		);
	} else
		return (
			<div
				style={{ position: screenWidth < 982 ? "fixed" : "absolute" }}
				className={`${LoginFormWrapper}  ${backdropClass}`}
			>
				<div className={`${FormWrapper} ${animationClass}`}>
					<button
						className={CloseButton}
						onClick={() => setFormIsActive(false)}
					>
						<Icon icon="akar-icons:cross" />
					</button>
					<form onSubmit={formik.handleSubmit}>
						<span className={Title}>Participar</span>
						{sortedFields.map(field => {
							const fieldValue = formik.values[field.fieldLabel];
							const length =
								typeof fieldValue === "string" ? fieldValue.length : 0;

							return (
								<div className={InputWrapper} key={field.fieldLabel}>
									<input
										style={{
											outline: getOutlineStyle(field.fieldLabel, mainColor),
										}}
										placeholder={field.fieldLabel}
										className={`${TextInput} ${
											formik.touched[field.fieldLabel] &&
											formik.errors[field.fieldLabel]
												? WithError
												: ""
										}`}
										type={field.fieldType}
										id={field.fieldLabel}
										name={field.fieldLabel}
										required={field.isRequired}
										onChange={formik.handleChange}
										onBlur={e => {
											formik.handleBlur(e);
											if (field.fieldLabel === "user")
												setUserFieldFocused(false);
										}}
										onFocus={
											field.fieldLabel === "user"
												? () => setUserFieldFocused(true)
												: undefined
										}
										value={formik.values[field.fieldLabel].toString()}
									/>
									{field.fieldLabel === "user" && userFieldFocused ? (
										<div
											style={{
												backgroundColor:
													formik.touched[field.fieldLabel] &&
													formik.errors[field.fieldLabel]
														? "red"
														: mainColor,
												color: getTextColor(mainColor),
											}}
											className={CharCounter}
										>
											{length}/30
										</div>
									) : null}
								</div>
							);
						})}
						<div className={DisclaimerWrapper}>
							<input
								type="checkbox"
								id="disclaimerAccepted"
								name="disclaimerAccepted"
								checked={formik.values.disclaimerAccepted}
								onChange={formik.handleChange}
							/>
							<label htmlFor="disclaimerAccepted">
								<span>Al registrar su cuenta, acepta nuestros</span>{" "}
								<a href={disclaimerUrl} target="_blank">
									Términos y condiciones.
								</a>
							</label>
							{/* {formik.touched.disclaimerAccepted &&
							formik.errors.disclaimerAccepted ? (
								<div>{formik.errors.disclaimerAccepted}</div>
							) : null} */}
						</div>
						<button
							style={{
								backgroundColor: mainColor,
								color: getTextColor(mainColor),
							}}
							className={`${ButtonTheme} ${SubmitButton}`}
							type="submit"
						>
							Acceder
						</button>
					</form>
				</div>
			</div>
		);
};

export default RegisterForm;
