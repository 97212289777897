export const phoneNumberFormat = (number: string) => {
  if (number !== undefined || null || "") {
    let phoneNumber = number.replace(/[^\d]/g, "");
    phoneNumber = phoneNumber.replace(/^0+/, "");

    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
      3,
      6
    )} ${phoneNumber.slice(6, 10)}`;
  }
  return;
};
