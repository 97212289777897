import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { devToolsEnhancer } from "@redux-devtools/extension";

import { registerReducer } from "./Register/RegisterReducer";
import { recoveryReducer } from "./Recovery/RecoveryReducer";
import { brandReducer } from "./Brand/BrandReducer";
import { utilsReducer } from "./Utils/UtilsReducer";
import { productsReducer } from "./Products/ProductsReducer";
import { userReducer } from "./User/UserReducer";
import { eventReducer } from "./Event/EventReducer";
import { DashboardReducer } from "./Dashboard/DashboardReducer";
import { videoReducer } from "./Video/VideoReducer";
import { collectionReducer } from "./Collection/CollectionReducer";
import { authenticationReducer } from "./Authentication/reducer";
import { TeamReducer } from "./Team/TeamReducer";
import { landingReducer } from "./Landing/LandingReducer";

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  registration: registerReducer,
  recovery: recoveryReducer,
  brand: brandReducer,
  utils: utilsReducer,
  products: productsReducer,
  user: userReducer,
  event: eventReducer,
  dashboard: DashboardReducer,
  video: videoReducer,
  collection: collectionReducer,
  team: TeamReducer,
  landing: landingReducer,
});

export const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk), devToolsEnhancer())
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
