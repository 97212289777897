import { SVGProps } from "react";

const CalendarMaterial = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.607 1.393h-1.393V.464a.464.464 0 0 0-.928 0v.929H3.714V.464a.464.464 0 1 0-.928 0v.929H1.393C.623 1.393 0 2.016 0 2.786v8.821C0 12.377.624 13 1.393 13h10.214c.77 0 1.393-.624 1.393-1.393V2.786c0-.77-.624-1.393-1.393-1.393Zm.464 10.214a.464.464 0 0 1-.464.464H1.393a.464.464 0 0 1-.464-.464V5.571H12.07v6.036Zm0-6.964H.93V2.786c0-.257.207-.465.464-.465h1.393v.929a.464.464 0 1 0 .928 0v-.929h5.572v.929a.464.464 0 1 0 .928 0v-.929h1.393c.257 0 .465.208.465.465v1.857Z"
      fill="currentColor"
    />
  </svg>
);

export default CalendarMaterial;
