import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgArrowRightComplete = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M.362 4.59h6.657L3.961 1.547l.777-.769 4.377 4.359-4.377 4.358-.771-.768L7.019 5.68H.362V4.59Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgArrowRightComplete;
