import ReactDOM from "react-dom";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import PopUpAlert, {
  PopUpAlertTypes,
  PopUpButtonsTypes,
} from "../../PopUpAlert/PopUpAlert";

interface UnsyncStoreProps {
  isOpen: boolean;
  onClose(): void;
  type?: PopUpAlertTypes;
  title?: string;
  message?: string;
  buttons?: PopUpButtonsTypes[];
  clickText?: string;
  handleClickText?(): void;
  width?: string;
}

const ModalAlert = ({
  isOpen = false,
  onClose,
  type = PopUpAlertTypes.error,
  title,
  message,
  buttons,
  clickText,
  width,
  handleClickText,
}: UnsyncStoreProps) => {

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalLayout>
      <PopUpAlert
        type={type}
        message={message}
        title={title}
        width={width}
        buttons={buttons}
        clickText={clickText}
        handleClickText={handleClickText}
        handleCloseIcon={onClose}
      />
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};

export default ModalAlert;
