import styles from "./LandingSideBar.module.scss";
import { LandingButton, RootState } from "../../../utils/types";
import { Catalog, Header, Widgets } from "../../FiraIcons";
import Typography from "../../FiraIcons/Typography";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FiraUserRole } from "../../../utils/enums";
import {
  modulesType,
  useLandingConfig,
} from "../../../providers/LandingConfigProvider";

const LandingSideBar = () => {
  const { t } = useTranslation();
  const { authentication, brand } = useSelector((state: RootState) => state);
  const { moduleInView, landingConfig } = useLandingConfig();
  const [buttonOptions, setButtonOptions] = useState<LandingButton[]>([]);

  useEffect(() => {
    let buttons: LandingButton[] = [];

    if (
      landingConfig.useOwnerDefaultLandingConfig &&
      brand.currentStore &&
      landingConfig.parametersSetted
    ) {
      buttons = [
        {
          name: t("views.landing.page.title"),
          module: modulesType.PAGE,
          icon: <Header height={16} width={16} />,
          component: <></>,
        },
      ];
    } else if (
      landingConfig.useOwnerDefaultLandingConfig &&
      !landingConfig.parametersSetted &&
      brand.currentStore
    ) {
      buttons = [
        {
          name: t("views.landing.page.title"),
          module: modulesType.PAGE,
          icon: <Header height={16} width={16} />,
          component: <></>,
        },
        {
          name: "Widgets",
          module: modulesType.WIDGETS,
          icon: <Widgets />,
          component: <></>,
        },
        {
          name: t("views.landing.catalog.title"),
          module: modulesType.CATALOG,
          icon: <Catalog />,
          component: <></>,
        },
      ];
    } else if (
      landingConfig.firaLandingConfiguration ||
      landingConfig.firaLandingConfiguration === null
    ) {
      buttons = [
        {
          name: t("views.landing.page.title"),
          module: modulesType.PAGE,
          icon: <Header height={16} width={16} />,
          component: <></>,
        },
        {
          name: t("views.landing.typography.title"),
          module: modulesType.TYPOGRAPHY,
          icon: <Typography height={16} width={16} />,
          component: <></>,
        },
        {
          name: "Widgets",
          module: modulesType.WIDGETS,
          icon: <Widgets />,
          component: <></>,
        },
        {
          name: t("views.landing.catalog.title"),
          module: modulesType.CATALOG,
          icon: <Catalog />,
          component: <></>,
        },
      ];
    }
    if (authentication.currentRole === FiraUserRole.OPERATOR) {
      buttons = buttons.filter(
        (value) => value.module !== modulesType.TYPOGRAPHY
      );
    }

    setButtonOptions(buttons);
  }, [landingConfig]);

  return (
    <div className={styles.LandingSideBar}>
      {buttonOptions.map((button, i: number) => (
        <FiraLandingButton key={i} button={button} inView={moduleInView} />
      ))}
    </div>
  );
};

const FiraLandingButton = (props: any) => {
  const { button, inView } = props;
  const [active, setActive] = useState<boolean>(false);
  const {
    setModuleInView,
    showModuleBar,
    setShowModuleBar,
    moduleInView,
    mobileModuleBar,
  } = useLandingConfig();

  const handleModuleInView = (module: modulesType) => {
    setModuleInView(module);
    if (mobileModuleBar && moduleInView === module) {
      setShowModuleBar(!showModuleBar);
    }
  };

  useEffect(() => {
    (inView as modulesType) === button.module
      ? setActive(true)
      : setActive(false);
  }, [inView]);
  return (
    <>
      <div
        className={`${styles.LandingButton}`}
        onClick={() => handleModuleInView(button.module)}
      >
        <div
          className={`${styles.LandingButton__Icon} ${
            active ? styles.active : ""
          }`}
        >
          {button.icon}
        </div>
        <div className={`${styles.LandingButton__Title}`}>{button.name}</div>
      </div>
    </>
  );
};

export default LandingSideBar;
