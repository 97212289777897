import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import { finishEventActionCreator } from "../../../store/Event/EventActions";
import { RootState } from "../../../utils/types";
import Button, { ButtonStyle } from "../../Button/Button";
import styles from "./FinishStream.module.scss";
import { useEvent } from "../../../providers/EventProvider";
//import {useContext} from "react";
//import {GeneralContext} from "../../../context/GeneralContext";

interface FinishStreamProps {
  isOpen: boolean;
  onClose(): void;
  updateCount(): void;
}

const FinishStream = ({
  isOpen = false,
  onClose,
  updateCount,
}: FinishStreamProps) => {
  const { event } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { clearPopups } = useEvent();
  //const {setConnected, setEventFinished} = useContext<any>(GeneralContext);

  const handleCancel = () => {
    onClose();
  };

  const finishBroadcast = async () => {
    if (event.currentEvent) {
      if (event.currentEvent) {
        //setConnected(false)
        //setEventFinished(true)
        dispatch(finishEventActionCreator(event.currentEvent.id));
        clearPopups();
        updateCount();
        onClose();
      }
    }
  };

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalLayout>
      <div className={styles.finishModalWrapper}>
        <h5>{t("modals.eventDetail.finish.title")}</h5>
        <p className="alert-text">{t("modals.eventDetail.finish.message")}</p>
        <div className={styles.buttonGroup}>
          <Button
            width="132px"
            height="42px"
            buttonStyle={ButtonStyle.transparent}
            clickHandler={handleCancel}
            isDisabled={event.isLoading}
          >
            <h6>{t("modals.eventDetail.finish.no")}</h6>
          </Button>
          <Button
            width="132px"
            height="42px"
            clickHandler={finishBroadcast}
            isDisabled={event.isLoading}
          >
            <h6>{t("modals.eventDetail.finish.yes")}</h6>
          </Button>
        </div>
      </div>
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};

export default FinishStream;
