import { useTranslation } from "react-i18next";
import { FiraLiveLogo, Video } from "../../../../components/FiraIcons";
import { FiraUserPermissionComponent } from "../../../../components/NewUserComponents";
import { FiraSimplePermissionList } from "../../../../components/NewUserComponents/FiraUserPermissionComponent/FiraUserPermissionComponent";
import { FiraPackage } from "../../../../utils/enums";
import { useEffect, useState } from "react";
import { hasPermissions } from "../../../../utils/handlePermissionCheckboxSelection";
import { useSelector } from "react-redux";
import { RootState } from "../../../../utils/types";
import { SimplePermissionItem } from "../../../../types/Response";

interface PermissionItemsForAdminProps {
  handlePermissionsByProduct(product: FiraPackage): void;
  isLoading: boolean;
}

const PermissionItemsForAdmin = ({
  handlePermissionsByProduct,
  isLoading,
}: PermissionItemsForAdminProps) => {
  const { t } = useTranslation();
  const { authentication } = useSelector((state: RootState) => state);
  const [firaProducts, setFiraProducts] = useState<SimplePermissionItem[]>();

  const FIRA_WEB_FOR_ADMIN = {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.manager.title"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
    ),
    permissions: [
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.manager.dashboard"
      ),
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.manager.events"
      ),
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.manager.products"
      ),
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.manager.team"
      ),
    ],
  };

  const PROSELLER_FOR_ADMIN = {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.proseller.title"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
    ),
    permissions: [
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.proseller.proseller"
      ),
    ],
  };

  const FIRA_TV_FOR_ADMIN = {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.title"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
    ),
    permissions: [
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.title"
      ),
    ],
  };

  useEffect(() => {
    if (authentication.user?.permissionsStore) {
      setFiraProducts(authentication.user?.permissionsStore[0].permissions);
    }
  }, [authentication]);

  return (
    <>
      <FiraUserPermissionComponent
        permissions={[]}
        title={FIRA_WEB_FOR_ADMIN.title}
        icon={<FiraLiveLogo />}
        description={FIRA_WEB_FOR_ADMIN.description}
        onModuleClick={() => handlePermissionsByProduct(FiraPackage.FIRA_WEB)}
        isDisabled={
          hasPermissions(FiraPackage.FIRA_WEB, firaProducts) || isLoading
        }
      >
        <FiraSimplePermissionList
          permissions={FIRA_WEB_FOR_ADMIN.permissions}
        />
      </FiraUserPermissionComponent>
      <FiraUserPermissionComponent
        permissions={[]}
        title={PROSELLER_FOR_ADMIN.title}
        icon={<FiraLiveLogo />}
        description={PROSELLER_FOR_ADMIN.description}
        onModuleClick={() => handlePermissionsByProduct(FiraPackage.PROSELLER)}
        isDisabled={
          hasPermissions(FiraPackage.PROSELLER, firaProducts) || isLoading
        }
      >
        <FiraSimplePermissionList
          permissions={PROSELLER_FOR_ADMIN.permissions}
        />
      </FiraUserPermissionComponent>
      <FiraUserPermissionComponent
        permissions={[]}
        title={FIRA_TV_FOR_ADMIN.title}
        icon={<Video />}
        description={FIRA_TV_FOR_ADMIN.description}
        onModuleClick={() => handlePermissionsByProduct(FiraPackage.FIRA_TV)}
        isDisabled={
          hasPermissions(FiraPackage.FIRA_TV, firaProducts) || isLoading
        }
      >
        <FiraSimplePermissionList permissions={FIRA_TV_FOR_ADMIN.permissions} />
      </FiraUserPermissionComponent>
    </>
  );
};

export default PermissionItemsForAdmin;
