import { ReactElement, ReactNode } from "react";

import "./ContextMenu.scss";

interface Props {
  position?: "absolute" | "fixed" | "initial";
  anchorPoint: { x: number; y: number };
  isVisible?: boolean;
  navLinks?: { name: string; icon: ReactElement; clickHandler(): void }[];
}
/**
 * ContextMenu description
 * @prop {'absolute' | 'fixed'} position - Position of the ContextMenu, it can be absolute or a fixed position
 * @prop {{x: number, y: number}} anchorPoint - Point in the x and y axis to be use as position for the ContextMenu
 * @prop {boolean} isVisible - set the visibility of the ContextMenu
 * @prop {{name: string, icon: ReactElement, clickHandler(): void}[]} navLinks - is the array of buttons that will have the ContextMenu
 */
const ContextMenu = ({
  position = "fixed",
  anchorPoint,
  isVisible = false,
  navLinks = [],
}: Props) => {
  const style = {
    position,
    top: anchorPoint.y,
    left: anchorPoint.x,
  };

  return isVisible ? (
    <div className="ContextMenu" style={style}>
      {navLinks.map((link) => (
        <ContextMenuButton
          key={link.name}
          icon={link.icon}
          clickHandler={link.clickHandler}
        >
          {link.name}
        </ContextMenuButton>
      ))}
    </div>
  ) : null;
};

interface ContextMenuButtonProps {
  clickHandler?(): void;
  children?: ReactNode | undefined;
  icon?: ReactElement;
}
const ContextMenuButton = ({
  clickHandler,
  children,
  icon,
}: ContextMenuButtonProps) => {
  return (
    <button className="ContextMenuButton" onClick={clickHandler}>
      {icon}
      <span className="TextContent lead-text">{children}</span>
    </button>
  );
};

export default ContextMenu;
