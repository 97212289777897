import React, { useEffect, useState } from "react";
import { Edit, MagnifyingGlass, TrashCan } from "../../../components/FiraIcons";
import { TableImage } from "../../../components/Table/Table";
import { FiraProductVariation, ImageProductObject } from "../../../utils/types";
import noImageStoreSVG from "../../../assets/svg/no-image-store.svg";
import Stylesheet from "./VariationsTable.module.scss";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Input, { InputStyles } from "../../../components/Input/Input";
import { getCurrencySymbol } from "../../../utils/getCurrencySymbol";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import { useProductContext } from "../../../store/Products/ProductVariationsContext";
import Button, { ButtonStyle } from "../../../components/Button/Button";
import ModalProductVariations from "../ModalProductVariations/ModalProductVariations";
import ModalImageVariation from "../ModalImageVariation/ModalImageVariation";
import { useTranslation } from "react-i18next";

interface VariationTableHeaderProps {
  canEdit: boolean;
  selectedCount: number;
  onDeleteSelected: () => void;
  onSearch: (query: string) => void;
}

const VariationTableHeader: React.FC<VariationTableHeaderProps> = ({
  canEdit,
  selectedCount,
  onDeleteSelected,
  onSearch,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  return (
    <div className={Stylesheet.ContainerTableHeader}>
      <div
        className={`${Stylesheet.itemsHeader} ${
          !canEdit ? Stylesheet.IsDisabled : ""
        }`}
      >
        <label
          className={`${Stylesheet.itemTableHeaderLeft} ${
            !canEdit ? Stylesheet.IsDisabled : ""
          }`}
        >{`${selectedCount > 0 ? selectedCount : "0"} seleccionados`}</label>
        {canEdit && (
          <label
            className={Stylesheet.itemTableHeaderRight}
            onClick={onDeleteSelected}
          >
            <TrashCan width={24} height={24} />{" "}
            {t("views.products.detailProduct.delete")}
          </label>
        )}
      </div>
      <div style={{ display: "flex", width: "70%" }}>
        <Input
          type="text"
          width="100%"
          height="48px"
          placeholder={t("views.products.detailProduct.placeholderSearch")}
          value={searchQuery}
          inputStyle={InputStyles.profile}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            onSearch(e.target.value);
          }}
          icon={<MagnifyingGlass width={24} height={24} />}
        />
      </div>
    </div>
  );
};

interface ProductVariationTableProps {
  canEdit?: boolean;
  variations: FiraProductVariation[];
  onChange(): void;
}

interface EditedValues {
  productId: string;
  sku: string;
  name: string;
  quantity: number;
  price1: number;
  price2: number;
  image: string;
}

const VariationsTable: React.FC<ProductVariationTableProps> = ({
  canEdit = true,
  variations,
  onChange,
}) => {
  const { t } = useTranslation();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [editedValues, setEditedValues] = useState<
    Record<string, EditedValues>
  >({});
  const [editMode, setEditMode] = useState<string | null>(null);
  const { brand, products } = useSelector((state: RootState) => state);
  const storeCurrency =
    brand.currentStore?.regionalConfiguration.currency || "USD";
  const {
    currentProductVariations,
    editCurrentProductVariation,
    removeFromCurrentProductVariations,
    editImageInCurrentProductVariations,
  } = useProductContext();

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedItems(
      selectAll ? [] : variations.map((variation) => variation.sku)
    );
  };
  const [filteredVariations, setFilteredVariations] =
    useState<FiraProductVariation[]>(variations);

  useEffect(() => {
    setFilteredVariations(currentProductVariations);
  }, [currentProductVariations]);

  const updateVariationImage = (sku: string, imageUrl: string) => {
    editImageInCurrentProductVariations(sku, imageUrl);
    onChange();
  };

  const toggleSelectItem = (sku: string) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(sku)) {
        return prevSelected.filter((item) => item !== sku);
      } else {
        return [...prevSelected, sku];
      }
    });
  };

  const handleEdit = (sku: string) => {
    onChange();
    setEditMode(sku);
    const selectedVariation = currentProductVariations.find(
      (variation) => variation.sku === sku
    );

    // Guardar la imagen actual en una variable separada
    const currentImage = selectedVariation?.image || "";

    setEditedValues({
      ...editedValues,
      [sku]: {
        productId: products.currentProduct.id + "",
        sku: selectedVariation?.sku || "",
        name: selectedVariation?.name || "",
        quantity: selectedVariation?.quantity || 0,
        price1: selectedVariation?.price1 || 0,
        price2: selectedVariation?.price2 || 0,
        image: currentImage, // Usar la imagen actual
      },
    });
  };

  const handleInputChange = (
    sku: string,
    field: string,
    value: string | number
  ) => {
    setEditedValues((prevEditedValues) => {
      const currentValues = prevEditedValues[sku] || {};
      return {
        ...prevEditedValues,
        [sku]: {
          ...currentValues,
          [field]: value + "",

          ...(currentValues.image !== undefined && {
            image: currentValues.image,
          }),
        },
      };
    });
  };

  const handleSaveEdit = (sku: string) => {
    const editedVariation = editedValues[sku];

    if (editedVariation && editedVariation.image !== undefined) {
      editCurrentProductVariation(sku, editedVariation);
    } else {
      const currentVariation = variations.find(
        (variation) => variation.sku === sku
      );
      if (currentVariation) {
        editCurrentProductVariation(sku, {
          ...editedVariation,
          image: currentVariation.image,
        });
      }
    }
    setEditMode(null);
  };

  const handleDelete = (sku: string) => {
    removeFromCurrentProductVariations(sku);
    onChange();
  };

  const handleDeleteSelected = () => {
    selectedItems.map((item) => {
      removeFromCurrentProductVariations(item);
    });
    setSelectedItems([]);
    setSelectAll(false);
    onChange();
  };

  const handleSearch = (query: string) => {
    const filteredVariations =
      query.trim() === ""
        ? variations
        : variations.filter((variation) =>
            variation.name.toLowerCase().includes(query.toLowerCase())
          );
    setFilteredVariations(filteredVariations);
  };
  const [showModalVariations, setShowModalVariations] = useState(false);
  const [showModalImages, setShowModalImages] = useState(false);
  const onCloseModal = () => {
    setShowModalVariations(false);
  };
  const onCloseModalImages = () => {
    setShowModalImages(false);
  };
  const [currentSku, setCurrentSku] = useState("");

  const onShowModalImage = (sku: string) => {
    if (!canEdit) return;
    setCurrentSku(sku);
    setShowModalImages(true);
  };

  const handleModalChangeVariations = () => {
    onChange();
  };
  return (
    <div className={Stylesheet.ContainerTable}>
      {showModalVariations && (
        <ModalProductVariations
          onClose={onCloseModal}
          onChange={handleModalChangeVariations}
        />
      )}
      {showModalImages && (
        <ModalImageVariation
          onClose={onCloseModalImages}
          onImageSave={(urlImage) => {
            const selectedSku = currentSku;

            if (selectedSku) {
              updateVariationImage(selectedSku, urlImage || "");
            }
          }}
        />
      )}
      <VariationTableHeader
        canEdit={canEdit}
        selectedCount={selectedItems.length}
        onDeleteSelected={handleDeleteSelected}
        onSearch={handleSearch}
      />
      {filteredVariations && filteredVariations.length > 0 && (
        <table className={Stylesheet.TableWrapper}>
          <thead>
            <tr>
              <th>
                <Checkbox
                  isDisabled={!canEdit}
                  clickHandler={toggleSelectAll}
                  isActive={selectAll}
                />
              </th>
              <th></th>
              <th className={Stylesheet.TableHeaderTextLeft}>
                {t("views.products.detailProduct.variant")}
              </th>
              <th className={Stylesheet.TableHeaderText}>SKU</th>
              <th className={Stylesheet.TableHeaderText}>
                {t("views.products.detailProduct.inventory")}
              </th>
              <th className={Stylesheet.TableHeaderText}>
                {t("views.products.detailProduct.price1")}
              </th>
              <th className={Stylesheet.TableHeaderText}>
                {t("views.products.detailProduct.price2")}
              </th>
              <th className={Stylesheet.TableHeaderText}></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredVariations.map((variation, index) => (
              <tr key={variation.sku}>
                <td className={Stylesheet.itemTr} style={{ height: "67px" }}>
                  <Checkbox
                    isDisabled={!canEdit}
                    clickHandler={() => toggleSelectItem(variation.sku)}
                    isActive={selectedItems.includes(variation.sku)}
                  />
                </td>
                <td
                  className={Stylesheet.itemTr}
                  onClick={() => onShowModalImage(variation.sku)}
                  style={{ cursor: "pointer" }}
                >
                  <TableImage
                    src={
                      variation.image === "" ? noImageStoreSVG : variation.image
                    }
                  />
                </td>
                <td className={Stylesheet.itemTr}>{variation.name}</td>
                <td
                  className={Stylesheet.itemTr}
                  style={{ textAlign: "center" }}
                >
                  {editMode === variation.sku ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Input
                        inputId={`sku_${variation.sku}`}
                        inputName={`sku_${variation.sku}`}
                        width="72px"
                        height="40px"
                        borderRadius="4px"
                        value={editedValues[variation.sku]?.sku || ""}
                        onChange={(e) =>
                          handleInputChange(
                            variation.sku,
                            "sku",
                            e.target.value
                          )
                        }
                        onBlur={() => handleSaveEdit(variation.sku)}
                      />
                    </div>
                  ) : (
                    variation.sku
                  )}
                </td>
                <td
                  className={Stylesheet.itemTr}
                  style={{ textAlign: "center" }}
                >
                  {editMode === variation.sku ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Input
                        type={"text"}
                        inputId={`quantity_${variation.sku}`}
                        inputName={`quantity_${variation.sku}`}
                        width="72px"
                        value={
                          editedValues[variation.sku]?.quantity + "" || "0"
                        }
                        onChange={(e) =>
                          handleInputChange(
                            variation.sku,
                            "quantity",
                            e.target.value
                          )
                        }
                        onBlur={() => handleSaveEdit(variation.sku)}
                      />
                    </div>
                  ) : (
                    variation.quantity
                  )}
                </td>
                <td
                  className={Stylesheet.itemTr}
                  style={{ textAlign: "center" }}
                >
                  {editMode === variation.sku ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Input
                        inputId={`price1_${variation.sku}`}
                        inputName={`price1_${variation.sku}`}
                        value={editedValues[variation.sku]?.price1 + "" || "0"}
                        onChange={(e) =>
                          handleInputChange(
                            variation.sku,
                            "price1",
                            e.target.value
                          )
                        }
                        width="72px"
                        currency={getCurrencySymbol(storeCurrency)}
                        onBlur={() => handleSaveEdit(variation.sku)}
                      />
                    </div>
                  ) : (
                    variation.price1
                  )}
                </td>
                <td
                  className={Stylesheet.itemTr}
                  style={{ textAlign: "center" }}
                >
                  {editMode === variation.sku ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Input
                        inputId={`price2_${variation.sku}`}
                        inputName={`price2_${variation.sku}`}
                        value={editedValues[variation.sku]?.price2 + "" || "0"}
                        onChange={(e) =>
                          handleInputChange(
                            variation.sku,
                            "price2",
                            e.target.value
                          )
                        }
                        width="72px"
                        currency={getCurrencySymbol(storeCurrency)}
                        onBlur={() => handleSaveEdit(variation.sku)}
                      />
                    </div>
                  ) : (
                    variation.price2 || "-"
                  )}
                </td>

                <td
                  className={Stylesheet.itemTr}
                  style={{ textAlign: "center" }}
                >
                  {canEdit && (
                    <div className={Stylesheet.optionsContainer}>
                      <span
                        className={Stylesheet.tableOption}
                        style={
                          editMode === variation.sku
                            ? { background: "#FFDE07" }
                            : {}
                        }
                        onClick={() => handleEdit(variation.sku)}
                      >
                        <Edit width={24} height={24} />
                      </span>
                      <span
                        className={Stylesheet.tableOption}
                        onClick={() => handleDelete(variation.sku)}
                      >
                        <TrashCan width={24} height={24} />
                      </span>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className={Stylesheet.AddButton}>
        <Button
          isDisabled={!canEdit}
          width="105px"
          height="32px"
          clickHandler={(e) => setShowModalVariations(true)}
          buttonStyle={ButtonStyle.border}
        >
          <span className="lead-text">
            {t("views.products.detailProduct.addButton")}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default VariationsTable;
