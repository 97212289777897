import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgModIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M21.89 8c0 4.418-3.532 8-7.89 8-4.357 0-7.889-3.582-7.889-8s3.532-8 7.89-8c4.357 0 7.889 3.582 7.889 8ZM.86 26.641C2.165 21.054 7.634 18 13.297 18h1.41c5.662 0 11.13 3.054 12.434 8.641a26.04 26.04 0 0 1 .566 3.362c.108 1.1-.782 1.997-1.872 1.997H2.167c-1.09 0-1.98-.898-1.872-1.997.113-1.15.313-2.28.566-3.362Z"
      fill="#000"
    />
    <path
      d="M21.048 24.07v-.546a.667.667 0 0 0-1.333 0v.554a2.52 2.52 0 0 0 1.333-.008ZM19.715 24.666v1.832c0 .344 0 .515.049.677.049.162.144.305.335.591l.045.068c.107.161.161.242.238.242.076 0 .13-.08.237-.242l.045-.068c.19-.286.286-.428.335-.59.05-.162.05-.334.05-.678V24.66c-.44.099-.896.1-1.334.006Z"
      fill="#fff"
    />
  </svg>
);

export default SvgModIcon;
