import * as React from "react";
import { SVGProps } from "react";

const CarrouselEventPreview = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={221}
    height={102}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={221} height={102} rx={8} fill="#F7F8F9" />
    <path
      d="M15.802 22.777c0 .428.324.776.725.776H204.31c.401 0 .726-.348.726-.776 0-.43-.325-.777-.726-.777H16.527c-.4 0-.726.348-.726.777ZM10.454 56.953c.155.16.155.42.005.585a.373.373 0 0 1-.278.121.352.352 0 0 1-.268-.121l-1.8-1.914A.429.429 0 0 1 8 55.33c0-.11.041-.215.113-.292l1.8-1.915a.363.363 0 0 1 .546 0c.15.16.15.425-.005.585l-1.52 1.622 1.52 1.622ZM210.117 53.706a.431.431 0 0 1-.005-.585.372.372 0 0 1 .278-.121c.098 0 .196.038.268.121l1.8 1.915a.43.43 0 0 1 .113.292c0 .11-.041.215-.113.292l-1.8 1.915a.362.362 0 0 1-.546 0 .431.431 0 0 1 .005-.585l1.521-1.622-1.521-1.622ZM110.691 88.553c0 .572-.433 1.036-.968 1.036-.534 0-.967-.464-.967-1.036 0-.572.433-1.035.967-1.035.535 0 .968.463.968 1.035ZM107.425 88.553c0 .858-.65 1.553-1.451 1.553-.802 0-1.452-.695-1.452-1.553s.65-1.553 1.452-1.553c.801 0 1.451.695 1.451 1.553ZM113.957 88.553c0 .572-.434 1.036-.968 1.036s-.968-.464-.968-1.036c0-.572.434-1.035.968-1.035s.968.463.968 1.035ZM117.222 88.553c0 .572-.433 1.036-.967 1.036-.535 0-.968-.464-.968-1.036 0-.572.433-1.035.968-1.035.534 0 .967.463.967 1.035Z"
      fill="#D5DDE0"
    />
    <rect x={16} y={11} width={89} height={8} rx={1.5} fill="#D5DDE0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 31a4 4 0 0 1 4-4h85a4 4 0 0 1 4 4v48a4 4 0 0 1-4 4H19a4 4 0 0 1-4-4V31Zm46.44 28.023c-1.19 0-2.33-.429-3.172-1.193-.842-.764-1.314-1.8-1.314-2.881 0-1.08.472-2.117 1.314-2.881.841-.765 1.982-1.194 3.172-1.194 1.19 0 2.331.43 3.172 1.194.842.764 1.314 1.8 1.314 2.88 0 1.081-.472 2.118-1.314 2.882-.84.764-1.982 1.193-3.172 1.193Zm0 .582c1.36 0 2.664-.49 3.626-1.363.961-.874 1.501-2.058 1.501-3.293 0-1.235-.54-2.42-1.501-3.293-.962-.873-2.266-1.364-3.626-1.364-1.36 0-2.664.49-3.625 1.364-.962.873-1.502 2.058-1.502 3.293 0 1.235.54 2.42 1.502 3.293.961.873 2.265 1.363 3.625 1.363Zm-.938-6.402a.347.347 0 0 0-.17.031.31.31 0 0 0-.127.108.271.271 0 0 0-.046.151v2.91c0 .054.016.107.047.152a.31.31 0 0 0 .127.108.348.348 0 0 0 .333-.022l2.243-1.456a.301.301 0 0 0 .099-.103.27.27 0 0 0 0-.267.3.3 0 0 0-.1-.103l-2.242-1.456a.341.341 0 0 0-.164-.053ZM114 31a4 4 0 0 1 4-4h85a4 4 0 0 1 4 4v48a4 4 0 0 1-4 4h-85a4 4 0 0 1-4-4V31Zm46.44 28.023c-1.19 0-2.331-.429-3.172-1.193-.841-.764-1.314-1.8-1.314-2.881 0-1.08.473-2.117 1.314-2.881.841-.765 1.982-1.194 3.172-1.194 1.19 0 2.331.43 3.172 1.194.842.764 1.314 1.8 1.314 2.88 0 1.081-.472 2.118-1.314 2.882-.841.764-1.982 1.193-3.172 1.193Zm0 .582c1.36 0 2.664-.49 3.626-1.363.961-.874 1.501-2.058 1.501-3.293 0-1.235-.54-2.42-1.501-3.293-.962-.873-2.266-1.364-3.626-1.364-1.36 0-2.664.49-3.625 1.364-.962.873-1.502 2.058-1.502 3.293 0 1.235.54 2.42 1.502 3.293.961.873 2.265 1.363 3.625 1.363Zm-.938-6.402a.35.35 0 0 0-.17.031.315.315 0 0 0-.127.108.276.276 0 0 0-.046.151v2.91c0 .054.016.107.046.152a.315.315 0 0 0 .127.108.351.351 0 0 0 .334-.022l2.243-1.456a.3.3 0 0 0 .098-.103.264.264 0 0 0 0-.267.3.3 0 0 0-.098-.103l-2.243-1.456a.343.343 0 0 0-.164-.053Z"
      fill="#D5DDE0"
    />
  </svg>
);

export default CarrouselEventPreview;
