import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgHeader = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 16 16"
    version="1.1"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M15 0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm0 4.5c0 .3-.2.5-.5.5h-13c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5v3z"></path>
      <circle cx="2.7" cy="2.7" r="0.7"></circle>
      <circle cx="4.7" cy="2.7" r="0.7"></circle>
      <circle cx="6.7" cy="2.7" r="0.7"></circle>
    </g>
  </svg>
);

export default SvgHeader;
