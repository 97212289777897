import styles from "./InputWS.module.scss";
import WhatsAppLine from "../FiraIcons/WhatsAppLine";
import FiraSelect, { FiraSelectSimple } from "../FiraSelect/FiraSelect";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { getCountriesActionCreator } from "../../store/Utils/UtilsActions";
import { useDispatch, useSelector } from "react-redux";
import { Country, FiraPhone } from "../../utils/types";
import { RootState } from "../../store/Store";
import { phoneNumberFormat } from "../../utils/phoneNumberFormat";
import { useUtils } from "../../providers/UtilsProvider";
import { CountryCode } from "../../services/utils";

export interface CallingCodeSimple {
  value: string;
  name: string;
}

interface InputWSProps {
  width?: string;
  height?: string;
  onChange(number: string, code?: CountryCode): void;
  phone?: FiraPhone;
  disable?: boolean;
}

const InputWS = ({
  width = "100%",
  height = "32px",
  onChange,
  phone,
  disable = false,
}: InputWSProps) => {
  const { utils } = useSelector((state: RootState) => state);
  const { countryCodes } = useUtils();
  const dispatch = useDispatch();
  const style = {
    width,
    height,
  };
  const [selectedCode, setSelectedCode] = useState<CountryCode>();
  const [number, setNumber] = useState<string>(phone ? phone.number : "");
  const getCallingCodes = async () => {
    dispatch(getCountriesActionCreator());
  };

  const handleCallingCodeSelect = (value: CountryCode) => {
    setSelectedCode(value);
    onChange(number, value);
  };

  const phoneMask = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const cleanedValue = inputValue.replace(/[^0-9]/g, "");
    const truncatedValue = cleanedValue.slice(0, 15);
    setNumber(truncatedValue || "");
    onChange(truncatedValue, selectedCode);
  };

  const handleCodeInit = (code: CountryCode) => {
    setSelectedCode(code);
  };

  useEffect(() => {
    if (utils.countries.length === 0) {
      getCallingCodes();
    }
  }, []);

  useEffect(() => {
    if (phone && countryCodes.length > 0) {
      setNumber(phone.number);
      const code = countryCodes.find((c) => c.countryCode === phone.country);
      if (code) {
        handleCodeInit(code);
      }
    }
  }, [phone, countryCodes]);

  return (
    <div style={style} className={styles.InputWS}>
      <WhatsAppLine color={"#56CD62"} />
      <FiraSelect
        currentSelection={selectedCode}
        renderSelection={(value) => {
          if (value)
            return <div style={{ fontSize: 12 }}>{value.dialCode}</div>;
          return <></>;
        }}
        height={"100%"}
        isDisabled={disable}
      >
        <>
          {countryCodes.map((code, index) => (
            <div key={index} onClick={() => handleCallingCodeSelect(code)}>
              {code.name}
            </div>
          ))}
        </>
      </FiraSelect>
      <input
        type="text"
        placeholder={"31 000 0000"}
        onChange={(e) => phoneMask(e)}
        value={number}
        disabled={disable}
      />
    </div>
  );
};

export default InputWS;
