import React from "react";
import { Icon } from "@iconify-icon/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Button, { ButtonStyle } from "../../Button/Button";
import Input, { InputStyles } from "../../Input/Input";
import { Checkbox } from "../..";
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";
import { RootState } from "../../../store/Store";
import { FiraStore } from "../../../types/Stores";
import { getOwnerStoresActionCreator } from "../../../store/Brand/BrandActions";
import { AlertType, usePopupAlert } from "../../../providers/AlertProvider";
import {
  getGlobalEventSettings,
  setGlobalEventSettings,
  updateStoresDefaultConfig,
} from "../../../services/settings";
import InfoHelpTooltip from "../../InfoHelpTooltip";

const parseData = (
  stores: FiraStore[],
  configs: string[]
): { storeId: string; enabled: boolean }[] => {
  return stores.map((store) => ({
    storeId: store.id,
    enabled: configs.includes(store.id),
  }));
};

const EventPermissions: React.FC = () => {
  const { brand, authentication } = useSelector((state: RootState) => state);
  const dispath = useDispatch();
  const { t } = useTranslation();
  const [search, setSearch] = React.useState("");
  const [initialState, setInitialState] = React.useState<{
    isActive: boolean;
    globalCounter: boolean;
    stores: string[];
  }>({
    isActive: false,
    globalCounter: false,
    stores: [],
  });
  const [form, setForm] = React.useState<{
    isActive: boolean;
    globalCounter: boolean;
    stores: string[];
  }>(initialState);
  const [isChanged, setIsChanged] = React.useState(false);
  const [filterList, setFilterList] = React.useState(
    brand.list.filter((s) => s.state === "ACTIVE")
  );
  const showAlert = usePopupAlert();

  const toggleActive = () => {
    setForm({
      ...form,
      isActive: !form.isActive,
      stores: !form.isActive === false ? [] : form.stores,
    });
  };

  const toggleCounters = () => {
    setForm((prevState) => ({
      ...prevState,
      globalCounter: !prevState.globalCounter,
    }));
  };

  const toggleAll = () => {
    if (
      form.stores.length ===
      brand.list.filter((s) => s.state === "ACTIVE").length
    ) {
      setForm({ ...form, stores: [] });
      return;
    }

    setForm({
      ...form,
      stores: brand.list.filter((s) => s.state === "ACTIVE").map((s) => s.id),
    });
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setFilterList(
      brand.list.filter(
        (s) =>
          s.name.toLowerCase().includes(event.target.value.toLowerCase()) &&
          s.state === "ACTIVE"
      )
    );
  };

  const onStoreSelected = (store: FiraStore) => {
    if (form.stores.includes(store.id)) {
      setForm({ ...form, stores: form.stores.filter((s) => s !== store.id) });
      return;
    }
    setForm({ ...form, stores: [...form.stores, store.id] });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const parsedData = parseData(brand.list, form.stores);
    try {
      await updateStoresDefaultConfig(parsedData);
      if (initialState.globalCounter !== form.globalCounter) {
        await setGlobalEventSettings();
        setInitialState((prevState) => ({
          ...prevState,
          globalCounter: form.globalCounter,
        }));
      }

      showAlert(AlertType.success, "Cambio guardado con exito");

      if (authentication.user && authentication.user.businessClientId) {
        dispath(
          getOwnerStoresActionCreator(authentication.user.businessClientId)
        );
      }
    } catch (error) {
      showAlert(AlertType.error, JSON.stringify(error));
    }
  };

  const handleCancelAction = () => {
    setForm(initialState);
  };

  const initSettings = async () => {
    const globalCounter = await getGlobalEventSettings();
    setInitialState((prevState) => ({ ...prevState, globalCounter }));
    setForm((prevState) => ({ ...prevState, globalCounter }));
  };

  const renderStoreList = () => {
    return (
      <ul style={List}>
        {brand.isLoading && (
          <div style={ListLoadingWrapper}>
            <Icon size={32} color="#ffde07" icon="line-md:loading-loop" />
          </div>
        )}
        <li onClick={toggleAll} style={Item}>
          <div>
            <Checkbox
              onChange={toggleAll}
              isActive={
                form.stores.length ===
                brand.list.filter((s) => s.state === "ACTIVE").length
              }
            />
          </div>
          <span style={ItemText}>Seleccionar Todo</span>
        </li>
        {filterList.map((store) => {
          return (
            <li
              onClick={() => onStoreSelected(store)}
              key={store.id}
              style={Item}
            >
              <div>
                <Checkbox
                  onChange={() => onStoreSelected(store)}
                  isActive={form.stores.includes(store.id)}
                />
              </div>
              <span style={ItemText}>{store.name}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  React.useEffect(() => {
    setIsChanged(JSON.stringify(form) !== JSON.stringify(initialState));
  }, [form, initialState]);

  React.useEffect(() => {
    const initialList = brand.list
      .filter(
        (store) =>
          store.useOwnerDefaultConfig == true && store.state === "ACTIVE"
      )
      .map((store) => store.id);

    setForm((prevState) => ({
      ...prevState,
      stores: initialList,
      isActive: initialList.length > 0,
    }));
    setInitialState((prevState) => ({
      ...prevState,
      stores: initialList,
      isActive: initialList.length > 0,
    }));
  }, [brand.list]);

  React.useEffect(() => {
    if (authentication.user && authentication.user.businessClientId) {
      dispath(
        getOwnerStoresActionCreator(authentication.user.businessClientId)
      );
    }
  }, [authentication.user]);

  React.useEffect(() => {
    initSettings();
  }, []);

  return (
    <form style={Form} onSubmit={handleSubmit}>
      <div style={ComponentWrapper}>
        <span style={Title}>
          {t("components.settings.event.tabs.permissions")}
        </span>
        <div style={PermissionWrapper}>
          <span>{t("components.settings.event.permissions.description")}</span>
          <ToggleSwitch
            hideTags
            checked={form.isActive}
            handleChecked={toggleActive}
          />
        </div>
      </div>

      {form.isActive && (
        <div style={ComponentWrapper}>
          <Input
            width="100%"
            biggerIcon
            fontSize="15px"
            placeholder={t("inputs.searchPlaceholder")}
            value={search}
            onChange={onSearchChange}
            inputStyle={InputStyles.searchConfig}
            icon={<Icon icon="mynaui:search" />}
          />
          {renderStoreList()}
        </div>
      )}

      <div style={ComponentWrapper}>
        <span style={Title}>{t("components.settings.event.extra.title")}</span>
        <div style={PermissionWrapper}>
          <div style={{ display: "flex", justifyContent: "center", gap: 4 }}>
            <span>
              {t("components.settings.event.extra.globalCounters.label")}
            </span>
            <InfoHelpTooltip
              text={t("components.settings.event.extra.globalCounters.tooltip")}
            />
          </div>

          <ToggleSwitch
            hideTags
            checked={form.globalCounter}
            handleChecked={toggleCounters}
          />
        </div>
      </div>

      <div style={ButtonWrapper}>
        <Button
          buttonStyle={ButtonStyle.light}
          clickHandler={handleCancelAction}
          type="button"
        >
          {t("buttons.cancel")}
        </Button>
        <Button type="submit" isDisabled={!isChanged}>
          {t("buttons.save")}
        </Button>
      </div>
    </form>
  );
};

const Form: React.CSSProperties = {
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: 16,
};

const ComponentWrapper: React.CSSProperties = {
  display: "grid",
  gridTemplateRows: "auto 1fr",
  gridTemplateColumns: "1fr",
  gap: 16,
};

const PermissionWrapper: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: 14,
  fontWeight: 400,
};

const Title: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 600,
};

const List: React.CSSProperties = {
  position: "relative",
  listStyle: "inside",
  maxHeight: 300,
  minHeight: 250,
  overflowY: "auto",
  listStyleType: "none",
  backgroundColor: "#F7F8F9",
  borderRadius: 8,
  padding: 9,
};

const ListLoadingWrapper: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(1, 1, 1, 0.4)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Item: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 10,
  padding: 8,
  borderBottom: "1px solid #D5DDE0",
};

const ItemText: React.CSSProperties = {
  fontSize: 12,
  lineHeight: "24px",
};

const ButtonWrapper: React.CSSProperties = {
  display: "flex",
  gap: 10,
  justifyContent: "center",
};

export default EventPermissions;
