import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgThumbnailBannerComercial = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 197 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect
      width={197}
      height={28}
      rx={4}
      transform="matrix(1 0 0 -1 0 28)"
      fill="#F7F8F9"
    />
    <rect
      width={28.946}
      height={3}
      rx={1}
      transform="matrix(1 0 0 -1 8.766 9.49)"
      fill="#D5DDE0"
    />
    <rect
      width={44.66}
      height={4.167}
      rx={1}
      transform="matrix(1 0 0 -1 8.766 16.005)"
      fill="#D5DDE0"
    />
    <rect
      width={17}
      height={5.97}
      rx={2.985}
      transform="matrix(1 0 0 -1 9 23.878)"
      fill="#D5DDE0"
    />
  </svg>
);

export default SvgThumbnailBannerComercial;
