import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgFakeInput = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="40px"
    viewBox="0 0 225 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.343 14.343a8 8 0 1 1 11.313 11.313 8 8 0 0 1-11.313-11.313ZM205 10c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10Zm-1.439 8.56a1.501 1.501 0 1 0-2.124-2.12 1.501 1.501 0 0 0 2.124 2.12Zm5 0a1.501 1.501 0 1 0-2.124-2.12 1.501 1.501 0 0 0 2.124 2.12Zm-7.778 2.463a1.004 1.004 0 0 0-.768.804.997.997 0 0 0 .166.747c.677.967 1.973 2.17 3.703 2.53 1.824.379 3.875-.23 5.859-2.435a.997.997 0 0 0-.061-1.427.989.989 0 0 0-.731-.255 1.004 1.004 0 0 0-.694.344c-1.616 1.795-2.982 2.019-3.966 1.815-1.079-.224-1.982-1.021-2.472-1.719a.995.995 0 0 0-.645-.412 1.003 1.003 0 0 0-.391.008Z"
      fill="#D5DDE0"
    />
    <rect x={0.5} y={0.5} width={224} height={39} rx={7.5} stroke="#D5DDE0" />
  </svg>
);

export default SvgFakeInput;
