import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgWidgets = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <g>
      <g>
        <path
          fill="#000"
          d="M14.26 10.408v5.565a.348.348 0 01-.348.348H8.348A.348.348 0 018 15.973v-5.565a.348.348 0 01.348-.347h5.564a.348.348 0 01.348.347zm-.348 6.608H8.348a.348.348 0 00-.348.348v5.565a.348.348 0 00.348.348h5.564a.348.348 0 00.348-.348v-5.565a.348.348 0 00-.348-.348zm6.956 0h-5.564a.348.348 0 00-.348.348v5.565a.348.348 0 00.348.348h5.564a.348.348 0 00.348-.348v-5.565a.348.348 0 00-.348-.348zm2.957-6.187l-4.82-2.782a.348.348 0 00-.477.129l-2.783 4.82a.349.349 0 00.129.476l4.82 2.783a.347.347 0 00.477-.129l2.782-4.82a.346.346 0 00-.026-.387.347.347 0 00-.102-.09z"
        ></path>
      </g>
    </g>
  </svg>
);

export default SvgWidgets;
