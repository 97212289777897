import React from "react";

interface Props {
  title?: string;
}
const SettingsStepLayout: React.FC<Props> = ({ children, title }) => {
  return (
    <div style={Wrapper}>
      {title && <div style={Title}>{title}</div>}
      {children}
    </div>
  );
};

const Wrapper: React.CSSProperties = {
  width: "100%",
  height: "100vh",
  overflowY: "scroll",
  padding: "1px 8px",
  boxSizing: "border-box",
  paddingBottom: "200px",
  display: "flex",
  flexDirection: "column",
  gap: 16,
};

const Title: React.CSSProperties = {
  padding: "10px 32px",
  fontWeight: "700",
  fontSize: 16,
  backgroundColor: "#fff",
  borderRadius: 8,
};

export default SettingsStepLayout;
