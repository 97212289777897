import React,{useState} from "react";
import  "./NextEvents.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
//import { firaLiveWidget, getNextEvents } from "firalibrary";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import EventItem from "./EventItem";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
/**
 * Description of main video carousel
 * @prop {string} fira_key - store id
 * @prop {string} primaryColor - button and swiper pagination color
 */
const  MainNextEvents=({ 
  firaKey,
  primaryTextColor,
  displayColor,
  buttonTextColor,
  primaryColor,
  backgroundColor,
  type,
 }) => {

  const { t } = useTranslation();
  const fira_key =firaKey;// domElement.getAttribute("fira-key");
  const backgrounComponent ="";//domElement.getAttribute("backgroun-component");
  const typeEvent=type;
  const [events, setEvents] = useState([{}]);
  const styleSwiper={
    backgroundColor: backgroundColor,
    padding: "10px"
  };
  
  const dataPreview=[
    {
      "id": "636a559eefc8e70f4872c8ff",
      "scheduledDate": "2022-12-31T00:00:00.000+00:00",
      "eventDescription": t('views.firaTv.exampleItems.eventDescription'),
      "evenTeaser": null,
      "eventPreviewImage": null,
      "firaEventCategory": null,
      "eventName":t('views.firaTv.exampleItems.eventName')
    },
    {
      "id": "636a56c8efc8e70f4872c900",
      "scheduledDate": "2022-12-30T13:17:00.000+00:00",
      "eventDescription":t('views.firaTv.exampleItems.eventDescription'),
       "evenTeaser": null,
      "eventPreviewImage": null,
      "firaEventCategory": null,
      "eventName":t('views.firaTv.exampleItems.eventName')
    },
    {
      "id": "636a58aaefc8e70f4872c901",
      "scheduledDate": "2022-12-12T13:26:00.000+00:00",
      "eventDescription": t('views.firaTv.exampleItems.eventDescription'), 
      "evenTeaser": null,
      "eventPreviewImage": null,
      "firaEventCategory": null,
      "eventName":t('views.firaTv.exampleItems.eventName')
    }
  ]

 const callEndpoint=async ()=>{
  // const data= await getNextEvents(fira_key,typeEvent );
  setEvents(dataPreview);//data.list);

 };

  useEffect(()=>{
    //firaLiveWidget(fira_key);
    callEndpoint();
  },[]);


  return (
    <div className={"MainCarrouselEvents_mainContainer"} style={styleSwiper}>
      <Swiper
      style={{
        "--swiper-pagination-color": primaryColor,
        "--swiper-pagination-bullet-inactive-color": "#999999",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "12px",
        "--swiper-pagination-bullet-horizontal-gap": "4px",
      }}
        pagination={{
          dynamicBullets: false,
          clickable: true
        }}
        modules={[Pagination]}
        spaceBetween={30}
        className="mySwiper"
      >
        {events.map((item, index)=>{
          return(
            <SwiperSlide key={index}>
              <EventItem 
              firakey={fira_key} 
              firasrc={item.id} 
              key={item.eventName} 
              buttonTextColor={buttonTextColor}
              primaryColor={primaryColor} 
              textColor={primaryTextColor}
              displayColor={displayColor} 
              event={item} 
              backgrounComponent={backgrounComponent} 
              type={typeEvent}/></SwiperSlide>
          );
        })}
      </Swiper>
    </div>
      
  );
};


export default MainNextEvents;