import "./Card.scss";

export enum CredentialStatus {
  available = "available",
  inUse = "inUse",
  error = "error",
}
interface Props {
  clickHandler?(): void;
  isInactive?: boolean;
  status: string;
  location: string;
  protocol: string;
}
/**
 * Description of Card
 * @prop {function} clickHandler - handler for the action of the Button
 * @prop {boolean} isInactive - tells the card to be in active status
 * @prop {string} status - indicates card status success|warning|danger
 * @prop {string} location - indicates the location of the credential
 * @prop {string} protocol - indicates the protocol of the credential
 */
const Card = (props: Props) => {
  const {
    clickHandler,
    isInactive = false,
    status = CredentialStatus.available,
    location,
    protocol,
  } = props;

  return (
    <div className={`ContainerCard ${isInactive ? "disabledCard" : ""}`}>
      <div className="ContainerRow">
        <div className={`ContainerText`}>
          <div className={`ItemTextTitle`}>País: </div>
          <span className={`ItemTextContent`}>{location}</span>
        </div>

        <div className={`ContainerStatus`}>
          <div className={`ItemEllipse ${status}`}></div>
        </div>
      </div>

      <div className={`ContainerSecondaryText`}>
        <div className={`ItemTextTitle`}>Protocolo: </div>
        <div className={`ItemTextContent`}>{protocol}</div>
      </div>

      <button
        disabled={isInactive}
        className={`ItemButton`}
        onClick={clickHandler}
      >
        Copiar
      </button>
    </div>
  );
};

export default Card;
