import { SVGProps } from "react";

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDrag = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="15"
    fill="none"
    viewBox="0 0 11 15"
  >
    <circle
      cx="8.794"
      cy="12.794"
      r="2.206"
      fill="#D0D0D0"
      transform="rotate(180 8.794 12.794)"
    ></circle>
    <circle
      cx="2.206"
      cy="12.794"
      r="2.206"
      fill="#D0D0D0"
      transform="rotate(180 2.206 12.794)"
    ></circle>
    <circle
      cx="8.794"
      cy="7.501"
      r="2.206"
      fill="#D0D0D0"
      transform="rotate(180 8.794 7.5)"
    ></circle>
    <circle
      cx="2.206"
      cy="7.501"
      r="2.206"
      fill="#D0D0D0"
      transform="rotate(180 2.206 7.5)"
    ></circle>
    <circle
      cx="8.794"
      cy="2.206"
      r="2.206"
      fill="#D0D0D0"
      transform="rotate(180 8.794 2.206)"
    ></circle>
    <circle
      cx="2.206"
      cy="2.206"
      r="2.206"
      fill="#D0D0D0"
      transform="rotate(180 2.206 2.206)"
    ></circle>
  </svg>
);

export default SvgDrag;
