import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgWindow = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.444.09h-7.6a.85.85 0 0 0-.597.246A.84.84 0 0 0 3 .93v5.877c0 .223.09.436.248.593a.85.85 0 0 0 .597.247h7.6a.85.85 0 0 0 .597-.247.84.84 0 0 0 .248-.593V.93a.84.84 0 0 0-.248-.593.85.85 0 0 0-.597-.246Zm0 6.716h-7.6V.93h7.6v5.877Z"
      fill="currentColor"
    />
    <path
      d="M7.221 9.325H1.31V5.127h.844v-.84H1.31a.85.85 0 0 0-.597.247.84.84 0 0 0-.248.593v4.198c.001.222.09.436.248.593a.85.85 0 0 0 .597.247H7.22a.85.85 0 0 0 .597-.247.84.84 0 0 0 .248-.593v-.84h-.845v.84Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgWindow;
