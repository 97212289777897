import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { handleError, RequestError } from "../../services/errorHandling";
import { getAllUsersFromStore, getAllUsersService } from "../../services/store";
import {
  getFiraUserService,
  updateUserStatusService,
} from "../../services/user";
import { FiraUserListResponse, FiraUserResponse } from "../../types/Response";
import { OwnerTeamActions } from "../actionsContants";

interface GetOwnerTeamMemberActionType {
  type:
    | OwnerTeamActions.getTeamMemberLoading
    | OwnerTeamActions.getTeamMemberSuccess
    | OwnerTeamActions.getTeamMemberFailed;
  payload: FiraUserResponse | RequestError;
}

export const getOwnerTeamMemberActionCreator = (userId: string) => {
  return async (
    dispatch: ThunkDispatch<
      GetOwnerTeamMemberActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: OwnerTeamActions.getTeamMemberLoading });
      const response = await getFiraUserService(userId);
      dispatch({
        type: OwnerTeamActions.getTeamMemberSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: OwnerTeamActions.getTeamMemberFailed,
        payload: handleError(error),
      });
    }
  };
};

interface GetAllUsersActionType {
  type:
    | OwnerTeamActions.getAllUsersLoading
    | OwnerTeamActions.getAllUsersFailed
    | OwnerTeamActions.getAllUsersSuccess;
  payload?: FiraUserListResponse[] | RequestError;
}

export const getAllUsersActionCreator = (
  offSet: number,
  size: number,
  store?: string
) => {
  return async (
    dispatch: ThunkDispatch<
      GetAllUsersActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: OwnerTeamActions.getAllUsersLoading });

      const response = store
        ? await getAllUsersFromStore(store)
        : await getAllUsersService(offSet, size);
      dispatch({
        type: OwnerTeamActions.getAllUsersSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: OwnerTeamActions.getAllUsersFailed,
        payload: handleError(error),
      });
    }
  };
};

interface ResetUsersActionType {
  type: OwnerTeamActions.resetUsers;
}

export const resetUsersActionCreator = (): ResetUsersActionType => {
  return { type: OwnerTeamActions.resetUsers };
};

interface ResetCurrentUserActionType {
  type: OwnerTeamActions.resetCurrentUser;
}

export const resetCurrentUserActionCreator = (): ResetCurrentUserActionType => {
  return { type: OwnerTeamActions.resetCurrentUser };
};

interface UpdateUserStatusActionType {
  type:
    | OwnerTeamActions.updateUserStatusLoading
    | OwnerTeamActions.updateUsersStatusSuccess
    | OwnerTeamActions.updateUsersStatusFailed;
  payload?: string | RequestError;
}

export const updateUserStatusActionCreator = (
  userId: string,
  storeId: string
) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateUserStatusActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: OwnerTeamActions.updateUserStatusLoading });
      const response = await updateUserStatusService(userId, storeId);
      dispatch({
        type: OwnerTeamActions.updateUsersStatusSuccess,
        payload: userId,
      });
    } catch (error) {
      dispatch({
        type: OwnerTeamActions.updateUsersStatusFailed,
        payload: handleError(error),
      });
    }
  };
};

export type OwnerActionType =
  | GetOwnerTeamMemberActionType
  | GetAllUsersActionType
  | ResetUsersActionType
  | ResetCurrentUserActionType
  | UpdateUserStatusActionType;
