import { ReactElement } from "react";
import type { To } from "react-router-dom";
import { AuthenticationState } from "../store/Authentication/reducer";
import { BrandState } from "../store/Brand/BrandReducer";
import { ProductsState } from "../store/Products/ProductsReducer";
import { RecoveryState } from "../store/Recovery/RecoveryReducer";
import { RegisterState } from "../store/Register/RegisterReducer";
import { UtilsState } from "../store/Utils/UtilsReducer";
import { TeamState, UserState } from "../store/User/UserReducer";
import { EventState } from "../store/Event/EventReducer";
import { DashboardState } from "../store/Dashboard/DashboardReducer";
import { VideoState } from "../store/Video/VideoReducer";
import { BadWordsType } from "../components/EventCreation/EventAttachments/EventAttachments";
import { EcommerceType, ProductStatus, FiraEventType } from "./enums";
import { CollectionState } from "../store/Collection/CollectionReducer";
import { SimplePermissionItem } from "../types/Response";
import { modulesType } from "../views/LandingConfigurationView/LandingConfigurationView";

export interface UserRegisterForm {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
  code?: { code: string };
  storeName?: string | null;
}

export interface NavLink {
  name: string;
  icon: ReactElement;
  to: To;
  clickHandler?(): void;
  subLink?: subNavLink[];
}

export interface subNavLink {
  name: string;
  to: To;
}

export interface LandingButton {
  name: string;
  module: modulesType;
  icon: ReactElement;
  component: ReactElement;
  clickHandler?(): void;
}

export enum UserPlans {
  starter = "STARTER",
  medium = "MEDIUM",
  premium = "PREMIUM",
}

export enum Currency {
  usd = "USD",
  eur = "EUR",
  pen = "PEN",
  brl = "BRL",
  mxn = "MXN",
  cop = "COP",
  clp = "CLP",
  ars = "ARS",
  gtq = "GTQ",
  nio = "NIO",
  crc = "CRC",
  hnl = "HNL",
  svc = "SVC",
  clf = "CLF",
  bob = "BOB",
}

export enum Language {
  es = "ES",
  en = "EN",
}

export enum Countries {
  chi = "Chile",
  es = "España",
}

export interface Languages {
  id: string;
  code: string;
  name: string;
}

export interface Country {
  alpha3Code: string;
  callingCodes: string[];
  name: string;
  coordinates: Coordinates;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface Currencies {
  name: string;
  code: string;
  symbol: string;
}

//DEPRECATED
export interface Address {
  address1: string;
  address2?: string;
  city?: string;
  postCode?: string;
  state?: string;
  country?: string;
}
//DEPRECATED

export interface Categories {
  id: string;
  imageUrl?: string;
  name?: string;
}

export interface FiraLoginInput {
  fieldLabel: string;
  fieldType: string; // TODO: COULD BE A ENUM
  isRequired: boolean;
  order: number;
}

export interface FiraLoginInputParams {
  id?: number;
  fieldLabel: string;
  fieldType: string;
  inputType?: string;
  isRequired: boolean;
  order?: number;
}

export interface FiraConfiguration {
  id?: string;
  enableLogin: boolean;
  enableCart?: boolean;
  enableAdvisorLogin?: boolean;
  enableEcommerceLogin?: boolean;
  enableBuyNowButton?: boolean;
  enableChatLogin?: boolean;
  enableNicknameModification?: boolean;
  likesUrls?: string[];
  registerUrl?: string;
  loadingUrl?: string;
  cartIconUrl?: string;
  mainColor: string;
  decimalSeparator?: string;
  thousandsSeparator?: string;
  showPrices?: boolean;
  showViews?: boolean;
  loginForm: FiraLoginInput[];
  disclaimerUrl?: string;
  personalDataUrl?: string;
  showLoading?: boolean;
  buyButtonText?: string | null;
  resolution?: string;
  isGlobal?: boolean;
}
export interface Ecommerce {
  credentials: string;
  ecommerce: string;
  state: string;
}

export interface FiraProductVariation {
  productId?: string;
  sku: string;
  name: string;
  price1: number;
  price2?: number;
  price3?: number;
  quantity: number;
  directUrl?: string;
  image?: string;
}

export interface FiraProductOption {
  id: string;
  productId?: string;
  name: string;
  optionItems: string[];
}

export interface FiraProduct {
  id: string;
  externalId: string;
  storeId: string;
  sku: string;
  name: string;
  description: string;
  price1: string;
  price2: string;
  price3: string;
  quantity: number;
  directUrl: string;
  createAt?: string;
  modifiedAt?: string;
  images: string[];
  firaProductOptionDtos: FiraProductOption[];
  firaVariationsDtos: FiraProductVariation[];
  status: ProductStatus;
  origin: EcommerceType;
  labels: FiraProductLabels[];
  categories: Category[];
  isAdvancedUrl: boolean;
  associatedStores?: AssociatedStore[];
  isGlobal?: boolean;
}

export interface AssociatedStore {
  id: string;
  name: string;
  currency: string;
}
export interface FiraProductLabels {
  id?: string;
  businessClientId?: string;
  name: string;
  labelColor: string;
  fontColor: string;
}
export interface LabelProductCreate {
  name: string;
  labelColor: string;
  fontColor: string;
}
export interface ImageProductObject {
  id: number;
  image: File;
  imageUrl: string;
  isNew: boolean;
  isDelete: boolean;
  selected: boolean;
  isPrimary: boolean;
}

export interface RootState {
  authentication: AuthenticationState;
  registration: RegisterState;
  recovery: RecoveryState;
  brand: BrandState;
  utils: UtilsState;
  user: UserState;
  products: ProductsState;
  event: EventState;
  dashboard: DashboardState;
  video: VideoState;
  collection: CollectionState;
  team: TeamState;
}

export interface LoginResponse {
  id: string;
  firstName: string;
  lastName: string;
  role: "ADMIN" | "SELLER";
  token: string;
  sessionId: string;
  username: string;
  stores: FiraUserStore[];
  businessClientId?: string;
}

export interface RefreshTokenResponse {
  token: string;
  id: string;
  firstName: string;
  lastName: string;
  role: "ADMIN" | "SELLER";
  username: string;
  stores: FiraUserStore[];
}

export interface RegisterResponse {
  id: string;
  firstName: string;
  lastName: string;
  role: "ADMIN" | "SELLER";
  username: string;
}

export interface EditStoreProfileForm {
  name: string;
  description: string;
  language: string;
  currency: string;
  logo?: string;
}

export interface CreateStoreForm {
  name: string;
  description: string;
  language: string;
  currency: string;
  address: Address;
  logo?: string;
}

export interface FiraUser {
  id: string;
  birthdate?: Date;
  email: string;
  gender?: GenderTypes;
  mobilephone?: string;
  role: "ADMIN" | "SELLER";
  username: string;
  firstName: string;
  lastName: string;
  address: Address;
  categories?: Categories[];
  avatar?: string;
  stores?: FiraUserStore[];
  active: boolean;
  isActive: boolean;
  password: string;
  status?: boolean;
  firaPermissionsDto?: FiraPermissions;
}

export interface FiraUserForm {
  firstName: string;
  lastName: string;
  birthdate?: Date;
  gender?: GenderTypes | string;
  mobilephone?: FiraPhone;
  avatar?: string;
  username?: string;
  email?: string;
}

export enum GenderTypes {
  F = "FEMALE",
  M = "MALE",
  OTHER = "OTHER",
}

export interface privateChat {
  chatUrl: string;
  moderatorId: string;
  userId: string;
}

export interface FiraBroadcast {
  id: string;
  broadcastingId: string;
  authorId: string;
  firebaseId: string;
  created: Date;
  live: boolean;
  preview: string;
  storeId: string;
  store: FiraStore;
  productIds: string[];
  customField: string;
  broadcastTitle: string;
  endedAt: Date;
  recordingId: string;
  chatUrl: string;
  showInFiraTV: true;
  privateChatUrls: privateChat[];
  converted: true;
  firaWebConfiguration: FiraConfiguration;
}

export interface FiraEvent {
  id: string;
  storeId: string;
  eventName: string;
  startDate: string;
  endDate: Date;
  scheduledDate: Date;
  creationDate: Date;
  status: EventStatus;
  userId: string;
  firaWebConfiguration: FiraConfiguration;
  moderators: FiraUserModerator[];
  userActionsStats: FiraUserActionsStats;
  publisherCredentialsDto?: PublisherCredentialsDto;
  suscriberCredentialsDto?: SuscriberCredentialsDto;
  urlBroadcastEvent: string;
  eventDescription?: string;
  eventTeaser?: string;
  eventPreviewImage?: string;
  firaEventCategory?: Category[];
  eventType?: FiraEventType;
  testUnits?: EventTestUnits;
  parentId?: string;
  isParent?: boolean;
  isGlobal?: boolean;
}

export interface EventTestUnits {
  streamTimeAllowed: Number;
  connectionsAllowed: Number;
  recordTimeAllowed: Number;
}

export interface FiraUserActionsStats {
  allConnectionTime: number;
  averageConnectionTime: number;
  bouncingRate: number;
  chatMessageCounting: number;
  clickAverage: number;
  engagementRate: number;
  eventDuration: number;
  likes: number;
  webConnections: number;
  mobileConnections: number;
  shares: number;
  carts: number;
  regularClicks: number;
  popupClicks: number;
}

export interface FiraUserModerator {
  id: string;
  email: string;
  role: RoleType;
  firstName: string;
  lastName: string;
  avatar: string;
  username: string | null;
}

export interface FiraEventNew {
  id: string;
  storeId?: string;
  eventName?: string;
  startDate?: Date;
  endDate?: Date;
  status?: EventStatus;
}
export interface FiraEventForm {
  storeId?: string;
  eventName: string;
  eventDateTimeRange: EventDateTimeRange;
}
export interface EventDateTimeRange {
  startDate: string;
  endDate: string;
}

export enum EventStatus {
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
  DONE = "DONE",
}
export interface FiraUserStore {
  storeId: string;
  role: "ADMIN" | "SELLER" | "CUSTOMER";
  firaPermissionsDto: FiraPermissions;
  active: boolean;
}

export interface FiraPermissions {
  //id?: string;
  userId?: string;
  storeId?: string;
  dashStatics: boolean;
  teamAddMember: boolean;
  teamEditMember: boolean;
  teamDeleteMember: boolean;

  eventsAddEvent: boolean;
  eventsEditEvent: boolean;
  eventsDeleteEvent: boolean;
  eventsModeration: boolean;
  eventsShowStatics: boolean;
  productsAddProduct: boolean;
  productsEditProduct: boolean;
  productsDeleteProduct: boolean;
  productsBatchUpload: boolean;
}

export interface PermissionsForm {
  dashStatics: boolean;
  teamAddMember: boolean;
  teamEditMember: boolean;
  teamDeleteMember: boolean;
  eventsAddEvent: boolean;
  eventsEditEvent: boolean;
  eventsDeleteEvent: boolean;
  eventsModeration: boolean;
  eventsShowStatics: boolean;
  productsAddProduct: boolean;
  productsEditProduct: boolean;
  productsDeleteProduct: boolean;
  productsBatchUpload: boolean;
}
export interface InvitationForm {
  storeId?: string;
  email?: string;
  userEmail?: string;
  firaPermissionsDto: PermissionsForm;
}

export enum InvitationStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  CLOSED = "CLOSED",
}

export interface FiraInvitation {
  id: string;
  userId: string;
  username: string;
  avatar: string;
  email: string;
  storeId: string;
  storeName: string;
  status?: InvitationStatus;
  createdAt: Date;
  acceptedAt: Date;
}

export interface streamBlock {
  name: string;
  wordList: string[];
}

export interface EventSetUpConfiguration {
  id: string;
  firaWebConfiguration: FiraConfiguration | null;
  products: string[];
  moderatorList: Moderator[];
  badWordsType: BadWordsType;
  isGlobal: boolean;
}

export interface BroadcastConfig {
  showInFiraTv: false;
  live: false;
  firaWebConfiguration: FiraConfiguration;
}

export interface SetUpFiraWebConfig {
  enableLogin: boolean;
  enableCart?: boolean;
  enableChatLogin?: boolean;
  enableNicknameModification?: boolean;
  likesUrl?: [];
  mainColor: string;
  decimalSeparator?: string;
  thousandsSeparator?: string;
  showPrices?: boolean;
  showViews?: boolean;
  loginForm: FiraLoginInput[];
}
export interface StartEventResponse {
  status: EventStatus;
  queues: ResponseQueues;
  startedTime: string;
}

export interface ResponseQueues {
  likeQueue: string;
  shareQueue: string;
  shoppingCartQueue: string;
  mobConnQueue: string;
  webConnQueue: string;
  regularClickQueue: string;
  popupClickQueue: string;
  broadcastingProductActionQueue: string[];
}

export interface UpdateEventResponse {
  id: string;
  message: string;
}

export interface TemplatesResponse {
  eventName: string;
  eventDate: Date;
  firaWebConfiguration: FiraConfiguration;
}

export interface FiraCredentials {
  publisherCredentialsDto: PublisherCredentialsDto;
  suscriberCredentialsDto: SuscriberCredentialsDto;
}

export interface PublisherCredentialsDto {
  key: string;
  url: string;
}

export interface SuscriberCredentialsDto {
  app: string;
  host: string;
  password: string;
  streamName: string;
  token: string;
  username: string;
  channel: string;
  appId: string;
}

export interface ResponseValidModerator {
  id: string;
  name: string;
  color?: string;
  role: "moderator" | "member";
  token: string;
  banned: boolean;
  broadcastId?: string;
  userParams?: any;
  moderator: boolean;
}

export interface ResponseSimpleProduct {
  id: string;
  broadcastingId: string;
  product: {
    id: string;
    name: string;
    directUrl: string;
    images: string[];
    price1: number;
    price2: number;
    price3: number;
  };
  onPopup: boolean;
}

export interface ResponseCurrentPopup {
  broadcastingProductId: string;
  onPopup: boolean;
  updateTime: string; // UTC time
  remainingTime: number;
}

export interface AddEventProductForm {
  broadcastingId: string;
  broadcastingProduct: string[];
}

export interface ResponseAddEventProduct {
  broadcastingId: string;
  broadcastingProductActionQueue: string[];
}

export interface AddEventProductPayload {
  products: ResponseSimpleProduct[];
  response: ResponseAddEventProduct;
}

export interface ResponseDeleteEventProduct {
  id: string;
  message: string;
}

export interface ResponseSendPopUp {
  id: string;
  message: string;
}

export interface SendPopUpForm {
  broadcastingId: string;
  broadcastingProductId: string;
  price: string;
}

export interface ResponseDeletePopUp {
  id: string;
  message: string;
}

export interface EditEventResponse {
  id: string;
  message: string;
}

export interface DashboardCountingResponse {
  livesCounting: number;
  livesTotalTime: number;
  liveTestCount: number;
  engagement: number;
  connections: {
    webConnections: number;
    mobileConnection: number;
  };
  clicksAverage: number;
  bouncingRate: number | null;
  connectionTime: number | null;
  averageConnectionTime: number;
  allConnectionTime: number;
  chatMessage: number | null;
  likeCount: number | null;
  shareCount: number | null;
  clicksCount: number | null;
}
export interface DashboardOwnerCountingResponse {
  allConnectionTime: number;
  chatMessageCounting: number;
  eventDuration: number;
  subscriptions: StoreSubscription[];
  likes: number;
  mobileConnections: number | null;
  popupClicks: number | null;
  regularClicks: number;
  shares: number;
  shoppingCarts: number | null;
  webConnections: number | null;
  amountLive: number | null;
  amountTest: number | null;
}

interface StoreSubscription {
  id: string;
  alias: string;
  validFrom: string;
  validTo: string;
}

export interface DashboardEvent {
  id: string;
  name: string;
  starDate: string;
  products: number;
  likes: number;
  shares: number;
  connections: number;
  messages?: number;
  storeName?: string;
}

export interface NextEventList {
  id: string;
  eventName: string | undefined;
  scheduleDate: string;
  endedDate: string;
  status: EventStatus;
}
export interface ResponseDeleteEventProduct {
  id: string;
  message: string;
}

export interface FiraParticipant {
  banned: boolean;
  shadowBanned: boolean;
  color: string;
  id: string;
  name: string;
  role: "moderator" | "member" | "fira_audience";
}

export interface ResponseQueue {
  id: string;
  count: number;
  createdAt: Date;
}

export interface ResponseSummary {
  webConnections: number;
  mobileConnections: number;
  likes: number;
  shares: number;
  shoppingCarts: number;
  regularClicks: number;
  popupClicks: number;
}

export interface ProductClicksTable {
  info: {
    name: string;
    images: string[];
  };
  summary: ResponseProductQueue;
}
export interface ResponseProductQueue {
  broadcastingProductId: string;
  rcPriceOneCounting: number;
  rciPriceOneCounting: number;
  rcPriceTwoCounting: number;
  rcPriceThreeCounting: number;
  pcPriceOneCounting: number;
  pciPriceOneCounting: number;
  pcPriceTwoCounting: number;
  pcPriceThreeCounting: number;
  totalRciPriceCounting: number;
  totalRcPriceCounting: number;
  totalPciPriceCounting: number;
  totalPcPriceCounting: number;
  shoppingCartPriceOneCounting: number;
  shoppingCartPriceTwoCounting: number;
  shoppingCartPriceThreeCounting: number;
}

export interface ResponseProductQueue {
  broadcastingProductId: string;
  rcPriceOneCounting: number;
  rcPriceTwoCounting: number;
  rcPriceThreeCounting: number;
  pcPriceOneCounting: number;
  pcPriceTwoCounting: number;
  pcPriceThreeCounting: number;
  shoppingCartPriceOneCounting: number;
  shoppingCartPriceTwoCounting: number;
  shoppingCartPriceThreeCounting: number;
}

export interface ResponseLiveData {
  eventName: string;
  firaWebConfiguration: FiraConfiguration;
  firaWebLogin?: boolean;
  startDate: Date;
  endDate: Date;
  suscriberCredentialsDto?: SuscriberCredentialsDto;
  storeName: string;
  storeLogo: string;
  currency: Currency;
  language: Language;
  status: EventStatus;
}

export interface UserInfoResponse {
  params: {
    user_0: string;
    email: string;
    phone: string;
    username: string;
  };
  bpaCount: number;
}

export interface Category {
  id: string;
  description: string;
}

export interface ItemStore {
  id: string;
  name: string;
  currency: string;
}

export interface EventGeneralDetailsType {
  broadcastingId: string;
  eventName: string;
  scheduleDate: Date;
  eventDescription?: string;
  categories?: string[];
  eventTeaser?: File | null;
  eventTeaserUrl?: string;
  eventPreviewImage?: File | null;
  eventPreviewImageUrl?: string;
  firaEventCategory?: Category[];
  newDate?: Date;
  imageAvailable?: boolean;
  teaserAvailable?: boolean;
}

export interface SettingSubElements {
  title: string;
  component: ReactElement;
  isActive?: boolean;
  id: string;
}

export interface SettingElements {
  title: string;
  subElements: SettingSubElements[];
  icon: ReactElement;
}

export interface FiraVideo {
  id: string;
  storeId: string;
  originEvent: string;
  videoName: string;
  eventName: string;
  creationDate: Date;
  videoDescription: string;
  categories: FiraVideoCategory[];
  videoUrls: FiraVideoUrl[];
  products?: FiraVideoProduct[];
  visibility: "HIDE" | "VISIBLE";
  eventTeaser: string;
  eventPreviewImage: string;
  videoStats: FiraVideoStats;
  videoClassification: FiraVideoclassification;
  videoEditing: "ENABLED" | "DISABLED";
  visibleTimeMillis: number;
  productsCount: number;
  status: "AVAILABLE" | "PROCESSING" | "FAILED";
  isParent: boolean | null;
  parentId: string | null;
}

export interface FiraVideoCategory {
  id: string;
  description: string;
}

export interface FiraVideoInfo {
  vodId: string;
  videoName: string;
  videoDescription: string;
}
export interface FiraVideoUrl {
  url: string;
  videoFormat: "MP4" | "FLV" | "TS" | "HLS";
  videoSize: number;
  videoDuration: number;
  videoDurationState: "COMPLETE" | "INCOMPLETE" | "NOT_FOUND";
  state: "CORRUPTED" | "VALID" | "NOT_FOUND" | "INVALID";
}

export interface infoVideoStats {
  videoProductId: string;
  image: string[];
  productName: string;
}
export interface DataActionProductsStats {
  info: infoVideoStats;
  count: number;
}

export interface DataVideoStats {
  likeCount: number;
  shareCount: number;
  videoPlayCount: number;
  commentCount: number;
  productClickCount: number;
  audienceCount: number;
  elapsedInit: "2023-03-29T08:00:00";
  elapsedEnd: "2023-03-29T09:00:00";
}
export interface AudienceCounts {
  webAudienceCount: number;
  mobileAudienceCount: number;
  androidAudienceCount: number;
  iosAudienceCount: number;
}
export interface FiraVideoStats {
  likeCount: number;
  shareCount: number;
  videoPlayCount: number;
  productClicksAvg: number;
  bouncingRate: number;
  engagementRate: number;
  commentCount: number;
  productClickCount: number;
  videoPlayAvgTime: number;
  productsClickStats: DataActionProductsStats[];
  audienceCount: AudienceCounts;
  statsTime: DataVideoStats[];
  videoPlayAccum: number;
}

export interface FiraVideoProduct {
  id: string;
  product: VideoProduct;
  status: "ACTIVATE" | "DEACTIVATE";
}
export interface VideoProduct {
  id: string;
  externalId: string;
  storeId: string;
  sku: string;
  name: string;
  price1: string;
  price2: string;
  price3: string;
  quantity: number;
  directUrl: string;
  images: string[];
  origin: string;
  status: "string";
}
export enum VideoAgeGroup {
  fourteenOlder = "FOURTEEN_OLDER",
  sevenOlder = "SEVEN_OLDER",
  kids = "KIDS",
  adults = "ADULTS",
}

export interface FiraVideoclassification {
  code: string;
  description: string;
  ageGroup: VideoAgeGroup;
  requireParentalGuide: boolean;
}

export enum VideoFilter {
  mostViewed = "MOST_VIEWED",
  mostLiked = "MOST_LIKED",
  mostShared = "MOST_SHARED",
  descend = "DESCEND_ALPHABET",
  ascend = "ASCEND_ALPHABET",
  newer = "NEWER_CREATION_DATE",
  older = "OLDER_CREATION_DATE",
  byName = "BY_VIDEO_NAME",
  default = "DEFAULT",
  custom = "CUSTOM",
}
export interface ChartDataOptionItemType {
  name: string;
  color: string;
  active: boolean;
}

export enum Visibility {
  hide = "HIDE",
  visible = "VISIBLE",
}

export enum Mode {
  automatic = "AUTOMATIC",
  manual = "MANUAL",
}
export interface FiraCollection {
  id: string;
  storeId: string;
  collectionName: string;
  collectionDescription?: string;
  visibility: Visibility;
  creationDate: string;
  settingMode: Mode;
  videos: FiraVideo[];
  sortVideos: VideoFilter;
  imageUrl?: string;
}

export interface FiraSimpleCollection {
  storeId: string;
  id?: string;
  collectionName: string;
  collectionDescription?: string;
  videos: string[];
  imageUrl?: string;
}

export interface EditCollectionResponse {
  id: string;
  message: string;
}

export interface CollectionBasicData {
  collectionName: string;
  collectionDescription?: string;
}

export interface SortableRowItemObject {
  index: number;
  image: File;
  imageUrl: string;
  isNew: boolean;
  isDelete: boolean;
  selected: boolean;
  isPrimary: boolean;
}

export enum StepRoutesComponent {
  general = "GENERAL_DETAILS",
  video = "VIDEO",
  products = "PRODUCTS",
  comments = "COMMENT",
}

export interface StepComponent {
  title: string;
  route: StepRoutes;
  active: boolean;
  ministeps?: Step[];
}
export interface FiraEcommerce {
  ecommerce: string;
  credentials: string;
  state: "UPDATING" | "SINCRONIZED";
  mainLanguage: "SPANISH" | "PORTUGUESE" | "ENGLISH";
  currency: ecommerceCurrencies;
}

enum EcommerceCurrencies {
  USD = "USD",
  BRL = "BRL",
  ARS = "ARS",
  COP = "COP",
  EUR = "EUR",
}

export interface UpdatePricesBody {
  vodProductId: string;
  pricesToChange: {
    price1: string;
    price2: string;
    price3: string;
  };
}
export interface UpdateCategoryBody {
  videoId: string;
  categories: Category[];
}

export interface UpdateCategoryBody {
  videoId: string;
  categories: Category[];
}

export enum WidgetType {
  mainCarousel = "MAIN_CAROUSEL",
  gridEvents = "GRID_EVENTS",
  nextEventsCarousel = "NEXT_EVENTS_CARROUSEL",
  vodGallery = "GRID_VOD_GALLERY",
  bannerSlider = "BANNER_SLIDER",
  bannerCommercial = "BANNER_COMMERCIAL",
  bannerTop = "BANNER_TOP",
  categories = "COLLECTION_CATEGORIES",
}

export enum EventSelectType {
  nextEvents = "NEXT_EVENTS",
  prevEvents = "PREVIOUS_EVENTS",
  collections = "COLLECTIONS",
}

export interface FiraConfigurationOptions {
  title?: string;
  firstCollectionId?: string;
  secondCollectionId?: string;
  thirdCollectionId?: string;
  fourthCollectionId?: string;
  fifthCollectionId?: string;
  sixthCollectionId?: string;
  seventhCollectionId?: string;
  eightCollectionId?: string;
  primaryTextColor?: string;
  secondaryTextColor?: string;
  tertiaryTextColor?: string;
  displayColor?: string;
  cardColor?: string;
  buttonTextColor?: string;
  primaryColor?: string;
  backgroundColor?: string;
  type?: EventSelectType | null;
  eventId?: string;
  collectionId?: string;
  videoSort?: boolean;
  videoFilter?: boolean;
  secondaryColor?: string;
  tertiaryColor?: string;
  quaternaryColor?: string;
  quinaryColor?: string;
  firstImage?: string;
  secondImage?: string;
  thirdImage?: string;
  firstUrl?: string;
  secondUrl?: string;
  thirdUrl?: string;
  firstVideoId?: string;
  secondVideoId?: string;
  thirdVideoId?: string;
  collectionArray?: FiraCollection[] | null;
}

export enum WidgetCategory {
  calendar = "CALENDAR",
  carousel = "CAROUSEL",
  cards = "CARDS",
  scheduled = "SCHEDULED",
  grid = "MOSAIC",
  banners = "BANNERS",
  collections = "COLLECTIONS",
}

export interface CriteriaType {
  name: string;
  criteria: string;
}
export interface StatsDataType {
  name: string;
  id: string;
  value: number;
  color: string;
}

export interface FiraBusinessUser {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  isActive: boolean;
}
export interface StoreUser {
  // userId: string;
  id: string;
  username: string;
  firstName: string;
  lastName?: string;
  email: string;
  role: roleType;
  avatar: string;
  isActive: boolean;
  mobilephone?: string;
}
export enum RoleType {
  //Deprecated
  //ADMIN = "ADMIN",
  SELLER = "SELLER",
  PROSELLER = "PROSELLER",
  OPERATOR = "OPERATOR",
  MANAGER = "MANAGER",
  MODERATOR = "MODERATOR",
  ACCOUNT_OWNER = "ACCOUNT_OWNER",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export interface CreateOwnerStoreForm {
  name: string;
  businessClientId: string;
  regionalConfiguration: {
    language: string;
    currency: string;
  };
  state: ownerStoreState;
  contact: {
    webpages: string[];
  };
}

export interface FiraTeamMember {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  gender?: "MALE" | "FEMALE" | "OTHER";
  isActive?: boolean;
  mobilephone?: FiraPhone;
  birthdate?: string;
  address?: {
    address1: string;
    address2: string;
    city: string;
    postCode: string;
    state: string;
    country: string;
  };
  avatar?: string;
  lastSignedIn?: string;
}

export interface FiraPermissionsStore {
  storeId: string;
  storeName?: string;
  role?: string;
  lastSignedIn?: string;
}

export interface FiraMultiEventForm {
  storeIdList?: string[];
  eventName: string;
  eventDateTimeRange: EventDateTimeRange;
}

export interface FiraMassiveEventForm {
  storeId: string;
  eventName: string;
  eventDateTimeRange: EventDateTimeRange;
}

export enum StoreStatusType {
  ONHOLD = "ON_HOLD",
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  CANCELLED = "CANCELLED",
  DONE = "DONE",
  DEACTIVATED = "DEACTIVATED",
  DELETED = "DELETED",
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  INPROCESS = "IN_PROCESS",
  VALID = "VALID",
  INVALID = "INVALID",
  INACTIVE = "INACTIVE",
}

export interface FiraSimpleEvent {
  endDate: string;
  eventName: string;
  eventType: FiraEventType;
  id: string;
  scheduledDate: string;
  startDate: string;
  status: string;
  isParent: boolean;
  parentId: string;
}

export interface FiraSimpleStore {
  id: string;
  name: string;
  legalName: string;
  brand: {
    logo: string;
    favicon: string;
    banner: string;
    name: string;
  };
  state: string;
  color: string;
}

export interface OwnerEventsType {
  event: FiraSimpleEvent;
  store: FiraSimpleStore;
}

export interface OwnerEventCreateResponse {
  id: string;
  eventName: string;
  startDate: Date;
  endDate: Date;
  status: string;
}

interface workflowListType {
  performedAt: Date;
  performedBy: string;
  operationType: string;
}
/** Deprecated */
interface contactType {
  emails?: string[];
  phones?: FiraPhone[];
  socialMedia?: socialMediaType[];
  webpages?: string[];
}
interface FiraPhone {
  country: string;
  operator: string;
  number: string;
  type: string;
}
interface socialMediaType {
  type: string;
  account: string;
}

/** Deprecated */

interface brandType {
  logo: string;
  favicon: string;
  banner: string;
  name: string;
}

interface regionalConfigurationType {
  language: string;
  currency: string;
  timeZone?: string;
  weightUnit: string;
  unitSystem?: string;
}
interface locationType {
  address: string;
  city: string;
  province: string;
  country: string;
  zipCode: string | number;
  region: string;
  state?: string;
}

interface UserStoreType {
  businessClientId: string;
  id: string;
  legalName: string;
  name: string;
  productsOn: string[];
  role: RoleType;
  urlLogo: string;
}

interface TeamMember {
  user: StoreUser;
  stores: [
    {
      storeName: string;
      apps: string[];
    }
  ];
  superAdmin?: boolean;
}

interface InvitationType {
  storeId: string;
  name: string;
  userEmail: string;
  role: RoleType | null;
  permissions: SimplePermissionItem[];
}

export enum PermissionsByGroupType {
  FIRA_WEB_DASHBOARD = "FIRA_WEB_DASHBOARD",
  FIRA_WEB_VIEW_EVENTS = "FIRA_WEB_VIEW_EVENTS",
  FIRA_WEB_MANAGE_EVENTS = "FIRA_WEB_MANAGE_EVENTS",
  FIRA_WEB_MODERATOR = "FIRA_WEB_MODERATOR",
  FIRA_WEB_STATISTICS_EVENTS = "FIRA_WEB_STATISTICS_EVENTS",
  FIRA_WEB_USER_VIEWS = "FIRA_WEB_USER_VIEWS",
  FIRA_WEB_MANAGE_USER = "FIRA_WEB_MANAGE_USER",
  FIRA_WEB_MANAGE_PRODUCTS = "FIRA_WEB_MANAGE_PRODUCTS",
  FIRA_WEB_BATCH_PRODUCTS = "FIRA_WEB_BATCH_PRODUCTS",
  FIRA_TV_MANAGE_VODS = "FIRA_TV_MANAGE_VODS",
  FIRA_TV_WIDGETS = "FIRA_TV_WIDGETS",
  FIRA_TV_STATISTICS = "FIRA_TV_STATISTICS",
  FIRA_TV_MODERATE_COMMENTS = "FIRA_TV_MODERATE_COMMENTS",
  FIRA_TV_MANAGE_COLLECTIONS = "FIRA_TV_MANAGE_COLLECTIONS",
}

export enum PermissionCategories {
  FIRA_WEB_DASHBOARD = "FIRA_WEB_DASHBOARD",
  EVENT_MANAGEMENT = "EVENT_MANAGEMENT",
  EVENT_MODERATION = "EVENT_MODERATION",
  EVENT_STATISTICS = "EVENT_STATISTICS",
  PRODUCTS_MANAGEMENT = "PRODUCTS_MANAGEMENT",
  PRODUCTS_BATCH = "PRODUCTS_BATCH",
  VOD_MANAGEMENT = "VOD_MANAGEMENT",
  WIDGETS_MANAGEMENT = "WIDGETS_MANAGEMENT",
  FIRA_TV_STATISTICS = "FIRA_TV_STATISTICS",
  FIRA_TV_COLLECTIONS_MANAGEMENT = "FIRA_TV_COLLECTIONS_MANAGEMENT",
}

export interface LoginFormResponse {
  fieldLabel: string;
  fieldType: string;
  isRequired: boolean;
  order: number;
}

export interface ConfigurationTemplate {
  enableLogin: boolean;
  enableChatLogin?: boolean;
  enableNicknameModification?: boolean;
  likesUrls: string[];
  mainColor: string;
  showPrices?: boolean;
  showViews?: boolean;
  loginForm: LoginFormResponse[];
  cartIconUrl?: string;
  loadingUrl?: string;
  disclaimerUrl: string;
  resolution: string;
  buyButtonText: string;
}

export interface DefaultConfigResponse {
  configurationTemplate: ConfigurationTemplate | null;
}

export interface SalesforceUserInfo {
  sub: string;
  name: string;
  preferred_username: string;
  email: string;
  given_name: string;
  family_name: string;
  zoneinfo: string;
  locale: string;
  updated_at: string;
}
