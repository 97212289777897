import { workflowListType } from "./Response";

export interface FiraStore {
  id: string;
  name: string;
  legalName: string;
  businessClientId: string;
  storeType: StoreType;
  location: StoreLocationType;
  regionalConfiguration: RegionalConfigurationType;
  brand: BrandContactType;
  state: string;
  contact: StoreContactType;
  isAutoExpose?: boolean;
  workflowList: workflowListType[];
  useOwnerDefaultConfig?: boolean;
  productConfig: { canCreate: boolean; canEdit: boolean } | null;
  additionalFields: { [key: string]: string } | null;
}

export type StoreGeneralInformationType = {
  name: string;
  legalName: string;
  description: string;
  location: StoreLocationType;
  regionalConfiguration: RegionalConfigurationType;
  storeType: string;
  isAutoExpose: boolean;
  additionalFields: { [key: string]: string };
};

export type StoreLocationType = {
  address: string;
  address2?: string;
  city: string;
  country: string;
  latitude?: number;
  longitude?: number;
  province: string;
  region?: string;
  state?: string;
  zipCode: string | number;
};

export type RegionalConfigurationType = {
  currency: string;
  language: string;
  timeZone: string;
  weightUnit?: string;
  unitSystem?: string;
};

export enum StoreType {
  TEXTILE = "TEXTILE",
  ELECTRONICS = "ELECTRONICS",
  VARIOUS = "VARIOUS",
  COSMETICS = "COSMETICS",
  MAIN = "MAIN_STORE",
}

export enum UnitSystemType {
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
}

export type StoreContactType = {
  emails: string[];
  phones: FiraPhone[];
  socialMedia: SocialMediaType[];
  webpages: string[];
};

export type Phone = {
  country: string;
  operator: string;
  number: string;
  type: PhoneType;
};

export enum PhoneType {
  MOBILE = "MOBILE",
  HOME = "HOME",
  BUSINESS = "BUSINESS",
}

export type SocialMediaType = {
  type: Account;
  account: string;
};

export enum Account {
  TWITTER = "TWITTER",
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  TIKTOK = "TIKTOK",
}

export type BrandContactType = {
  logo: string;
  favicon: string;
  banner: string;
  name: string;
  fontName?: string;
  mainColor?: string;
  secondaryColor?: string;
};
