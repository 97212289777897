import { t } from "i18next";

export const ManagerWeb = () => {
  return {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.manager.title"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
    ),
    permissions: [
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.manager.dashboard"
      ),
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.manager.events"
      ),
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.manager.products"
      ),
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.manager.team"
      ),
    ],
  };
};

export const ProSeller = () => {
  return {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.proseller.title"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
    ),
    permissions: [
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.proseller.proseller"
      ),
    ],
  };
};

export const FiraTV = () => {
  return {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.title"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
    ),
    permissions: [
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.title"
      ),
    ],
  };
};
