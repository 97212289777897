import { useEffect, useState } from "react";
import { TableCell, TableRow } from "../..";
import styles from "./DashboardEvents.module.scss";
import { convertDateUTC, timeZoneDate } from "../../../utils/handleDates";
import { timeTextFormatHMS } from "../../../utils/timeTextFormatHMS";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  DashboardCountingResponse,
  DashboardEvent,
  NextEventList,
  RootState,
} from "../../../utils/types";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { getEventActionCreator } from "../../../store/Event/EventActions";
import Skeleton from "../../Skeleton/Skeleton";

import "react-multi-date-picker/styles/colors/yellow.css";
import CardWidget from "../../../widgets/CardWidget/CardWidget";
import InteractionsWidget from "../../../widgets/InteractionsWidget/InteractionsWidget";
import AudienceResumeWidget from "../../../widgets/AudienceResumeWidget/AudienceResumeWidget";
import ViewsByDevices from "../../../widgets/ViewsbyDevicesWidget/ViewsByDevicesWidget";
import TableWidget from "../../../widgets/TableWidget/TableWidget";
import EventListWidget from "../../../widgets/EventListWidget/EventListWidget";
import {
  dashboardBroadcastCounting,
  dashboardBroadcastEventsList,
  dashboardBroadcastNextEvents,
} from "../../../services/events";
import { usePopupAlert, AlertType } from "../../../providers/AlertProvider";
import { handleError } from "../../../services/errorHandling";
import { setDateRangeActionCreator } from "../../../store/Dashboard/DashboardActions";
import Button from "../../Button/Button";
import { resetCurrentStoreActionCreator } from "../../../store/Brand/BrandActions";
import { FiraUserRole } from "../../../utils/enums";
import operatorPermissions, {
  FIRA_PERMISSIONS_GROUP,
} from "../../../utils/operatorPermissions";

interface EventBodyType {
  id: string;
  name: string;
  date: string;
  products: number;
  likes: number;
  shares: number;
}

const DashboardView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showAlert = usePopupAlert();
  const { dashboard, brand, utils, authentication } = useSelector(
    (state: RootState) => state
  );

  const [dashboardCounting, setDashboardCounting] =
    useState<DashboardCountingResponse>();
  const [eventList, setEventList] = useState<DashboardEvent[]>([]);
  const [nextEventList, setNextEventList] = useState<NextEventList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState({
    loadingCounting: false,
    loadingNextEvents: false,
    loadingEventList: false,
  });

  const eventsHeader = [
    { name: "", size: "9px" },
    { name: t("views.dashboard.table.name"), size: "112px" },
    { name: t("views.dashboard.table.date"), size: "120px" },
    { name: t("views.dashboard.table.products"), size: "142px" },
    { name: t("views.dashboard.table.likes"), size: "97px" },
    { name: t("views.dashboard.table.shares"), size: "97px" },
    { name: "", size: "29px" },
  ];
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if (utils.screenWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [utils.screenWidth]);

  const handleEventClick = (evento: DashboardEvent) => {
    dispatch(getEventActionCreator(evento.id));
    navigate("/home/events/event-detail/stats");
  };

  const parseEventsArray = (events: DashboardEvent[]) => {
    let temparray: EventBodyType[] = [];
    events.sort(
      (a, b) => new Date(b.starDate).getTime() - new Date(a.starDate).getTime()
    );
    events.forEach((event) => {
      let tempObj = {
        id: event.id,
        name: event.name,
        date: format(new Date(event.starDate), "dd/MM/yyyy"),
        products: event.products,
        likes: event.likes,
        shares: event.shares,
      };
      temparray.push(tempObj);
    });

    return temparray;
  };

  const callBroadcastCounting = async () => {
    if (brand.currentStore) {
      try {
        const response = await dashboardBroadcastCounting(
          brand.currentStore.id,
          formatDate(convertDateUTC(dashboard.firstDate)),
          formatDate(convertDateUTC(dashboard.lastDate))
        );
        setDashboardCounting(response.data);
        setLoadingState((prevState) => ({
          ...prevState,
          loadingCounting: false,
        }));
      } catch (error) {
        const handledError = handleError(error);
        showAlert(AlertType.error, handledError.message);
      }
    }
  };

  const callDashboardNextEvents = async () => {
    const current = new Date();
    current.setHours(0);
    current.setMinutes(0);
    if (brand.currentStore) {
      try {
        const response = await dashboardBroadcastNextEvents(
          brand.currentStore.id,
          formatDate(convertDateUTC(current)),
          0,
          200
        );
        setNextEventList(response.data);

        setLoadingState((prevState) => ({
          ...prevState,
          loadingNextEvents: false,
        }));
      } catch (error) {
        const handledError = handleError(error);
        showAlert(AlertType.error, handledError.message);
      }
    }
  };

  const callDashboardEventList = async () => {
    if (brand.currentStore) {
      try {
        const response = await dashboardBroadcastEventsList(
          brand.currentStore.id,
          formatDate(convertDateUTC(dashboard.firstDate)),
          formatDate(convertDateUTC(dashboard.lastDate)),
          0,
          200
        );
        if (response.data) {
          response.data.sort((a, b) =>
            new Date(a.starDate).getTime() > new Date(b.starDate).getTime()
              ? 1
              : -1
          );
          setEventList(response.data);
        } else {
          setEventList([]);
        }

        setLoadingState((prevState) => ({
          ...prevState,
          loadingEventList: false,
        }));
      } catch (error) {
        const handledError = handleError(error);
        showAlert(AlertType.error, handledError.message);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setLoadingState((prevState) => ({
      ...prevState,
      loadingCounting: true,
      loadingEventList: true,
      loadingNextEvents: true,
    }));
    callBroadcastCounting();
    callDashboardNextEvents();
    callDashboardEventList();
  }, [
    dashboard.firstDate,
    dashboard.lastDate,
    brand.currentStore,
    setIsLoading,
  ]);

  const formatDate = (date: Date): string => {
    return timeZoneDate(date);
  };

  const handleEventList = () => {
    navigate("/home/events");
  };

  const handleResetCurrentStore = () => {
    //siempre que se hace el reset debes redirigir al mainboard
    authentication.sessionId &&
      dispatch(resetCurrentStoreActionCreator(authentication.sessionId));
    navigate("/home/mainboard");
  };

  const containerCards = (
    <div
      className={`${styles.countersWrapper} ${
        isMobile ? styles.mobileCounter : ""
      }`}
    >
      <CardWidget
        title={t("views.dashboard.transmissionsNumber")}
        description={t("views.dashboard.transmissionsNumberDesc")}
        data={
          dashboardCounting && dashboardCounting.livesCounting
            ? dashboardCounting.livesCounting + ""
            : "0"
        }
        isLoading={loadingState.loadingCounting}
      />
      <CardWidget
        title={t("views.dashboard.testTransmissionsNumber")}
        description={t("views.dashboard.testTransmissionsNumberDesc")}
        data={
          dashboardCounting && dashboardCounting.liveTestCount
            ? String(dashboardCounting.liveTestCount)
            : "0"
        }
        isLoading={loadingState.loadingCounting}
      />
      <CardWidget
        title={t("views.dashboard.transmissionsTime")}
        description={t("views.dashboard.transmissionsTimeDesc")}
        data={
          dashboardCounting && dashboardCounting.livesTotalTime
            ? timeTextFormatHMS(dashboardCounting.livesTotalTime) + ""
            : "0"
        }
        isLoading={loadingState.loadingCounting}
      />
      <CardWidget
        title={t("views.dashboard.engagement")}
        description={t("views.dashboard.engagementDesc")}
        data={
          dashboardCounting && dashboardCounting.engagement
            ? dashboardCounting.engagement + "%"
            : "0%"
        }
        isLoading={loadingState.loadingCounting}
      />
      <CardWidget
        title={t("views.dashboard.rate")}
        description={t("views.dashboard.rateDesc")}
        data={
          dashboardCounting && dashboardCounting.bouncingRate
            ? dashboardCounting.bouncingRate + "%"
            : "0%"
        }
        isLoading={loadingState.loadingCounting}
      />
      <CardWidget
        title={t("views.dashboard.time")}
        description={t("views.dashboard.timeDesc")}
        data={
          dashboardCounting && dashboardCounting.averageConnectionTime
            ? timeTextFormatHMS(dashboardCounting.averageConnectionTime) + ""
            : "0"
        }
        isLoading={loadingState.loadingCounting}
      />
      <CardWidget
        title={t("views.dashboard.timeVisual")}
        description={t("views.dashboard.clicksDesc")}
        data={
          dashboardCounting && dashboardCounting.allConnectionTime
            ? timeTextFormatHMS(dashboardCounting.allConnectionTime) + ""
            : "0"
        }
        isLoading={loadingState.loadingCounting}
      />
    </div>
  );
  return (
    <>
      <div className={styles.dashboardContainer}>
        {/* boton de prueba para resetear la current store siendo account owner */}
        {/* {authentication.isAccountOwner && (
          <Button clickHandler={handleResetCurrentStore}>
            <p>reset current store</p>
          </Button>
        )} */}
        {containerCards}
        {!isMobile ? (
          <div className={styles.desktopContainer}>
            <div className={styles.desktopContainer__firstCol}>
              <TableWidget
                title={t("views.dashboard.titleTable")}
                subTitle={t("views.dashboard.subTitleTable")}
                header={eventsHeader}
                bodyData={parseEventsArray(eventList)}
                onClick={handleEventClick}
                isLoading={loadingState.loadingEventList}
              />
              <EventListWidget
                isLoading={loadingState.loadingNextEvents}
                nextEventList={nextEventList}
                handleEventList={handleEventList}
              />
            </div>
            <div className={styles.desktopContainer__secondCol}>
              <AudienceResumeWidget listEvent={eventList} />
              <ViewsByDevices
                desktop={
                  dashboardCounting
                    ? dashboardCounting.connections.webConnections
                    : 0
                }
                mobile={
                  dashboardCounting
                    ? dashboardCounting.connections.mobileConnection
                    : 0
                }
                other={0}
                isLoading={loadingState.loadingCounting}
              />
              <InteractionsWidget
                likes={
                  dashboardCounting?.likeCount
                    ? dashboardCounting?.likeCount
                    : 0
                }
                clicks={
                  dashboardCounting?.clicksCount
                    ? dashboardCounting?.clicksCount
                    : 0
                }
                messages={
                  dashboardCounting?.chatMessage
                    ? dashboardCounting?.chatMessage
                    : 0
                }
                shares={
                  dashboardCounting?.shareCount
                    ? dashboardCounting?.shareCount
                    : 0
                }
                isLoading={loadingState.loadingCounting}
                pieHeight={268}
              />
            </div>
          </div>
        ) : (
          <div className={styles.mobileContainer}>
            <AudienceResumeWidget listEvent={eventList} />
            <div className={styles.subGrid}>
              <div className={styles.subGrid__views}>
                <ViewsByDevices
                  desktop={
                    dashboardCounting
                      ? dashboardCounting.connections.webConnections
                      : 0
                  }
                  mobile={
                    dashboardCounting
                      ? dashboardCounting.connections.mobileConnection
                      : 0
                  }
                  other={0}
                  isLoading={loadingState.loadingCounting}
                />
              </div>
              <div className={styles.subGrid__list}>
                <EventListWidget
                  isLoading={loadingState.loadingNextEvents}
                  nextEventList={nextEventList}
                  handleEventList={handleEventList}
                />
              </div>
              <div className={styles.subGrid__interactions}>
                <InteractionsWidget
                  likes={
                    dashboardCounting?.likeCount
                      ? dashboardCounting?.likeCount
                      : 0
                  }
                  clicks={
                    dashboardCounting?.clicksCount
                      ? dashboardCounting?.clicksCount
                      : 0
                  }
                  messages={
                    dashboardCounting?.chatMessage
                      ? dashboardCounting?.chatMessage
                      : 0
                  }
                  shares={
                    dashboardCounting?.shareCount
                      ? dashboardCounting?.shareCount
                      : 0
                  }
                  isLoading={loadingState.loadingCounting}
                  pieHeight={360}
                />
              </div>
            </div>
            <TableWidget
              title={t("views.dashboard.titleTable")}
              subTitle={t("views.dashboard.subTitleTable")}
              header={eventsHeader}
              bodyData={parseEventsArray(eventList)}
              onClick={handleEventClick}
              isLoading={loadingState.loadingEventList}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardView;
