import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { SettingElements, SettingSubElements } from "../../../utils/types";
import { SubItemArrow } from "../../FiraIcons";
import "./settingsSidebar.scss";

type SettingsSidebarProps = {
  settingElements: SettingElements[];
  setSettingElements: Dispatch<SetStateAction<SettingElements[]>>;
  setComponent: Dispatch<SetStateAction<ReactElement | null>>;
  inactiveAllItems(): void;
  component: ReactElement | null;
  screenWidth: number;
  activeItem(id: string): void;
  componentRef: React.RefObject<HTMLDivElement>;
};

export default function SettingsSidebar({
  settingElements,
  setSettingElements,
  setComponent,
  inactiveAllItems,
  component,
  screenWidth,
  activeItem,
  componentRef,
}: SettingsSidebarProps) {
  const sidebar = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const clickhandler = (subElement: SettingSubElements) => {
    const settingItems: SettingElements[] = [...settingElements];

    inactiveAllItems();

    activeItem(subElement.id);

    setComponent(subElement.component);
    setSettingElements(settingItems);
  };

  useEffect(() => {
    if (sidebar.current && screenWidth < 981) {
      if (!component) {
        if (sidebar.current) {
          sidebar.current.style.display = "block";
        }
      }

      if (!component) {
        setTimeout(() => {
          if (sidebar.current) {
            sidebar.current.style.opacity = "1";
          }
        }, 100);
      } else {
        sidebar.current.style.opacity = "0";
      }

      if (component && componentRef.current) {
        setTimeout(() => {
          if (sidebar.current && componentRef.current) {
            sidebar.current.style.display = "none";
            componentRef.current.style.display = "block";
          }
        }, 400);
      }
    }
  }, [component]);

  useEffect(() => {
    if (sidebar.current && screenWidth > 981) {
      if (sidebar.current) {
        sidebar.current.style.display = "block";
        sidebar.current.style.opacity = "1";
      }
      if (componentRef.current) {
        componentRef.current.style.display = "block";
      }
    } else if (sidebar.current && screenWidth < 981) {
      if (sidebar.current && component) {
        sidebar.current.style.display = "none";
        sidebar.current.style.opacity = "0";
      }
      if (componentRef.current) {
        componentRef.current.style.display = "block";
      }
    }
  }, [screenWidth]);

  return (
    <div id="SettingsSidebar" ref={sidebar}>
      <ul id="mainList">
        {settingElements.map((element, index) => (
          <li key={index}>
            <div className="sideBarElement">
              <div className="sideBarElementIcon">{element.icon}</div>
              <span className="sidebarTitle">{t(element.title)}</span>
            </div>

            <ul className="subList">
              {element.subElements.map((subElement, index) => (
                <li
                  className={`subListElement${
                    subElement.isActive ? " subElementActive" : ""
                  }`}
                  onClick={() => {
                    clickhandler(subElement);
                  }}
                  key={index}
                >
                  <span className="sidebarSubTitle">{t(subElement.title)}</span>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}
