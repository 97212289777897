import { ReactElement, ReactNode, useState } from "react";
import {
  Link,
  To,
  useLocation,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import { subNavLink } from "../../utils/types";
import styles from "./FiraNavButton.module.scss";

export enum NavButtonPosition {
  top = "TOP_SECTION",
  bottom = "BOTTOM_SECTION",
}

interface FiraNavButtonProps {
  clickHandler?(): void;
  to: To;
  children?: ReactNode | undefined;
  icon?: ReactElement;
  position?: NavButtonPosition;
  subButtons?: subNavLink[];
}

const FiraNavButton = ({
  clickHandler,
  to,
  children,
  icon,
  position = NavButtonPosition.top,
  subButtons = [],
}: FiraNavButtonProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });
  const [subMenuIsOpen, setSubMenuIsOpen] = useState<boolean>(false);
  const location = useLocation();

  const subMatch = subButtons.filter((p) => {
    return location.pathname.includes(p.to.toString());
  });

  const subActive = subMatch.length > 0;

  const handleClick = () => {
    if (clickHandler && subButtons.length == 0) clickHandler();
    if (subButtons.length > 0) {
      setSubMenuIsOpen(true);
    } else {
      return;
    }

    if (subMenuIsOpen) {
      if (clickHandler) clickHandler();
      setSubMenuIsOpen(false);
    }
  };

  return (
    <>
      <Link
        to={to}
        className={`${styles.NavButton} ${
          (match && position === NavButtonPosition.top) || subMatch.length > 0
            ? styles.isActive
            : ""
        }`}
        onClick={handleClick}
      >
        <div
          className={`${
            position === NavButtonPosition.top
              ? styles.NavButton__Icon
              : styles.BottomIcon
          }`}
        >
          {icon}
        </div>
        {children}
      </Link>
      {subMenuIsOpen &&
        subButtons.map((sub) => {
          return match || subActive ? (
            <SubButton
              key={sub.name}
              name={sub.name}
              to={sub.to}
              clickHandler={clickHandler}
            />
          ) : (
            ""
          );
        })}
    </>
  );
};

interface SubButtonProps {
  name?: string;
  to: To;
  clickHandler?(): void;
}

const SubButton = ({ name, to, clickHandler }: SubButtonProps) => {
  const location = useLocation();

  const isActive = location.pathname.includes(to.toString());
  return (
    <Link to={to} className={styles.navSubButton} onClick={clickHandler}>
      <div
        className={`${styles.subButtonName} ${
          isActive ? styles.isSubActive : ""
        }`}
      >
        {name}
      </div>
    </Link>
  );
};

export default FiraNavButton;
