import { ChatBubble, EmptyChatBubble, FakeInput, Sign } from "../FiraIcons";
import styles from "./VideoInstructions.module.scss";
import defaultLogo from "../../assets/img/fira-logo.png";
import { dateFormat } from "../../utils/handleDates";
import { useTranslation } from "react-i18next";

interface VideoInstructionsProps {
  startDate: Date;
  storeLogo?: string;
  eventColor: string;
}

const VideoInstructions = ({
  startDate,
  storeLogo = "",
  eventColor,
}: VideoInstructionsProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.instrutionsContainer}>
      <div className={styles.videoPlaceholder}>
        <div className={styles.dateContainer}>
          <div className={styles.storeLogo}>
            <img src={storeLogo ? storeLogo : defaultLogo} />
          </div>
          <div className={styles.centerText}>
            <p className="blockquote-text">
              {t("views.events.eventDetail.instructions.title")}
            </p>
            <p className="body-14-text">{dateFormat(new Date(startDate))}</p>
          </div>
          <div className={styles.rightlogo}>
            <Sign style={{ color: eventColor ? eventColor : `#BEEF00` }} />
          </div>
        </div>
        <div className={styles.messageContainer}>
          <div className={styles.inside}>
            <p>{t("views.events.eventDetail.instructions.instruction")}</p>
          </div>
        </div>
      </div>
      <div className={styles.chatPlaceholder}>
        <div className={styles.message}>
          <EmptyChatBubble className={styles.bubble} />
          <p className={styles.title}>
            {t("views.events.eventDetail.fakeChat.title")}
          </p>
          <p className={styles.sub}>
            {t("views.events.eventDetail.fakeChat.subTitle")}
          </p>
        </div>
        <div className={styles.input}>
          <FakeInput />
        </div>
      </div>
    </div>
  );
};

export default VideoInstructions;
