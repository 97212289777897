import { t } from "i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import { RootState } from "../../../store/Store";
import {
  getAvailableVideosActionCreator,
  resetAvailableListActionCreator,
} from "../../../store/Video/VideoActions";
import { FiraVideo } from "../../../utils/types";
import { CloseButton, SimpleCheck } from "../../FiraIcons";
import {
  InfiniteTable,
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableImage,
  TableRow,
} from "../../Table/Table";
import noImageStoreSVG from "../../../assets/svg/no-image-store.svg";
import styles from "./VideosListModal.module.scss";
import Checkbox from "../../Checkbox/Checkbox";
import Button, { ButtonStyle } from "../../Button/Button";
import Skeleton from "../../Skeleton/Skeleton";

interface VideosListModalProps {
  isOpen: boolean;
  onClose(): void;
  save(videos: FiraVideo[]): void;
  addNewVideos?(videos: string[]): void;
  preSelectedList?: FiraVideo[];
}

const VideosListModal = ({
  isOpen,
  onClose,
  save,
  addNewVideos,
  preSelectedList,
}: VideosListModalProps) => {
  const { brand, video } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [videosList, setVideosList] = useState<FiraVideo[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const [selectedVideos, setSelectedVideos] = useState<FiraVideo[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  if (!isOpen) {
    return null;
  }

  const checkSelection = (videoId: string) => {
    if (selectedIds.includes(videoId)) {
      return true;
    } else {
      return false;
    }
  };

  const headerRows = [
    {
      name: "",
      size: "20px",
    },
    {
      name: "",
      size: "40px",
    },
    {
      name: t("modals.videosList.table.videoName"),
      size: "80px",
    },
    {
      name: t("modals.videosList.table.desc"),
      size: "40px",
    },
    {
      name: t("modals.videosList.table.teaser"),
      size: "40px",
    },
    {
      name: t("modals.videosList.table.products"),
      size: "40px",
    },
    {
      name: "",
      size: "120px",
    },
  ];

  const header = (
    <TableRow>
      {headerRows.map((head, i) => (
        <TableHead key={i} size={head.size} centerContent={i !== 2}>
          {head.name}
        </TableHead>
      ))}
    </TableRow>
  );

  const body = videosList.map((video, i) => {
    return (
      <TableRow key={i}>
        <TableCell size="20px">
          <Checkbox
            clickHandler={() => {
              handleCheckbox(video);
            }}
            isActive={checkSelection(video.id)}
          />
        </TableCell>
        <TableCell size="20px">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TableImage
              src={
                video.eventPreviewImage
                  ? video.eventPreviewImage
                  : noImageStoreSVG
              }
            />
          </div>
        </TableCell>
        <TableCell size="80px">
          <p>{video.videoName}</p>
        </TableCell>
        <TableCell size="40px" centerContent>
          <p>
            {video.videoDescription !== null ? (
              <SimpleCheck />
            ) : (
              <span className={styles.simpleLine}>-</span>
            )}
          </p>
        </TableCell>
        <TableCell size="40px" centerContent>
          <p>
            {video.eventTeaser !== null ? (
              <SimpleCheck />
            ) : (
              <span className={styles.simpleLine}>-</span>
            )}
          </p>
        </TableCell>
        <TableCell size="40px" centerContent>
          {video.productsCount}
        </TableCell>
        <TableCell size="120px"></TableCell>
      </TableRow>
    );
  });

  const SkeletonBody = () => {
    return (
      <TableRow>
        <TableCell size="20px">
          <Checkbox />
        </TableCell>
        <TableCell size="20px">
          <Skeleton width="20" height="20px" />
        </TableCell>
        <TableCell size="80px">
          <Skeleton width="60px" height="20px" />
        </TableCell>
        <TableCell size="40px" centerContent>
          <Skeleton width="30px" height="20px" />
        </TableCell>
        <TableCell size="40px" centerContent>
          <Skeleton width="30px" height="20px" />
        </TableCell>
        <TableCell size="40px" centerContent>
          <Skeleton width="30px" height="20px" />
        </TableCell>
        <TableCell size="120px">
          <Skeleton width="100px" height="20px" />
        </TableCell>
      </TableRow>
    );
  };

  const loadingBody = (
    <>
      <SkeletonBody />
      <SkeletonBody />
      <SkeletonBody />
      <SkeletonBody />
      <SkeletonBody />
    </>
  );

  const getVideos = () => {
    brand.currentStore &&
      dispatch(
        getAvailableVideosActionCreator(brand.currentStore.id, 0, 20, "DEFAULT")
      );
  };

  const closeModal = () => {
    setVideosList([]);
    onClose();
    dispatch(resetAvailableListActionCreator());
  };

  const saveData = () => {
    let videos: string[] = [];
    if (selectedVideos.length > 0) {
      selectedVideos.map((vid) => {
        videos.push(vid.id);
      });
    }
    if (addNewVideos) addNewVideos(videos);

    save(selectedVideos);
    onClose();
  };

  const handleVideoFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event?.target.value;
    setFilterValue(keyword);
    if (video.availableList) {
      if (keyword !== "") {
        setVideosList(
          video.availableList.filter((vod) => {
            return vod.videoName
              .toLowerCase()
              .includes(keyword.toLocaleLowerCase());
          })
        );
      } else {
        setVideosList(video.availableList);
      }
    }
  };

  const handleCheckbox = (video: FiraVideo) => {
    if (selectedIds.includes(video.id)) {
      setSelectedVideos((videosList) => [
        ...videosList.filter((v) => video.id !== v.id),
      ]);
      setSelectedIds((selectedIds) => [
        ...selectedIds.filter((id) => video.id !== id),
      ]);
      return;
    }

    setSelectedVideos((videosList) => [...videosList, video]);
    setSelectedIds((selectedIds) => [...selectedIds, video.id]);
  };

  const handleHeaderCheckBox = () => {
    if (videosList.length === 0) {
      return;
    }

    if (selectedIds.length === videosList.length) {
      setSelectedIds([]);
      setSelectedVideos([]);
    } else {
      setSelectedVideos(videosList);
      let arr: string[] = [];
      videosList.map((i) => {
        arr.push(i.id);
      });
      setSelectedIds(arr);
    }
  };

  useEffect(() => {
    if (video.availableList !== undefined) {
      setVideosList(video.availableList);
    }
  }, [video]);

  useEffect(() => {
    if (preSelectedList) {
      setSelectedVideos(preSelectedList);
      let ids: string[] = [];
      preSelectedList.map((item) => {
        ids.push(item.id);
      });

      setSelectedIds(ids);
    }
  }, [preSelectedList]);

  useEffect(() => {
    getVideos();
  }, []);

  return (
    <ModalLayout>
      <div className={styles.VideosListModalWrapper}>
        <div className={styles.VideosListModalWrapper__Top}>
          <div className={styles.VideosListModalWrapper__Top__Title}>
            {t("modals.videosList.title")} ({selectedVideos.length})
          </div>
          <div style={{ cursor: "pointer" }}>
            <CloseButton clickHandler={closeModal} />
          </div>
        </div>
        <div className={styles.separator}></div>
        <div>
          <TableHeader
            checkboxHandle={handleHeaderCheckBox}
            checkboxIsActive={
              selectedVideos.length === videosList.length &&
              videosList.length > 0
            }
            searchInputValue={filterValue}
            searchInputChangeHandle={handleVideoFiltering}
          />
          <div className={styles.VideosListModalWrapper__Table}>
            {!video.isLoading ? (
              <InfiniteTable
                header={header}
                body={body}
                loadData={() => ""}
                rowsNumber={videosList.length}
                hasMore={videosList.length >= 20 && !video.error}
              />
            ) : (
              <>
                <Table header={header} body={loadingBody} />
              </>
            )}
          </div>
        </div>
        <div className={styles.separator}></div>
        <div className={styles.VideosListModalWrapper__Buttons}>
          <Button
            buttonStyle={ButtonStyle.border}
            height={"32px"}
            width={"142px"}
            clickHandler={closeModal}
          >
            <span className={styles["body-12-low-text"]}>
              {t("modals.videosList.abandon")}
            </span>
          </Button>
          <Button height={"32px"} width={"142px"} clickHandler={saveData}>
            <span className={styles["body-12-low-text"]}>
              {t("modals.videosList.save")}
            </span>
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default VideosListModal;
