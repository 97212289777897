import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSuccessBubble = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M14.416 4.594a7.49 7.49 0 0 0-1.612-2.4 7.435 7.435 0 0 0-2.4-1.611A7.46 7.46 0 0 0 7.5 0h-.033a7.48 7.48 0 0 0-5.298 2.217 7.449 7.449 0 0 0-1.594 2.39 7.531 7.531 0 0 0 .23 6.275v2.544c0 .425.345.77.77.77H4.12A7.524 7.524 0 0 0 7.467 15h.036a7.501 7.501 0 0 0 2.89-.574 7.437 7.437 0 0 0 2.39-1.594 7.446 7.446 0 0 0 1.616-2.377A7.5 7.5 0 0 0 15 7.533a7.519 7.519 0 0 0-.584-2.94Z"
      fill="currentColor"
    />
    <path
      d="M9.912 5.16A.685.685 0 0 1 10.36 5c.167.001.327.06.446.165.12.106.188.248.193.398a.545.545 0 0 1-.17.406L7.405 9.815a.647.647 0 0 1-.209.135.706.706 0 0 1-.718-.12L4.205 7.792a.584.584 0 0 1-.15-.188.529.529 0 0 1 .134-.644.654.654 0 0 1 .214-.127.71.71 0 0 1 .503.008c.08.032.15.077.21.134l1.797 1.613 2.983-3.41a.215.215 0 0 1 .017-.018h-.001Z"
      fill="#fff"
    />
  </svg>
);

export default SvgSuccessBubble;
