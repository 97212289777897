import * as React from "react";
import { SVGProps } from "react";

const SvgCloseX = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m2 2 8.5 8.5m0-8.5L2 10.5"
      stroke="#000"
      strokeWidth={3}
      fill="currentColor"
    />
  </svg>
);

export default SvgCloseX;
