import { Country } from "../../utils/types.d";
import { CountriesActions, UtilActions } from "../actionsContants";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { handleError } from "../../services/errorHandling";
import { getCountriesService } from "../../services/utils";

interface SetNavbarStateActionType {
  type: UtilActions.setNavbarState;
  payload: boolean;
}
export const setNavbarState = (state: boolean): SetNavbarStateActionType => {
  return { type: UtilActions.setNavbarState, payload: state };
};

interface SetScreenWidthActionType {
  type: UtilActions.currentScreenWidth;
  payload: number;
}
export const setCurrentScreenWidthActionCreator = (
  width: number
): SetScreenWidthActionType => {
  return { type: UtilActions.currentScreenWidth, payload: width };
};
interface GetCountriesActionType {
  type:
    | CountriesActions.getCountriesFailed
    | CountriesActions.getCountriesSuccess
    | CountriesActions.getCountriesLoading;
  payload: Country[];
}

export const getCountriesActionCreator = () => {
  return async (
    dispatch: ThunkDispatch<
      GetCountriesActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: CountriesActions.getCountriesLoading });
      const response = await getCountriesService();
      dispatch({
        type: CountriesActions.getCountriesSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CountriesActions.getCountriesFailed,
        payload: handleError(error),
      });
    }
  };
};

interface SetSettingsModalActionType {
  type: UtilActions.setSettingsModalState;
  payload: boolean;
}

export const setSettingsModalStateActionCreator = (
  state: boolean
): SetSettingsModalActionType => {
  return { type: UtilActions.setSettingsModalState, payload: state };
};

interface SetPrevPageActionType {
  type: UtilActions.setPrevPageState;
  payload: string;
}

export const setPrevPageActionCreator = (
  state: string
): SetPrevPageActionType => {
  return { type: UtilActions.setPrevPageState, payload: state };
};

export type UtilsActionType =
  | SetNavbarStateActionType
  | SetScreenWidthActionType
  | GetCountriesActionType
  | SetSettingsModalActionType
  | SetPrevPageActionType;
