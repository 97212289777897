import * as React from "react";

const Square = ({
  title,
  titleId,
  ...props
}) => (
<svg width="1.05em" height="1.05em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<rect x="0.25" y="0.25" width="15.5" height="15.5" stroke="currentColor" strokeWidth="0.5"/>
</svg>
);

export default Square;