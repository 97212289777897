import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ItemStore } from "../../utils/types";
import { EmptyPieChartWidget, MagnifyingGlass, SimpleX } from "../FiraIcons";
import Input, { InputStyles } from "../Input/Input";
import styles from "./SearchSelectStore.module.scss";

interface SelectProps {
  storeId?: string;
  data: ItemStore[];
  options: ItemStore[];
  onchange(items: ItemStore[], isGlobal: boolean): void;
}

const SearchSelectStore: React.FC<SelectProps> = ({
  data,
  options,
  onchange,
  storeId,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<ItemStore[]>(
    data ? data : []
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const node = useRef<HTMLDivElement>(null);
  const MAX_NAME_LENGTH = 20;
  const { t } = useTranslation();
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: MouseEvent) => {
    if (node.current && !node.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  const toggleOption = (option: ItemStore) => {
    if (option.id === storeId) {
      return;
    }

    const selectedIndex = selectedOptions.findIndex((o) => o.id === option.id);
    if (selectedIndex === -1) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      const updatedOptions = [...selectedOptions];
      updatedOptions.splice(selectedIndex, 1);
      setSelectedOptions(updatedOptions);
    }
  };

  const isChecked = (option: ItemStore) => {
    if (option.id === storeId) {
      return true;
    }
    return selectedOptions.some((o) => o.id === option.id);
  };

  useEffect(() => {}, [selectedOptions]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setIsOpen(true);
  };
  const handleInputFocus = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    let isGlobal: boolean;
    if (
      selectedOptions.length > 0 &&
      selectedOptions.length === options.length &&
      selectedOptions.every(isChecked)
    ) {
      isGlobal = true;
    } else {
      isGlobal = false;
    }
    onchange(selectedOptions, isGlobal);
  }, [selectedOptions]);

  return (
    <div ref={node} style={{ position: "relative" }}>
      <div className={styles.InputWrapper}>
        <Input
          height={"40"}
          width={"100%"}
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          placeholder={t("views.products.detailProduct.search")}
          icon={<MagnifyingGlass width={20} height={20} />}
          inputStyle={InputStyles.searchConfig}
        />
      </div>

      {isOpen && (
        <ul
          style={{
            position: "absolute",
            zIndex: 1,
            backgroundColor: "#F7F8F9",
            padding: "0.5rem",
            maxHeight: "200px",
            overflowY: "auto",
            width: "100%",
          }}
        >
          <li className={styles.itemSelectList}>
            <label style={{ display: "flex" }}>
              <div
                className={`${styles.CheckboxWrapper} ${
                  storeId && selectedOptions.length === options.length - 1
                    ? styles.IsActive
                    : selectedOptions.length === options.length
                    ? styles.IsActive
                    : ""
                } `}
              >
                <input
                  className={styles.Checkbox}
                  type="checkbox"
                  checked={
                    selectedOptions.length === options.length &&
                    !selectedOptions.some((o) => o.id === storeId)
                  }
                  onChange={() => {
                    if (
                      (storeId &&
                        selectedOptions.length === options.length - 1) ||
                      (selectedOptions.length === options.length &&
                        !selectedOptions.some((o) => o.id === storeId))
                    ) {
                      setSelectedOptions([]);
                    } else {
                      const newSelectedOptions = options.filter(
                        (option) => option.id !== storeId
                      );
                      setSelectedOptions(newSelectedOptions);
                    }
                  }}
                />
                <div className={styles.CheckboxButton}></div>
              </div>
              Seleccionar todo
            </label>
          </li>
          {options
            .filter((option) =>
              option.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((option) => (
              <li
                className={styles.itemSelectList}
                key={option.id}
                style={option.id === storeId ? { color: "#C2C2C2" } : {}}
              >
                <label style={{ display: "flex" }}>
                  <div
                    className={`${styles.CheckboxWrapper} ${
                      selectedOptions.some((o) => o.id === option.id)
                        ? styles.IsActive
                        : ""
                    } `}
                  >
                    <input
                      className={styles.Checkbox}
                      type="checkbox"
                      checked={selectedOptions.some((o) => o.id === option.id)}
                      onChange={() => toggleOption(option)}
                    />
                    <div className={styles.CheckboxButton}></div>
                  </div>
                  {option.name.length > MAX_NAME_LENGTH
                    ? `${option.name.substring(0, MAX_NAME_LENGTH)}...`
                    : option.name}
                </label>
              </li>
            ))}
        </ul>
      )}
      <div style={{ maxHeight: "250px", overflowY: "auto" }}>
        {selectedOptions.length === 0 ? (
          <EmptyPieChartWidget width={230} height={240} />
        ) : (
          <ul style={{ height: "250px" }}>
            {selectedOptions.map((option, index) => (
              <li key={option.id} className={styles.itemList}>
                <label>
                  <b>{index + 1}. </b>{" "}
                  {option.name.length > MAX_NAME_LENGTH
                    ? `${option.name.substring(0, MAX_NAME_LENGTH)}...`
                    : option.name}{" "}
                </label>
                <div
                  onClick={() => toggleOption(option)}
                  style={{ cursor: "pointer", marginRight: "20px" }}
                >
                  <SimpleX />
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchSelectStore;
