import { apiBusinessPrivate, apiManagementPrivate } from "./api";
import {
  CriteriaType,
  FiraVideo,
  UpdateCategoryBody,
  UpdatePricesBody,
} from "../utils/types";
import { DashboardVodStatsResponse } from "../types/Response";

export const getOwnerVideos = (
  offSet: number,
  limit: number,
  sortType: string,
  regexName?: string
): Promise<FiraVideo[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiBusinessPrivate.post<FiraVideo[]>(
        `/v1/fira-tv/video/get-entire-gallery/${offSet}/${limit}/${sortType}`,
        { regexName }
      );
      resolve(response.data);
    } catch (error) {
      console.error;
      reject([]);
    }
  });
};

export const getVideosService = (
  storeId: string,
  offSet: number,
  limit: number,
  sortType: string
) => {
  return apiBusinessPrivate.get<FiraVideo[]>(
    `/v1/fira-tv/video/get-gallery/${storeId}/${offSet}/${limit}/${sortType}`
  );
};

export const getVideosByService = (
  storeId: string,
  offSet: number,
  limit: number,
  regexName: string
) => {
  return apiBusinessPrivate.get<FiraVideo[]>(
    `/v1/fira-tv/video/find-gallery-by-name/${storeId}/${regexName}/${offSet}/${limit}`
  );
};

export const updateVideoVisibility = (videoStoreId: string) => {
  return apiBusinessPrivate.put(
    `/v1/fira-tv/video/update-video-store-visibility/${videoStoreId}`
  );
};

export const getAvailableVideosService = (
  storeId: string,
  offSet: number,
  limit: number,
  sortType: string
) => {
  return apiBusinessPrivate.get<FiraVideo[]>(
    `/v1/fira-tv/video/get-available-gallery/${storeId}/${offSet}/${limit}/${sortType}`
  );
};

export const updateVideoProductState = (videoProductId: string) => {
  return apiBusinessPrivate.put(
    `/v1/fira-tv/product/vod-product/state-change/${videoProductId}`
  );
};

export const updateAllVideoPricesVisibility = (
  videoId: string,
  price: string
) => {
  return apiBusinessPrivate.put(
    `/v1/fira-tv/video/product/allowed-global-price/change/${videoId}/${price}`
  );
};

export const updateVideoProductInventory = (videoId: string, count: string) => {
  let body = {
    stock: count,
  };
  return apiBusinessPrivate.put(
    `/v1/fira-tv/product/vod-product/stock/${videoId}`,
    body
  );
};

export const updateVideoProductSku = (videoId: string, sku: string) => {
  let body = {
    sku: sku,
  };
  return apiBusinessPrivate.put(
    `/v1/fira-tv/product/vod-product/sku/${videoId}`,
    body
  );
};
export const updateVideoProductPrices = (body: UpdatePricesBody) => {
  return apiBusinessPrivate.put(
    "/v1/fira-tv/product/vod-product/change-price",
    body
  );
};

// edit services of the video
export const updateVideoPrimaryData = (
  videoId: string,
  videoName: string,
  videoDescription: string
) => {
  let body = {
    videoName: videoName,
    videoDescription: videoDescription,
  };

  return apiBusinessPrivate.put(
    `/v1/fira-tv/video/edit/primary-data/${videoId}`,
    body
  );
};

export const updateVideoTeaser = (videoId: string, teaser: File) => {
  const body = new FormData();
  if (teaser) {
    if (teaser) body.append("file", teaser, teaser?.name);
  } else body.append("file", new Blob(undefined, { type: "video/mp4" }), "");

  const headers = {
    "Content-Type": `multipart/form-data`,
  };
  return apiBusinessPrivate.put(
    `/v1/fira-tv/video/edit/update-teaser/${videoId}`,
    body,
    { headers }
  );
};

export const updateVideoPreviewImage = (videoId: string, preview: File) => {
  const body = new FormData();
  if (preview) {
    if (preview) body.append("file", preview, preview?.name);
  } else body.append("file", new Blob(undefined, { type: "image/png" }), "");

  const headers = {
    "Content-Type": `multipart/form-data`,
  };
  return apiBusinessPrivate.put(
    `/v1/fira-tv/video/edit/update-preview-image/${videoId}`,
    body,
    { headers }
  );
};

export const deleteVideoCategory = (body: UpdateCategoryBody) => {
  return apiBusinessPrivate.put(`/v1/fira-tv/video/edit/delete-category`, body);
};

export const addVideoCategory = (body: UpdateCategoryBody) => {
  return apiBusinessPrivate.put(`/v1/fira-tv/video/edit/add-category`, body);
};

export const updateVideo = async (
  videoId: string,
  file: File,
  onUploadProgress: (progressEvent: ProgressEvent) => void
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    return await apiBusinessPrivate.post(
      `/v1/fira-tv/video/replace-video/${videoId}`,
      formData,
      {
        onUploadProgress: (progressEvent) => {
          onUploadProgress(progressEvent);
        },
      }
    );
  } catch (error: any) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
};

export const getVideoCriteria = (videoId: string) => {
  return apiBusinessPrivate.get<CriteriaType[]>(
    `/v1/fira-tv/video/list-criteria-vod/${videoId}`
  );
};

export const getVideoStats = (videoId: string, criteria: string) => {
  return apiBusinessPrivate.get(
    `v1/video-actions/vod/stats-by-criteria/${criteria}/${videoId}`
  );
};

export const getCounterTestVideosService = (id: string) => {
  return apiManagementPrivate.get<number>(
    `/api/v1/fira-broadcast-event-controller/get-counter-test/${id}`
  );
};

export const getAccountOwnerDashboardVODStatisticsService = (
  id: string,
  initialDate: string,
  finalDate: string
) => {
  return apiBusinessPrivate.get(
    `/v1/fira-business/owner-dashboard/vod-statistics-by-date/${id}/${initialDate}/${finalDate}`
  );
};

export const replaceVideoService = (
  videoId: string,
  files: File[],
  onUploadProgress?: (progressEvent: ProgressEvent) => void
) => {
  try {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });

    return apiBusinessPrivate.post(
      `/v1/fira-tv/video/replace-files-video/${videoId}`,
      formData
      // {
      //   onUploadProgress: (progressEvent) => {
      //     onUploadProgress(progressEvent);
      //   },
      // }
    );
  } catch (error: any) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
};

export const getDashboardStatisticByDateService = (
  storeId: string,
  initialDate: string,
  finalDate: string
) => {
  return apiBusinessPrivate.get<DashboardVodStatsResponse>(
    `/v1/fira-tv/dashboard/fira-vod-statistics-by-date/${storeId}/${initialDate}/${finalDate}`
  );
};

export const downloadVODStatistics = (videoId: string) => {
  return apiBusinessPrivate.get(
    `/v1/fira-tv/video/download-stats-vod/${videoId}`,
    {
      headers: {
        "Content-Disposition": "attachment",
        "Content-Type": "application/vnd.ms-excel",
      },
      responseType: "arraybuffer",
    }
  );
};
