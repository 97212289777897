import { t } from "i18next";
import { useEffect, useState } from "react";
import WidgetConfigurationLayout from "../../layout/WidgetConfigurationLayout/WidgetConfigurationLayout";
import {
  EventSelectType,
  FiraCollection,
  FiraConfigurationOptions,
  FiraEvent,
  FiraVideo,
  WidgetType,
} from "../../utils/types.d";
import SelectByName, {
  NameSelectType,
} from "../WidgetConfigurationComponents/SelectByName/SelectByName";
import ColorInput from "../WidgetConfigurationComponents/ColorInput/ColorInput";
import WidgetSelect from "../WidgetConfigurationComponents/WidgetSelect/WidgetSelect";
import styles from "./WidgetConfigurationCard.module.scss";
import ToggleInput, {
  ToggleInputType,
} from "../WidgetConfigurationComponents/ToggleInput/ToggleInput";
import WidgetImageInput from "../WidgetConfigurationComponents/WidgetImageInput/WidgetImageInput";
import WidgetRadioButton, {
  RadioOptions,
} from "../WidgetConfigurationComponents/WidgetRadioButton/WidgetRadioButton";
import URLInput from "../WidgetConfigurationComponents/URLInput/URLInput";
import TitleInput from "../WidgetConfigurationComponents/TitleInput/TitleInput";
import SelectByEventName from "../WidgetConfigurationComponents/SelectByEventName/SelectByEventName";
import SelectByCollectionName from "../WidgetConfigurationComponents/SelectByCollectionName/SelectByCollectionName";

const widgetConfigInitialState: FiraConfigurationOptions = {
  firstCollectionId: "",
  secondCollectionId: "",
  thirdCollectionId: "",
  fourthCollectionId: "",
  fifthCollectionId: "",
  sixthCollectionId: "",
  seventhCollectionId: "",
  eightCollectionId: "",
  primaryTextColor: "#000000",
  secondaryTextColor: "#067DC7",
  buttonTextColor: "#ffffff",
  primaryColor: "#067DC7",
  cardColor: "#ffffff",
  displayColor: "#ffffff",
  backgroundColor: "",
  title: "",
  tertiaryTextColor: "#000000",
  secondaryColor: "#067DC7",
  tertiaryColor: "#067DC7",
  quaternaryColor: "#067DC7",
  quinaryColor: "#067DC7",
  videoFilter: true,
  videoSort: false,
  type: EventSelectType.nextEvents,
  firstImage: "",
  secondImage: "",
  thirdImage: "",
  eventId: "",
  collectionArray: [],
};

interface WidgetConfigurationCardProps {
  configHandler?(config: FiraConfigurationOptions): void;
  widgetType?: WidgetType;
}

const WidgetConfigurationCard = ({
  configHandler,
  widgetType,
}: WidgetConfigurationCardProps) => {
  const [widgetConfiguration, setWidgetConfiguration] =
    useState<FiraConfigurationOptions>(widgetConfigInitialState);

  const handleChange = (value: string, input: string) => {
    switch (input) {
      case "primaryTextColor":
        setWidgetConfiguration({
          ...widgetConfiguration,
          primaryTextColor: value,
        });
        break;
      case "secondaryTextColor":
        setWidgetConfiguration({
          ...widgetConfiguration,
          secondaryTextColor: value,
        });
        break;
      case "tertiaryTextColor":
        setWidgetConfiguration({
          ...widgetConfiguration,
          tertiaryTextColor: value,
        });
        break;
      case "buttonTextColor":
        setWidgetConfiguration({
          ...widgetConfiguration,
          buttonTextColor: value,
        });
        break;
      case "primaryColor":
        setWidgetConfiguration({
          ...widgetConfiguration,
          primaryColor: value,
        });
        break;
      case "secondaryColor":
        setWidgetConfiguration({
          ...widgetConfiguration,
          secondaryColor: value,
        });
        break;
      case "tertiaryColor":
        setWidgetConfiguration({
          ...widgetConfiguration,
          tertiaryColor: value,
        });
        break;
      case "quaternaryColor":
        setWidgetConfiguration({
          ...widgetConfiguration,
          quaternaryColor: value,
        });
        break;
      case "quinaryColor":
        setWidgetConfiguration({
          ...widgetConfiguration,
          quinaryColor: value,
        });
        break;
      case "cardColor":
        setWidgetConfiguration({
          ...widgetConfiguration,
          cardColor: value,
        });
        break;
      case "backgroundColor":
        if (value === undefined) {
          setWidgetConfiguration({
            ...widgetConfiguration,
            backgroundColor: "",
          });
        } else {
          setWidgetConfiguration({
            ...widgetConfiguration,
            backgroundColor: value,
          });
        }

        break;
      case "displayColor":
        setWidgetConfiguration({
          ...widgetConfiguration,
          displayColor: value,
        });
        break;
    }
  };

  const handleCollectionSelectByName = (value: FiraCollection) => {
    if (value) {
      setWidgetConfiguration({
        ...widgetConfiguration,
        title: value.collectionName,
      });
      setWidgetConfiguration({
        ...widgetConfiguration,
        collectionId: value.id,
      });
    } else {
      setWidgetConfiguration({
        ...widgetConfiguration,
        collectionId: "",
      });
    }
  };

  const handleCollectionSelectByOrder = (
    value: FiraCollection,
    order: Order
  ) => {
    if (value) {
      switch (order) {
        case Order.first:
          {
            setWidgetConfiguration({
              ...widgetConfiguration,
              firstCollectionId: value.id,
            });
          }
          break;
        case Order.second:
          {
            setWidgetConfiguration({
              ...widgetConfiguration,
              secondCollectionId: value.id,
            });
          }
          break;
        case Order.third:
          {
            setWidgetConfiguration({
              ...widgetConfiguration,
              thirdCollectionId: value.id,
            });
          }
          break;
      }
    }
  };

  const handleEventSelectByOrder = (value: FiraVideo, order: Order) => {
    if (value) {
      switch (order) {
        case Order.first:
          {
            setWidgetConfiguration({
              ...widgetConfiguration,
              firstVideoId: value.id,
            });
          }
          break;
        case Order.second:
          {
            setWidgetConfiguration({
              ...widgetConfiguration,
              secondVideoId: value.id,
            });
          }
          break;
        case Order.third:
          {
            setWidgetConfiguration({
              ...widgetConfiguration,
              thirdVideoId: value.id,
            });
          }
          break;
      }
    }
  };

  const handleEventSelectionByName = (value: FiraEvent) => {
    if (value)
      setWidgetConfiguration({ ...widgetConfiguration, eventId: value.id });
  };

  const handleType = (type: EventSelectType) => {
    switch (type) {
      case EventSelectType.prevEvents:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            title: "Eventos anteriores",
            type: type,
            collectionId: undefined,
          });
        }
        break;
      case EventSelectType.nextEvents:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            title: "Próximos eventos",
            type: type,
            collectionId: undefined,
          });
        }
        break;
      case EventSelectType.collections:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            title: "Colección",
            type: type,
          });
        }
        break;
    }
  };

  const handleToggle = (type: ToggleInputType, value: boolean) => {
    switch (type) {
      case ToggleInputType.videoFilter:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            videoFilter: value,
          });
        }
        break;
      case ToggleInputType.videoSort:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            videoSort: value,
          });
        }
        break;
    }
  };

  const handleImage = (image: string, type: Order) => {
    switch (type) {
      case Order.first:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            firstImage: image,
          });
        }
        break;
      case Order.second:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            secondImage: image,
          });
        }
        break;
      case Order.third:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            thirdImage: image,
          });
        }
        break;
    }
  };

  const handleUrl = (url: string, type: Order) => {
    switch (type) {
      case Order.first:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            firstUrl: url,
          });
        }
        break;
      case Order.second:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            secondUrl: url,
          });
        }
        break;
      case Order.third:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            thirdUrl: url,
          });
        }
        break;
    }
  };

  const handleSelectType = (value: string, type: NameSelectType) => {
    switch (type) {
      case NameSelectType.event:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            eventId: value,
          });
        }
        break;
      case NameSelectType.collection:
        {
          setWidgetConfiguration({
            ...widgetConfiguration,
            collectionId: value,
          });
        }
        break;
    }
  };

  const handleTitle = (value: string) => {
    setWidgetConfiguration({
      ...widgetConfiguration,
      title: value,
    });
  };

  const handleDefaultColors = (colors: FiraConfigurationOptions) => {
    setWidgetConfiguration(colors);
  };

  const handleMultipleCollections = (collections: FiraCollection[]) => {
    if (collections !== undefined) {
      setWidgetConfiguration({
        ...widgetConfiguration,
        collectionArray: collections,
      });
    }
    if (collections[0]) {
      setWidgetConfiguration({
        ...widgetConfiguration,
        firstCollectionId: collections[0].id,
      });
    }
    if (collections[1]) {
      setWidgetConfiguration({
        ...widgetConfiguration,
        secondCollectionId: collections[1].id,
      });
    }
    if (collections[2]) {
      setWidgetConfiguration({
        ...widgetConfiguration,
        thirdCollectionId: collections[2].id,
      });
    }
    if (collections[3]) {
      setWidgetConfiguration({
        ...widgetConfiguration,
        fourthCollectionId: collections[3].id,
      });
    }
    if (collections[4]) {
      setWidgetConfiguration({
        ...widgetConfiguration,
        fifthCollectionId: collections[4].id,
      });
    }
    if (collections[5]) {
      setWidgetConfiguration({
        ...widgetConfiguration,
        sixthCollectionId: collections[5].id,
      });
    }
    if (collections[6]) {
      setWidgetConfiguration({
        ...widgetConfiguration,
        seventhCollectionId: collections[6].id,
      });
    }
    if (collections[7]) {
      setWidgetConfiguration({
        ...widgetConfiguration,
        eightCollectionId: collections[7].id,
      });
    }
  };

  /** RENDERING */

  const configTypeHandler = () => {
    switch (widgetType) {
      case WidgetType.gridEvents: {
        return (
          <GridEventsConfig
            onChange={handleChange}
            onTypeSelect={handleType}
            onNameSelect={handleCollectionSelectByName}
          />
        );
      }
      case WidgetType.nextEventsCarousel: {
        return (
          <NextEventsConfig
            onChange={handleChange}
            onTypeSelect={handleType}
            onNameSelect={handleCollectionSelectByName}
          />
        );
      }
      case WidgetType.mainCarousel: {
        return <MainCarouselConfig onChange={handleChange} />;
      }
      case WidgetType.vodGallery: {
        return (
          <VODGalleryConfig
            onChange={handleChange}
            onToggleChange={handleToggle}
            onSelect={handleCollectionSelectByName}
            onDefaultColors={handleDefaultColors}
            widgetConfig={widgetConfiguration}
          />
        );
      }
      case WidgetType.bannerSlider: {
        return (
          <BannerSliderConfig
            onBannerImage={handleImage}
            onChange={handleChange}
            onUrlChange={handleUrl}
            onSelectChange={handleCollectionSelectByOrder}
            onDefaultColors={handleDefaultColors}
            onEventSelectChange={handleEventSelectByOrder}
          />
        );
      }
      case WidgetType.bannerCommercial: {
        return (
          <BannerCommercialConfig
            onSelect={handleSelectType}
            onBannerImage={handleImage}
          />
        );
      }
      case WidgetType.bannerTop: {
        return <BannerTopConfig />;
      }
      case WidgetType.categories: {
        return (
          <CategoriesConfig
            onTitleChange={handleTitle}
            onChange={handleChange}
            onCollections={handleMultipleCollections}
            onUrlChange={handleUrl}
          />
        );
      }
    }
  };

  useEffect(() => {
    if (configHandler) configHandler(widgetConfiguration);
  }, [widgetConfiguration]);

  return (
    <>
      <div className={styles.WidgetConfigurationCard}>
        <div className={styles.WidgetConfigurationCard__Title}>
          {t("components.widgetConfigCard.title")}
        </div>
        <div>{configTypeHandler()}</div>
      </div>
    </>
  );
};

//** CARDS

//COLLECTION CARDS
interface GridEventsConfigProps {
  onChange(input: string, value: string): void;
  onTypeSelect(type: EventSelectType): void;
  onNameSelect(value: FiraCollection): void;
}
const GridEventsConfig = ({
  onChange,
  onTypeSelect,
  onNameSelect,
}: GridEventsConfigProps) => {
  const [selected, setSelected] = useState<EventSelectType>();
  const handleEventTypeSelect = (type: EventSelectType) => {
    setSelected(type);
    onTypeSelect(type);
  };

  const handleColor = (color: string, type: string) => {
    onChange(color, type);
  };

  useEffect(() => {
    handleEventTypeSelect(EventSelectType.nextEvents);
  }, []);

  return (
    <WidgetConfigurationLayout>
      <WidgetSelect onChange={handleEventTypeSelect} />
      {selected === EventSelectType.collections && (
        <SelectByName
          title={t("components.selectByNameWidget.collectionTitle")}
          type={NameSelectType.collection}
          onSelect={onNameSelect}
        />
      )}
      <div>
        <hr />
      </div>
      <div className={styles.sectionTitle}>
        {t("components.widgetConfigCard.sectionTitle")}
      </div>
      <ColorInput
        title={t(
          "views.firaTv.widgets.cards.COLLECTION_CARDS.primaryTextColor"
        )}
        type="primaryTextColor"
        onChange={handleColor}
      />
      <ColorInput
        title={t(
          "views.firaTv.widgets.cards.COLLECTION_CARDS.secondaryTextColor"
        )}
        type="secondaryTextColor"
        onChange={handleColor}
      />
      <ColorInput
        title={t("views.firaTv.widgets.cards.COLLECTION_CARDS.buttonTextColor")}
        type="buttonTextColor"
        onChange={handleColor}
      />
      <ColorInput
        title={t("views.firaTv.widgets.cards.COLLECTION_CARDS.primaryColor")}
        type="primaryColor"
        onChange={handleColor}
      />
      <ColorInput
        title={t("views.firaTv.widgets.cards.COLLECTION_CARDS.cardColor")}
        type="cardColor"
        onChange={handleColor}
      />
      <ColorInput
        title={t("views.firaTv.widgets.cards.COLLECTION_CARDS.backgroundColor")}
        type="backgroundColor"
        onChange={handleColor}
        noColor
      />
    </WidgetConfigurationLayout>
  );
};

//** CAROUSEL */
// COLLECTION CAROUSEL
interface NextEventConfigProps {
  onChange(input: string, value: string): void;
  onTypeSelect(type: EventSelectType): void;
  onNameSelect(value: FiraCollection): void;
}

const NextEventsConfig = ({
  onChange,
  onTypeSelect,
  onNameSelect,
}: NextEventConfigProps) => {
  const [selected, setSelected] = useState<EventSelectType>();
  const handleEventTypeSelect = (type: EventSelectType) => {
    setSelected(type);
    onTypeSelect(type);
  };
  const handleColor = (color: string, type: string) => {
    onChange(color, type);
  };

  useEffect(() => {
    handleEventTypeSelect(EventSelectType.prevEvents);
  }, []);

  return (
    <>
      <WidgetConfigurationLayout>
        <WidgetSelect onChange={handleEventTypeSelect} noNextEvents />
        {selected === EventSelectType.collections && (
          <SelectByName
            title={t("components.selectByNameWidget.collectionTitle")}
            type={NameSelectType.collection}
            onSelect={onNameSelect}
          />
        )}
        <div>
          <hr />
        </div>
        <div className={styles.sectionTitle}>
          {t("components.widgetConfigCard.sectionTitle")}
        </div>
        <ColorInput
          title={t(
            "views.firaTv.widgets.carousel.COLLECTION_CAROUSEL.primaryTextColor"
          )}
          type="primaryTextColor"
          onChange={handleColor}
        />
        <ColorInput
          title={t(
            "views.firaTv.widgets.carousel.COLLECTION_CAROUSEL.displayColor"
          )}
          type="displayColor"
          onChange={handleColor}
        />
        <ColorInput
          title={t(
            "views.firaTv.widgets.carousel.COLLECTION_CAROUSEL.primaryColor"
          )}
          type="primaryColor"
          onChange={handleColor}
        />
        <ColorInput
          title={t(
            "views.firaTv.widgets.carousel.COLLECTION_CAROUSEL.backgroundColor"
          )}
          type="backgroundColor"
          onChange={handleColor}
          noColor
        />
      </WidgetConfigurationLayout>
    </>
  );
};

//** SCHEDULED */
//COMING SOON

interface MainCarouselConfigProps {
  onChange(input: string, value: string): void;
}

const MainCarouselConfig = ({ onChange }: MainCarouselConfigProps) => {
  const handleColor = (color: string, type: string) => {
    onChange(color, type);
  };

  return (
    <>
      <WidgetConfigurationLayout>
        <div>
          <hr />
        </div>
        <div className={styles.sectionTitle}>
          {t("components.widgetConfigCard.sectionTitle")}
        </div>
        <ColorInput
          title={t(
            "views.firaTv.widgets.scheduled.COMING_SOON.primaryTextColor"
          )}
          type="primaryTextColor"
          onChange={handleColor}
        />
        <ColorInput
          title={t("views.firaTv.widgets.scheduled.COMING_SOON.displayColor")}
          type="displayColor"
          onChange={handleColor}
        />
        <ColorInput
          title={t(
            "views.firaTv.widgets.scheduled.COMING_SOON.buttonTextColor"
          )}
          type="buttonTextColor"
          onChange={handleColor}
        />
        <ColorInput
          title={t("views.firaTv.widgets.scheduled.COMING_SOON.primaryColor")}
          type="primaryColor"
          onChange={handleColor}
        />
        <ColorInput
          title={t(
            "views.firaTv.widgets.scheduled.COMING_SOON.backgroundColor"
          )}
          type="backgroundColor"
          onChange={handleColor}
          noColor
        />
      </WidgetConfigurationLayout>
    </>
  );
};

/** MOSAIC - GRID */
// VOD GALLERY
interface VODGalleryConfigProps {
  onChange(input: string, value: string): void;
  onToggleChange(toggleType: ToggleInputType, value: boolean): void;
  onSelect(collection: FiraCollection): void;
  widgetConfig?: FiraConfigurationOptions;
  onDefaultColors(colors: FiraConfigurationOptions): void;
}

const VODGalleryConfig = ({
  onChange,
  onToggleChange,
  onSelect,
  widgetConfig,
  onDefaultColors,
}: VODGalleryConfigProps) => {
  const [sortVideos, setSortVideos] = useState<boolean>(false);
  const [filterVideos, setFilterVideos] = useState<boolean>(true);
  const [defaultColors, setDefaultColors] = useState<FiraConfigurationOptions>(
    widgetConfig!
  );

  const handleColor = (color: string, type: string) => {
    onChange(color, type);
  };

  const handleCheck = (value: ToggleInputType) => {
    switch (value) {
      case ToggleInputType.videoFilter:
        {
          setFilterVideos(!filterVideos);
        }
        break;
      case ToggleInputType.videoSort:
        {
          setSortVideos(!sortVideos);
        }
        break;
    }
  };

  const handleDefaultColors = () => {
    setDefaultColors({
      ...defaultColors,
      primaryTextColor: "#000000",
      secondaryTextColor: "#49A5DF",
      tertiaryTextColor: "#000000",
      primaryColor: "#000000",
      secondaryColor: "#ABAFB1",
      tertiaryColor: "#FFFFFF",
      quaternaryColor: "#000000",
      quinaryColor: "#868586",
    });
  };

  useEffect(() => {
    onToggleChange(ToggleInputType.videoSort, sortVideos);
  }, [sortVideos]);

  useEffect(() => {
    onToggleChange(ToggleInputType.videoFilter, filterVideos);
  }, [filterVideos]);

  useEffect(() => {
    handleDefaultColors();
  }, []);

  useEffect(() => {
    onDefaultColors(defaultColors);
  }, [defaultColors]);

  return (
    <>
      <WidgetConfigurationLayout>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <ToggleInput
            label={"Ordenar galeria de videos"}
            checked={sortVideos}
            onChange={handleCheck}
            value={ToggleInputType.videoSort}
          />
          <ToggleInput
            label={"Filtros galeria de videos"}
            checked={filterVideos}
            onChange={handleCheck}
            value={ToggleInputType.videoFilter}
          />
        </div>
        <SelectByName
          title={`${t("components.selectByNameWidget.collectionTitle")}`}
          optional
          type={NameSelectType.collection}
          onSelect={onSelect}
        />
        <div>
          <hr />
        </div>
        <div className={styles.sectionTitle}>
          {t("components.widgetConfigCard.sectionTitle")}
        </div>
        <ColorInput
          title={t("views.firaTv.widgets.grid.VOD_GALLERY.primaryTextColor")}
          type="primaryTextColor"
          onChange={handleColor}
          color={"#000000"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.grid.VOD_GALLERY.secondaryTextColor")}
          type="secondaryTextColor"
          onChange={handleColor}
          color={"#49A5DF"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.grid.VOD_GALLERY.tertiaryTextColor")}
          type="tertiaryTextColor"
          onChange={handleColor}
          color={"#000000"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.grid.VOD_GALLERY.primaryColor")}
          type="primaryColor"
          onChange={handleColor}
          color={"#000000"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.grid.VOD_GALLERY.secondaryColor")}
          type="secondaryColor"
          onChange={handleColor}
          color={"#ABAFB1"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.grid.VOD_GALLERY.tertiaryColor")}
          type="tertiaryColor"
          onChange={handleColor}
          color={"#FFFFFF"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.grid.VOD_GALLERY.quaternaryColor")}
          type="quaternaryColor"
          onChange={handleColor}
          color={"#000000"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.grid.VOD_GALLERY.quinaryColor")}
          type="quinaryColor"
          onChange={handleColor}
          color={"#868586"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.grid.VOD_GALLERY.backgroundColor")}
          type="backgroundColor"
          onChange={handleColor}
          noColor
        />
      </WidgetConfigurationLayout>
    </>
  );
};

/** BANNERS */
// BANNER SLIDER

export enum Order {
  first = "FIRST",
  second = "SECOND",
  third = "THIRD",
}

interface BannerSliderConfigProps {
  onChange(input: string, type: string): void;
  onBannerImage(image: string, order: Order): void;
  onUrlChange(url: string, order: Order): void;
  onSelectChange(collection: FiraCollection, order: Order): void;
  onEventSelectChange(video: FiraVideo, order: Order): void;
  widgetConfig?: FiraConfigurationOptions;
  onDefaultColors(colors: FiraConfigurationOptions): void;
}

const BannerSliderConfig = ({
  onChange,
  onBannerImage,
  onUrlChange,
  onSelectChange,
  widgetConfig,
  onDefaultColors,
  onEventSelectChange,
}: BannerSliderConfigProps) => {
  const [bannerOneType, setBannerOneType] = useState<RadioOptions | null>(null);
  const [bannerTwoType, setBannerTwoType] = useState<RadioOptions | null>(null);
  const [bannerThreeType, setBannerThreeType] = useState<RadioOptions | null>(
    null
  );
  const [bannerOnehasImage, setBannerOneHasImage] = useState<boolean>(false);
  const [bannerTwohasImage, setBannerTwoHasImage] = useState<boolean>(false);
  const [bannerThreehasImage, setBannerThreeHasImage] =
    useState<boolean>(false);
  const [defaultColors, setDefaultColors] = useState<FiraConfigurationOptions>(
    widgetConfig!
  );

  const handleColor = (color: string, type: string) => {
    onChange(color, type);
  };

  const handleBannerOneImage = (image: string) => {
    onBannerImage(image, Order.first);
    if (image === "") {
      handleFirstUrl("");
      onBannerImage("", Order.first);
    }
  };

  const handleBannerTwoImage = (image: string) => {
    onBannerImage(image, Order.second);
    if (image === "") {
      handleSecondUrl("");
      onBannerImage("", Order.second);
    }
  };

  const handleBannerThreeImage = (image: string) => {
    onBannerImage(image, Order.third);
    if (image === "") {
      handleThirdUrl("");
      onBannerImage("", Order.third);
    }
  };

  const handleFirstUrl = (url: string) => {
    onUrlChange(url, Order.first);
  };

  const handleSecondUrl = (url: string) => {
    onUrlChange(url, Order.second);
  };

  const handleThirdUrl = (url: string) => {
    onUrlChange(url, Order.third);
  };

  const handleFirstCollection = (collection: FiraCollection) => {
    onSelectChange(collection, Order.first);
  };

  const handleSecondCollection = (collection: FiraCollection) => {
    onSelectChange(collection, Order.second);
  };

  const handleThirdCollection = (collection: FiraCollection) => {
    onSelectChange(collection, Order.third);
  };

  const handleFirstVideo = (video: FiraVideo) => {
    onEventSelectChange(video, Order.first);
  };

  const handleSecondVideo = (video: FiraVideo) => {
    onEventSelectChange(video, Order.second);
  };

  const handleThirdVideo = (video: FiraVideo) => {
    onEventSelectChange(video, Order.third);
  };

  const handleDefaultColors = () => {
    setDefaultColors({
      ...defaultColors,
      primaryTextColor: "#FFFFFF",
      secondaryTextColor: "#FFFFFF",
      tertiaryTextColor: "#FFFFFF",
      primaryColor: "#000000",
      secondaryColor: "#067DC7",
      tertiaryColor: "#FFFFFF",
      quaternaryColor: "#E4E4E4",
    });
  };

  useEffect(() => {
    handleDefaultColors();
  }, []);

  useEffect(() => {
    onDefaultColors(defaultColors);
  }, [defaultColors]);

  return (
    <>
      <WidgetConfigurationLayout>
        <WidgetImageInput
          title="Banner #1"
          hasImage={setBannerOneHasImage}
          onImage={handleBannerOneImage}
        />
        <WidgetRadioButton
          title={t("components.widgetRadioButton.videoTitle")}
          onChange={setBannerOneType}
        />
        {bannerOneType !== null &&
          (bannerOneType === RadioOptions.collections ? (
            <SelectByCollectionName onSelect={handleFirstCollection} />
          ) : (
            <SelectByEventName
              onSelect={handleFirstVideo}
              defaultName={t("components.selectByEventNameWidget.defaultv1")}
            />
          ))}
        <div>
          <hr />
        </div>
        {bannerOnehasImage && (
          <>
            <URLInput onChange={handleFirstUrl} />
            <div>
              <hr />
            </div>
          </>
        )}

        <WidgetImageInput
          title="Banner #2"
          hasImage={setBannerTwoHasImage}
          onImage={handleBannerTwoImage}
        />
        <WidgetRadioButton
          title={t("components.widgetRadioButton.videoTitle")}
          onChange={setBannerTwoType}
        />
        {bannerTwoType !== null &&
          (bannerTwoType === RadioOptions.collections ? (
            <SelectByCollectionName onSelect={handleSecondCollection} />
          ) : (
            <SelectByEventName
              onSelect={handleSecondVideo}
              defaultName={t("components.selectByEventNameWidget.defaultv1")}
            />
          ))}
        <div>
          <hr />
        </div>
        {bannerTwohasImage && (
          <>
            <URLInput onChange={handleSecondUrl} />
            <div>
              <hr />
            </div>
          </>
        )}
        <WidgetImageInput
          title="Banner #3"
          hasImage={setBannerThreeHasImage}
          onImage={handleBannerThreeImage}
        />
        <WidgetRadioButton
          title={t("components.widgetRadioButton.videoTitle")}
          onChange={setBannerThreeType}
        />
        {bannerThreeType !== null &&
          (bannerThreeType === RadioOptions.collections ? (
            <SelectByCollectionName onSelect={handleThirdCollection} />
          ) : (
            <SelectByEventName
              onSelect={handleThirdVideo}
              defaultName={t("components.selectByEventNameWidget.defaultv1")}
            />
          ))}
        <div>
          <hr />
        </div>
        {bannerTwohasImage && (
          <>
            <URLInput onChange={handleThirdUrl} />
            <div>
              <hr />
            </div>
          </>
        )}
        <div className={styles.sectionTitle}>
          {t("components.widgetConfigCard.sectionTitle")}
        </div>
        {/* <TitleInput name={"Título de la sección"} /> */}
        <ColorInput
          title={t("views.firaTv.widgets.banners.SLIDER.primaryTextColor")}
          onChange={handleColor}
          type={"primaryTextColor"}
          color={"#FFFFFF"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.banners.SLIDER.secondaryTextColor")}
          onChange={handleColor}
          type={"secondaryTextColor"}
          color={"#FFFFFF"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.banners.SLIDER.tertiaryTextColor")}
          onChange={handleColor}
          type={"tertiaryTextColor"}
          color={"#FFFFFF"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.banners.SLIDER.primaryColor")}
          onChange={handleColor}
          type={"primaryColor"}
          color={"#000000"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.banners.SLIDER.secondaryColor")}
          onChange={handleColor}
          type={"secondaryColor"}
          color={"#067DC7"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.banners.SLIDER.tertiaryColor")}
          onChange={handleColor}
          type={"tertiaryColor"}
          color={"#FFFFFF"}
        />
        <ColorInput
          title={t("views.firaTv.widgets.banners.SLIDER.quaternaryColor")}
          onChange={handleColor}
          type={"quaternaryColor"}
          color={"#E4E4E4"}
        />
        {/* <ColorInput title="“Ver más”" onChange={() => ""} type={""} />
        <ColorInput title="Circulo imagen" onChange={() => ""} type={""} />
        <ColorInput title="Fondo" onChange={() => ""} type={""} noColor /> */}
      </WidgetConfigurationLayout>
    </>
  );
};

//BANNER COMMERCIAL
interface BannerCommercialConfigProps {
  onBannerImage(image: string, type: Order): void;
  onSelect(value: string, type: NameSelectType): void;
}

const BannerCommercialConfig = ({
  onBannerImage,
  onSelect,
}: BannerCommercialConfigProps) => {
  const [titleToShow, setTitleToShow] = useState<string>(
    t("views.firaTv.widgets.banners.COMMERCIAL.inputTitle")
  );
  const [type, setType] = useState<EventSelectType | null>(null);

  const handleTitle = (value: EventSelectType) => {
    if (value === EventSelectType.collections) {
      setTitleToShow(
        t("components.selectByCollectionNameWidget.collectionTitle")
      );
    } else if (value === EventSelectType.prevEvents) {
      setTitleToShow(t("views.firaTv.widgets.banners.COMMERCIAL.inputTitle"));
    }
  };

  const handleChange = (value: EventSelectType) => {
    setType(value);
  };

  const handleImageChange = (preview: string) => {
    onBannerImage(preview, Order.first);
  };

  const handleSelectionEvent = (value: FiraVideo) => {
    if (value == null) {
      onSelect("", NameSelectType.event);
      return;
    }
    onSelect(value.originEvent, NameSelectType.event);
  };

  const handleSelectionCollection = (value: FiraCollection) => {
    if (value == null) {
      onSelect("", NameSelectType.collection);
      return;
    }
    onSelect(value.id, NameSelectType.collection);
  };

  useEffect(() => {
    if (type != null) handleTitle(type);
  }, [type]);

  return (
    <WidgetConfigurationLayout
      style={{ minHeight: "550px", paddingBottom: "300px" }}
    >
      <div>
        <div className={styles.sectionTitle}>Banner</div>
        <WidgetImageInput onImage={handleImageChange} />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <div className={styles.sectionTitle}>{titleToShow}</div>
        <WidgetSelect showLabel={false} noNextEvents onChange={handleChange} />
        {type !== null &&
          (type === EventSelectType.prevEvents ? (
            <SelectByEventName
              defaultName={t("components.selectByEventNameWidget.defaultv1")}
              onSelect={handleSelectionEvent}
            />
          ) : (
            <SelectByCollectionName onSelect={handleSelectionCollection} />
          ))}
      </div>
    </WidgetConfigurationLayout>
  );
};

//TOP BANNER
interface BannerTopProps {}

const BannerTopConfig = () => {
  const [selected, setSelected] = useState<EventSelectType | null>(null);
  return (
    <WidgetConfigurationLayout>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <WidgetSelect onChange={setSelected} />
        {selected !== null && (
          <div>
            <div className={styles.sectionTitle}>EVENTO ASOCIADO AL WIDGET</div>
            <SelectByName type={NameSelectType.event} />
          </div>
        )}
      </div>

      <div>
        <hr />
      </div>
      <div className={styles.sectionTitle}>
        {t("components.widgetConfigCard.sectionTitle")}
      </div>
      <TitleInput name={"Nombre del evento"} />
      <ColorInput
        title={"Color"}
        subtitle={"(nombre del evento)"}
        type={""}
        onChange={() => ""}
        color={"#FFFFFF"}
      />
      <ColorInput
        title={"Fondo"}
        type={""}
        onChange={() => ""}
        color={"#000000"}
      />
    </WidgetConfigurationLayout>
  );
};

/** COLLECTIONS */
//CATEGORIES
interface CategoriesConfigProps {
  onTitleChange?(value: string): void;
  onChange(color: string, type: string): void;
  onCollections?(val: FiraCollection[]): void;
  onUrlChange?(val: string, order: Order): void;
}

const CategoriesConfig = ({
  onTitleChange,
  onChange,
  onCollections,
  onUrlChange,
}: CategoriesConfigProps) => {
  const [url, setUrl] = useState<string>("");
  const [selectedCollections, setSelectedCollections] =
    useState<FiraCollection[]>();

  const getTitle = () => {
    const length: string = selectedCollections?.length.toString()!;
    return `${t("components.selectByCollectionNameWidget.multiple")} (${
      length || "0"
    }/8)`;
  };

  const handleMultipleSelect = (collections: FiraCollection[]) => {
    setSelectedCollections(collections);
    if (onCollections) onCollections(collections);
  };

  const handleHeader = (value: string) => {
    if (onTitleChange) onTitleChange(value);
  };

  const handleColor = (color: string, type: string) => {
    onChange(color, type);
  };

  const handleUrl = (url: string) => {
    setUrl(url);
    if (onUrlChange) onUrlChange(url, Order.first);
  };

  return (
    <WidgetConfigurationLayout>
      <SelectByName
        type={NameSelectType.collection}
        title={getTitle()}
        multipleSelection
        onMultipleSelect={handleMultipleSelect}
      />
      <div>
        <hr />
      </div>

      <>
        <URLInput onChange={handleUrl} />
        <div>
          <hr />
        </div>
      </>
      <div className={styles.sectionTitle}>
        {t("components.widgetConfigCard.sectionTitle")}
      </div>

      <TitleInput name={"Título de la sección"} onChange={handleHeader} />
      <ColorInput
        title="Nombre de la colección"
        type="primaryTextColor"
        onChange={handleColor}
      />
      <ColorInput
        title="“Ver más”"
        type="secondaryTextColor"
        onChange={handleColor}
      />
      <ColorInput
        title="Circulo imagen"
        type="primaryColor"
        onChange={handleColor}
      />
      <ColorInput
        title="Fondo"
        type="backgroundColor"
        onChange={handleColor}
        noColor
      />
    </WidgetConfigurationLayout>
  );
};

export default WidgetConfigurationCard;
