import "./RadioButton.scss";

interface Props {
  clickHandler?(): void;
  isActive?: boolean;
  isDisabled?: boolean;
}
/**
 * RadioButton description
 * @prop {function} clickHandler - optional function to trigger a click in the radio
 * @prop {boolean} isActive - set the radio to an active state
 * @prop {boolean} isDisabled - disabled the radio button so it cannot be used
 */
const RadioButton = (props: Props) => {
  const { clickHandler, isActive = false, isDisabled = false } = props;
  return (
    <div
      onClick={isDisabled ? undefined : clickHandler}
      className={`Radio ${isActive ? "IsActive" : ""} ${
        isDisabled ? "IsDisabled" : ""
      }`}
    ></div>
  );
};

export default RadioButton;
