import * as React from "react";
import { SVGProps } from "react";

const DateItem = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 12.58a2.5 2.5 0 0 1 2.5-2.5h10a2.5 2.5 0 0 1 2.5 2.5V19a3.5 3.5 0 0 1-3.5 3.5h-8A3.5 3.5 0 0 1 7.5 19v-6.42Z"
      stroke="#000"
    />
    <rect
      x={9.064}
      y={14.226}
      width={3.613}
      height={3.613}
      rx={1}
      fill="#010202"
    />
    <rect x={11} y={8} width={1.5} height={4} rx={0.75} fill="#010202" />
    <path
      d="M19 8.75a.75.75 0 0 0-1.5 0v2.5a.75.75 0 0 0 1.5 0v-2.5Z"
      fill="#010202"
    />
    <path
      d="M7.533 11.5a1 1 0 0 1 1-1h12.968a1 1 0 0 1 1 1v1.58H7.533V11.5Z"
      fill="#010202"
    />
  </svg>
);

export default DateItem;
