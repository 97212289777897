import { useEffect, useState } from "react";
import styles from "./SecurityIndicator.module.scss";

export enum IndicatorBar {
  empty = "",
  low = "Low",
  medium = "Medium",
  max = "Max",
}

interface IndicatorProps {
  percentage?: number;
  indicator?: IndicatorBar;
  customClass?: { percentage: number; class: string };
}

const SecurityIndicator = ({
  percentage = 0,
  indicator = IndicatorBar.empty,
}: IndicatorProps) => {
  const [barClass, setBarClass] = useState<IndicatorBar>(IndicatorBar.empty);

  const style = {
    width: `${percentage}%`,
  };

  const classes = `${styles.IndicatorBar} ${
    styles[barClass as keyof typeof styles]
  }`;

  useEffect(() => {
    indicator ? setBarClass(indicator) : setBarClass(IndicatorBar.empty);
  }, [indicator]);

  return (
    <div className={styles.IndicatorWrapper}>
      <div className={`${classes}`}></div>
    </div>
  );
};

export default SecurityIndicator;
