import { SVGProps } from "react";

const OpenLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 7.234v2.468c0 .612-.22 1.135-.66 1.57-.44.434-.97.651-1.59.651h-6.5c-.62 0-1.15-.217-1.59-.652A2.126 2.126 0 0 1 0 9.701V3.286c0-.612.22-1.135.66-1.57.44-.434.97-.65 1.59-.65h5.5c.073 0 .133.022.18.068.047.047.07.106.07.178v.493a.239.239 0 0 1-.25.247h-5.5c-.344 0-.638.121-.883.363A1.18 1.18 0 0 0 1 3.285v6.417c0 .34.122.63.367.871.245.242.54.363.883.363h6.5c.344 0 .638-.12.883-.363A1.18 1.18 0 0 0 10 9.702V7.234a.238.238 0 0 1 .25-.247h.5a.238.238 0 0 1 .25.247ZM14 .57v3.95c0 .133-.05.249-.148.346a.484.484 0 0 1-.352.147.484.484 0 0 1-.352-.147L11.773 3.51 6.68 8.537a.249.249 0 0 1-.36 0l-.89-.879a.242.242 0 0 1-.078-.177c0-.067.026-.126.078-.178l5.093-5.028L9.148.918A.471.471 0 0 1 9 .57c0-.133.05-.249.148-.347A.484.484 0 0 1 9.5.077h4c.135 0 .253.049.352.146A.472.472 0 0 1 14 .57Z"
      fill="currentColor"
    />
  </svg>
);

export default OpenLink;
