import { t } from "i18next";
import { PermissionsByGroupType } from "../../../../utils/types.d";

export const OperatorDashboard = () => {
  return {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.dashboard.title"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescriptionv3"
    ),
    permissions: [
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.dashboard.statistics"
        ),
        group: PermissionsByGroupType.FIRA_WEB_DASHBOARD,
      },
    ],
  };
};

export const OperatorEvents = () => {
  return {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.events.title"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescriptionv3"
    ),
    permissions: [
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.events.event_mgmt"
        ),
        group: PermissionsByGroupType.FIRA_WEB_MANAGE_EVENTS,
      },
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.events.moderation"
        ),
        group: PermissionsByGroupType.FIRA_WEB_MODERATOR,
      },
      // {
      //   name: t(
      //     "components.settings.userRolesAndPermissions.permissionsDetail.events.statistics"
      //   ),
      //   group: PermissionsByGroupType.FIRA_WEB_STATISTICS_EVENTS,
      // },
    ],
  };
};

export const OperatorProducts = () => {
  return {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.products.title"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
    ),
    permissions: [
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.products.management"
        ),
        group: PermissionsByGroupType.FIRA_WEB_MANAGE_PRODUCTS,
      },
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.products.batch_load"
        ),
        group: PermissionsByGroupType.FIRA_WEB_BATCH_PRODUCTS,
      },
    ],
  };
};

export const OperatorFiraTV = () => {
  return {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.title"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
    ),
    permissions: [
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.VOD_mgmt"
        ),
        group: PermissionsByGroupType.FIRA_TV_MANAGE_VODS,
      },
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.widgets"
        ),
        group: PermissionsByGroupType.FIRA_TV_WIDGETS,
      },
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.statistics"
        ),
        group: PermissionsByGroupType.FIRA_TV_STATISTICS,
      },
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.moderation"
        ),
        group: PermissionsByGroupType.FIRA_TV_MODERATE_COMMENTS,
      },
      {
        name: t(
          "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.collections"
        ),
        group: PermissionsByGroupType.FIRA_TV_MANAGE_COLLECTIONS,
      },
    ],
  };
};

export const OperatorProSeller = () => {
  return {
    title: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.proseller.proseller"
    ),
    description: t(
      "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
    ),
    permissions: [
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.proseller.products"
      ),
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.proseller.events"
      ),
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.proseller.moderation"
      ),
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.proseller.statistics"
      ),
      t(
        "components.settings.userRolesAndPermissions.permissionsDetail.proseller.vod"
      ),
    ],
  };
};
