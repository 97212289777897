import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function RoundedX({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 10 10"
      aria-labelledby={titleId}
      {...props}
    >
      <path
        fill="currentColor"
        d="M1.707.293A1 1 0 00.293 1.707L3.586 5 .293 8.293a1 1 0 001.414 1.414L5 6.414l3.293 3.293a1 1 0 001.414-1.414L6.414 5l3.293-3.293A1 1 0 008.293.293L5 3.586 1.707.293z"
      ></path>
    </svg>
  );
}

export default RoundedX;
