import { RequestError } from "../../services/errorHandling";
import { RegisterResponse } from "../../utils/types";
import { AuthActions } from "../actionsContants";
import { RegisterActionType } from "./RegisterActions";

export interface RegisterState {
  isLoading: boolean;
  isRegistering: boolean;
  isActivating: boolean;
  processFinish: boolean;
  registerResult?: RegisterResponse;
  registerError?: RequestError;
  activationError?: RequestError;
  email?: string;
}
const initialState: RegisterState = {
  isLoading: false,
  isRegistering: false,
  isActivating: false,
  processFinish: false,
};
export const registerReducer = (
  state: RegisterState = initialState,
  action: RegisterActionType
): RegisterState => {
  switch (action.type) {
    case AuthActions.registerLoading: {
      return { ...state, isLoading: true };
    }
    case AuthActions.registerSuccess: {
      const { email, data } = action.payload;
      return {
        ...state,
        isLoading: false,
        isRegistering: true,
        registerError: undefined,
        registerResult: data as RegisterResponse,
        email: email as string,
      };
    }
    case AuthActions.registerFailed: {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        isRegistering: false,
        registerError: data as RequestError,
        registerResult: undefined,
      };
    }
    case AuthActions.activationLoading: {
      return { ...state, isLoading: true };
    }
    case AuthActions.activationSuccess: {
      return {
        isLoading: false,
        isRegistering: false,
        isActivating: false,
        processFinish: true,
      };
    }
    case AuthActions.activationError: {
      return {
        ...state,
        isLoading: false,
        isActivating: false,
        activationError: action.payload as RequestError,
      };
    }
    case AuthActions.resetRegister: {
      return initialState;
    }
    case AuthActions.resetAuthAlert: {
      return { ...state, processFinish: false };
    }
    case AuthActions.logout: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
