import styles from "./MassificationForm.module.scss";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "../../../ToggleSwitch/ToggleSwitch";
import { useContext, useEffect, useState } from "react";
import { StoreProfileContext } from "../StoreProfile";

const MassificationForm = () => {
  const { t } = useTranslation();
  const { handleChange, form } = useContext(StoreProfileContext);
  const [value, setValue] = useState<boolean>(false);

  const handleSwitch = () => {
    let data;
    data = { ...form, isAutoExpose: !value };
    handleChange(data);
  };

  useEffect(() => {
    setValue(form.isAutoExpose);
  }, [form]);

  return (
    <div className={styles.MassificationForm}>
      <div className={styles.SwitchContainer}>
        <label>{t("components.store_settings.massification.label")}</label>
        <ToggleSwitch
          hideTags={true}
          checked={value}
          handleChecked={handleSwitch}
        />
      </div>
    </div>
  );
};

export default MassificationForm;
