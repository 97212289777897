export enum ProductStatus {
  active = "ACTIVE",
  inactive = "INACTIVE",
  draft = "DRAFT",
  other = "OTHER",
}

export enum EcommerceType {
  FIRA = "FIRA",
  NUVEMSHOP = "NUVEMSHOP",
}

export enum FiraEventType {
  test = "TEST",
  broadcast = "BROADCAST",
}

export enum EventType {
  test = "test",
  broadcast = "broadcast",
}

export enum FiraUserRole {
  ACCOUNT_OWNER = "ACCOUNT_OWNER",
  MANAGER = "MANAGER",
  OPERATOR = "OPERATOR",
  MODERATOR = "MODERATOR",
  PROSELLER = "PROSELLER",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum FiraPackage {
  FIRA_WEB = "FIRA_WEB",
  FIRA_TV = "FIRA_TV",
  PROSELLER = "PROSELLER",
}

export enum ownerStoreState {
  onhold = "ONHOLD",
  active = "ACTIVE",
  suspended = "SUSPENDED",
  canceled = "CANCELLED",
  done = "DONE",
  deactivated = "DEACTIVATED",
  deleted = "DELETED",
  approved = "APPROVED",
  pending = "PENDING",
  rejected = "REJECTED",
  inprocess = "INPROCESS",
  valid = "VALID",
  invalid = "INVALID",
}

export enum EventStatus {
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
  DONE = "DONE",
}

export enum StreamProvider {
  RED5 = "RED5",
  AGORA = "AGORA",
}

export enum PermissionCardType {
  dashboard = "DASHBOARD",
  products = "PORDUCTS",
  events = "EVENTS",
  firaTv = "FIRA_TV",
}

export enum InviteStatus {
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
  ACCEPTED = "ACCEPTED",
  CLOSED = "CLOSED",
}
