import * as React from "react";

const Play = ({
  title,
  titleId,
  ...props
}) => (
  <svg 
      width="1em"
    height="1em"
   viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.4002 5.98944C12.4002 9.1009 9.85293 11.6289 6.70371 11.6289C3.5545 11.6289 1.00723 9.1009 1.00723 5.98944C1.00723 2.87799 3.5545 0.35 6.70371 0.35C9.85293 0.35 12.4002 2.87799 12.4002 5.98944Z" stroke="white" strokeWidth="0.7"/>
<path d="M8.68728 5.81487C8.87207 5.93403 8.87009 6.20493 8.68359 6.32143L5.69783 8.1865C5.49718 8.31183 5.2372 8.16648 5.23893 7.92992L5.26646 4.15723C5.26818 3.92067 5.53025 3.77905 5.72906 3.90724L8.68728 5.81487Z" fill="white"/>
</svg>
);

export default Play;