export const handleSearch = (arr: any, value: string) => {
  let newArr = arr;
  if (value !== "") {
    newArr = arr.filter((i: any) => {
      return i.name.toLowerCase().includes(value.toLowerCase());
    });
    return newArr;
  } else {
    return arr;
  }
};
