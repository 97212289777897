import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
    currentColor?: string;
    height?: string;
}

const SvgWebScreenChatMockup = ({
                                       title,
                                       titleId,
                                       currentColor,
                                       height,
                                       ...props
                                   }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="305"
        height={height}
        fill="none"
        viewBox="0 0 305 200"
    >
        <path
            stroke="#D5DDE0"
            d="M1.314 10a9.5 9.5 0 019.5-9.5h284a9.5 9.5 0 019.5 9.5v180a9.5 9.5 0 01-9.5 9.5h-284a9.5 9.5 0 01-9.5-9.5V10z"
        ></path>
        <rect
            width="208.76"
            height="131.824"
            x="12.715"
            y="21.875"
            stroke="#D5DDE0"
            rx="5.5"
        ></rect>
        <rect
            width="208.76"
            height="24.191"
            x="0.5"
            y="-0.5"
            stroke="#D5DDE0"
            rx="5.5"
            transform="matrix(1 0 0 -1 12.215 179.154)"
        ></rect>
        <rect
            width="37.26"
            height="18.347"
            x="0.75"
            y="-0.75"
            fill="#F7F8F9"
            stroke={currentColor}
            strokeWidth="1.5"
            rx="5.25"
            transform="matrix(1 0 0 -1 16.016 176.363)"
        ></rect>
        <rect
            width="18.26"
            height="21.401"
            x="0.75"
            y="-0.75"
            fill="#F7F8F9"
            stroke={currentColor}
            strokeWidth="1.5"
            rx="3.25"
            transform="matrix(1 0 0 -1 18.293 48.88)"
        ></rect>
        <rect
            width="38.76"
            height="19.847"
            fill="#F7F8F9"
            rx="6"
            transform="matrix(1 0 0 -1 57.055 177.863)"
        ></rect>
        <rect
            width="40.28"
            height="19.847"
            fill="#F7F8F9"
            rx="6"
            transform="matrix(1 0 0 -1 97.336 177.863)"
        ></rect>
        <rect
            width="38.76"
            height="19.847"
            fill="#F7F8F9"
            rx="6"
            transform="matrix(1 0 0 -1 139.895 177.863)"
        ></rect>
        <rect
            width="40.28"
            height="19.847"
            fill="#F7F8F9"
            rx="6"
            transform="matrix(1 0 0 -1 179.414 177.863)"
        ></rect>
        <rect
            width="47.64"
            height="8.16"
            x="0.5"
            y="-0.5"
            stroke="#F7F8F9"
            rx="3.5"
            transform="matrix(1 0 0 -1 229.574 174.574)"
        ></rect>
        <rect
            width="8.36"
            height="9.16"
            fill="#F7F8F9"
            rx="4"
            transform="matrix(1 0 0 -1 281.256 175.574)"
        ></rect>
        <rect
            width="60.04"
            height="9.16"
            fill="#F7F8F9"
            rx="4"
            transform="matrix(1 0 0 -1 229.574 64.121)"
        ></rect>
        <rect
            width="60.04"
            height="8.397"
            fill="#F7F8F9"
            rx="4"
            transform="matrix(1 0 0 -1 229.574 142.746)"
        ></rect>
        <rect
            width="60.04"
            height="8.397"
            fill="#F7F8F9"
            rx="4"
            transform="matrix(1 0 0 -1 229.574 103.816)"
        ></rect>
        <rect
            width="60.04"
            height="8.397"
            fill="#F7F8F9"
            rx="4"
            transform="matrix(1 0 0 -1 229.574 123.664)"
        ></rect>
        <rect
            width="60.04"
            height="8.397"
            fill="#F7F8F9"
            rx="4"
            transform="matrix(1 0 0 -1 229.574 83.97)"
        ></rect>
        <rect
            width="60.04"
            height="9.16"
            fill="#F7F8F9"
            rx="4"
            transform="matrix(1 0 0 -1 229.574 45.04)"
        ></rect>
        <rect
            width="59.477"
            height="8.831"
            fill="#F7F8F9"
            rx="4"
            transform="matrix(1 0 0 -1 230.162 163.115)"
        ></rect>
        <rect
            width="23.791"
            height="4.675"
            fill={currentColor}
            rx="2.338"
            transform="matrix(1 0 0 -1 230.162 153.248)"
        ></rect>
        <rect
            width="11.896"
            height="4.675"
            fill={currentColor}
            rx="2.338"
            transform="matrix(1 0 0 -1 277.744 153.248)"
        ></rect>
        <rect
            width="11.4"
            height="4.58"
            fill={currentColor}
            rx="2.29"
            transform="matrix(1 0 0 -1 278.215 54.197)"
        ></rect>
        <rect
            width="24.32"
            height="4.58"
            fill={currentColor}
            rx="2.29"
            transform="matrix(1 0 0 -1 229.574 54.197)"
        ></rect>
        <rect
            width="24.32"
            height="4.58"
            fill="#F7F8F9"
            rx="2.29"
            transform="matrix(1 0 0 -1 229.574 133.588)"
        ></rect>
        <rect
            width="24.32"
            height="4.58"
            fill="#F7F8F9"
            rx="2.29"
            transform="matrix(1 0 0 -1 229.574 94.658)"
        ></rect>
        <rect
            width="24.32"
            height="4.58"
            fill="#F7F8F9"
            rx="2.29"
            transform="matrix(1 0 0 -1 229.574 113.74)"
        ></rect>
        <rect
            width="24.32"
            height="4.58"
            fill="#F7F8F9"
            rx="2.29"
            transform="matrix(1 0 0 -1 229.574 74.043)"
        ></rect>
        <rect
            width="24.32"
            height="4.58"
            fill="#F7F8F9"
            rx="2.29"
            transform="matrix(1 0 0 -1 229.574 34.35)"
        ></rect>
        <rect
            width="68.92"
            height="159.305"
            x="224.754"
            y="20.346"
            stroke="#D5DDE0"
            rx="9.5"
        ></rect>
    </svg>
);

export default SvgWebScreenChatMockup;
