import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableImage,
  TableInput,
  TableRow,
} from "../Table/Table";
import styles from "./EditProductsTabes.module.scss";
import { Ellipse, EmptyProducts, Eye, EyeClose } from "../FiraIcons";
import { FiraVideoProduct, RootState, VideoProduct } from "../../utils/types";
import { useClickOutside } from "../../utils/useClickOutside";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAllPricesVisibilityCreator,
  updateVideoProductInfoActionCreator,
  updateVideoProductStateActionCreator,
} from "../../store/Video/VideoActions";
import { usePopper } from "react-popper";
import Input from "../Input/Input";
import ModalAlert from "../Modals/ModalAlert/ModalAlert";

let timerID: NodeJS.Timeout;

const EditProductsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { video, brand } = useSelector((state: RootState) => state);
  const [filterValue, setFilterValue] = useState("");
  const [productsList, setProductsList] = useState<FiraVideoProduct[]>([]);
  const [scrollActive, setScrollActive] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [editedValue, setEditedValue] = useState({
    name: "",
    value: "",
    id: "",
  });
  const [pricesToChange, setPricesToChange] = useState({
    vodProductId: "",
    pricesToChange: {
      price1: "",
      price2: "",
      price3: "",
    },
  });

  const handleError = (message: string) => {
    setShowError(true);
    setErrorMessage(message);
    timerID = setTimeout(() => {
      setShowError(false);
      setErrorMessage("");
    }, 5000);
  };

  const handleFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
    if (keyword !== "") {
      setProductsList(
        productsList.filter((p) => {
          return String(p.product.name)
            .replace("null", "")
            .toLowerCase()
            .includes(keyword.toLowerCase());
        })
      );
    } else {
      video.currentVideo && setProductsList(video.currentVideo?.products || []);
    }
  };

  const handleEditing = (
    inputValue: string,
    inputName: string | number,
    rowId: string
  ) => {
    let PriceValue = inputValue.replace(",", ".");
    if (inputValue.length === 0) {
      PriceValue = "0";
    }
    if (inputName === "price1" || inputName === "price2") {
      let product = productsList.find((p) => {
        return p.id === rowId;
      });

      if (product) {
        setPricesToChange({
          vodProductId: rowId,
          pricesToChange: {
            price1:
              inputName === "price1" ? PriceValue : product.product.price1,
            price2:
              inputName === "price2" ? PriceValue : product.product.price2,
            price3: product.product.price3,
          },
        });
      }
    }
    inputValue.length !== 0 &&
      setEditedValue({ name: String(inputName), value: PriceValue, id: rowId });
  };

  const convertText = (text: string) => {
    let newText = text.toLowerCase();
    let firstLetter = newText[0].toUpperCase();
    let restOfText = newText.slice(1);
    return firstLetter + restOfText;
  };

  const checkPricesVisibility = () => {
    let visibility = productsList.find((p) => {
      return p.product.price1;
    });

    return visibility === undefined;
  };

  const usersHeader = [
    { name: "", size: "12px" },
    { name: "", size: "70px" },
    { name: t("components.editProductsTable.table.name"), size: "" },
    { name: t("components.editProductsTable.table.sku"), size: "" },
    { name: t("components.editProductsTable.table.quantity"), size: "" },
    { name: t("components.editProductsTable.table.price"), size: "" },
    { name: t("components.editProductsTable.table.discount"), size: "" },
    // brand.currentStore?.ecommerce && {
    //   name: t("components.editProductsTable.table.entry"),
    //   size: "",
    // },
    {
      name: (
        <div className={styles.headerOption}>
          <OptionsButton
            header
            videoId={video.currentVideo && video.currentVideo.id}
            allPricesHiden={checkPricesVisibility()}
          />
        </div>
      ),
      size: "50px",
    },
    { name: "", size: "30px" },
  ];

  const header = (
    <TableRow>
      {usersHeader.map(
        (head, i) =>
          head && (
            <TableHead key={i} size={head.size} centerContent={i !== 2}>
              {head.name}
            </TableHead>
          )
      )}
    </TableRow>
  );

  const body = productsList.map((product, index) => {
    const inactiveProduct = product.status === "DEACTIVATE";
    return (
      <TableRow key={product.id} inactive={inactiveProduct}>
        <TableCell size="12px"></TableCell>
        <TableCell>
          <TableImage
            opacity={inactiveProduct}
            src={product.product.images[0]}
          />
        </TableCell>
        <TableCell size="">
          <p
            className={`${inactiveProduct ? styles.inactiveText : ""} ${
              styles.ellipsis
            }`}
          >
            {product.product.name}
          </p>
        </TableCell>
        <TableCell centerContent>
          {inactiveProduct ? (
            <p className={inactiveProduct ? styles.inactiveText : ""}>
              {product.product.sku}
            </p>
          ) : (
            <TableInput
              inputValue={String(product.product.sku)}
              inputName={"sku"}
              rowId={product.id}
              handleChange={handleEditing}
              returnError={() =>
                handleError(t("components.editProductsTable.errors.emptyInput"))
              }
              notEmpty
            />
          )}
        </TableCell>
        <TableCell centerContent>
          <div className={styles.centerInput}>
            {inactiveProduct ? (
              <p className={styles.inactiveText}>{product.product.quantity}</p>
            ) : (
              <TableInput
                inputValue={String(product.product.quantity)}
                inputName={"quantity"}
                rowId={product.id}
                handleChange={handleEditing}
                returnError={() =>
                  handleError(
                    t("components.editProductsTable.errors.emptyInput")
                  )
                }
                onlyNumbers
              />
            )}
          </div>
        </TableCell>
        <TableCell centerContent>
          <div className={styles.centerInput}>
            {inactiveProduct ? (
              String(product.product.price1) === "undefined" ? (
                <Input fontSize="12px" width="88px" isDisabled />
              ) : (
                <p className={styles.inactiveText}>{product.product.price1}</p>
              )
            ) : (
              <>
                {String(product.product.price1) === "undefined" ? (
                  <Input fontSize="12px" width="88px" isDisabled />
                ) : (
                  <TableInput
                    inputValue={String(product.product.price1)}
                    inputName={"price1"}
                    rowId={product.id}
                    handleChange={handleEditing}
                    returnError={() =>
                      handleError(
                        t("components.editProductsTable.errors.emptyInput")
                      )
                    }
                    onlyPrices
                    noCeros
                  />
                )}
              </>
            )}
          </div>
        </TableCell>
        <TableCell centerContent>
          <div className={styles.centerInput}>
            {inactiveProduct ? (
              String(product.product.price1) === "undefined" ? (
                <Input fontSize="12px" width="88px" isDisabled />
              ) : (
                <p className={styles.inactiveText}>{product.product.price2}</p>
              )
            ) : (
              <>
                {String(product.product.price2) === "undefined" ? (
                  <Input fontSize="12px" width="88px" isDisabled />
                ) : (
                  <TableInput
                    inputValue={String(product.product.price2)}
                    inputName={"price2"}
                    rowId={product.id}
                    handleChange={handleEditing}
                    returnError={() =>
                      handleError(
                        t("components.editProductsTable.errors.emptyInput")
                      )
                    }
                    onlyPrices
                  />
                )}
              </>
            )}
          </div>
        </TableCell>
        {/* {brand.currentStore?.ecommerce && (
          <TableCell centerContent>
            <div className={styles.originStore}>
              <div
                className={`
              ${styles.inside}
               ${product.product.origin === "FIRA" ? styles.fira : styles.other}
              `}
              >
                <p>{convertText(product.product.origin)}</p>
              </div>
            </div>
          </TableCell>
        )} */}
        <TableCell centerContent size="50px">
          <OptionsButton
            inactiveProduct={inactiveProduct}
            productId={product.id}
            scrollActive={scrollActive}
            allPricesHiden={checkPricesVisibility()}
          />
        </TableCell>
        <TableCell size="30px"></TableCell>
      </TableRow>
    );
  });

  useEffect(() => {
    if (video.currentVideo) {
      setProductsList(video.currentVideo.products || []);
    }
  }, [video.currentVideo?.products]);

  useEffect(() => {
    if (editedValue.name.length !== 0) {
      if (editedValue.name === "price1" || editedValue.name === "price2") {
        dispatch(
          updateVideoProductInfoActionCreator(
            editedValue.id,
            editedValue.name,
            pricesToChange
          )
        );
      } else {
        dispatch(
          updateVideoProductInfoActionCreator(
            editedValue.id,
            editedValue.name,
            editedValue.value
          )
        );
      }
    }
  }, [editedValue]);

  useEffect(() => {
    if (video.error) {
      handleError(video.error.message);
    }
  }, [video.error]);

  useEffect(() => {}, [productsList]);
  return (
    <div className={styles.EditProductsWrapper}>
      <TableHeader
        hideCheckBox
        searchInputValue={filterValue}
        searchInputChangeHandle={handleFiltering}
      />

      {productsList.length > 0 ? (
        <Table header={header} body={body} detectScroll={setScrollActive} />
      ) : (
        <EmptyProductView />
      )}
      {showError && (
        <ModalAlert
          isOpen={showError}
          title={errorMessage}
          onClose={() => setShowError(false)}
        />
      )}
    </div>
  );
};

const EmptyProductView = () => {
  return (
    <div className={styles.EmptyWrapper}>
      <EmptyProducts />
    </div>
  );
};

interface OptionsButtonProps {
  header?: boolean;
  inactiveProduct?: boolean;
  productId?: string;
  scrollActive?: number;
  videoId?: string;
  allPricesHiden?: boolean;
}

const OptionsButton = ({
  header,
  inactiveProduct,
  productId,
  scrollActive,
  videoId,
  allPricesHiden,
}: OptionsButtonProps) => {
  const [showPopup, setShowPopUp] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const handleOption = (e: SyntheticEvent<HTMLElement>) => {
    setShowPopUp(!showPopup);
  };
  useEffect(() => {
    setShowPopUp(false);
  }, [scrollActive]);
  return (
    <div className={styles.rowOption}>
      <div onClick={handleOption} ref={setReferenceElement}>
        <Ellipse />
      </div>
      {showPopup && (
        <CustomPopperElement
          referenceElement={referenceElement}
          handleClose={() => setShowPopUp(false)}
          header={header}
          inactiveProduct={inactiveProduct}
          productId={productId}
          videoId={videoId}
          allPricesHiden={allPricesHiden}
        />
      )}
    </div>
  );
};

interface CustomPopperElementProps {
  handleClose(): void;
  header?: boolean;
  inactiveProduct?: boolean;
  productId?: string;
  referenceElement: HTMLDivElement | null;
  videoId?: string;
  allPricesHiden?: boolean;
}
const CustomPopperElement = ({
  referenceElement,
  handleClose,
  header = false,
  inactiveProduct,
  productId,
  videoId,
  allPricesHiden,
}: CustomPopperElementProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const domNode = useClickOutside(handleClose);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto-start",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["bottom", "left"],
          padding: 8,
        },
      },
      {
        name: "offset",
        options: {
          offset: [10, 10],
        },
      },
    ],
  });
  const containerStyle: any = {
    ...styles.popper,
    zIndex: 99,
    borderRadius: "6px",
  };

  return (
    <div ref={setPopperElement} style={containerStyle} {...attributes.popper}>
      <div ref={domNode}>
        <PopUpOption
          handleClose={handleClose}
          header={header}
          inactiveProduct={inactiveProduct}
          productId={productId}
          videoId={videoId}
          allPricesHiden={allPricesHiden}
        />
      </div>
    </div>
  );
};

interface PopUpOptionProps {
  handleClose(): void;
  header?: boolean;
  inactiveProduct?: boolean;
  productId?: string;
  videoId?: string;
  allPricesHiden?: boolean;
}

const PopUpOption = ({
  handleClose,
  header = false,
  inactiveProduct,
  productId,
  videoId,
  allPricesHiden,
}: PopUpOptionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleInactiveProduct = () => {
    productId && dispatch(updateVideoProductStateActionCreator(productId));
    handleClose();
  };

  const handleAllHidePrices = () => {
    videoId && dispatch(updateAllPricesVisibilityCreator(videoId));
    handleClose();
  };

  return (
    <div className={styles.popUpOption}>
      {!header && (
        <div
          className={`${styles.option} `}
          onClick={() => handleInactiveProduct()}
        >
          {inactiveProduct ? (
            <>
              <Eye className={styles.icon} />
              <p className={styles.text}>
                {t("components.editProductsTable.popup.rows.showProduct")}
              </p>
            </>
          ) : (
            <>
              <EyeClose className={styles.icon} />
              <p className={styles.text}>
                {t("components.editProductsTable.popup.rows.hideProduct")}
              </p>
            </>
          )}
        </div>
      )}
      {header && (
        <div
          className={`${styles.option}`}
          onClick={() => handleAllHidePrices()}
        >
          {allPricesHiden ? (
            <>
              <Eye className={styles.icon} />
              <p className={styles.text}>
                {t("components.editProductsTable.popup.header.showPrices")}
              </p>
            </>
          ) : (
            <>
              <EyeClose className={styles.icon} />
              <p className={styles.text}>
                {t("components.editProductsTable.popup.header.hidePrices")}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default EditProductsTable;
