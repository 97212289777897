import { t } from "i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  PopUpAlert,
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";
import Button, { ButtonStyle } from "../../components/Button/Button";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { ArrowDownFill } from "../../components/FiraIcons";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import {
  PopUpAlertTypes,
  PopUpButtonsTypes,
} from "../../components/PopUpAlert/PopUpAlert";
import { orderingType } from "../../components/Table/Table";
import ModalLayout from "../../layout/ModalLayout/ModalLayout";
import { handleError } from "../../services/errorHandling";
import {
  changeUserStatusService,
  getAllStoreStatsService,
  getAllUsersFromStore,
  getBusinessStoreByIdService,
  getStoreBy,
  softDeleteOwnerStoreService,
} from "../../services/store";
import {
  RoleType,
  RootState,
  StoreUser,
  TeamMember,
} from "../../utils/types.d";
import CardWidget from "../../widgets/CardWidget/CardWidget";
import { StatusSelectType } from "../StoresView/StoresView";
import noImageError from "../../assets/svg/no-image-store.svg";
import styles from "./StoresDetailView.module.scss";
import { dateFormat, timeZoneDate } from "../../utils/handleDates";
import { timeTextFormatHMS } from "../../utils/timeTextFormatHMS";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";
import {
  getOwnerStoresActionCreator,
  setCurrentStoreActionCreator,
} from "../../store/Brand/BrandActions";
import {
  setPrevPageActionCreator,
  setSettingsModalStateActionCreator,
} from "../../store/Utils/UtilsActions";
import { FiraStore } from "../../types/Stores";

const StoresDetail = () => {
  const { utils, authentication, brand } = useSelector(
    (state: RootState) => state
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [storeInfo, setStoreInfo] = useState<FiraStore>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [userList, setUserList] = useState<StoreUser[]>();
  const showAlert = usePopupAlert();
  const dispatch = useDispatch();
  const [storeData, setStoreData] = useState<FiraStore>();

  const handleBackButton = () => {
    navigate("/home/stores");
  };

  const getStoreData = () => {
    if (id) {
      getBusinessStoreByIdService(id).then((val) => {
        setStoreData(val.data);
      });
    }
  };

  const handleChangeContext = (store: FiraStore) => {
    authentication.sessionId &&
      dispatch(setCurrentStoreActionCreator(authentication.sessionId, store));
  };

  const trailingOptions = (
    <>
      <Button
        clickHandler={
          storeData ? () => handleChangeContext(storeData) : () => ""
        }
      >
        <span style={{ fontWeight: "500" }}>
          {t("views.stores.details.accessBtn")}
        </span>
      </Button>
    </>
  );

  const cancelSoftDelete = () => {
    setModalIsOpen(false);
  };

  const handleIsOpen = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const softDelete = async () => {
    handleIsOpen();

    if (storeInfo?.id) {
      try {
        if (authentication.user && authentication.user.businessClientId) {
          await softDeleteOwnerStoreService(
            storeInfo.id,
            authentication.user?.username
          );
          showAlert(AlertType.success, t("views.stores.storeDeleteSuccess"));

          dispatch(
            getOwnerStoresActionCreator(authentication.user.businessClientId)
          );
        }

        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } catch (error) {
        const handledError = handleError(error);
        handleErrorAlert(handledError.message);
      }
    }
  };

  const popUpButtons: PopUpButtonsTypes[] = [
    {
      title: t("modals.storeDetails.cancel"),
      handleClick: cancelSoftDelete,
      type: "border",
    },
    {
      title: t("modals.storeDetails.accept"),
      handleClick: softDelete,
      type: "fill",
    },
  ];

  const getAllUsers = async () => {
    if (storeInfo) {
      try {
        const response = await getAllUsersFromStore(storeInfo.id);
        setUserList(parUserObj(response.data));
      } catch (error) {
        const handledError = handleError(error);
        showAlert(AlertType.error, handledError.message);
      }
    }
  };

  const parUserObj = (users: TeamMember[]) => {
    let tempList: StoreUser[] = [];

    users.map((user) => {
      tempList.push(user.user);
    });

    return tempList;
  };

  const getStore = async () => {
    if (id) {
      try {
        const response = await getStoreBy(id);
        setStoreInfo(response.data);
      } catch (error) {}
    }
  };

  const handleErrorAlert = (error: string) => {
    showAlert(AlertType.error, error);
  };

  const handleEdit = () => {
    if (authentication.sessionId && storeData) {
      dispatch(
        setCurrentStoreActionCreator(authentication.sessionId, storeData)
      );

      setTimeout(() => {
        dispatch(setSettingsModalStateActionCreator(true));
      }, 1500);
    }
  };

  useEffect(() => {
    getStore();
    dispatch(setPrevPageActionCreator(""));
  }, []);

  useEffect(() => {
    getAllUsers();
  }, [storeInfo]);

  useEffect(() => {
    if (utils.screenWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [utils.screenWidth]);

  useEffect(() => {
    if (id) {
      getStoreData();
    }
  }, [id]);

  useEffect(() => {
    if (!brand.isLoading && brand.currentStore) {
      navigate("/home/dashboard");
    }
  }, [brand]);

  return (
    <>
      {modalIsOpen && (
        <ModalLayout>
          <PopUpAlert
            type={PopUpAlertTypes.warning}
            width={"620px"}
            title={t("modals.storeDetails.title")}
            message={t("modals.storeDetails.desc")}
            buttons={popUpButtons}
          />
        </ModalLayout>
      )}
      <FiraNavBar
        backButtonHandler={handleBackButton}
        titleSection={storeInfo?.name || ""}
        trailingOptions={trailingOptions}
      />
      <div
        className={`${styles.StoresDetailsWrapper} ${
          isMobile ? styles.mobileWrapper : ""
        }`}
      >
        <div
          className={`${styles.StoresDetailsWrapper__CardsWrapper} ${
            isMobile ? styles.mobileCounter : ""
          }`}
        >
          <StatsCards store={storeInfo} />
        </div>
        <div className={styles.StoresDetailsWrapper__TableWrapper}>
          <TeamTable
            isMobile={isMobile}
            users={userList}
            storeId={storeInfo?.id}
            onError={handleErrorAlert}
            store={storeData}
          />
        </div>
        <div className={styles.StoresDetailsWrapper__InfoWrapper}>
          {storeData && (
            <StoreInfoComponent
              store={storeData}
              isMobile={isMobile}
              onDelete={handleIsOpen}
              onEdit={handleEdit}
            />
          )}
        </div>
      </div>
    </>
  );
};

const StatsCards = (props: any) => {
  const { store } = props;
  const [storeStats, setStoreStats] = useState<{
    livesCounting: number;
    connections: { webConnections: number; mobileConnection: number };
    livesTotalTime: number;
    engagement: number;
    bouncingRate: number;
    liveTestCount: number;
  }>();

  const getAllStoreStats = async () => {
    const init = store.workflowList[0].performedAt;
    const tempDate = new Date();
    tempDate.setUTCHours(23);
    tempDate.setUTCMinutes(59);
    tempDate.setUTCSeconds(59);
    const final = timeZoneDate(tempDate);
    if (store.id) {
      const response = await getAllStoreStatsService(store.id, init, final);
      const data = response.data;
      setStoreStats({
        livesCounting: data.livesCounting || 0,
        connections: data.connections || 0,
        livesTotalTime: data.livesTotalTime || 0,
        engagement: data.engagement || 0,
        bouncingRate: data.bouncingRate || 0,
        liveTestCount: data.liveTestCount || 0,
      });
    }
  };

  useEffect(() => {
    if (store !== undefined) getAllStoreStats();
  }, [store]);

  return (
    <>
      <CardWidget
        title={t("views.dashboard.transmissionsNumber")}
        description={t("views.dashboard.transmissionsNumberDesc")}
        data={
          storeStats ? Math.floor(storeStats?.livesCounting).toString() : "0"
        }
        isLoading={false}
      />
      <CardWidget
        title={t("views.dashboard.testingLives")}
        description={""}
        data={storeStats?.liveTestCount.toString() || "0"}
        isLoading={false}
      />
      <CardWidget
        title={t("views.dashboard.transmissionTime")}
        description={""}
        data={timeTextFormatHMS(storeStats?.livesTotalTime) || "0"}
        isLoading={false}
      />
      <CardWidget
        title={t("views.dashboard.totalAudience")}
        description={""}
        data={
          storeStats
            ? (
                storeStats?.connections.mobileConnection +
                storeStats?.connections.webConnections
              ).toString()
            : "0"
        }
        isLoading={false}
      />

      <CardWidget
        title={t("views.dashboard.engagement")}
        description={t("views.dashboard.engagementDesc")}
        data={`${storeStats?.engagement.toString() || "0"}%`}
        isLoading={false}
      />
      <CardWidget
        title={t("views.dashboard.rate")}
        description={t("views.dashboard.rateDesc")}
        data={`${storeStats?.bouncingRate.toString() || "0"}%`}
        isLoading={false}
      />
    </>
  );
};

interface StatusSelect {
  name: string;
  value: StatusSelectType;
  color: string;
}

enum OrderingType {
  status = "STATUS",
  role = "ROLE",
  descend = "DESCEND_ALPHABET",
  ascend = "ASCEND_ALPHABET",
}

interface TeamTableProps {
  storeId?: string;
  isMobile?: boolean;
  onError(error: string): void;
  users?: StoreUser[];
  store?: FiraStore;
}

const TeamTable = ({
  storeId = "",
  isMobile = false,
  onError,
  users = [],
  store,
}: TeamTableProps) => {
  const { authentication } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userList, setUserList] = useState<StoreUser[]>(users || []);
  const [filterValue, setFilterValue] = useState<string>("");

  const orderingList = [
    {
      id: 1,
      name: t("views.stores.table.status"),
      value: OrderingType.status,
    },
    {
      id: 2,
      name: t("views.stores.table.role"),
      value: OrderingType.role,
    },
    {
      id: 3,
      name: "A - Z",
      value: OrderingType.descend,
    },
    {
      id: 4,
      name: "Z - A",
      value: OrderingType.ascend,
    },
  ];
  const optionsStatus = [
    {
      name: t("components.statusSelect.active"),
      value: StatusSelectType.active,
      color: "#FFF29C",
    },
    {
      name: t("components.statusSelect.inactive"),
      value: StatusSelectType.inactive,
      color: "#F7F8F9",
    },
  ];

  const rolType = (role: RoleType) => {
    switch (role) {
      case RoleType.ACCOUNT_OWNER:
        return t("views.stores.details.table.roles.account_owner");
      case RoleType.MANAGER:
        return t("views.stores.details.table.roles.manager");
      case RoleType.MODERATOR:
        return t("views.stores.details.table.roles.moderator");
      case RoleType.OPERATOR:
        return t("views.stores.details.table.roles.operator");
      case RoleType.PROSELLER:
        return t("views.stores.details.table.roles.proseller");
      case RoleType.SELLER:
        return t("views.stores.details.table.roles.seller");
    }
  };

  const [selectedFilter, setSelectedFilter] = useState<orderingType>(
    orderingList[0]
  );

  const handleUserStatus = async (user: StoreUser) => {
    if (user && storeId) {
      try {
        await changeUserStatusService(user.id, storeId, user.role);
      } catch (error) {
        const handledError = handleError(error);
        onError(handledError.message);
      }
    }
  };

  const handleOrdering = (id: number) => {
    let selected = orderingList.filter((p) => {
      return p.id === id;
    });

    setSelectedFilter(selected[0]);
  };

  const handleFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
    if (keyword !== "") {
      setUserList(
        users.filter((u) => {
          return String(u.firstName)
            .replace("null", "")
            .toLowerCase()
            .includes(keyword.toLowerCase());
        })
      );
    } else {
      setUserList(users);
    }
  };
  const handleSeeUserDetails = (userId: string) => {
    navigate(`/home/team/${userId}`);
  };

  const handleNewUserButton = () => {
    authentication.sessionId &&
      store &&
      dispatch(setCurrentStoreActionCreator(authentication.sessionId, store));
    dispatch(setPrevPageActionCreator("storesDetailView"));
    navigate("/home/team/new");
  };

  useEffect(() => {
    if (users.length !== 0) {
      setUserList(users);
    }
  }, [users]);

  const headerColumns = [
    {
      name: "",
      size: "5px",
    },
    {
      name: "",
      size: "40px",
    },
    {
      name: t("views.stores.details.table.name"),
      size: "30%",
    },
    {
      name: t("views.stores.details.table.status"),
      size: "90px",
    },
    {
      name: t("views.stores.details.table.role"),
      size: "30%",
    },
    {
      name: "",
      size: "100%",
    },
    {
      name: "",
      size: "5px",
    },
  ];

  const header = (
    <TableRow>
      {headerColumns.map((item, i) => {
        return (
          <TableHead key={i} size={item.size} centerContent={i !== 2}>
            {item.name}
          </TableHead>
        );
      })}
    </TableRow>
  );

  const body = (
    <>
      {userList.map((user: StoreUser) => {
        return (
          <TableRow key={user.id}>
            <TableCell size={"5px"}></TableCell>
            <TableCell size={"80px"}>
              <div className={styles.AvatarContainer}>
                <div className={styles.UserAvatar}>
                  <img src={user.avatar || noImageError} alt={user.firstName} />
                </div>
              </div>
            </TableCell>
            <TableCell size={"20%"}>
              <p className={styles.ellipsis}>{user.firstName}</p>
            </TableCell>
            <TableCell size={"90px"} centerContent>
              <CustomSelect
                key={user.id}
                width="80px"
                height="22px"
                options={optionsStatus}
                value={
                  user.isActive ? optionsStatus[0].name : optionsStatus[1].name
                }
                handleSelected={() => handleUserStatus(user)}
                icon={<ArrowDownFill fontSize={"9px"} />}
                backgroundColor={
                  user.isActive
                    ? optionsStatus[0].color
                    : optionsStatus[1].color
                }
                padding={"0px 6px"}
              />
            </TableCell>
            <TableCell size={"15%"} centerContent>
              <p className={styles.ellipsis}>{rolType(user.role)}</p>
            </TableCell>

            <TableCell size={"100%"} RightContent>
              <Button
                buttonStyle={ButtonStyle.transparent}
                clickHandler={() => handleSeeUserDetails(user.id)}
              >
                <p className={styles.StoreTableLink}>
                  {t("views.stores.table.detailsBtn")}
                </p>
              </Button>
            </TableCell>
            <TableCell size="5px"></TableCell>
          </TableRow>
        );
      })}
    </>
  );

  return (
    <>
      <TableHeader
        hideCheckBox={true}
        orderingList={orderingList}
        orderingValue={selectedFilter}
        orderingClick={handleOrdering}
        searchInputValue={filterValue}
        searchInputChangeHandle={handleFiltering}
      />
      <div
        className={`${isMobile ? styles.tableIsMobile : ""} ${
          styles.StoreTableComponent
        }`}
      >
        <div style={{ width: "98%", height: "100%" }}>
          {userList && userList.length !== 0 && (
            <Table header={header} body={body} />
          )}
        </div>
      </div>
      <div className={styles.TableBottom}>
        <Button
          buttonStyle={ButtonStyle.border}
          height={"32px"}
          clickHandler={handleNewUserButton}
        >
          <span style={{ fontWeight: 500 }}>
            {t("views.collections.details.table.addButton")}
          </span>
        </Button>
      </div>
    </>
  );
};

interface StoreInfoComponentProps {
  store?: FiraStore;
  isMobile: boolean;
  onDelete(): void;
  onEdit(): void;
}

const StoreInfoComponent = ({
  store,
  isMobile = false,
  onDelete,
  onEdit,
}: StoreInfoComponentProps) => {
  const handleCreationDate = () => {
    if (store) {
      let d = new Date(store?.workflowList[0].performedAt);
      return dateFormat(d);
    } else {
      return "--/--/----";
    }
  };

  return (
    <div
      className={`${styles.StoreInfoComponent} ${
        isMobile ? styles.mobileInfoComponent : styles.desktopInfoComponent
      }`}
    >
      <div className={styles.InfoGrid}>
        <div className={`${styles.InfoGrid__Item}`} id={styles.storename}>
          <label className={styles["alert-text-14-bold"]}>{store?.name}</label>
          <p>
            {t("views.stores.details.createdDate")} {handleCreationDate()}
          </p>
        </div>
        <div className={`${styles.InfoGrid__Item}`} id={styles.contact}>
          <label className={styles["alert-text-14-bold"]}>
            {t("views.stores.details.contact")}
          </label>
          <p>{store?.legalName !== null ? store?.legalName : ""}</p>

          {store?.contact?.emails !== null &&
            store?.contact?.emails &&
            store?.contact.emails.map((email, i) => (
              <p key={i}>{email || ""}</p>
            ))}
          {store?.contact?.phones !== null &&
            store?.contact?.phones &&
            store?.contact.phones.map((phone, i) => (
              <p key={i}> {phone.number || ""}</p>
              // +00codehere!
            ))}
        </div>
        <div className={`${styles.InfoGrid__Item}`} id={styles.country}>
          <label className={styles["alert-text-14-bold"]}>
            {t("views.stores.details.country")}
          </label>
          <p>{store?.location !== null ? store?.location.country : ""}</p>
        </div>
        <div className={`${styles.InfoGrid__Item}`} id={styles.currency}>
          <label className={styles["alert-text-14-bold"]}>
            {t("views.stores.details.currency")}
          </label>
          <p>
            {store?.regionalConfiguration.currency !== null
              ? store?.regionalConfiguration.currency
              : ""}
          </p>
        </div>
        <div className={`${styles.InfoGrid__Item}`} id={styles.city}>
          <label className={styles["alert-text-14-bold"]}>
            {t("views.stores.details.city")}
          </label>
          <p>{store?.location !== null ? store?.location.city : ""}</p>
        </div>
        <div className={`${styles.InfoGrid__Item}`} id={styles.website}>
          <label className={styles["alert-text-14-bold"]}>
            {t("views.stores.details.website")}
          </label>
          <p>
            {store?.contact?.webpages !== null &&
              store?.contact?.webpages &&
              store.contact.webpages.map((web, i) => (
                <span key={i}>{web || ""}</span>
              ))}
          </p>
        </div>
      </div>

      <div
        className={`${isMobile ? styles.mobileButtons : styles.desktopButtons}`}
      >
        <div className={styles.button}>
          <Button
            height="32px"
            buttonStyle={ButtonStyle.main}
            width={"100%"}
            clickHandler={onEdit}
          >
            <span className={styles.StoreInfoComponent__InnerButton}>
              {t("views.stores.details.editBtn")}
            </span>
          </Button>
        </div>
        <div className={styles.button}>
          <Button
            height="32px"
            buttonStyle={ButtonStyle.mainBorder}
            width={"100%"}
          >
            <span className={styles.StoreInfoComponent__InnerButton}>
              {t("views.stores.details.transferBtn")}
            </span>
          </Button>
        </div>
        <div className={styles.button}>
          <Button
            height="32px"
            buttonStyle={ButtonStyle.delete}
            width={"100%"}
            clickHandler={onDelete}
          >
            <span className={styles.StoreInfoComponent__InnerButton}>
              {t("views.stores.details.deleteBtn")}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StoresDetail;
