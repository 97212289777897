import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify-icon/react";

import stylesheet from "./StepLogin.module.scss";
import { useWizard } from "../../providers/WizardProvider";
import Input from "../Input/Input";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import Button, { ButtonStyle } from "../Button/Button";
import { ArrowDownFill, TrashCan } from "../FiraIcons";
import OpenLink from "../FiraIcons/OpenLink";
import { urlRegex } from "../../utils/constants";
import { isValidUrl } from "../../utils/utils";

const {
  Wrapper,
  Header,
  InputsWrapper,
  UrlWrapper,
  InputItemWrapper,
  InputSelect,
  InputText,
  InputList,
  Delete,
  SelectWrapper,
  IconWrapper,
} = stylesheet;

enum FieldLabels {
  text = "event_creation.params.fieldTypes.text",
  email = "event_creation.params.fieldTypes.email",
  password = "event_creation.params.fieldTypes.password",
  tel = "event_creation.params.fieldTypes.tel",
  number = "event_creation.params.fieldTypes.number",
}

const StepLogin: React.FC = () => {
  const {
    form,
    setFormKey,
    loginActive,
    toggleLogin,
    loginForm,
    setLoginInput,
    removeLoginInput,
    updateLoginInputs,
    toggleSave,
  } = useWizard();
  const { t } = useTranslation();

  const handleDisclaimerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // TODO: validate that is an URL
    setFormKey("disclaimerUrl", event.target.value);
  };

  const handleAddNewParam = () => {
    if (loginForm.length > 5) return;

    setLoginInput("Text", "text", false);
  };

  const handleInputChange = (
    value: string,
    name: "selectType" | "placeholder" | "required",
    index: number
  ) => {
    let newInputs = loginForm;
    switch (name) {
      case "placeholder":
        newInputs[index].fieldLabel = value;

        break;
      case "selectType":
        newInputs[index].fieldType = value;
        break;
      case "required":
        newInputs[index].isRequired = !loginForm[index].isRequired;
        break;
    }
    updateLoginInputs(newInputs);
  };

  const handleRemoveInput = (index: number) => {
    removeLoginInput(index);
  };

  React.useEffect(() => {
    console.log(form.disclaimerUrl, isValidUrl(form.disclaimerUrl));
    if (loginActive) {
      if (isValidUrl(form.disclaimerUrl)) {
        toggleSave(false);
        return;
      }
      toggleSave(true);
    } else {
      toggleSave(false);
    }
  }, [loginActive, form.disclaimerUrl]);

  return (
    <div className={Wrapper}>
      <div className={Header}>
        <span>{t("event_creation.params.loginParamsTitle")}</span>
        <span>{t("event_creation.params.loginParamsMessage")}</span>
      </div>
      <div className={InputsWrapper}>
        <div className={Header}>
          <span>{t("event_creation.params.loginEnabled")}</span>
          <ToggleSwitch checked={loginActive} handleChecked={toggleLogin} />
        </div>
        <div className={InputList}>
          {loginForm.map((input, index) => (
            <InputItem
              key={index}
              index={index}
              input={input.fieldLabel}
              type={input.fieldType}
              required={input.isRequired}
              isDisable={index === 0 ? true : !loginActive}
              onChange={handleInputChange}
              onRemove={handleRemoveInput}
            />
          ))}
        </div>
        <Button
          clickHandler={handleAddNewParam}
          isDisabled={loginForm.length >= 5 || !loginActive}
          height="40px"
        >
          {t("event_creation.params.addFields")}
        </Button>
      </div>
      <div className={UrlWrapper}>
        <div>
          <span>{t("event_creation.params.terms_and_conditions")}</span>
          <a href={form.disclaimerUrl} target="_blank">
            <OpenLink />
          </a>
        </div>

        <Input
          onChange={handleDisclaimerChange}
          value={form.disclaimerUrl}
          height="40px"
          width="100%"
          isDisabled={!loginActive}
        />
      </div>
    </div>
  );
};

interface InputItemProps {
  index: number;
  input: string;
  type: string;
  required: boolean;
  isDisable: boolean;
  onChange(value: string, name: string, index: number): void;
  onRemove(index: number): void;
}
const InputItem: React.FC<InputItemProps> = ({
  index,
  input,
  type,
  required,
  isDisable,
  onChange,
  onRemove,
}) => {
  const { t } = useTranslation();
  const selectLabels = [
    { label: t(FieldLabels.text), value: "text" },
    { label: t(FieldLabels.email), value: "email" },
    { label: t(FieldLabels.password), value: "password" },
    { label: t(FieldLabels.tel), value: "tel" },
    { label: t(FieldLabels.number), value: "number" },
  ];

  const handleInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    onChange(event.target.value, event.target.name, index);
  };

  const handleInputRequired = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChange("", event.currentTarget.name, index);
  };

  return (
    <div className={InputItemWrapper}>
      <div className={SelectWrapper}>
        <select
          value={type}
          disabled={isDisable}
          className={InputSelect}
          name="selectType"
          onChange={handleInput}
        >
          {selectLabels.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <ArrowDownFill className={IconWrapper} width={10} color={"#5C5F62"} />
      </div>

      <div className={InputText}>
        <Input
          fontSize="16px"
          onChange={handleInput}
          inputName="placeholder"
          value={input}
          width="100%"
          height="40px"
          isDisabled={isDisable}
        />
      </div>
      <Button
        name="required"
        isDisabled={isDisable}
        width="123px"
        height="40px"
        buttonStyle={required ? ButtonStyle.main : ButtonStyle.border}
        clickHandler={handleInputRequired}
      >
        {t("event_creation.params.requiredField")}
      </Button>
      <Button
        isDisabled={isDisable}
        width="33px"
        height="40px"
        buttonStyle={ButtonStyle.border}
        className={isDisable ? undefined : Delete}
        clickHandler={() => onRemove(index)}
      >
        <span
          style={{
            color: "#230B34",
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TrashCan color={isDisable ? "#D0D0D0" : "#F16063"} fontSize={18} />
        </span>
      </Button>
    </div>
  );
};

export default StepLogin;
