import { EditablePermissionsType } from "../components/NewUserComponents/FiraUserPermissionComponent/FiraUserPermissionComponent";
import { SimplePermissionItem } from "../types/Response";
import { FiraPermissionsModel } from "../views/SendInvitationView/FiraPermissions/PermissionsItemsForOperator/PermissionItemsForOperator";
import { FiraPackage, FiraUserRole } from "./enums";

export const selectAll = (model: FiraPermissionsModel) => {
  let selectAll: string[] = [];

  model.permissionsGroup.map((element: any) => {
    selectAll = [...selectAll, ...element.permissions];
  });
  return selectAll;
};

export const setAllAvailable = (
  model: FiraPermissionsModel,
  userPermissions: SimplePermissionItem[]
) => {
  let selectAllAvailable: string[] = [];
  model.permissionsGroup.map((element: any) => {
    if (isPermissionGroupAvailable(userPermissions, element.permissions)) {
      selectAllAvailable.push(element.permissions);
    }
  });
  return selectAllAvailable;
};

//Cambiar el funcionamineto por al funcion areAllActive()
export const checkAllActive = (model: FiraPermissionsModel) => {
  const allActive = model.permissionsGroup.every((check) => check.isActive);
  return allActive;
};

export const activateAllOptions = (model: FiraPermissionsModel) => {
  const allActive = model.permissionsGroup.map((check) => ({
    ...check,
    isActive: true,
  }));

  return allActive;
};

export const inactivateAllOptions = (model: FiraPermissionsModel) => {
  const allInactive = model.permissionsGroup.map((check) => ({
    ...check,
    isActive: false,
  }));

  return allInactive;
};

export const hasPermissions = (
  firaProduct: FiraPackage,
  permissionsArr: any
) => {
  if (permissionsArr) {
    if (permissionsArr.map((item: any) => item.firaProduct === firaProduct)) {
      return (
        permissionsArr.find((item: any) => item.firaProduct === firaProduct)
          .permissions.length === 0
      );
    }
    return false;
  }
  return true;
};

//Returns an array of objects with the status of the permissions group
export const checkAvailablePermissions = (
  userPermissions: string[],
  invitation: string[]
) => {
  return invitation.map((item: string) => ({
    item,
    available: userPermissions.includes(item),
  }));
};

export const isPermissionAvailable = (
  permissions: string[],
  toCheck: string
) => {
  return permissions.includes(toCheck);
};

export const isPermissionGroupAvailable = (
  userPermissions: SimplePermissionItem[],
  permissionGroup: string[]
) => {
  return permissionGroup.every((str) =>
    userPermissions.some((obj) => obj.permissions.includes(str))
  );
};

export const onlyAvailablePermissions = (
  userPermissionsFullList: string[],
  invitationPermissions: string[]
) => {
  const result = [];
  for (const element of invitationPermissions) {
    if (userPermissionsFullList.includes(element)) {
      result.push(element);
    }
  }
  return result;
};

/**
 * FOR PERMITTION EDITING
 * - OWNER AND USER
 */

/** Usado para verficar si el firaProduct existe dentro de los permisos del usuario
 * Si el usuario pasa de operador a moderador, se chequea si los permisos anteriores son suficientes
 * para activar el botón
 */
export const userHasPermission = (
  userPermissions: SimplePermissionItem[],
  firaProduct: FiraPackage,
  previousRole?: FiraUserRole
) => {
  return userPermissions.some((obj) => obj.firaProduct === firaProduct);
};

/**
 * En caso de que el usuario sea degradado, se chequean todos los campos,
 * En caso de que se degrade de moderador, se chequean todo excepto los correspondientes
 * a ProSeller
 */

/** used to check all of the items */
export const automaticSelectionHandler = (
  userPermissions: EditablePermissionsType[]
) => {
  return userPermissions.forEach((item) => (item.isActive = true));
};

export const singleCheckHandler = (
  permissionsGroup: EditablePermissionsType,
  fullPermissions: EditablePermissionsType[]
) => {
  const newArray = [...fullPermissions];
  const index = newArray.findIndex(
    (item) => item.name === permissionsGroup.name
  );

  if (index !== -1) {
    newArray[index].isActive = !newArray[index].isActive;
    return newArray;
  }
};

/** Chequea si todos los permisos pertenecientes al grupo se encuentran
 * entre los permisos del usuario
 */
export const checkActiveByGroup = (
  firaProduct: FiraPackage,
  permissionGroup: string[],
  userPermissions: SimplePermissionItem[],
  card?: string
) => {
  let arr: string[] | undefined = userPermissions.find(
    (item) => item.firaProduct === firaProduct
  )?.permissions;

  if (arr !== undefined) {
    let compare = permissionGroup.every((item) => arr?.includes(item));
    return compare;
  }

  return false;
};

export const areAllActive = (permissions: EditablePermissionsType[]) => {
  return permissions.every((item) => item.isActive);
};

//Similar to activateAllOptions and inactivateAllOptions
export const activateAll = (permissions: EditablePermissionsType[]) => {
  const allActive = permissions.map((check) => ({
    ...check,
    isActive: true,
  }));

  return allActive;
};

export const inactivateAll = (permissions: EditablePermissionsType[]) => {
  const allInactive = permissions.map((check) => ({
    ...check,
    isActive: false,
  }));

  return allInactive;
};

export const hasSomePermissions = (permissions: EditablePermissionsType[]) => {
  for (const productObj of permissions) {
    if (productObj.isActive === true) {
      return true;
    }
  }
  return false;
};
