import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgChatBubble = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M27 14.5C27 20.299 21.627 25 15 25a13.59 13.59 0 0 1-3.52-.459c-.877.444-2.888 1.296-6.272 1.851-.3.048-.528-.264-.41-.543.532-1.254 1.011-2.925 1.155-4.449C4.117 19.555 3 17.14 3 14.5 3 8.701 8.373 4 15 4s12 4.701 12 10.5Zm-16.5 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm6 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM21 16a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgChatBubble;
