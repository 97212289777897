import * as React from "react";
import { SVGProps } from "react";

const MainCarrouselPreview = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={221}
    height={102}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={221} height={102} rx={8} fill="#F7F8F9" />
    <rect
      x={119.911}
      y={76.094}
      width={36.366}
      height={7.906}
      rx={1.5}
      fill="#D5DDE0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21a4 4 0 0 1 4-4h92.994a4 4 0 0 1 4 4v59a4 4 0 0 1-4 4H16a4 4 0 0 1-4-4V21Zm50.432 34.313a4.87 4.87 0 0 1-4.872-4.875 4.877 4.877 0 0 1 4.872-4.874 4.87 4.87 0 0 1 4.872 4.874 4.877 4.877 0 0 1-4.872 4.875Zm0 .697A5.566 5.566 0 0 0 68 50.438a5.573 5.573 0 0 0-5.568-5.57 5.566 5.566 0 0 0-5.568 5.57 5.573 5.573 0 0 0 5.568 5.572Zm-1.019-7.66a.349.349 0 0 0-.373.347v3.483a.348.348 0 0 0 .55.283l2.437-1.741a.347.347 0 0 0 0-.567l-2.436-1.74a.348.348 0 0 0-.178-.065Z"
      fill="#D5DDE0"
    />
    <rect x={120} y={17} width={89} height={8} rx={1.5} fill="#D5DDE0" />
    <rect x={120} y={27} width={89} height={3} rx={1} fill="#D5DDE0" />
    <rect x={120} y={32} width={89} height={3} rx={1} fill="#D5DDE0" />
    <rect x={120} y={37} width={89} height={3} rx={1} fill="#D5DDE0" />
    <rect x={120} y={42} width={89} height={3} rx={1} fill="#D5DDE0" />
    <rect x={120} y={47} width={89} height={3} rx={1} fill="#D5DDE0" />
    <rect
      x={122.876}
      y={55.935}
      width={8.894}
      height={8.498}
      rx={1.5}
      fill="#D5DDE0"
    />
    <rect
      x={122.876}
      y={66.015}
      width={8.894}
      height={3.755}
      rx={1.5}
      fill="#D5DDE0"
    />
    <rect
      x={136.513}
      y={66.015}
      width={8.894}
      height={3.755}
      rx={1.5}
      fill="#D5DDE0"
    />
    <rect
      x={150.348}
      y={66.015}
      width={8.894}
      height={3.755}
      rx={1.5}
      fill="#D5DDE0"
    />
    <rect
      x={165.961}
      y={66.015}
      width={8.894}
      height={3.755}
      rx={1.5}
      fill="#D5DDE0"
    />
    <rect
      x={136.513}
      y={55.935}
      width={8.894}
      height={8.498}
      rx={1.5}
      fill="#D5DDE0"
    />
    <rect
      x={150.348}
      y={55.935}
      width={8.894}
      height={8.498}
      rx={1.5}
      fill="#D5DDE0"
    />
    <rect
      x={165.961}
      y={55.935}
      width={8.894}
      height={8.498}
      rx={1.5}
      fill="#D5DDE0"
    />
  </svg>
);

export default MainCarrouselPreview;
