import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button, { ButtonStyle } from "../../../components/Button/Button";
import { AdminIcon, ModIcon, UserIcon } from "../../../components/FiraIcons";
import { FiraUserRoleComponent } from "../../../components/NewUserComponents";
import { InvitationType, RoleType, RootState } from "../../../utils/types.d";
import styles from "./FiraOperatorRole.module.scss";

interface FiraOperatorRoleProps {
  handleNext(): void;
  handlePrev(): void;
  handleInvite(): void;
  onSelect(role: RoleType): void;
  invitation?: InvitationType;
  isLoading: boolean;
}

const FiraOperatorRole = ({
  handleNext,
  handlePrev,
  handleInvite,
  onSelect,
  invitation,
  isLoading,
}: FiraOperatorRoleProps) => {
  const { t } = useTranslation();
  const { authentication } = useSelector((state: RootState) => state);
  const [selectedRole, setSelectedRole] = useState<RoleType | null>(
    invitation?.role || null
  );

  const handleSelection = (role: RoleType) => {
    setSelectedRole(role);
  };

  const admin = {
    role: {
      icon: <AdminIcon />,
      name: t("views.operators.admin_name"),
      description: t("views.operators.admin_desc"),
    },
    onClick: () => handleSelection(RoleType.MANAGER),
    isSelected: selectedRole === RoleType.MANAGER,
  };

  const operator = {
    role: {
      icon: <UserIcon />,
      name: t("views.operators.operator_name"),
      description: t("views.operators.operator_desc"),
    },
    onClick: () => handleSelection(RoleType.OPERATOR),
    isSelected: selectedRole === RoleType.OPERATOR,
  };

  const moderator = {
    role: {
      icon: <ModIcon />,
      name: t("views.operators.moderator_name"),
      description: t("views.operators.moderator_desc"),
    },
    onClick: () => handleSelection(RoleType.MODERATOR),
    isSelected: selectedRole === RoleType.MODERATOR,
  };

  useEffect(() => {
    if (selectedRole) {
      onSelect(selectedRole);
    }
  }, [selectedRole]);

  return (
    <>
      <div className={styles.FiraOperatorRole}>
        <p className={styles.Title}>{t("views.operators.operator_role")}</p>
        <p className={styles.Desc}>{t("views.operators.operator_role_desc")}</p>
        <div className={styles.Operators}>
          {authentication.isAccountOwner && (
            <FiraUserRoleComponent
              role={admin.role}
              onClick={admin.onClick}
              isSelected={admin.isSelected}
            />
          )}

          <FiraUserRoleComponent
            role={operator.role}
            onClick={operator.onClick}
            isSelected={operator.isSelected}
          />
          <FiraUserRoleComponent
            role={moderator.role}
            onClick={moderator.onClick}
            isSelected={moderator.isSelected}
          />
        </div>
        <div className={styles.Buttons}>
          <Button
            width="116px"
            height="32px"
            buttonStyle={ButtonStyle.border}
            clickHandler={handlePrev}
          >
            <span className={styles.Inner}>{t("views.operators.prevBtn")}</span>
          </Button>
          {selectedRole === RoleType.OPERATOR && (
            <Button
              width="116px"
              height="32px"
              buttonStyle={ButtonStyle.main}
              clickHandler={handleInvite}
              isDisabled={selectedRole === null || isLoading}
            >
              <span className={styles.Inner}>
                {t("views.operators.inviteBtn")}
              </span>
            </Button>
          )}
          {selectedRole !== RoleType.OPERATOR && (
            <Button
              width="116px"
              height="32px"
              buttonStyle={ButtonStyle.main}
              clickHandler={handleNext}
              isDisabled={selectedRole === null}
            >
              <span className={styles.Inner}>
                {t("views.operators.nextBtn")}
              </span>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default FiraOperatorRole;
