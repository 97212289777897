import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCopyPaperFill = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="5 3 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.6 23c-.44 0-.817-.157-1.13-.47A1.543 1.543 0 0 1 8 21.4V10.2h1.6v11.2h8.8V23H9.6Zm3.2-3.2a1.54 1.54 0 0 1-1.13-.47 1.541 1.541 0 0 1-.47-1.13V8.6c0-.44.157-.817.47-1.13A1.54 1.54 0 0 1 12.8 7H20c.44 0 .817.157 1.13.47.314.313.47.69.47 1.13v9.6c0 .44-.157.817-.47 1.13-.313.314-.69.47-1.13.47h-7.2Zm0-1.6H20V8.6h-7.2v9.6Z"
      fill="#ABAFB1"
    />
    <path fill="#ABAFB1" d="M12 7.797h8.8v11.2H12z" />
  </svg>
);

export default SvgCopyPaperFill;
