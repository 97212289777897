import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MainLoader } from "..";
import { RootState } from "../../store/Store";
import { Step, StepRoutes } from "../../views/EditVideoView/EditVideoView";
import Button, { ButtonStyle } from "../Button/Button";
import EventErrorMessage from "../EventCreation/EventErrorMessage/EventErrorMessage";

import BreadcrumbNavBar from "../NavBar/BreadcrumbNavBar/BreadcrumbNavBarComp";

import styles from "./StepByStep.module.scss";

interface Props {
  arrayView: JSX.Element[];
  steps: Step[];
  handleChange(step: string): void;
}

const StepByStep = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { brand, event } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [breadcrumbSteps, setBreadcrumbSteps] = useState<Step[]>(props.steps);
  const [breadcrumbMiniSteps, setBreadcrumbMiniSteps] = useState<Step[]>();
  const [currentRoute, setCurrentRoute] = useState<StepRoutes>(
    StepRoutes.general
  );

  const [wrongFiles, setWrongFiles] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [errorMessageIsOpen, setErrorMessageIsOpen] = useState<boolean>(false);

  const [showLoader, setShowLoader] = useState<boolean>(false);

  const updateActiveRoutes = (keys: Array<number>) => {
    const routes = [...breadcrumbSteps];
    //--ACTIVE
    const activeRoutes = routes.slice(keys[0], keys[1]);
    activeRoutes.map((item, i) => {
      activeRoutes[i].active = true;
    });
    routes.splice.apply(activeRoutes, [keys[0], keys[1]]);
    //--INACTIVE
    const inactiveRoutes = routes.slice(keys[1], routes.length);
    inactiveRoutes.map((item, i) => {
      inactiveRoutes[i].active = false;
    });
    routes.splice.apply(inactiveRoutes, [keys[0], routes.length]);
    return routes;
  };

  const handleNavigationPrev = () => {
    props.handleChange(currentRoute);
    breadcrumbSteps.map((step, i) => {
      if (currentRoute === breadcrumbSteps[0].route) return;
      if (currentRoute === breadcrumbSteps[i].route) {
        setBreadcrumbSteps(updateActiveRoutes([0, i]));
        setCurrentRoute(breadcrumbSteps[i - 1].route);
      }
    });
  };

  const handleNavigationNext = () => {
    breadcrumbSteps.map((step, i) => {
      if (currentRoute === breadcrumbSteps[i].route) {
        if (
          currentRoute === breadcrumbSteps[breadcrumbSteps.length - 1].route
        ) {
          handleSubmit();
        } else {
          setBreadcrumbSteps(updateActiveRoutes([0, i + 2]));
          setCurrentRoute(breadcrumbSteps[i + 1].route);
        }
      }
    });
    props.handleChange(currentRoute);
  };

  const handleSubmit = async () => {
    navigate("/home/videos");
  };

  const closeModal = () => {
    setErrorMessageIsOpen(false);
  };

  useEffect(() => {
    setBreadcrumbSteps(props.steps);
  }, [props]);

  return (
    <>
      {showLoader && <MainLoader />}

      {errorMessageIsOpen && (
        <EventErrorMessage
          isOpen={errorMessageIsOpen}
          onClose={closeModal}
          goToCalendar={() => navigate("/home/videos")}
        ></EventErrorMessage>
      )}

      <div className={styles.Events}>
        <div className={styles.EventCreationContainer}>
          <BreadcrumbNavBar steps={breadcrumbSteps} />
          <div className={styles.EventView}>
            {(() => {
              breadcrumbSteps.map((item, key) => {
                if (currentRoute === item.route) {
                  return <>{props.arrayView[0]}</>;
                }
              });
              switch (currentRoute) {
                case StepRoutes.general:
                  return <>{props.arrayView[0]}</>;
                case StepRoutes.video:
                  return <>{props.arrayView[1]}</>;
                case StepRoutes.products:
                  return <>{props.arrayView[2]}</>;
                case StepRoutes.comments:
                  return <>CUATRO</>;
              }
            })()}
          </div>
          <div className={styles.EventButtons}>
            <StepsButtons
              navNext={handleNavigationNext}
              navPrev={handleNavigationPrev}
              route={currentRoute}
              breadcrumbSteps={breadcrumbSteps}
              locationType={"EDIT"}
              wrongFiles={wrongFiles}
              isLoading={isLoading}
              loader={showLoader}
            />
          </div>
        </div>
      </div>
    </>
  );
};

interface StepsButtonsProps {
  navNext(): void;
  navPrev(): void;
  route: StepRoutes;
  locationType: string;
  breadcrumbSteps: Step[];
  wrongFiles: boolean;
  isLoading: boolean;
  loader: boolean;
}

const StepsButtons = ({
  navNext,
  navPrev,
  route,
  locationType,
  breadcrumbSteps,
  wrongFiles = false,
  isLoading = false,
  loader = false,
}: StepsButtonsProps) => {
  const urlRegex = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/))(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)(\.){1,}[a-zA-Z0-9()]*\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=])*/gi
  );

  return (
    <>
      <Button
        buttonStyle={ButtonStyle.border}
        type={"button"}
        height={"43px"}
        width={"176px"}
        clickHandler={navPrev}
        isDisabled={route === breadcrumbSteps[0].route}
      >
        <span className={`${styles.Inner} ${styles["body-14-text"]}`}>
          {t("event_creation.prevButton")}
        </span>
      </Button>

      <Button
        buttonStyle={ButtonStyle.main}
        type={"button"}
        height={"43px"}
        width={"176px"}
        clickHandler={navNext}
        isDisabled={false}
      >
        <>
          {locationType === "EDIT" && (
            <span className={`${styles.Inner} ${styles["body-14-text"]}`}>
              {route === breadcrumbSteps[breadcrumbSteps.length - 1].route
                ? t("views.videos.steps.end")
                : t("views.videos.steps.next")}
            </span>
          )}
        </>
      </Button>
    </>
  );
};

export default StepByStep;
