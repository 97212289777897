import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  ReactElement,
} from "react";
import "./InputTextArea.scss";

interface Props {
  onChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
  type?: HTMLInputTypeAttribute | undefined;
  isDisabled?: boolean;
  value?: string;
  haveError?: boolean | undefined;
  isValidated?: boolean;
  width?: string;
  height?: string;
  icon?: ReactElement;
  showPswButton?: boolean;
  inputName?: string;
  inputId?: string;
  placeholder?: string;
}

const InputTextArea = (props: Props) => {
  const {
    onChange,
    inputName,
    inputId,
    isDisabled = false,
    value = "",
    height = "90px",
    width = "150px",
    placeholder = "",
  } = props;

  const style = {
    height,
    width,
  };

  return (
    <div className="InputTextAreaWrapper">
      <textarea
        name={inputName}
        id={inputId}
        className="InputTextArea"
        style={style}
        disabled={isDisabled}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputTextArea;
