import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgLanding = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 24 24"
    version="1.1"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M19.6 4.5h-15C3.2 4.5 2.1 5.6 2.1 7v10.6c0 1.4 1.1 2.5 2.5 2.5h15c1.4 0 2.5-1.1 2.5-2.5V7c0-1.4-1.1-2.5-2.5-2.5zm1.4 5.6H3.2V6.9c0-.7.6-1.3 1.3-1.3h15.3c.7 0 1.3.6 1.3 1.3v3.2z"></path>
    <path d="M8.7 8.1c0 .5-.4.8-.8.8-.5 0-.8-.4-.8-.8 0-.5.4-.8.8-.8.5 0 .8.3.8.8zM6 8.1c0 .5-.4.8-.8.8-.5 0-.8-.4-.8-.8 0-.5.4-.8.8-.8s.8.3.8.8z"></path>
  </svg>
);

export default SvgLanding;
