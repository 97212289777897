import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { handleError, RequestError } from "../../services/errorHandling";
import { DashboardActions } from "../actionsContants";
import { LogoutActionType } from "../Auth/AuthActions";

interface SetDateRangeActionType {
  type: DashboardActions.setDateRange;
  payload: { firstDate: Date; lastDate: Date };
}
export const setDateRangeActionCreator = (
  firstDate: Date,
  lastDate: Date
): SetDateRangeActionType => {
  return {
    type: DashboardActions.setDateRange,
    payload: { firstDate, lastDate },
  };
};

export type DashboardActionType = SetDateRangeActionType | LogoutActionType;
