import React, { SetStateAction } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ImageProductObject } from "../../../utils/types";
import { handleImageLoadError } from "../../../utils/handleImageLink";
import ItemDraggable from "../../../components/FiraIcons/ItemDraggable";
import StarIcon from "../../../components/FiraIcons/Star";
import CrossIcon from "../../../components/FiraIcons/CloseImage";
import { TrashCan } from "../../../components/FiraIcons";

interface Props {
  items: ImageProductObject[];
  canEdit?: boolean;
  setItems: React.Dispatch<SetStateAction<ImageProductObject[]>>;
  onDelete(url: string): void;
  onChangeItems(): void;
}
const ImagesGrid: React.FC<Props> = ({
  items,
  setItems,
  children,
  onDelete,
  onChangeItems,
  canEdit = true,
}) => {
  const sortableIndex = items.map((i, index) => index);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((_, index) => index === active.id);
      const newIndex = items.findIndex((_, index) => index === over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);
      onChangeItems();
      setItems(newItems);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        disabled={!canEdit}
        items={sortableIndex}
        strategy={horizontalListSortingStrategy}
      >
        <div style={GridStyle}>
          {items.map((img, index) => (
            <GridItem
              canEdit={canEdit}
              key={index}
              index={index}
              image={img}
              size={index === 0 ? "large" : "small"}
              onDelete={onDelete}
            />
          ))}
          <div style={items.length === 1 ? ImageInputAlt : ImageInput}>
            {children}
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );
};

interface GridItemProps {
  canEdit: boolean;
  index: number;
  image: ImageProductObject;
  size: "small" | "large";
  onDelete(url: string): void;
}
const GridItem: React.FC<GridItemProps> = ({
  canEdit,
  size,
  image,
  index,
  onDelete,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: index });
  const mainStyle = size === "large" ? GridItemMainStyle : {};

  const handleDeleteButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDelete(image.imageUrl);
  };

  const handlePropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        minWidth: size === "large" ? "200px" : "100px",
        minHeight: size === "large" ? "200px" : "100px",
        transform: CSS.Transform.toString(transform),
        transition,
        ...GridItemStyle,
        ...mainStyle,
      }}
    >
      {canEdit && (
        <button
          style={DeleteButton}
          onClick={handleDeleteButtonClick}
          onMouseDown={handlePropagation}
          onPointerDown={handlePropagation}
        >
          {image.isDelete ? <CrossIcon /> : <TrashCan color="#D0D0D0" />}
        </button>
      )}
      {image.selected && (
        <div style={Star}>
          <StarIcon />
        </div>
      )}
      <img
        style={size === "large" ? ImageAlt : Image}
        src={image.imageUrl}
        onError={handleImageLoadError}
      />
      {canEdit && (
        <div style={Draggable}>
          <ItemDraggable />
        </div>
      )}
    </div>
  );
};

const GridStyle: React.CSSProperties = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, 100px)",
  gridTemplateRows: "repeat(auto-fit, 100px)",
  gridAutoFlow: "column",
  gap: 10,
  padding: 10,
};

const ImageInput: React.CSSProperties = {
  gridColumnStart: 1,
  gridColumnEnd: "last-line",
};

const ImageInputAlt: React.CSSProperties = {
  gridColumnStart: 3,
  gridColumnEnd: "last-line",
  gridRowStart: 1,
  gridRowEnd: "last-line",
};

const GridItemStyle: React.CSSProperties = {
  cursor: "grab",
  position: "relative",
  justifySelf: "stretch",
  border: "1px solid #D5DDE0",
  borderRadius: 8,
  backgroundColor: "#D5DDE0",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const GridItemMainStyle: React.CSSProperties = {
  gridColumnStart: 1,
  gridColumnEnd: 3,
  gridRowStart: 1,
  gridRowEnd: 3,
};

const Image: React.CSSProperties = {
  borderRadius: 4,
  maxWidth: 100,
  maxHeight: 100,
  objectFit: "contain",
};

const ImageAlt: React.CSSProperties = {
  borderRadius: 4,
  maxWidth: 200,
  maxHeight: 200,
  objectFit: "contain",
};

const Draggable: React.CSSProperties = {
  position: "absolute",
  bottom: 8,
  right: 8,
};

const Star: React.CSSProperties = {
  position: "absolute",
  top: 8,
  right: 8,
};

const DeleteButton: React.CSSProperties = {
  position: "absolute",
  top: 8,
  left: 8,
  width: 16,
  height: 16,
  borderRadius: 4,
  backgroundColor: "#F7F8F9",
  border: "1px solid #D5DDE0",
  padding: 0,
  margin: 0,
};

export default ImagesGrid;
