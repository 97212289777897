import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgStar = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M19.778 9.254a1.357 1.357 0 0 0-.789-2.444l-5.5-.208a.14.14 0 0 1-.122-.091l-1.9-5.134a1.357 1.357 0 0 0-2.549 0L7.024 6.53a.14.14 0 0 1-.122.092l-5.5.207a1.357 1.357 0 0 0-.789 2.445l4.315 3.392a.14.14 0 0 1 .049.146l-1.485 5.262a1.357 1.357 0 0 0 2.065 1.497l4.56-3.055a.135.135 0 0 1 .152 0l4.559 3.055a1.344 1.344 0 0 0 1.558 0 1.344 1.344 0 0 0 .507-1.467l-1.497-5.28a.135.135 0 0 1 .049-.146l4.333-3.423Z"
      fill="#FFDE07"
    />
  </svg>
);

export default SvgStar;
