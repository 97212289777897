import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendRecoveryCodeActionCreator } from "../../store/Recovery/RecoveryActions";
import { RootState } from "../../utils/types";
import { useTranslation } from "react-i18next";
import Input, { InputStyles } from "../Input/Input";
import Button from "../Button/Button";
import { ArrowRight } from "../FiraIcons";
import "./PasswordRecovery.scss";

const PasswordRecovery = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recovery = useSelector((state: RootState) => state.recovery);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(sendRecoveryCodeActionCreator(email));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    const { value } = e.target;
    const validated = regex.test(value);

    setEmail(value);
    setValidEmail(validated);
  };

  useEffect(() => {
    if (recovery.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [recovery.isLoading]);

  useEffect(() => {
    if (recovery.email !== undefined) {
      navigate("/pwdcode");
    }
  }, [recovery.email, navigate]);

  return (
    <div className="RecoveryForm">
      <div className="body-14-text desc">
        {t("auth.password_recovery.message")}
      </div>
      <form className="RecoveryFormContainer" onSubmit={handleSubmit}>
        <div className="FormGroup">
          <label className="lead-text form-label">
            {t("auth.password_recovery.email")}
          </label>
          <div className="input-container">
            <Input
              width="316px"
              height="46px"
              type="text"
              value={email}
              inputStyle={InputStyles.normal}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="ButtonWrapper">
          <Button
            width="316px"
            height="46px"
            type="submit"
            isDisabled={loading || !validEmail}
          >
            <div className="ButtonInner" style={{ fontWeight: 700 }}>
              {t("auth.password_recovery.button")}
              <ArrowRight className="icon" />
            </div>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PasswordRecovery;
