import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgVideo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.25A2.25 2.25 0 0 1 8.25 9h8.438a2.25 2.25 0 0 1 2.23 1.955l3.5-1.555A1.125 1.125 0 0 1 24 10.428v8.394a1.125 1.125 0 0 1-1.582 1.027l-3.5-1.554a2.25 2.25 0 0 1-2.23 1.955H8.25A2.25 2.25 0 0 1 6 18v-6.75Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgVideo;
