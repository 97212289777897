import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCopyPaperBold = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.333 15.667H4.667a2.5 2.5 0 0 1-2.5-2.5V4.833a.833.833 0 0 0-1.667 0v8.334a4.167 4.167 0 0 0 4.167 4.166h6.666a.833.833 0 0 0 0-1.666ZM15.5 6.45a1.09 1.09 0 0 0-.05-.225V6.15a.893.893 0 0 0-.158-.233l-5-5a.892.892 0 0 0-.234-.159h-.075L9.717.667H6.333a2.5 2.5 0 0 0-2.5 2.5V11.5a2.5 2.5 0 0 0 2.5 2.5H13a2.5 2.5 0 0 0 2.5-2.5V6.45Zm-5-2.942 2.158 2.159h-1.325a.833.833 0 0 1-.833-.834V3.508Zm3.333 7.992a.833.833 0 0 1-.833.833H6.333A.833.833 0 0 1 5.5 11.5V3.167a.833.833 0 0 1 .833-.834h2.5v2.5a2.5 2.5 0 0 0 2.5 2.5h2.5V11.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCopyPaperBold;
