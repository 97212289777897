import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  DashboardOwnerCountingResponse,
  RootState,
  StatsDataType,
} from "../../../utils/types";
import styles from "./DashboardOwner.module.scss";
import CardWidget from "../../../widgets/CardWidget/CardWidget";
import InteractionsWidget from "../../../widgets/InteractionsWidget/InteractionsWidget";
import StoresStatsPercentageWidget from "../../../widgets/StoresStatsPercentageWidget/StoresStatsPercentageWidget";
import TableWidget from "../../../widgets/TableWidget/TableWidget";
import {
  dashboardBroadcastCounting,
  dashboardOwnerAllStats,
  dashboardOwnerStatsByDate,
} from "../../../services/events";
import { convertDateUTC, timeZoneDate } from "../../../utils/handleDates";
import { first } from "lodash";
import { timeTextFormatHMS } from "../../../utils/timeTextFormatHMS";
import { ownerStoreList } from "../../../services/store";
import { FirStoresStatsResponse } from "../../../types/Response";
import { getOwnerSubscriptions } from "../../../services/user";
import toMaterialStyle from "material-color-hash";
import { useNavigate } from "react-router-dom";
import Button from "../../Button/Button";
import { setCurrentStoreActionCreator } from "../../../store/Brand/BrandActions";
import { FiraStore } from "../../../types/Stores";

interface storeTableBody {
  name: string;
  state: string;
  button: ReactElement | null;
}

const DashboardOwner = () => {
  const { authentication, utils, dashboard } = useSelector(
    (state: RootState) => state
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [dashboardCounting, setDashboardCounting] =
    useState<DashboardOwnerCountingResponse>();
  const [storesList, setStoresList] = useState<FiraStore[]>([]);
  const [subscription, setSubscription] = useState();
  const [dataLives, setDataLives] = useState<StatsDataType[]>([]);
  const [dataClicks, setDataClicks] = useState<StatsDataType[]>([]);
  const [dataAudience, setDataAudience] = useState<StatsDataType[]>([]);
  const [isLoadingCounting, setIsLoadingCounting] = useState(false);
  const [isLoadingStats, setIsloadingStats] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [allAudienceCount, setAllAudienceCount] = useState<string>("0");

  const getStatsByDay = async (
    id: string,
    firstDate: string,
    lastDate: string
  ) => {
    setIsLoadingCounting(true);
    try {
      const response = await dashboardOwnerStatsByDate(id, firstDate, lastDate);
      setDashboardCounting(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingCounting(false);
  };

  const redirectToStore = (store: FiraStore) => {
    navigate(`/home/stores/${store.id}`);
  };

  interface buttonProp {
    store: FiraStore;
  }
  const TableButton = ({ store }: buttonProp) => {
    return (
      <div
        onClick={() => redirectToStore(store)}
        className={styles.tablebutton}
      >
        <span>ver mas</span>
      </div>
    );
  };

  const parseStoresArray = (stores: FiraStore[]) => {
    let temparray: storeTableBody[] = [];

    stores.forEach((store, i) => {
      let tempObj = {
        name: store.name,
        state: t(`views.stores.status.${store.state.toLowerCase()}`),
        button: store.state === "ACTIVE" ? <TableButton store={store} /> : null,
      };
      temparray.push(tempObj);
    });

    return temparray;
  };

  const getOwnerStoreList = async () => {
    if (authentication.user?.businessClientId) {
      setIsLoadingList(true);
      try {
        const response = await ownerStoreList(
          authentication.user.businessClientId
        );

        setStoresList(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoadingList(false);
  };

  const getActiveSubscription = async (id: string) => {
    setIsloadingStats(true);
    try {
      const response = await getOwnerSubscriptions(id);
      setSubscription(response.data.subscriptionList[0]);
      getAllsubsStats(response.data.subscriptionList[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllsubsStats = async (subscription: string) => {
    try {
      const response = await dashboardOwnerAllStats(subscription);
      parsePieChartArray(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const parsePieChartArray = (arr: FirStoresStatsResponse[]) => {
    let livesCount: StatsDataType[] = [];
    let clicksCount: StatsDataType[] = [];
    let audienceCount: StatsDataType[] = [];
    arr.map((store: FirStoresStatsResponse) => {
      livesCount.push({
        name: store.name,
        id: store.id,
        value: store.actionStats.amountLive,
        color: toMaterialStyle(store.id, 400).backgroundColor,
      });
      clicksCount.push({
        name: store.name,
        id: store.id,
        value: store.actionStats.popupClicks + store.actionStats.regularClicks,
        color: toMaterialStyle(store.id, 400).backgroundColor,
      });
      audienceCount.push({
        name: store.name,
        id: store.id,
        value:
          store.actionStats.webConnections +
          store.actionStats.mobileConnections,
        color: toMaterialStyle(store.id, 400).backgroundColor,
      });
    });

    setDataLives(livesCount);
    setDataClicks(clicksCount);
    setDataAudience(audienceCount);
    setIsloadingStats(false);
  };

  useEffect(() => {
    if (
      dashboardCounting &&
      dashboardCounting.mobileConnections !== null &&
      dashboardCounting.webConnections !== null
    ) {
      setAllAudienceCount(
        String(
          dashboardCounting.mobileConnections + dashboardCounting.webConnections
        )
      );
    } else {
      setAllAudienceCount("0");
    }
  }, [dashboardCounting]);

  useEffect(() => {
    if (utils.screenWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [utils.screenWidth]);

  useEffect(() => {
    if (authentication.user) {
      getOwnerStoreList();
      if (
        dashboard.firstDate &&
        dashboard.lastDate &&
        authentication.user.businessClientId
      ) {
        getStatsByDay(
          authentication.user.businessClientId,
          dashboard.firstDate.toISOString(),
          dashboard.lastDate.toISOString()
        );
      }
    }
  }, [dashboard.firstDate, dashboard.lastDate]);

  useEffect(() => {
    if (authentication.user?.businessClientId) {
      getActiveSubscription(authentication.user.businessClientId);
    }
  }, [authentication]);

  const containerCards = (
    <div
      className={`${styles.countersWrapper} ${
        isMobile ? styles.mobileCounter : ""
      }`}
    >
      <CardWidget
        title={t("views.dashboardOwner.livesCount")}
        description={t("views.dashboardOwner.livesCount")}
        data={
          dashboardCounting && dashboardCounting.amountLive
            ? String(dashboardCounting.amountLive)
            : "0"
        }
        isLoading={isLoadingCounting}
      />
      <CardWidget
        title={t("views.dashboardOwner.livesTest")}
        description={t("views.dashboardOwner.livesTest")}
        data={
          dashboardCounting && dashboardCounting.amountTest
            ? String(dashboardCounting.amountTest)
            : "0"
        }
        isLoading={isLoadingCounting}
      />
      <CardWidget
        title={t("views.dashboardOwner.transmisionCount")}
        description={t("views.dashboardOwner.transmisionCount")}
        data={
          dashboardCounting && dashboardCounting.allConnectionTime
            ? timeTextFormatHMS(dashboardCounting.allConnectionTime) + ""
            : "0"
        }
        isLoading={isLoadingCounting}
      />

      <CardWidget
        title={t("views.dashboardOwner.audienceTotal")}
        description={t("views.dashboardOwner.audienceTotal")}
        data={allAudienceCount}
        isLoading={isLoadingCounting}
      />
    </div>
  );

  return (
    <div className={styles.dashboardContainer}>
      {containerCards}
      <StoresStatsPercentageWidget
        title={t("views.dashboardOwner.widgetStatsStoreTitle")}
        subTitle={t("views.dashboardOwner.widgetStatsStoreSubTitle")}
        isLoading={isLoadingStats}
        dataLive={dataLives}
        dataClicks={dataClicks}
        dataAudience={dataAudience}
      />
      <div
        className={`${
          isMobile ? styles.mobileContainer : styles.desktopContainer
        }`}
      >
        <div className={`${styles.desktopContainer__firstCol}`}>
          <TableWidget
            header={[
              { name: "", size: "5%" },
              { name: "Nombre", size: "30%" },
              { name: "Estado", size: "30%" },
              { name: "", size: "15%" },
            ]}
            bodyData={parseStoresArray(storesList)}
            title={t("views.dashboardOwner.tableWidgetTitle")}
            subTitle={t("views.dashboardOwner.tableWidgteSubTitle")}
            isLoading={isLoadingList}
            onClick={() => {}}
            rowAlignLeft={0}
            clickActive
            rowButton={false}
          />
        </div>
        <div className={`${styles.desktopContainer__secondCol}`}>
          <InteractionsWidget
            likes={dashboardCounting?.likes ? dashboardCounting?.likes : 0}
            clicks={
              dashboardCounting?.popupClicks && dashboardCounting.regularClicks
                ? dashboardCounting?.popupClicks +
                  dashboardCounting.regularClicks
                : 0
            }
            messages={
              dashboardCounting?.chatMessageCounting
                ? dashboardCounting?.chatMessageCounting
                : 0
            }
            shares={dashboardCounting?.shares ? dashboardCounting?.shares : 0}
            isLoading={isLoadingCounting}
            pieHeight={268}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardOwner;
