export enum AuthActions {
  loginLoading = "LOGIN_LOADING_ACTION",
  loginSuccess = "LOGIN_SUCCESS_ACTION",
  loginFailed = "LOGIN_FAILED_ACTION",

  registerLoading = "REGISTER_LOADING_ACTION",
  registerSuccess = "REGISTER_SUCCESS_ACTION",
  registerFailed = "REGISTER_FAILED_ACTION",
  activationLoading = "ACTIVATION_LOADING_ACTION",
  activationSuccess = "ACTIVATION_SUCCESS_ACTION",
  activationError = "ACTIVATION_ERROR_ACTION",
  resetRegister = "RESET_REGISTER",

  recoveryLoading = "RECOVERY_LOADING_ACTION",
  recoverySuccess = "RECOVERY_SUCCESS_ACTION",
  recoveryFailed = "RECOVERY_FAILED_ACTION",
  validateCodeLoading = "VALIDATE_CODE_LOADING_ACTION",
  validateCodeSuccess = "VALIDATE_CODE_SUCCESS_ACTION",
  validateCodeFailed = "VALIDATE_CODE_FAILED_ACTION",
  changePasswordLoading = "CHANGE_PASSWORD_LOADING_ACTION",
  changePasswordSuccess = "CHANGE_PASSWORD_SUCCESS_ACTION",
  changePasswordFailed = "CHANGE_PASSWORD_FAILED_ACTION",

  resetAuthAlert = "RESET_AUTH_ALERT_ACTION",

  refreshTokenLoading = "REFRESH_TOKEN_LOADING_ACTION",
  refreshTokenSuccess = "REFRESH_TOKEN_SUCCESS_ACTION",
  refreshTokenFailed = "REFRESH_TOKEN_FAILED_ACTION",
  setStatusBusinessRedirection = "SET_IS_BUSINESS_REDIRECTION",
  logout = "LOGOUT_ACTION",

  getAccountOwnerInfoLoading = "GET_ACCOUNT_OWNER_INFO_LOADING_ACTION",
  getAccountOwnerInfoSuccess = "GET_ACCOUNT_OWNER_INFO_SUCCESS_ACTION",
  getAccountOwnerInfoFailed = "GET_ACCOUNT_OWNER_INFO_FAILED_ACTION",
}

export enum BrandActions {
  getStoreLoading = "GET_STORES_LOADING_ACTION",

  getUserStoresLoading = "GET_USER_STORES_LOADING_ACTION",
  getUserStoresSuccess = "GET_USER_STORES_SUCCESS_ACTION",
  getUserStoresFailed = "GET_USER_STORES_FAILED_ACTION",

  setIsNewUser = "SET_IS_NEW_USER_ACTION",

  setCurrentStore = "SET_CURRENT_STORE_ACTION",
  updateCurrentStore = "UPDATE_CURRENT_STORE_ACTION",

  createStoreLoading = "CREATE_STORE_LOADING_ACTION",
  createStoreSuccess = "CREATE_STORE_SUCCESS_ACTION",
  createStoreFailed = "CREATE_STORE_FAILED_ACTION",

  editStoreProfileLoading = "EDIT_STORE_PROFILE_LOADING_ACTION",
  editStoreProfileSuccess = "EDIT_STORE_PROFILE_SUCCESS_ACTION",
  editStoreProfileFailed = "EDIT_STORE_PROFILE_FAILED_ACTION",

  resetEditStoreMessage = " RESET_EDIT_STORE_MESSAGE_ACTION",
  resetCreateStoreMessage = " RESET_CREATE_STORE_MESSAGE_ACTION",
  resetCurrentStore = "RESET_CURRENT_STORE_ACTION",

  setSyncStoreCode = "SET_SYNC_STORE_CODE_ACTION",

  updateCurrentEcommerce = "UPDATE_CURRENT_STORE_ECOMMERCE",

  getOwnerStoresLoading = "GET_OWNER_STORES_LOADING_ACTION",
  getOwnerStoresSuccess = "GET_OWNER_STORES_SUCCESS_ACTION",
  getOwnerStoresFailed = "GET_OWNER_STORES_FAILED_ACTION",

  setCurrentOwnerStore = "SET_CURRENT_OWNER_STORE_ACTION",
  resetCurrentOwnerStore = "RESET_CURRENT_OWNER_STORE_ACTION",

  updateOwnerStoreLoading = "UPDATE_OWNER_STORE_LOADING_ACTION",
  updateOwnerStoreSuccess = "UPDATE_OWNER_STORE_SUCCESS_ACTION",
  updateOwnerStoreFailed = "UPDATE_OWNER_STORE_FAILED_ACTION",

  createOwnerStoreLoading = "CREATE_OWNER_STORE_LOADING_ACTION",
  createSOwnertoreSuccess = "CREATE_OWNER_STORE_SUCCESS_ACTION",
  createOwnerStoreFailed = "CREATE_OWNER_STORE_FAILED_ACTION",

  cleanSuccessMessage = "CLEAN_SUCCESS_MESSAGE_ACTION",

  removeCurrentStore = "REMOVE_CURRENT_STORE",
}

export enum UtilActions {
  setNavbarState = "SET_NAVBAR_STATE_ACTION",

  currentScreenWidth = "CURRENT_SCREEN_WIDTH_ACTION",

  setSettingsModalState = "SET_SETTINGS_MODAL_STATE_ACTION",

  setPrevPageState = "SET_PREV_PAGE_STATE",
}

export enum ProductsActions {
  getStoreProductsLoading = "GET_STORE_PRODUCTS_LOADING_ACTION",
  getStoreProductsSuccess = "GET_STORE_PRODUCTS_SUCCESS_ACTION",
  getStoreProductsFailed = "GET_STORE_PRODUCTS_FAILED_ACTION",

  deleteProductsLoading = "DELETE_PRODUCTS_LOADING_ACTION",
  deleteProductsSuccess = "DELETE_PRODUCTS_SUCCESS_ACTION",
  deleteProductsFailed = "DELETE_PRODUCTS_FAILED_ACTION",

  createProductLoading = "CREATE_PRODUCT_LOADING_ACTION",
  createProductSuccess = "CREATE_PRODUCT_SUCCESS_ACTION",
  createProductFailed = "CREATE_PRODUCT_FAILED_ACTION",

  updateProductLoading = "UPDATE_PRODUCT_LOADING_ACTION",
  updateProductSuccess = "UPDATE_PRODUCT_SUCCESS_ACTION",
  updateProductFailed = "UPDATE_PRODUCT_FAILED_ACTION",

  updateProductInfoLoading = "UPDATE_PRODUCT_INFO_LOADING_ACTION",
  updateProductInfoSuccess = "UPDATE_PRODUCT_INFO_SUCCESS_ACTION",
  updateProductInfoFailed = "UPDATE_PRODUCT_INFO_FAILED_ACTION",

  updateImagesProductLoading = "ADD_IMAGES_PRODUCT_LOADING_ACTION",
  updateImagesProductSuccess = "ADD_IMAGES_PRODUCT_SUCCESS_ACTION",
  updateImagesProductFailed = "ADD_IMAGES_PRODUCT_FAILED_ACTION",
  updateImagesProductReset = "RESET_IMAGES_PRODUCT_ACTION",

  setCurrentProduct = "SET_CURRENT_PRODUCT_ACTION",

  resetCurrentProduct = "RESET_CURRENT_PRODUCT_ACTION",
}

export enum LanguagesActions {
  getLanguagesSuccess = "GET_LANGUAGES_SUCCESS_ACTION",
  getLanguagesFailed = "GET_LANGUAGES_FAILED_ACTION",
}

export enum CountriesActions {
  getCountriesLoading = "GET_COUNTRIES_LOADING_ACTION",
  getCountriesSuccess = "GET_COUNTRIES_SUCCESS_ACTION",
  getCountriesFailed = "GET_COUNTRIES_FAILED_ACTION",
}

export enum CurrenciesActions {
  getCurrenciesSuccess = "GET_CURRENCIES_SUCCESS_ACTION",
  getCurrenciesFailed = "GET_CURRENCIES_FAILED_ACTION",
}
export enum UserActions {
  getUserLoading = "GET_USER_INFO_LOADING_ACTION",
  getUserSuccess = "GET_USER_INFO_SUCCESS_ACTION",
  getUserFailed = "GET_USER_INFO_FAILED_ACTION",

  updateUserLoading = "PUT_USER_INFO_LOADING_ACTION",
  updateUserSuccess = "PUT_USER_INFO_SUCCESS_ACTION",
  updateUserFailed = "PUT_USER_INFO_FAILED_ACTION",

  getUsersLoading = "GET_USERS_LOADING_ACTION",
  getUsersSuccess = "GET_USERS_SUCCESS_ACTION",
  getUsersFailed = "GET_USERS_FAILED_ACTION",

  createUsersPermissionsLoading = "CREATE_USERS_PERMISSIONS_LOADING_ACTION",
  createUsersPermissionsSuccess = "CREATE_USERS_PERMISSIONS_SUCCESS_ACTION",
  createUsersPermissionsFailed = "CREATE_USERS_PERMISSIONS_FAILED_ACTION",

  sendInvitationPermissionsLoading = "SEND_INVITATION_LOADING_ACTION",
  sendInvitationPermissionsSuccess = "SEND_INVITATION_SUCCESS_ACTION",
  sendInvitationPermissionsFailed = "SEND_INVITATION_FAILED_ACTION",

  //DEPRECATED
  updateUsersPermissionsLoading = "UPDATE_USERS_PERMISSIONS_LOADING_ACTION",
  updateUsersPermissionsSuccess = "UPDATE_USERS_PERMISSIONS_SUCCESS_ACTION",
  updateUsersPermissionsFailed = "UPDATE_USERS_PERMISSIONS_FAILED_ACTION",
  //DEPRECATED

  updateUserStatusLoading = "UPDATE_USERS_STATUS_LOADING_ACTION",
  updateUsersStatusSuccess = "UPDATE_USERS_STATUS_SUCCESS_ACTION",
  updateUsersStatusFailed = "UPDATE_USERS_STATUS_FAILED_ACTION",

  cleanMessage = "CLEAN_MESSAGE_ACTION",

  getInvitationListLoading = "GET_INVITATION_LIST_LOADING",
  getInvitationListSuccess = "GET_INVITATION_LIST_SUCCESS",
  getInvitationListError = "GET_INVITATION_LIST_ERROR",

  acceptInvitationLoading = "POST_ACCEPT_INVITATION_LOADING",
  acceptInvitationSuccess = "POST_ACCEPT_INVITATION_SUCCESS",
  acceptInvitationError = "POST_ACCEPT_INVITATION_ERROR",

  rejectInvitationLoading = "POST_REJECT_INVITATION_LOADING",
  rejectInvitationSuccess = "POST_REJECT_INVITATION_SUCCESS",
  rejectInvitationError = "POST_REJECT_INVITATION_ERROR",

  getModeratorsLoading = "GET_MODERATORS_LIST_LOADING",
  getModeratorsSuccess = "GET_MODERATORS_LIST_SUCCESS",
  getModeratorsFailed = "GET_MODERATORS_LIST_ERROR",

  /** Move to Team Actions */
  updateUserRoleLoading = "UPDATE_USER_ROLE_LOADING_ACTION",
  updateUserRoleSuccess = "UPDATE_USER_ROLE_SUCCESS_ACTION",
  updateUserRoleFailed = "UPDATE_USER_ROLE_FAILED_ACTION",

  updateUserPermissionsLoading = "UPDATE_USER_PERMISSIONS_LOADING_ACTION",
  updateUserPermissionsSuccess = "UPDATE_USER_PERMISSIONS_SUCCESS_ACTION",
  updateUserPermissionsFailed = "UPDATE_USER_PERMISSIONS_FAILED_ACTION",

  cleanUserPermissionsMessage = "CLEAN_USER_PERMISSIONS",
  /** Move to Team Actions */
}

export enum EventActions {
  getStoreEventsLoading = "GET_STORE_EVENTS_LOADING_ACTION",
  getStoreEventsSuccess = "GET_STORE_EVENTS_SUCCESS_ACTION",
  getStoreEventsFailed = "GET_STORE_EVENTS_FAILED_ACTION",

  getEventLoading = "GET_EVENT_LOADING_ACTION",
  getEventSuccess = "GET_EVENT_SUCCESS_ACTION",
  getEventFailed = "GET_EVENT_FAILED_ACTION",

  generateCredentialsLoading = "GENERATE_CREDENTIALS_LOADING_ACTION",
  generateCredentialsSuccess = "GENERATE_CREDENTIALS_SUCCESS_ACTION",
  generateCredentialsFailed = "GENERATE_CREDENTIALS_FAILED_ACTION",

  updateCredentialsLoading = "UPDATE_CREDENTIALS_LOADING_ACTION",
  updateCredentialsSuccess = "UPDATE_CREDENTIALS_SUCCESS_ACTION",
  updateCredentialsFailed = "UPDATE_CREDENTIALS_FAILED_ACTION",

  createEventSuccess = "CREATE_EVENT_SUCCESS_ACTION",
  createEventLoading = "CREATE_EVENT_LOADING_ACTION",
  createEventFailed = "CREATE_EVENT_FAILED_ACTION",

  resetEventMessage = "RESET_EVENT_MESSAGE_ACTION",

  getFiraWebConfigurationLoading = "GET_FIRA_WEB_CONFIG_LOADING_ACTION",
  getFiraWebConfigurationSuccess = "GET_FIRA_WEB_CONFIG_SUCCESS_ACTION",
  getFiraWebConfigurationFailed = "GET_FIRA_WEB_CONFIG_FAILED_ACTION",

  getStreamBlocksLoading = "GET_STREAM_BLOCKS_LOADING_ACTION",
  getStreamBlocksSuccess = "GET_STREAM_BLOCKS_SUCCESS_ACTION",
  getStreamBlocksFailed = "GET_STREAM_BLOCKS_FAILED_ACTION",

  setCurrentEvent = "SET_CURRENT_EVENT_ACTION",
  resetCurrentEvent = "RESET_CURRENT_EVENT_ACTION",
  setStoresListEvent = "SET_STORES_LIST_EVENTACTION", //action para eventos globales

  putCancelEventLoading = "PUT_CANCEL_EVENT_LOADING_ACTION",
  putCancelEventSuccess = "PUT_CANCEL_EVENT_SUCCESS_ACTION",
  putCancelEventFailed = "PUT_CANCEL_EVENT_FAILED_ACTION",

  updateEventLoading = "UPDATE_EVENT_LOADING_ACTION",
  updateEventSuccess = "UPDATE_EVENT_SUCCESS_ACTION",
  updateEventFailed = "UPDATE_EVENT_FAILED_ACTION",
  updateEventReset = "UPDATE_EVENT_RESET_ACTION",

  startEventLoading = "START_EVENT_LOADING_ACTION",
  startEventSuccess = "START_EVENT_SUCCESS_ACTION",
  startEventFailed = "START_EVENT_FAILED_ACTION",

  finishEventLoading = "FINISH_EVENT_LOADING_ACTION",
  finishEventSuccess = "FINISH_EVENT_SUCCESS_ACTION",
  finishEventFailed = "FINISH_EVENT_FAILED_ACTION",

  cleanMessage = "CLEAN_MESSAGE_ACTION",

  getCurrentEventProductsLoading = "GET_CURRENT_EVENT_PRODUCTS_LOADING",
  getCurrentEventProductsSuccess = "GET_CURRENT_EVENT_PRODUCTS_SUCCESS",
  getCurrentEventProductsFailed = "GET_CURRENT_EVENT_PRODUCT_FAILED",

  addCurrentEventProductsLoading = "ADD_CURRENT_EVENT_PRODUCTS_LOADING",
  addCurrentEventProductsSuccess = "ADD_CURRENT_EVENT_PRODUCTS_SUCCESS",
  addCurrentEventProductsFailed = "ADD_CURRENT_EVENT_PRODUCTS_FAILED",

  reactiveCurrentEventProductsLoading = "REACTIVE_CURRENT_EVENT_PRODUCTS_LOADING",
  reactiveCurrentEventProductsSuccess = "REACTIVE_CURRENT_EVENT_PRODUCTS_SUCCESS",
  reactiveCurrentEventProductsFailed = "REACTIVE_CURRENT_EVENT_PRODUCTS_FAILED",

  deleteCurrentEventProductsLoading = "DELETE_CURRENT_EVENT_PRODUCTS_LOADING",
  deleteCurrentEventProductsSuccess = "DELETE_CURRENT_EVENT_PRODUCTS_SUCCESS",
  deleteCurrentEventProductsFailed = "DELETE_CURRENT_EVENT_PRODUCTS_FAILED",

  sendPopUpLoading = "SEND_POPUP_LOADING",
  sendPopUpSuccess = "SEND_POPUP_SUCCESS",
  sendPopUpFailed = "SEND_POPUP_FAILED",

  deletePopUpLoading = "DELETE_POPUP_LOADING",
  deletePopUpSuccess = "DELETE_POPUP_SUCCESS",
  deletePopUpFailed = "DELETE_POPUP_FAILED",

  editEventLoading = "EDIT_EVENT_LOADING_ACTION",
  editEventSuccess = "EDIT_EVENT_SUCCESS_ACTION",
  editEventFailed = "EDIT_EVENT_FAILED_ACTION",
  editEventReset = "EDIT_EVENT_RESET_ACTION",

  getInactiveProductsLoading = "GET_INACTIVE_PRODUCTS_LOADING",
  getInactiveProductsSuccess = "GET_INACTIVE_PRODUCTS_SUCCESS",
  getInactiveProductsFailed = "GET_INACTIVE_PRODUCTS_FAILED",

  getEventQueuesLoading = "GET_EVENT_QUEUES_LOADING_ACTION",
  getEventQueuesSuccess = "GET_EVENT_QUEUES_SUCCESS_ACTION",
  getEventQueuesFailed = "GET_EVENT_QUEUES_FAILED_ACTION",

  updateStatsLoading = "UPDATE_STATS_LOADING_ACTION",
  updateStatsSuccess = "UPDATE_STATS_SUCCESS_ACTION",
  updateStatsFailed = "UPDATE_STATS_FAILED_ACTION",

  getEventCategoriesLoading = "GET_EVENT_CATEGORIES_LOADING_ACTION",
  getEventCategoriesSuccess = "GET_EVENT_CATEGORIES_SUCCESS_ACTION",
  getEventCategoriesFailed = "GET_EVENT_CATEGORIES_FAILED_ACTION",

  testEventTimerFinish = "TEST_EVENT_TIMER_FINISH_ACTION",

  getEventTemplatesLoading = "GET_EVENT_TEMPLATES_LOADING_ACTION",
  getEventTemplatesSuccess = "GET_EVENT_TEMPLATES_SUCCESS_ACTION",
  getEventTemplateFailed = "GET_EVENT_TEMPLATES_FAILED_ACTION",

  setUpEventLoading = "FIRST_SET_UP_EVENT_LOADING_ACTION",
  setUpEventSuccess = "FIRST_SET_UP_EVENT_SUCCESS_ACTION",
  setUpEventFailed = "FIRST_SET_UP_EVENT_FAILED_ACTION",

  resetCurrentEventProducts = "RESET_CURRENT_EVENT_PRODUCTS_ACTION",
}

export enum DashboardActions {
  setDateRange = "SET_DATE_RANGE_ACTION",

  getDashboardCountingLoading = "GET_DASHBOARD_COUTING_LOADING_ACTION",
  getDashboardCountingSuccess = "GET_DASHBOARD_COUTING_SUCCESS_ACTION",
  getDashboardCountingFailed = "GET_DASHBOARD_COUTING_FAILED_ACTION",
  setDashboardCountingReset = "SET_DASHBOARD_COUNTING_RESET",
  getDashboardNextEventLoading = "GET_DASHBOARD_NEXT_EVENT_LOADING_ACTION",
  getDashboardNextEventSuccess = "GET_DASHBOARD_NEXT_EVENT_SUCCESS_ACTION",
  getDashboardNextEventFailed = "GET_DASHBOARD_NEXT_EVENT_FAILED_ACTION",

  getDashboardEventListLoading = "GET_DASHBOARD_EVENT_LIST_LOADING_ACTION",
  getDashboardEventListSuccess = "GET_DASHBOARD_EVENT_LIST_SUCCESS_ACTION",
  getDashboardEventListFailed = "GET_DASHBOARD_EVENT_LIST_FAILED_ACTION",
}

export enum VideoActions {
  getVideosLoading = "GET_VIDEOS_LODING_ACTION",
  getVideosSuccess = "GET_VIDEOS_SUCCESS_ACTION",
  getVideosFailed = "GET_VIDEOS_FAILED_ACTION",

  resetVideoList = "RESET_VIDEO_LIST_ACTION",

  getVideosByLoading = "GET_VIDEOS_BY_LODING_ACTION",
  getVideosBySuccess = "GET_VIDEOS_BY_SUCCESS_ACTION",
  getVideosByFailed = "GET_VIDEOS_BY_FAILED_ACTION",

  setCurrentVideo = "SET_CURRENT_VIDEO_ACTION",
  resetCurrentVideo = "RESET_CURRENT_VIDEO_ACTION",

  updateVisibilityLoading = "UPDATE_VIDEO_VISIBILITY_LOADING_ACTION",
  updateVisibilitySuccess = "UPDATE_VIDEO_VISIBILITY_SUCCESS_ACTION",
  updateVisibilityFailed = "UPDATE_VIDEO_VISIBILITY_FAILED_ACTION",

  getAvailableVideosLoading = "GET_AVAILABLE_VIDEOS_LOADING_ACTION",
  getAvailableVideosSuccess = "GET_AVAILABLE_VIDEOS_SUCCESS_ACTION",
  getAvailableVideosFailed = "GET_AVAILABLE_VIDEOS_FAILED",

  resetAvailableVideosList = "RESET_CURRENT_AVAILABLE_VIDEOS_LIST",

  updateVideoProductStateLoading = "UPDATE_VIDEO_PRODUCT_STATE_LOADING_ACTION",
  updateVideoProductStateSuccess = "UPDATE_VIDEO_PRODUCT_STATE_SUCCESS_ACTION",
  updateVideoProductStateFailed = "UPDATE_VIDEO_PRODUCT_STATE_FAILED_ACTION",

  updateVideoProductInfoLoading = "UPDATE_VIDEO_PRODUCT_INFO_LOADING_ACTION",
  updateVideoProductInfoSuccess = "UPDATE_VIDEO_PRODUCT_INFO_SUCCESS_ACTION",
  updateVideoProductInfoFailed = "UPDATE_VIDEO_PRODUCT_INFO_FAILED_ACTION",

  updateAllPricesVisibilityLoading = "UPDATE_ALL_PRICES_VISIBILITY_LOADING_ACTION",
  updateAllPricesVisibilitySuccess = "UPDATE_ALL_PRICES_VISIBILITY_SUCCESS_ACTION",
  updateAllPricesVisibilityFailed = "UPDATE_ALL_PRICES_VISIBILITY_FAILED_ACTION",

  updateVideoGeneralInfoLoading = "UPDATE_VIDEO_GENERAL_INFO_LOADING_ACTION",
  updateVideoGeneralInfoSuccess = "UPDATE_VIDEO_GENERAL_INFO_SUCCESS_ACTION",
  updateVideoGeneralInfoFailed = "UPDATE_VIDEO_GENERAL_INFO_FAILED_ACTION",

  updateVideoPreviewImageLoading = "UPDATE_VIDEO_PREVIEW_IMAGE_LOADING_ACTION",
  updateVideoPreviewImageSuccess = "UPDATE_VIDEO_PREVIEW_IMAGE_SUCCESS_ACTION",
  updateVideoPreviewImageFailed = "UPDATE_VIDEO_PREVIEW_IMAGE_FAILED_ACTION",

  updateVideoTeaserLoading = "UPDATE_VIDEO_TEASER_LOADING_ACTION",
  updateVideoTeaserSuccess = "UPDATE_VIDEO_TEASER_SUCCESS_ACTION",
  updateVideoTeaserFailed = "UPDATE_VIDEO_TEASER_FAILED_ACTION",

  addVideoCategoryLoading = "ADD_VIDEO_CATEGORY_LOADING_ACTION",
  addVideoCategorySuccess = "ADD_VIDEO_CATEGORY_SUCCESS_ACTION",
  addVideoCategoryFailed = "ADD_VIDEO_CATEGORY_FAILED_ACTION",

  deleteVideoCategoryLoading = "DELETE_VIDEO_CATEGORY_LOADING_ACTION",
  deleteVideoCategorySuccess = "DELETE_VIDEO_CATEGORY_SUCCESS_ACTION",
  deleteVideoCategoryFailed = "DELETE_VIDEO_CATEGORY_FAILED_ACTION",

  getVideoProductsStatsLoading = "GET_VIDEO_PRODUCTS_STATS_LOADING_ACTION",
  getVideoProductsStatsSuccess = "GET_VIDEO_PRODUCTS_STATS_SUCCESS_ACTION",
  getVideoProductsStatsFailed = "GET_VIDEO_PRODUCTS_STATS_FAILED_ACTION",

  getVideoCriteriaLoading = "GET_VIDEO_CRITERIA_LOADING_ACTION",
  getVideoCriteriaSuccess = "GET_VIDEO_CRITERIA_SUCCESS_ACTION",
  getVideoCriteriaFailed = "GET_VIDEO_CRITERIA_FAILED_ACTION",

  getVideoStatsLoading = "GET_VIDEO_STATS_LOADING_ACTION",
  getVideoStatsSuccess = "GET_VIDEO_STATS_SUCCESS_ACTION",
  getVideoStatsFailed = "GET_VIDEO_STATS_FAILED_ACTION",

  resetVideoStats = "RESET_VIDEO_STATS_ACTION",

  replaceVideoFileLoading = "REPLACE_VIDEO_FILE_LOADING_ACTION",
  replaceVideoFileSuccess = "REPLACE_VIDEO_FILE_SUCCESS_ACTION",
  replaceVideoFileFailed = "REPLACE_VIDEO_FILE_FAILED_ACTION",
}

export enum CollectionActions {
  getCollectionsLoading = "GET_COLLECTIONS_LOADING_ACTION",
  getCollectionsSuccess = "GET_COLLECTIONS_SUCCESS_ACTION",
  getCollectionsFailed = "GET_COLLECTIONS_FAILED_ACTION",

  resetCollectionList = "RESET_COLLECTION_LIST",

  setCurrentCollection = "SET_CURRENT_COLLECTION_ACTION",
  resetCurrentCollection = "RESET_CURRENT_COLLECTION",

  createCollectionLoading = "CREATE_COLLECTION_LOADING_ACTION",
  createCollectionSuccess = "CREATE_COLLECTION_SUCCESS_ACTION",
  createCollectionFailed = "CREATE_COLLECTION_FAILED_ACTION",

  editCollectionLoading = "EDIT_COLLECTION_LOADING_ACTION",
  editCollectionSuccess = "EDIT_COLLECTION_SUCCESS_ACTION",
  editCollectionFailed = "EDIT_COLLECTION_FAILED_ACTION",

  deleteCollectionLoading = "DELETE_COLLECTION_LOADING_ACTION",
  deleteCollectionSuccess = "DELETE_COLLECTION_SUCCESS_ACTION",
  deleteCollectionFailed = "DELETE_COLLECTION_FAILED_ACTION",

  sortCollectionVideosByLoading = "SORT_COLLECTION_VIDEOS_LOADING_ACTION",
  sortCollectionVideosBySuccess = "SORT_COLLECTION_VIDEOS_SUCCESS_ACTION",
  sortCollectionVideosByFailed = "SORT_COLLECTION_VIDEOS_FAILED_ACTION",
}

export enum OwnerTeamActions {
  getTeamMemberLoading = "GET_TEAM_MEMBER_LOADING_ACTION",
  getTeamMemberSuccess = "GET_TEAM_MEMBER_SUCCESS_ACTION",
  getTeamMemberFailed = "GET_TEAM_MEMBER_FAILED_ACTION",

  setCurrentTeamMemberLoading = "SET_TEAM_MEMBER_LOADING_ACTION",
  setCurrentTeamMemberSuccess = "SET_TEAM_MEMBER_SUCCESS_ACTION",
  setCurrentTeamMemberFailed = "SET_TEAM_MEMBER_FAILED_ACTION",

  getAllUsersLoading = "GET_ALL_USERS_LOADING_ACTION",
  getAllUsersSuccess = "GET_ALL_USERS_SUCCESS_ACTION",
  getAllUsersFailed = "GET_ALL_USERS_FAILED_ACTION",

  updateUserStatusLoading = "UPDATE_USERS_STATUS_LOADING_ACTION",
  updateUsersStatusSuccess = "UPDATE_USERS_STATUS_SUCCESS_ACTION",
  updateUsersStatusFailed = "UPDATE_USERS_STATUS_FAILED_ACTION",

  resetUsers = "RESET_USERS_ACTION",
  resetCurrentUser = "RESET_CURRENT_USER_ACTION",
}

export enum MultiEventActions {
  createMultiEventLoading = "CREATE_MULTI_EVENT_LOADING_ACTION",
  createMultiEventSuccess = "CREATE_MULTI_EVENT_SUCCESS_ACTION",
  createMultiEventFailed = "CREATE_MULTI_EVENT_FAILED_ACTION",

  cleanMultiSuccessMessage = "CLEAN_MULTI_SUCCESS_MESSAGE_ACTION",
}

export enum LandingActions {
  createLandingLoading = "CREATE_LANDING_LOADING_ACTION",
  createLandingSuccess = "CREATE_LANDING_SUCCESS_ACTION",
  createLandingFailed = "CREATE_LANDING_FAILED_ACTION",

  updateLandingLoading = "UPDATE_LANDING_LOADING_ACTION",
  updateLandingSuccess = "UPDATE_LANDING_SUCCESS_ACTION",
  updateLandingFailed = "UPDATE_LANDING_FAILED_ACTION",

  cleanLandingMessage = "CLEAN_LANDING_MESSAGE_ACTION",
}
