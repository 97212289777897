import React from "react";

function SvgStores() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        d="M19.637 7.706L17.95 4.47c-.15-.29-.418-.469-.704-.469H6.752c-.286 0-.553.178-.704.469L4.362 7.706c-.87 1.672-.099 3.997 1.528 4.263.117.019.236.028.356.028.768 0 1.45-.406 1.917-1.034.467.628 1.15 1.034 1.917 1.034.769 0 1.45-.406 1.917-1.034.467.628 1.15 1.034 1.917 1.034.769 0 1.45-.406 1.917-1.034.47.628 1.15 1.034 1.917 1.034.122 0 .24-.01.356-.028 1.631-.263 2.406-2.588 1.533-4.263zM17.753 13c-.26 0-.517-.047-.766-.119V16H7.012v-3.119c-.25.069-.507.119-.766.119-.156 0-.315-.012-.468-.037a2.798 2.798 0 01-.426-.113V19c0 .553.371 1 .831 1h11.638c.46 0 .831-.447.831-1v-6.15c-.14.05-.28.09-.426.113a3.013 3.013 0 01-.473.037z"
      ></path>
    </svg>
  );
}

export default SvgStores;
