import React from "react";

function Bell() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      fill="none"
      viewBox="0 0 16 20"
    >
      <path
        fill="#fff"
        d="M8 0a1 1 0 011 1v2l-.001.032-.001.021-.002.03A6.002 6.002 0 0113.999 9c0 3.093.625 4.312 1.599 6.21l.035.068c.17.33-.071.722-.443.722H.808a.496.496 0 01-.442-.722l.035-.068c.973-1.898 1.598-3.117 1.598-6.21a6.002 6.002 0 015.004-5.918L7 3.042A.823.823 0 016.999 3V1a1 1 0 011-1zM10 18a2 2 0 01-4 0h4z"
      ></path>
    </svg>
  );
}

export default Bell;
