import React, { useEffect, useRef, useState } from "react";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { Icon } from "@iconify/react";

import { useChatContext } from "../../providers/ChatProvider";
import stylesheet from "./InputMessage.module.scss";
import {
	restoreCaretPosition,
	saveCaretPosition,
} from "../../utils/caretUtils";
import { getTextColor } from "../../utils/colorUtils";
import useScreenWidth from "../../hooks/useScreenWidth";
import { formatNumber } from "../../utils/numberUtils";
import {useTranslation} from "react-i18next";

const {
	InputWrapper,
	TextInput,
	Placeholder,
	CharCounter,
	CharCounterOverLimit,
	EmojiPickerWrapper,
	ChatInputControls,
	EmojiToggleButton,
	Button,
	IconWrapper,
	TextWrapper,
	alwaysShow,
	MobileInputControls,
	LikeButton,
	Text,
} = stylesheet;

const InputMessage: React.FC = () => {
	const { t } = useTranslation();
	const { sendNewMessage, status, getChatConfig } = useChatContext();
	const textareaRef = useRef<HTMLDivElement>(null);
	const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
	const hideButtonTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const emojiPickerRef = useRef<HTMLDivElement>(null);
	const [isEmpty, setIsEmpty] = useState(true);
	const [isFocused, setIsFocused] = useState(false);
	const [charCount, setCharCount] = useState(0);
	const [isOverLimit, setIsOverLimit] = useState(false);
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);

	const screenWidth = useScreenWidth();

	const { mainColor } = getChatConfig();

	const debouncedHandleInput = () => {
		if (debounceTimeout.current) {
			clearTimeout(debounceTimeout.current);
		}

		debounceTimeout.current = setTimeout(() => {
			handleInput();
		}, 100); // 300ms delay
	};

	const onSendButtonPressed = (event: React.MouseEvent) => {
		event.preventDefault();
		handleSendMessage();
	};

	const handleSendMessage = async () => {
		if (textareaRef.current) {
			const content = textareaRef.current.innerText;
			if (charCount > 200) return;

			const trimmedMessage = content.trim();
			if (trimmedMessage !== "") {
				const response = await sendNewMessage(trimmedMessage);

				if (response) {
					textareaRef.current.innerHTML = "";
					setIsEmpty(true);
					setCharCount(0);
				} else {
					console.error("FIRA CHAT: error sending message, try again.");
				}
			}
		}
	};

	const handleKeyDown = async (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleSendMessage();
		}
	};

	const handleInput = () => {
		if (textareaRef.current) {
			const content = textareaRef.current.innerText;
			setIsEmpty(textareaRef.current.innerHTML.trim() === "");
			setCharCount(content.length);
			setIsOverLimit(content.length > 200);
		}
	};

	const handlePaste = (e: React.ClipboardEvent) => {
		e.preventDefault(); // Prevent the default paste behavior

		const text = e.clipboardData.getData("text/plain");
		const existingContent = textareaRef.current
			? textareaRef.current.innerText
			: "";
		const totalLength = existingContent.length + text.length;

		setIsOverLimit(totalLength > 200);

		const selection = document.getSelection();
		if (selection && textareaRef.current) {
			const range = selection.getRangeAt(0);

			// Delete the selected text (if any)
			range.deleteContents();

			// Create a new Text node for the pasted plain text
			const textNode = document.createTextNode(text);

			// Insert the text node at the caret position
			range.insertNode(textNode);

			// Move the caret to the end of the new text node
			range.setStartAfter(textNode);
			range.setEndAfter(textNode);
			selection.removeAllRanges();
			selection.addRange(range);

			debouncedHandleInput();
		}
	};

	const onEmojiClick = (emojiObject: EmojiClickData) => {
		const emoji = emojiObject.emoji;

		if (textareaRef.current) {
			textareaRef.current.focus();
			restoreCaretPosition(textareaRef.current);

			const selection = document.getSelection();
			if (selection) {
				const range = selection.getRangeAt(0);
				range.deleteContents();
				const textNode = document.createTextNode(emoji);
				range.insertNode(textNode);
				range.setStartAfter(textNode);
				range.setEndAfter(textNode);
				selection.removeAllRanges();
				selection.addRange(range);
				handleInput();
			}
		}
	};

	const toggleEmojiPicker = () => {
		setShowEmojiPicker(!showEmojiPicker);

		if (textareaRef.current) {
			textareaRef.current.focus();
		}
	};

	const handleInputFocus = () => {
		if (hideButtonTimeoutRef.current) {
			clearTimeout(hideButtonTimeoutRef.current);
			hideButtonTimeoutRef.current = null;
		}

		setIsFocused(true);
	};

	const handleInputBlur = () => {
		hideButtonTimeoutRef.current = setTimeout(() => {
			setIsFocused(false);
		}, 150);

		if (textareaRef.current) saveCaretPosition(textareaRef.current);
	};

	const focusStyles = isFocused
		? {
				outline: `2px solid ${isOverLimit ? "red" : mainColor}`,
				borderRadius: "8px",
		  }
		: {};

	const charCounterClassNames = `${CharCounter} ${
		isFocused ? alwaysShow : ""
	} ${isOverLimit ? CharCounterOverLimit : ""}`;

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (
				emojiPickerRef.current &&
				!emojiPickerRef.current.contains(event.target as Node)
			) {
				setShowEmojiPicker(false); // Hide picker if clicked outside
			}
		};

		// Add event listener when component mounts
		document.addEventListener("mousedown", handleClickOutside);

		// Cleanup event listener on component unmount
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [emojiPickerRef]);

	return (
		<div className={InputWrapper}>
			<div
				ref={textareaRef}
				className={TextInput}
				contentEditable
				role="textbox"
				aria-multiline="true"
				onKeyDown={handleKeyDown}
				onInput={handleInput}
				onFocus={handleInputFocus}
				onBlur={handleInputBlur}
				style={focusStyles}
				onPaste={handlePaste}
			></div>
			{isEmpty && <span className={Placeholder}>{t("components.chat.message")}</span>}
			{showEmojiPicker && (
				<div ref={emojiPickerRef} className={EmojiPickerWrapper}>
					<EmojiPicker
						previewConfig={{ showPreview: false }}
						onEmojiClick={onEmojiClick}
					/>
				</div>
			)}

			{screenWidth < 981 && (
				<div className={MobileInputControls}>
					{isFocused ? (
						<button
							onClick={onSendButtonPressed}
							disabled={charCount > 200}
							style={{
								backgroundColor: mainColor,
								color: getTextColor(mainColor),
								fontSize: 20,
							}}
						>
							<Icon icon="fa:paper-plane" />
						</button>
					) : null}
				</div>
			)}

			<div
				className={`${ChatInputControls} `}
			>
				<button
					style={{ color: mainColor, backgroundColor: getTextColor(mainColor) }}
					onClick={toggleEmojiPicker}
					className={`${Button} ${EmojiToggleButton}`}
				>
					<span className={IconWrapper}>
						<Icon icon="radix-icons:face" />
					</span>
				</button>

				<button
					style={{ backgroundColor: mainColor, color: getTextColor(mainColor) }}
					onClick={onSendButtonPressed}
					className={Button}
					disabled={charCount > 200}
				>
					<span className={TextWrapper}>{t("components.chat.sendButton")}</span>
					<span className={IconWrapper}>
						<Icon icon="fa:paper-plane" />
					</span>
				</button>
			</div>
			<div
				className={charCounterClassNames}
				style={{
					backgroundColor: screenWidth < 981 ? mainColor : "#fff",
					color: screenWidth < 981 ? getTextColor(mainColor) : undefined,
				}}
			>
				{charCount}/200
			</div>
		</div>
	);
};

export default InputMessage;
