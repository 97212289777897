import { useTranslation } from "react-i18next";
import Add from "../FiraIcons/Add";
import AddImageBlack from "../FiraIcons/AddImageBlack";
import StyleSheet from "./AddImageButton.module.scss";
import React from "react";

interface Props {
  canEdit?: boolean;
  clickHandler?(): void;
  onDrop?(files: FileList): void;
}
/**
 * Description of AddImage component
 * @prop {function} clickHandler - handler for the action of the add button
 */
const AddImageButton: React.FC<Props> = ({
  clickHandler,
  onDrop,
  canEdit = true,
}) => {
  const { t } = useTranslation();

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!canEdit) return;

    const { files } = event.dataTransfer;
    if (onDrop && files) {
      onDrop(files);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div
      className={`${StyleSheet.ContainerImageButton} ${
        !canEdit ? StyleSheet.IsDisabled : ""
      }`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <button
        className={`${StyleSheet.AddImageButton} ${
          !canEdit ? StyleSheet.IsDisabled : ""
        }`}
        disabled={!canEdit}
        onClick={clickHandler}
      >
        <AddImageBlack />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
          }}
        >
          <label
            htmlFor=""
            style={{
              cursor: "pointer",
            }}
          >
            {t("views.products.detailProduct.fileFormat")}
            <span className={StyleSheet.LabelAddImageButton}>
              Jpg, Jpeg, Png, Gif
            </span>
          </label>

          <label
            htmlFor=""
            style={{
              cursor: "pointer",
            }}
          >
            {t("views.products.detailProduct.sizeRecommended")}

            <span className={StyleSheet.LabelAddImageButton}>
              1080×1080 (1:1)
            </span>
          </label>

          <label
            htmlFor=""
            style={{
              cursor: "pointer",
            }}
          >
            {t("views.products.detailProduct.maximumWeight")}

            <span className={StyleSheet.LabelAddImageButton}>5MB</span>
          </label>
        </div>
      </button>
    </div>
  );
};

export default AddImageButton;
