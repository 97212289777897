import { ThunkDispatch } from "redux-thunk/es/types";
import { FiraLoginResponse } from "../../types/Response";
import { AuthActions, BrandActions } from "../actionsContants";
import { AxiosResponse } from "axios";
import { RequestError, handleError } from "../../services/errorHandling";
import { loginService } from "../../services/auth";
import { refreshTokenService } from "../../services/auth";
import { AnyAction } from "redux";
import { getStoreBy } from "../../services/store";
import { SetCurrentStoreActionType } from "../Brand/BrandActions";
import { firaBusinessLoginService } from "../../services/authentication";
import { getOwnerData } from "../../services/user";

interface LoginActionType {
  type:
    | AuthActions.loginSuccess
    | AuthActions.loginFailed
    | AuthActions.loginLoading;
  payload?: FiraLoginResponse | RequestError;
  superAdmin?: boolean;
}
export const loginActionCreator = (
  userData: string,
  typeOfuserData: "USERNAME" | "EMAIL" | "PHONE_NUMBER",
  password: string
) => {
  return async (
    dispatch: ThunkDispatch<
      LoginActionType,
      Record<string, unknown>,
      LoginActionType
    >
  ) => {
    try {
      dispatch({ type: AuthActions.loginLoading });
      let response: AxiosResponse<FiraLoginResponse, any>;

      switch (typeOfuserData) {
        case "USERNAME":
          response = await loginService(password, userData);
          break;
        case "EMAIL":
          response = await loginService(password, undefined, userData);
          break;
        case "PHONE_NUMBER":
          response = await loginService(
            password,
            undefined,
            undefined,
            userData
          );
          break;
      }

      dispatch({ type: AuthActions.loginSuccess, payload: response.data });
    } catch (error) {
      dispatch({ type: AuthActions.loginFailed, payload: handleError(error) });
    }
  };
};

export interface RefreshTokenActionType {
  type:
    | AuthActions.refreshTokenSuccess
    | AuthActions.refreshTokenFailed
    | AuthActions.refreshTokenLoading
    | BrandActions.getStoreLoading;
  payload?: FiraLoginResponse | RequestError;
}
export const refreshTokenActionCreator = (
  sessionId: string,
  storeId?: string
) => {
  return async (
    dispatch: ThunkDispatch<
      RefreshTokenActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: AuthActions.refreshTokenLoading });
      const response = await refreshTokenService(sessionId, storeId);
      dispatch({
        type: AuthActions.refreshTokenSuccess,
        payload: response.data,
      });

      if (storeId) {
        dispatch({ type: BrandActions.getStoreLoading });

        const otherResponse = await getStoreBy(storeId);

        dispatch({
          type: BrandActions.setCurrentStore,
          payload: otherResponse.data,
        });
      }
    } catch (error) {
      dispatch({
        type: AuthActions.refreshTokenFailed,
        payload: handleError(error),
      });
    }
  };
};

export interface LogoutActionType {
  type: AuthActions.logout;
}
export const logoutActionCreator = (): LogoutActionType => {
  return { type: AuthActions.logout };
};

export const businessLoginActionCreator = (
  token: string,
  userId: string,
  clientId: string
) => {
  return async (
    dispatch: ThunkDispatch<
      LoginActionType,
      Record<string, unknown>,
      LoginActionType
    >
  ) => {
    try {
      dispatch({ type: AuthActions.loginLoading });
      let response: AxiosResponse<FiraLoginResponse, any>;
      if (token && userId && clientId) {
        response = await firaBusinessLoginService(token, userId, clientId);
        dispatch({
          type: AuthActions.loginSuccess,
          payload: response.data,
          superAdmin: true,
        });
      }
    } catch (error) {
      dispatch({ type: AuthActions.loginFailed, payload: handleError(error) });
    }
  };
};

export interface BusinessRedirectionActionType {
  type: AuthActions.setStatusBusinessRedirection;
  payload: boolean;
}
export const setStatusBusinessRedirection = (
  isBusinessRedirection: boolean
): BusinessRedirectionActionType => {
  return {
    type: AuthActions.setStatusBusinessRedirection,
    payload: isBusinessRedirection,
  };
};

interface GetOwnerAccountNameActionType {
  type:
    | AuthActions.getAccountOwnerInfoLoading
    | AuthActions.getAccountOwnerInfoSuccess
    | AuthActions.getAccountOwnerInfoFailed;
  payload: unknown | RequestError; //Define this type
}
export const getOwnerInfoData = (businessClientId: string) => {
  return async (
    dispatch: ThunkDispatch<
      GetOwnerAccountNameActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({
        type: AuthActions.getAccountOwnerInfoLoading,
      });
      const response = await getOwnerData(businessClientId);
      dispatch({
        type: AuthActions.getAccountOwnerInfoSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: AuthActions.getAccountOwnerInfoFailed,
      });
    }
  };
};

export type AuthenticationActionType =
  | LoginActionType
  | RefreshTokenActionType
  | LogoutActionType
  | SetCurrentStoreActionType
  | BusinessRedirectionActionType
  | GetOwnerAccountNameActionType;
