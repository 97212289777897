import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarIcon,
  Dashboard,
  FiraLiveLogo,
  OpenBox,
  Video,
} from "../../../../components/FiraIcons";
import { FiraUserPermissionComponent } from "../../../../components/NewUserComponents";
import { FiraSimplePermissionList } from "../../../../components/NewUserComponents/FiraUserPermissionComponent/FiraUserPermissionComponent";
import {
  getPermissions,
  getPermissionsByGroupService,
} from "../../../../services/user";
import { FiraPackage } from "../../../../utils/enums";
import {
  PermissionCategories,
  PermissionsByGroupType,
  RootState,
} from "../../../../utils/types.d";
import {
  activateAllOptions,
  checkAvailablePermissions,
  inactivateAllOptions,
  isPermissionAvailable,
  isPermissionGroupAvailable,
  selectAll,
} from "../../../../utils/handlePermissionCheckboxSelection";
import styles from "./PermissiosnForOperator.module.scss";
import ModalLayout from "../../../../layout/ModalLayout/ModalLayout";
import { useSelector } from "react-redux";
import {
  SimplePermissionItem,
  SimpleUserStoreItem,
} from "../../../../types/Response";

interface PermissionForOperatorProps {
  addPermissionsByGroup(firaProduct: FiraPackage, permissions: string[]): void;
  deletePermissionsByGroup(
    firaProduct: FiraPackage,
    permissions: string[]
  ): void;
  deleteEntireGroup?(firaProduct: FiraPackage): void;
  isLoading: boolean;
}

export interface FiraPermissionsModel {
  title: string;
  description: string;
  permissionsGroup: SimplePermissionsType[] | any[];
  package: FiraPackage;
  selectedAll: boolean;
}

export interface SimplePermissionsType {
  name: string;
  permissions: string[];
  isActive: boolean;
}

const PermissionItemsForOperator = ({
  addPermissionsByGroup,
  deletePermissionsByGroup,
  deleteEntireGroup,
  isLoading = true,
}: PermissionForOperatorProps) => {
  const permissionModelInitialState = {
    title: "",
    description: "",
    permissionsGroup: [],
    package: FiraPackage.FIRA_WEB,
    selectedAll: false,
  };

  const { t } = useTranslation();
  const { authentication } = useSelector((state: RootState) => state);
  const [userPermissionFullList, setUserPermissionFullList] =
    useState<SimplePermissionItem[]>();
  const [dashboard, setDashboard] = useState<FiraPermissionsModel>(
    permissionModelInitialState
  );
  const [events, setEvents] = useState<FiraPermissionsModel>(
    permissionModelInitialState
  );
  const [firaTV, setFiraTV] = useState<FiraPermissionsModel>(
    permissionModelInitialState
  );
  const [proseller, setProseller] = useState<FiraPermissionsModel>(
    permissionModelInitialState
  );
  const [products, setProducts] = useState<FiraPermissionsModel>(
    permissionModelInitialState
  );
  const [loadingPage, setLoadingPage] = useState<boolean>(true);

  const getPermissionGroups = async (group: PermissionCategories) => {
    try {
      const val = await getPermissions(group);
      return val;
    } catch (error) {
      // Handle error
      console.error("An error occurred:", error);
      return []; // Or some other default value
    }
  };

  const handleDashboardData = async () => {
    setDashboard({
      title: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.dashboard.title"
      ),
      description: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescriptionv3"
      ),
      permissionsGroup: [
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.dashboard.statistics"
          ),
          permissions: await getPermissionGroups(
            PermissionCategories.FIRA_WEB_DASHBOARD
          ),
          isActive: false,
        },
      ],
      package: FiraPackage.FIRA_WEB,
      selectedAll: false,
    });
  };

  const handleEventsData = async () => {
    setEvents({
      title: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.events.title"
      ),
      description: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescriptionv3"
      ),
      permissionsGroup: [
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.events.event_mgmt"
          ),
          permissions: await getPermissionGroups(
            PermissionCategories.EVENT_MANAGEMENT
          ),
          isActive: false,
        },
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.events.moderation"
          ),
          permissions: await getPermissionGroups(
            PermissionCategories.EVENT_MODERATION
          ),
          isActive: false,
        },
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.events.statistics"
          ),
          permissions: await getPermissionGroups(
            PermissionCategories.EVENT_STATISTICS
          ),
          isActive: false,
        },
      ],
      package: FiraPackage.FIRA_WEB,
      selectedAll: false,
    });
  };

  const handleFiraTVData = async () => {
    setFiraTV({
      title: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.title"
      ),
      description: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
      ),
      permissionsGroup: [
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.VOD_mgmt"
          ),
          permissions: await getPermissionGroups(
            PermissionCategories.VOD_MANAGEMENT
          ),
          isActive: false,
        },
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.widgets"
          ),
          permissions: await getPermissionGroups(
            PermissionCategories.WIDGETS_MANAGEMENT
          ),
          isActive: false,
        },
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.statistics"
          ),
          permissions: await getPermissionGroups(
            PermissionCategories.FIRA_TV_STATISTICS
          ),
          isActive: false,
        },
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.firaTV.collections"
          ),
          permissions: await getPermissionGroups(
            PermissionCategories.FIRA_TV_COLLECTIONS_MANAGEMENT
          ),
          isActive: false,
        },
      ],
      package: FiraPackage.FIRA_TV,
      selectedAll: false,
    });
  };

  const handleProsellerData = () => {
    setProseller({
      title: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.proseller.proseller"
      ),
      description: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
      ),
      permissionsGroup: [
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.proseller.products"
          ),
          permissions: [],
          isActive: false,
        },
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.proseller.events"
          ),
          permissions: [],
          isActive: false,
        },
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.proseller.moderation"
          ),
          permissions: [],
          isActive: false,
        },
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.proseller.statistics"
          ),
          permissions: [],
          isActive: false,
        },
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.proseller.vod"
          ),
          permissions: [],
          isActive: false,
        },
      ],
      package: FiraPackage.PROSELLER,
      selectedAll: false,
    });
  };

  const handleProductsData = async () => {
    setProducts({
      title: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.products.title"
      ),
      description: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
      ),
      permissionsGroup: [
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.products.management"
          ),
          permissions: await getPermissionGroups(
            PermissionCategories.PRODUCTS_MANAGEMENT
          ),

          isActive: false,
        },
        {
          name: t(
            "components.settings.userRolesAndPermissions.permissionsDetail.products.batch_load"
          ),
          permissions: await getPermissionGroups(
            PermissionCategories.PRODUCTS_BATCH
          ),

          isActive: false,
        },
      ],
      package: FiraPackage.FIRA_WEB,
      selectedAll: false,
    });
  };

  /** Permissions handlers, for single permissions and for all */

  const handleFiraWebPermissions = (
    model: string,
    permissions: SimplePermissionsType
  ) => {
    if (!permissions.isActive) {
      addPermissionsByGroup(FiraPackage.FIRA_WEB, permissions.permissions);
    } else {
      deletePermissionsByGroup(FiraPackage.FIRA_WEB, permissions.permissions);
    }

    //for checkbox handling
    //Todo: make and enum for the cases
    switch (model) {
      case "EVENTS": {
        const updated = updateStatus(events, permissions);
        setEvents({
          ...events,
          permissionsGroup: updated,
        });
        if (!permissions.isActive) {
          setEvents({
            ...events,
            permissionsGroup: updated,
            selectedAll: false,
          });
        }
        break;
      }
      case "PRODUCTS": {
        const updated = updateStatus(products, permissions);
        setProducts({
          ...products,
          permissionsGroup: updated,
        });
        if (!permissions.isActive) {
          setProducts({
            ...products,
            permissionsGroup: updated,
            selectedAll: false,
          });
        }
        break;
      }
      default:
        return;
    }
  };

  const handleFiraTVPermissions = (permissions: SimplePermissionsType) => {
    const updated = updateStatus(firaTV, permissions);
    setFiraTV({
      ...firaTV,
      permissionsGroup: updated,
    });
    if (!permissions.isActive) {
      addPermissionsByGroup(FiraPackage.FIRA_TV, permissions.permissions);
    } else {
      deletePermissionsByGroup(FiraPackage.FIRA_TV, permissions.permissions);
      setFiraTV({
        ...firaTV,
        permissionsGroup: updated,
        selectedAll: false,
      });
    }
  };

  const handleEventPermissions = (permissions: SimplePermissionsType) => {
    handleFiraWebPermissions("EVENTS", permissions);
  };

  const handleProductsPermissions = (permissions: SimplePermissionsType) => {
    handleFiraWebPermissions("PRODUCTS", permissions);
  };

  const handleDashboardPermissions = (permissions: SimplePermissionsType) => {
    if (!dashboard.selectedAll) {
      setDashboard({
        ...dashboard,
        selectedAll: true,
      });
      addPermissionsByGroup(FiraPackage.FIRA_WEB, permissions.permissions);
    } else {
      setDashboard({
        ...dashboard,
        selectedAll: false,
      });
      deletePermissionsByGroup(FiraPackage.FIRA_WEB, permissions.permissions);
    }
  };

  const handleProSellerPermissios = () => {
    if (!proseller.selectedAll) {
      setProseller({
        ...proseller,
        selectedAll: true,
      });
      addPermissionsByGroup(FiraPackage.PROSELLER, []);
    } else {
      setDashboard({
        ...dashboard,
        selectedAll: false,
      });
      if (deleteEntireGroup) {
        deleteEntireGroup(FiraPackage.PROSELLER);
      }
    }
  };
  /**
   * Estas funciones deberian ser optimizadas y crear una sola que reciba los parametros de cada uno
   * recordar que el código no debe repetirse tanto
   */

  const selectAllEventPermissions = () => {
    const all = selectAll(events);
    if (!events.selectedAll) {
      setEvents({
        ...events,
        selectedAll: true,
        permissionsGroup: activateAllOptions(events),
      });
      if (all !== undefined) {
        addPermissionsByGroup(FiraPackage.FIRA_WEB, all);
      }
    } else {
      setEvents({
        ...events,
        selectedAll: false,
        permissionsGroup: inactivateAllOptions(events),
      });
      deletePermissionsByGroup(FiraPackage.FIRA_WEB, all);
    }
  };

  const selectAllProductsPermissions = () => {
    const all = selectAll(products);
    if (!products.selectedAll) {
      setProducts({
        ...products,
        selectedAll: true,
        permissionsGroup: activateAllOptions(products),
      });
      if (all !== undefined) {
        addPermissionsByGroup(FiraPackage.FIRA_WEB, all);
      }
    } else {
      setProducts({
        ...products,
        selectedAll: false,
        permissionsGroup: inactivateAllOptions(products),
      });
      deletePermissionsByGroup(FiraPackage.FIRA_WEB, all);
    }
  };

  const selectAllFiraTvPermissions = () => {
    if (userPermissionFullList) {
      const all = selectAll(firaTV);
      if (!firaTV.selectedAll) {
        setFiraTV({
          ...firaTV,
          selectedAll: true,
          permissionsGroup: activateAllOptions(firaTV),
        });
        if (all !== undefined) {
          addPermissionsByGroup(FiraPackage.FIRA_TV, all);
        }
      } else {
        setFiraTV({
          ...firaTV,
          selectedAll: false,
          permissionsGroup: inactivateAllOptions(firaTV),
        });
        deletePermissionsByGroup(FiraPackage.FIRA_TV, all);
      }
    }
  };

  const updateStatus = (
    obj: FiraPermissionsModel,
    toChange: SimplePermissionsType
  ) => {
    const updateStatus = obj.permissionsGroup.map((permissionObj) =>
      permissionObj.name === toChange.name
        ? { ...permissionObj, isActive: !permissionObj.isActive }
        : permissionObj
    );

    return updateStatus;
  };

  const handleAllData = async () => {
    await handleDashboardData();
    await handleEventsData();
    await handleProductsData();
    await handleFiraTVData();
    await handleProsellerData();

    setLoadingPage(false);
  };

  useEffect(() => {
    if (authentication.user?.permissionsStore) {
      setUserPermissionFullList(
        authentication.user.permissionsStore[0].permissions
      );
      handleAllData();
    }
  }, [authentication]);

  return (
    <>
      {isLoading ||
        (loadingPage && (
          <ModalLayout>
            <div className={styles.loaderContainer}>
              <span className={styles.loader}></span>
            </div>
          </ModalLayout>
        ))}
      <FiraUserPermissionComponent
        permissions={dashboard.permissionsGroup[0]}
        title={dashboard.title}
        icon={<Dashboard />}
        description={dashboard.description}
        onPermissionClick={handleDashboardPermissions}
        selectedAll={dashboard.selectedAll}
        userPermissions={userPermissionFullList}
        isDisabled={isLoading}
      >
        <FiraSimplePermissionList
          permissions={dashboard.permissionsGroup.map((p) => {
            return p.name;
          })}
        />
      </FiraUserPermissionComponent>

      <FiraUserPermissionComponent
        permissions={[]}
        title={events.title}
        icon={<CalendarIcon />}
        description={events.description}
        isEditable={true}
        editablePermissions={events.permissionsGroup}
        selectedAll={events.selectedAll}
        onEditablePermissionClick={handleEventPermissions}
        onModuleClick={selectAllEventPermissions}
        userPermissions={userPermissionFullList}
        isDisabled={isLoading}
      >
        <FiraSimplePermissionList
          permissions={events.permissionsGroup.map((p) => {
            return p.name;
          })}
        />
      </FiraUserPermissionComponent>

      <FiraUserPermissionComponent
        permissions={[]}
        title={products.title}
        icon={<OpenBox />}
        description={products.description}
        isEditable={true}
        selectedAll={products.selectedAll}
        editablePermissions={products.permissionsGroup}
        onEditablePermissionClick={handleProductsPermissions}
        onModuleClick={selectAllProductsPermissions}
        userPermissions={userPermissionFullList}
        isDisabled={isLoading}
      >
        <FiraSimplePermissionList
          permissions={products.permissionsGroup.map((p) => {
            return p.name;
          })}
        />
      </FiraUserPermissionComponent>

      <FiraUserPermissionComponent
        permissions={[]}
        title={firaTV.title}
        icon={<Video />}
        isEditable={true}
        editablePermissions={firaTV.permissionsGroup}
        description={firaTV.description}
        selectedAll={firaTV.selectedAll}
        onEditablePermissionClick={handleFiraTVPermissions}
        onModuleClick={selectAllFiraTvPermissions}
        userPermissions={userPermissionFullList}
        isDisabled={isLoading}
      >
        <FiraSimplePermissionList
          permissions={firaTV.permissionsGroup.map((p) => {
            return p.name;
          })}
        />
      </FiraUserPermissionComponent>
      <FiraUserPermissionComponent
        permissions={[]}
        title={proseller.title}
        icon={<FiraLiveLogo />}
        description={proseller.description}
        onPermissionClick={handleProSellerPermissios}
        userPermissions={userPermissionFullList}
        isDisabled={isLoading}
      >
        <FiraSimplePermissionList
          permissions={proseller.permissionsGroup.map((p) => {
            return p.name;
          })}
        />
      </FiraUserPermissionComponent>
    </>
  );
};

export default PermissionItemsForOperator;
