import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
  currentColor?: string;
  height?: string;
}

const SvgDots = ({
  title,
  titleId,
  currentColor,
  height,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="12"
    fill="none"
    viewBox="0 0 56 12"
  >
    <circle cx="10" cy="6" r="2" fill="#D9D9D9"></circle>
    <circle cx="22" cy="6" r="2" fill={currentColor}></circle>
    <circle cx="34" cy="6" r="2" fill="#D9D9D9"></circle>
    <circle cx="46" cy="6" r="2" fill="#D9D9D9"></circle>
  </svg>
);

export default SvgDots;
