import { RequestError } from "../../services/errorHandling";
import {
  CriteriaType,
  DataActionProductsStats,
  DataVideoStats,
  EditEventResponse,
  FiraVideo,
  FiraVideoInfo,
  FiraVideoProduct,
  UpdateCategoryBody,
  VideoProduct,
} from "../../utils/types";
import { AuthActions, VideoActions } from "../actionsContants";
import {
  VideoActionType,
  GetVideosResponse,
  UpdateVideoVisibilityResponse,
  UpdateVideoProductStateResponse,
} from "./VideoActions";

export interface VideoState {
  isLoading: boolean;
  list: FiraVideo[];
  availableList: FiraVideo[];
  error?: RequestError;
  currentVideo?: FiraVideo;
}

const initialState: VideoState = {
  isLoading: false,
  list: [],
  availableList: [],
};

export const videoReducer = (
  state = initialState,
  action: VideoActionType
): VideoState => {
  switch (action.type) {
    case VideoActions.getVideosLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case VideoActions.getVideosSuccess: {
      const { payload, pagination } = action.payload as GetVideosResponse;
      let newList = [] as FiraVideo[];
      if (pagination) {
        const newVideos = payload as FiraVideo[];
        newList = [...state.list];
        newList.push(...newVideos);
      } else {
        newList = payload as FiraVideo[];
      }

      return {
        ...state,
        list: newList,
        isLoading: false,
        error: undefined,
      };
    }
    case VideoActions.getVideosFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case VideoActions.resetVideoList: {
      return {
        ...state,
        list: [],
        isLoading: false,
        error: undefined,
      };
    }
    case VideoActions.setCurrentVideo: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        currentVideo: payload as FiraVideo,
        error: undefined,
      };
    }
    case VideoActions.resetCurrentVideo: {
      return {
        ...state,
        currentVideo: initialState.currentVideo,
      };
    }
    case VideoActions.updateVisibilityLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case VideoActions.updateVisibilitySuccess: {
      const { videoId, message } =
        action.payload as UpdateVideoVisibilityResponse;
      const newList = state.list;
      newList.map((element) => {
        if (element.id.includes(videoId)) {
          if (message.toString() == "Video visibility changed: VISIBLE") {
            element.visibility = "VISIBLE";
          } else {
            element.visibility = "HIDE";
          }
        }
      });
      return {
        ...state,
        isLoading: false,
        error: undefined,
        list: newList,
      };
    }
    case VideoActions.updateVisibilityFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case VideoActions.updateVideoProductStateLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case VideoActions.updateVideoProductStateSuccess: {
      const { id, message } = action.payload as UpdateVideoProductStateResponse;
      const newProducts = state.currentVideo?.products;
      let newVideo = state.currentVideo;
      newProducts &&
        newProducts.map((element) => {
          if (element.id.includes(id)) {
            if (
              message.toString() ==
              "product state changed successfully, new state: DEACTIVATE"
            ) {
              element.status = "DEACTIVATE";
            } else {
              element.status = "ACTIVATE";
            }
          }
        });
      if (state.currentVideo && newProducts) {
        newVideo = {
          ...state.currentVideo,
          products: newProducts,
        };
      }

      return {
        ...state,
        isLoading: false,
        currentVideo: newVideo,
        error: undefined,
      };
    }

    case VideoActions.updateVideoTeaserSuccess: {
      const { id, message } = action.payload as EditEventResponse;
      let newVideo = state.currentVideo;
      if (state.currentVideo) {
        newVideo = {
          ...state.currentVideo,
          eventTeaser: message,
        };
      }
      return {
        ...state,
        isLoading: false,
        currentVideo: newVideo,
        error: undefined,
      };
    }

    case VideoActions.updateVideoPreviewImageSuccess: {
      const { id, message } = action.payload as EditEventResponse;
      let newVideo = state.currentVideo;
      if (state.currentVideo) {
        newVideo = {
          ...state.currentVideo,
          eventPreviewImage: message,
        };
      }
      return {
        ...state,
        isLoading: false,
        currentVideo: newVideo,
        error: undefined,
      };
    }
    case VideoActions.updateVideoProductStateFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case VideoActions.updateVideoProductInfoLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case VideoActions.updateVideoProductInfoSuccess: {
      const { payload } = action;
      const newProducts = state.currentVideo?.products;
      const product = payload as FiraVideoProduct;
      let newVideo = state.currentVideo;
      newProducts &&
        newProducts.map((element) => {
          if (element.id.includes(product.id)) {
            element.product = product.product;
          }
        });
      if (state.currentVideo && newProducts) {
        newVideo = {
          ...state.currentVideo,
          products: newProducts,
        };
      }

      return {
        ...state,
        isLoading: false,
        currentVideo: newVideo,
        error: undefined,
      };
    }
    case VideoActions.updateVideoProductInfoFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }
    case VideoActions.updateAllPricesVisibilityLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case VideoActions.updateAllPricesVisibilitySuccess: {
      const { payload } = action;

      let newVideo = state.currentVideo;

      if (state.currentVideo) {
        newVideo = {
          ...state.currentVideo,
          products: (payload as FiraVideo).products,
        };
      }

      return {
        ...state,
        isLoading: false,
        currentVideo: newVideo,
        error: undefined,
      };
    }
    case VideoActions.updateAllPricesVisibilityFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case VideoActions.updateVideoGeneralInfoLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case VideoActions.updateVideoGeneralInfoSuccess: {
      const { payload } = action;
      let newVideo = state.currentVideo;

      if (state.currentVideo) {
        newVideo = {
          ...state.currentVideo,
          videoName: (payload as FiraVideoInfo).videoName,
          videoDescription: (payload as FiraVideoInfo).videoDescription,
        };
      }
      return {
        ...state,
        isLoading: false,
        currentVideo: newVideo,
        error: undefined,
      };
    }
    case VideoActions.updateVideoGeneralInfoFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case AuthActions.logout: {
      return initialState;
    }

    case VideoActions.getAvailableVideosLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case VideoActions.getAvailableVideosSuccess: {
      const { payload } = action;

      return {
        ...state,
        availableList: payload as FiraVideo[],
        isLoading: false,
        error: undefined,
      };
    }

    case VideoActions.getAvailableVideosFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case VideoActions.resetAvailableVideosList: {
      return {
        ...state,
        isLoading: false,
        availableList: [],
        error: undefined,
      };
    }
    case VideoActions.getVideoProductsStatsSuccess: {
      const { payload } = action;
      let newVideo = state.currentVideo;
      let arrayProductsClicks = [] as DataActionProductsStats[];

      if (state.currentVideo) {
        if (state.currentVideo.videoStats.productsClickStats) {
          arrayProductsClicks.push(
            ...state.currentVideo.videoStats.productsClickStats,
            payload as DataActionProductsStats
          );
        } else {
          arrayProductsClicks.push(payload as DataActionProductsStats);
        }
      }

      if (state.currentVideo) {
        newVideo = {
          ...state.currentVideo,
          videoStats: {
            ...state.currentVideo.videoStats,
            productsClickStats: arrayProductsClicks,
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        currentVideo: newVideo,
        error: undefined,
      };
    }

    case VideoActions.addVideoCategorySuccess: {
      const { payload } = action;
      let data = payload as UpdateCategoryBody;
      let newVideo = state.currentVideo;
      if (state.currentVideo) {
        newVideo = {
          ...state.currentVideo,
          categories: data.categories,
        };
      }
      return {
        ...state,
        currentVideo: newVideo,
        isLoading: false,
        error: undefined,
      };
    }

    case VideoActions.deleteVideoCategorySuccess: {
      const { payload } = action;
      let data = payload as UpdateCategoryBody;
      let newVideo = state.currentVideo;
      if (state.currentVideo) {
        newVideo = {
          ...state.currentVideo,
          categories: data.categories,
        };
      }
      return {
        ...state,
        currentVideo: newVideo,
        isLoading: false,
        error: undefined,
      };
    }

    case VideoActions.resetVideoStats: {
      let newVideo = state.currentVideo;
      if (state.currentVideo) {
        newVideo = {
          ...state.currentVideo,
          videoStats: {
            ...state.currentVideo.videoStats,
            statsTime: [],
          },
        };
      }
      return {
        ...state,
        currentVideo: newVideo,
      };
    }
    case VideoActions.getVideoStatsSuccess: {
      const { payload } = action;
      let newVideo = state.currentVideo;
      let arrayProductsClicks = [] as DataVideoStats[];

      if (state.currentVideo) {
        if (state.currentVideo.videoStats.statsTime) {
          arrayProductsClicks.push(
            ...state.currentVideo.videoStats.statsTime,
            payload as unknown as DataVideoStats
          );
        } else {
          arrayProductsClicks.push(payload as unknown as DataVideoStats);
        }
      }

      if (state.currentVideo) {
        newVideo = {
          ...state.currentVideo,
          videoStats: {
            ...state.currentVideo.videoStats,
            statsTime: arrayProductsClicks,
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        currentVideo: newVideo,
        error: undefined,
      };
    }
    default: {
      return state;
    }
  }
};
