import FiraNavBar from "../../../FiraNavBar/FiraNavBar";
import styles from "./UserPermissionsDetail.module.scss";
import Permissions from "../../../../types/Permissions.d";
import { ReactElement, useState } from "react";
import { FiraLiveLogo } from "../../../FiraIcons";
import { useTranslation } from "react-i18next";
import FiraUserPermissionComponent, {
  FiraSimplePermissionList,
} from "../../../NewUserComponents/FiraUserPermissionComponent/FiraUserPermissionComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../../utils/types";

interface Props {
  backButtonHandler(): void;
  permissions: any[];
}
type PermissionListType = {
  id: number;
  icon: ReactElement;
  title: string;
  permissionList: string[];
};

const UserPermissionsDetail: React.FC<Props> = ({
  backButtonHandler,
  permissions,
}) => {
  const { t } = useTranslation();
  const { brand } = useSelector((state: RootState) => state);
  const [permissionsList, SetPermissionsList] = useState();
  const permissionListSample: PermissionListType[] = [
    {
      id: 1,
      icon: <FiraLiveLogo />,
      title: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.manager.title"
      ),
      permissionList: [
        t(
          "components.settings.userRolesAndPermissions.permissionsDetail.manager.dashboard"
        ),
        t(
          "components.settings.userRolesAndPermissions.permissionsDetail.manager.events"
        ),
        t(
          "components.settings.userRolesAndPermissions.permissionsDetail.manager.products"
        ),
        t(
          "components.settings.userRolesAndPermissions.permissionsDetail.manager.team"
        ),
      ],
    },
    {
      id: 2,
      icon: <FiraLiveLogo />,
      title: t(
        "components.settings.userRolesAndPermissions.permissionsDetail.proseller.title"
      ),
      permissionList: [
        t(
          "components.settings.userRolesAndPermissions.permissionsDetail.proseller.proseller"
        ),
      ],
    },
  ];
  return (
    <div className={styles.UserPermissionsDetailWrapper}>
      <div className={styles.header}>
        <FiraNavBar
          titleSection={
            brand.currentStore
              ? brand.currentStore.name
              : t(
                  "components.settings.userRolesAndPermissions.permissionsDetail.title"
                )
          }
          backButtonHandler={backButtonHandler}
        />
      </div>
      <div className={styles.permissionsDetail}>
        <span className={styles.subtTitle}>
          {t(
            "components.settings.userRolesAndPermissions.permissionsDetail.subTitle"
          )}
        </span>
        <div className={styles.permissionsGroup}>
          {permissionListSample.map((permission) => {
            return (
              <div style={{ minHeight: "222px" }}>
                <FiraUserPermissionComponent
                  icon={permission.icon}
                  description={t(
                    "components.settings.userRolesAndPermissions.permissionsDetail.permissionsDescription"
                  )}
                  title={permission.title}
                  key={permission.id}
                  permissions={[]}
                  isOnlyDisplay
                >
                  <FiraSimplePermissionList
                    permissions={permission.permissionList}
                  />
                </FiraUserPermissionComponent>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UserPermissionsDetail;
