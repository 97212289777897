export const emailRegex =
  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

export const currencyRegex = /^\d+(?:\.|\,)?\d{0,2}$/;

export const MAX_VIDEO_FILE_SIZE = 2048;

export const CONNECTION_LIMIT_FOR_TEST_EVENT = 10;

export const strongPasswordTesterRegEx = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{10,})"
);
export const mediumPasswordTesterRegEx = new RegExp(
  "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
);

export const urlRegex = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/))(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)(\.){1,}[a-zA-Z0-9()]*\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=])*/gi
);
