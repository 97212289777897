import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../../utils/types";

const AccountOwnerGuard: React.FC = () => {
  const { isUserLogIn, isAccountOwner } = useSelector(
    (state: RootState) => state.authentication
  );

  return isUserLogIn && isAccountOwner ? <Outlet /> : <Navigate to={"/"} />;
};

export default AccountOwnerGuard;
