import { SimplePermissionItem } from "../types/Response";

type FilterType = "group" | "permission";

const operatorPermissions = (
  permissionsArray: SimplePermissionItem[],
  filterType: FilterType,
  filterValue: string
) => {
  let permissionFound = false; // Variable para indicar si se encontró el permiso

  for (const permissionGroup of permissionsArray) {
    const { permissions, firaProduct } = permissionGroup;

    if (filterType === "group") {
      if (firaProduct === filterValue) {
        permissionFound = true;
        break; // Detener el loop
      }
    } else if (filterType === "permission") {
      if (permissions.includes(filterValue)) {
        permissionFound = true;
        break; // Detener el loop
      }
    }
  }

  if (!permissionFound) {
    permissionFound = false;
  }

  return permissionFound;
};

export default operatorPermissions;

export enum FIRA_PERMISSIONS_GROUP {
  FIRA_WEB = "FIRA_WEB",
  FIRA_TV = "FIRA_TV",
  PROSELLER = "PROSELLER",
}

export enum FIRA_WEB_DASHBOARD_PERMISSIONS {
  EVENT_GET_STATS = "EVENT_GET_STATS",
  EVENT_DOWNLOAD_ALL_STATS = "EVENT_DOWNLOAD_ALL_STATS",
  EVENT_DOWNLOAD_HISTORICAL_STATS = "EVENT_DOWNLOAD_HISTORICAL_STATS",
}

export enum FIRA_WEB_VIEW_EVENTS_PERMISSIONS {
  EVENT_USER = "EVENT_USER",
  EVENT_USER_PARAMS = "EVENT_USER_PARAMS",
  EVENT_GET_MODERATORS = "EVENT_GET_MODERATORS",
  LAST_EVENT = "LAST_EVENT",
  USERS_GET_MODERATORS = "USERS_GET_MODERATORS",
  GET_EVENT_CONFIGURATION = "GET_EVENT_CONFIGURATION",
  EVENT_CATEGORY_BY_ID = "EVENT_CATEGORY_BY_ID",
  EVENT_GET_ALL_CATEGORY = "EVENT_GET_ALL_CATEGORY",
  DOWNLOAD_CHAT = "DOWNLOAD_CHAT",
  DOWNLOAD_ALL_CHANNEL = "DOWNLOAD_ALL_CHANNEL",
}

export enum FIRA_WEB_MANAGE_EVENTS_PERMISSIONS {
  UPDATE_EVENT_CONFIGURATION = "UPDATE_EVENT_CONFIGURATION",
  EVENT_ADD_CATEGORY = "EVENT_ADD_CATEGORY",
  STOP_RECORDING = "STOP_RECORDING",
  START_RECORDING = "START_RECORDING",
  UPLOAD_LIKES = "UPLOAD_LIKES",
  DELETE_EVENT_CONFIGURATION = "DELETE_EVENT_CONFIGURATION",
}

export enum FIRA_WEB_MODERATOR_PERMISSIONS {
  PRODUCT_BY_STORE_ID = "PRODUCT_BY_STORE_ID",
  START_EVENT = "START_EVENT",
  EVENT_BY_ID = "EVENT_BY_ID",
  EVENT_PUBLISHER_CREDENTIALS = "EVENT_PUBLISHER_CREDENTIALS",
  EVENT_GENERATE_CREDENTIALS = "EVENT_GENERATE_CREDENTIALS",
  EVENT_LIST_CHAT_MODERATOR = "EVENT_LIST_CHAT_MODERATOR",
  FINISH_BROADCAST = "FINISH_BROADCAST",
  CHAT_BY_ID = "CHAT_BY_ID",
  VALIDATE_CHAT_MODERATOR = "VALIDATE_CHAT_MODERATOR",
  EVENT_LIST_CHAT = "EVENT_LIST_CHAT",
  USER_BY_ID = "USER_BY_ID",
  USER_UPDATE_PROFILE = "USER_UPDATE_PROFILE",
  USER_INVITATION_LIST = "USER_INVITATION_LIST",
  USER_LOGOUT_SESSION = "USER_LOGOUT_SESSION",
  LOGOUT = "LOGOUT",
  STORE_BY_ID = "STORE_BY_ID",
  STORE_ACTIVE_USER = "STORE_ACTIVE_USER",
}

export enum FIRA_WEB_STATISTICS_EVENTS_PERMISSIONS {
  EVENT_GET_STATS = "EVENT_GET_STATS",
  EVENT_DOWNLOAD_ALL_STATS = "EVENT_DOWNLOAD_ALL_STATS",
  EVENT_DOWNLOAD_HISTORICAL_STATS = "EVENT_DOWNLOAD_HISTORICAL_STATS",
}

export enum FIRA_WEB_MANAGE_PRODUCTS_PERMISSIONS {
  PRODUCT_BY_ID = "PRODUCT_BY_ID",
  PRODUCT_BY_ID_ORIGIN = "PRODUCT_BY_ID_ORIGIN",
  GET_ALL_FIRA_PRODUCT = "GET_ALL_FIRA_PRODUCT",
  ADD_IMAGE_PRODUCT = "ADD_IMAGE_PRODUCT",
  UPDATE_PRODUCT = "UPDATE_PRODUCT",
  DELETE_IMAGE_PRODUCT = "DELETE_IMAGE_PRODUCT",
  SORT_IMAGE_PRODUCT = "SORT_IMAGE_PRODUCT",
  DELETE_PRODUCT_LIST = "DELETE_PRODUCT_LIST",
}

export enum FIRA_WEB_BATCH_PRODUCTS_PERMISSIONS {
  PRELOAD_PRODUCTS_FILE = "PRELOAD_PRODUCTS_FILE",
  SYNCHRONIZE_PRODUCTS_ECOMMERCE = "SYNCHRONIZE_PRODUCTS_ECOMMERCE",
  UPLOAD_PRODUCTS_FILE = "UPLOAD_PRODUCTS_FILE",
  UPLOAD_PRODUCT_UPDATE_FILE = "UPLOAD_PRODUCT_UPDATE_FILE",
  GET_REJECTED_LIST = "GET_REJECTED_LIST",
  GET_DUPLICATED_LIST = "GET_DUPLICATED_LIST",
  GET_APPROVED_LIST = "GET_APPROVED_LIST",
  CREATE_REJ_EXCEL = "CREATE_REJ_EXCEL",
  CREATE_DUP_EXCEL = "CREATE_DUP_EXCEL",
  CREATE_APP_EXCEL = "CREATE_APP_EXCEL",
}

export enum FIRA_TV_MANAGE_VODS_PERMISSIONS {
  GET_AVAILABLE_GALLERY="GET_AVAILABLE_GALLERY",
  GET_ALL_GALLERY="GET_ALL_GALLERY",
  SEARCH_GALLERY_NAME="SEARCH_GALLERY_NAME",
  LIST_VOD_CLASSIFICATION="LIST_VOD_CLASSIFICATION",
  LIST_VOD_CRITERIA="LIST_VOD_CRITERIA",
  GET_VOD_DATA="GET_VOD_DATA",
  UPDATE_VOD_VISIBILITY="UPDATE_VOD_VISIBILITY",
  UPDATE_VOD_TEASER="UPDATE_VOD_TEASER",
  UPDATE_VOD_PREVIEW_IMAGE="UPDATE_VOD_PREVIEW_IMAGE",
  VOD_ADD_CATEGORY="VOD_ADD_CATEGORY",
  UPDATE_VOD_PRIMARY_DATA="UPDATE_VOD_PRIMARY_DATA",
  ALLOWED_GLOBAL_PRICE="ALLOWED_GLOBAL_PRICE",
  REPLACE_VIDEO="REPLACE_VIDEO",
  REPLACE_FILES_VIDEO="REPLACE_FILES_VIDEO",
  UPDATE_VOD_CLASSIFICATION="UPDATE_VOD_CLASSIFICATION",
  VOD_DELETE_CATEGORY="VOD_DELETE_CATEGORY",
  FIND_VOD_ID="FIND_VOD_ID",
  UPDATE_VOD_PRODUCT_STATE="UPDATE_VOD_PRODUCT_STATE",
  UPDATE_VOD_PRODUCT_PRICE="UPDATE_VOD_PRODUCT_PRICE",
  UPDATE_VOD_PRODUCT_STOCK="UPDATE_VOD_PRODUCT_STOCK",
  UPDATE_VOD_PRODUCT_SKU="UPDATE_VOD_PRODUCT_SKU",
}

export enum FIRA_TV_STATISTICS_PERMISSION {
  GET_AVAILABLE_GALLERY="GET_AVAILABLE_GALLERY",
  GET_ALL_GALLERY="GET_ALL_GALLERY",
  SEARCH_GALLERY_NAME="SEARCH_GALLERY_NAME",
  LIST_VOD_CLASSIFICATION="LIST_VOD_CLASSIFICATION",
  LIST_VOD_CRITERIA="LIST_VOD_CRITERIA",
  GET_VOD_DATA="GET_VOD_DATA",
}

export enum FIRA_TV_MANAGE_COLLECTIONS {
  FETCH_COLLECTION="FETCH_COLLECTION",
  GET_COLLECTION_ID="GET_COLLECTION_ID",
  CREATE_COLLECTION="CREATE_COLLECTION",
  EDIT_COLLECTION="EDIT_COLLECTION",
  ADD_TO_COLLECTION="ADD_TO_COLLECTION",
  SORT_COLLECTION_BY="SORT_COLLECTION_BY",
  UPDATE_IMAGE_COLLECTION="UPDATE_IMAGE_COLLECTION",
  DELETE_COLLECTION="DELETE_COLLECTION",
  DELETE_FROM_COLLECTION="DELETE_FROM_COLLECTION",
  COLLECTION_DELETE_STORE="COLLECTION_DELETE_STORE",
}
