import React from "react";

import stylesheet from "./AdditionalVars.module.scss";
import Button, { ButtonStyle } from "../../Button/Button";
import { useTranslation } from "react-i18next";
import Input from "../../Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import {
  convertArrayToObject,
  convertObjectToArray,
} from "../../../utils/formatters";
import { Icon } from "@iconify-icon/react";
import { AlertType, usePopupAlert } from "../../../providers/AlertProvider";
import { postAccountVars } from "../../../services/settings";
import { getOwnerInfoData } from "../../../store/Authentication/actions";
import { getStoreBy } from "../../../services/store";
import { updateCurrentStoreActionCreator } from "../../../store/Brand/BrandActions";

const MAX_INPUTS = 5;

const AdditionalVars: React.FC = () => {
  const { t } = useTranslation();
  const showAlert = usePopupAlert();
  const { authentication, brand } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [inputs, setInputs] = React.useState<{ [key: string]: string }[]>([]);
  const [initialInputs, setInitialInputs] = React.useState([...inputs]);
  const [isChanged, setIsChanged] = React.useState(false);
  const [isLoading, setisLoading] = React.useState(false);

  const areInputsDifferent = (newInputs: any[], initialInputs: any[]) => {
    return JSON.stringify(newInputs) !== JSON.stringify(initialInputs);
  };

  const isValidUrlParam = (str: string): boolean => {
    return /^[a-zA-Z0-9-_~.!*'();:@&=+$,/?#\[\]]+$/.test(str);
  };

  const handleInputChange =
    (index: number, key: string, isKey: boolean) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newInputs = [...inputs];
      if (isKey) {
        const value = newInputs[index][key];
        delete newInputs[index][key];
        newInputs[index][event.target.value] = value;
      } else {
        newInputs[index][key] = event.target.value;
      }
      setIsChanged(areInputsDifferent(newInputs, initialInputs));
      setInputs(newInputs);
    };

  const handleDelete = (index: number) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
    setIsChanged(areInputsDifferent(newInputs, initialInputs));
  };

  const handleAddInput = (event: React.FormEvent) => {
    event.preventDefault();
    const newInputs = [...inputs, { "": "" }];
    setInputs(newInputs);
    setIsChanged(areInputsDifferent(newInputs, initialInputs));
  };

  const handleReset = () => {
    setInputs([...initialInputs]);
    setIsChanged(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setisLoading(true);
    for (const input of inputs) {
      const key = Object.keys(input)[0];
      const value = input[key];

      if (!key || !value) {
        setisLoading(false);
        showAlert(AlertType.error, t("alerts.vars_error"));
        return;
      }

      if (!isValidUrlParam(key) || !isValidUrlParam(value)) {
        setisLoading(false);
        showAlert(AlertType.error, t("alerts.vars_url_error"));
        return;
      }
    }

    const finalObject = convertArrayToObject(inputs);

    if (authentication.ownerInfo) {
      try {
        await postAccountVars(authentication.ownerInfo.id, finalObject);

        dispatch(getOwnerInfoData(authentication.ownerInfo.id));
        updateCurrentStore();
        setIsChanged(false);
        showAlert(AlertType.success, t("alerts.success"));
      } catch (error) {
        showAlert(AlertType.error, JSON.stringify(error));
      }
    }
    setisLoading(false);
  };

  const updateCurrentStore = async () => {
    if (brand.currentStore) {
      const { data } = await getStoreBy(brand.currentStore.id);
      dispatch(updateCurrentStoreActionCreator(data));
    }
  };

  const renderInputs = (input: { [key: string]: string }, index: number) => {
    return (
      <div key={index} className={stylesheet.InputWrapper}>
        <span>{`${index + 1}/${MAX_INPUTS}`}</span>
        {Object.entries(input).map(([key, value], idx) => (
          <div className={stylesheet.ValueInputs} key={idx}>
            <Input
              type="text"
              width="100%"
              height="40px"
              value={key}
              placeholder={t("inputs.varKeyPlaceholder")}
              onChange={handleInputChange(index, key, true)}
            />
            <Input
              type="text"
              width="100%"
              height="40px"
              value={value}
              placeholder={t("inputs.varValuePlaceholder")}
              onChange={handleInputChange(index, key, false)}
            />
          </div>
        ))}
        <Button
          height="40px"
          width="40px"
          type="button"
          buttonStyle={ButtonStyle.border}
          clickHandler={() => handleDelete(index)}
        >
          <Icon className={stylesheet.Icon} icon="solar:trash-bin-2-bold" />
        </Button>
      </div>
    );
  };

  React.useEffect(() => {
    if (authentication.ownerInfo && authentication.ownerInfo.additionalFields) {
      setInputs(
        convertObjectToArray(authentication.ownerInfo.additionalFields)
      );
      setInitialInputs(
        convertObjectToArray(authentication.ownerInfo.additionalFields)
      );
    }
  }, [authentication]);

  return (
    <div className={stylesheet.Wrapper}>
      <div className={stylesheet.Form}>
        <div className={stylesheet.Title}>
          <span>{t("components.settings.additionals.title")}</span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={stylesheet.Header}>
            <span>{t("components.settings.additionals.title")}</span>
            <span>{t("components.settings.additionals.subtitle")}</span>
          </div>
          <div className={stylesheet.Inputs}>
            {inputs.map((input, index) => renderInputs(input, index))}
            <Button
              isDisabled={inputs.length === MAX_INPUTS}
              clickHandler={handleAddInput}
              height="40px"
            >
              <span className={stylesheet.AddText}>
                {t("buttons.add_vars")}
              </span>
            </Button>
          </div>
          <div className={stylesheet.SubmitWrapper}>
            <Button
              clickHandler={handleReset}
              type="button"
              buttonStyle={ButtonStyle.border}
            >
              <span className={stylesheet.AddText}>{t("buttons.cancel")}</span>
            </Button>
            <Button type="submit" isDisabled={!isChanged || isLoading}>
              <span className={stylesheet.AddText}>{t("buttons.save")}</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdditionalVars;
