import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../utils/types";
import { useParams } from "react-router-dom";
import { FiraUserRole } from "../../utils/enums";

const UserPermissionsGuard = () => {
  const params = useParams();
  const role = useSelector(
    (state: RootState) => state.authentication.currentRole
  );

  return role === FiraUserRole.ACCOUNT_OWNER ||
    role === FiraUserRole.MANAGER ? (
    <Outlet />
  ) : (
    <Navigate to={"/"} />
  );
};

export default UserPermissionsGuard;
