import styles from "./InputMedia.module.scss";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Image } from "../FiraIcons";
import { useTranslation } from "react-i18next";
export enum InputMediaBorderStyle {
  normal = "normal",
  dashed = "dashed",
}

export enum InputMediaType {
  video = "video",
  image = "image",
}

interface InputMediaProps {
  width?: string;
  height?: string;
  borderStyle?: InputMediaBorderStyle;
  formats?: string;
  sizes?: string;
  mediaType: InputMediaType;
  onFileChange(file: File | null): void;
  isDisabled?: boolean;
  preview?: string;
}

const InputMedia = ({
  width,
  height,
  borderStyle = InputMediaBorderStyle.normal,
  formats,
  sizes,
  mediaType,
  onFileChange,
  isDisabled,
  preview,
}: InputMediaProps) => {
  const { t } = useTranslation();
  const style = { width, height };
  const classes = `${styles.InputMediaWrapper} ${
    styles[borderStyle as keyof typeof styles]
  }`;
  const mediaRef = useRef<HTMLInputElement>(null);
  const mediaFormRef = useRef<HTMLFormElement>(null);
  const [isMediaLoaded, setIsMediaLoaded] = useState<boolean>(false);
  const [mediaPreview, setMediaPreview] = useState<string>("");
  const [mediaFile, setMediaFile] = useState<File | null>(null);

  const onMediaClickHandle = () => {
    mediaRef.current?.click();
  };

  const onMediaInputHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const media = event.target.files[0];
      const url = URL.createObjectURL(media);
      setMediaPreview(url);
      setMediaFile(media);
      //Validate this
      setIsMediaLoaded(true);
    }
  };

  useEffect(() => {
    if (preview) {
      setMediaPreview(preview);
      setIsMediaLoaded(true);
    }
  }, [preview]);

  useEffect(() => {
    if (onFileChange) {
      onFileChange(mediaFile);
    }
  }, [mediaFile]);

  return (
    <div
      className={`${classes} ${isDisabled ? styles.InputMediaDisabled : ""}`}
      style={style}
    >
      <form ref={mediaFormRef} style={{ display: "none" }}>
        <input
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          ref={mediaRef}
          onChange={onMediaInputHandleChange}
        />
      </form>
      {!isMediaLoaded ? (
        <div className={styles.NoMedia} onClick={onMediaClickHandle}>
          <div className={styles.LoadIcon}>
            <Image fontSize={24} />
          </div>
          <label className={styles.LoadLabel}>
            {mediaType === InputMediaType.image
              ? t("components.inputMedia.loadImg")
              : t("components.inputMedia.loadVideo")}
          </label>
          {formats && (
            <div className={styles.Formats}>
              <span>{t("components.inputMedia.formats")}</span>
              <span>{formats}</span>
            </div>
          )}
          {sizes && (
            <div className={styles.Formats}>
              <span>{t("components.inputMedia.sizes")}</span>
              <span>{sizes}</span>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.LoadedImage} onClick={onMediaClickHandle}>
          <img src={mediaPreview} alt="Image preview" loading="lazy" />
        </div>
      )}
    </div>
  );
};

export default InputMedia;
