import React from "react";

import { EventStatus } from "../utils/enums";
import useSocket, { SocketEvents } from "../hook/useSocket";
import { useEvent } from "./EventProvider";
import { getLiveSumary } from "../services/events";
import { ResponseSummary } from "../utils/types";

interface ReducerState {
  likes: number;
  shares: number;
  clicks: number;
  popupClicks: number;
  connections: number;
  mobileConnections: number;
}
enum ActionType {
  FILL_VALUES = "FILL_VALUES",
  SET_LIKES = "SET_LIKES",
  SET_SHARES = "SET_SHARES",
  SET_CLICKS = "SET_CLICKS",
  SET_POPUP_CLICKS = "SET_POPUP_CLICKS",
  SET_CONNECTIONS = "SET_CONNECTIONS",
  SET_MOBILE_CONNECTIONS = "SET_MOBILE_CONNECTIONS",
}
interface Action {
  type: ActionType;
  payload?: Partial<ReducerState> | number;
}

const reducerInitialState: ReducerState = {
  likes: 0,
  shares: 0,
  clicks: 0,
  popupClicks: 0,
  connections: 0,
  mobileConnections: 0,
};
const reducer = (state: ReducerState, action: Action) => {
  switch (action.type) {
    case ActionType.FILL_VALUES:
      return {
        ...state,
        ...(action.payload as Partial<ReducerState>),
      };
    case ActionType.SET_LIKES:
      return {
        ...state,
        likes: action.payload as number,
      };
    case ActionType.SET_SHARES:
      return {
        ...state,
        shares: action.payload as number,
      };
    case ActionType.SET_CLICKS:
      return {
        ...state,
        clicks: action.payload as number,
      };
    case ActionType.SET_POPUP_CLICKS:
      return {
        ...state,
        popupClicks: action.payload as number,
      };
    case ActionType.SET_CONNECTIONS:
      return {
        ...state,
        connections: action.payload as number,
      };
    case ActionType.SET_MOBILE_CONNECTIONS:
      return {
        ...state,
        mobileConnections: action.payload as number,
      };
    default:
      return state;
  }
};

interface WebsocketState {
  eventCounters: ReducerState;
  fillEventCounters(counters: ReducerState): void;
  onMessageHandler(event: MessageEvent): void;
}
const WebsocketContext = React.createContext<WebsocketState | null>(null);

interface ProviderProps {
  children: React.ReactNode;
  firaSrc: string;
  status: EventStatus;
}
const WebsocketProvider: React.FC<ProviderProps> = ({
  children,
  status,
  firaSrc,
}) => {
  const [state, dispatch] = React.useReducer(reducer, reducerInitialState);
  const { loadCurrentPopups, removePopup, clearPopups } = useEvent();

  const fillEventCounters = (summary: ReducerState) => {
    dispatch({
      type: ActionType.FILL_VALUES,
      payload: summary,
    });
  };

  const addLikes = (likes: number) => {
    dispatch({ type: ActionType.SET_LIKES, payload: likes });
  };

  const addShares = (shares: number) => {
    dispatch({ type: ActionType.SET_SHARES, payload: shares });
  };

  const addClicks = (clicks: number) => {
    dispatch({ type: ActionType.SET_CLICKS, payload: clicks });
  };

  const addPopupClicks = (clicks: number) => {
    dispatch({ type: ActionType.SET_POPUP_CLICKS, payload: clicks });
  };

  const addConnections = (connections: number) => {
    dispatch({ type: ActionType.SET_CONNECTIONS, payload: connections });
  };

  const addMobileConnections = (connections: number) => {
    dispatch({ type: ActionType.SET_MOBILE_CONNECTIONS, payload: connections });
  };

  const onMessageHandler = (event: MessageEvent) => {
    const receivedEvent = JSON.parse(event.data);
    switch (receivedEvent.type) {
      case SocketEvents.LIKE:
        addLikes(parseInt(receivedEvent.content, 10));
        break;
      case SocketEvents.SHARE:
        addShares(parseInt(receivedEvent.content, 10));
        break;
      case SocketEvents.REGULAR_CLICK:
        addClicks(parseInt(receivedEvent.content, 10));
        break;
      case SocketEvents.POP_UP_CLICK:
        addPopupClicks(parseInt(receivedEvent.content, 10));
        break;
      case SocketEvents.WEB_CONN:
        addConnections(parseInt(receivedEvent.content.count, 10));
        break;
      case SocketEvents.MOBILE_CONN:
        addMobileConnections(parseInt(receivedEvent.content.count, 10));
        break;
      case SocketEvents.ADD_POPUP:
        loadCurrentPopups();
        break;
      case SocketEvents.REMOVE_POPUP:
        removePopup(receivedEvent.content.broadcastingProductId);
        break;
    }
  };

  const liveSummary = async (firaSrc: string) => {
    try {
      const response = await getLiveSumary(firaSrc);
      const summary = response.data as ResponseSummary;
      const counters = {
        likes: summary.likes,
        shares: summary.shares,
        clicks: summary.regularClicks,
        popupClicks: summary.popupClicks,
        connections: summary.webConnections,
        mobileConnections: summary.mobileConnections,
      };
      fillEventCounters(counters);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    switch (status) {
      case EventStatus.STARTED:
        liveSummary(firaSrc);
        break;
      case EventStatus.DONE:
        clearPopups();
        break;
      default:
        break;
    }
  }, []);

  return (
    <WebsocketContext.Provider
      value={{ eventCounters: state, fillEventCounters, onMessageHandler }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

const useWebSocket = () => {
  const context = React.useContext(WebsocketContext);

  if (!context) {
    throw new Error("You have to use the context inside WebsocketProvider");
  }

  return context;
};

export { WebsocketProvider, useWebSocket };
