import { useEffect, useState } from "react";
import UserPermissionsDetail from "./UserPermissionsDetail/UserPermissionsDetail";
import UserPermissionsTable from "./UserPermissionsTable/UserPermissionsTable";
import styles from "./UserRolesAndPermissions.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import { SimpleUserStoreItem } from "../../../types/Response";
enum viewType {
  TABLE = "TABLE",
  DETAIL = "DETAIL",
}

const UserRolesAndPermissions: React.FC = () => {
  const { authentication } = useSelector((state: RootState) => state);
  const [selectedStore, setSelectedStore] = useState("");
  const [activeView, setActiveView] = useState(viewType.TABLE);
  const [permissionStore, setPermissionStore] = useState<any[]>();
  const [selectedPermissionsList, setSelectedPermissionsList] =
    useState<any[]>();

  const handleSelectedId = (id: string) => {
    setSelectedStore(id);
  };
  useEffect(() => {
    if (selectedStore.length != 0) {
      setActiveView(viewType.DETAIL);
      const res = permissionStore?.find((obj) => {
        return obj.storeId;
      });
      if (res) {
        setSelectedPermissionsList(res);
      }
    } else {
      setActiveView(viewType.TABLE);
    }
  }, [selectedStore]);

  useEffect(() => {
    if (authentication.user) {
      setPermissionStore(authentication.user.permissionsStore);
    }
  }, [authentication]);

  return (
    <div className={styles.UsersPermissionsWrapper}>
      {
        {
          TABLE: (
            <UserPermissionsTable
              handleSelectedId={handleSelectedId}
              stores={permissionStore || []}
            />
          ),
          DETAIL: (
            <UserPermissionsDetail
              backButtonHandler={() => setSelectedStore("")}
              permissions={selectedPermissionsList || []}
            />
          ),
        }[activeView]
      }
    </div>
  );
};

export default UserRolesAndPermissions;
