import { RequestError } from "../../services/errorHandling";
import {
  FiraOwnerTeamMemberResponse,
  FiraUserListResponse,
  FiraUserResponse,
} from "../../types/Response";
import { OwnerTeamActions } from "../actionsContants";
import { OwnerActionType } from "./TeamActions";

export interface TeamState {
  isLoading: boolean;
  currentTeamMember: FiraUserResponse | null;
  list: FiraUserListResponse[];
  error?: RequestError;
}

const initialState: TeamState = {
  isLoading: false,
  currentTeamMember: null,
  list: [],
};

export const TeamReducer = (
  state = initialState,
  action: OwnerActionType
): TeamState => {
  switch (action.type) {
    case OwnerTeamActions.getTeamMemberLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case OwnerTeamActions.getTeamMemberSuccess: {
      const { payload } = action;

      return {
        ...state,
        isLoading: false,
        currentTeamMember: payload as FiraUserResponse,
      };
    }

    case OwnerTeamActions.getTeamMemberFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case OwnerTeamActions.getAllUsersLoading: {
      return { ...state, isLoading: true, error: undefined };
    }

    case OwnerTeamActions.getAllUsersSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        list: payload as FiraUserListResponse[],
      };
    }

    case OwnerTeamActions.getAllUsersFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case OwnerTeamActions.resetUsers: {
      return {
        ...state,
        isLoading: false,
        list: [],
        currentTeamMember: null,
        error: undefined,
      };
    }

    case OwnerTeamActions.resetCurrentUser: {
      return {
        ...state,
        isLoading: false,
        currentTeamMember: null,
        error: undefined,
      };
    }
    case OwnerTeamActions.updateUserStatusLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case OwnerTeamActions.updateUsersStatusSuccess: {
      const { payload } = action;

      let userList = state.list;

      userList.map((element) => {
        if (element.user.id.includes(payload as string)) {
          element.user.isActive = !element.user.isActive;
        }
      });

      return {
        ...state,
        isLoading: false,
        list: userList as FiraUserListResponse[],
        error: payload as RequestError,
      };
    }

    case OwnerTeamActions.updateUsersStatusFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    default: {
      return state;
    }
  }
};
