import { ReactNode, useState } from "react";
import { useClickOutside } from "../../utils/useClickOutside";
import { ArrowDown } from "../FiraIcons";
import Styles from "./CustomSelect.module.scss";

export enum CustomSelectStyle {
  main = "Main",
  light = "Light",
  border = "Border",
}
interface CustomSelectProps {
  selectId?: string; //identifica el select de forma opcional en caso de necesitar varios
  handleSelected(option: any, selectId?: string): void; //retorna el valor del seleccionado, y el id del select actual de forma opcional
  options: any[];
  value: string;
  indicatorColor?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  border?: boolean;
  center?: boolean;
  selectStyle?: CustomSelectStyle;
  icon?: ReactNode;
  backgroundColor?: string;
  padding?: string;
  listHeight?: string;
  showIcon?: boolean;
}

export interface optionType {
  id: number;
  name: string;
  value: string | number;
  color?: string;
  selectId?: string;
  image?: string;
}

const CustomSelect = ({
  selectId,
  handleSelected,
  options,
  value,
  indicatorColor,
  width = "113px",
  height = "22px",
  fontSize = "12px",
  border = false,
  center = false,
  selectStyle = CustomSelectStyle.light,
  icon = <ArrowDown />,
  backgroundColor,
  padding,
  listHeight = "142px",
  showIcon = true,
}: CustomSelectProps) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const domNode = useClickOutside(() => setShowOptions(false));
  const handleSelectedOption = (option: any, selectId?: string) => {
    setShowOptions(false);
    handleSelected(option, selectId && selectId);
  };
  return (
    <div
      className={`${Styles.CustomSelect} ${
        {
          Light: Styles.LightBackground,
          Main: Styles.MainBackground,
          Border: Styles.BorderStyle,
        }[selectStyle]
      }`}
      style={{ width: width, height: height }}
      ref={domNode}
    >
      <div
        style={{
          height: height,
          fontSize: fontSize,
          backgroundColor: backgroundColor,
          padding: padding,
        }}
        className={`${Styles.select} ${showOptions ? Styles.clicked : ""} 
        ${border ? Styles.border : ""} `}
        onClick={() => setShowOptions(!showOptions)}
      >
        <div
          className={`${Styles.innerElement} ${center ? Styles.center : ""}`}
        >
          {indicatorColor && (
            <div
              className={Styles.indicator}
              style={{ background: indicatorColor }}
            ></div>
          )}
          <div className={`${Styles.text} `}>{value}</div>
        </div>
        {showIcon && icon}
      </div>
      {showOptions && (
        <div
          style={{
            fontSize: fontSize,
            top: height,
            maxHeight: listHeight,
            backgroundColor: backgroundColor,
          }}
          className={`${Styles.list} ${border ? Styles.borderList : ""}`}
        >
          {options.map((op) => {
            return (
              <p
                key={op.id}
                className={`${Styles.element} ${
                  border ? Styles.borderElement : ""
                }`}
                onClick={() => handleSelectedOption(op, selectId && selectId)}
              >
                {indicatorColor && (
                  <span
                    style={{
                      backgroundColor: op.color,
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  ></span>
                )}
                {op.name}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
