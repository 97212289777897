import { t } from "i18next";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadImageService } from "../../../services/upload";
import { Image, TrashCan } from "../../FiraIcons";
import MainLoader from "../../MainLoader/MainLoader";
import styles from "./WidgetImageInput.module.scss";

interface WidgetImageInputProps {
  title?: string;
  hasImage?(val: boolean): void;
  onImage?(preview: string): void;
}

const WidgetImageInput = ({
  title,
  hasImage,
  onImage,
}: WidgetImageInputProps) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [imagePreview, setImagePreview] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onImageInputHandleChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    if (event.target.files) {
      const fileType = event.target.files[0].type;
      if (!fileType.match(/.(jpg|jpeg|png)$/i)) {
        return;
      }
      setIsLoading(true);
      // const img = URL.createObjectURL(event.target.files[0]);
      const img = await uploadImageService(event.target.files[0]);

      setImagePreview(img.data.images[0]);
      if (onImage) {
        onImage(img.data.images[0]);
      }
      setIsLoading(false);
    }
  };

  const onImageClickHandle = () => {
    inputRef.current?.click();
  };

  const clearImage = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setImagePreview("");
    formRef.current?.reset();
    if (onImage) {
      onImage("");
    }
  };

  useEffect(() => {
    if (hasImage) {
      imagePreview !== "" ? hasImage(true) : hasImage(false);
    }
  }, [imagePreview]);

  return (
    <>
      {isLoading && <MainLoader />}
      <div className={styles.WidgetImageInputWrapper}>
        <div className={styles.WidgetImageInputWrapper__Title}>{title}</div>
        <div
          className={styles.WidgetImageInputWrapper__Banner}
          onClick={onImageClickHandle}
        >
          <form ref={formRef} style={{ display: "none" }}>
            <input
              ref={inputRef}
              type="file"
              accept="image/jpeg, image/jpg, image/png"
              onChange={onImageInputHandleChange}
            />
          </form>
          {imagePreview !== "" && (
            <div
              className={`${styles.WidgetImageInputWrapper__Banner__Delete}`}
              onClick={clearImage}
            >
              <TrashCan color={imagePreview !== "" ? "#f16063" : "#D0D0D0"} />
            </div>
          )}

          {imagePreview === "" ? (
            <div className={styles.UploadHere}>
              <div className={styles.UploadHere__Icon}>
                <Image />
              </div>
              <label>{t("components.widgetImageInput.load")}</label>
            </div>
          ) : (
            <div className={styles.Preview}>
              <img src={imagePreview} alt="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WidgetImageInput;
