import {
  Cross,
  DangerCircle,
  SuccessCircle,
  WarningCircle,
} from "../FiraIcons";
import styles from "./PopUpAlert.module.scss";

export enum PopUpAlertTypes {
  error = "error",
  success = "success",
  warning = "warning",
}

export interface PopUpButtonsTypes {
  title: string;
  handleClick(): void;
  type: "border" | "fill";
}

interface Props {
  type?: PopUpAlertTypes;
  handleCloseIcon?(): void;
  title?: string;
  message?: string;
  buttons?: PopUpButtonsTypes[];
  clickText?: string;
  handleClickText?(): void;
  width?: string;
}

/**
 * Description of PopUpComponent
 * @prop {PopUpAlertTypes} type - type of Pop Up Alert: Error | Success | Warning
 * @prop {function} handleCloseIcon - handler for the close icon
 * @prop {string} title - title to display inside pop up
 * @prop {string} message - message to display inside pop up
 * @prop {PopUpButtonsTypes[]} buttons - an array of PopUpButtonsTypes for render the buttons
 * @prop {string} clickText - a decorated Text to click inside the pop up message
 * @prop {function} handleClickText - handler for the clickText
 * @prop {string}  width - tells the pop up width
 */

const PopUpAlert = ({
  type = PopUpAlertTypes.error,
  title,
  message,
  buttons,
  clickText,
  width,
  handleClickText,
  handleCloseIcon,
}: Props) => {
  let borderStyle = "";
  switch (type) {
    case PopUpAlertTypes.warning:
      borderStyle = styles.warning;
      break;
    case PopUpAlertTypes.success:
      borderStyle = styles.success;
      break;
    case PopUpAlertTypes.error:
      borderStyle = styles.error;
      break;
  }
  return (
    <div className={styles.PopUpAlertContainer} style={{ width: width }}>
      <div className={`${styles.PopUpALertWrapper} ${borderStyle}`}>
        <div className={styles.header}>
          <div>
            {
              {
                warning: <WarningCircle />,
                success: <SuccessCircle />,
                error: <DangerCircle />,
              }[type]
            }
          </div>
          <p className={styles.title}>{title}</p>
          {handleCloseIcon && (
            <div className={styles.closeIcon} onClick={handleCloseIcon}>
              <Cross />
            </div>
          )}
        </div>
        {message || clickText ? (
          <div className={styles.content}>
            <p className="body-14-text">{message}</p>
            <p onClick={handleClickText} className={styles.boldText}>
              {clickText}
            </p>
          </div>
        ) : (
          ""
        )}
        {buttons && (
          <div className={styles.buttonsGroup}>
            {buttons?.length > 0 &&
              buttons.map((button, i) => {
                return (
                  <button
                    key={i}
                    onClick={button.handleClick}
                    className={`
                  ${styles.button} ${
                      button.type === "border" ? styles.border : styles.fill
                    }
                  ${borderStyle}`}
                  >
                    {button.title}
                  </button>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PopUpAlert;
