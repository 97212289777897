import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgTrashCan = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.23 9.045c.366 0 .67.303.67.689v.357c0 .376-.304.689-.67.689H7.67a.685.685 0 0 1-.67-.69v-.356a.68.68 0 0 1 .67-.69h2.738c.556 0 1.04-.394 1.165-.952l.143-.64C11.94 6.579 12.672 6 13.512 6h3.877c.83 0 1.571.58 1.786 1.405l.153.686c.124.559.608.954 1.165.954h2.738Zm-1.39 13.043c.286-2.664.786-8.992.786-9.056a.7.7 0 0 0-.17-.524.682.682 0 0 0-.491-.22H8.942a.663.663 0 0 0-.491.22.742.742 0 0 0-.18.524c.002.012.02.235.05.607.134 1.656.505 6.267.745 8.449.17 1.607 1.225 2.617 2.752 2.654 1.18.027 2.394.036 3.636.036 1.17 0 2.357-.009 3.572-.036 1.581-.027 2.635-1.02 2.814-2.654Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTrashCan;
