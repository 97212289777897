import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgInfo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5 0a5 5 0 1 0 0 10A5 5 0 0 0 5 0Zm0 2.143a.536.536 0 1 1 0 1.071.536.536 0 0 1 0-1.071Zm1.429 5.759H3.57v-.804h1.027V5.045h-.67V4.24h1.474v2.857h1.027v.804Z"
      fill="#D0D0D0"
    />
  </svg>
);

export default SvgInfo;
