import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgStatistics = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4 19.714a1.571 1.571 0 0 1 1.571-1.571h3.143a1.571 1.571 0 0 1 1.572 1.571v4.715A1.571 1.571 0 0 1 8.714 26H5.571A1.571 1.571 0 0 1 4 24.429v-4.715Zm7.857-6.285a1.571 1.571 0 0 1 1.572-1.572h3.142a1.571 1.571 0 0 1 1.572 1.572v11A1.571 1.571 0 0 1 16.57 26H13.43a1.571 1.571 0 0 1-1.572-1.571v-11Zm7.857-7.858A1.571 1.571 0 0 1 21.286 4h3.143A1.571 1.571 0 0 1 26 5.571V24.43A1.571 1.571 0 0 1 24.429 26h-3.143a1.571 1.571 0 0 1-1.572-1.571V5.57Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgStatistics;
