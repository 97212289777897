export const TRANSLATIONS_EN = {
  auth: {
    express: {
      fira: {
        success_description:
          "This is the web address of my personal site. Save it and share it with your friends.",
        failed_description:
          "Oops! It seems that something went wrong. Please try again.",
      },
      title: "Create your Unique link",
      success_title: "Hi ❤️",
      success_description:
        "This is your Unique Link for you and your customers to access Mary Kay Live Shopping. Copy and share it!",
      failed_description: "Welcome!!!",
      disabled: {
        title: "Dear user",
        message:
          "Login is currently inactive. Please contact the admin team for more information.",
        closing: "Thank you for your understanding.",
      },
    },
    header: {
      welcome_back: "Welcome back",
      sign_up: "Sign Up now!",
      recovery: "Password recovery",
      pwdcode: "Recovery code",
      changepwd: "Change password",
      activate: "Activation code",
      invitation_message:
        "The brand {referer} has invited you to become part of it's team",
    },
    tabs: {
      login: "Log in",
      create_account: "Create account",
    },
    login: {
      message: "Passwords must be at least 8 characters.",
      user: "Username / Email",
      password: "Password",
      password_recovery: "Restore password",
      button: "Log in",
    },
    password_recovery: {
      message: "We will send you instructions to reset your password.",
      email: "Email",
      button: "Send",
    },
    pwdcode: {
      message:
        "The recovery code was sent to your email. Please enter the code:",
      recovery_message: "Didn't get the recovery code?",
      resend: "Resend",
      button: "Send",
    },
    changepwd: {
      new_pass: "New password",
      confirm_pass: "Confirm password",
      button: "Log in",
    },
    alerts: {
      registration_success: "Your password has been changed successfully",
      activation_success: "Your account has been successfully activated",
    },
    register: {
      name: "First Name",
      lastname: "Last Name",
      email: "Email",
      username: "Username",
      password: "Password",
      confirm_pass: "Confirm Password",
      terms_and_conditions: "By registering an account, you agree to our",
      terms_and_conditions_link:
        "Terms of Service and Privacy Policy and Cookies.",
      button: "Create Account",
      message: "* Use at least 8 characters",
    },
    activation: {
      message:
        "The activation code was sent to your email. Please enter the code:",
      resend_message: "Didn't get the activation code?",
      resend: "Resend",
      button: "Send",
    },
    messages: {
      error: {
        username_not_found: "You do not have a FIRA account",
        create_account: "Create account",
        email_not_found: "You do not have a FIRA account",
        not_activated: "You do not have a FIRA account",
        invalid_password: "Incorrect credentials",
        wrong_credentials: "Have you made a mistake?",
        unavailable_user: "User or e-mail already registered",
        recover_password: "Do you want to recover your password?",
        invalid_user_code: "Incorrect activation code",
        try_again: "Try again",
        expired_user_code: "Invalid activation code",
        request_new_code: "Request new one",
        default: "We have encountered an error try again",
      },
    },
  },

  profile: {
    edit_profile_modal: {
      title: "Personal Profile",
      firstName: "FirstName",
      lastName: "LastName",
      birthdate: "Date of birth",
      gender: "Gender",
      gender_radio: {
        other: "Other",
      },
      telephone: "Telephone number",
      email: "Email",
      username: "User",
      password: "Password",
      repeatPassword: "Repeat Password",
      cancel: "Cancel",
      submit: "Submit",
    },
  },
  views: {
    products: {
      title: "Products",
      exportButton: "Export",
      importButton: "Import",
      addProductButton: "Add Product",
      tableHeader: {
        name: "Name",
        inventory: "Inventory",
        price: "Price",
        priceWithTaxes: "Price",
        extraPrice: "Price",
        discount: "Discount",
        visibility: "Visibility",
      },
      detailProduct: {
        newProduct: "New Product",
        save: "Save",
        edit: "Edit",
        saving: "Saving..",
        saved: "Product saved",
        imageError: "file is not an image",
        error: "We have encountered an error try again",
        discard: "Discard",
        form: {
          name: "Name",
          SKU: "SKU",
          skuInfo: "Unique inventory code assigned to a product",
          exterId: "External Id",
          exterIdInfo: "Unique code of a product generated by e-commerce",
          inventory: "Inventory",
          URL: "URL",
          price1: "Price",
          price2: "Price 2",
          price3: "Price 3",
          media: "Media elements",
          discount: "Price with discount",
          optional: "Discount (optional)",
          images:
            "The featured image is the one that appears first on the cover of the product during the transmission",
          retrieve: "Retrieve",
          addImage: "Max size 1080px x 1080px",
          description: "Description",
        },
        origin: "Origin",
        categoryTitle: "Categories",
        variationOption: "Variation Options",
        addButton: "Add",
        delete: "Delete",
        placeholderSearch: "Tap to search...",
        variant: "Variant",
        inventory: "Inventory",
        price1: "Price 1",
        price2: "Price 2",
        labels: "Labels",
        label: "Label",
        example: "e.g.: Offer",
        addImageVar: "Assign Image to a Variant",
        cancel: "Cancel",
        ready: "Ready",
        variationName: "Variation Name",
        variationValue: "Variation Values",
        addValue: "Add Value",
        fileFormat: "File format",
        sizeRecommended: "Recommended size",
        maximumWeight: "Maximum file weight",
        addVariant: "Add variant",
        emptyVariant: "All variations were added",
        errorImages: "Only images in .jpg .jpeg .png format can be uploaded",
        stores: "Stores",
        search: "Tap to search...",
      },
      visibiliy: {
        owner: "Owner",
        total: "Total",
        partial: "Partial",
      },
    },
    events: {
      title: "Events",
      status: {
        broadcast: {
          scheduled: "Scheduled",
          started: "Started",
          not_started: "Not started",
          cancelled: "Cancelled",
          done: "Finalized",
          config: "Configure",
        },
        test: {
          scheduled: "Scheduled test",
          started: "Test started",
          not_started: "Test not started",
          cancelled: "Test canceled",
          done: "Finalized",
          config: "Configure",
        },
      },
      header: {
        listButton: "Events List",
        today: "Today",
        day: "Day",
        month: "Month",
        week: "Week",
        eventName: "Event name",
        date: "Date",
        startHour: "Start hour",
        endHour: "Finish hour",
        products: "Products",
        status: "Status",
        options: "Options",
        filter: "Tap to Search...",
      },
      table: {
        products: "products",
        statusOptions: {
          scheduled: "Scheduled",
          initEvent: "Init Event",
          live: "Live",
          finished: "Finished",
          cancelled: "Cancelled",
        },
      },
      new_popup: {
        event_name: "Event name",
        placeholderSearch: "Search store...",
        start_time: "Date",
        time: "Time",
        button_create: "Create Live",
        button_create_test: "Create test live",
        placeholder: "DD/MM/YY",
        simulcastEvent: "Simulcast event",
        independentEvents: "Independent events",
        infoSimulcast:
          "Broadcast an event simultaneously in several stores, which will be carried out by the main account",
        infoIndependent:
          "Schedule an independent event simultaneously for multiple stores",
      },
      popup: {
        event_name: "Name of the event",
        start_time: "start time",
        end_time: "End time",
        button_create: "Create Event",
      },
      popup_top: {
        event_name: "Name of the Live",
        start_time: "Start Date",
        time: "Time",
        button_create: "Create Live",
        button_create_test: "Create test live",
        placeholder: "DD/MM/YY",
      },
      popup_option: {
        moderation: "Moderate",
        statistics: "Transmission Detail",
        edit_event: "Edit",
        cancel_event: "Cancel",
        event_canceled: "Event Canceled",
      },
      eventDetail: {
        moderationTitle: "{{eventName}} | Moderation",
        statisticsTitle: "{{eventName}} | Statistics",
        credentials: "Credentials",
        statistics: "Summary LIVE",
        postStats: "Summary POST-LIVE",
        moderation: "Moderation",
        likes: "Likes",
        shares: "Shares",
        connections: "Audience",
        averageConnectionTime: "Average connection time",
        connectionTime: "Connection time",
        engagement: "Engagement",
        bouncingRate: "Bouncing rate",
        averageClicks: "Clicks",
        chatMessages: "Chat messages",
        liveTime: "Live time",
        start: "Start Live",
        finish: "End Live",
        startTest: "Start test",
        finishTest: "End test",
        exportData: "Export",
        processing: "Processing...",
        infoId: "Unique event identifier",
        messages: {
          copy_success: "copy success!",
          invalid_id: "Stream ID {{streamId}} invalid",
          not_started:
            "Can not finish a broadcast with status different than started",
          no_value: "Error 500, No value present",
          other_started: "There is another event started",
          red5_error:
            "Credentials cannot be generated at the moment, try again later.",
          cant_generate_stats:
            "An error occurred while downloading the statistics, please review the store configuration",
        },
        instructions: {
          title: "Start the live",
          instruction: "If the credentials do not exist, click on credentials",
        },
        fakeChat: {
          title: "No chats yet",
          subTitle: "Send a message to start the conversation.",
          refresh: "Refresh the chat to be able to interact.",
          refreshButton: "Refresh",
        },
        products: {
          title: "Popup Windows",
          messages: {
            popupError: "You can only have 2 products as highlighted",
          },
          filter: "Tap to Search....",
          add: "Add",
          highlight: "Highlight",
        },
        chat: {
          input: "Text..",
        },
        skeleton: {
          message:
            'Start the transmission from your favorite streaming software and click "refresh" to start watching the LIVE preview',
          refresh: "Refresh",
          finished: "Event ended",
        },
        users_table: {
          title: "Viewers data",
          subtitle: "Data captured during the live event",
          export: "Export",
          filter: "Tap to Search....",
          username: "Name",
          email: "Email",
          phone: "Phone",
          bpaCount: "Products Clicks",
        },
        products_clicks: {
          title: "Product clicks",
          subtitle: "Exact number of clicks per product",
          product: "Product",
          clicks: "Clicks",
          interactionTitle: "Clicks to product interaction",
          interactionSubtitle: "Exact number of clicks by product",
          gallery: "Gallery",
          highlighted: "Highlighted",
          interactionTotal: "Total interaction",
          buyTitle: "Clicks on product with purchase intention",
          buySubtitle: "Exact number of clicks to “buy” per product",
          buyIntent: "Purchase intent",
        },
        player: {
          connecting: "Connecting",
          reconnecting: "Reconnecting",
          finished: "Event ended",
          refresh: "Refresh",
          text_1: "You are experiencing connection difficulties,",
          text_2: "press the refresh button",
          text_3: "to reconnect",
        },
        statiticsChart: {
          title: "Live statistics",
          subtitle: "Space to perform in versus between specific metrics",
          likes: "Likes",
          shares: "Shares",
          connections: "Connections",
          messages: "Messages",
          clicks: "Clicks",
        },
        concurrencyChart: {
          title: "Concurrency",
          subtitle:
            "Exact amount of user assistance per minute during the live",
          connections: "Connections",
        },
        optionsDropdown: {
          likes: "Likes",
          shares: "Shares",
          clicks: "Clicks",
          messages: "Messages",
        },
      },
      calendar: {
        showMore: " more",
        moderate: "Moderate",
        detail: "Transmission detail",
        canceled: "Canceled",
        config: "Configure",
        modList: {
          title: "Participants",
          roles: {
            admin: "Admin",
            seller: "Seller",
            proseller: "Proseller",
            operator: "Operator",
            manager: "Manager",
            moderator: "Moderator",
            account_owner: "Account owner",
          },
        },
      },
    },
    users: {
      title: "Team",
      active: "Active",
      inactive: "Inactive",
      add: "Add a member",
      table: {
        name: "Name",
        email: "Email",
        phone: "Telephone",
        permissions: "Permissions",
        total: "Total",
        partial: "Partial",
        none: "None",
        inactive: "Inactive",
        admin: "Administrator",
      },
      messages: {
        update:
          "Permissions for {{firstName}} {{lastName}} have been successfully modified",
        invitation: "Invitation sent successfully",
        error_user_currently: "User currently in this Store",
      },
    },
    edit_permissions: {
      title: "Team - {nameToShow}",
      dismiss_button: "Dismiss",
      save_button: "Save",
      role: "Role:",
      select_operator: "Select the role of the operator",
      profile: "Profile:",
      select_operator_profile: "Select the operator profile",
      roles: {
        admin: "Admin",
        seller: "Seller",
        proseller: "Proseller",
        operator: "Operator",
        manager: "Manager",
        moderator: "Moderator",
        account_owner: "Account owner",
      },
    },
    dashboard: {
      title: "Dashboard",
      infoText: "View the summary of your statistics",
      transmissionsNumber: "Number of lives",
      testTransmissionsNumber: "Test lives",
      transmissionsNumberDesc:
        "Number of events held in the selected date range",
      testTransmissionsNumberDesc:
        "Number of test events held in the selected date range",
      transmissionsTime: "Time of lives",
      transmissionsTimeDesc: "Time spent during your LIVE sessions",
      engagement: "Engagement",
      engagementDesc:
        "Percentage of interaction of active users during the LIVE. ",
      connections: "Visualizations",
      connectionsDesc:
        "Number of people who viewed the live according to device",
      rate: "Bounce rate",
      rateDesc: "Percentage of users who did not interact during the LIVE. ",
      time: "Average watch time",
      timeDesc: "Average stay of customers in your LIVE sessions",
      timeVisual: "Watch time",
      testingLives: "Testing lives",
      audienceCount: "Amount of audience",
      totalAudience: "Total audience",
      reproductionTotal: "Total playback",
      reproductionAverage: "Reproduction average",
      transmissionTime: "Transmission time",

      events: "Events",
      Metrics: "Metrics",
      titleTable: "Recent events",
      nextsEvents: "Events",
      subTitleTable: "View in this space the finished lives",
      from: "From",
      to: "To",
      noEvents: "No events",
      showEvents: "Show events",
      table: {
        name: "Name",
        date: "Date",
        products: "Products",
        likes: "likes",
        shares: "Share ",
        audience: "Audience",
      },
      MetricsSelect: {
        audience: "Audience",
        conversion: "Conversion",
        likes: "likes",
        shares: "shares",
      },
      dateRangeSelect: {
        personalized: "Personalized",
        today: "Today",
        yesterday: "Yesterday",
        lastSeven: "Last 7 days",
        lastThirty: "Last 30 days",
        lastNinety: "Last 90 days",
        lastMonth: "Last month",
        lastYear: "Last year",
        thisWeek: "This week to date",
        thisMonth: "This month to date",
        thisTrimester: "This quarter to date",
      },
      greetings: {
        goodMorning: "Good morning",
        goodAfternoon: "Good afternoon",
        goodEvening: "Good evening",
        welcome: "Welcome",
        toAccount: "to account",
      },

      reproductionAccum: "Total Playback Time",
    },
    dashboardOwner: {
      title: "Dashboard",
      infoText: "Your performance summary for this month ",
      widgetStatsStoreTitle: "Stats Percentage",
      widgetStatsStoreSubTitle:
        "Compare and contrast the clicks generated by each store ",
      tableWidgetTitle: "Managed Stores",
      tableWidgteSubTitle:
        "In this space you can see all the stores of the account",
      livesCount: "Number of lives",
      transmisionCount: "Transmission time",
      audienceTotal: "Total audience",
      livesTest: "Test lives",
    },
    dashboardOwnerVideos: {
      audienceTotal: "Total audience",
      store: "Store",
      audience: "Audience",
      viewsAverage: "Views average",
      viewsTotal: "Views total",
      tableDescription:
        "In this space you can view all the stores in the account with their audience and number of reproductions, average and total",
    },

    firaTv: {
      title: "Widgets",
      settings: "Settings",
      sectionTitle: "Section title",
      backgroundColor: "Background Color",
      nextEvents: "Next events",
      pastEvents: "Previous events",
      selectWidget: "Select a widget from the list",
      selectEventType: "Select the videos to show",
      copyCode: "Copy the following code into your web page",
      copy: "Copy",
      selectEventColors: "Select color",
      desktop: "Desktop view",
      mobile: "Mobile view",
      widgetSelectionView: {
        title: "Settings @widget",
        searchbar: "Click to search...",
        cards: "Cards",
        mosaic: "Mosaic",
        banners: "Banners",
        collections: "Collections",
        carousel: "Carousel",
        calendar: "Calendar",
        scheduled: "Scheduled",
        thumbnails: {
          collectionCarousel: "Collection carousel",
          comingSoon: "Coming soon",
          collectionCard: "Collection card",
          vodGallery: "VOD gallery",
          bannerCommercial: "Commercial banner",
          bannerSlider: "Banner slider",
          bannerLive: "Top banner - live or VOD",
          categories: "Categories",
        },
      },
      widgets: {
        cards: {
          COLLECTION_CARDS: {
            primaryTextColor: "Upcoming events, event name, description",
            secondaryTextColor: 'Date and time, "see more"',
            buttonTextColor: "Button text",
            primaryColor: "Details and button",
            cardColor: "Event cards",
            backgroundColor: "Background",
            infoCardDescription:
              "Use this widget to present your audience with a collection of videos, with information about when they were made and a brief description of each one.",
            title: "Collection card",
          },
        },
        scheduled: {
          COMING_SOON: {
            title: "Coming soon",
            infoCardDescripttion:
              "Use this widget to inform your audience about one of the scheduled events, including the time remaining until its start, and offer them the option to receive notifications when it starts.",
            primaryTextColor: "Event name, description, timer",
            displayColor: "Display",
            buttonTextColor: "Button text",
            primaryColor: "Details and button",
            backgroundColor: "Background",
          },
        },
        carousel: {
          COLLECTION_CAROUSEL: {
            title: "Collection carousel",
            infoCardDescription:
              "Use this widget to highlight the most relevant events in your collections to your audience. With this widget you can show the category, the name of the event and the date it took place. Two events will be displayed at once.",
            primaryTextColor: "Featured Events",
            displayColor: "Display",
            primaryColor: "Details",
            backgroundColor: "Background",
          },
        },
        grid: {
          VOD_GALLERY: {
            title: "VOD gallery",
            infoCardDescription:
              "Use this widget to show your audience the videos stored in the collections library. Also, this widget can serve as a redirect page in combination with other widgets that also redirect to collections. With this widget, you can change the organization and filters of events.",
            primaryTextColor: '"FILTERS"',
            secondaryTextColor: 'Checkbox, "see more"',
            tertiaryTextColor: "Filter name",
            primaryColor: "Categories text color",
            secondaryColor: "# of videos in category",
            tertiaryColor: "Sort button background",
            quaternaryColor: "Selected order",
            quinaryColor: "Not selected order",
            backgroundColor: "Background",
          },
        },
        banners: {
          SLIDER: {
            title: "Banner slider",
            infoCardDescription:
              "Use this slider banner widget to display the latest events on the front page.",
            primaryTextColor: "Collection name",
            secondaryTextColor: "Category name",
            tertiaryTextColor: "Button text color",
            primaryColor: "Button color",
            secondaryColor: "Active slide and arrows color",
            tertiaryColor: "Arrows background color",
            quaternaryColor: "Inactive slide",
          },
          COMMERCIAL: {
            title: "Commercial Banner",
            infoCardDescription:
              "Use this widget to introduce your audience to a featured event or direct them to a specific collection.",
            input: "VIDEO ASSOCIATED TO THE BANNER",
          },
          TOP: {
            title: "top banner - live or VOD",
            infoCardDescription:
              "Use this widget to keep your users updated about live videos or a particular video that you have selected.",
          },
        },
        collections: {
          CATEGORIES: {
            title: "Categories",
            infoCardDescription:
              "Use this widget to show your audience the various categories of events that have been broadcast. On the main page, they will be displayed with a representative image and the name of the category.",
          },
        },
      },
      exampleItems: {
        eventName: "Event name",
        fakeDate: "Monday, December 2022",
        titlePlaceholder: "Section title",
        eventDescription: "Event description",
      },
    },
    videos: {
      route: "Videos",
      title: "Video Gallery",
      table: {
        name: "Video",
        type: "Type",
        visibility: "Visibility",
        date: "Transmission date",
        category: "Category",
        options: {
          hide: "Hide",
          visible: "Visible",
        },
        state: {
          processing: "Processing...",
          processed: "Processed",
          failed: "Failed",
        },
      },
      filter: "Tap to Search...",
      filterOptions: {
        recent: "Most Recent",
        older: "Older",
        viewed: "Most viewed",
        alphabet: "A to Z",
        reverse: "Z to A",
        like: "More likes",
        share: "Most shared",
      },
      steps: {
        generalData: "General Data",
        video: "Video",
        products: "Products",
        comments: "Comments",
        next: "Next",
        end: "Finish",
      },
      editVideo: {
        general_details: {
          title: "General information",
          message: "Complete the following information related to your event",
          forms: {
            event_name: "Video name",
            datetime: "Date and Time",
            desc: "Video description",
            select_categories: "Select categories",
            load_teaser: "Upload teaser",
            load_image: "Upload image",
            optional: "(Optional)",
            teaser_info: "Video with teaser of the event",
            file_format: "File format",
            recommended_size: "Recommended size",
            image_info: "Cover image for your event",
            placeholder: "Tap to Search....",
          },
        },
      },
      errors: {
        category: "Can not delete all categories",
        stats: "An error occurred while downloading the statistics.",
      },
      labels: {
        titleStats: "General Data",
        subtitleStats: "Space to perform a comparison between specific metrics",
        current: "Today",
        yesterday: "Yesterday",
        lastWeek: "Last week",
        lastTwoWeeks: "Last two weeks",
        lastMonth: "Last Month",
        lastTrimester: "Last Trimester",
        lastSemester: "Last semester",
        lastYear: "Last Year",
        yourStores: "Your stores",
        titlePieChart: "Stats Percentage",
        subtitlePieChart:
          "Compare the following data between each of the stores.",
      },
      selects: {
        filter: "Filter",
      },
      productList: {
        title: "Star products",
        subtitle: "Products with the highest interaction by store",
      },
      previewLink: "Preview",
    },
    collections: {
      title: "Collections",
      titlePage: "Video collections",
      createCollection: "Create collection",
      table: {
        name: "Collection name",
        date: "Creation date",
        videos: "Videos",
        id: "Collection ID",
      },
      details: {
        newTitle: "New collection",
        discard: "Discard",
        save: "Save",
        collectionName: "Collection name",
        description: "Description",
        cover: "Cover picture for your collection",
        listTitle: "Collection videos",
        novideos:
          "There are no videos in this collection,\n search or explore to add videos.",
        table: {
          name: "Name",
          desc: "Description",
          teaser: "Teaser",
          image: "Image",
          products: "Products",
          addButton: "Add",
        },
        delete: "Delete collection",
        deletAlert: "Do you wish to delete {{collectionName}}?",
        deleteMessage: "This action can't be undone.",
        cancel: "Cancel",
        saved: "Collection saved successfully",
        discardAlert: "Do you want to save before leaving the page?",
        discardMessage: "Leaving this page will remove all unsaved changes.",
        leavePageButton: "Leave page",
        saveAndLeave: "Save and leave",
        error: "An error has occurred. Please try again",
      },
    },
    stores: {
      title: "Stores",
      createBtn: "Create store",
      importBtn: "Import",
      exportBtn: "Export",
      table: {
        storeName: "Name",
        status: "Status",
        storeId: "Store ID",
        detailsBtn: "See details",
        accessBtn: "Access",
        role: "Role",
      },
      status: {
        onhold: "On hold",
        active: "Active",
        suspended: "Suspended",
        canceled: "Canceled",
        done: "Done",
        deactivated: "Inactive",
        deleted: "Deleted",
        approved: "Approved",
        pending: "Pending",
        rejected: "Rejected",
        inprocess: "In process",
        valid: "Valid",
        invalid: "Invalid",
      },
      createSuccess: "¡You have successfully created the store!",
      details: {
        accessBtn: "Access the store",
        createdDate: "Created on",
        contact: "Contact",
        country: "Country",
        currency: "Currency",
        city: "City",
        website: "Website",
        editBtn: "Edit information",
        transferBtn: "Transfer ownership",
        deleteBtn: "Delete store",
        table: {
          name: "Operator Name",
          status: "Status",
          role: "Role",
          operatorId: "Operator ID",
          seeDetails: "See details",
          roles: {
            admin: "Admin",
            seller: "Seller",
            proseller: "ProSeller",
            operator: "Operator",
            manager: "Manager",
            account_owner: "Account Owner",
            moderator: "Moderator",
          },
        },
      },
      error: "An error has occurred. Please try again.",
      storeDeleteSuccess: "The store was successfully removed!",
    },
    team: {
      title: "Team",
      invites: "Invites",
      name: "Name",
      status: "Status",
      seeProfileBtn: "See profile",
      teamMember: "Create team member",
      inviteMemeber: "Invite team member",
      details: {
        title: "Stores",
        table: {
          storeName: "Store Name",
          role: "Role",
          lastAccess: "Last access",
          permissions: "See permissions",
          deleteUserButton: "Delete operator",
        },
      },
    },
    ownerCalendar: {
      title: "Calendar / Events",
    },
    operators: {
      title: "Add new operator",
      invitation_title: "Operator information",
      invitation_desc:
        "Complete the general data of your new operator to continue with the process.",
      nextBtn: "Next",
      prevBtn: "Return",
      createBtn: "Create",
      inviteBtn: "Invite",
      name: "Operator name",
      email: "Email",
      operator_role: "Operator role",
      operator_role_desc:
        "Choose the role that the operator you are creating will occupy.",
      operator_name: "Operador",
      operator_desc:
        "This profile will have limitations of functions within the service, according to the level assigned to it, it must have a supervisor who can see or modify its actions.",
      operator_profile: "Profile: Operator",
      operator_profile_desc: "Select the modules you want this user to access",
      admin_name: "Admin",
      admin_desc:
        "This profile will be in charge of managing the store under his responsibility, he is in charge of managing the lives he performs.",
      admin_profile: "Profile: Admin",
      admin_profile_desc:
        "Choose the service in which the admin will be assigned",
      moderator_name: "Moderator",
      moderator_desc:
        "This profile will be in charge of interacting in the chat of the lives to which it is assigned, it will have message handling, pop ups, among other certain functions for a live.",
      moderator_profile: "Profile: Moderator",
      moderator_profile_desc:
        "Choose the service in which the moderator will be assigned",
      error: "Data not loaded, please try again!",
      duplicated_invitation: "There is another invitation pending for the user",
      himself_invitation: "You can not create an account for yourself",
      already_invited: "The user was already added to the store",
      success: "Invitation sent to email: ",
    },
    landing: {
      title: "Fira Onlive",
      tag: "New",
      subLinks: {
        config: "Configuration",
        landing: "Landing",
        preview: "Preview",
      },
      backBtn: "Back",
      saveBtn: "Save",
      createBtn: "Create",
      editBtn: "Edit Url",
      infoLineOne: "Edit and customize the ending of your URL.",
      infoLineTwo: "Example: firaonlive.com/exampleurl",
      storeName: "LandingName",
      page: {
        title: "Page",
        headerHeight: "Header height in px",
        colorStyle: "Color style",
        background: "Background",
        header: "Header",
        footer: "Footer",
        logoAlignment: "Logo alignment",
        left: "Left",
        center: "Center",
        shoppingCart: "Shopping cart",
        shoppingCartSwitch: "Add shopping cart",
      },
      widgets: {
        widgetStyle: "Widget Style",
        titleLabel: "Title",
        screen: "Screen",
        details: "Details",
        background: "Background",
        widgetsDesc: "Select events to show",
        collection: "Collection associated with the widget",
        collectionPlaceholder: "Collection name",
        collectionError: "You must select a collection for your widget",
        selectWidget: "Select widget",
        featured: "Featured lives",
        featuredInfo:
          "By activating this widget, a series of VODs will be displayed.",
        featuredProducts: "Featured products",
        featuredProductsInfo:
          "To use this widget, the shopping cart must be activated.",
        products: "Products",
        widgetTitle: "Widget title",
        searchInput: "Tap to search...",

        widgetProductsErrorV2:
          "You must select at least one product for the featured products widget.",
      },
      typography: {
        title: "Typography",
        body: "Content",
        footer: "Footer",
      },
      catalog: {
        title: "Catalog",
        moduleTitle: "Online Catalog",
        toggleLabel: "Activate product catalog",
        products: "Products",
        desc: "Select the products that will appear in the catalog section.",
        all: "Select all",
      },
      createSuccess: "You have successfully created the landing page!",
      createError: "Oops! It seems that the URL already exists.",
      updateSuccess: "You have successfully edited the landing page!",
      updateError: "There was an error, please try again.",
      phoneError: "Please enter a valid phone number",
      popUp:
        "You haven't configured your landing. Click the configuration button to finish the process.",
    },
  },
  components: {
    chat: {
      headerChatText: "Chat",
      headerUserText: "Users",
      inputPlaceholder: "Text",
      deleteMenuButton: "Delete",
      banMenuButton: "Ban",
      unbanMenuButton: "Unban",
      shadowBanMenuButton: "Shadow Ban",
      removeShadowBaneMenuButton: "Remove Shadow Ban",
      pinMessageMenuButton: "Pin Message",
      unpinMessageMenuButton: "Unpin Message",
      noSearch: "Enter the username",
      noUsers: "No users found",
      loading: "Loading...",
      searchPlaceholder: "Tap to search...",
      message: "Type a message...",
      sendButton: "Send",
      noStarted: "The event has not started",
      info: "Registered information",
      detailsButton: "User details",
      hide: "Hide",
      copy: "Copied!",
      noData: "No Data",
    },
    table: {
      counterText: "Selected",
      optionsButton: "Remove",
      searchPlaceHolder: "Tap to Search....",
      filteringTitle: "Order:",
    },
    navBar: {
      contextMenu: {
        changeBrandButton: "Change brand",
        storeProfileButton: "Store Profile",
        userProfileButton: "Personal Profile",
        invitationsButton: "My Invitations",
      },
      logoutButton: "Sign off",
      settingButton: "Settings",
      accountConfig: {
        title: "Account Settings",
        syncTitle: "Synchronization",
      },
      storeSettingButton: "General",
      storeInfoButton: "Store Information",
      profileSettingsButtons: "Personal Profile",
      userSecurityButton: "Security",
      profileRolesAndPermissions: "Roles and permissions",
    },
    synchronization: {
      title: "Synchronization with Ecommerce",
      unsunc: "Unsynchronize",
      subtitle: "Add e-commerce",
      description:
        "Choose the e-commerce platform you want to synchronize with",
      loading: "Processing...",
      success: "Synchronization was successful!",
      failed: "Failed synchronization! ",
      retry: "Please try again.",
    },
    videoSettings: {
      title: "Video Settings",
      subtitle: "Replace your video in the case of requiring any adjustment",
      replaceVideo: "Replace video",
      editVideo: "Edit video",
      errorVideo: "Preview no available",
      popUpDecision: {
        title: "Are you sure you want to replace this video?",
        description: "This can make this action only once",
      },
      popUpError: {
        title: "There was an error replacing the video!",
        description: "It's necessary to upload the video again.",
        retry: "Please try again.",
      },
      popUpFormatError: {
        title: "The video format is not supported!",
        description: "Remember that the video must be in format ",
      },
      popUpLoading: {
        title: "Uploading video...",
        loading: "Loading...",
      },
      popUpSizeError: {
        title: "The video size is too large!",
        description: "Remember that the video must be less than ",
      },
      popUpOnlyOneMp4Error: {
        title: "There was an error replacing the video!",
        description: "You can only select one .mp4 file.",
      },
      popUpTS: {
        title: "There was an error replacing the video!",
        description:
          "Please select at least one .m3u8 file and at least one .ts file, or only one .mp4 file.",
      },
    },
    imageUpload: {
      load: "Load image",
      fileFormat: "File format",
      fileSize: "Recommended size",
    },
    editProductsTable: {
      table: {
        name: "Name",
        sku: "SKU",
        quantity: "Inventory",
        price: "Price",
        discount: "Discount",
        entry: "Entry",
      },
      popup: {
        header: {
          hideProducts: "Hide all products",
          showProducts: "Show all products",
          hidePrices: "Hide all prices",
          showPrices: "Show all prices",
        },
        rows: {
          hideProduct: "Hide product",
          showProduct: "Show product",
          hidePrices: "Hide prices",
          showPrices: "Show prices",
        },
      },
      errors: {
        emptyInput: "The field cannot be empty",
      },
    },
    infoCard: {
      title: "Functioning description",
      NEXT_EVENTS_CARROUSEL:
        "Utiliza este widget para presentar a tu audiencia una colección de videos, con información acerca del momento en que fueron realizados y una breve descripción de cada uno.",
    },
    widgetConfigCard: {
      title: "General",
      sectionTitle: "Widget style",
    },
    selectEventsWidget: {
      title: "Select events to display",
      eventTypes: "Event types",
      nextEvents: "Upcoming events",
      prevEvents: "Previous events",
      collections: "Collections",
    },
    selectByNameWidget: {
      eventTitle: "Event associated with the widget",
      collectionTitle: "Collection associated with the widget",
      select: "Select",
      optional: "(optional)",
    },
    selectByCollectionNameWidget: {
      collectionTitle: "Collection associated with the widget",
      defaultv1: "Collection name",
      multiple: "Collections associated with the widget MAX 8 ",
    },
    selectByEventNameWidget: {
      title: "Video associated with the banner",
      defaultv1: "Event name",
    },
    widgetRadioButton: {
      videos: "Saved videos",
      collections: "Collections",
      videoTitle: "Video associated with the banner",
    },
    widgetImageInput: {
      load: "Load image",
    },
    widgetUrlInput: {
      title: "Redirect page",
    },
    statusSelect: {
      active: "Active",
      inactive: "Inactive",
    },
    permissionCard: {
      title: "Permissions:",
      edit: "Edit Permissions",
    },
    settings: {
      sidebar: {
        modules: "Modules",
        events: "Events",
        landing: "Landing",
        product: "Product",
        additionals: "Additional Variables",
      },
      additionals: {
        title: "Additional Store Variables",
        subtitle:
          "These are variables that will be used for the construction of the URL of the products and the landing page of each store.",
      },
      product: {
        title: "Global Product Settings",
        subtitle: "Permissions",
        canCreate: "Stores can",
        canEdit: "Stores can",
        canCreateCon: "new products",
        canEditCon: "product details",
        create: "upload",
        edit: "edit",
      },
      event: {
        title: "Global Event Settings",
        tabs: {
          permissions: "Permissions",
          graphicConfig: "Graphic settings",
        },
        permissions: {
          description: "Use default settings by Owner for all selected stores",
        },
        extra: {
          title: "Global settings",
          globalCounters: {
            label: "The events will display global counters",
            tooltip:
              "By enabling this option, you will see the total sum of views across all concurrent events instead of individual views from each user.",
          },
        },
      },
      landing: {
        title: "Global landing configuration",
        one: "Click the button to start configuring your page",
        permissions: {
          blocker: "Do step 1 first",
          useDefault: "Apply account settings to all selected stores.",
          widgetDefault:
            "This permission locks widget and shopping cart settings.",
        },
      },
      userDetails: {
        success: "User data saved successfully.",
        error: "An error has occurred, please try again.",
      },
      userRolesAndPermissions: {
        permissionsTable: {
          title: "Role And Permissions",
          storeName: "Store Name",
          role: "Role",
          lastLog: "Last login",
          seePermission: "View permissions",
          orderOptions: {
            recent: "Most recent",
            older: "Oldest",
            alphabet: "A to Z",
            reverse: " from Z to A",
          },
        },
        permissionsDetail: {
          title: "@StoreName",
          subTitle: "Services you have access to",
          permissionsDescription:
            "You will have only permissions to use the functions of this service Permissions:",
          permissionsDescriptionv2:
            "You will have permissions to use the following features",
          permissionsDescriptionv3:
            "You will have permissions to use the functions of this service",
          manager: {
            title: "Manager web",
            titlev2: "Manager",
            dashborad: "Dashboard",
            events: "Events",
            eventsv2: "Watch events",
            products: "Products",
            team: "Team",
          },
          proseller: {
            title: "App proseller",
            proseller: "Proseller",
            products: "Products management",
            events: "Events management",
            moderation: "Moderation",
            statistics: "Statistics",
            vod: "Recorded videos management",
          },
          firaTV: {
            title: "Fira TV",
            moderation: "Comment moderation",
            VOD_mgmt: "VOD management",
            statistics: "Statistics",
            collections: "Collection management",
          },
          dashboard: {
            title: "Dashboard",
            statistics: "Statistics",
          },
          events: {
            title: "Events",
            event_mgmt: "Event management",
            moderation: "Moderation",
            statistics: "Statistics",
          },
          products: {
            title: "Products",
            create: "Create products",
            batch_load: "Batch load",
            edit_products: "Edit products",
            delete_products: "Delete products",
            management: "Products Management",
          },
        },
      },
    },
    userSecurity: {
      title: "Security",
      currentPwd: "Current password",
      email: "Email",
      changePwd: "Change password",
      lastChange: "Last change on",
      forgotPwd: "I forgot my password",
      forgotPwdv2: "Have you forgotten the password?",
      newPwd: "New password",
      confirmPwd: "Confirm password",
      securityBar: "Password strength",
      cancelButton: "Cancel",
      saveButton: "Save",
      noMatch: "The passwords don't match",
      wrongPwd: "The credentials don't match",
    },
    createStoreForm: {
      title: "Create new store",
      formTitle: "Store information",
      name: "Store name",
      url: "Website URL",
      language: "Language",
      currency: "Currency",
      cancel: "Cancel",
      create: "Create store",
    },
    selects: {
      contextChange: {
        yourAccount: "Your owner account",
      },
      storeTypes: {
        TEXTILE: "Textile",
        ELECTRONICS: "Electronics",
        VARIOUS: "Various",
        COSMETICS: "Cosmetics",
      },
      currencies: {
        USD: "United States Dollar",
        EUR: "Euro",
        PEN: "Peruvian Nuevo Sol",
        BRL: "Brazilian Real",
        MXN: "Mexican Peso",
        COP: "Colombian Peso",
        CLP: "Chilean Peso",
        ARS: "Argentine Peso",
        GTQ: "Guatemalan Quetzal",
        NIO: "Nicaraguan Córdoba",
        CRC: "Costa Rican Colón",
        HNL: "Honduran Lempira",
        SVC: "Salvadoran Colón",
        CLF: "Unidad de Fomento",
        BOB: "Bolivian boliviano",
      },
      unitSystems: {
        METRIC: "Metric System",
        IMPERIAL: "Imperial System",
      },
    },
    store_settings: {
      general_info: {
        title: "General information",
        store_name: "Store name",
        company: "Company name",
        store_type: "Store industry",
        language: "Language",
        country: "Country",
        city: "City",
        address: "Address",
        zip_code: "Zip code",
        currency: "The currency in which your products are sold",
        time_zone: "Timezone",
        unit_system: "Unit system",
        weight_unit: "Default weight unit",
        description: "Store description",
      },
      brand_identity: {
        title: "Brand identity",
        brand_logo: "Brand logo",
        brand_favicon: "Fav Icon",
        typography: "Typography",
        brand_colors: "Brand colors",
        main_color: "Main color",
        secondary_color: "Secondary color",
      },
      contact_info: {
        title: "Contact information",
        email: "Email",
        phoneNumber: "Phone number",
        web: "Web page URL",
      },
      massification: {
        title: "Massification",
        label: "Automatically showcase the latest live (stream).",
      },
      cancel_btn: "Cancel",
      save_btn: "Save",
      next_btn: "Next",
      prev_btn: "Return",
      error: "An error has occurred, please try again.",
      success: "The store has been edited successfully!",
    },
    inputMedia: {
      load: "Load Image",
      loadVideo: "Load video",
      formats: "Formats: ",
      sizes: "Recommended Size ",
    },
    typeSelect: {
      title: "Type of events",
    },
  },
  modals: {
    pickStore: {
      title: "SELECT A STORE",
      addStoreButton: "Add a store",
    },
    profile: {
      edit_profile_modal: {
        title: "Personal Profile",
        firstName: "First Name",
        lastName: "Last Name",
        birthdate: "Date of birth",
        gender: "Gender",
        gender_radio: {
          male: "M",
          female: "F",
          other: "Other",
        },
        gender_option: {
          male: "Male",
          female: "Female",
          other: "Other",
        },
        mobilephone: "Phone number",
        email: "Email",
        email_short: "Email",
        username: "User",
        cancel: "Cancel",
        submit: "Submit",
        edit: "Edit",
        alert_success: "The information has been saved successfully",
        alert_error: "An error has occurred, please try again.",
        avatar: "Profile picture",
        day: "DD",
        month: "MM",
        year: "YYYY",
        selectPlaceholder: "Select",
        numberPlaceholder: "000-000-00",
        codePlaceholder: "+234",
      },
    },
    products: {
      load_modal: {
        title: "Import products with EXCEL",
        message_1: "Download",
        message_2: "Sample EXCEL",
        message_3: "template of the required format",
        drop_zone: "Add file",
        cancel_button: "Cancel",
        load_button: "Load and continue",
      },
      load_data_modal: {
        title: "Load State",
        state: "State",
        quantity: "Quantity",
        valid: "Valid",
        duplicated: "Duplicated",
        rejected: "Rejected",
        cancel_button: "Cancel",
        save_button: "Save",
        rejected_file: "Rejected Products",
        duplicated_file: "Duplicated Products",
        approved_file: "Approved Products",
        download_file: "Download",
        info: "These are the products that have an error in the excel template",
      },
    },
    invitations: {
      title: "Pending Invitations",
      invitation:
        "The store {{store}} is inviting you to be part of its work team",
      no_invitation: "You do not have pending invitations",
      accept: "Accept",
      dismiss: "Dismiss",
      table: {
        store: "Store",
        invitation: "Invitation",
        accept: "Accept",
        yes: "Yes",
        no: "No",
      },
      messages: {
        accepted: "You are now part of the {{store}} team",
        rejected: "You have declined the invitation from {{store}}",
        error: "An error has occurred. Please try again later",
      },
    },
    success_message: {
      title: "You have successfully created your event!",
      edited: "You have successfully updated your event!",
      description1: " We have sent to your email all the details of the event ",
      description2: " for the day ",
    },
    eventDetail: {
      addProducts: {
        title: "Selected products",
        filter: "Tap to Search....",
        table: {
          name: "Name",
          sku: "SKU",
          inventory: "Inventory",
          price: "Price",
          discount: "Discount",
        },
        add: "Add",
        cancel: "Cancel",
        messages: {
          error_generic: "an error occurred while adding the selected products",
          error_1:
            "It no was possible to find the instance under id {{eventId}} and Stage STARTED",
          error_2:
            "No BroadcastProduct instances where found by 62defcce9c5dea286cc0f6fe id",
          error_3: "No product was added, all already exits",
          error_4:
            "It no was possible to find the instance under id {{eventId}} and Stage SCHEDULED",
        },
      },
      deleteProducts: {
        title: "Delete Product",
        message: "Are you sure you want to remove {{name}}?",
        yes: "Yes",
        no: "No",
      },
      credentials: {
        title: "Transmission Configuration",
        subTitle:
          "Copy and paste the following codes into your streaming platform",
        integration: {
          title: "Integration configuration",
          instruction_1:
            'Copy and paste the following code into the section of your page where you want the button to appear to direct to the "Live".',
          instruction_2:
            "Copy and paste the following script at the bottom of your page for the execution of the Fira library.",
        },
        credentials: {
          title: "Broadcast credentials",
          generate: "Generate",
          server: "Server",
          key: "Key",
          messages: {
            copied: "¡Copied!",
            success: "Credentials created successfully",
          },
        },
        messages: {
          invalid_id: "Stream ID {{streamId}} invalid",
          error_saving: "Error saving broadcasting credentials",
          cant_generate:
            "Can not generate credentials to broadcast not scheduled",
        },
      },
      start: {
        title: "Start transmission",
        message_1:
          "Before starting the event, make sure you are streaming. If you start the event without a video signal, it will not be possible to record it.",
        message_2: "Are you ready to start the event now?",
        yes: "Yes",
        no: "No",
      },
      finish: {
        title: "End transmission",
        message: "Are you sure you want to end live?",
        yes: "Yes",
        no: "No",
      },
    },
    unsync: {
      title: "Unsynchronize @ecommerce",
      content: "Are you sure you want to unsync the ecommcerce @ecommerce?",
      yes: "Yes",
      no: "No",
    },
    insertVideo: {
      title: "Insert video",
      subtitle: "INSERT OPTIONS",
      option: "Show player controls",
      cancel: "Cancel",
      copy: "Copy",
      messages: {
        copied: "Successfully copied!",
        error: "An error occurred while copying. Please try again",
      },
    },
    videosList: {
      title: "Selected videos",
      table: {
        videoName: "Video name",
        desc: "Description",
        teaser: "Teaser",
        products: "Products",
      },
      abandon: "Leave page",
      save: "Save and Leave",
    },
    storeDetails: {
      title: "Are you sure you want to delete the store?",
      desc: "Please note that this cannot be undone.",
      cancel: "Cancel",
      accept: "Accept",
    },
  },

  dialogs: {
    deleteProducts: {
      title: "Attention!",
      singleTextContent: "Are you sure you want to delete {productName}?",
      multipleTextContent:
        "Are you sure you want to delete {quantity} products?",
      textContent: "This action cannot be undone.",
      deleteButton: "Delete",
    },
    cancelButton: "Cancel",
  },
  store: {
    edit_store_modal: {
      title_create: "Add New Store",
      title: "Store Profile",
      store_name: "Name of the store",
      store_id: "Store ID",
      name: "Name",
      desc: "Description",
      desc_2: "Store description",
      lang: "Language",
      currency: "Currency",
      state: "State",
      city: "City",
      country: "Country",
      postCode: "Postal Code",
      address: "Address",
      save: "Save",
      cancel: "Cancel",
      edit: "Edit",
      alert_success: "The information has been saved successfully",
      alert_success_create: "Your store {name} has been successfully created",
    },
    brand_identity: {
      title: "Brand identity",
      edit: "Edit",
      brand_logo: "Brand logo",
      upload: "Upload image",
    },
    messages: {
      error: {
        file_is_not_image: "Error loading image",
        default: "We have encountered an error try again",
      },
    },
  },
  permission: {
    title: "Team",
    btncancel: "Cancel",
    btnsend: "Enviar invitation",
    subtitle: "Add Member",
    email: "Email",
    list: {
      title: "Permissions",
      subtitle: "Employee will have the following permissions",
      selectall: "Select all",
      dashboard: {
        title: "Dashboard",
        title_desc: "Can access all items",
        dashStatics: "Statistics",
        dashStatics_desc:
          "Information of the management carried out in the month",
      },
      event_item: {
        title: "Event",
        title_desc:
          "The user will be able to access all the information of the module",
        eventsManage: "Event management",
        eventsManage_desc:
          "The user can make any kind of changes to the events",
        eventsAddEvent: "Create Events",
        eventsAddEvent_desc: "The user can create events",
        eventsEditEvent: "Edit events",
        eventsEditEvent_desc: "User can edit events",
        eventsDeleteEvent: "Delete Events",
        eventsDeleteEvent_desc: "User can delete events",
        eventsModeration: "Moderation",
        eventsModeration_desc:
          "The user can moderate the events to which he is assigned",
        eventsShowStatics: "Statistics",
        eventsShowStatics_desc:
          "The user will be able to visualize the post-Live statistics",
      },
      credential: {
        title: "Credentials",
        title_desc:
          "The user will be able to create and assign credentials to other users",
        credentialsAdd: "Create credentials",
        credentialsAdd_desc:
          "The user will be able to create and assign credentials to other users",
        credentialsShow: "See credentials",
        credentialsShow_desc:
          "The user will be able to see their credentials and those of other users",
        credentialsDelete: "Delete credentials",
        credentialsDelete_desc:
          "The user will be able to delete his credentials and those of other users",
      },
      team: {
        title: "Team",
        title_desc:
          "The user will be able to access all the information of the module",
        teamAddMember: "Add members",
        teamAddMember_desc:
          "The user can invite people to be part of his work teamo ",
        teamEditMember: "Edit members",
        teamEditMember_desc:
          "The user will be able to edit the permissions of other team members",
        teamDeleteMember: "Delete members",
        teamDeleteMember_desc:
          "The user will be able to remove other team members",
      },
      products: {
        title: "Products",
        title_desc:
          "The user will be able to access all the information of the module",
        productsAddProduct: "Create products",
        productsAddProduct_desc: "The user will be able to create products",
        productsBatchUpload: "Massive uploads",
        productsBatchUpload_desc:
          "The user will be able to perform bulk uploads",
        productsEditProduct: "Edit products",
        productsEditProduct_desc: "The user will be able to edit products",
        productsDeleteProduct: "Delete products",
        productsDeleteProduct_desc: "The user can delete products",
      },
      firatv: {
        title: "Fira Tv",
        title_desc:
          "The user will be able to access all the information of the module",
        firaTvRebroadcast: "Rebroadcast",
        firaTvRebroadcast_desc:
          "The user will be able to select which lives he wants to be displayed on the landing page",
        firaTvExportBroadcast: "Export",
        firaTvExportBroadcast_desc: "User will be able to export videos",
        firaTvDeleteBroadcast: "Delete",
        firaTvDeleteBroadcast_desc: "User will be able to delete videos",
      },
    },
    inactivate_button: "Inactivate user",
    activate_button: "Activate User",
  },
  event_creation: {
    live: "Create Live",
    generalRoute: "General Details",
    teamRoute: "Team",
    productsRoute: "Products",
    paramsRoute: "Params",
    prevButton: "Return",
    nextButton: "Next",
    save: "Save",
    credentials: {
      title: "Select your credential",
      message:
        "Choose the credential option closest to your country, to have a better connection",
    },
    general_details: {
      title: "General information",
      message: "Complete the following information related to your event",
      forms: {
        event_name: "Event name",
        datetime: "Date and Time",
        desc: "Event description",
        select_categories: "Select categories",
        load_teaser: "Upload teaser",
        load_image: "Upload image",
        optional: "(Optional)",
        teaser_info: "Video with teaser of the event",
        file_format: "File format",
        recommended_size: "Recommended size",
        image_info: "Cover image for your event",
        globalModeration: "Global moderation",
        individuallModeration: "Individual moderation",
        infoGlobal:
          "Select 'Global Moderation' if you prefer chat moderation to be managed centrally by the main account.",
        infoIndividual:
          "Select 'Individual Moderation' if you want each store to have its own chat and manage chat moderation independently",
      },
      image_error:
        "The selected image has the wrong format or size, please try again.",
    },
    team: {
      title: "Select your team",
      message: "Choose the members that will be active in the event",
      placeholder: "Tap to Search....",
      selected: "Selected",
      info: "The account owner, administrator(s), and live creator will always be designated as moderators.",
    },
    params: {
      interfaceTitle: "Select your video interface settings",
      interfaceMessage:
        "Choose or create a template based on the features you need",
      interfaceButton: "Select",
      interfaceAddMore: "Add",
      colorSelectionTitle: "Broadcast Main Color",
      colorSelectionMessage:
        "Select the color you will use to highlight your brand's interactions in chat",
      chat_and_web: "Chat and Web Products",
      productsMockup: "Products",
      loginParamsTitle: "Login Enabling",
      loginParamsMessage:
        "Capture more detailed information about your audience by enabling the login",
      loginEnabled: "Login enabled",
      addFields: "Add Additional Parameters",
      requiredField: "Required",
      attachmentsTitle: "Attachments",
      attachmentsMessage:
        "Load and select the elements that you are going to use during the session",
      loadingIcon: "Loading Icon",
      shoppingBag: "Shopping Bag",
      likes: "Likes Images",
      words_to_moderate: "Words to moderate",
      fieldTypes: {
        username: "Username",
        text: "Text",
        email: "Email",
        password: "Password",
        tel: "Phone",
        number: "Number",
      },
      broadcastingResolution: {
        title: "Broadcasting Orientation",
        subtitle:
          "Select the direction in which the live stream will be conducted; it can be either horizontal or vertical.",
        horizontal: "Horizontal Broadcast",
        vertical: "Vertical Broadcast",
      },
      terms_and_conditions: "Enter the URL of the Terms and Conditions",
      terms_and_conditions_personal_data:
        "Enter the URL of the personal data processing",
      default: "Default",
      custom: "Custom",
      drag: "Drag your documents",
      add_word_placeholder: "Add word...",
      attachments: "Attachments",
      load: "Load file",
      colorPreview: "Color preview",
      buyButtonTitle: "Product Button Configuration",
      buyButtonMessage:
        "Customize the text of the button for selling your products",
      buyButtonDefault: "Default",
      buyButtonCustom: "Custom",
      buyButtonText: "Button Text",
      buyButtonSave: "Save",
      buyButtonPlaceholder: "E.g.: Buy",
      buyButtonPrev: "Preview",
      buyButtonProductName: "Product Name",
      buyButtonView: "Buy",
      noSavedAlert:
        "Oops! To continue, click on save to confirm the information",
    },

    incorrect_file_loaded: "Please check that your file type is ",
    fail: "Failed to create event!",
    fail_message:
      "We are experiencing issues at the moment, please try again later.",
    fail_btn: "Understood",
  },
  dropzone: {
    title: "Drag your documents",
  },
  toggle: {
    yes: "YES",
    no: "NO",
  },
  widgets: {
    audienceResume: {
      title: "Summary of the situation",
      audiencia: "Audience",
    },
    interactions: {
      title: "Interactions",
      subtitle: "In this space you can see all the",
      subtitle_2: "Total amount by type of interaction",
      likes: "Likes",
      shares: "Shares",
      messages: "Messages",
      clicks: "Clicks",
      total: "Total",
    },
    viewsByDevices: {
      title: "Views by device",
      desktop: "Desktop",
      mobile: "Mobile",
      other: "Other",
    },
    documentation: {
      title1: "Enhance your ",
      title2: "Campaign",
      title3: " for better reach",
      button: "Coming soon",
    },
    card: {
      availableTime: "Available time",
      avgReproduction: "Average Playback Time",
    },
    highlights: {
      title: "Outstanding lives", //O featured???
      subtitle: "In this space you can see 3 outstanding lives",
      empty: "You have no more events",
      select: {
        likes: "Likes",
        shares: "Shares",
        clicks: "Clicks",
        messages: "Messages",
        audience: "Audience",
        filter: "Filter",
      },
    },
  },
  calendar: {
    from: "From",
    to: "To",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    sunday: "S",
    monday: "M",
    tuesday: "T",
    wednesday: "W",
    thursday: "T",
    friday: "F",
    saturday: "S",
  },
  inputs: {
    searchPlaceholder: "Tap to Search....",
    varKeyPlaceholder: "Variable name",
    varValuePlaceholder: "Variable default value",
  },
  forms: {
    name_label: "Name",
    lastname_label: "Last name",
    user_label: "User",
    email_label: "Email",
    phonenumber_label: "Phone Number",
    whatsapp_label: "WhatsApp to receive your orders",
  },
  table: {
    header: {
      name: "Name",
      email: "Email",
      invite_date: "Invite date",
      store: "Store",
      role: "Role",
      status: "Status",
    },
  },
  buttons: {
    save: "Save",
    cancel: "Cancel",
    next: "Next",
    back: "Back",
    create_account: "Create Account",
    copy_url: "Copy URL",
    try_again: "Try again",
    preview: "Preview",
    setup: "Setup",
    select_all: "Select all",
    add_vars: "Add Additional Parameters",
    resend: "Resend",
    annul: "Annul",
    continue: "Continue",
  },
  alerts: {
    success: "Change saved",
    url_copy: "URL copied!",
    landing_error: "Store already has landing:",
    savings: "Savings",
    vars_error: "Both key and value must be non-empty.",
    vars_url_error: "Both key and value must be valid URL parameters.",
    invite_success: "invite sent!",
    invite_cancelled: "Invitation cancelled",
    error: "We found an error, please try again.",
  },
  invitation: {
    status: {
      CANCELLED: "Cancelled",
      PENDING: "Pending",
      ACCEPTED: "Accepted",
      CLOSED: "Rejected",
    },
  },
  user: {
    role: {
      OPERATOR: "Operator",
      MODERATOR: "Moderator",
      MANAGER: "Admin",
    },
  },
  wizard: {
    steps: {
      COLOR: "Color",
      BUTTON: "Buy button",
      ORIENTATION: "Orientation",
      LOGIN: "Login",
    },
  },
};
