import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import {
  acceptInvitationActionCreator,
  rejectInvitationActionCreator,
} from "../../../store/User/UserActions";
import { FiraInvitation, RootState } from "../../../utils/types";
import Button, { ButtonStyle } from "../../Button/Button";
import { ArrowLeft } from "../../FiraIcons";
import PopUpAlert, { PopUpAlertTypes } from "../../PopUpAlert/PopUpAlert";

import styles from "./InvitationModal.module.scss";

let timerID: NodeJS.Timeout;

enum messageTypes {
  accepted = "accepted",
  rejected = "rejected",
  error = "error",
}

interface InvitationModalProps {
  isOpen: boolean;
  onClose(): void;
}

const InvitationModal = ({ isOpen = false, onClose }: InvitationModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state);
  const [messageType, setMessageType] = useState("");
  const [storeName, setStoreName] = useState("Prueba");

  const handleAcceptInvitation = (id: string, storeName: string) => {
    dispatch(acceptInvitationActionCreator(id));
    setStoreName(storeName);
    setMessageType(messageTypes.accepted);
  };

  const handleRejectInvitation = (id: string, storeName: string) => {
    dispatch(rejectInvitationActionCreator(id));
    setStoreName(storeName);
    setMessageType(messageTypes.rejected);
  };

  const handleCloseAlert = () => {
    setStoreName("");
    setMessageType("");
  };

  useEffect(() => {
    if (!user.isLoading) {
      if (user.error) {
        setMessageType(messageTypes.error);
      }
      if (messageType !== "") {
        timerID = setTimeout(() => {
          handleCloseAlert();
        }, 5000);
      }
    }
  }, [user]);

  const handleBack = () => {
    onClose();
  };

  let content = (
    <div className={styles.noInvitationContent}>
      <p className="body-14-text">{t("modals.invitations.no_invitation")}</p>
    </div>
  );

  if (user.invitations.length > 1) {
    content = (
      <InvitationGroup
        invitationList={user.invitations}
        acceptHandle={handleAcceptInvitation}
        rejecttHandle={handleRejectInvitation}
        isLoading={user.isLoading}
      />
    );
  } else if (user.invitations.length == 1) {
    content = (
      <SingleInvitation
        invitation={user.invitations[0]}
        acceptHandle={handleAcceptInvitation}
        rejecttHandle={handleRejectInvitation}
        isLoading={user.isLoading}
      />
    );
  }
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalLayout>
      {messageType !== "" && !user.isLoading && (
        <PopUpAlert
          type={
            messageType == messageTypes.accepted
              ? PopUpAlertTypes.success
              : PopUpAlertTypes.error
          }
          title={
            {
              accepted: t("modals.invitations.messages.accepted", {
                store: storeName,
              }),
              rejected: t("modals.invitations.messages.rejected", {
                store: storeName,
              }),
              error: t("modals.invitations.messages.error"),
            }[messageType]
          }
          width="575px"
          handleCloseIcon={handleCloseAlert}
        />
      )}

      <div className={styles.invitationModalWrapper}>
        <Button
          width={"30px"}
          height={"30px"}
          buttonStyle={ButtonStyle.transparent}
          clickHandler={() => handleBack()}
          className={styles.invitationBackButton}
        >
          <ArrowLeft className={styles.icon} />
        </Button>
        <div className={`${styles.header} ${styles.row} h5`}>
          <p>{t("modals.invitations.title")}</p>
        </div>

        {content}
      </div>
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};
interface SingleInvitationProps {
  invitation: FiraInvitation;
  acceptHandle(id: string, storeName: string): void;
  rejecttHandle(id: string, storeName: string): void;
  isLoading: boolean;
}

const SingleInvitation = ({
  invitation,
  acceptHandle,
  rejecttHandle,
  isLoading,
}: SingleInvitationProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.singleInvitationContent}>
      <p className="body-14-text">
        {t("modals.invitations.invitation", { store: invitation.storeName })}
      </p>
      <div className={styles.buttonGroup}>
        <Button
          width="131px"
          height="42px"
          buttonStyle={ButtonStyle.transparent}
          clickHandler={() =>
            rejecttHandle(invitation.id, invitation.storeName)
          }
        >
          <h6>{t("modals.invitations.dismiss")}</h6>
        </Button>
        <Button
          width="131px"
          height="42px"
          clickHandler={() => acceptHandle(invitation.id, invitation.storeName)}
          isDisabled={isLoading}
        >
          <h6>{t("modals.invitations.accept")}</h6>
        </Button>
      </div>
    </div>
  );
};

interface InvitationGroupProps {
  invitationList: FiraInvitation[];
  acceptHandle(id: string, storeName: string): void;
  rejecttHandle(id: string, storeName: string): void;
  isLoading: boolean;
}

const InvitationGroup = ({
  invitationList,
  acceptHandle,
  rejecttHandle,
  isLoading,
}: InvitationGroupProps) => {
  const { t } = useTranslation();

  const TableHead = (
    <tr>
      <th className={`${styles.left}`}>
        <p className="lead-text">{t("modals.invitations.table.store")}</p>
      </th>
      <th className={`${styles.center}`}>
        <p className="lead-text">{t("modals.invitations.table.invitation")}</p>
      </th>
      <th className={`${styles.right}`}>
        <p className="lead-text">{t("modals.invitations.table.accept")}</p>
      </th>
    </tr>
  );

  const TableBody = invitationList.map((invitation: FiraInvitation) => {
    return (
      <tr className={styles.rowContent} key={invitation.id}>
        <td className={`${styles.left} lead-text `}>
          <span className={styles.LimitText}>{invitation.storeName}</span>
        </td>
        <td className={`${styles.center} lead-text`}>
          <span className={styles.LimitText}>{invitation.username}</span>
        </td>
        <td className={styles.right}>
          <div className={styles.buttonGroup}>
            <Button
              width="39px"
              height="19px"
              buttonStyle={ButtonStyle.border}
              clickHandler={() =>
                rejecttHandle(invitation.id, invitation.storeName)
              }
              isDisabled={isLoading}
            >
              <p className="lead-text">{t("modals.invitations.table.no")}</p>
            </Button>
            <Button
              width="39px"
              height="19px"
              clickHandler={() =>
                acceptHandle(invitation.id, invitation.storeName)
              }
              isDisabled={isLoading}
            >
              <p className="lead-text">{t("modals.invitations.table.yes")}</p>
            </Button>
          </div>
        </td>
      </tr>
    );
  });
  return (
    <div className={styles.invitationGroupContent}>
      <table className={styles.invitationsTable}>
        <thead>{TableHead}</thead>
        <tbody>{TableBody}</tbody>
      </table>
    </div>
  );
};

export default InvitationModal;
