import { EditablePermissionsType } from "../components/NewUserComponents/FiraUserPermissionComponent/FiraUserPermissionComponent";
import { getPermissions, getPermissionsByGroupService } from "../services/user";
import { SimplePermissionItem } from "../types/Response";
import { FiraPackage, FiraUserRole, PermissionCardType } from "./enums";
import { checkActiveByGroup } from "./handlePermissionCheckboxSelection";
import {
  FiraUser,
  PermissionCategories,
  PermissionsByGroupType,
} from "./types.d";

export const handleUserPermissions = (user: FiraUser) => {
  let permissions = "none";

  if (user.status) {
    if (user.firaPermissionsDto) {
      const inactivePermissions = Object.entries(
        user.firaPermissionsDto
      ).filter((key) => {
        return key[1] == false;
      });
      const activePermissions = Object.entries(user.firaPermissionsDto).filter(
        (key) => {
          return key[1] == true;
        }
      );
      if (inactivePermissions.length == 0) {
        permissions = "total";
      } else if (
        activePermissions.length > 0 &&
        inactivePermissions.length > 0
      ) {
        permissions = "partial";
      } else {
        permissions = "none";
      }
    }
  } else {
    return (permissions = "inactive");
  }

  return permissions;
};

export const permissionsEditableObjectBuilder = async (
  group: { name: string; group: PermissionsByGroupType }[],
  firaProduct: FiraPackage,
  userPermissions?: SimplePermissionItem[],
  card?: PermissionCardType
) => {
  let permissions: EditablePermissionsType[] = [];

  for (let item of group) {
    await getPermissionsByGroupService(item.group).then((val) => {
      permissions.push({
        name: item.name,
        permissions: val.data,
        firaProduct: firaProduct,
        isActive: userPermissions
          ? checkActiveByGroup(firaProduct, val.data, userPermissions)
          : false,
        card: card,
      });
    });
  }
  return permissions;
};

export const permissionTitlesObjBuilder = (
  group: { name: string; group: PermissionsByGroupType }[]
) => {
  let strings: string[] = [];

  group.forEach((item) => {
    strings.push(item.name);
  });

  return strings;
};

export const permissionsGroupUnifier = (
  permissions: SimplePermissionItem[]
) => {
  const mergedMap = new Map<FiraPackage, string[]>();
  permissions.forEach(
    (item: { firaProduct: FiraPackage; permissions: string[] }) => {
      const { firaProduct, permissions } = item;
      if (!mergedMap.has(firaProduct)) {
        mergedMap.set(firaProduct, []);
      }
      const mergedPermissions = mergedMap.get(firaProduct);
      mergedPermissions && mergedPermissions.push(...permissions);
    }
  );

  const mergedResult: { firaProduct: FiraPackage; permissions: string[] }[] =
    [];
  mergedMap.forEach((permissions, firaProduct) => {
    const uniquePermissions = Array.from(new Set(permissions)); // Remove duplicates
    mergedResult.push({ firaProduct, permissions: uniquePermissions });
  });

  return mergedResult || [];
};

export const firaPermissionsDataHandler = (
  role: FiraUserRole | null,
  permissions: SimplePermissionItem[]
) => {
  const unifiedProducts: { firaProduct: FiraPackage; permissions: string[] }[] =
    [];
  permissions.forEach((firaProduct) => {
    const existingFiraProduct = unifiedProducts.find(
      (unifiedProducts) =>
        unifiedProducts.firaProduct === firaProduct.firaProduct
    );

    if (existingFiraProduct) {
      existingFiraProduct.permissions = [];
    } else {
      unifiedProducts.push({
        firaProduct: firaProduct.firaProduct || "",
        permissions: [],
      });
    }
  });

  if (role !== null) {
    switch (role) {
      case FiraUserRole.MANAGER: {
        return unifiedProducts;
      }
      case FiraUserRole.MODERATOR: {
        return unifiedProducts.filter(
          (obj) => obj.firaProduct !== FiraPackage.PROSELLER
        );
      }
      default:
        return permissionsGroupUnifier(permissions);
    }
  }
};

export const SimplePermissionsCaller = async (
  group: PermissionCategories[]
) => {
  let permissions: string[] = [];
  for (let item of group) {
    await getPermissions(item).then((val) => {
      permissions = [...permissions, ...val];
    });
  }

  return permissions;
};
