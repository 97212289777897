import axios from "axios";
import { MessageType } from "../hooks/useAgoraSignaling";
import {
  BanRequestBody,
  DeleteMessageRequestBody,
  GetChatHistoricalResponse,
  GetChatSummaryResponse,
  GetTokenReponse,
  MessageRequest,
  UserMessage,
} from "../types";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_MANAGEMENT,
});

const apiActions = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_ACTIONS,
});

export const getToken = async (userId: string) => {
  return api.get<GetTokenReponse>(
    `/api/v1/fira-broadcast-event-controller/chat-token/${userId}`
  );
};

export const sendMessageToAPI = (
  src: string,
  eventStartDate: string,
  type: MessageType,
  message: UserMessage,
  parentId?: string
) => {
  let body: MessageRequest = {
    broadcastId: src,
    chatUser: {
      banned: false,
      isAnonUser: false,
      name: message.from,
      userId: message.userId,
      role: type === MessageType.ADMIN ? "moderator" : "fira_audience",
    },
    externalId: message.itemId,
    message: message.msg,
    eventStartDate,
  };

  if (parentId) {
    body = { ...body, parentId, isGlobal: typeof parentId === "string" };
  }

  return apiActions.post("/v1/fira-chat/message", body);
};

export const pinMessageToAPI = (
  src: string,
  message: UserMessage,
  parentId?: string
) => {
  let body: MessageRequest = {
    broadcastId: src,
    chatUser: {
      role: message.type === MessageType.ADMIN ? "moderator" : "fira_audience",
      banned: false,
      isAnonUser: false,
      name: message.from,
      userId: message.userId,
    },
    externalId: message.itemId,
    message: message.msg,
  };

  if (parentId) {
    body = { ...body, parentId, isGlobal: typeof parentId === "string" };
  }

  return apiActions.post("/v1/fira-chat/message/pin", body);
};

export const deleteMessageToAPI = (
  src: string,
  message: UserMessage,
  parentId?: string
) => {
  let body: DeleteMessageRequestBody = {
    broadcastId: src,
    externalId: message.itemId,
    message: message.msg,
  };

  if (parentId) {
    body = { ...body, parentId, isGlobal: typeof parentId === "string" };
  }

  return apiActions.post("/v1/fira-chat/message/del", body);
};

export const banUserToAPI = (
  src: string,
  userId: string,
  parentId?: string
) => {
  let body: BanRequestBody = {
    broadcastId: src,
    userId,
  };

  if (parentId) {
    body = { ...body, parentId, isGlobal: typeof parentId === "string" };
  }

  return apiActions.post("/v1/fira-chat/ban", body);
};

export const getChatSummary = (src: string) => {
  return apiActions.get<GetChatSummaryResponse>(
    `/v1/fira-chat/general-summary/${src}`
  );
};

export const getChatHistorical = (
  src: string,
  isGlobal?: boolean,
  parentId?: string,
  lastMsgId?: string
) => {
  return apiActions.get<GetChatHistoricalResponse[]>(
    `/v1/fira-chat/messages/${src}`,
    {
      params: { lastId: lastMsgId, isGlobal, parentId },
    }
  );
};
