import { FiraLanding } from "../../types/Landing";
import { RequestError } from "../../services/errorHandling";
import { LandingActionType } from "./LandingActions";
import { LandingActions } from "../actionsContants";

export interface LandingState {
  isLoading: boolean;
  currentLanding?: FiraLanding;
  createSuccess?: boolean;
  updateSuccess?: boolean;
  error?: RequestError;
}

const initialState: LandingState = {
  isLoading: false,
};

export const landingReducer = (
  state = initialState,
  action: LandingActionType
): LandingState => {
  switch (action.type) {
    case LandingActions.createLandingLoading: {
      return { ...state, isLoading: true };
    }
    case LandingActions.createLandingSuccess: {
      return {
        ...state,
        isLoading: false,
        createSuccess: true,
      };
    }

    case LandingActions.createLandingFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case LandingActions.updateLandingLoading: {
      return { ...state, isLoading: true };
    }

    case LandingActions.updateLandingSuccess: {
      return {
        ...state,
        isLoading: false,
        updateSuccess: true,
      };
    }

    case LandingActions.updateLandingFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case LandingActions.cleanLandingMessage: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
