export const convertObjectToArray = (obj: {
  [key: string]: string;
}): { [key: string]: string }[] => {
  return Object.entries(obj).map(([key, value]) => ({ [key]: value }));
};

export const convertArrayToObject = (
  arr: { [key: string]: string }[]
): { [key: string]: string } => {
  return arr.reduce((acc, curr) => {
    const key = Object.keys(curr)[0];
    acc[key] = curr[key];
    return acc;
  }, {} as { [key: string]: string });
};
