import { ChangeEvent, useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Line,
  LineChart,
} from "recharts";

import "./GeneralStatisticsChart.scss";
import { ChartDataOptionItemType, CriteriaType } from "../../utils/types";
import Skeleton from "../Skeleton/Skeleton";

import ArrowRight from "../FiraIcons/ArrowRight";
import ArrowLeft from "../FiraIcons/ArrowLeft";
import Input from "../Input/Input";
import {
  completeDate,
  hyphenDateFormat,
  shortYearDate,
} from "../../utils/handleDates";
import { t } from "i18next";
import { Calendar, DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import CalendarMaterial from "../FiraIcons/CalendarMaterial";
import SelectCheckbox from "../SelectCheckBox/SelectCheckbox";
import CustomSelect from "../CustomSelect/CustomSelect";
import Select from "../Select/Select";
import "./calendar-picker.scss";
import SelectOptionBox from "../SelectCheckBox/SelectOptionBox";

interface itemsOption {
  value: string;
  label: string;
  isSelected: boolean;
}
interface StatiticsChartProps {
  title: string;
  subTitle?: string;
  lineType?:
    | "monotone"
    | "monotoneX"
    | "monotoneY"
    | "natural"
    | "step"
    | "stepAfter"
    | "stepBefore"
    | "linear"
    | undefined;
  bodyData: any[];
  fieldX: string;
  optionsToShow: ChartDataOptionItemType[];
  elementsToShow?: number;
  isLoading?: boolean;
  listenChange?(criteria: string): void;
  isSelectCheckboxes: boolean;
  criteriaSelect?: CriteriaType[] | undefined;
}

interface ChartData {
  name: string;
  active: boolean;
  color: string;
  openList?: boolean;
  id?: number;
}

export default function GeneralStatiticsChart({
  title,
  subTitle,
  lineType = "monotone",
  bodyData,
  optionsToShow,
  fieldX,
  elementsToShow = 1,
  isLoading = false,
  listenChange,
  isSelectCheckboxes,
  criteriaSelect,
}: StatiticsChartProps) {
  const [chartData, setChartData] = useState<ChartData[]>([...optionsToShow]);
  const [selectsOptions, setSelectOptions] = useState<any[]>([]);

  const [chartWidth, setChartWidth] = useState<string>("100%");
  const [criteriaSelected, setCriteriaSelected] = useState<string>("current");
  const [criteriaCall, setCriteriaCall] = useState("");
  const [checkboxItems, setCheckboxitems] = useState<itemsOption[]>([]);

  const initData = async () => {
    const newChartWidth = bodyData.length * 7;
    setChartWidth(newChartWidth < 100 ? "100%" : `${newChartWidth}%`);

    const newSelectsOptions: ChartData[] = [];
    optionsToShow.map((item, index) => {
      if (item.active && newSelectsOptions.length < elementsToShow) {
        newSelectsOptions.push({ ...item, id: index });
      }
    });

    setSelectOptions(newSelectsOptions);

    setChartData([...optionsToShow]);
  };

  useEffect(() => {
    initData();
  }, [bodyData]);

  useEffect(() => {
    listenChange && listenChange(criteriaSelected);
  }, []);

  //cuando hay un cambio de criteria, volvemos a filtrar los datos
  // con los elementos que guardamos en checkboxItems
  useEffect(() => {
    selectChange(checkboxItems);
  }, [listenChange]);

  //aqui llamamos a la funcion que filtra los datos mostrados en la grafica
  //y guardamos los filtros seleccionados
  const handleSelectedCheckbox = (items: itemsOption[]) => {
    setCheckboxitems(items);
    selectChange(items);
  };

  //CAMBIO DE ESTADO EN LA SELECCION MULTIPLE DE LA GRAFICA
  const selectChange = (items: itemsOption[]) => {
    //SET DE OPCIONES DE GRAFICA EN FALSE ANTES DE REALIZAR COMPARACION CON SELECCIONADOS
    const newChartData: ChartData[] = chartData.map((item: any) => {
      return {
        ...item,
        active: false,
      };
    });
    //SET DE ELEMENTOS SELECCIONADOS
    items.map((item) => {
      newChartData.map((charItem: ChartData) => {
        if (charItem.name === item.label) {
          charItem.active = true;
        }
      });
    });
    //ASIGNACION DE VALORES PARA ACTUALIZAR GRAFICA
    setChartData(newChartData);
  };

  const handleCustomSelectOpen = (index: number) => {
    const newSelectsOptions = selectsOptions.map((item, i) => {
      if (item.id === index) {
        return {
          ...item,
          openList: !item.openList,
        };
      } else {
        return item;
      }
    });

    setSelectOptions(newSelectsOptions);
  };

  const handleCustomSelectSelected = (
    index: number,
    name: string,
    color: string
  ) => {
    const newSelectsOptions = selectsOptions.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          name,
          color,
          openList: false,
        };
      } else {
        return item;
      }
    });

    setSelectOptions(newSelectsOptions);
    const newChartData: ChartData[] = chartData.map((item: any) => {
      return {
        ...item,
        active: false,
      };
    });

    newSelectsOptions.map((item) => {
      newChartData.map((charItem: ChartData) => {
        if (charItem.name === item.name) {
          charItem.active = true;
        }
      });
    });
    setChartData(newChartData);
  };

  const CriteriaNames = [
    { name: "current", traslate: t("views.videos.labels.current") },
    { name: "yesterday", traslate: t("views.videos.labels.yesterday") },
    { name: "last-week", traslate: t("views.videos.labels.lastWeek") },
    { name: "two-weeks", traslate: t("views.videos.labels.lastTwoWeeks") },
    { name: "last-month", traslate: t("views.videos.labels.lastMonth") },
    { name: "trimester", traslate: t("views.videos.labels.lastTrimester") },
    { name: "semester", traslate: t("views.videos.labels.lastSemester") },
    { name: "yearly", traslate: t("views.videos.labels.lastYear") },
  ];

  const getCriteriaTraslate = (criteria: string) => {
    let option = "";
    CriteriaNames.map((item, key) => {
      if (item.name === criteria) {
        option = item.traslate;
      }
    });
    return option;
  };

  const getCriteriaName = (criteria: string) => {
    let option = "";
    CriteriaNames.map((item, key) => {
      if (item.traslate === criteria) {
        option = item.name;
      }
    });
    return option;
  };

  const configCriteria = () => {
    const listCriteria: itemsOption[] = [];

    criteriaSelect?.map((item, key) => {
      listCriteria.push({
        value: getCriteriaTraslate(item.criteria),
        label: getCriteriaTraslate(item.criteria),
        isSelected: true,
      });
    });
    return listCriteria;
  };

  const handleSelectBox = (item: itemsOption) => {
    const value = item.value;

    const nameCriteria = getCriteriaName(value);
    setCriteriaCall(nameCriteria);
    listenChange && listenChange(nameCriteria);
    setCriteriaSelected(value);
  };


  return (
    <div className={"statiticsChartWrapper"}>
      <div
        style={{
          width: "100%",
          height: "285px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "16px",
          paddingTop: "16px",
          paddingBottom: "5px",
        }}
        className={"statiticsChartContainer"}
        id={"generalStatiticsChart"}
      >
        <div className={"header"}>
          {!isLoading && (
            <div className={"header_copy_wrapper"}>
              <label className={"header_title"}>{title}</label>
              <p className={"small_text"}>{subTitle}</p>
            </div>
          )}
          {isLoading && (
            <div className={"header_copy_loading"}>
              <>
                <Skeleton width={"141px"} height={"22px"} />
                <Skeleton width={"98px"} height={"22px"} />
              </>
            </div>
          )}
          <div className={"select_wrapper"}>
            {isSelectCheckboxes && (
              <div style={{ width: "140px" }}>
                <SelectCheckbox onChange={handleSelectedCheckbox} />
              </div>
            )}

            {selectsOptions.length > 1 &&
              !isLoading &&
              !isSelectCheckboxes &&
              elementsToShow > 1 &&
              selectsOptions.map((item) => {
                return (
                  <CustomSelect
                    key={item.id}
                    options={chartData}
                    handleSelected={({ name, color }) => {
                      handleCustomSelectSelected(item.id, name, color);
                    }}
                    value={item.name}
                    indicatorColor={item.color}
                    selectId={item.id}
                    width="125px"
                  />
                );
              })}
            {isSelectCheckboxes && (
              <SelectOptionBox
                onChange={handleSelectBox}
                data={configCriteria()}
                itemSelected={{
                  value: t("views.videos.labels.current"),
                  label: t("views.videos.labels.current"),
                }}
                name="mode"
              />
              /* <Select
                onChange={handleSelect}
                data={configCriteria()}
                name="mode"
                width={213}
                height={32}
                value={criteriaSelected}
              />

              /* <DateRangePickerComponent
                onChange={listenChange}
                criteriaData={criteriaSelect}
              />*/
            )}

            {isLoading && (
              <>
                <Skeleton width={"113px"} height={"22px"} />
                <Skeleton width={"113px"} height={"22px"} />
              </>
            )}
          </div>
        </div>

        <div className={"chartWrapper"}>
          {!isLoading && (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={950}
                data={bodyData}
                margin={{
                  top: 5,
                  right: 0,
                  left: -5,
                  bottom: 5,
                }}
              >
                <CartesianGrid stroke="#f0f0f0" />
                <XAxis dataKey={fieldX} stroke="#000" />
                <YAxis stroke="#000" width={50} />
                <Tooltip />

                {chartData.map((item: any, key) => {
                  if (item.active) {
                    return (
                      <Line
                        key={key}
                        type="monotone"
                        dataKey={item.dataKey}
                        stroke={item.color}
                        name={item.name}
                      />
                    );
                  }
                })}
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
}

interface dateRangeProps {
  onChange?(startDate: Date, endDate: Date): void;
  criteriaData?: CriteriaType[];
}

const DateRangePickerComponent = (props: dateRangeProps) => {
  const [opened, setOpened] = useState(false);

  const [openSelect, setOpenSelect] = useState(false);
  const [initDate, setInitDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [selectData, setSelectData] = useState<string[]>();

  useEffect(() => {
    var date = new Date();

    var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    setInitDate(primerDia);

    var ultimoDia = new Date();
    setEndDate(ultimoDia);
  }, []);
  const openRange = () => {
    setOpened(!opened);
  };

  const onClickOutsideListener = () => {
    //setOpenSelect(false);
    //setOpened(false);
    document.removeEventListener("click", onClickOutsideListener);
  };

  const showDate = () => {
    if (initDate == endDate) {
      return `${completeDate(endDate ? endDate : new Date())}`;
    } else {
      return `${shortYearDate(
        initDate ? initDate : new Date()
      )} - ${shortYearDate(endDate ? endDate : new Date())}`;
    }
  };

  const handleChangeDate = (startdate: Date, enddate: Date) => {
    setInitDate(startdate);
    setEndDate(enddate);
    props.onChange ? props.onChange(startdate, enddate) : "";
  };

  const handleChangeCriteria = (criteria: string) => {};
  return (
    <div className={"DateRangePicker"}>
      <div
        className={`${"pickerButton"} ${opened ? opened : ""}`}
        onClick={openRange}
      >
        <CalendarMaterial className={"icon"} />
        <div className={"dateText"}>{showDate()}</div>
      </div>
      {opened && (
        <div
          className={"calendarContainer"}
          onMouseLeave={() => {
            document.addEventListener("click", onClickOutsideListener);
          }}
          onMouseEnter={() => {
            document.removeEventListener("click", onClickOutsideListener);
          }}
        >
          <Personalized
            initDate={initDate}
            endDate={endDate}
            onChange={handleChangeDate}
            onChangeCriteria={handleChangeCriteria}
            criteriaData={props.criteriaData}
          />
        </div>
      )}
    </div>
  );
};
interface PersonalizedProps {
  initDate: Date;
  endDate: Date;
  onChange(init: Date, end: Date): void;
  onChangeCriteria(criteria: string): void;
  criteriaData?: CriteriaType[];
}

const Personalized = ({
  initDate,
  endDate,
  onChange,
  onChangeCriteria,
  criteriaData,
}: PersonalizedProps) => {
  const dispatch = useDispatch();
  const { brand, video } = useSelector((state: RootState) => state);
  const [startDate, setStartDate] = useState<Date>(initDate);
  const [endedDate, setEndedDate] = useState<Date>(endDate);
  const [selectedMode, setSelectedMode] = useState<string>();
  const [openCalendar, setOpenCalendar] = useState<boolean>(true);

  const weekDays = [
    t("calendar.sunday"),
    t("calendar.monday"),
    t("calendar.tuesday"),
    t("calendar.wednesday"),
    t("calendar.thursday"),
    t("calendar.friday"),
    t("calendar.saturday"),
  ];
  const months = [
    t("calendar.january"),
    t("calendar.february"),
    t("calendar.march"),
    t("calendar.april"),
    t("calendar.may"),
    t("calendar.june"),
    t("calendar.july"),
    t("calendar.august"),
    t("calendar.september"),
    t("calendar.october"),
    t("calendar.november"),
    t("calendar.december"),
  ];

  const optionRangex = [
    t("views.dashboard.dateRangeSelect.personalized"),
    t("views.dashboard.dateRangeSelect.today"),
    t("views.dashboard.dateRangeSelect.yesterday"),
    t("views.dashboard.dateRangeSelect.lastSeven"),
    t("views.dashboard.dateRangeSelect.lastThirty"),
    t("views.dashboard.dateRangeSelect.lastNinety"),
    t("views.dashboard.dateRangeSelect.lastMonth"),
    t("views.dashboard.dateRangeSelect.thisWeek"),
    t("views.dashboard.dateRangeSelect.thisMonth"),
    t("views.dashboard.dateRangeSelect.thisTrimester"),
  ];

  const arrayCriteria = () => {
    const arrayC: string[] = [
      t("views.dashboard.dateRangeSelect.personalized"),
    ];
    if (criteriaData) {
      criteriaData.map((item, key) => {
        arrayC.push(item.name);
      });
    }

    return arrayC;
  };

  const optionRange = arrayCriteria();

  const callRequest = (init: Date, end: Date | null) => {
    if (brand.currentStore && init && end) {
      end.setHours(23);
      end.setMinutes(59);
      callRequestMode(init, end);
    } else {
      if (brand.currentStore && init) {
        const newEnd = init;
        newEnd.setHours(23);
        newEnd.setMinutes(59);
        callRequestMode(init, newEnd);
      }
    }
  };

  const callRequestMode = (init: Date, end: Date) => {
    if (brand.currentStore) {
      onChange(init, end);
      const current = new Date();
      current.setHours(0);
      current.setMinutes(0);
    }
  };
  const [values, setValues] = useState([
    new DateObject("2023/02/18"),
    new DateObject("2023/02/20"),
  ]);

  const handleChange = (objects: DateObject[]) => {
    if (objects[1]) {
      setEndedDate(new Date(objects[1].toString()));
    } else {
      setEndedDate(new Date(objects[0].toString()));
    }
    if (objects[0]) {
      setStartDate(new Date(objects[0].toString()));
    }

    callRequest(
      new Date(objects[0].toString()),
      objects[1] ? new Date(objects[1].toString()) : null
    );
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    setSelectedMode((item) => value);

    if (value === t("views.dashboard.dateRangeSelect.personalized")) {
      setOpenCalendar(true);
    } else {
      setOpenCalendar(false);
      onChangeCriteria(value);
    }

    /*
    switch (value) {
      case t("views.dashboard.dateRangeSelect.personalized"):
        setOpenCalendar(true);
        break;
      case t("views.dashboard.dateRangeSelect.today"):
        setOpenCalendar(false);
        TodayMode();
        break;
      case t("views.dashboard.dateRangeSelect.yesterday"):
        setOpenCalendar(false);
        break;
      default:
        setOpenCalendar(false);
    }*/
  };

  const TodayMode = () => {
    const current = new Date();
    current.setHours(0);
    current.setMinutes(0);
    const currentEnd = new Date();
    currentEnd.setHours(23);
    currentEnd.setMinutes(59);
    setStartDate(current);
    setEndedDate(currentEnd);
    onChange(current, currentEnd);
  };

  return (
    <>
      <div className={"selectRange"}>
        <h6>{"Intervalo de fechas"}</h6>
        <Select
          onChange={handleSelect}
          data={optionRange}
          name="mode"
          width={380}
          height={36}
          value={selectedMode}
        />
      </div>

      <div className={"rangeInputs"}>
        <div>
          <h6>{t("calendar.from")}</h6>
          <Input
            key={"start"}
            value={hyphenDateFormat(startDate)}
            width="170px"
            height="36px"
            inputName="startDate"
            onChange={() => {}}
          />
        </div>
        <div>
          <h6>{t("calendar.to")}</h6>
          <Input
            key={"end"}
            value={hyphenDateFormat(endedDate)}
            width="170px"
            height="36px"
            inputName="endedDate"
            onChange={() => {}}
          />
        </div>
      </div>

      <div>
        {openCalendar && (
          <Calendar
            //value={values}
            onChange={handleChange}
            numberOfMonths={2}
            range
            hideYear
            weekDays={weekDays}
            months={months}
            shadow={false}
            renderButton={CustomButton}
            className="yellow custom-calendar "
          />
        )}
      </div>
    </>
  );
};

const CustomButton = (
  direction: String,
  handleClick: any,
  disabled: boolean
) => {
  return (
    <div
      onClick={handleClick}
      style={{
        padding: "0 10px",
        fontWeight: "bold",
        color: disabled ? "black" : "black",
        cursor: "pointer",
      }}
      className={"cursor-pointer"}
    >
      {direction === "right" ? (
        <ArrowRight className="icon" />
      ) : (
        <ArrowLeft className="icon" />
      )}
    </div>
  );
};
