import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import Button, { ButtonStyle } from "../../components/Button/Button";
import {
  createCollectionActionCreator,
  deleteCollectionActionCreator,
  editCollectionActionCreator,
  getVODByActionCreator,
  resetCurrentCollectionActionCreator,
} from "../../store/Collection/CollectionActions";
import styles from "./CollectionsDetailView.module.scss";
import { ChangeEvent, useEffect, useState } from "react";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import {
  MainLoader,
  PopUpAlert,
  TableHeader,
  TextEditor,
} from "../../components";
import {
  Drag,
  EmptyPieChartWidget,
  SimpleCheck,
  TrashCan,
} from "../../components/FiraIcons";
import ImageUploadInput from "../../components/ImageUploadInput/ImageUploadInput";
import { t } from "i18next";
import {
  FiraSimpleCollection,
  FiraVideo,
  VideoFilter,
} from "../../utils/types.d";
import { orderingType } from "../../components/Table/Table";
import VideosListModal from "../../components/Modals/VideosListModal/VideosListModal";
import noImageStoreSVG from "../../assets/svg/no-image-store.svg";
import { RootState } from "../../store/Store";
import { convertToHTML } from "draft-convert";
import {
  PopUpAlertTypes,
  PopUpButtonsTypes,
} from "../../components/PopUpAlert/PopUpAlert";
import ModalLayout from "../../layout/ModalLayout/ModalLayout";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import Skeleton from "../../components/Skeleton/Skeleton";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";

let timerID: NodeJS.Timeout;

enum messageTypes {
  loading = "loading",
  error = "error",
  success = "success",
}

export enum LocationType {
  new = "NEW",
  edit = "EDIT",
}

export interface CollectionsDetailViewForm {
  name: string;
  description: string;
  videos: string[];
}

const initialState: CollectionsDetailViewForm = {
  name: "",
  description: "",
  videos: [""],
};

const CollectionsDetailView = () => {
  const { brand, collection } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [locationType, setLocationType] = useState<LocationType>();
  const [collectionName, setCollectionName] = useState<string>("");
  const [textAreaState, setTextAreaState] = useState(
    EditorState.createWithContent(ContentState.createFromText(""))
  );
  const [form, setForm] = useState<CollectionsDetailViewForm>(initialState);
  const [videoListModalIsOpen, setVideoListModalIsOpen] =
    useState<boolean>(false);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [sometingChanged, setSomethingChanged] = useState<boolean>(false);

  const orderingList = [
    {
      id: 1,
      name: t("views.videos.filterOptions.alphabet"),
      value: VideoFilter.ascend,
    },
    {
      id: 2,
      name: t("views.videos.filterOptions.reverse"),
      value: VideoFilter.descend,
    },
  ];
  const [filterValue, setFilterValue] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<orderingType>(
    orderingList[0]
  );
  const [videosList, setVideosList] = useState<FiraVideo[]>([]);
  const [imagePreview, setImagePreview] = useState<string>("");
  const [imageFile, setImageFile] = useState<File>();
  const [videosToDelete, setVideosToDelete] = useState<string[]>([]);
  const [videosToAdd, setVideosToAdd] = useState<string[]>([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [messageType, setMessageType] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showDiscardAlert, setShowDiscardAlert] = useState<boolean>(false);

  const handleBackButton = () => {
    navigate(-1);
    dispatch(resetCurrentCollectionActionCreator());
  };

  const getVODSBy = () => {
    if (collection.currentCollection) {
      dispatch(
        getVODByActionCreator(
          collection.currentCollection.id,
          selectedFilter.value as string
        )
      );
    }
  };

  const handleDelete = () => {
    handleCancel();
    setMessageType(messageTypes.loading);
    if (collection.currentCollection) {
      dispatch(deleteCollectionActionCreator(collection.currentCollection?.id));
    }
  };

  const handleDeleteVideo = (video: FiraVideo) => {
    setSomethingChanged(true);
    if (locationType === "EDIT") {
      setVideosToDelete([...videosToDelete, video.id]);
    }
    if (videosList.includes(video)) {
      setVideosList((videosList) => [
        ...videosList.filter((v) => video.id !== v.id),
      ]);
      return;
    }
  };

  const handleCancel = () => {
    setShowDeleteAlert(false);
  };

  const handleSave = () => {
    const response = handleCollectionFormat();
    if (response && imageFile) {
      dispatch(createCollectionActionCreator(response, imageFile));
    } else {
      setShowDiscardAlert(false);
      setMessageType(messageTypes.error);
      setErrorMessage(t("views.collections.details.error"));
    }

    setShowAlert(true);
  };

  const checkDeleteFormat = (videos: string[]) => {
    let newArr: string[] = [];
    videos.map((item) => {
      const check = videosList.find((i) => i.id === item);
      if (check === undefined) {
        newArr.push(item);
      }
    });

    return newArr;
  };

  const handleEdit = () => {
    let body = {
      collectionName: form.name,
      collectionDescription: form.description,
    };

    const del = checkDeleteFormat(videosToDelete);

    if (collection.currentCollection) {
      if (imageFile) {
        if (del.length < videosList.length) {
          dispatch(
            editCollectionActionCreator(
              collection.currentCollection.id,
              body,
              del,
              videosToAdd,
              imageFile
            )
          );
        }
      } else {
        dispatch(
          editCollectionActionCreator(
            collection.currentCollection.id,
            body,
            del,
            videosToAdd
          )
        );
      }
      setShowAlert(true);
    }
  };

  const handleVideosList = (videos: FiraVideo[]) => {
    const newArr: FiraVideo[] = [...videosList];
    videos.map((item) => {
      const check = videosList.find((i) => i.id === item.id);
      if (check === undefined) {
        newArr.push(item);
      }
    });

    setVideosList(newArr);
  };

  const handleNewVideos = (videos: string[]) => {
    setSomethingChanged(true);
    setVideosToAdd(videos);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSomethingChanged(true);
    const { value } = e.target;
    setForm({ ...form, name: value });
  };

  const handleEditor = (editorState: EditorState) => {
    setSomethingChanged(true);
    const html = convertToHTML(editorState.getCurrentContent());
    setTextAreaState(editorState);
    setForm({
      ...form,
      description: html,
    });
  };

  const handleImage = (image: File) => {
    setSomethingChanged(true);
    setImageFile(image);
    setImagePreview(URL.createObjectURL(image));
  };

  const handleOrdering = (id: number) => {
    let selected = orderingList.filter((p) => {
      return p.id === id;
    });

    setSelectedFilter(selected[0]);
  };

  const handleVideoFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
    if (collection.currentCollection) {
      if (keyword !== "") {
        setVideosList(
          collection.currentCollection?.videos.filter((col) => {
            return col.videoName.toLowerCase().includes(keyword.toLowerCase());
          })
        );
      } else {
        setVideosList(collection.currentCollection?.videos);
      }
    }
  };

  const openModal = () => {
    setVideoListModalIsOpen(true);
  };

  const handleLocation = () => {
    if (location.pathname.includes("new")) {
      setLocationType(LocationType.new);
    } else if (location.pathname.includes("edit")) {
      setLocationType(LocationType.edit);
    }
  };

  const handleCollectionFormat = () => {
    let videos: string[] = [];

    videosList.map((vid) => {
      videos.push(vid.id);
    });

    if (brand.currentStore) {
      let body: FiraSimpleCollection = {
        storeId: brand.currentStore?.id,
        collectionName: form.name,
        collectionDescription: form.description,
        videos: videos,
      };

      return body;
    }
  };

  const handleCloseAlert = () => {
    setMessageType("");
    setShowAlert(false);
  };

  const deleteAlertButtos: PopUpButtonsTypes[] = [
    {
      title: t("views.collections.details.delete"),
      handleClick() {
        handleDelete();
      },
      type: "border",
    },
    {
      title: t("views.collections.details.cancel"),
      handleClick() {
        handleCancel();
      },
      type: "fill",
    },
  ];

  const discardAlertButtos: PopUpButtonsTypes[] = [
    {
      title: t("views.collections.details.leavePageButton"),
      handleClick() {
        handleBackButton();
      },
      type: "border",
    },
    {
      title: t("views.collections.details.saveAndLeave"),
      handleClick() {
        locationType === "EDIT" ? handleEdit() : handleSave();
      },
      type: "fill",
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      setVideosList((prevItem) =>
        arrayMoveImmutable(prevItem, oldIndex, newIndex)
      );
    }
  };

  const HeaderRender = () => {
    return (
      <div className={styles.HeaderRender}>
        <div style={{ width: "52px" }} className={styles.Head}></div>
        <div style={{ width: "80px" }} className={styles.Head}></div>
        <div style={{ width: "190px" }} className={styles.Head}>
          {t("views.collections.details.table.name")}
        </div>
        <div style={{ width: "100px" }} className={styles.Head}>
          {t("views.collections.details.table.desc")}
        </div>
        <div style={{ width: "100px" }} className={styles.Head}>
          {t("views.collections.details.table.teaser")}
        </div>
        <div style={{ width: "100px" }} className={styles.Head}>
          {t("views.collections.details.table.image")}
        </div>
        <div style={{ width: "100px" }} className={styles.Head}>
          {t("views.collections.details.table.products")}
        </div>
        <div style={{ width: "130px" }} className={styles.Head}></div>
      </div>
    );
  };

  interface RowRenderProps {
    video: FiraVideo;
    clickHandler(video: FiraVideo): void;
  }

  const RowRender = ({ video, clickHandler }: RowRenderProps) => {
    const clickDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      clickHandler(video);
    };
    return (
      <li className={styles.RowRender}>
        <div className={styles.Cell}>
          <div className={styles.DragItem} style={{ width: "52px" }}>
            <Drag />
          </div>
        </div>
        <div className={styles.Cell} style={{ width: "80px" }}>
          <div className={styles.ImageContainer}>
            <img
              src={
                video.eventPreviewImage
                  ? video.eventPreviewImage
                  : noImageStoreSVG
              }
              alt=""
            />
          </div>
        </div>
        <div className={styles.Cell} style={{ width: "190px" }}>
          <p>{video.videoName}</p>
        </div>
        <div className={styles.Cell} style={{ width: "100px" }}>
          {video.videoDescription !== null ? <SimpleCheck /> : <span>-</span>}
        </div>
        <div className={styles.Cell} style={{ width: "100px" }}>
          {video.eventTeaser !== null ? <SimpleCheck /> : <span>-</span>}
        </div>
        <div className={styles.Cell} style={{ width: "100px" }}>
          {video.eventPreviewImage !== null ? <SimpleCheck /> : <span>-</span>}
        </div>
        <div className={styles.Cell} style={{ width: "100px" }}>
          {video.productsCount}
        </div>
        <div className={`${styles.DeleteCell}`} style={{ width: "130px" }}>
          <button onClick={clickDelete}>
            <TrashCan />
          </button>
        </div>
      </li>
    );
  };

  const SkeletonRow = () => {
    return (
      <li className={styles.RowRender}>
        <div className={styles.Cell}>
          <div className={styles.DragItem} style={{ width: "52px" }}></div>
        </div>
        <div className={styles.Cell} style={{ width: "80px" }}>
          <Skeleton width="32px" height="32px" />
        </div>
        <div className={styles.Cell} style={{ width: "190px" }}>
          <Skeleton width="160px" height="22px" />
        </div>
        <div className={styles.Cell} style={{ width: "100px" }}>
          <Skeleton width="80px" height="22px" />
        </div>
        <div className={styles.Cell} style={{ width: "100px" }}>
          <Skeleton width="80px" height="22px" />
        </div>
        <div className={styles.Cell} style={{ width: "100px" }}>
          <Skeleton width="80px" height="22px" />
        </div>
        <div className={styles.Cell} style={{ width: "100px" }}>
          <Skeleton width="80px" height="22px" />
        </div>
        <div
          className={`${styles.DeleteCell}`}
          style={{ width: "130px" }}
        ></div>
      </li>
    );
  };

  const SortableListItem = SortableElement(
    ({
      item,
      callback,
    }: {
      item: FiraVideo;
      callback(video: FiraVideo): void;
    }) => {
      const element: FiraVideo = item;
      const handleCallback = () => {
        callback(element);
      };

      return <RowRender video={element} clickHandler={handleCallback} />;
    }
  );

  const SortableList = SortableContainer(
    ({
      items,
      callback,
    }: {
      items: FiraVideo[];
      callback(video: FiraVideo): void;
    }) => {
      return (
        <ul className="list-item">
          {items.map((item, index) => {
            return (
              <SortableListItem
                key={index}
                index={index}
                item={item}
                callback={callback}
              />
            );
          })}
        </ul>
      );
    }
  );

  const sortCollectionVideos = () => {
    const videos = collection.collectionVideos?.videos;
    if (videos) setVideosList(videos);

    let newVideoArray: string[] = [];
    if (videos && videos.length > 0) {
      videos.map((vid) => {
        newVideoArray.push(vid.id);
      });
    }

    return newVideoArray;
  };

  const checkValidation = () => {
    if (
      sometingChanged &&
      videosList.length !== 0 &&
      !collection.isLoading &&
      form.name !== ""
    ) {
      return false;
    }
    return true;
  };

  const handleDiscardAlert = () => {
    if (sometingChanged) {
      setShowDiscardAlert(true);
    } else {
      handleBackButton();
    }
  };

  useEffect(() => {
    if (locationType === "EDIT" && collection.currentCollection) {
      const { collectionName, collectionDescription } =
        collection.currentCollection;
      setForm({
        name: collectionName,
        description: collectionDescription ? collectionDescription : "",
        videos: sortCollectionVideos(),
      });
      if (collection.currentCollection) {
        setCollectionName(collection.currentCollection.collectionName);
        setImagePreview(collection.currentCollection.imageUrl || "");
        const blocksFromHTML = convertFromHTML(
          collectionDescription ? collectionDescription : ""
        );
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setTextAreaState(EditorState.createWithContent(state));
      }
    }
  }, [locationType]);

  useEffect(() => {
    if (showAlert) {
      if (collection.isLoading && showAlert) {
        setMessageType(messageTypes.loading);
      } else {
        if (collection.error && showAlert) {
          setMessageType(messageTypes.error);
          setErrorMessage(t("views.collections.details.error"));
          setShowDiscardAlert(false);
          setShowDeleteAlert(false);
        } else if (!collection.error && showAlert) {
          setMessageType(messageTypes.success);
          setShowDiscardAlert(false);
          setShowDeleteAlert(false);
          setSomethingChanged(false);
        }
        if (messageType !== "") {
          timerID = setTimeout(() => {
            handleCloseAlert();
          }, 5000);
        }
      }
    }

    if (collection.deletedCollection) {
      navigate(-1);
    }
  }, [collection.isLoading, collection.error, collection.deletedCollection]);

  useEffect(() => {
    getVODSBy();
  }, [selectedFilter]);

  useEffect(() => {
    sortCollectionVideos();
  }, [collection.collectionVideos]);

  useEffect(() => {
    handleLocation();
  }, []);

  const style = {
    marginLeft: "5px",
  };
  let optionBtn = (
    <>
      <div style={style}>
        <Button
          clickHandler={handleDiscardAlert}
          buttonStyle={ButtonStyle.border}
        >
          <span className={styles["body-12-low-text"]}>
            {t("views.collections.details.discard")}
          </span>
        </Button>
      </div>
      <div style={style}>
        <Button
          clickHandler={locationType === "EDIT" ? handleEdit : handleSave}
          isDisabled={checkValidation()}
        >
          <span className={styles["body-12-low-text"]}>
            {t("views.collections.details.save")}
          </span>
        </Button>
      </div>
    </>
  );
  return (
    <>
      <FiraNavBar
        backButtonHandler={handleBackButton}
        titleSection={
          locationType === "NEW"
            ? t("views.collections.details.newTitle")
            : collection.currentCollection?.collectionName
        }
        trailingOptions={optionBtn}
      />

      {messageType === messageTypes.loading && <MainLoader />}
      {messageType !== "" && messageType !== messageTypes.loading ? (
        <PopUpAlert
          width="100%"
          type={
            {
              success: PopUpAlertTypes.success,
              error: PopUpAlertTypes.error,
            }[messageType]
          }
          title={
            {
              success: t("views.collections.details.saved"),
              error: errorMessage,
            }[messageType]
          }
          handleCloseIcon={handleCloseAlert}
        />
      ) : (
        ""
      )}
      <div className={styles.CollectionsDetailViewWrapper}>
        <div className={styles.TopContainer}>
          <div id={styles["form"]} className={styles.Card}>
            <div className={styles.InputGroup}>
              <label htmlFor="name">
                {t("views.collections.details.collectionName")}
              </label>
              <Input
                inputName={"name"}
                inputId={"name"}
                value={form.name}
                width={"100%"}
                height={"32px"}
                onChange={handleChange}
              />
            </div>
            <div className={styles.InputGroup}>
              <label htmlFor="desc">
                {t("views.collections.details.description")}
              </label>
              <TextEditor
                editorState={textAreaState}
                onChangeHandler={handleEditor}
                id="description"
                name="description"
                visibleCounter={false}
                maxLenght={140}
              />
            </div>
          </div>
          <div id={styles["image"]} className={styles.Card}>
            <>
              <div className={styles.UploadCard}>
                <ImageUploadInput
                  description={t("views.collections.details.cover")}
                  fileFormat={"Jpg, Jpeg, Png"}
                  fileSize={"600x600 (1:1)"}
                  imageResult={handleImage}
                  preview={
                    imagePreview || collection.currentCollection?.imageUrl
                  }
                />
              </div>
            </>
          </div>
        </div>

        <div id={styles["list"]} className={styles.Card}>
          <span className={styles.CardTitle}>
            {t("views.collections.details.listTitle")}
          </span>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                display:
                  videosList !== undefined && videosList.length > 0
                    ? "block"
                    : "none",
              }}
            >
              <TableHeader
                hideCheckBox
                searchInputValue={filterValue}
                searchInputChangeHandle={handleVideoFiltering}
                orderingList={orderingList}
                orderingValue={selectedFilter}
                orderingClick={handleOrdering}
                onFocus={() => setIsFiltering(true)}
                onBlur={() => setIsFiltering(false)}
              />
            </div>

            {videosList && videosList.length > 0 ? (
              <>
                <div
                  style={{
                    maxHeight: "240px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {videosList.length > 0 ? (
                    <>
                      <HeaderRender />
                      <div className={styles.PseudoTable}>
                        {!collection.isLoading ? (
                          <SortableList
                            axis={"y"}
                            items={videosList}
                            onSortEnd={onSortEnd}
                            callback={handleDeleteVideo}
                          />
                        ) : (
                          <>
                            <SkeletonRow />
                            <SkeletonRow />
                            <SkeletonRow />
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className={styles.EmptyStateOnSearch}>
                      <EmptyPieChartWidget />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <EmptyCollectionsState />
            )}
            <div className={styles.TableBottom}>
              <Button buttonStyle={ButtonStyle.border} clickHandler={openModal}>
                <span>{t("views.collections.details.table.addButton")}</span>
              </Button>
            </div>
          </div>
        </div>
        {locationType === "EDIT" && (
          <>
            <div className={styles.separator}></div>
            <div style={{ marginLeft: "auto" }}>
              <Button
                buttonStyle={ButtonStyle.delete}
                height={"32px"}
                width={"142px"}
                clickHandler={() => setShowDeleteAlert(true)}
              >
                <span style={{ fontWeight: "500" }}>
                  {t("views.collections.details.delete")}
                </span>
              </Button>
            </div>
          </>
        )}
      </div>

      {videoListModalIsOpen && (
        <VideosListModal
          isOpen={videoListModalIsOpen}
          onClose={() => setVideoListModalIsOpen(false)}
          save={handleVideosList}
          addNewVideos={handleNewVideos}
          preSelectedList={videosList}
        />
      )}
      {showDeleteAlert && !showDiscardAlert && (
        <ModalLayout>
          <PopUpAlert
            type={PopUpAlertTypes.warning}
            title={t("views.collections.details.deleteAlert", {
              collectionName,
            })}
            message={t("views.collections.details.deleteMessage")}
            width={"620px"}
            buttons={deleteAlertButtos}
          />
        </ModalLayout>
      )}
      {showDiscardAlert && !showDeleteAlert && (
        <ModalLayout>
          <PopUpAlert
            type={PopUpAlertTypes.warning}
            title={t("views.collections.details.discardAlert")}
            message={t("views.collections.details.discardMessage")}
            width={"620px"}
            buttons={discardAlertButtos}
          />
        </ModalLayout>
      )}
    </>
  );
};

const EmptyCollectionsState = () => {
  return (
    <div className={styles.EmptyStateWrapper}>
      <div className={styles.emptyDescription}>
        {t("views.collections.details.novideos")}
      </div>
    </div>
  );
};

export default CollectionsDetailView;
