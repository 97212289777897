import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgBigSmartphone = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="70 80 560 400"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M481.19 52.312a41.376 41.376 0 0 0-41.375-41.375h-179.48a41.373 41.373 0 0 0-41.375 41.375v455.38a41.376 41.376 0 0 0 41.375 41.375h179.48a41.373 41.373 0 0 0 41.375-41.375zm-63.422-29.57a3.533 3.533 0 0 0-3.535 3.535v2.137c0 3.668-1.457 7.183-4.051 9.777a13.816 13.816 0 0 1-9.773 4.051h-100.98c-3.668 0-7.184-1.457-9.778-4.05s-4.05-6.11-4.05-9.778v-2.137a3.533 3.533 0 0 0-3.532-3.535h-21.738a29.566 29.566 0 0 0-29.566 29.57v455.38a29.565 29.565 0 0 0 29.566 29.57h179.48c7.84 0 15.363-3.117 20.906-8.66a29.581 29.581 0 0 0 8.66-20.91V52.312a29.582 29.582 0 0 0-8.66-20.91 29.572 29.572 0 0 0-20.906-8.66zm-40.898 2.45a4.972 4.972 0 0 1 4.973 4.968 4.973 4.973 0 0 1-4.973 4.973 4.971 4.971 0 0 1 0-9.941zm-11.695 4.968a2.298 2.298 0 0 0-2.297-2.297h-25.605a2.298 2.298 0 0 0 0 4.598h25.605a2.299 2.299 0 0 0 2.297-2.301z"
      fillRule="evenodd"
    />
    <path
      d="M379.17 30.16c0 3.066-4.598 3.066-4.598 0 0-3.063 4.598-3.063 4.598 0M221.22 96.211h-2.191a2.61 2.61 0 0 0-1.852.77 2.617 2.617 0 0 0-.77 1.851v14.023c0 .695.278 1.36.77 1.852a2.61 2.61 0 0 0 1.852.77h2.191zM221.22 135.36h-2.191a2.61 2.61 0 0 0-1.852.77 2.617 2.617 0 0 0-.77 1.851v33.906a2.623 2.623 0 0 0 2.621 2.621h2.192zM221.22 186.32h-2.191c-.696 0-1.364.273-1.852.766a2.617 2.617 0 0 0-.77 1.851v33.906c0 .696.278 1.364.77 1.856a2.606 2.606 0 0 0 1.852.765h2.191zM483.59 151.65c0-.695-.277-1.36-.77-1.852a2.61 2.61 0 0 0-1.851-.77h-2.192v63.384h2.192a2.61 2.61 0 0 0 1.851-.77 2.61 2.61 0 0 0 .77-1.851V151.65z"
      fillRule="evenodd"
    />
    <use x={70} y={592.813} xlinkHref="#BigSmartphone_svg__v" />
    <use x={78.027} y={592.813} xlinkHref="#BigSmartphone_svg__a" />
    <use x={83.422} y={592.813} xlinkHref="#BigSmartphone_svg__b" />
    <use x={90.84} y={592.813} xlinkHref="#BigSmartphone_svg__f" />
    <use x={98.219} y={592.813} xlinkHref="#BigSmartphone_svg__c" />
    <use x={103.449} y={592.813} xlinkHref="#BigSmartphone_svg__b" />
    <use x={110.867} y={592.813} xlinkHref="#BigSmartphone_svg__e" />
    <use x={122.504} y={592.813} xlinkHref="#BigSmartphone_svg__m" />
    <use x={130.332} y={592.813} xlinkHref="#BigSmartphone_svg__l" />
    <use x={141.27} y={592.813} xlinkHref="#BigSmartphone_svg__k" />
    <use x={149.734} y={592.813} xlinkHref="#BigSmartphone_svg__j" />
    <use x={157.52} y={592.813} xlinkHref="#BigSmartphone_svg__e" />
    <use x={165.352} y={592.813} xlinkHref="#BigSmartphone_svg__a" />
    <use x={170.746} y={592.813} xlinkHref="#BigSmartphone_svg__i" />
    <use x={178.301} y={592.813} xlinkHref="#BigSmartphone_svg__u" />
    <use x={187.277} y={592.813} xlinkHref="#BigSmartphone_svg__a" />
    <use x={192.672} y={592.813} xlinkHref="#BigSmartphone_svg__f" />
    <use x={200.055} y={592.813} xlinkHref="#BigSmartphone_svg__t" />
    <use x={207.883} y={592.813} xlinkHref="#BigSmartphone_svg__h" />
    <use x={215.668} y={592.813} xlinkHref="#BigSmartphone_svg__i" />
    <use x={219.418} y={592.813} xlinkHref="#BigSmartphone_svg__g" />
    <use x={70} y={603.75} xlinkHref="#BigSmartphone_svg__s" />
    <use x={74.758} y={603.75} xlinkHref="#BigSmartphone_svg__a" />
    <use x={80.152} y={603.75} xlinkHref="#BigSmartphone_svg__d" />
    <use x={87.668} y={603.75} xlinkHref="#BigSmartphone_svg__r" />
    <use x={102.871} y={603.75} xlinkHref="#BigSmartphone_svg__c" />
    <use x={108.102} y={603.75} xlinkHref="#BigSmartphone_svg__h" />
    <use x={115.887} y={603.75} xlinkHref="#BigSmartphone_svg__b" />
    <use x={127.113} y={603.75} xlinkHref="#BigSmartphone_svg__q" />
    <use x={136.266} y={603.75} xlinkHref="#BigSmartphone_svg__d" />
    <use x={143.781} y={603.75} xlinkHref="#BigSmartphone_svg__p" />
    <use x={151.566} y={603.75} xlinkHref="#BigSmartphone_svg__j" />
    <use x={163.16} y={603.75} xlinkHref="#BigSmartphone_svg__o" />
    <use x={171.176} y={603.75} xlinkHref="#BigSmartphone_svg__a" />
    <use x={176.57} y={603.75} xlinkHref="#BigSmartphone_svg__d" />
    <use x={184.086} y={603.75} xlinkHref="#BigSmartphone_svg__n" />
    <use x={187.836} y={603.75} xlinkHref="#BigSmartphone_svg__b" />
    <use x={195.254} y={603.75} xlinkHref="#BigSmartphone_svg__g" />
    <use x={201.734} y={603.75} xlinkHref="#BigSmartphone_svg__c" />
  </svg>
);

export default SvgBigSmartphone;
