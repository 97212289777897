import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgTimeClockFill = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 15.157c3.533 0 6.396-2.848 6.396-6.361S11.532 2.435 8 2.435c-3.532 0-6.396 2.848-6.396 6.36 0 3.514 2.864 6.362 6.396 6.362Z"
      fill="#000"
      stroke="#000"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 5.615v3.711h2.665" fill="#000" />
    <path
      d="M8 5.615v3.711h2.665"
      stroke="#fff"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="m4.27.844-3.198 3.18Z" fill="#000" />
    <path
      d="m4.27.844-3.198 3.18"
      stroke="#000"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="m11.73.844 3.198 3.18Z" fill="#000" />
    <path
      d="m11.73.844 3.198 3.18"
      stroke="#000"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="m3.736 13.566-1.598 1.59Z" fill="#000" />
    <path
      d="m3.736 13.566-1.598 1.59"
      stroke="#000"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="m12.264 13.566 1.598 1.59Z" fill="#000" />
    <path
      d="m12.264 13.566 1.598 1.59"
      stroke="#000"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgTimeClockFill;
