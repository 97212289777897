import { RequestError } from "../../services/errorHandling";
import { FiraCollection, FiraVideo, VideoFilter } from "../../utils/types";
import { CollectionActions } from "../actionsContants";
import { CollectionActionType } from "./CollectionActions";

export interface CollectionState {
  isLoading: boolean;
  list: FiraCollection[];
  error?: RequestError;
  collectionVideos?: FiraCollection;
  currentCollection?: FiraCollection;
  deletedCollection?: boolean;
}

const initialState: CollectionState = {
  isLoading: false,
  list: [],
};

export const collectionReducer = (
  state = initialState,
  action: CollectionActionType
): CollectionState => {
  switch (action.type) {
    case CollectionActions.getCollectionsLoading: {
      return { ...state, isLoading: true, error: undefined };
    }
    case CollectionActions.getCollectionsSuccess: {
      const { payload } = action;
      let newList = [] as FiraCollection[];
      if (state.list.length > 0) {
        const newCollections = payload as FiraCollection[];
        newList = [...state.list];
        newList.push(...newCollections);
      } else {
        newList = payload as FiraCollection[];
      }
      return {
        ...state,
        list: newList,
        isLoading: false,
        error: undefined,
      };
    }

    case CollectionActions.getCollectionsFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case CollectionActions.resetCollectionList: {
      return {
        isLoading: false,
        list: [],
        error: undefined,
      };
    }

    case CollectionActions.setCurrentCollection: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        currentCollection: payload as FiraCollection,
        error: undefined,
      };
    }

    case CollectionActions.resetCurrentCollection: {
      return {
        ...state,
        currentCollection: initialState.currentCollection,
      };
    }

    case CollectionActions.createCollectionLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }

    case CollectionActions.createCollectionSuccess: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        currentCollection: payload as FiraCollection,
      };
    }

    case CollectionActions.createCollectionFailed: {
      const { payload } = action;
      return { ...state, isLoading: true, error: undefined };
    }

    case CollectionActions.deleteCollectionLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }

    case CollectionActions.deleteCollectionSuccess: {
      const { payload } = action;

      return {
        ...state,
        isLoading: false,
        error: undefined,
        deletedCollection: true,
      };
    }

    case CollectionActions.deleteCollectionFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case CollectionActions.getCollectionsLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }

    case CollectionActions.editCollectionLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }

    case CollectionActions.editCollectionSuccess: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };
    }

    case CollectionActions.editCollectionFailed: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    case CollectionActions.sortCollectionVideosByLoading: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }

    case CollectionActions.sortCollectionVideosBySuccess: {
      const { payload } = action;

      return {
        ...state,
        isLoading: false,
        collectionVideos: payload as FiraCollection,
        error: undefined,
      };
    }

    case CollectionActions.sortCollectionVideosByFailed: {
      const { payload } = action;

      return {
        ...state,
        isLoading: false,
        error: payload as RequestError,
      };
    }

    default: {
      return state;
    }
  }
};
