import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCrossCircleFill = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15 4C8.928 4 4 8.928 4 15s4.928 11 11 11 11-4.928 11-11S21.072 4 15 4Zm5.247 16.5-2.09-.011L15 16.617l-3.146 3.872-2.101.011a.258.258 0 0 1-.253-.264.27.27 0 0 1 .055-.165L13.691 15 9.566 9.94a.293.293 0 0 1-.066-.176c0-.143.11-.264.253-.264l2.101.011L15 13.383l3.146-3.872 2.101-.011c.132 0 .253.121.253.264a.27.27 0 0 1-.055.165L16.32 15l4.125 5.071c.033.044.055.11.055.165 0 .143-.11.264-.253.264Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCrossCircleFill;
