import axios, { AxiosError } from "axios";
import { error } from "console";
import { config } from "process";
import { Dispatch, Store } from "redux";
import { logoutActionCreator } from "../store/Auth/AuthActions";
import { RootState } from "../store/Store";
import {
  apiBusinessPrivate,
  apiManagementPrivate,
  apiServicePrivate,
} from "./api";

const interceptorSetup = (store: Store<RootState>) => {
  const { dispatch } = store;

  apiManagementPrivate.interceptors.request.use(
    (config) => {
      const { authentication } = store.getState();
      const token = authentication.user?.token as string;

      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiManagementPrivate.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: Error | AxiosError) => {
      const { authentication } = store.getState();
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          authentication.sessionId && logout(dispatch);
        }
        return Promise.reject(error);
      }
      authentication.sessionId && logout(dispatch);
      return Promise.reject(error);
    }
  );

  apiBusinessPrivate.interceptors.request.use(
    (config) => {
      const { authentication } = store.getState();
      const token = authentication.user?.token as string;

      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiBusinessPrivate.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: Error | AxiosError) => {
      const { authentication } = store.getState();
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          authentication.sessionId && logout(dispatch);
        }
        return Promise.reject(error);
      }
      authentication.sessionId && logout(dispatch);
      return Promise.reject(error);
    }
  );

  apiServicePrivate.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: Error | AxiosError) => {
      const { authentication } = store.getState();
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          authentication.sessionId && logout(dispatch);
        }
        return Promise.reject(error);
      }
      authentication.sessionId && logout(dispatch);
      return Promise.reject(error);
    }
  );
};

const logout = async (dispatch: Dispatch) => {
  dispatch(logoutActionCreator());
};

export default interceptorSetup;
