import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalLayout from "../../layout/ModalLayout/ModalLayout";
import { replaceVideoService, updateVideo } from "../../services/videos";
import Button, { ButtonStyle } from "../Button/Button";
import { Edit, Replace } from "../FiraIcons";
import PopUpAlert, {
  PopUpAlertTypes,
  PopUpButtonsTypes,
} from "../PopUpAlert/PopUpAlert";
import VideoControls from "../VideoControls/VideoControls";
import "./VideoSettings.scss";
import { MAX_VIDEO_FILE_SIZE } from "../../utils/constants";

interface VideoSettingsProps {
  title: string;
  subtitle: string;
  videoUrl: string;
  videoId: string;
  disableButtons?: boolean;
}

interface VideoFile {
  url: string | null;
  file: File | null;
}

interface SettingsType {
  showPopUp: boolean;
  videoFile: VideoFile;
  hasError: boolean;
  formatError: boolean;
  sizeError: boolean;
  videoNoAvailable: boolean;
  showLoading: boolean;
  percentageVideoLoaded: number;
  disableButtons: boolean;
  onlyOneMp4: boolean;
  missingTSorHSL: boolean;
  fileList?: File[];
}

const VideoSettings = ({
  title,
  subtitle,
  videoUrl,
  videoId,
  disableButtons = false,
}: VideoSettingsProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [settings, setSettings] = useState<SettingsType>({
    showPopUp: false,
    showLoading: false,
    hasError: false,
    formatError: false,
    sizeError: false,
    videoNoAvailable: false,
    disableButtons: disableButtons,
    videoFile: {
      url: null,
      file: null,
    },
    percentageVideoLoaded: 0,
    onlyOneMp4: false,
    missingTSorHSL: false,
    fileList: undefined,
  });

  const { t } = useTranslation();

  const handlePopUpOpen = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      showPopUp: true,
    }));
  };

  const handlePopUpClose = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      showPopUp: false,
    }));
  };

  const handlePopUpAccept = () => {
    selectedVideo();
    handlePopUpClose();
  };

  const popUpsButtons: PopUpButtonsTypes[] = [
    {
      title: "Cancelar",
      handleClick: handlePopUpClose,
      type: "border",
    },
    {
      title: "Aceptar",
      handleClick: handlePopUpAccept,
      type: "fill",
    },
  ];

  //NO BORRES ESTO RAYNE
  const onUploadProgress = (progressEvent: ProgressEvent) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      showLoading: true,
    }));

    const loaded = progressEvent.loaded;
    const total = progressEvent.total;
    const percentCompleted = Math.round((loaded * 100) / total);

    setSettings((prevSettings) => ({
      ...prevSettings,
      percentageVideoLoaded: percentCompleted,
    }));
  };

  const selectedVideo = async () => {
    const video = videoRef?.current;

    const { url, file } = settings.videoFile;

    if (video && url && file) {
      const res = await replaceVideoService(videoId, [file]);
      if (res.status !== 200) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          hasError: true,
        }));
      }
      setSettings((prevSettings) => ({
        ...prevSettings,
        showLoading: false,
        showPopUp: false,
      }));

      if (res.status === 200) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          disableButtons: true,
          videoNoAvailable: false,
          showLoading: false,
          showPopUp: false,
        }));
        video.src = url;
        video.load();
      }
    } else if (settings.fileList) {
      const res = await replaceVideoService(videoId, settings.fileList);
      if (res.status !== 200) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          hasError: true,
        }));
      }
      setSettings((prevSettings) => ({
        ...prevSettings,
        showLoading: false,
        showPopUp: false,
      }));

      if (res.status === 200) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          disableButtons: true,
          videoNoAvailable: true,
          showLoading: false,
          showPopUp: false,
        }));
      }
    }
  };

  const handleReplaceVideo = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;

    input.addEventListener("change", (e: Event) => {
      const target = e.target as HTMLInputElement;
      const selectedFiles = Array.from(target.files as FileList);

      if (selectedFiles.length === 0) {
        return;
      }

      const hasM3u8 = selectedFiles.some((file) => file.name.endsWith(".m3u8"));
      const hasTs = selectedFiles.some((file) => file.name.endsWith(".ts"));
      const allMp4 = selectedFiles.every((file) => file.type === "video/mp4");
      const selectedM3u8Files: File[] = [];

      if (allMp4) {
        if (selectedFiles.length > 1) {
          setSettings((prevSettings) => ({
            ...prevSettings,
            sizeError: false,
            formatError: false,
            hasError: false,
            onlyOneMp4: true,
            missingTSorHSL: false,
          }));
          return;
        }
      } else if (hasM3u8 && hasTs) {
        selectedM3u8Files.push(
          ...selectedFiles.filter((file) => file.name.endsWith(".m3u8"))
        );
        selectedM3u8Files.push(
          ...selectedFiles.filter((file) => file.name.endsWith(".ts"))
        );
      } else {
        setSettings((prevSettings) => ({
          ...prevSettings,
          sizeError: false,
          formatError: false,
          hasError: false,
          onlyOneMp4: false,
          missingTSorHSL: true,
        }));
        return;
      }
      let file = selectedFiles[0];
      if (file.type.startsWith("video/mp4")) {
        const url = URL.createObjectURL(file);

        setSettings((prevSettings) => ({
          ...prevSettings,
          videoFile: {
            url,
            file,
          },
        }));

        handlePopUpOpen();
      } else {
        setSettings((prevSettings) => ({
          ...prevSettings,
          sizeError: false,
          formatError: false,
          hasError: false,
          onlyOneMp4: false,
          missingTSorHSL: false,
          fileList: selectedM3u8Files,
        }));

        handlePopUpOpen();
      }
    });

    input.click();
  };

  const closePopUp = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      hasError: false,
      formatError: false,
      sizeError: false,
      onlyOneMp4: false,
      missingTSorHSL: false,
    }));
  };

  const handleReAttempt = () => {
    selectedVideo();
    closePopUp();
  };

  useEffect(() => {
    // video url is hls
    if (videoUrl.includes("m3u8")) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        videoNoAvailable: true,
      }));
    }
  }, []);

  return (
    <div className="VideoSettings">
      <div className="video_settings_wrapper">
        <div className="info_container">
          <h5 className="info_title">{title}</h5>
          <p className="info_text">{subtitle}</p>
        </div>
        <div className="video_wrapper">
          <video
            className="setting_video"
            src={videoUrl}
            ref={videoRef}
            muted
            playsInline
          ></video>
          {settings.videoNoAvailable && (
            <h6 className="noAvailableVideo">
              {t("components.videoSettings.errorVideo")}
            </h6>
          )}
          {!settings.videoNoAvailable && <VideoControls videoRef={videoRef} />}
        </div>
        <div className="buttons_wrapper">
          <Button
            clickHandler={handleReplaceVideo}
            buttonStyle={ButtonStyle.border}
            height="40px"
            width="176px"
            isDisabled={settings.disableButtons}
          >
            <div className="button buttonReplace">
              <p>{t("components.videoSettings.replaceVideo")}</p>
              <Replace
                style={{
                  fontSize: "15px",
                }}
              />
            </div>
          </Button>
        </div>
      </div>
      {settings.showPopUp && !settings.hasError && (
        <ModalLayout>
          <PopUpAlert
            title={t("components.videoSettings.popUpDecision.title")}
            message={t("components.videoSettings.popUpDecision.message")}
            type={PopUpAlertTypes.warning}
            width="620px"
            buttons={popUpsButtons}
          ></PopUpAlert>
        </ModalLayout>
      )}
      {settings.hasError && (
        <SettingsErrorModal
          handleClickText={handleReAttempt}
          closeErrorPopUp={closePopUp}
          title={t("components.videoSettings.popUpError.title")}
          message={t("components.videoSettings.popUpError.message")}
          clickText={t("components.videoSettings.popUpError.retry")}
        />
      )}
      {settings.formatError && (
        <SettingsErrorModal
          handleClickText={() => {}}
          closeErrorPopUp={closePopUp}
          clickText=".mp4"
          title={t("components.videoSettings.popUpFormatError.title")}
          message={t("components.videoSettings.popUpFormatError.message")}
        />
      )}
      {settings.sizeError && (
        <SettingsErrorModal
          handleClickText={() => {}}
          closeErrorPopUp={closePopUp}
          clickText="2GB"
          title={t("components.videoSettings.popUpSizeError.title")}
          message={t("components.videoSettings.popUpSizeError.description")}
        />
      )}
      {settings.onlyOneMp4 && (
        <SettingsErrorModal
          handleClickText={() => {}}
          closeErrorPopUp={closePopUp}
          clickText={t("components.videoSettings.popUpError.retry")}
          title={t("components.videoSettings.popUpOnlyOneMp4Error.title")}
          message={t(
            "components.videoSettings.popUpOnlyOneMp4Error.description"
          )}
        />
      )}
      {settings.missingTSorHSL && (
        <SettingsErrorModal
          handleClickText={() => {}}
          closeErrorPopUp={closePopUp}
          clickText={t("components.videoSettings.popUpError.retry")}
          title={t("components.videoSettings.popUpTS.title")}
          message={t("components.videoSettings.popUpTS.description")}
        />
      )}
      {settings.showLoading && !settings.showPopUp && (
        <SettingsLoadingModal
          percentageVideoLoaded={settings.percentageVideoLoaded}
        />
      )}
    </div>
  );
};

export default VideoSettings;

interface SettingsLoadingModalProps {
  percentageVideoLoaded: number;
}

const SettingsLoadingModal = ({
  percentageVideoLoaded,
}: SettingsLoadingModalProps) => {
  return (
    <ModalLayout>
      <div className="settings_loading_modal">
        <div className="settings_loading_header">
          <h6>{t("components.videoSettings.popUpLoading.title")}</h6>
        </div>
        <div className="settings_loading_body">
          <div
            className="fira_logo_wrapper"
            style={{
              background:
                percentageVideoLoaded < 100
                  ? `linear-gradient(0deg,rgba(255, 222, 7, 1) 0%,rgba(255, 255, 255, 1) ${percentageVideoLoaded}%)`
                  : "rgba(255, 222, 7, 1)",
            }}
          >
            <div className="text-up">
              <span>FIRA</span>
            </div>
            <div className="text-down">
              <span className="text-left">ON</span>
              <span className="text-right">LIVE</span>
            </div>
          </div>
          <div className="settings_loading_progress">
            <h6>
              {percentageVideoLoaded}%{" "}
              {t("components.videoSettings.popUpLoading.loading")}
            </h6>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

interface SettingsErrorModalProps {
  handleClickText: () => void;
  clickText: string;
  closeErrorPopUp: () => void;
  title: string;
  message: string;
}

const SettingsErrorModal = ({
  handleClickText,
  closeErrorPopUp,
  clickText,
  title,
  message,
}: SettingsErrorModalProps) => {
  return (
    <ModalLayout>
      <PopUpAlert
        title={title}
        message={message}
        clickText={clickText}
        handleClickText={handleClickText}
        type={PopUpAlertTypes.error}
        width="620px"
        handleCloseIcon={closeErrorPopUp}
      ></PopUpAlert>
    </ModalLayout>
  );
};
