import * as React from "react";

const Filter = ({
  title,
  titleId,
  ...props
}) => (
<svg width="1em" height="1em" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1667 0H0.833333C0.61232 0 0.400358 0.0887222 0.244078 0.246649C0.0877975 0.404575 0 0.61877 0 0.842112V3.02318C0 3.46361 0.1775 3.89645 0.485833 4.20803L5 8.76975V15.158C5.00016 15.3015 5.03656 15.4425 5.10575 15.5678C5.17494 15.6931 5.27462 15.7985 5.39537 15.8739C5.51612 15.9494 5.65393 15.9925 5.79575 15.9991C5.93758 16.0057 6.07873 15.9756 6.20583 15.9117L9.53917 14.2275C9.82167 14.0843 10 13.793 10 13.4738V8.76975L14.5142 4.20803C14.8225 3.89645 15 3.46361 15 3.02318V0.842112C15 0.61877 14.9122 0.404575 14.7559 0.246649C14.5996 0.0887222 14.3877 0 14.1667 0ZM8.5775 7.82575C8.49996 7.90381 8.43847 7.99661 8.39657 8.09879C8.35467 8.20097 8.33317 8.31051 8.33333 8.42112V12.9534L6.66667 13.7955V8.42112C6.66683 8.31051 6.64533 8.20097 6.60343 8.09879C6.56152 7.99661 6.50004 7.90381 6.4225 7.82575L1.66667 3.02318V1.68422H13.3342L13.3358 3.01729L8.5775 7.82575Z" fill="black"/>
</svg>
);

export default Filter;