import React from "react";

import iPhone from "../../assets/img/iPhone.png";
import MacBook from "../../assets/img/MacBook.png";

interface Props {
  noRadiusTop?: boolean;
}
const LandingIntro: React.FC<Props> = ({ noRadiusTop = false }) => {
  const style: React.CSSProperties = noRadiusTop
    ? {}
    : { borderTopLeftRadius: 8, borderTopRightRadius: 8 };
  return (
    <div style={{ ...Wrapper, ...style }}>
      <img style={Img} src={MacBook} alt="MacBook 16 Pro" />
      <img style={Img} src={iPhone} alt="iPhone" />
    </div>
  );
};

const Wrapper: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#E6E6E6",
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  padding: 21,
  gap: 26,
};

const Img: React.CSSProperties = {
  maxHeight: 380,
};

export default LandingIntro;
