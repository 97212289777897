import { ReactNode } from "react";
import ModalLayout from "../../layout/ModalLayout/ModalLayout";
import Button, { ButtonStyle } from "../Button/Button";

import "./Dialog.scss";

interface DialogProps {
  title?: string;
  textContent?: ReactNode | ReactNode[];
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmHandler?(): void;
  cancelHandler?(): void;
}
const Dialog = ({
  title,
  textContent,
  confirmButtonText,
  cancelButtonText,
  confirmHandler,
  cancelHandler,
}: DialogProps) => {
  const buttonsStyle = {
    with: "131px",
    height: "42px",
  };

  return (
    <ModalLayout>
      <div className="DialogWrapper">
        <h5 className="Title">{title}</h5>
        <p className="TextContent alert-text-12">{textContent}</p>
        <div className="ButtonWrappers">
          <Button
            clickHandler={cancelHandler}
            buttonStyle={ButtonStyle.transparent}
            width={buttonsStyle.with}
            height={buttonsStyle.height}
          >
            <h6>{cancelButtonText}</h6>
          </Button>
          <Button
            clickHandler={confirmHandler}
            width={buttonsStyle.with}
            height={buttonsStyle.height}
          >
            <h6>{confirmButtonText}</h6>
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default Dialog;
