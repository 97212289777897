import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import {
  FiraEvent,
  FiraEventNew,
  OwnerEventCreateResponse,
  OwnerEventsType,
  RootState,
} from "../../utils/types";
import {
  Add,
  ArrowLeft,
  ArrowRight,
  MagnifyingGlass,
} from "../../components/FiraIcons";
import { Button } from "../../components";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import styles from "./OwnerEventsView.module.scss";
import { EventStatus, EventType, FiraEventType } from "../../utils/enums";
import NewCustomCalendar from "../../components/NewCustomCalendar/NewCustomCalendar";
import {
  getAllBusinessEventsByDate,
  getAllByssinessByName,
  getEventResume,
} from "../../services/events";
import toMaterialStyle from "material-color-hash";
import { ButtonStyle } from "../../components/Button/Button";
import {
  completeDate,
  convertDateUTC,
  dateFormat,
  eventDateFormat,
  timeFormat,
  timeToLocalTimeZone,
  weekDayAndNumberWithComma,
} from "../../utils/handleDates";
import Input, { InputStyles } from "../../components/Input/Input";
import { useClickOutside } from "../../utils/useClickOutside";
import FullCalendar from "@fullcalendar/react";
import { useDebounce } from "../../utils/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { EventCreationPopUp } from "../../components/Popups";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";
import { handleError } from "../../services/errorHandling";
import { useTranslation } from "react-i18next";
import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";
import EventDetailPopUp, {
  PopUpPosition,
} from "../../components/Popups/EventDetailPopUp/EventDetailPopUp";
import { EventResumeResponse } from "../../types/Response";
import ModalLayout from "../../layout/ModalLayout/ModalLayout";
import { resetCurrentStoreActionCreator } from "../../store/Brand/BrandActions";
import { setPrevPageActionCreator } from "../../store/Utils/UtilsActions";
import { cleanMessageActionCreator } from "../../store/Event/EventActions";
import { parseTime } from "format-hours";

enum CalendarNav {
  prev = "PREV",
  next = "NEXT",
  today = "TODAY",
}

enum CalendarViewTypes {
  month = "dayGridMonth",
  week = "timeGridWeek",
  day = "timeGridDay",
}

const OwnerEventsView = () => {
  const { authentication, event, utils } = useSelector(
    (state: RootState) => state
  );
  const showAlert = usePopupAlert();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentView, setCurrentView] = useState<CalendarViewTypes>(
    CalendarViewTypes.month
  );
  const [calendarHeaderTitle, setCalendarHeaderTitle] = useState("");
  const [currentCalendar, setCurrenCalendar] = useState<FullCalendar>();
  const [currentEvents, setCurrentEvents] = useState<OwnerEventsType[]>([]);
  const [posx, SetPosx] = useState(0);
  const [posy, SetPosy] = useState(0);
  const [showCreate, setShowCreate] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [eventSuccess, setEventSuccess] = useState<FiraEventNew>();
  const [isEdited, setIsEdited] = useState(false);

  const getEvents = async (initial: Date, end: Date) => {
    setCurrentEvents([]);
    if (authentication.user && authentication.user.businessClientId) {
      try {
        const response = await getAllBusinessEventsByDate(
          authentication.user?.businessClientId,
          initial.toISOString(),
          end.toISOString()
        );

        setCurrentEvents(parseResponse(response.data));
      } catch (error) {
        const handledError = handleError(error);
        showAlert(AlertType.error, handledError.message);
      }
    }
  };

  const getCalendar = (calendar: FullCalendar) => {
    setCurrenCalendar(calendar);
  };

  const handleClickEvent = (e: SyntheticEvent<HTMLButtonElement>) => {
    let parent = e.currentTarget.getBoundingClientRect();
    parent && SetPosx(parent.x + 100);
    SetPosy(parent.y + 60);
    setShowCreate(true);
    const el = document.getElementsByClassName("overlay")[0];
    el.id = "#overlay";
  };

  const handleCreateSuccess = () => {
    setShowCreate(false);
    setShowSuccess(true);

    setTimeout(() => {
      dispatch(cleanMessageActionCreator());
      if (event.currentEvent)
        setEventSuccess({
          id: event.currentEvent?.id ? event.currentEvent?.id : "",
          eventName: event.currentEvent ? event.currentEvent?.eventName : "",
          startDate: new Date(event.currentEvent?.startDate),
          endDate: event.currentEvent?.endDate,
          status: event.currentEvent
            ? event.currentEvent?.status
            : EventStatus.SCHEDULED,
        });
    }, 2500);
  };

  const calendarViewHandler = (view: CalendarViewTypes) => {
    if (currentCalendar) {
      const calendar = currentCalendar.getApi();
      switch (view) {
        case CalendarViewTypes.day:
          calendar.changeView(CalendarViewTypes.day);
          setCurrentView(CalendarViewTypes.day);
          setCalendarHeaderTitle(calendar.view.title.replace(/,|\bde\b/g, ""));
          break;
        case CalendarViewTypes.week:
          calendar.changeView(CalendarViewTypes.week);
          setCurrentView(CalendarViewTypes.week);
          setCalendarHeaderTitle(calendar.view.title.replace(/,|\bde\b/g, ""));
          break;
        case CalendarViewTypes.month:
          calendar.changeView(CalendarViewTypes.month);
          setCurrentView(CalendarViewTypes.month);
          setCalendarHeaderTitle(calendar.view.title.replace(/,|\bde\b/g, ""));
          break;
        default:
          break;
      }
      getEvents(calendar.view.currentStart, calendar.view.currentEnd);
    }
  };

  const calendarNavigateHandler = (action: CalendarNav) => {
    if (currentCalendar) {
      const calendar = currentCalendar.getApi();
      switch (action) {
        case CalendarNav.next:
          calendar.next();
          setCalendarHeaderTitle(calendar.view.title.replace(/,|\bde\b/g, ""));
          break;
        case CalendarNav.prev:
          calendar.prev();
          setCalendarHeaderTitle(calendar.view.title.replace(/,|\bde\b/g, ""));
          break;
        case CalendarNav.today:
          calendar.today();
          setCalendarHeaderTitle(calendar.view.title.replace(/,|\bde\b/g, ""));
          break;
        default:
          break;
      }
      getEvents(calendar.view.currentStart, calendar.view.currentEnd);
    }
  };

  useEffect(() => {
    if (currentCalendar) {
      const calendar = currentCalendar.getApi();
      setCalendarHeaderTitle(calendar.view.title.replace(/,|\bde\b/g, ""));
      getEvents(calendar.view.currentStart, calendar.view.currentEnd);
    }
  }, [currentCalendar]);

  useEffect(() => {
    if (event.cancelSuccess && currentCalendar) {
      const calendar = currentCalendar.getApi();
      getEvents(calendar.view.currentStart, calendar.view.currentEnd);
      dispatch(cleanMessageActionCreator());
    }
  }, [event.cancelSuccess]);

  useEffect(() => {
    if (event.createSuccess && currentCalendar) {
      console.log("msg--> calendar");
      const calendar = currentCalendar.getApi();
      if (event.createEventResponse && event.currentEvent) {
        console.log("msg--> createEventResponse currentEvent");
        const eventObject = {
          id: event.currentEvent?.id ? event.currentEvent?.id : "",
          eventName: event.currentEvent ? event.currentEvent?.eventName : "",
          startDate: new Date(event.currentEvent?.startDate),
          endDate: event.currentEvent?.endDate,
          status: event.currentEvent
            ? event.currentEvent?.status
            : EventStatus.SCHEDULED,
        };
        event.currentEvent && setEventSuccess(eventObject);
        console.log("msg--> Success msg");
        handleCreateSuccess();
        getEvents(calendar.view.currentStart, calendar.view.currentEnd);
      }
    }

    event.createSuccess == false && setShowSuccess(false);
  }, [event.createSuccess, currentCalendar, event.error]);

  useEffect(() => {
    if (
      (event.editedEvent ||
        event.updatedEvent ||
        event.createSuccess ||
        event.cancelSuccess) &&
      currentCalendar
    ) {
      const calendar = currentCalendar.getApi();
      if (event.createSuccess) {
        setIsEdited(false);
      } else {
        setIsEdited(true);
      }
      event.currentEvent &&
        setEventSuccess({
          id: event.currentEvent.id,
          eventName: event.currentEvent.eventName,
          startDate: event.currentEvent.scheduledDate,
          endDate: event.currentEvent.endDate,
          status: event.currentEvent.status,
        });

      handleCreateSuccess();
      authentication.sessionId &&
        dispatch(resetCurrentStoreActionCreator(authentication.sessionId));
      getEvents(calendar.view.currentStart, calendar.view.currentEnd);
    }
    if (event.error) {
      showAlert(AlertType.error, event.error.message);
    }
  }, [
    event.updatedEventResponse,
    event.editedEvent,
    currentCalendar,
    event.error,
    event.cancelSuccess,
  ]);

  useEffect(() => {
    dispatch(setPrevPageActionCreator("Calendar"));
    if (event.editedEvent) {
      setShowCreate(false);
      setShowSuccess(true);
    }
  }, []);

  let buttonOption;
  {
    buttonOption = (
      <Button width="140px" height="32px" clickHandler={handleClickEvent}>
        <div className={styles.TextButton}>
          <span className="lead-text">
            {t("views.events.popup.button_create")}
          </span>
          <Add width={"17"} height={"17"} />
        </div>
      </Button>
    );
  }

  return (
    <>
      <div className="overlay"></div>
      <div className={styles.OwnerEventsViewWrapper}>
        <FiraNavBar
          titleSection={t("views.events.title")}
          trailingOptions={buttonOption}
        />
        <div className={styles.CalendarWrapper}>
          <OwnerCalendarHeader
            controllerLabel={calendarHeaderTitle}
            currentCalendarView={currentView}
            handleCalendarViewChange={calendarViewHandler}
            handleControllerNavigation={calendarNavigateHandler}
          />
          <NewCustomCalendar
            getCalendarApi={getCalendar}
            eventsList={currentEvents}
            //eventsList={currentEvents.filter((e: any) => !e.parentId)}
          />
        </div>
        {showCreate && (
          <EventCreationPopUp
            x={utils.screenWidth - 110}
            y={448}
            onClose={() => setShowCreate(false)}
            customDestination="#overlay"
          />
        )}
        {showSuccess && eventSuccess && (
          <SuccessMessage
            edited={isEdited}
            eventName={eventSuccess.eventName ? eventSuccess.eventName : ""}
            date={dateFormat(new Date())}
            onClose={() => setShowSuccess(false)}
            isOpen={showSuccess}
          />
        )}
        {showSuccess && event.editedEvent && (
          <SuccessMessage
            edited={isEdited}
            eventName={
              event.currentEvent?.eventName ? event.currentEvent?.eventName : ""
            }
            date={dateFormat(new Date())}
            onClose={() => setShowSuccess(false)}
            isOpen={showSuccess}
          />
        )}
      </div>
    </>
  );
};

interface OwnerCalendarHeaderProps {
  currentCalendarView: CalendarViewTypes;
  controllerLabel: string;
  handleControllerNavigation(action: CalendarNav): void;
  handleCalendarViewChange(view: CalendarViewTypes): void;
}

const OwnerCalendarHeader: React.FC<OwnerCalendarHeaderProps> = ({
  currentCalendarView,
  controllerLabel,
  handleControllerNavigation,
  handleCalendarViewChange,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.CalendarHeader}>
      <div className={styles.Controls}>
        <ArrowLeft
          onClick={() => handleControllerNavigation(CalendarNav.prev)}
          className={styles.Arrows}
        />
        <div className={styles.Text}>{controllerLabel}</div>
        <ArrowRight
          onClick={() => handleControllerNavigation(CalendarNav.next)}
          className={styles.Arrows}
        />
      </div>

      <InputSearchbar />

      <Button
        buttonStyle={ButtonStyle.border}
        width={"59px"}
        clickHandler={() => handleControllerNavigation(CalendarNav.today)}
      >
        {t("views.events.header.today")}
      </Button>

      <div className={styles.ButtonGroup}>
        <Button
          width="66px"
          buttonStyle={
            currentCalendarView === CalendarViewTypes.day
              ? ButtonStyle.main
              : ButtonStyle.border
          }
          className={styles.Left}
          clickHandler={() => handleCalendarViewChange(CalendarViewTypes.day)}
        >
          {t("views.events.header.day")}
        </Button>
        <Button
          width="66px"
          buttonStyle={
            currentCalendarView === CalendarViewTypes.week
              ? ButtonStyle.main
              : ButtonStyle.border
          }
          className={styles.Middle}
          clickHandler={() => handleCalendarViewChange(CalendarViewTypes.week)}
        >
          {t("views.events.header.week")}
        </Button>
        <Button
          width="66px"
          buttonStyle={
            currentCalendarView === CalendarViewTypes.month
              ? ButtonStyle.main
              : ButtonStyle.border
          }
          className={styles.Right}
          clickHandler={() => handleCalendarViewChange(CalendarViewTypes.month)}
        >
          {t("views.events.header.month")}
        </Button>
      </div>
    </div>
  );
};

const InputSearchbar = () => {
  const [showList, setShowList] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [filteredList, setFilteredList] = useState<OwnerEventsType[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showEventDetail, setShowEventDetail] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventResumeResponse>();
  const [selectedId, setSelectedId] = useState("");

  const { authentication } = useSelector((state: RootState) => state);
  const { t } = useTranslation();

  const domNode = useClickOutside(() => setShowList(false));
  const debounced = useDebounce(inputValue, 800);
  const showAlert = usePopupAlert();

  const getEventsByName = async () => {
    if (authentication.user && authentication.user.businessClientId) {
      try {
        const response = await getAllByssinessByName(
          authentication.user.businessClientId,
          inputValue
        );
        setFilteredList(parseResponse(response.data));
      } catch (error) {
        const handledError = handleError(error);
        showAlert(AlertType.error, handledError.message);
      }
    }
    if (inputValue.length === 0) {
      setFilteredList([]);
    }
  };

  const handleEventsFiltering = (eventEl: ChangeEvent<HTMLInputElement>) => {
    const keyword = eventEl.target.value;
    setInputValue(keyword);
    setFirstLoad(false);
  };

  const handleClickEvent = async (e: EventTarget, id: string) => {
    await getEvent(id);
    setShowEventDetail(true);
  };

  const getEvent = async (id: string) => {
    try {
      const response = await getEventResume(id);
      setSelectedEvent(response.data);
      setSelectedId(id);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    !firstLoad && getEventsByName();
  }, [debounced]);

  return (
    <>
      <div
        className={styles.InputSearchBar}
        ref={domNode}
        onClick={() => setShowList(true)}
        id="#CenteredDestination"
      >
        <Input
          width="100%"
          icon={<MagnifyingGlass className="SearchIcon" />}
          placeholder={t("views.events.header.filter")}
          value={inputValue}
          onChange={handleEventsFiltering}
          height={"32px"}
          inputStyle={InputStyles.noFocus}
        />
        {showList && filteredList.length !== 0 && (
          <div className={styles.ListContainer}>
            {filteredList &&
              filteredList.map((event, i) => {
                return (
                  <div
                    key={i}
                    className={styles.ListElement}
                    onClick={(e) => handleClickEvent(e.target, event.event.id)}
                  >
                    <div className={styles.InfoElement}>
                      <p className={styles.EventName}>
                        {event.event.eventName}
                      </p>
                      <p className={styles.DateTime}>
                        {completeDate(new Date(event.event.scheduledDate))}{" "}
                        {timeToLocalTimeZone(
                          new Date(event.event.scheduledDate)
                        )}
                      </p>
                    </div>
                    <div className={styles.InfoElement}>
                      <p className={styles.StoreName}>{event.store.name}</p>
                      <p
                        className={`${
                          event.event.eventType === FiraEventType.broadcast
                            ? styles.NormalStatus
                            : styles.TestStatus
                        }
                       ${
                         {
                           [EventStatus.SCHEDULED]: styles.Scheduled,
                           [EventStatus.STARTED]: styles.Started,
                           [EventStatus.NOT_STARTED]: styles.NotStarted,
                           [EventStatus.DONE]: styles.Finished,
                           [EventStatus.CANCELLED]: styles.Finished,
                         }[event.event.status]
                       }
                      `}
                      >
                        {event.event.status === EventStatus.STARTED ? (
                          <div className={styles.Dot}></div>
                        ) : (
                          ""
                        )}
                        {event.event.eventType !== "TEST"
                          ? {
                              [EventStatus.SCHEDULED]: t(
                                "views.events.status.broadcast.scheduled"
                              ),
                              [EventStatus.NOT_STARTED]: t(
                                "views.events.status.broadcast.not_started"
                              ),
                              [EventStatus.STARTED]: t(
                                "views.events.status.broadcast.started"
                              ),
                              [EventStatus.DONE]: t(
                                "views.events.status.broadcast.done"
                              ),
                              [EventStatus.CANCELLED]: t(
                                "views.events.status.broadcast.cancelled"
                              ),
                            }[event.event.status]
                          : {
                              [EventStatus.SCHEDULED]: t(
                                "views.events.status.broadcast.scheduled"
                              ),
                              [EventStatus.NOT_STARTED]: t(
                                "views.events.status.broadcast.not_started"
                              ),
                              [EventStatus.STARTED]: t(
                                "views.events.status.broadcast.started"
                              ),
                              [EventStatus.DONE]: t(
                                "views.events.status.broadcast.done"
                              ),
                              [EventStatus.CANCELLED]: t(
                                "views.events.status.broadcast.cancelled"
                              ),
                            }[event.event.status]}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {showEventDetail && selectedEvent && (
        <>
          <ModalLayout>
            <EventDetailPopUp
              eventInfo={selectedEvent}
              onClose={() => setShowEventDetail(false)}
              customPosition={PopUpPosition.centered}
              customDestination={"#CenteredDestination"}
              eventId={selectedId}
            />
          </ModalLayout>
        </>
      )}
    </>
  );
};

const parseResponse = (response: string) => {
  const stores: any[] = [];
  const lines = response.split("\n");

  for (const line of lines) {
    if (line.startsWith("data:")) {
      const data = line.substring(5).trim();

      try {
        let store = JSON.parse(data);
        (store.store["color"] = toMaterialStyle(
          store.store.id,
          400
        ).backgroundColor),
          stores.push(store);
      } catch (error) {
        console.error("Error parsing store data:", error);
      }
    }
  }
  return stores;
};

export default OwnerEventsView;
