import React from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TableHeader } from "../../../components";
import { orderingType } from "../../../components/Table/Table";
import { FiraInvite } from "../../../types/Response";
import { EmptyPieChartWidget } from "../../../components/FiraIcons";
import { RootState } from "../../../store/Store";
import {
  cancelInvitation,
  getAllInvitations,
  getInvitationsByStore,
  resendInvitation,
} from "../../../services/user";
import FiraTable from "../../../components/FiraTable";

import stylesheet from "./Invitations.module.scss";
import { eventDateFormat } from "../../../utils/handleDates";
import { useClickOutside } from "../../../utils/useClickOutside";
import { InviteStatus } from "../../../utils/enums";
import { AlertType, usePopupAlert } from "../../../providers/AlertProvider";

export enum InvitationSorting {
  NEWER = "NEWER_CREATION_DATE",
  OLDER = "OLDER_CREATION_DATE",
}

const Invitations: React.FC = () => {
  const { brand, authentication } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = React.useState("");
  const [invitations, setInvitations] = React.useState<FiraInvite[]>([]);
  const [filterInvites, setFilterInvites] = React.useState<FiraInvite[]>([]);

  const showAlert = usePopupAlert();

  const sortingList = [
    {
      id: 1,
      name: t("views.videos.filterOptions.recent"),
      value: InvitationSorting.NEWER,
    },
    {
      id: 2,
      name: t("views.videos.filterOptions.older"),
      value: InvitationSorting.OLDER,
    },
  ];
  const [sortBy, setSortBy] = React.useState<orderingType>(sortingList[0]);

  const handleSorting = (id: number) => {
    const sort = sortingList.find((p) => {
      return p.id === id;
    });
    sort && setSortBy(sort);
  };

  const handleInviteResend = async (invite: FiraInvite) => {
    try {
      await resendInvitation(invite.id);
      await getInvitations();
      showAlert(AlertType.success, t("alerts.invite_success"));
    } catch (error) {
      showAlert(AlertType.error, t("alerts.error"));
    }
  };

  const handleInviteCancelation = async (invite: FiraInvite) => {
    try {
      await cancelInvitation(invite.id);
      await getInvitations();
      showAlert(AlertType.warning, t("alerts.invite_cancelled"));
    } catch (error) {
      showAlert(AlertType.error, t("alerts.error"));
    }
  };

  const handleInvitationFiltering = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
    setFilterInvites(invitations.filter((inv) => inv.name.includes(keyword)));
  };

  const getInvitations = async () => {
    if (brand.currentStore) {
      const response = await getInvitationsByStore(brand.currentStore.id);
      setInvitations(response);
      setFilterInvites(response);
    } else if (authentication.ownerInfo) {
      const response = await getAllInvitations(authentication.ownerInfo.id);
      setInvitations(response);
      setFilterInvites(response);
    }
  };

  const renderEmptyInvitations = () => {
    return (
      <div className={stylesheet.EmptyWrapper}>
        <EmptyPieChartWidget />
      </div>
    );
  };

  const renderStatusBadge = (status: InviteStatus) => {
    const badgetColor =
      status === InviteStatus.CANCELLED
        ? badgeCancelled
        : status === InviteStatus.CLOSED
        ? badgeClosed
        : badgePending;
    return (
      <div style={{ ...statusBadge, ...badgetColor }}>
        {t(`invitation.status.${status}`)}
      </div>
    );
  };

  React.useEffect(() => {
    getInvitations();
  }, []);

  React.useEffect(() => {
    switch (sortBy.value) {
      case InvitationSorting.OLDER:
        setFilterInvites((prevState) =>
          prevState.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        );
        break;
      case InvitationSorting.NEWER:
        setFilterInvites((prevState) =>
          prevState.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          )
        );
        break;
    }
  }, [sortBy]);

  return (
    <div style={wrapper}>
      <TableHeader
        hideCheckBox
        searchInputValue={filterValue}
        searchInputChangeHandle={handleInvitationFiltering}
        orderingList={sortingList}
        orderingValue={sortBy}
        orderingClick={handleSorting}
      />
      <div style={tableWrapper}>
        {invitations.length > 0 ? (
          <FiraTable
            data={filterInvites}
            columns={
              brand.currentStore
                ? [
                    { key: "name", title: t("table.header.name") },
                    { key: "email", title: t("table.header.email") },
                    {
                      key: "createdAt",
                      title: t("table.header.invite_date"),
                      render(value) {
                        return eventDateFormat(value);
                      },
                    },
                    {
                      key: "role",
                      title: t("table.header.role"),
                      render(value) {
                        return t(`user.role.${value}`);
                      },
                    },
                    {
                      key: "status",
                      title: t("table.header.status"),
                      render(_, row) {
                        return renderStatusBadge(row.status);
                      },
                    },
                    {
                      key: "id",
                      title: "",
                      render(_, row) {
                        return (
                          <InviteOptions
                            invite={row}
                            resendHandler={handleInviteResend}
                            cancelHandler={handleInviteCancelation}
                          />
                        );
                      },
                    },
                  ]
                : [
                    { key: "name", title: t("table.header.name") },
                    { key: "email", title: t("table.header.email") },
                    {
                      key: "createdAt",
                      title: t("table.header.invite_date"),
                      render(value) {
                        return eventDateFormat(value);
                      },
                    },
                    { key: "storeName", title: t("table.header.store") },
                    {
                      key: "role",
                      title: "Rol",
                      render(value) {
                        return t(`user.role.${value}`);
                      },
                    },
                    {
                      key: "status",
                      title: t("table.header.status"),
                      render(_, row) {
                        return renderStatusBadge(row.status);
                      },
                    },
                    {
                      key: "id",
                      title: "",
                      render(_, row) {
                        return (
                          <InviteOptions
                            invite={row}
                            resendHandler={handleInviteResend}
                            cancelHandler={handleInviteCancelation}
                          />
                        );
                      },
                    },
                  ]
            }
          />
        ) : (
          renderEmptyInvitations()
        )}
      </div>
    </div>
  );
};

interface InvitaOptionsProps {
  invite: FiraInvite;
  resendHandler(invite: FiraInvite): void;
  cancelHandler(invite: FiraInvite): void;
}
const InviteOptions: React.FC<InvitaOptionsProps> = ({
  invite,
  resendHandler,
  cancelHandler,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "left",
  });
  const domNode = useClickOutside(() => setIsOpen(false));

  const resendInvite = () => {
    setIsOpen(false);
    resendHandler(invite);
  };

  const cancelInvite = () => {
    setIsOpen(false);
    cancelHandler(invite);
  };

  const renderOptions = () => {
    return ReactDOM.createPortal(
      <div
        style={{ ...optionsWrapper, ...styles.popper }}
        {...attributes.popper}
        ref={setPopperElement}
      >
        <div style={optionButtonsWrapper} ref={domNode}>
          <button
            style={{ ...tableButton, ...optionButton }}
            onClick={cancelInvite}
          >
            {t("buttons.cancel")}
          </button>
          <button
            style={{ ...tableButton, ...optionButton }}
            onClick={resendInvite}
          >
            {t("buttons.resend")}
          </button>
        </div>
      </div>,
      document.querySelector("#modal-root") as Element
    );
  };

  return (
    <div style={tableButtonWrapper}>
      {invite.status === InviteStatus.CLOSED ? (
        <span style={resendOption} onClick={resendInvite}>
          {"Reenviar"}
        </span>
      ) : invite.status === InviteStatus.PENDING ? (
        <button
          style={tableButton}
          ref={setReferenceElement}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon fontSize={16} icon={"gravity-ui:ellipsis-vertical"} />
        </button>
      ) : (
        <></>
      )}

      {isOpen && renderOptions()}
    </div>
  );
};

const wrapper: React.CSSProperties = {
  height: "100%",
};

const tableWrapper: React.CSSProperties = {
  height: "100%",
  marginBottom: 50,
  backgroundColor: "#fff",
  padding: 16,
  boxSizing: "border-box",
};

const tableButtonWrapper: React.CSSProperties = {
  position: "relative",
  display: "flex",
  justifyContent: "end",
};

const tableButton: React.CSSProperties = {
  background: "none",
  border: "none",
  cursor: "pointer",
};

const optionButton: React.CSSProperties = {
  padding: 8,
  textAlign: "left",
};

const optionsWrapper: React.CSSProperties = {
  zIndex: 5,
};

const optionButtonsWrapper: React.CSSProperties = {
  boxShadow: "0px 10px 20px 0px #0000001A",
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  padding: 8,
  gap: 16,
  borderRadius: 10,
};

const resendOption: React.CSSProperties = {
  fontWeight: 500,
  textDecorationLine: "underline",
};

const statusBadge: React.CSSProperties = {
  borderRadius: 4,
  padding: "4px 6px 4px 6px",
  border: "1px solid",
  display: "flex",
  justifyContent: "center",
  maxWidth: 130,
  fontWeight: 500,
  fontSize: 12,
};

const badgePending: React.CSSProperties = {
  borderColor: "#D5DDE0",
};

const badgeCancelled: React.CSSProperties = {
  borderColor: "#D5DDE0",
  color: "#D5DDE0",
};

const badgeClosed: React.CSSProperties = {
  borderColor: "#F16063",
  color: "#F16063",
};

export default Invitations;
