import React, { useEffect } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { useWizard } from "../../providers/WizardProvider";
import { ChatV1, ChatV2, Web } from "../Mockups";

import stylesheet from "./StepColorPicker.module.scss";
import { useTranslation } from "react-i18next";

const { Wrapper, Header, Picker, Preview, Divider } = stylesheet;

const StepColorPicker: React.FC = () => {
  const { form, setFormKey } = useWizard();
  const { t } = useTranslation();

  const handleColorChange = (color: ColorResult) => {
    setFormKey("mainColor", color.hex);
  };

  useEffect(() => {
    setFormKey("mainColor", form.mainColor || "#ffde07");
  }, []);
  return (
    <div className={Wrapper}>
      <div className={Header}>
        <span>{t("event_creation.params.colorSelectionTitle")}</span>
        <span>{t("event_creation.params.colorSelectionMessage")}</span>
      </div>
      <div className={Picker}>
        <SketchPicker color={form.mainColor} onChange={handleColorChange} />
      </div>
      <div className={Preview}>
        <span>{t("event_creation.params.colorPreview")}</span>
        <div className={Divider}>
          <ChatV1 currentColor={form.mainColor} />
          <ChatV2 currentColor={form.mainColor} />
        </div>
        <Web currentColor={form.mainColor} />
      </div>
    </div>
  );
};

export default StepColorPicker;
