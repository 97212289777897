import { useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import { RootState } from "../../../utils/types";
import Button, { ButtonStyle } from "../../Button/Button";
import { Cross } from "../../FiraIcons";
import SvgBigSuccess from "../../FiraIcons/BigSuccess";
import styles from "./InsertVideo.module.scss";

const FIRA_SCRIPT_URL_DEV = `https://firalivedev.blob.core.windows.net/fira-live-widget/index.js`;
const FIRA_SCRIPT_URL_PREPROD = `https://firalivepro.blob.core.windows.net/fira-live-widget-preprod/index.min.js`;
const FIRA_SCRIPT_URL_PRO = `https://firalivepro.blob.core.windows.net/fira-live-widget/index.min.js`;

const getURLByEnvironment = (environment: string): string => {
  switch (environment) {
    case "Production":
      return FIRA_SCRIPT_URL_PRO;
    case "Preproduction":
      return FIRA_SCRIPT_URL_PREPROD;
    case "Development":
      return FIRA_SCRIPT_URL_DEV;
    default:
      return FIRA_SCRIPT_URL_PRO;
  }
};

interface InsertVideoProps {
  isOpen: boolean;
  onClose(): void;
}
let timerID: NodeJS.Timeout;

const InsertVideo = ({ isOpen = false, onClose }: InsertVideoProps) => {
  const { video } = useSelector((state: RootState) => state);
  const { t } = useTranslation();

  const [showAlert, setShowAlert] = useState(false);

  const envUrl = getURLByEnvironment(
    process.env.REACT_APP_CURRENT_ENVIRONMENT || "Production"
  );

  const scriptTextExample = ` <div id="fira-video-container"></div>
  <script src="${envUrl}" id="fira-live-widget" fira-key="${video.currentVideo?.storeId}"></script> 
  <script>setIframeOnContainer("${video.currentVideo?.originEvent}","fira-video-container" ) </script> 
`;

  const handleCancel = () => {
    onClose();
  };
  const fallbackCopyTextToClipboard = (text: string) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const copyTextToClipboard = async (text: string = "") => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      handleShowAlert();
    } catch (error) {
      console.log("ERROR COPYING TEXT", error);
    }
  };

  const handleShowAlert = () => {
    setShowAlert(true);
    timerID = setTimeout(() => {
      setShowAlert(false);
      onClose();
      clearTimeout(timerID);
    }, 1000);
  };

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalLayout>
      {showAlert ? (
        <div className={styles.insertVideoSuccess}>
          <SvgBigSuccess className={styles.icon} />
          <p>{t("modals.insertVideo.messages.copied")}</p>
        </div>
      ) : (
        <div className={styles.InsertVideoWrapper}>
          <div className={styles.header}>
            <p className="body-14-text">{t("modals.insertVideo.title")}</p>
            <Cross className={styles.icon} onClick={handleCancel} />
          </div>
          <div className={styles.content}>
            <div className={styles.htmlContainer}>
              <p className={styles.ellipsis}>{scriptTextExample}</p>
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              buttonStyle={ButtonStyle.border}
              clickHandler={handleCancel}
            >
              <p>{t("modals.insertVideo.cancel")}</p>
            </Button>
            <Button
              clickHandler={() => copyTextToClipboard(` ${scriptTextExample}`)}
            >
              <p>{t("modals.insertVideo.copy")}</p>
            </Button>
          </div>
        </div>
      )}
    </ModalLayout>,
    document.getElementById("modal-root") as Element
  );
};

export default InsertVideo;
