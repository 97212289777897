import styles from "./Timer.module.scss";
import { useTimer } from "react-timer-hook";

interface TimerProps {
  handleAction?: () => void;
  remaingTime: number;
}

const Timer = ({ handleAction, remaingTime }: TimerProps) => {
  const time = new Date();
  time.setSeconds(remaingTime);
  const { seconds, minutes } = useTimer({
    autoStart: true,
    expiryTimestamp: time,
    onExpire: () => {
      handleAction && handleAction();
    },
  });

  return (
    <div className={styles.timer}>
      {"0" + minutes}: {seconds > 9 ? seconds : "0" + seconds}
    </div>
  );
};

export default Timer;
