import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CurrencyResponse,
  getCountriesService,
  getCurrenciesService,
  getLanguageService,
} from "../../../../services/utils";
import { StoreGeneralInformationType } from "../../../../types/Stores";
import { Country, Languages } from "../../../../utils/types";
import Input, { InputStyles } from "../../../Input/Input";
import { SimpleFormSelect } from "../../../Selects/FormSelect/FormSelect";
import {
  Currencies,
  StoreTypes,
  timeZonesUTC,
  UnitSystems,
  WeightUnits,
} from "../../../Selects/SelectUtils";
import TextEditor from "../../../TextEditor/TextEditor";
import { GeneralInfoInitialState, StoreProfileContext } from "../StoreProfile";
import styles from "./GeneralInformationForm.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/Store";

const GeneralInformationForm = () => {
  const { t } = useTranslation();
  const [info, setInfo] = useState<StoreGeneralInformationType>(
    GeneralInfoInitialState
  );
  const { handleChange, setInvalidGeneralForm, form } =
    useContext(StoreProfileContext);
  const { brand } = useSelector((state: RootState) => state);
  const [textAreaState, setTextAreaState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(form.description ? form.description : "")
    )
  );
  /** All Selects */
  const [languages, setLanguages] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [storeTypes, setStoreTypes] = useState<
    {
      name: string;
      value: string;
    }[]
  >([]);
  const [currencies, setCurrencies] = useState<CurrencyResponse[]>([]);
  const [unitSystems, setUnitSystems] = useState<
    {
      name: string;
      value: string;
    }[]
  >([]);
  const [weightUnits, setWeightUnits] = useState<
    {
      name: string;
      value: string;
    }[]
  >([]);
  const [timeZones, setTimeZones] = useState<
    {
      name: string;
      value: string;
    }[]
  >([]);

  const selectTransform = (arr: Languages[] | Country[]): string[] => {
    return arr?.map((val) => val.name);
  };

  const initSelects = async () => {
    try {
      //Change the service call for redux states
      const languagesResponse = await getLanguageService();
      setLanguages(selectTransform(languagesResponse.data));

      const countriesResponse = await getCountriesService();
      setCountries(selectTransform(countriesResponse.data));

      const storeTypesResponse = await StoreTypes();
      setStoreTypes(storeTypesResponse);

      const currenciesResponse = await getCurrenciesService();
      setCurrencies(currenciesResponse);

      const unitSystemsResponse = await UnitSystems();
      setUnitSystems(unitSystemsResponse);

      const weightUnitResponse = await WeightUnits();
      setWeightUnits(weightUnitResponse);

      const timeZonesResponse = await timeZonesUTC();

      setTimeZones(timeZonesResponse);
    } catch (error) {
      console.error("error retrieving selects data");
    }
  };

  const handleSelectChange = (type: string, value: string) => {
    switch (type) {
      case "country":
        setInfo({ ...info, location: { ...info.location, country: value } });
        break;
      case "storeType":
        setInfo({ ...info, storeType: value });
        break;
      case "language":
        setInfo({
          ...info,
          regionalConfiguration: {
            ...info.regionalConfiguration,
            language: value,
          },
        });
        break;
      case "currency":
        setInfo({
          ...info,
          regionalConfiguration: {
            ...info.regionalConfiguration,
            currency: value,
          },
        });
        break;
      case "weightUnit":
        setInfo({
          ...info,
          regionalConfiguration: {
            ...info.regionalConfiguration,
            weightUnit: value,
          },
        });
        break;
      case "timeZone":
        setInfo({
          ...info,
          regionalConfiguration: {
            ...info.regionalConfiguration,
            timeZone: value,
          },
        });
        break;
      case "unitSystem":
        setInfo({
          ...info,
          regionalConfiguration: {
            ...info.regionalConfiguration,
            unitSystem: value,
          },
        });
        break;
    }
  };
  /** All Selects */

  /** All Inputs */
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setInfo({ ...info, name: value });
        break;
      case "company":
        setInfo({ ...info, legalName: value });
        break;
      case "city":
        setInfo({ ...info, location: { ...info.location, city: value } });
        break;
      case "zipCode":
        setInfo({ ...info, location: { ...info.location, zipCode: value } });
        break;
      case "address":
        setInfo({ ...info, location: { ...info.location, address: value } });
        break;
    }
  };

  const inputValidator = (value: string | number) => {
    return value === "" || value === undefined || value === null;
  };

  const fullFormValidator = () => {
    const fieldsToValidate = [
      info.name,
      info.legalName,
      info.storeType,
      info.regionalConfiguration.currency,
      info.regionalConfiguration.language,
      info.regionalConfiguration.timeZone,
      info.location.country,
      info.location.zipCode,
      info.location.city,
    ];

    const isValid = fieldsToValidate.every((field) => !inputValidator(field));
    setInvalidGeneralForm(!isValid);
  };

  /*const handleEditor = (editorState: EditorState) => {
    setTextAreaState(editorState);
    setInfo({
      ...info,
      description: editorState.getCurrentContent().getPlainText(),
    });
  };*/

  const handleEditor = (editorState: EditorState) => {
    const html = convertToHTML(editorState.getCurrentContent());
    setTextAreaState(editorState);
    setInfo({
      ...info,
      description: html,
    });
  };
  /** All Inputs */

  const getTimeZoneName = (timezone: string) => {
    let object: { name: string; value: string } | undefined;
    if (timeZones) object = timeZones.find((obj) => obj.value === timezone);
    if (object) {
      return `(UTC${object.value}) ${object.name}`;
    }
  };

  const onInputVariationChange =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setInfo({
        ...info,
        additionalFields: {
          ...info.additionalFields,
          [key]: event.target.value,
        },
      });
    };

  const renderVarsInputs = (key: string, value: string, index: number) => {
    return (
      <div className={styles.Input} key={index}>
        <label>{key}</label>
        <Input
          height="40px"
          onChange={onInputVariationChange(key)}
          width="100%"
          value={value}
        />
      </div>
    );
  };

  useEffect(() => {
    fullFormValidator();
    handleChange(info);
  }, [info]);

  useEffect(() => {
    if (form) {
      setInfo(form);
      if (form.description) {
        const blocksFromHTML = convertFromHTML(form.description);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setTextAreaState(EditorState.createWithContent(state));
      }
    }
    initSelects();
  }, []);

  return (
    <>
      <div className={styles.GeneralInformationFormWrapper}>
        <form className={styles.GeneralInformationFormWrapper__Form}>
          {/* Store Name */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="name">
              {t("components.store_settings.general_info.store_name")}*
            </label>
            <Input
              width="100%"
              height="40px"
              borderRadius="4px"
              inputName={"name"}
              value={info ? info.name : ""}
              placeholder={t(
                "components.store_settings.general_info.store_name"
              )}
              onChange={handleInputChange}
              inputStyle={InputStyles.profile}
              invalid={inputValidator(info.name)}
            />
          </div>
          {/* Legal Name */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="company">
              {t("components.store_settings.general_info.company")}*
            </label>
            <Input
              width="100%"
              height="40px"
              borderRadius="4px"
              inputName={"company"}
              value={info ? info.legalName : ""}
              placeholder={t("components.store_settings.general_info.company")}
              onChange={handleInputChange}
              inputStyle={InputStyles.profile}
              invalid={inputValidator(info.legalName)}
            />
          </div>
          {/* Store type */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="storeType">
              {t("components.store_settings.general_info.store_type")}*
            </label>
            <SimpleFormSelect
              placeholder={t(
                "components.store_settings.general_info.store_type"
              )}
              id={"storeType"}
              name={
                info && info.storeType
                  ? t(`components.selects.storeTypes.${info?.storeType}`)
                  : ""
              }
              invalid={inputValidator(info.storeType)}
            >
              <>
                {storeTypes &&
                  storeTypes.map((type, i) => (
                    <div
                      key={i}
                      onClick={() =>
                        handleSelectChange("storeType", type.value)
                      }
                    >
                      {type.name}
                    </div>
                  ))}
              </>
            </SimpleFormSelect>
          </div>
          {/* Language */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="language">
              {t("components.store_settings.general_info.language")}*
            </label>
            <SimpleFormSelect
              placeholder={t("components.store_settings.general_info.language")}
              id={"language"}
              name={
                info && info.regionalConfiguration
                  ? info.regionalConfiguration.language
                  : ""
              }
              invalid={inputValidator(
                info.regionalConfiguration &&
                  info.regionalConfiguration.language
              )}
            >
              <>
                {languages &&
                  languages.map((lang, i) => (
                    <div
                      key={i}
                      onClick={() => handleSelectChange("language", lang)}
                    >
                      {lang}
                    </div>
                  ))}
              </>
            </SimpleFormSelect>
          </div>
          {/* Country */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="country">
              {t("components.store_settings.general_info.country")}*
            </label>
            <SimpleFormSelect
              placeholder={t("components.store_settings.general_info.country")}
              id={"country"}
              name={info && info.location ? info.location.country : ""}
              invalid={inputValidator(info.location && info.location.country)}
            >
              <>
                {countries &&
                  countries.map((country, i) => (
                    <div
                      key={i}
                      onClick={() => handleSelectChange("country", country)}
                    >
                      {country}
                    </div>
                  ))}
              </>
            </SimpleFormSelect>
          </div>
          {/* City */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="city">
              {t("components.store_settings.general_info.city")}*
            </label>
            <Input
              width="100%"
              height="40px"
              borderRadius="4px"
              inputName={"city"}
              value={info ? info.location.city : ""}
              placeholder={t("components.store_settings.general_info.city")}
              onChange={handleInputChange}
              inputStyle={InputStyles.profile}
              invalid={inputValidator(info.location && info.location.city)}
            />
          </div>
          {/* Address */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="address">
              {t("components.store_settings.general_info.address")}
            </label>
            <Input
              width="100%"
              height="40px"
              borderRadius="4px"
              inputName={"address"}
              value={info ? info.location.address : ""}
              placeholder={t("components.store_settings.general_info.address")}
              onChange={handleInputChange}
              inputStyle={InputStyles.profile}
            />
          </div>
          {/* Zip code */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="zipCode">
              {t("components.store_settings.general_info.zip_code")}*
            </label>
            <Input
              width="100%"
              height="40px"
              borderRadius="4px"
              inputName={"zipCode"}
              value={info ? String(info.location.zipCode) : ""}
              placeholder={t("components.store_settings.general_info.zip_code")}
              onChange={handleInputChange}
              inputStyle={InputStyles.profile}
              invalid={inputValidator(info.location && info.location.zipCode)}
            />
          </div>
          {/* Currencies */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="currency">
              {t("components.store_settings.general_info.currency")}*
            </label>
            <SimpleFormSelect
              placeholder={t("components.store_settings.general_info.currency")}
              id={"currency"}
              name={
                info?.regionalConfiguration.currency
                  ? `${t(
                      `components.selects.currencies.${info?.regionalConfiguration.currency}`
                    )} (${info?.regionalConfiguration.currency})`
                  : ""
              }
              invalid={inputValidator(
                info.regionalConfiguration &&
                  info.regionalConfiguration.currency
              )}
            >
              <>
                {currencies &&
                  currencies.map((currency, i) => (
                    <div
                      key={i}
                      onClick={() =>
                        handleSelectChange("currency", currency.code)
                      }
                    >{`${t(
                      `components.selects.currencies.${currency.code}`
                    )} (${currency.symbol})`}</div>
                  ))}
              </>
            </SimpleFormSelect>
          </div>
          {/* Time Zone */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="timeZone">
              {t("components.store_settings.general_info.time_zone")}*
            </label>
            <SimpleFormSelect
              placeholder={t(
                "components.store_settings.general_info.time_zone"
              )}
              id={"timeZone"}
              name={
                info &&
                info.regionalConfiguration &&
                info.regionalConfiguration.timeZone
                  ? getTimeZoneName(info.regionalConfiguration.timeZone)
                  : ""
              }
              invalid={inputValidator(
                info.regionalConfiguration &&
                  info.regionalConfiguration.timeZone
              )}
            >
              <>
                <>
                  {timeZones &&
                    timeZones.map((utc, i) => (
                      <div
                        key={i}
                        onClick={() =>
                          handleSelectChange("timeZone", utc.value)
                        }
                      >
                        {`(UTC${utc.value}) ${utc.name}`}
                      </div>
                    ))}
                </>
              </>
            </SimpleFormSelect>
          </div>
          {/* Unit System */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="unitSystem">
              {t("components.store_settings.general_info.unit_system")}
            </label>
            <SimpleFormSelect
              placeholder={t(
                "components.store_settings.general_info.unit_system"
              )}
              id={"unitSystem"}
              name={
                info?.regionalConfiguration.unitSystem
                  ? t(
                      `components.selects.unitSystems.${info?.regionalConfiguration.unitSystem}`
                    )
                  : ""
              }
            >
              <>
                {unitSystems &&
                  unitSystems.map((unit, i) => (
                    <div
                      key={i}
                      onClick={() =>
                        handleSelectChange("unitSystem", unit.value)
                      }
                    >{`${unit.name}`}</div>
                  ))}
              </>
            </SimpleFormSelect>
          </div>
          {/* Weight Unit */}
          <div
            className={styles.GeneralInformationFormWrapper__Form__FormGroup}
          >
            <label htmlFor="weightUnit">
              {t("components.store_settings.general_info.weight_unit")}
            </label>
            <SimpleFormSelect
              placeholder={t(
                "components.store_settings.general_info.weight_unit"
              )}
              id={"weightUnit"}
              name={
                info && info.regionalConfiguration
                  ? info.regionalConfiguration.weightUnit
                  : ""
              }
            >
              <>
                {weightUnits &&
                  weightUnits.map((unit, i) => (
                    <div
                      key={i}
                      onClick={() =>
                        handleSelectChange("weightUnit", unit.name)
                      }
                    >{`${unit.name} (${unit.value})`}</div>
                  ))}
              </>
            </SimpleFormSelect>
          </div>
          {/* Store Description */}
        </form>
        <div
          className={`${styles.GeneralInformationFormWrapper__TextArea}`}
          style={{ gridColumn: "span 2" }}
        >
          <label htmlFor="storeDescription">
            {t("components.store_settings.general_info.description")}
          </label>
          <TextEditor
            editorState={textAreaState}
            onChangeHandler={handleEditor}
            id="description"
            name="description"
            visibleCounter={false}
            maxLenght={280}
          />
        </div>
        {info.additionalFields &&
          Object.entries(info.additionalFields).length > 0 && (
            <div
              className={`${styles.Wrapper}`}
              style={{ gridColumn: "span 2" }}
            >
              <label htmlFor="additionalVars">
                {t("components.settings.sidebar.additionals")}
              </label>
              <div className={styles.InputsWrapper}>
                {Object.entries(info.additionalFields).map(
                  ([key, value], index) => renderVarsInputs(key, value, index)
                )}
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default GeneralInformationForm;
