import { CircleX } from "../../FiraIcons";
import styles from "./VideoPopup.module.scss";
import noImageStoreSVG from "../../../assets/svg/no-image-store.svg";
import {
  ResponseCurrentPopup,
  ResponseSimpleProduct,
} from "../../../utils/types";
import Timer from "../../Timer/Timer";

interface VideoPopupProps {
  popup: ResponseCurrentPopup;
  product?: ResponseSimpleProduct;
  removeAction(popup: ResponseCurrentPopup): void;
}

const VideoPopup = ({ popup, product, removeAction }: VideoPopupProps) => {
  console.log("PRODUCT POPUP", product);
  return (
    <div key={popup.broadcastingProductId} className={styles.PopupContainer}>
      <div
        className={styles.closeIcon}
        onClick={() => {
          removeAction(popup);
        }}
      >
        <CircleX className={styles.icon} />
      </div>

      <div className={styles.timer}>
        <Timer
          remaingTime={popup.remainingTime}
          handleAction={() => removeAction(popup)}
        />
      </div>
      <div className={styles.inside}>
        <img
          alt={product?.product.name}
          src={
            product?.product.images.length !== 0 &&
            product?.product.images[0] !== ""
              ? product?.product.images[0]
              : noImageStoreSVG
          }
        />
      </div>
    </div>
  );
};

export default VideoPopup;
