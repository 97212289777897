import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import {
  cleanMessageActionCreator,
  updateUserActionCreator,
} from "../../../store/User/UserActions";
import { setSettingsModalStateActionCreator } from "../../../store/Utils/UtilsActions";
import { phoneNumberFormat } from "../../../utils/phoneNumberFormat";
import { Country, FiraUserForm, GenderTypes } from "../../../utils/types.d";
import Button, { ButtonStyle } from "../../Button/Button";
import FiraSelect, {
  FiraSelectOption,
  FiraSelectStyle,
} from "../../FiraSelect/FiraSelect";
import Input, { InputStyles } from "../../Input/Input";
import InputImage from "../../InputImage/InputImage";
import PopUpAlert, { PopUpAlertTypes } from "../../PopUpAlert/PopUpAlert";
import styles from "./UserProfile.module.scss";
import { CountryCode, getPhoneCodes } from "../../../services/utils";
import { useUtils } from "../../../providers/UtilsProvider";
import { AlertType, usePopupAlert } from "../../../providers/AlertProvider";

const UserProfile = () => {
  const { t, i18n } = useTranslation();
  const { authentication, user } = useSelector((state: RootState) => state);
  const { countryCodes, loadCountryCodes } = useUtils();
  const alert = usePopupAlert();
  const dispatch = useDispatch();
  const initialState: FiraUserForm = {
    avatar: user.info?.avatar,
    firstName: user.info ? user.info.firstName : "",
    lastName: user.info ? (user.info.lastName ? user.info.lastName : "") : "",
    mobilephone: user.info ? user.info.mobilephone : undefined,
    username: user.info ? user.info.username : "",
    email: user.info ? user.info.email : "",
    gender: user.info ? user.info.gender : undefined,
    birthdate: user.info?.birthdate ? new Date(user.info.birthdate) : undefined,
  };
  const [form, setForm] = useState<FiraUserForm>(initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userAvatar, setUserAvatar] = useState<File | undefined>();
  const formRef = useRef<HTMLFormElement>(null);
  const [mobilephone, setMobilephone] = useState<string>("");
  const [updated, setUpdated] = useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [selectedCode, setSelectedCode] = useState<CountryCode>();
  const currentYear = new Date().getFullYear();
  const [months, setMonths] = useState<FiraSelectOption[]>([]);
  const [years, setYears] = useState<FiraSelectOption[]>([]);
  const [days, setDays] = useState<FiraSelectOption[]>([]);
  const [selectedYear, setSelectedYear] = useState<FiraSelectOption>();
  const [selectedDay, setSelectedDay] = useState<FiraSelectOption>();
  const [selectedMonth, setSelectedMonth] = useState<FiraSelectOption>();
  const [birthdate, setUserBirthdate] = useState<Date>();
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<PopUpAlertTypes>(
    PopUpAlertTypes.success
  );
  const [errorMessage, setErrorMessage] = useState("");

  /** GENDER SELECTION */

  const genderData = [
    {
      name: t("modals.profile.edit_profile_modal.gender_option.female"),
      value: GenderTypes.F,
    },
    {
      name: t(`modals.profile.edit_profile_modal.gender_option.male`),
      value: GenderTypes.M,
    },
    {
      name: t(`modals.profile.edit_profile_modal.gender_option.other`),
      value: GenderTypes.OTHER,
    },
  ];

  const getGenderOption = (gender: GenderTypes | string) => {
    switch (gender) {
      case GenderTypes.F:
        return genderData[0];
      case GenderTypes.M:
        return genderData[1];
      case GenderTypes.OTHER:
        return genderData[2];
      default:
        return genderData[0];
    }
  };

  const getGender = (g: string) => {
    switch (g) {
      case "FEMALE":
        return GenderTypes.F;
      case "MALE":
        return GenderTypes.M;
      case "OTHER":
        return GenderTypes.OTHER;
      default:
        return GenderTypes.F;
    }
  };

  const [selectedGender, setSelectedGender] = useState<FiraSelectOption>(
    getGenderOption(user.info?.gender || "")
  );

  const handleGenderSelect = (value: GenderTypes) => {
    switch (value) {
      case "FEMALE":
        setForm({ ...form, gender: GenderTypes.F });
        setSelectedGender(genderData[0]);
        break;
      case "MALE":
        setForm({ ...form, gender: GenderTypes.M });
        setSelectedGender(genderData[1]);
        break;
      case "OTHER":
        setForm({ ...form, gender: GenderTypes.OTHER });
        setSelectedGender(genderData[2]);
        break;
      default:
        setForm({ ...form, gender: GenderTypes.F });
        setSelectedGender(genderData[0]);
        break;
    }
  };

  /** DATE SELECTION */
  const selectMonth = (month: FiraSelectOption) => {
    setSelectedMonth(month);
  };

  const selectYear = (year: FiraSelectOption) => {
    setSelectedYear(year);
  };

  const selectDay = (day: FiraSelectOption) => {
    setSelectedDay(day);
  };

  const getAllMonths = () => {
    const m = [];

    for (let month = 0; month < 12; month++) {
      const date = new Date(0, month);
      const monthName = date.toLocaleString(i18n.language, { month: "long" });

      m.push({ value: month + 1, name: monthName });
    }

    return m;
  };

  const handleMonthChange = () => {
    const currentMonth = selectedMonth?.value || 0;

    const daysInMonth = new Date(
      selectedYear?.value || years[0].value,
      currentMonth,
      0
    ).getDate();

    const d: FiraSelectOption[] = [];

    for (let day = 1; day <= daysInMonth; day++) {
      d.push({ value: day, name: day.toString() });
    }

    setDays(d);
  };

  const handleFormDate = () => {
    const y = parseInt(selectedYear?.value) || 0;
    const m = parseInt(selectedMonth?.value) - 1 || 0;
    const d = parseInt(selectedDay?.value) || 0;
    const inputsDate = new Date(y, m, d);
    setForm((prevState) => ({
      ...prevState,
      birthdate: inputsDate,
    }));
  };

  const handleInvalidForm = () => {
    if (
      form.firstName === "" ||
      form.lastName === "" ||
      form.birthdate === null ||
      form.mobilephone === null ||
      mobilephone === "" ||
      selectedDay === undefined ||
      selectedMonth === undefined ||
      selectedYear === undefined ||
      parseInt(selectedYear.value) > currentYear ||
      parseInt(selectedYear.value) < currentYear - 100
    ) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  };

  const phoneFormat = (number: string) => {
    setForm((prevState) => ({
      ...prevState,
      mobilephone: prevState.mobilephone
        ? { ...prevState.mobilephone, number }
        : { country: "", number, operator: "", type: "" },
    }));
  };

  const phoneMask = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const phone = value.replace(/\s+/g, "");
    setMobilephone(phoneNumberFormat(value) || "");
    phoneFormat(value);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "firstName":
        setForm({ ...form, firstName: value });
        break;
      case "lastName":
        setForm({ ...form, lastName: value });
        break;
      case "username":
        setForm({ ...form, username: value });
        break;
      case "email":
        setForm({ ...form, email: value });
        break;
    }
  };

  const handleImage = (image: File) => {
    setUserAvatar(image);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setUpdated(true);
    setIsChanged(false);

    authentication.user &&
      dispatch(
        updateUserActionCreator(authentication.user.id, form, userAvatar, false)
      );

    setUserAvatar(undefined);
  };

  const handleReset = (e: React.FormEvent) => {
    e.preventDefault();
    setForm(initialState);
    formRef.current?.reset();
    dispatch(setSettingsModalStateActionCreator(false));
  };

  const initSelects = async () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 100;
    const endYear = currentYear;

    const y: FiraSelectOption[] = [];
    for (let year = startYear; year <= endYear; year++) {
      y.push({ value: year, name: year.toString() });
    }
    setYears(y.reverse());
    setMonths(getAllMonths());

    const d: FiraSelectOption[] = [];

    for (let day = 1; day <= 31; day++) {
      d.push({ value: day, name: day.toString() });
    }

    setDays(d);
    initPhoneCodes();
  };

  const initPhoneCodes = () => {
    if (countryCodes.length === 0) loadCountryCodes();
  };

  const handleCallingCodeSelect = (value: CountryCode) => {
    setSelectedCode(value);
    setForm((prevState) => ({
      ...prevState,
      mobilephone: prevState.mobilephone
        ? {
            ...prevState.mobilephone,
            country: value.countryCode,
            operator: value.dialCode,
            type: "MOBILE",
          }
        : {
            country: value.countryCode,
            operator: value.dialCode,
            type: "MOBILE",
            number: "",
          },
    }));
  };

  const compareInfo = () => {
    const initialKeys: Array<keyof FiraUserForm> = Object.keys(
      initialState
    ) as Array<keyof FiraUserForm>;
    for (const key of initialKeys) {
      if (key !== "birthdate" && key !== "mobilephone") {
        if (form[key] !== initialState[key]) {
          setIsChanged(true);
          break;
        } else {
          setIsChanged(false);
        }
      } else if (key === "birthdate") {
        const date1 = form[key];
        const date2 = initialState[key];

        if (date1) {
          date1.setHours(0);
          date1.setMinutes(0);
          date1.setSeconds(0);
        }

        if (date2) {
          date2.setHours(0);
          date2.setMinutes(0);
          date2.setSeconds(0);
        }
        const parseDate1 = date1 && new Date(date1).getTime();
        const parseDate2 = date2 && new Date(date2).getTime();
        if (parseDate1 !== parseDate2) {
          setIsChanged(true);
          break;
        } else {
          setIsChanged(false);
        }
      } else if (key === "mobilephone") {
        const formCode = form[key]?.operator;
        const initialCode = initialState[key]?.operator;

        if (formCode !== initialCode) {
          setIsChanged(true);
          break;
        } else {
          setIsChanged(false);
        }
      }
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    if (
      selectedDay !== undefined &&
      selectedMonth !== undefined &&
      selectedYear !== undefined
    ) {
      handleMonthChange();
      handleFormDate();
    }
  }, [selectedDay, selectedMonth, selectedYear]);

  useEffect(() => {
    if (user.info && user.info.birthdate) {
      const parseDate = new Date(user.info.birthdate);
      setUserBirthdate(parseDate);
      setForm({ ...form, mobilephone: user.info.mobilephone });
    }
  }, [user.info]);

  useEffect(() => {
    if (!user.isLoading && birthdate !== null && birthdate) {
      const userBirthdate = new Date(birthdate);
      setSelectedDay({
        value: userBirthdate.getDate(),
        name: userBirthdate.getDate().toString(),
      });
      if (years) {
        setSelectedYear({
          value: userBirthdate.getFullYear(),
          name: userBirthdate.getFullYear().toString(),
        });
      }
    } else if (birthdate == null) {
      setSelectedDay(undefined);
      setSelectedMonth(undefined);
      setSelectedYear(undefined);
    }
  }, [birthdate]);

  useEffect(() => {
    if (birthdate && birthdate !== null) {
      const userBirthdate = new Date(birthdate);
      if (months !== undefined && months.length > 0) {
        setSelectedMonth({
          value: userBirthdate.getMonth() + 1,
          name: months[userBirthdate.getMonth()].name,
        });

        handleMonthChange();
      }
    } else {
      setSelectedMonth(undefined);
    }
  }, [months]);

  useEffect(() => {
    if (user.error && updated) {
      setIsLoading(false);
      alert(AlertType.error, errorMessage);
    } else {
      setIsLoading(false);
    }
  }, [user.error]);

  useEffect(() => {
    initSelects();
    if (form.mobilephone) {
      setMobilephone(form.mobilephone.number);

      // TODO init initial phone code
      const code = countryCodes.find(
        (c) => c.countryCode === form.mobilephone?.country
      );
      if (code) setSelectedCode(code);
    }
    setForm({ ...form, gender: getGender(user.info?.gender || "") });
  }, []);

  useEffect(() => {
    handleInvalidForm();
    compareInfo();
  }, [form]);

  useEffect(() => {
    if (user.updatedSuccess) {
      alert(
        AlertType.success,
        t("modals.profile.edit_profile_modal.alert_success")
      );
    }
  }, [user.updatedSuccess]);

  useEffect(() => {
    setIsLoading(user.isLoading);
    if (user.error && showAlert) {
      alert(AlertType.error, errorMessage);
    }
  }, [user.isLoading, user.error]);

  useEffect(() => {
    if (userAvatar !== undefined) {
      setIsChanged(true);
    }
  }, [userAvatar]);

  return (
    <div className={styles.OutsideContainer}>
      <div className={styles.ProfileWrapper}>
        <div className={styles.ProfileWrapper__Top}>
          <span className={styles.ProfileWrapper__Top__Title}>
            {t("modals.profile.edit_profile_modal.title")}
          </span>
        </div>
        {/* END OF TOP */}
        <hr />

        <div className={`${styles.ProfileWrapper__FormContainer}`}>
          {/* OPEN COMPONENT */}
          <form onSubmit={handleSubmit} ref={formRef}>
            <fieldset disabled={isLoading}>
              <div
                className={`${styles.ProfileWrapper__FormContainer__OpenMenu} `}
              >
                <div
                  className={
                    styles.ProfileWrapper__FormContainer__OpenMenu__GridContainer
                  }
                >
                  {/* avatar */}
                  <div
                    className={
                      styles.ProfileWrapper__FormContainer__OpenMenu__GridContainer__FormItem
                    }
                    id={styles["avatar"]}
                  >
                    <label htmlFor="avatar">
                      {t("modals.profile.edit_profile_modal.avatar")}
                    </label>
                    <div className={styles.InputImageContainer}>
                      <div style={{ width: "194px", height: "194px" }}>
                        <InputImage
                          width="100%"
                          height="100%"
                          noImageHeight="60%"
                          noImageWidth="60%"
                          preview={form.avatar}
                          imageResult={handleImage}
                        />
                      </div>
                    </div>
                  </div>
                  {/* gender */}
                  <div
                    className={
                      styles.ProfileWrapper__FormContainer__OpenMenu__GridContainer__FormItem
                    }
                    id={styles["gender"]}
                  >
                    <label htmlFor="gender">
                      {t("modals.profile.edit_profile_modal.gender")}
                    </label>

                    <FiraSelect
                      width="100%"
                      height="40px"
                      selectStyle={FiraSelectStyle.outline}
                      currentSelection={selectedGender}
                      renderSelection={(gender) => {
                        if (gender) return <div>{gender.name}</div>;
                        return (
                          <div>
                            {t(
                              "modals.profile.edit_profile_modal.selectPlaceholder"
                            )}
                          </div>
                        );
                      }}
                    >
                      <>
                        {genderData.map((item, i) => (
                          <div
                            key={i}
                            onClick={() => handleGenderSelect(item.value)}
                          >
                            {item.name}
                          </div>
                        ))}
                      </>
                    </FiraSelect>
                  </div>
                  {/* username */}
                  <div
                    className={
                      styles.ProfileWrapper__FormContainer__OpenMenu__GridContainer__FormItem
                    }
                    id={styles["username"]}
                  >
                    <label htmlFor="username">
                      {t("modals.profile.edit_profile_modal.username")}
                    </label>
                    <Input
                      inputName={"username"}
                      inputId={"username"}
                      width="100%"
                      height="40px"
                      inputStyle={InputStyles.profile}
                      value={form.username}
                      onChange={handleChange}
                      isDisabled={true}
                    />
                  </div>
                  {/* firstname */}
                  <div
                    className={
                      styles.ProfileWrapper__FormContainer__OpenMenu__GridContainer__FormItem
                    }
                    id={styles["firstname"]}
                  >
                    <label htmlFor="firstName">
                      {t("modals.profile.edit_profile_modal.firstName")}
                    </label>
                    <Input
                      inputName={"firstName"}
                      inputId={"firstName"}
                      width="100%"
                      height="40px"
                      inputStyle={InputStyles.profile}
                      value={form.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  {/* lastname */}
                  <div
                    className={
                      styles.ProfileWrapper__FormContainer__OpenMenu__GridContainer__FormItem
                    }
                    id={styles["lastname"]}
                  >
                    <label htmlFor="lastName">
                      {t("modals.profile.edit_profile_modal.lastName")}(s)
                    </label>
                    <Input
                      inputName={"lastName"}
                      inputId={"lastName"}
                      width="100%"
                      height="40px"
                      inputStyle={InputStyles.profile}
                      value={form.lastName}
                      onChange={handleChange}
                    />
                  </div>
                  {/* birthdate */}
                  <div
                    className={
                      styles.ProfileWrapper__FormContainer__OpenMenu__GridContainer__FormItem
                    }
                    id={styles["birthdate"]}
                  >
                    <label htmlFor="birthdate">
                      {t("modals.profile.edit_profile_modal.birthdate")}
                    </label>
                    <div className={styles.birthdateArea}>
                      <div className={styles.birthdateArea__Item}>
                        <FiraSelect
                          width="100%"
                          height="40px"
                          renderSelection={(day) => {
                            if (day) return <div>{day.name}</div>;
                            return (
                              <div>
                                {t("modals.profile.edit_profile_modal.day")}
                              </div>
                            );
                          }}
                          selectStyle={FiraSelectStyle.outline}
                          currentSelection={selectedDay}
                        >
                          <>
                            {days.map((day, i) => {
                              return (
                                <div key={i} onClick={() => selectDay(day)}>
                                  {day.name}
                                </div>
                              );
                            })}
                          </>
                        </FiraSelect>
                      </div>
                      <div className={styles.birthdateArea__Item}>
                        <FiraSelect
                          width="100%"
                          height="40px"
                          selectStyle={FiraSelectStyle.outline}
                          currentSelection={selectedMonth}
                          renderSelection={(month) => {
                            if (month)
                              return (
                                <div style={{ textTransform: "capitalize" }}>
                                  {month.name}
                                </div>
                              );
                            return (
                              <div>
                                {t("modals.profile.edit_profile_modal.month")}
                              </div>
                            );
                          }}
                        >
                          <>
                            {months &&
                              months.map((month, i) => {
                                return (
                                  <div
                                    key={i}
                                    onClick={() => selectMonth(month)}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {month.name}
                                  </div>
                                );
                              })}
                          </>
                        </FiraSelect>
                      </div>
                      <div className={styles.birthdateArea__Item}>
                        <FiraSelect
                          width="100%"
                          height="40px"
                          renderSelection={(year) => {
                            if (year) return <div>{year.name}</div>;
                            return (
                              <div>
                                {t("modals.profile.edit_profile_modal.year")}
                              </div>
                            );
                          }}
                          selectStyle={FiraSelectStyle.outline}
                          currentSelection={selectedYear}
                        >
                          <>
                            {years.map((year, i) => {
                              return (
                                <div key={i} onClick={() => selectYear(year)}>
                                  {year.name}
                                </div>
                              );
                            })}
                          </>
                        </FiraSelect>
                      </div>
                    </div>
                  </div>
                  {/* phonenumber */}
                  <div
                    className={
                      styles.ProfileWrapper__FormContainer__OpenMenu__GridContainer__FormItem
                    }
                    id={styles["phonenumber"]}
                  >
                    <label htmlFor="mobilephone">
                      {t("modals.profile.edit_profile_modal.mobilephone")}
                    </label>
                    <div className={styles.phoneNumberArea}>
                      <div>
                        <FiraSelect
                          currentSelection={selectedCode}
                          selectStyle={FiraSelectStyle.phonecode}
                          width="100%"
                          height="100%"
                          renderSelection={(code) => {
                            if (code)
                              return (
                                <div
                                  style={{
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {code.dialCode}{" "}
                                  <img src={code.flagUrl} alt={code.name} />
                                </div>
                              );

                            return (
                              <div>
                                {t(
                                  "modals.profile.edit_profile_modal.codePlaceholder"
                                )}
                              </div>
                            );
                          }}
                        >
                          <>
                            {countryCodes.map((val, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handleCallingCodeSelect(val)}
                                  style={{
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {val.name}{" "}
                                  <img src={val.flagUrl} alt={val.name} />
                                </div>
                              );
                            })}
                          </>
                        </FiraSelect>
                      </div>
                      <div>
                        <Input
                          inputName={"mobilephone"}
                          inputId={"mobilephone"}
                          inputStyle={InputStyles.profile}
                          value={mobilephone}
                          onChange={(e) => {
                            phoneMask(e);
                          }}
                          width="100%"
                          height="100%"
                          placeholder={t(
                            "modals.profile.edit_profile_modal.numberPlaceholder"
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  {/* email */}
                  <div
                    className={
                      styles.ProfileWrapper__FormContainer__OpenMenu__GridContainer__FormItem
                    }
                    id={styles["email"]}
                  >
                    <label htmlFor="email">
                      {t("modals.profile.edit_profile_modal.email")}
                    </label>
                    <Input
                      inputId={"email"}
                      inputName={"email"}
                      width={"100%"}
                      height="40px"
                      inputStyle={InputStyles.profile}
                      value={form.email}
                      onChange={handleChange}
                      isDisabled={true}
                    />
                  </div>
                  {/* buttons */}
                  <div id={styles["bottom-btns"]}>
                    <Button
                      buttonStyle={ButtonStyle.border}
                      height={"32px"}
                      width={"106px"}
                      type={"button"}
                      clickHandler={(e) => {
                        handleReset(e);
                      }}
                    >
                      <span className={styles.CancelButton}>
                        {t("store.edit_store_modal.cancel")}
                      </span>
                    </Button>
                    <Button
                      height={"32px"}
                      width={"106px"}
                      type={"submit"}
                      isDisabled={isLoading || invalid || !isChanged}
                    >
                      <span className={styles.SaveButton}>
                        {t("store.edit_store_modal.save")}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
