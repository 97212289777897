import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import AgoraRTC, {
  IAgoraRTCClient,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  UID,
  IAgoraRTCRemoteUser,
} from "agora-rtc-sdk-ng";

const CHANNEL_TYPE = "live";
const CODEC_TYPE = "vp8";
const CLIENT_TYPE = "audience";

type LiveStreamParamsType = {
  // A variable to hold a remote audio track.
  remoteAudioTrack?: IRemoteAudioTrack;
  // A variable to hold a remote video track.
  remoteVideoTrack?: IRemoteVideoTrack;
  // A variable to hold the remote user id.s
  remoteUid: UID;
};

interface Props {
  setVideoExist: React.Dispatch<React.SetStateAction<boolean>>;
  subscriberData: { channel: string; appId: string; token: string };
  handlevideoPlayerRef: Dispatch<SetStateAction<HTMLVideoElement | null>>;
  isVideoMuted: boolean;
  // setIsVideoMuted:Dispatch<SetStateAction<boolean>,
}
export const AgoraPlayer: React.FC<Props> = ({
  setVideoExist,
  subscriberData,
  handlevideoPlayerRef,
  isVideoMuted,
}) => {
  const videoPlayerWrapperRef = useRef<HTMLDivElement>(null);
  const agoraClientRef = useRef<IAgoraRTCClient | null>(null);
  const [channelParameters, setChannelParameters] =
    useState<LiveStreamParamsType>();

  const connectToLiveStream = async () => {
    // Join the channel
    try {
      agoraClientRef.current = await AgoraRTC.createClient({
        mode: CHANNEL_TYPE,
        codec: CODEC_TYPE,
      });

      await agoraClientRef.current.setClientRole(CLIENT_TYPE, { level: 1 });

      await agoraClientRef.current.join(
        subscriberData.appId,
        subscriberData.channel,
        subscriberData.token
      );

      agoraClientRef.current.on("user-published", async (user, mediaType) => {
        switch (mediaType) {
          case "video":
            await agoraClientRef.current?.subscribe(user, mediaType);
            setChannelParameters((previousState) => ({
              ...previousState,
              remoteVideoTrack: user.videoTrack,
              remoteUid: user.uid,
            }));
            break;
          case "audio":
            await agoraClientRef.current?.subscribe(user, mediaType);
            setChannelParameters((previousState) => ({
              ...previousState,
              remoteAudioTrack: user.audioTrack,
              remoteUid: user.uid,
            }));
            break;
          default:
            break;
        }
      });
      agoraClientRef.current.on("user-unpublished", async (user, mediaType) => {
        switch (mediaType) {
          case "video":
            await agoraClientRef.current?.unsubscribe(user, mediaType);
            setChannelParameters((previousState) => ({
              ...previousState,
              remoteVideoTrack: undefined,
              remoteUid: user.uid,
            }));
            setVideoExist(false);
            break;
          case "audio":
            await agoraClientRef.current?.unsubscribe(user, mediaType);
            setChannelParameters((previousState) => ({
              ...previousState,
              remoteAudioTrack: undefined,
              remoteUid: user.uid,
            }));
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Failed to join the channel:", error);
    }
  };
  useEffect(() => {
    connectToLiveStream();
    return () => {
      // Clean up
      if (agoraClientRef.current) {
        agoraClientRef.current.removeAllListeners();
        agoraClientRef.current.leave();
        // agoraClientRef.current.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (channelParameters) {
      if (channelParameters.remoteVideoTrack && videoPlayerWrapperRef.current) {
        channelParameters.remoteVideoTrack.play(videoPlayerWrapperRef.current, {
          fit: "contain",
        });
        setVideoExist(true);
        const videoTrackId = channelParameters.remoteVideoTrack.getTrackId();
        const playerRef = document.getElementById(
          `video_${videoTrackId}`
        ) as HTMLVideoElement;

        handlevideoPlayerRef(playerRef);
        // videoPlayerRef.current = document.getElementById(
        //   `video_${videoTrackId}`
        // ) as HTMLVideoElement;
      } else {
        // setVideoExist(false);
      }
    }
  }, [channelParameters]);

  useEffect(() => {
    if (isVideoMuted) {
      channelParameters?.remoteAudioTrack &&
        channelParameters.remoteAudioTrack.stop();
    } else {
      channelParameters?.remoteAudioTrack &&
        channelParameters.remoteAudioTrack.play();
    }
  }, [isVideoMuted]);

  return (
    <div
      ref={videoPlayerWrapperRef}
      style={{ width: "100%", height: "100%" }}
    ></div>
  );
};
