import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
    currentColor?: string;
    height?: string;
}

const SvgMobileScreenChatMockup = ({
                            title,
                            titleId,
        currentColor,
    height,
                            ...props
                        }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="91"
        height={height}
        fill="none"
        viewBox="0 0 91 200"
    >
        <g>
            <rect
                width="17.162"
                height="20.637"
                x="0.75"
                y="-0.75"
                fill="#F7F8F9"
                stroke={currentColor}
                strokeWidth="1.5"
                rx="3.25"
                transform="matrix(1 0 0 -1 4.97 52.7)"
            ></rect>
            <rect
                width="89.323"
                height="199"
                x="0.992"
                y="0.5"
                stroke="#D5DDE0"
                rx="9.5"
            ></rect>
            <rect
                width="10.45"
                height="11.45"
                fill={currentColor}
                rx="3"
                transform="matrix(1 0 0 -1 75.885 182.441)"
            ></rect>
            <rect
                width="10.45"
                height="9.924"
                fill={currentColor}
                rx="3"
                transform="matrix(1 0 0 -1 75.885 196.945)"
            ></rect>
            <rect
                width="10.45"
                height="10.687"
                fill={currentColor}
                rx="3"
                transform="matrix(1 0 0 -1 75.885 166.412)"
            ></rect>
            <rect
                width="10.45"
                height="9.924"
                fill={currentColor}
                rx="3"
                transform="matrix(1 0 0 -1 75.885 151.143)"
            ></rect>
            <rect
                width="10.45"
                height="10.687"
                fill={currentColor}
                rx="3"
                transform="matrix(1 0 0 -1 75.885 136.641)"
            ></rect>
            <rect
                width="12.69"
                height="3.817"
                fill="#F7F8F9"
                rx="1"
                transform="matrix(1 0 0 -1 8.703 25.19)"
            ></rect>
            <rect
                width="13.436"
                height="3.817"
                fill="#F7F8F9"
                rx="1"
                transform="matrix(1 0 0 -1 72.9 15.266)"
            ></rect>
            <rect
                width="10.45"
                height="10.687"
                fill="#F7F8F9"
                rx="5.225"
                transform="matrix(1 0 0 -1 9.45 18.32)"
            ></rect>
            <rect
                width="64.189"
                height="18.347"
                x="0.75"
                y="-0.75"
                fill="#F7F8F9"
                stroke={currentColor}
                strokeWidth="1.5"
                rx="1.25"
                transform="matrix(1 0 0 -1 6.465 161.096)"
            ></rect>
            <rect
                width="65.689"
                height="19.847"
                fill="#F7F8F9"
                rx="2"
                transform="matrix(1 0 0 -1 6.465 183.973)"
            ></rect>
            <rect
                width="65.689"
                height="12.977"
                fill="#F7F8F9"
                rx="2"
                transform="matrix(1 0 0 -1 6.465 199.236)"
            ></rect>
        </g>
    </svg>
);

export default SvgMobileScreenChatMockup;
