import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { refreshTokenActionCreator } from "../../../store/Authentication/actions";
import { RootState } from "../../../utils/types";

const AuthGuard: React.FC = () => {
  const {
    redirectedFromFiraBusiness,
    isUserLogIn,
    sessionId,
    currentStoreIdSession,
  } = useSelector((state: RootState) => state.authentication);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUserLogIn && !redirectedFromFiraBusiness) {
      sessionId &&
        dispatch(refreshTokenActionCreator(sessionId, currentStoreIdSession));
    }

    return;
  }, []);

  return isUserLogIn ? <Outlet /> : <Navigate to={"/"} />;
};

export default AuthGuard;
